// Import necessary packages
import React, { useEffect, useMemo, useRef } from 'react';
import { Grid } from 'antd';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import AppLoader from '../common/AppLoader';
import { LabelBullet } from '@amcharts/amcharts4/charts';
import './style.css';
import { getCurrentResolution } from '../operationalAnalytics/common';
import loading_logo from '../../assets/images/loading_logo.gif';

const { useBreakpoint } = Grid;

// Apply amCharts themes
am4core.useTheme(am4themes_animated);

const dataTypes: any = {
  time: 'hours',
  area: 'acres',
};

interface Props {
  data?: any;
  loader: boolean;
  chartId: string;
  labels?: string[];
  barColors: string[];
  yTitle?: string;
  onClick?: (category: string) => void;
  dataType?: string;
  className?: string;
  tabId?: string;
  cardWidth?: number;
}

const MultiStackedBarChart: React.FC<Props> = ({
  data,
  loader,
  chartId,
  labels,
  barColors,
  yTitle,
  onClick,
  dataType,
  className,
  tabId,
  cardWidth,
}) => {
  const screens = useBreakpoint();
  const resolution = getCurrentResolution(screens);
  const chartRef = useRef<any>(null);

  const getDataType = useMemo(() => {
    return dataType ? dataTypes[dataType] : 'hours';
  }, [dataType]);

  useEffect(() => {
    // Create chart instance
    const chart = am4core.create(chartId, am4charts.XYChart);
    chartRef.current = chart;
    chart.logo.disabled = true;
    chart.paddingTop = 44;
    if (!loader && data?.length > 0) {
      // Add data
      chart.data = data;
    }

    chart.responsive.enabled = true;

    // Create axes
    const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'category';
    chart.paddingBottom = 0;

    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.grid.template.disabled = true;
    categoryAxis.renderer.minGridDistance = 9;
    //working for 4 data
    categoryAxis.renderer.cellStartLocation = 0.3;
    categoryAxis.renderer.cellEndLocation = 0.65;

    if (data.length >= 8) {
      categoryAxis.renderer.cellStartLocation = 0.3;
      categoryAxis.renderer.cellEndLocation = 0.68;
    }

    categoryAxis.renderer.labels.template.fontFamily = 'Montserrat';
    categoryAxis.renderer.labels.template.fontWeight = '400'; // Set font weight
    categoryAxis.renderer.labels.template.fontSize = 10;
    categoryAxis.renderer.labels.template.fill = am4core.color('#f00');
    categoryAxis.renderer.line.disabled = true;

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.fontSize = 10;
    valueAxis.title.fontFamily = 'Montserrat';
    valueAxis.title.fontWeight = '500';
    valueAxis.title.fill = am4core.color('#333333');

    valueAxis.renderer.labels.template.fontSize = 10;
    valueAxis.renderer.labels.template.fontFamily = 'Montserrat';
    valueAxis.renderer.labels.template.fontWeight = '400';
    valueAxis.renderer.labels.template.fill = am4core.color('#5C5C5C');
    valueAxis.maxPrecision = 0;

    // valueAxis.renderer.labels.template.paddingRight = 0;
    // valueAxis.renderer.labels.template.paddingLeft = 0;
    // valueAxis.renderer.labels.template.marginRight = 6;
    // valueAxis.renderer.labels.template.marginLeft = 6;
    categoryAxis.renderer.labels.template.paddingBottom = 0;
    categoryAxis.renderer.labels.template.marginBottom = 0;
    // valueAxis.min = 0;
    if (yTitle) valueAxis.title.text = yTitle;
    const adapters: { [category: string]: any } = {};

    // Create series
    function createSeries(
      field: any,
      name: any,
      stacked: any,
      color: any,
      time?: string,
    ) {
      if (time) {
        const filteredData = data?.filter(
          (item: any) => item[field] !== '0.00',
        );

        if (filteredData.length === 0) {
          return; // Skip creating the series if all values are zero
        }
      } else {
        const filteredData = data?.filter((item: any) => item[field] !== 0);

        if (filteredData.length === 0) {
          return; // Skip creating the series if all values are zero
        }
      }

      const series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = field;
      series.dataFields.categoryX = 'category';
      series.name = name;
      // series.columns.template.tooltipText =
      //   time.length > 1 ? `[bold]{${time}}[/]` : '[bold]{valueY}[/]';
      // series.columns.template.tooltipText = `[bold]{${time}}[/]`;
      series.columns.template.tooltipY = 0;
      series.clustered = true;

      series.columns.template.width = am4core.percent(80);
      if (onClick) {
        series.cursorOverStyle = am4core.MouseCursorStyle.pointer;
        series.columns.template.events.on('hit', (ev: any) => {
          onClick(ev.target.dataItem.categoryX);
        });
      }
      if (time) {
        series.columns.template.tooltipHTML = `
      <div style="background-color: #99999C; font-size: 12px; padding: 
      8px, 12px, 8px, 12px, font-family: Montserrat">
      <strong>{${time}}</strong>
      </div>
      `;
      } else {
        series.columns.template.tooltipHTML = `
      <div style="background-color: #99999C; font-size: 12px; padding: 
      8px, 12px, 8px, 12px, font-family: Montserrat">
      <strong>{valueY}</strong>
      </div>
      `;
      }

      if (series.tooltip) {
        series.tooltip.pointerOrientation = 'down';
        series.tooltip.getFillFromObject = false;
        series.tooltip.background.fill = am4core.color('#99999C');
        series.tooltip.label.fill = am4core.color('#fff');
        series.columns.template.tooltipPosition = 'pointer';
        series.tooltip.background.stroke = am4core.color('#99999C');
        // series.tooltip.getFillFromObject = false;
      }

      series.stacked = stacked;
      series.columns.template.fill = am4core.color(color);
      series.strokeWidth = 0;
      series.columns.template.column.cornerRadiusTopLeft = 3;
      series.columns.template.column.cornerRadiusTopRight = 3;
      if (onClick) {
        chart.data.forEach((dataItem) => {
          const adapter = categoryAxis.renderer.labels.template.adapter.add(
            'html',
            (html: any, target: any) => {
              if (dataItem.category === target.dataItem.category) {
                adapters[dataItem.category] = target;
              }
              return target;
            },
          );

          series.columns.template.events.on('over', (event: any) => {
            const column = event.target;
            column.fillOpacity = 1;
            const category = event.target.dataItem.categoryX;
            const adapter = adapters[category];
            const labelElement =
              adapter.element.node.querySelector('.total-hours');
            labelElement.innerHTML = `Select`;
            labelElement.style.fontSize = '10px'; // Change font size
            labelElement.style.fontWeight = '600'; // Change font weight
            labelElement.style.color = '#007AFF';
            labelElement.style.fontFamily = 'Montserrat';
            chart?.series?.each((otherSeries: any) => {
              otherSeries?.columns?.each((otherColumn: any) => {
                if (
                  otherColumn?.dataItem?.categoryX ===
                    column?.dataItem?.categoryX &&
                  otherColumn !== column
                ) {
                  otherColumn.fillOpacity = 0.3; // Reduced opacity
                }
              });
            });
          });

          series.columns.template.events.on('out', (event: any) => {
            const category = event.target.dataItem.categoryX;
            const column = event.target;
            chart?.series?.each((otherSeries: any) => {
              otherSeries?.columns?.each((otherColumn: any) => {
                if (
                  otherColumn?.dataItem?.categoryX ===
                  column?.dataItem?.categoryX
                ) {
                  otherColumn.fillOpacity = 1; // Original opacity
                }
              });
            });
            const adapter = adapters[category];
            if (adapter) {
              const total = chart.data.find(
                (item) => item.category === category,
              )?.total;

              const labelElement =
                adapter.element.node.querySelector('.total-hours');
              labelElement.innerHTML = `${total}`;
              labelElement.style.fontWeight = '500'; // Change font weight
              labelElement.style.color = '#99999c';
              labelElement.style.fontFamily = 'Montserrat';
              labelElement.style.fontSize = '10px';
            }
          });
        });
      }
      return series;
    }
    if (!loader && data?.length > 0) {
      chart.events.on('beforedatavalidated', () => {
        // Use adapter to update label text with total hours
        categoryAxis.renderer.labels.template.adapter.add(
          'html',
          (html: any, target: any) => {
            const category = target.dataItem?.category;
            const total = chart.data.find(
              (item) => item.category === target.dataItem.category,
            )?.total;
            if (chartId === 'CoverageByOperations1') {
              return `<div class="category">${
                category == 'No Implement' ? 'Unselected' : category
              }<div> <div class="total-hours">${total} acres</div>`;
            }
            return `<div class="category">${
              category == 'No Implement' ? 'Unselected' : category
            }<div> <div class="total-hours">${total}</div>`;
          },
        );
      });
    }
    createSeries(
      'value1',
      'Value1',
      false,
      barColors[0],
      chartId !== 'CoverageByOperations1' ? 'time1' : '',
    );
    createSeries(
      'value2',
      'Value2',
      false,
      barColors[1],
      chartId !== 'CoverageByOperations1' ? 'time2' : '',
    );
    createSeries(
      'value3',
      'Value3',
      true,
      barColors[2],
      chartId !== 'CoverageByOperations1' ? 'time3' : '',
    );
    createSeries(
      'value4',
      'Value4',
      false,
      barColors[3],
      chartId !== 'CoverageByOperations1' ? 'time4' : '',
    );

    // Fix axis scale on load
    chart.events.on('ready', function (ev: any) {
      valueAxis.min = 0;
      valueAxis.max = valueAxis.maxZoomed;
      valueAxis.strictMinMax = true;
    });

    chart.zoomOutButton = new am4core.ZoomOutButton();
    chart.zoomOutButton.hide();

    if (chart.svgContainer) {
      chart.svgContainer.autoResize = false;
    }

    chart.zoomOutButton = new am4core.ZoomOutButton();
    chart.zoomOutButton.hide();

    // Disable zooming with mouse wheel
    chart.chartContainer.wheelable = false;
    // Ensure the chart cannot be zoomed programmatically
    chart.seriesContainer.draggable = false;
    chart.seriesContainer.resizable = false;
    chart.plotContainer.mouseOptions.sensitivity = 0.5;

    // Set up custom scrollbar
    if (!loader && chart && data?.length > 4) {
      chart.scrollbarX = new am4core.Scrollbar();
      chart.scrollbarX.marginBottom = 0; // Set the margin from the top
      chart.scrollbarX.parent = chart.bottomAxesContainer;
      chart.scrollbarX.startGrip.disabled = true;
      chart.scrollbarX.endGrip.disabled = true;
      chart.scrollbarX.minHeight = 4;
      if (chart.scrollbarX) {
        if (data.length <= 6) {
          chart.scrollbarX.start = 0;
          chart.scrollbarX.end = 0.8;
        } else if (data.length > 6 && data.length <= 8) {
          chart.scrollbarX.start = 0;
          chart.scrollbarX.end = 0.5;
        } else {
          chart.scrollbarX.start = 0;
          chart.scrollbarX.end = 0.4;
        }
      }
      // Prevent zooming by double click
      chart.seriesContainer.events.disableType('doublehit');
      chart.chartContainer.background.events.disableType('doublehit');
      chart.chartContainer.background.events.disableType('over');
    }

    let indicator: any;
    let indicatorInterval: any;

    function showIndicator(chart: any) {
      if (!indicator) {
        indicator = chart.tooltipContainer.createChild(am4core.Container);
        indicator.background.fill = am4core.color('#fff');
        indicator.background.fillOpacity = 0.8;
        indicator.width = am4core.percent(100);
        indicator.height = am4core.percent(100);

        // const indicatorLabel = indicator.createChild(am4core.Label);
        // indicatorLabel.text = 'Loading...';
        // indicatorLabel.align = 'center';
        // indicatorLabel.valign = 'middle';
        // indicatorLabel.fontSize = 16;
        // indicatorLabel.fontFamily = 'Montserrat';
        // indicatorLabel.dy = 50;

        // eslint-disable-next-line no-var
        var hourglass = indicator.createChild(am4core.Image);
        hourglass.href = loading_logo;
        hourglass.align = 'center';
        hourglass.valign = 'middle';
        hourglass.horizontalCenter = 'middle';
        hourglass.verticalCenter = 'middle';
        hourglass.scale = 0.7;
      }

      indicator.hide(0);
      indicator.show();

      // clearInterval(indicatorInterval);
      // indicatorInterval = setInterval(function () {
      //   hourglass?.animate(
      //     [
      //       {
      //         from: 0,
      //         to: 360,
      //         property: 'rotation',
      //       },
      //     ],
      //     2000,
      //   );
      // }, 3000);
    }

    function hideIndicator() {
      indicator?.hide();
      clearInterval(indicatorInterval);
    }

    loader ? showIndicator(chart) : hideIndicator();

    return () => {
      if (chart) {
        chart.dispose();
      }
    };
  }, [data, loader, tabId, cardWidth]);

  return <div className={className} id={chartId} />;
};

export default MultiStackedBarChart;
