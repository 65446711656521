/* eslint-disable no-console */
import { Input, Modal, notification, Select, Spin } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { getVineRowsData, setVineLane } from '../../constants/Api';
import { PolygonDropDown, VineLanePayloadType } from '../../constants/types';
import { ApplicationContext } from '../../context/AppContext';
import translate from '../../locale/en_translate.json';

import { useTranslation } from 'react-i18next';

interface Props {
  showModal: boolean;
  handleCancel: (reload?: boolean) => void;
  vineLanePayload: VineLanePayloadType;
  polygonsListForPop: PolygonDropDown[];
}
export const VineLaneModal1: React.FC<Props> = ({
  showModal,
  handleCancel,
  vineLanePayload,
  polygonsListForPop,
}: Props) => {
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const [name, setName] = useState<string>('');
  const [leftVineName, setLeftVineName] = useState<string>('');
  const [leftVineUuid, setLeftVineUuid] = useState<string>('');
  const [rightVineName, setRightVineName] = useState<string>('');
  const [rightVineUuid, setRightVineUuid] = useState<string>('');
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [loader, setLoader] = useState<boolean>(false);
  const [rows, setRows] = useState<any>([]);
  const [num, setNum] = useState(0);
  const [selectedPolygonUuid, setSelectedPolygonUuid] = useState('');
  useEffect(() => {
    if (vineLanePayload) {
      if (vineLanePayload.name) {
        setName(vineLanePayload.name);
      }
      if (vineLanePayload.vineRows && vineLanePayload.vineRows.length) {
        setLeftVineName(vineLanePayload.vineRows[0].name);
        setRightVineName(vineLanePayload.vineRows[1].name);
      }
      if (vineLanePayload.vineRows && vineLanePayload.vineRows.length) {
        setLeftVineUuid(vineLanePayload.vineRows[0].rowUuid);
        setRightVineUuid(vineLanePayload.vineRows[1].rowUuid);
      }

      getVineRows();
    }
  }, [vineLanePayload]);
  useEffect(() => {
    if (
      userDetails &&
      userDetails.organization &&
      selectedPolygonUuid &&
      selectedPolygonUuid.trim().length > 0 &&
      name &&
      name.trim().length > 0
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [userDetails, selectedPolygonUuid, name]);
  const getVineRows = async () => {
    try {
      const { organization } = userDetails;
      const response: any = await getVineRowsData(
        organization.api_url,
        organization.farm.id,
      );
      const { records } = response;
      setRows(records);
    } catch (error: any) {
      notification.error({
        message: error?.message,
      });
    }
  };

  const AddVineLane = async () => {
    try {
      setLoader(true);
      const payload = {
        name: name.trim(),
        left_row_uuid: leftVineUuid,
        right_row_uuid: rightVineUuid,
        polygon_uuid: selectedPolygonUuid,
      };
      setName(name.trim());
      // const payload = {
      //   start_vine_row_name: leftVineName,
      //   end_vine_row_name: rightVineName,
      //   polygon_uuid: selectedPolygonUuid,
      // };
      const { organization } = userDetails;
      if (vineLanePayload.action === 'ADD') {
        const resp = await setVineLane(
          organization.api_url,
          organization.farm.id,
          payload,
        );
        if (resp && resp.msg) {
          handleCancel(true);
          notification.success({
            message: resp.msg,
          });
        }
      }
    } catch (err: any) {
      notification.error({
        message: err?.message,
      });
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (
      rows &&
      rows.length &&
      leftVineUuid &&
      rightVineUuid &&
      leftVineUuid === rightVineUuid
    ) {
      const n = num === rows.length - 1 ? 0 : num;
      setLeftVineName(rows[n].name);
      setRightVineUuid(rows[n].vine_row_uuid);
      setNum(n + 1);
    }
  }, [leftVineUuid, rows]);

  useEffect(() => {
    if (
      rows &&
      rows.length &&
      leftVineUuid &&
      rightVineUuid &&
      leftVineUuid === rightVineUuid
    ) {
      const n = num === rows.length - 1 ? 0 : num;
      setRightVineName(rows[n].name);
      setLeftVineUuid(rows[n].vine_row_uuid);
      setNum(n + 1);
    }
  }, [rightVineUuid, rows]);
  return (
    <Modal
      className="commonPopup addPolygon"
      title={t(translate.map.crop_lane)}
      centered
      width={'60vw'}
      closable={false}
      visible={showModal}
      onCancel={() => handleCancel()}
      okText="Save"
      onOk={AddVineLane}
      okButtonProps={{
        className: 'btnSave ',
        disabled: isDisabled,
      }}
      cancelButtonProps={{
        className: 'btnCancel',
      }}
    >
      <div className="formRow">
        <div className="formCol">
          <label className="formLabel">
            Name <span style={{ color: 'red' }}> *</span>
          </label>
          <Input
            className="commInput"
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
        </div>
        <div className="formCol">
          <label className="formLabel">
            {t(translate.map.boundary)} <span style={{ color: 'red' }}> *</span>
          </label>
          <Select
            className="customSelect"
            onSelect={(value) => {
              setSelectedPolygonUuid(value);
            }}
            value={selectedPolygonUuid}
          >
            {polygonsListForPop.map((data: any) => {
              if (data.name !== '') {
                return (
                  <option value={data.polygon_uuid} key={data.polygon_uuid}>
                    {data.name} ({data.color})
                  </option>
                );
              }
            })}
          </Select>
        </div>
      </div>
      <div className="formRow">
        <div className="formCol">
          <label className="formLabel">
            Left {t(translate.map.crop_row)}{' '}
            <span style={{ color: 'red' }}> *</span>
          </label>
          <Select
            className="customSelect"
            onSelect={(e) => setLeftVineUuid(e)}
            value={leftVineUuid}
          >
            {rows.map((data: any) => {
              if (data.name !== '') {
                return (
                  <option value={data.vine_row_uuid} key={data.vine_row_uuid}>
                    {data.name}
                  </option>
                );
              }
            })}
          </Select>
        </div>
        <div className="formCol">
          <label className="formLabel">
            Right {t(translate.map.crop_row)}{' '}
            <span style={{ color: 'red' }}> *</span>
          </label>
          <Select
            className="customSelect"
            onSelect={(e) => setRightVineUuid(e)}
            value={rightVineUuid}
          >
            {rows.map((data: any) => {
              if (data.name !== '') {
                return (
                  <option value={data.vine_row_uuid} key={data.vine_row_uuid}>
                    {data.name}
                  </option>
                );
              }
            })}
          </Select>
        </div>
      </div>
      <div className="edit-loader">
        {loader && (
          <div className="loader">
            <Spin size="large" />
          </div>
        )}
      </div>
    </Modal>
  );
};
export default VineLaneModal1;
