import React, { useState } from 'react';
import { Tabs } from 'antd';
// import { track } from '../../../util/logger';
const { TabPane } = Tabs;
// import '../../common/tab.css';
import Tractors from './Tractors';
import Customers from './Customers';

const Inventory: React.FC = () => {
  const [tabid, setTabid] = useState('1');
  return (
    <Tabs
      activeKey={tabid}
      centered
      id="fleet-tab-FleetHealthContainer"
      className="inventoryTabs"
      onChange={(key) => {
        setTabid(key);
        // track(key, {
        //   event: `${key} Tab`,
        // });
      }}
    >
      <TabPane tab="Customers" key="1" className="attTableHeight mBottom0">
        <Customers />
      </TabPane>
      <TabPane tab="Tractors" key="2" className="attTableHeight mBottom0">
        <Tractors />
      </TabPane>
    </Tabs>
  );
};

export default Inventory;
