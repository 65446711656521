export const activeFilterData = [
  {
    id: true,
    name: 'Active',
  },
  {
    id: false,
    name: 'Inactive',
  },
];
export const statusFilter = [
  {
    id: true,
    name: 'Active',
  },
  {
    id: false,
    name: 'Inactive',
  },
];
export const resolveFilterData = [
  { name: 'Resolved', value: 'completed' },
  { name: 'Unresolved', value: 'open,inprogress' },
];

export const resolveFilterData1 = [
  { name: 'Resolved', value: 'resolved' },
  { name: 'Unresolved', value: 'unresolved' },
];

export const softUpdateFilterData = [
  { name: 'Up To Date', id: 'uptodate' },
  { name: 'Update Ready', id: 'update_ready' },
  { name: 'Show All', id: 'show_all' },
];

export const product = [
  { name: 'Tractor', value: 'Tractor' },
  { name: 'Battery', value: 'Battery' },
  { name: 'Base Station', value: 'Base Station' },
];

export const days = [
  { name: '7 Days', value: '7' },
  { name: '15 Days', value: '15' },
  { name: '30 Days', value: '30' },
];

export const ptoOptions = [
  {
    id: true,
    name: 'ON',
  },
  {
    id: false,
    name: 'OFF',
  },
];

export const floatingHitchData = [
  {
    id: true,
    name: 'Yes',
  },
  {
    id: false,
    name: 'No',
  },
];

export const Updateproduct = [
  { name: 'Tractor', value: 'Tractor' },
  { name: 'Basestation', value: 'Base Station' },
];

export const StatusData = [
  { name: 'Online', id: 'online' },
  { name: 'Offline', id: 'offline' },
  { name: 'Show All', id: 'show_all' },
];

export const MaintenanceStatus = [
  { name: 'Open', id: 'Open' },
  { name: 'Due', id: 'Due' },
  { name: 'Completed', id: 'Completed' },
  { name: 'Show All', id: 'show_all' },
];

export const GearData = [
  { name: 'L1', id: 'L1' },
  { name: 'L2', id: 'L2' },
  { name: 'L3', id: 'L3' },
  { name: 'M1', id: 'M1' },
];

export const TypesData = [
  {
    value: 'Manual',
    name: 'Manual',
  },
  {
    value: 'Autodrive',
    name: 'Autodrive',
  },
];

export const statusList = [
  {
    value: 'All',
    name: 'All',
  },
  {
    value: 'created',
    name: 'Created',
  },
  {
    value: 'started',
    name: 'Started',
  },
  {
    value: 'finished',
    name: 'Finished',
  },
];

export const dateRangeFilters = [
  { name: 'Month', value: 'Monthly' },
  { name: 'Day', value: 'Daily' },
];
