/* eslint-disable react/require-default-props */
/* eslint-disable no-unused-vars */
import { Checkbox, TreeSelect } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';

interface Props {
  options: any[];
  handleSelect: (id?: any) => void;
  testId?: string;
  selectedValue?: string[];
  label?: string;
}

const MultiSelect: React.FC<Props> = ({
  options,
  handleSelect,
  testId,
  selectedValue,
  label,
}: Props) => {
  const [selectedId, setSelectedId] = useState<any[]>([]);
  const [list, setList] = useState<any[]>([]);
  const [focus, setFocus] = useState(false);

  const labelClass =
    focus || selectedId?.length ? 'label label-float' : 'label';

  const filterTreeNode = (inputValue: string, treeNode: any) => {
    if (!inputValue) {
      return true;
    }
    const titleText = treeNode?.title?.props?.children;
    if (titleText?.toLowerCase()?.includes(inputValue?.toLowerCase())) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    setSelectedId(selectedValue || []);
  }, [selectedValue]);

  const allIds = useMemo(
    () => options.map(({ id }: any) => String(id)),
    [options],
  );

  const handleSelectAllChange = (status: string) => {
    if (status === 'select') {
      onSelect(allIds);
    } else {
      onSelect([]);
    }
  };

  const onSelect = (ids: any) => {
    setSelectedId(ids.length ? ids : []);
    if (handleSelect) handleSelect(ids);
  };

  useMemo(() => {
    const toptions = options
      .sort((a: any, b: any) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
      )
      .map((data: any) => ({
        title: (
          <span className="ellipsis" data-testid={`${data.name}-MultiSelect`}>
            {data.name}
          </span>
        ),
        value: `${data.id}`,
      }));
    setList(toptions);
  }, [options]);

  return (
    <div className="multiSelectDropdown">
      <div
        className="float-label"
        onBlur={() => setFocus(false)}
        onFocus={() => setFocus(true)}
      >
        <TreeSelect
          onClear={() => onSelect([])}
          allowClear
          showSearch
          // placeholder={placeholder}
          data-testid={testId}
          treeCheckable
          defaultValue={selectedId}
          showCheckedStrategy={TreeSelect.SHOW_CHILD}
          className="treeSelect"
          style={{ minWidth: '250px', width: 'auto' }}
          onChange={onSelect}
          value={selectedId}
          maxTagCount={1}
          filterTreeNode={filterTreeNode}
          maxTagPlaceholder={(omittedValues: any) =>
            `+ ${omittedValues.length}`
          }
          dropdownRender={(menu) => (
            <>
              {selectedId?.length && selectedId.length === list.length ? (
                <Checkbox
                  onChange={() => handleSelectAllChange('unselect')}
                  checked={selectedId.length === list.length}
                  className="ant-select-tree-checkbox custom-select-dropdown"
                  data-testid="unSelectAllCheckbox-MultiSelect"
                >
                  Unselect All
                </Checkbox>
              ) : (
                <Checkbox
                  onChange={() => handleSelectAllChange('select')}
                  checked={selectedId.length === list.length}
                  className="ant-select-tree-checkbox custom-select-dropdown"
                  data-testid="selectAllCheckbox-MultiSelect"
                >
                  Select All
                </Checkbox>
              )}
              {menu}
            </>
          )}
          treeData={list}
        />
        <label className={labelClass}>{label} </label>
      </div>
    </div>
  );
};

export default MultiSelect;
