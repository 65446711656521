/* eslint-disable no-console */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-mixed-operators */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-underscore-dangle */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
import React, { useState, useContext, useEffect, useRef } from 'react';
import {
  notification,
  Select,
  Button,
  Modal,
  Tooltip,
  Layout,
  Row,
  DatePicker,
} from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { saveAs } from 'file-saver';
import {
  ExclamationCircleOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import { AxiosResponse } from 'axios';
import translate from '../../../../locale/en_translate.json';
import { ApplicationContext } from '../../../../context/AppContext';
import { sortAlphaNumber, initScroller } from '../../../../constants/Common';
import downloadIcon from '../../../../assets/images/nDwd.svg';
import favoriteIcon from '../../../../assets/images/favorite_icon.svg';
import './style.css';
import usePaginate from '../../../../hooks/usePaginate';
import constants from '../../../../constants/constant';
import { LogViewerDataIndex } from '../../common/types';
import useColumns from '../../../../hooks/useColumns';
import CustomSelect from '../../../common/CustomSelect';
import { hourInfo1 } from '../../common/common';
import InfiniteScrollTable from '../../../common/InfiniteScrollTable';
import AddBasestationLogFavModal from './AddBasestationLogFavModal';
import { BasestationLogDataModel } from './BasestationLogDataModel';
import BasestationLogViewerConvertStatus from './BasestationLogViewerConvertStatus';
import {
  downloadLogPdf,
  generateBaseLog,
  getBaseStationDetails,
  getBasestationLogViewer,
} from '../../API';
import { mapNexusBaseLogViewerData } from '../../../../lib/dataFormat';
import { Content } from 'antd/lib/layout/layout';
import TableExt from '../../../widget/TableExt';

const { DOWNLOAD_SIZE } = constants;
let columnsData: any = [];

const BasestationLogViewer: React.FC = () => {
  const { t } = useTranslation();
  const { confirm } = Modal;
  const { userDetails } = useContext(ApplicationContext);
  const [fromDate, setFromDate] = useState<any>(new Date());
  const [basestationsData, setBasestationData] = useState<any>([]);
  const [selectedBase, setSelectedBasestation] = useState<any>();
  const [selectedBaseId, setSelectedBaseId] = useState<any>();
  const [selectedDurationHour, setSelectedDurationHour] = useState<number>(1);
  const [addDataModel, setAddDataModel] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [selectedModelId, setSelectedModelId] = useState<Set<number>>(
    new Set<number>(),
  );
  const [selectedModel, setSelectedModel] = useState<string>('');
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [selectFrequency, setSelectFrequency] = useState<number>(10);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [addFavModal, setAddFavModal] = useState(false);
  const [toggleColumnsStatus, setToggleColumnsStatus] = useState(false);
  const [tractorMap, setTractorMap] = useState(new Map());
  const [modelsDINX, setModelsDINX] = useState<LogViewerDataIndex[]>([]);
  const [dataIndexs, setdataIndexs] = useState<any[]>([]);
  const [showConvert, setShowConvert] = useState<boolean>(false);
  const [transacationId, setTransactionId] = useState<number | undefined>();

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize] = useState<number>(25);
  const [total, setTotal] = useState<number>(0);
  const [initialize, setInitialize] = useState<boolean>(false);
  const tableRef = useRef<any>(null);
  const [sortInfo, setSortInfo] = useState(null);
  const [csvData, setCSVData] = useState<any[]>([]);
  const [downloadData, setDownloadData] = useState({
    isDownloaded: false,
    percent: 0,
    status: '',
  });

  const frequency = [
    { value: 10, name: '10 HZ' },
    { value: 1, name: '1 HZ' },
  ];
  const { filterData, setData, activity, hasMore, checkActivity } =
    usePaginate();
  const { headers, columns, setColumns, setColumnsData } =
    useColumns('BaseLogViewer');

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      getBaseStationList();
    }
  }, [userDetails]);

  useEffect(() => {
    loadLogViewer();
  }, [userDetails, pageNumber, pageSize, sortInfo]);

  const loadLogViewer = () => {
    if (userDetails && !userDetails.organization) return;
    if (checkActivity()) {
      // eslint-disable-next-line no-unused-expressions
      pageNumber !== 1 ? setPageNumber(1) : getLogViewer();
    } else if (activity.current === 'paginate' || activity.current === '') {
      getLogViewer();
    }
  };

  const wrapResult = async (
    userDetails: any,
    orgId: number,
    pageNumber: number,
    pageSize: number,
  ) => {
    const result = await getBasestationLogViewer(
      userDetails,
      orgId,
      pageNumber,
      pageSize,
      sortInfo,
    );
    return {
      result,
    };
  };

  const getLogViewer = async () => {
    try {
      if (checkActivity()) initScroller();
      setLoader(true);
      const { result } = await wrapResult(
        userDetails.organization.api_url,
        userDetails.organization.id,
        pageNumber,
        pageSize,
      );
      setTotal(result.total_records_count);
      const { records } = result;
      let data = Array.isArray(records) ? records : [];

      data = mapNexusBaseLogViewerData(data);
      setData(data);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    } finally {
      setLoader(false);
      if (!initialize) setInitialize(true);
    }
  };
  const handleSort = (value: any) => {
    activity.current = 'sort';
    setSortInfo(value);
  };
  useEffect(() => {
    columnsData = [
      {
        title: `${t(translate.logViewer.createdTime)}`,
        dataIndex: 'createTime',
        key: 'createTime',
        width: '180px',
        render: (createTime: string) => <span>{createTime}</span>,
      },
      {
        title: `${t(translate.logViewer.createdBy)}`,
        dataIndex: 'created_by',
        key: 'created_by',
        ellipsis: true,
        width: '130px',
        render: (createdBy: string) => <span>{createdBy}</span>,
      },
      {
        title: `${t(translate.logViewer.baseStation)}`,
        dataIndex: 'tractorName',
        key: 'tractorName',
        width: '150px',
        render: (tractorName: string) => (
          <span>
            {tractorName.length > 12 ? (
              <Tooltip title={tractorName}>{tractorName}</Tooltip>
            ) : (
              <>{tractorName}</>
            )}
          </span>
        ),
      },
      {
        title: `${t(translate.modal.organizationName)}`,
        dataIndex: 'orgName',
        key: 'orgName',
        width: '180px',
        render: (orgName: string) => <span>{orgName}</span>,
      },
      {
        title: `${t(translate.logViewer.modelName)}`,
        dataIndex: 'modelName',
        key: 'modelName',
        width: '130px',
        render: (modelName: string, record: any) => (
          <div>
            <span>{modelName}</span>
            {modelName === 'Debug' && (
              <Tooltip title={t(translate.modal.convertStatus)}>
                <ExclamationCircleOutlined
                  onClick={() => showStatus(record)}
                  style={{
                    color: 'orange',
                    fontSize: '14px',
                    paddingLeft: '10px',
                  }}
                />
              </Tooltip>
            )}
          </div>
        ),
      },
      {
        title: `${t(translate.logViewer.dataItems)}`,
        dataIndex: 'dataItems',
        key: 'dataItems',
        width: '190px',
        render: (data: string) => (
          <span>
            <div
              className="emailStyle"
              style={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                cursor: 'pointer',
              }}
              onClick={() => showDataItems(data)}
            >
              {data || '-'}
            </div>
          </span>
        ),
      },
      {
        title: `${t(translate.logViewer.startTime)}`,
        dataIndex: 'startTime',
        key: 'startTime',
        width: '180px',
        render: (startTime: string) => <span>{startTime}</span>,
      },
      {
        title: `${t(translate.logViewer.endTime)}`,
        dataIndex: 'endTime',
        key: 'endTime',
        width: '180px',
        render: (endTime: string) => <span>{endTime}</span>,
      },
      {
        title: `${t(translate.logViewer.status)}`,
        dataIndex: 'status',
        key: 'status',
        width: '110px',
        render: (status: string) => <span>{status}</span>,
      },
      {
        title: `${t(translate.logViewer.remarks)}`,
        dataIndex: 'remarks',
        key: 'remarks',
        width: '140px',
        render: (remarks: string) => <span>{remarks}</span>,
      },

      {
        title: `${t(translate.logViewer.actions)}`,
        dataIndex: 'actions',
        key: 'actions',
        width: '90px',
        // eslint-disable-next-line react/display-name
        render: (item: string, dataItem: any) => (
          <>
            {item === 'completed' &&
              !dataItem.remarks.includes('no data available') && (
                <>
                  <img
                    src={downloadIcon}
                    alt="download"
                    onClick={() => {
                      downloadPdfMethod(dataItem);
                    }}
                  />

                  <img
                    src={favoriteIcon}
                    alt="download"
                    className="favoriteIcon"
                    onClick={() => {
                      const idata = dataItem.data_indexes.map(
                        (ele: any, i: any) => {
                          const obj = {
                            data_index: ele,
                            name: dataItem.data_index_names[i],
                          };
                          return obj;
                        },
                      );
                      setdataIndexs(idata);
                      setSelectedModel(dataItem.modelName);
                      setAddFavModal(true);
                    }}
                  />
                </>
              )}
            {(item !== 'completed' ||
              dataItem.remarks.includes('no data available')) && <span>-</span>}
          </>
        ),
      },
    ];
    setColumnsData(columnsData);
  }, []);

  const handleLoadMore = () => {
    if (pageNumber === 1 && checkActivity() && document) initScroller();

    activity.current = 'paginate';
    setPageNumber(pageNumber + 1);
  };

  useEffect(() => {
    if (
      selectedBase &&
      selectedBase.serial_number &&
      fromDate &&
      selectedBase.customer_organization_id
    ) {
      setSaveDisabled(false);
    } else {
      setSaveDisabled(true);
    }
  }, [selectedBase, fromDate]);

  const showStatus = (record: any) => {
    setShowConvert(true);
    setTransactionId(record.transaction_id);
  };

  const showDataItems = (content: string) => {
    if (content !== '') {
      Modal.info({
        content,
      });
    }
  };

  const getBaseStationList = async () => {
    try {
      const basestation: any = await getBaseStationDetails(
        pageNumber,
        1000,
        '',
      );
      let data =
        basestation && basestation.records && basestation?.records.length > 0
          ? basestation?.records
          : [];
      const tractorData: any = [];
      // eslint-disable-next-line array-callback-return
      data.filter((ele: any) => {
        if (ele.id) {
          tractorData.push(ele);
        }
      });
      data = sortAlphaNumber(tractorData);
      setBasestationData(tractorData);
      const ttractorMap = data.reduce(
        (a: any, c: any) => a.set(c.id, c),
        new Map(),
      );
      setTractorMap(ttractorMap);
      setSelectedBaseId(tractorData[0]?.id);
      setSelectedBasestation(tractorData[0]);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    }
  };

  const fromDateChange = (date: any) => {
    setFromDate(date);
  };
  const handleTractor = async (id: any) => {
    setSelectedBaseId(id);
    setSelectedBasestation(tractorMap.get(id));
  };

  const generate = async () => {
    setLoader(true);
    try {
      const dataIndexes = Array.from(selectedModelId);
      if (dataIndexes.length === 0) {
        notification.error({
          message: 'please select Data Items',
        });
        setLoader(false);
        return;
      }
      const date = new Date(fromDate);
      date.setHours(0);
      date.setMinutes(0);
      const startHourString = moment(fromDate).format('HH');
      const startHour = parseInt(startHourString, 10);
      date.setHours(date.getHours() + startHour);
      const start = moment(date).format('YYYY-MM-DD-HH-00-00');
      date.setHours(date.getHours() + selectedDurationHour);
      const end = moment(date).format('YYYY-MM-DD-HH-00-00');
      const data_indexes = Array.from(selectedModelId);
      const serialNumber = selectedBase?.serial_number;
      const payload = {
        start_date_time: start,
        end_date_time: end,
        model_name: selectedModel,
        data_indexes,
        organization_id: selectedBase?.customer_organization_id,
        data_rate_in_hz: selectFrequency,
      };
      const records = await generateBaseLog(
        userDetails.organization.api_url,
        userDetails.organization.id,
        serialNumber,
        payload,
      );

      if (records && records.msg) {
        setLoader(false);
        notification.success({
          message: records.msg,
        });
        setTimeout(() => {
          handleRefresh();
        }, 2000);
      }
    } catch (err) {
      notification.error({
        message: 'error occurs at generate',
      });
    } finally {
      setLoader(false);
    }
  };

  const handleDurationHour = async (id: number) => {
    setSelectedDurationHour(id);
  };

  const handleFrequency = async (id: number) => {
    setSelectFrequency(id);
  };
  const closeDataModel = (
    selModel: string,
    selModels: LogViewerDataIndex[],
  ) => {
    setSelectedModel(selModel);
    setModelsDINX(selModels);
    const tSelectModelIds = selModels.reduce(
      (a: Set<number>, { data_index }: LogViewerDataIndex) => {
        a.add(data_index);
        return a;
      },
      new Set<number>(),
    );
    setSelectedModelId(tSelectModelIds);
    setAddDataModel(false);
  };

  const downloadPdfMethod = async (item: any) => {
    confirm({
      title: `${t(translate.logViewer.areYouSureToDownload)}?`,
      icon: <ExclamationCircleOutlined />,
      content: '',
      okButtonProps: {
        className: 'okBtn',
      },
      cancelButtonProps: {
        className: 'canelBtn',
      },
      onOk() {
        if (
          process.env.REACT_APP_ALPHA_FEATURES?.toLowerCase().includes('oauth')
        ) {
          window.open(item.file_path);
        } else {
          downloadPdf(item);
        }
      },
    });
  };

  const downloadPdf = async (item: any) => {
    try {
      setLoader(true);
      const { organization } = userDetails;
      const response: AxiosResponse<any> = await downloadLogPdf(
        organization.api_url,
        item.transaction_id,
      );
      let fileName = item.createTime.replace(' ', '-');
      const deposition: string = response.headers['content-disposition'];
      if (deposition && deposition.includes('file')) {
        const data = deposition.split('=');
        fileName = data.length > 0 ? data[1] : 'file';
      }

      saveAs(response.data, fileName);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    } finally {
      setLoader(false);
    }
  };

  const handleRefresh = () => {
    activity.current = 'refresh';
    loadLogViewer();
  };

  const closeConvert = () => {
    setShowConvert(false);
  };

  const handleDownload = async () => {
    try {
      if (downloadData.status === 'start') return;
      setDownloadData({
        ...downloadData,
        status: 'start',
        percent: 10,
      });
      let data: any[] = [];
      const pages = Math.ceil(total / DOWNLOAD_SIZE);
      // eslint-disable-next-line no-restricted-syntax
      for (const page of Array.from({ length: pages }, (_, i) => i + 1)) {
        // eslint-disable-next-line no-await-in-loop
        const { result } = await wrapResult(
          userDetails,
          userDetails.organization.id,
          page,
          DOWNLOAD_SIZE,
        );
        const { records } = result;
        let tdata = Array.isArray(records) ? records : [];
        tdata = mapNexusBaseLogViewerData(tdata);
        data = [...data, ...tdata];
        setDownloadData({
          ...downloadData,
          status: 'start',
          percent: Math.round((data.length / total) * 100),
        });
      }

      setCSVData([...data]);
      setDownloadData({
        ...downloadData,
        percent: 100,
        status: 'success',
      });
    } catch (error: any) {
      setDownloadData({
        ...downloadData,
        status: 'exception',
      });
      notification.error({ message: error.message });
    }
  };

  return (
    <Layout className="posRel">
      <Content>
        <div
          className="tblContainer viewportContainer"
          onClick={() => setToggleColumnsStatus(!toggleColumnsStatus)}
        >
          <Row>
            <div className="common_wrapper tab2FilterWidget_wrapper">
              <div className="filters_card ad_filters_card filtersHealthScreen">
                <Select
                  placeholder="Select Basestation"
                  style={{ width: 300 }}
                  onSelect={handleTractor}
                  value={selectedBaseId}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option!.children as unknown as string)
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA!.children as unknown as string)
                      .toLowerCase()
                      .localeCompare(
                        (optionB!.children as unknown as string).toLowerCase(),
                      )
                  }
                >
                  {basestationsData.map((data: any) => {
                    if (data?.id) {
                      return (
                        <option key={data?.id} value={data?.id}>
                          {`${data?.name || ' - '} - ${data?.serial_number} (${
                            data?.customer_organization?.name
                          })`}
                        </option>
                      );
                    }
                    return <></>;
                  })}
                </Select>
                <Button
                  className="ant-btn-primary"
                  onClick={() => setAddDataModel(true)}
                >
                  <UnorderedListOutlined />{' '}
                  {t(translate.logViewer.selectDataModel)}
                </Button>
                <DatePicker
                  format="YYYY-MM-DD"
                  allowClear={false}
                  defaultValue={moment(fromDate)}
                  onChange={(date: any) => fromDateChange(date)}
                  disabledDate={(d) => !d || d.isAfter(new Date())}
                />
                <CustomSelect
                  label={t(translate.MyJumpAndGo.duration)}
                  cssClass="min_width"
                  testId="durationBaseStation-BasestationLogViewer"
                  value={selectedDurationHour}
                  setValue={(id: any) => {
                    handleDurationHour(id);
                  }}
                  options={hourInfo1}
                  optionKey="value"
                  optionDisplay="text"
                  ketValue="Hr"
                />

                <CustomSelect
                  label={t(translate.reportsDistribution.frequency)}
                  cssClass="min_width"
                  testId="frequencyBaseStation-BasestationLogViewer"
                  value={selectFrequency}
                  setValue={(id: any) => {
                    handleFrequency(id);
                  }}
                  options={frequency}
                  optionKey="value"
                  optionDisplay="name"
                />
                <Button
                  className="ant-btn-primary"
                  disabled={saveDisabled}
                  onClick={() => generate()}
                >
                  {t(translate.buttons.generate)}
                </Button>
                <TableExt
                  handleRefresh={handleRefresh}
                  handleDownload={handleDownload}
                  downloadData={downloadData}
                  csvHeaders={headers}
                  csvData={csvData}
                  csvFilename="Basestations"
                  tableName="BaseLogViewer"
                  sortColumns={['created_date_time']}
                  tableRef={tableRef}
                  columnsData={columnsData}
                  handleCallback={(args: any[]) => {
                    setColumns(args);
                  }}
                  toggleColumnsStatus={toggleColumnsStatus}
                />
              </div>
              {/* <div className="filters_card">
              <p>
                {t(translate.baseStation.tractor)} :{' '}
                {selectedTractor
                  ? `${selectedTractor?.clientNodeName || ' - '} - ${
                      selectedTractor?.serial_number
                    } (${selectedTractor?.organization?.name})`
                  : ''}
              </p>
            </div> */}

              <div className="common_table fullHeight">
                <InfiniteScrollTable
                  columns={columns}
                  loading={loader}
                  filterData={filterData}
                  totalcount={total}
                  filename="Basestations"
                  handleLoadMore={handleLoadMore}
                  hasMore={hasMore}
                />
              </div>
            </div>
          </Row>
        </div>

        {addFavModal && (
          <AddBasestationLogFavModal
            callback={(state: boolean) => {
              setAddFavModal(false);
            }}
            selectedModels={dataIndexs}
            selectedModel={selectedModel}
          />
        )}
        {addDataModel && (
          <BasestationLogDataModel
            showModal={addDataModel}
            selModel={selectedModel}
            selModelId={selectedModelId}
            handleCancel={(selModel, selModels) =>
              closeDataModel(selModel, selModels)
            }
          />
        )}
        {showConvert && (
          <BasestationLogViewerConvertStatus
            showModal={showConvert}
            closeModal={closeConvert}
            transacationId={transacationId}
          />
        )}
      </Content>
    </Layout>
  );
};

export default BasestationLogViewer;
