/* eslint-disable no-console */
import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Button,
  Input,
  Row,
  Space,
  Tooltip,
  message,
  notification,
} from 'antd';
import Layout, { Content } from 'antd/lib/layout/layout';
import translate from '../../../locale/en_translate.json';
import InfiniteScrollTable from '../../common/InfiniteScrollTable';
import usePaginate from '../../../hooks/usePaginate';
import { ApplicationContext } from '../../../context/AppContext';
import { initScroller } from '../../../constants/Common';
import {
  getMarkTractorHealthSummary,
  getMarkTractorVersions,
  getMarkTractorsData,
} from '../api';
import { useTranslation } from 'react-i18next';
import { mapMarkTractorHealthData } from '../Common';
import './style.css';
import CustomMultiSelect from '../../common/CustomMultiSelect';
import totlaTractors from '../../../assets/images/tractors.svg';
import CustomSelect from '../../common/CustomSelect';
import copyIcon from '../../../assets/images/copy_icon.png';
import goldBuild from '../../../assets/images/goldbuild.svg';
import nonGoldBuild from '../../../assets/images/non-goldbuild.svg';
import refreshIcon from '../../../assets/images/refreshNew.svg';
import { CSVLink } from 'react-csv';
import Download from './Download';
import CropOrgFilter from '../../common/CropOrgFilter';

const DOWNLOAD_SIZE = 100;
let columns: any;

const TractorHealth = () => {
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const searchString = useRef<string>('');
  const [selectedOrgId, setSelectedOrgId] = useState<string[]>([]);
  const [search, setSearch] = useState<string>('');
  const [total, setTotal] = useState<number>(0);
  const [pageSize] = useState<any>(25);
  const [loading, setLoading] = useState(false);
  const [summary, setSummary] = useState<any>('');
  const [connection, setConnection] = useState<any>('');
  const { filterData, setData, activity, hasMore, checkActivity } =
    usePaginate();
  const [firmwareVersions, setFirmwareVersions] = useState<any>([]);
  const [selectedFirmware, setSelectedFirmware] = useState<any>([]);
  const [sofwareVersions, setSoftwareVersions] = useState<any>([]);
  const [selectedSoftware, setSelectedSoftware] = useState<any>([]);
  const [selectedCard, setSelectedCard] = useState<string>('gold build');
  const [buildType, setBuildType] = useState<any>('gold build');
  const [sortedInfo, setSortedInfo] = useState<any>();
  const init = useRef<boolean>(true);
  const buildData = [
    { name: 'Gold Build', value: 'gold build' },
    { name: 'Non-Gold Build', value: 'non gold build' },
  ];
  const [csvData, setCSVData] = useState<any[]>([]);
  const [headers, setHeaders] = useState<any[]>([]);
  const [downloadData, setDownloadData] = useState({
    isDownloaded: false,
    percent: 0,
    status: '',
  });
  const [fileDownloadStatus, setFileDownloadStatus] = useState<boolean>(false);

  const wrapResult = async (
    orgUrl: any,
    orgData: any,
    software: any,
    firmware: any,
    goldBuild: string,
    pageNumber: number,
    pageSize: number,
    connection: any,
    search: string,
    sortInfo: any,
  ) => {
    const result = await getMarkTractorsData(
      orgUrl,
      orgData,
      software,
      firmware,
      goldBuild,
      pageNumber,
      pageSize,
      connection,
      search,
      sortInfo,
    );
    return {
      result,
      searchKey: search,
    };
  };

  const getTractorsList = async (orgData: any, page_number: any) => {
    if (!userDetails?.organization || selectedOrgId.length === 0) return;
    try {
      if (checkActivity()) initScroller();
      setLoading(true);
      const { result, searchKey } = await wrapResult(
        userDetails?.organization.api_url,
        orgData,
        selectedSoftware,
        selectedFirmware,
        selectedCard,
        page_number,
        pageSize,
        connection,
        search,
        sortedInfo,
      );

      if (searchString.current !== searchKey) return;

      // eslint-disable-next-line no-underscore-dangle
      setTotal(result?._metadata?.total_records_count);

      const { records } = result;
      const data = Array.isArray(records) ? records : [];
      const updatedData = mapMarkTractorHealthData(data);

      setData(updatedData);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const loadTractors = () => {
    if (userDetails && !userDetails.organization) return;
    if (checkActivity()) {
      // eslint-disable-next-line no-unused-expressions
      pageNumber !== 1
        ? setPageNumber(1)
        : getTractorsList([...selectedOrgId], pageNumber);
    } else if (activity.current === 'paginate' || activity.current === '') {
      getTractorsList([...selectedOrgId], pageNumber);
    }
  };

  const handleOrg = (ids: any) => {
    activity.current = 'filter';
    setSelectedOrgId(ids);
  };

  const handleLoadMore = () => {
    if (pageNumber === 1 && checkActivity() && document) initScroller();

    activity.current = 'paginate';
    setPageNumber(pageNumber + 1);
  };

  // const handleSearch = (e: any) => {
  //   activity.current = 'search';
  //   const value = e.target.value.trim();
  //   if (value.length <= 1) {
  //     searchString.current = '';
  //     setSearch('');
  //   } else {
  //     searchString.current = value;
  //     setSearch(value);
  //   }
  // };

  const handleRefresh = () => {
    activity.current = 'refresh';
    loadTractors();
  };

  useEffect(() => {
    if (
      userDetails &&
      userDetails.organization &&
      selectedOrgId.length > 0 &&
      init.current
    ) {
      loadTractors();
      init.current = false;
    }
  }, [userDetails, selectedOrgId]);

  useEffect(() => {
    if (selectedOrgId.length === 0) {
      setData([]);
      setTotal(0);
    } else {
      loadTractors();
    }
  }, [selectedCard, sortedInfo, pageNumber]);

  const getTractorVersions = async (category: string) => {
    try {
      const { organization } = userDetails;
      const response = await getMarkTractorVersions(
        organization?.api_url,
        category,
      );
      return response;
    } catch (error: any) {
      console.log('error', error);
    }
  };

  const getVersions = async () => {
    const softwareVersions = await getTractorVersions('software');
    const firmwareVersions = await getTractorVersions('firmware');
    const softwareList = softwareVersions
      ?.sort((a: any, b: any) => b.is_gold_build - a.is_gold_build)
      ?.map((data: any, i: any) => ({
        title: (
          <span className="ellipsis swVersion">{data.software_version}</span>
        ),
        value: data.software_version,
        isGoldBuild: data?.is_gold_build ? 'gold' : '',
      }));
    setSoftwareVersions(softwareList);
    const firmwareList = firmwareVersions
      ?.sort((a: any, b: any) => b.is_gold_build - a.is_gold_build)
      ?.map((data: any, i: any) => ({
        title: (
          <span className="ellipsis swVersion">{data.software_version}</span>
        ),
        value: data.software_version,
        isGoldBuild: data?.is_gold_build ? 'gold' : '',
      }));
    setFirmwareVersions(firmwareList);
  };

  const getHealthSummary = async () => {
    try {
      const { organization } = userDetails;
      const tractorSummary = await getMarkTractorHealthSummary(
        organization.api_url,
        selectedOrgId,
      );
      setSummary(tractorSummary);
    } catch (error: any) {
      notification.error({ message: error.message });
    }
  };

  useEffect(() => {
    if (userDetails && userDetails.organization && selectedOrgId.length > 0) {
      getHealthSummary();
      getVersions();
    } else {
      setSummary('');
    }
  }, [selectedOrgId, userDetails]);

  useEffect(() => {
    columns = [
      {
        title: t(translate.healthData.tpin),
        dataIndex: 'tractor_pin',
        key: 'tractor_pin',
        width: '170px',
        sortDirections: ['descend', 'ascend', 'descend'],
        sorter: true,
        render: (tractor_pin: string) => (
          <span className="emailAlign hoverWrapper">
            <span className="tractorPinStyle">{tractor_pin}</span>
            {tractor_pin !== '-' && (
              <img
                src={copyIcon}
                alt="Edit"
                height="32"
                width="32"
                className="marginLeftIcon hoverShow1 on-hover-hand-change"
                onClick={() => {
                  navigator.clipboard.writeText(tractor_pin);
                  notification.info({ message: 'Copied' });
                }}
              />
            )}
          </span>
        ),
      },
      {
        title: t(translate.healthData.name),
        dataIndex: 'tractor_name',
        key: 'tractor_name',
        width: '130px',
        sortDirections: ['descend', 'ascend', 'descend'],
        sorter: true,
      },
      {
        title: `${t(translate.healthData.customerName)}`,
        dataIndex: 'organization_name',
        key: 'organization_name',
        width: '175px',
        sortDirections: ['descend', 'ascend', 'descend'],
        sorter: true,
        ellipsis: true,
      },
      {
        title: `${t(translate.healthData.customerType)}`,
        dataIndex: 'org_type',
        key: 'org_type',
        width: '120px',
        ellipsis: true,
        sortDirections: ['descend', 'ascend', 'descend'],
        sorter: (a: any, b: any) => a.org_type.localeCompare(b.org_type),
      },
      {
        title: `${t(translate.healthData.isGoldenBuild)}`,
        dataIndex: 'goldBuild',
        key: 'goldBuild',
        width: '100px',
        ellipsis: true,
        sortDirections: ['descend', 'ascend', 'descend'],
        sorter: (a: any, b: any) =>
          a.goldBuild === b.goldBuild ? 0 : a.goldBuild ? -1 : 1,
        render: (goldBuild: boolean) => <span>{goldBuild ? 'Yes' : 'No'}</span>,
      },
      {
        title: `${t(translate.healthData.permittedMismatch)}`,
        dataIndex: 'permitted_mismatch',
        key: 'permitted_mismatch',
        sortDirections: ['descend', 'ascend', 'descend'],
        sorter: (a: any, b: any) =>
          a.goldBuild === b.goldBuild ? 0 : a.goldBuild ? -1 : 1,
        width: '150px',
        ellipsis: true,
      },
      {
        title: `${t(translate.healthData.software)}`,
        dataIndex: 'tractor_software_version',
        key: 'tractor_software_version',
        sortDirections: ['descend', 'ascend', 'descend'],
        sorter: true,
        width: '120px',
        render: (tractor_software_version: string) => (
          <>{tractor_software_version || '-'} </>
        ),
      },
      {
        title: `${t(translate.healthData.firmware)}`,
        dataIndex: 'tractor_firmware_version',
        key: 'tractor_firmware_version',
        sortDirections: ['descend', 'ascend', 'descend'],
        sorter: true,
        width: '175px',
        render: (tractor_firmware_version: string) => (
          <>{tractor_firmware_version || '-'} </>
        ),
      },
      // {
      //   title: `${t(translate.healthData.lastOnlineTime)}`,
      //   dataIndex: 'last_online_time',
      //   key: 'last_online_time',
      //   width: '180px',
      // },
    ];
    if (columns) {
      const header = columns.map((column: any) => ({
        label: column.title,
        key: column.dataIndex,
      }));
      setHeaders(header);
    }
  }, []);

  const delay = (ms = 1000) =>
    new Promise((resolve) => setTimeout(resolve, ms));

  useEffect(() => {
    const { status } = downloadData || {};
    const download = async () => {
      const doc = document;
      const element: any = doc.querySelector(`#FleetStatus`);
      if (element) {
        element?.click();
      }
      await delay();
      setFileDownloadStatus(false);
    };
    if (['success', 'exception'].includes(status)) download();
  }, [downloadData]);

  const handleBuild = (value: any) => {
    if (value === 'gold build') {
      setSelectedCard('gold build');
    } else if (value === 'non gold build') {
      setSelectedCard('non gold build');
    } else {
      setSelectedCard('tractors');
    }
    setBuildType(value);
  };

  const handleFirmware = (e: any) => {
    setSelectedFirmware(e);
  };

  const handleSoftware = (e: any) => {
    setSelectedSoftware(e);
  };

  const handleChange = (pagination: any, filters: any, sorter: any) => {
    if (sorter.column && sorter.column.sorter === true) {
      activity.current = 'filter';
      const { columnKey, order } = sorter;
      setSortedInfo({
        sort_column: columnKey,
        sort_order: order === 'ascend' ? 'asc' : 'desc',
      });
    }
  };

  const handleSubmit = () => {
    activity.current = 'filter';
    if (search !== '') {
      setPageNumber(1);
    }
    if (userDetails && selectedOrgId.length) {
      loadTractors();
      getHealthSummary();
    } else {
      message.warning('Please select an Crop');
      setData([]);
    }
  };

  const handleDownload = async () => {
    setFileDownloadStatus(true);
    try {
      if (downloadData.status === 'start') return;
      setDownloadData({
        ...downloadData,
        status: 'start',
        percent: 10,
      });
      let data: any[] = [];
      const pages = Math.ceil(total / DOWNLOAD_SIZE);
      for (const page of Array.from({ length: pages }, (_, i) => i + 1)) {
        const { result } = await wrapResult(
          userDetails?.organization.api_url,
          selectedOrgId,
          selectedSoftware,
          selectedFirmware,
          selectedCard,
          page,
          DOWNLOAD_SIZE,
          connection,
          search,
          sortedInfo,
        );
        const { records } = result;
        let tdata = Array.isArray(records) ? records : [];
        tdata = mapMarkTractorHealthData(tdata);
        data = [...data, ...tdata];
        setDownloadData({
          ...downloadData,
          status: 'start',
          percent: Math.round((data.length / total) * 100),
        });
      }

      setCSVData([...data]);
      setDownloadData({
        ...downloadData,
        percent: 100,
        status: 'success',
      });
    } catch (error: any) {
      setDownloadData({
        ...downloadData,
        status: 'exception',
      });
      notification.error({ message: error.message });
    }
  };

  return (
    <>
      <div className="widget_section fleet_status">
        <div
          className={
            selectedCard === 'tractors'
              ? 'gridItem tractorHlth gridbackground'
              : 'gridItem tractorHlth'
          }
          onClick={() => {
            setConnection('');
            setSelectedCard('tractors');
            setBuildType('');
          }}
        >
          <img src={totlaTractors} alt="total Tractors" />
          <div className="widget_content">
            <div className="assignTxt cardTitle">
              {t(translate.healthData.summary.tractors)}
            </div>
            <div className="assignNum">
              {summary?.total_no_of_tractors || 0}
            </div>
          </div>
        </div>
        <div
          className={
            selectedCard === 'gold build'
              ? 'gridItem tractorHlth gridbackground'
              : 'gridItem tractorHlth'
          }
          onClick={() => {
            setSelectedCard('gold build');
            setConnection('');
            setBuildType('gold build');
          }}
        >
          <img src={goldBuild} alt="gold build" />
          <div className="widget_content">
            <div className="assignTxt cardTitle">
              {t(translate.healthData.summary.goldBuild)}
            </div>
            <div className="assignNum">
              {' '}
              {summary?.total_no_of_gold_builds || 0}
            </div>
          </div>
        </div>
        <div
          className={
            selectedCard === 'non gold build'
              ? summary?.permitted !== null
                ? 'gridItem tractorHlth gridbackground mtRight'
                : 'gridItem tractorHlth gridbackground'
              : summary?.permitted !== null
              ? 'gridItem tractorHlth mtRight'
              : 'gridItem tractorHlth'
          }
          onClick={() => {
            setSelectedCard('non gold build');
            setConnection('');
            setBuildType('non gold build');
          }}
        >
          <img src={nonGoldBuild} alt="non gold build" />
          <div className="widget_content">
            <div className="assignTxt cardTitle">
              {t(translate.healthData.summary.nonGoldBuild)}
            </div>
            <div className="assignNum">
              {' '}
              {summary?.total_no_of_non_gold_builds || 0}
            </div>
          </div>
          {summary?.permitted !== null && (
            <div>
              <div className="forwardTxt">
                <span className="squareCon"></span>
                <span>{summary?.permitted || 0}</span>
                <h4>{t(translate.healthData.summary.permitted)}</h4>
              </div>
              <div className="forwardTxt">
                <span className="squareCon squareBg"></span>
                <span>{summary?.non_permitted || 0}</span>
                <h4>{t(translate.healthData.summary.nonPermitted)}</h4>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="fleet_status_container">
        <Row>
          <div className="common_wrapper">
            <div className="filters_card filter_inputs align_top">
              <div className="filtersHealthScreen">
                <CropOrgFilter handleOrgs={handleOrg} />
                <CustomMultiSelect
                  handleTreeSelect={handleSoftware}
                  selectedId={selectedSoftware}
                  list={sofwareVersions}
                  label="SW version"
                  tagRequired
                />
                <CustomMultiSelect
                  handleTreeSelect={handleFirmware}
                  selectedId={selectedFirmware}
                  list={firmwareVersions}
                  label="FW version"
                  tagRequired
                />

                <CustomSelect
                  label="Builds"
                  cssClass="min_width"
                  value={buildType}
                  setValue={(id: any) => {
                    activity.current = 'filter';
                    handleBuild(id);
                  }}
                  options={buildData}
                  optionKey="value"
                  optionDisplay="name"
                  isAll
                />
                <Button
                  className="submitBtn"
                  onClick={handleSubmit}
                  loading={loading}
                >
                  {t(translate.buttons.submit)}
                </Button>
              </div>
              <div className="FleetStatusFilterIcons">
                <CSVLink
                  id="FleetStatus"
                  style={{ display: 'none' }}
                  data={csvData}
                  filename={`FleetStatus.csv`}
                  headers={headers}
                >
                  <div className="filterIcon">
                    <div className="downloadIcon" />
                  </div>
                </CSVLink>
                <div className="filterIcon" onClick={handleDownload}>
                  <div className="downloadIcon" />
                </div>
                <img
                  src={refreshIcon}
                  onClick={handleRefresh}
                  height={32}
                  width={32}
                  style={{ marginLeft: '7px' }}
                />
              </div>
            </div>
            <div className="common_table">
              <InfiniteScrollTable
                columns={columns}
                loading={loading}
                hasMore={hasMore}
                filterData={filterData}
                totalcount={total}
                handleLoadMore={handleLoadMore}
                filename={total > 0 ? 'Tractors' : 'Tractor'}
                onChange={handleChange}
              />
            </div>
          </div>
        </Row>
      </div>

      {fileDownloadStatus && (
        <Download
          loading={fileDownloadStatus}
          setLoading={setFileDownloadStatus}
          percent={downloadData?.percent || 0}
          status={downloadData?.status}
        />
      )}
    </>
  );
};

export default TractorHealth;
