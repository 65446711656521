import React, { useContext, useEffect, useRef, useState } from 'react';
import Layout, { Content } from 'antd/lib/layout/layout';
import { Avatar, Badge, Input, notification, Row, Space, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  PlusOutlined,
  SearchOutlined,
  WarningOutlined,
} from '@ant-design/icons';

import { getEmployeesList } from '../../constants/Api';

import { ApplicationContext } from '../../context/AppContext';
import translate from '../../locale/en_translate.json';

import copyIcon from '../../assets/images/copy_icon.png';
import sortDown from '../../assets/images/sort_down.svg';
import sortUp from '../../assets/images/sort_up.svg';

import { track } from '../../util/logger';
import TeamsDashBoard from '../teamsAndPrivileges/TeamsDashBoard';
import AddEmployee from '../common/AddEmployee';
import ViewEmployee from '../common/ViewEmployee';
import EmployeeDetails from '../teamsAndPrivileges/EmployeeDetails';
import { TeamsContext } from '../../context/TeamContext';
import PermissionsDashBoard from '../teamsAndPrivileges/PermissionsDashBoard';
import { privilagesConstants } from '../../constants/Privilages';
import { activeFilterData } from '../../constants/AppData';
import { formatTeams, initScroller } from '../../constants/Common';
import CustomSelect from '../common/CustomSelect';
import InfiniteScrollTable from '../common/InfiniteScrollTable';
import usePaginate from '../../hooks/usePaginate';

import 'antd/dist/antd.css';
import './style.css';
import { mapEmployessData } from '../../lib/dataFormat';

const Employees: React.FC = () => {
  const { t } = useTranslation();
  const { userDetails, privilegeChecker } = useContext(ApplicationContext);
  const { teamReducer } = useContext(TeamsContext);
  const [teamsState] = teamReducer;
  const [pageSize] = useState<any>(25);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [search, setSearch] = useState<string>('');
  const [active, setActive] = useState<boolean>(true);
  const [toggleWidget, setToggleWidget] = useState(false);
  const [empId, setEmpId] = useState(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [editroleFlag, setEditRolesFlag] = useState<boolean>();
  const [enableEmployeeDetails, setEnableEmployeeDetails] =
    useState<boolean>(false);
  const [refresh, setRefresh] = useState(false);
  const [total, setTotal] = useState<number>();
  const [teamids, setTeamids] = useState<number>(0);
  const [sortedInfo, setSortedInfo] = useState<any>({
    columnKey: 'first_name',
    order: 'ascend',
  });
  const [sortedData, setSortedData] = useState<any>({
    first_name: 'ascend',
  });
  const [AddEmployeeFlag, setAddEmployeeFlag] = useState<boolean>(false);
  const [permissionFlag, setPermission] = useState<boolean>(false);

  const [initialize, setInitialize] = useState<boolean>(false);
  const searchString = useRef<string>('');
  const { filterData, setData, activity, hasMore, checkActivity } =
    usePaginate();

  useEffect(() => {
    return () => {
      setSearch('');
      setTeamids(0);
      setTotal(0);
    };
  }, []);

  const trackMsg = () => {
    track('Email copy', {
      event: 'Email copied',
    });
  };

  const columnValues: any = [
    {
      title: () => {
        return (
          <>
            {t(translate.headers.employee)}
            <span className="sort-pad">
              <img
                className="sort-pad"
                src={sortedData['first_name'] === 'ascend' ? sortUp : sortDown}
              />
            </span>
          </>
        );
      },
      dataIndex: 'first_name',
      key: 'first_name',
      width: '20%',
      defaultSortOrder: 'ascend',
      sortDirections: ['descend', 'ascend', 'descend', 'ascend'],
      showSorterTooltip: false,
      sorter: (a: { first_name: string }, b: { first_name: string }) =>
        a.first_name.localeCompare(b.first_name),
      sortOrder: sortedInfo.columnKey === 'first_name' && sortedInfo.order,
      ellipsis: true,
      render: (username: string, row: any) => {
        return (
          <>
            <span className="emailAlign empName">
              {row.appAcess && !row.username ? (
                <Tooltip
                  title="Need Email to grant some permissions."
                  placement="right"
                  zIndex={10000}
                >
                  <Badge
                    count={<WarningOutlined style={{ color: '#EC9322' }} />}
                  >
                    {row?.profile_url ? (
                      <Avatar
                        style={{
                          width: '34px',
                          height: '34px',
                          backgroundColor: '#67AFB7',
                          color: 'white',
                          fontFamily: 'Montserrat-Bold',
                          border: row.is_org_admin
                            ? '1px solid #EC9322'
                            : 'none',
                          transition: '0.4s all ease-in-out',
                        }}
                        src={row?.profile_url}
                        className={row.is_org_admin && 'employee-avatar'}
                      />
                    ) : (
                      <Avatar
                        style={{
                          width: '34px',
                          height: '34px',
                          backgroundColor: '#67AFB7',
                          color: 'white',
                          fontFamily: 'Montserrat-Bold',
                          border: row.is_org_admin
                            ? '1px solid #EC9322'
                            : 'none',
                          transition: '0.4s all ease-in-out',
                        }}
                        className={row.is_org_admin && 'employee-avatar'}
                      >
                        {row?.first_name?.charAt(0)}
                        {row?.last_name?.charAt(0)}
                      </Avatar>
                    )}
                  </Badge>
                </Tooltip>
              ) : (
                <>
                  {row?.profile_url !== '' && row?.profile_url ? (
                    <Avatar
                      src={row?.profile_url}
                      className={row?.is_org_admin && 'profile-avatar'}
                    ></Avatar>
                  ) : (
                    <Avatar
                      style={{
                        width: '34px',
                        height: '34px',
                        backgroundColor: '#67AFB7',
                        color: 'white',
                        fontFamily: 'Montserrat-Bold',
                        border: row.is_org_admin ? '1px solid #EC9322' : 'none',
                        transition: '0.4s all ease-in-out',
                      }}
                      src={row?.profile_url}
                      className={row.is_org_admin && 'employee-avatar'}
                    >
                      {row?.first_name?.charAt(0)}
                      {row?.last_name?.charAt(0)}
                    </Avatar>
                  )}
                </>
              )}
              <Tooltip title={`${row.first_name} ${row.last_name}`}>
                <span
                  className="wwrapBreakAll"
                  data-testid={`${row.first_name} ${row.last_name}-Employees`}
                >
                  {row.first_name} {row.last_name}
                </span>
              </Tooltip>
            </span>
          </>
        );
      },
    },
    {
      title: `${t(translate.headers.email)}`,
      dataIndex: 'email',
      key: 'email',
      width: '25%',
      render: (email: string, record: any) => {
        return (
          <span
            className="hoverWrapper"
            data-testid={email || record?.username}
          >
            <span className="emailWdFix">{email || record?.username}</span>
            <img
              src={copyIcon}
              alt="Edit"
              height="32"
              width="32"
              className="marginLeftIcon hoverShow1 on-hover-hand-change"
              onClick={() => {
                navigator.clipboard.writeText(email || record?.username);
                notification.info({ message: 'Copied' });
                trackMsg();
              }}
            />
          </span>
        );
      },
    },
    {
      title: `${t(translate.employees.phoneNumber)}`,
      dataIndex: 'contact_number',
      key: 'contact_number',
      width: '20%',
      render: (contact_number: string, record: any) => {
        return (
          <span
            className=" hoverWrapper"
            data-testid={contact_number || record?.username}
          >
            <span>{contact_number || '-'}</span>
          </span>
        );
      },
    },
    {
      title: `${t(translate.employees.team)}`,
      dataIndex: 'teamName',
      key: 'teamName',
      width: '35%',
      render: (teamName: any, record: any) => {
        return (
          <Tooltip title={teamName}>
            <div className="description-text">
              {record?.teams.length > 3
                ? formatTeams(record.teams, true)
                : teamName}
            </div>
          </Tooltip>
        );
      },
    },
  ];

  const wrapResult = async (
    apiUrl: string,
    orgId: number,
    pageNumber: number,
    pageSize: number,
    search: string,
    active: boolean,
    sortedInfo: string,
  ) => {
    const result = await getEmployeesList(
      apiUrl,
      orgId,
      pageNumber,
      pageSize,
      search,
      active,
      sortedInfo,
    );
    return {
      result,
      searchKey: search,
    };
  };

  const getEmployees = async () => {
    try {
      if (checkActivity()) initScroller();
      setLoading(true);
      const { result, searchKey } = await wrapResult(
        userDetails.organization.api_url,
        userDetails.organization.id,
        pageNumber,
        pageSize,
        search,
        active,
        sortedInfo,
      );
      if (searchString.current !== searchKey) return;

      setTotal(result._metadata.total_records_count);
      const { records } = result;
      let data = Array.isArray(records) ? records : [];

      data = mapEmployessData(data);
      setData(data);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    } finally {
      setLoading(false);
      if (!initialize) setInitialize(true);
    }
  };

  const getRow = (record: any) => {
    setEmpId(record.id);
    setAddEmployeeFlag(false);
    setToggleWidget(true);
    track('Employee', {
      event: `${record.first_name} ${record.last_name} Clicked`,
    });
  };

  const handleLoadMore = () => {
    if (pageNumber === 1 && checkActivity() && document) initScroller();

    activity.current = 'paginate';
    setPageNumber(pageNumber + 1);
  };

  const handleChange = (pagination: any, filters: any, sorter: any) => {
    activity.current = 'sort';

    const { columnKey, order } = sorter;
    setSortedData({ ...sortedData, [columnKey]: order });
    setSortedInfo(sorter);
  };

  const handleSearch = (e: any) => {
    activity.current = 'search';
    const value = e.target.value.trim().regX;
    if (value.length <= 1) {
      searchString.current = '';
      setSearch('');
    } else {
      searchString.current = value;
      setSearch(value);
    }
  };

  const loadEmployees = () => {
    if (userDetails && !userDetails.organization) return;
    if (checkActivity()) {
      pageNumber !== 1 ? setPageNumber(1) : getEmployees();
    } else if (activity.current === 'paginate' || activity.current === '') {
      getEmployees();
    }
  };

  useEffect(() => {
    const { roles = [] } = userDetails;
    const [orgAdmin] = roles.map((ele: any) => ele?.role_key === 'ORG_ADMIN');
    if (orgAdmin) {
      const obj = {
        title: `${t(translate.employees.opsCode)}`,
        dataIndex: 'ops_code',
        key: 'ops_code',
      };
      columnValues.push(obj);
    }
  }, [userDetails]);

  useEffect(() => {
    if (teamids) setPermission(true);
  }, [teamids]);

  useEffect(() => {
    if (sortedInfo && sortedInfo.order && initialize) loadEmployees();
  }, [sortedInfo.order, active, teamsState.empRefresh]);

  useEffect(() => {
    loadEmployees();
  }, [userDetails, pageNumber, search]);

  useEffect(() => {
    if (refresh && pageNumber === 1) {
      loadEmployees();
      setRefresh(false);
    }
  }, [refresh]);

  const addEmployees = () => {
    setAddEmployeeFlag(true);
    track('Add Employee', {
      event: 'Add Employee Clicked',
    });
  };

  const Close = (state = false) => {
    setAddEmployeeFlag(false);
    if (state) {
      activity.current = 'refresh';
      loadEmployees();
    }
    track('Add Employee', {
      event: 'Add Employee Closed',
    });
  };
  const editRoles = () => {
    searchString.current = '';
    setSearch('');
    setEditRolesFlag(true);
  };

  const seeMore = () => {
    setToggleWidget(false);
    setEnableEmployeeDetails(true);
    setActive(true);
  };

  const closeDetails = () => {
    searchString.current = '';
    setSearch('');
    setEnableEmployeeDetails(false);
    setToggleWidget(false);
  };
  const closePermission = () => {
    setPermission(false);
    setTeamids(0);
  };

  return (
    <Layout className="posRel">
      {!editroleFlag && !enableEmployeeDetails && !permissionFlag && (
        <>
          <Content>
            <div className="tblContainer viewportContainer">
              <Row>
                <div className="common_wrapper wWidget_wrapper">
                  <div className="filters_card spaceBtnAlignCenter personnelCard">
                    <div className="displayFlex">
                      <Space size="large">
                        <Input
                          data-testid="searchDirectoryInputField-Employees"
                          addonBefore={<SearchOutlined />}
                          placeholder="Search Directory"
                          className="common_search"
                          onChange={(e) => handleSearch(e)}
                          autoComplete="off"
                        />
                      </Space>

                      <CustomSelect
                        testId="statusDropdownField-Employees"
                        label="Status"
                        cssClass="min_width"
                        value={active}
                        setValue={(id: any) => {
                          activity.current = 'filter';
                          setActive(id);
                        }}
                        options={activeFilterData}
                        optionKey="id"
                        optionDisplay="name"
                      ></CustomSelect>
                    </div>
                    <div className="personnelFlex">
                      <div
                        onClick={() => {
                          editRoles();
                          track('OpenVersionHistory', {
                            event: 'Version History Modal Open',
                          });
                        }}
                        className="viewTeamsBtn"
                        data-testid="viewTeamsButton-Employees"
                      >
                        {t(translate.headers.viewTeams)}
                      </div>
                      {privilegeChecker(privilagesConstants.Add_Employees) && (
                        <div
                          data-testid="addNewButton-Employees"
                          className="addNewBtn Button2"
                          onClick={addEmployees}
                        >
                          <PlusOutlined /> {t(translate.buttons.addNew)}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="common_table">
                    <InfiniteScrollTable
                      columns={columnValues}
                      loading={loading}
                      hasMore={hasMore}
                      filterData={filterData}
                      totalcount={total}
                      handleLoadMore={handleLoadMore}
                      filename="Employees"
                      onRowClick={(record) => getRow(record)}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </Row>
            </div>
          </Content>
        </>
      )}
      {AddEmployeeFlag && (
        <AddEmployee Close={Close} toggleWidget={AddEmployeeFlag} />
      )}
      {permissionFlag && (
        <PermissionsDashBoard
          setPermission={closePermission}
          teamId={teamids}
          setTeamId={setTeamids}
        />
      )}
      {toggleWidget && (
        <ViewEmployee
          empId={empId}
          seeMore={seeMore}
          onClose={(state = false) => {
            setToggleWidget(false);
            if (state) {
              activity.current = 'refresh';
              loadEmployees();
            }
          }}
        />
      )}
      {enableEmployeeDetails && (
        <EmployeeDetails
          empId={empId}
          closeEmpDetails={closeDetails}
          setTeamids={setTeamids}
        />
      )}
      {editroleFlag && <TeamsDashBoard setEditRolesFlag={setEditRolesFlag} />}
    </Layout>
  );
};

export default Employees;
