import React, { useEffect, useContext, useState } from 'react';
import {
  Avatar,
  Button,
  notification,
  Checkbox,
  Input,
  Modal,
  Select,
} from 'antd';
import translate from '../../locale/en_translate.json';
import { useTranslation } from 'react-i18next';
import { ApplicationContext } from '../../context/AppContext';
import logo from '../../assets/images/logo192.png';
import { TeamUser } from '../../constants/types';
import {
  getTeamById,
  getTeamUsers,
  duplicateAllTeam,
} from '../../constants/Api';
import './style.css';
import { useTeamsContext } from '../../context/TeamContext';
import { SET_TEAMS_REFRESH } from '../../context/actions';

interface Props {
  showModal: boolean;
  closeModel: (data?: boolean) => void;
  teamId: number;
  setTeamId: (id: number) => void;
}

const { TextArea } = Input;
export const DuplicateTeam: React.FC<Props> = ({
  showModal,
  closeModel,
  teamId,
  setTeamId,
}: Props) => {
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const { teamReducer } = useTeamsContext();
  const [, dispatch] = teamReducer;
  const [selectedItems, setSelectedItems] = useState<any>([]);
  const [results, setResult] = useState<any[]>([]);
  const [title, setTitle] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [team, setTeam] = useState<any>({});
  const [checked, setChecked] = useState<boolean>(false);
  const [saveDisabled, setSaveDisabled] = useState(true);

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      getTeam();
      getTeamEmployees();
    }
  }, [userDetails]);
  useEffect(() => {
    if (userDetails && userDetails.organization) {
      setSaveDisabled(title.trim().length < 1 || description.trim().length < 1);
    }
  }, [title, description]);

  const payload: any = {
    name: title,
    description: description,
    users: selectedItems,
    duplicate: true,
    source_team_id: team?.id,
    copy_all_users_to_new_team: checked,
  };

  const getTeam = async () => {
    try {
      const response = await getTeamById(
        userDetails.organization.api_url,
        userDetails.organization.id,
        teamId,
      );
      setTeam(response);
    } catch (error: any) {
      notification.error({ message: 'Unable to get team data' });
    }
  };
  const getTeamEmployees = async () => {
    const teamUsers: TeamUser[] = await getTeamUsers(
      userDetails.organization.api_url,
      userDetails.organization.id,
      teamId,
      false,
      '',
    );
    setResult(teamUsers);
  };

  const duplicateTeam = async () => {
    try {
      const data = await duplicateAllTeam(
        userDetails.organization.api_url,
        userDetails.organization.id,
        payload,
      );
      if (data?.id) {
        notification.success({
          message: t(translate.label.duplicateteamCreated),
          duration: 2,
        });
        setTeamId(data.id);
        dispatch({ type: SET_TEAMS_REFRESH });
        closeModel(true);
      } else {
        notification.error({ message: 'Duplication failed', duration: 2 });
        closeModel();
      }
    } catch (error: any) {
      notification.error({
        message: error.message || 'Could not create a Duplicate request',
      });
      closeModel();
    }
  };

  const onTitleChange = (event: any) => {
    const regex = /^[a-zA-Z0-9 @#$&*)(+_-]*$/;
    if (regex.test(event.target.value)) {
      setTitle(event.target.value);
    }
  };

  const onDescriptionChange = (event: any) =>
    setDescription(event.target.value);

  return (
    <>
      <Modal
        className="modalContainer teammodal teamEmpContainer"
        visible={showModal}
        onCancel={() => closeModel()}
        width={590}
        closable={false}
        maskClosable={false}
        centered
        footer={[
          <>
            <Button
              data-testid="duplicateEmpCancelButton-DuplicateTeam"
              key="back"
              className="btnteamCancel captalize Button"
              onClick={() => closeModel()}
            >
              {t(translate.buttons.cancel)}
            </Button>
            <Button
              data-testid="duplicateEmpSaveButton-DuplicateTeam"
              disabled={saveDisabled}
              key="submit"
              onClick={duplicateTeam}
              className="duplicateSaveButton btnteamSave Button"
            >
              {t(translate.buttons.save)}
            </Button>
          </>,
        ]}
      >
        <div className="createteamContainer">
          <div className="createtitle">
            <div className="pull-left">
              <h1 className="title headline1">
                {t(translate.duplicateTeam.duplicate)} {team.name}?
              </h1>
            </div>
            <div className="img-div pull-right">
              <img src={logo} />
            </div>
          </div>
          <p className="teamdescription body1">
            {`Use teams to manage employee's access and permissions to the Monarch
            Platform.`}
          </p>
          <div className="inputTeam">
            <Input
              data-testid="duplicateTeamNameInputField-DuplicateTeam"
              placeholder="Enter Team Name"
              value={title}
              maxLength={50}
              className="team-input input-text"
              onChange={onTitleChange}
            />
            <div className="titleCount">{title.length} / 50</div>
          </div>
          <TextArea
            data-testid="duplicateTeamDescInputField-DuplicateTeam"
            style={{ width: '100%' }}
            placeholder="Create a description to further identify the specifics of the team"
            showCount
            maxLength={110}
            className="team-text-area-2 input-text body1"
            value={description}
            onChange={onDescriptionChange}
          />
          <Select
            data-testid="addEmployeeDropdown-DuplicateTeam"
            mode="multiple"
            placeholder="Add Employees"
            style={{ width: '100%' }}
            onChange={(e) => setSelectedItems(e)}
            className="dropdownStyle team-select"
            showSearch={true}
            filterOption={(input, option) => {
              return (
                option?.title?.toLowerCase().indexOf(input.toLowerCase()) >= 0
              );
            }}
            maxTagCount="responsive"
          >
            {results.map((ele: any) => {
              return (
                <Select.Option
                  title={`${ele.first_name} ${ele.last_name}`}
                  value={ele?.id}
                  key={ele?.id}
                >
                  <span
                    data-testid={`${ele.first_name} ${ele.last_name}-DuplicateTeam`}
                    className="emailAlign empName"
                  >
                    <Avatar
                      style={{
                        width: '34px',
                        height: '34px',
                        marginRight: '25px',
                        backgroundColor: '#67AFB7',
                        color: 'white',
                        fontFamily: 'Montserrat-Bold',
                      }}
                      src={ele?.profile_url}
                    >
                      {ele?.first_name?.charAt(0)}
                      {ele?.last_name?.charAt(0)}
                    </Avatar>
                    <span className="optionname">
                      {ele.first_name} {ele.last_name}
                    </span>
                  </span>
                </Select.Option>
              );
            })}
          </Select>
          <Checkbox
            data-testid="migrateEmpCheckBox-DuplicateTeam"
            value={checked}
            onChange={() => setChecked(!checked)}
            className="team-checkbox"
          >
            {t(translate.duplicateTeam.migrateEmployees)}
          </Checkbox>
        </div>
      </Modal>
    </>
  );
};

export default DuplicateTeam;
