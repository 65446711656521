import React from 'react';
import Layout, { Content } from 'antd/lib/layout/layout';
import schVerified from '../../assets/images/schVerified.svg';
import InfiniteScroll from 'react-infinite-scroll-component';
import AdminLoader from '../admin/AdminLoader';

interface Props {
  loader: boolean;
  filterData: any;
  handleLoadMore: () => void;
  hasMore: any;
}

export const SchUpdatesList: React.FC<Props> = ({
  loader,
  filterData,
  handleLoadMore,
  hasMore,
}) => {
  return (
    <Layout className="yourFleetTbl">
      <div className="mainContent">
        <Content>
          <div className="tblContainer viewportContainer">
            <div className="common_wrapper upWidget_wrapper pad1220">
              <div className="schUpDate mb12">Up to Date</div>

              <InfiniteScroll
                scrollThreshold={0.95}
                height={window.innerHeight - 240}
                dataLength={filterData.length} //This is important field to render the next data
                next={() => handleLoadMore()}
                hasMore={hasMore}
                loader={
                  <></>
                  // <div style={{ width: '100%', textAlign: 'center' }}>
                  //   <p>Loading...</p>
                  // </div>
                }
              >
                <div className="schUpdatesListContainer">
                  {filterData &&
                    filterData?.map((data: any) => (
                      <div className="schListItems" key={data?.id}>
                        <div className="tractorContainer mb8">
                          <div className="outOfTxt">{data?.name}</div>
                          <img src={schVerified} alt="" />
                        </div>
                        <div className="swUpdateTxt">
                          <span>Software</span>
                          <span className="ml5">{data?.softwareVersion}</span>
                        </div>
                        <div className="swUpdateTxt">
                          <span>Firmware</span>
                          <span className="ml5">{data?.firmWareVersion}</span>
                        </div>
                      </div>
                    ))}
                </div>
              </InfiniteScroll>
            </div>
          </div>
        </Content>
      </div>
      <AdminLoader loader={loader} />
    </Layout>
  );
};
