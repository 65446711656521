import { Tabs } from 'antd';
import React, { useState } from 'react';
import FleetIssues from './FleetIssues';

const { TabPane } = Tabs;

const Maintainence: React.FC = () => {
  const [tabid, setTabid] = useState('1');

  return (
    <Tabs
      activeKey={tabid}
      centered
      id="fleet-tab-FleetHealthContainer"
      className="maintenanceTabs"
      onChange={(key) => {
        setTabid(key);
        // track(key, {
        //   event: `${key} Tab`,
        // });
      }}
    >
      <TabPane
        tab="Support Tickets"
        key="1"
        className="attTableHeight mBottom0"
      >
        <FleetIssues />
      </TabPane>
    </Tabs>
  );
};

export default Maintainence;
