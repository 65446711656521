/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-unused-vars */
import {
  notification,
  Spin,
  Select,
  Form,
  Upload,
  Button,
  Radio,
  Space,
} from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserManualAppContext } from './UserManualContext';
import {
  EXECUTE,
  SET_SELECTED_KEYS,
  SET_TOPIC_EDIT,
} from './UserManualReducer';
import translate from '../../../../locale/en_translate.json';
import './style.css';
import CInput from '../../../common/CInput';
import TextArea from '../../../common/TextArea';
import { editTopic, UploadToS3, UploadToS3Status } from '../../API';
import { FileUplaodS3Payload } from '../../common/types';
import ErrorCodes from '../../common/ErrorCodes';
import { randomNumber } from '../../../../constants/Common';

interface Props {
  refresh: () => void;
}
const EditTopicWidget: React.FC<Props> = ({ refresh }) => {
  const { userManualReducer } = useContext(UserManualAppContext);
  const [state, dispatch] = userManualReducer;
  const [formData, setFormData] = useState<any>({});
  const [contentType, setContentType] = useState<string>('content');
  const [loader, setLoader] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [reload, setReload] = useState<boolean>(false);
  const { t } = useTranslation();
  const [fileList, setFileList] = useState<any>([]);
  const [description, setDescription] = useState<string>('');
  const [toggleErrorCodes, setToggleErrorCodes] = useState<boolean>(false);
  const { selectedTopic, selectedChapter, selectedKeys } = state;
  const { id: topicId } = selectedTopic;

  const { id: chapterId } = selectedChapter;
  const [errors, setErrors] = useState({
    title: true,
  });

  useEffect(() => {
    setIsDisabled(!Object.values(errors).every((item) => !item));
  }, [errors]);

  useEffect(
    () => () => {
      dispatch({ type: SET_SELECTED_KEYS, payload: [] });
    },
    [],
  );

  useEffect(() => {
    const {
      topic_number,
      title,
      description: desc,
      key_words,
      indicator_error_codes,
      parent_topic_id,
    } = selectedTopic;
    setFormData({
      // ...formData,
      title,
      // description: desc,
      key_words: key_words || [],
      // indicator_error_codes: indicator_error_codes || [],
      topic_number,
      parent_topic_id,
    });
    setDescription(desc);
    dispatch({ type: SET_SELECTED_KEYS, payload: indicator_error_codes || [] });
    setReload(!reload);
  }, [selectedTopic]);

  useEffect(() => {
    if (formData?.title && formData?.title.trim().length > 0) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [formData]);

  const editTopicMethod = async () => {
    try {
      setLoader(true);
      const data = new FormData();

      if (fileList.length > 0) {
        // data.append('file', fileList[0].originFileObj);
        data.append('file_name', fileList[0].name);
        data.append('content_type', 'pdf');
      }
      data.append('description', description?.trim());

      Object.keys(formData).forEach((key) => {
        if (typeof formData[key] === 'string' && formData[key].trim().length)
          data.append(key, formData[key]);
        if (Array.isArray(formData[key]) && formData[key].length)
          data.append(key, formData[key]);
        if (typeof formData[key] === 'number') data.append(key, formData[key]);
      });

      data.append('indicator_error_codes', state.selectedKeys);

      const response: any = await editTopic(chapterId, data, topicId);

      const { id, chapter_id, upload_file_response } = response;

      if (upload_file_response) {
        const {
          fields,
          url: s3ApiUrl,
        }: { url: string; fields: FileUplaodS3Payload | any } =
          upload_file_response;

        if (fields) {
          const s3FormData = Object.keys(fields).reduce((a, key) => {
            a.append(key, fields[key]);
            return a;
          }, new FormData());
          if (fileList.length > 0) {
            s3FormData.append('file', fileList[0].originFileObj);
          }
          try {
            const res = await UploadToS3(s3ApiUrl, s3FormData);
            if (res?.ok) {
              const { status } = res;
              if (status === 204) {
                const ups3StausPayload = {
                  file_name: fields?.key,
                };
                const { msg } = await UploadToS3Status(
                  chapter_id,
                  id,
                  ups3StausPayload,
                );
                notification.success({
                  message: msg || 'Topic Updated Successfully',
                });
                dispatch({ type: SET_TOPIC_EDIT, payload: false });
                refresh();
              }
            }
          } catch (error: any) {
            notification.error({ message: error.message });
          }
        }
      } else if (response && response.id) {
        const { msg } = response;
        notification.success({
          message: msg || 'Topic Updated Successfully',
        });
        refresh();
      }
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    } finally {
      setLoader(false);
    }
  };

  const onChange = (info: any) => {
    // eslint-disable-next-line no-param-reassign
    info.file.status = 'done';
    // setFileList(info.fileList);
    setFileList([info.fileList[info.fileList.length - 1]]);
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const CInput1 = (name: string, title: string) => (
    <Form.Item label={title} className="mb16 w25">
      <CInput
        max={60}
        min={3}
        value={formData[name]}
        name="title"
        onChange={(value: string) => {
          setFormData({ ...formData, title: value });
        }}
        hasError={(state) => setErrors({ ...errors, title: state })}
      />
    </Form.Item>
  );

  const handleTags = (fields: string, value: any) => {
    setFormData({
      ...formData,
      [fields]: value,
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const CTags = (name: string, title: string, value: any[]) => (
    <Form.Item label={title} className="mb16 w25">
      <Select
        mode="tags"
        style={{ width: '100%' }}
        placeholder={title}
        onChange={(tags) => handleTags(name, tags)}
        value={value}
      />
    </Form.Item>
  );

  const handleSubmit = () => {
    editTopicMethod();
  };

  const handleOnChange = (e: any) => {
    setContentType(e.target.value);
  };

  return (
    <>
      {CInput1('title', 'Title')}
      <Form.Item>
        <Radio.Group onChange={handleOnChange} value={contentType}>
          <Radio value="file"> {t(translate.myTask.fileUpload)} </Radio>
          <Radio value="content"> {t(translate.howToManual.content)} </Radio>
        </Radio.Group>
      </Form.Item>
      {contentType === 'content' ? (
        <TextArea
          description={description}
          setDescription={setDescription}
          readOnly={false}
          height={400}
          hideToolbar={false}
        />
      ) : (
        <Form.Item>
          <Upload
            listType="text"
            accept=".pdf"
            onChange={onChange}
            key={randomNumber()}
          >
            <Button className="primary">
              {t(translate.buttons.UploadAFile)}
            </Button>
          </Upload>
          {fileList[0] && <div>{fileList[0]?.name}</div>}
        </Form.Item>
      )}
      {CTags('key_words', 'Keywords', formData.key_words)}
      <Form.Item label={t(translate.label.errorCodes)} className="mb16 w25">
        <Button type="primary" onClick={() => setToggleErrorCodes(true)}>
          {t(translate.buttons.addErrorCodes)}
        </Button>
      </Form.Item>
      <Form.Item className="mb16 w25">
        <Select
          mode="tags"
          style={{ width: '100%' }}
          // placeholder={t(translate.label.errorCodes)}
          disabled
          value={state.selectedKeys}
        />
      </Form.Item>
      <Form.Item>
        <Space>
          <Button
            htmlType="button"
            onClick={() =>
              dispatch({
                type: EXECUTE,
                payload: {
                  topicEdit: false,
                  topicCreate: false,
                },
              })
            }
          >
            {t(translate.buttons.cancel)}
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            disabled={isDisabled}
            onClick={handleSubmit}
          >
            {t(translate.buttons.save)}
          </Button>
        </Space>
      </Form.Item>
      <div className="edit-loader">
        {loader && (
          <div className="loader">
            <Spin size="large" />
          </div>
        )}
      </div>
      {toggleErrorCodes && (
        <ErrorCodes onClose={() => setToggleErrorCodes(false)} />
      )}
    </>
  );
};

export default EditTopicWidget;
