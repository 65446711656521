import React, { useContext, useEffect, useState } from 'react';
import { Card, notification, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import translate from '../../locale/en_translate.json';
import { Actions, JagDetails } from '../../constants/types';
import { getDateTime, secondsToHms } from '../../constants/Common';
import { getJgDetails } from '../../constants/Api';
import loading_logo from '../../assets/images/loading_logo.gif';
import { ApplicationContext } from '../../context/AppContext';
import AppLoader from '../common/AppLoader';

interface Props {
  actiondata: Actions;
}
const JngTabDetails: React.FC<Props> = ({ actiondata }: Props) => {
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const [detailsList, setDetailsList] = useState<JagDetails>();
  const [loader, setLoader] = useState<boolean>(false);

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      jgDetails();
    }
  }, [userDetails, actiondata]);

  const jgDetails = async () => {
    try {
      setLoader(true);
      const data = await getJgDetails(
        userDetails.organization.api_url,
        actiondata.drive_action_uuid,
      );
      setDetailsList(data);
      setLoader(false);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    }
  };

  return (
    <div className="dbWidget detailsTab">
      <Card className="jumpangoDetails">
        <div style={{ display: 'flex' }}>
          <div className="col">
            <div className="dbRow aiCenter pad8">
              <div className="leftLbl overline2">
                {t(translate.Jumpandgo.operator)}
              </div>
              <div className="rightLbl overline2">
                {actiondata.operator?.first_name}{' '}
                {actiondata.operator?.last_name}{' '}
              </div>
            </div>
            <div className="dbRow aiCenter pad8">
              <div className="leftLbl overline2">
                {t(translate.taskmaster.starttime)}
              </div>
              <div className="rightLbl overline2">
                {actiondata.drive_action_start_date_time
                  ? getDateTime(actiondata.drive_action_start_date_time)
                  : actiondata.start_time
                  ? getDateTime(actiondata.start_time)
                  : '-'}
              </div>
            </div>

            <div className="dbRow aiCenter pad8">
              <div className="leftLbl overline2">
                {t(translate.jumpandgoactions.jngStartSoc)}
              </div>
              <div className="rightLbl overline2">
                {detailsList?.jng_start_soc
                  ? `${
                      detailsList?.jng_start_soc - detailsList?.jng_finish_soc
                    }%`
                  : '-'}
              </div>
            </div>
            <div className="dbRow aiCenter pad8">
              <div className="leftLbl overline2">
                {t(translate.jumpandgoactions.distance)}
              </div>
              <div className="rightLbl overline2">
                {detailsList?.distance
                  ? `${Number(detailsList?.distance)
                      .toFixed(2)
                      .toLocaleString()} Miles`
                  : '-'}
              </div>
            </div>
            <div className="dbRow aiCenter pad8">
              <div className="leftLbl overline2">
                {t(translate.jumpandgoactions.manualHours)}
              </div>
              <div className="rightLbl overline2">
                {detailsList?.manual_hours
                  ? `${secondsToHms(detailsList?.manual_hours)}`
                  : '-'}
              </div>
            </div>
            <div className="dbRow aiCenter pad8">
              <div className="leftLbl overline2">
                {t(translate.jumpandgoactions.energy)}
              </div>
              <div className="rightLbl overline2">
                {detailsList?.energy
                  ? `${Number(detailsList?.energy)
                      .toFixed(2)
                      .toLocaleString()} kwh`
                  : '-'}
              </div>
            </div>
            <div className="dbRow aiCenter pad8">
              <div className="leftLbl overline2">
                {t(translate.jumpandgoactions.operationalSavings)}
              </div>
              <div className="rightLbl overline2">
                {detailsList?.operational_savings
                  ? `$${Number(detailsList?.operational_savings).toFixed(2)}`
                  : '-'}
              </div>
            </div>
            <div className="dbRow aiCenter pad8">
              <div className="leftLbl overline2">
                {t(translate.jumpandgoactions.dieselCost)}
              </div>
              <div className="rightLbl overline2">
                {detailsList?.diesel_cost
                  ? `$${Number(detailsList?.diesel_cost).toFixed(2)}`
                  : '-'}
              </div>
            </div>
          </div>
          <div className="col">
            <div className="dbRow aiCenter pad8">
              <div className="leftLbl overline2">
                {t(translate.baseStation.tractorName)}
              </div>
              <div className="rightLbl overline2">
                {actiondata.tractor.name}
              </div>
            </div>
            <div className="dbRow aiCenter pad8">
              <div className="leftLbl overline2">
                {t(translate.Jumpandgo.finishTime)}
              </div>
              <div className="rightLbl overline2">
                {actiondata.drive_action_finish_date_time
                  ? getDateTime(actiondata.drive_action_finish_date_time)
                  : actiondata.finish_time
                  ? getDateTime(actiondata.finish_time)
                  : '-'}
              </div>
            </div>
            <div className="dbRow aiCenter pad8">
              <div className="leftLbl overline2">
                {t(translate.jumpandgoactions.avgSpeed)}
              </div>
              <div className="rightLbl overline2">
                {detailsList?.avg_speed
                  ? `${Number(Number(detailsList.avg_speed) * 0.621371).toFixed(
                      1,
                    )} Mph`
                  : '-'}
              </div>
            </div>
            <div className="dbRow aiCenter pad8">
              <div className="leftLbl overline2">
                {t(translate.jumpandgoactions.idleHours)}
              </div>
              <div className="rightLbl overline2">
                {detailsList?.idle_hours
                  ? `${secondsToHms(detailsList?.idle_hours)}`
                  : '-'}
              </div>
            </div>
            <div className="dbRow aiCenter pad8">
              <div className="leftLbl overline2">
                {t(translate.jumpandgoactions.autonomyHours)}
              </div>
              <div className="rightLbl overline2">
                {detailsList?.autonomy_hours
                  ? `${secondsToHms(detailsList?.autonomy_hours)}`
                  : '-'}
              </div>
            </div>
            <div className="dbRow aiCenter pad8">
              <div className="leftLbl overline2">
                {t(translate.jumpandgoactions.costOfElectricityUsed)}
              </div>
              <div className="rightLbl overline2">
                {detailsList?.cost_of_electricity_used
                  ? `$${Number(detailsList?.cost_of_electricity_used).toFixed(
                      2,
                    )}`
                  : '-'}
              </div>
            </div>
            <div className="dbRow aiCenter pad8">
              <div className="leftLbl overline2">
                {t(translate.jumpandgoactions.aggregateEmissionReduction)}
              </div>
              <div className="rightLbl overline2">
                {detailsList?.aggregate_emission_reduction
                  ? `${Number(
                      detailsList?.aggregate_emission_reduction,
                    ).toFixed(2)} kg`
                  : '-'}
              </div>
            </div>
            <div className="dbRow aiCenter pad8">
              <div className="leftLbl overline2">
                {t(translate.jumpandgoactions.electricityRate)}
              </div>
              <div className="rightLbl overline2">
                {detailsList?.electricity_rate
                  ? `$${Number(detailsList?.electricity_rate).toFixed(2)}`
                  : '-'}
              </div>
            </div>
          </div>
        </div>
        <AppLoader loader={loader} />
      </Card>
    </div>
  );
};

export default JngTabDetails;
