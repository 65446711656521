import { Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import FileUploadView from '../FileUploadView/fileuploadview';
import History from '../history/History';

const { TabPane } = Tabs;

const FileUploadTabs: React.FC = () => {
  const [tabId, setTabId] = useState<any>('1');

  return (
    <Tabs
      centered
      activeKey={tabId}
      className="common_tabs tabSpace support_tabs"
      onChange={(key) => setTabId(key)}
    >
      <TabPane tab="Settings" key="1" className="attTableHeight mBottom0">
        <FileUploadView />
      </TabPane>
      <TabPane tab="History" key="2" className="attTableHeight mBottom0">
        <History />
      </TabPane>
    </Tabs>
  );
};

export default FileUploadTabs;
