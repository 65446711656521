/* eslint-disable react/jsx-key */
import React from 'react';
import battery_charger from '../../assets/images/battery_charger_new.svg';
import motor_rpm from '../../assets/images/motor_rpm_new.svg';
// import Update_WiFi from '../../assets/images/Update_WiFi.svg';
import tractor_outline from '../../assets/images/tractor_outline_new.svg';
import { getSummary } from '../../constants/types';
import Interweave from 'interweave';
import { Tabs } from 'antd';

interface Props {
  summaryList: getSummary;
  setSeeAll: (data: boolean) => void;
  text?: string;
}

const { TabPane } = Tabs;

export const CommonRightSchedule: React.FC<Props> = ({
  summaryList,
  setSeeAll,
  text,
}) => {
  return (
    <div className="rightSchedule">
      <div className="firstSch">
        <div className="otaHead mb10">How to Update</div>
        <div className="updateTractorTxt">
          To update your tractors successfully, please make sure that the
          following requirements are met prior to your installation.{' '}
        </div>
        <div className="commonGrid">
          <div className="gridItems">
            <div className="gridTopTxt">1</div>
            <img src={battery_charger} alt="charger" />
            <div className="updateTractorTxt mt12">Battery above 20%</div>
            <div className="gridTxt">
              Plug in any tractors with low battery for a successful update.
            </div>
          </div>
          <div className="gridItems">
            {' '}
            <div className="gridTopTxt">2</div>
            <img src={motor_rpm} alt="motor" />
            <div className="updateTractorTxt mt12">Turn Motor Off</div>
            <div className="gridTxt">
              Turn only the motor off and leave the Smart Screen on.
            </div>
          </div>
          <div className="gridItems">
            {' '}
            <div className="gridTopTxt">3</div>
            <img src={tractor_outline} alt="tractor_outline" />
            <div className="updateTractorTxt mt12">Computer Online</div>
            <div className="gridTxt">
              All roof computers must be online and functional.
            </div>
          </div>
        </div>
        {text && (
          <div className="updateTractorTxt mt15">
            {' '}
            Once the update has completed, power the tractor down, switch the
            12V off, and wait 30s before restarting.
          </div>
        )}
      </div>
      <div className="secondSch">
        <div className="firstGrid mb15">
          <div className="otaHead">Release Notes</div>
          <div
            className="releaseNotesTxt pointer seeAll"
            onClick={() => setSeeAll(true)}
          >
            See All
          </div>
        </div>
        <div className="rNotesSec">
          {summaryList?.latest_software &&
          summaryList?.latest_software?.length > 1 ? (
            <Tabs centered type="card" className="commonReleaseTabs">
              {summaryList?.latest_software?.map((data: any) => (
                <TabPane tab={data?.model} key={data?.software_version_id}>
                  <div className="releaseNotesTxt">
                    <Interweave content={data?.release_notes} />
                  </div>
                </TabPane>
              ))}
            </Tabs>
          ) : (
            <>
              {summaryList?.latest_software?.map((data: any) => (
                <div className="releaseNotesTxt">
                  <Interweave content={data?.release_notes} />
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
