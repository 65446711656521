import { MinusOutlined } from '@ant-design/icons';
import { Checkbox } from 'antd';
import React from 'react';

import { track } from '../../util/logger';

import datalayerIcon from '../../assets/images/mpLayersIcon_new.svg';
// Path
import mpl_autodrive from '../../assets/images/layer_icons/layer-autodrive-box.svg';
import mpl_manual from '../../assets/images/layer_icons/layer-manual-drive.svg';
import mpl_planned_autodrive from '../../assets/images/layer_icons/layer-planned-autodrive.svg';
// Fleet
import t_autodrive_icon from '../../assets/images/layer_icons/layer-autodrive.svg';
import t_available_icon from '../../assets/images/layer_icons/layer-avail.svg';
import t_charging_ion from '../../assets/images/layer_icons/layer-charging.svg';
import t_error_icon from '../../assets/images/layer_icons/layer-error.svg';
import t_manual_icon from '../../assets/images/layer_icons/layer-manual.svg';
import t_off_icon from '../../assets/images/layer_icons/layer-offline.svg';
// Alerts
import t_critical_icon from '../../assets/images/layer_icons/layer-critical-health.svg';
import t_guradrail_icon from '../../assets/images/layer_icons/layer-guardrails.svg';
import t_low_loc_icon from '../../assets/images/layer_icons/layer-low-local.svg';
// Farm
// import mpl_basestation from '../../assets/images/layer_icons/layer-basestation.svg';
import ground_zero from '../../assets/images/ground_zero.svg';

import mpl_blue from '../../assets/images/layer_icons/layer-blue.svg';
import mpl_boundary from '../../assets/images/layer_icons/layer-boundary.svg';
import mpl_field from '../../assets/images/layer_icons/layer-green.svg';
import mpl_waypoints from '../../assets/images/layer_icons/layer-waypoint.svg';
import mpl_geofence from '../../assets/images/layer_icons/white geofence.svg';

interface Props {
  mapLayersToggle: boolean;
  setMapLayersToggle: (mapLayersToggle: boolean) => void;
  showBasestations: boolean;
  setShowBasestations: (showBasestations: boolean) => void;
  farm: boolean;
  setFarm: (farm: boolean) => void;
  showWaypoints: boolean;
  setShowWaypoints: (showWaypoints: boolean) => void;
  showPath: boolean;
  setShowPath: (showPath: boolean) => void;
  showDataLayers: boolean;
  setShowDataLayers: (showDataLayers: boolean) => void;
  showWhitePolygons: boolean;
  setShowWhitePolygons: (showWhitePolygons: boolean) => void;
  showGrayPolygons: boolean;
  selectFarmItems: (items: boolean) => void;
  showGreenPolygons: boolean;
  setShowGreenPolygons: (showGreenPolygons: boolean) => void;
  showBluePolygons: boolean;
  setShowBluePolygons: (showBluePolygons: boolean) => void;
  showBrownPolygons: boolean;
  setShowBrownPolygons: (showBrownPolygons: boolean) => void;
}
const MapLayers: React.FC<Props> = ({
  mapLayersToggle,
  setMapLayersToggle,
  showBasestations,
  setShowBasestations,
  farm,
  setFarm,
  showWaypoints,
  setShowWaypoints,
  showPath,
  setShowPath,
  showDataLayers,
  setShowDataLayers,
  showWhitePolygons,
  setShowWhitePolygons,
  showGrayPolygons,
  selectFarmItems,
  showGreenPolygons,
  setShowGreenPolygons,
  showBluePolygons,
  setShowBluePolygons,
  showBrownPolygons,
  setShowBrownPolygons,
}) => {
  return (
    <div
      className={
        mapLayersToggle ? 'bottomMapLayers mpOpen' : 'bottomMapLayers mpClose'
      }
    >
      <div className="dataLayerWrapper">
        <div className="dataLayerRowHead mb28">
          <div
            className="mpCloseIcon"
            data-testid="mapLayerClose-MapLayers"
            onClick={() => {
              setMapLayersToggle(!mapLayersToggle);
            }}
            style={{ cursor: 'pointer' }}
          ></div>
          <div className="mpLayerHead">
            <span className="dataIcon">
              <img src={datalayerIcon} alt="dataLayer" />
            </span>
            <span className="dataHead" data-testid="mapLayersHeader-MapLayers">
              MAP LAYERS
            </span>
          </div>
        </div>
        <div className="dataLayerSec">
          <div className="dataLayerContainer mb7">
            <div className="dataLayerRow mplChkBox">
              <div className="checkBoxItem">
                <span
                  className="mapLayerNameHead"
                  data-testid="layerLegend-MapLayers"
                >
                  FLEET
                </span>
              </div>
            </div>
            <div className="dataLayerInnerRow mplChkBox">
              <div className="checkBoxItem ml-8 dblock pdr0">
                <div className="mplStatus">
                  <div className="mplItem">
                    <img src={t_available_icon} alt="" />
                    <span
                      className="mplItemlbl"
                      data-testid="available-MapLayer"
                    >
                      Available
                    </span>
                  </div>
                  <div className="mplItem">
                    <img src={t_off_icon} alt="" />
                    <span className="mplItemlbl" data-testid="inUse-MapLayer">
                      Off
                    </span>
                  </div>
                </div>
                <div className="mplStatus2">
                  <div className="mplItem">
                    <img src={t_charging_ion} alt="" />
                    <span
                      className="mplItemlbl"
                      data-testid="charging-MapLayer"
                    >
                      Charging
                    </span>
                  </div>
                </div>
                <div className="mplStatus2">
                  <div className="mplItem">
                    <img src={t_manual_icon} alt="" />
                    <span className="mplItemlbl">Manual Drive</span>
                  </div>
                </div>
                <div className="mplStatus2">
                  <div className="mplItem">
                    <img src={t_autodrive_icon} alt="" />
                    <span className="mplItemlbl">Autodrive</span>
                  </div>
                </div>
                <div className="mplStatus2">
                  <div className="mplItem">
                    <img src={t_error_icon} alt="" />
                    <span className="mplItemlbl">
                      Operating + Level 3 Error Code
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="dataLayerContainer mb7">
            <div className="dataLayerRow mplChkBox">
              <div className="checkBoxItem">
                <span
                  className="mapLayerNameHead"
                  data-testid="farmLayerHeader-MapLayer"
                >
                  Farm
                </span>
                <span
                  className="lChkBox"
                  data-testid="farmOverallCheckBox-MapLayers"
                >
                  <Checkbox
                    defaultChecked={farm}
                    checked={farm}
                    onChange={(e) => {
                      setFarm(e.target.checked);
                      selectFarmItems(e.target.checked);
                    }}
                  ></Checkbox>
                  {(showWaypoints ||
                    showGrayPolygons ||
                    showGreenPolygons ||
                    showWhitePolygons ||
                    showBasestations) &&
                    !farm && <MinusOutlined className="fleet-minus" />}
                </span>
              </div>
            </div>
            <div className="dataLayerInnerRow mplChkBox">
              <div className="checkBoxItem ml50">
                <span className="iconW">
                  <img src={mpl_boundary} alt="tractor" />
                </span>
                <span className="layerName">Boundary (Brown)</span>
                <span
                  className="lChkBox"
                  data-testid="boundryBrownCheckBox-MapLayers"
                >
                  <Checkbox
                    defaultChecked={showBrownPolygons}
                    checked={showBrownPolygons}
                    onChange={(e) => {
                      setShowBrownPolygons(e.target.checked);
                    }}
                  ></Checkbox>
                </span>
              </div>
            </div>
            <div className="dataLayerInnerRow mplChkBox">
              <div className="checkBoxItem ml50">
                <span className="iconW">
                  <img src={mpl_geofence} alt="tractor" />
                </span>
                <span className="layerName">Geofence (White)</span>
                <span
                  className="lChkBox"
                  data-testid="geofenceWhiteCheckBox-MapLayers"
                >
                  <Checkbox
                    defaultChecked={showWhitePolygons}
                    checked={showWhitePolygons}
                    onChange={(e) => {
                      setShowWhitePolygons(e.target.checked);
                    }}
                  ></Checkbox>
                </span>
              </div>
            </div>
            <div className="dataLayerInnerRow mplChkBox">
              <div className="checkBoxItem ml50">
                <span className="iconW">
                  <img src={mpl_field} alt="tractor" />
                </span>
                <span className="layerName">Fields (Green)</span>
                <span
                  className="lChkBox"
                  data-testid="fieldGreenCheckBox-MapLayers"
                >
                  <Checkbox
                    defaultChecked={showGreenPolygons}
                    checked={showGreenPolygons}
                    onChange={(e) => {
                      setShowGreenPolygons(e.target.checked);
                    }}
                  ></Checkbox>
                </span>
              </div>
            </div>
            <div className="dataLayerInnerRow mplChkBox">
              <div className="checkBoxItem ml50">
                <span className="iconW">
                  <img src={mpl_blue} alt="tractor" />
                </span>
                <span className="layerName">Stay-Off Zones (Blue)</span>
                <span
                  className="lChkBox"
                  data-testid="stayOffCheckBox-MapLayers"
                >
                  <Checkbox
                    defaultChecked={showBluePolygons}
                    checked={showBluePolygons}
                    onChange={(e) => {
                      setShowBluePolygons(e.target.checked);
                    }}
                  ></Checkbox>
                </span>
              </div>
            </div>
            <div className="dataLayerInnerRow mplChkBox">
              <div className="checkBoxItem ml50">
                <span className="iconW">
                  <img
                    src={mpl_waypoints}
                    alt="tractor"
                    width="13"
                    height="18"
                  />
                </span>
                <span className="layerName">Waypoints</span>
                <span
                  className="lChkBox"
                  data-testid="wayPointCheckBox-MapLayers"
                >
                  <Checkbox
                    defaultChecked={showWaypoints}
                    checked={showWaypoints}
                    onChange={(e) => {
                      setShowWaypoints(e.target.checked);
                    }}
                  ></Checkbox>
                </span>
              </div>
            </div>
            <div className="dataLayerInnerRow mplChkBox">
              <div className="checkBoxItem ml50">
                <span className="iconW mplBsIcon">
                  <img
                    src={ground_zero}
                    width="13"
                    height="18"
                    alt="tractor"
                    style={{ marginTop: 8 }}
                  />
                </span>
                <span className="layerName ">Ground Zero</span>
                <span
                  className="lChkBox"
                  data-testid="basestationCheckBox-MapLayers"
                >
                  <Checkbox
                    defaultChecked={showBasestations}
                    checked={showBasestations}
                    onChange={(e) => {
                      track('Live Map', {
                        event: `Data Layers Base Stations Checked ${e.target.checked}`,
                      });
                      setShowBasestations(e.target.checked);
                    }}
                  ></Checkbox>
                </span>
              </div>
            </div>
          </div>
          <div className="dataLayerContainer mb7">
            <div className="dataLayerRow mplChkBox">
              <div className="checkBoxItem">
                <span
                  className="mapLayerNameHead"
                  data-testid="pathLayerHeader-MapLayer"
                >
                  Paths
                </span>
                <span className="lChkBox" data-testid="pathCheckBox-MapLayers">
                  <Checkbox
                    defaultChecked={showPath}
                    checked={showPath}
                    onChange={(e) => {
                      setShowPath(e.target.checked);
                    }}
                  ></Checkbox>
                </span>
              </div>
            </div>
            <div className="dataLayerInnerRow mplChkBox">
              <div className="checkBoxItem ml50">
                <span className="iconW">
                  <img
                    src={mpl_autodrive}
                    alt="tractor"
                    width="16"
                    height="16"
                  />
                </span>
                <span className="layerName" data-testid="autoDrive-MapLayers">
                  Autodrive
                </span>
              </div>
            </div>
            <div className="dataLayerInnerRow mplChkBox">
              <div className="checkBoxItem ml50">
                <span className="iconW">
                  <img
                    src={mpl_planned_autodrive}
                    alt="tractor"
                    width="16"
                    height="16"
                  />
                </span>
                <span
                  className="layerName"
                  data-testid="plannedAutoDrive-MapLayer"
                >
                  Planned Autodrive
                </span>
                <span></span>
              </div>
            </div>
            <div className="dataLayerInnerRow mplChkBox">
              <div className="checkBoxItem ml50">
                <span className="iconW">
                  <img src={mpl_manual} alt="tractor" width="16" height="16" />
                </span>
                <span className="layerName" data-testid="manualLayer-MapLayer">
                  Manual Drive
                </span>
              </div>
            </div>
          </div>
          <div className="dataLayerContainer mb7">
            <div className="dataLayerRow mplChkBox">
              <div className="checkBoxItem">
                <span
                  className="mapLayerNameHead"
                  data-testid="alertsLayerHeader-MapLayers"
                >
                  Alerts
                </span>
                <span
                  className="lChkBox"
                  data-testid="alertsCheckBox-MapLayers"
                >
                  <Checkbox
                    defaultChecked={showDataLayers}
                    checked={showDataLayers}
                    onChange={(e) => {
                      setShowDataLayers(e.target.checked);
                    }}
                  />
                </span>
              </div>
            </div>
            <div className="dataLayerInnerRow mplChkBox">
              <div className="checkBoxItem ml50">
                <span className="iconW">
                  <img src={t_guradrail_icon} alt="tractor" />
                </span>
                <span
                  className="layerName"
                  data-testid="triggeredGuardrails-MapLayers"
                >
                  Triggered Guardrails
                </span>
              </div>
            </div>
            <div className="dataLayerInnerRow mplChkBox">
              <div className="checkBoxItem ml50">
                <span className="iconW">
                  <img src={t_critical_icon} alt="tractor" />
                </span>
                <span
                  className="layerName"
                  data-testid="tractorErrors-MapLayers"
                >
                  Critical Health Issues
                </span>
              </div>
            </div>
            <div className="dataLayerInnerRow mplChkBox">
              <div className="checkBoxItem ml50">
                <span className="iconW">
                  <img src={t_low_loc_icon} alt="tractor" />
                </span>
                <div
                  className="layerName t-low-loc"
                  data-testid="tractorErrors-MapLayers"
                >
                  <p className="m0">Low Localization</p>
                  <p className="t-unknow-location m0">
                    Exact tractor location is Unknown on map
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MapLayers;
