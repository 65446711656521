import {
  Input,
  Checkbox,
  Progress,
  Button,
  notification,
  Spin,
  Modal,
  Collapse,
} from 'antd';

import Layout from 'antd/lib/layout/layout';
import React, { useContext, useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import { ApplicationContext } from '../../context/AppContext';
import { useTranslation } from 'react-i18next';
import applicationIds from '../../locale/applicationIds.json';
import Interweave from 'interweave';
import closeGreen from '../../assets/images/closeGreen.svg';
import {
  Updatefleetmaintenancerule,
  getfleetmaintenancerulelist,
} from '../../constants/Api';
import { getDateTimes } from '../../constants/Common';
import { Document, Page } from 'react-pdf';
import translate from '../../locale/en_translate.json';
const { Panel } = Collapse;

interface Props {
  Close: () => void;
  toggleWidget: boolean;
  mainRecord: any;
}
const MaintenanceView: React.FC<Props> = ({
  Close,
  toggleWidget,
  mainRecord,
}: Props) => {
  const { t } = useTranslation();
  const { TextArea } = Input;
  const { userDetails } = useContext(ApplicationContext);
  const [loader, setLoader] = useState<boolean>(false);
  const [maintenancerule, setmaintenancerule] = useState<any>();
  const [index, setIndex] = useState<any>();
  const [indexper, setindexper] = useState<number>();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [fleetmainobj, setfleetmainobj] = useState<any>();
  const [numPages, setNumPages] = useState(0);
  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  useEffect(() => {
    if (userDetails && userDetails.organization && mainRecord)
      getfleetmaintenancerule();
  }, [userDetails, mainRecord]);

  const getfleetmaintenancerule = async () => {
    try {
      setLoader(true);
      const data = await getfleetmaintenancerulelist(
        userDetails.organization.api_url,
        userDetails.organization.id,
        mainRecord?.id,
      );
      if (data) {
        let j = 0;
        data?.fleet_maintenance_rule_actions.map((ele: any, i: any) => {
          if (ele.is_completed) {
            j = j + 1;
          }
        });
        setmaintenancerule(data);
        setIndex(j);
      }
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (index || index === 0) {
      const length = maintenancerule?.fleet_maintenance_rule_actions.length;
      const data = (100 * index) / length;
      setindexper(Math.round(data));
    }
  }, [index]);

  const actionFlag = (e: any, record: any) => {
    let i = 0;
    maintenancerule?.fleet_maintenance_rule_actions.map((ele: any) => {
      if (record?.id === ele.id) {
        ele.is_completed = e.target.checked;
      }
      if (ele.is_completed) {
        i = i + 1;
      }
    });
    setIndex(i);
    setmaintenancerule({ ...maintenancerule });
  };

  const commentsMethod = (e: any, record: any) => {
    maintenancerule?.fleet_maintenance_rule_actions.map((ele: any, i: any) => {
      if (record?.id === ele.id) {
        ele.comments = e.target.value;
      }
    });
    setmaintenancerule({ ...maintenancerule });
  };

  const saveMaintenanceMethod = async () => {
    const fleetmaintenance: any = [];
    maintenancerule?.fleet_maintenance_rule_actions.map((ele: any) => {
      const obj = {
        id: ele.id,
        comments: ele.comments,
        is_completed: ele.is_completed,
      };
      fleetmaintenance.push(obj);
    });

    const payload = {
      status: indexper === 100 ? 'Completed' : maintenancerule?.status,
      fleet_maintenance_rule_actions_list: fleetmaintenance,
    };
    try {
      setLoader(true);
      const data = await Updatefleetmaintenancerule(
        userDetails.organization.api_url,
        userDetails.organization.id,
        mainRecord?.id,
        payload,
      );
      notification.success({
        message: data.msg,
      });
      Close();
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    } finally {
      setLoader(false);
    }
  };

  const learcnMore = (data: any) => {
    setPreviewOpen(true);
    setfleetmainobj(data);
  };

  const handleCancel = () => {
    setPreviewOpen(false);
  };

  const genExtra = (i: any, ele: any) => (
    <>
      <span
        className="body2 learnBtn"
        onClick={(e: any) => {
          e.stopPropagation();
          learcnMore(ele);
        }}
      >
        Learn More
      </span>
      <Checkbox
        checked={ele?.is_completed}
        onClick={(e: any) => {
          actionFlag(e, ele);
          e.stopPropagation();
        }}
      />
    </>
  );

  return (
    <Layout>
      <div className={'rightSideWidegt ' + (toggleWidget ? 'expand' : 'hide')}>
        <div className="widgetCloseBtn flexBtn">
          <img
            id={applicationIds.ticketScreen.editClose}
            src={closeGreen}
            alt="close icon"
            data-testid="modelCloseIcon-TicketViewEdit"
            onClick={Close}
          />
        </div>
        <div className="profileView rightWidgetView maintenanceWidget">
          <div className="proRow tcktInfo">
            <h4 className="posRel errorTcktTitle maintenanceTitle">
              <span style={{ fontFamily: 'Montserrat-SemiBold' }}>
                {maintenancerule?.tractor?.name}
              </span>
            </h4>
            <h3 className="headline2_B mb0">
              {getDateTimes(maintenancerule?.open_date_time)}
            </h3>
          </div>

          <div className="proRowType rowGapBtm3 mb32">
            <h5 className="fSbd headline3">{maintenancerule?.name}</h5>
          </div>

          <div className="proRowType rowGapBtm3 mb10">
            <h5 className="fSbd headline3">
              {t(translate.maintainenceView.description)}
            </h5>
            <div className="txtPaddLftGap w100 body2 mb0">
              <Interweave content={maintenancerule?.description} />
            </div>
          </div>

          <div className="proRowType rowGapBtm3">
            {maintenancerule?.fleet_maintenance_rule_actions &&
              maintenancerule?.fleet_maintenance_rule_actions.length > 0 && (
                <h5 className="fSbd headline3">
                  {t(translate.maintainenceView.performActions)}
                </h5>
              )}
            <div className="maintenanceCard">
              <Collapse>
                {maintenancerule?.fleet_maintenance_rule_actions &&
                  maintenancerule?.fleet_maintenance_rule_actions.length > 0 &&
                  maintenancerule?.fleet_maintenance_rule_actions?.map(
                    (ele: any, i: any) => (
                      <Panel
                        header={ele.action}
                        key={i}
                        extra={genExtra(i, ele)}
                      >
                        <div className="w100 mb0">
                          <h5 className="addCommentTxt body2 fSbd">
                            {t(translate.maintainenceView.addComments)}
                          </h5>
                          <h6 className="createTcktBtn mt10 ticketComments">
                            <div>
                              <>
                                <div className="tcktCommentBox">
                                  <TextArea
                                    data-testid="CommentInputField-TicketViewEdit"
                                    style={{ width: '100%' }}
                                    placeholder="Enter Comments here"
                                    className="tckComt"
                                    maxLength={500}
                                    value={ele.comments}
                                    onChange={(e: any) => {
                                      commentsMethod(e, ele);
                                    }}
                                    disabled={
                                      maintenancerule?.status === 'Completed'
                                    }
                                  />
                                </div>
                              </>
                            </div>
                          </h6>
                        </div>
                      </Panel>
                    ),
                  )}
              </Collapse>
            </div>
          </div>
          {maintenancerule?.fleet_maintenance_rule_actions &&
            maintenancerule?.fleet_maintenance_rule_actions.length > 0 && (
              <div className="maintenanceProgressBar">
                <Progress percent={indexper} />
              </div>
            )}
        </div>
        <div className="edit-loader">
          {loader && (
            <div className="loader">
              <Spin size="large" />
            </div>
          )}
        </div>
        <div className="maintenanceBtns">
          <Button
            data-testid="editTeamCancelButton-EditTeam"
            className="btnteamCancel captalize Button"
            onClick={Close}
          >
            {t(translate.buttons.cancel)}
          </Button>
          {maintenancerule?.status !== 'Completed' && (
            <Button
              data-testid="editTeamSaveButton-EditTeam"
              key="submit"
              className="btnteamSave captalize Button"
              onClick={saveMaintenanceMethod}
            >
              {t(translate.buttons.save)}
            </Button>
          )}
        </div>
      </div>
      <Modal
        visible={previewOpen}
        title={'  '}
        footer={null}
        maskClosable={false}
        onCancel={handleCancel}
        className="previewModal closemaintenance"
      >
        <div className="previewmodalBody maintenanceModal">
          {fleetmainobj?.media_type === 'image' && (
            <img
              alt=""
              className="img-responsive"
              src={fleetmainobj?.kb_file_url}
            />
          )}
          {fleetmainobj?.media_type === 'video' && (
            <video width="100%" height="100%" id="video" controls>
              <source src={fleetmainobj?.kb_file_url} id="source" />
            </video>
          )}
          {fleetmainobj?.media_type === 'pdf' && (
            <Document
              file={fleetmainobj?.kb_file_url}
              onLoadSuccess={onDocumentLoadSuccess}
              className="pdf-document"
            >
              {Array.from(new Array(numPages), (el, index) => (
                <Page
                  key={`page_${index + 1}`}
                  className="pdf-page"
                  pageNumber={index + 1}
                  scale={1.5}
                />
              ))}
            </Document>
          )}
        </div>
      </Modal>
    </Layout>
  );
};

export default MaintenanceView;
