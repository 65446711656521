export const mqttConstants = {
  PTOSTATE: 3,
  FNR: 5,
  SEATBELTSTATE: 21,
  GEAR: 43,
  RPM: 40,
  LEFTHITCH: 45,
  RIGHTHITCH: 47,
  ENERGY: 74,
  PSIFLOW: 92,
  VELOCITY: 6,
  ROLL: 4,
  PITCH: 5,
  TRACTORLOCATIONLAT: 1,
  TRACTORLOCATIONLONG: 0,
  BEARING: 5,
  IICSTATE: 0,
  CHARGINGSTATUS: 102,
  BREAK: 52,
  SEATOCCUPIED: 20,
  TURNSIGNAL: 55,
  HOOKUPCONTROL: 103,
  WORKFRONTLIGHT: 57,
  WORKREARLIGHT: 58,
  WORKLEFTLIGHT: 59,
  WORKRIGHTLIGHT: 60,
  LOWLOCALIZATION: 12,
  TIREANGLE: 41,
  HEADLIGHT: 54,
  FRONTWORKINGLIGHT: 57,
  REARWORKINGLIGHT: 58,
  LEFTSIDEWORKINGLIGHT: 59,
  RIGHTSIDEWORKINGLIGHT: 60,
  POWER100V: 108,
  POWER240V: 109,
  TARGETGEAR: 110,
  NOSPEED: 15,
  CHARGINGTIME: 111,
  SCREENON: 116,
  ESSTOP: 9,
  POWEROFF: 2,

  XAVIER1_POWER_STATUS: 10, // front left, fronT right
  XAVIER2_POWER_STATUS: 17, // pto
  XAVIER3_POWER_STATUS: 24, // front
  XAVIER4_POWER_STATUS: 31, // rear
  XAVIER6_POWER_STATUS: 167, // rear and pto

  XAVIER1_SYSTEM_NODE: 150, // front left, fron right
  XAVIER2_SYSTEM_NODE: 151, // pto
  XAVIER3_SYSTEM_NODE: 152, // front
  XAVIER6_SYSTEM_NODE: 155, // rear and pto
  INTERNET_SPEED: 174,
  N2_POWER_STATUS: 5, // system model
  ACTIVE_NETWORk: 209, // system model

  XAVIER4_SYSTEM_NODE: 153,
  XAVIER5_SYSTEM_NODE: 154,
  XAVIER5_POWER_STATUS: 160,
  active_network: 209,
  eth: 210,
  wifi_ssid_name: 211,
  router_model: 212,
  router_firmware_version: 213,
  nuc1_power_status: 0,
  nuc1_cpu_usage: 1,
  nuc1_cpu_temp: 2,
  nuc1_disk_usage: 3,
  nuc1_cpu_ram: 4,
  nuc2_power_status: 5,
  nuc2_cpu_usage: 6,
  nuc2_cpu_temp: 7,
  nuc2_disk_usage: 8,
  nuc2_cpu_ram: 9,

  FRONT_CAMERA_STATUS: 201,
  REAR_CAMERA_STATUS: 202,
  RIGHT_CAMERA_STATUS: 203,
  LEFT_CAMERA_STATUS: 204,
  REAR_RIGHT_CAMERA_STATUS: 205,
  REAR_LEFT_CAMERA_STATUS: 206,
  PTO_CAMERA_STATUS: 207,
  DRIVER_CAMERA_STATUS: 221,
};
