export const EXECUTE = 'EXECUTE';
export const SELECTED_TRACTOR = 'SELECTED_TRACTOR';
export const SELECTED_TRACTORS = 'SELECTED_TRACTORS';
export const SET_SHOW_REMOTE_DRIVE_DETAILS = 'SET_SHOW_REMOTE_DRIVE_DETAILS';
export const SET_IMPLEMENT = 'SET_IMPLEMENT';
export const SET_SHOW_PRESENT_COMMAND = 'SET_SHOW_PRESENT_COMMAND';
export const SET_SETUP_KEYS = 'SET_SETUP_KEYS';
export const SET_TOGGLE_TIMER = 'SET_TOGGLE_TIMER';
export const LOAD_DRIVING_TRACTORS = 'LOAD_DRIVING_TRACTORS';
export const SELECTED_IMPLEMENT_NAME = 'SELECTED_IMPLEMENT_NAME';
export const SELECTED_IMPLEMENT_TYPE = 'SELECTED_IMPLEMENT_TYPE';

export const SELECTED_ITEM_NAME = 'SELECTED_ITEM_NAME';
export const SELECTED_VINE_BLOCK = 'SELECTED_VINE_BLOCK';
export const LOAD_RUNNING_TRACTORS = 'LOAD_RUNNING_TRACTORS';
export const SELECTED_WAYPOINT = 'SELECTED_WAYPOINTS';
export const SET_RESET_TIMER = 'SET_RESET_TIMER';
export const SET_TRIGGERED_GUARDRAIL = 'SET_TRIGGERED_GUARDRAIL';
export const SET_SHOW_PATH_MODAL = 'SET_SHOW_PATH_MODAL';
export const SET_ADD_STEP = 'SET_ADD_STEP';
export const RESET_STATE = 'RESET_STATE';
export const SELECTED_ROUTINE = 'SELECTED_ROUTINE';
export const AUTO_DRIVE_MONITOR_ERROR_CODES = 'AUTO_DRIVE_MONITOR_ERROR_CODES';
export const AUTO_DRIVE_ERROR = 'AUTO_DRIVE_ERROR';
export const SHOW_TRACTOR_START = 'SHOW_TRACTOR_START';
export const SHOW_TRACTOR_COMMAND_LOADER = 'SHOW_TRACTOR_COMMAND_LOADER';
export const SNAP_SHOT_INITIATED = 'SNAP_SHOT_INITIATED';
export const SET_SHOW_SNAPSHOT = 'SET_SHOW_SNAPSHOT';
export const AUTO_DRIVE_LOGOUT_ERROR_CODES = 'AUTO_DRIVE_LOGOUT_ERROR_CODES';
export const SET_TIME_LEFT = 'SET_TIME_LEFT';
export const SET_PINNED_TRACTORS = 'SET_PINNED_TRACTORS';
export const SET_SHOW_TERM_AND_COND = 'SET_SHOW_TERM_AND_COND';
export const SET_SHOW_ROUTINE = 'SET_SHOW_ROUTINE';
export const SET_AUTO_DRIVE_INTERRUPT_MESSAGES =
  'SET_AUTO_DRIVE_INTERRUPT_MESSAGES';

export const SET_AUTO_DRIVE_MONITOR_POP = 'SET_AUTO_DRIVE_MONITOR_POP';
export const SET_AUTO_DRIVE_POP = 'SET_AUTO_DRIVE_POP';
export const SET_AUTO_DRIVE_FEEDBACK_POP = 'SET_AUTO_DRIVE_FEEDBACK_POP';
export const SET_AUTO_DRIVE_FEEDBACK_PAYLOAD =
  'SET_AUTO_DRIVE_FEEDBACK_PAYLOAD';
export const SET_SHOW_CONNECT_LOADER = 'SET_SHOW_CONNECT_LOADER';
export const SET_SELECTED_TRACTOR_LOCATION = 'SET_SELECTED_TRACTOR_LOCATION';
export const SET_SHOW_IMPLEMENT = 'SET_SHOW_IMPLEMENT';
export const SET_SELECTED_OPENED_FLEET_TAB = 'SET_SELECTED_OPENED_FLEET_TAB';
export const SET_LOADER_FOR_IMPLEMENT_CHANGE =
  'SET_LOADER_FOR_IMPLEMENT_CHANGE';
export const SET_SHOW_LOSE_CONNECTION = 'SET_SHOW_LOSE_CONNECTION';
export const SET_ACTIVE_AUTODRIVE_TAB = 'SET_ACTIVE_AUTODRIVE_TAB';
export const SET_TRIGGER_TO_STARTING_POINT = 'SET_TRIGGER_TO_STARTING_POINT';
export const SET_SHOW_CONNECT_TRY_AGAIN_POP = 'SET_SHOW_CONNECT_TRY_AGAIN_POP';
export const SHOW_SIDE_FEED_BACK = 'SHOW_SIDE_FEED_BACK';
export const SHOW_AUTO_DRIVE_POOR_CONNECTIVITY_LOADER =
  'SHOW_AUTO_DRIVE_POOR_CONNECTIVITY_LOADER';
export const SET_WAY_POINTS_FOR_REMOTE_AV = 'SET_WAY_POINTS_FOR_REMOTE_AV';
export const SET_AUTO_DRIVE_LOADER = 'SET_AUTO_DRIVE_LOADER';
export const SET_REMAINING_TIME = 'SET_REMAINING_TIME';
export const SET_VELOCITY = 'SET_VELOCITY';
export const SET_PAUSE_RESUME_LOADER = 'SET_PAUSE_RESUME_LOADER';
export const SET_FEED_LANES = 'SET_FEED_LANES';
export const SET_SELECTED_FEED_LANES = 'SET_SELECTED_FEED_LANES';
export const SET_FEED_AMOUNT = 'SET_FEED_AMOUNT';
// export const SET_SELECTED_IMPLEMENT_WIDTH = 'SET_SELECTED_IMPLEMENT_WIDTH';
// export const SET_SHOW_IMPLEMENT_HANDLER = 'SET_SHOW_IMPLEMENT_HANDLER';
