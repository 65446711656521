import axios from 'axios';
import RoutesConstants from '../routes/RoutesConstant';

const axiosInstance = axios.create();
const whiteList = [RoutesConstants.Login, RoutesConstants.ForgotPassword];

const checkWL = (url: string) => {
  if (url.includes('remote-access')) {
    return false;
  } else {
    return !whiteList.includes(url);
  }
};

// Add a request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  },
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    const accessDenied = error.toString().indexOf('401');
    const url = window.location.pathname;
    if (checkWL(url)) {
      // if (!window.location.href.includes('login')) {
      if (accessDenied !== -1) {
        localStorage.clear();
        return (window.location.href = RoutesConstants.Login);
      }
    }

    if (error.message?.includes('Network Error')) {
      // notification.error({ message: error.message });
      // eslint-disable-next-line no-console
      console.error(error);
      return Promise.reject(error);
    } else if (accessDenied) {
      let message = error.message;
      if (
        error.response &&
        error.response?.data &&
        error.response?.data?.error
      ) {
        message = error?.response?.data?.error?.message;
      }
      return Promise.reject(new Error(message));
    } else {
      const message: string =
        error?.response?.data?.error?.message || error?.message;
      const err = new Error(message);
      return Promise.reject(err);
    }
    // const authorization = error.toString().indexOf('403');
    // if (authorization !== -1) {
    //   return (window.location.href = RoutesConstants.Exception[403]);
    // }
  },
);
export default axiosInstance;
