import { Button, Card, Empty, Layout, Menu, notification, Result } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import SubItems from './SubItems';
import RenderPDF from './RenderPDF';
// import topics from './topics.json';
import './style.css';
import TextArea from '../common/TextArea';
import { useHistory, useLocation } from 'react-router-dom';
import { getChapterTopics } from '../../constants/Api';
import { ApplicationContext } from '../../context/AppContext';
import RoutesConstants from '../../routes/RoutesConstant';

const HowToManual: React.FC = () => {
  const { userDetails } = useContext(ApplicationContext);
  const location: any = useLocation();
  const state: any = location && location.state ? location.state : {};
  const { chapter, errorCodes = '' } = state;
  const [openKeys, setOpenKeys] = useState(['']);
  const [selectedTopic, setSelectedTopic] = useState<any>();
  const [topics, setTopics] = useState<any>([]);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const { push } = useHistory();

  const rootSubmenuKeys = [''];
  const onOpenChange = (keys: any) => {
    const latestOpenKey = keys.find((key: any) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const initTopics = async () => {
    try {
      const records = await getChapterTopics(
        userDetails.organization.api_url,
        chapter.id,
        errorCodes,
      );
      setTopics(records);
      if (records && records.length) {
        setSelectedTopic(records[0]);
        setSelectedKeys([records[0].id]);
      }
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    }
  };

  useEffect(() => {
    if (userDetails && userDetails.organization && chapter) initTopics();
  }, [userDetails, chapter]);

  const goBack = () => {
    const lastTabIndex: string | null = localStorage.getItem('lastTabIndex');
    const tlastTabIndex: any = lastTabIndex && JSON.parse(lastTabIndex);
    if (tlastTabIndex?.catlog === 'FleetHealth') {
      push({
        pathname: RoutesConstants.FleetHealth,
        state: {
          action: 'DIAGNOSTICS',
        },
      });
    } else if (tlastTabIndex?.catlog === 'Organization') {
      push(RoutesConstants.Organization, { state: 'Fleet' });
    }
  };

  return (
    <Layout className="how-to-manual">
      <Card
        title={
          <h3 className="pointer" onClick={() => goBack()}>
            Manual : {chapter?.title}
          </h3>
        }
      >
        <div className="section-container">
          <div className="left-section">
            <Menu
              defaultSelectedKeys={selectedKeys}
              mode="inline"
              className="hm_listitems"
              openKeys={openKeys}
              onOpenChange={onOpenChange}
            >
              {topics ? (
                topics.map((topic: any) => (
                  <>
                    {topic.is_has_sub_topics ? (
                      <>
                        <SubItems
                          topic={topic}
                          setSelectedTopic={setSelectedTopic}
                        />
                      </>
                    ) : (
                      <>
                        <Menu.Item
                          key={topic.id}
                          onClick={() => setSelectedTopic(topic)}
                          className={
                            selectedTopic?.id === topic?.id
                              ? 'topic-selected'
                              : ''
                          }
                        >
                          {topic.title}
                        </Menu.Item>
                      </>
                    )}
                  </>
                ))
              ) : (
                <></>
              )}
            </Menu>
          </div>
          <div className="right-section">
            <div className="height-overflow">
              {topics && topics.length === 0 ? (
                <Result
                  icon={<Empty />}
                  extra={
                    <Button type="primary" onClick={() => goBack()}>
                      {' '}
                      Back
                    </Button>
                  }
                />
              ) : (
                <>
                  {selectedTopic?.file_path ? (
                    <RenderPDF filePath={selectedTopic?.file_path} />
                  ) : (
                    selectedTopic?.description && (
                      <TextArea
                        description={selectedTopic?.description}
                        readOnly
                        hideToolbar
                      />
                    )
                  )}
                </>
              )}
              {/* <RenderPDF filePath="https://mtrepairmanual.s3.amazonaws.com/dev/chapter_id-15-topic_number-undefined-2022-09-01-07-33-39.pdf" /> */}
            </div>
          </div>
        </div>
      </Card>
    </Layout>
  );
};

export default HowToManual;
