import { Select } from 'antd';
import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getSeverityIcon } from './common';
import './style.css';

interface Props {
  // eslint-disable-next-line no-unused-vars
  onChange: (state: any) => void;
  value: any[];
  label: string;
}

const SeverityFilter: React.FC<Props> = ({ onChange, value, label }) => {
  const { t } = useTranslation();
  const [selectedIds, setSelectedIds] = useState<any[]>([]);
  const [focus, setFocus] = useState(false);

  const labelClass = focus || value?.length ? 'label label-float' : 'label';

  const servirityLevel = [
    { name: 'Low', value: 1 },
    { name: `Medium`, value: 2 },
    { name: `High`, value: 3 },
  ];

  const selectSeverityLevel = async (e: any) => {
    setSelectedIds(e);
    onChange(e);
  };

  useEffect(() => {
    if (!selectedIds?.length && value?.length) setSelectedIds(value);
  }, [value]);

  useEffect(() => {
    if (!selectedIds.length) setFocus(false);
  }, [selectedIds]);

  return (
    <div className="multiSelectDropdown">
      <div
        className="float-label"
        onBlur={() => setFocus(false)}
        onFocus={() => setFocus(true)}
      >
        <Select
          data-testid="severityLevelDropdown-Tickets"
          allowClear
          mode="multiple"
          // placeholder="Severity Level"
          style={{ minWidth: 180 }}
          onChange={selectSeverityLevel}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option!.title as string)
              ?.toLowerCase()
              ?.includes(input?.toLowerCase())
          }
          filterSort={(optionA, optionB) =>
            (optionA!.title as string)
              ?.toLowerCase()
              ?.localeCompare(
                (optionB!.title as unknown as string).toLowerCase(),
              )
          }
          maxTagCount="responsive"
          value={selectedIds}
        >
          {servirityLevel.map((ele: any) => (
            <option
              title={ele.name}
              value={ele.value}
              key={ele.value}
              data-testid={`${ele.name}-Tickets`}
            >
              <div className="description">
                <img
                  className="mr8"
                  src={getSeverityIcon(ele?.value, 1)}
                  alt=""
                />
                <img
                  className="mr8"
                  src={getSeverityIcon(ele?.value, 2)}
                  alt=""
                />
                <img
                  className="mr8"
                  src={getSeverityIcon(ele?.value, 3)}
                  alt=""
                />
                {ele?.name}
              </div>
            </option>
          ))}
        </Select>
        <label className={labelClass}>{label} </label>
      </div>
    </div>
  );
};

export default memo(SeverityFilter);
