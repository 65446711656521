import { notification, Button, Layout } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getModelsListData, updateTractor } from '../API';
import { ApplicationContext } from '../../../context/AppContext';
import translate from '../../../locale/en_translate.json';
import closeGreen from '../../../assets/images/right_widget_close_icon.svg';
import loading_logo from '../../../assets/images/loading_logo.gif';
import {
  NumberValidationMethod,
  ValidateIPAddress,
} from '../../../constants/Common';
import CInput from '../../common/CInput';
import CustomSelect from '../../common/CustomSelect';

interface Props {
  showModel: boolean;
  close: (state?: boolean) => void;
  selectedTractor: any;
}

interface ModelData {
  id: number | string;
  name: string;
}

const EditTractor: React.FC<Props> = ({
  showModel,
  close,
  selectedTractor,
}: Props) => {
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [name, setName] = useState(selectedTractor.name);
  const [serialNumber, setSerialNumber] = useState(
    selectedTractor.serial_number,
  );
  const [model, setModel] = useState<number>(selectedTractor.model_id);
  const [loader, setLoader] = useState<boolean>(false);
  const [modelsData, setModelsData] = useState<ModelData[]>([]);
  const [radioId, setRadioId] = useState<string>(selectedTractor?.radio_id);
  const [radioChannelNumber, setRadioChannelNumber] = useState<string>(
    selectedTractor?.radio_channel_number,
  );
  const [formData, setFormData] = useState<any>({
    ipAddress: selectedTractor.ip_address,
    n2IpAddress: selectedTractor.n2_ip_address,
    voucherUID: selectedTractor?.voucher_uid,
  });
  const [validationMsg, setValidationMsg] = useState<string>('');

  useEffect(() => {
    if (
      name &&
      name.trim().length > 0 &&
      serialNumber &&
      serialNumber.trim().length > 0 &&
      model &&
      Number(radioId) &&
      Number(radioChannelNumber)
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [name, serialNumber, model, radioId, radioChannelNumber]);

  useEffect(() => {
    if (userDetails) {
      getModelsList();
    }
  }, [userDetails]);

  const getModelsList = async () => {
    try {
      const modelList = await getModelsListData('tractor_model');
      setModelsData(modelList);
    } catch (error: any) {
      notification.error({
        message: error?.message,
      });
    }
  };

  const validate = () => {
    const { ipAddress, n2IpAddress } = formData;
    if (ipAddress && ipAddress?.trim() !== '') {
      const valid = ValidateIPAddress(formData.ipAddress);
      if (valid) return valid;
      setValidationMsg('Invalid IP Address');
      return valid;
    }
    if (n2IpAddress && n2IpAddress?.trim() !== '') {
      const valid = ValidateIPAddress(formData.n2IpAddress);
      if (valid) return valid;
      setValidationMsg('Invalid N2 IP Address');
      return valid;
    }
    return true;
  };

  const UpdateTractorMethod = async () => {
    setValidationMsg('');
    const payload = {
      name: name.trim(),
      serial_number: serialNumber.trim(),
      tractor_model_id: Number(model),
      customer_organization_id: selectedTractor.customer_organization_id,
      radio_id: Number(radioId),
      radio_channel_number: Number(radioChannelNumber),
      ip_address: formData.ipAddress || '',
      n2_ip_address: formData.n2IpAddress || '',
      voucher_uid: formData.voucherUID || '',
    };
    if (!validate()) return;
    try {
      setLoader(true);
      const response: { msg: string } = await updateTractor(
        selectedTractor.id,
        payload,
      );
      notification.success({
        message: response.msg,
      });
      close(true);
    } catch (err: any) {
      notification.error({
        message: err?.message,
      });
    } finally {
      setLoader(false);
    }
  };

  const handleChangeRadio = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRadioId(NumberValidationMethod(e, radioId, 65364));
  };

  const handleRadioChannelNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRadioChannelNumber(NumberValidationMethod(e, radioChannelNumber, 255));
  };

  const changeName = (e: any) => {
    const value = e.replace(/[^a-zA-Z0-9_  -]/g, '');
    setName(value);
  };

  return (
    <Layout>
      <div className={'rightSideWidegt ' + (showModel ? 'expand' : 'hide')}>
        <div className="widgetCloseBtn">
          <img src={closeGreen} alt="close icon" onClick={() => close()} />
        </div>

        <div className="profileEdit widget_card">
          <h3 className="headline3 rowGapBtm3" style={{ textAlign: 'center' }}>
            {t(translate.label.editTractor)}
          </h3>
          <p className="error-message">{validationMsg}</p>
          <div className="mb20">
            <CInput
              max={60}
              min={3}
              value={name}
              name={name}
              label={t(translate.tractors.name)}
              required={true}
              onChange={changeName}
            />
          </div>
          <div className="mb20">
            <CInput
              value={serialNumber}
              name="Serial Number"
              label={t(translate.tractors.serialNumber)}
              required={true}
              onChange={(e) => setSerialNumber(e)}
            />
          </div>
          <div className="mb20">
            <CInput
              value={selectedTractor.customer_organization_name}
              label={t(translate.tractors.organization)}
              required={true}
              disabled
              name="organization"
            />
          </div>

          <div className="filters_card selectLabel">
            <div className="proRowType">
              <CustomSelect
                label="model"
                value={model}
                setValue={(e: any) => setModel(e)}
                options={modelsData}
                optionKey="id"
                optionDisplay="name"
              />
            </div>
          </div>
          <div className="mb20">
            <CInput
              value={radioId}
              label={t(translate.tractors.radio_id)}
              required={true}
              onChange={handleChangeRadio}
              name="radioId"
            />
          </div>
          <div className="mb20">
            <CInput
              value={radioChannelNumber}
              label={t(translate.tractors.radio_channel_number)}
              required={true}
              onChange={handleRadioChannelNumber}
              name="radioChannelNumber"
            />
          </div>

          <div className="mb20">
            <CInput
              value={formData.ipAddress}
              label={t(translate.healthData.ipAddress)}
              onChange={(e) => {
                setFormData({ ...formData, ipAddress: e.trim() });
              }}
              name="ipAddress"
            />
          </div>
          <div className="mb20">
            <CInput
              value={formData.n2IpAddress}
              label={t(translate.healthData.n2IpAddress)}
              onChange={(e) => {
                setFormData({ ...formData, n2IpAddress: e.trim() });
              }}
              name="n2IpAddress"
            />
          </div>
          <div className="mb20">
            <CInput
              value={formData.voucherUID}
              label={t(translate.healthData.voucherUID)}
              onChange={(e) => {
                setFormData({ ...formData, voucherUID: e.trim() });
              }}
              name="voucherUID"
            />
          </div>
          <div>
            <div className="edit-loader">
              {loader && (
                <img
                  style={{ width: '36px', height: '36px' }}
                  src={loading_logo}
                />
              )}
            </div>
            <div className="create_ticket_btn_sec">
              <Button onClick={UpdateTractorMethod} disabled={isDisabled}>
                {t(translate.buttons.save)}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default EditTractor;
