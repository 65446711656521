import {
  Avatar,
  Button,
  Input,
  notification,
  Popover,
  Switch,
  Tooltip,
} from 'antd';
import Layout from 'antd/lib/layout/layout';
import React, { useContext, useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import './style.css';
import closeGreen from '../../assets/images/closeGreen.svg';
import AlertIcon from '../../assets/images/Group 1296.svg';
import tractorImg from '../../assets/images/tractorImg.png';
import tractorAlertIcon from '../../assets/images/tractorAlert.svg';
import tractorspic from '../../assets/images/tractor_health.svg';
import applicationIds from '../../locale/applicationIds.json';
import { useHistory } from 'react-router-dom';
import RoutesConstants from '../../routes/RoutesConstant';
import {
  getBatteryDetailsForTractor,
  Updatetractor,
  getRadioData,
  enableDisableHbRadio,
  getTireInformation,
} from '../../constants/Api';
import { ApplicationContext } from '../../context/AppContext';
import { BatteryList, tireList, TractorsList } from '../../constants/types';
import {
  getTractorStatusClass,
  getStatusForTractor,
  tractorModelImageMap,
} from '../../constants/Common';
import { track } from '../../util/logger';
import DataCollectionModal from './DataCollectionModal';
import translate from '../../locale/en_translate.json';
import { useTranslation } from 'react-i18next';
import { privilagesConstants } from '../../constants/Privilages';
import AppLoader from '../common/AppLoader';
import AssignBasestationTractor from '../basestation/AssignBasestationTractor';
import { getIndicatorIcons } from '../remote_drive_new/common';

interface Props {
  fleetobj: TractorsList;
  Close: () => void;
  setRefresh: () => void;
  updateFleet: (data: any) => void;
  fleetflage: boolean;
}
const Editfleet: React.FC<Props> = ({
  fleetobj,
  updateFleet,
  Close,
  setRefresh,
  fleetflage,
}: Props) => {
  const { goBack, push } = useHistory();
  const { t } = useTranslation();
  const [editFlag, setEditFlag] = useState<boolean>(true);
  const [Name, setName] = useState(fleetobj?.name);
  const { userDetails, privilegeChecker, user } =
    useContext(ApplicationContext);
  const [loader, setLoader] = useState<boolean>(false);

  const [showDataCollection, setShowDataCollection] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [batteryDetails, setBatteryDetails] = useState<BatteryList>();
  const [assignflag, setassignflag] = useState<boolean>(false);
  const [assignObj, setAssignObj] = useState<any>();
  const [hbRadioStatus, setHbRadioStatus] = useState<boolean>();
  const [tireInfo, setTireInfo] = useState<tireList>();

  useEffect(() => {
    if (Name && Name.trim().length > 0) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [Name]);

  const updateHbRadio = async (e: boolean) => {
    try {
      setLoader(true);
      const payload: any = {
        is_tractor_hb_over_radio: e,
      };
      await enableDisableHbRadio(
        userDetails.organization.api_url,
        fleetobj?.id,
        payload,
      );
      getRadioSettings();
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      getRadioSettings();
    }
  }, [userDetails]);

  const getRadioSettings = async () => {
    try {
      setLoader(true);
      const { is_tractor_hb_over_radio } = await getRadioData(
        userDetails.organization.api_url,
        fleetobj?.id,
      );
      setHbRadioStatus(is_tractor_hb_over_radio);
    } catch (err: any) {
      notification.error({
        message: err?.message,
      });
    } finally {
      setLoader(false);
    }
  };

  const getBatteryDetails = async (tractorId: number) => {
    try {
      setLoader(true);
      const response = await getBatteryDetailsForTractor(
        userDetails.organization.api_url,
        userDetails.organization_id,
        tractorId,
      );
      if (response && response.length) {
        setBatteryDetails(response[0]);
      }
    } catch (err: any) {
      notification.error({
        message: err?.message,
      });
    } finally {
      setLoader(false);
    }
  };

  const getTireDetails = async (serial_number: string) => {
    try {
      setLoader(true);
      const response = await getTireInformation(
        userDetails.organization.api_url,
        serial_number,
      );
      setTireInfo(response);
    } catch (err: any) {
      notification.error({
        message: err?.message,
      });
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (fleetobj && userDetails && userDetails.organization) {
      setName(fleetobj?.name);
      setEditFlag(true);
      getBatteryDetails(fleetobj?.id);
      getTireDetails(fleetobj?.serial_number);
    }
  }, [fleetobj, userDetails]);

  const content = (
    <div>
      <p>
        {t(translate.fleet.updateAvailable)}
        <br />
        {t(translate.fleet.please)}{' '}
        <a
          onClick={() => {
            push({
              pathname: RoutesConstants.FleetHealth,
              state: {
                action: 'SOFTWARE',
                tractorId: fleetobj?.id,
              },
            });
          }}
          style={{ textDecoration: 'underline' }}
        >
          {t(translate.fleet.updateNow)}{' '}
        </a>
      </p>
    </div>
  );

  const Editdetails = () => {
    setEditFlag(false);
    track('Edit Fleet', {
      event: 'Edit Fleet Details Clicked',
    });
  };
  const SaveTractor = async () => {
    const data = {
      name: Name,
    };
    try {
      setLoader(true);
      const response: any = await Updatetractor(
        userDetails.organization.api_url,
        fleetobj.id,
        data,
      );
      fleetobj.name = Name;
      if (response && response?.id === fleetobj?.id)
        updateFleet({ ...fleetobj, ...response });
      notification.success({ message: t(translate.label.tractorUpdated) });
      setEditFlag(true);
      setRefresh();
      track('Edit Fleet', {
        event: 'Edit fleet name Successfully',
      });
    } catch (err: any) {
      track('Edit Fleet', {
        event: 'Edit fleet name Failed',
      });
      notification.error({
        message: err?.message,
      });
    } finally {
      setLoader(false);
    }
  };

  const MapOpen = () => {
    push({
      pathname: RoutesConstants.LiveMap,
      state: {
        tractorId: fleetobj.id,
      },
    });
    track('LiveMap', {
      event: 'Moved to LiveMap',
    });
  };

  const showUpdaterIcon = (record: any) => {
    if (
      record?.softwareversion &&
      record?.heartbeat?.software_version !==
        record?.latest_software?.software_version
    ) {
      return true;
    }

    return false;
  };

  const tractorIcon =
    fleetobj?.indicatorsData &&
    fleetobj?.indicatorsData?.filter(
      (indicator: any) => indicator?.indicator_level === 3,
    ).length > 0
      ? tractorAlertIcon
      : tractorspic;

  const assignMethod = (obj: any) => {
    setAssignObj(obj);
    setassignflag(true);
  };
  const handleCancel = (state = false) => {
    if (state) {
      Close();
    }
    setassignflag(false);
  };

  const cancelEdit = () => {
    setEditFlag(true);
    setName(fleetobj?.name);
  };

  return (
    <Layout>
      <div className={'rightSideWidegt ' + (fleetflage ? 'expand' : 'hide')}>
        {editFlag && (
          <>
            <div className="widgetCloseBtn">
              <img
                id={applicationIds.fleetScreen.fleetCloseBtn}
                data-testid="closeButton-Editfleet"
                src={closeGreen}
                alt="close icon"
                onClick={Close}
              />
            </div>
            <div>
              <div className="profileView rightWidgetView">
                <AppLoader loader={loader} />
                <div className="proRow tractorPic">
                  <Avatar
                    style={{ height: 162, width: 224 }}
                    src={
                      tractorModelImageMap(fleetobj?.tractor_model) ??
                      tractorImg
                    }
                    alt=""
                  ></Avatar>
                </div>
                <div className="proRow rowGapBtm4 tractorType ">
                  <h4
                    className="headline3 rowGapBtm6 txtCenter"
                    data-testid="tractorHeader-Editfleet"
                  >
                    {fleetobj?.name}
                  </h4>
                  <h5 className="overline1 fSbd rowGapBtm6">
                    {fleetobj?.tractor_model === 'PV' ||
                    fleetobj?.tractor_model === 'DV'
                      ? 'MK-V'
                      : fleetobj?.tractor_model}
                  </h5>
                  <Tooltip title="View in Live Map">
                    <span
                      className={getTractorStatusClass(fleetobj.isAvailable)}
                      onClick={MapOpen}
                    >
                      {getStatusForTractor(fleetobj?.isAvailable)}
                    </span>
                  </Tooltip>
                </div>
                <div>
                  <div className="tractorDetailsContainer rowGapBtm30">
                    <div
                      className="trDetailsTxt headline4 rowGapBtm4"
                      data-testid="tractorDetails-Editfleet"
                    >
                      {t(translate.editFleet.tractorDetails)}
                    </div>
                    <div className="tractorList rowGapBtm30">
                      <Tooltip
                        overlayInnerStyle={{
                          color: 'rgb(61, 60, 60)',
                          backgroundColor: '#fff',
                          fontSize: 10,
                          border: '1px solid #ccc',
                          borderRadius: 4,
                        }}
                        title="View in Tractor Diagnostics"
                      >
                        <div
                          className={`tractorPics ${
                            fleetobj?.isTractorError && 't_error'
                          }`}
                          data-testid="tractorPicHover-Editfleet"
                          onClick={() =>
                            push({
                              pathname: RoutesConstants.FleetHealth,
                              state: {
                                tractorId: fleetobj.id,
                                action: 'INDICATOR',
                              },
                            })
                          }
                        >
                          <div className="indicatorimges">
                            {getIndicatorIcons(fleetobj)}
                          </div>
                        </div>
                      </Tooltip>
                      <div>
                        <div>
                          <div className="fleetSwVer headline5">Hour Meter</div>
                          <div className="fleetSwVerNo body2">
                            {fleetobj?.hour_meter ?? '-'}
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="fleetSwVer headline5">
                          {t(translate.fleet.pin)}
                        </div>
                        <div className="fleetSwVerNo pinBody">
                          {fleetobj?.tractor_pin ?? '-'}
                        </div>
                      </div>
                      <div>
                        <div
                          className="fleetSwVer headline5"
                          data-testid="softwareVersion-Editfleet"
                        >
                          {t(translate.fleet.softwareVersion)}
                        </div>
                        {
                          <div
                            className="fleetSwVerNo body2"
                            data-testid="tractversion-EditFleet"
                          >
                            {fleetobj?.softwareversion ?? '-'}
                            {showUpdaterIcon(fleetobj) && (
                              <Popover
                                overlayClassName="softwarePopover"
                                content={content}
                                title=""
                              >
                                <img
                                  src={AlertIcon}
                                  height="24px"
                                  data-testid="updateIcon-Editfleet"
                                />
                              </Popover>
                            )}
                          </div>
                        }
                      </div>
                      <div>
                        <div className="fleetSwVer headline5">
                          {t(translate.fleet.tireBrand)}
                        </div>
                        <div className="fleetSwVerNo body2">
                          {tireInfo ? tireInfo?.brand : '-'}
                        </div>
                      </div>

                      <div>
                        <div className="fleetSwVer headline5">
                          {t(translate.fleet.tireType)}
                        </div>
                        <div className="fleetSwVerNo body2">
                          {tireInfo ? tireInfo?.tire_type : '-'}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="tractorDetailsContainer rowGapBtm30">
                    <div
                      className="trDetailsTxt headline4 rowGapBtm4"
                      data-testid="batteryDetails-Editfleet"
                    >
                      {t(translate.editFleet.batteryDetails)}
                    </div>
                    <div className="tractorList rowGapBtm30">
                      <div>
                        <div
                          className="fleetSwVer headline5"
                          data-testid="batteryName-Editfleet"
                        >
                          {t(translate.fleet.name)}
                        </div>
                        <div className="fleetSwVerNo body2">
                          {batteryDetails?.battery_name ?? '-'}
                        </div>
                      </div>
                      <div>
                        <div className="fleetSwVer headline5">
                          {t(translate.fleet.hourMeter)}
                        </div>
                        <div className="fleetSwVerNo body2">
                          {batteryDetails &&
                          batteryDetails?.current_hour_meter_hours > 0
                            ? `${batteryDetails?.current_hour_meter_hours} hours`
                            : '-'}
                        </div>
                      </div>
                      <div>
                        <div className="fleetSwVer headline5">
                          {t(translate.fleet.pin)}
                        </div>
                        <div className="fleetSwVerNo body2">
                          {batteryDetails?.bin === '0'
                            ? '-'
                            : batteryDetails?.bin}
                        </div>
                      </div>

                      <div />
                    </div>
                  </div>

                  <div
                    className={
                      user?.isSupportUser()
                        ? 'tractorDetailsContainer rowGapBtm30'
                        : 'rowGapBtm30'
                    }
                  >
                    <div
                      className="trDetailsTxt headline4 rowGapBtm4 basestationdetailsgrid"
                      data-testid="baseStationDetails-Editfleet"
                    >
                      <span>{t(translate.editFleet.basestationDetails)}</span>
                      {privilegeChecker(
                        privilagesConstants.Edit_base_station_information,
                      ) &&
                        fleetobj?.connection === 'online' && (
                          <div className="assignstationbtn">
                            <button
                              className="Button"
                              onClick={() => {
                                assignMethod(fleetobj);
                              }}
                            >
                              {t(translate.buttons.edit)}
                            </button>
                          </div>
                        )}
                    </div>
                    <div className="trDetailsTxt headline3 rowGapBtm4"></div>
                    <div className="tractorList rowGapBtm30 ">
                      <div className="tractorLeft">
                        <div className="fleetSwVer headline5">
                          {t(translate.editFleet.station)}
                        </div>
                        <div className="fleetSwVerNo body2">
                          {fleetobj?.basestation?.name ?? '-'}
                        </div>
                      </div>
                      <div className="tractorRight">
                        <div className="fleetSwVer headline5">
                          {t(translate.fleet.pin)}
                        </div>
                        <div className="fleetSwVerNo body2">
                          {fleetobj?.basestation?.bsin ?? '-'}
                        </div>
                      </div>
                    </div>
                  </div>
                  {user?.isSupportUser() && (
                    <div className="mt20">
                      <div className="tractorList rowGapBtm30">
                        <div
                          className="fleetSwVer headline5"
                          data-testid="batteryName-Editfleet"
                        >
                          {t(translate.fleet.radioSettings)}
                        </div>
                        <div className="fleetSwVerNo body2">
                          <Switch
                            checked={hbRadioStatus}
                            onChange={(e) => updateHbRadio(e)}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {privilegeChecker(privilagesConstants.Edit_Fleet_Details) && (
                <div className="proeditBtn saveTxtBtn">
                  <button
                    id={applicationIds.fleetScreen.editFleetDetails}
                    onClick={Editdetails}
                    data-testid="fleetEditDetailsLink-Editfleet"
                  >
                    {t(translate.buttons.editDetails)}
                  </button>
                </div>
              )}
            </div>
          </>
        )}
        {!editFlag && (
          <div className="profileEdit rightWidgetView editFleetView">
            <AppLoader loader={loader} />
            <div className="proRow mt30">
              <div className="proRow mb24 tractorPic">
                <Avatar
                  style={{ height: 162, width: 224 }}
                  src={
                    tractorModelImageMap(fleetobj?.tractor_model) ?? tractorImg
                  }
                  alt=""
                ></Avatar>
              </div>
            </div>
            <div className="proInputMain">
              <h4 className="proInput">
                <div className="fleetSwVer mlb tractorLable">
                  {t(translate.editFleet.tractorName)}
                </div>
                <Input
                  id={applicationIds.fleetScreen.fleetName}
                  placeholder="Name"
                  defaultValue={Name}
                  onChange={(e) => setName(e.target.value)}
                  data-testid="tractorNameEditInputField-Editfleet"
                />
              </h4>
            </div>
            <div className="editTractorDetCon">
              <div className="tractorDetailsContainer">
                <div className="trDetailsTxt headline4 rowGapBtm4">
                  {t(translate.editFleet.tractorDetails)}
                </div>
                <div className="tractorList rowGapBtm30">
                  <div className="tractorPics1">
                    <div className="indicatorimges">
                      {getIndicatorIcons(fleetobj)}
                    </div>
                  </div>
                  <div>
                    <div className="fleetSwVer headline5">
                      {t(translate.fleet.hourMeter)}
                    </div>
                    <div className="fleetSwVerNo body2">
                      {fleetobj?.housemeter ?? '-'}
                    </div>
                  </div>
                  <div>
                    <div className="fleetSwVer headline5">
                      {t(translate.fleet.softwareVersion)}
                    </div>
                    <div className="fleetSwVerNo body2">
                      {fleetobj?.softwareversion ?? '-'}{' '}
                      <img src={AlertIcon} height="24px" />
                    </div>
                  </div>

                  <div>
                    <div className="fleetSwVer headline5">
                      {t(translate.fleet.pin)}
                    </div>
                    <div className="fleetSwVerNo body2">
                      {fleetobj?.tractor_pin ?? '-'}
                    </div>
                  </div>
                </div>
              </div>

              <div className="tractorDetailsContainer mt14">
                <div className="trDetailsTxt headline4 rowGapBtm4">
                  {t(translate.editFleet.batteryDetails)}
                </div>
                <div className="tractorList rowGapBtm30">
                  <div>
                    <div className="fleetSwVer headline5">
                      {t(translate.fleet.name)}
                    </div>
                    <div className="fleetSwVerNo body2">
                      {batteryDetails?.battery_name ?? '-'}
                    </div>
                  </div>
                  <div>
                    <div className="fleetSwVer headline5">
                      {t(translate.fleet.hourMeter)}
                    </div>
                    <div className="fleetSwVerNo body2">
                      {batteryDetails &&
                      batteryDetails?.current_hour_meter_hours > 0
                        ? `${batteryDetails?.current_hour_meter_hours} hours`
                        : '-'}
                    </div>
                  </div>
                  <div>
                    <div className="fleetSwVer headline5">
                      {t(translate.fleet.pin)}
                    </div>
                    <div className="fleetSwVerNo body2">
                      {batteryDetails?.bin === '0' ? '-' : batteryDetails?.bin}
                    </div>
                  </div>

                  <div />
                </div>
              </div>

              <div
                className={
                  user?.isSupportUser()
                    ? 'tractorDetailsContainer mt14'
                    : 'mt14'
                }
              >
                <div className="trDetailsTxt headline4 rowGapBtm4 ">
                  {t(translate.editFleet.basestationDetails)}
                </div>
                <div className="tractorList rowGapBtm30">
                  <div className="tractorLeft">
                    <div className="fleetSwVer headline5">
                      {t(translate.editFleet.station)}
                    </div>
                    <div className="fleetSwVerNo body2">
                      {fleetobj?.basestation?.name ?? '-'}
                    </div>
                  </div>
                  <div className="tractorRight">
                    <div className="fleetSwVer headline5">
                      {t(translate.fleet.pin)}
                    </div>
                    <div className="fleetSwVerNo body2">
                      {fleetobj?.basestation?.bsin ?? '-'}
                    </div>
                  </div>
                </div>
              </div>
              {user?.isSupportUser() && (
                <div className="mt20">
                  <div className="tractorList rowGapBtm30">
                    <div
                      className="fleetSwVer headline5"
                      data-testid="batteryName-Editfleet"
                    >
                      {t(translate.fleet.radioSettings)}
                    </div>
                    <div className="fleetSwVerNo body2">
                      <Switch
                        disabled
                        checked={hbRadioStatus}
                        onChange={(e) => updateHbRadio(e)}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="newFleetBtns editFleetButtons">
              <Button
                className="fleetCancel Button"
                data-testid="fleetCancelButton-Editfleet"
                onClick={cancelEdit}
              >
                {t(translate.buttons.cancel)}
              </Button>
              <Button
                className="fleetSave Button"
                id={applicationIds.fleetScreen.saveTractor}
                onClick={SaveTractor}
                disabled={disabled}
                data-testid="fleetSaveButton-Editfleet"
              >
                {t(translate.buttons.save)}
              </Button>
            </div>
          </div>
        )}
      </div>
      {showDataCollection && (
        <DataCollectionModal
          showModal={showDataCollection}
          closeModal={() => setShowDataCollection(false)}
          tractorIdentity={fleetobj}
        />
      )}

      {assignObj && assignflag && (
        <AssignBasestationTractor
          showModal={assignflag}
          handleCancel={(value) => handleCancel(value)}
          assignObj={assignObj}
        ></AssignBasestationTractor>
      )}
    </Layout>
  );
};

export default Editfleet;
