// Import necessary packages and styles
import React, { useEffect } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import '@amcharts/amcharts4/core.js';
import '@amcharts/amcharts4/charts.js';

interface Props {
  data: any;
  chartId: string;
  smooth: boolean;
  points: boolean;
  coverArea: boolean;
  lineColor: string;
  areaColor?: string;
  loader: boolean;
}
// Apply amCharts themes
am4core.useTheme(am4themes_animated);

const LineChart: React.FC<Props> = ({
  data,
  chartId,
  smooth,
  points,
  coverArea,
  lineColor,
  loader,
}) => {
  useEffect(() => {
    // Create a chart instance
    const chart = am4core.create(chartId, am4charts.XYChart);
    chart.logo.disabled = true;

    chart.data = data;

    // Create category axis
    const categoryAxis = chart.xAxes.push(new am4charts.DateAxis());
    // categoryAxis.dataFields.category = 'category';
    categoryAxis.renderer.labels.template.fontSize = 10;
    // categoryAxis.title.text = 'Categories';
    // categoryAxis.renderer.grid.template.disabled = true;
    // categoryAxis.renderer.labels.template.disabled = true;

    // Create value axis
    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.labels.template.fontSize = 10;
    // valueAxis.title.text = 'Values';
    // valueAxis.renderer.grid.template.disabled = true;
    // valueAxis.renderer.labels.template.disabled = true;

    // Create series
    const series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = 'value';
    series.dataFields.dateX = 'category';
    series.name = 'Series 1';
    series.tooltipText = '{dateX} : {valueY}';

    // Add a cursor
    chart.cursor = new am4charts.XYCursor();

    // // Add legend
    // chart.legend = new am4charts.Legend();
    const markerTemplate = chart?.legend?.markers?.template;
    if (markerTemplate) {
      markerTemplate.width = 10;
      markerTemplate.height = 10;
      chart.legend.position = 'top';
      chart.legend.align = 'center';
      chart.legend.fontFamily = 'Montserrat-Regular';
      chart.legend.fontSize = 10;
    }

    //hide grid behind the chart
    categoryAxis.renderer.grid.template.opacity = 0;
    // valueAxis.renderer.grid.template.opacity = 0;

    // // Set the line to be smooth
    if (smooth) {
      series.tensionX = 0.8;
    }

    // Customize the color of the line
    series.stroke = am4core.color(lineColor);

    series.fill = am4core.color(lineColor);

    //stroke width
    series.strokeWidth = 2;

    // Apply gradient fill to the series
    // const gradient = new am4core.LinearGradient();
    // gradient.rotation = 90; // 90 degrees for top to bottom direction
    // gradient.addColor(am4core.color('#379526'), 0.6);
    // gradient.addColor(am4core.color('#379526'), 0);

    // series.fill = gradient;

    // fill color below the chart
    if (coverArea) {
      const gradient = new am4core.LinearGradient();
      gradient.addColor(am4core.color('#379526'), 0.6); // Start color (red) at 0%
      gradient.addColor(am4core.color('#379526'), 0); // Middle color (green) at 50%
      gradient.rotation = 90; // 90 degrees for top to bottom direction
      series.fillOpacity = 1; // Adjust the opacity as needed
      series.fill = am4core.color(lineColor);
      series.fill = gradient;
      series.strokeWidth = 2.5;

      // Customize tooltip appearance (if needed)
      if (series.tooltip) {
        series.tooltip.getFillFromObject = false;
        series.tooltip.background.fill = am4core.color('#379526'); // Set tooltip background color
        series.tooltip.label.fill = am4core.color('#ffffff'); // Set tooltip text color
      }
    }

    // // fill color below the chart
    // if (coverArea) {
    //   series.fillOpacity = 0.2; // Adjust the opacity as needed
    //   series.fill = am4core.color(lineColor);
    // }

    // Show points on the line
    if (points) {
      const bullet = series.bullets.push(new am4charts.CircleBullet());
      bullet.circle.fill = am4core.color(lineColor);
    }

    let indicator: any;
    let indicatorInterval: any;

    function showIndicator(chart: any) {
      if (!indicator) {
        indicator = chart.tooltipContainer.createChild(am4core.Container);
        indicator.background.fill = am4core.color('#fff');
        indicator.background.fillOpacity = 0.8;
        indicator.width = am4core.percent(100);
        indicator.height = am4core.percent(100);

        const indicatorLabel = indicator.createChild(am4core.Label);
        indicatorLabel.text = 'Loading...';
        indicatorLabel.align = 'center';
        indicatorLabel.valign = 'middle';
        indicatorLabel.fontSize = 20;
        indicatorLabel.dy = 50;

        // eslint-disable-next-line no-var
        // var hourglass = indicator.createChild(am4core.Image);
        // hourglass.href =
        //   'https://s3-us-west-2.amazonaws.com/s.cdpn.io/t-160/hourglass.svg';
        // hourglass.align = 'center';
        // hourglass.valign = 'middle';
        // hourglass.horizontalCenter = 'middle';
        // hourglass.verticalCenter = 'middle';
        // hourglass.scale = 0.7;
      }

      indicator.hide(0);
      indicator.show();

      // clearInterval(indicatorInterval);
      // indicatorInterval = setInterval(function () {
      //   hourglass?.animate(
      //     [
      //       {
      //         from: 0,
      //         to: 360,
      //         property: 'rotation',
      //       },
      //     ],
      //     2000,
      //   );
      // }, 3000);
    }

    function hideIndicator() {
      indicator?.hide();
      clearInterval(indicatorInterval);
    }

    loader ? showIndicator(chart) : hideIndicator();

    // Clean up when the component is unmounted
    return () => {
      chart.dispose();
    };
  }, [data, chartId, points, coverArea, smooth, lineColor, loader]); // Ensure this effect runs only once on mount

  return (
    <div
      id={chartId}
      style={{ width: '100%', height: smooth ? '350px' : '230px' }}
    />
  );
};

export default LineChart;
