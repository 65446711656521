import { Button, Input } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React from 'react';
import X_icon from '../../assets/images/X_icon.svg';
import alert_circle from '../../assets/images/alert_circle.svg';

interface Props {
  readonly showSaveOperation: boolean;
  operationName: string;
  setOperationName: (state: string) => void;
  setShowSaveOperation: (state: boolean) => void;
  handleSavePreset: () => void;
  handleGeneratePath: () => void;
}

const SavePreset: React.FC<Props> = ({
  showSaveOperation,
  operationName,
  setOperationName,
  setShowSaveOperation,
  handleSavePreset,
  handleGeneratePath,
}: Props) => {
  const disableOperation = () => operationName.trim().length === 0;

  return (
    <Modal
      visible={showSaveOperation}
      closable={false}
      footer={false}
      className="edit_operator"
    >
      <div className="cross_close" onClick={() => setShowSaveOperation(false)}>
        {' '}
        <img src={X_icon} />
      </div>
      <div className="popup_head">
        <span className="head_text">Save as Preset?</span>
      </div>
      <p className="popup_desc_text">
        Saving this operation will make the set up process easier in the future
      </p>
      <div className="input_sec">
        <Input
          name="OperationName"
          value={operationName}
          onChange={(e) => setOperationName(e.target.value)}
        />
        {disableOperation() && (
          <p className="error_msg">
            <img src={alert_circle} />
            Please enter your name{' '}
          </p>
        )}
      </div>
      <div className="button_sec">
        <Button
          key="cancel"
          className="normal_btn"
          onClick={() => {
            handleGeneratePath();
            setShowSaveOperation(false);
          }}
        >
          Don’t Save
        </Button>
        <Button
          key="edit"
          className="green_btn"
          onClick={() => {
            handleSavePreset();
          }}
          disabled={disableOperation()}
        >
          Save
        </Button>
      </div>
    </Modal>
  );
};

export default SavePreset;
