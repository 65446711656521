/* eslint-disable no-console */
import React from 'react';
import {
  getConnectivityImg,
  tractorConstantsNew,
  getNetworkTypeImg,
  wrapIndictorsData,
  isEmptyValue,
} from '../../constants/Common';
import constant from '../../constants/constant';

import wifiOff from '../../assets/images/wOff.svg';
import tractorAlertIcon from '../../assets/images/tractorAlert.svg';
import tractorspic from '../../assets/images/tractor_health.svg';
import nworkOff from '../../assets/images/low.svg';
import base_station_icon from '../../assets/images/base_station_icon.svg';

const { TRACTOR_COMMUNICATION } = constant;

export const getIndicatorIcons = (tractor: any) => {
  const { indicators: data } = tractor;
  const indicatorsData = wrapIndictorsData(data);
  const connectivity = getConnectivityImg(
    indicatorsData[TRACTOR_COMMUNICATION],
  );
  const isBaseStationIcon = !isEmptyValue(tractor?.heartbeat?.bs_serial_no);
  if (tractor.isAvailable === tractorConstantsNew.off.key) {
    return (
      <>
        <div className="t_signals">
          <img
            height="18px"
            className="mr4"
            style={{ opacity: '0.3' }}
            src={tractor.isTractorError ? tractorAlertIcon : tractorspic}
          />
          {tractor.active_network === 'cellular' ? (
            <img height="18px" className="mr4" src={nworkOff} />
          ) : (
            <img height="18px" className="mr4" src={wifiOff} />
          )}
        </div>
      </>
    );
  }
  return (
    connectivity.trim() !== '' && (
      <div className={`t_signals ${tractor.isTractorError ? 't_error' : ''}`}>
        <div className="t_signals_img">
          <img
            className="mr4"
            height="18px"
            src={tractor.isTractorError ? tractorAlertIcon : tractorspic}
          />
        </div>
        <div className="t_signals_img">
          {tractor.active_network === 'cellular' ? (
            <img
              className="mr4"
              height="18px"
              src={getNetworkTypeImg(tractor)}
            />
          ) : (
            <img
              height={'18px'}
              className={isBaseStationIcon ? 'mrl10' : 'mr4'}
              src={
                isBaseStationIcon
                  ? base_station_icon
                  : getNetworkTypeImg(tractor)
              }
            />
          )}
        </div>
      </div>
    )
  );
};
