/* eslint-disable react/no-unescaped-entities */
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import {
  Button,
  Input,
  Layout,
  Space,
  Col,
  Spin,
  notification,
  message,
} from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import 'antd/dist/antd.css';
import { useHistory } from 'react-router';
import RoutesConstants from '../../routes/RoutesConstant';
import { Content } from 'antd/lib/layout/layout';
import { useTranslation } from 'react-i18next';
import translate from '../../locale/en_translate.json';
import applicationIds from '../../locale/applicationIds.json';
import 'antd/dist/antd.css';
import { ApplicationContext } from '../../context/AppContext';
import jwt_decode from 'jwt-decode';
import { UserDetails } from '../../constants/types';
import { getFCMToken } from '../../firebaseInit';
import loading_logo from '../../assets/images/loading_logo.gif';
import { track } from '../../util/logger';
import {
  confirmUserNamechange,
  loginWithToken,
  subscribe,
} from '../../constants/Api';
import WingspanAI_logo_temp from '../../assets/images/WingspanAI_logo_temp.svg';
import ForgotPassword from './ForgotPassword';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { REMOTE_ACCESS_GRANT } from '../../constants/constant';
import { version } from '../../../package.json';
import { Auth } from 'aws-amplify';
import monarch_technology from '../../assets/images/monarch_technology.svg';
import './wingspan.css';
import { useLocation } from 'react-router-dom';
import { isMonarchDigital } from '../admin/common/lib';

interface Props {
  handleSubmit?: (record: any) => void;
  authData?: (record: any) => void;
  username?: string;
}
const LoginWing: React.FC<Props> = ({
  handleSubmit,
  authData,
  username,
}: Props) => {
  const [userName, setUserName] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [errors, setErrors] = useState<string>('');
  const [isValid, setIsValid] = useState<boolean>(true);
  const [loginDisabled, setLoginDisabled] = useState(true);
  const { updateUserDetails } = useContext(ApplicationContext);
  const [spinning, setSpinning] = useState(false);
  const queryParams = new URLSearchParams(location.search);
  const action = String(queryParams.get('supportid'));

  const history = useHistory();
  const { pathname } = useLocation();
  const translation = useTranslation();
  const [resetPassword, setResetPassword] = useState<boolean>(false);

  useEffect(() => {
    if (pathname.includes(RoutesConstants.ChangeUserName)) {
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get('token');
      const action = urlParams.get('action');
      const email_expiration = urlParams.get('email_expiration');
      const confirmNameChange = async () => {
        try {
          setSpinning(true);
          await confirmUserNamechange({
            token,
            action,
            email_expiration,
          });
          message.success({
            type: 'success',
            content:
              'Your email has been verified successfully. Please log in.',
            duration: 30,
            contentBg: '#CFE2CC',
            className: 'toastPlace',
          });
        } catch (err) {
          notification.error({
            message: 'Verification link is expired or is invalid',
            duration: 30,
          });
        } finally {
          setSpinning(false);
        }
      };
      confirmNameChange();
    }
  }, [pathname]);

  useEffect(() => {
    if (action && action !== 'null') setUserName(action);
  }, [action]);

  const pushLink = (link: string) => {
    if (history) {
      history.push(link);
    }
  };
  const translateData = (content: string) => {
    return translation.t(content);
  };

  const resetData = () => {
    setLoginDisabled(true);
    setIsValid(true);
    setErrors('');
  };
  useEffect(() => {
    setLoginDisabled(userName.trim().length < 1 || password.trim().length < 1);
    setIsValid(true);
    setErrors('');
    return () => {
      resetData();
    };
  }, [userName, password]);

  const handleRemoteAccess = () => {
    const keyCheck = localStorage.getItem(REMOTE_ACCESS_GRANT);
    if (keyCheck)
      window.open(
        `${window.location.origin}/${REMOTE_ACCESS_GRANT}?remote_access_token=${keyCheck}`,
        '_blank',
      );
  };

  const handleLogin = async (e: any) => {
    if (handleSubmit) {
      handleSubmit({ userName, password });
      e.preventDefault();
    }
    try {
      setSpinning(true);
      const response = await Auth.signIn({ username: userName, password });
      const access_token = response.signInUserSession.idToken.jwtToken;
      localStorage.setItem('access_token', access_token);
      try {
        const { Authorization } = await loginWithToken({
          org_type: 'customer',
          username: userName,
        });
        handleAuthToken(Authorization);
      } catch (error: any) {
        notification.error({
          message: error?.message,
          duration: 2,
        });
      }
    } catch (err: any) {
      track('Login', {
        event: 'LoginFailed',
      });
      setErrors(
        err.toString().includes('Password attempts exceeded')
          ? 'Password attempts exceeded'
          : 'Incorrect Username or Password',
      );
      setIsValid(false);
    } finally {
      setSpinning(false);
    }
  };

  const handleAuthToken = (Authorization: string) => {
    localStorage.setItem('auth', Authorization);
    updateUserDetails();
    const userDetails: UserDetails = jwt_decode(Authorization || '');
    registerToken(userDetails);
    track('Login', {
      event: 'Login Success',
    });
    if (authData) {
      authData(Authorization);
    }
    if (userDetails) {
      handleRemoteAccess();
      if (userDetails?.organization)
        pushLink(
          isMonarchDigital(userDetails?.organization)
            ? RoutesConstants.Admin
            : RoutesConstants.Attention,
        );
    } else {
      setErrors('user details not found');
    }
  };

  const registerToken = async (userDetails: UserDetails) => {
    try {
      const token = await getFCMToken();
      subscribe(userDetails.organization.api_url, {
        platform: 'web',
        api_service: 'fcm',
        token,
      });
    } catch (err: any) {
      setErrors(err.message);
    }
  };

  const handleResetScreen = () => {
    setResetPassword(true);
    pushLink(RoutesConstants.EmailConfirm);
  };

  return (
    <Spin
      className="loader"
      spinning={spinning}
      indicator={
        <img style={{ width: '40px', height: '40px' }} src={loading_logo} />
      }
    >
      <div className="loginContainer wai_loginContainer">
        <div className="loginWrapper wai_login_wrapper">
          <div className="monarchImg wingspanai_logo">
            <img alt="monarch" src={WingspanAI_logo_temp} />
          </div>
          <div className="loginContWrap wai_loginContWrap">
            <div className="wai_loginTxtSec">
              <div className="wai_loginTxt">
                Leading the
                <br />
                way in farm
                <br />
                autonomy
              </div>
              <div className="wai_loginTxt1">
                Leading the way in farm autonomy
              </div>
            </div>
            <div className="loginRight loginForm wai_loginRight">
              <Col>
                <Layout className="layout">
                  <Content className="container">
                    <Space direction="vertical">
                      <p className="errMsgDiv" data-testid={`${errors}-Login`}>
                        {errors}
                      </p>
                      <div className="loginFRow wai_loginFRow">
                        <Input
                          id={applicationIds.loginScreen.userId}
                          placeholder="Email"
                          value={userName}
                          onChange={(e) => setUserName(e.target.value)}
                          defaultValue={username}
                          className={classNames({
                            validationClass: !isValid,
                          })}
                          data-testid="emailInputField-Login"
                        />
                      </div>
                      <div className="loginFRow wai_loginFRow mb50">
                        <div
                          onClick={handleResetScreen}
                          className="forgotTxt wai_forgotTxt"
                          data-testid="forgotpasswrdlink-Login"
                        >
                          Forgot Password
                        </div>
                        <Input.Password
                          id={applicationIds.loginScreen.password}
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          onPressEnter={(e) => handleLogin(e)}
                          placeholder="Password"
                          className={classNames({
                            validationClass: !isValid,
                          })}
                          data-testid="passwordInputField-Login"
                          iconRender={(visible) =>
                            visible ? (
                              <EyeTwoTone style={{ color: '#fff' }} />
                            ) : (
                              <EyeInvisibleOutlined style={{ color: '#fff' }} />
                            )
                          }
                        />
                      </div>

                      <div className="rememberTxt wai_rememberTxt">
                        <Checkbox /> Remember Me
                      </div>
                      <Button
                        id={applicationIds.loginScreen.login}
                        type="primary"
                        disabled={loginDisabled}
                        onClick={(e) => handleLogin(e)}
                        className="btnTxt wai_btnTxt"
                        data-testid="loginButton-Login"
                      >
                        {translateData(translate.buttons.login)}
                      </Button>
                    </Space>
                  </Content>
                </Layout>
              </Col>
            </div>
          </div>
          <div className="wai_brand_logo">
            <img src={monarch_technology} alt="" />
          </div>
        </div>
        <div className="versionCon">
          <h1>Version</h1>
          <h3>{version}</h3>
        </div>
      </div>
      {resetPassword && <ForgotPassword />}
    </Spin>
  );
};

export default LoginWing;
