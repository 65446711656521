/* eslint-disable no-console */
import { notification } from 'antd';
import { Feature, View } from 'ol';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import XYZ from 'ol/source/XYZ';
import { Icon, Style } from 'ol/style';
import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  displayRecenterAllViewPortSetUp,
  getPathBorderColor,
  getPathColor,
  getPathFeatureStyle,
  getTractorIcon,
  jAGStartNdStop,
  pathConstants,
} from '../../constants/Common';
import { ApplicationContext } from '../../context/AppContext';

import OLMap from 'ol/Map';
import { ScaleLine } from 'ol/control';
import LineString from 'ol/geom/LineString';
import Point from 'ol/geom/Point';
import * as proj from 'ol/proj';
import ground_zero from '../../assets/images/ground_zero.svg';

import {
  getCompleteOperationPoints,
  getRoutePoints,
} from '../../constants/Api';
import { UserDetails } from '../../constants/types';
import AppLoader from '../common/AppLoader';
import { RemoteDriveAppCtx } from '../remote_drive_new/RemoteDriveContext';

let globalSelectedTractorLocation: any = null;
interface Props {
  selectedRoutine: any;
  type: number;
}

export const SimpleMap: React.FC<Props> = ({ selectedRoutine, type }) => {
  const { userDetails, APPReducer } = useContext(ApplicationContext);
  const [loader, setLoader] = useState(false);
  const [mapInitialized, setMapInitialized] = useState(false);
  const [appState] = APPReducer;
  const { groundZero } = appState;
  const { RDReducer } = useContext(RemoteDriveAppCtx);
  const [state] = RDReducer;
  const { selectedTractorLocation } = state;

  const mapElement: any = useRef();
  const mapRef = useRef<OLMap | null>(null);
  const initialLayer = useRef<VectorLayer<any>>();
  const scaleControl = () => {
    const control = new ScaleLine({
      units: 'metric',
      className: 'positionMap',
    });
    return control;
  };
  useEffect(() => {
    if (
      userDetails &&
      userDetails.organization &&
      groundZero &&
      !mapInitialized
    ) {
      initializeMap();
    }
  }, [userDetails, groundZero, mapInitialized]);

  useEffect(() => {
    globalSelectedTractorLocation = selectedTractorLocation;
  }, [selectedTractorLocation]);
  useEffect(() => {
    const init = async () => {
      const feature = globalSelectedTractorLocation;
      const pointTypeSelected = feature.get('pointTypeSelected');
      const pointTypeBearing: any = feature.get('pointTypeBearing');
      const pointTypeBattery: any = feature.get('pointTypeBattery');
      const pointTypeIni: any = feature.get('pointTypeIni');
      const tractorName: any = feature.get('name');
      const isAvailable = feature.get('isAvailable');
      const drive_action_created_at = feature.get('drive_action_created_at');
      const isFaulty = feature.get('isFaulty') ? true : false;
      const pointTypeIsSelectedForToolTip = false;
      const pointTypeIsMoving = feature.get('pointTypeIsMoving') ? true : false;
      const pointTypeLowLocalization = feature.get('pointTypeLowLocalization')
        ? true
        : false;
      const onZoom = false;
      const style = getTractorIcon(
        pointTypeSelected,
        false, // with out hover
        pointTypeBearing,
        pointTypeIni,
        false,
        pointTypeIsMoving,
        onZoom,
        pointTypeBattery,
        pointTypeLowLocalization,
        tractorName,
        pointTypeIsSelectedForToolTip,
        1000,
        isFaulty,
        isAvailable,
        drive_action_created_at,
      );
      globalSelectedTractorLocation.setStyle(style);

      initialLayer?.current
        ?.getSource()
        ?.addFeatures([globalSelectedTractorLocation]);

      const feats = initialLayer?.current?.getSource().getFeatures();
      displayRecenterAllViewPortSetUp(feats, mapRef.current);
    };
    if (
      userDetails &&
      userDetails.organization &&
      mapInitialized &&
      globalSelectedTractorLocation
    ) {
      init();
    }
  }, [userDetails, mapInitialized, selectedRoutine]);
  useEffect(() => {
    initialLayer.current
      ?.getSource()
      .getFeatures()
      .forEach((feature: any) => {
        const routine = feature.get('pointType');
        routine === pathConstants.ROUTINE &&
          initialLayer.current?.getSource().removeFeature(feature);
      });
    const init = async () => {
      await drawRoutine(userDetails, selectedRoutine, initialLayer, mapRef);
    };
    if (
      userDetails &&
      userDetails.organization &&
      selectedRoutine &&
      (selectedRoutine.id || selectedRoutine.incomplete_operation_uuid) &&
      groundZero &&
      initialLayer &&
      initialLayer.current &&
      mapRef &&
      mapRef.current &&
      mapInitialized
    ) {
      init();
    }
  }, [userDetails, selectedRoutine, groundZero, mapInitialized]);

  const drawRoutine = async (
    userDetails: UserDetails,
    selectedRoutine: any,
    layer: any,
    mapRef: any,
  ) => {
    setLoader(true);
    try {
      const routinePoints =
        type == 1
          ? await getRoutePoints(
              userDetails.organization.api_url,
              userDetails.organization.farm.id,
              selectedRoutine.id,
            )
          : await getCompleteOperationPoints(
              userDetails.organization.api_url,
              selectedRoutine.incomplete_operation_uuid,
            );
      if (routinePoints && routinePoints.length > 0) {
        const coordinates: any = [];
        routinePoints.forEach((element: any) => {
          coordinates.push(
            proj.fromLonLat([element.longitude, element.latitude]),
          );
        });
        const startPointFeature = jAGStartNdStop(coordinates[0], 'Start', 3);
        const endPointFeature = jAGStartNdStop(
          coordinates[coordinates.length - 1],
          'End',
          3,
        );
        const layerLines = new Feature({
          geometry: new LineString(coordinates),
          name: `Routine: ${selectedRoutine.name}`,
          pointType: pathConstants.ROUTINE,
          color: getPathColor(pathConstants.ROUTINE),
          borderColor: getPathBorderColor(pathConstants.ROUTINE),
        });
        layerLines.setId(999);
        layerLines.setStyle(
          getPathFeatureStyle(pathConstants.ROUTINE, getZoomValue()),
        );
        layer.current
          ?.getSource()
          .getFeatures()
          .forEach((feature: any) => {
            const routine = feature.get('pointType');
            routine === pathConstants.ROUTINE &&
              layer.current?.getSource().removeFeature(feature);
          });

        layer?.current
          ?.getSource()
          .addFeatures([layerLines, startPointFeature, endPointFeature]);

        if (mapRef && mapRef.current) {
          const feats = layer?.current?.getSource().getFeatures();
          displayRecenterAllViewPortSetUp(feats, mapRef.current);
        }
      }
    } catch (error: any) {
      notification.error({
        message: 'No Data Found',
      });
    } finally {
      setLoader(false);
    }
  };
  const getZoomValue = (): number =>
    mapRef?.current?.getView().getZoom() as number;
  const initializeMap = async () => {
    try {
      const { latitude, longitude } = groundZero;

      const baseLocation = new Feature({
        geometry: new Point(proj.fromLonLat([longitude, latitude])),
      });
      baseLocation.set('name', 'Ground zero');
      //  set id 1 for base station recenter
      baseLocation.setId(1);
      const style = new Style({
        image: new Icon({
          src: ground_zero,
          scale: 0.2,
        }),
        zIndex: 100,
      });
      baseLocation.setStyle(style);
      const initialFeaturesLayer = new VectorLayer({
        source: new VectorSource({
          features: [],
        }),
      });

      const satelliteLayer = new TileLayer({
        source: new XYZ({
          url: 'http://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}&s=Ga',
          cacheSize: 1000,
        }),
        visible: true,
      });
      // create map
      const initialMap = new OLMap({
        target: mapElement.current,
        layers: [satelliteLayer, initialFeaturesLayer],
        controls: [scaleControl()],
        view: new View({
          projection: 'EPSG:3857',
          center: proj.transform(
            [longitude, latitude],
            'EPSG:4326',
            'EPSG:3857',
          ),
          zoom: 17,
          maxZoom: 25,
        }),
      });
      // set flag for map initialization
      setMapInitialized(true);
      mapRef.current = initialMap;
      initialLayer.current = initialFeaturesLayer;
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    }
  };

  return (
    <div className="seeMapContainer">
      <div
        ref={mapElement}
        style={{
          width: '100vh',
          height: 275,
          borderRadius: 10,
          display:
            selectedRoutine?.id || selectedRoutine?.incomplete_operation_uuid
              ? 'block'
              : 'none',
        }}
        className={`posHd`}
      ></div>
      <AppLoader loader={loader} />
    </div>
  );
};
