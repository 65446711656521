/* eslint-disable no-console */
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import './css/styles.scss';
import './css/global.scss';
import { Col, Popover, Row, Space, Table, Tabs, Tooltip } from 'antd';
import user_fill from './images/user-fill-1.svg';
import info_img from './images/info-3.svg';
import trip_outline from './images/trip_outline-01.svg';
import { ColumnsType } from 'antd/lib/table';

import { ApplicationContext } from '../../context/AppContext';
import useAnalyticsDetails from './hooks/useAnalyticsDetails';
import useStore from './store/operationAnalytics';
import StackedBarChart from '../charts/StackedBarChart';
import useOperationalAnalyticDetailsStore from './store/operationAnalyticDetails';
import tractorAnalyticDetailsStore from './store/tractorAnalyticDetails';
// import DateRangePicker from './ui/DateRangePicker';
import { isEmpty, fetchName, getHoursAndMinutes } from './common';
// import LineChart from '../charts/LineChart';
import useAnalytics from './hooks/useAnalytics';
import { completedWorkInfoContent } from './constants';
import OperationalCoverageMap from './OperationalCoverageMap/OperationalCoverageMap';
import { HrsMins, WeekendsFilter } from './ui/helper';
import moment from 'moment';
import Spinner from '../common/Spinner';
import CDateRangePicker from './ui/CDateRangePicker';
import Savings from './totalSavings/Savings';

const { TabPane } = Tabs;
interface DataType {
  key: React.Key;
  name: string;
  age: number;
  address: string;
}
interface Props {
  onBack: () => void;
  operationType: string;
  selectedTab: string;
}

const OperationAnalytics: React.FC<Props> = ({
  onBack,
  operationType,
  selectedTab,
}: Props) => {
  const { setSelectedDate } = useStore();
  const [tabid, setTabid] = useState('1');
  const cardRef = useRef<HTMLDivElement | null>(null);
  const [cardSize, setCardSize] = useState({ width: 0, height: 0 });

  const {
    operationalHours,
    // coverage,
    completedWork,
    energyCostSavingsData,
    // implementTypeEfficiencyData,
    totalSavings,
    co2Savings,
    operationalHoursByOperations,
    // coverageByOperations,
    addCompletedWorkData,
  } = useOperationalAnalyticDetailsStore();

  const { selectTractor } = tractorAnalyticDetailsStore();
  const {
    includeWeekends,
    dateRange,
    toggleWeekends,
    setDateRange,
    setView,
    setFromView,
    view,
  } = useStore();

  const {
    operationsLoader,
    efficiencyChartLoader,
    savingChartLoader,
    completedWorkLoader,
    initCompletedWorkData,
    initAnalyticsData,
    initImplementTypeEfficiencyData,
    initOperationalHoursAndCoverageData,
  } = useAnalyticsDetails();

  const { dateRangeText } = useAnalytics();
  const { userDetails } = useContext(ApplicationContext);

  useEffect(() => {
    setTabid(selectedTab);
  }, [selectedTab]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        setCardSize({
          width: entry.contentRect.width,
          height: entry.contentRect.height,
        });
      }
    });

    if (cardRef.current) {
      resizeObserver.observe(cardRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    if (userDetails?.organization) {
      const [fromDate, toDate] = dateRange;
      initCompletedWorkData(
        operationType,
        fromDate?.startOf('day')?.toDate()?.getTime(),
        toDate?.endOf('day')?.toDate()?.getTime(),
      );
      initAnalyticsData(
        fromDate?.startOf('day')?.toDate()?.getTime(),
        toDate?.endOf('day')?.toDate()?.getTime(),
        operationType,
      );
      initOperationalHoursAndCoverageData(
        fromDate?.startOf('day')?.toDate()?.getTime(),
        toDate?.endOf('day')?.toDate()?.getTime(),
        operationType,
      );
      initImplementTypeEfficiencyData(
        fromDate?.startOf('day')?.toDate()?.getTime(),
        toDate?.endOf('day')?.toDate()?.getTime(),
        operationType,
      );
    }
    return () => {
      addCompletedWorkData([]);
    };
  }, [userDetails, dateRange]);

  // const dateRangeText = useMemo(() => {
  //   if (dateRange && !dateRange.length) return '';
  //   const [fromDate, toDate] = dateRange;
  //   return (
  //     <>
  //       {moment(fromDate).format('MMMM Do')} - {moment(toDate).format('Do')}
  //     </>
  //   );
  // }, [dateRange]);

  const operatorsBlock = useCallback((operators = []) => {
    return operators?.slice(0, 3).map((operator: any, key: number) => {
      const { first_name, last_name, id } = operator;
      return (
        <div key={id} className={`avatar-circles${key === 0 ? '' : key}`}>
          <div className="mm uppercase">
            {key !== 2
              ? fetchName(first_name, last_name)
              : `+${operators.length - 2}`}
          </div>
        </div>
      );
    });
  }, []);

  const columns: ColumnsType<any> = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (date) => {
        return date ? moment(date).format('MM/DD') : '';
      },
    },
    {
      title: 'Tractor',
      dataIndex: 'tractorShortPin',
      key: 'tractorShortPin',
      render: (value, record) => {
        return (
          <Tooltip title={record?.tractorPin}>
            <span>{value}</span>
          </Tooltip>
        );
      },
    },
    {
      title: 'Implement',
      dataIndex: 'implement',
      key: 'implement',
      render: (implement) => (!isEmpty(implement) ? implement : 'No Implement'),
    },
    {
      title: 'Duration',
      dataIndex: 'duration',
      key: 'duration',
      render(value) {
        const data = getHoursAndMinutes(value);
        return <HrsMins hours={data?.hours} minutes={data?.minutes} />;
      },
    },
    {
      title: 'Distance',
      dataIndex: 'distance',
      key: 'distance',
      render(value) {
        return <>{isEmpty(value) ? 0 : value} mi</>;
      },
    },
    // {
    //   title: 'Coverage',
    //   dataIndex: 'coverage',
    //   key: 'coverage',
    //   ellipsis: true,
    //   render(value) {
    //     return <>{isEmpty(value) ? 0 : value} ac</>;
    //   },
    //   // width: 70,
    // },
    {
      title: 'Operator',
      dataIndex: 'operators',
      key: 'operators',
      ellipsis: true,
      render(value) {
        return (
          <Space>{Array.isArray(value) ? operatorsBlock(value) : ''}</Space>
        );
      },
    },
  ];

  const getRow = (record: any) => {
    setFromView(view);
    selectTractor(record);
    setView('tractor');
    setSelectedDate(moment(record.date));
  };

  return (
    <>
      <section className="background-parent">
        <Row gutter={[20, 20]}>
          <Col xs={24} sm={24} md={24} lg={12} xl={10}>
            <div className="frame-parent">
              <div className="frame-wrapper">
                <div className="frame-group">
                  <div className="back-header">
                    <div className="backButtonBig" onClick={onBack}></div>
                    <div className="header">
                      <div className="mowing">{operationType}</div>
                      <div className="date-picker-section">
                        <div className="date-month-selection">
                          {dateRangeText}
                        </div>
                        <CDateRangePicker
                          handleOnChange={(dates) => {
                            setDateRange(dates);
                          }}
                          selectedDate={dateRange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="operations-graph op_detail1" ref={cardRef}>
                <section className="op_tabs_sec">
                  <div className="chip_container">
                    <div className="shape-compiler">
                      <img
                        className="implement-1-icon1"
                        alt=""
                        src={user_fill}
                      />

                      <div className="image-parser">
                        {operationalHoursByOperations?.length}
                      </div>
                    </div>
                  </div>
                  <Tabs
                    defaultActiveKey={tabid}
                    onChange={(key) => {
                      setTabid(key);
                    }}
                    activeKey={tabid}
                  >
                    <TabPane
                      tab={
                        <>
                          <div>
                            <div className="op_tab_title">
                              Operational Hours
                            </div>
                          </div>

                          <div className="wrapper">
                            <div className="div34">
                              <span className="span hrsText">
                                {operationalHours?.hours}
                              </span>
                              <span className="h">h</span>
                              <span className="span1 hrsText">
                                {' '}
                                {operationalHours?.minutes}
                              </span>
                              <span className="m1">m</span>
                            </div>
                          </div>
                        </>
                      }
                      key="operationalHours"
                    >
                      <StackedBarChart
                        className="msBarChart gph1"
                        loader={operationsLoader}
                        chartId="operationalHoursByOperators1"
                        barColors={['#67afb7', '#f1ce90', '#ec9322', '#cfcfcf']}
                        labels={['level1', 'level2', 'level4', 'level5']}
                        data={operationalHoursByOperations}
                        yTitle="Hours of Operation"
                        dataType="time"
                        tabId={tabid}
                        cardWidth={cardSize.width}
                      />
                    </TabPane>
                    {/* <TabPane
                      tab={
                        <div>
                          <div className="op_tab_title">Coverage (ac)</div>
                          <div className="container">
                            <div className="div35">{coverage}</div>
                          </div>
                        </div>
                      }
                      key="coverage"
                    >
                      <StackedBarChart
                        className="msBarChart"
                        loader={operationsLoader}
                        chartId="CoverageByOperators1"
                        barColors={['#4078C0']}
                        labels={['level1']}
                        data={coverageByOperations}
                        yTitle="Acres Covered"
                        dataType="area"
                        tabId={tabid}
                        cardWidth={cardSize.width}
                      />
                    </TabPane> */}
                  </Tabs>
                </section>
              </div>
              <div className="bottom-left-graphs operation2_left">
                <div className="header2">
                  <div className="header2">
                    <div className="title-info">
                      <div className="operations9">Completed Work</div>
                      <div className="info-bubbles">
                        {/* <img className="info-3-icon" src={info_img} /> */}
                        <Popover
                          overlayClassName="savingPopover"
                          placement="bottomLeft"
                          content={completedWorkInfoContent}
                          // trigger={['click']}
                        >
                          <img className="info-3-icon" src={info_img} />
                        </Popover>
                      </div>
                    </div>
                    <div className="shape-compiler">
                      <img
                        className="implement-1-icon1"
                        alt=""
                        src={trip_outline}
                      />

                      <div className="image-parser">
                        {completedWork?.length}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tableParent scrollBarPos1">
                  <Table
                    className="completedWorkTable"
                    columns={columns}
                    dataSource={completedWork}
                    rowClassName={() => 'operationsTableRowClassName'}
                    scroll={{ y: 150 }}
                    tableLayout="auto"
                    pagination={{ pageSize: completedWork?.length }}
                    onRow={(record) => {
                      return {
                        onClick: (event) => getRow(record), // click row
                      };
                    }}
                    loading={{
                      indicator: (
                        <div>
                          <Spinner loader={true} />
                        </div>
                      ),
                      spinning: completedWorkLoader,
                    }}
                  />
                </div>
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={14}>
            <div className="scale-scientist">
              <WeekendsFilter
                includeWeekends={includeWeekends}
                toggleWeekends={toggleWeekends}
              />
              <OperationalCoverageMap operationType={operationType} />
              <Savings
                totalSavings={totalSavings}
                co2Savings={co2Savings}
                energyCostSavingsData={energyCostSavingsData}
                savingChartLoader={savingChartLoader}
              />

              {/* <div className="container-17 efc_savings">
                <div className="bottom-left-graphs1 no-gap">
                  <div className="header2 align-top">
                    <div className="align-left graph-call-outs6">
                      <div className="operations4 op-title">Efficiency</div>
                      <div className="proximity-provider">
                        <div className="layer-llama">
                          <span className="span2">
                            {implementTypeEfficiencyData?.totalEfficiency}{' '}
                          </span>
                          <span className="achr">ac/hr</span>
                        </div>
                      </div>
                    </div>
                    <div>
                      <img src={hr2} alt="" />
                    </div>
                  </div>
                  <div className="link-aggregator savings-graph">
                    <LineChart
                      loader={efficiencyChartLoader}
                      data={implementTypeEfficiencyData?.efficiencyData}
                      chartId="efficiency"
                      smooth={true}
                      points={true}
                      coverArea={true}
                      lineColor="#546cb2"
                    />
                  </div>
                </div>

                <div className="bottom-left-graphs1 no-gap">
                  <div className="header2 align-top">
                    <div className="align-left graph-call-outs6">
                      <div className="title-info">
                        <div className="operations op-title">Savings</div>
                      </div>
                      <div className="proximity-provider">
                        <div className="layer-llama">
                          <span className="span2">${totalSavings}</span>
                        </div>
                      </div>
                    </div>
                    <div>
                      <img src={bnatterycharger} alt="" />
                    </div>
                  </div>
                  <div className="link-aggregator savings-graph">
                    <LineChart
                      loader={savingChartLoader}
                      data={energyCostSavingsData}
                      chartId="savings"
                      smooth={true}
                      points={true}
                      coverArea={true}
                      lineColor="#546cb2"
                      height={115}
                    />
                  </div>
                </div>
              </div> */}
            </div>
          </Col>
        </Row>
      </section>
    </>
  );
};

export default OperationAnalytics;
