import React from 'react';
import { Col, Layout, Row } from 'antd';
import FAQs from './FAQs';
import Topics from './Topics';

import hc_monarch_logo from '../HelpCenter/images/hc_monarch_logo.svg';
import { useHistory } from 'react-router-dom';
// import RoutesConstants from '../../routes/RoutesConstant';
import { useHelpContext } from './HelpCenterContext';
import HealthcenterSearch from './HealthcenterSearch';
import { defaultRoute, searchRoute } from './lib/constants';
import './styles.css';

const colProps = {
  xs: 24,
  sm: 24,
  md: 12,
  lg: 12,
  xl: 12,
};

const DefaultView: React.FC = () => {
  const { push } = useHistory();
  const { HCReducer } = useHelpContext();
  const [, dispatch] = HCReducer;

  const handleCategory = (catdata: any) => {
    dispatch({
      type: 'CATEGORY',
      payload: catdata,
    });
    push(defaultRoute);
  };

  return (
    <div className="common_tabs">
      <div className="help_center_container">
        <div className="hc_top_bg"></div>
        <div className="hc_logo_section">
          <div
            className="flex cursorPointer"
            onClick={() => {
              push(searchRoute);
            }}
          >
            <img src={hc_monarch_logo} alt="logo" />
            <h4 className="hc_title_top">Help Center</h4>
          </div>
        </div>
        <div className="hc_search_section">
          <Layout>
            <Row gutter={[0, 40]}>
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 20, offset: 2 }}
                lg={{ span: 12, offset: 6 }}
                xl={{ span: 12, offset: 6 }}
              >
                <Row gutter={[20, 20]}>
                  <Col span={24} className="hc_search">
                    <HealthcenterSearch flag={true} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Layout>
        </div>
        <div className="hc_searched_category">
          <Row gutter={[20, 0]}>
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 20, offset: 2 }}
              lg={{ span: 20, offset: 2 }}
              xl={{ span: 20, offset: 2 }}
            >
              <Topics colProps={colProps} handleCategory={handleCategory} />
              <h1 className="sec_border"></h1>
            </Col>
          </Row>
          <Row gutter={[20, 0]}>
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 20, offset: 2 }}
              lg={{ span: 20, offset: 2 }}
              xl={{ span: 20, offset: 2 }}
            >
              <FAQs />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default DefaultView;
