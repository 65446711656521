import React, { useContext, useEffect, useRef, useState } from 'react';
import AppLoaderV2 from '../common/AppLoaderV2';
import noImplement from '../../assets/images/no_implement.png';
import { notification } from 'antd';
import { getHitchSnapshot } from '../../constants/Api';
import { ApplicationContext } from '../../context/AppContext';
import { RemoteDriveAppCtx } from './RemoteDriveContext';
const INTERVAL = 5000;
const API_COUNT = 12;

interface Props {
  hitchImage: string;
  setHitchImage: (hitchImage: string) => void;
  driveActionUUID: string;
  cameraType?: 'pto' | 'front';
}

const HitchImage: React.FC<Props> = ({
  driveActionUUID,
  hitchImage,
  setHitchImage,
  cameraType = 'pto',
}) => {
  const { triggerSnapshot } = useContext(RemoteDriveAppCtx);
  const { userDetails } = useContext(ApplicationContext);
  const interval = useRef<any>(null);
  const counter = useRef<number>(0);
  const [imgLoader, setImgLoader] = useState<boolean>(true);

  const getHitchImage = async () => {
    try {
      setImgLoader(true);
      const { organization } = userDetails;
      const snap = await getHitchSnapshot(
        organization.api_url,
        driveActionUUID,
        // selectedTractor?.heartbeat?.drive_action_details.current_drive_action,
        organization.fleet.id,
        cameraType,
      );
      if (snap?.is_image_available || counter.current === API_COUNT) {
        clearInterval(interval.current);
        setImgLoader(false);
      }
      snap?.is_image_available &&
        snap.image_url &&
        setHitchImage(snap.image_url);
    } catch (error: any) {
      notification.error({
        message: error.response?.data?.error?.message || error.message,
      });
    }
  };
  const initialize = () => {
    counter.current = 0;
    getHitchImage();
    interval.current = setInterval(() => {
      if (counter.current === API_COUNT) {
        clearInterval(interval.current);
        return;
      }
      counter.current += 1;
      getHitchImage();
    }, INTERVAL);
  };

  useEffect(() => {
    if (driveActionUUID && driveActionUUID?.trim() !== '') initialize();
    return () => clearInterval(interval.current);
  }, [driveActionUUID]);

  return imgLoader ? (
    <div className="hitchImageContainer">
      <AppLoaderV2 loader={imgLoader} />

      <img src={noImplement} className="defaulticon impImgW" />
    </div>
  ) : (
    <div className="hitchImageContainer">
      {hitchImage && hitchImage !== '' ? (
        <img src={hitchImage} className="defaulticon impImgW" />
      ) : (
        <>
          {' '}
          <div style={{ position: 'absolute', width: '100%', top: '8px' }}>
            <div className="hitchImageBlock">
              <span className="hitchLabel"> Hitch View</span>
              <span
                data-testid="cameraViewIcon-CamerasPage1"
                className="captureIcon"
                style={{ position: 'relative', right: '0px', top: '0px' }}
                onClick={() => {
                  triggerSnapshot();
                  initialize();
                }}
              ></span>
            </div>
          </div>
          <img src={noImplement} className="defaulticon impImgW" />
        </>
      )}
    </div>
  );
};

export default HitchImage;
