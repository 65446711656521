import { Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import './style.css';

interface Props {
  timeoutMinutes: number;
  promptTimeoutMinutes: number;
  handleAction?: () => void;
}

const Timer: React.FC<Props> = ({
  timeoutMinutes,
  promptTimeoutMinutes,
  handleAction,
}) => {
  const [remaining, setRemaining] = useState(0);
  const [isVisible, setIsVisible] = useState(false);

  const onPrompt = () => {
    // Fire a Modal Prompt
    setIsVisible(true);
  };

  const onIdle = () => {
    // Close Modal Prompt
    // Do some idle action like log out your user
    if (handleAction) {
      handleAction();
    }
    window.close();
  };

  const { reset, isPrompted, getRemainingTime } = useIdleTimer({
    onPrompt,
    onIdle,
    timeout: 1000 * 60 * timeoutMinutes,
    promptTimeout: 1000 * 60 * promptTimeoutMinutes,
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      'visibilitychange',
    ],
    immediateEvents: [],
    debounce: 0,
    throttle: 0,
    eventsThrottle: 200,
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: false,
    crossTab: false,
    syncTimers: 0,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      if (isPrompted()) {
        setRemaining(Math.ceil(getRemainingTime() / 1000));
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [getRemainingTime, isPrompted]);

  return (
    <Modal
      className="remoteTimedout"
      visible={isVisible}
      footer={null}
      closable={false}
    >
      <>
        <div className="">
          <div className="timeCount">{remaining}</div>
          <h3>Are you still there?</h3>
          <p>Window will close automatically.</p>
          <div className="timedoutBtns">
            <a
              href="#"
              onClick={() => {
                reset();
                setIsVisible(false);
                setRemaining(0);
              }}
            >
              Continue
            </a>
          </div>
        </div>
      </>
    </Modal>
  );
};

export default Timer;
