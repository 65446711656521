import { Layout, Row, Input, notification, Space } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { useContext, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import translate from '../../locale/en_translate.json';
import {
  getCategoryList,
  getimplementbyId,
  getImplementsList,
  getImplementsSummary,
} from '../../constants/Api';
import { ApplicationContext } from '../../context/AppContext';
import InfiniteScrollTable from '../common/InfiniteScrollTable';
import sortDown from '../../assets/images/sort_down.svg';
import sortUp from '../../assets/images/sort_up.svg';
import { initClearSelection, initScroller } from '../../constants/Common';
import { privilagesConstants } from '../../constants/Privilages';
import { statusFilter } from '../../constants/AppData';
import { delay } from '../../constants/Common';
import AddImplement from './AddImplement';
import imp_alert_circle from '../../assets/images/missing_info.svg';
import imp_autodrive from '../../assets/images/registered_autonomy.svg';
import verified from '../../assets/images/verified.svg';
import imp_hitch from '../../assets/images/total_registered.svg';
import imp_logo from '../../assets/images/operating.svg';
import CustomSelect from '../common/CustomSelect';
import CustomSelect1 from '../common/CustomSelect1';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import ImplementDetails from './ImplementDetails';
import alert_circle_red from '../../assets/images/alert_circle_red.svg';
import usePaginate from '../../hooks/usePaginate';
import { mapImplementsData } from '../../lib/dataFormat';

interface Props {
  setAddNewImplement: (data: boolean) => void;
}
const Implements: React.FC<Props> = ({ setAddNewImplement }) => {
  const { t } = useTranslation();
  const { userDetails, privilegeChecker, APPReducer } =
    useContext(ApplicationContext);
  const [addImplementFlag, setAddImplementFlag] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize] = useState<any>(25);
  const [implementSearch, setImplementSearch] = useState<string>('');
  const [loader, setLoader] = useState<boolean>(true);
  const [totalCount, setTotalCount] = useState<any>();
  const [implementDetails, setImplementDetails] = useState<boolean>(false);
  const [detailsList, setDetailsList] = useState('');
  const [refresh, setRefresh] = useState(false);
  const [active, setActive] = useState<boolean>(true);
  const [implementType, setImpleteType] = useState<any>([]);
  const [implemeant_Type, setImplemeant] = useState<any>('');
  const [init, setInit] = useState<boolean>(false);
  const [implmentsSummary, setimplmentsSummary] = useState<any>();

  const [sortedData, setSortedData] = useState<any>({
    name: 'ascend',
  });
  const [sortedInfo, setSortedInfo] = useState<any>({
    columnKey: 'name',
    order: 'ascend',
  });
  const [keyCode, setKeyCode] = useState<boolean>(false);
  const [tstate] = APPReducer;
  const { showAutoDriveConnect } = tstate;

  const searchString = useRef<string>('');
  const { filterData, setData, activity, hasMore, checkActivity } =
    usePaginate();

  useEffect(() => {
    if (userDetails?.organization) getProfileLookps();
    loadImplements();
    ImplementsSummary();
  }, [userDetails]);

  useEffect(() => {
    if (sortedInfo && sortedInfo.order && init) loadImplements();
  }, [sortedInfo.order]);

  useEffect(() => {
    if (init) loadImplements();
  }, [pageNumber, implementSearch, active, implemeant_Type, refresh]);

  useEffect(() => {
    if (init) ImplementsSummary();
    setRefresh(false);
  }, [refresh, active]);

  const columns = [
    {
      title: (filters: any) => {
        return (
          <>
            {t(translate.implements.implementid)}
            <span className="sort-pad">
              {sortedInfo.columnKey === 'name' && (
                <img
                  className="sort-pad"
                  src={sortedData['name'] === 'ascend' ? sortUp : sortDown}
                />
              )}
            </span>
          </>
        );
      },
      defaultSortOrder: 'ascend',
      sortDirections: ['descend', 'ascend', 'descend'],
      dataIndex: 'name',
      key: 'name',
      sorter: (a: any, b: any) => {
        return a.name?.localeCompare(b.name);
      },
      sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
      ellipsis: true,
      showSorterTooltip: false,
      render: (name: string, implmentdeta: any) => {
        return (
          <span data-testid={`${name}-Implements`}>
            {implmentdeta?.is_implement_info_missing ? (
              <img
                src={alert_circle_red}
                alt=""
                style={{ marginRight: '7px' }}
              />
            ) : implmentdeta?.is_autonomy_implement ? (
              <img src={verified} alt="" style={{ marginRight: '7px' }} />
            ) : (
              ''
            )}
            {name}
          </span>
        );
      },
    },
    {
      title: `${t(translate.implements.manufacturer)}`,
      dataIndex: 'manufacturer',
      key: 'manufacturer',
      render: (manufacturer: string) => {
        return (
          <span data-testid={`${manufacturer}-Implements`}>{manufacturer}</span>
        );
      },
    },
    {
      title: `${t(translate.implements.model)}`,
      dataIndex: 'model',
      key: 'model',
      render: (model: string) => {
        return (
          <span className="description" data-testid={`${model}-Implements`}>
            {model}
          </span>
        );
      },
    },

    {
      title: `${t(translate.implements.implemeantType)}`,
      dataIndex: 'implement_type',
      key: 'implement_type',
      render: (implement_type: string) => {
        return (
          <span data-testid={`${implement_type}-Implements`}>
            {implement_type}
          </span>
        );
      },
    },
  ];

  const wrapResult = async (
    apiUrl: string,
    orgId: number,
    pageNumber = 1,
    pageSize = 25,
    search: string,
    active: boolean,
    implement_type: any,
    sortedInfo: string,
  ) => {
    const report = await getImplementsList(
      apiUrl,
      orgId,
      pageNumber,
      pageSize,
      search,
      active,
      implement_type,
      sortedInfo,
    );
    return {
      report,
      searchKey: search,
    };
  };

  const handleChange = (pagination: any, filters: any, sorter: any) => {
    setLoader(true);
    activity.current = 'sort';
    const { columnKey, order } = sorter;
    setSortedData({ ...sortedData, [columnKey]: order });
    setSortedInfo(sorter);
  };

  const loadImplements = () => {
    if (!userDetails.organization) return;
    if (checkActivity()) {
      pageNumber !== 1 ? setPageNumber(1) : getImplementsListData();
    } else if (activity.current === 'paginate' || activity.current === '') {
      getImplementsListData();
    }
  };

  const getProfileLookps = async () => {
    try {
      const cropsdata = await getCategoryList(
        userDetails.organization.api_url,
        'implement_type',
      );
      setImpleteType(cropsdata);
    } catch (error: any) {
      notification.error({
        message: error.message,
        duration: 2,
      });
    }
  };
  const ImplementsSummary = async () => {
    try {
      if (!userDetails?.organization) return;
      const summaryData = await getImplementsSummary(
        userDetails.organization.api_url,
        userDetails.organization.fleet.id,
      );
      setimplmentsSummary(summaryData);
    } catch (error: any) {
      notification.error({
        message: error.message,
        duration: 2,
      });
    }
  };
  const getImplementsListData = async () => {
    try {
      if (checkActivity()) initScroller();
      if (keyCode) {
        await delay(1000);
      }

      setLoader(true);
      const { report, searchKey } = await wrapResult(
        userDetails.organization.api_url,
        userDetails.organization.fleet.id,
        pageNumber,
        pageSize,
        implementSearch,
        active,
        implemeant_Type === 'All' ? '' : implemeant_Type,
        sortedInfo,
      );
      if (searchString.current !== searchKey) return;
      setTotalCount(report?._metadata?.total_records_count);
      const { records } = report;
      let data = Array.isArray(records) ? records : [];
      data = mapImplementsData(data, tstate);
      setData(data);
    } catch (error: any) {
      notification.error({
        message: error.message,
        duration: 2,
      });
    } finally {
      setLoader(false);
      if (!init) setInit(true);
    }
  };

  const handleSearch = (e: any) => {
    activity.current = 'search';
    const value = e.target.value.trim().regX;
    if (value.length <= 1) {
      searchString.current = '';
      setImplementSearch('');
    } else {
      searchString.current = value;
      setImplementSearch(value);
    }
  };

  const addImplement = () => {
    setAddImplementFlag(true);
  };

  const Close = () => {
    activity.current = 'refresh';
    setAddImplementFlag(false);
    setImplementDetails(false);
    initClearSelection();
  };

  const handleLoadMore = () => {
    if (pageNumber === 1 && checkActivity() && document) initScroller();

    activity.current = 'paginate';
    setPageNumber(pageNumber + 1);
  };

  const getRow = (record: any) => {
    editimplementmethod(record?.config_implements_uuid);
  };

  const editimplementmethod = async (id: string) => {
    try {
      const implemeantsData = await getimplementbyId(
        userDetails.organization.api_url,
        userDetails.organization.fleet.id,
        id,
      );
      setDetailsList(implemeantsData);
      setImplementDetails(true);
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    }
  };
  const handleKey = (e: any) => {
    setKeyCode(false);
    if (e.keyCode === 8) {
      setKeyCode(true);
    }
  };

  const addNewImplements = () => {
    setAddNewImplement(true);
  };

  return (
    <Layout>
      <div className="mainContent">
        <Content>
          <div className="tblContainer viewportContainer">
            <Row>
              {showAutoDriveConnect && (
                <div className="gridContainer">
                  <div className="gridItem">
                    <img src={imp_hitch} alt="total_unresolved" />
                    <div className="widget_content">
                      <div className="assignTxt">
                        {t(translate.implements.summary.totalRegistered)}
                      </div>
                      <div className="assignNum">
                        {implmentsSummary?.total_registered_implements_count}
                      </div>
                    </div>
                  </div>
                  <div className="gridItem">
                    <img src={imp_autodrive} alt="assignedToMe" />

                    <div className="widget_content">
                      <div className="assignTxt">
                        {t(translate.implements.summary.autonomyRegistered)}
                      </div>
                      <div className="assignNum">
                        {
                          implmentsSummary?.total_registered_autonomy_implements_count
                        }
                      </div>
                    </div>
                  </div>
                  <div className="gridItem">
                    <img src={imp_alert_circle} alt="assignedToMe" />

                    <div className="widget_content">
                      <div className="assignTxt">
                        {t(translate.implements.summary.missingInformation)}
                      </div>
                      <div className="assignNum">
                        {
                          implmentsSummary?.total_missing_autonomy_implements_count
                        }
                      </div>
                    </div>
                  </div>

                  <div className="gridItem">
                    <img src={imp_logo} alt="mtSupport" />

                    <div className="widget_content">
                      <div className="assignTxt">
                        {t(translate.implements.summary.operatedWeek)}
                      </div>
                      <div className="assignNum">
                        {implmentsSummary?.total_week_operated_implements_count}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div
                className={`common_wrapper ${
                  !showAutoDriveConnect ? 'wWidget_wrapper' : ''
                }`}
              >
                <div className="filters_card spaceBtnAlignCenter">
                  <div className="displayFlex">
                    <Space size="large">
                      <Input
                        data-testid="searchInventoryInputField-Implements"
                        addonBefore={<SearchOutlined />}
                        placeholder="Search Inventory"
                        className="common_search"
                        onChange={(e) => handleSearch(e)}
                        onKeyDown={(e) => handleKey(e)}
                        autoComplete="off"
                      />
                    </Space>

                    <CustomSelect
                      label="Status"
                      cssClass="min_width"
                      testId="statusField-Implements"
                      value={active}
                      setValue={(id: any) => {
                        activity.current = 'filter';
                        setActive(id);
                      }}
                      options={statusFilter}
                      optionKey="id"
                      optionDisplay="name"
                    ></CustomSelect>

                    <CustomSelect1
                      label="Type"
                      cssClass="min_width"
                      testId="typeField-Implements"
                      value={implemeant_Type}
                      setValue={(id: any) => {
                        activity.current = 'filter';
                        setImplemeant(id);
                      }}
                      options={implementType}
                      optionKey="name"
                      isAll={true}
                    />
                  </div>
                  {privilegeChecker(privilagesConstants.Add_Implements) && (
                    <div
                      className="addNewBtn Button2"
                      data-testid="addNewImplementsButton-Implements"
                      // onClick={addImplement}
                      onClick={addNewImplements}
                    >
                      <PlusOutlined /> {t(translate.buttons.addNew)}
                    </div>
                  )}
                </div>
                <div className="common_table implements_tbl">
                  <InfiniteScrollTable
                    columns={columns}
                    hasMore={hasMore}
                    loading={loader}
                    filterData={filterData}
                    totalcount={totalCount}
                    handleLoadMore={handleLoadMore}
                    filename="Implements"
                    onRowClick={(record) => getRow(record)}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </Row>
          </div>
        </Content>

        {addImplementFlag && (
          <AddImplement
            Close={Close}
            toggleWidget={addImplementFlag}
            setRefresh={() => setRefresh(true)}
          />
        )}
        {detailsList && implementDetails && (
          <ImplementDetails
            Close={Close}
            toggleWidget={implementDetails}
            detailsList={detailsList}
            setRefresh={() => setRefresh(true)}
            setActive={setActive}
          />
        )}
      </div>
    </Layout>
  );
};
export default Implements;
