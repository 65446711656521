import React, { useEffect, useState } from 'react';
import logoHorizontalWhite from '../assets/images/logo_horizontal_white_rgb.png';
import appleStore from '../assets/images/app_store.svg';
import googlePlay from '../assets/images/play_store.svg';

export default function MobileRoot() {
  const [params, setParams] = useState<string>('');
  useEffect(() => {
    const reqParams = window.location.href.split('?');
    reqParams && reqParams.length > 1 && setParams(reqParams[1]);
  }, []);
  return (
    <>
      <div className="loginContainer">
        <div className="loginWrapper mobileLoginWrapper">
          <div className="monarchImg">
            <img alt="monarch" src={logoHorizontalWhite} className="compLogo" />
          </div>
          <div className="loginContWrap">
            <a
              href={`${process.env.REACT_APP_SLUG}://forgot_password?${params}`}
            >
              {' '}
              <h3>Open in App</h3>
            </a>
            <div className="appIcons">
              <a href={process.env.REACT_APP_GOOGLE_PLAY_STORE}>
                <img
                  // height={100}
                  alt="Get it on Google Play"
                  src={googlePlay}
                />
              </a>
              <a href={process.env.REACT_APP_IOS_APP_STORE}>
                {' '}
                <img
                  // height={77}
                  src={appleStore}
                  alt="Download on the App Store"
                />
              </a>
            </div>
          </div>
        </div>{' '}
      </div>
    </>
  );
}
