import {
  SET_EMP_REFRESH,
  SET_TITLE,
  SET_TEAMS,
  SET_TEAMS_REFRESH,
} from './actions';

interface InitialStateType {
  title: string;
  teams: [];
  teamsRefresh: boolean;
  empRefresh: boolean;
}
interface Action {
  type: string;
  payload: any;
}

export const initialState: InitialStateType = {
  title: 'Teams',
  teams: [],
  teamsRefresh: false,
  empRefresh: false,
};

export default function reducer(state: InitialStateType, action: Action) {
  const { type, payload } = action;
  switch (type) {
    case SET_TITLE:
      return { ...state, title: 'Change Teams State' };
    case SET_TEAMS_REFRESH:
      return { ...state, teamsRefresh: !state.teamsRefresh };
    case SET_TEAMS:
      return { ...state, teams: payload };
    case SET_EMP_REFRESH:
      return { ...state, empRefresh: !state.empRefresh };
    default:
      throw new Error();
  }
}
