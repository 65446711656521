/* eslint-disable no-console */
/* eslint-disable react/jsx-key */
import { Col, Row, notification } from 'antd';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import dw_icon from '../HelpCenter/images/dw_icon.svg';
import { useHelpContext } from './HelpCenterContext';
import SubCategoryVideo from './SubCategoryVideo';
import { getSubCategories } from './services/API';
import { ApplicationContext } from '../../context/AppContext';
import '../HelpCenter/styles.css';

// interface Props {
//   selectedTrainingVideo: any;
//   trainingVideos: any[];
// }
const CategoryVideo: React.FC = () => {
  const [video, setVideo] = useState<any>(null);
  const videoRef = useRef<any>();
  const nextVideo = useRef<number>(0);
  const { HCReducer } = useHelpContext();
  const [state] = HCReducer;
  const { category, selectedVideo, videos } = state;
  const { userDetails } = useContext(ApplicationContext);
  const [subcategories, setSubCategories] = useState<any[]>([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const { organization } = userDetails;
        const { api_url } = organization;
        const result = await getSubCategories(api_url, category?.id);
        const data = Array.isArray(result?.data) ? result?.data : [];
        setSubCategories(data);
      } catch (error: any) {
        notification.error({ message: error.message });
      }
    };
    if (userDetails?.organization && category) fetchCategories();
  }, [category, userDetails]);

  useEffect(() => {
    if (selectedVideo) setVideo(selectedVideo);
  }, [selectedVideo]);

  const videoList = useMemo(
    () => videos.map(({ file_url }: any) => file_url),
    [videos],
  );

  const handleDownload = (downloadVideo: any) => {
    if (downloadVideo?.file_url) {
      const a = document.createElement('a');
      a.href = downloadVideo?.file_url;
      a.download = downloadVideo?.title;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  const getVideo = () => {
    const doc = document;
    const video: any = doc.getElementsByClassName('ifram_video_big')[0];
    return video;
  };

  const load = useCallback(() => {
    nextVideo.current += 1;
    const video: any = getVideo();
    if (nextVideo.current < videoList.length) {
      setVideo(videos[nextVideo.current]);
      video.src = videoList[nextVideo.current];
      // video.src = 'https://archive.org/download/ElephantsDream/ed_hd.ogv';
      video.load();
      initVideo();
    }
  }, [videoList]);

  const initVideo = () => {
    videoRef.current = setInterval(() => {
      const video: any = getVideo();
      if (video) {
        if (video.paused && video.readyState === 4) {
          video.play();
          clearInterval(videoRef.current);
        }
      }
    }, 1000);
  };

  useEffect(() => {
    if (document) {
      // initVideo();
      const video = getVideo();
      initVideo();
      video.addEventListener('ended', (event: any) => {
        // eslint-disable-next-line no-console
        console.log('The end of the video!');
        clearInterval(videoRef.current);
        load();
      });
    }
  }, [document]);

  useEffect(() => {
    return () => videoRef.current && clearInterval(videoRef.current);
  }, []);

  return (
    <Row gutter={[16, 16]}>
      <Col xs={24} sm={24} md={24} lg={12} xl={16}>
        <div className="catVideo">
          <div className="video-container">
            <video controls src={video?.file_url} className="ifram_video_big" />
          </div>
          <div className="video_title_download_sec">
            <h1 className="video_title_head">{video?.title}</h1>
            <div className="dflex">
              <button
                className="v_download_btn"
                onClick={() => {
                  handleDownload(video);
                }}
              >
                <img src={dw_icon} alt="" /> Download
              </button>
            </div>
          </div>
        </div>
      </Col>

      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
        <div className="video_thumb_scroll">
          <div className="tabTitle_heading">Training Videos</div>
          {subcategories &&
            subcategories.length > 0 &&
            subcategories.map((ele: any) => (
              <SubCategoryVideo
                key={ele.id}
                subCategory={ele}
                setVideo={setVideo}
                selectedVideo={video}
              />
            ))}
        </div>
      </Col>
    </Row>
  );
};

export default CategoryVideo;
