import { Avatar, Button, Modal, notification, Space } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  getAllTractorSnapShot,
  getUserProfile,
  getVideoURL,
  toGetCameraUrl,
} from '../../constants/Api';
import {
  getDateTime,
  getUserName,
  sortCameraViews,
  tractorCamerasLabels,
} from '../../constants/Common';
import { ApplicationContext } from '../../context/AppContext';
import loading_logo from '../../assets/images/loading_logo.gif';
import { SET_LAST_TAB_INDEX } from '../../context/actions';
import RoutesConstants from '../../routes/RoutesConstant';
import moment from 'moment';
import AppLoader from '../common/AppLoader';

interface Props {
  data: any;
  close?: () => void;
}

const NotificationView: React.FC<Props> = ({ data, close }) => {
  const { push } = useHistory();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [foundCamera, setFoundCamera] = useState<any>();
  const [operatorName, setOperatorName] = useState<string>('');
  const [snapshot, setSnapshot] = useState<any | null>(null);
  const [video, setVideo] = useState<string>('');
  const [showVideo, setShowVideo] = useState<boolean>(false);
  const [notificationData, setNotificationData] = useState<any>();
  const [videoError, setVideoError] = useState<boolean>(false);
  const [imageError, setImageError] = useState<boolean>(false);
  const [resetFlag, setResetFlag] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const { userDetails, APPReducer } = useContext(ApplicationContext);
  const [, dispatch] = APPReducer;

  useEffect(() => {
    if (data && Object.keys(data)) {
      handleClickNewNotification(data);
    }
    return () => {
      setShowVideo(false);
      setVideo('');
      setNotificationData(null);
      setFoundCamera(null);
      setVideoError(false);
      setImageError(false);
    };
  }, [data]);

  const handleClickNewNotification = (data: any) => {
    if (
      Object.keys(data).includes('guard_rail_type') &&
      ['human_protection', 'path_protection'].includes(data.guard_rail_type)
    ) {
      setNotificationData({ ...data, message: data?.body });
    } else if (data?.event_category === 'guard_rails') {
      setNotificationData({
        ...data,
        tractor_id: data.tractor_id,
        guard_rail_uuid: data.ref_uuid,
        camera: data.camera || 'front',
        message: data.message,
      });
    }
  };

  useEffect(() => {
    if (userDetails && userDetails.organization && notificationData) {
      getCameraUrl();
    }
  }, [notificationData]);

  useEffect(() => {
    if (foundCamera) getUserData();
  }, [foundCamera]);

  useEffect(() => {
    if (
      userDetails &&
      userDetails.organization &&
      notificationData &&
      resetFlag
    ) {
      setShowVideo(false);
      setResetFlag(false);
      setVideoError(false);
      setImageError(false);
      getCameraUrl();
    }
  }, [resetFlag]);

  useEffect(() => {
    if (foundCamera) {
      getVideoData();
      getSnapshotData();
    }
  }, [foundCamera]);

  const getSnapshotData = async () => {
    const { organization } = userDetails;
    const { created_date_time, status, created_at, tractor_id } = foundCamera;
    const { records: snapshots } = await getAllTractorSnapShot(
      organization.api_url,
      organization.farm.id,
      tractor_id ? tractor_id : '',
      1,
      25,
      status ? status : '',
      created_at ? created_at : '',
      String(
        moment(created_date_time).subtract({ minutes: 1 }).toDate().getTime(),
      ),
      String(moment(created_date_time).add({ minutes: 1 }).toDate().getTime()),
    );
    const [snapshot] = snapshots.filter(
      (item: any) => item.transaction_id === notificationData.guard_rail_uuid,
    );
    if (snapshot) setSnapshot(snapshot);
  };

  const getUserData = async () => {
    const user = await getUserProfile(
      userDetails.organization.api_url,
      foundCamera.operator_id,
    );
    const username = getUserName(user);
    setOperatorName(username);
  };

  const getVideoData = async () => {
    try {
      const { tractor, event_date_time: updatedTime } = notificationData;
      let response = await getVideoURL(
        userDetails.organization.api_url,
        userDetails.organization.farm.id,
        tractor?.id,
        updatedTime,
      );
      response = sortCameraViews(response, 'direction', 'videos');
      const [tvideo] = response.filter(
        (item: any) =>
          item.direction === foundCamera?.camera?.replace('_', '-'),
      );
      if (tvideo) {
        setVideoError(false);
        setVideo(tvideo.video_url);
      } else {
        setVideoError(true);
      }
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    }
  };

  const getCameraUrl = async () => {
    try {
      setLoader(true);
      const { guard_rail_uuid: transaction_id } = notificationData;
      const response = await toGetCameraUrl(
        userDetails.organization.api_url,
        userDetails.organization.id,
        transaction_id,
      );
      setFoundCamera(response);
      if (response && response.camera_image_url) {
        setImageError(false);
      } else {
        setImageError(true);
      }
      setIsModalVisible(true);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    } finally {
      setLoader(false);
    }
  };

  const checkAnalytics = (snap: any) =>
    snap?.status === 'Completed' &&
    !['None', 'null'].includes(snap.drive_action_uuid);

  const titleHTML = () => {
    return (
      <div style={{ position: 'relative' }}>
        <div>
          {notificationData?.tractor?.name} |{' '}
          {getDateTime(notificationData?.event_date_time)}
        </div>
        <div className="notification-header-right">
          <div className="">
            <Button onClick={() => setShowVideo(!showVideo)}>
              {!showVideo ? 'Watch Video' : 'Stop Video'}
            </Button>
          </div>
          <div className="">
            <Button onClick={() => setResetFlag(true)}>Refresh</Button>
          </div>
          {snapshot && checkAnalytics(snapshot) && (
            <div className="notification-analytics">
              <Button
                onClick={() => {
                  dispatch({
                    type: SET_LAST_TAB_INDEX,
                    payload: {
                      catlog: 'Analytics',
                      tab: 'Operations',
                    },
                  });
                  push(RoutesConstants.Analytic, {
                    action: 'Operations',
                    drive_action: snapshot,
                  });
                }}
              >
                SEE ANALYTICS
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  };
  return (
    <Modal
      className="modal-theme-1"
      style={{ top: 20, zIndex: 9999 }}
      width={1000}
      title={titleHTML()}
      visible={isModalVisible}
      onCancel={() => {
        setIsModalVisible(false);
        close && close();
      }}
      footer={false}
    >
      <div style={{ position: 'relative' }}>
        <div className="ant-modal-title mb10">
          {foundCamera?.camera &&
            `${tractorCamerasLabels[foundCamera?.camera]} Camera`}
        </div>
        <div className="notification-right-label" style={{ top: 0 }}>
          <Space>
            <div>{notificationData?.message} </div>
            <div> | </div>
            <div>{operatorName}</div>
          </Space>
        </div>
      </div>
      {notificationData?.guard_rail_image_url || foundCamera ? (
        <>
          {showVideo ? (
            <>
              {!videoError ? (
                <video
                  src={video}
                  style={{ width: '100%', height: '100%' }}
                  playsInline
                  controls
                  // autoPlay
                  onError={() => {
                    // notification.error({
                    //   message: 'Failed to load video',
                    // });
                    setVideoError(true);
                  }}
                />
              ) : (
                <div className="progressText">
                  Video upload in progress, please try later
                </div>
              )}
            </>
          ) : (
            <>
              {!imageError ? (
                <img
                  src={
                    notificationData?.guard_rail_image_url ||
                    foundCamera.camera_image_url
                  }
                  style={{ width: '100%' }}
                />
              ) : (
                <div className="progressText">
                  {' '}
                  <div>Image upload in progress, please try later</div>
                </div>
              )}
            </>
          )}
        </>
      ) : (
        <p className="progressText">
          {' '}
          Preview is not available. <br /> Can you please try again later
        </p>
      )}
      <AppLoader loader={loader} />
    </Modal>
  );
};

export default NotificationView;
