import React, { useContext, useEffect, useState } from 'react';
import { Button, Checkbox, Modal, Space, Card, Tabs, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import './style.css';
import Interweave from 'interweave';
import verM_logo from '../../assets/images/verM_logo.svg';
import { getVersionList } from '../../constants/Api';
import { ApplicationContext } from '../../context/AppContext';
import { updateVersionData } from '../../constants/types';
import moment from 'moment';
import 'moment-duration-format';
import translate from '../../locale/en_translate.json';

const { TabPane } = Tabs;
type PropTypes = {
  isHistoryModalVisible: boolean;
  setIsHistoryModalVisible: (state: boolean) => void;
  data: any;
};
export const VersionHistoryModal: React.FC<PropTypes> = ({
  isHistoryModalVisible,
  setIsHistoryModalVisible,
  data,
}) => {
  const { userDetails } = useContext(ApplicationContext);
  const { t } = useTranslation();
  const [isReset, setIsReset] = useState(false);
  const [updatedVersion, setUpdatedVersion] = useState<any[]>([]);
  const [tabid, setTabid] = useState<any>('1');
  const onChange = (e: any) => {
    // eslint-disable-next-line no-console
    console.log(`checked = ${e.target.checked}`);
  };

  const handleOk = () => {
    setIsHistoryModalVisible(false);
  };

  const handleCancel = () => {
    setIsHistoryModalVisible(false);
  };
  useEffect(() => {
    if (userDetails && userDetails.organization) {
      if (tabid === '1') {
        getVersionLists('software');
      } else if (tabid === '2') {
        getVersionLists('firmware');
      } else if (tabid === '3') {
        getVersionLists('bs_software');
      }
    }
  }, [userDetails, tabid]);

  const getVersionLists = async (version: any) => {
    setUpdatedVersion([]);
    updatedVersion.length = 0;
    try {
      const records = await getVersionList(
        userDetails.organization.api_url,
        userDetails.organization.id,
        version,
      );

      const data = records.map((record: updateVersionData) => {
        return {
          versionid: record.software_version_id,
          newVersion: record.software_version,
          releaseNotes: record.release_notes,
          release_date:
            moment().diff(moment(record.release_date), 'hours') < 24
              ? 'today'
              : moment().diff(moment(record.release_date), 'hours') >= 24 &&
                moment().diff(moment(record.release_date), 'hours') <= 48
              ? 'yesterday'
              : moment(record.release_date).endOf('day').fromNow(),
        };
      });

      setUpdatedVersion(data);
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    }
  };

  return (
    <div>
      <Modal
        visible={isHistoryModalVisible}
        onCancel={handleCancel}
        footer={false}
        className="versionHistPopup"
        width={768}
      >
        {/* {!isReset ? ( */}
        <>
          <Card className="softwareCard">
            <Tabs
              activeKey={tabid}
              onChange={(key) => {
                setTabid(key);
              }}
              className="editTabs softwareTabs"
            >
              <TabPane tab="Software" key="1">
                <div className="verHistorySec">
                  <h3 className="verHistTxt posRel headline2 fSbd">
                    {t(translate.updates.versionHistory)}
                    <img
                      src={verM_logo}
                      alt="mlogo"
                      className="mLogo"
                      height="33"
                    />
                  </h3>
                  <div className="version-block">
                    <ul style={{ listStyle: 'none' }}>
                      {updatedVersion.map((item, key) => {
                        return (
                          <li className="version-item" key={key}>
                            <Space>
                              <div>
                                <div className="verDtl">
                                  <span className="verNo">
                                    {item?.newVersion}
                                  </span>
                                  <span className="verDate">
                                    {item?.release_date}
                                  </span>
                                </div>
                                <div className="verDec body1">
                                  <Interweave content={item?.releaseNotes} />
                                </div>
                              </div>
                              {/* <div className="reset-block">
                                {key === 2 && (
                                  <Button
                                    onClick={() => setIsReset(!isReset)}
                                    className="verResetBtn"
                                  >
                                    Reset
                                  </Button>
                                )}
                              </div> */}
                            </Space>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
                <div className="verResetSec" style={{ display: 'none' }}>
                  <>
                    <h3 className="verHistTxt mb12 posRel">
                      {t(translate.updates.resetToFactory)}
                      <img
                        src={verM_logo}
                        alt="mlogo"
                        className="mLogo"
                        height="33"
                      />
                    </h3>
                    <div className="verDtl mb32">
                      <span className="verNo">
                        {data?.software_version || 'macOS 12.0.1'}
                      </span>
                      <span className="verDate">
                        {' '}
                        {t(translate.updates.twoWeeks)}
                      </span>
                    </div>
                    <div className="verDec w100Per mb24">
                      {t(translate.updates.updateMsg)}
                    </div>
                    <div className="releaseNotes mb24">
                      <div className="verNoteHead">
                        {' '}
                        {t(translate.updates.releaseNotes)}:
                      </div>
                      <div className="verDec2">
                        {t(translate.updates.releaseInfo)}
                      </div>
                    </div>

                    <div>
                      <ul className="verTractorList">
                        <li className="mb24">
                          <Checkbox checked onChange={onChange}>
                            {t(translate.updates.ps1)}
                          </Checkbox>
                        </li>
                        <li className="mb24">
                          <Checkbox checked onChange={onChange}>
                            {t(translate.updates.ps2)}
                          </Checkbox>
                        </li>
                        <li className="mb24">
                          <Checkbox checked onChange={onChange}>
                            {t(translate.updates.ps3)}
                          </Checkbox>
                        </li>
                        <li className="mb24">
                          <Checkbox checked onChange={onChange}>
                            {t(translate.updates.ps4)}
                          </Checkbox>
                        </li>
                      </ul>
                      <div className="verBtnSec">
                        <Space>
                          <Button
                            onClick={() => setIsReset(false)}
                            className="verCancelBtn"
                          >
                            {t(translate.buttons.cancel)}
                          </Button>
                          <Button
                            type="primary"
                            onClick={() => setIsReset(false)}
                            className="verResetBtn"
                          >
                            {t(translate.buttons.reset)}
                          </Button>
                        </Space>
                      </div>
                    </div>
                  </>
                </div>
              </TabPane>
              <TabPane tab="Firmware" key="2">
                <div className="verHistorySec">
                  <h3 className="verHistTxt posRel headline2 fSbd">
                    {t(translate.updates.versionHistory)}
                    <img
                      src={verM_logo}
                      alt="mlogo"
                      className="mLogo"
                      height="33"
                    />
                  </h3>
                  <div className="version-block">
                    <ul style={{ listStyle: 'none' }}>
                      {updatedVersion.map((item, key) => {
                        return (
                          <li className="version-item" key={key}>
                            <Space>
                              <div>
                                <div className="verDtl">
                                  <span className="verNo">
                                    {item?.newVersion}
                                  </span>
                                  <span className="verDate">
                                    {item?.release_date}
                                  </span>
                                </div>
                                <div className="verDec body1">
                                  <Interweave content={item?.releaseNotes} />
                                </div>
                              </div>
                              {/* <div className="reset-block">
                                {key === 2 && (
                                  <Button
                                    onClick={() => setIsReset(!isReset)}
                                    className="verResetBtn"
                                  >
                                    Reset
                                  </Button>
                                )}
                              </div> */}
                            </Space>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </TabPane>
              <TabPane tab="Basestation" key="3">
                <div className="verHistorySec">
                  <h3 className="verHistTxt posRel headline2 fSbd">
                    {t(translate.updates.versionHistory)}
                    <img
                      src={verM_logo}
                      alt="mlogo"
                      className="mLogo"
                      height="33"
                    />
                  </h3>
                  <div className="version-block">
                    <ul style={{ listStyle: 'none' }}>
                      {updatedVersion.map((item, key) => {
                        return (
                          <li className="version-item" key={key}>
                            <Space>
                              <div>
                                <div className="verDtl">
                                  <span className="verNo">
                                    {item?.newVersion}
                                  </span>
                                  <span className="verDate">
                                    {item?.release_date}
                                  </span>
                                </div>
                                <div className="verDec body1">
                                  <Interweave content={item?.releaseNotes} />
                                </div>
                              </div>
                            </Space>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </TabPane>
            </Tabs>
          </Card>
        </>
      </Modal>
    </div>
  );
};
