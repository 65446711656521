import React, { useContext, useEffect, useRef, useState } from 'react';
import { getBaseStations } from '../API';
import { ApplicationContext } from '../../../context/AppContext';
import { Input, notification, Row } from 'antd';
import Layout from 'antd/lib/layout/layout';
import translate from '../../../locale/en_translate.json';
import { useTranslation } from 'react-i18next';
import InfiniteScrollTable from '../../common/InfiniteScrollTable';
import usePaginate from '../../../hooks/usePaginate';
import useColumns from '../../../hooks/useColumns';
import { initScroller } from '../../../constants/Common';
import constants from '../../../constants/constant';
import { mapNexusBasestationData } from '../../../lib/dataFormat';
import copyIcon from '../../../assets/images/copy_icon.png';
import { SearchOutlined } from '@ant-design/icons';
import TableExt from '../../widget/TableExt';
import ViewBaseStationPassword from './ViewBaseStationPassword';
import EditBasestation from './EditBasestation';

let columnsData: any[] = [];
const title = 'Basestations';
const { DOWNLOAD_SIZE } = constants;

const Basestation = () => {
  const { userDetails } = useContext(ApplicationContext);
  const { t } = useTranslation();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize] = useState<any>(25);
  const [loader, setLoader] = useState<boolean>(false);
  const tableRef = useRef<any>(null);
  const [csvData, setCSVData] = useState<any[]>([]);
  const [downloadData, setDownloadData] = useState({
    isDownloaded: false,
    percent: 0,
    status: '',
  });
  const [search, setSearch] = useState('');
  const searchString = useRef('');
  const [total, setTotal] = useState(0);
  const init = useRef(false);
  const [toggleColumnsStatus, setToggleColumnsStatus] =
    useState<boolean>(false);

  const { filterData, setData, activity, hasMore, checkActivity } =
    usePaginate();
  const { headers, columns, setColumns, setColumnsData } =
    useColumns('Basestation');
  const [selectedBasestation, setSelectedBasestation] = useState<any>(null);

  const handleLoadMore = () => {
    if (pageNumber === 1 && checkActivity() && document) initScroller();

    activity.current = 'paginate';
    setPageNumber(pageNumber + 1);
  };

  const handleSearch = (e: any) => {
    activity.current = 'search';
    const value = e.target.value.trim();
    if (value.length <= 1) {
      searchString.current = '';
      setSearch('');
    } else {
      searchString.current = value;
      setSearch(value);
    }
  };

  const wrapResult = async (
    pageNumber: number,
    pageSize: number,
    search: string,
  ) => {
    const result = await getBaseStations(pageNumber, pageSize, search);
    return {
      result,
      searchKey: search,
    };
  };

  const getBasestationList = async () => {
    try {
      if (checkActivity()) initScroller();
      setLoader(true);
      const { result, searchKey } = await wrapResult(
        pageNumber,
        pageSize,
        search,
      );
      if (searchString.current !== searchKey) return;
      setTotal(result._metadata.total_records_count);
      const { records } = result;
      const data = mapNexusBasestationData(records);
      setData(data);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    } finally {
      if (!init.current) init.current = true;
      setLoader(false);
    }
  };

  const loadData = () => {
    if (userDetails && !userDetails.organization) return;
    if (checkActivity()) {
      pageNumber !== 1 ? setPageNumber(1) : getBasestationList();
    } else if (activity.current === 'paginate' || activity.current === '') {
      getBasestationList();
    }
  };

  useEffect(() => {
    columnsData = [
      {
        title: `${t(translate.tractors.name)}`,
        dataIndex: 'name',
        render: (baseStationName: string) => (
          <span data-testid={`${baseStationName}-BaseStation`}>
            {baseStationName}
          </span>
        ),
        key: 'name',
        width: '160px',
      },
      {
        title: `${t(translate.baseStation.model)}`,
        dataIndex: 'model',
        key: 'model',
        width: '120px',
      },
      {
        title: `${t(translate.tractors.organization)}`,
        dataIndex: 'Organization',
        key: 'Organization',
        width: '160px',
        ellipsis: true,
      },
      {
        title: `${t(translate.tractors.pin)}`,
        dataIndex: 'bsin',
        key: 'bsin',
        width: '150px',
      },

      {
        title: `${t(translate.tractors.radio_address)}`,
        dataIndex: 'radio_address',
        key: 'radio_address',
        width: '150px',
      },
      {
        title: `${t(translate.tractors.radio_channel_number)}`,
        dataIndex: 'radio_channel_number',
        key: 'radio_channel_number',
        width: '200px',
      },
      {
        title: `${t(translate.tractors.radio_net_id)}`,
        dataIndex: 'radio_net_id',
        key: 'radio_net_id',
        width: '150px',
      },
      {
        title: `${t(translate.tractors.mountPoint)}`,
        dataIndex: 'username',
        key: 'username',
        width: '220px',
        render: (username: string) => (
          <span className="hoverWrapper installationkey">
            <span>{username}</span>
            <img
              src={copyIcon}
              alt="Edit"
              height="32"
              width="32"
              className="marginLeftIcon hoverShow1 on-hover-hand-change"
              onClick={() => {
                navigator.clipboard.writeText(username);
                notification.info({ message: 'Copied' });
              }}
            />
          </span>
        ),
      },
      {
        title: `${t(translate.tractors.mountPointpassword)}`,
        dataIndex: 'mount_point_password',
        key: 'mount_point_password',
        width: '210px',
        render: (mount_point_password: string, record: any) => (
          <>
            <ViewBaseStationPassword
              password={mount_point_password}
              key={record.id}
            />
          </>
        ),
      },
      {
        title: `${t(translate.tractors.installation_key)}`,
        dataIndex: 'installation_key',
        key: 'installation_key',
        width: '210px',
        render: (installation_key: string) => (
          <span className="hoverWrapper installationkey">
            <span data-testid={`${installation_key}-InstallationKey`}>
              {installation_key}
            </span>
            <img
              src={copyIcon}
              alt="Edit"
              height="32"
              width="32"
              className="marginLeftIcon hoverShow1 on-hover-hand-change"
              onClick={() => {
                navigator.clipboard.writeText(installation_key);
                notification.info({ message: 'Copied' });
              }}
            />
          </span>
        ),
      },
      {
        title: `${t(translate.tractors.dateAdded)}`,
        dataIndex: 'startTime',
        key: 'startTime',
        width: '180px',
      },
    ];

    setColumnsData(columnsData);
  }, []);

  const handleRefresh = () => {
    activity.current = 'refresh';
    loadData();
  };

  const handleDownload = async () => {
    try {
      if (downloadData.status === 'start') return;
      setDownloadData({
        ...downloadData,
        status: 'start',
        percent: 10,
      });
      let data: any[] = [];
      const pages = Math.ceil(total / DOWNLOAD_SIZE);
      for (const page of Array.from({ length: pages }, (_, i) => i + 1)) {
        const { result } = await wrapResult(page, DOWNLOAD_SIZE, search);
        const { records } = result;
        let tdata = Array.isArray(records) ? records : [];
        tdata = mapNexusBasestationData(tdata);
        data = [...data, ...tdata];
        setDownloadData({
          ...downloadData,
          status: 'start',
          percent: Math.round((data.length / total) * 100),
        });
      }

      setCSVData([...data]);
      setDownloadData({
        ...downloadData,
        percent: 100,
        status: 'success',
      });
    } catch (error: any) {
      setDownloadData({
        ...downloadData,
        status: 'exception',
      });
      notification.error({ message: error.message });
    }
  };

  useEffect(() => {
    loadData();
  }, [userDetails, pageNumber, pageSize, search]);

  const closeModal = (state?: boolean) => {
    if (state) loadData();
    setSelectedBasestation(null);
  };

  return (
    <Layout onClick={() => setToggleColumnsStatus(!toggleColumnsStatus)}>
      <div className="mainContent">
        <div className="tblContainer viewportContainer">
          <Row>
            <div className="common_wrapper tabFilterWidget_wrapper">
              <div className="filters_card personnelCard">
                <div>
                  <Input
                    data-testid="searchDirectoryInputField-Employees"
                    addonBefore={<SearchOutlined />}
                    placeholder="Search Directory"
                    className="common_search"
                    onChange={(e) => handleSearch(e)}
                    autoComplete="off"
                  />
                </div>
                <TableExt
                  handleRefresh={handleRefresh}
                  handleDownload={handleDownload}
                  downloadData={downloadData}
                  csvHeaders={headers}
                  csvData={csvData}
                  csvFilename={title}
                  sortColumns={['created_date_time', 'name']}
                  tableRef={tableRef}
                  tableName="Basestation"
                  handleCallback={(args: any[]) => {
                    setColumns(args);
                  }}
                  columnsData={columnsData}
                  toggleColumnsStatus={toggleColumnsStatus}
                />
              </div>

              <div className="common_table">
                <InfiniteScrollTable
                  columns={columns}
                  loading={loader}
                  hasMore={hasMore}
                  filterData={filterData}
                  totalcount={total}
                  handleLoadMore={handleLoadMore}
                  filename="Basestations"
                  onRowClick={(record) => setSelectedBasestation(record)}
                />
              </div>
            </div>
          </Row>
        </div>
      </div>
      {selectedBasestation && (
        <EditBasestation
          toggleWidget={!!selectedBasestation}
          details={selectedBasestation}
          onClose={closeModal}
        />
      )}
    </Layout>
  );
};

export default Basestation;
