import React from 'react';

interface Props {
  title: string;
  content: any;
}

const ViewItem: React.FC<Props> = ({ title = '', content = <></> }) => (
  <div className="proRowType rowGapBtm3">
    <h5 className="subFlex alignCenter">
      <>
        <span className="headline4 subTitles">{title}</span>
        <span className="width50 body2">{content}</span>
      </>
    </h5>
  </div>
);

export default ViewItem;
