import React from 'react';
import { Button, Spin } from 'antd';
import { CloudDownloadOutlined, CloudUploadOutlined } from '@ant-design/icons';
import approveBtn from '../../../assets/images/approveBtn.svg';
import fail_cross from '../../../assets/images/fail_cross.svg';

interface Props {
  selectedTractor: any;
  tractorReport: TractorReport;
  reTestSpeed: () => void;
  speed: any;
  showServiceCheckFailure: boolean;
}

export interface TractorReport {
  connection_report: {
    connection: string;
    msg: string;
    status_code: number;
    drive_action_details: any;
  };
  mt_fleet_software_health_report: {
    msg: string;
    status_code: number;
    build_to_be_published: {
      software: [];
      firmware: [];
    };
  };
  ovpn_access_group: {
    source_org: string;
    target_org: string;
  };
}

const Overview: React.FC<Props> = ({
  selectedTractor,
  tractorReport,
  reTestSpeed,
  speed,
  showServiceCheckFailure,
}) => {
  const getVersionCheckText = (data: TractorReport) => {
    if (data.mt_fleet_software_health_report?.status_code === 2001) {
      return (
        <span style={{ color: '#788143' }}>
          Version Check Passed{' '}
          <img
            height="16px"
            src={approveBtn}
            alt=""
            style={{ marginTop: '-6px' }}
          />
        </span>
      );
    }

    return (
      <>
        <span style={{ color: 'red' }}>
          Version Check Failed, following Software / Firmware Versions needs to
          be published in target Organization.{' '}
          <img
            height="18px"
            src={fail_cross}
            alt=""
            style={{ marginTop: '-6px' }}
          />
        </span>
        {data.mt_fleet_software_health_report?.build_to_be_published.software &&
          data.mt_fleet_software_health_report?.build_to_be_published.software
            .length > 0 && (
            <div style={{ marginLeft: '20px' }}>
              Software:{' '}
              <ol>
                {data.mt_fleet_software_health_report?.build_to_be_published.software.map(
                  (version: string) => (
                    <ul key={version}>{version}</ul>
                  ),
                )}
              </ol>
            </div>
          )}
        {data.mt_fleet_software_health_report?.build_to_be_published.firmware &&
          data.mt_fleet_software_health_report?.build_to_be_published.firmware
            .length > 0 && (
            <div style={{ marginLeft: '20px' }}>
              Firmware:{' '}
              <ol>
                {data.mt_fleet_software_health_report?.build_to_be_published.firmware.map(
                  (version: string) => (
                    <ul key={version}>{version}</ul>
                  ),
                )}
              </ol>
            </div>
          )}
      </>
    );
  };

  const getOperatorName = () =>
    `${tractorReport.connection_report?.drive_action_details?.operator_firstname} ${tractorReport.connection_report?.drive_action_details?.operator_lastname}`;

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const getConnectionText = (tractorReport: TractorReport) => {
    if (tractorReport) {
      if (
        tractorReport &&
        tractorReport.connection_report?.status_code === 1006
      ) {
        return (
          <span style={{ color: '#788143' }}>
            Tractor Online{' '}
            <img
              height="16px"
              src={tractorReport?.connection_report?.msg && approveBtn}
              alt=""
              style={{ marginTop: '-6px' }}
            />
          </span>
        );
      }

      return (
        <span style={{ color: 'red' }}>
          {tractorReport.connection_report?.status_code === 1007 ? (
            <>{`Tractor is locked by ${getOperatorName()}, Cannot migrate.`}</>
          ) : (
            <>{tractorReport?.connection_report.msg}</>
          )}{' '}
          <img
            height="18px"
            src={tractorReport?.connection_report?.msg && fail_cross}
            alt=""
            style={{ marginTop: '-6px' }}
          />
        </span>
      );
    }
    return <></>;
  };

  return (
    <>
      {selectedTractor.migration?.status !== 'Inprogress' && (
        <ul>
          <li>Tractor Name: {selectedTractor && selectedTractor?.name}</li>
          <li>
            Source Org: {selectedTractor && selectedTractor?.organization?.name}
          </li>
          <li>Target Org: {selectedTractor?.target_organization_name}</li>
          <li>Tractor Status: {getConnectionText(tractorReport)}</li>
          <li>
            Version Check: &nbsp;
            {tractorReport ? getVersionCheckText(tractorReport) : <Spin />}
          </li>
          {showServiceCheckFailure && (
            <>
              <li>
                Service Check: &nbsp;{' '}
                <span style={{ color: 'red' }}>
                  Failed.{' '}
                  <img
                    height="18px"
                    src={fail_cross}
                    alt=""
                    style={{ marginTop: '-6px' }}
                  />
                </span>
              </li>
              <span
                style={{
                  color: 'black',
                  fontWeight: 'bold',
                  paddingLeft: '120px',
                }}
              >
                Reboot the Tractor and Try again{' '}
              </span>
            </>
          )}
          {speed && (
            <li>
              <CloudUploadOutlined style={{ color: 'green' }} />{' '}
              {speed?.network_health_report?.upload_speed
                ? Number(speed?.network_health_report?.upload_speed).toFixed(1)
                : 0}{' '}
              Mbps &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <CloudDownloadOutlined style={{ color: 'red' }} />{' '}
              {speed?.network_health_report?.download_speed
                ? Number(speed?.network_health_report?.download_speed).toFixed(
                    1,
                  )
                : 0}{' '}
              Mbps
            </li>
          )}
        </ul>
      )}
      <Button
        type="primary"
        onClick={() => reTestSpeed()}
        loading={speed === undefined}
      >
        Check Network Speed
      </Button>
    </>
  );
};

export default Overview;
