/* eslint-disable no-console */
import { Checkbox, Col, Modal, Radio, Row, Space, notification } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import {
  downloadMapMakerFeature,
  getSurveyVineRowsData,
  getSurveysBasedOnIds,
} from '../../constants/Api';
import { ApplicationContext } from '../../context/AppContext';
import translate from '../../locale/en_translate.json';

import { useTranslation } from 'react-i18next';
import AppLoader from '../common/AppLoader';

interface Props {
  showModal: boolean;
  handleCancel: () => void;
}

export const FileDownload: React.FC<Props> = ({
  showModal,
  handleCancel,
}: Props) => {
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const [selectedFeatureItem, setSelectedFeatureItem] = useState<any>();
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [loader, setLoader] = useState<boolean>(false);
  const [surveyVineRows, setSurveyVineRows] = useState<any>([]);
  const [surveyIds, setSurveyIds] = useState<number[]>([]);
  useEffect(() => {
    selectedFeatureItem &&
      selectedFeatureItem === 'survey_vinerows' &&
      getSurveyVineRowsListData();
    if (selectedFeatureItem) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [userDetails, selectedFeatureItem]);

  const CsvFileDownload = async () => {
    try {
      setLoader(true);
      if (selectedFeatureItem === 'survey_vinerows') {
        const records: any = await getSurveysBasedOnIds(
          userDetails.organization.api_url,
          userDetails.organization.id,
          userDetails.organization.farm.id,
          surveyIds,
        );
        const fileName = selectedFeatureItem || 'catrography';
        await saveAs(records.data, `${fileName}.csv`);
      } else {
        const records: any = await downloadMapMakerFeature(
          userDetails.organization.api_url,
          userDetails.organization.id,
          userDetails.organization.farm.id,
          selectedFeatureItem,
        );

        const fileName = selectedFeatureItem || 'catrography';
        await saveAs(records.data, `${fileName}.csv`);
      }
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    } finally {
      setLoader(false);
    }
    handleCancel();
  };
  // get the survey vine rows data
  const getSurveyVineRowsListData = async () => {
    try {
      setLoader(true);
      const { organization } = userDetails;
      const response = await getSurveyVineRowsData(
        organization.api_url,
        organization.farm.id,
      );
      const { records } = response;
      setSurveyVineRows(records);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    } finally {
      setLoader(false);
    }
  };
  const onChange = (e: any) => {
    if (e && e.length === 6) {
      const error = 'You can select maximum 4 items';
      notification.error({
        message: error,
      });
      e.pop();
    }
    setSurveyIds(e);
  };
  return (
    <Modal
      className="commonPopup addPolygon popupStyles"
      title="Download File"
      centered
      width={'50vw'}
      visible={showModal}
      onCancel={handleCancel}
      okText="Download"
      onOk={CsvFileDownload}
      okButtonProps={{
        className: 'btnSave Button',
        disabled: isDisabled,
      }}
      data-testid="downloadButton-FileDownload"
      cancelButtonProps={{
        className: 'btnCancel Button',
      }}
    >
      {selectedFeatureItem !== 'survey_vinerows' ? (
        <div className="cfqRadioBtn">
          <Radio.Group onChange={(e) => setSelectedFeatureItem(e.target.value)}>
            <Space direction="vertical">
              <Radio
                value="Waypoints"
                data-testid="wayPointDownload-FileDownload"
              >
                {' '}
                {t(translate.map.waypoints)}
              </Radio>
              <Radio
                value="Polygons"
                data-testid="polygonDownload-FileDownload"
              >
                {t(translate.map.boundaries)}
              </Radio>
              <Radio value="Vines" data-testid="vinesDownload-FileDownload">
                {t(translate.map.crops)}
              </Radio>
              <Radio
                value="vine_rows"
                data-testid="vinerowsDownload-FileDownload"
              >
                {' '}
                {t(translate.map.crop_rows)}
              </Radio>
              <Radio
                value="vine_blocks"
                data-testid="vineblockDownload-FileDownload"
              >
                {' '}
                {t(translate.map.crop_blocks)}
              </Radio>
              <Radio
                value="vine_lane"
                data-testid="vineLaneDownload-FileDownload"
              >
                {' '}
                {t(translate.map.crop_lanes)}
              </Radio>
              <Radio
                value="survey_vinerows"
                data-testid="vineLaneDownload-FileDownload"
              >
                {' '}
                Survey Vine Rows
              </Radio>
              <Radio value="Feed_Lane"> {t(translate.map.feed_line)}</Radio>
            </Space>
          </Radio.Group>
        </div>
      ) : (
        <>
          <Checkbox.Group
            style={{ width: '100%' }}
            className="checkList"
            onChange={onChange}
            value={surveyIds}
          >
            <Row>
              {!loader &&
                surveyVineRows &&
                surveyVineRows.length === 0 &&
                'No data available'}
              {surveyVineRows &&
                surveyVineRows.map((surveyVineRow: any) => (
                  <>
                    <Col span={8}>
                      <Checkbox value={surveyVineRow.id}>
                        {surveyVineRow.name}
                      </Checkbox>
                    </Col>
                  </>
                ))}
            </Row>
          </Checkbox.Group>
        </>
      )}
      <AppLoader loader={loader} />
    </Modal>
  );
};
export default FileDownload;
