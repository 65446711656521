import React, { useState } from 'react';
import { Tabs } from 'antd';
import TractorUsageTabs from './tractorUsageTabs';
import UsageDashboardTabs from './usageDashboardTabs';
// import TractorHealth from '../../admin/dealer/Planning/TractorHealth';

const { TabPane } = Tabs;

const Planning: React.FC = () => {
  const [tabid, setTabid] = useState<string>('tractors');

  return (
    <Tabs
      activeKey={tabid}
      centered
      id="org-tab-Organization"
      className="inventoryTabs"
      onChange={(key) => {
        setTabid(key);
      }}
    >
      <TabPane
        tab="Tractor Usage Report"
        key="tractors"
        className="attTableHeight mBottom0"
      >
        {tabid === 'tractors' && <TractorUsageTabs />}
      </TabPane>

      <TabPane
        tab="Usage Dashboard"
        key="UsageDashboard"
        className="attTableHeight mBottom0"
      >
        {tabid === 'UsageDashboard' && <UsageDashboardTabs />}
      </TabPane>
    </Tabs>
  );
};

export default Planning;
