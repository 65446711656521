import { Button, Card, Modal } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import translate from '../../../locale/en_translate.json';
import { getIndicatorImages } from '../../basestation/Common';
interface Props {
  showModal: boolean;
  handleCancel: () => void;
  indicatorDetails: any;
}
export const FleetIndicatorDetails: React.FC<Props> = ({
  showModal,
  handleCancel,
  indicatorDetails,
}: Props) => {
  const { t } = useTranslation();
  return (
    <Modal
      className="EmployeeModalContainer fm-modal thmDetails"
      centered
      title={t(translate.fleetIndicator.fleetIndicatorDetails)}
      visible={showModal}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel} className="btnCancel">
          {t(translate.buttons.close)}
        </Button>,
      ]}
    >
      <div className="stationHealthContainer">
        <div className="indiContainer">
          <div className="indiBlockRow">
            <div className="col2-styles">
              <div className="orgDetails detailTxt fltClr">
                <p>{t(translate.baseStation.tractorName)}</p>
              </div>
              <div className="orgData">
                <p>{indicatorDetails?.name}</p>
              </div>
            </div>
            <div className="col2-styles">
              <div className="orgDetails detailTxt fltClr">
                <p>{t(translate.indicatorDetails.errorCode)}</p>
              </div>
              <div className="orgData">
                <p>{indicatorDetails?.error_code}</p>
              </div>
            </div>
          </div>
          <div className="indiBlockRow">
            <div className="col2-styles">
              <div className="orgDetails detailTxt fltClr">
                <p> {t(translate.taskmaster.starttime)}</p>
              </div>
              <div className="orgData">
                <p>{indicatorDetails?.start_time}</p>
              </div>
            </div>
            <div className="col2-styles">
              <div className="orgDetails detailTxt fltClr">
                <p>{t(translate.indicatorDetails.resolvedTime)}</p>
              </div>
              <div className="orgData">
                <p>{indicatorDetails?.resolved_date_time}</p>
              </div>
            </div>
          </div>

          <div className="indiBlockRow">
            <div className="col2-styles">
              <div className="orgDetails detailTxt fltClr">
                <p>{t(translate.tractorDetails.indicatorName)}</p>
              </div>
              <div className="orgData">
                <p>{indicatorDetails?.indicator_name}</p>
              </div>
            </div>
            <div className="col2-styles">
              <div className="orgDetails detailTxt fltClr">
                <p>{t(translate.MyJumpAndGo.duration)}</p>
              </div>
              <div className="orgData">
                <p>{indicatorDetails?.Duration}</p>
              </div>
            </div>
          </div>
          <div className="indiBlockRow">
            <div className="col2-styles">
              <div className="orgDetails detailTxt fltClr">
                <p>{t(translate.myTask.description)}</p>
              </div>
              <div className="orgData ">
                <p className="descText">
                  {' '}
                  <img
                    width="20px"
                    height="20px"
                    src={getIndicatorImages(
                      indicatorDetails.indicator_name,
                      indicatorDetails.indicator_level,
                      true,
                    )}
                    className="mr6"
                  />
                  {indicatorDetails?.description}
                </p>
              </div>
            </div>
            <div className="col2-styles">
              <div className="orgDetails detailTxt fltClr">
                <p>{t(translate.MyJumpAndGo.issue)}</p>
              </div>
              <div className="orgData">
                <p>{indicatorDetails?.Issue}</p>
              </div>
            </div>
          </div>
          <div className="indiBlockRow">
            <div className="col2-styles">
              <div className="orgDetails detailTxt fltClr">
                <p>{t(translate.myTask.counterMeasure)}</p>
              </div>
              <div className="orgData">
                <p className="descText">{indicatorDetails?.counterMeasure}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default FleetIndicatorDetails;
