import { useState, useRef, useEffect } from 'react';

const usePaginate = (): any => {
  const [data, setData] = useState<any[]>([]);
  const [filterData, setFilterData] = useState<any[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(false);

  const activity = useRef<string>('');

  const checkActivity = () =>
    ['refresh', 'sort', 'search', 'filter', 'update', 'create'].includes(
      activity.current,
    );

  useEffect(() => {
    setHasMore(data.length === 25);
    if (checkActivity()) setFilterData(data);
    else if (activity.current === 'paginate')
      setFilterData([...filterData, ...data]);
    else setFilterData(data);
  }, [data]);

  useEffect(() => {
    return () => {
      setData([]);
      setFilterData([]);
      setHasMore(false);
    };
  }, []);

  return { filterData, setData, activity, hasMore, checkActivity };
};

export default usePaginate;
