import React, { useContext, useState, useEffect, useRef } from 'react';
import { Row, notification, Space, Input, Tag } from 'antd';
import Layout, { Content } from 'antd/lib/layout/layout';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import {
  getFleetDiagnostics,
  getOrgSubsystemsData,
  getTractorDataById,
  getTractorIndicatorSummaryData,
} from '../../constants/Api';
import { ApplicationContext } from '../../context/AppContext';
import { FleetHealthCxt } from './FleetHealthContext';

import translate from '../../locale/en_translate.json';
import sortDown from '../../assets/images/sort_down.svg';
import sortUp from '../../assets/images/sort_up.svg';
import successCheck from '../../assets/images/success_check.svg';
import alertIcon from '../../assets/images/alert_icon.svg';
import failCross from '../../assets/images/fail_cross.svg';
import FlagGrayIcon from '../../assets/images/flag_gray.svg';
import FlagOrangeIcon from '../../assets/images/flag_orange.svg';
import FlagRedIcon from '../../assets/images/flag_red.svg';
import autonomyNewW from '../../assets/images/autonomyNewW.svg';
import battery_chargerW from '../../assets/images/battery_chargerW.svg';
import hitchImpW from '../../assets/images/hitchImpW.svg';
import hDriveabilityW from '../../assets/images/hDriveabilityW.svg';
import autonomyFull from '../../assets/images/autonomy_n_full.svg';
import battery_charger from '../../assets/images/battery_charger.svg';
import driveability from '../../assets/images/driveability.svg';
import hitch from '../../assets/images/hitch1.svg';
import autonomyNewG from '../../assets/images/au_gray.svg';
import battery_chargerG from '../../assets/images/bc_gray.svg';
import hitchImpG from '../../assets/images/hi_gray.svg';
import hDriveabilityG from '../../assets/images/db_gray.svg';

import { TractorsList } from '../../constants/types';
import {
  batteryValue,
  fromToDateWrapper,
  getBatteryPercentage,
  getConnectivityImg,
  getTractorAvailableState,
  initScroller,
  tractorSort,
} from '../../constants/Common';

import { track } from '../../util/logger';
import constant, { STATUS_LIST, TICKET_TYPES } from '../../constants/constant';
import { RESET_NAVIGATE } from '../../context/actions';
import CSelect from '../common/CSelect';
import DiagnosticWidget from './DiagnosticWidget';
import TicketsCreateEdit from '../tickets/TicketsCreateEdit';
import Battery from '../common/Battery';
import InfiniteScrollTable from '../common/InfiniteScrollTable';
import { SearchOutlined } from '@ant-design/icons';
import CustomDate from '../common/CustomDate';
import CustomSeverityLevel from '../common/CustomSeverityLevel';
import CustomSelect1 from '../common/CustomSelect1';
import usePaginate from '../../hooks/usePaginate';
import { mapDiagnosticData } from '../../lib/dataFormat';

import 'react-datepicker/dist/react-datepicker.css';
import './style.css';

const {
  TRACTOR_COMMUNICATION,
  TRACTOR_AUTOMATION,
  TRACTOR_DRIVABILITY,
  HITCH_AND_IMPLEMENT,
  TRACTOR_ENERGY,
} = constant;

interface TractorResponseType {
  label: string;
  value: number | string | boolean;
  disabled: boolean;
  index: number | string;
  isAvailable?: string;
}

interface Props {
  tractorID?: number;
  showKnowledgeBase: (error_code: number) => void;
}
export const getTags = (details: any) => {
  const { functionality_affected } = details;
  let tags = <></>;
  if (functionality_affected && functionality_affected.trim() !== '') {
    tags = functionality_affected
      .split(functionality_affected.includes(';') ? ';' : ',')
      .map((element: string, key: number) => (
        <Tag key={key} className="tagsName">
          {element}
        </Tag>
      ));
  }
  return tags;
};
export const getSeverity = (level: number) => {
  let images;
  switch (level) {
    case 1:
      images = (
        <>
          <img src={FlagOrangeIcon} className="mr7" />
          <img src={FlagGrayIcon} className="mr7" />
          <img src={FlagGrayIcon} className="mr7" />
        </>
      );
      break;
    case 2:
      images = (
        <>
          <img src={FlagOrangeIcon} className="mr7" />
          <img src={FlagOrangeIcon} className="mr7" />
          <img src={FlagGrayIcon} className="mr7" />
        </>
      );
      break;
    case 3:
      images = (
        <>
          <img src={FlagRedIcon} className="mr7" />
          <img src={FlagRedIcon} className="mr7" />
          <img src={FlagRedIcon} className="mr7" />
        </>
      );
      break;
    default:
      images = (
        <>
          <img src={FlagGrayIcon} className="mr7" />
          <img src={FlagGrayIcon} className="mr7" />
          <img src={FlagGrayIcon} className="mr7" />
        </>
      );
      break;
  }
  return <Space>{images}</Space>;
};
const Diagnostics: React.FC<Props> = ({
  tractorID,
  showKnowledgeBase,
}: Props) => {
  const { t } = useTranslation();
  const { userDetails, APPReducer } = useContext(ApplicationContext);
  const [state, dispatch] = APPReducer;
  const { navigate, tractors } = state;
  let routeData: any;
  if (navigate) {
    routeData = navigate.data;
  }
  const { ticketRefreshStatus, SetTicketRefreshStatus } =
    useContext(FleetHealthCxt);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize] = useState<number>(25);
  const [tractorsData, setTractorsData] = useState<TractorResponseType[]>([]);
  const [tractorsHeartbeatData, setTractorsHeartbeatData] = useState<
    TractorResponseType[]
  >([]);
  const [tractorId, setTractorId] = useState<number>(tractorID || 0);
  const [toDateTime, setToDateTime] = useState<any>(null);
  const [total, setTotal] = useState<number>(0);
  const [search, setSearch] = useState<string>('');
  const [toggleWidget, setToggleWidget] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [errorDetails, setErrorDetails] = useState<any>();
  const [subSystem, setSubSystem] = useState<string>('');
  const [indicatorType, setIndicatorType] = useState<any>();
  const [level, setLevel] = useState<string>('');
  const [indicators, setIndicators] = useState<any>({});
  const [tractor, setTractor] = useState<any>(null);
  const [toggleTicketWidget, setToggleTicketWidget] = useState<boolean>(false);
  const [ticketDetails, setTicketDetails] = useState(null);
  const [tractorDetails, setTractorDetails] = useState<TractorsList>();
  const [subSystems, setSubSystems] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [sortedInfo, setSortedInfo] = useState<any>({
    columnKey: 'indicator_level',
    order: 'descend',
  });
  const [sortedData, setSortedData] = useState<any>({
    modified_date_time: 'descend',
  });
  const [dateFilter, setDateFilter] = useState<any>([]);
  const [init, setInit] = useState<boolean>(false);
  const [showHitch, setShowHitch] = useState<boolean>(false);
  const [indicatorList, setIndicatorList] = useState<any[]>(STATUS_LIST);
  const searchString = useRef<string>('');

  const { filterData, setData, activity, hasMore, checkActivity } =
    usePaginate();

  const columns = [
    {
      title: () => {
        return (
          <>
            {t(translate.headers.severity)}
            <span className="sort-pad">
              {sortedInfo.columnKey === 'indicator_level' && (
                <img
                  src={
                    sortedData['indicator_level'] === 'ascend'
                      ? sortUp
                      : sortDown
                  }
                />
              )}
            </span>
          </>
        );
      },

      sortDirections: ['descend', 'ascend', 'descend'],
      width: '13%',
      dataIndex: 'severity',
      key: 'indicator_level',
      render: (severity: number) => {
        return getSeverity(severity);
      },
      sorter: (a: any, b: any) => {
        return a.severity - b.severity;
      },
      sortOrder: sortedInfo.columnKey === 'indicator_level' && sortedInfo.order,
      ellipsis: true,
      showSorterTooltip: false,
    },
    {
      title: () => {
        return (
          <>
            {t(translate.headers.dateTime)}
            <span className="sort-pad">
              {sortedInfo.columnKey === 'modified_date_time' && (
                <img
                  className="sort-pad"
                  src={
                    sortedData['modified_date_time'] === 'ascend'
                      ? sortUp
                      : sortDown
                  }
                />
              )}
            </span>
          </>
        );
      },
      defaultSortOrder: 'ascend',
      sortDirections: ['descend', 'ascend', 'descend'],
      dataIndex: 'modified_date_time',
      key: 'modified_date_time',
      width: '18%',
      sorter: (a: any, b: any) => {
        return a.modified_date_time - b.modified_date_time;
      },
      sortOrder:
        sortedInfo.columnKey === 'modified_date_time' && sortedInfo.order,
      ellipsis: true,
      showSorterTooltip: false,
    },

    {
      title: `${t(translate.tractorDetails.errorcode)}`,
      dataIndex: 'error_code',
      key: 'error_code',
      width: '14%',
      render: (error_code: any) => <>{error_code}</>,
    },
    {
      title: `${t(translate.headers.indicatorType)}`,
      width: '18%',
      dataIndex: 'indicator_type',
      key: 'indicator_type',
    },
    {
      title: `${t(translate.headers.issue)}`,
      width: '18%',
      dataIndex: 'subsystem',
      key: 'subsystem',
      render: (subsystem: string) => {
        return <span className="description">{subsystem}</span>;
      },
    },
    {
      title: `${t(translate.myTask.description)}`,
      dataIndex: 'description',
      key: 'description',
      ellipsis: true,
      render: (description: string) => {
        return (
          <div className="description">
            <div className="description-text">{description}</div>
          </div>
        );
      },
    },
  ];

  const getTractorDetails = async (tractorId: number) => {
    const tractor: { records: TractorsList[] } = await getTractorDataById(
      userDetails.organization.api_url,
      userDetails.organization_id,
      tractorId,
    );
    const [tractorData] =
      tractor && tractor.records && tractor.records.length > 0
        ? tractor.records
        : [];
    tractorData['isOnline'] =
      tractorData.connection === 'online' ? true : false;
    const { connection } = tractorData;
    handleHitch(tractorData);
    setTractorDetails(tractorData);
    setTractor({ ...tractor, isOnline: tractorData['isOnline'], connection });
  };

  const handleHitch = (tractorData: any) => {
    const model = tractorData?.tractor_model;
    if (model?.toUpperCase() === 'NH' || model?.toUpperCase() === 'CIH') {
      setShowHitch(false);
      const newIndicatorList = STATUS_LIST?.filter(
        (item: any) => item.name !== 'Hitch & Implement',
      );
      setIndicatorList(newIndicatorList);
    } else {
      setShowHitch(true);
      setIndicatorList(STATUS_LIST);
    }
  };

  const getOrgSubsystems = async () => {
    const result: any = await getOrgSubsystemsData(
      userDetails.organization.api_url,
      userDetails.organization_id,
    );
    const subSystemsData = result && result.length > 0 ? result : [];
    const data = subSystemsData.map((item: any) =>
      Object.assign({
        label: item.subsystem,
        value: item.id,
      }),
    );
    setSubSystems(data);
  };

  const initDefaults = async () => {
    if (userDetails && userDetails.organization) {
      getOrgSubsystems();
    }
  };

  const wrapResult = async (
    apiUrl: string,
    tractorId: number,
    status: string,
    indicatorType: string,
    level: string,
    subsystem: string,
    fromDate: string,
    toDate: string,
    pageNumber: number,
    pageSize: number,
    search: string,
    sortedInfo: string,
  ) => {
    const result: any = await getFleetDiagnostics(
      apiUrl,
      tractorId,
      status,
      indicatorType,
      level,
      subsystem,
      fromDate,
      toDate,
      pageNumber,
      pageSize,
      search,
      sortedInfo,
    );
    return {
      result,
      searchKey: search,
    };
  };

  const fleetIndicatorHistoryList = async () => {
    if (checkActivity()) initScroller();
    const tstatus = handleRouteValidation();
    if (!tstatus) return;
    const { organization } = userDetails;
    try {
      const fromDateTime = dateFilter
        ? dateFilter[0] && moment(dateFilter[0]).startOf('day').toDate()
        : '';
      const toDateTime = dateFilter
        ? dateFilter[1] && moment(dateFilter[1]).endOf('day').toDate()
        : '';
      setLoading(true);
      const { result, searchKey } = await wrapResult(
        organization.api_url,
        tractorId ? tractorId : 0,
        'unresolved',
        indicatorType ? indicatorType : '',
        level ? level : '',
        subSystem ? subSystem : '',
        fromDateTime ? fromDateTime.getTime() : '',
        toDateTime ? toDateTime.getTime() : '',
        pageNumber,
        pageSize,
        search ? search : '',
        sortedInfo,
      );

      if (searchString.current !== searchKey) return;

      setTotal(result?._metadata?.total_records_count);
      const { records } = result;
      let data = Array.isArray(records) ? records : [];
      data = mapDiagnosticData(data);
      setData(data);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const wrapIndictorsData = (arr: any) => {
    const data = arr.reduce((a: any, c: any) => {
      a[c.indicator_key] = c.indicator_level;
      return a;
    }, {});
    return data;
  };

  const getTractorIndicatorSummary = async () => {
    try {
      const data = await getTractorIndicatorSummaryData(
        userDetails.organization.api_url,
        tractorId,
      );
      const indicatorsData = wrapIndictorsData(data);
      setIndicators(indicatorsData);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const getTractorsList = () => {
    try {
      const tractorData: TractorResponseType[] = tractors.map(
        (d: TractorsList) => {
          return {
            label: d.name,
            value: d.id,
            disabled: false,
            index: d.id,
            id: d.id,
            connection: d.connection,
            isOnline: d.connection === 'online' ? true : false,
            isAvailable: getTractorAvailableState(d.connection, d?.heartbeat),
          };
        },
      );
      const arr = tractorSort(tractorData, 'label');
      setTractorsHeartbeatData(arr);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    }
  };

  const handleSelect = async (e: any) => {
    setTractorId(e);
    track('Diagnostics', {
      event: `Select Tractor  ${e}`,
    });
  };

  const levelSelect = (e: string) => {
    setLevel(e);
    track('Diagnostics', {
      event: `Select Level  ${e}`,
    });
  };
  const selectIndicatorType = (e: string) => {
    setIndicatorType(e);
    track('Diagnostics', {
      event: `Select Indicator  ${e}`,
    });
  };
  const subSystemSelect = (e: string) => {
    setSubSystem(e);
    track('Diagnostics', {
      event: `Select SubSystem  ${e}`,
    });
  };

  const onDateRangeChange = (dates: any) => {
    if (dates) {
      const [from, to] = fromToDateWrapper(dates);
      setToDateTime(to.toDate());
      track('Diagnostics', {
        event: `Select From Date ${from.toDate()} & To Date ${to.toDate()}`,
      });
    } else {
      setToDateTime(null);
      track('Diagnostics', {
        event: 'Select From Date & To Date Empty',
      });
    }
  };

  const handleChange = (pagination: any, filters: any, sorter: any) => {
    activity.current = 'sort';
    const { columnKey, order } = sorter;
    setSortedData({ ...sortedData, [columnKey]: order });
    setSortedInfo(sorter);
  };

  const handleSearch = (e: any) => {
    activity.current = 'search';
    const value = e.target.value.trim().regX;
    searchString.current = value;
    if (value.length <= 2) {
      searchString.current = '';
      if (search != '') setData([]);
      setSearch('');
    } else {
      searchString.current = value;
      setSearch(value);
      track('Diagnostics', {
        event: `Search Ticket Num : ${value}`,
      });
    }
  };

  const handleLoadMore = () => {
    if (pageNumber === 1 && checkActivity() && document) initScroller();

    activity.current = 'paginate';
    setPageNumber(pageNumber + 1);
  };

  const getRow = (record: any) => {
    setErrorDetails(record);
    setToggleWidget(true);
    track('Diagnostics details', {
      event: 'Diagnostics Details',
    });
  };

  const getCrossCheck = (state: number) => {
    if (state == 0 || state == 1) {
      return <img src={successCheck} height="21px" width="21px" />;
    } else if (state == 2) {
      return <img src={alertIcon} height="21px" width="21px" />;
    } else if (state == 3) {
      return <img src={failCross} height="21px" width="21px" />;
    }
  };

  // ticket creation

  const onCreateTicket = () => {
    setTicketDetails(errorDetails);
    setToggleWidget(false);
    setToggleTicketWidget(true);
    track('CreateTicket', {
      event: 'Create Ticket',
    });
  };

  const onCloseTicketWidget = () => {
    setToggleWidget(true);
    setToggleTicketWidget(false);
    SetTicketRefreshStatus(!ticketRefreshStatus);
  };

  const handleIndicatorTypes = (indType: string) => {
    if (indType === indicatorType) {
      selectIndicatorType('');
    } else {
      selectIndicatorType(indType);
    }
  };

  const handleRouteValidation = () => {
    if (routeData && dateFilter) {
      const { event_date_time } = routeData;
      return (
        dateFilter[0]?.startOf('day').toDate().getTime() ===
        moment(event_date_time).startOf('day').toDate().getTime()
      );
    }
    return true;
  };

  useEffect(() => {
    if (dateFilter && dateFilter.length > 0) {
      onDateRangeChange(dateFilter);
    }
    if (dateFilter === null) {
      setToDateTime('');
    }
  }, [dateFilter]);

  useEffect(() => {
    if (tractors) getTractorsList();
  }, [tractors]);

  useEffect(() => {
    if (tractorsHeartbeatData.length > 0) {
      setTractorsData(tractorsHeartbeatData);
    }
  }, [tractorsHeartbeatData]);

  useEffect(() => {
    if (routeData) {
      const { event_date_time, event_level } = routeData;
      const dates = [
        moment(event_date_time).startOf('day'),
        moment(event_date_time).endOf('day'),
      ];
      setDateFilter(dates);
      levelSelect(event_level);
    }
  }, [routeData]);

  useEffect(() => {
    if (filterData.length && routeData) {
      const [temp] = filterData.filter(
        (ticket: any) => ticket.id == routeData.ref_uuid,
      );
      if (temp) getRow(temp);
    }
    if (routeData)
      dispatch({
        type: RESET_NAVIGATE,
      });
  }, [filterData]);

  useEffect(() => {
    initDefaults();
  }, [userDetails]);

  useEffect(() => {
    if (tractorsData.length) {
      setTractorId(
        routeData
          ? routeData.tractor.id
          : tractorID
          ? tractorID
          : Number(tractorsData[0]['index']),
      );
      setInit(true);
    }
    return () => {
      setTractorId(0);
    };
  }, [tractorsData]);

  useEffect(() => {
    if (userDetails && userDetails.organization && tractorId) {
      activity.current = 'filter';
      getTractorIndicatorSummary();
      getTractorDetails(tractorId);
      handleTractorStatus(tractorId);
    }
  }, [tractorId]);

  useEffect(() => {
    if (init) {
      activity.current = 'filter';
      loadAlerts();
    }
  }, [level, subSystem, indicatorType, toDateTime]);

  const loadAlerts = () => {
    if (userDetails && !userDetails.organization) return;
    if (checkActivity()) {
      pageNumber !== 1 ? setPageNumber(1) : fleetIndicatorHistoryList();
    } else if (activity.current === 'paginate' || activity.current === '') {
      fleetIndicatorHistoryList();
    }
  };

  useEffect(() => {
    if (
      userDetails &&
      userDetails.organization &&
      sortedInfo.order &&
      tractorId
    ) {
      loadAlerts();
    }
  }, [sortedInfo]);

  useEffect(() => {
    if (userDetails && userDetails.organization && tractorId && init) {
      loadAlerts();
    }
  }, [userDetails, pageNumber, tractorId, search]);

  const handleTractorStatus = (tractorId: string | number) => {
    if (tractorId) {
      const tractor: any = tractorsData.filter(
        (item: any) => item.id == tractorId,
      )[0];
      setTractor(tractor);
    }
  };

  return (
    <Layout>
      <DiagnosticWidget
        isEdit={isEdit}
        details={errorDetails}
        setIsEdit={setIsEdit}
        setToggleWidget={setToggleWidget}
        getSeverity={getSeverity}
        getTags={getTags}
        onCreateTicket={onCreateTicket}
        toggleWidget={toggleWidget}
        tractor={tractorDetails}
        showKnowledgeBase={showKnowledgeBase}
      />

      {ticketDetails && toggleTicketWidget && (
        <TicketsCreateEdit
          tractorId={tractorId}
          Close={onCloseTicketWidget}
          ticketObj={ticketDetails}
          toggleWidget={toggleTicketWidget}
          ticketCreationType={TICKET_TYPES.INDICATOR_ERROR_CODE}
        ></TicketsCreateEdit>
      )}

      <Content>
        <div className="tblContainer">
          <Row className="w100per">
            <div className="flex w100per">
              <div className="item mr20">
                <div className="mb16 selectTractorDiag1">
                  <CSelect
                    list={tractorsData.map((item) =>
                      Object.assign({
                        id: item.index,
                        name: item.label,
                        isAvailable: item.isAvailable,
                      }),
                    )}
                    selectTrigger={(selected) => {
                      handleSelect(selected ? selected?.id : '');
                    }}
                    placeholder=""
                    label="Tractors"
                    defaultId={String(tractorId)}
                    tractorStatus={true}
                    className="tractor-status"
                  />
                </div>
                <div className="flex-bg battConnBlk">
                  <div>
                    <div
                      className="center-text battConnTxt overline1 fSbd"
                      data-testid="batteryText-Diagnostics"
                    >
                      {t(translate.diagnsotics.summary.battery)}
                    </div>
                    <div className="btSecTxt diagbattery">
                      <Battery
                        battery={getBatteryPercentage(tractorDetails)}
                      ></Battery>
                      <span className="center-text battConnPer headline3 fMed">
                        {batteryValue(tractorDetails)}%
                      </span>
                    </div>
                  </div>
                  <div
                    className="pointer"
                    onClick={() =>
                      handleIndicatorTypes('Tractor_Communication')
                    }
                  >
                    <div
                      className="center-text battConnTxt overline1 fSbd"
                      data-testid="connectivityText-Diagnostics"
                    >
                      {t(translate.diagnsotics.summary.connectivity)}
                    </div>
                    <div
                      className={
                        indicatorType === 'Tractor_Communication'
                          ? 'pointerEvents'
                          : 'pointerEventsDefault'
                      }
                    >
                      <img
                        width="30"
                        className="indicatorImg"
                        src={getConnectivityImg(
                          indicators[TRACTOR_COMMUNICATION],
                          indicatorType === 'Tractor_Communication',
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="flex-1 flex-bg tractorHealthBlk"
                style={{ marginTop: '0' }}
              >
                <b
                  className="tractorHealthTitle overline2"
                  data-testid="tractorHealthHeader-Diagnostics"
                >
                  {t(translate.diagnsotics.summary.tractorHealth)}{' '}
                  <span>({tractor && tractor.connection})</span>
                </b>
                <div
                  className={
                    showHitch
                      ? 'flex tractorHealthGrid mt20'
                      : 'flex tractorHealthGrid mt20 noHitchGrid'
                  }
                >
                  <div
                    className="pointer"
                    onClick={() => handleIndicatorTypes('Tractor_Drivability')}
                  >
                    <div
                      className={
                        indicatorType === 'Tractor_Drivability'
                          ? 'pointerEvents'
                          : 'pointerEventsDefault'
                      }
                    >
                      <img
                        className="indicatorImg"
                        src={
                          indicatorType === 'Tractor_Drivability'
                            ? hDriveabilityW
                            : tractor?.isOnline
                            ? driveability
                            : hDriveabilityG
                        }
                      />
                    </div>

                    <div
                      className="center tractorHealthTxt overline1"
                      data-testid="drivabilityHeader-Diagnostics"
                    >
                      {getCrossCheck(indicators[TRACTOR_DRIVABILITY])}
                      {t(translate.diagnsotics.summary.drivability)}
                    </div>
                  </div>
                  <div
                    className="pointer"
                    onClick={() => handleIndicatorTypes('Tractor_Automation')}
                  >
                    <div
                      className={
                        indicatorType === 'Tractor_Automation'
                          ? 'pointerEvents'
                          : 'pointerEventsDefault'
                      }
                    >
                      <img
                        className="indicatorImg"
                        src={
                          indicatorType === 'Tractor_Automation'
                            ? autonomyNewW
                            : tractor?.isOnline
                            ? autonomyFull
                            : autonomyNewG
                        }
                      />
                    </div>

                    <div
                      className="center tractorHealthTxt overline1"
                      data-testid="autonomyHeader-Diagnostics"
                    >
                      {getCrossCheck(indicators[TRACTOR_AUTOMATION])}
                      {t(translate.diagnsotics.summary.autonomy)}
                    </div>
                  </div>
                  <div
                    className="pointer"
                    onClick={() => handleIndicatorTypes('Tractor_Energy')}
                  >
                    <div
                      className={
                        indicatorType === 'Tractor_Energy'
                          ? 'pointerEvents'
                          : 'pointerEventsDefault'
                      }
                    >
                      <img
                        className="indicatorImg"
                        src={
                          indicatorType === 'Tractor_Energy'
                            ? battery_chargerW
                            : tractor?.isOnline
                            ? battery_charger
                            : battery_chargerG
                        }
                      />
                    </div>

                    <div
                      className="center tractorHealthTxt overline1"
                      data-testid="batteryAndChargerText-Diagnostics"
                    >
                      {getCrossCheck(indicators[TRACTOR_ENERGY])}
                      {t(translate.diagnsotics.summary.batteryAndCharger)}
                    </div>
                  </div>
                  <div
                    className="pointer"
                    onClick={() => handleIndicatorTypes('Hitch_and_Implement')}
                    style={{ display: showHitch ? 'block' : 'none' }}
                  >
                    <div
                      className={
                        indicatorType === 'Hitch_and_Implement'
                          ? 'pointerEvents'
                          : 'pointerEventsDefault'
                      }
                    >
                      <img
                        className="indicatorImg"
                        src={
                          indicatorType === 'Hitch_and_Implement'
                            ? hitchImpW
                            : tractor?.isOnline
                            ? hitch
                            : hitchImpG
                        }
                      />
                    </div>

                    <div
                      className="center tractorHealthTxt overline1"
                      data-testid="hitchAndImplementText-Diagnostics"
                    >
                      {getCrossCheck(indicators[HITCH_AND_IMPLEMENT])}
                      {t(translate.diagnsotics.summary.hitchAndImplement)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="common_wrapper diag_wrapper mt16">
              <div className="filters_card">
                <div className="displayFlex">
                  <div>
                    <Input
                      addonBefore={<SearchOutlined />}
                      placeholder="Search Alerts"
                      className="common_search"
                      data-testid="searchInput-Diagnostics"
                      onChange={(e) => handleSearch(e)}
                      autoComplete="off"
                    />
                  </div>
                  <CustomDate
                    label="Start Date - End Date"
                    testId="datePicker-Diagnostics"
                    cssClass="min_width"
                    value={dateFilter}
                    onChange={(dates: any) => {
                      setDateFilter(dates);
                    }}
                  />
                  <CustomSeverityLevel
                    label="Severity Level"
                    testId="severityDropdownSelect-Diagnostics"
                    cssClass="min_width"
                    value={level}
                    onSelect={levelSelect}
                  />
                  <CustomSelect1
                    label="Indicator"
                    testId="indicatorDropdownSelect-Diagnostics"
                    cssClass="min_width"
                    value={indicatorType}
                    setValue={(selected: any) =>
                      selectIndicatorType(selected ? selected : '')
                    }
                    options={indicatorList.map((item) =>
                      Object.assign({
                        id: item.value,
                        name: item.name,
                      }),
                    )}
                    optionDisplay="name"
                    isAll={true}
                  />

                  <CustomSelect1
                    label="SubSystem"
                    testId="subSystemDropdownSelect-Diagnostics"
                    cssClass="min_width"
                    value={subSystem}
                    setValue={(selected: any) =>
                      subSystemSelect(selected ? selected : '')
                    }
                    options={subSystems.map((item: any) =>
                      Object.assign({
                        id: item.label,
                        name: item.label,
                      }),
                    )}
                    isAll={true}
                  />
                </div>
              </div>
              <div className="common_table">
                <InfiniteScrollTable
                  columns={columns}
                  hasMore={hasMore}
                  loading={loading}
                  filterData={filterData}
                  totalcount={total}
                  handleLoadMore={handleLoadMore}
                  onRowClick={(record) => getRow(record)}
                  filename="Alerts"
                  onChange={handleChange}
                />
              </div>
            </div>
          </Row>
        </div>
      </Content>
    </Layout>
  );
};

export default Diagnostics;
