/* eslint-disable no-console */
import React, { useEffect, useState, useContext } from 'react';
import {
  Layout,
  Row,
  Table,
  Card,
  Button,
  Breadcrumb,
  notification,
} from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { useTranslation } from 'react-i18next';
import translate from '../../locale/en_translate.json';
import AssignTractors from './AssignTractors';
import './style.css';
import {
  IndicatorsData,
  IndicatorsResponse,
  TractorsList,
} from '../../constants/types';
import {
  getIndicators,
  getTractorListByBasestation,
} from '../../constants/Api';
import { ApplicationContext } from '../../context/AppContext';
import { getIndicatorImages } from './Common';
import { indicatorsOrder } from '../../constants/Common';
import { useHistory, useLocation } from 'react-router-dom';
import BreadcrumbItem from 'antd/lib/breadcrumb/BreadcrumbItem';
import RoutesConstants from '../../routes/RoutesConstant';
import { CSVLink } from 'react-csv';
import dwl_icon from '../../assets/images/dwl_icon.png';
import applicationIds from '../../locale/applicationIds.json';

const BaseStationDetails: React.FC = () => {
  const { goBack, push } = useHistory();
  const { t } = useTranslation();
  const location = useLocation();

  const { userDetails } = useContext(ApplicationContext);
  const [addTractor, setAddTractor] = useState<boolean>(false);
  const [tractorList, setTractorList] = useState<TractorsList[]>([]);
  const [baseStationId, setBaseStationId] = useState<number>(0);
  const [baseStationName, setBaseStationName] = useState<string>('');
  const columns = [
    {
      title: `${t(translate.baseStation.tractorName)}`,
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: `${t(translate.baseStation.serialNumber)}`,
      dataIndex: 'serial_number',
      key: 'serial_number',
    },
    {
      title: `${t(translate.baseStation.fleetIndicators)}`,
      dataIndex: 'indicatorsData',
      key: 'indicatorsData',
      // eslint-disable-next-line react/display-name
      render: (indicators: IndicatorsData[], record: TractorsList) => (
        <span onClick={() => getIndicatorsListFunc(record)}>
          {indicators.map((indicator: IndicatorsData, i: number) => (
            <span key={i}>
              <img
                className="indicatorImg"
                width="30px"
                height="30px"
                src={getIndicatorImages(
                  indicator.indicator_name,
                  indicator.indicator_level,
                )}
              />
            </span>
          ))}
        </span>
      ),
    },
  ];
  const [headers, setHeaders] = useState();

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      const state: { baseStationId: number; name: string } = location.state as {
        baseStationId: number;
        name: string;
      };
      if (state && (state as { baseStationId: number; name: string })) {
        setBaseStationId(state.baseStationId);
        setBaseStationName(state.name);
        getTractors(state.baseStationId);
      }
    }
  }, [userDetails]);

  const getTractors = async (baseStationId: number) => {
    try {
      const { records } = await getTractorListByBasestation(
        userDetails.organization.api_url,
        userDetails.organization.id,
        baseStationId,
      );
      const list = records && records.length > 0 ? records : [];
      list.forEach((element: TractorsList) => {
        element.indicatorsData = [];
        for (const arrangeIndicator of indicatorsOrder) {
          for (const indicator of element.indicators) {
            if (arrangeIndicator === indicator.indicator_name) {
              element.indicatorsData.push(indicator);
            }
          }
        }
      });
      const headers: any = [];
      if (columns) {
        columns.forEach((column: any) => {
          if (column.title !== 'Fleet indicators') {
            headers.push({
              label: column.title,
              key: column.dataIndex,
            });
          }
        });
        setHeaders(headers);
      }
      setTractorList(records);
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    }
  };

  const closeFleetModel = () => {
    setAddTractor(false);
    getTractors(baseStationId);
  };

  // const fleetIndicatorsPage = () => {
  //   push({
  //     pathname: RoutesConstants.Indicators,
  //   });
  // };

  const getIndicatorsListFunc = async (record: TractorsList) => {
    try {
      const indicators: { data: IndicatorsResponse[] } = await getIndicators(
        userDetails.organization.api_url,
        userDetails.organization.fleet.id,
      );
      const data =
        indicators && indicators.data && indicators.data.length > 0
          ? indicators.data
          : [];
      const tempData = data.map((tractor) => {
        return {
          name: tractor.tractor_name,
          Tractor_Communication: tractor.indicators.find(
            (ind) => ind.indicator_key === 'Tractor_Communication',
          ),
          Tractor_Drivability: tractor.indicators.find(
            (ind) => ind.indicator_key === 'Tractor_Drivability',
          ),
          Tractor_Automation: tractor.indicators.find(
            (ind) => ind.indicator_key === 'Tractor_Automation',
          ),
          Tractor_Data: tractor.indicators.find(
            (ind) => ind.indicator_key === 'Tractor_Data',
          ),
          Tractor_Energy: tractor.indicators.find(
            (ind) => ind.indicator_key === 'Tractor_Energy',
          ),
        };
      });
      let item: any;
      for (const iterator of tempData) {
        if (
          iterator &&
          iterator.name &&
          record &&
          record.name &&
          record.name === iterator.name
        ) {
          item = iterator;
          break;
        }
      }
      if (item && item.name) {
        push({
          pathname: RoutesConstants.IndicatorDetails,
          state: {
            records: item,
          },
        });
      } else {
        push({
          pathname: RoutesConstants.Indicators,
        });
      }
    } catch (error: any) {
      push({
        pathname: RoutesConstants.Indicators,
      });
      notification.error({
        message: error.message,
      });
    }
  };
  return (
    <Layout>
      <div className="mainContent">
        <Breadcrumb>
          <BreadcrumbItem onClick={() => goBack()}>
            <a>{t(translate.baseStation.BaseStation)}</a>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <span style={{ color: '#EB921F' }}>{baseStationName}</span>
          </BreadcrumbItem>
        </Breadcrumb>
        <Content>
          <Row>
            <Card
              bordered={true}
              title={t(translate.headers.tractors)}
              className="dbWidget"
              extra={
                <div className="tableContainer">
                  <Button
                    id={applicationIds.baseStationScreen.baseStationDetails}
                    className="buttonTxt btn-create"
                    onClick={() => {
                      setAddTractor(!addTractor);
                    }}
                  >
                    {t(translate.buttons.add)}
                  </Button>
                </div>
              }
            >
              <Table
                dataSource={tractorList}
                columns={columns}
                pagination={false}
                sticky={true}
              />

              <CSVLink
                data={tractorList}
                headers={headers}
                filename={`BaseStationDetails.csv`}
              >
                {tractorList && tractorList.length > 0 && (
                  <img className="basebtnCSV" src={dwl_icon} />
                )}
              </CSVLink>
            </Card>
            {addTractor && (
              <AssignTractors
                showModal={addTractor}
                handleCancel={() => closeFleetModel()}
              />
            )}
          </Row>
        </Content>
      </div>
    </Layout>
  );
};

export default BaseStationDetails;
