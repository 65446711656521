/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useContext, useState } from 'react';
import Layout from 'antd/lib/layout/layout';
import close_icon from '../../assets/images/right_widget_close_icon.svg';
import { getDateTimes } from '../../constants/Common';
import translate from '../../locale/en_translate.json';
import { useTranslation } from 'react-i18next';
import { Button, Modal, notification } from 'antd';
import EditChargePoint from './EditChargePoint';
import { ApplicationContext } from '../../context/AppContext';
import { deleteChargePoint } from '../../constants/Api';
import AppLoader from '../common/AppLoader';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ChargePointDetailsList } from '../../constants/types';

const { confirm } = Modal;

interface Props {
  Close: (state?: boolean) => void;
  details: ChargePointDetailsList;
}

const ChargePointDetails: React.FC<Props> = ({ Close, details }: Props) => {
  const { userDetails } = useContext(ApplicationContext);
  const { t } = useTranslation();
  const [loader, setLoader] = useState<boolean>(false);
  const [editFlag, setEditFlag] = useState<boolean>(false);

  const deleteChargePointById = async () => {
    try {
      setLoader(true);
      const response: { msg: string } = await deleteChargePoint(
        userDetails.organization.api_url,
        userDetails.organization.id,
        details?.id,
      );
      notification.success({
        message: response.msg,
        duration: 2,
      });
      Close(true);
    } catch (err: any) {
      notification.error({
        message: err.message,
        duration: 2,
      });
    } finally {
      setLoader(false);
    }
  };

  const removeChargePoint = async () => {
    confirm({
      title: `Are you sure you want to delete ${details?.charge_point_name}?`,
      icon: <ExclamationCircleOutlined />,
      content: '',
      onOk() {
        deleteChargePointById();
      },
      onCancel() {},
      okButtonProps: {
        className: 'okBtn',
      },
      cancelButtonProps: {
        className: 'canelBtn',
      },
    });
  };

  return (
    <Layout>
      <div className="rightSideWidegt expand">
        <div className="widgetCloseBtn impCloseBtn">
          <img src={close_icon} alt="close icon" onClick={() => Close()} />
        </div>
        <div className="profileEdit widget_card">
          <div className="proRow tcktInfo">
            <h4 className="posRel errorTcktTitle headline3">
              <span style={{ fontFamily: 'Montserrat-SemiBold' }}>
                {t(translate.chargePoints.title)}
              </span>
            </h4>
            <h3 className="overline1">
              {getDateTimes(details?.created_date_time)}
            </h3>
          </div>

          <div className="proRowType rowGapBtm3">
            <h5 className="subFlex alignCenter">
              <span className="headline4 subTitles">
                {t(translate.chargePoints.chargePointName)}
              </span>
              <span className="width50 body2">
                <label>{details?.charge_point_name}</label>
              </span>
            </h5>
          </div>

          <div className="proRowType rowGapBtm3">
            <h5 className="subFlex alignCenter">
              <span className="headline4 subTitles">
                {t(translate.label.createdBy)}
              </span>
              <span className="width50 body2">
                <label>
                  {details?.created_by?.first_name}{' '}
                  {details?.created_by?.last_name}
                </label>
              </span>
            </h5>
          </div>

          <div className="proRowType rowGapBtm3">
            <h5 className="subFlex alignCenter">
              <span className="headline4 subTitles">
                {t(translate.fleetticketsHistory.createdbydate)}
              </span>
              <span className="width50 body2">
                <label>
                  {details?.created_date_time
                    ? getDateTimes(details?.created_date_time)
                    : '-'}
                </label>
              </span>
            </h5>
          </div>

          <div className="proRowType rowGapBtm3">
            <h5 className="subFlex alignCenter">
              <span className="headline4 subTitles">
                {t(translate.chargePoints.make)}
              </span>
              <span className="width50 body2">
                <label>{details?.make}</label>
              </span>
            </h5>
          </div>

          <div className="proRowType rowGapBtm3">
            <h5 className="subFlex alignCenter">
              <span className="headline4 subTitles">
                {t(translate.implements.model)}
              </span>
              <span className="width50 body2">
                <label>{details?.model}</label>
              </span>
            </h5>
          </div>

          <div className="proRowType rowGapBtm3">
            <h5 className="subFlex alignCenter">
              <span className="headline4 subTitles">
                {t(translate.chargePoints.voltage)}
              </span>
              <span className="width50 body2">
                <label>{details?.voltage}</label>
              </span>
            </h5>
          </div>

          <div className="proRowType rowGapBtm3">
            <h5 className="subFlex alignCenter">
              <span className="headline4 subTitles">
                {t(translate.chargePoints.electricityProvider)}
              </span>
              <span className="width50 body2">
                <label>
                  {details?.electricity_provider_plan_id
                    ? details?.electricity_provider_plan_id?.provider
                    : '-'}
                </label>
              </span>
            </h5>
          </div>

          <div className="proRowType rowGapBtm3">
            <h5 className="subFlex alignCenter">
              <span className="headline4 subTitles">
                {t(translate.modal.latitude)}
              </span>
              <span className="width50 body2">
                <label>
                  {details?.location_latlong
                    ? details?.location_latlong?.latitide
                    : '-'}
                </label>
              </span>
            </h5>
          </div>

          <div className="proRowType rowGapBtm3">
            <h5 className="subFlex alignCenter">
              <span className="headline4 subTitles">
                {t(translate.modal.longitude)}
              </span>
              <span className="width50 body2">
                <label>
                  {details?.location_latlong
                    ? details?.location_latlong?.longitude
                    : '-'}
                </label>
              </span>
            </h5>
          </div>

          <div className="proRowType rowGapBtm3">
            <h5 className="subFlex alignCenter">
              <span className="headline4 subTitles">
                {t(translate.modal.altitude)}
              </span>
              <span className="width50 body2">
                <label>
                  {details?.location_latlong
                    ? details?.location_latlong?.altitide
                    : '-'}
                </label>
              </span>
            </h5>
          </div>
        </div>

        <div className="loaderIcon">
          <AppLoader loader={loader} />
        </div>
        <div className="impBtmBtns chargePointStyle">
          <div
            className="impDeactivateBtn Button2"
            onClick={() => removeChargePoint()}
          >
            {' '}
            {t(translate.buttons.delete)}
          </div>
          <Button
            className="impEditBtn Button2"
            onClick={() => setEditFlag(true)}
          >
            {t(translate.buttons.editChargePoint)}
          </Button>
        </div>
      </div>
      {editFlag && details && (
        <EditChargePoint
          Close={Close}
          toggleWidget={editFlag}
          detailsList={details}
        />
      )}
    </Layout>
  );
};

export default ChargePointDetails;
