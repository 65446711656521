import { Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { track } from '../../../util/logger';
import GoldBuild from './GoldBuild';
import TractorHealth from './TractorHealth';
import Tickets from './Tickets';
import { useLocation } from 'react-router-dom';
import BasestationStatus from './BasestationStatus';
import FleetIndicatorHistory from './FleetIndicatorHistory';

const { TabPane } = Tabs;

const MarkMaintenance: React.FC = () => {
  const { state }: any = useLocation();
  const [tabid, setTabid] = useState('1');

  useEffect(() => {
    if (state) {
      setTabid('2');
    }
  }, [state]);

  return (
    <Tabs
      activeKey={tabid}
      centered
      id="fleet-tab-FleetHealthContainer"
      className="common_tabs"
      onChange={(key) => {
        setTabid(key);
        track(key, {
          event: `${key} Tab`,
        });
      }}
    >
      <TabPane tab="Fleet Status" key="1" className="attTableHeight mBottom0">
        <TractorHealth />
      </TabPane>
      <TabPane tab="Fleet Issues" key="2" className="attTableHeight mBottom0">
        <Tickets />
      </TabPane>
      <TabPane tab="Gold Build" key="3" className="attTableHeight mBottom0">
        <GoldBuild />
      </TabPane>
      <TabPane
        tab="BaseStation Status"
        key="4"
        className="attTableHeight mBottom0"
      >
        <BasestationStatus />
      </TabPane>
      <TabPane
        tab="Fleet Indicator"
        key="5"
        className="attTableHeight mBottom0"
      >
        <FleetIndicatorHistory />
      </TabPane>
    </Tabs>
  );
};

export default MarkMaintenance;
