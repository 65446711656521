import React, { useState } from 'react';
import { Tabs } from 'antd';
const { TabPane } = Tabs;
import TractorHealth from './TractorHealth';

const Planning: React.FC = () => {
  const [tabid, setTabid] = useState('1');
  return (
    <Tabs
      activeKey={tabid}
      centered
      id="fleet-tab-FleetHealthContainer"
      className="inventoryTabs"
      onChange={(key) => {
        setTabid(key);
        // track(key, {
        //   event: `${key} Tab`,
        // });
      }}
    >
      <TabPane tab="Tractor Health" key="1" className="attTableHeight mBottom0">
        <TractorHealth />
      </TabPane>
      ;
    </Tabs>
  );
};

export default Planning;
