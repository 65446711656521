/* eslint-disable react/jsx-key */
/* eslint-disable no-console */
import { Col, Row, notification, Menu } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, Switch } from 'react-router-dom';
import $ from 'jquery';

import { useHelpContext } from './HelpCenterContext';
import { getArticle, getSubCategories } from './services/API';
import { ApplicationContext } from '../../context/AppContext';
import CategoryVideo from './CategoryVideo';
import AppLoader from '../common/AppLoader';
import CVideo from './CVideo';

import PrivateRoute from '../../routes/PrivateRoute';
import RoutesConstants from '../../routes/RoutesConstant';

import { mediaRoute } from './lib/constants';
import '../HelpCenter/styles.css';
import { track } from '../../util/logger';

const TrainingVideos: React.FC = () => {
  return (
    <Switch>
      <PrivateRoute
        exact
        path={RoutesConstants.HelpCenter}
        component={DefaultView}
      />
      <PrivateRoute
        exact
        path={RoutesConstants.HelpCenterMedia}
        component={CategoryVideo}
      />
    </Switch>
  );
};

const DefaultView: React.FC = () => {
  const { push } = useHistory();
  const { HCReducer } = useHelpContext();
  const { userDetails } = useContext(ApplicationContext);
  const [state, dispatch] = HCReducer;
  const { category, video } = state;
  const [subCategories, setSubCategories] = useState<any[]>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

  const fetchSubCategories = async () => {
    try {
      setLoader(true);
      const { organization } = userDetails;
      const { api_url } = organization;
      const result = await getSubCategories(api_url, category?.id);
      const data = Array.isArray(result?.data) ? result?.data : [];
      if (data.length) setSelectedKeys([data[0].id]);
      setSubCategories(data);
    } catch (error: any) {
      notification.error({ message: error.message });
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (userDetails?.organization && category) fetchSubCategories();
  }, [category]);

  useEffect(() => {
    function onScroll(event: any) {
      const scrollPos = $(document).scrollTop();
      $('#menu-center .dataId').each(function () {
        const currLink: any = $(this);
        const refElement: any = $(`#${currLink.data('id')}`);
        // if (
        //   refElement.position().top <= Number(scrollPos) &&
        //   refElement.position().top + refElement.height() > Number(scrollPos)
        // ) {
        if (refElement.position().top - 36 <= Number(scrollPos)) {
          $('#menu-center  .dataId').removeClass('active');
          // currLink.addClass('active');
          setSelectedKeys([currLink.data('id')]);
        } else {
          currLink.removeClass('active');
        }
      });
    }

    const videoContainerDiv =
      document.getElementsByClassName('videoContainer')[0];
    if (videoContainerDiv)
      videoContainerDiv.addEventListener('scroll', onScroll);
  }, [document]);

  const handleSelect = (id: string) => {
    $('#menu-center  .dataId').removeClass('active');
    const element = document?.getElementById(id);
    element && element?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
      {!video && (
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={6} lg={6} xl={6}>
            <div className="left_section_scroll">
              <div className="byCat_section">
                <h2 className="byCat_head">By Category</h2>
                <div className="categoryItemsList">
                  <ul id="menu-center">
                    <Menu selectedKeys={selectedKeys} mode="vertical">
                      {subCategories &&
                        subCategories.map((item: any, i: number) => {
                          return (
                            <Menu.Item
                              key={item?.id ? item.id : i}
                              onClick={() => handleSelect(item.id)}
                            >
                              <span className="dataId" data-id={item?.id}>
                                {item.name}
                              </span>
                              {item?.count && <span>(0)</span>}
                            </Menu.Item>
                          );
                        })}
                    </Menu>
                  </ul>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={18} lg={18} xl={18}>
            <div className="tabHead_Content">
              <div className="videoContainer">
                {subCategories &&
                  subCategories.map((ele: any, i: number) => (
                    <div id={`${ele.id}`} style={{ width: '80%' }}>
                      <div className="tabHead_section">
                        <div className="tabHead_count">
                          {i == 0 && (
                            <>
                              <h1 className="tabTitle_head">
                                Monarch Training Guide
                              </h1>
                              <p className="tabTitle_desc">
                                {subCategories?.length &&
                                  subCategories[0]?.description}
                              </p>
                            </>
                          )}
                          <span>{ele?.name}</span>
                          {/* {subCategory && (
                            <span>({trainingVideos.length})</span>
                          )} */}
                        </div>
                      </div>
                      <VideoList
                        key={ele.id}
                        subCategoryId={ele.id}
                        selectedVideo={(video) => {
                          dispatch({
                            type: 'SELECTED_VIDEO',
                            payload: video,
                          });
                          track('Help Center Selected Video', {
                            event: `${video?.title}`,
                          });
                          push(mediaRoute);
                        }}
                      />
                    </div>
                  ))}
              </div>
            </div>
          </Col>
        </Row>
      )}

      <AppLoader loader={loader} />
    </>
  );
};

interface Props1 {
  selectedVideo: (video: any) => void;
  subCategoryId: string;
}

const VideoList: React.FC<Props1> = ({ selectedVideo, subCategoryId }) => {
  const [loader, setLoader] = useState(false);
  const { userDetails } = useContext(ApplicationContext);
  const [videos, setVideos] = useState<any[]>([]);

  useEffect(() => {
    return () => setVideos([]);
  }, []);

  const fetchArticles = async () => {
    try {
      setLoader(true);
      const { organization } = userDetails;
      const { api_url } = organization;
      const result = await getArticle(api_url, subCategoryId);
      const data = Array.isArray(result?.data) ? result?.data : [];
      setVideos(data.filter(({ media_type }: any) => media_type === 'video'));
    } catch (error: any) {
      notification.error({ message: error.message });
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (userDetails?.organization && subCategoryId) fetchArticles();
  }, [subCategoryId, userDetails]);

  return (
    <div className="trainingVideosContainer">
      <AppLoader loader={loader} />
      {videos &&
        videos.map((ele: any, i: number) => (
          <div className="trainingVideo" key={ele.id}>
            <CVideo
              handleSelect={(video) => {
                selectedVideo(video);
              }}
              video={ele}
            />
          </div>
        ))}
    </div>
  );
};

export default TrainingVideos;
