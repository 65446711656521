import { Col, Input, Layout, Menu, MenuProps, Row, notification } from 'antd';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import hc_monarch_logo from '../HelpCenter/images/hc_monarch_logo.svg';
import { CategoryType } from './heathCenterSearchReducer';
import { useHelpContext } from './HelpCenterContext';
import { ApplicationContext } from '../../context/AppContext';
import { searchArticle } from './services/API';
import AppLoader from '../common/AppLoader';
import no_result_tractor from '../HelpCenter/images/no_result_tractor.png';

import '../HelpCenter/styles.css';
import { dispatchSelectedArticle } from './actions/actionCreators';
import HealthcenterSearch from './HealthcenterSearch';
import { defaultRoute, mediaRoute } from './lib/constants';
import { useHistory } from 'react-router-dom';

const tagsMap: any = {
  pdf: 'PDF',
  video: 'Video',
  article: 'Article',
};

const SearchedResult1: React.FC = () => {
  const { push } = useHistory();
  const { userDetails } = useContext(ApplicationContext);
  const { HCReducer } = useHelpContext();
  const [state, dispatch] = HCReducer;
  const { searchKeyword, categories } = state;
  const [articleData, setAriclesData] = useState<any[]>([]);
  const [categoriesData, setCategoriesData] = useState<any[]>([]);
  const [mediaData, setMediaData] = useState<any>(null);
  const [categoryId, setCategoryId] = useState<string>('');
  const [mediaType, setMediaType] = useState<string>('');
  const [loader, setLoader] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);
  const [filterData, setFilterData] = useState<any>([]);
  const searchString = useRef<string>('');

  const wrapResult = async (payload: any, searchKey: string) => {
    const response = await searchArticle(payload);
    return { response, searchKey };
  };

  useEffect(() => {
    const init = async () => {
      try {
        if (searchKeyword?.trim().length < 3) return reset();
        setLoader(true);
        const { organization } = userDetails;
        const { api_url } = organization;
        const payload = {
          orgURL: api_url,
          search: searchKeyword || '',
          category: categoryId,
          file_type: mediaType,
        };
        const { response, searchKey } = await wrapResult(
          payload,
          searchKeyword,
        );
        // const response = await searchArticle(payload);
        if (searchString.current !== searchKey) return;
        if (response.data) {
          const { data } = response;
          const {
            all_categories_count,
            article_data,
            category_metadata,
            media_metadata,
          } = data;
          const catData = category_metadata
            ? category_metadata?.map(({ id, title, count }: any) => ({
                id,
                name: title,
                count: count,
              }))
            : [];
          setCategoriesData(catData);
          setAriclesData(article_data);
          setFilterData(article_data);
          setMediaData(media_metadata);
          setTotal(all_categories_count || 0);
        }
      } catch (error: any) {
        notification.error({ message: error.message });
      } finally {
        setLoader(false);
      }
    };
    searchString.current = searchKeyword;
    if (userDetails?.organization) init();
  }, [searchKeyword]);

  const reset = () => {
    setCategoriesData(
      categoriesData.map((item) => {
        return { ...item, count: 0 };
      }),
    );
    if (mediaData)
      setMediaData(
        Object.keys(mediaData).reduce((a: any, c: string) => {
          a[c] = 0;
          return a;
        }, {}),
      );
    setAriclesData([]);
    setFilterData([]);
    setTotal(0);
  };

  useEffect(() => {
    const articles = articleData.filter(
      ({ media_type }) => media_type == mediaType,
    );
    setFilterData(mediaType === '' ? articleData : articles);
  }, [mediaType]);

  useEffect(() => {
    const articles = articleData.filter(({ rootId }) => rootId === categoryId);
    setFilterData(categoryId === '' ? articleData : articles);
  }, [categoryId]);

  const getCategory = (catId: string) =>
    categories.filter(({ id }: any) => id === catId)[0];

  const handleMediaType: MenuProps['onClick'] = (e: any) => {
    const { key }: { key: CategoryType } = e;
    setCategoryId('');
    setMediaType(key);
  };

  const handleCategory: MenuProps['onClick'] = (e: any) => {
    const { key }: { key: CategoryType } = e;
    setMediaType('');
    setCategoryId(key);
  };

  const SubMenu = useMemo(
    () =>
      categoriesData.map((category: any, i: number) => (
        <Menu.Item key={category.id}>
          <span>{category.name}</span>
          <span>({category.count || 0})</span>
        </Menu.Item>
      )),
    [categoriesData],
  );

  const MediaTypeMenus = useMemo(() => {
    const arr = mediaData ? Object.keys(mediaData) : [];
    return arr.map((item) => {
      return (
        <Menu.Item key={item}>
          <span className={item === 'pdf' ? 'upperCase' : 'capitalize'}>
            {item === 'image' ? <>Article</> : <>{item}</>}
          </span>
          <span>({mediaData[item]})</span>
        </Menu.Item>
      );
    });
  }, [mediaData]);

  useEffect(() => {
    return () => {
      dispatch({
        type: 'SET_DATA',
        payload: {
          searchKeyword: '',
        },
      });
    };
  }, []);

  return (
    <div className="common_tabs">
      <div className="help_center_container">
        <div className="hc_top_bg"></div>
        <div className="hc_logo_section">
          <div
            className="flex cursorPointer"
            onClick={() => {
              dispatch({ type: 'RESET' });
              push(defaultRoute);
            }}
          >
            <img src={hc_monarch_logo} alt="logo" />
            <h4 className="hc_title_top">Help Center</h4>
          </div>
        </div>
        <div className="hc_search_section">
          <Layout>
            <Row gutter={[0, 40]}>
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 20, offset: 2 }}
                lg={{ span: 12, offset: 6 }}
                xl={{ span: 12, offset: 6 }}
              >
                <Row gutter={[20, 20]}>
                  <Col span={24} className="hc_search">
                    <div className="hc_search_black">
                      <HealthcenterSearch flag={true} />
                      {
                        <h4 className="searchedResultCount">
                          {filterData?.length} results found for{' '}
                          <strong>
                            <span>&ldquo;</span>
                            {searchKeyword}
                            <span>&ldquo;</span>
                          </strong>
                        </h4>
                      }
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Layout>
        </div>
        <div className="hc_results_section">
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={8} lg={6} xl={6}>
              <div className="left_section_scroll">
                <div className="byCat_section">
                  <h2 className="byCat_head fs16">Category</h2>
                  <div className="categoryItemsList menuItems">
                    {mediaData && (
                      <Menu
                        mode="vertical"
                        selectedKeys={[categoryId]}
                        onClick={handleCategory}
                      >
                        <Menu.Item key="">
                          <span>All Categories</span>
                          <span>({total})</span>
                        </Menu.Item>
                        {SubMenu}
                      </Menu>
                    )}
                  </div>
                </div>
                <div className="byCat_section">
                  <h2 className="byCat_head fs16">File Type</h2>
                  <div className="categoryItemsList menuItems">
                    {mediaData && (
                      <Menu
                        mode="vertical"
                        selectedKeys={[mediaType]}
                        onClick={handleMediaType}
                      >
                        <Menu.Item key="">
                          <span>All Categories</span>
                          <span>({total})</span>
                        </Menu.Item>
                        {MediaTypeMenus}
                      </Menu>
                    )}
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={16} lg={16} xl={16}>
              <AppLoader loader={loader} />
              {!loader && (
                <>
                  {filterData?.length ? (
                    <div className="tabHead_section mt30">
                      {filterData.map((article: any) => {
                        return (
                          <div
                            className="hc_searched_item1"
                            key={article.id}
                            onClick={() => {
                              const payload = {
                                category: getCategory(article.rootId),
                                subCategory: article.parentId,
                                articleId: article.id,
                              };
                              dispatchSelectedArticle(dispatch, payload);
                              if (article.media_type === 'video') {
                                dispatch({
                                  type: 'SELECTED_VIDEO',
                                  payload: article,
                                });
                                push(mediaRoute);
                              } else {
                                push(defaultRoute);
                              }
                            }}
                          >
                            <div className="hc_search_hover">
                              <h3 className="searched_cat_head search_cat_style">
                                {article?.category_name}
                              </h3>
                              <h2 className="searched_file_head">
                                <span className="searchFileHeader">
                                  {article.title}
                                </span>
                                <span
                                  className={
                                    article.media_type === 'pdf'
                                      ? 'type_chip_green'
                                      : 'type_chip_orange'
                                  }
                                >
                                  {article.media_type &&
                                    tagsMap[article.media_type]}
                                </span>
                              </h2>
                              <p className="searched_file_para">
                                {article?.description || ''}
                              </p>
                              {article.media_type === 'video' && (
                                <div className="video-thumb sel">
                                  <img
                                    className="video knowledgeImg"
                                    src={article?.poster}
                                  />
                                  <p className="name">{article?.title}</p>
                                </div>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <EmptyResult keyword={searchKeyword} />
                  )}
                </>
              )}
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default SearchedResult1;

function EmptyResult({ keyword }: any) {
  return (
    <div className="tabHead_section mt30">
      <div className="no_results_sec">
        <img src={no_result_tractor} alt="" />
        <div className="no_results_text">
          <span className="no_results_count">0</span>results found for{' '}
          <strong>
            <q>{keyword}</q>
          </strong>
        </div>
        <div className="no_results-helptext">
          Try searching for another keyword
        </div>
      </div>
    </div>
  );
}
