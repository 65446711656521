/* eslint-disable react/jsx-no-undef */
import { Button, Table, notification } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ApplicationContext } from '../../context/AppContext';
import translate from '../../locale/en_translate.json';
import { getCopycatplanner } from '../../constants/Api';
import loading_logo from '../../assets/images/loading_logo.gif';
import { downloadURI } from '../../constants/Common';
import SupportHistoryplannerView from './SupportHistoryplannerView';

interface Props {
  reportId: any;
}

const SupportHistoryplanner: React.FC<Props> = ({ reportId }: Props) => {
  const { userDetails } = useContext(ApplicationContext);
  const { t } = useTranslation();
  const [filterData, setFilter] = useState<any[]>([]);
  const [filterdata, setfilterdata] = useState<any[]>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [videoobj, setvideoobj] = useState<any>();
  const [videoflag, setvideoflag] = useState<boolean>(false);

  useEffect(() => {
    getshowVideodetails();
  }, []);

  const getshowVideodetails = async () => {
    try {
      const { organization } = userDetails;
      setLoader(true);
      const showplannner = await getCopycatplanner(
        userDetails.organization.api_url,
        organization.farm.id,
        reportId.tractorId,
        reportId.acr_start_date_timeVideo,
        reportId.acr_finish_date_timeVideo,
      );
      if (showplannner) {
        setFilter(showplannner);
        const data = showplannner
          .map((ele: any) => {
            return ele.direction;
          })
          .filter((ele1: any, i: any, array: any) => {
            return array.indexOf(ele1) === i;
          });
        const direactiondata = data.map((ele: any) => {
          const obj = {
            text: ele.replace('_', ' '),
            value: ele,
          };
          return obj;
        });
        setfilterdata(direactiondata);
      }
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    } finally {
      setLoader(false);
    }
  };

  const columns = [
    {
      title: `${t(translate.shadowvideohistory.time)}`,
      dataIndex: 'video_time',
      key: 'video_time',
    },

    {
      title: `${t(translate.shadowvideohistory.camera)}`,
      dataIndex: 'direction',
      key: 'direction',
      filters: filterdata,
      onFilter: (
        value: string | number | boolean,
        record: { direction: string | string[] },
      ) => record.direction.indexOf(String(value)) === 0,
      render: (direction: string) => (
        <>
          <span>{direction?.replace('_', ' ')}</span>
        </>
      ),
    },

    {
      title: `${t(translate.shadowvideohistory.actions)}`,
      dataIndex: 'video_url',
      key: 'video_url',
      render: (video_url: string, dataitem: any) => (
        <>
          {video_url ? (
            <Button
              type="primary"
              className="btn-create Button supportHistoryViewBtn"
              onClick={() => onRowClick(dataitem)}
            >
              View
            </Button>
          ) : (
            <span>-</span>
          )}
        </>
      ),
    },
    {
      title: `${t(translate.shadowvideohistory.download)}`,
      dataIndex: 'video_url',
      key: 'video_url',
      render: (video_url: string, dataitem: any) => (
        <>
          {video_url ? (
            <Button
              type="primary"
              className="btn-create Button supportHistoryBtn"
              onClick={() => downloadURI(video_url, dataitem.name)}
            >
              Download
            </Button>
          ) : (
            <span>-</span>
          )}
        </>
      ),
    },
  ];

  const onRowClick = (record: any) => {
    setvideoobj(record);
    setvideoflag(true);
  };

  const closeModel = () => {
    setvideoflag(false);
  };

  return (
    <>
      <div className="tblDft farmTabsTbl posRel tblStyles historyPlannerTbl">
        <Table
          dataSource={filterData}
          columns={columns}
          pagination={false}
          loading={{
            indicator: (
              <img
                style={{ width: '60px', height: '36px' }}
                src={loading_logo}
              />
            ),
            spinning: loader,
          }}
        />
      </div>
      <>
        {videoobj && videoflag && (
          <SupportHistoryplannerView
            showModel={videoflag}
            closeModel={closeModel}
            showdata={videoobj}
          ></SupportHistoryplannerView>
        )}
      </>
    </>
  );
};

export default SupportHistoryplanner;
