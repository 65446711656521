import { Input, InputNumber, Modal, notification, Select, Spin } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import {
  createChargePoint,
  getChargePointById,
  getElectricityPlan,
  UpdateChargePoint,
} from '../../constants/Api';
import { getSingleElevationValue } from '../../constants/Common';
import { WaypointPayloadTypes } from '../../constants/types';
import { ApplicationContext } from '../../context/AppContext';
import translate from '../../locale/en_translate.json';

import { useTranslation } from 'react-i18next';

interface Props {
  showModal: boolean;
  handleCancel: (reload?: boolean) => void;
  wayPointPayload: WaypointPayloadTypes;
}
export const ChargePoint: React.FC<Props> = ({
  showModal,
  handleCancel,
  wayPointPayload,
}: Props) => {
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const [name, setName] = useState<string>('');
  const [longitude, setLongitude] = useState<number>(0);
  const [latitude, setLatitude] = useState<number>(0);
  const [altitude, setAltitude] = useState<number>(0);
  const [loader, setLoader] = useState<boolean>(false);
  const [make, setMake] = useState('');
  const [model, setModel] = useState('');
  const [voltage, setVoltage] = useState('');
  const [electricityList, setElectricityList] = useState<any[]>([]);
  const [providerId, setProviderId] = useState<number>();
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [chargepointObj, setChargepointObj] = useState<any>();

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      getElectricityRate();
    }
  }, [userDetails]);

  useEffect(() => {
    if (wayPointPayload) {
      if (wayPointPayload.action === 'ADD') {
        wayPointPayload.name && setName(wayPointPayload.name);
        wayPointPayload.longitude && setLongitude(wayPointPayload.longitude);
        wayPointPayload.latitude && setLatitude(wayPointPayload.latitude);
        wayPointPayload.altitude && setAltitude(wayPointPayload.altitude);
      } else if (
        wayPointPayload.action === 'EDIT' &&
        wayPointPayload.waypointId
      ) {
        getChargePointsid(wayPointPayload.waypointId);
      }
    }
  }, [wayPointPayload]);

  useEffect(() => {
    if (chargepointObj) {
      setName(chargepointObj.charge_point_name);
      setLongitude(wayPointPayload.longitude);
      setLatitude(wayPointPayload.latitude);
      setAltitude(wayPointPayload.altitude);
      setMake(chargepointObj?.make);
      setModel(chargepointObj?.model);
      setVoltage(chargepointObj?.voltage);
      setProviderId(chargepointObj?.electricity_provider_plan_id?.id);
    }
  }, [chargepointObj]);

  const getChargePointsid = async (chargepointId: any) => {
    try {
      setLoader(true);
      const data = await getChargePointById(
        userDetails.organization.api_url,
        userDetails.organization.id,
        chargepointId,
      );
      setChargepointObj(data);
    } catch (error: any) {
      notification.error({
        message: error.message,
        duration: 2,
      });
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (
      userDetails &&
      userDetails.organization &&
      name &&
      name.trim().length > 0 &&
      make &&
      make.trim().length > 0 &&
      model &&
      model.trim().length > 0 &&
      voltage &&
      voltage.trim().length > 0 &&
      providerId
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [userDetails, name, make, model, voltage, providerId]);

  const getElectricityRate = async () => {
    try {
      setLoader(true);
      const data = await getElectricityPlan(
        userDetails.organization.api_url,
        userDetails.organization.id,
      );
      setElectricityList(data);
    } catch (error: any) {
      notification.error({
        message: error.message,
        duration: 2,
      });
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    const onchange = async () => {
      const altitude = await getSingleElevationValue([longitude, latitude]);
      setAltitude(altitude);
    };
    onchange();
  }, [latitude, longitude]);

  const saveVineRows = async () => {
    setLoader(true);
    const payload = {
      charge_point_name: name.trim(),
      make: make.trim(),
      model: model.trim(),
      voltage: voltage,
      electricity_provider_plan_id: providerId,
      location_latlong: {
        latitide: latitude,
        longitude: longitude,
        altitide: altitude,
      },
    };
    setName(name.trim());
    try {
      const { organization } = userDetails;
      if (wayPointPayload.action === 'ADD') {
        const response = await createChargePoint(
          organization.api_url,
          userDetails.organization.id,
          payload,
        );
        if (response && response.msg) {
          handleCancel(true);
          notification.success({
            message: response.msg,
          });
        }
      }
      if (wayPointPayload.action === 'EDIT' && wayPointPayload.waypointId) {
        const resp: { msg: string } = await UpdateChargePoint(
          userDetails.organization.api_url,
          userDetails.organization.id,
          chargepointObj?.id,
          payload,
        );
        if (resp && resp.msg) {
          handleCancel(true);
          notification.success({
            message: resp.msg,
          });
        }
      }
    } catch (error: any) {
      notification.error({
        message: error?.message,
      });
    } finally {
      setLoader(false);
    }
  };
  const onSelect = (e: any) => {
    setProviderId(e);
  };

  const onChangeVoltage = (value: any) => {
    const str = value;
    const regx = /^[+-]?\d+(\.\d+)?$/;
    if (0 <= Number(str) && (Number(str) === 0 || regx.test(str))) {
      setVoltage(str.trim() === '' ? '' : str);
    }
  };

  return (
    <Modal
      className="commonPopup addPolygon"
      title={t(translate.map.charge_point)}
      closable={false}
      centered
      width={'60vw'}
      visible={showModal}
      onCancel={() => handleCancel()}
      okText="SAVE"
      onOk={saveVineRows}
      okButtonProps={{
        className: 'btnSave ',
        disabled: isDisabled,
      }}
      data-testid="savebutton-Waypoint"
      cancelButtonProps={{
        className: 'btnCancel',
      }}
    >
      <div className="formRow">
        <div className="formCol">
          <label className="formLabel">
            Charge Point Name <span style={{ color: 'red' }}> *</span>
          </label>
          <Input
            className="commInput"
            data-testid="wayPointInput-Waypoint"
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
        </div>
        <div className="formCol">
          <label className="formLabel">
            Make <span style={{ color: 'red' }}> *</span>
          </label>
          <Input
            className="commInput"
            data-testid="wayPointInput-Waypoint"
            onChange={(e) => setMake(e.target.value)}
            value={make}
          />
        </div>
      </div>
      <div className="formRow">
        <div className="formCol">
          <label className="formLabel">
            Model <span style={{ color: 'red' }}> *</span>
          </label>
          <Input
            className="commInput"
            data-testid="wayPointInput-Waypoint"
            onChange={(e) => setModel(e.target.value)}
            value={model}
          />
        </div>
        <div className="formCol">
          <label className="formLabel">
            Voltage <span style={{ color: 'red' }}> *</span>
          </label>
          <Input
            className="commInput"
            data-testid="wayPointInput-Waypoint"
            onChange={(e) => onChangeVoltage(e.target.value)}
            value={voltage}
          />
        </div>
      </div>
      <div className="formRow">
        <div className="formCol">
          <label className="formLabel">
            Electricity Provider <span style={{ color: 'red' }}>*</span>
          </label>
          <Select
            placeholder="Select Electricity Provider"
            className="selectRoomDropDown chargepointDropDown w100"
            value={providerId}
            onSelect={(e: any) => onSelect(e)}
          >
            {electricityList &&
              electricityList.map((data: any) => (
                <option key={data.id} value={data.id}>
                  {data.provider}
                </option>
              ))}
          </Select>
        </div>
        <div className="formCol">
          <label className="formLabel">Altitude</label>
          <InputNumber
            className="commInput w100"
            value={altitude}
            onChange={(value) => setAltitude(value)}
          />
        </div>
      </div>
      <div className="formRow ">
        <div className="formCol">
          <label className="formLabel">Latitude</label>
          <InputNumber
            className="commInput w100"
            value={latitude}
            onChange={(value) => setLatitude(value)}
          />
        </div>
        <div className="formCol">
          <label className="formLabel">Longitude</label>
          <InputNumber
            className="commInput w100"
            value={longitude}
            onChange={(value) => setLongitude(value)}
          />
        </div>
      </div>
      <div className="edit-loader">
        {loader && (
          <div className="loader">
            <Spin size="large" />
          </div>
        )}
      </div>
    </Modal>
  );
};
export default ChargePoint;
