export const SHOW_ANALYTIC_DASHBOARD = 'wingspanAI.app.showAnalyticsDashboard';

const getSession = (key: string): string => {
  const item: string =
    sessionStorage.getItem(key) || JSON.stringify({ value: '' });
  return JSON.parse(item).value;
};

export const session = (
  key: string,
  value: string | undefined = undefined,
): string => {
  if (typeof value !== 'undefined')
    sessionStorage.setItem(key, JSON.stringify({ value }));

  return getSession(key);
};

export const deleteSession = (key: string): void =>
  sessionStorage.removeItem(key);
