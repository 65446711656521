import { Dispatch } from 'react';
import { MyActionType } from '../heathCenterSearchReducer';

export const dispatchSelectedArticle = (
  dispatch: Dispatch<MyActionType>,
  {
    category,
    subCategory,
    articleId,
  }: { category: string; subCategory: string; articleId: string },
): void => {
  dispatch({
    type: 'SET_DATA',
    payload: {
      category,
      subCategory,
      articleId,
    },
  });
};
