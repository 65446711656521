import { notification, Layout, Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import translate from '../../../../locale/en_translate.json';
import closeGreen from '../../../../assets/images/right_widget_close_icon.svg';
import CInput from '../../../common/CInput';
import { addChapter, updateChapter } from '../../API';
import CustomTextArea from '../../../common/CustomTextArea';
import CustomSelect from '../../../common/CustomSelect';
import loading_logo from '../../../../assets/images/loading_logo.gif';

interface Props {
  showModel: boolean;
  close: (state?: boolean) => void;
  chapter?: any;
}

const AddChapter: React.FC<Props> = ({ showModel, close, chapter = null }) => {
  const { t } = useTranslation();
  const [loader, setLoader] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [descriptionCount, setDescriptionCount] = useState<number>(0);
  const [formData, setFormData] = useState<any>({
    title: '',
    description: '',
    oem_code: 'Monarch',
    language: 'English',
  });

  const LanguageData = [
    { name: 'English', value: 'English' },
    { name: 'Spanish', value: 'Spanish' },
  ];

  const OemData = [{ name: 'Monarch', value: 'Monarch' }];

  const [errors, setErrors] = useState({
    title: !chapter,
    description: !chapter,
  });

  useEffect(() => {
    setErrors({
      ...errors,
      description: formData.description?.trim().length === 0,
    });
  }, [formData.description]);
  useEffect(() => {
    if (formData.description !== undefined)
      setDescriptionCount(formData.description.length);
  }, [formData.description]);

  useEffect(() => {
    setIsDisabled(!Object.values(errors).every((item) => !item));
  }, [errors]);

  useEffect(() => {
    if (chapter) {
      setFormData({
        title: chapter.title,
        description: chapter.description,
        oem_code: chapter.oem_code,
        language: chapter.language,
      });
    }
  }, [chapter]);

  const handleChapterMethod = async () => {
    try {
      setLoader(true);
      const { title, description, oem_code, language } = formData;
      if (chapter) {
        const response: { msg: string } = await updateChapter(chapter.id, {
          title,
          description,
          oem_code,
          language,
        });
        notification.success({
          message: response.msg,
          duration: 2,
        });
      } else {
        const response: { msg: string } = await addChapter({
          title,
          description,
          oem_code,
          language,
        });
        notification.success({
          message: response.msg,
          duration: 2,
        });
      }
      close(true);
    } catch (err: any) {
      notification.error({
        message: err?.message,
      });
    } finally {
      setLoader(false);
    }
  };

  return (
    <Layout>
      <div className={'rightSideWidegt ' + (showModel ? 'expand' : 'hide')}>
        <div className="widgetCloseBtn">
          <img src={closeGreen} alt="close icon" onClick={() => close()} />
        </div>

        <div className="profileEdit widget_card">
          <h3 className="headline3 rowGapBtm3" style={{ textAlign: 'center' }}>
            {t(translate.modal.addManual)}
          </h3>
          <div className="mb20">
            <CInput
              max={60}
              min={3}
              value={formData.title}
              name="title"
              label="Title"
              required={true}
              onChange={(e) => setFormData({ ...formData, title: e })}
              hasError={(state) => setErrors({ ...errors, title: state })}
            />
          </div>

          <div className="rowGapBtm3 mb20 desBorder">
            <CustomTextArea
              label="Description"
              value={formData.description}
              setValue={(data) => {
                setFormData({
                  ...formData,
                  description: data,
                });
              }}
              required={true}
              description={formData.description}
              descriptionCount={descriptionCount}
            />
          </div>
          <div className="filters_card selectLabel">
            <div className="proRowType">
              <CustomSelect
                label="Language"
                value={formData.language}
                setValue={(e) => {
                  setFormData({ ...formData, language: e });
                }}
                options={LanguageData}
                optionKey="value"
                optionDisplay="name"
              />
            </div>
          </div>

          <div className="filters_card selectLabel">
            <div className="proRowType">
              <CustomSelect
                label="OEM"
                value={formData.oem_code}
                setValue={(e) => {
                  setFormData({ ...formData, oem_code: e });
                }}
                options={OemData}
                optionKey="value"
                optionDisplay="name"
              />
            </div>
          </div>
          <div>
            <div className="edit-loader">
              {loader && (
                <img
                  style={{ width: '36px', height: '36px' }}
                  src={loading_logo}
                />
              )}
            </div>
            <div className="create_ticket_btn_sec">
              <Button onClick={handleChapterMethod} disabled={isDisabled}>
                {t(translate.buttons.add)}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AddChapter;
