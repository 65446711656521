import React, { useContext, useEffect, useState } from 'react';
import { Button, Modal, Input, Spin, notification } from 'antd';
import { ApplicationContext } from '../../context/AppContext';
import { changePassword } from '../../constants/Api';
import { useTranslation } from 'react-i18next';
import translate from '../../locale/en_translate.json';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import reject from '../../assets/images/crossIcon.svg';
import success_check from '../../assets/images/success_check.svg';
import { Auth } from 'aws-amplify';

interface Props {
  showModal: boolean;
  closeModel: () => void;
}

export const ChangePassword: React.FC<Props> = ({
  showModal,
  closeModel,
}: Props) => {
  const { userDetails } = useContext(ApplicationContext);
  const { t } = useTranslation();
  const [oldPassword, setOldPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [conformPassword, setConformPassword] = useState<string>('');
  const [loader, setLoader] = useState<boolean>(false);
  const [isDisable, setIsDisabled] = useState<boolean>(false);
  const [passwordNotMatch, setPasswordNotMatch] = useState('');
  const [isLengthValid, setIsLengthValid] = useState<boolean>(false);
  const [same, setSame] = useState<boolean>(false);
  const [isSpecialCharactorExist, setIsSpecialCharactorExist] =
    useState<boolean>(false);
  const [isNumberExist, setIsNumberExist] = useState<boolean>(false);
  const specialCharactorFormate = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
  const numberFormate = /\d/g;
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
  const [newPasswordVisible, setNewPasswordVisible] = useState<boolean>(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] =
    useState<boolean>(false);

  useEffect(() => {
    if (newPassword.trim().length > 7 && newPassword.trim().length < 15) {
      setIsLengthValid(true);
    } else {
      setIsLengthValid(false);
    }
    if (
      newPassword &&
      conformPassword &&
      newPassword.trim() !== '' &&
      conformPassword.trim() !== '' &&
      newPassword.trim() === conformPassword.trim()
    ) {
      setSame(true);
    } else {
      setSame(false);
    }
    if (specialCharactorFormate.test(newPassword.trim())) {
      setIsSpecialCharactorExist(true);
    } else {
      setIsSpecialCharactorExist(false);
    }
    if (numberFormate.test(newPassword.trim())) {
      setIsNumberExist(true);
    } else {
      setIsNumberExist(false);
    }
  }, [newPassword, conformPassword]);

  useEffect(() => {
    setIsDisabled(
      ![same, isSpecialCharactorExist, isNumberExist, isLengthValid].every(
        (item) => item,
      ),
    );
  }, [
    same,
    isSpecialCharactorExist,
    isNumberExist,
    isLengthValid,
    oldPassword,
    newPassword,
    conformPassword,
  ]);

  useEffect(() => {
    if (conformPassword.length > 1 && newPassword !== conformPassword) {
      setPasswordNotMatch("Passwords don't match");
    } else {
      setPasswordNotMatch('');
    }
  }, [oldPassword, newPassword, conformPassword]);

  const getToken = async () => {
    const session = await Auth.currentSession();
    const access_token = await session.getAccessToken().getJwtToken();
    return access_token;
  };

  const saveChangePassword = async () => {
    if (newPassword !== conformPassword) {
      setPasswordNotMatch("Passwords don't match");
      return;
    }

    const payload: any = {
      old_password: oldPassword.trim(),
      new_password: newPassword.trim(),
      access_token: await getToken(),
    };
    try {
      setLoader(true);
      const response: any = await changePassword(
        userDetails.organization.api_url,
        userDetails.id,
        payload,
      );
      notification.success({
        message: response.msg,
      });
      closeModel();
    } catch (err: any) {
      if (err.message.trim() == 'Password mismatch') {
        notification.error({
          message: 'Current password is incorrect',
        });
      } else
        notification.error({
          message: err?.message,
        });
    } finally {
      setLoader(false);
    }
  };
  return (
    <>
      <Modal
        closable={false}
        className="profileUpdatePopup newChangeStyle"
        title={t(translate.headers.changepassword)}
        confirmLoading={true}
        visible={showModal}
        onCancel={closeModel}
        footer={null}
      >
        <div className="formColW mb16 ">
          <label className="formLabel">Current Password</label>
          <div className="fieldIconBlk">
            <span
              className="posabs fieldeye showPwdIcon"
              data-testid="ShowCurrntPassIcon-ChangePassword"
            >
              {passwordVisible ? (
                <EyeTwoTone
                  onClick={() => {
                    setPasswordVisible(false);
                  }}
                />
              ) : (
                <EyeInvisibleOutlined
                  onClick={() => {
                    setPasswordVisible(true);
                  }}
                />
              )}
            </span>

            <Input
              data-testid="currentPwdInputField-ChangePassword"
              type={passwordVisible ? 'text' : 'password'}
              className="input"
              onChange={(e) => {
                setOldPassword(e.target.value);
              }}
            />
          </div>
        </div>

        <div className="formColW mb16">
          <label className="Txt1">New Password</label>
          <div className="fieldIconBlk">
            <span
              className="posabs fieldeye showPwdIcon"
              data-testid="ShowNewPassIcon-ChangePassword"
            >
              {newPasswordVisible ? (
                <EyeTwoTone
                  onClick={() => {
                    setNewPasswordVisible(false);
                  }}
                />
              ) : (
                <EyeInvisibleOutlined
                  onClick={() => {
                    setNewPasswordVisible(true);
                  }}
                />
              )}
            </span>

            <Input
              data-testid="newPwdInputField-ChangePassword"
              type={newPasswordVisible ? 'text' : 'password'}
              className="input"
              onChange={(e) => {
                setNewPassword(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="formColW">
          <label className="Txt1">Confirm Password</label>
          <div className="fieldIconBlk">
            <span
              className="posabs fieldeye showPwdIcon"
              data-testid="ShowConfirmPassIcon-ChangePassword"
            >
              {confirmPasswordVisible ? (
                <EyeTwoTone
                  onClick={() => {
                    setConfirmPasswordVisible(false);
                  }}
                />
              ) : (
                <EyeInvisibleOutlined
                  onClick={() => {
                    setConfirmPasswordVisible(true);
                  }}
                />
              )}
            </span>

            <Input
              data-testid="confirmPwdInputField-ChangePassword"
              type={confirmPasswordVisible ? 'text' : 'password'}
              className="input"
              onChange={(e) => setConformPassword(e.target.value)}
            />
          </div>
        </div>

        <div
          className="formRow"
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <label
            style={{ color: 'red' }}
            data-testid="Passwords don't match Message-ChangePassword"
          >
            {passwordNotMatch}
          </label>
        </div>

        <div className="resetCont mt32">
          <div>In order to protect you account, make sure password:</div>
          <div className="resetContChar mt10">
            <img
              width="20px"
              data-testid="minCharacter-ChangePassword"
              src={isLengthValid ? success_check : reject}
            />
            Is 8 to 14 characters long
          </div>
          <div className="resetContChar mt10">
            <img
              width="20px"
              data-testid="passwordNotMatch-ChangePassword"
              src={same ? success_check : reject}
            />
            Does not match your Password
          </div>
          <div className="resetContChar mt10">
            <img
              width="20px"
              data-testid="specialCharacter-ChangePassword"
              src={isSpecialCharactorExist ? success_check : reject}
            />
            Contains a special character
          </div>
          <div className="resetContChar mt10">
            <img
              width="20px"
              data-testid="numberContains-ChangePassword"
              src={isNumberExist ? success_check : reject}
            />
            Contains a number
          </div>
        </div>
        <div style={{ textAlign: 'center', marginTop: 42, marginBottom: 10 }}>
          <Button
            data-testid="cancelButton-ChangePassword"
            key="back"
            onClick={closeModel}
            className="changeBtnCancel"
          >
            {t(translate.buttons.cancel)}
          </Button>

          <Button
            data-testid="saveButton-ChangePassword"
            key="submit"
            onClick={saveChangePassword}
            disabled={isDisable || passwordNotMatch === "Passwords don't match"}
            className="changeBtnSave"
          >
            {t(translate.buttons.save)}
          </Button>
        </div>

        <div className="custom-loader-profile">
          {loader && (
            <div className="loader">
              <Spin size="large" />
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};
export default ChangePassword;
