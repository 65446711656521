import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Col,
  Popover,
  Progress,
  Row,
  Space,
  // Switch,
  Table,
  Tabs,
  Tooltip,
} from 'antd';
import user_fill from './images/user-fill-1.svg';
// import Search from 'antd/lib/transfer/search';
import info_img from './images/info-3.svg';
import trip_outline from './images/trip_outline-01.svg';
import cvg_map from './images/Map.png';
import { ColumnsType } from 'antd/lib/table';
import idTimeIcon from './images/idel_time.svg';

import { ApplicationContext } from '../../context/AppContext';
// import useAnalyticsDetails from './hooks/useAnalyticsDetails';
import useOperationalAnalyticStore from './store/operationAnalytics';
import useOperatorAnalyticStore from './store/operatorAnalyticStore';
import tractorAnalyticDetailsStore from './store/tractorAnalyticDetails';
import DateRangePicker from './ui/DateRangePicker';
import moment from 'moment';
import { fetchName, getHoursAndMinutes, isEmpty } from './common';
import { HistoryOutlined } from '@ant-design/icons';
import MultiStackedBarChart from '../charts/MultiStackedBarChart';
import useOperatorAnalytics from './hooks/useOperatorAnalytics';
import { HrsMins, WeekendsFilter } from './ui/helper';
import './css/styles.scss';
import './css/global.scss';
import Spinner from '../common/Spinner';
import OperationalCoverageMap from './OperationalCoverageMap/OperationalCoverageMap';
import { pathColorCodes } from './constants';
import CDateRangePicker from './ui/CDateRangePicker';
import { ChartSkeleton } from '../common/Skeleton';
import StackedBarChart from '../charts/StackedBarChart';

const { TabPane } = Tabs;

interface Props {
  onBack: () => void;
  operationType: string;
  selectedTab: string;
  setOperationType: (value: string) => void;
}

const OperatorView: React.FC<Props> = ({
  onBack,
  operationType,
  selectedTab,
  setOperationType,
}: Props) => {
  const [tabid, setTabid] = useState('1');
  const {
    operationalHoursByOperator,
    tractorEfficiencyData,
    coverageByOperator,
    operationalHours,
    coverage,
    completedWork,
    operatorId,
    efficiency,
    idleTime,
    addCompletedWorkData,
  } = useOperatorAnalyticStore();

  const {
    operationsLoader,
    completedWorkLoader,
    initCompletedWorkData,
    initOperatorHoursAndCoverageData,
  } = useOperatorAnalytics();

  const {
    view,
    dateRange,
    includeWeekends,
    toggleWeekends,
    setDateRange,
    setView,
    setFromView,
    setSelectedDate,
  } = useOperationalAnalyticStore();
  const { selectTractor } = tractorAnalyticDetailsStore();

  const { userDetails } = useContext(ApplicationContext);
  const [cardSize, setCardSize] = useState({ width: 0, height: 0 });
  const cardRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setTabid(selectedTab);
  }, [selectedTab]);

  useEffect(() => {
    if (userDetails?.organization && operatorId) {
      const [fromDate, toDate] = dateRange;
      initCompletedWorkData(
        operationType,
        fromDate?.startOf('day')?.toDate()?.getTime(),
        toDate?.endOf('day')?.toDate()?.getTime(),
        operatorId,
      );
      initOperatorHoursAndCoverageData(
        fromDate?.startOf('day')?.toDate()?.getTime(),
        toDate?.endOf('day')?.toDate()?.getTime(),
        operatorId,
      );
    }
    return () => {
      addCompletedWorkData([]);
    };
  }, [userDetails, operatorId, dateRange]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        setCardSize({
          width: entry.contentRect.width,
          height: entry.contentRect.height,
        });
      }
    });

    if (cardRef.current) {
      resizeObserver.observe(cardRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const dateRangeText = useMemo(() => {
    if (dateRange && !dateRange.length) return '';
    const [fromDate, toDate] = dateRange;
    return (
      <>
        {moment(fromDate).format('MMMM Do')} - {moment(toDate).format('Do')}
      </>
    );
  }, [dateRange]);

  const operatorsBlock = useCallback((operators = []) => {
    return operators?.slice(0, 3).map((operator: any, key: number) => {
      const { first_name, last_name, id } = operator;
      return (
        <div key={id} className={`avatar-circles${key === 0 ? '' : key}`}>
          <div className="mm uppercase">
            {key !== 2 ? fetchName(first_name, last_name) : '+2'}
          </div>
        </div>
      );
    });
  }, []);

  const columns: ColumnsType<any> = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (date) => {
        return date ? moment(date).format('DD/MM') : '';
      },
    },
    {
      title: 'Tractor',
      dataIndex: 'tractorShortPin',
      key: 'tractorShortPin',
      render: (value, record) => {
        return (
          <Tooltip title={record?.tractorPin}>
            <span>{value}</span>
          </Tooltip>
        );
      },
    },
    {
      title: 'Implement',
      dataIndex: 'implement',
      key: 'implement',
      render: (implement) => (!isEmpty(implement) ? implement : 'No Implement'),
    },
    {
      title: 'Duration',
      dataIndex: 'duration',
      key: 'duration',
      render(value, record: any) {
        const data = getHoursAndMinutes(value);
        const { operator } = record;
        const op_data = getHoursAndMinutes(operator?.duration);
        return (
          <Space direction="vertical" size="small">
            <HrsMins hours={data?.hours} minutes={data?.minutes} />
            {!!operator && (
              <HrsMins hours={op_data?.hours} minutes={op_data?.minutes} />
            )}
          </Space>
        );
      },
    },
    {
      title: 'Coverage',
      dataIndex: 'coverage',
      key: 'coverage',
      render(value, record: any) {
        return (
          <Space direction="vertical" size="small">
            <div>{isEmpty(value) ? 0 : value} ac</div>
            {record.operator && (
              <div>
                {!isNaN(record.operator?.acres_covered)
                  ? record.operator?.acres_covered.toFixed(2)
                  : 0}{' '}
                ac
              </div>
            )}
          </Space>
        );
      },
    },
    {
      title: 'Operator',
      dataIndex: 'operators',
      key: 'operators',
      render(value, record: any) {
        return (
          <Space direction="vertical" size="small">
            <Space>{Array.isArray(value) ? operatorsBlock(value) : ''}</Space>
            {!!record?.operator && (
              <Space>{operatorsBlock([record.operator])}</Space>
            )}
          </Space>
        );
      },
    },
  ];

  const getRow = (record: any) => {
    // setOperationType(record.operation_type);
    setFromView(view);
    selectTractor(record);
    setView('tractor');
    setSelectedDate(moment(record.date));
  };

  return (
    <>
      <section className="background-parent">
        <Row gutter={[20, 20]}>
          <Col xs={24} sm={24} md={24} lg={12} xl={10}>
            <div className="frame-parent">
              <div className="frame-wrapper">
                <div className="frame-group">
                  <div className="back-header">
                    <div className="backButtonBig" onClick={onBack}></div>
                    {/* <img src={Back_Button} height="55" onClick={onBack} /> */}
                    <div className="header">
                      <div className="mowing">{operationType}</div>
                      <div className="date-picker-section">
                        <div className="date-month-selection">
                          {/* November 3<sup>rd</sup> - 9<sup>th</sup> */}
                          {dateRangeText}
                        </div>
                        <div className="">
                          <CDateRangePicker
                            handleOnChange={(dates) => setDateRange(dates)}
                            selectedDate={dateRange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="operations-graph posrel op_detail2" ref={cardRef}>
                {operationsLoader && <ChartSkeleton />}
                <section className="op_tabs_sec">
                  <div className="chip_container">
                    <div className="shape-compiler">
                      <img
                        className="implement-1-icon1"
                        alt=""
                        src={user_fill}
                      />

                      <div className="image-parser">
                        {operationalHoursByOperator?.length}
                      </div>
                    </div>
                  </div>
                  <Tabs
                    defaultActiveKey={tabid}
                    onChange={(key) => {
                      setTabid(key);
                    }}
                    activeKey={tabid}
                  >
                    <TabPane
                      tab={
                        <>
                          <div>
                            <div className="op_tab_title">
                              Operational Hours
                            </div>
                          </div>

                          <div className="wrapper">
                            <div className="div34">
                              <span className="span">
                                {operationalHours?.hours}
                              </span>
                              <span className="h">h</span>
                              <span className="span1">
                                {' '}
                                {operationalHours?.minutes}
                              </span>
                              <span className="m1">m</span>
                            </div>
                          </div>
                        </>
                      }
                      key="operationalHours"
                    >
                      <MultiStackedBarChart
                        className="ophBOmsBarChart gph2"
                        loader={operationsLoader}
                        chartId="OperationalHoursByOperator1"
                        data={operationalHoursByOperator}
                        barColors={[
                          pathColorCodes.manual,
                          pathColorCodes.op_assists,
                          pathColorCodes.autonomy,
                          pathColorCodes.idle,
                        ]}
                        // barColors={['#67afb7', '#ec9322', '#f1ce90', '#cfcfcf']}
                        yTitle="Hours Of Operation"
                        // onClick={OnOperationsClick}
                        tabId={tabid}
                        cardWidth={cardSize.width}
                      />
                    </TabPane>
                    {/* <TabPane
                      tab={
                        <div>
                          <div className="op_tab_title">Coverage (ac)</div>
                          <div className="container">
                            <div className="div35">{coverage}</div>
                          </div>
                        </div>
                      }
                      key="coverage"
                    >
                      <StackedBarChart
                        className="ophBOmsBarChart"
                        loader={operationsLoader}
                        chartId="CoverageByOperations1"
                        data={coverageByOperator}
                        labels={['total']}
                        barColors={[
                          '#4078C0',
                          // '#ec9322',
                          // '#e1b05af7',
                          // '#d9d9d9',
                        ]}
                        yTitle="Acres Covered"
                        // onClick={OnOperationsClick}
                        dataType="area"
                        tabId={tabid}
                        cardWidth={cardSize.width}
                      />
                    </TabPane> */}
                    {/* <TabPane
                      disabled
                      tab={
                        <div>
                          <div className="op_tab_title">Efficiency (ac/hr)</div>
                          <div className="container">
                            <div className="div35">{efficiency}</div>
                          </div>
                        </div>
                      }
                      key="efficiency"
                    >
                      <div className="ophBOmsBarChart"></div>
                    </TabPane> */}
                  </Tabs>
                </section>
              </div>
              <div className="bottom-left-graphs operation3_left">
                <div className="header2">
                  <div className="header2">
                    <div className="title-info">
                      <div className="operations9">Operator Work</div>
                    </div>
                    <div className="shape-compiler">
                      <img
                        className="implement-1-icon1"
                        alt=""
                        src={trip_outline}
                      />

                      <div className="image-parser">
                        {completedWork?.length}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="link-aggregator tableParent">
                  <Table
                    className="completedWorkTable"
                    rowClassName={() => 'operationsTableRowClassName'}
                    columns={columns}
                    dataSource={completedWork}
                    scroll={{ y: 150 }}
                    pagination={{ pageSize: completedWork?.length }}
                    onRow={(record) => {
                      return {
                        onClick: (event) => getRow(record), // click row
                      };
                    }}
                    tableLayout="auto"
                    loading={{
                      indicator: (
                        <div>
                          <Spinner loader={true} />
                        </div>
                      ),
                      spinning: completedWorkLoader,
                    }}
                  />
                </div>
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={14}>
            <div className="scale-scientist opcg-wrapper">
              <div>
                <WeekendsFilter
                  includeWeekends={includeWeekends}
                  toggleWeekends={toggleWeekends}
                />
              </div>
              {/* <div className="coverage-map opcgmap">
                <div className="image-enhancer">
                  <div className="header3">
                    <div className="operations8">Operational Coverage</div>
                  </div>
                </div>
                <img src={cvg_map} style={{ position: 'relative' }} />
              </div> */}
              <OperationalCoverageMap
                operatorId={operatorId}
                onClick={setOperationType}
              />
              <div className="container-18 idle_efficiency">
                <div className="coverage-block1">
                  <div className="cIdelTime">
                    <div className="idelTimeIcon">
                      <img src={idTimeIcon} />
                    </div>
                    <div className="idelTimeSec">
                      <div className="IdelTimeLable">
                        <span>Idle time</span>
                        <span>
                          <Popover
                            overlayClassName="savingPopover"
                            placement="bottomLeft"
                            content="The period when the MK-V is turned on but not actively being driven or used."
                            // content={content}
                            trigger={['click']}
                          >
                            <img className="info-3-icon" src={info_img} />
                          </Popover>
                        </span>
                      </div>
                      <div className="IdelTimeValue">
                        <HrsMins
                          hours={idleTime.hours}
                          minutes={idleTime.minutes}
                        />
                      </div>
                    </div>
                  </div>

                  {/* <div className="idleTimeContainer">
                    <div>
                      <HistoryOutlined className="historyIcon" />
                    </div>
                    <div className="idleTimeLabel">
                      {' '}
                      Idle time{' '}
                      <Popover
                        overlayClassName="savingPopover"
                        placement="bottomLeft"
                        content="popover content"
                        // content={content}
                        trigger={['click']}
                      >
                        <img className="info-3-icon" src={info_img} />
                      </Popover>
                    </div>
                    <div className="idleTime">
                      <HrsMins
                        hours={idleTime.hours}
                        minutes={idleTime.minutes}
                      />
                    </div>
                  </div> */}
                </div>
                <div className="bottom-left-graphs1">
                  <div className="header2 align-top">
                    <div className="align-left graph-call-outs6">
                      <div className="operations4">Tractor usage</div>
                      <div className="proximity-provider"></div>
                    </div>
                    <div className="shape-compiler">
                      <img
                        className="implement-1-icon1"
                        alt=""
                        src={trip_outline}
                      />

                      <div className="image-parser">
                        {tractorEfficiencyData?.length}
                      </div>
                    </div>
                  </div>
                  <div className="tractor-efficiecny text-align-left">
                    {tractorEfficiencyData.map((tractor: any) => {
                      return (
                        <div key={tractor.tractorPin}>
                          <div className="align-stretch">
                            <span>{tractor.tractorPin}</span>
                            <span>
                              <HrsMins
                                hours={tractor.operationalHours.hours}
                                minutes={tractor?.operationalHours.minutes}
                              />
                              {/* {tractor.operationalHours.hours}h{' '}
                              {tractor?.operationalHours.minutes}m */}
                            </span>
                          </div>
                          <Progress
                            status="active"
                            percent={tractor?.percent}
                            showInfo={false}
                          />
                        </div>
                      );
                    })}
                    {/* <div>
                      <div className="align-stretch">
                        <span>10353</span>
                        <span>2h 31m</span>
                      </div>
                      <Progress percent={50} showInfo={false} />
                    </div>
                    <div>
                      <div className="align-stretch">
                        <span>Monarch 1</span>
                        <span>2h 31m</span>
                      </div>
                      <Progress percent={25} showInfo={false} />
                    </div> */}
                  </div>
                </div>
              </div>
              {/* <div className="savings">
                <div className="left-side">
                  <div className="graph-call-outs2">
                    <div className="title-info">
                      <div className="operations9">Total Savings</div>
                      <div className="info-bubbles">
                        <img className="info-3-icon" src={info_img} />
                      </div>
                    </div>
                    <div className="filter-merger">$287.32</div>
                  </div>
                  <div className="image-cropper">
                    <div className="graph-call-outs3">
                      <div className="operations10">CO2 Savings</div>
                      <div className="div49">62.8 lbs</div>
                    </div>
                    <div className="numerical-summarizer">
                      <img className="co2-icon" src={co2_img} />
                    </div>
                  </div>
                </div>
                <div className="savings-graph">
                  <img src={savings_graph} style={{ height: '123px' }} />
                </div>
              </div> */}
            </div>
          </Col>
        </Row>
      </section>
    </>
  );
};

export default OperatorView;
