import React, { useState, useRef, useEffect, useContext, useMemo } from 'react';
import { Card, Breadcrumb, Button, Space, notification } from 'antd';
import BreadcrumbItem from 'antd/lib/breadcrumb/BreadcrumbItem';
import Layout, { Content } from 'antd/lib/layout/layout';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  CaretRightOutlined,
  PauseOutlined,
  LeftOutlined,
  RightOutlined,
} from '@ant-design/icons';

import translate from '../../locale/en_translate.json';
import { getDateTime, sortCameraViews } from '../../constants/Common';
import { getTractorAudioFiles, getVideoURL } from '../../constants/Api';
import { ApplicationContext } from '../../context/AppContext';
import RoutesConstants from '../../routes/RoutesConstant';
import { Pages } from '../library/Library';
import { CamTypes } from '../../constants/types';
import BarHandler from '../common/BarHandler';
import CameraGrid4 from '../map/CameraGrid4';
import AppLoader from '../common/AppLoader';
import PlayBackSpeed from '../common/PlayBackSpeed';

import './style.css';

interface Props {
  stateData: any;
  setCurrentPage: (page: string) => void;
  setStateData: (state: any) => void;
}
const VideoSnaps: React.FC<Props> = ({
  stateData,
  setCurrentPage,
  setStateData,
}: Props) => {
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const ini = useRef(false);
  const iv = useRef<any>();
  const [tractor_id, setTractorId] = useState<number>(0);
  const [updatedTime, setUpdatedTime] = useState<number>(0);
  const [state, setstate] = useState();
  const [videos, setVideos] = useState<any>([]);
  const [playState, setPlayState] = useState(true);
  const [vLength, setVLength] = useState<number>(300);
  const [tractorName, setTractorName] = useState<string>('');
  const [showLoader, setShowLoader] = useState<boolean>(true);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [current, setCurrent] = useState('1');
  const [cameras, setCameras] = useState<string[]>([]);
  const [audioURL, setAudioURL] = useState<string>('');
  const audioRef = useRef<any>(null);
  const audioTimerRef = useRef<any>(null);

  useEffect(() => {
    setstate(stateData);
  }, [stateData]);

  useEffect(() => {
    if (state) {
      const { tractor_id, tractorName, video_time }: any = state;
      setTractorId(tractor_id);
      setTractorName(tractorName);
      setUpdatedTime(video_time);
    }
  }, [state]);

  useEffect(() => {
    return () => {
      setUpdatedTime(0);
      setVideos([]);
      setPlayState(true);
      setVLength(0);
      setRefresh(false);
      setShowLoader(false);
      clearInterval(iv.current);
    };
  }, []);

  const errorRef = useRef<any>({
    front: false,
    rear: false,
    pto: false,
    front_left: false,
    front_right: false,
  });

  const videoRef = useRef<CamTypes>({
    front: undefined,
    rear: undefined,
    pto: undefined,
    front_left: undefined,
    front_right: undefined,
  });

  const checkVideos = () => {
    return Object.values(videoRef.current).map((item) => item !== undefined);
  };

  const loadDuration = () => {
    if (checkVideos().includes(true) && ini.current) {
      const durations = Object.values(videoRef.current)
        .filter((item) => item)
        .map((item: any) => {
          const { player } = item.getState();
          return Math.round(player.duration);
        });
      setVLength(Math.max(...durations));
    }
  };

  const play = () => {
    setPlayState(false);
    Object.values(videoRef.current).forEach((item: any) => {
      if (item) {
        item.play();
      }
    });
  };

  const pause = () => {
    setPlayState(true);
    Object.values(videoRef.current).forEach((item: any) => {
      if (item) {
        item.pause();
      }
    });
  };

  const playbackRate = (steps: number) => {
    setPlayState(true);
    Object.values(videoRef.current).forEach((item: any) => {
      if (item) {
        item.video.video.playbackRate = steps;
        // const { player } = item.getState();
        // player.playbackRate = player.playbackRate + steps;
        item.pause();
      }
    });
  };

  const delay = (ms: number) =>
    new Promise<void>((resolve) => setTimeout(() => resolve(), ms));

  const seek = async (seconds: number) => {
    setPlayState(true);
    Object.values(videoRef.current).forEach((item: any) => {
      if (item) {
        item.seek(seconds);
        item.pause();
      }
    });
    await delay(3000);
    play();
  };

  const rightArrowClick = () => {
    setUpdatedTime((prev: any) => {
      return moment(prev).add(5, 'minutes').valueOf();
    });
  };

  const leftArrowClick = () => {
    setUpdatedTime((prev: any) => {
      return moment(prev).subtract(5, 'minutes').valueOf();
    });
  };

  const getVideoData = async () => {
    try {
      pause();
      ini.current = false;
      setShowLoader(true);
      let response = await getVideoURL(
        userDetails.organization.api_url,
        userDetails.organization.farm.id,
        tractor_id,
        updatedTime,
      );
      response = sortCameraViews(response, 'direction', 'videos');
      const cams = response.reduce(
        (a: any, data: any) => [...a, data.direction],
        [],
      );
      setCameras(cams);
      response = response
        .map((item: any) => {
          item.direction = item.direction.replace('-', '_');
          return item;
        })
        .filter((item: any) =>
          ['front', 'rear', 'pto', 'front_left', 'front_right'].includes(
            item.direction,
          ),
        );
      setVideos(response);
    } finally {
      setShowLoader(false);
      setRefresh(!refresh);
    }
  };

  const load = () => {
    Object.values(videoRef.current).forEach((item: any) => {
      if (item) {
        item.load();
      }
    });
  };

  const forward = (seconds: number) => {
    Object.values(videoRef.current).forEach((item: any) => {
      if (item) {
        item.video.video.currentTime = item.video.video.currentTime + seconds;
        item.seek(item.video.video.currentTime);
      }
    });
  };

  const openFullscreen = (videoUrl: any) => {
    videoUrl &&
      window.open(
        `${RoutesConstants.VideoPop}?videoUrl=${videoUrl}`,
        `Video URL`,
        'max-width: 100%',
      );
  };

  // const onError = (face: string) => {
  //   if (videoRef.current[face]) {
  //     const { player } = videoRef.current[face]?.getState();
  //     errorRef.current[face] = player.error ? true : false;
  //   }
  // };

  // const setMuted = (muted) => {
  //   Object.values(videoRef.current).map((item) => {
  //     if (item instanceof Player) {
  //       item.muted = muted;
  //     }
  //   });
  // };

  // const changeVolume = (steps:) => {
  //   Object.values(videoRef.current).map((item: Player) => {
  //     if (item instanceof Player) {
  //       item.volume = item.volume + steps;
  //     }
  //   });
  // };

  useEffect(() => {
    if (userDetails && userDetails.organization && updatedTime && tractor_id) {
      setVideos([]);
      getVideoData();
    }
  }, [updatedTime, tractor_id]);

  useEffect(() => {
    const checkReadyState = () => {
      ini.current = false;
      if (!checkVideos().includes(true)) return;
      const readyStates = Object.values(videoRef.current)
        .filter((item) => item)
        .every((item: any) => {
          if (item?.getState()) {
            const { player } = item.getState();
            errorRef.current[item.direction] = Boolean(player?.error);
            return player.readyState === 4;
          }
          return false;
        });
      if (readyStates) {
        ini.current = true;
        setRefresh(!refresh);
        clearInterval(iv.current);
      }
    };

    if (videos.length)
      iv.current = setInterval(() => {
        checkReadyState();
      }, 1000);

    return () => clearInterval(iv.current);
  }, [videos]);

  useEffect(() => {
    loadDuration();
    return () => {
      setRefresh(false);
    };
  }, [refresh]);

  const selectSpeed = (speed: number, e: any) => {
    setCurrent(e.key);
    playbackRate(speed);
  };

  useEffect(() => {
    const iniiAudioFiles = async () => {
      const { organization } = userDetails;
      const { api_url, id } = organization;
      try {
        const data: any = await getTractorAudioFiles(
          api_url,
          id,
          tractor_id,
          updatedTime,
        );
        if (data?.length) setAudioURL(data[0]?.audio_url);
      } catch (error: any) {
        notification.error({ message: error?.message });
      }
    };
    if (userDetails?.organization && updatedTime) iniiAudioFiles();
  }, [updatedTime]);

  const Audio = useMemo(
    () =>
      audioURL && (
        <div className="audioContainer">
          <div className="hitchLabel">{t(translate.snapShotView.audio)}</div>
          <div style={{ width: '80vw' }}>
            <audio ref={audioRef} controls className="audioBlock">
              <source src={audioURL} type="audio/mpeg" />
            </audio>
          </div>
        </div>
      ),
    [audioURL, audioRef],
  );

  useEffect(() => {
    audioTimerRef.current = setInterval(() => {
      const doc: any = document;
      const ele = doc.querySelector('.video_progress__filled');
      if (ele) {
        const currentTime = ele.dataset.time || 0;
        if (audioRef.current && !playState) {
          if (currentTime < audioRef.current.duration) {
            audioRef.current.play();
            audioRef.current.currentTime = currentTime;
          } else {
            audioRef.current.pause();
          }
        } else {
          audioRef.current?.pause();
        }
      }
    }, 1000);
    return () => {
      clearInterval(audioTimerRef.current);
    };
  }, [audioURL, playState]);

  return (
    <Layout>
      <div
        className="mainContent common_wrapper dayselDate"
        style={{ position: 'relative' }}
      >
        <Breadcrumb>
          <BreadcrumbItem
            onClick={() => {
              setCurrentPage(Pages.RecordedVideo);
              // push({
              //   pathname: RoutesConstants.RecordedVideo,
              // })
            }}
          >
            <a>{t(translate.recordedVideo.RecordedVideo)}</a>
          </BreadcrumbItem>
          <BreadcrumbItem
            onClick={() => {
              setCurrentPage(Pages.DayView);
              setStateData(state);
              // push({
              //   pathname: RoutesConstants.DayView,
              //   state: state,
              // })
            }}
          >
            <a>{tractorName}</a>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <span style={{ color: '#EB921F' }}>
              {t(translate.snapShotView.videos)}
            </span>
          </BreadcrumbItem>
        </Breadcrumb>
        <Content>
          <Space
            className="mt0"
            style={{
              marginBottom: 0,
              position: 'absolute',
              top: 10,
              right: 10,
            }}
          >
            <p style={{ fontFamily: 'Montserrat-Medium' }}>
              <a style={{ fontWeight: 'bold' }} onClick={leftArrowClick}>
                <LeftOutlined />
              </a>{' '}
              <span>{updatedTime && getDateTime(updatedTime)}</span>
              <a style={{ fontWeight: 'bold' }} onClick={rightArrowClick}>
                {' '}
                <RightOutlined />
              </a>
            </p>
          </Space>
          <Card
            bordered={true}
            className="dbWidget videoDbCon"
            style={{ width: '100%' }}
          >
            <div className="library-video-container hide-controls">
              <CameraGrid4
                videos={videos}
                errorRef={errorRef}
                videoRef={videoRef}
                showLoader={showLoader}
                cameras={cameras}
                playState={playState}
                play={play}
              />
              {videos.length > 0 && (
                <div className="barDisplay">
                  <Button
                    className="okBtn"
                    onClick={() => (playState ? play() : pause())}
                  >
                    {playState ? <CaretRightOutlined /> : <PauseOutlined />}
                  </Button>
                  <div className="video_progress">
                    <div className="video_progress__filled"></div>
                  </div>
                  <div
                    style={{
                      width: '140px',
                      fontFamily: 'Montserrat-SemiBold',
                    }}
                  >
                    {!!document.getElementsByClassName('video_progress')
                      .length && (
                      <BarHandler
                        videoRef={videoRef}
                        seek={seek}
                        vLength={vLength}
                      />
                    )}
                  </div>
                  <PlayBackSpeed selectSpeed={selectSpeed} speed={current} />
                </div>
              )}
            </div>
            <AppLoader loader={showLoader} />
          </Card>
          {Audio}
        </Content>
      </div>
    </Layout>
  );
};

export default VideoSnaps;
