import React from 'react';
import plusIconNew from '../../assets/images/plusIconNew.svg';

interface Props {
  onClick: () => void;
  label: string;
  width?: number;
}

const AddButton: React.FC<Props> = ({ onClick, label, width }) => {
  return (
    <div
      className="defaultBtn"
      style={{ width: width ? width : 'auto' }}
      onClick={onClick}
    >
      <img src={plusIconNew} height="10px" />
      <span className="addBtnLabel">{label}</span>
    </div>
  );
};

export default AddButton;
