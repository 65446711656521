import React, { useState } from 'react';
import { notification } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import copyIcon from '../../../assets/images/copy_icon.png';

interface Props {
  password: any;
}

const ViewBaseStationPassword: React.FC<Props> = ({ password }: Props) => {
  const [showpassword, setshowpassword] = useState<boolean>(false);

  return (
    <div className="hoverWrapper installationkey viewbaseSationPassword">
      <span>{showpassword ? password : '* * * * * *'}</span>
      <div
        className="showPwdIcon"
        onClick={() => {
          setshowpassword(!showpassword);
        }}
      >
        <>{showpassword ? <EyeTwoTone /> : <EyeInvisibleOutlined />}</>
      </div>
      <img
        src={copyIcon}
        alt="Edit"
        height="32"
        width="32"
        className="marginLeftIcon hoverShow1 on-hover-hand-change"
        onClick={() => {
          navigator.clipboard.writeText(password);
          notification.info({ message: 'Copied' });
        }}
      />
    </div>
  );
};

export default ViewBaseStationPassword;
