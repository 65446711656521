import { Layout, notification } from 'antd';
import React, { useState, useEffect, useContext, useRef } from 'react';
import { getBasestationList } from '../../constants/Api';
import { BaseStationResponse } from '../../constants/types';
import { ApplicationContext } from '../../context/AppContext';
import { useTranslation } from 'react-i18next';
import translate from '../../locale/en_translate.json';
import Editbasestation from './Editbasestation';
import InfiniteScrollTable from '../common/InfiniteScrollTable';
import sortDown from '../../assets/images/sort_down.svg';
import sortUp from '../../assets/images/sort_up.svg';
import {
  getTractorStatusClass,
  initClearSelection,
  initScroller,
  sortString,
} from '../../constants/Common';
import { track } from '../../util/logger';
import { mapBasestationData } from '../../lib/dataFormat';
import usePaginate from '../../hooks/usePaginate';

interface Props {
  search: string;
}
const BaseStation: React.FC<Props> = ({ search }: Props) => {
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const [pageSize] = useState<any>(25);
  const [baseflag, setbaseflag] = useState<boolean>(false);
  const [baseobj, setbaseData] = useState<any>(null);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const [sortedInfo, setSortedInfo] = useState<any>({
    columnKey: 'name',
    order: 'ascend',
  });
  const [refresh, setRefresh] = useState(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [sortedData, setSortedData] = useState<any>({
    name: 'ascend',
  });
  const [baseStationNames, setBaseStationNames] = useState<string[]>([]);
  const [initialize, setInitialize] = useState<boolean>(false);
  const searchString = useRef<string>('');

  const { filterData, setData, activity, hasMore, checkActivity } =
    usePaginate();

  const columns = [
    {
      render: (name: string) => {
        return <span data-testid={`${name}-BaseStation`}>{name}</span>;
      },
      title: () => {
        return (
          <>
            {t(translate.fleet.name)}
            <span className="sort-pad">
              <img
                className="sort-pad"
                src={sortedData['name'] === 'ascend' ? sortUp : sortDown}
              />
            </span>
          </>
        );
      },
      defaultSortOrder: 'ascend',
      sortDirections: ['descend', 'ascend', 'descend'],
      sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
      ellipsis: true,
      dataIndex: 'name',
      key: 'name',
      width: '20%',
      showSorterTooltip: false,
      sorter: true,
      onCell: (record: BaseStationResponse) => ({
        onClick: () => getRow(record),
      }),
    },
    {
      title: `${t(translate.fleet.status)}`,
      width: '30%',
      render: (record: any) => {
        return (
          <span className={getTractorStatusClass(record.status)}>
            {record.status}
          </span>
        );
      },
      onCell: (record: any) => ({
        onClick: () => getRow(record),
      }),
    },

    {
      title: `${t(translate.fleet.pin)}`,
      dataIndex: 'bsin',
      key: 'bsin',
      width: '30%',
      onCell: (record: any) => ({
        onClick: () => getRow(record),
      }),
    },
    {
      title: `${t(translate.fleet.softwareVersion)}`,
      dataIndex: 'software_version',
      key: 'software_version',
      width: '30%',
      render: (version: string, record: any) => (
        <div className="versionList">
          <span className="vSNo vs">{version}</span>
        </div>
      ),
      onCell: (record: any) => ({
        onClick: () => getRow(record),
      }),
    },
  ];

  const wrapResult = async (
    apiUrl: string,
    ogId: number,
    pageNumber: number,
    pageSize: number,
    search: string,
    sortedInfo: string,
  ) => {
    const result = await getBasestationList(
      apiUrl,
      ogId,
      pageNumber,
      pageSize,
      search,
      sortedInfo,
    );
    return {
      result,
      searchKey: search,
    };
  };

  const getBasestations = async () => {
    if (checkActivity()) initScroller();
    setLoading(true);
    try {
      const { result, searchKey } = await wrapResult(
        userDetails.organization.api_url,
        userDetails.organization.id,
        pageNumber,
        pageSize,
        search,
        sortedInfo,
      );

      if (searchString.current !== searchKey) return;

      setTotal(result?._metadata?.total_records_count);
      const { records } = result;
      let data = Array.isArray(records) ? records : [];
      data = mapBasestationData(data);
      setData(data);
    } catch (err: any) {
      notification.error({
        message: err?.message,
      });
    } finally {
      setLoading(false);
      if (!initialize) setInitialize(true);
    }
  };

  const getRow = (record: BaseStationResponse) => {
    setbaseData(record);
    setbaseflag(true);
    track('BaseStation', {
      event: `${record.name} Clicked`,
    });
  };

  const Close = () => {
    setbaseflag(false);
    setbaseData(null);
    initClearSelection();
    track('Edit BaseStation', {
      event: 'Edit baseStation Closed',
    });
  };

  const handleLoadMore = () => {
    if (pageNumber === 1 && checkActivity() && document) initScroller();

    activity.current = 'paginate';
    setPageNumber(pageNumber + 1);
  };

  const handleChange = (pagination: any, filters: any, sorter: any) => {
    activity.current = 'sort';
    const { columnKey, order } = sorter;
    setSortedData({ ...sortedData, [columnKey]: order });
    setSortedInfo(sorter);
  };

  useEffect(() => {
    if (userDetails && userDetails.organization) loadBaseStations();
  }, [userDetails, pageNumber, refresh]);

  useEffect(() => {
    searchString.current = search;
    activity.current = 'search';

    if (userDetails && userDetails.organization && initialize)
      loadBaseStations();
  }, [search]);

  useEffect(() => {
    const bNames = filterData.map((item: any) => item.name);
    if (baseobj?.id) {
      const [selectedBs] = filterData.filter(
        (item: any) => item.id === baseobj.id,
      );
      setbaseData(selectedBs);
    }
    setBaseStationNames(bNames);
  }, [filterData]);

  useEffect(() => {
    if (sortedInfo.order && initialize) loadBaseStations();
  }, [sortedInfo.order]);

  const loadBaseStations = () => {
    if (userDetails && !userDetails.organization) return;
    if (checkActivity()) {
      pageNumber !== 1 ? setPageNumber(1) : getBasestations();
    } else if (activity.current === 'paginate' || activity.current === '') {
      getBasestations();
    }
  };

  return (
    <Layout>
      {baseflag && (
        <Editbasestation
          baseobj={baseobj}
          Close={Close}
          setRefresh={() => setRefresh(!refresh)}
          baseflag={baseflag}
          baseStationNames={baseStationNames}
        ></Editbasestation>
      )}

      <div className="common_table">
        <InfiniteScrollTable
          columns={columns}
          hasMore={hasMore}
          loading={loading}
          filterData={sortString(filterData, 'status')}
          totalcount={total}
          handleLoadMore={handleLoadMore}
          filename="Base Stations"
          onChange={handleChange}
        />
      </div>
    </Layout>
  );
};

export default BaseStation;
