/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-vars */
import { Button, Card, Modal, Space, notification } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CloudDownloadOutlined, CloudUploadOutlined } from '@ant-design/icons';
import { PubSub } from 'aws-amplify';
import './style.css';
import translate from '../../../locale/en_translate.json';
import { getDateTime } from '../../../constants/Common';

interface Props {
  showModal: boolean;
  handleCancel: () => void;
  baseStationHealthDetails: any;
}
export const BaseStationHealthModal: React.FC<Props> = ({
  showModal,
  handleCancel,
  baseStationHealthDetails,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Modal
      className="EmployeeModalContainer fm-modal thmDetails"
      centered
      visible={showModal}
      onCancel={handleCancel}
      maskClosable={false}
      footer={false}
      title={baseStationHealthDetails.name}
    >
      <div className="stationHealthContainer">
        <div className="indiContainer bsBg">
          <div className="indiBlockRow">
            <div className="col2-styles">
              <div className="orgDetails detailTxt">
                <p>{t(translate.healthData.name)}</p>
              </div>
              <div className="orgData">
                <p>{baseStationHealthDetails?.name}</p>
              </div>
            </div>
            <div className="col2-styles">
              <div className="orgDetails detailTxt">
                <p>{t(translate.healthData.serialNumber)}</p>
              </div>
              <div className="orgData">
                <p>{baseStationHealthDetails?.serial_number}</p>
              </div>
            </div>
          </div>
          <div className="indiBlockRow">
            <div className="col2-styles">
              <div className="orgDetails detailTxt">
                <p>{t(translate.healthData.status)}</p>
              </div>
              <div className="orgData">
                <p>{baseStationHealthDetails?.jng}</p>
              </div>
            </div>
            <div className="col2-styles">
              <div className="orgDetails detailTxt">
                <p>{t(translate.healthData.orgName)}</p>
              </div>
              <div className="orgData">
                <p>{baseStationHealthDetails?.orgName}</p>
              </div>
            </div>
          </div>
          <div className="indiBlockRow">
            <div className="col2-styles">
              <div className="orgDetails detailTxt">
                <p>{t(translate.basestationHealthModal.lastOnlineTime)}</p>
              </div>
              <div className="orgData">
                <p>
                  {getDateTime(
                    baseStationHealthDetails?.connection?.last_online_time,
                  )}
                </p>
              </div>
            </div>
            <div className="col2-styles">
              <div className="orgDetails detailTxt">
                <p>{t(translate.basestationHealthModal.lastOfflineTime)}</p>
              </div>
              <div className="orgData">
                <p>
                  {getDateTime(
                    baseStationHealthDetails?.connection?.last_offline_time,
                  )}
                </p>
              </div>
            </div>
          </div>
          <div className="indiBlockRow">
            <div className="col2-styles">
              <div className="orgDetails detailTxt">
                <p>{t(translate.logViewer.modelName)}</p>
              </div>
              <div className="orgData">
                <p>{baseStationHealthDetails?.basestation_model}</p>
              </div>
            </div>
            <div className="col2-styles">
              <div className="orgDetails detailTxt">
                <p>
                  {t(
                    translate.basestationHealthModal.basestationSoftwareVersion,
                  )}
                </p>
              </div>
              <div className="orgData">
                <p>
                  {
                    baseStationHealthDetails?.heartbeat
                      ?.basestation_software_version
                  }
                </p>
              </div>
            </div>
          </div>

          <div className="indiBlockRow">
            <div className="col2-styles">
              <div className="orgDetails detailTxt">
                <p>{t(translate.healthData.routerstatus)}</p>
              </div>
              <div className="orgData">
                <p>{baseStationHealthDetails?.router_status}</p>
              </div>
            </div>
            <div className="col2-styles">
              <div className="orgDetails detailTxt">
                <p>{t(translate.healthData.sbcstatus)}</p>
              </div>
              <div className="orgData">
                <p>{baseStationHealthDetails?.sbc_status}</p>
              </div>
            </div>
          </div>
          <div className="indiBlockRow">
            <div className="col2-styles">
              <div className="orgDetails detailTxt">
                <p>{t(translate.healthData.tractorSoftwareVersion)}</p>
              </div>
              <div className="orgData">
                <p>
                  {baseStationHealthDetails?.heartbeat
                    ?.tractor_software_version || 'NA'}
                </p>
              </div>
            </div>
            <div className="col2-styles">
              <div className="orgDetails detailTxt">
                <p>{t(translate.healthData.ipAddress)}</p>
              </div>
              <div className="orgData">
                <p>{baseStationHealthDetails?.ip_address}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="indiContainer bsBg">
          <h3 className="indiHead">
            {t(translate.basestationHealthModal.ntripDetails)}
          </h3>
          <div className="indiBlockRow">
            <div className="col2-styles">
              <div className="orgDetails detailTxt">
                <p>{t(translate.basestationHealthModal.casterIp)}</p>
              </div>
              <div className="orgData">
                <p>{baseStationHealthDetails?.heartbeat?.NTRIP_caster_IP}</p>
              </div>
            </div>
            <div className="col2-styles">
              <div className="orgDetails detailTxt">
                <p>{t(translate.basestationHealthModal.casterPort)}</p>
              </div>
              <div className="orgData">
                <p>{baseStationHealthDetails?.heartbeat?.NTRIP_caster_PORT}</p>
              </div>
            </div>
          </div>
          <div className="indiBlockRow">
            <div className="col2-styles">
              <div className="orgDetails detailTxt">
                <p>{t(translate.basestationHealthModal.casterMountPoint)}</p>
              </div>
              <div className="orgData">
                <p>
                  {baseStationHealthDetails?.heartbeat?.NTRIP_caster_mountpoint}
                </p>
              </div>
            </div>
            <div className="col2-styles">
              <div className="orgDetails detailTxt">
                <p>{t(translate.basestationHealthModal.casterConnectivity)}</p>
              </div>
              <div className="orgData">
                <p>
                  {
                    baseStationHealthDetails?.heartbeat
                      ?.NTRIP_caster_connectivity
                  }
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="indiContainer bsBg">
          <h3 className="indiHead">
            {t(translate.basestationHealthModal.systemStatus)}
          </h3>
          <div className="indiBlockRow">
            <div className="col2-styles">
              <div className="orgDetails detailTxt">
                <p>{t(translate.basestationHealthModal.cpuTemp)}</p>
              </div>
              <div className="orgData">
                <p>
                  {
                    baseStationHealthDetails?.heartbeat?.system_status?.sbc
                      ?.cpu_temp
                  }
                </p>
              </div>
            </div>
            <div className="col2-styles">
              <div className="orgDetails detailTxt">
                <p>{t(translate.basestationHealthModal.cpuUsage)}</p>
              </div>
              <div className="orgData">
                <p>
                  {
                    baseStationHealthDetails?.heartbeat?.system_status?.sbc
                      ?.cpu_usage
                  }
                </p>
              </div>
            </div>
          </div>
          <div className="indiBlockRow">
            <div className="col2-styles">
              <div className="orgDetails detailTxt">
                <p>{t(translate.basestationHealthModal.ramUsage)}</p>
              </div>
              <div className="orgData">
                <p>
                  {
                    baseStationHealthDetails?.heartbeat?.system_status?.sbc
                      ?.ram_usage
                  }
                </p>
              </div>
            </div>
            <div className="col2-styles">
              <div className="orgDetails detailTxt">
                <p>{t(translate.basestationHealthModal.diskUsage)}</p>
              </div>
              <div className="orgData">
                <p>
                  {
                    baseStationHealthDetails?.heartbeat?.system_status?.sbc
                      ?.disk_usage
                  }
                </p>
              </div>
            </div>
          </div>
          <div className="indiBlockRow">
            <div className="col2-styles">
              <div className="orgDetails detailTxt">
                <p>{t(translate.healthData.activeNetwork)}</p>
              </div>
              <div className="orgData">
                <p>
                  {
                    baseStationHealthDetails?.heartbeat?.system_status
                      ?.active_network
                  }
                </p>
              </div>
            </div>
            <div className="col2-styles">
              <div className="orgDetails detailTxt">
                <p>{t(translate.basestationHealthModal.freeSpace)}</p>
              </div>
              <div className="orgData">
                <p>
                  {
                    baseStationHealthDetails?.heartbeat?.system_status?.sbc
                      ?.free_space
                  }
                </p>
              </div>
            </div>
          </div>
          <div className="indiBlockRow">
            <div className="col2-styles">
              <div className="orgDetails detailTxt">
                <p>{t(translate.basestationHealthModal.rtkConfigured)}</p>
              </div>
              <div className="orgData">
                <p>
                  {
                    baseStationHealthDetails?.heartbeat?.system_status
                      ?.radio_rtk_status
                  }
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="indiContainer bsBg">
          <h3 className="indiHead">
            {t(translate.basestationHealthModal.currentGeoLocation)}
          </h3>
          <div className="indiBlockRow">
            <div className="col2-styles">
              <div className="orgDetails detailTxt">
                <p>{t(translate.mountPoint.latitude)}</p>
              </div>
              <div className="orgData">
                <p>
                  {
                    baseStationHealthDetails?.heartbeat?.current_geo_location
                      ?.latitude
                  }
                </p>
              </div>
            </div>
            <div className="col2-styles">
              <div className="orgDetails detailTxt">
                <p>{t(translate.mountPoint.longitude)}</p>
              </div>
              <div className="orgData">
                <p>
                  {
                    baseStationHealthDetails?.heartbeat?.current_geo_location
                      ?.longitude
                  }
                </p>
              </div>
            </div>
          </div>

          <div className="indiBlockRow">
            <div className="col2-styles">
              <div className="orgDetails detailTxt">
                <p>{t(translate.basestationHealthModal.gnssTime)}</p>
              </div>
              <div className="orgData">
                <p>
                  {
                    baseStationHealthDetails?.heartbeat?.current_geo_location
                      ?.gnss_time
                  }
                </p>
              </div>
            </div>
            <div className="col2-styles">
              <div className="orgDetails detailTxt">
                <p>{t(translate.basestationHealthModal.gnssDate)}</p>
              </div>
              <div className="orgData">
                <p>
                  {getDateTime(
                    baseStationHealthDetails?.heartbeat?.current_geo_location
                      ?.gnss_date,
                  )}
                </p>
              </div>
            </div>
          </div>

          <div className="indiBlockRow">
            <div className="col2-styles">
              <div className="orgDetails detailTxt">
                <p>{t(translate.basestationHealthModal.latitudeDirection)}</p>
              </div>
              <div className="orgData">
                <p>
                  {
                    baseStationHealthDetails?.heartbeat?.current_geo_location
                      ?.latitude_direction
                  }
                </p>
              </div>
            </div>
            <div className="col2-styles">
              <div className="orgDetails detailTxt">
                <p>{t(translate.basestationHealthModal.longitudeDirection)}</p>
              </div>
              <div className="orgData">
                <p>
                  {
                    baseStationHealthDetails?.heartbeat?.current_geo_location
                      ?.longitude_direction
                  }
                </p>
              </div>
            </div>
          </div>

          <div className="indiBlockRow">
            <div className="col2-styles">
              <div className="orgDetails detailTxt">
                <p>{t(translate.basestationHealthModal.gpsStatus)}</p>
              </div>
              <div className="orgData">
                <p>
                  {
                    baseStationHealthDetails?.heartbeat?.current_geo_location
                      ?.gps_status
                  }
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="indiContainer bsBg">
          <h3 className="indiHead">
            {t(translate.basestationHealthModal.surveyedGeoLocation)}
          </h3>
          <div className="indiBlockRow">
            <div className="col2-styles">
              <div className="orgDetails detailTxt">
                <p>{t(translate.mountPoint.latitude)}</p>
              </div>
              <div className="orgData">
                <p>
                  {
                    baseStationHealthDetails?.heartbeat
                      ?.bs_surveyed_geo_location?.latitude
                  }
                </p>
              </div>
            </div>
            <div className="col2-styles">
              <div className="orgDetails detailTxt">
                <p>{t(translate.mountPoint.longitude)}</p>
              </div>
              <div className="orgData">
                <p>
                  {
                    baseStationHealthDetails?.heartbeat
                      ?.bs_surveyed_geo_location?.longitude
                  }
                </p>
              </div>
            </div>
          </div>

          <div className="indiBlockRow">
            <div className="col2-styles">
              <div className="orgDetails detailTxt">
                <p>{t(translate.mountPoint.altitude)}</p>
              </div>
              <div className="orgData">
                <p>
                  {
                    baseStationHealthDetails?.heartbeat
                      ?.bs_surveyed_geo_location?.altitude
                  }
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="indiContainer bsBg">
          <h3 className="indiHead">
            {t(translate.basestationHealthModal.radioDetails)}
          </h3>
          <div className="indiBlockRow">
            <div className="col2-styles">
              <div className="orgDetails detailTxt">
                <p>{t(translate.basestationHealthModal.radioPowerStatus)}</p>
              </div>
              <div className="orgData">
                <p>
                  {
                    baseStationHealthDetails?.heartbeat?.bs_model
                      ?.radio_power_status
                  }
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="indiContainer bsBg">
          <h3 className="indiHead">
            {t(translate.basestationHealthModal.connectedTractors)}
          </h3>
          <div className="indiBlockRow connectedTractors">
            {baseStationHealthDetails?.heartbeat?.tractors_connected?.List.map(
              (ele: any) => (
                <div className="col2-styles">
                  <div className="orgDetails detailTxt">
                    <p>{ele}</p>
                  </div>
                </div>
              ),
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default BaseStationHealthModal;
