/* eslint-disable no-console */
import { Input, Modal, notification, Select, Spin } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { getPolyColorData, setPolygon } from '../../constants/Api';
import {
  addingDefaultAltitude,
  handlePolyExistInBrown,
  makeChunkApiCalls,
} from '../../constants/Common';
import { LatLngVertices, PolyColor, PolyPayload } from '../../constants/types';
import { ApplicationContext } from '../../context/AppContext';
import translate from '../../locale/en_translate.json';

import { useTranslation } from 'react-i18next';
import blue from '../../assets/images/layer_icons/layer-blue.svg';
import brown from '../../assets/images/layer_icons/layer-boundary.svg';
import green from '../../assets/images/layer_icons/layer-green.svg';
import red from '../../assets/images/layer_icons/layer-red.svg';
import white from '../../assets/images/layer_icons/layer-white.svg';
import yellow from '../../assets/images/layer_icons/layer-yellow.svg';
import { brownColor } from '../../constants/constant';

interface Props {
  showModal: boolean;
  handleCancel: (reload?: boolean, brownPoly?: any) => void;
  polyPayload: PolyPayload;
  brownFeatureItems: Map<number, any>;
  createdPolyFeatureItem: any;
  brownPolygonsLength: number;
}
export const AddPoly: React.FC<Props> = ({
  showModal,
  handleCancel,
  polyPayload,
  brownFeatureItems,
  createdPolyFeatureItem,
  brownPolygonsLength,
}: Props) => {
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const [name, setName] = useState<string>('');
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [loader, setLoader] = useState<boolean>(false);
  const [polyColors, setPolyColors] = useState<PolyColor[]>([]);
  const [color, setSelectedColor] = useState('');
  const [latLongVertices, setLatLongVertices] = useState<LatLngVertices[]>([]);

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      getPolyColors();
    }
  }, [userDetails]);

  useEffect(() => {
    const onInIt = async () => {
      if (
        polyPayload &&
        polyPayload.lat_long_vertices &&
        polyPayload.lat_long_vertices.length > 0
      ) {
        const lat_long_vertices1 = addingDefaultAltitude(
          polyPayload.lat_long_vertices,
        );
        setLatLongVertices(lat_long_vertices1);
        const lat_long_vertices = await makeChunkApiCalls(
          polyPayload.lat_long_vertices,
        );
        lat_long_vertices &&
          lat_long_vertices.length > 0 &&
          setLatLongVertices(lat_long_vertices);
      }
    };
    onInIt();
  }, [polyPayload]);
  const getPolyColors = async () => {
    try {
      setLoader(true);
      const { organization } = userDetails;
      const response = await getPolyColorData(
        organization.api_url,
        organization.farm.id,
      );
      if (response && response.length > 0) {
        const col = brownPolygonsLength === 0 ? response.length - 1 : 0;
        setSelectedColor(response[col].value);
      }
      setPolyColors(response);
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    if (
      userDetails &&
      userDetails.organization &&
      name &&
      name.trim().length > 0 &&
      color &&
      color.trim().length > 0 &&
      latLongVertices &&
      latLongVertices.length > 0 &&
      ((handlePolyExistInBrown(brownFeatureItems, createdPolyFeatureItem) &&
        color !== brownColor) ||
        color == brownColor)
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [userDetails, name, color]);

  const AddPolygon = async () => {
    try {
      setLoader(true);
      const payload = {
        name: name.trim(),
        color,
        lat_long_vertices: latLongVertices,
        area: polyPayload.area,
      };
      setName(name.trim());

      const { organization } = userDetails;
      const resp = await setPolygon(
        organization.api_url,
        organization.farm.id,
        payload,
      );
      if (resp && resp.msg) {
        if (color === 'brown') {
          resp.vertices.map((item: any) => {
            item.x = item.x * 100;
            item.y = item.y * 100;
          });
          resp.polygone.vertices = resp.vertices;
          handleCancel(true, resp.polygone);
        } else {
          handleCancel(true);
        }
        notification.success({
          message: resp.msg,
        });
      }
    } catch (err: any) {
      notification.error({
        message: err?.message,
      });
    } finally {
      setLoader(false);
    }
  };
  const handleSelect = (e: any) => {
    setSelectedColor(e);
  };
  const getImage = (color: string): string => {
    let image;
    switch (color) {
      case 'red':
        image = red;
        break;

      case 'white':
        image = white;
        break;

      case 'green':
        image = green;
        break;

      case 'blue':
        image = blue;
        break;

      case 'brown':
        image = brown;
        break;

      case 'yellow':
        image = yellow;
        break;
      default:
        image = green;
        break;
    }
    return image;
  };
  return (
    <Modal
      className="commonPopup addPolygon adpSpl"
      title={`Add ${t(translate.map.boundary)}`}
      centered
      closable={false}
      width={'60vw'}
      visible={showModal}
      onCancel={() => handleCancel()}
      okText="Save"
      onOk={AddPolygon}
      okButtonProps={{
        className: 'btnSave ',
        disabled: isDisabled,
      }}
      cancelButtonProps={{
        className: 'btnCancel',
      }}
    >
      <div className="formRow">
        <div className="formCol">
          <label className="formLabel">
            Name <span style={{ color: 'red' }}> *</span>
          </label>
          <Input
            className="commInput"
            onChange={(e) => setName(e.target.value)}
          />

          <div className="edit-loader">
            {loader && (
              <div className="loader">
                <Spin size="large" />
              </div>
            )}
          </div>
        </div>
        <div className="formCol">
          <label className="formLabel">
            Color <span style={{ color: 'red' }}> *</span>
          </label>
          <Select
            className="customSelect"
            onSelect={handleSelect}
            value={color}
          >
            {polyColors.map((data: any) => {
              if (data.name !== '') {
                return (
                  <option value={data.value} key={data.value}>
                    <span key={data.value}>
                      <img
                        src={getImage(data.name.toLowerCase())}
                        className="ploy_icon"
                      />{' '}
                      {data.name.toLowerCase() === 'brown'
                        ? 'Farm Boundary'
                        : data.description}
                    </span>
                  </option>
                );
              }
            })}
          </Select>
        </div>
      </div>
      <div className="formRow">
        <div className="formColFull">
          <label className="formLabel">
            Vertices <span style={{ color: 'red' }}> *</span>
          </label>
          <div className="verticsSec">
            {latLongVertices.map((data: any) => {
              return (
                <>
                  <div>
                    ({data?.latitude}, {data?.longitude}, {data?.altitude})
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
      {color !== brownColor &&
        !handlePolyExistInBrown(brownFeatureItems, createdPolyFeatureItem) && (
          <div className="formRow">
            <div className="formColFull">
              <label className="formLabel" style={{ color: 'red' }}>
                The polygon being drawn should be within the overall Farm
                Boundary (Brown)
              </label>
            </div>
          </div>
        )}
    </Modal>
  );
};
export default AddPoly;
