import React, { useContext, useEffect, useState } from 'react';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import translate from '../../locale/en_translate.json';
import { Actions, ActionsData } from '../../constants/types';
import { getActions } from '../../constants/Api';
import { ApplicationContext } from '../../context/AppContext';
import { getDateTime, getTime } from '../../constants/Common';
import InfiniteScrollTable from '../common/InfiniteScrollTable';

interface Props {
  actiondata: Actions;
}
const JngTabActions: React.FC<Props> = ({ actiondata }: Props) => {
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const [jngAction, setJngAction] = useState<ActionsData[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPagesize] = useState<any>(25);
  const [loader, setLoader] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [filterData, setFilter] = useState<ActionsData[]>([]);
  const [totalcount, settotalcount] = useState<any>();

  const actionColumns = [
    {
      title: `${t(translate.baseStation.Name)}`,
      dataIndex: 'name',
      width: '15%',
    },
    {
      title: `${t(translate.taskmaster.starttime)}`,
      dataIndex: 'startDate',
      width: '220px',
    },
    {
      title: `${t(translate.taskmaster.finishtime)}`,
      dataIndex: 'finishDate',
      width: '220px',
    },
    {
      title: `${t(translate.MyJumpAndGo.duration)}`,
      dataIndex: 'duration',
    },
  ];

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      getDriveActions();
    }
  }, [userDetails, actiondata, pageNumber]);

  const getDriveActions = async () => {
    try {
      setLoader(true);
      const actionData = await getActions(
        userDetails.organization.api_url,
        actiondata.drive_action_uuid,
        pageNumber,
        pageSize,
      );
      settotalcount(actionData._metadata.total_records_count);
      const data =
        actionData && actionData.records && actionData.records.length > 0
          ? actionData.records
          : [];
      const driveActions: any = [];
      data.map((record: ActionsData) => {
        const obj = {
          name: record.autonomous_control_name,
          startDate: record.acr_start_date_time
            ? getDateTime(record.acr_start_date_time)
            : '-',
          finishDate: record.acr_finish_date_time
            ? getDateTime(record.acr_finish_date_time)
            : '-',
          duration: record.duration_in_seconds
            ? getTime(record.duration_in_seconds)
            : '-',
          status: record.autonomous_control_status_name,
        };
        driveActions.push(obj);
      });
      if (data.length > 0) {
        if (pageNumber === 1) {
          setFilter(driveActions);
        } else {
          setFilter([...filterData, ...driveActions]);
        }
      } else {
        setFilter(driveActions);
        setJngAction(driveActions);
      }
      if (driveActions.length < 25) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
      setLoader(false);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    }
  };

  const handleLoadMore = () => {
    setPageNumber(pageNumber + 1);
  };

  return (
    <div className="tblDft farmTabsTbl posRel">
      <InfiniteScrollTable
        columns={actionColumns}
        loading={loader}
        hasMore={hasMore}
        filterData={filterData}
        totalcount={totalcount}
        handleLoadMore={handleLoadMore}
        filename="JngTabActions"
      />
    </div>
  );
};

export default JngTabActions;
