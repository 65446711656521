/* eslint-disable react/jsx-key */
import React, { useState } from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';

interface Props {
  label?: string;
  value?: any;
  cssClass: any;
  onChange: (dates: any) => void;
  allowClear?: boolean;
}

const CustomYear: React.FC<Props> = ({
  label,
  value,
  cssClass,
  onChange,
  allowClear = true,
}: Props) => {
  const [focus, setFocus] = useState(false);

  const labelClass =
    focus || (value && value.length !== 0) ? 'label label-float' : 'label';

  const handleOnChange = (dates: any) => {
    onChange(dates);
  };

  function disabledYear(current: any) {
    const currentYear = moment().year();
    return current && (current.year() < 2020 || current.year() > currentYear);
  }

  return (
    <div
      className="float-label"
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      <>
        <DatePicker
          value={value ? moment(value, 'YYYY') : null}
          onChange={handleOnChange}
          allowClear={allowClear}
          inputReadOnly={true}
          disabledDate={disabledYear}
          picker="year"
          className={cssClass}
        />
        <label className={labelClass}>{label}</label>
      </>
    </div>
  );
};

export default CustomYear;
