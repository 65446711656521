import { Button, notification, Modal } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import InfiniteScrollTable from '../common/InfiniteScrollTable';
import { getHistoryDetails } from '../../constants/Api';
import translate from '../../locale/en_translate.json';
import { useTranslation } from 'react-i18next';
import { ApplicationContext } from '../../context/AppContext';
import { convertBytesToMB, getTimeOnly } from '../../constants/Common';
import moment from 'moment';
import usePaginate from '../../hooks/usePaginate';

interface Props {
  showModel: boolean;
  closeModel: () => void;
  historyRecords: any;
}

const HistoryDetailsModal: React.FC<Props> = ({
  showModel,
  closeModel,
  historyRecords,
}: Props) => {
  const { t } = useTranslation();
  const { activity } = usePaginate();
  const { userDetails } = useContext(ApplicationContext);
  const [loader, setLoader] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [totalCount, setTotalCount] = useState<any>();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize] = useState<number>(25);
  const [filterData, setFilterData] = useState<any[]>([]);

  useEffect(() => {
    getHistoryDetailsLogs();
  }, [historyRecords, pageNumber]);

  const getHistoryDetailsLogs = async () => {
    try {
      setLoader(true);
      const logs = await getHistoryDetails(
        userDetails.organization.api_url,
        historyRecords &&
          moment(historyRecords.consumed_date).format('YYYY-MM-D'),
        pageNumber,
        pageSize,
      );
      setTotalCount(logs._metadata.total_records_count);
      const data =
        logs && logs.records && logs.records.length > 0 ? logs.records : [];
      const shadowData: any[] = [];
      data.map((record: any) => {
        const obj = {
          hour: record?.hour ? getTimeOnly(record.hour) : '-',
          data_consumed_per_hour: record?.data_consumed_per_hour
            ? convertBytesToMB(record?.data_consumed_per_hour)
            : '-',
          giles: record?.folder_wise_hour_data?.Giles
            ? convertBytesToMB(record?.folder_wise_hour_data?.Giles)
            : '-',
          unifiedLogs: record?.folder_wise_hour_data?.UnifiedLogs
            ? convertBytesToMB(record?.folder_wise_hour_data?.UnifiedLogs)
            : '-',
          debug: record?.folder_wise_hour_data?.debug
            ? convertBytesToMB(record?.folder_wise_hour_data?.debug)
            : '-',
          ros_can_logs: record?.folder_wise_hour_data?.ros_can_logs
            ? convertBytesToMB(record?.folder_wise_hour_data?.ros_can_logs)
            : '-',
          tractor_to_basestation_sync: record?.folder_wise_hour_data
            ?.tractor_to_basestation_sync
            ? convertBytesToMB(
                record?.folder_wise_hour_data?.tractor_to_basestation_sync,
              )
            : '-',
        };
        shadowData.push(obj);
      });
      if (data.length > 0) {
        if (pageNumber === 1) {
          setFilterData([...shadowData]);
        } else {
          setFilterData([...filterData, ...shadowData]);
        }
      } else {
        setFilterData(shadowData);
      }
      if (shadowData.length < 25) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
      setLoader(false);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    }
  };

  const columns = [
    {
      title: `${t(translate.prioritizationHistory.hour)}`,
      dataIndex: 'hour',
      key: 'hour',
    },

    {
      title: `${t(translate.prioritizationHistory.giles)} (MB)`,
      dataIndex: 'giles',
      key: 'giles',
    },
    {
      title: `${t(translate.prioritizationHistory.unifiedLogs)} (MB)`,
      dataIndex: 'unifiedLogs',
      key: 'unifiedLogs',
    },
    {
      title: `${t(translate.prioritizationHistory.debug)} (MB)`,
      dataIndex: 'debug',
      key: 'debug',
    },
    {
      title: `${t(translate.prioritizationHistory.ros_can_logs)} (MB)`,
      dataIndex: 'ros_can_logs',
      key: 'ros_can_logs',
    },
    {
      title: `${t(
        translate.prioritizationHistory.tractor_to_basestation_sync,
      )} (MB)`,
      dataIndex: 'tractor_to_basestation_sync',
      key: 'tractor_to_basestation_sync',
    },
    {
      title: `${t(translate.prioritizationHistory.dataUsed)} (MB)`,
      dataIndex: 'data_consumed_per_hour',
      key: 'data_consumed_per_hour',
    },
  ];

  const handleLoadMore = () => {
    activity.current = 'paginate';
    setPageNumber(pageNumber + 1);
  };

  return (
    <Modal
      title={`History Details (${historyRecords.consumedDate})`}
      visible={showModel}
      className="baseModalContainer profileUpdatePopup grPopup baseModalContainerWidth"
      onCancel={closeModel}
      footer={[
        <Button
          type="primary"
          key="back"
          onClick={closeModel}
          className="employeeCreate"
          size="large"
        >
          {t(translate.buttons.close)}
        </Button>,
      ]}
    >
      <div className="tblDft farmTabsTbl posRel tblStyles common_table">
        <InfiniteScrollTable
          columns={columns}
          loading={loader}
          hasMore={hasMore}
          filterData={filterData}
          totalcount={totalCount}
          handleLoadMore={handleLoadMore}
          filename="Records"
        />
      </div>
    </Modal>
  );
};

export default HistoryDetailsModal;
