import React, { memo, useContext } from 'react';
import t_lock from '../../assets/images/tCLock.svg';
import t_speed from '../../assets/images/t_speed.svg';
import { RemoteDriveAppCtx } from '../remote_drive_new/RemoteDriveContext';

import { Progress, Tooltip } from 'antd';
import moment from 'moment';
import hitch_implement_B from '../../assets/images/hitch_implement_B.svg';
import hitch_implement_G from '../../assets/images/hitch_implement_G.svg';
import loading_gif from '../../assets/images/ticketFwd.gif';

import {
  batteryValue,
  checkTractorDrivingState,
  getBatteryPercentage,
  getChargingTextRemaining,
  getImplementName,
  getOperatorNameByHeartBeat,
  getSpeed,
  getSpeedFromVelocity,
  getStatusClass,
  getStatusForTractor,
  loginUserHaveInterruptsOrNot,
  noneAttached,
  offTimeSetUp,
  tractorConstantsNew,
} from '../../constants/Common';
import { TractorsList } from '../../constants/types';
import { ApplicationContext } from '../../context/AppContext';
import Battery from '../common/Battery';
import './style.css';
import { AutoDriveApplicationContext } from '../../context/AutoDriveContext';

interface Props {
  tractor?: any;
  setTractorTabes: (tractor: TractorsList) => void;
  from: 'AUTO_DRIVE' | 'FLEET_LIST';
}
const TractorCard: React.FC<Props> = ({
  tractor,
  setTractorTabes,
  from,
}: Props) => {
  const { userDetails } = useContext(ApplicationContext);
  const { getIndicatorIcons, RDReducer, selectedTractorsIds } =
    useContext(RemoteDriveAppCtx);
  const [state] = RDReducer;
  const {
    selectedTractor,
    pinnedTractors,
    showConnectLoader,
    autoDriveInterruptMsgs,
  } = state;

  const { AutoDriveReducer } = useContext(AutoDriveApplicationContext);
  const [autoDriveState] = AutoDriveReducer;
  const { velocity } = autoDriveState;
  const offDurationInHrs =
    tractor?.last_offline_date_time > 0
      ? Math.round(
          moment
            .duration(
              moment().diff(moment(tractor?.last_offline_date_time || 0)),
            )
            .asHours(),
        )
      : 0;
  const { heartbeat = undefined } = tractor;

  const showSpeed = () =>
    tractor.isAvailable !== tractorConstantsNew.off.key &&
    tractor.isAvailable !== tractorConstantsNew.available.key &&
    tractor.isAvailable !== tractorConstantsNew.faulty.key &&
    tractor.isAvailable !== tractorConstantsNew.charging.key &&
    tractor.heartbeat;

  const getImplementImg = () =>
    getImplementName(tractor) === noneAttached
      ? hitch_implement_G
      : hitch_implement_B;

  const getImplementClass = () =>
    getImplementName(tractor) === noneAttached ? 'hImpNameDbl' : 'hImpNameSel';

  return (
    <>
      <div
        className={`fleetBlk ${
          from === 'FLEET_LIST' &&
          ((tractor && tractor?.selected) ||
            (tractor && tractor.id && pinnedTractors.includes(tractor.id)))
            ? 'fleetBlk-selected'
            : ''
        }
        ${
          from === 'AUTO_DRIVE' &&
          tractor &&
          selectedTractorsIds.includes(tractor.id)
            ? 'fleetBlk-selected'
            : ''
        }
        ${
          tractor &&
          tractor.id &&
          loginUserHaveInterruptsOrNot(
            autoDriveInterruptMsgs,
            tractor?.id,
            heartbeat,
            userDetails?.id,
            true,
          )
        }
        ${
          from === 'FLEET_LIST' &&
          ((tractor && tractor?.selected) ||
            (tractor && tractor.id && pinnedTractors.includes(tractor.id))) &&
          getStatusClass(
            tractor.isAvailable,
            tractor?.heartbeat?.drive_action_details?.drive_action_created_at,
          ) + '_bdr'
        }
        `}
        onClick={() => setTractorTabes(tractor)}
        key={tractor.id}
      >
        {tractor.isAvailable === tractorConstantsNew.charging.key && (
          <>
            <Progress
              className="charging_status"
              percent={batteryValue(tractor)}
              showInfo={false}
            />
            <div className="charging_remain">
              {getChargingTextRemaining(heartbeat)}
            </div>
          </>
        )}
        {tractor.isAvailable === tractorConstantsNew.off.key &&
          Object.keys(tractor).includes('last_offline_date_time') && (
            <>
              {offDurationInHrs >= 1 && (
                <div className="charging_remain ">
                  OFF FOR {offTimeSetUp(offDurationInHrs)}
                </div>
              )}
            </>
          )}
        <div
          className={
            'tractorDtlSec  mbottom ' +
            (tractor.isAvailable === tractorConstantsNew.charging.key ||
            tractor.isAvailable === tractorConstantsNew.off.key
              ? 'mtop'
              : '')
          }
        >
          <div className="tractor_info">
            <div className="tPos">
              <div className="t_driverDtl">
                <div
                  className={`t_name ${
                    tractor?.name?.length > 18 ? 'marquee' : ''
                  } `}
                  data-testid={`${tractor.name}-TractorCard`}
                >
                  <span>
                    <Tooltip title={tractor.name}>
                      <span> {tractor.name} </span>
                    </Tooltip>
                  </span>
                </div>
                <div className="t_driver_name">
                  {getOperatorNameByHeartBeat(heartbeat).trim() !== '' &&
                    tractor.isAvailable !== tractorConstantsNew.off.key && (
                      <>
                        {getOperatorNameByHeartBeat(heartbeat).trim() !==
                          '' && (
                          <img className="mr5 tractorLockIcon" src={t_lock} />
                        )}
                        <span>{getOperatorNameByHeartBeat(heartbeat)}</span>
                      </>
                    )}
                </div>
              </div>
            </div>
          </div>
          <div>
            {!(
              tractor.id &&
              selectedTractorsIds.includes(tractor.id) &&
              showConnectLoader
            ) ? (
              <div
                className={`ttractor_state  ${getStatusClass(
                  tractor.isAvailable,
                  tractor?.heartbeat?.drive_action_details
                    ?.drive_action_created_at,
                )}`}
              >
                {getStatusForTractor(
                  tractor.isAvailable,
                  tractor?.heartbeat?.drive_action_details
                    ?.drive_action_created_at,
                )}
              </div>
            ) : (
              <div className="t-connect-loader">
                <img src={loading_gif} alt="" />
              </div>
            )}
            {tractor &&
              loginUserHaveInterruptsOrNot(
                autoDriveInterruptMsgs,
                tractor?.id,
                heartbeat,
                userDetails?.id,
              ) !== '' && (
                <div className="tractorState pusTxt">
                  {loginUserHaveInterruptsOrNot(
                    autoDriveInterruptMsgs,
                    tractor?.id,
                    heartbeat,
                    userDetails?.id,
                  )}
                </div>
              )}
          </div>
        </div>

        <div className="tractorDtlSec1">
          <div className="tractorBtlBlk fl_left">
            <div className="tImplementSec">
              <img src={getImplementImg()} alt="" />
              <span className={getImplementClass()}>
                {getImplementName(tractor)}
              </span>
            </div>
            {showSpeed() && (
              <div className="tSpeedtSec">
                <img src={t_speed} width="15" height="8" />
                <span className="tspeed">
                  {tractor &&
                  selectedTractor &&
                  selectedTractor.id === tractor.id &&
                  checkTractorDrivingState(tractor.heartbeat, userDetails.id)
                    ? getSpeedFromVelocity(velocity)
                    : getSpeed(tractor.heartbeat)}{' '}
                </span>
                <span className="tsMPH">MPH</span>
              </div>
            )}
          </div>
          <div className="tractorBtlBlk fl_right">
            <div className="tBatterySec">
              <Battery
                battery={getBatteryPercentage(tractor)}
                charging={getStatusForTractor(tractor.isAvailable)}
              ></Battery>{' '}
              <span className="bPer">{batteryValue(tractor)}%</span>
            </div>
            {getIndicatorIcons(tractor)}
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(TractorCard);
