import React, { useContext, useState } from 'react';
import { Button, Modal } from 'antd';
import './style.css';
import { ApplicationContext } from '../../context/AppContext';
import { UpdateComplianceCheck } from '../../constants/Api';
import { useHistory } from 'react-router-dom';
import RoutesConstants from '../../routes/RoutesConstant';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import Interweave from 'interweave';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

interface Props {
  showModal?: boolean;
  closeModel?: any;
  termsData?: any;
}
export const TermsConditions: React.FC<Props> = ({
  showModal,
  closeModel,
  termsData,
}: Props) => {
  const { userDetails } = useContext(ApplicationContext);
  const [numPages, setNumPages] = useState(0);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };
  const { push } = useHistory();
  const AcceptDeclineMethod = async (flag: boolean) => {
    const payload: any = {
      compliance_id: termsData.id,
      compliance_code: termsData.compliance_code,
      compliance_version: termsData.version,
      app_name: 'web',
      is_compliance_acknowledged: flag,
    };
    await UpdateComplianceCheck(
      userDetails.organization.api_url,
      userDetails.organization_id,
      userDetails?.id,
      payload,
    );
    if (flag) {
      closeModel();
    } else {
      push(RoutesConstants.Login);
    }
  };
  return (
    <>
      <Modal
        className="modalContainer profileUpdatePopup termspopup"
        title={termsData?.title || ''}
        visible={showModal}
        onCancel={closeModel}
        width={termsData.content_type === 'text' ? 700 : 1000}
        closable={false}
        maskClosable={false}
        footer={[
          <>
            {termsData.content_type === 'text' ? (
              <>
                {' '}
                <Button
                  key="back"
                  className="psaveBtn"
                  onClick={() => AcceptDeclineMethod(true)}
                >
                  CONFIRM
                </Button>
              </>
            ) : (
              <>
                <Button
                  key="back"
                  className="psaveBtn"
                  onClick={() => AcceptDeclineMethod(true)}
                >
                  ACCEPT
                </Button>
                <Button
                  key="submit"
                  className="pcancelBtn"
                  onClick={() => AcceptDeclineMethod(false)}
                >
                  DECLINE
                </Button>
              </>
            )}
          </>,
        ]}
      >
        <div className="modalBox flexnone">
          <div className="formColW">
            <div className="userIcon" key={termsData?.id}>
              {termsData.content_type === 'text' ? (
                <>
                  <Interweave content={termsData?.description} />
                </>
              ) : (
                <Document
                  file={`${userDetails?.organization?.api_url}farm/cartography/download-template?action=compliance_check`}
                  onLoadSuccess={onDocumentLoadSuccess}
                  className="pdf-document"
                >
                  {/* <Page pageNumber={pageNumber} /> */}
                  {Array.from(new Array(numPages), (el, index) => (
                    <Page
                      key={`page_${index + 1}`}
                      className="pdf-page"
                      pageNumber={index + 1}
                      scale={1.5}
                    />
                  ))}
                </Document>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default TermsConditions;
