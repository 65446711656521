import { useContext, useEffect, useState } from 'react';
import { ApplicationContext } from '../context/AppContext';
import { getOrgErrorCodesData } from '../constants/Api';
import { ErrorCodes } from '../lib/types';

export const useErrorCodes = (): ErrorCodes[] => {
  const [errorCodes, setErrorCodes] = useState<ErrorCodes[]>([]);
  const { user } = useContext(ApplicationContext);

  useEffect(() => {
    const init = async () => {
      try {
        if (!user) return;
        const { organization } = user;
        const result: ErrorCodes[] = await getOrgErrorCodesData(
          organization.api_url,
          organization.id,
        );
        const data = result && result.length > 0 ? result : [];
        setErrorCodes(data);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    };
    if (user) init();
  }, [user]);

  return errorCodes;
};
