// Import necessary packages
import React, { useEffect, useRef } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import AppLoader from '../../../common/AppLoader';

// Apply amCharts themes
am4core.useTheme(am4themes_animated);

interface Props {
  data: any;
  loader: boolean;
  chartId: string;
  labels: string[];
  barColors: string[];
  yTitle: string;
  onClick?: (data: any) => void;
}

const StackedBarChartWithSlider: React.FC<Props> = ({
  data,
  loader,
  chartId,
  labels,
  barColors,
  yTitle,
  onClick,
}) => {
  // UseEffect to create and destroy the chart
  useEffect(() => {
    // Create chart instance
    const chart: am4charts.XYChart = am4core.create(chartId, am4charts.XYChart);
    chart.logo.disabled = true;
    chart.data = data;

    // Create axes
    const categoryAxis = chart.xAxes.push(new am4charts.DateAxis());
    // categoryAxis.dataFields.dateX = 'category';
    categoryAxis.renderer.minGridDistance = 50;

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = yTitle;

    categoryAxis.renderer.labels.template.fontSize = 10;
    categoryAxis.renderer.labels.template.fill = am4core.color('#1F1F1F');
    categoryAxis.renderer.labels.template.fontFamily = 'Montserrat-Regular';
    categoryAxis.title.fontSize = 10;
    categoryAxis.title.fill = am4core.color('#333333');
    categoryAxis.title.fontFamily = 'Montserrat-Medium';
    categoryAxis.renderer.labels.template.fontSize = 10;

    valueAxis.renderer.labels.template.fontSize = 10;
    valueAxis.renderer.labels.template.fill = am4core.color('#5c5c5c');
    valueAxis.renderer.labels.template.fontFamily = 'Montserrat-Regular';
    valueAxis.title.fontSize = 10;
    valueAxis.title.fill = am4core.color('#333333');
    valueAxis.title.fontFamily = 'Montserrat-Medium';
    valueAxis.renderer.labels.template.fontSize = 10;

    categoryAxis.renderer.grid.template.strokeOpacity = 0;
    valueAxis.renderer.grid.template.strokeOpacity = 0.1;

    // chart.scrollbarX = new am4core.Scrollbar();
    // chart.scrollbarX.marginBottom = 20; // Set the margin from the top
    // chart.scrollbarX.parent = chart.bottomAxesContainer;
    // chart.scrollbarX.marginBottom = 40;
    // chart.strokeWidth = 0;

    const createSeries = (field: any, name: any, color: string) => {
      // Create series
      const series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = field;
      series.dataFields.dateX = 'category';
      series.name = name;
      series.stacked = true;
      series.columns.template.tooltipText = '{dateX}: [bold]{valueY}[/]';
      series.columns.template.fill = am4core.color(color);
      series.strokeWidth = 0;
      series.columns.template.column.cornerRadiusTopLeft = 5;
      series.columns.template.column.cornerRadiusTopRight = 5;
      // if (onClick) {
      series.columns.template.events.on('hit', (ev: any) => {
        // eslint-disable-next-line no-console
        console.log('ev', ev.target.dataItem);
        // ev.target.dataItem.categoryX, ev.target.dataItem.dataContext;
        onClick && onClick(ev.target.dataItem);
      });
      // }
    };

    categoryAxis.renderer.grid.template.opacity = 0;
    // valueAxis.renderer.grid.template.opacity = 0;
    labels[0] && createSeries('value', labels[0], barColors[0]);
    labels[1] && createSeries('value2', labels[1], barColors[1]);
    labels[2] && createSeries('value3', labels[2], barColors[2]);

    // // Legend
    chart.legend = new am4charts.Legend();
    // chart.legend.useDefaultMarker = true;
    const markerTemplate = chart?.legend?.markers?.template;
    if (markerTemplate) {
      markerTemplate.width = 10;
      markerTemplate.height = 10;
      chart.legend.position = 'top';
      chart.legend.align = 'center';
      chart.legend.fontFamily = 'Montserrat-Regular';
      chart.legend.fontSize = 10;
    }

    let indicator: any;
    let indicatorInterval: any;

    function showIndicator(chart: any) {
      if (!indicator) {
        indicator = chart.tooltipContainer.createChild(am4core.Container);
        indicator.background.fill = am4core.color('#fff');
        indicator.background.fillOpacity = 0.8;
        indicator.width = am4core.percent(100);
        indicator.height = am4core.percent(100);

        const indicatorLabel = indicator.createChild(am4core.Label);
        indicatorLabel.text = 'Loading...';
        indicatorLabel.align = 'center';
        indicatorLabel.valign = 'middle';
        indicatorLabel.fontSize = 20;
        indicatorLabel.dy = 50;

        // eslint-disable-next-line no-var
        // var hourglass = indicator.createChild(am4core.Image);
        // hourglass.href =
        //   'https://s3-us-west-2.amazonaws.com/s.cdpn.io/t-160/hourglass.svg';
        // hourglass.align = 'center';
        // hourglass.valign = 'middle';
        // hourglass.horizontalCenter = 'middle';
        // hourglass.verticalCenter = 'middle';
        // hourglass.scale = 0.7;
      }

      indicator.hide(0);
      indicator.show();

      // clearInterval(indicatorInterval);
      // indicatorInterval = setInterval(function () {
      //   hourglass?.animate(
      //     [
      //       {
      //         from: 0,
      //         to: 360,
      //         property: 'rotation',
      //       },
      //     ],
      //     2000,
      //   );
      // }, 3000);
    }

    function hideIndicator() {
      indicator?.hide();
      clearInterval(indicatorInterval);
    }

    loader ? showIndicator(chart) : hideIndicator();

    // Cleanup function to destroy the chart
    return () => {
      if (chart) {
        chart.dispose();
      }
    };
  }, [data, loader]);

  return (
    <div>
      <div id={chartId} style={{ width: '100%', height: '350px' }}></div>
    </div>
  );
};

export default StackedBarChartWithSlider;
