/* eslint-disable no-console */
/* eslint-disable react/jsx-key */
import { Col, Row, notification } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import '../HelpCenter/styles.css';
import { ApplicationContext } from '../../context/AppContext';
import { getArticle } from './services/API';
import AppLoader from '../common/AppLoader';
import CVideo from './CVideo';

interface Props {
  subCategory: any;
  setVideo: (data: any) => void;
  selectedVideo: any;
}
const SubCategoryVideo: React.FC<Props> = ({
  subCategory,
  setVideo,
  selectedVideo,
}) => {
  const [loader, setLoader] = useState<boolean>(false);
  const { userDetails } = useContext(ApplicationContext);
  const [trainingVideos, setTrainingVidoes] = useState<any[]>([]);

  const fetchArticles = async () => {
    try {
      setLoader(true);
      const { organization } = userDetails;
      const { api_url } = organization;
      const result = await getArticle(api_url, subCategory?.id);
      const data = Array.isArray(result?.data) ? result?.data : [];
      setTrainingVidoes(
        data.filter(({ media_type }: any) => media_type === 'video'),
      );
    } catch (error: any) {
      notification.error({ message: error.message });
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (userDetails?.organization && subCategory?.id) fetchArticles();
  }, [subCategory]);

  const handleSelect = (video: any) => {
    setVideo(video);
  };

  return (
    <>
      {trainingVideos && trainingVideos.length > 0 && (
        <>
          <div className="tabHead_section">
            <h1 className="tabTitle_head">
              {subCategory?.name} ({trainingVideos.length})
            </h1>
          </div>
          <div className="tabHead_Content">
            <Row gutter={[16, 16]}>
              {trainingVideos.map((ele: any, i: any) => (
                <Col xs={24} sm={24} md={12} lg={12} xl={12} key={ele.id}>
                  <CVideo
                    key={ele.id}
                    video={ele}
                    selectedVideoId={selectedVideo?.id}
                    handleSelect={handleSelect}
                  />
                </Col>
              ))}
            </Row>
          </div>
        </>
      )}

      <AppLoader loader={loader} />
    </>
  );
};

export default SubCategoryVideo;
