/* eslint-disable react/jsx-no-undef */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import translate from '../../../locale/en_translate.json';
import { Button, Input, Modal, Select, Spin } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { Country, State } from 'country-state-city';

interface Props {
  addCustomer: boolean;
  close: () => void;
}

const CreateCustomerModal: React.FC<Props> = ({
  addCustomer,
  close,
}: Props) => {
  const { t } = useTranslation();
  const [country, setCountry] = useState('');
  const [selectedState, setSelectedState] = useState<any[]>([]);

  return (
    <Modal
      title="Add Customer"
      visible={addCustomer}
      closable={false}
      className="tm-modal betaModal taskModal customerModal"
      footer={[
        <Button key="back" onClick={() => close()} className="btnCancel Button">
          {t(translate.buttons.cancel)}
        </Button>,
        <Button
          key="submit"
          onClick={close}
          className="tm-button btnSave Button"
        >
          {t(translate.buttons.save)}
        </Button>,
      ]}
    >
      <div className="formRow">
        <div className="formCol">
          <div className="ant-row ant-form-item">
            <div className="ant-col ant-form-item-label">
              <label>
                {t(translate.baseStation.Name)}
                <span style={{ color: 'red' }}> *</span>
              </label>
            </div>
            <div className="ant-col ant-form-item-control">
              <div className="ant-form-item-control-input">
                <div className="ant-form-item-control-input-content input-align">
                  <Input
                    max={60}
                    min={3}
                    name="orgName"
                    // value={orgName}
                    // onChange={(value: string) => setOrgName(value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="formCol">
          <div className="ant-row ant-form-item">
            <div className="ant-col ant-form-item-label">
              <label>
                {t(translate.myTask.description)}
                <span style={{ color: 'red' }}> *</span>
              </label>
            </div>
            <div className="ant-col ant-form-item-control">
              <div className="ant-form-item-control-input">
                <div className="ant-form-item-control-input-content input-align">
                  <TextArea
                    style={{ width: '100%' }}
                    showCount
                    autoSize={{ minRows: 3, maxRows: 8 }}
                    // value={description}
                    maxLength={500}
                    // onChange={(e: any) => setDescription(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="formRow">
        <div className="formCol">
          <div className="ant-row ant-form-item">
            <h4 className="adminDetails">
              {t(translate.modal.administratorDetails)} :{' '}
            </h4>
          </div>
        </div>
      </div>
      {/* <div className="edit-loader">
        {loader && (
          <div className="loader">
            <Spin size="large" />
          </div>
        )}
      </div> */}
      <div className="formRow">
        <div className="formCol">
          <div className="ant-row ant-form-item">
            <div className="ant-col ant-form-item-label">
              <label>
                {t(translate.modal.firstName)}
                <span style={{ color: 'red' }}> *</span>
              </label>
            </div>
            <div className="ant-col ant-form-item-control">
              <div className="ant-form-item-control-input">
                <div className="ant-form-item-control-input-content input-align">
                  <Input
                    // max={25}
                    // min={3}
                    name="firstname"
                    // value={firstName}
                    // onChange={(value: string) => setFirstName(value)}
                    type="firstname"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="formCol">
          <div className="ant-row ant-form-item">
            <div className="ant-col ant-form-item-label">
              <label>
                {t(translate.modal.lastName)}
                <span style={{ color: 'red' }}> *</span>
              </label>
            </div>
            <div className="ant-col ant-form-item-control">
              <div className="ant-form-item-control-input">
                <div className="ant-form-item-control-input-content input-align">
                  <Input
                    max={25}
                    min={3}
                    name="lastName"
                    // value={lastName}
                    // onChange={(value: string) => setLastName(value)}
                    type="lastName"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="formRow">
        <div className="formCol">
          <div className="ant-row ant-form-item">
            <div className="ant-col ant-form-item-label">
              <label>
                {t(translate.modal.emailId)}
                <span style={{ color: 'red' }}> *</span>
              </label>
            </div>
            <div className="ant-col ant-form-item-control">
              <div className="ant-form-item-control-input">
                <div className="ant-form-item-control-input-content input-align">
                  <Input
                    name="email"
                    // value={emailId}
                    // onChange={(value: string) => setEmailId(value)}
                    type="email"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="formCol">
          <div className="ant-row ant-form-item">
            <div className="ant-col ant-form-item-label">
              <label>
                {t(translate.modal.password)}
                <span style={{ color: 'red' }}> *</span>
              </label>
            </div>
            <div className="ant-col ant-form-item-control">
              <div className="ant-form-item-control-input">
                <div className="ant-form-item-control-input-content input-align">
                  <Input.Password
                    // onChange={(e) => {
                    //   const errors = validatePassword(e.target.value);
                    //   if (errors.length > 0) {
                    //     setPasswordError(errors);
                    //   } else {
                    //     setPasswordError('');
                    //   }

                    //   setPassword(e.target.value);
                    // }}
                    autoComplete="new-password"
                  />
                </div>
              </div>
              {/* <label style={{ color: 'red' }}>{passwordError}</label> */}
            </div>
          </div>
        </div>
      </div>
      <div className="formRow">
        <div className="formCol">
          <div className="ant-row ant-form-item">
            <h4 className="adminDetails">{t(translate.modal.address)} : </h4>
          </div>
        </div>
      </div>
      <div className="formRow">
        <div className="formCol">
          <div className="ant-row ant-form-item">
            <div className="ant-col ant-form-item-label">
              <label>
                {t(translate.modal.address)}
                <span style={{ color: 'red' }}> *</span>
              </label>
            </div>
            <div className="ant-col ant-form-item-control">
              <div className="ant-form-item-control-input">
                <div className="ant-form-item-control-input-content input-align">
                  <Input
                    max={60}
                    min={3}
                    name="orgAddress"
                    // value={orgAddress}
                    // onChange={(value: string) => setOrgAddress(value)}
                    type="address"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="formCol">
          <div className="ant-row ant-form-item">
            <div className="ant-col ant-form-item-label">
              <label>
                {t(translate.modal.aptOrSuitNo)}{' '}
                <span style={{ fontStyle: 'italic' }}> (Optional)</span>
              </label>
            </div>
            <div className="ant-col ant-form-item-control">
              <div className="ant-form-item-control-input">
                <div className="ant-form-item-control-input-content input-align">
                  <Input
                  // value={aptOrSuitNo}
                  // onChange={(e) => setAptOrSuitNo(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="formRow">
        <div className="formCol">
          <div className="ant-row ant-form-item">
            <div className="ant-col ant-form-item-label">
              <label>
                {t(translate.modal.country)}
                <span style={{ color: 'red' }}> *</span>
              </label>
            </div>
            <div className="ant-col ant-form-item-control">
              <div className="ant-form-item-control-input">
                <div className="ant-form-item-control-input-content input-align">
                  <Select
                    showSearch
                    placeholder="Select a country"
                    onChange={(e: any, data: any) => {
                      setCountry(data.key);
                      setSelectedState([]);
                    }}
                  >
                    {Country.getAllCountries().map((country) => (
                      <option
                        data-testid={`${country?.name}-AddOrganizationModal`}
                        key={country?.isoCode}
                        value={country?.name}
                      >
                        {country?.name}
                      </option>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="formCol">
          <div className="ant-row ant-form-item">
            <div className="ant-col ant-form-item-label">
              <label>
                {t(translate.modal.state)}{' '}
                <span style={{ color: 'red' }}> *</span>
              </label>
            </div>
            <div className="ant-form-item-control">
              <div className="ant-form-item-control-input">
                <div className="ant-form-item-control-input-content input-align">
                  <Select
                    showSearch
                    placeholder="Select a state"
                    onChange={(e: any, data: any) => {
                      setSelectedState(data.key);
                    }}
                    value={selectedState}
                  >
                    {country &&
                      State.getStatesOfCountry(country).map((state) => (
                        <option
                          key={state?.name}
                          value={state?.name}
                          data-testid={`${state?.name}-AddOrganizationModal`}
                        >
                          {state?.name}
                        </option>
                      ))}
                  </Select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="formRow">
        <div className="formCol">
          <div className="ant-row ant-form-item">
            <div className="ant-col ant-form-item-label">
              <label>
                {t(translate.modal.city)}
                <span style={{ color: 'red' }}> *</span>
              </label>
            </div>
            <div className="ant-col ant-form-item-control">
              <div className="ant-form-item-control-input">
                <div className="ant-form-item-control-input-content input-align">
                  <Input
                    max={25}
                    min={3}
                    name="city"
                    // value={city}
                    // onChange={(value: string) => setCity(value)}
                    type="city"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="formCol">
          <div className="ant-row ant-form-item">
            <div className="ant-col ant-form-item-label">
              <label>
                {t(translate.modal.zipCode)}
                <span style={{ color: 'red' }}> *</span>
              </label>
            </div>
            <div className="ant-col ant-form-item-control">
              <div className="ant-form-item-control-input">
                <div className="ant-form-item-control-input-content input-align">
                  <Input
                  // value={zipCode}
                  // onChange={(e) => setZipCode(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="formRow">
        <div className="formCol">
          <div className="ant-row ant-form-item">
            <div className="ant-col ant-form-item-label">
              <label>
                {t(translate.modal.timeZone)}
                <span style={{ color: 'red' }}> *</span>
              </label>
            </div>
            <div className="ant-col ant-form-item-control">
              <div className="ant-form-item-control-input">
                <div className="ant-form-item-control-input-content input-align">
                  <Select
                    // onSelect={(value: string) => setModelName(value)}
                    placeholder="Select Time Zone"
                  >
                    <option>All</option>
                  </Select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CreateCustomerModal;
