/* eslint-disable no-console */
/* eslint-disable react/jsx-no-undef */
import { Tooltip } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import translate from '../../locale/en_translate.json';

import ground_zero from '../../assets/images/groundZero.svg';
import map_apps from '../../assets/images/ic_apps.svg';
import map_file_upload from '../../assets/images/ic_file_upload.svg';
import map_image from '../../assets/images/ic_image.svg';
import map_map from '../../assets/images/ic_map.svg';
import map_mode from '../../assets/images/ic_mode.svg';
import map_my_location from '../../assets/images/ic_my_location.svg';
import map_file_download from '../../assets/images/map_file_download.svg';
import map_zoomin from '../../assets/images/map_zoomin.svg';
import map_zoomout from '../../assets/images/map_zoomout.svg';
interface Props {
  rightSideMenuCollapsed: boolean;
  toggleSatellite: boolean;
  handleZoom: (type: string) => void;
  reCenter: () => void;
  toggleMapMaker: () => void;
  setToggleSatellite: (state: boolean) => void;
  toggleGraticule: () => void;
  setImgUpload: (state: boolean) => void;
  setFileUpload: (state: boolean) => void;
  setFileDownload: (state: boolean) => void;
  setLatLong: (state: boolean) => void;
}
const MapActions: React.FC<Props> = ({
  rightSideMenuCollapsed,
  toggleSatellite,
  handleZoom,
  reCenter,
  toggleMapMaker,
  setToggleSatellite,
  toggleGraticule,
  setImgUpload,
  setFileUpload,
  setFileDownload,
  setLatLong,
}: Props) => {
  const { t } = useTranslation();
  return (
    <div
      className="livemapSideMenu"
      style={{
        right: rightSideMenuCollapsed ? 0 : 380,
        transition: 'all 0.2s',
      }}
    >
      <div className="sideMenuOne">
        <ul>
          <Tooltip title="" placement="left">
            <li onClick={() => handleZoom('zoomIn')}>
              <img src={map_zoomin} data-testid="zoominLiveMap-MapMakerMap" />
            </li>
          </Tooltip>
          <Tooltip title="" placement="left">
            <li onClick={() => handleZoom('zoomOut')}>
              <img src={map_zoomout} data-testid="zoomoutLiveMap-MapMakerMap" />
            </li>
          </Tooltip>
          {/* <Tooltip title="Full Screen" placement="left">
                <li onClick={toggleFullScreen}>
                  <img src={map_fullscreen} />
                </li>
              </Tooltip> */}
          <Tooltip title="" placement="left">
            <li onClick={reCenter}>
              <img
                src={map_my_location}
                data-testid="recenterLiveMap-MapMakerMap"
              />
            </li>
          </Tooltip>
        </ul>
      </div>
      <div className="sideMenuTwo">
        <ul>
          <Tooltip
            title={t(
              toggleSatellite
                ? translate.map.map_view
                : translate.map.satellite_view,
            )}
            placement="left"
          >
            <li
              onClick={() => {
                setToggleSatellite(!toggleSatellite);
              }}
            >
              <img
                src={map_map}
                data-testid="toggleSatelliteLiveMap-MapMakerMap"
              />
            </li>
          </Tooltip>
          <Tooltip title={t(translate.map.grid)} placement="left">
            <li onClick={toggleGraticule}>
              <img src={map_apps} data-testid="toggleGridLiveMap-MapMakerMap" />
            </li>
          </Tooltip>
          <Tooltip title={t(translate.map.map)} placement="left">
            <li onClick={toggleMapMaker}>
              <img src={map_mode} data-testid="toggleMapLiveMap-MapMakerMap" />
            </li>
          </Tooltip>
          <Tooltip title="Upload Farm Profile" placement="left">
            <li onClick={() => setImgUpload(true)}>
              <img
                src={map_image}
                data-testid="uploadFarmLiveMap-MapMakerMap"
              />
            </li>
          </Tooltip>
          <Tooltip title="Upload Map Elements" placement="left">
            <li onClick={() => setFileUpload(true)}>
              <img
                src={map_file_upload}
                data-testid="uploadElementsLiveMap-MapMakerMap"
              />
            </li>
          </Tooltip>
          <Tooltip title="Download Map Elements" placement="left">
            <li onClick={() => setFileDownload(true)}>
              <img
                src={map_file_download}
                data-testid="downloadLiveMap-MapMakerMap"
              />
            </li>
          </Tooltip>
          <Tooltip title={t(translate.map.ground_zero)} placement="left">
            <li onClick={() => setLatLong(true)}>
              <img
                src={ground_zero}
                data-testid="editGroundZeroLiveMap-MapMakerMap"
              />
            </li>
          </Tooltip>
        </ul>
      </div>
    </div>
  );
};

export default MapActions;
