import React, { useContext, useEffect, useState } from 'react';
import { Button, notification, Layout } from 'antd';
import { useTranslation } from 'react-i18next';
import { ApplicationContext } from '../../../context/AppContext';
import translate from '../../../locale/en_translate.json';
import { getModelsListData, updateBasestation } from '../API';
import { NumberValidationMethod } from '../../../constants/Common';
import AppLoader from '../../common/AppLoader';
import closeGreen from '../../../assets/images/right_widget_close_icon.svg';
import './style.css';
import CInput from '../../common/CInput';
import CustomSelect from '../../common/CustomSelect';

interface Props {
  details: any;
  toggleWidget: boolean;
  onClose: (state?: boolean) => void;
}

const EditBasestation: React.FC<Props> = ({
  toggleWidget,
  onClose,
  details,
}: Props) => {
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [name, setName] = useState(details.name);
  const [serialNumber, setSerialNumber] = useState(details.serial_number);
  const [loader, setLoader] = useState<boolean>(false);
  const [radioAddress, setRadioAddress] = useState<string>(
    details.radio_address,
  );
  const [radioChannelNumber, setRadioChannelNumber] = useState<string>(
    details.radio_channel_number,
  );
  const [baseStationPin, setBaseStationPin] = useState(details.bsin);
  const [bsModel, setBsModel] = useState<any>(details?.transmission_mode || '');
  const [modelData, setModelData] = useState<any>('');

  useEffect(() => {
    if (
      name &&
      name.trim().length > 0 &&
      serialNumber &&
      serialNumber.trim().length > 0 &&
      bsModel &&
      Number(radioAddress) &&
      Number(radioChannelNumber)
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [name, serialNumber, bsModel, radioAddress, radioChannelNumber]);

  const getModelData = async () => {
    try {
      const data = await getModelsListData('bs_radio_transmission_mode');
      setModelData(data);
      data.map((item: any) => {
        if (item.name === bsModel) {
          setBsModel(item.id);
        }
        return 0;
      });
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    }
  };

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      getModelData();
    }
  }, [userDetails]);

  const saveBasestation = async () => {
    const payload = {
      name: name.trim(),
      radio_address: Number(radioAddress),
      radio_channel_number: Number(radioChannelNumber),
      transmission_mode_id: bsModel,
    };
    try {
      setLoader(true);
      const response: { msg: string } = await updateBasestation(
        details.id,
        payload,
      );
      notification.success({
        message: response.msg,
      });
      onClose(true);
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    } finally {
      setLoader(false);
    }
  };

  const handleChangeRadio = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRadioAddress(NumberValidationMethod(e, radioAddress, 65364));
  };

  const handleRadioChannelNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRadioChannelNumber(NumberValidationMethod(e, radioChannelNumber, 255));
  };

  const changeName = (e: any) => {
    let { value } = e.target;
    value = value.replace(/[^a-zA-Z0-9_  -]/g, '');
    setName(value);
  };

  const handleClose = () => {
    close();
  };

  return (
    <Layout>
      <div className={'rightSideWidegt ' + (toggleWidget ? 'expand' : 'hide')}>
        <div className="widgetCloseBtn">
          <img
            src={closeGreen}
            alt="close icon"
            onClick={() => handleClose()}
          />
        </div>

        <div className="profileEdit widget_card">
          <h3 className="headline3 rowGapBtm3" style={{ textAlign: 'center' }}>
            {t(translate.label.editBaseStation)}
          </h3>
          <div className="mb20">
            <CInput
              value={name}
              name={name}
              label={t(translate.tractors.name)}
              onChange={changeName}
              required={true}
            />
          </div>
          <div className="mb20">
            <CInput
              value={serialNumber}
              name="Serial Number"
              label={t(translate.tractors.serialNumber)}
              disabled
              required={true}
              onChange={(e) => setSerialNumber(e)}
            />
          </div>

          <div className="filters_card selectLabel">
            <div className="proRowType">
              <CustomSelect
                label="Transmission Mode"
                value={bsModel}
                setValue={(e: any) => {
                  setBsModel(e);
                }}
                options={modelData}
                optionKey="id"
                optionDisplay="name"
              />
            </div>
          </div>
          <div className="mb20">
            <CInput
              value={radioAddress}
              label={t(translate.tractors.radio_address)}
              required={true}
              onChange={handleChangeRadio}
              name="radioId"
            />
          </div>
          <div className="mb20">
            <CInput
              value={radioChannelNumber}
              label={t(translate.tractors.radio_channel_number)}
              required={true}
              onChange={handleRadioChannelNumber}
              name="radioChannelNumber"
            />
          </div>

          <div className="mb20">
            <CInput
              value={baseStationPin}
              label={t(translate.tractors.basestationpin)}
              required={true}
              onChange={setBaseStationPin}
              name="baseStationPin"
              disabled
            />
          </div>
          <div>
            <AppLoader loader={loader} />
            <div className="create_ticket_btn_sec">
              <Button onClick={saveBasestation} disabled={isDisabled}>
                {t(translate.buttons.save)}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default EditBasestation;
