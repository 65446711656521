import React, { useContext, useEffect, useState } from 'react';
import Layout from 'antd/lib/layout/layout';
import { Card, Row, Button, Space, notification, Tooltip, Modal } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { ApplicationContext } from '../../context/AppContext';
import {
  getRestartManager,
  getSystemComponent,
  getTractorsListData,
  updateRestartManager,
} from '../../constants/Api';
import { RestartManagerResponse, TractorsList } from '../../constants/types';
import translate from '../../locale/en_translate.json';
import { useTranslation } from 'react-i18next';
import './style.css';
import {
  getDateTimes,
  tractorSort,
  getTractorAvailableState,
} from '../../constants/Common';
import InfiniteScrollTable from '../common/InfiniteScrollTable';
import CSelect from '../common/CSelect';

const { confirm } = Modal;

interface TractorResponseType {
  label: string;
  value: number | string | boolean;
  disabled: boolean;
  index: number | string;
  isAvailable?: string;
}

const RestartManager: React.FC = () => {
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const [tractorlist, settractorlist] = useState<TractorResponseType[]>([]);
  const [componentList, setComponentList] = useState<RestartManagerResponse[]>(
    [],
  );
  const [tractorId, setTractorId] = useState<number>(0);
  const [dataSource, setDataSource] = useState([]);
  const [compName, setCompName] = useState<string>('');
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [loader, setLoader] = useState<boolean>(false);
  const [totalcount, settotalcount] = useState<any>();
  const [filterData, setFilter] = useState<any[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      getTractorsList();
      getSystemComponentData();
    }
  }, [userDetails]);

  useEffect(() => {
    if (userDetails && userDetails.organization && pageNumber) {
      getRestartManagerData();
    }
  }, [pageNumber]);

  const getTractorsList = async () => {
    try {
      const tractor: { records: TractorsList[] } = await getTractorsListData(
        userDetails.organization.api_url,
        userDetails.organization_id,
        pageNumber,
      );
      const data =
        tractor && tractor.records && tractor.records.length > 0
          ? tractor.records
          : [];
      const tractorData: TractorResponseType[] = data.map((d: TractorsList) => {
        return {
          label: d.name,
          value: d.id,
          disabled: false,
          index: d.id,
          id: d.id,
          connection: d.connection,
          isOnline: d.connection === 'online' ? true : false,
          isAvailable: getTractorAvailableState(d.connection, d?.heartbeat),
        };
      });
      const arr = tractorSort(tractorData, 'label');
      settractorlist(arr);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    }
  };

  const getRestartManagerData = async () => {
    try {
      setLoader(true);
      const restartData = await getRestartManager(
        userDetails.organization.api_url,
        userDetails.organization.fleet.id,
        pageNumber,
      );
      settotalcount(restartData._metadata.total_records_count);
      const data =
        restartData && restartData.records && restartData.records.length > 0
          ? restartData.records
          : [];
      const restartInfo: any = [];
      data.map((record: RestartManagerResponse) => {
        const obj = {
          id: record.tractor.id,
          tractor_name: record.tractor.tractor_name
            ? record.tractor.tractor_name
            : '--',
          component_name: record ? record.component_name : '--',
          name: record.user
            ? record.user.first_name + ' ' + record.user.last_name
            : '--',
          created_date_time: record.created_date_time
            ? getDateTimes(record.created_date_time)
            : '--',
          modified_date_time: record.modified_date_time
            ? getDateTimes(record.modified_date_time)
            : '--',
          status: record.status ? record.status : '--',
          remarks: record.remarks ? record.remarks : '--',
        };
        restartInfo.push(obj);
      });
      if (data.length > 0) {
        if (pageNumber === 1) {
          setFilter(restartInfo);
          setDataSource(restartInfo);
        } else {
          setFilter([...filterData, ...restartInfo]);
          setDataSource(restartInfo);
        }
      } else {
        setFilter(restartInfo);
        setDataSource(restartInfo);
      }

      if (restartInfo.length < 25) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    } finally {
      setLoader(false);
    }
  };

  const getSystemComponentData = async () => {
    try {
      const records: any = await getSystemComponent(
        userDetails.organization.api_url,
        userDetails.organization?.fleet?.id,
        tractorId,
      );
      setComponentList(records);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    }
  };

  const columns = [
    {
      title: `${t(translate.RemoteAccessMHistory.tractor)}`,
      dataIndex: 'tractor_name',
      key: 'tractor_name',
      width: '7%',
    },
    {
      title: `${t(translate.restartManager.component)}`,
      dataIndex: 'component_name',
      key: 'component_name',
      width: '12%',
    },
    {
      title: `${t(translate.restartManager.userName)}`,
      dataIndex: 'name',
      key: 'name',
      width: '14%',
      ellipsis: true,
    },
    {
      title: `${t(translate.RemoteAccessMHistory.time)}`,
      dataIndex: 'created_date_time',
      key: 'created_date_time',
      width: '220px',
    },
    {
      title: `${t(translate.updateManager.updatedTime)}`,
      dataIndex: 'modified_date_time',
      key: 'modified_date_time',
      width: '220px',
    },
    {
      title: `${t(translate.myTask.status)}`,
      dataIndex: 'status',
      key: 'status',
      width: '9%',
    },
    {
      title: `${t(translate.RemoteAccessMHistory.remarks)}`,
      dataIndex: 'remarks',
      key: 'remarks',
      render: (remarks: any) => {
        return (
          <Tooltip title={remarks}>
            <div className="description-text">{remarks}</div>
          </Tooltip>
        );
      },
      width: '15%',
    },
  ];

  const updateRestartManagers = async () => {
    const payload = {
      component_name: compName,
      tractorId: tractorId,
      fleetId: userDetails.organization.fleet.id,
    };
    try {
      const resp = await updateRestartManager(
        userDetails.organization.api_url,
        userDetails.organization.fleet.id,
        tractorId,
        payload,
      );
      notification.success({ message: resp.msg });
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    }
  };

  const handleLoadMore = () => {
    setPageNumber(pageNumber + 1);
  };

  const showConfirm = () => {
    confirm({
      title: 'Do you Want to Restart?',
      onOk() {
        updateRestartManagers();
      },
      onCancel() {
        // console.log('Cancel');
      },
    });
  };

  return (
    <Layout>
      <div className="mainContent">
        <Content>
          <div className="tblContainer softwareUpdate viewportContainer">
            <Card className="mt5 mb40 tcktsBlk">
              <Space
                className="mt0"
                style={{ marginBottom: 0, position: 'relative' }}
              >
                <div className="dflexspace">
                  <div className="mr20">
                    <CSelect
                      list={tractorlist.map((item) =>
                        Object.assign({
                          id: item.value,
                          name: item.label,
                          isAvailable: item.isAvailable,
                        }),
                      )}
                      selectTrigger={(selected) => {
                        setTractorId(selected ? selected?.id : 0);
                      }}
                      placeholder="All Tractors"
                      label="Tractor"
                      isAll={true}
                      tractorStatus={true}
                    />
                  </div>
                  <div className="dW214 mr20">
                    <CSelect
                      list={componentList}
                      selectTrigger={(selected) => {
                        if (selected) {
                          const { component_name } = selected;
                          setCompName(component_name);
                        } else {
                          setCompName('');
                        }
                      }}
                      keyValue="component_name"
                      label="Component"
                      keyLabel="component_name"
                      placeholder="All Components"
                    />
                  </div>

                  <Button
                    type="primary"
                    className="employeeCreate"
                    data-testid="restartButton-RestartManager"
                    size="large"
                    onClick={() => showConfirm()}
                  >
                    {t(translate.buttons.restart)}
                  </Button>
                </div>
              </Space>
            </Card>
            <Row>
              <div className="tblDft farmTabsTbl posRel empTable">
                <InfiniteScrollTable
                  columns={columns}
                  loading={loader}
                  hasMore={hasMore}
                  filterData={filterData}
                  totalcount={totalcount}
                  handleLoadMore={handleLoadMore}
                  filename="Tractors"
                />
              </div>
            </Row>
          </div>
        </Content>
      </div>
    </Layout>
  );
};

export default RestartManager;
