import Layout from 'antd/lib/layout/layout';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FleetSettings,
  OrganizationProfileDetails,
  UserDetails,
} from '../../constants/types';
import { getFleetSettingData, getProfileData } from '../../constants/Api';
import './style.css';
import { ApplicationContext } from '../../context/AppContext';
import { Avatar } from 'antd';
import EditIcon from '../../assets/images/EditIcon.svg';
import EditfarmProfile from './EditfarmProfile';
import defaultProfile from '../../assets/images/defaultProfile.svg';
import mapUnavailable from '../../assets/images/map_unavailable.svg';
import { privilagesConstants } from '../../constants/Privilages';
import { useHistory } from 'react-router-dom';
import RoutesConstants from '../../routes/RoutesConstant';
import translate from '../../locale/en_translate.json';

interface Props {
  onContext?: (content: UserDetails) => void;
}
const OrganizationDetails: React.FC<Props> = ({ onContext }: Props) => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const { userDetails, privilegeChecker } = useContext(ApplicationContext);
  const [organizationProfile, setOrganizationProfile] =
    useState<OrganizationProfileDetails>();
  const [editprofile, setEditprofile] = useState<boolean>(false);
  const [fleetSetting, setFleetSetting] = useState<FleetSettings[]>([]);

  useEffect(() => {
    return () => {
      setFleetSetting([]);
    };
  }, []);

  useEffect(() => {
    if (userDetails && userDetails.organization && editprofile === false) {
      onContext && onContext(userDetails);
      getProfile();
      getFleetSetting();
    }
  }, [userDetails, editprofile]);

  const getProfile = async () => {
    if (userDetails) {
      const data: OrganizationProfileDetails = await getProfileData(
        userDetails.organization.api_url,
        userDetails.organization.id,
      );
      setOrganizationProfile(data);
    }
  };

  const getFleetSetting = async () => {
    try {
      const data: FleetSettings[] = await getFleetSettingData(
        userDetails.organization.api_url,
        userDetails.organization.id,
      );
      if (data) {
        const filteredSetting = data.filter(
          (setting) =>
            setting.name === 'electricity_rate' ||
            setting.name === 'diesel_cost',
        );
        setFleetSetting(filteredSetting);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const openMapMaker = () => {
    push(RoutesConstants.MapMakerBeta);
  };

  const mapMakerBtn = (
    <>
      {' '}
      {privilegeChecker(privilagesConstants.Access_Map_Maker) && (
        <div
          className="mapMakerBtn"
          data-testid="viewMapmaker-OrganizationDetails"
          onClick={() => openMapMaker()}
        >
          {t(translate.organizationDetails.viewMapMaker)}
        </div>
      )}
    </>
  );

  return (
    <Layout>
      <div className="common_wrapper hvh1">
        {organizationProfile && !editprofile && (
          <div className="profContainer farm_profile_container">
            <div className="profileDetails">
              <div className="profPicSec">
                <>
                  <div
                    className="profilePic editprofile mb53"
                    data-testid="profileImage-OrganizationDetails"
                  >
                    {organizationProfile?.profile_url ? (
                      <Avatar
                        src={organizationProfile?.profile_url}
                        className={'profile-style-width'}
                      >
                        {organizationProfile?.name?.charAt(0)}
                      </Avatar>
                    ) : (
                      <Avatar
                        src={defaultProfile}
                        className="defaultprofileimg"
                      ></Avatar>
                    )}
                  </div>

                  <div className="profCropDtl padt56">
                    <div className="profCropRow">
                      <span
                        className="profCropHead headline4"
                        data-testid="addressText-OrganizationDetails"
                      >
                        {t(translate.organizationDetails.address)}
                      </span>
                      <span className="profCropValue body1">
                        {organizationProfile.address &&
                        String(organizationProfile.address).trim() !== ''
                          ? `${organizationProfile.address}`
                          : '-'}
                        <span className="dblock">
                          {organizationProfile.city},&nbsp;
                          {organizationProfile?.state} &nbsp;
                          {organizationProfile.zip_code}
                        </span>
                      </span>
                    </div>

                    <div className="profCropRow">
                      <span
                        className="profCropHead headline4"
                        data-testid="acreageText-OrganizationDetails"
                      >
                        {t(translate.organizationDetails.totalAcres)}
                      </span>
                      <span className="profCropValue body1">
                        {organizationProfile.acreage &&
                        String(organizationProfile.acreage).trim() !== ''
                          ? `${organizationProfile.acreage} Acres`
                          : '-'}
                      </span>
                    </div>
                  </div>
                </>
              </div>
              <div className="profDetails">
                <div className="addressSec padt30">
                  <h3
                    className="mb28 orgTitle headline1"
                    data-testid={`${organizationProfile.name}-OrganizationDetails`}
                  >
                    {organizationProfile.name}
                    {privilegeChecker(
                      privilagesConstants.Edit_farm_profile_information,
                    ) && (
                      <label style={{ cursor: 'pointer', marginLeft: '20px' }}>
                        <img
                          src={EditIcon}
                          alt="edit"
                          width="16"
                          height="16"
                          className="editprofileImg"
                          data-testid="AutomationEdit-OrganizationDetails"
                          onClick={() => setEditprofile(true)}
                        />
                      </label>
                    )}
                  </h3>
                  <div className="profMap">
                    {organizationProfile?.farm_layout ? (
                      <div className="mapImg">
                        {mapMakerBtn}
                        <img
                          src={organizationProfile.farm_layout}
                          alt=""
                          className="pointer"
                        />
                      </div>
                    ) : (
                      <div className="mapImg unavalibleImg">
                        {mapMakerBtn}
                        <div className="unavailable-container">
                          <img
                            src={mapUnavailable}
                            className="mapimage"
                            alt=""
                          />
                          <p>
                            {t(translate.organizationDetails.mapUnAvailable)}
                          </p>
                        </div>
                      </div>
                      // <div className="unavalibleImg">
                      //   <div className="mapImg">
                      //     {mapMakerBtn}
                      //     <div className="unavailable-container">
                      //       <img
                      //         src={mapUnavailable}
                      //         className="mapimage"
                      //         alt=""
                      //       />
                      //       <p>
                      //         {t(translate.organizationDetails.mapUnAvailable)}
                      //       </p>
                      //     </div>
                      //   </div>
                      // </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {editprofile && (
          <EditfarmProfile
            setEditprofile={setEditprofile}
            organizationProfile={organizationProfile}
            fleetSetting={fleetSetting}
          />
        )}
      </div>
    </Layout>
  );
};

export default OrganizationDetails;
