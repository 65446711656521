import React, { useEffect, useState } from 'react';
import { Button, Input, Layout, notification, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import closeGreen from '../../../assets/images/right_widget_close_icon.svg';
import translate from '../../../locale/en_translate.json';
import CInput from '../../common/CInput';
import { validatePassword } from '../../../constants/Common';
import CustomTextArea from '../../common/CustomTextArea';
import CustomSelect from '../../common/CustomSelect';
import { Country, State } from 'country-state-city';
import { addOrganization, getModelsListData } from '../API';

interface Props {
  toggleWidget: boolean;
  onClose: (state?: boolean) => void;
}

const AddCustomer: React.FC<Props> = ({ toggleWidget, onClose }) => {
  const { t } = useTranslation();
  const [orgName, setOrgName] = useState<any>('');
  const [orgAddress, setOrgAddress] = useState('');
  const [firstName, setFirstName] = useState<any>('');
  const [lastName, setLastName] = useState<any>('');
  const [emailId, setEmailId] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [loader, setLoader] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [city, setCity] = useState<any>('');
  const [zipCode, setZipCode] = useState('');
  const [country, setCountry] = useState('');
  const [aptOrSuitNo, setAptOrSuitNo] = useState('');
  const [description, setDescription] = useState('');
  const [timeZoneList, setTimeZoneList] = useState<any[]>([]);
  const [timeZone, setTimeZone] = useState<any>('');
  const [selectedState, setSelectedState] = useState<any>('');
  const [cropTypeList, setCropTypeList] = useState<any[]>([]);
  const [croptype, setCroptype] = useState<any>('');
  const [errors, setErrors] = useState({
    firstname: true,
    orgName: true,
    orgAddress: true,
    lastName: true,
    email: true,
    password: true,
    city: true,
    selectedState: true,
    country: true,
    zipCode: true,
    description: true,
    timeZone: true,
    croptype: true,
  });

  useEffect(() => {
    setErrors({
      ...errors,
      password: validatePassword(password).length !== 0,
      description: description?.trim().length === 0,
      zipCode: zipCode?.trim().length === 0,
      selectedState: selectedState.length === 0,
      country: country?.trim().length === 0,
      timeZone: timeZone?.length === 0,
      croptype: croptype?.trim().length === 0,
    });
  }, [
    description,
    password,
    selectedState,
    zipCode,
    country,
    timeZone,
    croptype,
  ]);

  useEffect(() => {
    setIsDisabled(!Object.values(errors).every((item) => !item));
  }, [errors]);

  useEffect(() => {
    getTimeZoneList();
    getCropTypeList();
  }, []);

  const getCropTypeList = async () => {
    try {
      const resp = await getModelsListData('crop_type');
      setCropTypeList(resp);
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    }
  };

  const getTimeZoneList = async () => {
    try {
      const resp = await getModelsListData('time_zone');
      setTimeZoneList(resp);
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    }
  };

  const handleClose = () => {
    onClose();
  };

  const createOrganization = async () => {
    const payload: any = {
      address: orgAddress ? orgAddress.trim() : '',
      first_name: firstName ? firstName.trim() : '',
      last_name: lastName ? lastName.trim() : '',
      name: orgName ? orgName.trim() : '',
      password: password?.length > 0 ? password : null,
      username: emailId.trim(),
      city: city.trim(),
      state: selectedState,
      zip_code: zipCode,
      country: country.trim(),
      apt_or_suit_no: aptOrSuitNo,
      description: description.trim(),
      tz_info: timeZone,
      crop_type: croptype,
    };

    try {
      setLoader(true);
      const response = await addOrganization(payload);
      notification.success({
        message: response?.msg,
      });
      onClose(true);
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (country) {
      const data = State.getStatesOfCountry(country);
      setSelectedState('');
    }
  }, [country]);

  return (
    <Layout>
      <div className={'rightSideWidegt ' + (toggleWidget ? 'expand' : 'hide')}>
        <div className="widgetCloseBtn">
          <img src={closeGreen} alt="close icon" onClick={handleClose} />
        </div>

        <div className="profileEdit widget_card">
          <h3 className="headline3 rowGapBtm3" style={{ textAlign: 'center' }}>
            {t(translate.label.addCustomer)}
          </h3>
          <div className="flex-column">
            <CInput
              max={60}
              min={3}
              value={orgName}
              name="orgName"
              label="Organization Name"
              onChange={(value: string) => setOrgName(value)}
              hasError={(state) => setErrors({ ...errors, orgName: state })}
            />
            <div>
              <CustomTextArea
                label="Description"
                value={description}
                setValue={setDescription}
                description={description}
                descriptionCount={description?.length}
                required={true}
              />
            </div>
            <CustomSelect
              className="mb16"
              label="Crop Type"
              value={croptype}
              setValue={setCroptype}
              options={cropTypeList}
              optionKey="name"
              optionDisplay="name"
            />
            <CInput
              max={25}
              min={3}
              name="firstname"
              label="First Name"
              value={firstName}
              onChange={(value: string) => setFirstName(value)}
              type="firstname"
              dataTestid="firstNameInputField-AddOrganizationModal"
              dataTestidError="validationErr-firstName-AddOrganizationModal"
              hasError={(state) => setErrors({ ...errors, firstname: state })}
            />
            <CInput
              max={25}
              min={3}
              name="lastName"
              label="Last Name"
              value={lastName}
              onChange={(value: string) => setLastName(value)}
              type="lastName"
              dataTestid="lastNameInputField-AddOrganizationModal"
              dataTestidError="validationErr-lastName-AddOrganizationModal"
              hasError={(state) => setErrors({ ...errors, lastName: state })}
            />
            <CInput
              name="email"
              label="Email Address"
              value={emailId}
              onChange={(value: string) => setEmailId(value)}
              type="email"
              dataTestid="emailIdInputField-AddOrganizationModal"
              dataTestidError="notValidMailErr-AddOrganizationModal"
              hasError={(state) => setErrors({ ...errors, email: state })}
            />
            <div className="mb16">
              <Input.Password
                placeholder="Password"
                data-testid="passwordInputField-AddOrganizationModal"
                className="input"
                onChange={(e) => {
                  const errors = validatePassword(e.target.value);
                  if (errors.length > 0) {
                    setPasswordError(errors);
                  } else {
                    setPasswordError('');
                  }

                  setPassword(e.target.value);
                }}
                autoComplete="new-password"
              />
              <label
                style={{ color: 'red' }}
                data-testid={`${passwordError}-AddOrganizationModal`}
              >
                {passwordError}
              </label>
            </div>
            <CInput
              max={60}
              min={3}
              name="orgAddress"
              label="Address"
              value={orgAddress}
              onChange={(value: string) => setOrgAddress(value)}
              type="address"
              dataTestid="addressInputField-AddOrganizationModal"
              dataTestidError="validationErr-address-AddOrganizationModal"
              hasError={(state) => setErrors({ ...errors, orgAddress: state })}
            />
            <CInput
              name="appSuitNum"
              label="App Suit No"
              value={aptOrSuitNo}
              onChange={(value) => setAptOrSuitNo(value)}
            />
            <CustomSelect
              label="Country"
              value={country}
              setValue={setCountry}
              options={Country.getAllCountries().map((country: any) => {
                return { name: country?.name, value: country?.isoCode };
              })}
              optionKey="value"
              optionDisplay="name"
            />
            <CustomSelect
              label="State"
              value={selectedState}
              setValue={setSelectedState}
              options={
                country
                  ? State.getStatesOfCountry(country).map((country: any) => {
                      return { name: country?.name, value: country?.name };
                    })
                  : []
              }
              optionKey="value"
              optionDisplay="name"
            />
            <CInput
              max={25}
              min={3}
              name="city"
              label="City"
              value={city}
              onChange={(value: string) => setCity(value)}
              type="city"
              hasError={(state) => setErrors({ ...errors, city: state })}
            />
            <CInput
              name="zipCode"
              label="Zip Code"
              value={zipCode}
              onChange={(value) => setZipCode(value)}
            />
            <CustomSelect
              label="Time Zone"
              value={timeZone}
              setValue={setTimeZone}
              options={timeZoneList}
              optionKey="name"
              optionDisplay="name"
            />
            <div className="edit-loader">
              {loader && (
                <div className="loader">
                  <Spin size="large" />
                </div>
              )}
            </div>
            <div className="create_ticket_btn_sec">
              <Button
                onClick={() => {
                  createOrganization();
                }}
                disabled={isDisabled}
              >
                {t(translate.buttons.addCustomer)}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AddCustomer;
