import React, { useContext, useEffect, useState } from 'react';
import Layout from 'antd/lib/layout/layout';
import closeGreen from '../../assets/images/right_widget_close_icon.svg';
import applicationIds from '../../locale/applicationIds.json';
import { ApplicationContext } from '../../context/AppContext';
import {
  Avatar,
  Button,
  Input,
  Modal,
  notification,
  Select,
  Tooltip,
} from 'antd';
import {
  CheckOutlined,
  CloseOutlined,
  ExclamationCircleOutlined,
  WarningFilled,
} from '@ant-design/icons';
import {
  UpdateNewEmployee,
  updateUserProfileImageUpload,
} from '../../constants/Api';
import { isValidEmail, onlyNumbersAllow } from '../../constants/Common';
import { useTeamsContext } from '../../context/TeamContext';
import { useTranslation } from 'react-i18next';
import translate from '../../locale/en_translate.json';
import classNames from 'classnames';
import AppLoader from './AppLoader';
import ImageCrop from '../widget/ImageCrop';
interface Props {
  Close: () => void;
  toggleWidget: boolean;
  userProfile: any;
}

const validationData = {
  firstName: true,
  lastName: true,
  email: true,
  enabled: false,
};
const EditEmployee: React.FC<Props> = ({
  Close,
  toggleWidget,
  userProfile,
}: Props) => {
  const { t } = useTranslation();
  const { userDetails, getOrgCodesData, updateUserDetails } =
    useContext(ApplicationContext);
  const { teams } = useTeamsContext();
  const [selectTeamsIds, setselectTeamsIds] = useState<any[]>([]);
  const [orgAdminTeamIds, setOrgAdminTeamIds] = useState<string[]>([]);
  const [access, setAccess] = useState({
    app_access: false,
    tractor_access: false,
  });

  const [teamslist, setTeams] = useState<any>([]);
  const [firstName, setFirstName] = useState<any>();
  const [lastName, setLastName] = useState<any>();
  const [email, setEmail] = useState<string>('');
  const [contactNum, setContactNum] = useState<any>();
  const [validations, setValidations] = useState<any>(validationData);
  const [emailError, setEmailError] = useState<boolean>(false);
  const [firstNameError, setFirstNameError] = useState<boolean>();
  const [firstNameLongError, setFirstNameLongError] = useState<boolean>();
  const [firstNameSpecialCharaterError, setFirstNameSpecialCharaterError] =
    useState<boolean>();
  const [lastNameError, setLastNameError] = useState<boolean>();
  const [lastNameLongError, setLastNameLongError] = useState<boolean>();
  const [lastNameSpecialCharacterError, setLastNameSpecialCharacterError] =
    useState<boolean>();
  const [loader, setLoader] = useState<boolean>(false);
  const [status, setStatus] = useState<string>('');
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [fileUrl, setFileUrl] = useState<any>('');
  const [fileUrlData, setfileUrlData] = useState<any>('');
  const [confirmEmail, setConfirmEmail] = useState<string>('');
  const [disableOkButton, setDisableOkButton] = useState<boolean>(true);
  const [showConfirmPopUp, setShowConfirmPopUp] = useState<boolean>(false);
  const [emailLoader, setEmailLoader] = useState<boolean>(false);
  const { Option } = Select;

  useEffect(() => {
    if (
      firstName &&
      firstName?.trim().length > 2 &&
      firstName?.trim().length <= 25 &&
      !getregexErrors(firstName?.trim()) &&
      lastName &&
      lastName?.trim().length > 2 &&
      lastName?.trim().length <= 25 &&
      !getregexErrors(lastName?.trim()) &&
      selectTeamsIds &&
      selectTeamsIds.length > 0
    ) {
      if (access?.app_access) {
        const emildata = email?.trim().length > 0 && isValidEmail(email);
        setIsDisabled(!emildata);
      } else {
        setIsDisabled(false);
      }
    } else {
      setIsDisabled(true);
    }
  }, [firstName, lastName, email, access, selectTeamsIds]);

  const getregexErrors = (value: any) => {
    const exp = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
    const regexp = new RegExp(exp);
    return regexp.test(value);
  };

  useEffect(() => {
    if (userProfile) {
      setFirstName(userProfile?.first_name);
      setLastName(userProfile?.last_name);
      setEmail(userProfile?.email);
      setContactNum(userProfile?.contact_number);
      setFileUrl(userProfile?.profile_url);
      const st = userProfile.is_active ? 'true' : 'false';
      setStatus(st);
    }
  }, [userProfile]);

  useEffect(() => {
    const taccess = teams
      .filter((team: any) => selectTeamsIds.includes(team.id))
      .reduce(
        (a: any, team: any) => {
          if (team.app_access) a.app_access = true;
          if (team.tractor_access) a.tractor_access = true;
          return a;
        },
        { app_access: false, tractor_access: false },
      );
    setAccess(taccess);
  }, [selectTeamsIds]);

  useEffect(() => {
    if (userProfile && userProfile.teams) {
      const { teams } = userProfile;
      const fTeams = teams.filter(
        (team: any) => team?.name !== 'Organization Admin',
      );
      const [orgTeams] = teams.filter(
        (team: any) => team?.name === 'Organization Admin',
      );
      if (orgTeams) setOrgAdminTeamIds([orgTeams?.id]);
      setTeams(fTeams);
    }
  }, [userProfile]);

  useEffect(() => {
    if (teamslist) {
      const tempData: any = [];
      teamslist.map((ele: any) => {
        tempData.push(ele.id);
      });
      setselectTeamsIds(tempData);
    }
  }, [teamslist]);

  const handleConfirmEmail = (e: any) => {
    setConfirmEmail(e.target.value);
    if (e.target.value === email) {
      setDisableOkButton(false);
    } else {
      setDisableOkButton(true);
    }
  };

  useEffect(() => {
    const isValid = Object.values(validations).every((item) => item);
    if (isValid && Object.keys(validations).length) {
      if (userProfile.email === email.trim()) {
        editProfileSave();
      } else {
        setShowConfirmPopUp(true);
      }
    }
  }, [validations]);

  useEffect(() => {
    const exp = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
    const regexp = new RegExp(exp);
    if (regexp.test(firstName !== undefined && firstName)) {
      setFirstNameSpecialCharaterError(true);
    } else {
      setFirstNameSpecialCharaterError(false);
    }
    if (firstName?.trim() !== '' && firstName?.trim().length < 3) {
      setFirstNameError(true);
    } else {
      setFirstNameError(false);
    }
    if (firstName?.trim() !== '' && firstName?.trim().length > 25) {
      setFirstNameLongError(true);
    } else {
      setFirstNameLongError(false);
    }
  }, [firstName]);

  useEffect(() => {
    const exp = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
    const regexp = new RegExp(exp);
    if (regexp.test(lastName !== undefined && lastName)) {
      setLastNameSpecialCharacterError(true);
    } else {
      setLastNameSpecialCharacterError(false);
    }
    if (lastName?.trim() !== '' && lastName?.trim().length < 3) {
      setLastNameError(true);
    } else {
      setLastNameError(false);
    }

    if (lastName?.trim() !== '' && lastName?.trim().length > 25) {
      setLastNameLongError(true);
    } else {
      setLastNameLongError(false);
    }
  }, [lastName]);

  const handleFormSubmit = () => {
    const exp = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
    const regexp = new RegExp(exp);
    setValidations({
      ...validations,
      firstName:
        firstName?.trim() !== '' &&
        firstName?.trim().length > 2 &&
        firstName?.trim().length <= 25 &&
        !regexp.test(firstName),
      lastName:
        lastName?.trim() !== '' &&
        lastName?.trim().length > 2 &&
        lastName?.trim().length <= 25 &&
        !regexp.test(lastName),
      email:
        email?.trim() !== ''
          ? isValidEmail(email)
            ? true
            : false
          : email?.trim() === '',

      enabled: true,
    });
  };

  useEffect(() => {
    if (email && email?.trim() !== '') {
      const validationError = isValidEmail(email);
      setEmailError(!validationError ? true : false);
    } else {
      setEmailError(false);
    }
  }, [email]);

  const editProfileSave = async () => {
    const payload: any = {
      first_name: firstName.trim(),
      last_name: lastName.trim(),
      organization_id: userDetails.organization_id,
      email: email ? email : '',
      contact_number: contactNum ? contactNum.trim() : '',
      teams: orgAdminTeamIds?.length
        ? [...selectTeamsIds, ...orgAdminTeamIds]
        : selectTeamsIds,
      is_active: status === 'true' ? true : false,
    };

    try {
      setEmailLoader(true);
      const response = await UpdateNewEmployee(
        userDetails.organization.api_url,
        userProfile.id,
        payload,
      );
      getOrgCodesData();
      handleSave();
      notification.success({
        message: response.msg,
        duration: 2,
      });
      setShowConfirmPopUp(false);
      if (email.trim() !== userProfile.email) {
        notification.success({
          message: t(translate.label.emailVerification),
          duration: 2,
        });
      }
    } catch (err: any) {
      notification.error({
        message: err?.message,
        duration: 2,
      });
    } finally {
      setEmailLoader(false);
    }
  };

  const handleSave = async () => {
    try {
      setLoader(true);
      if (fileUrlData) {
        await imageUpload();
      }
      updateUserDetails();
      Close();
    } catch (err: any) {
      notification.error(err.message);
    } finally {
      setLoader(false);
    }
  };

  const selectTeams = async (e: any) => {
    setselectTeamsIds(e);
  };

  const imageUpload = async () => {
    try {
      const formData = new FormData();
      formData.append('image', fileUrlData);
      const response = await updateUserProfileImageUpload(
        userDetails.organization.api_url,
        userProfile.id,
        formData,
      );
      notification.success({
        message: response?.msg,
      });
    } catch (err: any) {
      notification.error({
        message: err.message,
        duration: 2,
      });
    }
  };

  return (
    <Layout>
      <div className={'rightSideWidegt ' + (toggleWidget ? 'expand' : 'hide')}>
        <div className="widgetCloseBtn">
          <img
            data-testid="editModalCloseIcon-EditEmployee"
            id={applicationIds.personnelScreen.AddEmpClose}
            src={closeGreen}
            alt="close icon"
            onClick={Close}
          />
        </div>
        <div className="profileEdit">
          <div className="proRow">
            <h4 className="headline3 rowGapBtm13">
              {t(translate.employeeEdit.title)}
            </h4>
          </div>
          <div className="uploadImg proRow mr0 rowGapBtm2">
            <div className="userIcon">
              <div className="proRow proPic">
                {fileUrl && fileUrl !== '' ? (
                  <Avatar src={fileUrl}></Avatar>
                ) : (
                  <Avatar
                    style={{
                      width: '145px',
                      height: '145px',
                      backgroundColor: '#67AFB7',
                      color: 'white',
                      fontFamily: 'Montserrat-Bold',
                    }}
                  >
                    {userProfile?.first_name?.charAt(0)}
                    {userProfile?.last_name?.charAt(0)}
                  </Avatar>
                )}
              </div>
              <ImageCrop
                setFileUrl={setFileUrl}
                setfileUrlData={setfileUrlData}
              ></ImageCrop>
            </div>
          </div>
          <div className="proRow">
            <AppLoader loader={loader} />
            <div className="width100 rowGapBtm3 ">
              <div>
                <Input
                  data-testid="firstNameEditInputField-EditEmployee"
                  placeholder="First Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  className={classNames({
                    errorClass:
                      firstNameError ||
                      firstNameSpecialCharaterError ||
                      firstNameLongError,
                  })}
                />
              </div>
              {firstNameError && (
                <label
                  style={{ color: 'red' }}
                  data-testid="minCharReqErrorMsg-firstName-EditEmployee"
                >
                  {t(translate.errorMessages.minCharError)}
                </label>
              )}
              {firstNameError && firstNameSpecialCharaterError && <br />}
              {firstNameSpecialCharaterError && (
                <label
                  style={{ color: 'red' }}
                  data-testid="specialCharErrorMsg-firstName-EditEmployee"
                >
                  {t(translate.errorMessages.specialCharError)}
                </label>
              )}
              {firstNameSpecialCharaterError && firstNameLongError && <br />}
              {firstNameLongError && (
                <label
                  style={{ color: 'red' }}
                  data-testid="maxCharReqErrorMsg-firstName-EditEmployee"
                >
                  {t(translate.errorMessages.maxCharError)}
                </label>
              )}
            </div>
            <div className="width100 rowGapBtm3">
              <div>
                <Input
                  data-testid="lastNameEditInputField-EditEmployee"
                  placeholder="Last Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  className={classNames({
                    errorClass:
                      lastNameError ||
                      lastNameSpecialCharacterError ||
                      lastNameLongError,
                  })}
                />
              </div>
              {lastNameError && (
                <label
                  style={{ color: 'red' }}
                  data-testid="minCharectorsReqErrorMsg-lasttName-EditEmployee"
                >
                  {t(translate.errorMessages.minCharError)}
                </label>
              )}
              {lastNameError && lastNameSpecialCharacterError && <br />}
              {lastNameSpecialCharacterError && (
                <label
                  style={{ color: 'red' }}
                  data-testid="specialCharErrorMsg-lasttName-EditEmployee"
                >
                  {t(translate.errorMessages.specialCharError)}
                </label>
              )}
              {lastNameSpecialCharacterError && lastNameLongError && <br />}
              {lastNameLongError && (
                <label
                  style={{ color: 'red' }}
                  data-testid="maxCharReqErrorMsg-lasttName-EditEmployee"
                >
                  {t(translate.errorMessages.maxCharError)}
                </label>
              )}
            </div>
            <h6 className="headline4 fSbd width100 mb10">
              {t(translate.employeeEdit.teams)}
            </h6>
            <div className=" width100 rowGapBtm3 teamsSelect">
              <Select
                mode="multiple"
                style={{
                  width: '100%',
                }}
                data-testid="TeamsEditInputField-EditEmployee"
                placeholder="Add Teams"
                value={teams && teams.length > 0 ? selectTeamsIds : ''}
                onChange={selectTeams}
                className="addEmployeeSelect multiSelectField"
                showSearch={true}
                optionFilterProp="children"
                filterOption={(input, option) => {
                  return (
                    option!.title
                      .toString()
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  );
                }}
              >
                {teams.map((team: any) => {
                  return (
                    <Option value={team?.id} key={team?.id} title={team.name}>
                      <div className="teamsDropdownBlock">
                        <div className="teamName">{team.name}</div>
                        <div className="teamDescription">
                          {team.description}
                        </div>
                      </div>
                    </Option>
                  );
                })}
              </Select>
              {selectTeamsIds.length > 0 && (
                <div className="mt10 flex-column">
                  {access.tractor_access ? (
                    <div style={{ color: '#366376' }}>
                      <CheckOutlined />{' '}
                      {t(
                        translate.employeeEdit.accessPermissions.tractorAccess,
                      )}
                    </div>
                  ) : (
                    <div>
                      <CloseOutlined />{' '}
                      {t(
                        translate.employeeEdit.accessPermissions.tractorAccess,
                      )}
                    </div>
                  )}
                  {access.app_access ? (
                    <div style={{ color: '#366376' }}>
                      <CheckOutlined />{' '}
                      {t(translate.employeeEdit.accessPermissions.appAccess)}
                    </div>
                  ) : (
                    <div>
                      <CloseOutlined />{' '}
                      {t(translate.employeeEdit.accessPermissions.appAccess)}
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="flex width100">
              {access.app_access && !email && (
                <Tooltip
                  title="Need Email to grant some permissions."
                  placement="bottom"
                >
                  <WarningFilled
                    style={{
                      color: '#fbb313',
                      marginRight: '10px',
                      fontSize: '22px',
                    }}
                  />
                </Tooltip>
              )}
              <span className="headline4 fSbd width100 mb10">
                {t(translate.employeeEdit.contact)}
              </span>
            </div>

            {access.app_access ? (
              <>
                <div className="width100 rowGapBtm3">
                  <div>
                    <Input
                      id={applicationIds.personnelScreen.editEmpMail}
                      data-testid="emailEditInputField-EditEmployee"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className={classNames({
                        errorClass: !validations.email,
                      })}
                    />
                  </div>
                  {emailError && (
                    <label
                      style={{ color: 'red' }}
                      data-testid="notValidMailErr-EditEmployee"
                    >
                      {t(translate.errorMessages.invalidEmail)}
                    </label>
                  )}
                </div>
              </>
            ) : (
              <>
                <div className="width100 rowGapBtm3">
                  <div>
                    <Input
                      id={applicationIds.personnelScreen.editEmpMail}
                      data-testid="emailEditInputField-EditEmployee"
                      placeholder="Email (optional)"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  {emailError && (
                    <label
                      style={{ color: 'red' }}
                      data-testid="notValidMailErr-EditEmployee"
                    >
                      {t(translate.errorMessages.invalidEmail)}
                    </label>
                  )}
                </div>
              </>
            )}
            <div className="width100 rowGapBtm3">
              <Input
                data-testid="contactNumEditInputField-EditEmployee"
                id={applicationIds.personnelScreen.editEmpContact}
                placeholder="Phone Number"
                value={contactNum}
                maxLength={15}
                onChange={(e) => setContactNum(e.target.value)}
                onKeyPress={(e: any) => onlyNumbersAllow(e)}
              />
            </div>
            {userProfile?.id !== userDetails?.id && (
              <>
                <h6 className="headline4 fSbd width100 mb10">
                  {t(translate.employeeEdit.status)}
                </h6>
                <div className="width100 rowGapBtm3">
                  <Select
                    data-testid="statusDropdown-EditEmployee"
                    getPopupContainer={(trigger) => trigger.parentNode}
                    // style={{ width: '284px' }}
                    value={status}
                    onSelect={(change) => setStatus(change)}
                    className="st_active"
                  >
                    <option
                      value="true"
                      key="true"
                      data-testid="activeStatus-EditEmployee"
                    >
                      {t(translate.employeeEdit.active)}
                    </option>
                    <option
                      value="false"
                      key="false"
                      data-testid="inActiveStatus-EditEmployee"
                    >
                      {t(translate.employeeEdit.inActive)}
                    </option>
                  </Select>
                </div>
              </>
            )}
          </div>
          <div className="proeditBtn tcktSaveBtn saveTxtBtn">
            <Button
              onClick={handleFormSubmit}
              data-testid="saveEditButton-EditEmployee"
              disabled={isDisabled}
            >
              {t(translate.buttons.save)}
            </Button>
          </div>
        </div>
      </div>
      {showConfirmPopUp && (
        <Modal
          closable={false}
          visible={showConfirmPopUp}
          className="delEmpModal editEmpModal"
          width={500}
          onOk={editProfileSave}
          onCancel={() => setShowConfirmPopUp(false)}
          footer={[
            <>
              <div>
                <Button
                  key="submit"
                  className="canelBtn"
                  onClick={() => {
                    setShowConfirmPopUp(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  key="back"
                  className="okBtn"
                  disabled={disableOkButton}
                  onClick={() => editProfileSave()}
                >
                  Ok
                </Button>
              </div>
              <p className="noteText">
                Note: Verification mail will be sent to new Email Id
              </p>
            </>,
          ]}
        >
          <div className="ant-modal-confirm-body-wrapper">
            <div className="ant-modal-confirm-body">
              <ExclamationCircleOutlined style={{ color: 'orange' }} />
              <div className="ant-modal-confirm-content">
                <p style={{ width: '350px' }}>
                  Are you sure you want to update email to <span>{email}</span>
                </p>

                <Input
                  placeholder="Confirm Email"
                  onChange={handleConfirmEmail}
                  value={confirmEmail}
                />
              </div>
            </div>
          </div>
          <AppLoader loader={emailLoader} />
        </Modal>
      )}
    </Layout>
  );
};
export default EditEmployee;
