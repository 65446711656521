import React, { useContext } from 'react';
import { Button, Modal, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import mtSupport from '../../assets/images/mtsupport.svg';
import { forwardTicketDetails } from '../../constants/Api';
import { delay } from '../../constants/Common';
import { ApplicationContext } from '../../context/AppContext';
import translate from '../../locale/en_translate.json';

interface Props {
  setForwardTicketLoader?: (data: boolean) => void;
  forwardCancel: () => void;
  TickObj: any;
  getTicketById: () => void;
  setEditFlagBody?: (data: boolean) => void;
  setTicketLoader: (data: boolean) => void;
}

const ForwardTicketModal: React.FC<Props> = ({
  setForwardTicketLoader,
  forwardCancel,
  TickObj,
  getTicketById,
  setEditFlagBody,
  setTicketLoader,
}: Props) => {
  const { userDetails } = useContext(ApplicationContext);
  const { t } = useTranslation();

  const forwardToMonarch = async () => {
    setEditFlagBody && setEditFlagBody(true);
    setForwardTicketLoader && setForwardTicketLoader(true);
    forwardCancel();
    try {
      await delay(2000);
      setForwardTicketLoader && setForwardTicketLoader(false);
      setTicketLoader(true);
      await forwardTicketDetails(
        userDetails.organization.api_url,
        TickObj?.fleet_id,
        TickObj?.id,
      );

      delay(2000).then(function () {
        setTicketLoader(false);
        setEditFlagBody && setEditFlagBody(false);
      });
      getTicketById();
    } catch (e: any) {
      notification.error({
        message: e.message,
        duration: 2,
      });
    }
  };
  return (
    <Modal
      visible={true}
      onCancel={forwardCancel}
      width={1000}
      closable={false}
      bodyStyle={{ height: 'auto' }}
      className="modalContainer profileUpdatePopup implementPopup"
      footer={[
        <>
          <Button
            data-testid="forwardCancelButton-ForwardTicketModal"
            key="back"
            className="impCancelBtn Button2"
            onClick={() => forwardCancel()}
          >
            {t(translate.buttons.cancel)}
          </Button>
          <Button
            data-testid="forwardToMonarchButton-ForwardTicketModal"
            key="submit"
            className="impDiscardBtn Button2 addTicketBtn"
            onClick={() => forwardToMonarch()}
          >
            {t(translate.buttons.forwardToMonarch)}
          </Button>
        </>,
      ]}
    >
      <div className="modalBox flexnone">
        <div className="formColW">
          <div className="userIcon">
            <h4 className="headline3">
              <img src={mtSupport} alt="mtSupport" height="36px" />{' '}
              {t(translate.tickets.forward)} {TickObj?.ticket_id}{' '}
              {t(translate.tickets.toMonarch)}
            </h4>
            <div className="body2 mt14">
              {t(translate.tickets.forwardTicketMsg)}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ForwardTicketModal;
