import React, { useContext, useEffect, useRef, useState } from 'react';
import { Row, Input, notification, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import Layout, { Content } from 'antd/lib/layout/layout';
import InfiniteScrollTable from '../common/InfiniteScrollTable';
import { getChargePoints, getChargePointDetails } from '../../constants/Api';
import translate from '../../locale/en_translate.json';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { ApplicationContext } from '../../context/AppContext';
import { initScroller } from '../../constants/Common';
import { ChargePointDetailsList } from '../../constants/types';
import AddChargePoint from './AddChargePoint';
import ChargePointDetails from './ChargePointDetails';
import { mapChargingPointData } from '../../lib/dataFormat';
import usePaginate from '../../hooks/usePaginate';

const ChargePoints: React.FC = () => {
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const [loader, setLoader] = useState<boolean>(true);
  const [total, setTotal] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [search, setSearch] = useState<string>('');
  const [init, setInit] = useState<boolean>(false);
  const [addChargePoint, setAddChargePoint] = useState<boolean>(false);
  const [pageSize] = useState<number>(25);
  const [detailsList, setDetailsList] =
    useState<ChargePointDetailsList | null>(null);
  const { filterData, setData, activity, hasMore, checkActivity } =
    usePaginate();

  const searchString = useRef<string>('');

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      setInit(true);
      loadChargePoints();
    }
  }, [userDetails]);

  useEffect(() => {
    if (init) {
      loadChargePoints();
    }
  }, [pageNumber, search]);

  const loadChargePoints = () => {
    if (userDetails && !userDetails.organization) return;
    if (checkActivity()) {
      pageNumber !== 1 ? setPageNumber(1) : getChargePointsList();
    } else if (activity.current === 'paginate' || activity.current === '') {
      getChargePointsList();
    }
  };

  const getChargePointsList = async () => {
    if (checkActivity()) initScroller();

    try {
      setLoader(true);
      const result = await getChargePoints(
        userDetails.organization.api_url,
        userDetails.organization.id,
        pageNumber,
        pageSize,
        search,
      );
      setTotal(result?._metadata?.total_records_count);
      const { records } = result;
      let data = Array.isArray(records) ? records : [];
      data = mapChargingPointData(data);
      setData(data);
    } catch (error: any) {
      notification.error({
        message: error.message,
        duration: 2,
      });
    } finally {
      activity.current = '';
      setLoader(false);
    }
  };

  const columns = [
    {
      title: `${t(translate.chargePoints.chargePointName)}`,
      dataIndex: 'charge_point_name',
      key: 'charge_point_name',
    },
    {
      title: `${t(translate.chargePoints.make)}`,
      dataIndex: 'make',
      key: 'make',
    },
    {
      title: `${t(translate.implements.model)}`,
      dataIndex: 'model',
      key: 'model',
    },
    {
      title: `${t(translate.chargePoints.electricityProvider)}`,
      dataIndex: 'electricity_provider',
      key: 'electricity_provider',
    },
  ];

  const handleLoadMore = () => {
    if (pageNumber === 1 && checkActivity() && document) initScroller();
    activity.current = 'paginate';
    setPageNumber(pageNumber + 1);
  };

  const handleSearch = (e: any) => {
    activity.current = 'search';
    const value = e.target.value.trim().regX;
    if (value.length <= 1) {
      searchString.current = '';
      setSearch('');
    } else {
      searchString.current = value;
      setSearch(value);
    }
  };

  const Close = (state = false) => {
    setDetailsList(null);
    if (state) {
      activity.current = 'refresh';
      loadChargePoints();
    }
  };

  const getRow = (record: any) => {
    getPointDetails(record.id);
  };

  const getPointDetails = async (id: number) => {
    try {
      setLoader(true);
      const response = await getChargePointDetails(
        userDetails.organization.api_url,
        userDetails.organization.id,
        id,
      );
      setDetailsList(response);
    } catch (err: any) {
      notification.error({
        message: err.message,
        duration: 2,
      });
    } finally {
      setLoader(false);
    }
  };

  return (
    <Layout>
      <div className="mainContent">
        <Content>
          <div className="tblContainer viewportContainer">
            <Row>
              <div className="common_wrapper wWidget_wrapper">
                <div className="filters_card spaceBtnAlignCenter">
                  <div className="displayFlex">
                    <Space size="large">
                      <Input
                        addonBefore={<SearchOutlined />}
                        placeholder="Search Name"
                        className="common_search"
                        onChange={(e) => handleSearch(e)}
                        autoComplete="off"
                      />
                    </Space>
                  </div>

                  <div
                    className="addNewBtn Button2"
                    onClick={() => setAddChargePoint(true)}
                  >
                    <PlusOutlined /> {t(translate.buttons.addNew)}
                  </div>
                </div>
                <div className="common_table">
                  <InfiniteScrollTable
                    columns={columns}
                    hasMore={hasMore}
                    loading={loader}
                    filterData={filterData}
                    totalcount={total}
                    handleLoadMore={handleLoadMore}
                    filename="Charge Points"
                    onRowClick={(record) => getRow(record)}
                  />
                </div>
              </div>
            </Row>
          </div>
        </Content>
        {addChargePoint && (
          <AddChargePoint
            Close={(state) => {
              setAddChargePoint(false);
              Close(state);
            }}
          />
        )}
        {detailsList && (
          <ChargePointDetails Close={Close} details={detailsList} />
        )}
      </div>
    </Layout>
  );
};

export default ChargePoints;
