/* eslint-disable no-console */
/* eslint-disable react/jsx-key */
import React, { useContext, useEffect, useRef, useState } from 'react';
import sendIcon from '../../assets/images/sendIcon.svg';
import { closeRoom, getConverseHistory } from '../../constants/Api';
import { Avatar, Button, Input, notification } from 'antd';
import { ApplicationContext } from '../../context/AppContext';
import AppLoader from '../common/AppLoader';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { SupportRoomList, converseHistory } from '../../constants/types';
import { getDateStamp, getHour } from '../../constants/Common';
import { PubSub } from 'aws-amplify';
import { ATOM } from '../../constants/constant';

interface Props {
  supportRoomDetails: SupportRoomList;
  closeChat: boolean;
  setCloseChat: (data: boolean) => void;
  updateChat: () => void;
  selectRoom: number;
  setSelectRoom: (data: any) => void;
}
export const ConverseChat: React.FC<Props> = ({
  supportRoomDetails,
  closeChat,
  setCloseChat,
  updateChat,
  selectRoom,
  setSelectRoom,
}) => {
  const { userDetails } = useContext(ApplicationContext);
  const [loader, setLoader] = useState(false);
  const [chatList, setChatList] = useState<any[]>([]);
  const [message, setMessage] = useState<string>('');
  const date = Date.now();
  const containerRef = useRef(null);
  const [messagesList, setMessagesList] = useState<any>(null);

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      getChatHistory();
    }
  }, [userDetails, supportRoomDetails, selectRoom]);

  const getChatHistory = async () => {
    try {
      setLoader(true);
      const list = await getConverseHistory(
        userDetails.organization.api_url,
        userDetails.organization.id,
        userDetails.id,
        selectRoom ? selectRoom : supportRoomDetails?.room_obj.room_uuid,
      );
      setChatList(list.records.reverse());
    } catch (error: any) {
      notification.error({ message: error.message });
    } finally {
      setLoader(false);
    }
  };

  const sendMsgs = () => {
    const roomId = supportRoomDetails?.room_obj.room_uuid;
    const orgId = supportRoomDetails.room_obj.organization.id;
    const payload = {
      organization_id: orgId,
      supportUser: true,
      room_uuid: roomId,
      source: ATOM,
      from: {
        user_id: userDetails?.id,
        first_name: userDetails?.first_name,
        last_name: userDetails?.last_name,
        email: userDetails?.username,
      },
      from_user: {
        user_id: userDetails?.id,
        first_name: userDetails?.first_name,
        last_name: userDetails?.last_name,
        email: userDetails?.username,
      },
      messageId: uuidv4(),
      content: {
        text: message,
      },
      datetime_ms: date,
      datetime: getDateStamp(date),
    };
    try {
      PubSub.publish(
        `support/organization/${userDetails.organization.id}/room/${roomId}`,
        payload,
      );
      setMessage('');
    } catch (error) {
      console.log('error publishing', error);
    }
  };

  useEffect(() => {
    if (
      userDetails &&
      userDetails.organization &&
      supportRoomDetails &&
      supportRoomDetails?.room_obj
    ) {
      const sub2 = PubSub.subscribe(
        `support/organization/${userDetails.organization.id}/room/${supportRoomDetails?.room_obj.room_uuid}`,
      ).subscribe({
        next: (data: any) => {
          console.log('data  ', data.value);

          const message = data.value;

          if (
            supportRoomDetails?.room_obj.room_uuid === message.room_uuid &&
            message?.content?.text &&
            message?.content?.text?.trim().length > 0
          ) {
            const append = {
              from_user: {
                first_name: message.from.first_name,
                last_name: message.from.last_name,
              },
              to_user: {
                first_name: message.to?.first_name,
                last_name: message.to?.last_name,
              },
              room_uuid: message.room_uuid,
              message: message?.content?.text,
              message_metadata: message,
            };
            setChatList((chatList: any) => [...chatList, append]);
          }
          if (
            message.close_room === true &&
            message.room_uuid === supportRoomDetails?.room_obj?.room_uuid
          ) {
            setTimeout(() => {
              updateChat();
            }, 3000);
          }
        },
        error: (error: any) => console.error('error subscribing', error),
      });

      return () => {
        sub2.unsubscribe();
      };
    }
  }, [userDetails, supportRoomDetails.room_obj]);

  const formatListMessages = () => {
    let tData = [...chatList];
    tData = tData.reduce((a, notification) => {
      const diff = moment()
        .endOf('day')
        .diff(moment(notification.created_date_time), 'hours');
      const formatDate = moment(notification.created_date_time).format(
        'dddd, MMM D, YYYY',
      );
      const day = diff < 24 ? 'Today' : formatDate;
      if (Array.isArray(a[day])) {
        a[day].push(notification);
      } else {
        a[day] = [];
        a[day].push(notification);
      }
      return a;
    }, {});
    setMessagesList(tData);
  };

  useEffect(() => {
    formatListMessages();
  }, [chatList]);

  const onKeyEnter = (e: any) => {
    if (e.key === 'Enter') {
      sendMsgs();
    }
  };

  const profileImage = (data: any) => {
    const image =
      data?.message_metadata?.from_user?.user_id ===
      supportRoomDetails?.room_obj?.customer_user_info.id ? (
        <Avatar
          src={supportRoomDetails?.room_obj?.customer_user_info?.profile_url}
          className="avatarMT"
          size={56}
        >
          {supportRoomDetails?.room_obj?.customer_user_info?.first_name.charAt(
            0,
          )}
        </Avatar>
      ) : (
        <Avatar className="avatarMT" size={56}>
          {data?.message_metadata?.from_user?.first_name.charAt(0)}
        </Avatar>
      );
    return image;
  };

  const closeRoomChat = async () => {
    try {
      setLoader(true);
      if (supportRoomDetails) {
        const payload = {
          customer_user_id: userDetails?.id,
        };
        const response = await closeRoom(
          userDetails.organization.api_url,
          userDetails.organization.id,
          userDetails.id,
          supportRoomDetails?.room_obj.room_uuid,
          payload,
        );
        notification.success({ message: response.message });
        updateChat();
      }
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (containerRef && containerRef.current) {
      const element: any = containerRef.current;
      element.scroll({
        top: element.scrollHeight,
        left: 0,
        behavior: 'smooth',
      });
    }
  }, [containerRef, messagesList, closeChat]);

  return (
    <div className="converseChat">
      <div className="chatMsgsBody" ref={containerRef}>
        {messagesList &&
          Object.keys(messagesList)
            .sort((a: any, b: any) => b.created_date_time - a.created_date_time)
            .map((item, ky: number) => {
              const view = messagesList[item].map(
                (data: converseHistory, index: number) => (
                  <>
                    {data?.message_metadata?.content?.text &&
                      data?.message_metadata?.content?.text.length > 0 && (
                        <div
                          className={
                            data?.message_metadata?.from_user?.user_id ===
                            (supportRoomDetails?.room_obj?.customer_user_info &&
                              supportRoomDetails?.room_obj?.customer_user_info
                                .id)
                              ? 'msgText rowReverse'
                              : 'msgText'
                          }
                        >
                          <div>
                            {supportRoomDetails?.room_obj?.customer_user_info &&
                              profileImage(data)}
                          </div>

                          <div
                            className={
                              data?.message_metadata?.from_user?.user_id ===
                              (supportRoomDetails?.room_obj
                                ?.customer_user_info &&
                                supportRoomDetails?.room_obj?.customer_user_info
                                  .id)
                                ? 'userMsgBox white triangle right-top'
                                : 'userMsgBox triangle left-top'
                            }
                          >
                            <div className="talkText">
                              <div className="orgTractorName">
                                {data?.message_metadata?.from_user.first_name}
                                <span className="msgTime body3">
                                  {getHour(data?.created_date_time)}
                                </span>
                              </div>
                              <div className="body1">
                                {data?.message_metadata?.content?.text}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                  </>
                ),
              );
              return (
                <>
                  <div key={ky}>
                    <p className="headline4 chatDate">{item}</p>
                    {view}
                  </div>
                </>
              );
            })}
      </div>
      {selectRoom ? (
        <div className="closeChatTxt">
          <div>This conversion has been closed.</div>
          <Button className="backToChat" onClick={() => setSelectRoom('')}>
            Back
          </Button>
        </div>
      ) : (
        <>
          {closeChat ? (
            <div className="endConverse">
              <p>Are you sure, you want to end this converse</p>
              <Button
                className="chatCloseBtn bgGray mr12"
                onClick={() => setCloseChat(false)}
              >
                CANCEL
              </Button>
              <Button className="chatCloseBtn Button" onClick={closeRoomChat}>
                END
              </Button>
            </div>
          ) : (
            <div className="chatSendingBlk">
              <Input
                maxLength={150}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                onKeyUp={(e: any) => onKeyEnter(e)}
                placeholder="Start a new message"
                className="overline1"
              />
              <div className="chatSendingOptions">
                <div className="chatCount">
                  {message !== undefined && message.length} <> / 150</>
                </div>
                <div className="msgSendBtn" onClick={sendMsgs}>
                  <span>Send</span>
                  <img height="12px" src={sendIcon} alt="" />
                </div>
              </div>
            </div>
          )}
        </>
      )}
      <AppLoader loader={loader} />
    </div>
  );
};
