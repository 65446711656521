/* eslint-disable no-restricted-globals */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-unused-vars */
/* eslint-disable react/require-default-props */
/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { Select } from 'antd';
import './style.css';

interface Props {
  label?: string;
  value?: any[];
  cssClass?: string;
  setValue?: (data: string | number | undefined) => void;
  options?: any[];
  optionKey?: string;
  optionDisplay?: string;
  required?: boolean;
  isAll?: boolean;
  testId?: any;
  ketValue?: any;
}
const CustomMultiSelectMode: React.FC<Props> = ({
  label,
  value,
  setValue,
  cssClass,
  options,
  optionKey = 'id',
  optionDisplay,
  required,
  isAll,
  testId,
  ketValue,
}: Props) => {
  const [focus, setFocus] = useState(false);

  const isEmpty = (value: any) => {
    if (typeof value === 'undefined') return true;
    if (typeof value === 'string' && value.trim() === '' && !isAll) return true;
    if (typeof value === 'number' && isNaN(value)) return true;
    if (value?.length === 0) return true;
    return false;
  };

  const labelClass = focus || !isEmpty(value) ? 'label label-float' : 'label';

  return (
    <div
      className="float-label multiple"
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      <Select
        data-testid={testId}
        value={value}
        className={`dropdown ${cssClass}`}
        onChange={(e: any) => {
          setValue && setValue(e);
        }}
        mode="multiple"
        maxTagCount={1}
        allowClear
      >
        {options &&
          options?.length > 0 &&
          options?.map((data: any, key) => (
            <option
              value={data[optionKey]}
              key={key}
              data-testid={`${
                optionDisplay ? data[optionDisplay] : data[optionKey]
              }-CustomSelect1`}
            >
              {optionDisplay ? data[optionDisplay] : data[optionKey]}{' '}
              {ketValue || ''}
            </option>
          ))}
      </Select>
      <label className={labelClass}>
        {label} {required && <span className="float-span">*</span>}
      </label>
    </div>
  );
};

export default CustomMultiSelectMode;
