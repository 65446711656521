/* eslint-disable no-console */
import React, { useContext, useEffect, useState } from 'react';

import { Checkbox, Modal, notification } from 'antd';
import loading_logo from '../../assets/images/loading_logo.gif';
import { discoverPolygon } from '../../constants/Api';
import { ApplicationContext } from '../../context/AppContext';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';

interface Props {
  showDiscover: boolean;
  handleCancel: (reloadPoly?: boolean) => void;
  brownPoly: any;
}
export const DiscoverModal2: React.FC<Props> = ({
  showDiscover,
  handleCancel,
  brownPoly,
}: Props) => {
  const { userDetails } = useContext(ApplicationContext);
  const [loader, setLoader] = useState(false);
  const [selectedFields, setselectedFields] = useState<CheckboxValueType[]>([]);
  const plainOptions = [
    { label: 'Find My Fields', value: 'green' },
    { label: 'Find My Blue Fields', value: 'blue' },
  ];
  const onClickReload = async () => {
    if (selectedFields && selectedFields.length > 0) {
      selectedFields.map((item) => discoverPolygonHandler(`${item}`));
    } else {
      notification.error({
        message: 'Please select option',
      });
    }
  };

  const discoverPolygonHandler = async (color = '') => {
    setLoader(true);
    try {
      const polygonUuid = brownPoly.polygon_uuid;
      const payload: { polygon_uuids: string[]; color?: string } = {
        polygon_uuids: [polygonUuid],
      };
      if (color !== '') {
        payload.color = color;
      }
      const { organization } = userDetails;
      const resp = await discoverPolygon(
        organization.api_url,
        organization.farm.id,
        payload,
      );
      if (resp && resp.msg) {
        handleCancel(true);
        notification.success({
          message: resp.msg,
        });
      }
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    } finally {
      setLoader(false);
    }
  };

  const onChange = (checkedValues: CheckboxValueType[]) => {
    // console.log('checked = ', checkedValues);
    setselectedFields(checkedValues);
  };
  return (
    <Modal
      className="commonPopup addPolygon"
      title=""
      centered
      width={'50vw'}
      visible={showDiscover}
      onCancel={() => handleCancel()}
      okText="Discover"
      onOk={onClickReload}
      okButtonProps={{
        className: 'btnSave ',
        disabled: false,
      }}
      cancelButtonProps={{
        className: 'btnCancel',
      }}
    >
      <div className="cfqRadioBtn">
        <Checkbox.Group
          options={plainOptions}
          defaultValue={['Apple']}
          onChange={onChange}
        />
      </div>
      <div className="edit-loader">
        <div className="edit-loader disoverLoaderLeft">
          {loader && (
            <div className="loader">
              <img
                style={{ width: '40px', height: '40px' }}
                src={loading_logo}
              />
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};
export default DiscoverModal2;
