import React from 'react';
import { Progress } from 'antd';
import './styles.scss';

interface Props {
  className?: string;
  status: string;
  percent: number;
}

const ProgressBar: React.FC<Props> = ({ className, status, percent }) => {
  const tstatus = status?.toLocaleLowerCase();
  return (
    <div className={`progressBarContainer ${className ?? ''}`}>
      <span className="pBLeftContent">
        {['pending', 'success', 'inprogress'].includes(tstatus) && (
          <Progress type="circle" width={32} percent={percent || 0} />
        )}
        {['failed'].includes(tstatus) && (
          <Progress
            type="circle"
            width={32}
            percent={percent || 0}
            status="exception"
          />
        )}
      </span>
      {<span>({status})</span>}
    </div>
  );
};

export default ProgressBar;
