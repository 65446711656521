import React from 'react';
import './styles.scss';

export const MapSkeleton: React.FC = () => (
  <div className="animationDiv bgNone">
    <div className="map-animation">
      <div className="map-top-line">
        <div className="map-top-line-sec">
          <div>
            <div className="mline w153 h17"></div>
          </div>
          <div className="map-top-small-lines">
            <div className="mline w63 h17"></div>
            <div className="mline w63 h17"></div>
            <div className="mline w63 h17"></div>
            <div className="mline w63 h17"></div>
          </div>
        </div>
      </div>
      {/* <div className="map-bg"></div> */}
      <div className="map-legend">
        <div className="topLine"></div>
        <div className="bullet-line-block">
          <div className="bullet_lines">
            <div className="bullet"></div>
            <div className="mline w53"></div>
          </div>
          <div className="bullet_lines">
            <div className="bullet"></div>
            <div className="mline w40"></div>
          </div>
          <div className="bullet_lines">
            <div className="bullet"></div>
            <div className="mline w63"></div>
          </div>
          <div className="bullet_lines">
            <div className="bullet"></div>
            <div className="mline w16"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export const CardSkeleton: React.FC = () => (
  <div className="animationDiv">
    <div className="ani_cards_container">
      <div className="ani_cards"></div>
      <div className="ani_cards"></div>
      <div className="ani_cards"></div>
      <div className="ani_cards"></div>
    </div>
  </div>
);

export const ChartSkeleton: React.FC = () => (
  <div className="animationDiv">
    <div className="card_a">
      <div className="header_a">
        <div className="img_a"></div>
        <div className="details_a">
          <span className="name_a"></span>
          <span className="about_a"></span>
        </div>
      </div>
      <div className="description_a">
        <div className="line_a line-1"></div>
        {/* <div className="line_a line-2"></div> */}
        <div className="line_a line-3"></div>
      </div>
      <div className="animate-pulse">
        <div className="flex space-x-4 items-baseline m-6">
          <div className="flex-1 h-24 bg-gray-200 rounded"></div>
          <div className="flex-1 h-36 bg-gray-200 rounded"></div>
          <div className="flex-1 h-48 bg-gray-200 rounded"></div>
          <div className="flex-1 h-64 bg-gray-300 rounded"></div>
          <div className="flex-1 h-72 bg-gray-200 rounded"></div>
          <div className="flex-1 h-64 bg-gray-300 rounded"></div>
          <div className="flex-1 h-48 bg-gray-200 rounded"></div>
          <div className="flex-1 h-36 bg-gray-200 rounded"></div>
          <div className="flex-1 h-72 bg-gray-300 rounded"></div>
          <div className="flex-1 h-36 bg-gray-200 rounded"></div>
          <div className="flex-1 h-48 bg-gray-200 rounded"></div>
          <div className="flex-1 h-64 bg-gray-300 rounded"></div>
          <div className="flex-1 h-72 bg-gray-200 rounded"></div>
          <div className="flex-1 h-64 bg-gray-300 rounded"></div>
          <div className="flex-1 h-48 bg-gray-200 rounded"></div>
          <div className="flex-1 h-36 bg-gray-200 rounded"></div>
          <div className="flex-1 h-24 bg-gray-200 rounded"></div>
        </div>
      </div>
    </div>
  </div>
);

export const LineChartSkeleton: React.FC = () => (
  // <div className="savings posrel">
  <div className="animationDiv">
    <div className="graph_ani_container">
      <div className="box_ani"></div>
      <div className="description_a">
        <div className="line_a line-1"></div>
        <div className="line_a line-2"></div>
        <div className="line_a line-3"></div>
      </div>
    </div>
  </div>
  // </div>
);
