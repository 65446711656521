import React from 'react';
import connecting_generating_path from '../../assets/images/connecting_generating_path.gif';

interface Props {
  loader: boolean;
}

const DairyLoader: React.FC<Props> = ({ loader }) => {
  return (
    <div className="edit-loader">
      {loader && (
        <div className="connectAutoDriveLoader loader">
          <img
            style={{ width: '100px', height: '100px', marginLeft: 40 }}
            src={connecting_generating_path}
          />
        </div>
      )}
    </div>
  );
};

export default DairyLoader;
