import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Input, Row, Tag, message, notification } from 'antd';
import Layout from 'antd/lib/layout/layout';
import translate from '../../../locale/en_translate.json';
import InfiniteScrollTable from '../../common/InfiniteScrollTable';
import usePaginate from '../../../hooks/usePaginate';
import { ApplicationContext } from '../../../context/AppContext';
import { initScroller, tractorConstantsNew } from '../../../constants/Common';
import { useTranslation } from 'react-i18next';
import './style.css';
import CustomSelect from '../../common/CustomSelect';
import { getBaseStationHealth, getTicketCategory } from '../API';
import CropOrgFilter from '../../common/CropOrgFilter';
import { EyeTwoTone, SearchOutlined } from '@ant-design/icons';
import TableExt from '../../widget/TableExt';
import constants from '../../../constants/constant';
import useColumns from '../../../hooks/useColumns';
import { mapNexusBasestationHealthData } from '../../../lib/dataFormat';
import BaseAudit from './BaseAudit';
import BaseStationHealthModal from './BaseStationHealthModal';

const { DOWNLOAD_SIZE } = constants;
const title = 'BasestationHealth';
let columnsData: any[] = [];

const BaseStationHealth = () => {
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const searchString = useRef<string>('');
  const [selectedOrgId, setSelectedOrgId] = useState<string[]>([]);
  const [search, setSearch] = useState<string>('');
  const [total, setTotal] = useState<number>(0);
  const [pageSize] = useState<any>(25);
  const [loading, setLoading] = useState(false);
  const [connection, setConnection] = useState<any>('online');
  const [toggleColumnsStatus, setToggleColumnsStatus] =
    useState<boolean>(false);
  const { filterData, setData, activity, hasMore, checkActivity } =
    usePaginate();
  const { headers, columns, setColumns, setColumnsData } =
    useColumns('BasestationHealth');
  const tableRef = useRef<any>(null);
  const [csvData, setCSVData] = useState<any[]>([]);
  const [downloadData, setDownloadData] = useState({
    isDownloaded: false,
    percent: 0,
    status: '',
  });
  const [showModal, setShowModal] = useState<boolean>(false);
  const [baseStationHealthDetails, setBaseStationHealthDetails] =
    useState<any>('');
  const [toggleErrorCodes, setToggleErrorCodes] = useState<boolean>(false);
  const connectionData = [
    { name: 'Online', value: 'online' },
    { name: 'Offline', value: 'offline' },
  ];
  const [modelData, setModelData] = useState<any[]>([]);
  const [model, setModel] = useState<string>('');
  const [subStatus, setsubStatus] = useState<any>('online');
  const [routerStatus, setrouterStatus] = useState<any>('online');
  const init = useRef(true);

  useEffect(() => {
    const init = async () => {
      try {
        const data = await getTicketCategory('basestation_model');
        setModelData(data);
      } catch (error: any) {
        message.error(error?.message);
      }
    };
    init();
  }, []);
  const wrapResult = async (
    customer_org_id: any,
    page_number: number,
    page_size: number,
    connection: any,
    searchKey: string,
    subStatus: any,
    routerStatus: any,
    model: any,
  ) => {
    const result = await getBaseStationHealth(
      customer_org_id,
      page_number,
      page_size,
      connection,
      searchKey,
      subStatus,
      routerStatus,
      model,
    );
    return {
      result,
      searchKey: search,
    };
  };
  const getBasestationHealthList = async (orgData: any, page_number: any) => {
    if (!userDetails?.organization || selectedOrgId.length === 0) return;
    try {
      if (checkActivity()) initScroller();
      setLoading(true);
      const { result, searchKey } = await wrapResult(
        selectedOrgId,
        page_number,
        pageSize,
        connection,
        search,
        subStatus,
        routerStatus,
        model,
      );

      if (searchString.current !== searchKey) return;
      setTotal(result?._metadata?.total_records_count);

      const { records } = result;
      let data = Array.isArray(records) ? records : [];
      data = mapNexusBasestationHealthData(data);
      setData(data);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    } finally {
      if (!init.current) init.current = false;
      setLoading(false);
    }
  };

  const loadBasestationHealth = () => {
    if (userDetails && !userDetails.organization) return;
    if (checkActivity()) {
      pageNumber !== 1
        ? setPageNumber(1)
        : getBasestationHealthList(selectedOrgId, pageNumber);
    } else if (activity.current === 'paginate' || activity.current === '') {
      getBasestationHealthList(selectedOrgId, pageNumber);
    }
  };

  const handleLoadMore = () => {
    if (pageNumber === 1 && checkActivity() && document) initScroller();

    activity.current = 'paginate';
    setPageNumber(pageNumber + 1);
  };

  const handleSearch = (e: any) => {
    activity.current = 'search';
    const value = e.target.value.trim();
    if (value.length <= 1) {
      searchString.current = '';
      setSearch('');
    } else {
      searchString.current = value;
      setSearch(value);
    }
  };

  useEffect(() => {
    if (init.current && selectedOrgId.length > 0) {
      loadBasestationHealth();
      init.current = false;
    }
  }, [
    userDetails,
    pageNumber,
    search,
    selectedOrgId,
    connection,
    model,
    subStatus,
    routerStatus,
  ]);

  useEffect(() => {
    columnsData = [
      {
        title: t(translate.healthData.name),
        dataIndex: 'name',
        key: 'name',
        render: (name: string) => <span>{name}</span>,
        onCell: (record: any) => ({
          onClick: () => getRow(record),
        }),
        width: '100px',
        ellipsis: true,
      },
      {
        title: `${t(translate.healthData.customerName)}`,
        dataIndex: 'customerName',
        key: 'customerName',
        ellipsis: true,
        render: (customerName: string) => <span>{customerName}</span>,
        onCell: (record: any) => ({
          onClick: () => getRow(record),
        }),
        width: '180px',
      },
      {
        title: `${t(translate.healthData.model)}`,
        dataIndex: 'model',
        key: 'model',
        onCell: (record: any) => ({
          onClick: () => getRow(record),
        }),
        width: '100px',
      },
      {
        title: `${t(translate.healthData.serialNumber)}`,
        dataIndex: 'serial_number',
        key: 'serial_number',
        onCell: (record: any) => ({
          onClick: () => getRow(record),
        }),
        width: '210px',
      },
      {
        title: `${t(translate.healthData.softwareVersion)}`,
        dataIndex: 'software_version',
        key: 'software_version',
        onCell: (record: any) => ({
          onClick: () => getRow(record),
        }),
        width: '150px',
        ellipsis: true,
      },
      {
        title: `${t(translate.healthData.lastUpdatedTime)}`,
        dataIndex: 'date_time',
        key: 'date_time',
        width: '180px',
        onCell: (record: any) => ({
          onClick: () => getRow(record),
        }),
      },
      {
        title: `${t(translate.healthData.activeNetwork)}`,
        dataIndex: 'active_network',
        key: 'active_network',
        onCell: (record: any) => ({
          onClick: () => getRow(record),
        }),
        width: '150px',
      },
      {
        title: `${t(translate.healthData.routerstatus)}`,
        dataIndex: 'router_status',
        key: 'router_status',
        onCell: (record: any) => ({
          onClick: () => getRow(record),
        }),
        width: '150px',
      },
      {
        title: `${t(translate.healthData.sbcstatus)}`,
        dataIndex: 'sbc_status',
        key: 'sbc_status',
        onCell: (record: any) => ({
          onClick: () => getRow(record),
        }),
        width: '150px',
      },
      {
        title: `${t(translate.healthData.status)}`,
        dataIndex: 'status',
        key: 'status',
        onCell: (record: any) => ({
          onClick: () => getRow(record),
        }),
        width: '100px',
        render: (status: any, record: any) => {
          const { isAvailable } = record;
          return (
            <span
              className={`fleetMsgTxt ${
                isAvailable === 'online'
                  ? tractorConstantsNew.available.class
                  : tractorConstantsNew.off.class
              }`}
            >
              {isAvailable === 'online' ? 'On' : 'Off'}
            </span>
          );
        },
      },
      {
        title: t(translate.healthData.rtkConfigured),
        dataIndex: 'rtkConfigured',
        key: 'rtkConfigured',
        width: '150px',
        onCell: (record: any) => ({
          onClick: () => getRow(record),
        }),
        ellipsis: true,
      },
      {
        title: `${t(translate.healthData.tractorsConnected)}`,
        dataIndex: 'tractorsConnected',
        key: 'tractorsConnected',
        width: '250px',
        onCell: (record: any) => ({
          onClick: () => getRow(record),
        }),
        render: (tractors: Array<string>, dataitem: any) =>
          tractors &&
          tractors?.length > 0 &&
          tractors?.map((tractor: any) => (
            <Tag color="blue" key={tractor} style={{ marginTop: 4 }}>
              {tractor}
            </Tag>
          )),
      },
      {
        title: t(translate.healthData.audit),
        dataIndex: 'audit',
        key: 'audit',
        render: (name: string, record: any) => (
          <span>
            {' '}
            <EyeTwoTone
              style={{ fontSize: '20px' }}
              onClick={() => {
                getRowValue(record);
              }}
            />
          </span>
        ),
        width: '100px',
        ellipsis: true,
      },
    ];
    setColumnsData(columnsData);
  }, []);

  const getRow = (record: any) => {
    setBaseStationHealthDetails(record);
    setShowModal(true);
  };
  const handleOrg = (ids: any) => {
    activity.current = 'filter';
    setSelectedOrgId(ids);
  };
  const handleRefresh = () => {
    activity.current = 'refresh';
    loadBasestationHealth();
  };

  const handleDownload = async () => {
    try {
      if (downloadData.status === 'start') return;
      setDownloadData({
        ...downloadData,
        status: 'start',
        percent: 10,
      });
      let data: any[] = [];
      const pages = Math.ceil(total / DOWNLOAD_SIZE);
      for (const page of Array.from({ length: pages }, (_, i) => i + 1)) {
        const { result } = await wrapResult(
          selectedOrgId,
          page,
          DOWNLOAD_SIZE,
          connection,
          search,
          subStatus,
          routerStatus,
          model,
        );
        const { records } = result;
        let tdata = Array.isArray(records) ? records : [];
        tdata = mapNexusBasestationHealthData(tdata);
        data = [...data, ...tdata];
        setDownloadData({
          ...downloadData,
          status: 'start',
          percent: Math.round((data.length / total) * 100),
        });
      }

      setCSVData([...data]);
      setDownloadData({
        ...downloadData,
        percent: 100,
        status: 'success',
      });
    } catch (error: any) {
      setDownloadData({
        ...downloadData,
        status: 'exception',
      });
      notification.error({ message: error.message });
    }
  };

  const getRowValue = (record: any) => {
    setBaseStationHealthDetails(record);
    setToggleErrorCodes(true);
  };
  const handleSubmit = () => {
    if (userDetails && selectedOrgId.length) {
      loadBasestationHealth();
    } else {
      message.warning('Please select Crop');
    }
  };

  return (
    <>
      <Layout onClick={() => setToggleColumnsStatus(!toggleColumnsStatus)}>
        <div className="mainContent">
          <div className="tblContainer viewportContainer">
            <Row>
              <div className="common_wrapper tabFilterWidget_wrapper">
                <div className="filters_card personnelCard ad_filters_card filtersHealthScreen">
                  <Input
                    addonBefore={<SearchOutlined />}
                    placeholder="Search Directory"
                    className="common_search w160"
                    onChange={(e) => handleSearch(e)}
                    autoComplete="off"
                  />

                  <CropOrgFilter handleOrgs={handleOrg} isDealer />

                  <CustomSelect
                    label="status"
                    cssClass="min_width"
                    value={connection}
                    setValue={(id: any) => {
                      setConnection(id);
                    }}
                    options={connectionData}
                    optionKey="value"
                    optionDisplay="name"
                    isAll
                  />

                  <CustomSelect
                    label="model"
                    cssClass="min_width"
                    value={model}
                    setValue={(id: any) => {
                      setModel(id);
                    }}
                    options={modelData}
                    optionKey="name"
                    optionDisplay="name"
                    isAll
                  />

                  <>
                    <CustomSelect
                      label="Sbc Status"
                      cssClass="min_width"
                      value={subStatus}
                      setValue={(id: any) => {
                        setsubStatus(id);
                      }}
                      options={connectionData}
                      optionKey="value"
                      optionDisplay="name"
                      isAll
                    />
                    <CustomSelect
                      label="Router Status"
                      cssClass="min_width"
                      value={routerStatus}
                      setValue={(id: any) => {
                        setrouterStatus(id);
                      }}
                      options={connectionData}
                      optionKey="value"
                      optionDisplay="name"
                      isAll
                    />
                    <Button
                      className="submitBtn"
                      onClick={handleSubmit}
                      loading={loading}
                    >
                      Submit
                    </Button>
                  </>
                  <div style={{ marginLeft: 'auto' }}>
                    <TableExt
                      handleRefresh={handleRefresh}
                      handleDownload={handleDownload}
                      downloadData={downloadData}
                      placeholder="Search"
                      csvHeaders={headers}
                      csvData={csvData}
                      csvFilename={title}
                      sortColumns={['name']}
                      tableRef={tableRef}
                      tableName="BasestationHealth"
                      handleCallback={(args: any[]) => {
                        setColumns(args);
                      }}
                      columnsData={columnsData}
                      toggleColumnsStatus={toggleColumnsStatus}
                    />
                  </div>
                </div>

                <div className="common_table fullHeight">
                  <InfiniteScrollTable
                    columns={columns}
                    loading={loading}
                    handleLoadMore={handleLoadMore}
                    hasMore={hasMore}
                    filterData={filterData}
                    totalcount={total}
                    filename={total > 0 ? 'Basestations' : 'Basestation'}
                  />
                </div>
              </div>
            </Row>
          </div>
        </div>
      </Layout>

      {baseStationHealthDetails && showModal && (
        <BaseStationHealthModal
          showModal={showModal}
          handleCancel={() => setShowModal(false)}
          baseStationHealthDetails={baseStationHealthDetails}
        />
      )}

      {baseStationHealthDetails && toggleErrorCodes && (
        <BaseAudit
          onClose={() => setToggleErrorCodes(false)}
          baseStationHealthDetails={baseStationHealthDetails}
        />
      )}
    </>
  );
};

export default BaseStationHealth;
