/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/display-name */
import { Button, notification, Select, Modal, Tabs, Card } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import {
  deleteJumpAndGoRecord,
  getMyTask,
  getTasksByJumpAndGo,
  selectJumpAndGo,
  getJumpAndGoAutonomousControls,
} from '../../constants/Api';
import { getDateTime } from '../../constants/Common';
import {
  Actions,
  Taskdetails,
  TaskResponse,
  jumpAndGo,
} from '../../constants/types';
import { ApplicationContext } from '../../context/AppContext';
import { useTranslation } from 'react-i18next';
import translate from '../../locale/en_translate.json';
import './style.css';
import { Content } from 'antd/lib/layout/layout';
import deleteIcon from '../../assets/images/del_icon.png';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import JngTabDetails from './JngTabDetails';
import JngTabActions from './JngTabActions';
import InfiniteScrollTable from '../common/InfiniteScrollTable';

interface Props {
  showModel: boolean;
  closeModel: () => void;
  actiondata: Actions;
}
const { TabPane } = Tabs;
const Jumpandgoctions: React.FC<Props> = ({
  showModel,
  closeModel,
  actiondata,
}: Props) => {
  const { t } = useTranslation();
  const { confirm } = Modal;
  const { userDetails } = useContext(ApplicationContext);
  const [taskList, setTaskList] = useState([]);
  const [assignedTasks, setAssignedTasks] = useState([]);
  const [selectedTasks, setSelectedTasks] = useState([]);
  const [pageSize, setPagesize] = useState<any>(25);
  const [actionsData, setActionsData] = useState<jumpAndGo[]>([]);
  const [filterActionsData, setFilterActionsData] = useState<jumpAndGo[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [loader, setLoader] = useState<boolean>(false);
  const [filterAssignedTasks, setFilterAssignedTasks] = useState<any>([]);
  const [assignedHasMore, setAssignedHasMore] = useState<boolean>(true);
  const [assignLoader, setAssignLoader] = useState<boolean>(false);
  const [tasksPageNumber, setTasksPageNumber] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [totalcount, settotalcount] = useState<any>();
  const [historyTotalcount, setHistoryTotalcount] = useState<any>();

  const taskColumns = [
    {
      title: `${t(translate.jumpandgoactions.taskname)}`,
      dataIndex: 'title',
    },
    {
      title: `${t(translate.jumpandgoactions.status)}`,
      dataIndex: 'task_status_name',
    },
    {
      title: `${t(translate.jumpandgoactions.createdby)}`,
      dataIndex: 'created_by',
      render: (text: any, record: Taskdetails) => {
        return <span>{record.created_by?.first_name}</span>;
      },
    },
    {
      title: `${t(translate.jumpandgoactions.supervisor)}`,
      dataIndex: 'supervisor',
      render: (text: any, record: Taskdetails) => {
        return <span>{record.supervisor?.first_name}</span>;
      },
    },
    {
      title: `${t(translate.jumpandgoactions.action)}`,
      dataIndex: 'action',
      key: 'action',
      render: (data: string, record: Taskdetails) => {
        return (
          <img
            src={deleteIcon}
            alt="Delete"
            className="marginLeftIcon"
            onClick={() => removeTask(record)}
          />
        );
      },
    },
  ];

  const columns = [
    {
      title: `${t(translate.baseStation.Name)}`,
      dataIndex: 'autonomous_control_name',
    },
    {
      title: `${t(translate.taskmaster.starttime)}`,
      dataIndex: 'date',
      width: '220px',
    },
    {
      title: `${t(translate.MyJumpAndGo.status)}`,
      dataIndex: 'autonomous_control_status_name',
    },
  ];

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      getTaskList();
      getAssignedTasks();
    }
  }, [userDetails, actiondata]);

  const getAssignedTasks = async () => {
    try {
      setAssignLoader(true);
      const tasks = await getTasksByJumpAndGo(
        userDetails.organization.api_url,
        actiondata.drive_action_uuid,
      );
      settotalcount(tasks._metadata.total_records_count);
      const data =
        tasks && tasks.records && tasks.records.length > 0 ? tasks.records : [];
      if (data.length > 0) {
        if (tasksPageNumber === 1) {
          setFilterAssignedTasks(data);
          setAssignedTasks(data);
        } else {
          setFilterAssignedTasks([...filterAssignedTasks, ...data]);
          setAssignedTasks(data);
        }
      } else {
        setFilterAssignedTasks(data);
        setAssignedTasks(data);
      }
      if (data.length < 25) {
        setAssignedHasMore(false);
      } else {
        setAssignedHasMore(true);
      }
      setAssignLoader(false);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    }
  };

  const getTaskList = async () => {
    try {
      const { records } = await getMyTask(
        userDetails.organization.api_url,
        userDetails.organization.id,
        userDetails.id,
        1,
        pageSize,
      );
      setTaskList(records);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    }
  };

  const handleChange = (value: any) => {
    if (value) {
      setSelectedTasks(value);
    }
  };

  const removeTask = async (record: any) => {
    confirm({
      title: 'Are you sure to remove Task?',
      icon: <ExclamationCircleOutlined />,
      content: '',
      onOk() {
        removeTasks(record);
      },
      onCancel() {},
      okButtonProps: {
        className: 'okBtn',
      },
      cancelButtonProps: {
        className: 'canelBtn',
      },
    });
  };

  const removeTasks = async (record: Taskdetails) => {
    try {
      await deleteJumpAndGoRecord(
        userDetails.organization.api_url,
        record.task_uuid,
        { drive_action_uuids: [actiondata.drive_action_uuid] },
      );
      await getAssignedTasks();
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    }
  };

  const addTasks = async () => {
    selectedTasks.map(async (taskUUID: string) => {
      const payload = {
        task_uuid: taskUUID,
        drive_action_uuids: [actiondata.drive_action_uuid],
      };
      try {
        await selectJumpAndGo(userDetails.organization.api_url, payload);
        await getAssignedTasks();
      } catch (error: any) {
        notification.error({
          message: error.message,
        });
      }
    });
  };

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      getJumpAndGoActions();
    }
  }, [userDetails, pageNumber, actiondata]);

  const getJumpAndGoActions = async () => {
    try {
      setLoader(true);
      const listData = await getJumpAndGoAutonomousControls(
        userDetails.organization.api_url,
        actiondata.drive_action_uuid,
        pageNumber,
        pageSize,
      );
      setHistoryTotalcount(listData._metadata.total_records_count);
      const data =
        listData && listData.records && listData.records.length > 0
          ? listData.records
          : [];
      const dataItem: any = [];
      data.map((record: jumpAndGo) => {
        const obj = {
          autonomous_control_name: record.autonomous_control_name,
          date: record.created_date_time
            ? getDateTime(record.created_date_time)
            : '-',
          autonomous_control_status_name: record.autonomous_control_status_name,
        };
        dataItem.push(obj);
      });
      if (data.length > 0) {
        if (pageNumber === 1) {
          setActionsData(dataItem);
          setFilterActionsData(dataItem);
        } else {
          setActionsData(dataItem);
          setFilterActionsData([...filterActionsData, ...dataItem]);
        }
      }
      if (dataItem.length < 25) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
      setLoader(false);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    }
  };

  const handleLoadMore = () => {
    setPageNumber(pageNumber + 1);
  };

  const TasksLoadMore = () => {
    setTasksPageNumber(pageNumber + 1);
  };

  return (
    <Modal
      title={t(translate.headers.jumpandgodetails)}
      visible={showModel}
      centered
      className="baseModalContainer jumpandgoModal"
      onCancel={closeModel}
      footer={[
        <Button
          type="primary"
          key="back"
          onClick={closeModel}
          className="employeeCreate"
          size="large"
        >
          {t(translate.buttons.close)}
        </Button>,
      ]}
    >
      <Content>
        <div className="mainContainer">
          <Card className="jumpandgoCard">
            <Tabs defaultActiveKey="1" className="elTabs">
              <TabPane
                tab="Details"
                key="1"
                className="attTableHeight mBottom0"
              >
                <JngTabDetails actiondata={actiondata} />

                <div style={{ marginBottom: 12, marginTop: 14 }}>
                  <div className="formCol">
                    <div className="ant-row ant-form-item">
                      <div className="ant-col ant-form-item-label">
                        <label className="taskTitle headline2">Tasks</label>
                      </div>
                      <div className="ant-col ant-form-item-control">
                        <div className="ant-form-item-control-input">
                          <Select
                            mode="multiple"
                            allowClear
                            style={{
                              width: '100%',
                              marginRight: 10,
                            }}
                            placeholder="Please select"
                            onChange={handleChange}
                            maxTagCount="responsive"
                            className="taskSearchField"
                          >
                            {taskList.map((task: TaskResponse) => {
                              return (
                                <Select.Option
                                  key={task.task_uuid}
                                  value={task.task_uuid}
                                >
                                  {task.title}
                                </Select.Option>
                              );
                            })}
                          </Select>
                          <Button
                            type="text"
                            className="addbtn"
                            onClick={addTasks}
                          >
                            {t(translate.buttons.add)}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tblDft farmTabsTbl posRel tblStyles">
                  <InfiniteScrollTable
                    columns={taskColumns}
                    loading={assignLoader}
                    hasMore={assignedHasMore}
                    filterData={filterAssignedTasks}
                    totalcount={totalcount}
                    handleLoadMore={TasksLoadMore}
                    filename="Tasks"
                  />
                </div>
              </TabPane>

              <TabPane
                tab="Actions"
                key="2"
                className="attTableHeight mBottom0 tblStyles"
              >
                <JngTabActions actiondata={actiondata} />
              </TabPane>

              <TabPane
                tab="History"
                key="3"
                className="attTableHeight mBottom0"
              >
                <div className="tblDft farmTabsTbl posRel tblStyles">
                  <InfiniteScrollTable
                    columns={columns}
                    loading={loader}
                    hasMore={hasMore}
                    filterData={filterActionsData}
                    totalcount={historyTotalcount}
                    handleLoadMore={handleLoadMore}
                    filename="Jumpandgoactions"
                  />
                </div>
              </TabPane>
            </Tabs>
          </Card>
        </div>
      </Content>
    </Modal>
  );
};

export default Jumpandgoctions;
Jumpandgoctions.displayName = 'jumpandgo';
