import { Tabs } from 'antd';
import React, { useState } from 'react';
import { track } from '../../../util/logger';
import GoldBuild from '../maintenance/GoldBuild';
import TractorMigration from '../../tractorMigration/TractorMigration';
import TractorHealth from '../tractorhealth/TractorHealth';

const { TabPane } = Tabs;

const DealersPlanning: React.FC = () => {
  const [tabid, setTabid] = useState('1');
  return (
    <Tabs
      activeKey={tabid}
      centered
      id="fleet-tab-FleetHealthContainer"
      className="common_tabs"
      onChange={(key) => {
        setTabid(key);
        track(key, {
          event: `${key} Tab`,
        });
      }}
    >
      <TabPane tab="Tractor Health" key="1" className="attTableHeight mBottom0">
        <TractorHealth />
      </TabPane>
      <TabPane tab="Gold Build" key="2" className="attTableHeight mBottom0">
        <GoldBuild />
      </TabPane>
      <TabPane
        tab="Tractor Migration"
        key="3"
        className="attTableHeight mBottom0"
      >
        <TractorMigration />
      </TabPane>
    </Tabs>
  );
};

export default DealersPlanning;
