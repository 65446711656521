/* eslint-disable no-console */
import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  ReloadOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import {
  Avatar,
  Button,
  Card,
  Checkbox,
  Col,
  Input,
  List,
  Menu,
  Radio,
  Select,
  Space,
  Switch,
  TimePicker,
  Tooltip,
  notification,
  Modal,
} from 'antd';
import Sider from 'antd/lib/layout/Sider';
import Layout from 'antd/lib/layout/layout';
import React, { useContext, useEffect, useState } from 'react';
import locationIcon from '../../assets/images/c_loc.svg';
import dwl_icon from '../../assets/images/dwl_icon.png';
import polygons from '../../assets/images/plg.svg';
import routes from '../../assets/images/rts.svg';
import vines from '../../assets/images/map-maker-icons/c.svg';
import vineBlock from '../../assets/images/map-maker-icons/cb.svg';
import vineRow from '../../assets/images/map-maker-icons/cr.svg';
import surveyCropRows from '../../assets/images/map-maker-icons/sc.svg';
import polyLine from '../../assets/images/map-maker-icons/pl.svg';
import abLine from '../../assets/images/map-maker-icons/al.svg';
import wayPoint from '../../assets/images/wpt.svg';
import chargePoint from '../../assets/images/charge_point.svg';
import translate from '../../locale/en_translate.json';
import MapMakerMap from './MapMakerMap';
import './style.css';

import { BackwardOutlined } from '@ant-design/icons';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { saveAs } from 'file-saver';
import moment from 'moment';
import ReactDatePicker from 'react-datepicker';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { useTranslation } from 'react-i18next';
import MarkIcon from '../../assets/images/marks.png';
import TravalledPointIcon from '../../assets/images/t-points.png';
import {
  deleteBulkVineRows,
  downloadMapMakerFeature,
  getAllMapTractors,
  publishAbBlockWithId,
} from '../../constants/Api';
import { getDateTime, isVineYardOrDairy } from '../../constants/Common';
import {
  AB_BLOCK,
  AB_LINE,
  CHARGE_POINTS,
  FEED_LANE,
  JUMP_AND_GO_MAP,
  POLYGON,
  POLY_LINE,
  REMOTE_AV_MAP,
  ROUTE,
  SURVEY_VINE_ROW,
  SURVEY_VINE_ROW_NEW,
  TRACTOR,
  TRAVELLED_ROUTE,
  VINE,
  VINE_BLOCK,
  VINE_LANE,
  VINE_ROW,
  WAY_POINT,
  dairyAutoDrive,
  listVisibility,
  types,
  vineYardAutoDrive,
} from '../../constants/constant';
import {
  MapFeature,
  MenuItem,
  TractorsList,
  UserDetails,
} from '../../constants/types';
import { ApplicationContext } from '../../context/AppContext';
import AppLoader from '../common/AppLoader';
import VBVRAccordion from './VBVRAccordion';
const { RangePicker } = TimePicker;
const { confirm } = Modal;
export interface ToggleLayer {
  type: string;
  value: boolean;
  position: number;
}
const MapMakerBeta: React.FC = () => {
  const { SubMenu } = Menu;
  const { t } = useTranslation();
  const { Option } = Select;
  const [collapsed, setCollapsed] = useState(true);
  const [detailsPage, setDetailsPage] = useState(false);
  const [trigger, setTrigger] = useState(true);
  const [menuItems, setMenuItems] = useState<MapFeature[]>([]);
  const [filteredMenuItems, setFilteredMenuItems] = useState<MapFeature[]>([]);
  const [routesData, setRoutes] = useState<MapFeature[]>([]);
  const [travelledRoutesData, setTravelledRoutesData] = useState<MapFeature[]>(
    [],
  );
  const [vinesData, setVines] = useState<MapFeature[]>([]);
  const [polyLinesData, setPolyLines] = useState<MapFeature[]>([]);
  const [vineRowsData, setVineRows] = useState<MapFeature[]>([]);
  const [abLines, setAbLines] = useState<MapFeature[]>([]);
  const [abBlocks, setAbBlocks] = useState<MapFeature[]>([]);
  const [vineBlocksData, setVineBlocks] = useState<MapFeature[]>([]);
  const [polygonsData, setPolygons] = useState<MapFeature[]>([]);
  const [suggestedPolygonsData, setSuggestedPolygons] = useState<MapFeature[]>(
    [],
  );
  const [suggestedNewPolygonsData, setNewSuggestedPolygons] = useState<
    MapFeature[]
  >([]);
  const [waypointsData, setWaypoints] = useState<MapFeature[]>([]);
  const [jumpAndGosData, setJumpAndGosData] = useState<MapFeature[]>([]);
  const [vineLaneData, setVineLaneData] = useState<MapFeature[]>([]);
  const [surveyVineRowData, setSurveyVineRowData] = useState<MapFeature[]>([]);
  const [surveyVineRowNewData, setSurveyVineRowNewData] = useState<
    MapFeature[]
  >([]);

  const [mapFeature, setMapFeature] = useState<MapFeature>();
  const [selectedMapFeature, setSelectedMapFeature] = useState<MapFeature>();
  const [selectedFeatureItem, setSelectedFeatureItem] =
    useState<MenuItem | null>();
  const [toggleLayer, setToggleLayer] = useState<ToggleLayer>();
  const [jumpAndGoTractorId, setJumpAndGoTractorId] = useState<any>();
  const [tractorsList, setTractorsList] = useState([]);
  const [selectedDateRange, setSelectedDateRange] = useState([
    moment().subtract(1, 'day').toDate(),
    moment().toDate(),
  ]);
  const [openKey, setOpenKey] = useState('');
  const [vineBlockId, setVineBlockId] = useState<string>();
  const [deleteRouteId, setDeleteRouteId] = useState<string>();
  const [deleteAbBlockId, setDeleteAbBlockId] = useState<string>();
  const [deleteSurveyVineRowId, setDeleteSurveyVineRowId] = useState<string>();
  const [deletePolyLineId, setDeletePolyLineId] = useState<string>('');
  const [loader, setLoader] = useState<boolean>(false);
  const [publishedTimeToReload, setPublishedTimeToReload] = useState<number>(0);
  const [reloadVineBlocks, setReloadVineBlocks] = useState(false);
  const [chargePoints, setChargePoints] = useState<MapFeature[]>([]);
  const [deleteFeedLane, setDeleteFeedLane] = useState<string>('');
  // layers toggles
  const onChange = (e: CheckboxChangeEvent) => {
    const item = e.target.value;
    item.visible = e.target.checked ? true : false;
    setMapFeature(item);
    setTrigger(!trigger);
  };
  // initialized for default selection of layer toggles
  const [checkedState, setCheckedState] = useState([
    listVisibility.WAYPOINT,
    listVisibility.POLYGON,
    listVisibility.VINE,
    listVisibility.VINEROW,
    listVisibility.VINEBLOCK,
    listVisibility.ROUTE,
    listVisibility.TRAVELLEDROUTE,
    listVisibility.MARKS,
    listVisibility.VINE_ROW_HEAD,
    listVisibility.VINELANE,
    listVisibility.JUMPANDGO,
    listVisibility.ABLINE,
    listVisibility.ABBLOCK,
    listVisibility.SURVEYVINROW,
    listVisibility.CHARGEPOINTS,
    listVisibility.POLY_LINE,
    listVisibility.SURVEY_VINE_ROW_NEW,
    listVisibility.FEED_LINE,
  ]);
  const [range, setRange] = useState<any>(0);
  const [times, setTimes] = useState<any>([0, 0]);
  const [selectedAll, setSelectedAll] = useState<boolean>(false);
  const [updateVineRows, setUpdateVineRows] = useState<boolean>(false);
  const [feedLanesData, setFeedLanesData] = useState<MapFeature[]>([]);

  const handle = useFullScreenHandle();

  const toggleMapFeatureVisible = (featureType: string, visible: boolean) => {
    const feature: any = {
      type: featureType,
      visible: visible,
    };
    setSelectedMapFeature(feature);
  };
  useEffect(() => {
    vineRowsData && handleSelectedMenu(selectedFeatureItem as MenuItem);
  }, [vineRowsData]);

  useEffect(() => {
    (feedLanesData ||
      polyLinesData ||
      abBlocks ||
      routesData ||
      surveyVineRowData ||
      surveyVineRowNewData) &&
      handleSelectedMenu(selectedFeatureItem as MenuItem);
  }, [
    feedLanesData,
    polyLinesData,
    abBlocks,
    routesData,
    surveyVineRowData,
    surveyVineRowNewData,
  ]);

  // set the right side slider dynamic content
  const handleSelectedMenu = (menuItem: MenuItem) => {
    if (menuItem && menuItem.type) {
      setSelectedFeatureItem(menuItem);
      switch (menuItem.type) {
        case ROUTE:
          setMenuItems(routesData);
          setFilteredMenuItems(routesData);
          break;
        case TRAVELLED_ROUTE:
          setMenuItems(travelledRoutesData);
          setFilteredMenuItems(travelledRoutesData);
          break;
        case VINE:
          setMenuItems(vinesData);
          setFilteredMenuItems(vinesData);
          toggleMapFeatureVisible(menuItem.type, true);
          break;
        case VINE_ROW: {
          setMenuItems(vineRowsData);
          setFilteredMenuItems(vineRowsData);
          toggleMapFeatureVisible(menuItem.type, true);
          const selectedLength = vineRowsData?.filter(
            (item: any) => item?.visible,
          )?.length;
          setSelectedAll(selectedLength === vineRowsData?.length);
          break;
        }
        case VINE_BLOCK:
          setMenuItems(vineBlocksData);
          setFilteredMenuItems(vineBlocksData);
          toggleMapFeatureVisible(menuItem.type, true);
          break;
        case POLYGON:
          setMenuItems([
            ...polygonsData,
            ...suggestedPolygonsData,
            ...suggestedNewPolygonsData,
          ]);
          setFilteredMenuItems([
            ...polygonsData,
            ...suggestedPolygonsData,
            ...suggestedNewPolygonsData,
          ]);
          toggleMapFeatureVisible(menuItem.type, true);
          break;
        case WAY_POINT:
          setMenuItems(waypointsData);
          setFilteredMenuItems(waypointsData);
          toggleMapFeatureVisible(menuItem.type, true);
          break;
        case JUMP_AND_GO_MAP:
          setMenuItems(jumpAndGosData);
          setFilteredMenuItems(jumpAndGosData);
          toggleMapFeatureVisible(menuItem.type, true);
          break;
        case REMOTE_AV_MAP:
          setToggleLayer({ type: REMOTE_AV_MAP, value: true, position: 10 });
          break;
        case VINE_LANE:
          setMenuItems(vineLaneData);
          setFilteredMenuItems(vineLaneData);
          toggleMapFeatureVisible(menuItem.type, true);
          break;

        case AB_LINE:
          setMenuItems(abLines);
          setFilteredMenuItems(abLines);
          toggleMapFeatureVisible(menuItem.type, true);
          break;
        case AB_BLOCK:
          setMenuItems(abBlocks);
          setFilteredMenuItems(abBlocks);
          toggleMapFeatureVisible(menuItem.type, true);
          break;
        case SURVEY_VINE_ROW:
          setMenuItems(surveyVineRowData);
          setFilteredMenuItems(surveyVineRowData);
          toggleMapFeatureVisible(menuItem.type, true);
          break;

        case CHARGE_POINTS:
          setMenuItems(chargePoints);
          setFilteredMenuItems(chargePoints);
          toggleMapFeatureVisible(menuItem.type, true);
          break;
        case POLY_LINE:
          setMenuItems(polyLinesData);
          setFilteredMenuItems(polyLinesData);
          toggleMapFeatureVisible(menuItem.type, true);
          break;
        case SURVEY_VINE_ROW_NEW:
          setMenuItems(surveyVineRowNewData);
          setFilteredMenuItems(surveyVineRowNewData);
          toggleMapFeatureVisible(menuItem.type, true);
          break;
        case FEED_LANE:
          setMenuItems(feedLanesData);
          setFilteredMenuItems(feedLanesData);
          toggleMapFeatureVisible(menuItem.type, true);
          break;
        default:
          break;
      }
    }
  };
  useEffect(() => {
    if (
      selectedFeatureItem &&
      selectedFeatureItem.type &&
      selectedFeatureItem.type === JUMP_AND_GO_MAP
    ) {
      setMenuItems(jumpAndGosData);
      setFilteredMenuItems(jumpAndGosData);
      toggleMapFeatureVisible(JUMP_AND_GO_MAP, true);
    }
  }, [jumpAndGosData, selectedFeatureItem]);
  // full screen set up
  const toggleFullScreen = () => {
    if (handle.active) {
      handle.exit();
    } else {
      handle.enter();
    }
  };
  // handling layers visibility
  const handleToggleLayer = (e: boolean, position: number, type?: string) => {
    setToggleLayer({
      type: type || '',
      value: e,
      position,
    });
  };
  // handling layers checkbox visibility
  useEffect(() => {
    if (toggleLayer && toggleLayer.position >= 0) {
      const updatedCheckedState = checkedState.map((item, index) =>
        index === toggleLayer.position ? toggleLayer.value : item,
      );
      setCheckedState(updatedCheckedState);
    }
  }, [toggleLayer]);
  // filtering menu list dynamicly
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const filtered = menuItems.filter((menuItem: MapFeature) => {
      return menuItem.name.toLowerCase().includes(e.target.value.toLowerCase());
    });

    setFilteredMenuItems(filtered);
  };
  // static menu list
  const list: MenuItem[] = [
    {
      name: t(translate.map.waypoints),
      image_url: wayPoint,
      type: WAY_POINT,
      layer: false,
    },
    {
      name: t(translate.map.boundaries),
      image_url: polygons,
      type: POLYGON,
      layer: false,
    },
    {
      name: t(translate.map.crops),
      image_url: vines,
      type: VINE,
      layer: false,
    },
    {
      name: t(translate.map.crop_rows),
      image_url: vineRow,
      type: VINE_ROW,
      layer: false,
    },
    {
      name: t(translate.map.crop_blocks),
      image_url: vineBlock,
      type: VINE_BLOCK,
      layer: false,
    },
    {
      name: 'Routines',
      image_url: routes,
      type: ROUTE,
      layer: false,
    },
    {
      name: 'Travelled Route',
      image_url: routes,
      type: TRAVELLED_ROUTE,
      layer: false,
    },
    {
      name: 'Marks',
      image_url: MarkIcon,
      type: 'MARKS',
      layer: true,
    },
    {
      name: t(translate.map.row_heads),
      image_url: MarkIcon,
      type: 'WINEROWHEAD',
      layer: true,
    },
    {
      name: t(translate.map.crop_lanes),
      image_url: TravalledPointIcon,
      type: VINE_LANE,
      layer: false,
    },
    {
      name: 'Jump & Go',
      image_url: TravalledPointIcon,
      type: JUMP_AND_GO_MAP,
      layer: true,
    },
    {
      name: 'Ab Line',
      image_url: abLine,
      type: AB_LINE,
      layer: false,
    },
    {
      name: 'Ab Block',
      image_url: polygons,
      type: AB_BLOCK,
      layer: false,
    },
    {
      name: t(translate.map.survey_crop_rows),
      image_url: surveyCropRows,
      type: SURVEY_VINE_ROW,
      layer: false,
    },
    {
      name: t(translate.map.survey_crop_rows_new),
      image_url: surveyCropRows,
      type: SURVEY_VINE_ROW_NEW,
      layer: false,
    },
    {
      name: t(translate.map.charge_points),
      image_url: chargePoint,
      type: CHARGE_POINTS,
      layer: false,
    },
    {
      name: t(translate.map.poly_line),
      image_url: polyLine,
      type: POLY_LINE,
      layer: false,
    },
    {
      name: t(translate.map.feed_line),
      image_url: polyLine,
      type: types.FEED_LANE,
      layer: true,
    },
  ];

  // polygon colors
  const polyColorHeaders = [
    {
      heading: t(translate.map.autonomy_boundary),
      color: 'white',
    },
    {
      heading: t(translate.map.block),
      color: 'green',
    },
    {
      heading: t(translate.map.walkway),
      color: 'yellow',
    },
    {
      heading: t(translate.map.intersection),
      color: 'red',
    },
    // {
    //   heading: 'Blocked',
    //   color: 'blue',
    // },
    {
      heading: t(translate.map.farm_boundary),
      color: 'brown',
    },
    {
      heading: t(translate.map.stay_off_zone),
      color: 'blue',
    },
    {
      heading: 'Suggested Green',
      color: 'green',
    },
    {
      heading: 'Suggested Blue',
      color: 'blue',
    },
  ];

  const recenterSelectedFeature = (mapFeature: MapFeature) => {
    setMapFeature(mapFeature);
    setTrigger(!trigger);
  };
  const [chapters, setChapters] = useState<MapFeature[]>([]);
  const { userDetails } = useContext(ApplicationContext);

  useEffect(() => {
    const vB = vineBlocksData;
    const vR = vineRowsData;
    vR &&
      vR.length > 0 &&
      vB &&
      vB.map((vb: any, index: number) => {
        const { vine_blocks_uuid: vine_blocks_uuidVb } = vb;
        vb.children = [];
        vR.map((vr: any) => {
          const { vine_blocks_uuid } = vr;
          vine_blocks_uuidVb === vine_blocks_uuid && vb.children.push(vr);
        });
        index + 1 == vB.length && setChapters(vB);
      });
  }, [vineBlocksData, vineRowsData]);

  const checkDownloadable = () => {
    const data = ['Polygons', 'Vines', 'VineRows', 'VineBlocks', 'Waypoints'];
    return data.includes(selectedFeatureItem?.name || '');
  };

  // down load csv file from backend
  const CsvFileDownload = async (category: string) => {
    try {
      const records: any = await downloadMapMakerFeature(
        userDetails.organization.api_url,
        userDetails.organization.id,
        userDetails.organization.farm.id,
        category,
      );

      const fileName = selectedFeatureItem?.name || 'catrography';
      await saveAs(records.data, `${fileName}.csv`);
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    }
  };

  const convertCategoryTests = (category: string) => {
    switch (category) {
      case 'Polygons':
        return 'polygons';

      case 'Vines':
        return 'vines';

      case 'VineRows':
        return 'vine_rows';

      case 'VineBlocks':
        return 'vine_blocks';

      case 'Waypoints':
        return 'waypoints';

      default:
        return '';
    }
  };

  useEffect(() => {
    const init = async () => {
      try {
        const { organization } = userDetails;
        const { records } = await getAllMapTractors(
          organization.api_url,
          organization.id,
        );
        setTractorsList(records);
      } catch (error: any) {
        notification.error({
          message: error.message,
        });
      }
    };
    if (userDetails && userDetails.organization) {
      init();
    }
  }, [userDetails]);

  const handleSelectDateRange = (dates: any[]) => {
    const [date1, date2] = dates;
    if (date1 && date2) {
      date1.getTime() < date2.getTime() && setSelectedDateRange([date1, date2]);
    } else {
      setSelectedDateRange([date1, date2]);
    }
  };
  const handleSelectDateRange1 = (jng: any, dates: any[]) => {
    let [date1Old, date2Old] = jng.times;
    let [date1, date2] = dates;
    date1Old = moment(date1Old).toDate().getTime();
    date2Old = moment(date2Old).toDate().getTime();
    date1 = moment(date1).toDate().getTime();
    date2 = moment(date2).toDate().getTime();

    if (date1Old > date1 || date2 > date2Old) {
      return;
    }
    if (date1 && date2) {
      if (date1 < date2) {
        jng.showTimes = dates;
        setMapFeature(jng);
        setTrigger(!trigger);
        setTimes(dates);
      }
    } else {
      jng.showTimes = dates;

      setMapFeature(jng);
      setTrigger(!trigger);
      setTimes(dates);
    }
  };

  const publishAbBlock = async (abBlockId: number) => {
    const payload = {
      is_published: true,
    };
    try {
      const { organization } = userDetails;
      const {
        msg,
        published_date_time,
      }: { msg: string; published_date_time: number } =
        await publishAbBlockWithId(
          userDetails.organization.api_url,
          organization.farm.id,
          abBlockId,
          payload,
        );
      published_date_time && setPublishedTimeToReload(published_date_time);
      notification.success({ message: msg });
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    }
  };

  const handleSelect = () => {
    const selectedLength = filteredMenuItems?.filter(
      (item: any) => item?.visible,
    )?.length;
    setSelectedAll(selectedLength === menuItems?.length);
  };

  const handleSelectAll = (e: any) => {
    const menuItemsSelect: any = [];
    filteredMenuItems.map((item: any) => {
      item.visible = e?.target?.checked;
      menuItemsSelect.push(item);
    });
    setFilteredMenuItems(menuItemsSelect);
    setSelectedAll(!selectedAll);
  };

  const deleteVineRows = async () => {
    try {
      const selectedVineRowIds: number[] = [];
      filteredMenuItems.filter((vineRow: any) => {
        if (vineRow?.visible) {
          selectedVineRowIds.push(vineRow?.id);
        }
      });
      const payload = {
        vine_row_ids: selectedVineRowIds,
      };
      const response: any = await deleteBulkVineRows(
        userDetails.organization.api_url,
        userDetails.organization.farm.id,
        payload,
      );
      setUpdateVineRows(!updateVineRows);
      notification.success({ message: response?.msg });
    } catch (error: any) {
      notification.error({
        message: error?.message,
      });
    }
  };

  const confirmDelete = () => {
    const title = t(translate.map.crop_row);
    const selectedVineRows: string[] = [];
    filteredMenuItems.filter((vineRow: any) => {
      if (vineRow?.visible) {
        selectedVineRows.push(vineRow?.name);
      }
    });
    const names = selectedVineRows?.toString();
    const titleMsg =
      selectedVineRows?.length > 1
        ? `Deleting ${selectedVineRows?.length} ${title}s ?`
        : `Deleting ${title} ${names} ?`;
    confirm({
      title: titleMsg,
      icon: <ExclamationCircleOutlined />,
      content: '',
      okButtonProps: {
        className: 'okBtn',
      },
      cancelButtonProps: {
        className: 'canelBtn',
      },
      onOk() {
        deleteVineRows();
      },
    });
  };

  const handleNotVineYards = (listItem: MenuItem) =>
    isVineYardOrDairy(userDetails as UserDetails) === vineYardAutoDrive &&
    listItem.type === FEED_LANE
      ? false
      : true;
  const handleNotDairy = (listItem: MenuItem) =>
    isVineYardOrDairy(userDetails as UserDetails) === dairyAutoDrive &&
    (listItem.type === VINE ||
      listItem.type === VINE_ROW ||
      listItem.type === VINE_BLOCK ||
      listItem.type === VINE_LANE ||
      listItem.type === AB_LINE ||
      listItem.type === AB_BLOCK)
      ? false
      : true;

  return (
    <FullScreen handle={handle}>
      <Layout style={{}}>
        <Sider
          collapsible
          collapsed={collapsed}
          collapsedWidth={0}
          onCollapse={setCollapsed}
          theme="light"
          width="380"
          className={`livemapSidebar mapmakerSidebar mmBeta ${
            selectedFeatureItem &&
            selectedFeatureItem.type &&
            selectedFeatureItem.type === JUMP_AND_GO_MAP
              ? 'jump-and-go-expand'
              : ''
          }`}
          trigger={null}
        >
          {collapsed && (
            <div
              className="tIconOpen"
              data-testid="openLayersIcon-MapMakerBeta"
              onClick={() => setCollapsed(false)}
            ></div>
          )}
          {!collapsed && (
            <div
              className="tIconClose"
              data-testid="closeLayersIcon-MapMakerBeta"
              onClick={() => setCollapsed(true)}
            ></div>
          )}
          {!detailsPage && (
            <List
              header={t(translate.map.layers)}
              className="demo-loadmore-list "
              itemLayout="horizontal"
              dataSource={list}
              renderItem={(item, index) =>
                handleNotVineYards(item) &&
                handleNotDairy(item) && (
                  <List.Item className="switchC">
                    <List.Item.Meta
                      avatar={<Avatar src={item.image_url} />}
                      title={
                        <Space direction="horizontal">
                          <div
                            onClick={() => {
                              item &&
                                item.type !== 'WINEROWHEAD' &&
                                item.type !== 'MARKS' &&
                                setDetailsPage(true);
                              handleSelectedMenu(item);
                            }}
                          >
                            {item.name}
                          </div>
                          <div className="w30">
                            {item.type !== REMOTE_AV_MAP && (
                              <Switch
                                defaultChecked={checkedState[index]}
                                checked={checkedState[index]}
                                data-testid={`${item.type}-MapMakerBetaLayers`}
                                size="small"
                                onClick={(e) =>
                                  handleToggleLayer(e, index, item?.type)
                                }
                              />
                            )}
                          </div>
                        </Space>
                      }
                    />
                  </List.Item>
                )
              }
            />
          )}
          {detailsPage && (
            <Layout className="site-layout lmSide">
              <div
                className="lmapBack"
                onClick={() => {
                  setDetailsPage(false);
                  setSelectedFeatureItem(null);
                }}
              >
                <BackwardOutlined />
              </div>
              <Card
                title={selectedFeatureItem?.name}
                bordered={true}
                className="abcc"
                extra={
                  selectedFeatureItem?.type === 'VINEROW' && (
                    <Space>
                      <Checkbox
                        checked={selectedAll}
                        onChange={(e) => handleSelectAll(e)}
                        className="mapCheckbox wdCheckbox"
                      >
                        {selectedAll ? (
                          <span className="checkboxTitle">Unselect All</span>
                        ) : (
                          <span className="checkboxTitle">Select All</span>
                        )}
                      </Checkbox>
                      <Tooltip
                        title={`Delete selected crop rows`}
                        placement="left"
                      >
                        <Button
                          key="submit"
                          className="Button"
                          onClick={confirmDelete}
                        >
                          Delete
                        </Button>
                      </Tooltip>
                    </Space>
                  )
                }
              >
                {checkDownloadable() && (
                  <img
                    className="btnSavefile"
                    src={dwl_icon}
                    onClick={() =>
                      CsvFileDownload(
                        convertCategoryTests(selectedFeatureItem?.name || ''),
                      )
                    }
                  />
                )}
                {selectedFeatureItem?.type != REMOTE_AV_MAP &&
                  selectedFeatureItem?.type !== JUMP_AND_GO_MAP && (
                    <Input
                      className=""
                      placeholder="Search"
                      onChange={(e) => handleSearch(e)}
                      suffix={<SearchOutlined className="searchIcon" />}
                    />
                  )}
                {selectedFeatureItem?.type != REMOTE_AV_MAP &&
                selectedFeatureItem?.type != POLYGON &&
                selectedFeatureItem?.type != VINE_BLOCK &&
                selectedFeatureItem?.type != JUMP_AND_GO_MAP &&
                filteredMenuItems &&
                filteredMenuItems.length > 0
                  ? filteredMenuItems.map((listItem, index) => {
                      return (
                        <>
                          <Col span={8} style={{ padding: '5px 2px' }}>
                            <Checkbox
                              key={index}
                              checked={listItem.visible}
                              value={listItem}
                              onChange={(e: any) => {
                                onChange(e);
                                selectedFeatureItem?.type === VINE_ROW &&
                                  handleSelect();
                              }}
                              className="mapCheckbox"
                            >
                              <div style={{ display: 'flex' }}>
                                <Tooltip title={listItem.name}>
                                  <div className="mapNames">
                                    {listItem.name}
                                  </div>{' '}
                                </Tooltip>
                                {selectedFeatureItem?.type === AB_BLOCK &&
                                  !listItem.isPublished && (
                                    <Tooltip title="Publish" placement="top">
                                      <ReloadOutlined
                                        onClick={(e) => {
                                          e.preventDefault();
                                          publishAbBlock(listItem?.id);
                                        }}
                                        style={{
                                          zIndex: 99,
                                          marginLeft: -22,
                                          left: 192,
                                          top: 7,
                                          position: 'absolute',
                                        }}
                                      />
                                    </Tooltip>
                                  )}

                                {(selectedFeatureItem?.type === TRACTOR ||
                                  selectedFeatureItem?.type === VINE ||
                                  selectedFeatureItem?.type === VINE_ROW ||
                                  selectedFeatureItem?.type === POLY_LINE ||
                                  selectedFeatureItem?.type === VINE_BLOCK ||
                                  selectedFeatureItem?.type === VINE_LANE ||
                                  selectedFeatureItem?.type === ROUTE ||
                                  selectedFeatureItem?.type === WAY_POINT ||
                                  selectedFeatureItem?.type === CHARGE_POINTS ||
                                  selectedFeatureItem?.type === AB_LINE ||
                                  selectedFeatureItem?.type === AB_BLOCK ||
                                  selectedFeatureItem?.type ===
                                    SURVEY_VINE_ROW ||
                                  selectedFeatureItem?.type ===
                                    SURVEY_VINE_ROW_NEW ||
                                  selectedFeatureItem?.type === FEED_LANE) && (
                                  <>
                                    <img
                                      src={locationIcon}
                                      width="28"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        recenterSelectedFeature(listItem);
                                      }}
                                    />
                                  </>
                                )}
                                {(selectedFeatureItem?.type ===
                                  SURVEY_VINE_ROW ||
                                  selectedFeatureItem?.type ===
                                    SURVEY_VINE_ROW_NEW ||
                                  selectedFeatureItem?.type === ROUTE ||
                                  selectedFeatureItem?.type === POLY_LINE ||
                                  selectedFeatureItem?.type === FEED_LANE ||
                                  selectedFeatureItem?.type === AB_BLOCK) && (
                                  <Tooltip title="Delete" placement="top">
                                    <DeleteOutlined
                                      onClick={(e) => {
                                        e.preventDefault();
                                        if (
                                          selectedFeatureItem?.type === ROUTE
                                        ) {
                                          setDeleteRouteId('');
                                          setTimeout(() => {
                                            setDeleteRouteId(listItem?.mapId);
                                          }, 100);
                                        }
                                        if (
                                          selectedFeatureItem?.type ===
                                          SURVEY_VINE_ROW_NEW
                                        ) {
                                          setDeleteSurveyVineRowId('');
                                          setTimeout(() => {
                                            setDeleteSurveyVineRowId(
                                              JSON.stringify(listItem),
                                            );
                                          }, 100);
                                        }
                                        if (
                                          selectedFeatureItem?.type ===
                                          SURVEY_VINE_ROW
                                        ) {
                                          setDeleteSurveyVineRowId('');
                                          setTimeout(() => {
                                            setDeleteSurveyVineRowId(
                                              JSON.stringify(listItem),
                                            );
                                          }, 100);
                                        }
                                        if (
                                          selectedFeatureItem?.type ===
                                          POLY_LINE
                                        ) {
                                          setDeletePolyLineId('');
                                          setTimeout(() => {
                                            setDeletePolyLineId(
                                              JSON.stringify(listItem),
                                            );
                                          }, 100);
                                        }
                                        if (
                                          selectedFeatureItem?.type === AB_BLOCK
                                        ) {
                                          setDeleteAbBlockId('');
                                          setTimeout(() => {
                                            setDeleteAbBlockId(
                                              JSON.stringify(listItem),
                                            );
                                          }, 100);
                                        }
                                        if (
                                          selectedFeatureItem?.type ===
                                          FEED_LANE
                                        ) {
                                          setDeleteFeedLane('');
                                          setTimeout(() => {
                                            setDeleteFeedLane(
                                              JSON.stringify(listItem),
                                            );
                                          }, 100);
                                        }
                                      }}
                                      style={{
                                        zIndex: 99,
                                        marginLeft: -22,
                                        left: 245,
                                        top: 7,
                                        position: 'absolute',
                                      }}
                                    />
                                  </Tooltip>
                                )}
                              </div>
                            </Checkbox>
                          </Col>
                        </>
                      );
                    })
                  : null}
                {selectedFeatureItem?.type === POLYGON &&
                polyColorHeaders &&
                filteredMenuItems &&
                filteredMenuItems.length > 0 ? (
                  <Menu mode="inline" defaultSelectedKeys={['1']}>
                    {polyColorHeaders.map((polyColor) => (
                      <>
                        <SubMenu
                          key={polyColor.heading}
                          title={polyColor.heading}
                          style={{ fontSize: '18px' }}
                          className="poly-menu-class"
                        >
                          {polyColor &&
                            filteredMenuItems &&
                            filteredMenuItems
                              .filter((listItem) => {
                                if (polyColor.heading === 'Suggested Green') {
                                  return (
                                    listItem.suggested &&
                                    listItem.color == polyColor.color
                                  );
                                }
                                if (polyColor.heading === 'Suggested Blue') {
                                  return (
                                    listItem.suggested &&
                                    listItem.color == polyColor.color
                                  );
                                } else {
                                  return (
                                    !listItem.suggested &&
                                    listItem.color == polyColor.color
                                  );
                                }
                              })
                              .map((listItem, index) => {
                                return (
                                  <div key={index} className="poly-class">
                                    <Menu.Item
                                      style={{ padding: '5px 2px' }}
                                      className=""
                                    >
                                      <Checkbox
                                        key={index}
                                        defaultChecked={listItem.visible}
                                        value={listItem}
                                        onChange={onChange}
                                        className={`mapCheckbox ${listItem.color}-box`}
                                      >
                                        <div style={{ display: 'flex' }}>
                                          <Tooltip title={listItem.name}>
                                            <div className="mapNames">
                                              {listItem.name}
                                            </div>{' '}
                                          </Tooltip>
                                          {selectedFeatureItem?.type ===
                                            POLYGON && (
                                            <>
                                              <img
                                                src={locationIcon}
                                                width="28"
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  recenterSelectedFeature(
                                                    listItem,
                                                  );
                                                }}
                                              />
                                            </>
                                          )}
                                        </div>
                                      </Checkbox>
                                    </Menu.Item>
                                  </div>
                                );
                              })}
                        </SubMenu>
                      </>
                    ))}
                  </Menu>
                ) : null}
                {selectedFeatureItem?.type === VINE_BLOCK &&
                  chapters &&
                  chapters.length && (
                    <div className="repairLayout leftMenuAlign vinBlockContainer mmVineBlock ">
                      {chapters.map((chapter: MapFeature) => {
                        return (
                          <VBVRAccordion
                            topic={chapter}
                            key={chapter.name}
                            toggleMapItem={() => setTrigger(!trigger)}
                            setMapFeature={(data: MapFeature) =>
                              setMapFeature(data)
                            }
                            recenterSelctedFeature={(data: MapFeature) => {
                              recenterSelectedFeature(data);
                            }}
                            reloadVineBlock={() =>
                              setReloadVineBlocks(!reloadVineBlocks)
                            }
                            deletVineBlock={(id: string) => {
                              setVineBlockId('');
                              setTimeout(() => {
                                setVineBlockId(id);
                              }, 100);
                            }}
                          />
                        );
                      })}
                    </div>
                  )}
                {selectedFeatureItem?.type === JUMP_AND_GO_MAP ? (
                  <>
                    <div className="jumpAndGoList tsDatePic">
                      <ReactDatePicker
                        isClearable
                        maxDate={new Date()}
                        selected={selectedDateRange[0]}
                        placeholderText="Select Start Date"
                        dropdownMode="select"
                        dateFormat="MM-dd-yyyy HH:mm"
                        showTimeSelect
                        onChange={(date: any) => {
                          handleSelectDateRange([date, selectedDateRange[1]]);
                          setOpenKey('');
                        }}
                        className="dpicker dpickerWidth"
                        timeIntervals={5}
                      />
                    </div>
                    <div className="jumpAndGoList tsDatePic">
                      <ReactDatePicker
                        isClearable
                        maxDate={new Date()}
                        minDate={selectedDateRange[0]}
                        // minTime={selectedDateRange[0]}
                        // maxTime={new Date()}
                        selected={selectedDateRange[1]}
                        placeholderText="Select End Date"
                        dropdownMode="select"
                        dateFormat="MM-dd-yyyy HH:mm"
                        showTimeSelect
                        onChange={(date: any) => {
                          handleSelectDateRange([selectedDateRange[0], date]);
                          setOpenKey('');
                        }}
                        className="dpicker dpickerWidth"
                        timeIntervals={5}
                      />
                    </div>
                    <div className="selectTractor sTcr">
                      <Select
                        style={{ width: 146, marginRight: 10 }}
                        placeholder="Select Tractor"
                        value={jumpAndGoTractorId}
                        onSelect={(e: any) => {
                          setJumpAndGoTractorId(e);
                        }}
                      >
                        <>
                          <Option value="">All</Option>
                          {tractorsList &&
                            tractorsList.length > 0 &&
                            tractorsList.map((data: TractorsList) => (
                              <Option key={data.id} value={data.id}>
                                {data.name}
                              </Option>
                            ))}
                        </>
                      </Select>
                    </div>
                    <Col className="tContWp">
                      <Radio.Group>
                        <Menu mode={'inline'} openKeys={[openKey]}>
                          {filteredMenuItems &&
                            filteredMenuItems.map((jng: any) => {
                              return (
                                <div
                                  className="tBoxDtlCont"
                                  key={jng?.created_date_time}
                                  style={{
                                    border:
                                      jng && jng?.visible
                                        ? '1px solid #fc7e09'
                                        : '',
                                  }}
                                  onClick={() => {
                                    jng.visible = !jng.visible;
                                    if (jng.visible) {
                                      setRange(jng.range);
                                      setTimes(jng.times);
                                    } else {
                                      setRange(0);
                                      setTimes([0, 0]);
                                    }
                                    jng.showTimes = jng.times;

                                    setMapFeature(jng);
                                    setTrigger(!trigger);
                                  }}
                                >
                                  <p className="topter">
                                    <b className="tOTxt">Operator:</b>
                                    <span className="clrOrg">
                                      {jng?.first_name} {jng?.last_name}
                                    </span>
                                  </p>
                                  <p className="jngStime">
                                    <b className="tOTxt">Start Time:</b>
                                    <span className="clrOrg">
                                      {jng?.drive_action_start_date_time
                                        ? getDateTime(
                                            jng?.drive_action_start_date_time,
                                          )
                                        : '-'}
                                    </span>
                                  </p>
                                  <p className="jngStime">
                                    <b className="tOTxt">Finish Time:</b>
                                    <span className="clrOrg">
                                      {jng?.drive_action_finish_date_time
                                        ? getDateTime(
                                            jng?.drive_action_finish_date_time,
                                          )
                                        : '-'}
                                    </span>
                                  </p>
                                  <p className="jngStime mb20">
                                    <b className="tOTxt">Tractor Name:</b>
                                    <span className="clrOrg">
                                      {jng?.tractorName}
                                    </span>
                                  </p>
                                  {jng?.visible &&
                                    range > 0 &&
                                    mapFeature?.id === jng.id && (
                                      <div
                                        style={{ width: '100%' }}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                        }}
                                      >
                                        <RangePicker
                                          format="hh:mm A"
                                          defaultValue={jng.times}
                                          value={times}
                                          onChange={(date: any) => {
                                            handleSelectDateRange1(jng, date);
                                          }}
                                        />
                                      </div>
                                    )}
                                </div>
                              );
                            })}
                        </Menu>
                      </Radio.Group>
                    </Col>
                    <AppLoader loader={loader} />
                  </>
                ) : null}
              </Card>
            </Layout>
          )}
        </Sider>
        <Layout className="site-layout mapMaker">
          <MapMakerMap
            mapFeature={mapFeature as MapFeature}
            setRoutesFeature={(data) => setRoutes(data)}
            setTravelledRoutesDataFeature={(data) =>
              setTravelledRoutesData(data)
            }
            setVineFeature={(data) => setVines(data)}
            setVineRowFeature={(data) => setVineRows(data)}
            setPolyLineFeature={(data) => setPolyLines(data)}
            setAbLineFeature={(data) => setAbLines(data)}
            setAbBlockFeature={(data) => setAbBlocks(data)}
            setVineBlockFeature={(data) => setVineBlocks(data)}
            setPolygonFeature={(data) => setPolygons(data)}
            setSuggestedPolygonFeature={(data) => setSuggestedPolygons(data)}
            setNewSuggestedPolygonFeature={(data) =>
              setNewSuggestedPolygons(data)
            }
            setWaypointFeature={(data) => setWaypoints(data)}
            setChargePointFeature={(data) => setChargePoints(data)}
            setJumpAndGoFeature={(data) => setJumpAndGosData(data)}
            setVineLaneFeature={(data) => setVineLaneData(data)}
            setSurveyVineRowFeature={(data) => setSurveyVineRowData(data)}
            setSurveyVineRowNewFeature={(data) => setSurveyVineRowNewData(data)}
            setFeedLanesFeature={(data) => setFeedLanesData(data)}
            trigger={trigger}
            toggleMapFeature={selectedMapFeature}
            toggleFullScreen={toggleFullScreen}
            toggleLayer={toggleLayer as ToggleLayer}
            handleToggleLayer={(e, type, index) =>
              handleToggleLayer(e, index, type)
            }
            rightSideMenuCollapsed={collapsed}
            vineblockId={vineBlockId as string}
            selectedDateRange={selectedDateRange as Date[]}
            jumpAndGoTractorId={jumpAndGoTractorId}
            setLoader={setLoader}
            publishedTimeToReload={publishedTimeToReload}
            reloadVineBlocks={reloadVineBlocks}
            deleteRouteId={deleteRouteId as string}
            deleteSurveyVineRowId={deleteSurveyVineRowId as string}
            deletePolyLineId={deletePolyLineId as string}
            updateVineRows={updateVineRows}
            filteredMenuItems={filteredMenuItems}
            selectedFeatureItem={selectedFeatureItem as MenuItem}
            deleteAbBlockId={deleteAbBlockId as string}
            deleteFeedLane={deleteFeedLane as string}
          />
        </Layout>
      </Layout>
    </FullScreen>
  );
};

export default MapMakerBeta;
