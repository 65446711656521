import React, { useEffect } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import am4themes_material from '@amcharts/amcharts4/themes/material';

am4core.useTheme(am4themes_animated);
am4core.useTheme(am4themes_material);

interface StackedLineChartProps {
  data: Array<{
    category: string;
    level1: number;
    level2: number;
    level3: number;
  }>;
  chartId: string;
  points: boolean;
  labels: string[];
  lineColors: string[];
  loader: boolean;
  onClick?: (data: any) => void;
}

const MultiLineChart: React.FC<StackedLineChartProps> = ({
  data,
  chartId,
  points,
  labels,
  lineColors,
  loader,
  onClick,
}) => {
  useEffect(() => {
    // Create chart instance
    const chart = am4core.create(chartId, am4charts.XYChart);
    chart.logo.disabled = true;

    // Add data
    chart.data = data;

    // Create axes
    const categoryAxis = chart.xAxes.push(new am4charts.DateAxis());
    categoryAxis.renderer.grid.template.location = 0;
    //categoryAxis.renderer.minGridDistance = 30;

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

    // Create series
    function createSeries(field: any, name: any, color: string) {
      const series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.valueY = field;
      series.dataFields.dateX = 'category';
      series.name = name;
      series.tooltipText = '{dateX}: [b]{valueY}[/]';
      series.strokeWidth = 2;
      series.stroke = am4core.color(color);
      series.fill = am4core.color(color);
      categoryAxis.renderer.grid.template.opacity = 0;
      // valueAxis.renderer.grid.template.opacity = 0;

      categoryAxis.renderer.labels.template.fontSize = 10;
      categoryAxis.renderer.labels.template.fill = am4core.color('#1F1F1F');
      categoryAxis.renderer.labels.template.fontFamily = 'Montserrat-Regular';
      categoryAxis.title.fontSize = 10;
      categoryAxis.title.fill = am4core.color('#333333');
      categoryAxis.title.fontFamily = 'Montserrat-Medium';
      categoryAxis.renderer.labels.template.fontSize = 10;

      valueAxis.renderer.labels.template.fontSize = 10;
      valueAxis.renderer.labels.template.fill = am4core.color('#5c5c5c');
      valueAxis.renderer.labels.template.fontFamily = 'Montserrat-Regular';
      valueAxis.title.fontSize = 10;
      valueAxis.title.fill = am4core.color('#333333');
      valueAxis.title.fontFamily = 'Montserrat-Medium';
      valueAxis.renderer.labels.template.fontSize = 10;

      if (points) {
        const bullet = series.bullets.push(new am4charts.CircleBullet());
        bullet.circle.fill = am4core.color(color);
        bullet.events.on('hit', function (ev: any) {
          if (onClick) onClick(ev.target.dataItem);
          // alert(
          //   'Clicked on ' +
          //     ev.target.dataItem.dateX +
          //     ': ' +
          //     ev.target.dataItem.valueY,
          // );
        });
      }
    }

    labels[0] && createSeries('value', labels[0], lineColors[0]);
    labels[1] && createSeries('value2', labels[1], lineColors[1]);
    labels[2] && createSeries('value3', labels[2], lineColors[2]);

    chart.legend = new am4charts.Legend();
    chart.cursor = new am4charts.XYCursor();

    const markerTemplate = chart?.legend?.markers?.template;
    if (markerTemplate) {
      markerTemplate.width = 10;
      markerTemplate.height = 10;
      chart.legend.position = 'top';
      chart.legend.align = 'center';
      chart.legend.fontFamily = 'Montserrat-Regular';
      chart.legend.fontSize = 10;
    }

    let indicator: any;
    let indicatorInterval: any;

    function showIndicator(chart: any) {
      if (!indicator) {
        indicator = chart.tooltipContainer.createChild(am4core.Container);
        indicator.background.fill = am4core.color('#fff');
        indicator.background.fillOpacity = 0.8;
        indicator.width = am4core.percent(100);
        indicator.height = am4core.percent(100);

        const indicatorLabel = indicator.createChild(am4core.Label);
        indicatorLabel.text = 'Loading...';
        indicatorLabel.align = 'center';
        indicatorLabel.valign = 'middle';
        indicatorLabel.fontSize = 20;
        indicatorLabel.dy = 50;

        // eslint-disable-next-line no-var
        // var hourglass = indicator.createChild(am4core.Image);
        // hourglass.href =
        //   'https://s3-us-west-2.amazonaws.com/s.cdpn.io/t-160/hourglass.svg';
        // hourglass.align = 'center';
        // hourglass.valign = 'middle';
        // hourglass.horizontalCenter = 'middle';
        // hourglass.verticalCenter = 'middle';
        // hourglass.scale = 0.7;
      }

      indicator.hide(0);
      indicator.show();

      // clearInterval(indicatorInterval);
      // indicatorInterval = setInterval(function () {
      //   hourglass?.animate(
      //     [
      //       {
      //         from: 0,
      //         to: 360,
      //         property: 'rotation',
      //       },
      //     ],
      //     2000,
      //   );
      // }, 3000);
    }

    function hideIndicator() {
      indicator?.hide();
      clearInterval(indicatorInterval);
    }

    loader ? showIndicator(chart) : hideIndicator();

    return () => {
      chart.dispose(); // Cleanup on component unmount
    };
  }, [data, loader]);

  return <div id={chartId} style={{ width: '100%', height: '230px' }}></div>;
};

export default MultiLineChart;
