import { notification } from 'antd';
import { Feature } from 'ol';
import Point from 'ol/geom/Point';
import VectorLayer from 'ol/layer/Vector';
import * as proj from 'ol/proj';
import Cluster from 'ol/source/Cluster';
import VectorSource from 'ol/source/Vector';
import { Fill, Stroke, Style } from 'ol/style';
import { getWaypointsData } from '../../constants/Api';
import {
  getLatitudeAndLongitude,
  priority,
  wayPointClusterHoverStyle,
  wayPointStyle,
} from '../../constants/Common';
import { MapFeature, UserDetails } from '../../constants/types';
import CircleStyle from 'ol/style/Circle';
import { WAY_POINT } from '../../constants/constant';

export const getWaypoints = async (
  userDetails: UserDetails,
  base: [number, number],
): Promise<{ layer: any; list: MapFeature[] }> => {
  try {
    const { organization } = userDetails;
    const response = await getWaypointsData(
      organization.api_url,
      organization.farm.id,
    );

    const { records } = response;
    const waypointsFeatures: Feature<any>[] = [];
    const waypointList: MapFeature[] = [];
    records.map((point: any) => {
      const id = `waypoint_${point.id}`;
      let latAndLong: number[] = [];
      if (point.latitude !== null && point.longitude !== null) {
        latAndLong = [point.longitude, point.latitude];
      } else {
        latAndLong = getLatitudeAndLongitude(
          [point.y / 100, point.x / 100],
          base,
          true,
        );
      }
      const pointLocation = new Feature({
        geometry: new Point(proj.fromLonLat(latAndLong)),
        name: point.name,
        pointType: 'waypoint',
      });
      const pointLocation1 = new Feature({
        geometry: new Point(proj.fromLonLat(latAndLong)),
        name: point.name,
        pointType: 'waypoint',
      });
      pointLocation1.setStyle(
        new Style({
          image: new CircleStyle({
            radius: 5,
            stroke: new Stroke({
              color: 'blue',
            }),
            fill: new Fill({
              color: '#3399CC',
            }),
          }),
          zIndex: 10,
        }),
      );
      pointLocation.setId(id);
      waypointsFeatures.push(pointLocation);
      // waypointsFeatures.push(pointLocation1);
      const geometry = pointLocation.getGeometry();
      const coordinates = geometry?.getCoordinates();
      waypointList.push({
        id: point.id,
        name: point.name,
        type: WAY_POINT,
        mapId: id,
        visible: true,
        style: wayPointStyle,
        coordinates,
      });
    });
    const clusterSource = new Cluster({
      distance: 10,
      source: new VectorSource({
        features: waypointsFeatures,
      }),
    });

    const waypointsFeaturesLayer = new VectorLayer({
      source: clusterSource,
      visible: true,
      zIndex: priority.WAYPOINT,
      style: function (feature: any) {
        const size = feature.get('features').length;
        let style = new Style({});
        if (size === 1) {
          style = wayPointStyle;
        }
        if (size > 1) {
          const feat = feature.get('features');
          let names = '';
          feat.map((fe: any, index: number) => {
            names = `${names}  ${fe.get('name')} ${
              index + 1 === feat.length ? '' : ','
            }`;
          });
          feature.set('name', names);
          style = wayPointClusterHoverStyle(size);
        }
        return style;
      },
    });
    return { layer: waypointsFeaturesLayer, list: waypointList };
  } catch (error: any) {
    notification.error({
      message: error.message,
    });
    return { layer: null, list: [] };
  }
};
