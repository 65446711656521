export enum CategoryType {
  video = 'video',
  pdf = 'pdf',
  text = 'text',
  all = 'all',
}

interface Action {
  type: string;
  payload: boolean | string | CategoryType | any | any[];
}

export type MyActionType =
  | { type: 'VIEW_RESULTS'; payload: boolean }
  | { type: 'HANDLE_SEARCH'; payload: any }
  | { type: 'SEARCH_KEYWORD'; payload: string }
  | { type: 'CATEGORY_TYPE'; payload: CategoryType }
  | { type: 'ARTICLE_ID'; payload: string }
  | { type: 'ARTICLE'; payload: any }
  | { type: 'CATEGORY'; payload: any }
  | { type: 'SUB_CATEGORY'; payload: any }
  | { type: 'CATEGORIES'; payload: any[] }
  | { type: 'SET_DATA'; payload: any }
  | { type: 'VIDEOS'; payload: any[] }
  | { type: 'SELECTED_VIDEO'; payload: any };

export interface InitialState {
  searchKeyword: string;
  categoryType: CategoryType;
  category: any;
  subCategory: any;
  article: any;
  articleId: string;
  categories: any;
  videos: any[];
  selectedVideo: any;
}

export const initialState = {
  searchKeyword: '',
  categoryType: CategoryType.all,
  category: null,
  subCategory: null,
  article: null,
  articleId: '',
  categories: [],
  videos: [],
  selectedVideo: null,
};

export default function (state: InitialState, action: Action): InitialState {
  const { type, payload } = action;
  // eslint-disable-next-line no-console
  console.log('type', type);
  switch (type) {
    case 'SEARCH_KEYWORD': // set search keyword
      return { ...state, searchKeyword: payload };
    case 'HANDLE_SEARCH': // trigger search
      return {
        ...state,
        searchKeyword: payload.searchKeyword,
      };
    case 'CATEGORY_TYPE':
      return { ...state, categoryType: payload }; // Filter Search Results
    case 'CATEGORY':
      return { ...state, category: payload }; // Goto Category
    case 'SUB_CATEGORY':
      return { ...state, subCategory: payload }; // Goto Aricle from Search
    case 'CATEGORIES':
      return { ...state, categories: payload }; // load Categories
    case 'ARTICLE_ID':
      return { ...state, articleId: payload }; // load articles id
    case 'ARTICLE':
      return { ...state, article: payload }; // load articles
    case 'VIDEOS':
      return { ...state, videos: payload }; // load articles
    case 'SELECTED_VIDEO':
      return { ...state, selectedVideo: payload }; // load articles
    case 'SET_DATA':
      return { ...state, ...payload }; // set multiple states
    case 'RESET':
      return { ...initialState };
    default:
      return state;
  }
}
