import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, DatePicker, message, notification } from 'antd';
import moment from 'moment';
import tdh from '../images/tdh.svg';
import uds from '../images/uds.svg';

import { ApplicationContext } from '../../../context/AppContext';
import {
  getTractorReport,
  getCropTypes,
  getTractorPins,
  getOrgsByCrops,
  getFirmwareVersions,
  getSoftwareVersions,
} from '../api';
import CustomMultiSelect from './CustomMultiSelect';
import { barColors, extractHours } from '../Common';
import StackedBarChart from './charts/StackedBarChart';
import './style.css';
import { QuestionCircleOutlined } from '@ant-design/icons';
import BarChart from './charts/BarChart';

const { RangePicker } = DatePicker;

const TractorResultsDashboard: React.FC = () => {
  const defaultStartDate = moment().startOf('week');
  let defaultEndDate = moment(defaultStartDate).add(6, 'days');
  if (defaultEndDate.isAfter(moment(), 'day')) {
    defaultEndDate = moment(); // Set default end date as current date
  }
  const { userDetails } = useContext(ApplicationContext);
  const [orgList, setOrgList] = useState<any[]>([]);
  const [loader, setLoader] = useState(false);
  const [selectedOrg, setSelectedOrg] = useState<any>([]);
  const [tractorPins, setTractorPins] = useState<any>([]);
  const [selectedPin, setSelectedPin] = useState<any>([]);
  const [firmwareVersions, setFirmwareVersions] = useState<any>([]);
  const [selectedFirmware, setSelectedFirmware] = useState<any>([]);
  const [sofwareVersions, setSoftwareVersions] = useState<any>([]);
  const [selectedSoftware, setSelectedSoftware] = useState<any>([]);
  const [dateFilter, setDateFilter] = useState<any>([
    defaultStartDate,
    defaultEndDate,
  ]);
  const [selectedCrop, setSelectedCrop] = useState<any>([]);
  const [cropType, setCropType] = useState<any>([]);
  const initial = useRef<boolean>(true);
  const [keyData, setKeyData] = useState<any>({});
  const [tractorHourData, setTractorHourData] = useState<any[]>([]);
  const [customerInsights, setCustomerInsights] = useState<any[]>([]);
  const [undesiredStops, setUndesiredStops] = useState<any[]>([]);
  const [group2Actions, setGroup2Actions] = useState<any[]>([]);
  const [group1Actions, setGroup1Actions] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [group1TractorPins, setGroup1TractorPins] = useState<any[]>([]);
  const [group1TractorLabels, setGroup1TractorLabels] = useState<any[]>([]);
  const [group2TractorPins, setGroup2TractorPins] = useState<any[]>([]);
  const [group2TractorLabels, setGroup2TractorLabels] = useState<any[]>([]);
  const [custInSightsDataLabels, setCustInSightsDataLabels] = useState<any>([]);
  const [custInSightsStopsLabels, setCustInSightsStopsLabels] = useState<any>(
    [],
  );
  const [intialiseData, setIntialiseData] = useState<boolean>(false);

  const getFilterData = async () => {
    try {
      // const { organization } = userDetails;
      // const { api_url } = organization;
      const fromDate =
        dateFilter?.length > 0 ? dateFilter[0]?.format('YYYY-MM-DD') : '';
      const toDate =
        dateFilter?.length > 0 ? dateFilter[1]?.format('YYYY-MM-DD') : '';
      setLoader(true);
      const response = await getCropTypes(userDetails?.organization?.api_url);
      const cropList = response?.crop_types?.map((data: any) => ({
        title: <span className="ellipsis">{data}</span>,
        value: data,
      }));
      setCropType(cropList);
      const defaultSelectedCrops = response?.crop_types?.map(
        (item: any) => item,
      );
      setSelectedCrop(defaultSelectedCrops);
      const records = await getOrgsByCrops(
        userDetails?.organization.api_url,
        defaultSelectedCrops,
      );
      const orgList = records?.org_ids?.map((data: any) => ({
        title: <span className="ellipsis">{data.name}</span>,
        value: `${data.id}`,
      }));
      const defaultSelectedIds = records?.org_ids
        ?.map((item: any) => String(item.id))
        ?.slice(0, 10);
      setSelectedOrg(defaultSelectedIds);
      setOrgList(orgList);
      const tractors = await getTractorPins(
        userDetails?.organization?.api_url,
        defaultSelectedIds,
      );
      const pinList = tractors?.tractor_pins?.map((data: any) => ({
        title: <span className="ellipsis">{data}</span>,
        value: data,
      }));
      const defaultSelectedPins = tractors?.tractor_pins?.map(
        (item: any) => item,
      );
      setTractorPins(pinList);
      setSelectedPin(defaultSelectedPins);
      const firmwares = await getFirmwareVersions(
        userDetails?.organization?.api_url,
        defaultSelectedPins,
        fromDate,
        toDate,
      );
      const firmwareList = firmwares?.firmware_versions?.map((data: any) => ({
        title: <span className="ellipsis">{data}</span>,
        value: data,
      }));
      const defaultSelectedFirmwares = firmwares?.firmware_versions?.map(
        (item: any) => item,
      );
      setFirmwareVersions(firmwareList);
      setSelectedFirmware(defaultSelectedFirmwares);
      const softwares = await getSoftwareVersions(
        userDetails?.organization?.api_url,
        defaultSelectedPins,
        fromDate,
        toDate,
      );
      const softwareList = softwares?.software_versions?.map((data: any) => ({
        title: <span className="ellipsis">{data}</span>,
        value: data,
      }));
      const defaultSelectedSoftwares = softwares?.software_versions?.map(
        (item: any) => item,
      );
      setSoftwareVersions(softwareList);
      setSelectedSoftware(defaultSelectedSoftwares);
    } catch (error: any) {
      notification.error({ message: error.message });
    } finally {
      setIntialiseData(true);
      setLoader(false);
      initial.current = false;
    }
  };

  useEffect(() => {
    if (userDetails?.organization) getFilterData();
  }, [userDetails]);

  useEffect(() => {
    const init = async () => {
      try {
        const { organization } = userDetails;
        const records = await getOrgsByCrops(
          organization.api_url,
          selectedCrop,
        );
        const orgList = records?.org_ids?.map((data: any) => ({
          title: <span className="ellipsis">{data.name}</span>,
          value: `${data.id}`,
        }));
        // const defaultSelectedIds = records?.org_ids?.map((item: any) =>
        //   String(item.id),
        // );
        // setSelectedOrg(defaultSelectedIds);
        setOrgList(orgList);
      } catch (error: any) {
        notification.error({
          message: error.message,
          duration: 1,
        });
      }
    };
    if (selectedCrop?.length && initial.current === false) init();
  }, [selectedCrop]);

  useEffect(() => {
    const init = async () => {
      try {
        const { organization } = userDetails;
        const { api_url } = organization;
        const response = await getTractorPins(api_url, selectedOrg);
        const pinList = response?.tractor_pins?.map((data: any) => ({
          title: <span className="ellipsis">{data}</span>,
          value: data,
        }));
        setTractorPins(pinList);
      } catch (error: any) {
        notification.error({ message: error.message });
      }
    };
    if (selectedOrg?.length && initial.current === false) init();
  }, [selectedOrg]);

  useEffect(() => {
    const init = async () => {
      try {
        const { organization } = userDetails;
        const { api_url } = organization;
        const fromDate =
          dateFilter?.length > 0 ? dateFilter[0]?.format('YYYY-MM-DD') : '';
        const toDate =
          dateFilter?.length > 0 ? dateFilter[1]?.format('YYYY-MM-DD') : '';
        const response = await getFirmwareVersions(
          api_url,
          selectedPin,
          fromDate,
          toDate,
        );
        const softwareVersions = await getSoftwareVersions(
          api_url,
          selectedPin,
          fromDate,
          toDate,
        );
        const firmwareList = response?.firmware_versions?.map((data: any) => ({
          title: <span className="ellipsis">{data}</span>,
          value: data,
        }));
        const softwareList = softwareVersions?.software_versions?.map(
          (data: any) => ({
            title: <span className="ellipsis">{data}</span>,
            value: data,
          }),
        );
        setSoftwareVersions(softwareList);
        setFirmwareVersions(firmwareList);
      } catch (error: any) {
        notification.error({ message: error.message });
      }
    };
    if (selectedPin?.length && initial.current === false) init();
  }, [selectedPin, dateFilter]);

  const getData = async () => {
    try {
      setIsLoading(true);
      const { organization } = userDetails;
      const fromDate =
        dateFilter?.length > 0 ? dateFilter[0]?.format('YYYY-MM-DD') : '';
      const toDate =
        dateFilter?.length > 0 ? dateFilter[1]?.format('YYYY-MM-DD') : '';
      const response = await getTractorReport(
        organization.api_url,
        selectedCrop,
        selectedOrg,
        selectedPin,
        selectedFirmware,
        selectedSoftware,
        fromDate,
        toDate,
      );
      setKeyData(response);
      const hoursData =
        response?.org_tractor_driving_data?.tractor_driving_data?.map(
          (ele: any) => {
            return {
              org_name: ele.org_name,
              'Autonomy Hours': extractHours(ele?.autonomy_hours),
              'Manual Hours': extractHours(ele?.manual_hours),
              'Operator Assist': extractHours(ele?.operator_assist),
            };
          },
        );
      if (!response?.org_tractor_driving_data?.tractor_driving_data?.length)
        message.error('Tractor Driving Hours Data Not Found');
      const group2_fault_actions =
        response?.group_two_error_code_by_fault_actions?.map((obj: any) => {
          return Object.entries(obj).reduce((acc: any, [key, value]: any) => {
            acc.category = key;
            acc.value = value;
            return acc;
          }, {});
        });
      if (!response?.group_two_error_code_by_fault_actions?.length)
        message.error('Group 2 Fault Actions Data Not Found');
      const group1_fault_actions =
        response?.group_one_error_code_segment_by_fault_actions?.map(
          (obj: any) => {
            return Object.entries(obj).reduce((acc: any, [key, value]: any) => {
              acc.category = key;
              acc.value = value;
              return acc;
            }, {});
          },
        );
      if (!response?.group_one_error_code_segment_by_fault_actions?.length)
        message.error('Group 1 Fault Actions Data Not Found');

      const group1_ticket_data = Object.entries(
        response?.group_one_error_code_segment_by_tractor_pin,
      ).map(([category, segments]: any) => {
        const categoryData: any = { category };
        segments?.forEach((segment: any) => {
          const [segmentName, value]: any = Object.entries(segment)[0];
          categoryData[segmentName] = value;
        });
        return categoryData;
      });
      if (
        !Object.entries(response?.group_one_error_code_segment_by_tractor_pin)
          ?.length
      )
        message.error('Group 1 Ticket Data Not Found');
      const group2_ticket_data = Object.entries(
        response.group_two_error_code_segment_by_tractor_pin,
      ).map(([category, segments]: any) => {
        const categoryData: any = { category };
        segments?.forEach((segment: any) => {
          const [segmentName, value]: any = Object.entries(segment)[0];
          categoryData[segmentName] = value;
        });
        return categoryData;
      });
      const insightsData = response?.cust_insights_data?.map((ele: any) => {
        return {
          org_name: ele?.org_name,
          [ele.crop_type]: extractHours(ele?.total_hours),
        };
      });
      const insightsStops =
        response?.cust_insight_undesired_stop_count_seg_by_crops?.map(
          (ele: any) => {
            return {
              org_name: ele?.org_name,
              [ele.crop_type]: ele?.count_of_undesired_stops,
            };
          },
        );
      const insightsDataLabels = response?.cust_insights_data?.reduce(
        (acc: any, ele: any) => {
          if (!acc.includes(ele.crop_type)) {
            acc.push(ele.crop_type);
          }
          return acc;
        },
        [],
      );
      const insightsStopsLabels =
        response?.cust_insight_undesired_stop_count_seg_by_crops?.reduce(
          (acc: any, ele: any) => {
            if (!acc.includes(ele.crop_type)) {
              acc.push(ele.crop_type);
            }
            return acc;
          },
          [],
        );
      if (!response?.cust_insights_data?.length)
        message.error('Customer Insights Hours Data Not Found');
      if (!response?.cust_insight_undesired_stop_count_seg_by_crops?.length)
        message.error('Customer Insights Stops Data Not Found');
      if (
        !Object.entries(response?.group_two_error_code_segment_by_tractor_pin)
          ?.length
      )
        message.error('Group 2 Ticket Data Not Found');

      const allTractors1: string[] = group1_ticket_data?.reduce(
        (acc: string[], curr: any) => {
          Object.keys(curr).forEach((key: string) => {
            if (key !== 'category' && !acc.includes(key)) {
              acc.push(key);
            }
          });
          return acc;
        },
        [],
      );
      const allTractors2: string[] = group2_ticket_data?.reduce(
        (acc: string[], curr: any) => {
          Object.keys(curr).forEach((key: string) => {
            if (key !== 'category' && !acc.includes(key)) {
              acc.push(key);
            }
          });
          return acc;
        },
        [],
      );
      setTractorHourData(hoursData);
      setCustomerInsights(insightsData);
      setUndesiredStops(insightsStops);
      setGroup1Actions(group1_fault_actions);
      setGroup2Actions(group2_fault_actions);
      setGroup1TractorPins(group1_ticket_data);
      setGroup1TractorLabels(allTractors1);
      setGroup2TractorPins(group2_ticket_data);
      setGroup2TractorLabels(allTractors2);
      setCustInSightsDataLabels(insightsDataLabels);
      setCustInSightsStopsLabels(insightsStopsLabels);
    } catch (error: any) {
      notification.error({ message: error.message });
    } finally {
      setIsLoading(false);
    }
  };

  const emptyData = () => {
    setKeyData([]);
    setTractorHourData([]);
    setCustomerInsights([]);
    setUndesiredStops([]);
    setGroup1Actions([]);
    setGroup2Actions([]);
    setGroup1TractorPins([]);
    setGroup1TractorLabels([]);
    setGroup2TractorPins([]);
    setGroup2TractorLabels([]);
  };

  const loadData = () => {
    if (selectedCrop?.length) {
      emptyData();
      getData();
    }
  };

  useEffect(() => {
    if (initial.current && intialiseData) {
      getData();
      initial.current = false;
    }
  }, [
    selectedCrop,
    selectedOrg,
    selectedPin,
    selectedFirmware,
    selectedSoftware,
    intialiseData,
  ]);

  const handleOrg = (ids: any) => {
    setTractorPins([]);
    setFirmwareVersions([]);
    setSoftwareVersions([]);
    setSelectedPin([]);
    setSelectedFirmware([]);
    setSelectedSoftware([]);
    setSelectedOrg(ids);
  };

  const disabledDates = (current: any) => {
    return (
      (current && current > moment()) ||
      (current && current <= moment('12-31-2022'))
    );
  };

  const onDateRangeChange = (dates: any) => {
    setFirmwareVersions([]);
    setSoftwareVersions([]);
    setSelectedFirmware([]);
    setSelectedSoftware([]);
    setDateFilter(dates);
  };

  const handleCrop = (ids: any) => {
    setOrgList([]);
    setTractorPins([]);
    setFirmwareVersions([]);
    setSoftwareVersions([]);
    setSelectedOrg([]);
    setSelectedPin([]);
    setSelectedFirmware([]);
    setSelectedSoftware([]);
    setSelectedCrop(ids);
  };

  const handleSubmit = () => {
    if (userDetails && selectedCrop.length) loadData();
    else {
      message.warning('Please select Crop');
      // resetData();
    }
  };

  const handlePins = (e: any) => {
    setFirmwareVersions([]);
    setSoftwareVersions([]);
    setSelectedFirmware([]);
    setSelectedSoftware([]);
    setSelectedPin(e);
  };
  const handleFirmware = (e: any) => {
    setSelectedFirmware(e);
  };

  const handleSoftware = (e: any) => {
    setSelectedSoftware(e);
  };

  return (
    <div className="common_tabs">
      <div className="ad_ResultsTitleFlex">
        <div className="ad_ResultsTitle">Undesired Stops Dashboard</div>
        <div className="ad_filters_card1 ad_filters_card_new">
          <div className="ad_displayFlexNew">
            <CustomMultiSelect
              handleTreeSelect={handleCrop}
              selectedId={selectedCrop}
              list={cropType}
              label="Crops"
            />
            <CustomMultiSelect
              handleTreeSelect={handleOrg}
              selectedId={selectedOrg}
              list={orgList}
              label="Organizations"
            />
            <CustomMultiSelect
              handleTreeSelect={handlePins}
              selectedId={selectedPin}
              list={tractorPins}
              label="Tractor Name"
            />
            <RangePicker
              allowClear={false}
              format="YYYY-MM-DD"
              value={dateFilter}
              onChange={onDateRangeChange}
              disabledDate={disabledDates}
            />
            <CustomMultiSelect
              handleTreeSelect={handleFirmware}
              selectedId={selectedFirmware}
              list={firmwareVersions}
              label="Firmware version"
            />
            <CustomMultiSelect
              handleTreeSelect={handleSoftware}
              selectedId={selectedSoftware}
              list={sofwareVersions}
              label="Software version"
            />
            <Button
              className="submitBtn"
              onClick={handleSubmit}
              loading={isLoading}
            >
              Submit
            </Button>
            {/* <div className="filterIcon">
              <QuestionCircleOutlined className="helpIcon" />
            </div> 
            <div className="filterIcon">
              <div className="downloadIcon" />
            </div> */}
          </div>
        </div>
      </div>
      <div className="ant-tabs-content-holder ad_content_holder">
        <div className="ad_common_wrapper SummaryCont">
          <div className="widgets_blocknew">
            <div className="gridItem summaryCard">
              <img src={tdh} alt="Kms Driven" />
              <div className="widget_content">
                <div className="assignTxt">Tractor Driving Hours</div>
                <div className="assignNum">
                  {keyData?.summary?.total_driving_hours}
                </div>
              </div>
            </div>
            <div className="gridItem summaryCard">
              <img src={uds} alt="Kms Driven" />
              <div className="widget_content">
                <div className="assignTxt">Undesired Stops / Hour</div>
                <div className="assignNum">
                  {keyData?.summary?.undesired_stops_per_hour}
                </div>
              </div>
            </div>
          </div>
          {/* <AppLoader loader={loader} /> */}
          <div className="ad_new_charts">
            <div className="ad_new_charts_left">
              <div className="ad_new_charts_left_top">
                <div className="ad_new_charts_box ad_new_charts_left_top_one">
                  <div className="ad_new_charts_flex">
                    <div className="ad_new_charts_title">
                      <h6>Tractor Driving Hours</h6>
                      <h3>
                        {keyData?.org_tractor_driving_data
                          ?.total_driving_hours_min || '-'}
                      </h3>
                    </div>
                  </div>
                  <StackedBarChart
                    loader={isLoading}
                    chartId="tractorHours"
                    barColors={barColors}
                    labels={[
                      'Manual Hours',
                      'Operator Assist',
                      'Autonomy Hours',
                    ]}
                    data={tractorHourData}
                    yTitle="Driving Hours"
                    category="org_name"
                    xTitle="Organization"
                  />
                </div>
                <div className="ad_new_charts_box ad_new_charts_left_top_two">
                  <div className="ad_new_charts_flex">
                    <div className="ad_new_charts_title">
                      <h6>Undesired Stops</h6>
                      <h3>
                        {keyData?.undesired_stops?.total_undesired_stops || '-'}
                      </h3>
                    </div>
                  </div>
                  <StackedBarChart
                    loader={isLoading}
                    chartId="undesiredStops"
                    barColors={barColors}
                    labels={[
                      'Spools Down',
                      'Spooled Up',
                      'Brakes Are Applied',
                      'others',
                    ]}
                    data={keyData?.undesired_stops?.undesired_stops_data}
                    yTitle="Frequency"
                    category="org_name"
                    xTitle="Organization"
                  />
                </div>
              </div>
              <div className="">
                <div className="ad_new_charts_box">
                  <div className="ad_new_charts_flex">
                    <div className="ad_new_charts_title">
                      <h6>Total Errors</h6>
                      <h3>{keyData?.error_code_data?.total_error || '-'}</h3>
                    </div>
                  </div>
                  <StackedBarChart
                    loader={isLoading}
                    chartId="total errors"
                    barColors={['#A3D5D8', '#CFCFCF', '#F6C990', '#DE827A']}
                    labels={['level 0', 'level 1', 'level 2', 'level 3']}
                    data={
                      keyData?.error_code_data
                        ?.error_codes_segment_by_levels_data
                    }
                    yTitle="Frequency"
                    category="org_name"
                    xTitle="Organization"
                  />
                </div>
              </div>
              <div className="ad_new_charts_left_top">
                {/* <h6>Customer Insights</h6> */}
                <div className="ad_new_charts_box ad_new_charts_left_top_one">
                  <div className="ad_new_charts_flex">
                    <div className="ad_new_charts_title">
                      <h6>Group 1 Error codes</h6>
                    </div>
                  </div>
                  <BarChart
                    loader={isLoading}
                    chartId="group1faultactions"
                    data={group1Actions}
                    yTitle="Frequency"
                    xTitle="Error Code"
                  />
                </div>
                <div className="ad_new_charts_box ad_new_charts_left_top_two">
                  <div className="ad_new_charts_flex">
                    <div className="ad_new_charts_title">
                      <h6>Group 1 Error code - Tractor Name</h6>
                    </div>
                  </div>
                  <StackedBarChart
                    loader={isLoading}
                    chartId="tractorPin1"
                    barColors={barColors}
                    labels={group1TractorLabels}
                    data={group1TractorPins}
                    yTitle="Frequency"
                    category="category"
                    xTitle="Error Code"
                  />
                </div>
              </div>
              <div className="ad_new_charts_left_top">
                {/* <h6>Customer Insights</h6> */}
                <div className="ad_new_charts_box ad_new_charts_left_top_one">
                  <div className="ad_new_charts_flex">
                    <div className="ad_new_charts_title">
                      <h6>Group 2 Error codes</h6>
                    </div>
                  </div>
                  <BarChart
                    loader={isLoading}
                    chartId="group2faultactions"
                    data={group2Actions}
                    yTitle="Frequency"
                    xTitle="Error Code"
                  />
                </div>
                <div className="ad_new_charts_box ad_new_charts_left_top_two">
                  <div className="ad_new_charts_flex">
                    <div className="ad_new_charts_title">
                      <h6>Group 2 Error Code - Tractor Name</h6>
                    </div>
                  </div>
                  <StackedBarChart
                    loader={isLoading}
                    chartId="tractorPin2"
                    barColors={barColors}
                    labels={group2TractorLabels}
                    data={group2TractorPins}
                    yTitle="Frequency"
                    category="category"
                    xTitle="Error Code"
                  />
                </div>
              </div>
              <div className="ad_new_charts_left_top">
                {/* <h6>Customer Insights</h6> */}
                <div className="ad_new_charts_box ad_new_charts_left_top_one">
                  <div className="ad_new_charts_flex">
                    <div className="ad_new_charts_title">
                      <h6>Customer Insights Hours</h6>
                    </div>
                  </div>
                  <StackedBarChart
                    loader={isLoading}
                    chartId="insightsCropType"
                    barColors={barColors}
                    labels={custInSightsDataLabels}
                    data={customerInsights}
                    yTitle="Drving Hours"
                    category="org_name"
                    xTitle="Organization"
                  />
                </div>
                <div className="ad_new_charts_box ad_new_charts_left_top_two">
                  <div className="ad_new_charts_flex">
                    <div className="ad_new_charts_title">
                      <h6>Customer Insights Stops</h6>
                    </div>
                  </div>
                  <StackedBarChart
                    loader={isLoading}
                    chartId="customerStops"
                    barColors={barColors}
                    labels={custInSightsStopsLabels}
                    data={undesiredStops}
                    yTitle="Total Undesired stops"
                    category="org_name"
                    xTitle="Organization"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TractorResultsDashboard;
