import React, { useState } from 'react';
import { Tabs } from 'antd';
import Tractors from './Tractors';
import Customers from './Customers';
import BaseStation from './BaseStation';

const { TabPane } = Tabs;

const Inventory: React.FC = () => {
  const [tabid, setTabid] = useState<string>('tractors');

  return (
    <Tabs
      activeKey={tabid}
      centered
      id="org-tab-Organization"
      className="inventoryTabs"
      onChange={(key) => {
        setTabid(key);
      }}
    >
      <TabPane
        tab="Tractors"
        key="tractors"
        className="attTableHeight mBottom0"
      >
        {tabid === 'tractors' && <Tractors />}
      </TabPane>
      <TabPane
        tab="Customers"
        key="customers"
        className="attTableHeight mBottom0"
      >
        {tabid === 'customers' && <Customers />}
      </TabPane>
      <TabPane
        tab="Basestations"
        key="basestation"
        className="attTableHeight mBottom0"
      >
        {tabid === 'basestation' && <BaseStation />}
      </TabPane>
    </Tabs>
  );
};

export default Inventory;
