/* eslint-disable react/display-name */
import React, { useContext, useState, useEffect } from 'react';
import { Row, notification, Tooltip } from 'antd';
import Layout, { Content } from 'antd/lib/layout/layout';
import {
  getFleetIndicatorHistory,
  getTractorsListData,
} from '../../constants/Api';
import { ApplicationContext } from '../../context/AppContext';
import {
  fleetHistoryData,
  fleetObject,
  TractorsList,
} from '../../constants/types';
import {
  fromToDateWrapper,
  getDateTime,
  getDateTimes,
  getDiffTime,
  getTractorAvailableState,
  tractorSort,
} from '../../constants/Common';
import { useTranslation } from 'react-i18next';
import translate from '../../locale/en_translate.json';
import 'react-datepicker/dist/react-datepicker.css';
import { getIndicatorImages } from '../basestation/Common';
import InfiniteScrollTable from '../common/InfiniteScrollTable';
import './style.css';
import moment from 'moment';
import CustomSelect from '../common/CustomSelect2';
import CustomDate from '../common/CustomDate';

interface StatusListType {
  name: string;
  value: string;
}

interface LevelType {
  number: string;
  value: string;
}

interface TractorResponseType {
  label: string;
  value: number | string | boolean;
  disabled: boolean;
  index: number | string;
  isAvailable?: string;
}

const FleetIndicatorHistory: React.FC = () => {
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const [fleetHistory, setFleetHistory] = useState<fleetObject[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [pageSize, setPagesize] = useState<any>(25);
  const [tractors, setTractorsData] = useState<TractorResponseType[]>([]);
  const [tractorId, setTractorId] = useState<string>();
  const [indicatorKey, setIndicatorKey] = useState<any>();
  const [fromDateTime, setFromDateTime] = useState<any>(
    moment().subtract(1, 'day').toDate(),
  );
  const [toDateTime, setToDateTime] = useState<any>(moment().toDate());
  const [totalCount, setTotalCount] = useState(0);
  const [loader, setLoader] = useState<boolean>(false);
  const [filterData, setFilter] = useState<fleetObject[]>([]);
  const statusList: StatusListType[] = [
    { name: 'Tractor_Communication', value: 'Tractor_Communication' },
    { name: 'Tractor_Automation', value: 'Tractor_Automation' },
    { name: 'Tractor_Drivability', value: 'Tractor_Drivability' },
    { name: 'Tractor_Data', value: 'Tractor_Data' },
    { name: 'Tractor_Energy', value: 'Tractor_Energy' },
    { name: 'Hitch_and_Implement', value: 'Hitch_and_Implement' },
  ];
  const [indicatorLevel, setIndicatorLevel] = useState<any>();
  const level: LevelType[] = [
    { number: '1', value: '1' },
    { number: '2', value: '2' },
    { number: '3', value: '3' },
  ];

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      const init = async () => {
        await fleetIndicatorHistoryList();
      };
      init();
    }
  }, [
    userDetails,
    pageNumber,
    pageSize,
    tractorId,
    indicatorKey,
    indicatorLevel,
    fromDateTime,
    toDateTime,
  ]);

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      getTractorsList();
    }
  }, [userDetails]);

  const fleetIndicatorHistoryList = async () => {
    const { organization } = userDetails;
    try {
      setLoader(true);
      const fleetView = await getFleetIndicatorHistory(
        organization.api_url,
        organization.fleet.id,
        tractorId ? tractorId : '',
        indicatorKey ? indicatorKey : '',
        fromDateTime ? fromDateTime.getTime() : '',
        toDateTime ? toDateTime.getTime() : '',
        pageNumber,
        pageSize,
        indicatorLevel ? indicatorLevel : '',
      );
      const { _metadata } = fleetView;
      setTotalCount(_metadata.total_records_count);
      const data =
        fleetView && fleetView.records && fleetView.records.length > 0
          ? fleetView.records
          : [];
      const fleetData: fleetObject[] = [];
      data.map((record: fleetHistoryData) => {
        const startTime = getDateTime(record.created_date_time);
        const EndTime = getDateTime(record.resolved_date_time);

        const obj = {
          id: record.id,
          name: record.tractor.name,
          description: record.description ? record.description : '',
          level: record.indicator_level,
          start_time: record.created_date_time
            ? getDateTimes(record.created_date_time)
            : '',
          indicator_name: record.indicator_name,
          explanation: record.explanation ?? '-',
          indicator_level: record.indicator_level,
          error_code: record.error_code,
          resolved_date_time: record.resolved_date_time
            ? getDateTimes(record.resolved_date_time)
            : '',
          Duration: record.resolved_date_time
            ? getDiffTime(startTime, EndTime)
            : '',
        };
        fleetData.push(obj);
      });
      if (data.length > 0) {
        if (pageNumber === 1) {
          setFilter(fleetData);
        } else {
          setFilter([...filterData, ...fleetData]);
        }
      } else {
        setFilter(fleetData);
        setFleetHistory(fleetData);
      }

      if (fleetData.length < 25) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
      setLoader(false);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    }
  };
  const columns = [
    {
      title: `${t(translate.baseStation.tractorName)}`,
      dataIndex: 'name',
      key: 'name',
      width: '12%',
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
    },
    {
      title: `${t(translate.taskmaster.starttime)}`,
      dataIndex: 'start_time',
      key: 'start_time',
      width: '14%',
    },
    {
      title: `${t(translate.tractorDetails.indicatorName)}`,
      dataIndex: 'indicator_name',
      key: 'indicator_name',
      width: '12%',
      sorter: (a: any, b: any) =>
        a.indicator_name.localeCompare(b.indicator_name),
    },
    {
      title: `${t(translate.tractorDetails.errorcode)}`,
      dataIndex: 'error_code',
      key: 'error_code',
      width: '10%',
      sorter: (a: any, b: any) => a.error_code.localeCompare(b.error_code),
    },
    {
      title: `${t(translate.myTask.description)}`,
      dataIndex: 'description',
      key: 'description',
      sorter: (a: any, b: any) => a.description.localeCompare(b.description),
      render: (description: string, levelObj: fleetObject) => {
        return (
          <div className="description">
            <img
              width="18px"
              height="18px"
              src={getIndicatorImages(
                levelObj.indicator_name,
                levelObj.level,
                true,
              )}
            />
            <Tooltip title={description}>
              <div className="description-text">{description}</div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: `${t(translate.tractorDetails.explanation)}`,
      dataIndex: 'explanation',
      key: 'explanation',
      width: '12%',
      render: (explanation: string) => {
        return (
          <Tooltip title={explanation}>
            <div className="description-text">{explanation}</div>
          </Tooltip>
        );
      },
    },
    {
      title: `${t(translate.tractorDetails.ResolvedTime)}`,
      dataIndex: 'resolved_date_time',
      key: 'resolved_date_time',
      width: '14%',
    },
    {
      title: `${t(translate.copycat.duration)}`,
      dataIndex: 'Duration',
      key: 'Duration',
      width: '10%',
    },
  ];

  const getTractorsList = async () => {
    try {
      const tractor: { records: TractorsList[] } = await getTractorsListData(
        userDetails.organization.api_url,
        userDetails.organization_id,
        pageNumber,
      );
      const data =
        tractor && tractor.records && tractor.records.length > 0
          ? tractor.records
          : [];
      const tractorData: TractorResponseType[] = data.map((d: TractorsList) => {
        return {
          label: d.name,
          value: d.id,
          disabled: false,
          index: d.id,
          id: d.id,
          connection: d.connection,
          isOnline: d.connection === 'online' ? true : false,
          isAvailable: getTractorAvailableState(d.connection, d?.heartbeat),
        };
      });
      const arr = tractorSort(tractorData, 'label');
      setTractorsData(arr);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    }
  };

  const handleSelect = async (e: any) => {
    fleetHistory.length = 0;
    filterData.length = 0;
    setPageNumber(1);
    setTractorId(e);
  };

  const statusSelect = (e: string) => {
    fleetHistory.length = 0;
    filterData.length = 0;
    setPageNumber(1);
    setIndicatorKey(e);
  };

  const levelSelect = (e: string) => {
    fleetHistory.length = 0;
    filterData.length = 0;
    setPageNumber(1);
    setIndicatorLevel(e);
  };

  const handleLoadMore = () => {
    setPageNumber(pageNumber + 1);
  };

  const onDateRangeChange = (dates: any) => {
    fleetHistory.length = 0;
    filterData.length = 0;
    if (dates) {
      setPageNumber(1);
      setFilter([]);
      setFleetHistory([]);
      const [from, to] = fromToDateWrapper(dates);
      setFromDateTime(from.toDate());
      setToDateTime(to.toDate());
    } else {
      setFromDateTime(null);
      setToDateTime(null);
    }
  };

  return (
    <Layout>
      <div className="mainContent">
        <div className="tblContainer viewportContainer">
          <Row>
            <div className="common_wrapper wWidget_wrapper">
              <div className="filters_card personnelCard">
                <CustomSelect
                  label="Tractor"
                  cssClass="min_width"
                  value={tractorId}
                  setValue={(id: any) => {
                    handleSelect(id);
                  }}
                  options={tractors.map((item) =>
                    Object.assign({
                      id: item.index,
                      name: item.label,
                      isAvailable: item.isAvailable,
                    }),
                  )}
                  optionKey="id"
                  optionDisplay="name"
                  isAll={true}
                />
                <CustomSelect
                  label="Indicator"
                  cssClass="min_width"
                  value={indicatorKey}
                  setValue={(id: any) => {
                    statusSelect(id);
                  }}
                  options={statusList.map((item: any) =>
                    Object.assign({
                      id: item.index,
                      name: item.name,
                      value: item.value,
                      key: item.value,
                    }),
                  )}
                  optionKey="value"
                  optionDisplay="name"
                  isAll={true}
                />
                <CustomSelect
                  label="Level"
                  cssClass="min_width"
                  value={indicatorLevel}
                  setValue={(id: any) => {
                    levelSelect(id);
                  }}
                  options={level.map((item: any) =>
                    Object.assign({
                      id: item.index,
                      name: item.number,
                      value: item.value,
                      key: item.value,
                    }),
                  )}
                  optionKey="value"
                  optionDisplay="name"
                  isAll={true}
                />
                <CustomDate
                  label="Select Date"
                  cssClass="min_width"
                  value={[moment(fromDateTime), moment(toDateTime)]}
                  onChange={(dates: any) => {
                    onDateRangeChange(dates);
                  }}
                  format="YYYY-MM-DD"
                />
              </div>
              <div className="common_table">
                <InfiniteScrollTable
                  // xScroll={1800}
                  columns={columns}
                  loading={loader}
                  hasMore={hasMore}
                  filterData={filterData}
                  totalcount={totalCount}
                  handleLoadMore={handleLoadMore}
                  filename="FleetindicatorHistory"
                />
              </div>
            </div>
          </Row>
        </div>
      </div>
    </Layout>
  );
};

export default FleetIndicatorHistory;
