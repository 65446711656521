/* eslint-disable no-console */
import { notification } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import d_edit from '../../assets/images/d_edit.svg';
import dnd_equal_icon from '../../assets/images/dnd_equal_icon.svg';
import empty_star from '../../assets/images/empty_star.svg';
import filled_star from '../../assets/images/filled_star.svg';
import trash_d from '../../assets/images/trash_d.svg';
import {
  favaratePreset,
  getPresetOperationsData,
  updatePresetOrder,
} from '../../constants/Api';
import { AutoDriveTabs, OldOperation } from '../../constants/constant';
import { ApplicationContext } from '../../context/AppContext';
import { AutoDriveApplicationContext } from '../../context/AutoDriveContext';
import { SET_TRACTOR_IMPLEMENT_STATE } from '../../context/actions';
import { DairyCommand, operationType } from '../../lib/types';
import { RemoteDriveAppCtx } from '../remote_drive_new/RemoteDriveContext';
import {
  SET_ACTIVE_AUTODRIVE_TAB,
  SET_SELECTED_FEED_LANES,
  SET_SETUP_KEYS,
  SET_SHOW_IMPLEMENT,
} from '../remote_drive_new/actions';

interface Props {
  setNewOrEditOperation: (state: operationType) => void;
  setSelectedImplement: (implement: string) => void;

  setShowPresetDelete: (state: boolean) => void;
  setAutoDriveLoader: (state: boolean) => void;
  selectedImplement: string;
  setSelectedCommand: (command: DairyCommand | null) => void;
  setShowMixed: (state: boolean) => void;

  refreshOperations: boolean;
}

const PresetList: React.FC<Props> = ({
  setNewOrEditOperation,
  selectedImplement,
  setShowPresetDelete,
  setSelectedCommand,
  refreshOperations,
  setAutoDriveLoader,
  setShowMixed,
}: Props) => {
  const { userDetails } = useContext(ApplicationContext);

  const { AutoDriveReducer } = useContext(AutoDriveApplicationContext);
  const [autoDriveState, autoDriveDispatch] = AutoDriveReducer;
  const { tractorImplementState } = autoDriveState;
  const { RDReducer } = useContext(RemoteDriveAppCtx);
  const [state, dispatch] = RDReducer;
  const { selectedTractor, setUpKeys } = state;
  const [showEditOpsList, setShowEditOpsList] = useState(false);
  const [presetOperations, setPresetOperations] = useState<DairyCommand[]>([]);
  const [apiCallSuccess, setApiCallSuccess] = useState(false);

  useEffect(() => {
    userDetails && userDetails.organization && getPresetOperations();
  }, [userDetails, selectedImplement, refreshOperations]);

  const getPresetOperations = async () => {
    setAutoDriveLoader(true);
    setApiCallSuccess(false);
    try {
      const result = await getPresetOperationsData(
        userDetails.organization.api_url,
        selectedImplement,
      );
      if (result && result.length > 0) {
        const orderedItems = result.sort((a: DairyCommand, b: DairyCommand) => {
          if (a.is_favourite === b.is_favourite) {
            return 0;
          }
          return a.is_favourite ? -1 : 1;
        });
        setPresetOperations(orderedItems);
      } else {
        setPresetOperations([]);
      }
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    } finally {
      setApiCallSuccess(true);

      setAutoDriveLoader(false);
    }
  };

  // drag and drop code start here
  const onDragStart = (
    event: React.DragEvent<HTMLDivElement>,
    index: number,
  ) => {
    event.dataTransfer.setData('index', index.toString());
  };

  const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const onDrop = (event: React.DragEvent<HTMLDivElement>, newIndex: number) => {
    const oldIndex = parseInt(event.dataTransfer.getData('index'));
    const item = presetOperations[oldIndex];
    const newItems = presetOperations.filter((_, index) => index !== oldIndex);
    newItems.splice(newIndex, 0, item);
    setPresetOperations(newItems);
  };
  const handleFavoritePreset = async (presetOperation: DairyCommand) => {
    setAutoDriveLoader(true);
    try {
      const payload = {
        is_favourite: !presetOperation.is_favourite,
      };
      const { msg: message } = await favaratePreset(
        userDetails.organization.api_url,
        payload,
        presetOperation?.preset_uuid as string,
      );
      message &&
        notification.success({
          message,
        });
      message && getPresetOperations();
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    } finally {
      setAutoDriveLoader(false);
    }
  };

  const presetChange = async () => {
    setAutoDriveLoader(true);
    const ids = presetOperations.map((preset: DairyCommand) => preset.id);
    try {
      const payload = {
        sorted_list: ids,
        implement_uuid: selectedImplement,
      };
      const { msg: message } = await updatePresetOrder(
        userDetails.organization.api_url,
        payload,
      );
      if (message) {
        // notification.success({
        //   message,
        // });
        setShowEditOpsList(false);
        getPresetOperations();
      }
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    } finally {
      setAutoDriveLoader(false);
    }
  };

  return (
    <>
      {!showEditOpsList && (
        <div className="preset_operations_section">
          <div className="preset_operation_head">
            <div className="po_row">
              <h2 className="preset_head_text">Preset Operations</h2>
              <span
                className="edit_list"
                onClick={() => setShowEditOpsList(true)}
              >
                <img src={d_edit} />
                Edit List
              </span>
            </div>
            <div className="po_row">
              <h2 className="choose_list_text">Choose from list</h2>
            </div>
          </div>
          <div className="preset_list">
            {presetOperations.map((presetOperation: DairyCommand) => (
              <>
                <div className="preset_list_item" key={presetOperation.id}>
                  <div className="item_row">
                    <div
                      className="star_sec cursor_pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleFavoritePreset(presetOperation);
                      }}
                    >
                      {' '}
                      {presetOperation.is_favourite && (
                        <img src={filled_star} />
                      )}
                      {!presetOperation.is_favourite && (
                        <img src={empty_star} />
                      )}
                    </div>
                    <div className="item_dtl_sec">
                      <h2 className="item_line1">{presetOperation.name}</h2>
                      <p className="item_line2">
                        {presetOperation.command.lane_list.length}{' '}
                        {`${
                          presetOperation.command.lane_list.length === 1
                            ? 'lane'
                            : 'lanes'
                        }`}{' '}
                      </p>
                    </div>
                    <div className="item_btn_sec">
                      <button
                        className="select_btn"
                        onClick={() => {
                          setNewOrEditOperation(OldOperation);
                          setSelectedCommand(presetOperation);
                          dispatch({
                            type: SET_ACTIVE_AUTODRIVE_TAB,
                            payload: AutoDriveTabs.Block,
                          });
                        }}
                      >
                        Select
                      </button>
                    </div>
                  </div>
                </div>
              </>
            ))}
            {apiCallSuccess && presetOperations.length === 0 && (
              <div className="no_preset_list">
                No presets available for the implement
              </div>
            )}
          </div>
          <div className="d_button_sec">
            <button
              className="button_default d_Button3"
              onClick={() => {
                // clear operation
                setNewOrEditOperation('');
                autoDriveDispatch({
                  type: SET_SHOW_IMPLEMENT,
                  payload: true,
                });
                tractorImplementState[selectedTractor.id] = false;
                autoDriveDispatch({
                  type: SET_TRACTOR_IMPLEMENT_STATE,
                  payload: tractorImplementState,
                });
              }}
            >
              Previous
            </button>
            <button
              className="button_green d_Button3"
              onClick={() => {
                setShowMixed(false);
                setNewOrEditOperation('NEW');
                dispatch({
                  type: SET_ACTIVE_AUTODRIVE_TAB,
                  payload: AutoDriveTabs.Block,
                });
                dispatch({
                  type: SET_SETUP_KEYS,
                  payload: {
                    ...setUpKeys,
                    isBlockSelected: false,
                  },
                });
                dispatch({
                  type: SET_SELECTED_FEED_LANES,
                  payload: [],
                });
                setSelectedCommand(null);
              }}
            >
              Start New
            </button>
          </div>
        </div>
      )}
      {showEditOpsList && (
        <div className="preset_operations_section">
          <div className="preset_operation_head">
            <div className="po_row">
              <h2 className="preset_head_text">Preset Operations</h2>
              <span className="done_btn" onClick={() => presetChange()}>
                Done
              </span>
            </div>
            <div className="po_row">
              <h2 className="choose_list_text">Choose from list</h2>
            </div>
          </div>
          <div className="preset_list">
            {presetOperations.map(
              (presetOperation: DairyCommand, index: number) => (
                <>
                  <div
                    className="preset_list_item"
                    draggable
                    onDragStart={(e) => onDragStart(e, index)}
                    onDragOver={onDragOver}
                    onDrop={(e) => onDrop(e, index)}
                    key={presetOperation.id}
                  >
                    <div className="item_row1">
                      <div className="star_sec">
                        <img src={dnd_equal_icon} />
                      </div>
                      <div className="item_dtl_sec">
                        <h2 className="item_line1">{presetOperation.name}</h2>
                        <p className="item_line2">
                          {presetOperation.command.lane_list.length}{' '}
                          {`${
                            presetOperation.command.lane_list.length === 1
                              ? 'lane'
                              : 'lanes'
                          }`}{' '}
                        </p>
                      </div>
                      <div
                        className="item_btn_sec on-hover-hand-change"
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowPresetDelete(true);
                          setSelectedCommand(presetOperation);
                        }}
                      >
                        <div className="delete_block">
                          <span>
                            <img src={trash_d} />
                          </span>
                          <span>Delete</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ),
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default PresetList;
