import React, { useContext, useEffect, useState } from 'react';
import Layout from 'antd/lib/layout/layout';
import { Avatar, Button, Input, message, notification, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import defaultProfile from '../../assets/images/defaultProfile.svg';
import translate from '../../locale/en_translate.json';

import { ApplicationContext } from '../../context/AppContext';
import {
  getCrops,
  updateFleetSettingData,
  updateorganization,
  updateProfileImageUpload,
} from '../../constants/Api';
import { FleetSettings } from '../../constants/types';

import AppLoader from '../common/AppLoader';
import ImageCrop from '../widget/ImageCrop';

import './style.css';

const { Option } = Select;

interface Props {
  setEditprofile: (data: any) => void;
  organizationProfile: any;
  fleetSetting: FleetSettings[];
}

const EditfarmProfile: React.FC<Props> = ({
  setEditprofile,
  organizationProfile,
  fleetSetting,
}: Props) => {
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const [firstName, setFirstName] = useState<string>(organizationProfile?.name);
  const [acreage, setAcreage] = useState<string>(
    organizationProfile?.acreage
      ? organizationProfile?.acreage + ' ' + 'Acres'
      : '',
  );
  const [address, setAddress] = useState<string>(organizationProfile?.address);
  const [building, setBuilding] = useState<string>(
    organizationProfile?.apt_or_suit_no,
  );
  const [loader, setLoader] = useState<boolean>(false);
  const [city, setCity] = useState<string>(organizationProfile?.city);
  const [state, setState] = useState<string>(organizationProfile?.state);
  const [zipcode, setZipcode] = useState<string>(organizationProfile?.zip_code);
  const [country, setCountry] = useState<string>(organizationProfile?.country);
  const [electricityrate, setElectricityRate] = useState<string>('');
  const [disselRate, setDieselRate] = useState<string>('');
  const [fileUrl, setFileUrl] = useState<any>('');
  const [fileUrlData, setfileUrlData] = useState<any>('');

  const [crops, setCrops] = useState<any[]>([]);
  const [cropsData, setData] = useState<any[]>([]);
  const [validations, setValidations] = useState<any>({
    firstName: true,
    address: true,
    city: true,
    state: true,
    zipcode: true,
    country: true,
    enabled: false,
  });

  useEffect(() => {
    return () => {
      setCrops([]);
      setCity('');
      setState('');
      setZipcode('');
      setCountry('');
      setBuilding('');
      setAcreage('');
      setFirstName('');
      setAddress('');
      setElectricityRate('');
      setDieselRate('');
    };
  }, []);

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      getProfileLookUps();
    }
  }, [userDetails]);

  useEffect(() => {
    if (fleetSetting && fleetSetting.length > 0) {
      fleetSetting.forEach((setting) => {
        if (setting.name === 'electricity_rate') {
          setElectricityRate(setting.value);
        }

        if (setting.name === 'diesel_cost') {
          setDieselRate(setting.value);
        }
      });
    }
  }, [fleetSetting]);

  const getProfileLookUps = async () => {
    const cropsData = await getCrops(
      userDetails.organization.api_url,
      'org_farm_crops',
    );
    setData(cropsData);
    const splitData = organizationProfile?.crops?.split(',');
    const res = cropsData.filter((data: any) => splitData?.includes(data.name));
    const crops = res.map(({ name }: any) => name);
    setCrops(crops);
  };
  const saveFarmProfile = () => {
    setValidations({
      ...validations,
      firstName: firstName?.trim() !== '',
      address: address?.trim() !== '',
      city: city?.trim() !== '',
      state: state?.trim() !== '',
      zipcode: zipcode?.trim() !== '',
      country: country?.trim() !== '',
      enabled: true,
    });
  };

  useEffect(() => {
    const isValid = Object.values(validations).every((item) => item);
    isValid && Object.keys(validations).length
      ? handleFormSubmit()
      : validations.enabled && message.warn('fill all required fields');
  }, [validations]);

  const handleFormSubmit = async () => {
    const payload = {
      name: firstName?.trim(),
      address: address?.trim(),
      crops: crops?.toString(),
      acreage: acreage,
      apt_or_suit_no: building?.trim(),
      city: city?.trim(),
      state: state?.trim(),
      zip_code: zipcode?.trim(),
      country: country?.trim(),
    };
    setLoader(true);
    try {
      await updateorganization(
        userDetails.organization.api_url,
        userDetails.organization.id,
        payload,
      );
      await updateFleetSetting();
      if (fileUrlData) {
        imageUpload();
      } else {
        setEditprofile(false);
        notification.success({
          message: t(translate.label.farmProfileUpdatd),
        });
      }
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    } finally {
      setLoader(false);
    }
  };

  const updateFleetSetting = async () => {
    try {
      const payload = [
        {
          name: 'electricity_rate',
          value: electricityrate,
        },
        {
          name: 'diesel_cost',
          value: disselRate,
        },
      ];
      await updateFleetSettingData(
        userDetails.organization.api_url,
        userDetails.organization.id,
        payload,
      );
    } catch (error: any) {
      notification.error({
        message: error?.message,
      });
    }
  };

  const imageUpload = async () => {
    try {
      const formData = new FormData();
      formData.append('image', fileUrlData);
      await updateProfileImageUpload(
        userDetails.organization.api_url,
        userDetails.organization.id,
        formData,
      );
      setEditprofile(false);
      notification.success({
        message: t(translate.label.farmProfileUpdatd),
      });
    } catch (err: any) {
      notification.error({
        message: err?.message,
      });
    }
  };

  return (
    <Layout>
      <div className="filters_card  personnelCard">
        <div
          className="teamarrow"
          data-testid="arrowEdit-EditFarmProfile"
          onClick={() => setEditprofile(false)}
        ></div>
        <span className="headline1 lh56">
          {t(translate.farmProfileEdit.title)}
        </span>
      </div>
      {organizationProfile && (
        <div className="profileDetails">
          <AppLoader loader={loader} />
          <div className="profPicSec editFarmIcon ">
            <div className="uploadImg proRow mr0 rowGapBtm2">
              <div className="userIcon">
                <div className="proRow proPic defaultimgicon">
                  {fileUrl !== '' ? (
                    <Avatar src={fileUrl} className="defaulticon"></Avatar>
                  ) : (
                    <>
                      {organizationProfile?.profile_url ? (
                        <Avatar
                          className="defaulticon"
                          src={organizationProfile?.profile_url}
                        ></Avatar>
                      ) : (
                        <Avatar
                          src={defaultProfile}
                          className="defaulticon"
                        ></Avatar>
                      )}
                    </>
                  )}
                </div>
                <ImageCrop
                  setFileUrl={setFileUrl}
                  setfileUrlData={setfileUrlData}
                ></ImageCrop>
              </div>
            </div>
          </div>
          <div className="profDetailSec">
            <div className="addressSec">
              <h3 className="headline3">
                {t(translate.farmProfileEdit.farmInfo)}
              </h3>
            </div>
            <div className="width448 mb15">
              <Input
                placeholder="First Name"
                data-testid="farmnameEdit-EditFarmProfile"
                defaultValue={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                className={classNames({
                  errorClass: !firstName && !validations.firstName,
                })}
              />
            </div>
            <div className="width448 mb15">
              <Input
                placeholder="Acres"
                data-testid="acresEdit-EditFarmProfile"
                defaultValue={acreage}
                onChange={(e) => setAcreage(e.target.value)}
                className={classNames({
                  errorClass: !acreage && !validations.acreage,
                })}
              />
            </div>
            <div className="width448 mb15 farmSelect">
              <Select
                mode="multiple"
                placeholder="Crops"
                data-testid="cropsDropDown-EditFarmProfile"
                style={{ width: '100%' }}
                value={crops}
                onChange={setCrops}
                maxTagCount="responsive"
              >
                {cropsData.map((ele: any) => {
                  return (
                    <Option
                      title={ele.name}
                      data-testid={`${ele.name}-CropsDropdown`}
                      value={ele?.name}
                      key={ele?.id}
                    >
                      <div className="teamName">{ele.name}</div>
                    </Option>
                  );
                })}
              </Select>
            </div>
            <div className="addressSec pt2">
              <h3 className="headline3">
                {t(translate.farmProfileEdit.address)}
              </h3>
            </div>
            <div className="width448 mb15">
              <Input
                placeholder="Street"
                data-testid="addressEdit-EditFarmProfile"
                defaultValue={address}
                onChange={(e) => setAddress(e.target.value)}
                className={classNames({
                  errorClass: !address && !validations.address,
                })}
              />
            </div>
            <div className="width448 mb15">
              <Input
                className="aptSuitBuildNo"
                placeholder="Apt, Suite, Building (Optional)"
                defaultValue={building}
                onChange={(e) => setBuilding(e.target.value)}
              />
            </div>
            <div className="width448 flexBtn mb15">
              <Input
                placeholder="City"
                data-testid="cityEdit-EditFarmProfile"
                defaultValue={city}
                onChange={(e) => setCity(e.target.value)}
                className={classNames('width216', {
                  errorClass: !city && !validations.city,
                })}
              />
              <Input
                placeholder="State"
                data-testid="stateEdit-EditFarmProfile"
                defaultValue={state}
                onChange={(e) => setState(e.target.value)}
                className={classNames('width80', {
                  errorClass: !state && !validations.state,
                })}
              />

              <Input
                placeholder="Zipcode"
                data-testid="zipcodeEdit-EditFarmProfile"
                defaultValue={zipcode}
                onChange={(e) => setZipcode(e.target.value)}
                className={classNames('width122', {
                  errorClass: !zipcode && !validations.zipcode,
                })}
              />
            </div>
            <div className="width448 mb15">
              <Input
                placeholder="Country"
                data-testid="countryEdit-EditFarmProfile"
                defaultValue={country}
                onChange={(e) => setCountry(e.target.value)}
                className={classNames({
                  errorClass: !country && !validations.country,
                })}
              />
            </div>
            <div className="addressSec pt2">
              <h3 className="headline3">
                {t(translate.farmProfileEdit.fleetSettings)}
              </h3>
            </div>
            <div className="width448 mb15">
              <label>{t(translate.farmProfileEdit.electricityRate)}</label>

              <Input
                placeholder="Electricity Rate"
                data-testid="electricyEdit-EditFarmProfile"
                defaultValue={electricityrate}
                onChange={(e) => setElectricityRate(e.target.value)}
                className={classNames({
                  errorClass: !electricityrate && !validations.electricityrate,
                })}
                type="number"
                value={electricityrate}
              />
            </div>
            <div className="width448 mb15">
              <label>{t(translate.farmProfileEdit.dieselCost)}</label>
              <Input
                placeholder="Diesel Cost"
                data-testid="dieselEdit-EditFarmProfile"
                defaultValue={disselRate}
                onChange={(e) => setDieselRate(e.target.value)}
                className={classNames({
                  errorClass: !disselRate && !validations.disselRate,
                })}
                type="number"
                value={disselRate}
              />
            </div>
          </div>
          <Button
            key="submit"
            onClick={saveFarmProfile}
            className="btnSaveFarm farmSaveBtn"
            data-testid="saveButton-EditFarmProfile"
          >
            {t(translate.buttons.save)}
          </Button>
        </div>
      )}
    </Layout>
  );
};

export default EditfarmProfile;
