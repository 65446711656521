import React, { useContext, useEffect, useRef, useState } from 'react';
import ViewItem from '../common/ViewItem';
import CommentsView from './CommentsView';
import { useTranslation } from 'react-i18next';
import { getTicketInfo, resolveTicketSync } from '../API';
import { ApplicationContext } from '../../../context/AppContext';
import translate from '../../../locale/en_translate.json';
import { getDateTimes } from '../../../constants/Common';
import copyIcon from '../../../assets/images/copy_icon.png';
import { checkCanresolveTickets, getSeverityIcon } from '../common/common';
import closeGreen from '../../../assets/images/right_widget_close_icon.svg';
import { Button, notification, Space, Tag } from 'antd';
import ResolvedModal from './ResolvedModal';
import EditTicket from './EditTicket';

interface Props {
  ticketId: string;
  toggleWidget: boolean;
  onClose: (state?: boolean) => void;
}

const TicketDetails: React.FC<Props> = ({
  ticketId,
  toggleWidget,
  onClose,
}) => {
  const { t } = useTranslation();
  const [details, setDetails] = useState<any>(null);
  const { userDetails, dealerDetails } = useContext(ApplicationContext);
  const [loader, setLoader] = useState<boolean>(false);
  const [resolveModal, setResolveModal] = useState<boolean>(false);
  const [editComment, setEditComment] = useState<boolean>(false);
  const [commentId, setCommentId] = useState<any>();
  const [showEditTicketModal, setShowEditTicketModal] =
    useState<boolean>(false);
  const [selectedTicketById, setSelectedTicketById] = useState(0);
  const [resolveTickets, setResolveTickets] = useState<boolean>(false);
  const init = useRef<boolean>(false);

  const copyToClipBoard = (data: any) => {
    navigator.clipboard.writeText(data);
  };

  const latLongData = (latLong: number) => {
    const latLongMsg =
      latLong === details?.ticket_more_information?.location_latlong?.latitude
        ? 'Latitude copied'
        : 'Longitude copied';
    return (
      <span className="mr20">
        <Tag>{latLong}</Tag>
        {!!latLong && (
          <img
            src={copyIcon}
            onClick={() => {
              copyToClipBoard(latLong);
              notification.info({
                message: latLongMsg,
              });
            }}
            className="marginLeftIcon cursor-pointer"
          />
        )}
      </span>
    );
  };

  const getTicketDetails = async () => {
    try {
      setLoader(true);
      if (ticketId) {
        const response = await getTicketInfo(ticketId);
        setDetails(response);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error', error);
    } finally {
      if (!init.current) init.current = true;
      setLoader(false);
    }
  };

  useEffect(() => {
    if (userDetails.organization) {
      getTicketDetails();
    }

    if (dealerDetails?.group_list !== null) {
      setResolveTickets(checkCanresolveTickets(dealerDetails));
    }
  }, [ticketId]);

  const resolveTicket = async (payloadStatus: any) => {
    const payload = payloadStatus;
    try {
      setLoader(true);
      const response = await resolveTicketSync(details?.id, payload);
      getTicketDetails();
      notification.success({
        message: response.msg,
      });
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    } finally {
      setLoader(false);
    }
  };

  const resolveModalDetails = () => {
    setSelectedTicketById(details?.id);
    setResolveModal(true);
  };
  const handleInProgressTicket = () => {
    if (details.nexus_ticket_status !== 'done' && resolveTickets) {
      resolveTicket({ ticket_status: 'done' });
    } else {
      resolveModalDetails();
    }
  };

  const closeEditTicketModal = (state = false) => {
    setShowEditTicketModal(false);
  };

  return (
    <div className={'rightSideWidegt ' + (toggleWidget ? 'expand' : 'hide')}>
      <div className="widgetCloseBtn flexBtn">
        <img
          src={closeGreen}
          alt="close icon"
          onClick={() => onClose()}
          data-testid="modelCloseIcon-TicketViewEdit"
        />
      </div>
      <div className="profileEdit widget_card">
        <div className="proRow">
          <h4 className="posRel  headline3">
            <span>Ticket #{ticketId}</span>
          </h4>
        </div>
        <ViewItem
          title={t(translate.supportTicket.ticketNumber)}
          content={details?.atom_ticket_id}
        />
        <ViewItem
          title={t(translate.supportTicket.ticketNumber)}
          content={
            details?.created_date_time
              ? getDateTimes(details.created_date_time)
              : '-'
          }
        />
        <ViewItem
          title={t(translate.supportTicket.equipment)}
          content={details?.equipment ? details?.equipment?.name : 'Other'}
        />
        <ViewItem
          title={t(translate.supportTicket.status)}
          content={
            details?.status === 'inprogress' ? 'In Progress' : details?.status
          }
        />
        <ViewItem
          title={t(translate.supportTicket.ticketPriority)}
          content={
            details?.ticket_priority === 0
              ? 'P0'
              : details?.ticket_priority === 1
              ? 'P1'
              : details?.ticket_priority === 2
              ? 'P2'
              : details?.ticket_priority === 3
              ? 'P3'
              : '-'
          }
        />
        <ViewItem
          title={t(translate.supportTicket.severity)}
          content={
            <Space>
              <img
                className="mr8"
                src={getSeverityIcon(details?.ticket_level, 1)}
                alt="sort"
              />
              <img
                className="mr8"
                src={getSeverityIcon(details?.ticket_level, 2)}
                alt="sort"
              />
              <img
                className="mr8"
                src={getSeverityIcon(details?.ticket_level, 3)}
                alt="sort"
              />
            </Space>
          }
        />
        <ViewItem
          title={t(translate.supportTicket.incidentTime)}
          content={
            details?.issue_occurred_date_time
              ? getDateTimes(details.issue_occurred_date_time)
              : '-'
          }
        />
        <ViewItem
          title={t(translate.supportTicket.tractorOperational)}
          content={details?.tractor_operational ? 'Yes' : 'No'}
        />
        <ViewItem
          title={t(translate.supportTicket.ticketDescription)}
          content={details?.ticket_description}
        />
        <ViewItem
          title={t(translate.supportTicket.tags)}
          content={
            details?.meta_data?.Tags && details?.meta_data?.Tags.length > 0
              ? details?.meta_data?.Tags.map((tag: any) => (
                  <Tag color="blue" key={tag} style={{ marginBottom: 4 }}>
                    {tag}
                  </Tag>
                ))
              : '-'
          }
        />
        <hr />
        <ViewItem
          title={t(translate.supportTicket.group)}
          content={details?.group_id ? details?.group_id?.name : '-'}
        />
        <ViewItem
          title={t(translate.supportTicket.Assignee)}
          content={
            details?.assignee
              ? `${details?.assignee?.first_name} ${details?.assignee?.last_name}`
              : '-'
          }
        />
        <ViewItem
          title={t(translate.supportTicket.nextUp)}
          content={
            details?.ticket_next_up_information !== null &&
            details?.ticket_next_up_information?.length > 0
              ? details?.ticket_next_up_information
                  ?.split(',')
                  .map((nextUp: any) => (
                    <Tag color="blue" key={nextUp} style={{ marginBottom: 4 }}>
                      {nextUp}
                    </Tag>
                  ))
              : '-'
          }
        />
        <hr />
        <ViewItem
          title={t(translate.supportTicket.ticketCreator)}
          content={
            details?.meta_data?.monarch_service_user
              ? `${details?.meta_data?.monarch_service_user?.first_name} ${details?.meta_data?.monarch_service_user?.last_name}`
              : details?.meta_data?.reported_by_user
              ? `${details?.meta_data?.reported_by_user?.first_name} ${details?.meta_data?.reported_by_user?.last_name}`
              : '-'
          }
        />
        <ViewItem
          title={t(translate.supportTicket.ticketCreatorsEmail)}
          content={
            details?.meta_data?.monarch_service_user
              ? details?.meta_data?.monarch_service_user?.username
              : details?.meta_data?.reported_by_user?.username || '-'
          }
        />
        <ViewItem
          title={t(translate.supportTicket.ticketCreatorsPhonenumber)}
          content={details?.meta_data?.reported_by_user?.contact_number || '-'}
        />
        <ViewItem
          title={t(translate.supportTicket.ticketCreatorsTeam)}
          content={
            details &&
            details?.meta_data?.teams_list &&
            details?.meta_data?.teams_list.length > 0
              ? details?.meta_data?.teams_list.map((teams: any) => (
                  <Tag
                    color="#808080de"
                    key={teams}
                    style={{ marginBottom: 4 }}
                  >
                    {teams}
                  </Tag>
                ))
              : '-'
          }
        />
        <hr />
        <ViewItem
          title={t(translate.supportTicket.equipment)}
          content={details?.equipment ? details?.equipment?.name : 'Other'}
        />
        <ViewItem
          title={t(translate.supportTicket.implementName)}
          content={details?.ticket_more_information?.implement_name || '-'}
        />
        <ViewItem
          title={t(translate.supportTicket.soc)}
          content={details?.ticket_more_information?.soc || '-'}
        />
        <ViewItem
          title={t(translate.supportTicket.operationMode)}
          content={details?.atom_ticket_id}
        />
        <ViewItem
          title={t(translate.supportTicket.tractorOperational)}
          content={details?.tractor_operational ? 'Yes' : 'No'}
        />
        <ViewItem
          title={t(translate.supportTicket.tractorLocation)}
          content={
            details?.ticket_more_information ? (
              <div className="latLogStyle">
                {latLongData(
                  details?.ticket_more_information?.location_latlong?.latitude,
                )}
                {latLongData(
                  details?.ticket_more_information?.location_latlong?.longitude,
                )}
              </div>
            ) : (
              '-'
            )
          }
        />
        <ViewItem
          title={t(translate.supportTicket.resolutionType)}
          content={details?.resolution_type || '-'}
        />
        <ViewItem
          title={t(translate.supportTicket.resolvedBy)}
          content={details?.resolved_by || '-'}
        />
        <ViewItem
          title={t(translate.supportTicket.repairType)}
          content={details?.repair_type || '-'}
        />
        <ViewItem
          title={t(translate.supportTicket.repairLocation)}
          content={details?.repair_location || '-'}
        />
        <ViewItem
          title={t(translate.supportTicket.travelRequired)}
          content={details?.travel_required ? 'Yes' : 'No'}
        />
        <ViewItem
          title={t(translate.supportTicket.warrantyRepair)}
          content={details?.warranty_repair ? 'Yes' : 'No'}
        />
        <ViewItem
          title={t(translate.supportTicket.resolutionDescription)}
          content={details?.resolution_description || '-'}
        />
        <CommentsView
          selectedTicketId={details?.id}
          data={details}
          setEditComment={setEditComment as any}
          setCommentId={setCommentId}
          setSelectedTicketById={setSelectedTicketById as any}
          onClose={() => getTicketDetails()}
        />
      </div>
      <>
        <div className="create_ticket_btn_sec dlSaveEdit">
          {details && details.status === 'open' && init.current && (
            <Button
              className="ant-btn-primary btn-create tkt_status_btn"
              onClick={() => resolveTicket({ ticket_status: 'inprogress' })}
            >
              {t(translate.serviceticket.inProgress)}
            </Button>
          )}
          {details &&
          details?.status === 'inprogress' &&
          resolveTickets &&
          init.current ? (
            <Button
              className="ant-btn-primary btn-create tkt_status_btn"
              onClick={handleInProgressTicket}
            >
              {details?.nexus_ticket_status !== 'done'
                ? `${t(translate.serviceticket.done)}`
                : `${t(translate.serviceticket.resolved)}`}
            </Button>
          ) : (
            details?.nexus_ticket_status !== 'done' &&
            details?.status !== 'open' &&
            details?.status !== 'completed' &&
            init.current && (
              <Button
                className="ant-btn-primary btn-create tkt_status_btn "
                onClick={() => resolveTicket({ ticket_status: 'done' })}
              >
                {t(translate.serviceticket.done)}
              </Button>
            )
          )}
          {details?.status !== 'completed' && (
            <Button
              className="editStyle btnteamCancel"
              onClick={() => setShowEditTicketModal(true)}
            >
              {t(translate.buttons.edit)}
            </Button>
          )}
        </div>
      </>
      {resolveModal && (
        <ResolvedModal
          showModal={resolveModal}
          closeModal={() => {
            setResolveModal(false);
          }}
          selectedTicketId={selectedTicketById}
          getTicketDetails={() => getTicketDetails()}
          dataList={details}
        />
      )}
      {showEditTicketModal && (
        <EditTicket
          showModal={showEditTicketModal}
          closeModal={closeEditTicketModal}
          selectedTicketId={details?.id}
          ticketDetails={details}
        />
      )}
    </div>
  );
};

export default TicketDetails;
