import React, { useContext, useState } from 'react';
import { ApplicationContext } from '../../../context/AppContext';
import { Tabs } from 'antd';
import OrgTractorUsageReport from './OrgTractorUsageReport';
import TractorUsageReport from './TractorUsageReport';
// import TractorHealth from '../../admin/dealer/Planning/TractorHealth';

const { TabPane } = Tabs;

const TractorUsageTabs = () => {
  const { APPReducer } = useContext(ApplicationContext);
  const [, dispatch] = APPReducer;
  const [tabid, setTabid] = useState<string>('organization');

  return (
    <Tabs
      activeKey={tabid}
      centered
      id="org-tab-Organization"
      className="inventoryTabs"
      onChange={(key) => {
        setTabid(key);
      }}
    >
      <TabPane
        tab="Organizations"
        key="organization"
        className="attTableHeight mBottom0"
      >
        {tabid === 'organization' && <OrgTractorUsageReport />}
      </TabPane>
      <TabPane
        tab="Tractors"
        key="tractors"
        className="attTableHeight mBottom0"
      >
        {tabid === 'tractors' && <TractorUsageReport />}
      </TabPane>
    </Tabs>
  );
};

export default TractorUsageTabs;
