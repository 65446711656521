/* eslint-disable react/display-name */
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  Button,
  Card,
  Collapse,
  DatePicker,
  Empty,
  message,
  notification,
  Row,
  Table,
} from 'antd';
import Layout, { Content } from 'antd/lib/layout/layout';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { ApplicationContext } from '../../../context/AppContext';
import usePaginate from '../../../hooks/usePaginate';
import translate from '../../../locale/en_translate.json';
import {
  delay,
  downloadURI,
  fromToDateWrapper,
  initScroller,
} from '../../../constants/Common';
import TableExt from '../../widget/TableExt';
import InfiniteScrollTable from '../../common/InfiniteScrollTable';
import { downloadCSATReport, getcsatReport } from '../API';
import { mapNexusCSATReportData } from '../../../lib/dataFormat';
import OrgTypeFilter from '../common/OrgTypeFilter';

const { Panel } = Collapse;

const { RangePicker } = DatePicker;

let columns: any[] = [];

const UsageDashboardReport = () => {
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const [loader, setLoader] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize] = useState<number>(25);
  const [headers, setHeaders] = useState<any>();
  const [total, setTotal] = useState<number>(0);
  const [dealers, setDealers] = useState<string[]>([]);
  const [fromtime, setfromTime] = useState<any>(
    moment().subtract(5, 'day').toDate(),
  );
  const [totime, settoTime] = useState<any>(moment().toDate());
  const [range, setRange] = useState<number>(7);
  const tableRef = useRef<any>(null);
  const { filterData, setData, activity, hasMore, checkActivity } =
    usePaginate();
  const init = useRef<boolean>(false);
  const [summary, setSummary] = useState<any>(null);
  const [cummulative, setCummulative] = useState<any>({});
  const [downloadData, setDownloadData] = useState({
    isDownloaded: false,
    percent: 0,
    status: '',
  });
  const [defautlTab, setDefaultTab] = useState<string | string[]>([]);
  const [selectedOrg, setSelectedOrg] = useState<any>([]);
  const [toggleColumnsStatus, setToggleColumnsStatus] =
    useState<boolean>(false);

  const toHours = (min: number) => Math.floor(min ? min / 60 : 0);

  const getTotalHours = (arr: number[] | number) => {
    const [mins] = Array.isArray(arr) ? arr : [arr];
    const hours = toHours(Number(mins));
    const minutes = mins ? Number(mins) % 60 : 0;
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(
      2,
      '0',
    )}`;
  };

  const render = (value: number) => getTotalHours(value);

  columns = useMemo(
    () => [
      {
        title: `${t(translate.csatReport.customer_name)}`,
        dataIndex: 'customer_name',
        key: 'customer_name',
        sorter: {
          compare: (a: any, b: any) =>
            a.customer_name.localeCompare(b.customer_name),
        },
      },
      {
        title: `${t(translate.csatReport.numoftractors)}`,
        dataIndex: 'number_of_tractors',
        key: 'number_of_tractors',
        sorter: (a: any, b: any) => a.number_of_tractors - b.number_of_tractors,
      },
      {
        title: `${t(translate.csatReport.drivingHourspertractor)} (Hrs)`,
        dataIndex: 'driving_hours_per_tractor',
        key: 'driving_hours_per_tractor',
        sorter: {
          compare: (a: any, b: any) =>
            a.driving_hours_per_tractor - b.driving_hours_per_tractor,
        },
        render,
      },
      {
        title: 'Total Driving Hours',
        children: [
          {
            title: `last ${range} days (Hrs)`,
            dataIndex: 'last_7_days',
            key: 'last_7_days',
            sorter: {
              compare: (a: any, b: any) => a.last_7_days - b.last_7_days,
            },
            render,
          },
          {
            title: `prior ${range} days (Hrs)`,
            dataIndex: 'prior_7_days',
            key: 'prior_7_days',
            sorter: {
              compare: (a: any, b: any) => a.prior_7_days - b.prior_7_days,
            },
            render,
          },
          {
            title: `penultimate ${range} days (Hrs)`,
            dataIndex: '3_weeks_ago',
            key: '3_weeks_ago',
            sorter: {
              compare: (a: any, b: any) => a['3_weeks_ago'] - b['3_weeks_ago'],
            },
            render,
          },
        ],
      },
      {
        title: `Total Usage in Last ${range} Days`,
        children: [
          {
            title: 'Manual Hours',
            dataIndex: 'manual_hours',
            key: 'manual_hours',
            sorter: {
              compare: (a: any, b: any) => a.manual_hours - b.manual_hours,
            },
            render,
          },
          {
            title: 'AutoDrive Hours',
            dataIndex: 'autodrive_hours',
            key: 'autodrive_hours',
            sorter: {
              compare: (a: any, b: any) =>
                a.autodrive_hours - b.autodrive_hours,
            },
            render,
          },
          {
            title: 'Row Follow Hours',
            dataIndex: 'row_follow_hours',
            key: 'row_follow_hours',
            sorter: {
              compare: (a: any, b: any) =>
                a.row_follow_hours - b.row_follow_hours,
            },
            render,
          },
          {
            title: 'Idle Hours',
            dataIndex: 'idle_hours',
            key: 'idle_hours',
            sorter: {
              compare: (a: any, b: any) => a.idle_hours - b.idle_hours,
            },
            render,
          },
          {
            title: 'Fleet utilization',
            dataIndex: 'fleet_utilization',
            key: 'fleet_utilization',
            sorter: {
              compare: (a: any, b: any) =>
                a.fleet_utilization - b.fleet_utilization,
            },
          },
          {
            title: 'Count of Tractors < 30 minutes',
            dataIndex: 'count_of_tractors',
            key: 'count_of_tractors',
            sorter: {
              compare: (a: any, b: any) =>
                b.count_of_tractors - a.count_of_tractors,
            },
          },
        ],
      },
      {
        title: 'Nexus Issues',
        children: [
          {
            title: 'High',
            dataIndex: 'level_3_tickets',
            key: 'level_3_tickets',
            sorter: {
              compare: (a: any, b: any) =>
                a.level_3_tickets - b.level_3_tickets,
            },
          },
          {
            title: 'Medium',
            dataIndex: 'level_2_tickets',
            key: 'level_2_tickets',
            sorter: {
              compare: (a: any, b: any) =>
                a.level_2_tickets - b.level_2_tickets,
            },
          },
          {
            title: 'Low',
            dataIndex: 'level_1_tickets',
            key: 'level_1_tickets',
            sorter: {
              compare: (a: any, b: any) =>
                a.level_1_tickets - b.level_1_tickets,
            },
          },
        ],
      },
    ],
    [range],
  );

  const wrapResult = async (
    fromtime: string,
    totime: string,
    pageNumber: number,
    pageSize: number,
    selectedOrg: string[],
  ) => {
    const result = await getcsatReport(
      fromtime,
      totime,
      pageNumber,
      pageSize,
      selectedOrg,
    );
    return {
      result,
      customer_org_id: selectedOrg,
    };
  };

  const getCSATReport = async () => {
    try {
      if (checkActivity()) initScroller();
      setLoader(true);
      const fromDate = moment(fromtime).format('YYYY-MM-DD');
      const toDate = moment(totime).format('YYYY-MM-DD');
      const { result } = await wrapResult(
        fromDate || '',
        toDate || '',
        pageNumber,
        pageSize,
        selectedOrg,
      );

      if (result?.analytics_data) setTotal(result?.analytics_data?.length);
      const { analytics_data, summary, cumulative_data } = result;
      let data = Array.isArray(analytics_data) ? analytics_data : [];
      data = mapNexusCSATReportData(data);
      setData(data);
      setSummary(summary);
      setCummulative(cumulative_data || {});
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    } finally {
      if (!init.current) init.current = true;
      setLoader(false);
    }
  };

  const loadCsat = () => {
    if (!userDetails?.organization || selectedOrg.length === 0) return;
    if (checkActivity()) {
      pageNumber !== 1 ? setPageNumber(1) : getCSATReport();
    } else if (activity.current === 'paginate' || activity.current === '') {
      getCSATReport();
    }
  };

  const handleLoadMore = () => {
    if (pageNumber === 1 && checkActivity() && document) initScroller();

    activity.current = 'paginate';
    setPageNumber(pageNumber + 1);
  };

  const handleRefresh = () => {
    activity.current = 'refresh';
    loadCsat();
  };

  const handleDownload = async () => {
    try {
      if (downloadData.status === 'start') return;
      setDownloadData({
        ...downloadData,
        status: 'start',
        percent: 10,
      });
      const fromDate = moment(fromtime).format('YYYY-MM-DD');
      const toDate = moment(totime).format('YYYY-MM-DD');
      const { csat_file_url } = await downloadCSATReport(
        selectedOrg,
        fromDate || '',
        toDate || '',
        dealers || '',
      );
      setDownloadData({
        ...downloadData,
        percent: 100,
        status: 'success',
      });
      await delay(1000); // Wait for the state change before download
      downloadURI(csat_file_url, 'CSATReport.csv');
    } catch (error: any) {
      setDownloadData({
        ...downloadData,
        status: 'exception',
      });
      notification.error({ message: error.message });
    }
  };

  const onDateRangeChange = (dates: any) => {
    activity.current = 'filter';
    if (dates) {
      const [from, to] = fromToDateWrapper(dates);
      setfromTime(from.toDate());
      settoTime(to.toDate());
    } else {
      setfromTime(null);
      settoTime(null);
    }
  };

  function disabledDate(current: any) {
    return current > moment() || current < moment().subtract(180, 'day');
  }

  const ItemLabel = (name: string) => <div className="text-center">{name}</div>;

  const handleRange = () => {
    const numDays = moment(totime).diff(moment(fromtime), 'days');
    setRange(numDays + 1);
  };

  const onChange = (key: string | string[]) => {
    setDefaultTab(key);
  };

  const handleSubmit = () => {
    if (selectedOrg.length) {
      handleRange();
      handleRefresh();
    } else {
      message.warn('Select Oragnization');
    }
  };

  const getkeyData = (key: string) =>
    filterData.filter((item: any) => item[key]).map((item: any) => item[key]);

  const getTotal = (key: string) =>
    getkeyData(key).reduce((a: number, c: number) => {
      // eslint-disable-next-line no-param-reassign
      a += c;
      return a;
    }, 0);

  useEffect(() => {
    const header: any = [];
    if (columns) {
      columns.forEach((column: any) =>
        header.push({
          label: column.title,
          key: column.dataIndex,
        }),
      );
      setHeaders(header);
    }
  }, [columns]);

  useEffect(() => {
    loadCsat();
  }, [userDetails, pageNumber]);

  useEffect(() => {
    if (selectedOrg.length && !init.current) loadCsat();
  }, [selectedOrg]);

  const SummaryData = useMemo(
    () => () =>
      (
        <Table.Summary fixed>
          <Table.Summary.Row>
            <Table.Summary.Cell index={0}>Grand Total</Table.Summary.Cell>
            <Table.Summary.Cell index={1}>
              {getTotal('number_of_tractors')}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={2}>
              {getTotalHours(cummulative?.driving_hours_per_tractor)}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={3}>
              {getTotalHours(cummulative?.last_7_days)}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={4}>
              {getTotalHours(cummulative?.prior_7_days)}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={5}>
              {getTotalHours(cummulative['3_weeks_ago'])}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={6}>
              {getTotalHours(cummulative?.manual_hours)}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={7}>
              {getTotalHours(cummulative?.autonomy_hours)}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={8}>
              {getTotalHours(cummulative?.ADAS_row_follow_hours)}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={9}>
              {getTotalHours(cummulative?.idle_hours)}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={10}>- </Table.Summary.Cell>
            <Table.Summary.Cell index={11}>
              {getTotalHours(cummulative?.routines_autodrive_shadow_hours)}
            </Table.Summary.Cell>

            <Table.Summary.Cell index={12}>
              {cummulative?.level_3_tickets}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={13}>
              {cummulative?.level_2_tickets}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={14}>
              {cummulative?.level_1_tickets}
            </Table.Summary.Cell>
          </Table.Summary.Row>
        </Table.Summary>
      ),
    [filterData, cummulative],
  );
  const handleOrg = (ids: string[]) => {
    setSelectedOrg(ids);
  };
  return (
    <Layout className="posRel">
      <Content>
        <div
          className="tblContainer viewportContainer"
          onClick={() => setToggleColumnsStatus(!toggleColumnsStatus)}
        >
          <Row>
            <div className="common_wrapper tab2ColumnFilterWidget_wrapper">
              <div className="filters_card ad_filters_card filtersHealthScreen">
                <OrgTypeFilter
                  handleOrgs={handleOrg}
                  setDealers={setDealers}
                  isflag={true}
                />
                <RangePicker
                  format="MM-DD-YYYY"
                  style={{ width: '230px' }}
                  onChange={(dates: any) => {
                    onDateRangeChange(dates);
                  }}
                  defaultValue={[moment(fromtime), moment(totime)]}
                  disabledDate={disabledDate}
                />
                <Button
                  className="submitBtn"
                  onClick={handleSubmit}
                  loading={loader}
                >
                  {t(translate.buttons.submit)}
                </Button>
                <TableExt
                  handleRefresh={handleRefresh}
                  handleDownload={handleDownload}
                  downloadData={downloadData}
                  tableRef={tableRef}
                />
              </div>
              <div className="us_scroll">
                {' '}
                <Collapse
                  onChange={onChange}
                  activeKey={defautlTab}
                  className="drivingHoursSection"
                  expandIconPosition="right"
                >
                  <Panel header="Driving Hours" key="1">
                    {summary ? (
                      <Card className="mb20">
                        <div className="csatDashboard">
                          <div className="drivingHours">
                            <div>
                              <div className="bold">
                                {t(translate.csatReport.drivingHours)}
                              </div>
                              <div>0 {t(translate.csatReport.hours)}</div>
                              <div> &lt; 1{t(translate.csatReport.hour)}</div>
                              <div>01-10 {t(translate.csatReport.hours)}</div>
                              <div>11-30 {t(translate.csatReport.hours)}</div>
                              <div>&gt; 30 {t(translate.csatReport.hours)}</div>
                            </div>
                            <div className="text-center">
                              <div className="bold">{`Last ${range} Days Customers`}</div>
                              {ItemLabel(summary?.total_zero_hours_customer)}
                              {ItemLabel(
                                summary?.less_than_zero_hours_customers,
                              )}
                              {ItemLabel(summary['1_10_hour_customers'])}
                              {ItemLabel(summary['11_30_hour_customers'])}
                              {ItemLabel(
                                summary?.greater_than_30_hour_customers,
                              )}
                            </div>
                            <div className="text-center">
                              <div className="bold">
                                {`Last ${range} Days % of Total`}
                              </div>
                              {ItemLabel(
                                summary?.total_zero_hours_customer_percent,
                              )}
                              {ItemLabel(
                                summary?.less_than_zero_hours_customers_percent,
                              )}
                              {ItemLabel(
                                summary['1_10_hour_customers_percent'],
                              )}
                              {ItemLabel(
                                summary['11_30_hour_customers_percent'],
                              )}
                              {ItemLabel(
                                summary?.greater_than_30_hour_customers_percent,
                              )}
                            </div>
                          </div>
                          <div className="trendingData">
                            <div className="text-center">
                              <div className="bold">
                                0 {t(translate.csatReport.hoursTrend)}
                              </div>
                              <div>{`Last ${range} days`}</div>
                              <div>{`Prior ${range} days`}</div>
                              <div>{`penultimate ${range} days`}</div>
                            </div>
                            <div className="text-center">
                              <div className="bold">Count</div>
                              {ItemLabel(summary?.zero_hour_trend_last_7_days)}
                              {ItemLabel(summary?.zero_hour_trend_proir_7_days)}
                              {ItemLabel(summary?.zero_hour_trend_3_weeks_ago)}
                            </div>
                          </div>
                          <div>&nbsp;</div>
                        </div>
                      </Card>
                    ) : (
                      <Empty />
                    )}
                  </Panel>
                </Collapse>
              </div>
              <div className="common_table fullHeight">
                <InfiniteScrollTable
                  columns={columns}
                  loading={loader}
                  handleLoadMore={handleLoadMore}
                  hasMore={hasMore}
                  filterData={filterData}
                  totalcount={total}
                  filename="Reports"
                  summary={SummaryData}
                  xScroll={3000}
                />
              </div>
            </div>
          </Row>
        </div>
      </Content>
    </Layout>
  );
};

export default UsageDashboardReport;
