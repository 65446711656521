import React, { useContext, useEffect, useState } from 'react';
import { Button, Modal, notification } from 'antd';
import CustomTextArea from '../common/CustomTextArea';
import translate from '../../locale/en_translate.json';
import { ApplicationContext } from '../../context/AppContext';
import { useTranslation } from 'react-i18next';
import { UpdateStatus } from '../../constants/Api';
import AppLoader from '../common/AppLoader';
import tractorAnalyticDetailsStore from '../operationalAnalytics/store/tractorAnalyticDetails';

interface Props {
  TickObj: any;
  statusLabel: any;
  resolveCancel: () => void;
  ticketObj: any;
  setStatusModal: (data: boolean) => void;
  SetStatusLable: (data: any) => void;
  getTicketById: () => void;
  Close: (action?: string) => void;
  editFlag?: boolean;
}

const ResolveTicketModal: React.FC<Props> = ({
  TickObj,
  statusLabel,
  resolveCancel,
  ticketObj,
  setStatusModal,
  SetStatusLable,
  getTicketById,
  Close,
  editFlag,
}: Props) => {
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const { ticketsData, addTicketsData } = tractorAnalyticDetailsStore();
  const [loader, setLoader] = useState<boolean>(false);
  const [descriptionCount, setDescriptionCount] = useState<number>(0);
  const [description, setDescription] = useState<any>();

  useEffect(() => {
    if (description !== undefined) setDescriptionCount(description.length);
  }, [description]);

  const StatusOnChange = () => {
    const status = statusLabel === 'Unresolved' ? true : false;
    if (status) {
      UpdateTicketStatus('completed');
    } else {
      UpdateTicketStatus('inprogress');
    }
  };

  const UpdateTicketStatus = async (status: any) => {
    const obj: any = {
      status: status,
    };
    if (description) {
      obj.reason_message = description.trim();
    }

    try {
      setLoader(true);
      await UpdateStatus(
        userDetails.organization.api_url,
        userDetails.organization.fleet.id,
        ticketObj.id,
        obj,
      );
      setStatusModal(false);
      if (status === 'completed') {
        SetStatusLable('Resolved');
      } else {
        SetStatusLable('Unresolved');
      }
      notification.success({
        message: t(translate.label.ticketStatus),
        duration: 2,
      });
      if (editFlag) {
        Close('update');
      } else {
        getTicketById();
      }
    } catch (err: any) {
      notification.error({
        message: err.message,
        duration: 2,
      });
    } finally {
      setLoader(false);
    }
  };

  return (
    <Modal
      visible={true}
      onCancel={resolveCancel}
      width={1000}
      closable={false}
      bodyStyle={{ height: 'auto' }}
      className="modalContainer profileUpdatePopup implementPopup"
      footer={[
        <>
          <Button
            data-testid="resolveCancelButton-ResolveTicketModal"
            key="back"
            className="impCancelBtn Button2"
            onClick={() => resolveCancel()}
          >
            {t(translate.buttons.cancel)}
          </Button>
          <Button
            data-testid="resolveConfirmButton-ResolveTicketModal"
            key="submit"
            className="impDiscardBtn Button2"
            onClick={() => StatusOnChange()}
          >
            {statusLabel === 'Resolved' ? 'Unresolve' : 'Confirm'}
          </Button>
        </>,
      ]}
    >
      <div className="modalBox flexnone">
        <div className="formColW">
          <div className="userIcon">
            <h4 className="headline3">
              {t(translate.tickets.resolveTicket)} {TickObj?.ticket_id}
            </h4>
            <p className="body2">
              {t(translate.tickets.confirmResolve)}{' '}
              {statusLabel === 'Resolved' ? 'unresolve' : 'resolve'}{' '}
              {t(translate.tickets.thisTicket)}
            </p>

            <div className="mt24 filters_card selectLabel textAreaPlaceHolder">
              <CustomTextArea
                label="Description"
                dataTestid="resolveDescriptionInputField-ResolveTicketModal"
                value={description}
                setValue={setDescription}
                description={description}
                descriptionCount={descriptionCount}
              />
              <AppLoader loader={loader} />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ResolveTicketModal;
