import React, { useContext, useEffect, useRef, useState } from 'react';
import sortDown from '../../assets/images/sort_down.svg';
import sortUp from '../../assets/images/sort_up.svg';
import { track } from '../../util/logger';
import { Row, notification, Space, Input, Tag } from 'antd';
import Layout, { Content } from 'antd/lib/layout/layout';
import { getTicketsHistory, getOrgEquipments } from '../../constants/Api';
import { ApplicationContext } from '../../context/AppContext';
import {
  fromToDateWrapper,
  initClearSelection,
  initScroller,
} from '../../constants/Common';
import { useTranslation } from 'react-i18next';
import translate from '../../locale/en_translate.json';
import 'react-datepicker/dist/react-datepicker.css';
import InfiniteScrollTable from '../common/InfiniteScrollTable';

import plusIconNew from '../../assets/images/plusIconNew.svg';
import FlagGrayIcon from '../../assets/images/flag_gray.svg';
import FlagOrangeIcon from '../../assets/images/flag_orange.svg';
import FlagRedIcon from '../../assets/images/flag_red.svg';
import TicketsCreateEdit from './TicketsCreateEdit';
import TicketViewEdit from './TicketViewEdit';
import applicationIds from '../../locale/applicationIds.json';
import { FleetHealthCxt } from '../fleethealth/FleetHealthContext';
import { RESET_NAVIGATE } from '../../context/actions';
import { privilagesConstants } from '../../constants/Privilages';
import { speCharRegX } from '../../constants/constant';
import { SearchOutlined } from '@ant-design/icons';
import CustomSelect from '../common/CustomSelect';
import CustomDate from '../common/CustomDate';
import CustomSeverityLevel from '../common/CustomSeverityLevel';
import TicketSummary from './TicketSummary';
import { resolveFilterData } from '../../constants/AppData';
import CustomSelect1 from '../common/CustomSelect1';
import { mapTickets } from '../../lib/dataFormat';
import usePaginate from '../../hooks/usePaginate';
import './TicketsStyle.css';

interface TractorResponseType {
  label: string;
  value: number | string | boolean;
  disabled: boolean;
  index: number | string;
}

type Props = {
  createTicket?: boolean;
  equipId: any;
  ticketId?: any;
};

const Tickets: React.FC<Props> = ({ createTicket, equipId, ticketId }) => {
  const { t } = useTranslation();
  const { userDetails, APPReducer, privilegeChecker } =
    useContext(ApplicationContext);
  const [state, dispatch] = APPReducer;
  const { navigate, tractors } = state;
  let routeData: any;
  if (navigate) {
    routeData = navigate.data;
  }
  const { ticketRefreshStatus } = useContext(FleetHealthCxt);
  const [loading, setLoading] = useState<boolean>(true);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize] = useState<any>(25);
  const [sortedInfo, setSortedInfo] = useState<any>({
    columnKey: 'created_date_time',
    order: 'descend',
  });

  const [tractorsData, setTractorsData] = useState<TractorResponseType[]>([
    { label: 'All', value: '', disabled: false, index: '' },
  ]);
  const [status, setStatus] = useState<any>('open,inprogress');
  const [createdAt, setCreatedAt] = useState<number>(0);
  const [toDateTime, setToDateTime] = useState<any>(null);
  const [search, setSearch] = useState<string>(ticketId || '');
  const [severityLevel, setSeverityLevel] = useState<string>('');
  const [total, setTotal] = useState<number>(0);
  const [showForm, setShowForm] = useState<boolean>(false);
  const [showViewEdit, setShowViewEdit] = useState<boolean>(false);
  const [selectedTicket, setSelectedTicket] = useState<any>(null);
  const [equipmentsList, setEquipmentsList] = useState<any[]>([]);
  const [equipmentsListMap, setEquipmentsListMap] = useState<Map<number, any>>(
    new Map(),
  );
  const [equipmentId, setEquipmentId] = useState<number | undefined>();
  const [refresh, setRefresh] = useState<boolean>(false);
  const [init, setInit] = useState<boolean>(false);
  const [dateFilter, setDateFilter] = useState<any>([]);
  const [sortedData, setSortedData] = useState<any>({
    created_date_time: 'descend',
  });
  const searchString = useRef<string>(ticketId || '');
  const [summeryflag, setSummeryFlag] = useState<boolean>(false);
  const { filterData, activity, checkActivity, hasMore, setData } =
    usePaginate();

  useEffect(() => {
    return () => {
      setTractorsData([]);
      setTotal(0);
      setSearch('');
      setEquipmentsList([]);
      setSelectedTicket(null);
      setDateFilter([]);
      setSeverityLevel('');
    };
  }, []);

  const columns = [
    {
      title: `${t(translate.fleetticketsHistory.ticketid)}`,
      dataIndex: 'ticket_id',
      key: 'ticket_id',
      width: '12%',
    },
    {
      title: () => {
        return (
          <>
            {t(translate.fleetticketsHistory.dateTime)}
            <span className="sort-pad">
              {sortedInfo.columnKey === 'created_date_time' && (
                <img
                  className="sort-pad"
                  src={
                    sortedData['created_date_time'] === 'ascend'
                      ? sortUp
                      : sortDown
                  }
                />
              )}
            </span>
          </>
        );
      },
      sortDirections: ['descend', 'ascend', 'descend'],
      dataIndex: 'created_date_time',
      key: 'created_date_time',
      width: '15%',
      render: (created_date_time: string, ticketsObj: any) => (
        <span data-testid={`${ticketsObj?.ticket_description}-date-Tickets`}>
          {created_date_time}
        </span>
      ),
      sorter: (a: { date_time: number }, b: { date_time: number }) => {
        return a.date_time - b.date_time;
      },
      sortOrder:
        sortedInfo.columnKey === 'created_date_time' && sortedInfo.order,
      ellipsis: true,
      showSorterTooltip: false,
    },
    {
      title: () => {
        return (
          <>
            {t(translate.fleetticketsHistory.severity)}
            <span className="sort-pad">
              {sortedInfo.columnKey === 'ticket_level' && (
                <img
                  className="sort-pad"
                  src={
                    sortedData['ticket_level'] === 'ascend' ? sortUp : sortDown
                  }
                />
              )}
            </span>
          </>
        );
      },
      defaultSortOrder: 'ascend',
      sortDirections: ['descend', 'ascend', 'descend'],
      dataIndex: 'ticket_level',
      key: 'ticket_level',
      width: '9%',
      sorter: (a: { ticket_level: number }, b: { ticket_level: number }) => {
        return a.ticket_level - b.ticket_level;
      },
      sortOrder: sortedInfo.columnKey === 'ticket_level' && sortedInfo.order,
      ellipsis: true,
      showSorterTooltip: false,
      render: (level: any) => {
        return (
          <div className="description" data-testid={`${level}-Tickets`}>
            <img className="mr7" src={getSeverityIcon(level, 1)} />
            <img className="mr7" src={getSeverityIcon(level, 2)} />
            <img className="mr7" src={getSeverityIcon(level, 3)} />
          </div>
        );
      },
    },
    {
      title: `${t(translate.baseStation.status)}`,
      dataIndex: 'ticketStatus',
      key: 'ticketStatus',
      width: '12%',
      render: (ticketStatus: string) =>
        ticketStatus === 'completed' ? (
          <Tag
            className="resolveStyle"
            style={{ borderRadius: '12px' }}
            data-testid={`${ticketStatus}-Tickets`}
          >
            {t(translate.tickets.status.resolved)}
          </Tag>
        ) : (
          <>
            {ticketStatus === 'inprogress' ? (
              <Tag
                className="inProgressStyle"
                data-testid={`${ticketStatus}-Tickets`}
              >
                {t(translate.tickets.status.inProgress)}
              </Tag>
            ) : (
              <>
                {ticketStatus === 'Forwarded' ? (
                  <Tag
                    className="forwardStyle"
                    data-testid={`${ticketStatus}-Tickets`}
                  >
                    {ticketStatus}
                  </Tag>
                ) : (
                  <Tag
                    color="red"
                    className="openStyle"
                    data-testid={`${ticketStatus}-Tickets`}
                  >
                    {t(translate.tickets.status.open)}
                  </Tag>
                )}{' '}
              </>
            )}
          </>
        ),
    },
    {
      title: `${t(translate.fleetticketsHistory.assignto)}`,
      dataIndex: 'assignee',
      key: 'assignee',
      width: '12%',
      render: (assignee: string, record: any) => (
        <div data-testid={`${assignee}-Tickets`}>
          {record.is_service_ticket_raised ? (
            <div style={{ fontFamily: 'Montserrat-Medium' }}>
              {t(translate.tickets.defaultAssignee)}
            </div>
          ) : record.assignee ? (
            <>{assignee}</>
          ) : (
            <>-</>
          )}
        </div>
      ),
    },
    {
      title: `${t(translate.fleetticketsHistory.equipment)}`,
      dataIndex: 'equipmentName',
      key: 'equipmentName',
      width: '15%',
      render: (equipmentName: string) => (
        <span
          data-testid={`${equipmentName}-Tickets`}
          style={{ color: equipmentName === 'Other' ? '#99999C' : '' }}
        >
          {equipmentName}
        </span>
      ),
    },
    {
      title: `${t(translate.fleetticketsHistory.ticketDescription)}`,
      dataIndex: 'ticket_description',
      key: 'ticket_description',
      width: '20%',
      ellipsis: true,
      render: (ticket_description: string) => {
        return (
          <span
            className="description"
            data-testid={`${ticket_description}-Tickets`}
          >
            {ticket_description}
          </span>
        );
      },
    },
  ];

  const getTractorsList = () => {
    try {
      const tdata = tractors.map(({ id, name }: any) =>
        Object.assign({
          label: name,
          value: id,
          disabled: false,
          index: id,
        }),
      );
      setTractorsData([...tractorsData, ...tdata]);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    }
  };

  const loadEquipments = async () => {
    setEquipmentsList([]);
    let result: any = await getOrgEquipments(
      userDetails.organization.api_url,
      userDetails.organization_id,
    );
    result = result.filter(
      (equipment: any) => equipment.equipment_type !== 'None',
    );
    result = result.map((equip: any, i: number) => {
      const { equipment_id, name } = equip;
      equip.uuid = i;
      equip.equipment_id = equipment_id;
      equip.name = !speCharRegX?.test(name) ? encodeURIComponent(name) : name;
      return equip;
    });
    setEquipmentsList(result);
    const tmap = result.reduce((a: any, c: any) => {
      a.set(c.uuid, c);
      return a;
    }, new Map());
    setEquipmentsListMap(tmap);
    if (equipId) {
      result.map((ele: any) => {
        if (ele?.equipment_id === equipId) {
          setEquipmentId(ele.uuid);
        }
      });
    }
  };

  const wrapResult = async (
    apiUrl: string,
    orgId: number,
    pageNumber: number,
    pageSize: number,
    filter: any,
    fromDateTime: number | string,
    toDateTime: number | string,
    sortedInfo: any,
  ) => {
    const response = await getTicketsHistory(
      apiUrl,
      orgId,
      pageNumber,
      pageSize,
      filter,
      fromDateTime,
      toDateTime,
      !routeData ? (search === 'monarch' ? '' : search) : routeData.ref_uuid,
      sortedInfo,
    );
    return { response, searchKey: search };
  };

  const getTickets = async () => {
    if (checkActivity()) initScroller();
    const filter: any = { pageNumber, pageSize };
    let equipment;
    if (typeof equipmentId !== 'undefined' && !isNaN(equipmentId))
      equipment = equipmentsListMap.get(Number(equipmentId));
    try {
      setLoading(true);
      if (Number(severityLevel) > 0) {
        filter.severityLevel = severityLevel;
      }
      if (equipment && equipment?.equipment_id) {
        filter.equipment = {
          equipment_id:
            equipment?.equipment_id === 'NULL'
              ? 'NULL'
              : equipment?.equipment_id,
          equipment_type: equipment?.equipment_type,
        };
      }
      if (createdAt > 0) {
        filter.createdAt = createdAt;
      }
      if (status !== '' && !routeData) {
        filter.status = status;
      }

      const fromDateTime =
        dateFilter && !routeData
          ? dateFilter[0] && dateFilter[0].startOf('day').toDate()
          : undefined;
      const toDateTime =
        dateFilter && !routeData
          ? dateFilter[1] && dateFilter[1].endOf('day').toDate()
          : undefined;
      if (!routeData && search === 'monarch') {
        filter.is_service_ticket_raised = true;
      }
      const { searchKey, response: result } = await wrapResult(
        userDetails.organization.api_url,
        userDetails.organization.fleet.id,
        pageNumber,
        pageSize,
        filter,
        fromDateTime ? fromDateTime.getTime() : '',
        toDateTime ? toDateTime.getTime() : '',
        sortedInfo,
      );
      if (searchString.current !== searchKey) return;

      setTotal(result?._metadata?.total_records_count);
      const { records } = result;
      let data = Array.isArray(records) ? records : [];
      data = mapTickets(data);
      setData(data);
    } catch (error: any) {
      notification.error({
        message: error.message,
        duration: 2,
      });
    } finally {
      setLoading(false);
    }
  };

  const getSeverityIcon = (level: number, orderNumber: number) => {
    if (level >= 3) {
      return FlagRedIcon;
    }
    if (orderNumber <= level) {
      return FlagOrangeIcon;
    }
    return FlagGrayIcon;
  };

  const handleChange = (pagination: any, filters: any, sorter: any) => {
    setLoading(true);
    activity.current = 'sort';
    const { columnKey, order } = sorter;
    setSortedData({ ...sortedData, [columnKey]: order });
    setSortedInfo(sorter);
  };

  const handleSearch = (e: any) => {
    activity.current = 'search';
    const value = e.target.value.trimLeft('').regX;
    searchString.current = value;
    setSearch(value);
  };

  useEffect(() => {
    if (dateFilter === null) {
      setToDateTime('');
    }
  }, [dateFilter]);

  const levelSelect = (e: string) => {
    setSeverityLevel(e);
    track('Tickets', {
      event: `Select Level ${e}`,
    });
  };

  const equipmentSelect = (e: any) => {
    setEquipmentId(e);
    track('Tickets', {
      event: `Select Equipment ${e}`,
    });
  };

  const statusSelect = (e: string) => {
    setStatus(e);
    track('Tickets', {
      event: `Select Status ${e}`,
    });
  };

  const handleLoadMore = () => {
    if (pageNumber === 1 && checkActivity() && document) initScroller();

    activity.current = 'paginate';
    setPageNumber(pageNumber + 1);
  };

  const Close = (action = 'close') => {
    setShowForm(false);
    setShowViewEdit(false);
    setSummeryFlag(true);
    if (action !== 'close') {
      activity.current = action;
      if (pageNumber === 1) {
        loadTickets();
      } else {
        setPageNumber(1);
      }
    }
    initClearSelection();
  };

  const showCreateForm = () => {
    setSummeryFlag(false);
    setShowForm(true);
    track('Addticket', {
      event: 'Add Ticket',
    });
  };

  const afterTicketSave = () => {
    // resetData();
    // filterData.length = 0;
    setRefresh(!refresh);
  };

  const viewTicket = (ticket: any) => {
    setSummeryFlag(false);
    setShowViewEdit(true);
    setSelectedTicket(ticket);
    track('DetailsTicket', {
      event: 'Ticket Details',
    });
  };

  useEffect(() => {
    if (dateFilter && dateFilter.length > 0) {
      onDateRangeChange(dateFilter);
    }
  }, [dateFilter]);

  const onDateRangeChange = (dates: any) => {
    if (dates) {
      const [from, to] = fromToDateWrapper(dates);
      setToDateTime(to.toDate());
      track('Tickets', {
        event: `Select From Date ${from.toDate()} & To Date ${to.toDate()}`,
      });
    } else {
      setToDateTime(null);
      track('Tickets', {
        event: 'Select From Date & To Date Empty',
      });
    }
  };

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      loadEquipments();
      // getassigned();
      if (!equipId) loadTickets();
      if (!routeData) setInit(true);
    }
  }, [userDetails]);

  useEffect(() => {
    if (tractors) getTractorsList();
  }, [tractors]);

  useEffect(() => {
    if (init) {
      activity.current = 'filter';
      loadTickets();
    }
  }, [createdAt, severityLevel, equipmentId, toDateTime, status]);

  useEffect(() => {
    if (init) loadTickets();
  }, [pageNumber, sortedInfo]);

  useEffect(() => {
    if ((init && search?.length > 2) || !search?.length) loadTickets();
  }, [search]);

  useEffect(() => {
    if (init) {
      activity.current = 'refresh';
      loadTickets();
    }
  }, [refresh]);

  const loadTickets = () => {
    if (userDetails && !userDetails.organization) return;
    if (checkActivity()) {
      pageNumber !== 1 ? setPageNumber(1) : getTickets();
    } else if (activity.current === 'paginate' || activity.current === '') {
      getTickets();
    }
  };

  const getTickets1 = async () => {
    const tickethistory = await getTicketsHistory(
      userDetails.organization.api_url,
      userDetails.organization.fleet.id,
      pageNumber,
      pageSize,
      {},
      '',
      '',
      routeData.ref_uuid,
      sortedInfo,
    );
    let data =
      tickethistory?.records && tickethistory.records.length > 0
        ? tickethistory.records
        : [];
    data = mapTickets(data);
    if (data.length) {
      const [temp] = data.filter(
        (ticket: any) => ticket.ticket_id == routeData.ref_uuid,
      );
      setInit(true);
      if (temp) viewTicket(temp);
      dispatch({
        type: RESET_NAVIGATE,
      });
    }
  };

  useEffect(() => {
    if (routeData) {
      const { ref_uuid, status } = routeData;
      if (ref_uuid) setSearch(ref_uuid);
      setStatus(status);
      getTickets1();
    }
  }, [routeData]);

  useEffect(() => {
    if (createTicket) {
      setShowForm(true);
    }
  }, [createTicket]);

  useEffect(() => {
    if (init) afterTicketSave();
  }, [ticketRefreshStatus]);

  return (
    <Layout style={{ height: '80vh' }}>
      <div className="mainContent">
        <Content>
          <div className="tblContainer viewportContainer">
            <Row>
              <TicketSummary summeryflag={summeryflag} />
              <div className="common_wrapper">
                <div className="filters_card">
                  <div>
                    <Input
                      addonBefore={<SearchOutlined />}
                      id={applicationIds.personnelScreen.ticketSearcchText}
                      placeholder="Search Tickets"
                      data-testid="searchInputField-Tickets"
                      autoComplete="off"
                      onChange={(e) => handleSearch(e)}
                      value={search}
                      className="common_search"
                    />
                  </div>
                  <CustomDate
                    label="Start Date - End Date"
                    cssClass="min_width"
                    value={dateFilter}
                    onChange={(dates: any) => {
                      setDateFilter(dates);
                    }}
                  ></CustomDate>
                  <CustomSeverityLevel
                    testId="severityLevelDropdownField-Tickets"
                    label="Severity Level"
                    cssClass="min_width"
                    value={severityLevel}
                    onSelect={(selected) => {
                      levelSelect(selected);
                    }}
                  />
                  <CustomSelect
                    label="Status"
                    cssClass="min_width"
                    value={status}
                    setValue={statusSelect}
                    options={resolveFilterData}
                    optionKey="value"
                    optionDisplay="name"
                    testId="statusDropdownField-Tickets"
                  ></CustomSelect>
                  <CustomSelect1
                    label="Equipment"
                    testId="equipmentDropdown-Tickets"
                    cssClass="min_width"
                    value={equipmentId}
                    setValue={equipmentSelect}
                    options={equipmentsList}
                    optionDisplay="name"
                    optionKey="uuid"
                  ></CustomSelect1>

                  {privilegeChecker(
                    privilagesConstants.Create_a_fleet_ticket,
                  ) &&
                    !showForm &&
                    !showViewEdit && (
                      <div
                        className="createTicketStyle"
                        onClick={showCreateForm}
                      >
                        <img
                          id={applicationIds.ticketScreen.editTicket}
                          src={plusIconNew}
                          height="10px"
                        />
                        <span
                          className="create_ticket_btn"
                          data-testid="createTicketButton-Tickets"
                        >
                          {t(translate.buttons.createTicket)}
                        </span>
                      </div>
                    )}
                </div>
                <div className="common_table tableHover">
                  <InfiniteScrollTable
                    columns={columns}
                    hasMore={hasMore}
                    loading={loading}
                    filterData={filterData}
                    totalcount={total}
                    handleLoadMore={handleLoadMore}
                    filename="Tickets"
                    onRowClick={(record) => viewTicket(record)}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </Row>
          </div>
        </Content>
      </div>

      {showForm && (
        <TicketsCreateEdit
          Close={Close}
          toggleWidget={showForm}
        ></TicketsCreateEdit>
      )}
      {selectedTicket && showViewEdit && (
        <TicketViewEdit
          Close={() => {
            Close();
            loadTickets();
          }}
          ticketObj={selectedTicket}
          toggleWidget={showViewEdit}
        ></TicketViewEdit>
      )}
    </Layout>
  );
};

export default Tickets;
