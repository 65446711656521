import React, { useRef, useState, useEffect } from 'react';
import { getMinSecFormat } from '../../lib/utilities';
import play_fill from '../../assets/images/play_fill.svg';
import videoThumbnail from './images/video-thumbnail.jpg';

interface Props {
  selectedVideoId?: number;
  video: {
    id: number;
    title: string;
    file_url: string;
    poster: string;
  };
  handleSelect: (video: any) => void;
}

const CVideo: React.FC<Props> = ({ selectedVideoId, video, handleSelect }) => {
  const { id, title, file_url } = video;
  const videoRef = useRef<any>();
  const [duration, setDuration] = useState<string>('');

  const initVideo = () => {
    videoRef.current = setInterval(() => {
      const video: any = document.getElementById('v-' + id);
      if (video) {
        if (video.readyState === 4) {
          clearInterval(videoRef.current);
          const duration = Math.floor(video.duration);
          setDuration(getMinSecFormat(duration));
        }
      }
    }, 1000);
  };

  useEffect(() => {
    if (video) initVideo();
    return () => {
      if (videoRef.current) clearInterval(videoRef.current);
    };
  }, [video]);

  return (
    <div
      className={`video-thumb ${
        video.id === selectedVideoId ? 'selected' : ''
      }`}
    >
      <div
        className="videoBox"
        onClick={() => {
          handleSelect(video);
        }}
      >
        {video?.poster ? (
          <img className="video knowledgeImg" src={video?.poster} />
        ) : (
          <img className="video knowledgeImg" src={videoThumbnail} />
        )}

        <video
          id={`v-${id}`}
          className="video knowledgeImg"
          src={file_url}
          style={{ display: 'none' }}
        />
        <div className="videoLayer">
          <img src={play_fill} alt="play" className="helpCenterplayIcon" />
        </div>
        <span className="videoTime">{duration}</span>
      </div>
      <p className="name">{title}</p>
    </div>
  );
};
export default CVideo;
