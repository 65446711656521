/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
import { Avatar, Button, Card, Input, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CloseOutlined } from '@ant-design/icons';
import edit_icon from '../../../assets/images/editIcon.png';
import delete_icon from '../../../assets/images/delete_icon.png';
import { getDateTimes } from '../../../constants/Common';
import { addComments, deleteComment } from '../API';
import translate from '../../../locale/en_translate.json';
import CustomTextArea from '../../common/CustomTextArea';
import AppLoader from '../../common/AppLoader';

interface Props {
  selectedTicketId: number;
  // ticketDetails: any;
  data: any;
  setEditComment: (data: boolean) => void;
  setCommentId: any;
  setSelectedTicketById: (id: number) => void;
  handleRefresh: () => void;
  tabId: string;
}

const InternalExternalComments: React.FC<Props> = ({
  selectedTicketId,
  // ticketDetails,
  data,
  setEditComment,
  setCommentId,
  setSelectedTicketById,
  handleRefresh,
  tabId,
}) => {
  const { TextArea } = Input;
  const { t } = useTranslation();
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [comment, setComment] = useState<boolean>(false);
  const [commentData, setCommentData] = useState<string>('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (commentData && commentData.trim().length > 0) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [commentData]);

  const SaveComment = async () => {
    setLoading(true);
    const customerPayload = {
      data_key: 'COMMENTS',
      data_values: commentData,
    };
    const internalPayload = {
      data_key: 'COMMENTS',
      data_values: commentData,
      comment_type: 'internal_comment',
    };
    try {
      const response: { msg: string } = await addComments(
        selectedTicketId,
        tabId === 'InternalComments' ? internalPayload : customerPayload,
      );
      handleRefresh();
      setComment(false);
      notification.success({ message: response.msg });
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const deleteCommentData = async (id: number) => {
    try {
      const response: { msg: string } = await deleteComment(
        selectedTicketId,
        id,
      );
      handleRefresh();
      notification.success({ message: response.msg });
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    }
  };

  const commentDetails = (details: any) => {
    setSelectedTicketById(selectedTicketId);
    setCommentId(details);
    setEditComment(true);
  };

  return (
    <>
      <div className="commentsWidth">
        <div className="commentsList">
          {data &&
            data.comments &&
            data.comments.map((el: any, index: any) => (
              <>
                {tabId === 'CustomerComments'
                  ? el?.comment_type === 'customer_comment' && (
                      <div className="commentsBlk" key={index}>
                        <div className="commentsIcon">
                          <Avatar
                            style={{
                              width: '43px',
                              height: '43px',
                              backgroundColor: '#fff',
                              color: '#737272e8',
                              fontFamily: 'Montserrat-Bold',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            {' '}
                            {el?.created_by?.first_name?.charAt(0)}
                            {el?.created_by?.last_name?.charAt(0)}
                          </Avatar>
                        </div>

                        <div className="commentsContent">
                          <h5>{el?.data_text}</h5>
                          <h6>{el?.created_at}</h6>
                          <h6>{getDateTimes(el?.created_date_time)}</h6>
                        </div>
                        {data &&
                          data.status !== 'completed' &&
                          el?.created_at === 'NEXUS' && (
                            <div className="editDelComments">
                              <img
                                src={edit_icon}
                                onClick={() => commentDetails(el)}
                                alt="editIcon"
                                height="18px"
                              />

                              <img
                                src={delete_icon}
                                onClick={() => deleteCommentData(el?.id)}
                                alt="delIcon"
                                height="18px"
                              />
                            </div>
                          )}
                      </div>
                    )
                  : el?.comment_type === 'internal_comment' && (
                      <div className="commentsBlk" key={index}>
                        <div className="commentsIcon">
                          <Avatar
                            style={{
                              width: '43px',
                              height: '43px',
                              backgroundColor: '#fff',
                              color: '#737272e8',
                              fontFamily: 'Montserrat-Bold',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            {' '}
                            {el?.created_by?.first_name?.charAt(0)}
                            {el?.created_by?.last_name?.charAt(0)}
                          </Avatar>
                        </div>

                        <div className="commentsContent">
                          <h5>{el?.data_text}</h5>
                          <h6>{el?.created_at}</h6>
                          <h6>{getDateTimes(el?.created_date_time)}</h6>
                        </div>
                        {data &&
                          data.status !== 'completed' &&
                          el?.created_at === 'NEXUS' && (
                            <div className="editDelComments">
                              <img
                                src={edit_icon}
                                onClick={() => commentDetails(el)}
                                alt="editIcon"
                                height="18px"
                              />

                              <img
                                src={delete_icon}
                                onClick={() => deleteCommentData(el?.id)}
                                alt="delIcon"
                                height="18px"
                              />
                            </div>
                          )}
                      </div>
                    )}
              </>
            ))}
        </div>
        {comment && (
          <>
            <div className="tcktCommentBox newCommentStyle">
              <CustomTextArea
                label="Description"
                value={commentData}
                setValue={setCommentData}
                description={commentData}
                descriptionCount={commentData?.length ?? 0}
                required={true}
              />
              <CloseOutlined
                onClick={() => {
                  setComment(false);
                  setCommentData('');
                }}
              />
              <div className="commentsCounts">
                {commentData && commentData.trim() && (
                  <span onClick={SaveComment} className="commentSaveBtn">
                    {t(translate.buttons.save)}
                  </span>
                )}
              </div>
            </div>
            {/* <Button
              disabled={isDisabled}
              onClick={SaveComment}
              className="commentSubmitBtn"
            >
              {t(translate.buttons.submit)}
            </Button> */}
          </>
        )}

        {!comment && (
          <Button className="commentSaveBtn" onClick={() => setComment(true)}>
            {t(translate.buttons.addaComment)}
          </Button>
        )}
      </div>
      <AppLoader loader={loading} />
    </>
  );
};

export default InternalExternalComments;
