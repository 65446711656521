/* eslint-disable react/jsx-key */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-shadow */
import { notification, Button, Tag, Layout, Row } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import { useTranslation } from 'react-i18next';
import { ApplicationContext } from '../../../../context/AppContext';
import EmailAddModal from './EmailAddModal';
import translate from '../../../../locale/en_translate.json';
import './styles.css';
import constants from '../../../../constants/constant';
import usePaginate from '../../../../hooks/usePaginate';
import { initScroller } from '../../../../constants/Common';
import { mapNexusReportsData } from '../../../../lib/dataFormat';
import TableExt from '../../../widget/TableExt';
import InfiniteScrollTable from '../../../common/InfiniteScrollTable';
import { getReportsData } from '../../API';
import OnDemandReportsList from './OnDemandReportsList';

const { DOWNLOAD_SIZE } = constants;
let columns: any = [];

const OnDemandReports: React.FC = () => {
  const { userDetails } = useContext(ApplicationContext);
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [selectedEmails, setSelectedEmails] = useState<Array<string>>([]);
  const [selectedReportId, setSelectedReportId] = useState(1);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [headers, setHeaders] = useState<any>([]);
  const [pageSize] = useState<number>(25);
  const [total, setTotal] = useState<number>(0);
  const [initialize, setInitialize] = useState<boolean>(false);
  const { filterData, setData, activity, hasMore, checkActivity } =
    usePaginate();
  const [loader, setLoader] = useState<boolean>(false);
  const [csvData, setCSVData] = useState<any[]>([]);
  const [downloadData, setDownloadData] = useState({
    isDownloaded: false,
    percent: 0,
    status: '',
  });
  const [sortInfo, setSortInfo] = useState(null);
  const [toggleColumnsStatus, setToggleColumnsStatus] = useState(false);
  const [recordObj, setRecordObj] = useState();
  const [recordFlag, setRecordFlag] = useState(false);

  const wrapResult = async (
    orgId: number,
    pageNumber: number,
    pageSize: number,
  ) => {
    const result = await getReportsData(
      orgId,
      pageNumber,
      pageSize,
      'prod',
      sortInfo,
    );
    return { result };
  };

  const getReportsDetails = async () => {
    try {
      if (checkActivity()) initScroller();
      setLoader(true);
      const { result } = await wrapResult(
        userDetails.organization_id,
        pageNumber,
        pageSize,
      );

      setTotal(result._metadata.total_records_count);
      const { records } = result;
      let data = Array.isArray(records) ? records : [];
      data = mapNexusReportsData(data);
      setData(data);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    } finally {
      setLoader(false);
      if (!initialize) setInitialize(true);
    }
  };

  const loadReports = () => {
    if (userDetails && !userDetails.organization) return;
    if (checkActivity()) {
      // eslint-disable-next-line no-unused-expressions
      pageNumber !== 1 ? setPageNumber(1) : getReportsDetails();
    } else if (activity.current === 'paginate' || activity.current === '') {
      getReportsDetails();
    }
  };

  const handleLoadMore = () => {
    if (pageNumber === 1 && checkActivity() && document) initScroller();

    activity.current = 'paginate';
    setPageNumber(pageNumber + 1);
  };

  const handleRefresh = () => {
    activity.current = 'refresh';
    loadReports();
  };

  const getRow = (record: any) => {
    setRecordObj(record);
    setRecordFlag(true);
  };
  useEffect(() => {
    columns = [
      {
        title: `${t(translate.baseStation.Name)}`,
        dataIndex: 'name',
        key: 'name',
        width: '320px',
        ellipsis: true,
        render: (name: string) => (
          <span data-testid={`${name}-OnDemandReports`}>{name}</span>
        ),
        onCell: (record: any) => ({
          onClick: () => getRow(record),
        }),
      },
      {
        title: `${t(translate.reportsDistribution.frequency)}`,
        dataIndex: 'frequency',
        key: 'frequency',
        render: (frequency: string, record: any) => (
          <span data-testid={`${frequency}-${record.name}-OnDemandReports`}>
            {frequency}
          </span>
        ),
        onCell: (record: any) => ({
          onClick: () => getRow(record),
        }),
        width: '120px',
      },
      {
        title: `${t(translate.reportsDistribution.emails)}`,
        dataIndex: 'emails',
        key: 'emails',
        render: (text: any) => (
          <>
            {text &&
              text.map((email: string) => (
                <Tag className="mb5" color="green">
                  {email}
                </Tag>
              ))}
          </>
        ),
      },
      {
        title: `${t(translate.updateManager.action)}`,
        dataIndex: 'action',
        key: 'action',
        render: (data: Array<string>, record: any) =>
          record.report_type !== 'ondemand' && (
            <Button
              data-testid="addButton-OnDemandReports"
              className="ant-btn-primary"
              onClick={() => {
                setSelectedReportId(record.reportId);
                setSelectedEmails(data);
                setShowModal(true);
              }}
            >
              {t(translate.buttons.add)}
            </Button>
          ),
        width: '120px',
      },
    ];
    const header: any = [];
    if (columns) {
      columns.map((column: any) =>
        header.push({
          label: column.title,
          key: column.dataIndex,
        }),
      );
      setHeaders(header);
    }
  }, []);

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      loadReports();
    }
  }, [userDetails, pageNumber, pageSize, sortInfo]);

  const closeReports = (state = false) => {
    setShowModal(false);
    if (state) handleRefresh();
  };

  const closeDemand = (state = false) => {
    setRecordFlag(false);
    if (state) handleRefresh();
  };

  const handleDownload = async () => {
    try {
      if (downloadData.status === 'start') return;
      setDownloadData({
        ...downloadData,
        status: 'start',
        percent: 10,
      });
      let data: any[] = [];
      const pages = Math.ceil(total / DOWNLOAD_SIZE);
      // eslint-disable-next-line no-restricted-syntax
      for (const page of Array.from({ length: pages }, (_, i) => i + 1)) {
        // eslint-disable-next-line no-await-in-loop
        const { result } = await wrapResult(
          userDetails.organization_id,
          page,
          DOWNLOAD_SIZE,
        );
        const { records } = result;
        let tdata = Array.isArray(records) ? records : [];
        tdata = mapNexusReportsData(tdata);
        data = [...data, ...tdata];
        setDownloadData({
          ...downloadData,
          status: 'start',
          percent: Math.round((data.length / total) * 100),
        });
      }

      setCSVData([...data]);
      setDownloadData({
        ...downloadData,
        percent: 100,
        status: 'success',
      });
    } catch (error: any) {
      setDownloadData({
        ...downloadData,
        status: 'exception',
      });
      notification.error({ message: error.message });
    }
  };

  const handleSort = (value: any) => {
    activity.current = 'sort';
    setSortInfo(value);
  };

  return (
    <>
      {!recordFlag && (
        <Layout onClick={() => setToggleColumnsStatus(!toggleColumnsStatus)}>
          <div className="mainContent">
            <div className="tblContainer viewportContainer">
              <Row>
                <div className="common_wrapper tab2FilterWidget_wrapper">
                  <div className="filters_card ad_filters_card filtersHealthScreen">
                    <TableExt
                      handleRefresh={handleRefresh}
                      handleDownload={handleDownload}
                      downloadData={downloadData}
                      csvHeaders={headers}
                      csvData={csvData}
                      csvFilename="ReportsManager"
                      toggleColumnsStatus={toggleColumnsStatus}
                    />
                  </div>

                  <div className="common_table">
                    <InfiniteScrollTable
                      columns={columns}
                      loading={loader}
                      hasMore={hasMore}
                      filterData={filterData}
                      totalcount={total}
                      handleLoadMore={handleLoadMore}
                      filename="Reports Manager"
                    />
                  </div>
                </div>
              </Row>
            </div>
          </div>
          {showModal && (
            <EmailAddModal
              showModal={showModal}
              close={closeReports}
              emails={selectedEmails}
              reportId={selectedReportId}
            />
          )}
        </Layout>
      )}

      {recordFlag && recordObj && (
        <OnDemandReportsList
          showModal={recordFlag}
          close={closeDemand}
          recordObj={recordObj}
        />
      )}
    </>
  );
};
export default OnDemandReports;
