import { Layout } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

interface Props {
  filePath: string;
}

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const RenderPDF: React.FC<Props> = ({ filePath }) => {
  const [numPages, setNumPages] = useState(0);

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  return (
    <Layout style={{ overflowY: 'auto', height: '100%' }}>
      <Content>
        <Document
          file={filePath}
          onLoadSuccess={onDocumentLoadSuccess}
          className="pdf-document"
        >
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              key={`page_${index + 1}`}
              className="pdf-page"
              pageNumber={index + 1}
            />
          ))}
        </Document>
      </Content>
    </Layout>
  );
};

export default RenderPDF;
