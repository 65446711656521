import React, { useState, useContext, useEffect } from 'react';
import { Content } from 'antd/lib/layout/layout';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
import {
  Layout,
  Row,
  Space,
  Input,
  notification,
  Popover,
  Tooltip,
} from 'antd';

import { ApplicationContext } from '../../context/AppContext';
import applicationIds from '../../locale/applicationIds.json';
import translate from '../../locale/en_translate.json';

import sortDown from '../../assets/images/sort_down.svg';
import sortUp from '../../assets/images/sort_up.svg';
import verHisIcon from '../../assets/images/Group 1296.svg';
import dwlIcon from '../../assets/images/dwlIcon.svg';
import dwlingIcon from '../../assets/images/verDownload.svg';

import { getSoftwaresList, getTractorsData } from '../../constants/Api';
import { Software } from '../../constants/types';
import { track } from '../../util/logger';
import { privilagesConstants } from '../../constants/Privilages';
import {
  getStatusForTractor,
  getTractorAvailableState,
  getTractorStatusClass,
  tractorSort,
} from '../../constants/Common';
import { SoftwareModal } from './SoftwareModal';
import { VersionHistoryModal } from './VersionHistoryModal';
import InfiniteScrollTable from '../common/InfiniteScrollTable';
import SoftwareHistoryLogs from './SoftwareHistoryLogs';
import {
  StatusData,
  Updateproduct,
  softUpdateFilterData,
} from '../../constants/AppData';
import CustomSelect from '../common/CustomSelect';
import BaseStationUpdates from './BaseStationUpdates';
import '../fleethealth/style.css';

export const SOFTWARE = 'software';
export const FIRMAWARE = 'firmware';
interface Props {
  onSubmit?: (content: string) => void;
  tabKey: string;
}
export const SoftwareUpdate: React.FC<Props> = ({
  onSubmit,
  tabKey,
}: Props) => {
  const { privilegeChecker, APPReducer } = useContext(ApplicationContext);
  const [state] = APPReducer;
  const { tractors } = state;
  const { t } = useTranslation();
  const [sortedInfo, setSortedInfo] = useState<any>({
    columnKey: 'name',
    order: 'ascend',
  });
  const [search, setSearch] = useState<string>('');
  const [softwaresList, setSoftwaresList] = useState<Software[]>([]);
  const [filterData, setFilterData] = useState<any>([]);
  const [totalcount, settotalcount] = useState<any>();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [updateStatus, setUpdateStatus] = useState<string>('show_all');
  const [baseUpdateStatus, setBaseUpdateStatus] = useState<string>('online');
  const [searchString, setSearchString] = useState<string>('');
  const { user, userDetails } = useContext(ApplicationContext);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedItem, setSelectedItem] = useState<Software>();
  const [isHistoryModalVisible, setIsHistoryModalVisible] = useState(false);
  const [tId, setTId] = useState<number>(0);
  const location: any = useLocation();
  const [productType, setProductType] = useState<string>('Tractor');

  const showModal = (record: any) => {
    setIsModalVisible(true);
    setSelectedItem(record);
  };
  const [sortedData, setSortedData] = useState<any>({
    name: 'ascend',
  });
  const [logData, setLogData] = useState();

  const replaceAt = (str: string | undefined) => {
    if (str && str.length) {
      const index = str.indexOf(':');
      let chars: any = str.split('');
      chars[index] = '\n';
      chars = chars
        .join('')
        .split('|')
        .join(`\n`)
        // .replace('Code changes are detected, they are', 'Build is modified')
        .replace('Code changes are detected, they are', '')
        .replace(/Repo name :/g, '')
        .replace(/, system is/g, ' : ');

      chars = chars.split('\n').map((item: any) => {
        item = item.split(':').reverse().join(' : ');
        return item;
      });
      chars = chars.join('\n');
      chars = `Build is modified ${chars}`;
      return chars;
    } else {
      return '';
    }
  };

  const showUpdaterIcon = (record: any) => {
    if (user?.isSupportUser()) {
      return true;
    }

    return (
      record?.latest_software?.software_version &&
      record?.software_version !== record?.latest_software?.software_version
    );
  };

  const showUpdaterIcon1 = (record: any) => {
    if (user?.isSupportUser()) {
      return true;
    }

    return (
      record?.latest_firmware_version_id &&
      record?.firmware_version_id !== record?.latest_firmware_version_id
    );
  };

  const columns = [
    {
      dataIndex: 'name',
      key: 'name',
      sorter: () => false,
      sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
      defaultSortOrder: 'ascend',
      sortDirections: ['descend', 'ascend', 'descend'],
      width: '20%',
      ellipsis: true,
      showSorterTooltip: false,
      title: () => {
        return (
          <>
            {t(translate.baseStation.tractorName)}
            {sortedInfo.columnKey === 'name' && (
              <img
                className="sort-pad"
                src={sortedData['name'] === 'ascend' ? sortUp : sortDown}
              />
            )}
          </>
        );
      },
      render: (name: string, record: any) => {
        return (
          <>
            <div
              className="flex-space-between jfStart"
              data-testid={`${name}-Updates`}
            >
              <span className="upTname">{name}</span>
            </div>
          </>
        );
      },
    },
    {
      title: `${t(translate.fleet.status)}`,
      width: '150px',
      render: (record: any) => {
        return (
          <span
            className={getTractorStatusClass(
              record.isAvailable,
              record?.heartbeat?.drive_action_details?.drive_action_created_at,
            )}
          >
            {getStatusForTractor(
              record?.isAvailable,
              record?.heartbeat?.drive_action_details?.drive_action_created_at,
            )}
          </span>
        );
      },
    },
    {
      title: `${t(translate.baseStation.pin)}`,
      dataIndex: 'tractor_pin',
      key: 'tractor_pin',
      width: '15%',
    },
    {
      title: `${t(translate.baseStation.tractorModal)}`,
      dataIndex: 'tractor_model',
      key: 'tractor_model',
      width: '15%',
    },
    {
      dataIndex: 'software_version',
      render: (id: string, record: Software) => {
        return (
          <>
            <span>
              {record &&
              record?.data &&
              record?.data?.software &&
              record?.data?.software?.software_update_status &&
              record?.data?.software?.software_update_status ===
                'in-progress' ? (
                <span>
                  {t(translate.updates.updating)}
                  <img
                    src={dwlingIcon}
                    height="38px"
                    alt="download"
                    className="ml80"
                  />
                </span>
              ) : (
                <div className="versionList">
                  <span className="vSNo">
                    <Tooltip title={id}> {id} </Tooltip>
                    <span
                      style={{
                        color: 'red',
                        fontWeight: 'bold',
                        fontSize: 14,
                      }}
                    >
                      {record?.data?.software?.is_software_build_modified
                        ? '*'
                        : ''}
                    </span>
                  </span>

                  {privilegeChecker(privilagesConstants.Begin_an_update) &&
                    showUpdaterIcon(record) && (
                      <div>
                        <Popover
                          overlayClassName="softwarePopover"
                          title={`Version ${record?.latest_software?.software_version} available now. Please Update.`}
                          placement="topRight"
                        >
                          <img src={verHisIcon} className="upMl" />
                        </Popover>

                        <img
                          src={dwlIcon}
                          height="38px"
                          alt="download"
                          className="upMl cPointer"
                          onClick={() => {
                            showModal({ ...record, type: SOFTWARE });
                            track('OpenSoftwareUpdated', {
                              event: 'Software Modal Open',
                            });
                          }}
                        />
                      </div>
                    )}
                </div>
              )}
            </span>
          </>
        );
      },
      key: 'software_version',
      sorter: (a: any, b: any) => {
        return a.software_version.localeCompare(b.software_version);
      },
      sortOrder:
        sortedInfo.columnKey === 'software_version' && sortedInfo.order,
      ellipsis: true,
      showSorterTooltip: false,
      sortDirections: ['descend', 'ascend', 'descend'],
      title: () => {
        return (
          <>
            {t(translate.headers.softwareVersion)}
            <span className="sort-pad">
              {sortedInfo.columnKey === 'software_version' && (
                <img
                  className="sort-pad"
                  src={
                    sortedData['software_version'] === 'ascend'
                      ? sortUp
                      : sortDown
                  }
                />
              )}
            </span>
          </>
        );
      },
    },
    {
      dataIndex: 'firmware_version_view',
      render: (id: string, record: Software) => {
        return (
          <>
            <span>
              {record &&
              record?.data &&
              record?.data?.firmware &&
              record?.data?.firmware?.software_update_status &&
              record?.data?.firmware?.software_update_status ===
                'in-progress' ? (
                <span>
                  {t(translate.updates.updating)}
                  <img
                    src={dwlingIcon}
                    height="38px"
                    alt="download"
                    className="upMl"
                  />
                </span>
              ) : (
                <div className="versionList">
                  <span className="vSNo">
                    <Tooltip title={id}>{id}</Tooltip>
                    <span
                      style={{
                        color: 'red',
                        fontWeight: 'bold',
                        fontSize: 14,
                      }}
                    >
                      {record?.data?.firmware?.is_software_build_modified
                        ? '*'
                        : ''}
                    </span>{' '}
                  </span>
                  {privilegeChecker(privilagesConstants.Begin_an_update) &&
                    showUpdaterIcon1(record) && (
                      <div>
                        <Popover
                          overlayClassName="softwarePopover"
                          placement="topRight"
                          title={`Version ${record?.latest_firmware?.software_version} available now. Please Update.`}
                        >
                          <img src={verHisIcon} className="upMl" />
                        </Popover>
                        <img
                          src={dwlIcon}
                          height="38px"
                          alt="download"
                          className="upMl cPointer"
                          onClick={() => {
                            showModal({ ...record, type: FIRMAWARE });
                            track('OpenFirmWareUpdated', {
                              event: 'FirmWare Modal Open',
                            });
                          }}
                        />
                      </div>
                    )}
                </div>
              )}
            </span>
          </>
        );
      },
      key: 'firmware_version',
      sorter: (a: any, b: any) => {
        return a.firmware_version.localeCompare(b.firmware_version);
      },
      sortOrder:
        sortedInfo.columnKey === 'firmware_version' && sortedInfo.order,
      ellipsis: true,
      showSorterTooltip: false,
      sortDirections: ['descend', 'ascend', 'descend'],
      title: () => {
        return (
          <>
            {t(translate.headers.firmwareVersion)}
            <span className="sort-pad">
              {sortedInfo.columnKey === 'firmware_version' && (
                <img
                  className="sort-pad"
                  src={
                    sortedData['firmware_version'] === 'ascend'
                      ? sortUp
                      : sortDown
                  }
                />
              )}
            </span>
          </>
        );
      },
    },
  ];

  const filterList = () => {
    let filteredList = [];
    if (updateStatus === 'uptodate') {
      filteredList = softwaresList?.filter((record: Software) => {
        if (
          record &&
          record?.software_version_id === record?.latest_software_version_id &&
          record &&
          record?.firmware_version_id === record?.latest_firmware_version_id &&
          record.name.toString() &&
          record.name
            .toString()
            .toLowerCase()
            .includes(searchString.toLowerCase())
        ) {
          return record;
        }
      });
    } else if (updateStatus === 'update_ready') {
      filteredList = softwaresList?.filter((record: Software) => {
        if (
          ((record &&
            record?.software_version_id !==
              record?.latest_software_version_id) ||
            (record &&
              record?.firmware_version_id !==
                record?.latest_firmware_version_id)) &&
          record.name.toString() &&
          record.name
            .toString()
            .toLowerCase()
            .includes(searchString.toLowerCase())
        ) {
          return record;
        }
      });
    } else {
      filteredList = softwaresList;
    }
    if (searchString) {
      filteredList = filteredList.filter((record: Software) => {
        if (
          record.name.toString() &&
          record.name
            .toString()
            .toLowerCase()
            .includes(searchString.toLowerCase())
        ) {
          return record;
        }
      });
    }
    if (!updateStatus && !searchString) {
      setFilterData(softwaresList);
      settotalcount(softwaresList.length);
    } else {
      settotalcount(filteredList.length);
      setFilterData(filteredList);
    }
  };

  const openSoftwareModal = async () => {
    const response = softwaresList?.filter(
      (software: Software) => software.id === tId,
    );
    if (response && response.length) {
      const list = response[0];
      showModal({ ...list, type: SOFTWARE });
    }
  };

  const getSoftwares = async () => {
    setLoading(true);
    try {
      const softwareReords = await getSoftwaresList(
        userDetails.organization.api_url,
        userDetails.organization.id,
      );
      const data =
        softwareReords && softwareReords.length > 0 ? softwareReords : [];

      const total_count =
        softwareReords?.length > 0 ? softwareReords.length : 0;
      let records: Software[] = [];
      const tractorsMap = getTractorsList();
      data.map((record: Software) => {
        const heartbeatFirmware =
          tractorsMap[record.id]?.heartbeat?.tractor_model?.split(',');
        const heartbeatFirmwareVersion =
          heartbeatFirmware?.length >= 122 ? heartbeatFirmware[121] : '-';
        const obj = {
          key: record.id,
          id: record.id,
          name: record.name,
          tractor_pin: tractorsMap[record.id]?.tractor_pin,
          heartbeat: tractorsMap[record.id]?.heartbeat,
          tractor_model: record.tractor_model,
          serial_number: record.serial_number,
          software_version: record?.software?.software_version ?? '-',
          firmware_version: record?.firmware?.software_version ?? '-',
          // firmware_version: heartbeatFirmwareVersion ?? '-',
          firmware_version_view: heartbeatFirmwareVersion ?? '-',

          software_version_id: record?.software?.software_version_id
            ? record?.software?.software_version_id
            : 0,
          latest_software_version_id:
            record?.software?.latest_software?.software_version_id ?? 0,
          firmware_version_id: record?.firmware?.software_version_id
            ? record?.firmware?.software_version_id
            : 0,
          latest_firmware_version_id: record?.firmware?.latest_firmware
            ?.software_version_id
            ? record?.firmware?.latest_firmware?.software_version_id
            : 0,
          isAvailable: getTractorAvailableState(
            tractorsMap[record.id]?.connection,
            tractorsMap[record.id]?.heartbeat,
          ),
          latest_software: record?.software?.latest_software,
          latest_firmware: record?.firmware?.latest_firmware,
          data: record,
        };
        records.push(obj);
      });
      records = tractorSort(records);
      settotalcount(total_count);
      setSoftwaresList(records);
      setFilterData(records);
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const getTractorsList = () =>
    tractors.reduce((a: any, c: any) => (a[c.id] = c) && a, {});

  const loadData = () => {
    if (tabKey !== '15') return;
    if (search != '') setPageNumber(1);
    if (userDetails && userDetails.organization && tractors.length)
      getSoftwares();
  };

  useEffect(() => {
    loadData();
    return () => {
      setFilterData([]);
      setSoftwaresList([]);
    };
  }, [userDetails, pageNumber, search, tractors, tabKey]);

  useEffect(() => {
    if (softwaresList.length && tId) {
      openSoftwareModal();
      setTId(0);
    }
  }, [tId, softwaresList]);

  useEffect(() => {
    if (location.state && location.state.action == 'SOFTWARE')
      setTId(location?.state?.tractorId);
    return () => {
      window.history.replaceState({}, document.title);
    };
  }, [location]);

  useEffect(() => {
    if (softwaresList.length) filterList();
  }, [searchString, updateStatus]);

  useEffect(() => {
    if (sortedInfo.columnKey === 'name') {
      const tdata = filterData.reverse();
      setFilterData([...tdata]);
    }
  }, [sortedInfo.order]);

  const handleSearch = (e: any) => {
    const value = e.target.value.trim().regX;
    onSubmit && onSubmit(value);
    setSearchString(value);

    track('SoftwareUpdate', {
      event: `Search Updates ${e.target.value}`,
    });
  };

  const handleChange = (pagination: any, filters: any, sorter: any) => {
    const { columnKey, order } = sorter;
    setSortedData({ ...sortedData, [columnKey]: order });
    setSortedInfo(sorter);
  };

  const handleProduct = (value: string) => {
    setSearchString('');
    setSearch('');
    setBaseUpdateStatus('online');
    setProductType(value);
  };

  return (
    <>
      <Layout>
        <div className="mainContent">
          <Content>
            <div className="tblContainer viewportContainer">
              <Row>
                <div className="common_wrapper wWidget_wrapper">
                  <div className="filters_card spaceBtnAlignCenter">
                    <div className="displayFlex">
                      <Space size="large">
                        <Input
                          addonBefore={<SearchOutlined />}
                          placeholder="Search Directory"
                          data-testid="searchUpdatesInputField-SoftwareUpdate"
                          className="common_search"
                          value={searchString}
                          onChange={(e) => handleSearch(e)}
                          // onKeyDown={(e) => handleKey(e)}
                          autoComplete="off"
                        />
                      </Space>

                      {productType === 'Tractor' && (
                        <CustomSelect
                          label="Status"
                          testId="updatesTractorStatusDropdown-SoftwareUpdate"
                          cssClass="min_width"
                          value={updateStatus}
                          setValue={(selected) => {
                            setUpdateStatus(selected);
                          }}
                          options={softUpdateFilterData}
                          optionKey="id"
                          optionDisplay="name"
                        />
                      )}
                      {productType === 'Base Station' && (
                        <CustomSelect
                          label="Status"
                          testId="updatesBasestationStatusDropdown-SoftwareUpdate"
                          cssClass="min_width"
                          value={baseUpdateStatus}
                          setValue={(selected) => {
                            setBaseUpdateStatus(selected);
                          }}
                          options={StatusData}
                          optionKey="id"
                          optionDisplay="name"
                        />
                      )}
                      <CustomSelect
                        label="PRODUCTS"
                        testId="updatesProductsDropdown-SoftwareUpdate"
                        cssClass="min_width"
                        value={productType}
                        setValue={(selected) => {
                          handleProduct(selected);
                        }}
                        options={Updateproduct}
                        optionKey="value"
                      />
                    </div>
                    {productType === 'Tractor' && (
                      <div className="version-history">
                        <div
                          id={applicationIds.updateScreen.versionHistory}
                          onClick={() => {
                            setIsHistoryModalVisible(true);
                            track('OpenVersionHistory', {
                              event: 'Version History Modal Open',
                            });
                          }}
                          className="vHtxt Button"
                          data-testid="versionHistoryLink-SoftwareUpdate"
                        >
                          {t(translate.updates.versionHistory)}
                        </div>
                      </div>
                    )}
                  </div>
                  {productType === 'Tractor' && (
                    <div className="common_table">
                      <InfiniteScrollTable
                        columns={columns}
                        loading={loading}
                        hasMore={false}
                        filterData={filterData}
                        totalcount={totalcount}
                        filename="Tractors"
                        onChange={handleChange}
                      />
                    </div>
                  )}
                  {productType === 'Base Station' && (
                    <BaseStationUpdates
                      search={searchString}
                      connection={baseUpdateStatus}
                    />
                  )}
                </div>
              </Row>
            </div>
          </Content>
        </div>

        {isModalVisible && (
          <SoftwareModal
            isModalVisible={isModalVisible}
            setIsModalVisible={(state) => {
              setIsModalVisible(state);
              getSoftwares();
            }}
            data={selectedItem as Software}
          />
        )}

        <VersionHistoryModal
          isHistoryModalVisible={isHistoryModalVisible}
          setIsHistoryModalVisible={setIsHistoryModalVisible}
          data={selectedItem}
        />
        <div>
          {logData && (
            <SoftwareHistoryLogs
              showModel={Boolean(logData)}
              closeModel={() => setLogData(undefined)}
              logData={logData}
            />
          )}
        </div>
      </Layout>
    </>
  );
};

export default SoftwareUpdate;
