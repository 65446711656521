/* eslint-disable no-console */
import { Input, InputNumber, Modal, notification, Select, Spin } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { saveVineRowsAutoGenerateData } from '../../constants/Api';
import {
  PolygonDropDown,
  VineRowPayload,
  VineRowPayloadTypes,
} from '../../constants/types';
import { ApplicationContext } from '../../context/AppContext';
import translate from '../../locale/en_translate.json';

import { useTranslation } from 'react-i18next';

interface Props {
  showModal: boolean;
  handleCancel: (reload?: boolean) => void;
  vineRowModel2FirstPayload: VineRowPayload;
  vineRowModel2LastPayload: VineRowPayload;
  polygonsListForPop: PolygonDropDown[];
}
export const VineRowModal2: React.FC<Props> = ({
  showModal,
  handleCancel,
  vineRowModel2FirstPayload,
  vineRowModel2LastPayload,
  polygonsListForPop,
}: Props) => {
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const [vineBlockName, setVineBlockName] = useState<string>('');
  const [numberOfRows, setNumberOfRows] = useState<number>(2);
  const [selectedPolygonUuid, setSelectedPolygonUuid] = useState('');
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [firstVineRowData, setFirstVineRowData] = useState<any>({
    start_row_head_a_y: vineRowModel2FirstPayload.vineRowXY[0].y,
    start_row_head_a_x: vineRowModel2FirstPayload.vineRowXY[0].x,
    start_row_head_b_y:
      vineRowModel2FirstPayload.vineRowXY[
        vineRowModel2FirstPayload.vineRowXY.length - 1
      ].y,
    start_row_head_b_x:
      vineRowModel2FirstPayload.vineRowXY[
        vineRowModel2FirstPayload.vineRowXY.length - 1
      ].x,
  });
  const [lastVineRowData, setLastVineRowData] = useState<any>({
    end_row_head_a_y: vineRowModel2LastPayload.vineRowXY[0].y,
    end_row_head_a_x: vineRowModel2LastPayload.vineRowXY[0].x,
    end_row_head_b_y:
      vineRowModel2LastPayload.vineRowXY[
        vineRowModel2LastPayload.vineRowXY.length - 1
      ].y,
    end_row_head_b_x:
      vineRowModel2LastPayload.vineRowXY[
        vineRowModel2LastPayload.vineRowXY.length - 1
      ].x,
  });
  const [loader, setLoader] = useState<boolean>(false);
  const saveVineRows = async () => {
    setLoader(true);
    const payload: VineRowPayloadTypes = {
      vine_block_name: vineBlockName.trim(),
      polygon_uuid: selectedPolygonUuid,
      num_of_rows: numberOfRows,
      start_row_head_a_y: vineRowModel2FirstPayload.vineRowXY[0].y,
      start_row_head_a_x: vineRowModel2FirstPayload.vineRowXY[0].x,
      start_row_head_b_y:
        vineRowModel2FirstPayload.vineRowXY[
          vineRowModel2FirstPayload.vineRowXY.length - 1
        ].y,
      start_row_head_b_x:
        vineRowModel2FirstPayload.vineRowXY[
          vineRowModel2FirstPayload.vineRowXY.length - 1
        ].x,
      end_row_head_a_y: vineRowModel2LastPayload.vineRowXY[0].y,
      end_row_head_a_x: vineRowModel2LastPayload.vineRowXY[0].x,
      end_row_head_b_y:
        vineRowModel2LastPayload.vineRowXY[
          vineRowModel2LastPayload.vineRowXY.length - 1
        ].y,
      end_row_head_b_x:
        vineRowModel2LastPayload.vineRowXY[
          vineRowModel2LastPayload.vineRowXY.length - 1
        ].x,
      ...firstVineRowData,
      ...lastVineRowData,
    };
    setVineBlockName(vineBlockName.trim());
    try {
      const { organization } = userDetails;
      const response = await saveVineRowsAutoGenerateData(
        organization.api_url,
        organization.farm.id,
        payload,
      );
      handleCancel(true);
      setLoader(false);
      notification.success({
        message: response.msg,
        className: 'map-maker-popup',
      });
    } catch (error: any) {
      notification.error({
        message: error?.message,
        className: 'map-maker-popup',
      });
    } finally {
      setLoader(false);
    }
  };
  const setVineRowDataHandler = (value: number, type: string) => {
    if (!Number(value)) {
      return;
    }
    const item = firstVineRowData;
    item[type] = value;
    setFirstVineRowData(item);
  };
  const setSecondVineRowDataHandler = (value: number, type: string) => {
    if (!Number(value)) {
      return;
    }
    const item = lastVineRowData;
    item[type] = value;
    setLastVineRowData(item);
  };

  const swapFirstAndB = () => {
    const {
      start_row_head_a_x,
      start_row_head_a_y,
      start_row_head_b_x,
      start_row_head_b_y,
    } = firstVineRowData;
    const payload = {
      start_row_head_a_x: start_row_head_b_x,
      start_row_head_a_y: start_row_head_b_y,
      start_row_head_b_x: start_row_head_a_x,
      start_row_head_b_y: start_row_head_a_y,
    };
    setFirstVineRowData(payload);
  };
  const swapLastAndB = () => {
    const {
      end_row_head_a_x,
      end_row_head_a_y,
      end_row_head_b_x,
      end_row_head_b_y,
    } = lastVineRowData;
    const payload = {
      end_row_head_a_x: end_row_head_b_x,
      end_row_head_a_y: end_row_head_b_y,
      end_row_head_b_x: end_row_head_a_x,
      end_row_head_b_y: end_row_head_a_y,
    };
    setLastVineRowData(payload);
  };

  useEffect(() => {
    if (
      userDetails &&
      userDetails.organization &&
      selectedPolygonUuid &&
      selectedPolygonUuid.trim().length > 0 &&
      vineBlockName &&
      vineBlockName.trim().length > 0 &&
      numberOfRows &&
      numberOfRows > 0
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [userDetails, selectedPolygonUuid, vineBlockName, numberOfRows]);

  return (
    <Modal
      className="commonPopup addPolygon vinrowAuto"
      title={`Auto Generate ${t(translate.map.crop_rows)}`}
      centered
      width={'60vw'}
      visible={showModal}
      closable={false}
      onCancel={() => handleCancel()}
      okText="Save"
      onOk={saveVineRows}
      okButtonProps={{
        className: 'btnSave ',
        disabled: isDisabled,
      }}
      cancelButtonProps={{
        className: 'btnCancel',
      }}
    >
      <div className="formRow width-class">
        <div className="formCol">
          <label className="formLabel">
            {t(translate.map.crop_block)} Name{' '}
            <span style={{ color: 'red' }}> *</span>
          </label>
          <Input
            className="commInput"
            onChange={(e) => setVineBlockName(e.target.value)}
            value={vineBlockName}
          />
        </div>
        <div className="formCol">
          <label className="formLabel">
            {t(translate.map.boundary)} <span style={{ color: 'red' }}> *</span>
          </label>
          <Select
            className="customSelect"
            onSelect={(value) => {
              setSelectedPolygonUuid(value);
            }}
            value={selectedPolygonUuid}
          >
            {polygonsListForPop.map((data: any) => {
              if (data.name !== '') {
                return (
                  <option value={data.polygon_uuid} key={data.polygon_uuid}>
                    {data.name} ({data.color})
                  </option>
                );
              }
            })}
          </Select>
        </div>
      </div>
      <div className="formRow">
        <div className="formCol ">
          <label className="formLabel">
            No of {t(translate.map.crop_rows)}{' '}
            <span style={{ color: 'red' }}> *</span>
          </label>
          <InputNumber
            min={1}
            type="number"
            max={99999}
            className="commInput w100"
            value={numberOfRows}
            onChange={(value) => setNumberOfRows(value)}
          />
        </div>
        <div className="formCol ">
          <label className="formLabel"></label>
          <button
            className="btnSave swapBtn  swapBtnAddOn"
            onClick={() => swapFirstAndB()}
            style={{ float: 'right' }}
          >
            Swap Head A & B
          </button>
        </div>
      </div>
      <div className="formRow">
        <div className="formCol">
          <label className="formLabel lblHeads">Start Point Head A</label>
        </div>
        <div className="formCol">
          <label className="formLabel lblHeads">Start Point Head B</label>
        </div>
      </div>
      <div className="formRow">
        <div className="formCol">
          <label className="formLabel">
            X <span style={{ color: 'red' }}> *</span>
          </label>
          <InputNumber
            type="number"
            className="commInput w100"
            onChange={(value) =>
              setVineRowDataHandler(value, 'start_row_head_a_x')
            }
            defaultValue={vineRowModel2FirstPayload.vineRowXY[0].x}
            value={firstVineRowData.start_row_head_a_x}
          />
        </div>
        <div className="formCol">
          <label className="formLabel">
            X <span style={{ color: 'red' }}> *</span>
          </label>
          <InputNumber
            type="number"
            className="commInput w100"
            onChange={(value) =>
              setVineRowDataHandler(value, 'start_row_head_b_x')
            }
            defaultValue={
              vineRowModel2FirstPayload.vineRowXY[
                vineRowModel2FirstPayload.vineRowXY.length - 1
              ].x
            }
            value={firstVineRowData.start_row_head_b_x}
          />
        </div>
      </div>
      <div className="formRow">
        <div className="formCol">
          <label className="formLabel">
            Y <span style={{ color: 'red' }}> *</span>
          </label>
          <InputNumber
            type="number"
            className="commInput w100"
            onChange={(value) =>
              setVineRowDataHandler(value, 'start_row_head_a_y')
            }
            defaultValue={vineRowModel2FirstPayload.vineRowXY[0].y}
            value={firstVineRowData.start_row_head_a_y}
          />
        </div>
        <div className="formCol">
          <label className="formLabel">
            Y <span style={{ color: 'red' }}> *</span>
          </label>
          <InputNumber
            type="number"
            className="commInput w100"
            onChange={(value) =>
              setVineRowDataHandler(value, 'start_row_head_b_y')
            }
            defaultValue={
              vineRowModel2FirstPayload.vineRowXY[
                vineRowModel2FirstPayload.vineRowXY.length - 1
              ].y
            }
            value={firstVineRowData.start_row_head_b_y}
          />
        </div>
      </div>
      <div className="formRow width-class">
        <div className="formCol"></div>
        <div className="formCol dfright">
          <label className="formLabel"> </label>
          <button
            className="btnSave swapBtn"
            onClick={() => swapLastAndB()}
            style={{ float: 'right' }}
          >
            Swap Head A & B
          </button>
        </div>
      </div>
      <div className="formRow">
        <div className="formCol">
          <label className="formLabel lblHeads">End Point Head A</label>
        </div>
        <div className="formCol">
          <label className="formLabel lblHeads">End Point Head B</label>
        </div>
      </div>
      <div className="formRow">
        <div className="formCol">
          <label className="formLabel">
            X <span style={{ color: 'red' }}> *</span>
          </label>
          <InputNumber
            type="number"
            className="commInput w100"
            onChange={(value) =>
              setSecondVineRowDataHandler(value, 'end_row_head_a_x')
            }
            defaultValue={vineRowModel2LastPayload.vineRowXY[0].x}
            value={lastVineRowData.end_row_head_a_x}
          />
        </div>
        <div className="formCol">
          <label className="formLabel">
            X <span style={{ color: 'red' }}> *</span>
          </label>
          <InputNumber
            className="commInput w100"
            onChange={(value) =>
              setSecondVineRowDataHandler(value, 'end_row_head_b_x')
            }
            defaultValue={
              vineRowModel2LastPayload.vineRowXY[
                vineRowModel2LastPayload.vineRowXY.length - 1
              ].x
            }
            value={lastVineRowData.end_row_head_b_x}
          />
        </div>
      </div>
      <div className="formRow">
        <div className="formCol">
          <label className="formLabel">
            Y <span style={{ color: 'red' }}> *</span>
          </label>
          <InputNumber
            type="number"
            className="commInput w100"
            onChange={(value) =>
              setSecondVineRowDataHandler(value, 'end_row_head_a_y')
            }
            defaultValue={vineRowModel2LastPayload.vineRowXY[0].y}
            value={lastVineRowData.end_row_head_a_y}
          />
        </div>
        <div className="formCol">
          <label className="formLabel">
            Y <span style={{ color: 'red' }}> *</span>
          </label>
          <InputNumber
            type="number"
            className="commInput w100"
            onChange={(value) =>
              setSecondVineRowDataHandler(value, 'end_row_head_b_y')
            }
            defaultValue={
              vineRowModel2LastPayload.vineRowXY[
                vineRowModel2LastPayload.vineRowXY.length - 1
              ].y
            }
            value={lastVineRowData.end_row_head_b_y}
          />
        </div>
      </div>
      <div className="edit-loader">
        {loader && (
          <div className="loader">
            <Spin size="large" />
          </div>
        )}
      </div>
    </Modal>
  );
};
export default VineRowModal2;
