/* eslint-disable no-unused-vars */
import { Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { getMigrationLogs } from '../API';

interface Props {
  migrationId: number;
  handleDisable: (e: boolean) => void;
}

const Logs: React.FC<Props> = ({ migrationId, handleDisable }) => {
  const [data, setData] = useState([]);
  const [showSpinner, setShowSpinner] = useState(true);

  useEffect(() => {
    const init = async () => {
      const { records } = await getMigrationLogs(migrationId, 1, 500);
      setData(records);
    };

    init();
    const interval = setInterval(() => {
      init();
    }, 5000);

    handleDisable(true);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (data) {
      const filteredData = data.filter(
        (d: any) => d.status === 'Completed' || d.status === 'Failed',
      );
      if (filteredData.length > 0) {
        setShowSpinner(false);
      }
    }
  }, [data]);

  const handleMessage = (record: any) => {
    if (record.status === 'Completed') {
      handleDisable(false);
    }

    if (record.status === 'Failed') {
      handleDisable(true);
    }

    return record.message;
  };

  return (
    <>
      <ul>
        {data &&
          data.map((record: any) => (
            <li key={record.id}>{handleMessage(record)}</li>
          ))}
        {showSpinner && (
          <ul>
            <Spin spinning />
          </ul>
        )}
      </ul>
    </>
  );
};

export default Logs;
