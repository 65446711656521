import React, { useContext, useEffect, useState } from 'react';
import { Modal, Space, Button, notification, Select, Tag } from 'antd';
import './style.css';
import Interweave from 'interweave';
import verM_logo from '../../assets/images/verM_logo.svg';
import { updateVersionData } from '../../constants/types';
import { ApplicationContext } from '../../context/AppContext';
import {
  getVersionList,
  updateBaseStationSoftwareAPI,
} from '../../constants/Api';

interface Props {
  isModalVisible: boolean;
  setIsModalVisible: (state: boolean) => void;
  data: any;
}
export const BaseStationModal: React.FC<Props> = ({
  isModalVisible,
  setIsModalVisible,
  data,
}: Props) => {
  const { userDetails } = useContext(ApplicationContext);
  const [updatedVersion, setUpdatedVersion] = useState<updateVersionData[]>([]);
  const [selectVersion, setSelectVersion] = useState<any>();
  const [softwareVesrion, setSoftwareVesrion] = useState<any>();

  const [shwoReleaseNotesFlag, setshwoReleaseNotesFlag] =
    useState<boolean>(false);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (userDetails && userDetails.organization && data) {
      const { basestation_model = '' } = data;
      getVersionLists('bs_software', basestation_model);
    }
  }, [data, userDetails]);

  const updateSoftware = async () => {
    const payload = {
      organization_id: data.organization_id,
      current_software_version_id: data.software_version_id,
      desired_software_version_id: selectVersion?.versionid,
    };
    try {
      await updateBaseStationSoftwareAPI(
        userDetails.organization.api_url,
        data.basestation_id,
        payload,
      );
      handleCancel();

      notification.success({
        message: 'Basestation update process initiated',
      });
      handleCancel();
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    }
  };
  const getVersionLists = async (
    build_category: any,
    tractor_model: string,
  ) => {
    try {
      const records = await getVersionList(
        userDetails.organization.api_url,
        userDetails.organization.id,
        build_category,
        tractor_model,
      );
      const data = records.map((record: updateVersionData) => {
        return {
          versionid: record.software_version_id,
          newVersion: record.software_version,
          releaseNotes: record.release_notes,
          isGoldBuild:
            record.build_info && record.build_info?.build_tag === 'gold'
              ? true
              : false,
        };
      });

      setSoftwareVesrion(data[0].newVersion);
      setSelectVersion(data[0]);
      setUpdatedVersion(data as []);
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    }
  };

  const handleSelectVersion = (version: any) => {
    const versionData = JSON.parse(version);
    setSoftwareVesrion(versionData.newVersion);
    setSelectVersion(versionData);
  };

  return (
    <div>
      <Modal
        closable={false}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={false}
        className="softwareInstall"
      >
        <div className="verInstallSec">
          <h4 className="verHistTxt mb12 posRel">
            BaseStation Update
            <img src={verM_logo} alt="mlogo" className="mLogo" height="33" />
          </h4>
          <div className="installDec">
            The update process will take 20-25 minutes and <b>{data?.name}</b>{' '}
            will be unavailable for use during that time. Please ensure you do
            not have any pending operations before starting installation.
          </div>

          <div className="instBtnSec">
            <Select
              style={{ width: 214, marginBottom: 20 }}
              className="dropdownStyle dW214"
              value={softwareVesrion}
              onSelect={(e: string) => handleSelectVersion(e)}
            >
              {updatedVersion.map((data: any, index: number) => {
                return (
                  <option key={index} value={JSON.stringify(data)}>
                    <div className="swOption">
                      {data.newVersion}{' '}
                      {data.isGoldBuild && (
                        <Tag color="gold" className="gold-tag">
                          gold
                        </Tag>
                      )}
                    </div>
                  </option>
                );
              })}
            </Select>
          </div>

          <div className="instBtnSec instBtn">
            <Space>
              <Button
                type="primary"
                className="installBtn"
                onClick={() => setshwoReleaseNotesFlag(!shwoReleaseNotesFlag)}
              >
                Release Notes
              </Button>
              <Button
                className="verCancelBtn mr0"
                onClick={() => handleCancel()}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                className="installBtn"
                onClick={() => updateSoftware()}
              >
                install
              </Button>
            </Space>
          </div>
          {shwoReleaseNotesFlag && (
            <div className="releaseNotes mb24">
              <div className="verNoteHead">Release Notes:</div>
              <div className="verDec2">
                <Interweave
                  content={
                    selectVersion
                      ? selectVersion?.releaseNotes
                      : data?.data?.software?.latest_software?.release_notes ||
                        '-'
                  }
                />
              </div>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};
