import { notification } from 'antd';
import useOperationalAnalyticDetailsStore from '../store/operationAnalyticDetails';
import {
  getEfficiencyData,
  getImplementTypeEfficiencyData,
  getOperationalHoursAndCoverage,
  getSavingsBasedOnOperation,
} from '../services/api';
import { useContext, useState } from 'react';
import { ApplicationContext } from '../../../context/AppContext';
import {
  fetchName,
  getOperationData,
  getTotalHours,
  getValue,
} from '../common';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useAnalyticsDetails = () => {
  const [completedWorkLoader, setCompletedWorkLoader] = useState(false);
  const [efficiencyChartLoader, setEfficiencyChartLoader] = useState(false);
  const [savingChartLoader, setSavingChartLoader] = useState(false);
  const [operationsLoader, setOperationsLoader] = useState(false);

  const {
    addCoverage,
    addOperationalHours,
    addCompletedWorkData,
    addEnergyCostSavings,
    addtotalSavings,
    addCo2Savings,
    addImplementTypeEfficiencyData,
    addNoImplementSummary,
    addOperationalHoursByOperations,
    addCoverageByOperations,
  } = useOperationalAnalyticDetailsStore();
  const { userDetails } = useContext(ApplicationContext);

  // const getValue = (item: any) => {
  //   return item ? item.replace(':', '.') : 0;
  // };

  const initAnalyticsData = async (
    fromDate: string,
    toDate: string,
    category: string,
  ) => {
    try {
      setSavingChartLoader(true);
      if (!userDetails?.organization) return false;
      const data = await getSavingsBasedOnOperation(fromDate, toDate, category);
      const { energy_saving_data, total_saving, total_co2_saved } = data;
      const formatData = energy_saving_data.map((item: any) => ({
        category: item.date,
        value: item.saving,
      }));
      addEnergyCostSavings(formatData);
      addtotalSavings(total_saving);
      addCo2Savings(total_co2_saved);
    } catch (error: any) {
      notification.error({ message: error?.message });
    } finally {
      setSavingChartLoader(false);
    }
  };

  const initCompletedWorkData = async (
    operationType = '',
    fromDate: string,
    toDate: string,
  ) => {
    try {
      setCompletedWorkLoader(true);
      addCompletedWorkData([]);
      if (!userDetails?.organization) return false;
      const data = await getEfficiencyData(operationType, fromDate, toDate);
      const { completed_work } = data;
      const fdata = completed_work
        .sort((a: any, b: any) => b.date - a.date)
        .map((item: any) => ({
          date: Number(item.date),
          duration: item.duration,
          tractor: item.tractor,
          tractorId: item?.tractor?.id,
          tractorPin: item?.tractor?.tractor_pin,
          tractorShortPin: item?.tractor
            ? item?.tractor?.tractor_pin?.slice(-5)
            : '',
          implement: item.implement_name,
          implementName: item.implement_name,
          coverage:
            item?.acres_covered && !isNaN(item?.acres_covered)
              ? item.acres_covered.toFixed(2)
              : '',
          operators: item.operators,
          distance: item?.distance,
        }));

      // "drive_action_uuid": "17126147340100",
      // "tractor_id": 1308,
      // "drive_action_start_date_time": 1712614734014,
      // "implement_name": "CORT\u00c1DOR\u00c1",
      // "operator_id": 1153,
      // "first_name": "Brian",
      // "last_name": "Hoefgen",
      // "tractor": {
      //     "id": 1308,
      //     "name": "B-10336",
      //     "serial_number": "PVO1336",
      //     "tractor_model": "PV-O",
      //     "tractor_pin": "MONPSMKVEPKA10336"
      // }
      addCompletedWorkData(fdata);
    } catch (error: any) {
      notification.error({ message: error?.message });
    } finally {
      setCompletedWorkLoader(false);
    }
  };

  const initImplementTypeEfficiencyData = async (
    fromDate: string,
    toDate: string,
    implementType: string,
  ) => {
    try {
      setEfficiencyChartLoader(true);
      if (!userDetails?.organization) return false;
      // const { organization } = userDetails;
      // const { api_url } = organization;
      const data = await getImplementTypeEfficiencyData(
        fromDate,
        toDate,
        implementType,
      );
      const { result, total_area, overall_implement_efficiency } = data;
      const efficiencyData = result.map((item: any) => ({
        category: item.date,
        value: item.implement_efficiency,
      }));
      addImplementTypeEfficiencyData(
        efficiencyData,
        overall_implement_efficiency,
        total_area,
      );
    } catch (error: any) {
      notification.error({ message: error?.message });
    } finally {
      setEfficiencyChartLoader(false);
    }
  };

  const initOperationalHoursAndCoverageData = async (
    from_date: string,
    to_date: string,
    imp_type?: string,
  ) => {
    try {
      setOperationsLoader(true);
      const response = await getOperationalHoursAndCoverage(
        from_date,
        to_date,
        imp_type,
      );
      // if (response?.coverage_and_op_hours_imp) {
      //   const { coverage_and_op_hours_imp } = response;
      //   const operationalHours = coverage_and_op_hours_imp.map((item: any) =>
      //     Object.assign({
      //       category: fetchName(item?.user_first_name, item?.user_last_name),
      //       total: getValue(item?.total_operational_hours),
      //       value1: getValue(item?.manual_hours),
      //       value2: getValue(item?.operator_assist_hours),
      //       value3: getValue(item?.autodrive_hours),
      //       value4: getValue(item?.ideal_hours),
      //     }),
      //   );
      //   const coverage = coverage_and_op_hours_imp.map((item: any) =>
      //     Object.assign({
      //       category: fetchName(item?.user_first_name, item?.user_last_name),
      //       total: item?.total_covered_area,
      //       value1: item?.manual_covered_area,
      //       value2: item?.operator_assist_covered_area,
      //       value3: item?.autodrive_covered_area,
      //       value4: item?.ideal_covered_area,
      //     }),
      //   );
      //   addOperationalHoursByOperators(operationalHours);
      //   addCoverageByOperators(coverage);
      // }
      if (response?.op_hours_and_cov_for_imp) {
        const {
          op_hours_and_cov_for_imp,
        }: { op_hours_and_cov_for_imp: any[] } = response;
        let operationalHoursData: any[] = [];
        if (op_hours_and_cov_for_imp.length) {
          const data = op_hours_and_cov_for_imp[0];
          const [key] = Object.keys(data);
          operationalHoursData = data[key] || [];
        }
        const operationalHours = operationalHoursData.map((item: any) => {
          return Object.assign({
            category: fetchName(
              item?.user_first_name,
              item?.user_last_name,
              true,
            ),
            total: getTotalHours(item?.total_operational_hours),
            value1: getValue(item?.manual_hours),
            value2: getValue(item?.operator_assist_hours),
            value3: getValue(item?.autodrive_hours),
            value4: getValue(item?.ideal_hours),
            time1: getTotalHours(item?.manual_hours),
            time2: getTotalHours(item?.operator_assist_hours),
            time3: getTotalHours(item?.autodrive_hours),
            time4: getTotalHours(item?.ideal_hours),
          });
        });
        const coverageByOperations = operationalHoursData.map((item: any) => {
          return Object.assign({
            category: fetchName(
              item?.user_first_name,
              item?.user_last_name,
              true,
            ),
            total: item?.total_covered_area_in_acre,
            value1: item?.total_covered_area_in_acre,
            // total: item?.total_covered_area,
            // value1: item?.manual_covered_area,
            // value2: item?.operator_assist_covered_area,
            // value3: item?.autodrive_covered_area,
            // value4: item?.ideal_covered_area,
          });
        });
        const allValuesAreMinutes = operationalHours.every((item: any) =>
          ['value1', 'value2', 'value3', 'value4'].every(
            (value) => item[value] < 1,
          ),
        );
        const processedData = getOperationData(
          operationalHours,
          allValuesAreMinutes,
        );
        addOperationalHoursByOperations(processedData);
        addCoverageByOperations(coverageByOperations);
      }
      if (response?.summary_op_hours_and_cov_for_imp) {
        const {
          combined_total_hours,
          combined_total_area,
          average_of_average_speed,
          combined_sum_of_total_distance,
          no_imp_efficiency,
          total_savings = 0,
        } = response.summary_op_hours_and_cov_for_imp;

        const operations = {
          hours: combined_total_hours?.split(':')?.[0],
          minutes: combined_total_hours?.split(':')?.[1],
        };
        addOperationalHours(operations);
        addCoverage(combined_total_area);
        addNoImplementSummary({
          avgSpeed: average_of_average_speed,
          distance: combined_sum_of_total_distance,
          efficiency: no_imp_efficiency,
          savings: total_savings,
        });
      }
    } catch (error: any) {
      notification.error({ message: error?.message });
    } finally {
      setOperationsLoader(false);
    }
  };

  return {
    operationsLoader,
    efficiencyChartLoader,
    savingChartLoader,
    completedWorkLoader,
    initAnalyticsData,
    initCompletedWorkData,
    initImplementTypeEfficiencyData,
    initOperationalHoursAndCoverageData,
  };
};

export default useAnalyticsDetails;
