import { Spin } from 'antd';
import React, { ReactNode } from 'react';
import loading_logo from '../../assets/images/loading_logo.gif';

interface Props {
  loader: boolean;
  chidren?: ReactNode;
}
const Spinner: React.FC<Props> = ({ children, loader }) => {
  const antIcon = (
    <img style={{ width: '35px', height: '35px' }} src={loading_logo} />
  );
  return (
    <Spin size="large" spinning={loader} indicator={antIcon}>
      {children}
    </Spin>
  );
};

export default Spinner;
