import moment from 'moment';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Button, DatePicker, Row, message, notification } from 'antd';
import Layout, { Content } from 'antd/lib/layout/layout';
import translate from '../../../locale/en_translate.json';
import InfiniteScrollTable from '../../common/InfiniteScrollTable';
import usePaginate from '../../../hooks/usePaginate';
import { ApplicationContext } from '../../../context/AppContext';
import { initScroller } from '../../../constants/Common';
import { getOrgTractorUsageReport } from '../API';
import { useTranslation } from 'react-i18next';
import { dateRangeFilters } from '../../../constants/AppData';
import CropOrgFilter from '../../common/CropOrgFilter';
import CustomSelect from '../../common/CustomSelect';

import { mapTractorUsageData } from '../../../lib/dataFormat';
import './style.css';
import useColumns from '../../../hooks/useColumns';
import TableExt from '../../widget/TableExt';

const { RangePicker } = DatePicker;

let columnsData: any;
const title = 'OrgTractorUsage';

const OrgTractorUsageReport: React.FC = () => {
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [selectedOrgId, setSelectedOrgId] = useState<string[]>([]);
  const [toggleColumnsStatus, setToggleColumnsStatus] = useState(false);
  const [total, setTotal] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [summary, setSummary] = useState<any>(null);
  const { filterData, setData, activity, hasMore, checkActivity } =
    usePaginate();
  const [sortedInfo, setSortedInfo] = useState<any>();
  const init = useRef<boolean>(true);
  const [downloadData, setDownloadData] = useState({
    isDownloaded: false,
    percent: 0,
    status: '',
  });
  const [filterColumnsData, setFilterColumnsData] = useState([]);
  const [totalCol, setTotalCol] = useState<any>();
  const [csvData, setCSVData] = useState<any[]>([]);
  const { headers, columns, setColumns, setColumnsData } =
    useColumns('TractorUsage');

  const [filter, setFilter] = useState<string>('Monthly');
  const [filterObj, setFilterObj] = useState({
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    from_date: moment().startOf('month').format('YYYY-MM-DD'),
    to_date: moment().endOf('month').format('YYYY-MM-DD'),
    type: filter,
  });

  const wrapResult = async (
    fromDate: string,
    toDate: string,
    selectedOrgId: string[],
  ) => {
    const result = await getOrgTractorUsageReport(
      fromDate,
      toDate,
      filter,
      selectedOrgId,
    );
    return {
      result,
    };
  };

  const getTractorsList = async () => {
    if (!userDetails?.organization || selectedOrgId.length === 0) return;
    try {
      if (checkActivity()) initScroller();
      setLoading(true);
      const { result } = await wrapResult(
        filterObj.from_date,
        filterObj.to_date,
        selectedOrgId,
      );

      // eslint-disable-next-line no-underscore-dangle

      const { tractor_data, summary } = result;
      let data = Array.isArray(tractor_data) ? tractor_data : [];
      data = mapTractorUsageData(data);
      setData(data);
      setSummary(summary);
      setTotal(data?.length);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const loadSummary = useCallback(
    () => (
      <>
        {!!filterData.length && summary && totalCol && (
          <tr>
            {totalCol.length > 0 && (
              <td style={{ textAlign: 'center' }}>
                <strong>{t(translate.tractorusagereport.grandTotal)}</strong>
              </td>
            )}
            {totalCol.includes('distance') && <td>{summary.total_distance}</td>}
            {totalCol.includes('energy_used') && (
              <td>{summary.total_energy_used}</td>
            )}
            {totalCol.includes('co2_saved') && (
              <td>{summary.total_co2_saved}</td>
            )}
            {totalCol.includes('human_detections') && (
              <td>{summary.total_human_detections}</td>
            )}
            {totalCol.includes('manual_hours') && (
              <td>{summary.total_manual_hours}</td>
            )}
            {totalCol.includes('follow_me_hours') && (
              <td>{summary.total_follow_me_hours}</td>
            )}
            {totalCol.includes('vinerow_hours') && (
              <td>{summary.total_vinerow_hours}</td>
            )}
            {totalCol.includes('copycat_hours') && (
              <td>{summary.total_copycat_hours}</td>
            )}
            {totalCol.includes('remote_av_hours') && (
              <td>{summary.total_remote_av_hours}</td>
            )}
            {totalCol.includes('autonomy_hours') && (
              <td>{summary.total_autonomy_hours}</td>
            )}
            {totalCol.includes('driving_hours') && (
              <td>{summary.total_driving_hours}</td>
            )}
            {totalCol.includes('standby_hours') && (
              <td>{summary.total_standby_hours}</td>
            )}
          </tr>
        )}
      </>
    ),
    [totalCol, summary, filterData],
  );

  const loadData = () => {
    if (userDetails && !userDetails.organization) return;
    if (checkActivity()) {
      // eslint-disable-next-line no-unused-expressions
      pageNumber !== 1 ? setPageNumber(1) : getTractorsList();
    } else if (activity.current === 'paginate' || activity.current === '') {
      getTractorsList();
    }
  };

  const handleRefresh = () => {
    activity.current = 'refresh';
    loadData();
  };

  useEffect(() => {
    if (
      userDetails &&
      userDetails.organization &&
      selectedOrgId.length > 0 &&
      init.current
    ) {
      loadData();
      init.current = false;
    }
  }, [userDetails, selectedOrgId]);

  useEffect(() => {
    loadData();
  }, [sortedInfo]);

  useEffect(() => {
    columnsData = [
      {
        title: `${t(translate.tractorusagereport.customer_name)}`,
        dataIndex: 'customer_name',
        key: 'customer_name',
        ellipsis: true,
        sorter: (a: { customer_name: string }, b: { customer_name: string }) =>
          a.customer_name.localeCompare(b.customer_name),
        width: '190px',
        render: (customer_name: string) => (
          <span data-testid={`${customer_name}-TractorUsageReport`}>
            {customer_name}
          </span>
        ),
        defaultSortOrder: 'ascend',
        sortDirections: ['descend', 'ascend', 'descend'],
      },
      {
        title: `${t(translate.tractorusagereport.kmsdriven)}`,
        dataIndex: 'distance',
        key: 'distance',
        width: '155px',
        render: (distance: string) => (
          <span data-testid={`${distance}-TractorUsageReport`}>{distance}</span>
        ),
        sorter: (a: { distance: number }, b: { distance: number }) =>
          a.distance - b.distance,
      },
      {
        title: `${t(translate.tractorusagereport.energyused)}`,
        dataIndex: 'energy_used',
        key: 'energy_used',
        width: '175px',
        render: (energy_used: string) => (
          <span data-testid={`${energy_used}-TractorUsageReport`}>
            {energy_used}
          </span>
        ),
        sorter: (a: { energy_used: number }, b: { energy_used: number }) =>
          a.energy_used - b.energy_used,
      },
      {
        title: `${t(translate.tractorusagereport.co2saved)}`,
        dataIndex: 'co2_saved',
        key: 'co2_saved',
        width: '155px',
        render: (co2_saved: string) => (
          <span data-testid={`${co2_saved}-TractorUsageReport`}>
            {co2_saved}
          </span>
        ),
        sorter: (a: { co2_saved: number }, b: { co2_saved: number }) =>
          a.co2_saved - b.co2_saved,
      },
      {
        title: `${t(translate.tractorusagereport.humandetections)}`,
        dataIndex: 'human_detections',
        key: 'human_detections',
        width: '175px',
        render: (human_detections: string) => (
          <span data-testid={`${human_detections}-TractorUsageReport`}>
            {human_detections}
          </span>
        ),
        sorter: (
          a: { human_detections: number },
          b: { human_detections: number },
        ) => a.human_detections - b.human_detections,
      },
      {
        title: `${t(translate.tractorusagereport.manualhrs)}`,
        dataIndex: 'manual_hours',
        key: 'manual_hours',
        width: '155px',
        sorter: (
          a: { manual_hours_num: number },
          b: { manual_hours_num: number },
        ) => a.manual_hours_num - b.manual_hours_num,
        render: (manual_hours: string) => (
          <span data-testid={`${manual_hours}-TractorUsageReport`}>
            {manual_hours}
          </span>
        ),
      },
      {
        title: `${t(translate.tractorusagereport.shadowHrs)}`,
        dataIndex: 'follow_me_hours',
        key: 'follow_me_hours',
        width: '155px',
        sorter: (
          a: { follow_me_hours_num: number },
          b: { follow_me_hours_num: number },
        ) => a.follow_me_hours_num - b.follow_me_hours_num,
        render: (follow_me_hours: string) => (
          <span data-testid={`${follow_me_hours}-TractorUsageReport`}>
            {follow_me_hours}
          </span>
        ),
      },
      {
        title: `${t(translate.tractorusagereport.rowFollowHrs)}`,
        dataIndex: 'vinerow_hours',
        key: 'vinerow_hours',
        width: '155px',
        sorter: (
          a: { vinerow_hours_num: number },
          b: { vinerow_hours_num: number },
        ) => a.vinerow_hours_num - b.vinerow_hours_num,
        render: (vinerow_hours: string) => (
          <span data-testid={`${vinerow_hours}-TractorUsageReport`}>
            {vinerow_hours}
          </span>
        ),
      },
      {
        title: `${t(translate.tractorusagereport.copyCatHrs)}`,
        dataIndex: 'copycat_hours',
        key: 'copycat_hours',
        width: '195px',
        sorter: (
          a: { copycat_hours_num: number },
          b: { copycat_hours_num: number },
        ) => a.copycat_hours_num - b.copycat_hours_num,
        render: (copycat_hours: string) => (
          <span data-testid={`${copycat_hours}-TractorUsageReport`}>
            {copycat_hours}
          </span>
        ),
      },
      {
        title: `${t(translate.tractorusagereport.autoDriveHrs)}`,
        dataIndex: 'remote_av_hours',
        key: 'remote_av_hours',
        sorter: (
          a: { remote_av_hours_num: number },
          b: { remote_av_hours_num: number },
        ) => a.remote_av_hours_num - b.remote_av_hours_num,
        render: (remote_av_hours: string) => <span>{remote_av_hours}</span>,
        width: '155px',
      },
      {
        title: `${t(translate.tractorusagereport.autonomyhrs)}`,
        dataIndex: 'autonomy_hours',
        key: 'autonomy_hours',
        width: '155px',
        sorter: (a: { autonomy_num: number }, b: { autonomy_num: number }) =>
          a.autonomy_num - b.autonomy_num,
        render: (autonomy: string) => (
          <span data-testid={`${autonomy}-TractorUsageReport`}>{autonomy}</span>
        ),
      },
      {
        title: `${t(translate.tractorusagereport.drivingHrs)}`,
        dataIndex: 'driving_hours',
        key: 'driving_hours',
        width: '155px',
        sorter: (
          a: { driving_hours_num: number },
          b: { driving_hours_num: number },
        ) => a.driving_hours_num - b.driving_hours_num,
        render: (driving_hours: string) => (
          <span data-testid={`${driving_hours}-TractorUsageReport`}>
            {driving_hours}
          </span>
        ),
      },
      {
        title: `${t(translate.tractorusagereport.idlehrs)}`,
        dataIndex: 'standby_hours',
        key: 'standby_hours',
        width: '155px',
        sorter: (
          a: { standby_hours_num: number },
          b: { standby_hours_num: number },
        ) => a.standby_hours_num - b.standby_hours_num,
        render: (standby_hours: string) => (
          <span data-testid={`${standby_hours}-TractorUsageReport`}>
            {standby_hours}
          </span>
        ),
      },
    ];

    // const tcols = columns.map((item: any) => item.key);
    // setTotalCol(tcols);

    const data1 = columnsData.slice(2);
    setFilterColumnsData(data1);
    setColumnsData(columnsData);

    const tcols = columnsData.map((item: any) => item.key);
    setTotalCol(tcols);

    return () => {
      setData([]);
      setTotal(0);
      setSummary(null);
      setColumns([]);
    };
  }, []);

  const handleChange = (pagination: any, filters: any, sorter: any) => {
    if (sorter.column && sorter.column.sorter === true) {
      activity.current = 'filter';
      const { columnKey, order } = sorter;
      setSortedInfo({
        sort_column: columnKey,
        sort_order: order === 'ascend' ? 'asc' : 'desc',
      });
    }
  };

  const handleOrg = (ids: any) => setSelectedOrgId(ids);

  const handleFilter = (value: string) => {
    const [from_date, to_date] = getDatesByWeek();

    if (value === 'Daily') {
      const [from_date, to_date] = getDatesByWeek();
      setFilterObj({ ...filterObj, from_date, to_date, type: value });
    }
    if (value === 'Monthly') {
      const from_date = moment(`${filterObj.year}-${filterObj.month}`)
        .startOf('month')
        .format('YYYY-MM-DD');
      const to_date = moment(`${filterObj.year}-${filterObj.month}`)
        .endOf('month')
        .format('YYYY-MM-DD');
      setFilterObj({ ...filterObj, from_date, to_date, type: value });
    }
    setFilter(value);
  };

  const disabledDates = (current: any) => {
    return (
      (current && current > moment()) ||
      (current && current <= moment('12-31-2022'))
    );
  };

  const onMonthly = (date: any) => {
    if (date) {
      const year = date.format('YYYY');
      const month = date.format('MM');
      const from_date = moment(date).startOf('month').format('YYYY-MM-DD');
      const to_date = moment(date).endOf('month').format('YYYY-MM-DD');
      setFilterObj({
        ...filterObj,
        year: Number(year),
        month: Number(month),
        from_date,
        to_date,
        type: 'Monthly',
      });
    }
  };

  const getDatesByWeek = (weekNumber = moment().week()) => {
    const from_date = moment()
      .day('Sunday')
      .week(weekNumber)
      .format('YYYY-MM-DD');
    const to_date = moment(from_date).add('6', 'days').format('YYYY-MM-DD');
    return [from_date, to_date];
  };

  const onDateRangeChange = (dates: any) => {
    const [from_date, to_date] = dates;
    setFilterObj({
      ...filterObj,
      from_date: from_date.format('YYYY-MM-DD'),
      to_date: to_date.format('YYYY-MM-DD'),
      type: 'Daily',
    });
  };

  const handleSubmit = () => {
    activity.current = 'filter';
    if (userDetails?.organization && selectedOrgId.length) {
      loadData();
    } else {
      message.warning('Please select Crop');
    }
  };

  const handleDownload = async () => {
    try {
      if (downloadData.status === 'start') return;
      setDownloadData({
        ...downloadData,
        status: 'start',
        percent: 10,
      });
      let data: any[] = [];
      // const pages = Math.ceil(total / DOWNLOAD_SIZE);
      // for (const page of Array.from({ length: pages }, (_, i) => i + 1)) {
      const { result } = await wrapResult(
        filterObj.from_date,
        filterObj.to_date,
        selectedOrgId,
      );

      const { tractor_data } = result;
      let tdata = Array.isArray(tractor_data) ? tractor_data : [];
      tdata = mapTractorUsageData(tdata);

      data = [...data, ...tdata];
      setDownloadData({
        ...downloadData,
        status: 'start',
        percent: Math.round((data.length / total) * 100),
      });
      // }

      setCSVData([...data]);
      setDownloadData({
        ...downloadData,
        percent: 100,
        status: 'success',
      });
    } catch (error: any) {
      setDownloadData({
        ...downloadData,
        status: 'exception',
      });
      notification.error({ message: error.message });
    }
  };

  useEffect(() => {
    const tColumns = columns.map((item: any) => item.key);
    setTotalCol([...tColumns]);
  }, [columns]);

  return (
    <Layout className="posRel">
      <Content>
        <div
          className="viewportContainer"
          onClick={() => setToggleColumnsStatus(!toggleColumnsStatus)}
        >
          <Row>
            <div className="common_wrapper tab2FilterWidget_wrapper">
              <div className="filters_card ad_filters_card filtersHealthScreen">
                <CropOrgFilter handleOrgs={handleOrg} isDealer />
                <CustomSelect
                  label="Filters"
                  cssClass="ad_min_width"
                  value={filter}
                  setValue={handleFilter}
                  options={dateRangeFilters}
                  optionKey="value"
                  optionDisplay="name"
                />
                {filter === 'Monthly' && (
                  <DatePicker
                    allowClear={false}
                    format="MMM-YYYY"
                    value={moment(`${filterObj.year}-${filterObj.month}`)}
                    onChange={onMonthly}
                    disabledDate={disabledDates}
                    picker="month"
                  />
                )}
                {filter === 'Daily' && (
                  <RangePicker
                    allowClear={false}
                    value={[
                      moment(filterObj.from_date),
                      moment(filterObj.to_date),
                    ]}
                    onChange={onDateRangeChange}
                    disabledDate={disabledDates}
                  />
                )}
                <Button
                  className="submitBtn"
                  onClick={handleSubmit}
                  loading={loading}
                >
                  {t(translate.buttons.submit)}
                </Button>
                <TableExt
                  handleRefresh={handleRefresh}
                  handleDownload={handleDownload}
                  downloadData={downloadData}
                  csvHeaders={headers}
                  csvData={csvData}
                  csvFilename={title}
                  sortColumns={['created_date_time', 'name']}
                  tableName="TractorUsage"
                  handleCallback={(args: any[]) => {
                    setColumns([columnsData[0], ...args]);
                  }}
                  columnsData={filterColumnsData}
                  toggleColumnsStatus={toggleColumnsStatus}
                />
              </div>
              <div className="common_table fullHeight orgTractorUsageReportTable">
                <InfiniteScrollTable
                  columns={columns}
                  loading={loading}
                  filterData={filterData}
                  totalcount={total}
                  filename={
                    total > 0 ? 'Fleet Usage Reports' : 'Fleet Usage Report'
                  }
                  onChange={handleChange}
                  summary={loadSummary}
                />
              </div>
            </div>
          </Row>
        </div>
      </Content>
    </Layout>
  );
};

export default OrgTractorUsageReport;
