/* eslint-disable no-console */
import React, { useContext, useEffect } from 'react';
import pauseTask from '../../assets/images/pause.svg';
import { checkTractorDrivingState } from '../../constants/Common';
import { ApplicationContext } from '../../context/AppContext';
import { RemoteDriveAppCtx } from './RemoteDriveContext';

import endBtn from '../../assets/images/end_disconnect.svg';
import playBtn from '../../assets/images/playBtn.svg';
import { PAUSE, RESUME } from '../../constants/constant';

interface Props {
  showDeleteConfirm: (tractorId: number) => void;
}
const RemoteDriveActionsNewDesign: React.FC<Props> = ({
  showDeleteConfirm,
}) => {
  const { userDetails } = useContext(ApplicationContext);
  const { RDReducer, handlePauseResumeStopLiveCom } =
    useContext(RemoteDriveAppCtx);
  const [state] = RDReducer;
  const { selectedTractor } = state;
  const { heartbeat } = selectedTractor;
  return (
    <>
      {checkTractorDrivingState(heartbeat, userDetails.id) &&
        heartbeat?.planning_manager?.planner_status?.length > 0 && (
          <>
            <div
              className={`adConnectBtnSec ${
                selectedTractor?.heartbeat?.planning_manager?.planner_status !==
                'paused'
                  ? 'adConnectBtnSecJustify'
                  : ''
              }`}
            >
              {selectedTractor?.heartbeat?.planning_manager?.planner_status ===
              'paused' ? (
                <>
                  <button
                    className="btnBlock"
                    onClick={() =>
                      handlePauseResumeStopLiveCom(
                        RESUME,
                        selectedTractor?.heartbeat,
                        'Click',
                      )
                    }
                  >
                    <img src={playBtn} alt="Playbutton" />
                    <span>Resume</span>
                  </button>
                  <button
                    className="btnBlock"
                    onClick={() => showDeleteConfirm(selectedTractor.id)}
                  >
                    <img src={endBtn} alt="Resumebutton" />
                    <span>End and Disconnect</span>
                  </button>
                </>
              ) : (
                <button
                  className="btnBlock op-pause-btn"
                  onClick={() =>
                    handlePauseResumeStopLiveCom(
                      PAUSE,
                      selectedTractor?.heartbeat,
                      'Click',
                    )
                  }
                >
                  <img src={pauseTask} alt="Playbutton" />
                  <span className="pause-txt">Pause Operation</span>
                </button>
              )}
            </div>
          </>
        )}
    </>
  );
};

export default RemoteDriveActionsNewDesign;
