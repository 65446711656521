/* eslint-disable no-console */
/* eslint-disable react/jsx-no-undef */
import React, { useContext, useEffect, useRef, useState } from 'react';
import LaborCostsBar from './labor_costs/LaborCostsBar';
import 'antd/dist/antd.css';
import './chartsStyles.css';
import FleetUsageBarCharts from './FleetUsageBarCharts';
import { Tabs } from 'antd';
import ElectrictyCost from './electricity_costs/ElectricityCostsBar';
import { ApplicationContext } from '../../context/AppContext';
import { getEnergyChartData, getlaborInBarChjarts } from '../../constants/Api';
const { TabPane } = Tabs;

const FleetUsageCharts: React.FC = () => {
  const { userDetails } = useContext(ApplicationContext);
  const [tabid, setTabid] = useState<any>('1');
  const [day, setday] = useState<any>('30');
  const [energyData, setEneryData] = useState<any>([]);
  const [labourData, setLabourData] = useState<any>([]);
  // const [savingsData, setSavingsData] = useState<any>(null);
  const [allData, setAllData] = useState<any>(null);
  const [energryLoader, setEnergyLoader] = useState<boolean>(true);
  const [labourLoader, setLabourLoader] = useState<boolean>(true);
  const [allLoader, setAllLoader] = useState<boolean>(true);
  const init = useRef<boolean>(false);

  const loadAllDay = async (type = '', day = '30') => {
    try {
      if (type === '') {
        setAllLoader(true);
        const data = await getElectricityCosts();
        const labourData = await getLaborCharts();
        if (labourData) setLabourData(labourData);
        if (data) {
          const { energyData } = data;
          setEneryData(energyData);
        }
        if (data && labourData) {
          const { energyData, savings: savingsData } = data;
          if (
            labourData &&
            labourData?.bar_chart_data_list &&
            energyData &&
            savingsData
          )
            setAllData({
              labourData: labourData?.bar_chart_data_list,
              energyData,
              savingsData,
            });
        }
      } else if (type === 'labour') {
        const labourData = await getLaborCharts(day);
        if (labourData) setLabourData(labourData);
      } else if (type === 'energy') {
        const data = await getElectricityCosts(day);
        if (data) {
          const { energyData } = data;
          setEneryData(energyData);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setAllLoader(false);
      init.current = true;
    }
  };

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      loadAllDay();
    }
    // return () => {
    //   setAllData(null);
    //   setEneryData([]);
    //   setLabourData([]);
    // };
  }, [userDetails, day]);

  const getElectricityCosts = async (filter = '') => {
    setEnergyLoader(true);
    try {
      const { energy_data, total_energy_used, total_savings }: any =
        await getEnergyChartData(
          userDetails.organization.api_url,
          userDetails.organization.id,
          filter?.trim() === '' ? day : filter,
        );
      let tenergyData = [];
      if (energy_data) {
        tenergyData = energy_data.map((item: any) => {
          return {
            date: item.date.split(',')[0],
            year: item.date.split(',')[1]?.trim(''),
            total: item.value,
            energy_used: item.energy_used,
          };
        });
        // setEneryData(tenergyData);
        // setSavingsData({
        //   total_energy_used,
        //   total_savings,
        // });
        return {
          energyData: tenergyData,
          savings: {
            total_energy_used,
            total_savings,
          },
        };
      }
    } catch (error: any) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setEnergyLoader(false);
    }
  };

  const getLaborCharts = async (filter = '') => {
    setLabourLoader(true);
    try {
      const laborcost: any = await getlaborInBarChjarts(
        userDetails.organization.api_url,
        userDetails.organization.id,
        filter?.trim() === '' ? day : filter,
      );
      // if (laborcost) setLabourData(laborcost);
      return laborcost;
    } catch (error: any) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setLabourLoader(false);
    }
  };

  const mapEnergyData = (energyData: any[]) =>
    energyData.map((item: any) => {
      return {
        date: item.date.split(',')[0],
        year: item.year,
        'Energy Cost': item.total,
        energy_used: item.energy_used,
      };
    });

  return (
    <>
      <Tabs
        className="chartsTabs"
        activeKey={tabid}
        onChange={(key) => {
          setTabid(key);
        }}
      >
        <TabPane tab="All" key="1">
          <div className="chartTabsContainer">
            {day && tabid && (
              <FleetUsageBarCharts
                data={allData}
                setTabid={setTabid}
                day={day}
                setday={(day) => {
                  init.current = false;
                  setday(day);
                }}
                loader={allLoader}
              ></FleetUsageBarCharts>
            )}
          </div>
        </TabPane>
        <TabPane tab="Energy" key="2">
          <div className="chartTabsContainer">
            {day && tabid && (
              <ElectrictyCost
                data={mapEnergyData(energyData)}
                day={day}
                setTabid={setTabid}
                setday={(filter) => loadAllDay('energy', filter)}
                loader={energryLoader}
                init={init}
              ></ElectrictyCost>
            )}
          </div>
        </TabPane>
        <TabPane tab="Labor" key="3">
          <div className="chartTabsContainer">
            {day && tabid && (
              <LaborCostsBar
                data={labourData}
                day={day}
                setTabid={setTabid}
                setday={(filter) => loadAllDay('labour', filter)}
                loader={labourLoader}
                init={init}
              ></LaborCostsBar>
            )}
          </div>
        </TabPane>
      </Tabs>
    </>
  );
};

export default FleetUsageCharts;
