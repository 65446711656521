/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-underscore-dangle */
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { Layout, Modal, notification, Row, Space, Tooltip } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import translate from '../../../../locale/en_translate.json';
import { ApplicationContext } from '../../../../context/AppContext';
import RoutesConstants from '../../../../routes/RoutesConstant';

import EditReleaseNotes from './EditReleaseNotes';
import edit_icon from '../../../../assets/images/nEdit.svg';
import deleteIcon from '../../../../assets/images/del_icon.svg';
import SwPublishModal from './SwPublishModal';
import { initScroller } from '../../../../constants/Common';
import usePaginate from '../../../../hooks/usePaginate';
import AddButton from '../../../common/AddButton';
import TableExt from '../../../widget/TableExt';
import InfiniteScrollTable from '../../../common/InfiniteScrollTable';
import constants from '../../../../constants/constant';
import { deleteChapter, getReleaseNotes } from '../../API';
import Manual from '../UserManual/Manual';
import pub_icon from '../../../../assets/images/pub_icon.svg';
import AddReleaseNotes from './AddReleaseNotes';

const { DOWNLOAD_SIZE } = constants;
let columns: any = [];

const ReleaseNotes: React.FC = () => {
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);

  const [selectedChapter, setSelectedChapter] = useState<any>();
  const [chapterPublish, setChapterPublish] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const { push } = useHistory();
  const [showAddReleaseNotes, setShowAddReleaseNotes] = useState(false);
  const [showEditReleaseNotes, setShowEditReleaseNotes] = useState(false);

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize] = useState<number>(25);
  const [total, setTotal] = useState<number>(0);
  const [initialize, setInitialize] = useState<boolean>(false);
  const [headers, setHeaders] = useState<any>([]);
  const tableRef = useRef<any>(null);
  const [csvData, setCSVData] = useState<any[]>([]);
  const [sortInfo, setSortInfo] = useState(null);
  const [downloadData, setDownloadData] = useState({
    isDownloaded: false,
    percent: 0,
    status: '',
  });
  const [toggleColumnsStatus, setToggleColumnsStatus] =
    useState<boolean>(false);
  const [showManual, setShowManual] = useState<boolean>(false);
  const [manualrecord, setManualrecord] = useState<any>();

  const { filterData, setData, activity, hasMore, checkActivity } =
    usePaginate();

  useEffect(() => {
    loadUserManual();
  }, [userDetails, pageNumber, pageSize, sortInfo]);

  const loadUserManual = () => {
    if (userDetails && !userDetails.organization) return;
    if (checkActivity()) {
      // eslint-disable-next-line no-unused-expressions
      pageNumber !== 1 ? setPageNumber(1) : getChapters();
    } else if (activity.current === 'paginate' || activity.current === '') {
      getChapters();
    }
  };

  const wrapResult = async (pageNumber: number, pageSize: number) => {
    const result = await getReleaseNotes(pageNumber, pageSize, false, sortInfo);
    return {
      result,
    };
  };

  const getChapters = async () => {
    try {
      if (checkActivity()) initScroller();
      setLoader(true);
      const { result } = await wrapResult(pageNumber, pageSize);

      setTotal(result._metadata.total_records_count);
      const { records } = result;
      const data = Array.isArray(records) ? records : [];
      setData(data);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    } finally {
      setLoader(false);
      if (!initialize) setInitialize(true);
    }
  };

  const handleLoadMore = () => {
    if (pageNumber === 1 && checkActivity() && document) initScroller();

    activity.current = 'paginate';
    setPageNumber(pageNumber + 1);
  };

  const deleteChapterById = async (id: number) => {
    try {
      setLoader(true);
      const { title } = await deleteChapter(id);
      notification.success({
        message: `${title} ${t(translate.releaseNotes.deleteSuccessfully)}`,
      });
      handleRefresh();
    } catch (err: any) {
      notification.error({
        message: err?.message,
      });
    } finally {
      setLoader(false);
    }
  };

  const handleOnClick = (record: any) => {
    setManualrecord(record);
    setShowManual(true);
  };

  const confirm = (record: any) => {
    Modal.confirm({
      title: `${t(translate.Oem.youWantToDeleteThe)} ${record.title}?`,
      icon: <ExclamationCircleOutlined />,
      // content: 'Some descriptions',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => deleteChapterById(record.id),
    });
  };

  const handleChapterPublish = (record: any) => {
    setSelectedChapter(record);
    setChapterPublish(true);
  };

  const handleChapterEdit = (record: any) => {
    setSelectedChapter(record);
    setShowEditReleaseNotes(true);
  };

  useEffect(() => {
    columns = [
      {
        title: `${translate.howToManual.title}`,
        dataIndex: 'title',
        width: '150px',
        render: (title: string) => <span>{title}</span>,
        onCell: (record: any) => ({
          onClick: () => handleOnClick(record),
        }),
      },
      {
        title: `${translate.myTask.description}`,
        dataIndex: 'description',
        width: '150px',
        onCell: (record: any) => ({
          onClick: () => handleOnClick(record),
        }),
        render: (description: string) => (
          <div className="description">
            <Tooltip title={description} className="emailStyle">
              {description}
            </Tooltip>
          </div>
        ),
      },

      {
        title: `${t(translate.releaseNotes.oemCode)}`,
        dataIndex: 'oem_code',
        width: '150px',
        onCell: (record: any) => ({
          onClick: () => handleOnClick(record),
        }),
      },
      {
        title: `${t(translate.releaseNotes.assignedSoftwares)}`,
        dataIndex: 'software_version_numbers',
        width: '180px',
        render: (softwareVersion: number) => (
          <div>{softwareVersion || '-'}</div>
        ),
        onCell: (record: any) => ({
          onClick: () => handleOnClick(record),
        }),
      },
      {
        title: `${t(translate.baseStation.status)}`,
        dataIndex: 'published_status',
        width: '150px',
        // render: (text: any) => getDateTime(text),
        render: (published_status: any) => (
          <>{published_status ? 'Published' : 'UnPublished'}</>
        ),
        onCell: (record: any) => ({
          onClick: () => handleOnClick(record),
        }),
      },
      {
        title: `${t(translate.updateManager.action)}`,
        dataIndex: 'published_status',
        width: '150px',
        render: (published_status: any, record: any) => (
          <>
            <Space>
              {!published_status && (
                <img
                  src={pub_icon}
                  onClick={() => handleChapterPublish(record)}
                  height="24"
                  width="24"
                  alt="publish"
                />
                // <CheckCircleOutlined
                //   style={{
                //     fontSize: '20px',
                //   }}
                //   onClick={() => handleChapterPublish(record)}
                // />
              )}
              <img
                src={edit_icon}
                alt="Edit"
                height="24"
                width="24"
                onClick={() => handleChapterEdit(record)}
                className="imgStyle"
              />
              <img
                src={deleteIcon}
                alt="Delete"
                height="24"
                width="24"
                className=""
                onClick={() => confirm(record)}
              />
            </Space>
          </>
        ),
      },
    ];

    const header: any = [];
    if (columns) {
      columns.map((column: any) =>
        header.push({
          label: column.title,
          key: column.dataIndex,
        }),
      );
      setHeaders(header);
    }
  }, []);

  const handleRefresh = () => {
    activity.current = 'refresh';
    loadUserManual();
  };
  const handleAdd = () => setShowAddReleaseNotes(true);

  const closeAddReleaseNotes = (state = false) => {
    setShowAddReleaseNotes(false);
    if (state) handleRefresh();
  };

  const closeEditReleaseNotes = (state = false) => {
    setShowEditReleaseNotes(false);
    if (state) handleRefresh();
  };

  const closeReleaseNotesPublish = (state = false) => {
    setChapterPublish(false);
    if (state) handleRefresh();
  };

  const handleDownload = async () => {
    try {
      if (downloadData.status === 'start') return;
      setDownloadData({
        ...downloadData,
        status: 'start',
        percent: 10,
      });
      let data: any[] = [];
      const pages = Math.ceil(total / DOWNLOAD_SIZE);
      // eslint-disable-next-line no-restricted-syntax
      for (const page of Array.from({ length: pages }, (_, i) => i + 1)) {
        // eslint-disable-next-line no-await-in-loop
        const { result }: any = await wrapResult(page, DOWNLOAD_SIZE);
        const { records }: any = result;
        const tdata = Array.isArray(records) ? records : [];
        data = [...data, ...tdata];
        setDownloadData({
          ...downloadData,
          status: 'start',
          percent: Math.round((data.length / total) * 100),
        });
      }

      setCSVData([...data]);
      setDownloadData({
        ...downloadData,
        percent: 100,
        status: 'success',
      });
    } catch (error: any) {
      setDownloadData({
        ...downloadData,
        status: 'exception',
      });
      notification.error({ message: error.message });
    }
  };
  const handleSort = (value: any) => {
    activity.current = 'sort';
    setSortInfo(value);
  };

  const closeManual = (state = false) => {
    setShowManual(false);
    if (state) handleRefresh();
  };

  return (
    <>
      {!showManual && (
        <Layout onClick={() => setToggleColumnsStatus(!toggleColumnsStatus)}>
          <div className="mainContent">
            <div className="tblContainer viewportContainer">
              <Row>
                <div className="common_wrapper tabFilterWidget_wrapper customer_wrapper">
                  <div className="filters_card personnelCard">
                    <AddButton
                      label="Add"
                      onClick={() => {
                        setShowAddReleaseNotes(true);
                      }}
                    />

                    <TableExt
                      handleRefresh={handleRefresh}
                      handleDownload={handleDownload}
                      downloadData={downloadData}
                      csvHeaders={headers}
                      csvData={csvData}
                      csvFilename="ReleaseNotes"
                      sortColumns={['created_date_time', 'name']}
                      tableRef={tableRef}
                      tableName="ReleaseNotes"
                      toggleColumnsStatus={toggleColumnsStatus}
                    />
                  </div>

                  <div className="common_table">
                    <InfiniteScrollTable
                      filterData={filterData}
                      columns={columns}
                      handleLoadMore={handleLoadMore}
                      hasMore={hasMore}
                      loading={loader}
                      totalcount={total}
                      filename="Release Notes"
                    />
                  </div>
                </div>
              </Row>
            </div>
          </div>
          {showAddReleaseNotes && (
            <AddReleaseNotes
              close={closeAddReleaseNotes}
              showModel={showAddReleaseNotes}
            />
          )}
          {showEditReleaseNotes && (
            <EditReleaseNotes
              close={closeEditReleaseNotes}
              showModel={showEditReleaseNotes}
              chapter={selectedChapter}
            />
          )}
          {chapterPublish && (
            <SwPublishModal
              showModal={chapterPublish}
              selectedChapter={selectedChapter}
              close={closeReleaseNotesPublish}
            />
          )}
        </Layout>
      )}

      {showManual && manualrecord && (
        <Manual
          close={closeManual}
          showModel={showManual}
          manualrecord={manualrecord}
        />
      )}
    </>
  );
};

export default ReleaseNotes;
