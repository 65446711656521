/* eslint-disable prefer-const */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { useTranslation } from 'react-i18next';
import loading_logo from '../../assets/images/loading_logo.gif';
import { BasicTooltip } from '@nivo/tooltip';
import 'antd/dist/antd.css';
import './chartsStyles.css';
import { days } from '../../constants/AppData';
import CSelect from '../common/CSelect';
import translate from '../../locale/en_translate.json';
import AppLoader from '../common/AppLoader';

interface Props {
  setTabid: (key: any) => void;
  day: string;
  setday: (key: any) => void;
  data: any;
  loader: boolean;
}

const FleetUsageBarCharts: React.FC<Props> = ({
  data,
  setTabid,
  day,
  setday,
  loader,
}: Props) => {
  const { t } = useTranslation();
  const [dataCost, setDataCost] = useState<any>();
  const [dateValue, setDate] = useState<any>();
  // const [loader, setLoader] = useState<boolean>(false);
  const [catgory, setCatgory] = useState<any>();

  const { energyData = [], labourData = [], savingsData = {} } = data || {};

  const { total_savings = 0, total_energy_used = 0 } = savingsData;

  useEffect(() => {
    const { energyData = [], labourData = [] } = data || {};
    if (
      energyData &&
      energyData.length > 0 &&
      labourData &&
      labourData.length > 0
    ) {
      const data1: any = [];
      energyData.forEach((element: any) => {
        labourData.find((ele: any) => {
          const obj: any = {};
          if (element.date === ele.date) {
            obj.date = element.date;
            obj['Energy Cost'] = element.total;
            obj['Labor Cost'] = ele.total;
            obj['energy_used'] = element.energy_used || 0;
            data1.push(obj);
          }
        });
      });
      setDataCost(data1);
    }
  }, [data]);

  useEffect(() => {
    if (dateValue) {
      if (catgory === 'Labor Cost') {
        setTabid('3');
      } else if (catgory === 'Energy Cost') {
        setTabid('2');
      }
    }
  }, [dateValue]);

  const bar_hour_tooltip = ({
    id,
    value,
    indexValue,
    data,
  }: {
    id: string | number;
    value: string | number;
    indexValue: string | number;
    data: any;
  }) => {
    let { energy_used } = data;
    // let energy_used;
    const tractor_name = id; // "PS1"
    let cost: string | number = '0';
    let cost1: string | number = '0'; // "USD 11.1"
    let totalValue: any;
    const date_str = indexValue; // "Jan 01"
    if (tractor_name === 'Labor Cost') {
      cost = value ? value : 0;
      if (typeof cost == 'string') {
        cost = parseFloat(cost);
      }
      if (energyData && energyData.length > 0) {
        energyData.forEach((ele: any) => {
          if (ele?.date === indexValue) {
            cost1 = ele?.total;
            energy_used = ele?.energy_used;
          }
        });
      }
      cost1 = cost1 ? cost1 : 0;
      if (typeof cost1 == 'string') {
        cost1 = parseFloat(cost1);
      }
      totalValue = cost + cost1;
    } else if (tractor_name === 'Energy Cost') {
      cost1 = value ? value : 0;
      if (typeof cost1 == 'string') {
        cost1 = parseFloat(cost1);
      }
      if (labourData && labourData.length > 0) {
        labourData.forEach((ele: any) => {
          if (ele?.date === indexValue) {
            cost = ele?.total;
          }
        });
      }
      cost = cost ? cost : 0;
      if (typeof cost == 'string') {
        cost = parseFloat(cost);
      }
      totalValue = cost + cost1;
    }

    return (
      <BasicTooltip
        id={
          <div className="bg-white-500">
            <div className="text-sm">
              <strong>{t(translate.fleetUsageCharts.date)}:</strong> {date_str}
            </div>
            <div className="text-sm">
              <strong>{t(translate.fleetUsageCharts.energyUsed)}:</strong>{' '}
              {Number(energy_used).toFixed(2)}{' '}
              {t(translate.fleetUsageCharts.kwh)}
            </div>
            <div className="text-sm">
              <strong>{t(translate.fleetUsageCharts.energyCost)}:</strong>{' '}
              {t(translate.fleetUsageCharts.usd)} {Number(cost1).toFixed(2)}
            </div>
            <div className="text-sm">
              <strong>{t(translate.fleetUsageCharts.laborCost)}:</strong>{' '}
              {t(translate.fleetUsageCharts.usd)} {Number(cost).toFixed(2)}
            </div>
            <div className="text-sm">
              <strong>{t(translate.fleetUsageCharts.total)}:</strong>{' '}
              {t(translate.fleetUsageCharts.usd)}{' '}
              {Number(totalValue).toFixed(2)}
            </div>
          </div>
        }
      />
    );
  };

  return (
    <div className="tractorUage">
      <>
        <div className="chartGraph" style={{ position: 'relative' }}>
          <div className="mt-5">
            <div className="text-3xl font-bold">
              {t(translate.fleetUsageCharts.title)}
            </div>

            <div className="text-xl my-4 px-4 py-2 w-auto inline-block bg-kikuchiba text-kogecha rounded">
              <strong>{t(translate.fleetUsageCharts.interaction)}:</strong>
              <br />
              {t(translate.fleetUsageCharts.info)}
            </div>
            <div className="ddDuration">
              <CSelect
                list={days}
                isAll={false}
                selectTrigger={(selected: any) => {
                  const { value = '30' } = selected;
                  setday(value);
                }}
                label="Days"
                keyValue="value"
                defaultId={day}
              />
            </div>
            <div className="totalEUS">
              <div className="totalEUS_left">
                <div className="euHead">
                  {t(translate.fleetUsageCharts.energyUsed)}
                </div>
                <div className="euValues">
                  <span className="euValuesTxt">{total_energy_used}</span>
                  <span className="euValuesMsr">
                    {t(translate.fleetUsageCharts.kwh)}
                  </span>
                </div>
              </div>
              <div className="totalEUS_right">
                <div className="euHead">
                  {t(translate.fleetUsageCharts.savings)}
                </div>
                <div className="euValues">
                  <span className="euValuesTxt">${total_savings}</span>
                </div>
              </div>
            </div>
            <div className="cost-graph-all">
              {dataCost && dataCost.length > 0 && (
                <ResponsiveBar
                  data={dataCost}
                  keys={['Labor Cost', 'Energy Cost']}
                  indexBy="date"
                  margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                  padding={0.3}
                  valueScale={{ type: 'linear' }}
                  indexScale={{ type: 'band', round: true }}
                  colors={['#8bbda7', '#445A69']}
                  enableLabel={false}
                  defs={[
                    {
                      id: 'dots',
                      type: 'patternDots',
                      background: 'inherit',
                      color: '#38bcb2',
                      size: 4,
                      padding: 1,
                      stagger: true,
                    },
                    {
                      id: 'lines',
                      type: 'patternLines',
                      background: 'inherit',
                      color: '#eed312',
                      rotation: -45,
                      lineWidth: 6,
                      spacing: 10,
                    },
                  ]}
                  borderColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                  }}
                  tooltip={bar_hour_tooltip}
                  axisTop={null}
                  axisRight={null}
                  axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Energy & Labor Cost',
                    legendPosition: 'middle',
                    legendOffset: -40,
                  }}
                  axisBottom={{
                    // format: "%b %d",
                    tickSize: 5,
                    tickPadding: 10,
                    tickRotation: 90,
                    legend: '',
                    legendOffset: 50,
                    legendPosition: 'middle',
                  }}
                  labelSkipWidth={12}
                  labelSkipHeight={12}
                  labelTextColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                  }}
                  role="application"
                  ariaLabel="Nivo bar chart demo"
                  barAriaLabel={function (e) {
                    return (
                      e.id +
                      ': ' +
                      e.formattedValue +
                      ' in date: ' +
                      e.indexValue
                    );
                  }}
                  onClick={(e: any) => {
                    setCatgory(e.id);
                    setDate(e.data);
                  }}
                  legends={[
                    {
                      dataFrom: 'keys',
                      anchor: 'bottom-right',
                      direction: 'column',
                      justify: false,
                      translateX: 120,
                      translateY: 0,
                      itemsSpacing: 2,
                      itemWidth: 100,
                      itemHeight: 20,
                      itemDirection: 'left-to-right',
                      itemOpacity: 0.85,
                      symbolSize: 20,
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemOpacity: 1,
                          },
                        },
                      ],
                    },
                  ]}
                />
              )}
            </div>
          </div>
        </div>
      </>

      <AppLoader loader={loader} />
    </div>
  );
};

export default FleetUsageBarCharts;
