import React from 'react';
import SomethingWentWrong from '../components/errors/SomethingWentWrong';

class ErrorBoundary extends React.Component {
  state = {
    hasError: null,
  };

  static getDerivedStateFromError = (error: any) => {
    // eslint-disable-next-line no-console
    console.error('error ', error);
    return { hasError: true };
  };
  componentDidCatch(error: any) {
    // eslint-disable-next-line no-console
    console.error('error ', error);
  }
  render() {
    if (this.state.hasError) {
      return <SomethingWentWrong />;
    }
    // eslint-disable-next-line react/prop-types
    return this.props?.children;
  }
}

export default ErrorBoundary;
