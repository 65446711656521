/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import { Content } from 'antd/lib/layout/layout';
import {
  AutoComplete,
  Layout,
  notification,
  Spin,
  Image,
  Row,
  Col,
} from 'antd';
import { useTranslation } from 'react-i18next';
import './style.css';
import {
  HistoryOutlined,
  LeftOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { ApplicationContext } from '../../context/AppContext';
import { useContext, useEffect, useRef, useState } from 'react';
import pdf_icon from '../../assets/images/pdf.png';
import {
  getFrequentData,
  getKnowledgeBaseList,
  getKnowledgeBaseTitle,
} from '../../constants/Api';
import KnowledgeBaseFile from './KnowledgeBaseFile';
import video_icon from '../../assets/images/play_icon.png';
import translate from '../../locale/en_translate.json';
import moment from 'moment';
interface Props {
  closeModel: () => void;
  titleview: any;
}
const KnowledgeBaseDetails: React.FC<Props> = ({ closeModel, titleview }) => {
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const [searchKey, setSearchKey] = useState<string>(titleview);
  const [loader, setLoader] = useState<boolean>(false);
  const [knowledgeData, setKnowledgeData] = useState<any>();
  const [knowledgeTitleData, setKnowledgeTitleData] = useState<any>();
  const [title, setTitle] = useState<string>(titleview);
  const { Option } = AutoComplete;
  const searchString = useRef<string>(titleview);
  const [recordFile, setRecordFile] = useState<any>();
  const [knowledgeFileFlag, setknowledgeFileFlag] = useState<boolean>(false);
  const [recentArtList, setRecentArtList] = useState<any>();

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      getknowledgeBase();
    }
  }, [userDetails, searchKey]);
  useEffect(() => {
    if (userDetails && userDetails.organization) {
      getrecetArticleList();
    }
  }, [userDetails]);

  useEffect(() => {
    if (userDetails && userDetails.organization && title) {
      getknowledgeTitle(title);
    }
  }, [userDetails, title]);

  const getknowledgeBase = async () => {
    try {
      const result: any = await getKnowledgeBaseList(searchKey);
      setKnowledgeData(result.data);
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    }
  };

  const getknowledgeTitle = async (title: any) => {
    try {
      setLoader(true);
      const result: any = await getKnowledgeBaseTitle(title);
      setKnowledgeTitleData(result.data);
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    } finally {
      setLoader(false);
    }
  };

  const getrecetArticleList = async () => {
    try {
      const result: any = await getFrequentData('searches');
      if (result) {
        setRecentArtList(result.data);
      }
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    }
  };

  const handleSearch = (e: any) => {
    const value = e.trim().regX;
    if (value.length <= 1) {
      searchString.current = '';
      setSearchKey('');
      setKnowledgeData([]);
      setKnowledgeTitleData([]);
    } else {
      searchString.current = value;
      setSearchKey(value);
    }
  };
  const onSelect = (e: any) => {
    setSearchKey(e);
    setTitle(e);
  };
  const Onkey = (e: any) => {
    if (e.key === 'Enter') {
      setTitle(e.target.value);
    }
  };
  const ClosedIcon = () => {
    setTitle('');
    setKnowledgeData([]);
    setKnowledgeTitleData([]);
  };
  const KnowledgeBaseFilelist = (record: any) => {
    setRecordFile(record);
    setknowledgeFileFlag(true);
  };

  return (
    <>
      {!knowledgeFileFlag && (
        <Layout>
          <Content>
            <div className="mainContent">
              <div className="tblContainer selectReport test knowledgeBaseContent knowledgeBaseDetailsBlk">
                <div>
                  <div className="baseSearchFilter">
                    <div className="baseSearchFilterBlk">
                      <Row>
                        <Col span={2} className="backbuttonPad">
                          <div
                            className="backBtnIcon backBtnCursor"
                            onClick={closeModel}
                          >
                            <LeftOutlined />
                            <span className="overline1">
                              {t(translate.KnowledgeBase.back)}
                            </span>
                          </div>
                        </Col>
                        <Col span={22}>
                          <div className="baseSearchFilterInrBlk">
                            <SearchOutlined />
                            <AutoComplete
                              allowClear={true}
                              style={{ width: 200 }}
                              onSearch={handleSearch}
                              onSelect={onSelect}
                              defaultValue={title}
                              placeholder=" Search"
                              data-testid="inputSupervisor-AddTask"
                              onKeyUp={(e: any) => Onkey(e)}
                              onClear={ClosedIcon}
                            >
                              {searchKey
                                ? knowledgeData &&
                                  knowledgeData.length > 0 &&
                                  knowledgeData.map((ele: any) => (
                                    <Option key={ele.id} value={ele.title}>
                                      <SearchOutlined />
                                      <span style={{ paddingLeft: '6px' }}>
                                        {ele.title}
                                      </span>
                                    </Option>
                                  ))
                                : recentArtList &&
                                  recentArtList.length > 0 &&
                                  recentArtList.map((ele: any) => (
                                    <Option key={ele.id} value={ele.title}>
                                      <HistoryOutlined />
                                      <span style={{ paddingLeft: '6px' }}>
                                        {ele.title}
                                      </span>
                                    </Option>
                                  ))}
                            </AutoComplete>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <div className="knowledgeBaseDetailsFlex">
                    {knowledgeTitleData &&
                      knowledgeTitleData.length > 0 &&
                      knowledgeTitleData.map((ele: any, i: any) => (
                        <div className="knowledgeBaseDetailsBox" key={i}>
                          {ele.media_type === 'image' && (
                            <div className="knowledgeBaseDetailsImg">
                              <Image
                                src={ele?.file_url}
                                className="knowledgebaseimg"
                                preview={{
                                  src: `${ele?.file_url}`,
                                }}
                              />
                            </div>
                          )}
                          {ele.media_type === 'pdf' && (
                            <div
                              className="knowledgeBaseDetailsImg knowledgeBaseDetailsPdf"
                              onClick={() => {
                                KnowledgeBaseFilelist(ele);
                              }}
                            >
                              <img src={pdf_icon} alt="" />
                            </div>
                          )}
                          {ele.media_type === 'video' && (
                            <div
                              className="knowledgeBaseDetailsImg"
                              onClick={() => {
                                KnowledgeBaseFilelist(ele);
                              }}
                            >
                              <video
                                className="video knowledgeImg"
                                src={ele?.file_url}
                                poster={ele?.thumbnail_file_url}
                              />
                              <div className="playIcon">
                                <img src={video_icon} alt="" />
                              </div>
                            </div>
                          )}
                          <div className="knowledgeBaseDetailsContent">
                            <h4 className="headline1">
                              {ele?.title[0].toUpperCase() +
                                ele?.title.slice(1)}
                            </h4>
                            <div>
                              <p className="headline4">
                                {t(translate.KnowledgeBase.createdBy)}:{' '}
                                <span className="">{ele?.created_by}</span>
                              </p>
                              <p className="headline4">
                                {t(translate.KnowledgeBase.published)}:{' '}
                                <span>
                                  {moment(ele?.created_date_time).fromNow()}
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="edit-loader">
              {loader && (
                <div className="loader">
                  <Spin size="large" />
                </div>
              )}
            </div>
          </Content>
        </Layout>
      )}

      <div>
        {recordFile && knowledgeFileFlag && (
          <KnowledgeBaseFile
            closeModel={() => {
              setknowledgeFileFlag(false);
            }}
            recordFile={recordFile}
          />
        )}
      </div>
    </>
  );
};

export default KnowledgeBaseDetails;
