/* eslint-disable no-console */
import { Button, Divider, Input, Layout, Space } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { PubSub } from 'aws-amplify';
import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  AUTO_DRIVE_ERROR_TOPIC,
  MOS_EVENTS_TOPIC,
  NONE,
  TRACTOR_STATUS,
} from '../../constants/constant';
import { TopicSubscribe } from '../../constants/types';
import { ApplicationContext } from '../../context/AppContext';
import CustomSelect from '../common/CustomSelect';

let globelArray: any = [];
const DevTools: React.FC = () => {
  const { userDetails } = useContext(ApplicationContext);
  const [topic, setTopic] = useState('');
  const [subScribedInput, setSubScribedInput] = useState('');
  const inputSubScribedTopiRef: any = useRef();

  const [publishedInput, setPublishedInput] = useState('');
  const [dataArray, setData] = useState<any>([]);
  const [command, setCommand] = useState('');
  const [topicsList, setTopicsList] = useState<TopicSubscribe[]>([]);
  const [selectedTopic, setSelectedTopic] = useState<string>();
  const selectedSubScribedTopiRef: any = useRef();

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      const list = [
        { name: NONE, value: NONE },
        {
          name: `HeartBeat/org/${userDetails.organization_id}`,
          value: `HeartBeat/org/${userDetails.organization_id}`,
        },
        {
          name: `${MOS_EVENTS_TOPIC}${userDetails.organization_id}`,
          value: `${MOS_EVENTS_TOPIC}${userDetails.organization_id}`,
        },
        {
          name: AUTO_DRIVE_ERROR_TOPIC,
          value: AUTO_DRIVE_ERROR_TOPIC,
        },
        {
          name: `remoteav_preview_${userDetails.organization_id}`,
          value: `remoteav_preview_${userDetails.organization_id}`,
        },
        {
          name: `live_event_notifications_${userDetails.organization_id}`,
          value: `live_event_notifications_${userDetails.organization_id}`,
        },
        {
          name: `autodrive_tractor_status_${userDetails.organization_id}`,
          value: `autodrive_tractor_status_${userDetails.organization_id}`,
        },
        {
          name: TRACTOR_STATUS,
          value: TRACTOR_STATUS,
        },
      ];
      setTopicsList(list);
    }
  }, [userDetails]);
  useEffect(() => {
    if (topic && topic.length > 0) {
      try {
        inputSubScribedTopiRef.current = PubSub.subscribe(topic).subscribe({
          next: (data: any) => {
            const x: string = JSON.stringify(data.value);
            if (globelArray.length > 999) globelArray.splice(0, 500);
            globelArray.push(x);
            setData([...globelArray]);
          },
          error: (error: any) => console.error(error),
        });
      } catch (error) {
        console.log(error);
      }
    }
  }, [topic]);
  useEffect(() => {
    const constTopic = selectedTopic;
    if (constTopic && constTopic.length > 0 && constTopic !== NONE) {
      try {
        selectedSubScribedTopiRef.current = PubSub.subscribe(
          constTopic,
        ).subscribe({
          next: (data: any) => {
            const x: string = JSON.stringify(data.value);
            if (globelArray.length > 999) globelArray.splice(0, 500);
            globelArray.push(x);
            setData([...globelArray]);
          },
          error: (error: any) => console.error(error),
        });
      } catch (error) {
        console.log(error);
      }
    }
  }, [selectedTopic]);

  const handleCommand = () => {
    PubSub.publish(publishedInput, command);
  };

  return (
    <Layout>
      <div className="mainContent">
        <div className="tblContainer viewportContainer">
          <h1 style={{ fontSize: 30 }}>IOT PUB/SUB</h1>
          <div>
            <div style={{ marginBottom: '20px' }}>
              <Space direction="vertical">
                <Space>
                  <Input
                    value={subScribedInput}
                    onChange={(e) => setSubScribedInput(e.target.value)}
                    placeholder="Topic"
                    style={{ width: '250px' }}
                  />
                  <Button
                    onClick={() => {
                      selectedSubScribedTopiRef?.current?.unsubscribe();
                      inputSubScribedTopiRef?.current?.unsubscribe();
                      setTopic(subScribedInput);
                      setSelectedTopic(NONE);
                    }}
                    // disabled={subScribedInput === topic}
                    disabled={subScribedInput.length === 0}
                  >
                    Connect
                  </Button>
                </Space>
                <Divider type="horizontal" />

                <Space>
                  <div style={{ display: 'flex' }}>
                    <CustomSelect
                      cssClass="dev-drop-down-width"
                      value={selectedTopic}
                      setValue={(id: any) => {
                        inputSubScribedTopiRef?.current?.unsubscribe();
                        selectedSubScribedTopiRef?.current?.unsubscribe();
                        setSelectedTopic(id);
                        setSubScribedInput('');
                      }}
                      options={topicsList.map((item: any) =>
                        Object.assign({
                          id: item.index,
                          name: item.name,
                          value: item.value,
                          key: item.value,
                        }),
                      )}
                      optionKey="value"
                      optionDisplay="name"
                    />
                  </div>
                </Space>
                <Divider type="horizontal" />
                <Space>
                  <Space>
                    <Input
                      value={publishedInput}
                      onChange={(e) => setPublishedInput(e.target.value)}
                      placeholder="Topic"
                      style={{ width: '250px' }}
                    />
                    <>
                      <TextArea
                        value={command}
                        onChange={(e) => setCommand(e.target.value)}
                        placeholder="Command"
                        style={{ width: '250px', height: '100px' }}
                      />
                      <Button onClick={() => handleCommand()}>Publish</Button>
                    </>
                    {/* )} */}
                  </Space>
                </Space>
                <Divider type="horizontal" />
                <Button
                  onClick={() => {
                    globelArray = [];
                    setData([]);
                  }}
                >
                  Clear messages
                </Button>
              </Space>
            </div>
            <div
              style={{
                overflowY: 'auto',
                height: 'calc(100vh - 100px)',
              }}
            >
              <hr />
              {dataArray.map((value: any, index: number) => (
                <>
                  <p key={index}>
                    <p>
                      {index + 1 === dataArray.length
                        ? 'Latest message at Bottom'
                        : ''}
                    </p>
                    {value}
                  </p>
                  <br />

                  <a onClick={() => navigator.clipboard.writeText(value)}>
                    copy
                  </a>
                  <br />
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default DevTools;
