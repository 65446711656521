import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, DatePicker, Row, Tooltip, message, notification } from 'antd';
import Layout, { Content } from 'antd/lib/layout/layout';
import translate from '../../../locale/en_translate.json';
import InfiniteScrollTable from '../../common/InfiniteScrollTable';
import usePaginate from '../../../hooks/usePaginate';
import { ApplicationContext } from '../../../context/AppContext';
import { initScroller } from '../../../constants/Common';
import { getDriveActionsTractorsList, getFleetindicatorHistory } from '../api';
import { useTranslation } from 'react-i18next';
import './style.css';
import CustomSelect from '../../common/CustomSelect';
import refreshIcon from '../../../assets/images/refreshNew.svg';
import { CSVLink } from 'react-csv';
import Download from './Download';
import CropOrgFilter from '../../common/CropOrgFilter';
import moment from 'moment';
import { getIndicatorImages } from '../../basestation/Common';
import { mapFleetIndHistoryData } from '../Common';
import CustomMultiSelect from '../../common/CustomMultiSelect';
import FleetIndicatorDetails from './FleetIndicatorDetails';

interface StatusListType {
  name: string;
  value: string;
}
const DOWNLOAD_SIZE = 100;
let columns: any;
const { RangePicker } = DatePicker;

const FleetIndicatorHistory = () => {
  const { t } = useTranslation();
  const defaultStartDate = moment().subtract(1, 'days').startOf('day');
  const defaultEndDate = moment().endOf('day');
  const { userDetails } = useContext(ApplicationContext);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [selectedOrgId, setSelectedOrgId] = useState<string[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [pageSize] = useState<any>(25);
  const [loading, setLoading] = useState(false);
  const [indicatorKey, setIndicatorKey] = useState<string>('');
  const { filterData, setData, activity, hasMore, checkActivity } =
    usePaginate();
  const [csvData, setCSVData] = useState<any[]>([]);
  const [headers, setHeaders] = useState<any[]>([]);
  const [downloadData, setDownloadData] = useState({
    isDownloaded: false,
    percent: 0,
    status: '',
  });
  const [fileDownloadStatus, setFileDownloadStatus] = useState<boolean>(false);
  const init = useRef<boolean>(true);
  const [dateFilter, setDateFilter] = useState<any>([
    defaultStartDate,
    defaultEndDate,
  ]);
  const [selectedTractorId, setselectedTractorId] = useState<string[]>([]);
  const [tractors, setTractors] = useState<any[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [indicatorDetails, setFleetIndicatorDetails] = useState<any>();

  const statusList: StatusListType[] = [
    {
      name: `${t(translate.fleetIndicatorHistory.tractorCommunication)}`,
      value: 'Tractor_Communication',
    },
    {
      name: `${t(translate.fleetIndicatorHistory.tractorAutomation)}`,
      value: 'Tractor_Automation',
    },
    {
      name: `${t(translate.fleetIndicatorHistory.tractorDrivability)}`,
      value: 'Tractor_Drivability',
    },
    {
      name: `${t(translate.fleetIndicatorHistory.tractorData)}`,
      value: 'Tractor_Data',
    },
    {
      name: `${t(translate.fleetIndicatorHistory.tractorEnergy)}`,
      value: 'Tractor_Energy',
    },
  ];

  const wrapResult = async (
    OrgUrl: any,
    selectedOrgId: any,
    pageNumber: number,
    pageSize: number,
    selectedTractorId: any,
    fromdate: any,
    todate: any,
    indicatorKey: any,
  ) => {
    const result = await getFleetindicatorHistory(
      userDetails?.organization.api_url,
      selectedOrgId,
      pageNumber,
      pageSize,
      selectedTractorId,
      fromdate,
      todate,
      indicatorKey,
    );
    return {
      result,
    };
  };

  const getbaseStationList = async () => {
    try {
      if (checkActivity()) initScroller();
      const fromDate =
        dateFilter?.length > 0
          ? new Date(dateFilter[0]).getTime().toString()
          : '';
      const toDate =
        dateFilter?.length > 0
          ? new Date(dateFilter[1]).getTime().toString()
          : '';
      setLoading(true);
      const { result } = await wrapResult(
        userDetails?.organization.api_url,
        selectedOrgId,
        pageNumber,
        pageSize,
        selectedTractorId,
        fromDate,
        toDate,
        indicatorKey,
      );

      setTotal(result?._metadata?.total_records_count);
      const { records } = result;
      let data = Array.isArray(records) ? records : [];
      data = mapFleetIndHistoryData(data);
      setData(data);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    } finally {
      setLoading(false);
    }
  };
  const loadBaseStation = () => {
    if (userDetails && !userDetails.organization) return;
    if (checkActivity()) {
      // eslint-disable-next-line no-unused-expressions
      pageNumber !== 1 ? setPageNumber(1) : getbaseStationList();
    } else if (activity.current === 'paginate' || activity.current === '') {
      getbaseStationList();
    }
  };

  const handleOrg = (ids: any) => {
    activity.current = 'filter';
    setSelectedOrgId(ids);
  };

  const handleLoadMore = () => {
    if (pageNumber === 1 && checkActivity() && document) initScroller();

    activity.current = 'paginate';
    setPageNumber(pageNumber + 1);
  };

  const handleRefresh = () => {
    activity.current = 'refresh';
    loadBaseStation();
  };
  useEffect(() => {
    if (
      userDetails &&
      userDetails.organization &&
      selectedOrgId.length > 0 &&
      init.current
    ) {
      loadBaseStation();
      init.current = false;
    }
  }, [userDetails, selectedOrgId]);

  useEffect(() => {
    if (selectedOrgId.length === 0) {
      setData([]);
      setTotal(0);
      setCSVData([]);
    } else {
      loadBaseStation();
    }
  }, [pageNumber]);

  useEffect(() => {
    columns = [
      {
        title: `${t(translate.baseStation.tractorName)}`,
        dataIndex: 'name',
        key: 'name',
        onCell: (record: any) => ({
          onClick: () => getRow(record),
        }),
        width: '195px',
      },
      {
        title: `${t(translate.taskmaster.starttime)}`,
        dataIndex: 'start_time',
        key: 'start_time',
        onCell: (record: any) => ({
          onClick: () => getRow(record),
        }),
        width: '180px',
      },
      {
        title: `${t(translate.tractorDetails.indicatorName)}`,
        dataIndex: 'indicator_name',
        key: 'indicator_name',
        onCell: (record: any) => ({
          onClick: () => getRow(record),
        }),
        width: '190px',
      },
      {
        title: `${t(translate.tractorDetails.errorcode)}`,
        dataIndex: 'error_code',
        key: 'error_code',
        onCell: (record: any) => ({
          onClick: () => getRow(record),
        }),
        width: '130px',
      },
      {
        title: `${t(translate.tractorDetails.software_version)}`,
        dataIndex: 'software_version',
        key: 'software_version',
        onCell: (record: any) => ({
          onClick: () => getRow(record),
        }),
        width: '150px',
      },
      {
        title: `${t(translate.tractorDetails.firmware_version)}`,
        dataIndex: 'firmware_version',
        key: 'firmware_version',
        onCell: (record: any) => ({
          onClick: () => getRow(record),
        }),
        width: '200px',
      },
      {
        title: `${t(translate.myTask.description)}`,
        dataIndex: 'description',
        key: 'description',
        onCell: (record: any) => ({
          onClick: () => getRow(record),
        }),
        width: '200px',
        render: (description: string, levelObj: any) => (
          <div className="description">
            <img
              width="25px"
              height="25px"
              src={getIndicatorImages(
                levelObj.indicator_name,
                levelObj.indicator_level,
                true,
              )}
            />
            <Tooltip title={description}>
              <div className="description-text">{description}</div>
            </Tooltip>
          </div>
        ),
      },
      {
        title: `${t(translate.tractorDetails.ResolvedTime)}`,
        dataIndex: 'resolved_date_time',
        key: 'resolved_date_time',
        onCell: (record: any) => ({
          onClick: () => getRow(record),
        }),
        width: '180px',
      },
      {
        title: `${t(translate.MyJumpAndGo.duration)}`,
        dataIndex: 'Duration',
        key: 'Duration',
        onCell: (record: any) => ({
          onClick: () => getRow(record),
        }),
        width: '120px',
      },
    ];

    const header: any = [];
    if (columns) {
      columns.map((column: any) =>
        header.push({
          label: column.title,
          key: column.dataIndex,
        }),
      );
      setHeaders(header);
    }
  }, []);

  const delay = (ms = 1000) =>
    new Promise((resolve) => setTimeout(resolve, ms));

  useEffect(() => {
    const { status } = downloadData || {};
    const download = async () => {
      const doc = document;
      const element: any = doc.querySelector(`#FleetIndicatorHistorys`);
      if (element) {
        element?.click();
      }
      await delay();
      setFileDownloadStatus(false);
    };
    if (['success', 'exception'].includes(status)) download();
  }, [downloadData]);

  const handleDownload = async () => {
    setFileDownloadStatus(true);
    try {
      if (downloadData.status === 'start') return;
      setDownloadData({
        ...downloadData,
        status: 'start',
        percent: 10,
      });
      let data: any[] = [];
      const fromDate =
        dateFilter?.length > 0
          ? new Date(dateFilter[0]).getTime().toString()
          : '';
      const toDate =
        dateFilter?.length > 0
          ? new Date(dateFilter[1]).getTime().toString()
          : '';
      const pages = Math.ceil(total / DOWNLOAD_SIZE);
      for (const page of Array.from({ length: pages }, (_, i) => i + 1)) {
        const { result } = await wrapResult(
          userDetails?.organization.api_url,
          selectedOrgId,
          page,
          DOWNLOAD_SIZE,
          selectedTractorId,
          fromDate,
          toDate,
          indicatorKey,
        );
        const { records } = result;
        let tdata = Array.isArray(records) ? records : [];
        tdata = mapFleetIndHistoryData(tdata);
        data = [...data, ...tdata];
        setDownloadData({
          ...downloadData,
          status: 'start',
          percent: Math.round((data.length / total) * 100),
        });
      }

      setCSVData([...data]);
      setDownloadData({
        ...downloadData,
        percent: 100,
        status: 'success',
      });
    } catch (error: any) {
      setDownloadData({
        ...downloadData,
        status: 'exception',
      });
      notification.error({ message: error.message });
    }
  };

  const handleDownloadData = () => {
    activity.current = 'filter';
    if (userDetails && selectedOrgId.length) {
      handleDownload();
    } else {
      message.warning('Please select an Orgnizations');
      setData([]);
      setCSVData([]);
      setTotal(0);
    }
  };

  const handleSubmit = () => {
    activity.current = 'filter';
    if (userDetails && selectedOrgId.length) {
      loadBaseStation();
    } else {
      message.warning('Please select an Orgnizations');
      setData([]);
      setCSVData([]);
      setTotal(0);
    }
  };

  const statusSelect = (e: string) => {
    setIndicatorKey(e);
  };
  const onDateRangeChange = (dates: any) => {
    setDateFilter(dates);
  };

  const disabledDates = (current: any) => {
    return (
      (current && current > moment()) ||
      (current && current <= moment('12-31-2022'))
    );
  };
  const getTractors = async () => {
    try {
      const tractor = await getDriveActionsTractorsList(
        userDetails.organization.api_url,
        selectedOrgId,
      );
      const totalTractors = tractor?.map((data: any) => ({
        title: <span className="ellipsis">{data.name}</span>,
        value: `${data.id}`,
      }));
      setTractors(totalTractors);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    }
  };

  useEffect(() => {
    if (selectedOrgId?.length) {
      setselectedTractorId([]);
      getTractors();
    } else {
      setData([]);
      setCSVData([]);
      setTractors([]);
      setselectedTractorId([]);
    }
  }, [selectedOrgId]);

  const handleTractor = (ids: any) => {
    activity.current = 'filter';
    setselectedTractorId(ids);
  };
  const getRow = (record: any) => {
    setFleetIndicatorDetails(record);
    setShowModal(true);
  };

  return (
    <>
      <div className="fleetindicator_container">
        <Row>
          <div className="common_wrapper_full">
            <div className="filters_card filter_inputs align_top">
              <div className="filtersHealthScreen">
                <CropOrgFilter handleOrgs={handleOrg} />

                <CustomMultiSelect
                  handleTreeSelect={handleTractor}
                  selectedId={selectedTractorId}
                  list={tractors}
                  label="Tractors"
                />

                <CustomSelect
                  label="Select Indicator"
                  cssClass="min_width"
                  value={indicatorKey}
                  setValue={(id: any) => {
                    activity.current = 'filter';
                    statusSelect(id);
                  }}
                  options={statusList}
                  optionKey="value"
                  optionDisplay="name"
                  isAll
                />
                <RangePicker
                  allowClear={false}
                  format="YYYY-MM-DD"
                  value={dateFilter}
                  onChange={onDateRangeChange}
                  disabledDate={disabledDates}
                />
                <Button
                  className="submitBtn"
                  onClick={handleSubmit}
                  loading={loading}
                >
                  {t(translate.buttons.submit)}
                </Button>
              </div>
              <div className="FleetStatusFilterIcons">
                <CSVLink
                  id="FleetIndicatorHistorys"
                  style={{ display: 'none' }}
                  data={csvData}
                  filename={`Fleet_Indicator.csv`}
                  headers={headers}
                >
                  <div className="filterIcon">
                    <div className="downloadIcon" />
                  </div>
                </CSVLink>
                <div
                  className="filterIcon"
                  onClick={() => {
                    handleDownloadData();
                  }}
                >
                  <div className="downloadIcon" />
                </div>
                <img
                  src={refreshIcon}
                  onClick={handleRefresh}
                  height={32}
                  width={32}
                  style={{ marginLeft: '7px' }}
                />
              </div>
            </div>
            <div className="common_table">
              <InfiniteScrollTable
                columns={columns}
                loading={loading}
                hasMore={hasMore}
                filterData={filterData}
                totalcount={total}
                handleLoadMore={handleLoadMore}
                filename={total > 0 ? 'Fleet Indicators' : 'Fleet Indicator'}
              />
            </div>
          </div>
        </Row>
      </div>
      {indicatorDetails && showModal && (
        <FleetIndicatorDetails
          showModal={showModal}
          handleCancel={() => setShowModal(false)}
          indicatorDetails={indicatorDetails}
        />
      )}
      {fileDownloadStatus && (
        <Download
          loading={fileDownloadStatus}
          setLoading={setFileDownloadStatus}
          percent={downloadData?.percent || 0}
          status={downloadData?.status}
        />
      )}
    </>
  );
};

export default FleetIndicatorHistory;
