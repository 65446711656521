/* eslint-disable operator-linebreak */
/* eslint-disable object-curly-newline */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
// import { useReactToPrint } from 'react-to-print';
import { ConfigProvider, Space } from 'antd';
import Download from '../Download/index';
import ExtCol from '../ExtCol';
import { delay } from '../../../constants/Common';
import './style.scss';

interface Props {
  handleRefresh?: () => void;
  handleDownload?: () => void;
  handleCallback?: (value: any[]) => void;
  placeholder?: string;
  sortColumns?: string[];
  csvData?: any[];
  csvHeaders?: any[];
  downloadData?: any;
  csvFilename?: string;
  children?: React.ReactNode;
  tableRef?: any;
  iconClass?: boolean;
  tableName?: string;
  columnsData?: any[];
  toggleColumnsStatus?: boolean;
  className?: string;
}

export const TableExt: React.FC<Props> = ({
  handleRefresh,
  handleDownload,
  handleCallback,
  csvData,
  csvHeaders,
  csvFilename,
  downloadData,
  iconClass,
  tableName = '',
  columnsData = [],
  toggleColumnsStatus,
  className = '',
}) => {
  const [loading, setLoading] = useState<boolean>(false);

  // const handlePrint = useReactToPrint({
  //   content: () => tableRef.current,
  // });

  // eslint-disable-next-line no-unused-vars
  const downloadAll = (e: React.MouseEvent<HTMLElement>) => {
    // message.info('please wait. downlading the files');
    e.stopPropagation();
    setLoading(true);
    if (handleDownload && downloadData?.status !== 'start') handleDownload();
  };

  useEffect(() => {
    const { status } = downloadData || {};
    const download = async () => {
      const doc = document;
      const element: any = doc.querySelector(`#${csvFilename}`);
      if (element) {
        element?.click();
      }
      await delay(1000);
      setLoading(false);
    };
    if (['success', 'exception'].includes(status)) download();
  }, [downloadData]);

  return (
    <>
      <ConfigProvider
        getPopupContainer={(triggerNode: any) => triggerNode?.parentNode}
      >
        <div className="filterContainer">
          <Space className={iconClass ? 'iconFiltersUnset' : 'iconFilters'}>
            {!handleDownload && csvData && (
              <div className="filterIcon">
                <CSVLink
                  id={csvFilename}
                  data={csvData}
                  filename={`${csvFilename}s.csv`}
                  data-testid="downloadFile-index"
                  headers={csvHeaders}
                >
                  <div className="downloadIcon" />
                </CSVLink>
              </div>
            )}

            {handleDownload && (
              <>
                <div className="filterIcon" onClick={downloadAll}>
                  {/* {loading ? (
                    <LoadingOutlined />
                  ) : (
                    <div className="downloadIcon" />
                  )} */}
                  <div className="downloadIcon" />
                </div>
              </>
            )}

            {/* <div
              className="filterIcon"
              onClick={tableRef?.current ? handlePrint : () => {}}
            >
              <div className="pdfIcon" />
            </div> */}
            <div
              className="filterIcon"
              onClick={() => handleRefresh && handleRefresh()}
            >
              <div
                className="refreshIcon rfresh_n_bdr mt0"
                data-testid="refreshIcon-index"
              />
            </div>
            {handleCallback && (
              <ExtCol
                tableName={tableName}
                columnsData={columnsData}
                toggleColumnsStatus={toggleColumnsStatus}
                handleCallback={handleCallback}
              />
            )}
          </Space>
        </div>
      </ConfigProvider>
      {handleDownload && (
        <Download
          loading={loading}
          setLoading={setLoading}
          percent={downloadData?.percent || 0}
          status={downloadData?.status}
        />
      )}

      {csvData && (
        <CSVLink
          style={{ display: 'none' }}
          id={csvFilename}
          data={csvData}
          filename={`${csvFilename}.csv`}
          headers={csvHeaders}
        >
          <div className="downloadIcon" />
        </CSVLink>
      )}
      {/* <div className="newTags">
        <Tag closable>Sales</Tag>
        <Tag closable>Updated time (oldest first)</Tag>
      </div> */}
    </>
  );
};

export default TableExt;
