import { Row, Space, notification } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import Layout from 'antd/lib/layout/layout';
import { TractorResponseType, TractorsList } from '../../constants/types';
import { ApplicationContext } from '../../context/AppContext';
import { getTractorsListData, getHistoryData } from '../../constants/Api';
import {
  getTractorAvailableState,
  initScroller,
  tractorSort,
} from '../../constants/Common';
import CustomSelect from '../common/CustomSelect';
import { useTranslation } from 'react-i18next';
import InfiniteScrollTable from '../common/InfiniteScrollTable';
import translate from '../../locale/en_translate.json';
import HistoryDetailsModal from './HistoryDetailsModal';
import moment from 'moment';
import usePaginate from '../../hooks/usePaginate';
import { mapHistoryData } from '../admin/Common';
import CustomDate from '../common/CustomDate';

const History: React.FC = () => {
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const { filterData, setData, activity, hasMore, checkActivity } =
    usePaginate();
  const [loader, setLoader] = useState<boolean>(false);
  const [totalCount, setTotalCount] = useState<any>(0);
  const [tractorId, setTractorId] = useState<any>();
  const [tractors, setTractorsData] = useState<TractorResponseType[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize] = useState<any>(25);
  const [historyDetailsModal, setHistoryDetailsModal] =
    useState<boolean>(false);
  const [historyRecords, setHistoryRecords] = useState<any>();

  const defaultStartDate = moment().startOf('week');
  let defaultEndDate = moment(defaultStartDate).add(7, 'days');
  if (defaultEndDate.isAfter(moment(), 'day')) {
    defaultEndDate = moment();
  }
  const [dateFilter, setDateFilter] = useState<any>([
    defaultStartDate,
    defaultEndDate,
  ]);

  const wrapResult = async (pageNumber: number, pageSize: number) => {
    const fromMonth =
      dateFilter?.length > 0 ? dateFilter[0]?.format('YYYY-MM-DD') : '';
    const toMonth =
      dateFilter?.length > 0 ? dateFilter[1]?.format('YYYY-MM-DD') : '';
    const result = await getHistoryData(
      userDetails.organization.api_url,
      userDetails.organization_id,
      tractorId,
      fromMonth,
      toMonth,
      pageNumber,
      pageSize,
    );
    return {
      result,
    };
  };

  const historyDataList = async () => {
    try {
      if (checkActivity()) initScroller();
      setLoader(true);
      const { result } = await wrapResult(pageNumber, pageSize);
      // eslint-disable-next-line no-underscore-dangle
      setTotalCount(result._metadata.total_records_count);
      const { records } = result;
      const data = mapHistoryData(records);
      setData(data);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    } finally {
      //   if (!init.current) init.current = true;
      setLoader(false);
    }
  };

  const loadHistory = () => {
    if (checkActivity()) {
      // eslint-disable-next-line no-unused-expressions
      pageNumber !== 1 ? setPageNumber(1) : historyDataList();
    } else if (activity.current === 'paginate' || activity.current === '') {
      historyDataList();
    }
  };

  useEffect(() => {
    loadHistory();
  }, [tractorId, dateFilter, pageNumber]);

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      getTractorsList();
    }
  }, [userDetails]);

  const getTractorsList = async () => {
    try {
      const tractor: { records: TractorsList[] } = await getTractorsListData(
        userDetails.organization.api_url,
        userDetails.organization_id,
        pageNumber,
      );
      const data =
        tractor && tractor.records && tractor.records.length > 0
          ? tractor.records
          : [];
      const tractorData: TractorResponseType[] = data.map((d: TractorsList) => {
        return {
          label: d.name,
          value: d.id,
          disabled: false,
          index: d.id,
          id: d.id,
          connection: d.connection,
          isOnline: d.connection === 'online' ? true : false,
          isAvailable: getTractorAvailableState(d.connection, d?.heartbeat),
        };
      });
      const arr = tractorSort(tractorData, 'label');
      setTractorsData(arr);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    }
  };

  const columns = [
    {
      title: `${t(translate.copycat.tractorName)}`,
      dataIndex: 'tractorName',
      key: 'tractorName',
      width: '14%',
    },
    {
      title: `${t(translate.dashboard.organization)}`,
      dataIndex: 'organization',
      key: 'organization',
      width: '14%',
    },
    {
      title: `${t(translate.prioritizationHistory.consumedDate)}`,
      dataIndex: 'consumedDate',
      key: 'consumedDate',
      width: '14%',
    },
    {
      title: `${t(translate.prioritizationHistory.dataConsumed)} (MB)`,
      dataIndex: 'data_consumed',
      key: 'data_consumed',
      width: '14%',
    },
    {
      title: `${t(translate.prioritizationHistory.filesUpload)}`,
      dataIndex: 'filesUpload',
      key: 'filesUpload',
      width: '14%',
    },
  ];

  const handleLoadMore = () => {
    if (pageNumber === 1 && checkActivity() && document) initScroller();

    activity.current = 'paginate';
    setPageNumber(pageNumber + 1);
  };

  const handleSelect = async (e: any) => {
    setTractorId(e);
    setPageNumber(1);
  };

  return (
    <Layout>
      <div className="mainContent">
        <div className="tblContainer viewportContainer">
          <Row>
            <div className="common_wrapper wWidget_wrapper">
              <div className="filters_card personnelCard">
                <Space>
                  <CustomSelect
                    label="Tractor"
                    cssClass="min_width"
                    value={tractorId}
                    setValue={(id: any) => {
                      handleSelect(id);
                    }}
                    options={tractors.map((item) =>
                      Object.assign({
                        id: item.index,
                        name: item.label,
                        // isAvailable: item.isAvailable,
                      }),
                    )}
                    optionKey="id"
                    optionDisplay="name"
                    isAll={true}
                  />
                  <CustomDate
                    label="Start Date - End Date"
                    cssClass="min_width"
                    value={dateFilter}
                    onChange={(dates: any) => {
                      activity.current = 'filter';
                      setDateFilter(dates);
                    }}
                  ></CustomDate>
                </Space>
              </div>
              <div className="common_table">
                <InfiniteScrollTable
                  columns={columns}
                  loading={loader}
                  hasMore={hasMore}
                  filterData={filterData}
                  totalcount={totalCount}
                  handleLoadMore={handleLoadMore}
                  filename="Records"
                  onRowClick={(record: any) => {
                    setHistoryRecords(record);
                    setHistoryDetailsModal(true);
                  }}
                />
              </div>
              {historyDetailsModal && (
                <HistoryDetailsModal
                  showModel={historyDetailsModal}
                  closeModel={() => setHistoryDetailsModal(false)}
                  historyRecords={historyRecords}
                />
              )}
            </div>
          </Row>
        </div>
      </div>
    </Layout>
  );
};

export default History;
