/* eslint-disable no-unused-expressions */
/* eslint-disable react/require-default-props */
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Modal, notification, Select, Spin } from 'antd';
import translate from '../../../../locale/en_translate.json';
import { ApplicationContext } from '../../../../context/AppContext';
import { getBuildList } from '../../common/types';
import { getBuildVersionsListData, publishChapter } from '../../API';

const { Option } = Select;

interface Props {
  showModal: boolean;
  // eslint-disable-next-line no-unused-vars
  close: (state?: boolean) => void;
  selectedChapter: any;
}

const SwPublishModal: React.FC<Props> = ({
  showModal,
  close,
  selectedChapter,
}: Props) => {
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const [loader, setLoader] = useState<boolean>(false);
  const [goldBuildList, setGoldBuildList] = useState<getBuildList[]>([]);
  const [selectedVersion, setSelectedVersion] = useState<string[]>([]);

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      getGoldBuildList();
    }
  }, [userDetails]);

  const getGoldBuildList = async () => {
    try {
      setLoader(true);
      const { records } = await getBuildVersionsListData(1, 100);
      const data = records.map((record: getBuildList) => ({
        id: record.id,
        software_version: record.software_version,
      }));
      setGoldBuildList(data);
    } catch (error: any) {
      notification.error({
        message: error?.message,
      });
    } finally {
      setLoader(false);
    }
  };

  const handlePublishChapter = async () => {
    try {
      if (selectedChapter && selectedVersion.length) {
        const { id } = selectedChapter;
        const payload = {
          software_version_numbers: selectedVersion.toString(),
        };
        await publishChapter(id, payload);
        notification.success({
          message: 'Successfully published',
        });
      }
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    } finally {
      close(true);
    }
  };

  const handleChange = (value: string[]) => {
    setSelectedVersion(value);
  };

  return (
    <Modal
      title={
        selectedChapter?.category === 'RELEASE_NOTES'
          ? t(translate.releaseNotes.publishReleaseNotes)
          : t(translate.label.publishManual)
      }
      visible={showModal}
      onOk={() => handlePublishChapter()}
      onCancel={() => close()}
      data-testid="publishButton-SwPublishModal"
      okText="Publish"
    >
      <Form.Item>
        <Select
          mode="multiple"
          allowClear
          style={{ overflow: 'hidden' }}
          onChange={handleChange}
          value={selectedVersion}
          placeholder="Select Software Version"
          data-testid="selectSoftwareVersion-SwPublishModal"
          maxTagCount="responsive"
        >
          {goldBuildList.map((item: any) => (
            <Option
              value={item.software_version}
              key={item.id}
              data-testid={`${item.software_version}-SwPublishModal`}
            >
              {item.software_version}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <div className="edit-loader">
        {loader && (
          <div className="loader1">
            <Spin size="large" />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default SwPublishModal;
