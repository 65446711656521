import React, { useEffect, useRef, useState } from 'react';
import { Player } from 'video-react';
import downloadIcon from '../../assets/images/videoDownload.png';
import 'video-react/dist/video-react.css'; // import css
import './style.css';
import { ExpandOutlined, PlayCircleOutlined } from '@ant-design/icons';
import RoutesConstants from '../../routes/RoutesConstant';
import { Empty } from 'antd';

const cameraMap: any = {
  front: 'Front',
  front_left: 'Left',
  front_right: 'Right',
  rear: 'Rear',
  pto: 'Hitch',
  driver: 'driver',
  rear_left: 'rear_left',
  rear_right: 'rear_left',
};

interface Props {
  videos: any[];
  videoRef: any;
  errorRef: any;
  showLoader?: any;
  cameras?: string[];
  playState: boolean;
  play: () => void;
}

const CameraGrid4: React.FC<Props> = ({
  videos,
  videoRef,
  errorRef,
  showLoader,
  cameras = [],
  playState,
  play,
}) => {
  const [index, setIndex] = useState(0);
  const [svideos, setsVideos] = useState<any>([]);
  const videoSortRef = useRef<any>();

  const NOVIDEO = () => {
    return (
      <>{!showLoader && <div className="noVideo">No Video Available</div>}</>
    );
  };

  const videoTag = (i: number, vid: any) => (
    <div id={`${index == i ? 'presentvideo' : 'subvideo'}`}>
      <Player
        ref={(player: any) => {
          videoRef.current[vid.direction] = player;
        }}
        playsInline
        src={vid.video_url}
      />
      {index == i && playState && (
        <div className="backdrop">
          <PlayCircleOutlined onClick={play} />
        </div>
      )}
      <ExpandOutlined
        className="fullScreenVideo"
        onClick={() => openFullscreen(vid.video_url)}
      />
    </div>
  );

  useEffect(() => {
    if (cameras.length) {
      videoSortRef.current = setInterval(() => {
        const elem = document.querySelectorAll('#cameraGrid3Container > div');
        if (elem.length) {
          // initVideoSort('cameraGrid3Container');
          clearInterval(videoSortRef.current);
        }
      }, 1000);
    }
    return () => {
      clearInterval(videoSortRef.current);
    };
  }, [cameras]);

  useEffect(() => {
    const fvideos = videos.reduce(
      (a, vid) => {
        a[vid.direction] = vid;
        return a;
      },
      {
        front: null,
        front_left: null,
        front_right: null,
        rear: null,
        pto: null,
        driver: null,
        rear_left: null,
        rear_right: null,
      },
    );
    const sortVideos: any = Object.values(fvideos).filter((item) => item);
    setsVideos(sortVideos);
  }, [videos]);

  const handleDownload = (video_url: string) =>
    video_url && window.open(video_url, '_self');

  const openFullscreen = (videoUrl: any) => {
    videoUrl &&
      window.open(
        `${RoutesConstants.VideoPop}?videoUrl=${videoUrl}`,
        `Video URL`,
        'max-width: 100%',
      );
  };
  return (
    <div className="camerasContainer videoGrid">
      {svideos.length || showLoader ? (
        <div className="containerMain posrel" id="cameraGrid3Container">
          {svideos
            .filter((vid: any) => !errorRef.current[vid.direction])
            .map((vid: any, i: number) => {
              const { direction } = vid;
              return (
                <>
                  <div
                    onClick={() => setIndex(i)}
                    className={index === i ? 'mainCont' : 'child '}
                    id={
                      cameras.includes(direction)
                        ? String(cameras.indexOf(direction))
                        : '9'
                    }
                  >
                    <p className="snapText"> {cameraMap[vid.direction]}</p>
                    {vid ? videoTag(i, vid) : NOVIDEO()}

                    {index === i && (
                      <a
                        className="video-download-btn"
                        onClick={() => handleDownload(vid.video_url)}
                      >
                        <img src={downloadIcon} />
                      </a>
                    )}
                  </div>
                </>
              );
            })}
        </div>
      ) : (
        <div className="noVideosBlock">
          <Empty description="No videos" />
        </div>
      )}
    </div>
  );
};

export default CameraGrid4;
