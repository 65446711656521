export const camerasVideos: string[] = [
  'FrontCamera',
  'front_left',
  'front_right',
  'RearCamera',
  'PTOCamera',
];
export const cameraName: any = {
  FrontCamera: 'Front',
  front_left: 'Left',
  front_right: 'Right',
  RearCamera: 'Rear',
  PTOCamera: 'Hitch',
};

export const indicator = [
  {
    drive_action_uuid: '17123432918690',
    indicators_data: [
      {
        created_date_time: 1712344446379,
        description: 'Gear shifting stuck',
        error_code: '20607',
        explanation: '',
        fleet_id: 18,
        geostamp:
          '-58933.96986815152|90995.7484461071|-907.0006227187645|12.8164|0.5901',
        id: 258244,
        indicator_history_uuid: '1712344446398',
        indicator_level: 3,
        indicator_name: 'Tractor Drivability',
        indicator_uuid: '1709575600821',
        is_acknowledged: true,
        latitude: 38.46855014009075,
        longitude: -122.38999622813618,
        modified_date_time: 1712344504220,
        organization_id: 20,
        resolved_date_time: null,
        sync_flag: true,
        tractor_id: 146,
      },
      {
        created_date_time: 1712344441188,
        description: '',
        error_code: '20610',
        explanation: '',
        fleet_id: 18,
        geostamp:
          '-58934.064852453455|90995.72303980698|-907.0338800243472|13.5404|0.5831',
        id: 258243,
        indicator_history_uuid: '1712344441193',
        indicator_level: 3,
        indicator_name: 'Tractor Drivability',
        indicator_uuid: '1709575600821',
        is_acknowledged: true,
        latitude: 38.46854990923883,
        longitude: -122.38999731790533,
        modified_date_time: 1712344506334,
        organization_id: 20,
        resolved_date_time: null,
        sync_flag: true,
        tractor_id: 146,
      },
    ],
    tractor: {
      connection: 'online',
      created_date_time: 1710160471238,
      id: 146,
      name: 'W-1022511sa',
      serial_number: 'PVO126',
      tractor_model: 'PV-O',
    },
  },
];
