/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-constant-condition */
/* eslint-disable no-useless-escape */
/* eslint-disable no-console */
import moment from 'moment';
import { renderToString } from 'react-dom/server';

import 'moment-duration-format';
import { Circle, Fill, Icon, Stroke, Style, Text } from 'ol/style';
import tractorIcon from '../assets/images/circleTnb.svg';
import VinerowHeadIcon from '../assets/images/orgRowBdr.png';
import pauseMapIcon from '../assets/images/pause_map_icon.svg';
import tractorAutoDrive from '../assets/images/ts_autodrive.png';
import tractorFaulty from '../assets/images/ts_available.png';
import tractorCharging from '../assets/images/ts_charging.png';
import tractorManual from '../assets/images/ts_manual.png';
import tractorOff from '../assets/images/ts_off.png';
import tractorCopyCat from '../assets/images/ts_routine.png';
import tractorVineRow from '../assets/images/ts_rowfollow.png';
import tractorService from '../assets/images/ts_service.png';
import tractorAvailable from '../assets/images/ts_service.svg';
import tractorShadow from '../assets/images/ts_shadow.png';
import chTractor from './../assets/images/CHTractor.png';
import chTractor1 from './../assets/images/CHTractor1.png';
import cnhTractor from './../assets/images/CNHTractor.png';
import ChargepointIcon from './../assets/images/charge_point_map.svg';
import TicketIcon from './../assets/images/m_ticket.svg';
import operationTicket from './../assets/images/operationTicket.svg';

import MarkIcon from './../assets/images/pinkRow.png';
import pvTractor from './../assets/images/pvTractor.png';
import feedDirection from './../assets/images/round_arrow.svg';
import RoundIcon from './../assets/images/selRound.svg';
import duration_icon from './../assets/images/tractor-icons/duration_icon.svg';
import fleetSpeedIcon from './../assets/images/tractor-icons/fleet_speed_icon.svg';
import speed_V2 from './../assets/images/tractor-icons/speed_V2.svg';
import start_time_icon from './../assets/images/tractor-icons/start_time_icon.svg';
import WaypointIcon from './../assets/images/tractor-icons/waypoint_single.svg';
// import medium from './../assets/images/medium.svg';
import medium from './../components/operationalAnalytics/images/medium.svg';
import low from './../components/operationalAnalytics/images/low.svg';
import drivability from './../components/operationalAnalytics/images/drivability.svg';
import obstacle_detected from './../components/operationalAnalytics/images/obstacle_detected.svg';

import {
  LatLngVertices,
  Localization,
  LocationCoordinates,
  MapFeature,
  MosEventsResp,
  Position,
  TractorHeartBeat,
  TractorsList,
  UserDetails,
} from './types';

import { mqttConstants } from '../util/MqttConstants';
import JumpnGoIconEnd from './../assets/images/end.png';
import highCellular from './../assets/images/high.svg';
import lowCellular from './../assets/images/low.svg';
import snapIcon from './../assets/images/m_sshot.svg';
import mediumCellular from './../assets/images/medium.svg';
import JumpnGoIconStart from './../assets/images/start.png';
import guardRails from './../assets/images/tractor-icons/guardRails.svg';
import guardRailsCluster from './../assets/images/tractor-icons/guardRailsCluster.svg';
import VineIcon from './../assets/images/vine_green.svg';
import fullWifi from './../assets/images/wFull.svg';
import lowWifi from './../assets/images/wLow.svg';
import mediumWifi from './../assets/images/wMedium.svg';

import { Feature } from 'ol';
import Geometry from 'ol/geom/Geometry';
import * as proj from 'ol/proj';
import VectorSource from 'ol/source/Vector';
import CircleStyle from 'ol/style/Circle';
import LowLocalizationNewTractorIcon from '../assets/images/noConnectivity.svg';
import FleetIndicator from '../assets/images/tractor-icons/FleetIndicator.svg';
import operationInd from '../assets/images/operationInd.svg';
import badLoc2 from '../assets/images/tractor-icons/badLoc2.svg';
import charging_hover from '../assets/images/tractor-icons/charging_hover.svg';
import charging_normal from '../assets/images/tractor-icons/charging_normal.svg';
import errorCodeCluster from '../assets/images/tractor-icons/errorCodeCluster.svg';
import DirectionIcon from '../assets/images/tractor-icons/inMotion.svg';
import redDirectionIcon from '../assets/images/tractor-icons/inRedMotion.svg';
import manualDirectionIcon from '../assets/images/tractor-icons/manualDirectionIcon.svg';
import redIcon from '../assets/images/tractor-icons/redIcon.svg';
import rvDirectionIcon from '../assets/images/tractor-icons/rvDirectionIcon.svg';
import selected_tractor_charging from '../assets/images/tractor-icons/selected_tractor_charging.svg';
import selected_tractor_nouser from '../assets/images/tractor-icons/selected_tractor_nouser.svg';
// import selected_tractor_user from '../assets/images/selected_tractor_user.svg';
import NewTractorIcon from '../assets/images/standBy.png';
import selected_tractor_user_faulty from '../assets/images/tractor-icons/selected_tractor_user_faulty.svg';
import {
  default as selected_tractor_user,
  default as selected_tractor_user_manual,
} from '../assets/images/tractor-icons/selected_tractor_user_manual.svg';
import selected_tractor_user_rv from '../assets/images/tractor-icons/selected_tractor_user_rv.svg';
// import tooltip_icon from '../assets/images/tooltip_icon.svg';
import tooltip_icon_medium from '../assets/images/tractor-icons/tooltip_icon_medium.svg';
import tractor__hover from '../assets/images/tractor-icons/tractor__hover.svg';
import tractor__normal from '../assets/images/tractor-icons/tractor__normal.svg';
import tractor_bg_shadow from '../assets/images/tractor-icons/tractor_bg_shadow.svg';
import tractor_lock_hover from '../assets/images/tractor-icons/tractor_lock_hover.svg';
import tractor_lock_normal from '../assets/images/tractor-icons/tractor_lock_normal.svg';
// import tractor_offline from '../assets/images/tractor_offline.svg';
// import tractor_offline_hover from '../assets/images/tractor_offline_hover.svg';
// import tractor_offline_selected from '../assets/images/tractor_offline_selected.svg';
import {
  default as tractor_offline,
  default as tractor_offline_hover,
  default as tractor_offline_selected,
} from '../assets/images/tractor-icons/tractor_offline.svg';
import tractorsCluster from '../assets/images/tractor-icons/tractorsCluster.svg';
import tractorsClusterHover from '../assets/images/tractor-icons/tractorsClusterHover.svg';

import waypointsCluster from '../assets/images/tractor-icons/waypointsCluster.svg';
import faultyTractorIcon from './../assets/images/tractor-icons/faultyTractorIcon.svg';
import conv3 from './../assets/images/wFull_w.svg';
import conv1 from './../assets/images/wLow_w.svg';
import conv2 from './../assets/images/wMedium_w.svg';

import { notification } from 'antd';
import { Auth } from 'aws-amplify';
// must add jsts
// import { io } from 'jsts';
declare let jsts: any;

// const { GeoJSONReader } = io;
// must add jsts
import { Coordinate } from 'ol/coordinate';
import GeoJSON from 'ol/format/GeoJSON';

import LineString from 'ol/geom/LineString';
import Point from 'ol/geom/Point';
import Polygon from 'ol/geom/Polygon';
import { getArea } from 'ol/sphere';

import { AdminHeartBeat } from '../components/admin/types';
import { FeedLaneType } from '../lib/types';
import {
  getAbBlockVerticesData,
  getBrownPolygonData,
  getPolyLineVerticesData,
  getPolygonData,
} from './Api';
import constant, {
  AB_BLOCK,
  ATOM,
  AUTO_DRIVE_METRIC_ESTIMATED_TIME_ARRIVAL,
  AUTO_DRIVE_METRIC_REFILL_POSE2D,
  BadLocalization,
  GUARD_RAILS,
  HOVER,
  MOBILE,
  MOS_CLOUD,
  PAUSED,
  POLYGON,
  POLY_LINE,
  POLY_LINE_ID_CONSTANT,
  SELECTED,
  STOPPED,
  SYSTEM_PAUSE,
  WEB,
  dairyAutoDrive,
  distanceForGoodPoints,
  getFirstArrayByPriority,
  interruptMessageTypes,
  redBdr,
  vineYardAutoDrive,
} from './constant';
import { getSeverity } from '../components/fleethealth/Diagnostics';
import { fetchName } from '../components/operationalAnalytics/common';
import { splitArrayIntoChunks } from '../components/operationalAnalytics/constants';
declare let google: any;

export const getAccessJwtToken = async () => {
  // Auth.currentSession() checks if token is expired and refreshes with Cognito if needed automatically
  const session = await Auth.currentSession();
  const access_token = await session.getIdToken().getJwtToken();
  localStorage.setItem('access_token', access_token);
  return access_token;
};

export const getHeaders = async (): Promise<{
  Authorization: string;
  Api_Token?: string;
}> => {
  await getAccessJwtToken();
  return {
    Authorization: localStorage.getItem('access_token') || '',
    Api_Token: localStorage.getItem('auth') || '',
  };
};

export const getDealerHeaders = async (): Promise<any> => {
  await getAccessJwtToken();
  return {
    Authorization: localStorage.getItem('access_token') || '',
    'Api-token': localStorage.getItem('dealer_api_token') || '',
  };
};

export const getAtomHeaders = async (): Promise<any> => {
  await getAccessJwtToken();
  return {
    Authorization: localStorage.getItem('access_token') || '',
    'Api-token': localStorage.getItem('dealer_api_token') || '',
  };
};

export const getUserName = (userDetails: UserDetails): string => {
  return `${userDetails.first_name} ${userDetails.last_name}`;
};

export const getLatitudeAndLongitude = (
  location: [number, number],
  base: [number, number],
  reverse = false,
): [number, number] => {
  const rEarth = 111319; // radius of earth is approximately 6378 kms (6378137 meters * pi/180)
  const deltaLat = location[0];
  const deltaLon = location[1];
  const newLatitude = base[0] + deltaLat / rEarth;
  const newLongitude =
    base[1] + deltaLon / rEarth / Math.cos(newLatitude * 0.01745);
  // default we send the lat after long
  return reverse ? [newLongitude, newLatitude] : [newLatitude, newLongitude];
};

export const convertRouteXYtoLatLang = (
  data: { y: number; x: number }[],
  groundLat: number,
  groundLang: number,
): { y: number; x: number }[] => {
  const rEarth = 111319; // radius of earth is approximately 6378 kms (6378137 meters * pi/180)
  data.forEach((element: { y: number; x: number }) => {
    const deltaLat = element.y;
    const deltaLon = element.x;
    element.y = groundLat + deltaLat / rEarth;
    element.x = groundLang + deltaLon / rEarth / Math.cos(element.y * 0.01745);
  });
  return data;
};

export const isAdmin = (userDetails: UserDetails): boolean => {
  if (userDetails && userDetails.roles) {
    const { roles } = userDetails;
    const admins = roles.filter((role) => {
      return role.role_key === 'ORG_ADMIN';
    });
    return admins.length > 0;
  }
  return false;
};
// const minutes = Math.floor(time / 60);
export const getMinsFromSecs = (time: number): number => {
  return Math.floor(time / 60);
};
export const getDateTime = (timestamp: number): string => {
  return moment(timestamp).format('MMM D, YYYY h:mm A');
};

export const getDateTimes = (timestamp: number): string => {
  return moment(timestamp).format('MM/D/YY h:mm A');
};

export const getDateTimesByString = (timestamp: string): string => {
  return moment(timestamp).format('MM/D/YY h:mm A');
};

export const getDateAndTime = (timestamp: number): string => {
  return moment(timestamp).format('MM-D-YYYY h:mm A');
};
export const getTimeOnly = (timestamp: number): string => {
  return moment(timestamp).format('hh:mm A');
};

export const getDateStamp = (timestamp: number): string => {
  return moment(timestamp).format('YYYY-MM-D h:mm:ss A');
};

export const getDate = (timestamp: number): string => {
  return moment(timestamp).format('MMM D, YYYY');
};
export const getDateOnly = (timestamp: number): string => {
  return moment(timestamp).format('MM/DD');
};

export const chatDate = (timestamp: number): string => {
  return moment(timestamp).format('MMM Do [at] h:mm A');
};

export const getFullDate = (timestamp: number): string => {
  return moment(timestamp).format('MM/D/YYYY');
};

export const getDateYMD = (timestamp: number): string => {
  return moment(timestamp).format('YYYY-MM-DD');
};

export const getTime = (timestamp: number): string => {
  return moment
    .duration(timestamp, 'seconds')
    .format('hh:mm:ss', { trim: false });
};
export const getHour = (timestamp: number): string => {
  return moment(timestamp).format('h:mm A');
};

export const getAnalyticFormat = (dates: any | undefined): string => {
  return dates
    ? `${moment(dates[0]).format('MMM')} ${moment(dates[0]).format(
        'D',
      )} - ${moment(dates[1]).format('D')}`
    : '-';
};

export const getDateFromString = (strDate: string): string => {
  const timestamp = Date.parse(strDate);
  return getDate(timestamp);
};

export const getSatelightRGBColor = (
  color: string,
  suggested = false,
): string => {
  switch (color) {
    case 'white':
      return 'rgba(255,255,255)';
    case 'green':
      return '#328D3B';
    case 'red':
      return '#FF0000';
    case 'yellow':
      return '#EBD36B';
    case 'blue':
      return suggested ? '#06348a' : '#7BA1E5';
    case 'brown':
      return '#7C5032';
    case 'abBlock':
      return '#032d57';
    case 'vineLaneGreen':
      return '#379526';
    case 'vineLaneYellow':
      return '#rgba(235, 211, 107, 0.15)';
    default:
      return color;
  }
};

export const getSatelightRGBFillColor = (
  color: string,
  suggested = false,
  type = '',
): string => {
  switch (color) {
    case 'white':
      return 'rgba(255,255,255,0.1)';
    case 'green':
      // return 'rgba(148, 204, 161, 0.22)';
      // return suggested ? 'rgba(160, 32, 240, .6)' : 'rgba(148, 204, 161, 0.22)';
      // return suggested
      //   ? 'rgba(148, 204, 161, 0.72)'
      //   : `rgba(148, 204, 161,${
      //       type === SELECTED || type === HOVER ? 0.8 : 0.22
      //     })`;
      return suggested
        ? '#328D3B'
        : `rgba(148, 204, 161,${
            type === SELECTED || type === HOVER ? 0.8 : 0.22
          })`;
    case 'red':
      return 'rgba(203, 52, 15, 0.15)';
    case 'yellow':
      return 'rgba(235, 211, 107, 0.15)';
    case 'blue':
      // return suggested ? 'rgba(6,53,138, 0.90)' : 'rgba(123, 161, 229, 0.40)';
      return suggested ? '#06348a' : 'rgba(123, 161, 229, 0.40)';
    case 'brown':
      return 'rgba(186, 156, 119, 0.1)';
    case 'abBlock':
      // return '#032d57';
      return 'rgba(3, 45, 87, 0.12)';
    case 'vineLaneGreen':
      return '#A3D5D8A0';
    case 'vineLaneYellow':
      return '#EBD36B';

    default:
      return color;
  }
};

export const getRoadMapRGBColor = (
  color: string,
  suggested = false,
  type = '',
): string => {
  switch (color) {
    case 'white':
      return 'rgba(255,255,255)';
    case 'green':
      return '#2FB53B';
    case 'red':
      return '#FF0000';
    case 'yellow':
      return '#EBD36B';
    case 'blue':
      return suggested ? '#06348a' : '#7BA1E5';
    case 'brown':
      return '#808285';
    case 'abBlock':
      return '#032d57';
    default:
      return color;
  }
};

export const getRoadMapRGBFillColor = (
  color: string,
  suggested = false,
  type = '',
): string => {
  switch (color) {
    case 'white':
      return 'rgba(255,255,255,0.1)';
    case 'green':
      return suggested
        ? 'rgba(148, 204, 161, 0.72)'
        : 'rgba(216, 230, 217, 0.77)';
    case 'red':
      return 'rgba(203, 52, 15, 0.15)';
    case 'yellow':
      return 'rgba(235, 211, 107, 0.15)';
    case 'blue':
      return suggested ? 'rgba(6,53,138, 0.90)' : 'rgba(123, 161, 229, 0.78)';
    case 'brown':
      return 'rgba(234, 212, 189. 0.96)';
    case 'abBlock':
      return 'rgba(3, 45, 87, 0.12)';
    default:
      return color;
  }
};

export const isNumber = (value: string): boolean => {
  const re = /^[0-9\b]+$/;
  return re.test(value);
};

export const suggestedPolygon = 'suggested-green';

export const indicatorsOrder = [
  'Tractor Communication',
  'Tractor Drivability',
  'Tractor Automation',
  'Tractor Data',
  'Tractor Energy',
];

export const guardRailtypes = [
  // '',
  'human_protection',
  'vehicle_protection',
  'pitch_protection',
  'roll_protection',
  'path_protection',
  'hitch_protection',
  'slip_protection',
  'pto_protection',
  'speed_protection',
];

export const isValidEmail = (value: string): boolean => {
  const regEmail =
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regEmail.test(value);
};

export const betaCams: any = {
  front: 'Front',
  'front-right': 'Right',
  'front-left': 'Left',
  rear: 'Rear',
  pto: 'Hitch',
};

export const pilotCams = [
  { camera_name: 'front', title: 'Front' },
  { camera_name: 'front-right', title: 'Right' },
  { camera_name: 'front-left', title: 'Left' },
  { camera_name: 'rear', title: 'Rear' },
  { camera_name: 'rear-right', title: 'Rear Right' },
  { camera_name: 'rear-left', title: 'Rear Left' },
  { camera_name: 'pto', title: 'Hitch' },
  { camera_name: 'driver-cam', title: 'Driver' },
  { camera_name: 'chassis-left', title: 'Chassis Left' },
  { camera_name: 'chassis-right', title: 'Chassis Right' },
];

export const getXYCoordinates = (
  latlong: [number, number] | number[],
  base: [number, number],
): [number, number] => {
  const rEarth = 111319; // radius of earth is approximately 6378 kms (6378137 meters * pi/180)
  const newLatitude = latlong[1];
  const newLongitude = latlong[0];
  let y: any = rEarth * (newLatitude - base[0]);
  let x: any =
    (newLongitude - base[1]) * Math.cos(newLatitude * 0.01745) * rEarth;
  x = x * 100;
  y = y * 100;
  return [x, y];
};

export const getRAVXYCoordinates = (
  latlong: [number, number] | number[],
  base: [number, number],
): [number, number] => {
  const rEarth = 111319; // radius of earth is approximately 6378 kms (6378137 meters * pi/180)
  const newLatitude = latlong[1];
  const newLongitude = latlong[0];
  const y: any = rEarth * (newLatitude - base[0]);
  const x: any =
    (newLongitude - base[1]) * Math.cos(newLatitude * 0.01745) * rEarth;
  return [x, y];
};

export const getXYCoordinatesList = (
  list: any,
  base: [number, number],
): { x: number; y: number }[] => {
  const rEarth = 111319; // radius of earth is approximately 6378 kms (6378137 meters * pi/180)
  const prepare: any[] = [];
  list.forEach((item: any) => {
    const newLatitude = item[1];
    const newLongitude = item[0];
    let y = rEarth * (newLatitude - base[0]);
    let x = (newLongitude - base[1]) * Math.cos(newLatitude * 0.01745) * rEarth;
    x = x * 100;
    y = y * 100;
    prepare.push({ x, y });
  });
  return prepare;
};

export const priority: any = {
  DRAWLAYER: 13,
  TRACTOR: 12,
  REFILL_SEEDER: 11,
  BASESTATION: 11,
  ROUTINE: 10.1,
  PATH: 10,
  PREVIEWPATH: 9.5,
  SNAP: 10.5,
  Ticket: 10.5,
  INDICATOR: 10.5,
  OPERATION_INDICATOR: 10.5,
  Operation_Ticket: 10.5,
  SPEED: 10.5,
  CHARGEPOINTS: 10.1,
  WAYPOINT: 10.1,
  VINE: 8,
  VINEROW: 7,
  VINEBLOCK: 6,
  ABLANE: 7,
  POLY_LINE: 7,
  ABBLOCK: 5.5,
  POLYGON: {
    suggestedGreen: 6,
    blue: 5,
    green: 4,
    red: 3,
    yellow: 2,
    white: 1.5,
    brown: 1,
  },
};

export const vineBlockStyle = new Style({
  stroke: new Stroke({
    color: '#6a1287',
    width: 3,
  }),
});
export const vineRowStyle = [
  new Style({
    stroke: new Stroke({
      color: '#002B04',
      width: 9,
    }),
    zIndex: 0,
  }),
  new Style({
    stroke: new Stroke({
      color: '#3E7743',
      width: 7,
    }),
    zIndex: 1,
  }),
];
export const abLineStyle = [
  new Style({
    stroke: new Stroke({
      color: '#032d57',
      width: 9,
    }),
    zIndex: 0,
  }),
  new Style({
    stroke: new Stroke({
      color: '#0b6ea9',
      width: 7,
    }),
    zIndex: 1,
  }),
];

export const PolyLineStyle = [
  new Style({
    stroke: new Stroke({
      color: '#032d57',
      width: 3,
    }),
    zIndex: 0,
  }),
  new Style({
    stroke: new Stroke({
      color: '#690cb0',
      width: 2,
    }),
    zIndex: 1,
  }),
];

export const vineRowNewFromJAGStyle = [
  new Style({
    stroke: new Stroke({
      color: '#red',
      width: 9,
    }),
    zIndex: 0,
  }),
  new Style({
    stroke: new Stroke({
      color: '#2596be',
      width: 7,
    }),
    zIndex: 1,
  }),
];
export const vineRowFromJAGStyle = [
  new Style({
    stroke: new Stroke({
      color: '#2596be',
      width: 9,
    }),
    zIndex: 0,
  }),
  new Style({
    stroke: new Stroke({
      color: '#2596be',
      width: 7,
    }),
    zIndex: 1,
  }),
];
export const previewVineRowStyle = [
  new Style({
    stroke: new Stroke({
      color: '#be258b',
      width: 9,
    }),
    zIndex: 0,
  }),
  new Style({
    stroke: new Stroke({
      color: '#be258b',
      width: 7,
    }),
    zIndex: 1,
  }),
];
export const getVineRowStyle1 = (sateLight = true, zoom = false) => [
  new Style({
    stroke: new Stroke({
      color: '#379526',
      width: 9,
    }),
    zIndex: 0,
  }),
  new Style({
    stroke: new Stroke({
      color: '#A3D5D8A0',
      width: 7,
    }),
    zIndex: 1,
  }),
];
export const feedLaneStyle = [
  new Style({
    stroke: new Stroke({
      color: '#000',
      width: 9,
    }),
    zIndex: 0,
  }),
  new Style({
    stroke: new Stroke({
      color: '#007AFF',
      width: 7,
    }),
    zIndex: 1,
  }),
];
export const selectedFeedLaneStyle = [
  new Style({
    stroke: new Stroke({
      color: '#000',
      width: 9,
    }),
    zIndex: 0,
  }),
  new Style({
    stroke: new Stroke({
      color: '#EC9322',
      width: 7,
    }),
    zIndex: 1,
  }),
];
export const getVineRowStyle = (sateLight = true, zoom = false) => [
  new Style({
    stroke: new Stroke({
      // color: setilight ? '#002B04' : '086E12',
      color: sateLight ? '#213F1A' : '#086E12',
      width: zoom ? 9 : 3,
    }),
    zIndex: 0,
  }),
  new Style({
    stroke: new Stroke({
      // color: setilight ? '#3E7743' : '76A87A',
      color: sateLight ? '#4C7648' : '#76A87A',
      width: zoom ? 7 : 1,
    }),
    zIndex: 1,
  }),
];
export const getVineRowColorStyle = () => [
  new Style({
    stroke: new Stroke({
      // color: '#FFFFFF',
      color: getPathBorderColor(tractorConstantsNew.remote_av.key),
      width: 9,
    }),
    zIndex: 500,
  }),
  new Style({
    stroke: new Stroke({
      // color: 'rgba(0,183,255,255)',
      color: getPathColor(tractorConstantsNew.remote_av.key),
      width: 7,
    }),
    zIndex: 501,
  }),
];

export const vineRowDisableStyle = new Style({
  stroke: new Stroke({
    color: '#0099ff',
    width: 2,
  }),
});

export const vineLaneStyle = new Style({
  stroke: new Stroke({
    // color: 'rgba(255, 255, 255, 0)',
    color: 'black',
    width: 0.1,
  }),
  fill: new Fill({
    color: 'rgba(180, 130, 130, 0.85)',
  }),
});
export const refillSeederStyle = new Style({
  image: new Icon({
    src: pauseMapIcon,
    anchor: [0.5, 1],
  }),
});
export const markStyle = new Style({
  image: new Icon({
    src: MarkIcon,
    size: [10, 10],
    // scale: 0.5,
    scale: 1,
  }),
});

export const vineRowHeadStyle = new Style({
  image: new Icon({
    src: VinerowHeadIcon,
    size: [10, 10],
    // scale: 0.5,
    scale: 1,
  }),
});

export const vineRowHoverStyle = (zoom: boolean) => [
  new Style({
    stroke: new Stroke({
      // color: '#3E7743',
      color: '#517F4D',
      width: zoom ? 9 : 3,
    }),
    zIndex: 0,
  }),
  new Style({
    stroke: new Stroke({
      // color: '#51BA5A',
      color: '#8AB187',
      width: zoom ? 7 : 1,
    }),
    zIndex: 1,
  }),
];

export const wayPointStyle = new Style({
  image: new Icon({
    src: WaypointIcon,
    // anchor: [0.5, 0.5],
    anchor: [0.5, 1],

    // size: [40, 40],
    // scale: 0.5,
  }),
});

export const wayPointHoverStyle = (text: string, isSatilight: boolean) =>
  new Style({
    image: new Icon({
      src: WaypointIcon,
      // size: [40, 40],
      scale: 1.2,
      anchor: [0.5, 1],
    }),
    text: new Text({
      text: text,
      // scale: 0.7,
      fill: new Fill({
        color: isSatilight ? '#fff' : '#000',
      }),
      stroke: new Stroke({
        color: isSatilight ? '#000' : '#fff',
        width: 4,
      }),
      offsetX: 10,
      offsetY: 0,
      font: '20px Montserrat-ExtraBold',
      textAlign: 'left',
      rotation: 0,
    }),
  });

export const wayPointClusterHoverStyle = (size: number, hover = false) =>
  new Style({
    image: new Icon({
      // src: hover ? waypointsClusterHover : waypointsCluster,
      src: waypointsCluster,
      scale: hover ? 1.2 : 1,
      anchor: [0.25, 1],
    }),
    text: new Text({
      text: size.toString(),
      scale: hover ? 1.2 : 1,
      fill: new Fill({
        color: '#fff',
      }),
      offsetX: hover ? 22 : 18,
      offsetY: hover ? -40 : -34,
      font: '15px Montserrat-ExtraBold',
    }),
  });

export interface RecenterCommand {
  type: string;
  mapId: string;
}

export const secondsToHms = (d: any, shortForm = false) => {
  d = Number(d);
  const h = Math.floor(d / 3600);
  const m = Math.floor((d % 3600) / 60);
  const s = Math.floor((d % 3600) % 60);

  let hDisplay = h > 0 ? h + (h == 1 ? ' hour ' : ' hours ') : '';
  let mDisplay = m > 0 ? m + (m == 1 ? ' minute ' : ' minutes ') : '';
  let sDisplay = s > 0 ? s + (s == 1 ? ' second' : ' seconds') : '';
  if (shortForm) {
    hDisplay = h > 0 ? h + (h == 1 ? ' hr ' : ' hrs ') : '';
    mDisplay = m > 0 ? m + (m == 1 ? ' min ' : ' mins ') : '';
    sDisplay = s > 0 ? s + (s == 1 ? ' sec' : ' secs') : '';
  }

  if (h <= 0 && m <= 0) {
    return sDisplay;
  }
  if (h === 0 && m > 0) {
    return mDisplay;
  } else if (h > 0 && m > 0) {
    return `${hDisplay}, ${mDisplay}`;
  } else if (h == 0 && m > 0 && s > 0) {
    return `${mDisplay}, ${sDisplay}`;
  } else {
    return hDisplay;
  }
};
export const row_follow_and_fallow_me_color = '#F1CE90';

export const tractorConstantsNew = {
  off: {
    key: 'off',
    value: 'Offline',
    class: 't_off',
    image: 'ts_off',
    color: '#CECECF',
  },
  available: {
    key: 'available',
    value: 'Available',
    class: 't_available',
    image: 'ts_available',
    color: '#EC9322',
  },
  charging: {
    key: 'charging',
    value: 'Charging',
    class: 't_charging',
    image: 'ts_charging',
    color: '#738023',
  },
  standby: {
    key: 'standby',
    value: 'Manual',
    class: 't_manual',
    image: 'ts_manual',
    color: '#366376',
  },
  copycat: {
    key: 'copycat',
    value: 'Routines',
    class: 't_copycat',
    image: 'ts_routine',
    color: '#546CB2',
  },
  follow_me: {
    key: 'follow_me',
    value: 'Shadow',
    class: 't_follow_me',
    image: 'ts_shadow',
    color: row_follow_and_fallow_me_color,
  },
  manual: {
    key: 'manual',
    value: 'Manual',
    class: 't_manual',
    image: 'ts_manual',
    color: '#366376',
  },
  vinerow: {
    key: 'vinerow',
    value: 'Row Follow',
    class: 't_vinerow',
    image: 'ts_rowfollow',
    color: row_follow_and_fallow_me_color,
  },
  remote_av: {
    key: 'remote_av',
    value: 'Autodrive',
    class: 't_remote_av',
    image: 'ts_autodrive',
    color: '#ec9322',
  },
  default: {
    key: 'default',
    value: 'Manual',
    class: 't_manual',
    image: 'ts_manual',
    color: '#366376',
  },
  faulty: {
    key: 'faulty',
    value: 'Error',
    class: 't_faulty',
    image: 'ts_faulty',
    color: '#D43D38',
  },
  service: {
    key: 'service',
    value: 'Service',
    class: 't_service',
    image: 'ts_service',
    color: '#CFCFCF',
  },
  update: {
    key: 'update',
    value: 'Updating',
    class: 't_update',
    image: 'ts_manual',
    color: '#007AFF54',
  },
};

export const tractorStateConstants = {
  CHARGING: 'Charging',
  INUSE: 'In Use',
  AVAILABLE: 'Available',
  OFF: 'Off',
  FAULTY: 'Faulty',
};

export const getTractorStatusClass = (
  isAvailable: string,
  drive_action_created_at = null,
): string =>
  `fleetMsgTxt ${getStatusClass(isAvailable, drive_action_created_at)}`;

export const getOperationStatus = (heartBeat: TractorHeartBeat): string => {
  return getOperationNewStatus(heartBeat);
};

export const getOperationNewStatus = (heartBeat: TractorHeartBeat): string => {
  if (heartBeat) {
    const charge = getBatteryStatus(heartBeat);
    const battery = Math.trunc(
      Number(heartBeat?.tractor_model?.split(',')[mqttConstants.ENERGY]),
    );
    const n2Status = Boolean(
      Number(
        heartBeat?.system_model?.split(',')[mqttConstants.N2_POWER_STATUS],
      ),
    );
    const { lockout_details, ota } = heartBeat;
    const status =
      lockout_details && lockout_details.is_tractor_lockout ? true : false;
    const updateStatus = ota && ota.is_ota_started ? true : false;
    if (updateStatus) {
      return tractorConstantsNew.update.key;
    }
    if (status) {
      return tractorConstantsNew.service.key;
    }
    if ((battery === -1 || !n2Status) && !heartBeat.is_simulator) {
      return tractorConstantsNew.faulty.key;
    }
    if (charge) {
      return tractorConstantsNew.charging.key;
    }
    return heartBeat.drive_action_details &&
      heartBeat.drive_action_details.current_drive_action
      ? heartBeat.planning_manager && heartBeat.planning_manager.planner_name
        ? heartBeat.planning_manager.planner_name
        : tractorConstantsNew.manual.key
      : tractorConstantsNew.available.key;
    // return heartBeat.drive_action_details &&
    // heartBeat.drive_action_details.current_drive_action
    // ? heartBeat.planning_manager && heartBeat.planning_manager.planner_name
    //   ? heartBeat.planning_manager.planner_name === 'standby'
    //     ? tractorContantsNew.manual.key
    //     : heartBeat.planning_manager.planner_name
    //   : tractorContantsNew.manual.key
    // : tractorContantsNew.available.key;
  }
  return tractorConstantsNew.off.key;
};

export const getBatteryPercentage = (tractor: any) =>
  (tractor &&
    tractor.heartbeat &&
    tractor.heartbeat.tractor_model &&
    tractor.heartbeat.tractor_model.length > 0 &&
    Math.trunc(
      Number(tractor.heartbeat.tractor_model.split(',')[mqttConstants.ENERGY]),
    )) ||
  '0';

export const getBatteryStatus = (heartbeat: any): boolean =>
  heartbeat &&
  heartbeat.tractor_model &&
  heartbeat.tractor_model.length > 0 &&
  Number(heartbeat?.tractor_model?.split(',')[mqttConstants.CHARGINGSTATUS]) ===
    1;

export const getInternetSpeed = (heartbeat: any): number =>
  heartbeat &&
  heartbeat.system_model &&
  heartbeat.system_model.length > 0 &&
  Math.round(
    Number(heartbeat?.system_model?.split(',')[mqttConstants.INTERNET_SPEED]),
  );

export const getInitials = (
  firstName: string | undefined,
  lastName: string | undefined,
): string => {
  if (firstName && lastName) {
    return `${firstName.charAt(0)}${lastName.charAt(0)}`;
  }
  return '';
};

export const sortAOByKey = (data: any[], key: string) => {
  function compare(a: any, b: any) {
    if (a[key] < b[key]) {
      return -1;
    }
    if (a[key] > b[key]) {
      return 1;
    }
    return 0;
  }
  data.sort(compare);
  return data;
};

export const sortKeyWrapper = (url: string, sort: any): string => {
  if (sort && Object.keys(sort).length) {
    const sort_order = sort.order === 'descend' ? 'DESC' : 'ASC';
    const sort_column = sort.columnKey;
    url += `&sort_order=${sort_order}&sort_column=${sort_column}`;
  }
  return url;
};

export const fromToDateWrapper = (dates: any[]) => {
  let [from, to] = dates;
  from = moment(
    `${from.get('month') + 1}-${from.get('date')}-${from.get('year')}`,
    'MM-DD-YYYY',
  );
  to = moment(
    `${to.get('month') + 1}-${to.get('date')}-${to.get('year')}`,
    'MM-DD-YYYY',
  )
    .add(1, 'day')
    .subtract('1', 'second');
  return [from, to];
};

export const getConnectivityImg = (state: number, hover = false) => {
  let img =
    state === 0 || state === 1 ? fullWifi : state === 2 ? mediumWifi : lowWifi;
  if (hover)
    img = state === 0 || state === 1 ? conv3 : state === 2 ? conv2 : conv1;
  return img;
};

export const getNetworkTypeImg = (record: any, hover = false): string => {
  const { indicators: data } = record;
  const indicatorsData = wrapIndictorsData(data);
  const state = indicatorsData[constant.TRACTOR_COMMUNICATION];
  const content = record?.heartbeat?.system_model?.split(',');
  const type =
    content && content?.length >= 210
      ? content[mqttConstants.ACTIVE_NETWORk]
      : '';
  let img = '';
  if (type === 'cellular') {
    img =
      state === 0 || state === 1
        ? highCellular
        : state === 2
        ? mediumCellular
        : lowCellular;
  } else {
    img =
      state === 0 || state === 1
        ? fullWifi
        : state === 2
        ? mediumWifi
        : lowWifi;
  }

  if (hover)
    img = state === 0 || state === 1 ? conv3 : state === 2 ? conv2 : conv1;
  return img;
};

export const handleSelectedClass = (
  e: React.MouseEvent<HTMLElement, MouseEvent>,
) => {
  const items = document.getElementsByClassName('rowClassName1');
  const elements = Array.from(items);
  elements.forEach((item: any) => {
    item.className = item.className.replace(' row-selected', '');
  });
  e.currentTarget.className = `${e.currentTarget.className} row-selected`;
};

export const initClearSelection = () => {
  const elements = document.getElementsByClassName('row-selected');
  Array.from(elements).forEach(function (element) {
    element.className = element.className.replace(' row-selected', '');
  });
};
export const batteryValue = (tab: any): number => {
  return Math.trunc(
    Number(tab?.heartbeat?.tractor_model?.split(',')[mqttConstants.ENERGY]),
  ) > 0
    ? Math.trunc(
        Number(tab?.heartbeat?.tractor_model?.split(',')[mqttConstants.ENERGY]),
      )
    : 0;
};

export const batteryVal = (heartbeat: any): number => {
  return Math.trunc(
    Number(heartbeat?.tractor_model?.split(',')[mqttConstants.ENERGY]),
  );
};

export const secondsToHours = (timeInSeconds: number): string => {
  let hours: string | number = Math.floor(timeInSeconds / 3600);
  if (hours.toString().length === 1) {
    hours = `0${hours}`;
  }
  /**
   * Gets remaining seconds.
   * @function remainingSeconds
   * @returns the seconds.
   */
  const remainingSeconds = timeInSeconds % 3600;
  let minutes: string | number = Math.floor(remainingSeconds / 60);
  if (minutes.toString().length === 1) {
    minutes = `0${minutes}`;
  }
  if (hours || minutes) return `${hours}:${minutes}`;
  else return '-';
};

export const getTripMeterValue = (heartbeat: TractorHeartBeat): string => {
  let res = '0:00';
  const startTime = heartbeat?.drive_action_details?.drive_action_start_time;
  const endTime = moment().valueOf();
  let min_difference;
  let hour_difference;
  if (startTime) {
    let difference = endTime - startTime;
    difference = Math.floor(difference / 1000);
    difference = Math.floor(difference / 60);
    min_difference = difference % 60;
    difference = Math.floor(difference / 60);
    hour_difference = difference % 24;
    res = `${hour_difference > 0 ? hour_difference : '0'}`;
    res = `${res} : ${
      min_difference > 0
        ? min_difference?.toString().length === 2
          ? min_difference
          : '0' + min_difference
        : '00'
    }`;
  }
  return res;
};

export const onlyNumbersAllow = (event: any) => {
  if (!/[0-9]/.test(event.key)) {
    return event.preventDefault();
  }
};

export const onlyNumberAndDecimalsAllow = (event: any) => {
  if (/[^0-9.{0,2}]/g.test(event.key)) {
    return event.preventDefault();
  }
};

export const onlyImplmentsNumberAndDecimalsAllow = (event: any) => {
  const regx = /^[+-]?\d+(\.\d+)?$/;
  if (regx.test(event)) {
    return event.preventDefault();
  }
};

export const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
};

export const validatePassword = (password: string): string => {
  if (password.length < 8) {
    return 'Password should contains At least 8 characters';
  }
  const lowercaseRegex = /(?=.*[a-z])/; // has at least one lower case letter
  if (!lowercaseRegex.test(password)) {
    return 'Password should contains At least 1 lowercase letter';
  }
  const uppercaseRegex = /(?=.*[A-Z])/; // has at least one upper case letter
  if (!uppercaseRegex.test(password)) {
    return 'Password should contains At least 1 Upper case letter';
  }
  const numRegex = /(?=.*[0-9])/; // has at least one number
  if (!numRegex.test(password)) {
    return 'Password should contains At least 1 number from 0 to 9';
  }
  const specialcharRegex = /[~!@#$%^&*()_+]/;
  if (!specialcharRegex.test(password)) {
    return 'Password should contains At least 1 special characters from below ~!@#$%^&*()_+';
  }
  return '';
};

export const specialcharRegex = (text: any): any => {
  const exp = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
  const regexp = new RegExp(exp);
  if (regexp.test(text !== undefined && text)) {
    return true;
  }
  return false;
};

export const getReleaseDate = (releaseDate: number): string =>
  releaseDate
    ? moment().diff(moment(releaseDate), 'hours') < 24
      ? 'today'
      : moment().diff(moment(releaseDate), 'hours') >= 24 &&
        moment().diff(moment(releaseDate), 'hours') <= 48
      ? 'yesterday'
      : moment(releaseDate).endOf('day').fromNow()
    : '';

export const wrapIndictorsData = (arr: any) => {
  const data = arr.reduce((a: any, c: any) => {
    a[c.indicator_key] = c.indicator_level;
    return a;
  }, {});
  return data;
};

export const polygonHoverLabel = (map: any): void => {
  let selected: any = null;
  map &&
    map.on('pointermove', function (e: any) {
      if (selected !== null && selected.get('type') === 'Polygon') {
        selected.getStyle()?.getText()?.setText('');
        selected.setStyle(selected.getStyle());
        selected = null;
      }
      map.forEachFeatureAtPixel(e.pixel, function (f: any) {
        selected = f;
        if (selected.get('type') === 'Polygon') {
          f.getStyle()?.getText()?.setText(f.get('label'));
          f.setStyle(f.getStyle());
        }
        return true;
      });
    });
};
export const abBlockHoverLabel = (map: any): void => {
  let selected: any = null;
  map &&
    map.on('pointermove', function (e: any) {
      if (selected !== null && selected.get('type') === AB_BLOCK) {
        selected.getStyle()?.getText()?.setText('');
        selected.setStyle(selected.getStyle());
        selected = null;
      }
      map.forEachFeatureAtPixel(e.pixel, function (f: any) {
        selected = f;
        if (selected.get('type') === AB_BLOCK) {
          f.getStyle()?.getText()?.setText(f.get('label'));
          f.setStyle(f.getStyle());
        }
        return true;
      });
    });
};
export const togglePolygonSelectionStyle = (
  polygonsLayerRef: any,
  toggleSatellite: boolean,
  mapId = '',
): void => {
  try {
    const features = polygonsLayerRef?.getSource()?.getFeatures();
    if (!toggleSatellite) {
      features?.map((feature: any) => {
        const id = feature.get('id');
        id !== mapId && feature.setStyle(feature.get('roadMapPolyStyle'));
      });
    } else {
      features?.map((feature: any) => {
        const id = feature.get('id');
        id !== mapId && feature.setStyle(feature.get('satelightPolyStyle'));
      });
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};
export const togglePolygonStyle = (
  polygonsLayerRef: any,
  toggleSatellite: boolean,
): void => {
  try {
    const features = polygonsLayerRef?.getSource()?.getFeatures();
    if (!toggleSatellite) {
      features?.map((feature: any) => {
        feature.setStyle(feature.get('roadMapPolyStyle'));
      });
    } else {
      features?.map((feature: any) => {
        feature.setStyle(feature.get('satelightPolyStyle'));
      });
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export const setPolygonStyle = (
  polygonsLayerRef: any,
  toggleSatellite: boolean,
): void => {
  const features = polygonsLayerRef?.current?.getSource().getFeatures();
  try {
    if (!toggleSatellite && features && features.length > 0) {
      features?.map((feature: any) => {
        feature.setStyle(feature.get('roadMapPolyStyle'));
      });
    } else {
      features?.map((feature: any) => {
        feature.setStyle(feature.get('satelightPolyStyle'));
      });
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export const toggleVineRowStyle = (
  vineRowsLayerRef: any,
  toggleSatellite: boolean,
  zoom: boolean,
): void => vineRowsLayerRef?.setStyle(getVineRowStyle(toggleSatellite, zoom));

export const displayRecenterViewPortSetUp = (
  feature: Feature<Geometry>,
  map: any,
  optional?: any,
): void => {
  if (feature && map) {
    const source = new VectorSource();
    if (optional) {
      source.addFeatures([feature, optional]);
    } else {
      source.addFeatures([feature]);
    }
    const view = map?.getView();
    view &&
      view?.fit(source.getExtent(), {
        constrainResolution: false,
        padding: [20, 20, 20, 20],
        duration: 3000,
      });
  }
};
export const centerTheLocation = (
  latLang: any,
  map: any,
  zoom?: number,
): void => {
  let prop: any = { duration: 2000, center: proj.fromLonLat(latLang) };
  if (zoom) {
    prop = { ...prop, zoom };
  }
  map && map?.getView().animate(prop);
};

export const displayRecenterAllViewPortSetUp = (
  features: Feature<Geometry>[],
  map: any,
  padding = 20,
  duration = 3000,
): void => {
  if (features && map) {
    const source = new VectorSource();
    source.addFeatures(features);
    const view = map?.getView();
    view &&
      view?.fit(source.getExtent(), {
        constrainResolution: false,
        padding: [padding, padding, padding, padding],
        duration,
      });
  }
};

export const getFullName = (record: any = {}): string => {
  if (!record) return '';
  const { first_name = '', last_name = '' } = record;
  let str = '';
  if (first_name) str += first_name;
  if (last_name) str += ` ${last_name}`;
  return str;
};

export const colors = ['#FBB313', '#44b3b7', '#EC9322'];

export const removeUnderScore = (data: string): string =>
  typeof data === 'string' ? data.replaceAll('_', ' ').toLowerCase() : '';

export const sliceIntoChunks = (arr: any, chunkSize: number) => {
  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
};

export const getRoadMapPolyStyle = (
  color: string,
  zIndex: number,
  suggested = false,
  type = '',
) => {
  //  'DEFAULT' | 'HOVER' | 'SELECTED',
  const rgbColor = getRoadMapRGBColor(color, suggested, type);
  const rgbFillColor = getRoadMapRGBFillColor(color, suggested, type);
  return new Style({
    fill: new Fill({
      color: color === 'white' ? 'rgba(255,255,255,0.1)' : rgbFillColor,
    }),
    stroke: new Stroke({
      color: rgbColor,
      width: color === 'white' ? 4 : 3,
    }),
    text: new Text({
      font: '16px Montserrat-SemiBold',
      fill: new Fill({ color: '#000' }),
      stroke: new Stroke({
        color: '#fff',
        width: 2,
      }),
      text: '',
    }),
    zIndex,
  });
};

export const polyDrawStyle = [
  new Style({
    stroke: new Stroke({
      color: '#ffffff',
      width: 8,
    }),
    zIndex: 0,
  }),
  new Style({
    fill: new Fill({
      color: 'rgba(0,153,255,0.4)',
    }),
    stroke: new Stroke({
      color: '#0099ff',
      width: 6,
    }),
    zIndex: 1,
  }),
];
export const getSateLightPolyStyle = (
  color: string,
  zIndex: number,
  suggested = false,
  type = '',
) => {
  //  'DEFAULT' | 'HOVER' | 'SELECTED',
  const rgbColor = getSatelightRGBColor(color, suggested);
  const rgbFillColor = getSatelightRGBFillColor(color, suggested, type);
  return new Style({
    fill: new Fill({
      color: rgbFillColor,
    }),
    stroke: new Stroke({
      color: rgbColor,
      width:
        color === 'brown' || color === 'white' || type === SELECTED ? 6 : 3,
    }),
    text: new Text({
      font: '16px Montserrat-SemiBold',
      fill: new Fill({ color: '#000' }),
      stroke: new Stroke({
        color: '#fff',
        width: 2,
      }),
      text: '',
    }),
    zIndex,
  });
};

export const pathConstants = {
  // Manual Driven
  MANUAL: 'manual',
  STANDBY: 'standby',

  ROW_FOLLOW: 'vine_row',
  ROW_FOLLOW_NEW: 'vinerow',
  // Autonomy Driven
  SHADOW_MODE: 'follow_me',
  ROUTINES: 'copycat',
  REMOTE_AV: 'remote_av',
  PREVIEW_PATH: 'preview_path',
  ROUTINE: 'routine',
};

export const pathColorConstants = {
  // MANUAL: '#00B7FF',
  // SHADOW_MODE: '#FFB76B',
  // ROW_FOLLOW: '#63F3FE',
  // ROUTINES: '#F36FC6',
  // STANDBY: '#C7C7C7',
  // DEFAULT: '#98DCE0',
  // REMOTE_AV: '#f19fd6',

  // MANUAL: '#11ADBC',
  // STANDBY: '#11ADBC',
  // ROW_FOLLOW: '#11ADBC',

  // SHADOW_MODE: '#713FB6',
  // ROUTINES: '#713FB6',
  // REMOTE_AV: '#713FB6',

  // DEFAULT: '#98DCE0',
  // Driven Path Fill #67AFB7 Stroke #366376
  // Driven Path Fill #67AFB7 Stroke #366376
  // Driven Path Fill #E9881F Stroke #7C5032
  // Path Ahead Fill #F1CE90 Stroke #E9881F
  MANUAL: '#67AFB7',
  STANDBY: '#67AFB7',
  DEFAULT: '#67AFB7',

  ROW_FOLLOW: row_follow_and_fallow_me_color,
  ROW_FOLLOW_NEW: row_follow_and_fallow_me_color,
  SHADOW_MODE: row_follow_and_fallow_me_color,

  ROUTINES: '#EC9322',
  REMOTE_AV: '#EC9322',
  PREVIEW_PATH: '#F1CE90',
  // ROUTINE: '#EC9322',
  ROUTINE: '#F1CE90', // used preview path color code
};
export const pathBorderColorConstants = {
  // MANUAL: '#366376',
  // STANDBY: '#366376',
  // ROW_FOLLOW: '#366376',

  // SHADOW_MODE: '#414F79',
  // ROUTINES: '#414F79',
  // REMOTE_AV: '#414F79',

  // DEFAULT: '#366376',
  MANUAL: '#4F8591',
  STANDBY: '#4F8591',
  DEFAULT: '#4F8591',

  ROW_FOLLOW: '#b87a0f',
  ROW_FOLLOW_NEW: '#b87a0f',
  SHADOW_MODE: '#b87a0f',

  ROUTINES: '#9A6B4E',
  REMOTE_AV: '#9A6B4E',
  PREVIEW_PATH: '#E9881F',
  // ROUTINE: '#71462C',
  ROUTINE: '#E9881F', // used preview path color code
};
export const colors1 = ['#44b3b7', row_follow_and_fallow_me_color, '#EC9322'];

export const getPathColor = (color: string): string => {
  switch (color) {
    case pathConstants.MANUAL:
      return pathColorConstants.MANUAL;
    case pathConstants.SHADOW_MODE:
      return pathColorConstants.SHADOW_MODE;
    case pathConstants.ROW_FOLLOW_NEW:
      return pathColorConstants.ROW_FOLLOW_NEW;
    case pathConstants.ROW_FOLLOW:
      return pathColorConstants.ROW_FOLLOW;
    case pathConstants.ROUTINES:
      return pathColorConstants.ROUTINES;
    case pathConstants.STANDBY:
      return pathColorConstants.STANDBY;
    case pathConstants.REMOTE_AV:
      return pathColorConstants.REMOTE_AV;
    case pathConstants.PREVIEW_PATH:
      return pathColorConstants.PREVIEW_PATH;
    case pathConstants.ROUTINE:
      return pathColorConstants.ROUTINE;

    default:
      return pathColorConstants.DEFAULT;
  }
};

export const getPathBorderColor = (color: string): string => {
  switch (color) {
    case pathConstants.MANUAL:
      return pathBorderColorConstants.MANUAL;
    case pathConstants.SHADOW_MODE:
      return pathBorderColorConstants.SHADOW_MODE;
    case pathConstants.ROW_FOLLOW_NEW:
      return pathBorderColorConstants.ROW_FOLLOW_NEW;
    case pathConstants.ROW_FOLLOW:
      return pathBorderColorConstants.ROW_FOLLOW;
    case pathConstants.ROUTINES:
      return pathBorderColorConstants.ROUTINES;
    case pathConstants.STANDBY:
      return pathBorderColorConstants.STANDBY;
    case pathConstants.REMOTE_AV:
      return pathBorderColorConstants.REMOTE_AV;
    case pathConstants.PREVIEW_PATH:
      return pathBorderColorConstants.PREVIEW_PATH;
    case pathConstants.ROUTINE:
      return pathBorderColorConstants.ROUTINE;

    default:
      return pathBorderColorConstants.DEFAULT;
  }
};

export const convertXyToLatLang = (
  data: [number, number][],
  groundLat: number,
  groundLang: number,
): [number, number][] => {
  const rEarth = 111319; // radius of earth is approximately 6378 kms (6378137 meters * pi/180)
  const finalList: [number, number][] = [];
  data.forEach((element: [number, number]) => {
    const deltaLat = element[1];
    const deltaLon = element[0];
    element[0] = groundLat + deltaLat / rEarth;
    element[1] =
      groundLang + deltaLon / rEarth / Math.cos(element[0] * 0.01745);
    finalList.push([element[1], element[0]]);
  });
  return finalList;
};

export const sortCameraViews = (
  arr: any[],
  orderkey: string,
  type = 'images',
) => {
  const dataset: any = {
    images: [
      'front',
      'front-left',
      'front-right',
      'rear',
      'pto',
      'driver',
      'rear-left',
      'rear-right',
    ],
    videos: [
      'FrontCamera',
      'front_left',
      'front_right',
      'RearCamera',
      'PTOCamera',
      'driver',
      'rear_left',
      'rear_right',
    ],
  };
  const orderData = dataset[type].reduce((a: any, c: any, i: number) => {
    const index = arr.findIndex(
      (ar: any) => ar[orderkey].replaceAll(' ', '') == c,
    );
    if (index !== -1) {
      if (type === 'videos') {
        arr[index][orderkey] = dataset['images'][i];
      }
      a.push(arr[index]);
    }
    return a;
  }, []);
  return orderData;
};
export const styleSnapFun = (feature: any) => {
  const size = feature.get('features').length;
  let style = new Style({});
  if (size === 1) {
    const feat = feature.get('features');
    // style = feat[0].get('style');
    style = snapshotStyle(1, false);
    feature.set('name', feat[0].get('name'));
    feature.set('pointType', feat[0].get('pointType'));
    feature.set('content', feat[0].get('content'));
    feature.set('hoverStyle', feat[0].get('hoverStyle'));
  }
  if (size > 1) {
    const feat = feature.get('features');
    let names = '';
    feat.map((fe: any, index: number) => {
      names = `${names} ${fe.get('name')}${
        index < feat.length - 1 ? ', ' : ''
      } `;
    });
    feature.set('name', names);
    style = snapshotStyle(size, false);
  }
  return style;
};

export const guardRailStyle = (count: number, hover = false) => {
  if (count === 1) {
    return new Style({
      image: new Icon({
        src: guardRails,
        scale: hover ? 1.2 : 1,
        anchor: [0.5, 1],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
      }),
      zIndex: 100,
    });
  } else {
    return new Style({
      image: new Icon({
        src: guardRailsCluster,
        scale: hover ? 1.2 : 1,
        anchor: [0.25, 1],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
      }),
      text: new Text({
        text: count.toString(),
        fill: new Fill({
          color: '#fff',
        }),
        offsetX: hover ? 33 : 28,
        offsetY: hover ? -53 : -45,
        font: '13px Montserrat-SemiBold',
      }),
    });
  }
};

export const snapshotStyle = (count: number, hover = false) => {
  if (count === 1) {
    return new Style({
      image: new Icon({
        src: snapIcon,
        scale: hover ? 2.5 : 2,
        anchor: [0.5, 1],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
      }),
      zIndex: 100,
    });
  } else {
    return new Style({
      image: new CircleStyle({
        radius: hover ? 16 : 14,
        stroke: new Stroke({
          color: '#fff',
        }),
        fill: new Fill({
          color: '#3399CC',
        }),
      }),
      text: new Text({
        text: count.toString(),
        fill: new Fill({
          color: '#fff',
        }),
        // offsetX: hover ? 33 : 28,
        // offsetY: hover ? -53 : -45,
        font: `${hover ? '18' : '16'}px Montserrat-SemiBold`,
      }),
    });
  }
};

export const styleFun = (feature: any) => {
  const size = feature.get('features').length;
  let style = new Style({});
  if (size === 1) {
    const feat = feature.get('features');
    style = guardRailStyle(1, false);
    feature.set('name', feat[0].get('name'));
    feature.set('pointType', feat[0].get('pointType'));
    feature.set('content', feat[0].get('content'));
    feature.set('hoverStyle', feat[0].get('hoverStyle'));
  }
  if (size > 1) {
    const feat = feature.get('features');
    let names = '';
    feat.map((fe: any, index: number) => {
      names = `${names} ${fe.get('name')}${
        index < feat.length - 1 ? ', ' : ''
      } `;
    });
    feature.set('name', names);
    style = guardRailStyle(size, false);
  }
  return style;
};

export const pathContants = {
  standby: { key: 'standby', value: 'Standby' },
  copycat: { key: 'copycat', value: 'Routines' },
  follow_me: { key: 'follow_me', value: 'Shadow Mode' },
  manual: { key: 'manual', value: 'Manual' },
  vinerow: { key: 'vinerow', value: 'Row Follow' },
  remoteav: { key: 'remote_av', value: 'Remote AV' },
};

export const formatCameraText = (text: string): string =>
  typeof text === 'string' ? text.trim().replace('_', '-').toUpperCase() : '';

export const getFleetUsagePointsInfo = (
  pointType: string,
  data: any,
): string => {
  let dataStr = '';
  if (pointType == 'INDICATOR') {
    dataStr = `<div class="errorCode_popup">
      <div class="erp_row erp_row_bt10">
          <div class="erp_row_tname">${data?.tractorName}</div>
          <div class="${
            data.resolved_date_time ? 'erp_row_status' : 'erp_row_status_ur'
          }">Resolved</div>
      </div>
      <div class="erp_row">
          <div class="erp_row_time">
            <span><img src="${start_time_icon}" alt="" /></span>
            <span>${
              data.created_date_time ? getHour(data.created_date_time) : ''
            }</span>
          </div>
          <div class="erp_row_duration">
            <span><img src="${duration_icon}" alt="" /></span>
            <span>${
              data.created_date_time && data.resolved_date_time
                ? timeDifference(
                    data.resolved_date_time,
                    data.created_date_time,
                  )
                : ''
            }</span>
          </div>
      </div>
    </div>`;
    // dataStr +=
    //   '<p>Indicator Name: <span>' + data.indicator_name + '</span></p>';
    // dataStr += '<p> Level: <span>' + data.indicator_level + '</span></p>';
    // dataStr += '<p> Error Code: <span>' + data.error_code + '</span></p>';
    // dataStr += '<p> Description: <span>' + data.description + '</span></p>';
    // dataStr +=
    //   '<p> Time: <span>' +
    //   (data.created_date_time ? getDateTime(data.created_date_time) : '') +
    //   '</span></p>';
    // dataStr +=
    //   '<p> Resolved Time: <span>' +
    //   (data.resolved_date_time ? getDateTime(data.resolved_date_time) : '') +
    //   '</span></p>';
  } else if (pointType == 'Ticket') {
    dataStr += '<p>Ticket ID: <span>' + data.ticket_id + '</span></p>';
    dataStr += '<p> Status: <span>' + data.status + '</span></p>';
    dataStr += '<p> Level: <span>' + data.ticket_level + '</span></p>';
    dataStr +=
      '<p> Start Time: <span>' +
      (data.created_date_time ? getDateTime(data.created_date_time) : '') +
      '</span></p>';
    dataStr +=
      '<p> Resolved Time: <span>' +
      (data.resolved_date_time ? getDateTime(data.completed_date_time) : '') +
      '</span></p>';

    dataStr +=
      '<p> Created By: <span>' +
      (data.created_by_user
        ? data.created_by_user.first_name + ' ' + data.created_by_user.last_name
        : '') +
      '</span></p>';

    dataStr +=
      '<p> Completed By: <span>' +
      (data.completed_by_user
        ? data.completed_by_user.first_name +
          ' ' +
          data.completed_by_user.last_name
        : '') +
      '</span></p>';
  } else if (pointType == 'JAG_START_STOP') {
    dataStr +=
      '<p> JumpAndGo: <span>' +
      (data.jAGStatus === 'Start' ? 'Start' : 'End') +
      '</span></p>';
    dataStr += '<p>Tractor : <span>' + data.tractor?.name + '</span></p>';
    dataStr +=
      '<p> Operator: <span>' +
      (data.operator
        ? data.operator?.first_name + ' ' + data.operator?.last_name
        : '') +
      '</span></p>';
    dataStr +=
      '<p> Start Time: <span>' +
      (data.drive_action_finish_date_time
        ? getDateTime(data.drive_action_start_date_time)
        : '') +
      '</span></p>';
    dataStr +=
      '<p> End Time: <span>' +
      (data.drive_action_finish_date_time
        ? getDateTime(data.drive_action_finish_date_time)
        : '') +
      '</span></p>';
  } else if (pointType == 'SNAP') {
    const { created_at, created_by, guard_rail_type, created_date_time } = data;
    if (created_at === 'GUARD_RAILS') {
      dataStr += '<p>Tractor : <span>' + data.tractor_id?.name + '</span></p>';
      dataStr +=
        '<p> Creator: <span>' + getFullName(created_by) + '</span></p>';
      dataStr += '<p> Creation Type: <span>' + guard_rail_type + '</span></p>';
      dataStr +=
        '<p> Created at: <span>' +
        (created_date_time ? getDateTime(created_date_time) : '') +
        '</span></p>';
    }
  } else if (pointType == 'SPEED') {
    const { download_speed_in_mbps, upload_speed_in_mbps } = data;
    dataStr += `<div class="errorCode_popup">
      <div class="erp_row">
          <div class="erp_row_time">
            <span>Upload Speed</span>
            <span>Download Speed</span>
          </div>
          <div class="erp_row_duration">
            <span>${upload_speed_in_mbps || 0} Mbps</span>
            <span>${download_speed_in_mbps || 0}Mbps</span>
          </div>
      </div>
    </div>`;
  }
  return dataStr;
};

export const getBadLocalizationFromLiveMap = (data: any): string => {
  const dataStr = `
  <div class="errorCode_popup">
   <div class="erp_row erp_row_bt10">
      <div class="erp_row_tname_full tn_wrap">${data?.tractorName}</div>
   </div>
   <div class="erp_row">
      <div class="erp_row_time">
         <span><img src="${start_time_icon}" alt="" /></span>
         <span>${
           data.created_date_time ? getHour(data.created_date_time) : ''
         }</span>
      </div>
      <div class="erp_row_duration">
         <span><img src="${duration_icon}" alt="" /></span>
         <span>${
           data.created_date_time && data.next_created_date_time
             ? timeDifference(
                 data.next_created_date_time,
                 data.created_date_time,
               )
             : ''
         }</span>
      </div>
   </div>
</div>`;
  /*
    <div class="errorCode_popup">
      <div class="erp_row erp_row_bt10">
          <div class="erp_row_tname">${data?.tractorName}</div>
          <div class="erp_row_status">Resolved</div>
      </div>
      <div class="erp_row erp_row_bt10">
          <div class="erp_row_tname">Tractor MKV-694</div>
          <div class="erp_row_status_ur">Unesolved</div>
      </div>
      <div class="erp_row">
          <div class="erp_row_time">
            <span><img src="${start_time_icon}" alt="" /></span>
            <span>6:34 AM</span>
          </div>
          <div class="erp_row_duration">
            <span><img src="${duration_icon}" alt="" /></span>
            <span>1 hr 32 min</span>
          </div>
      </div>
    </div>
  */
  return dataStr;
};
export const getFleetUsagePointsPathInfoFromLiveMap = (data: any): string => {
  // const planner_name =
  //   data && data.planner_name && getStatusForTractor(data.planner_name)
  //     ? getStatusForTractor(data.planner_name)
  //     : '';
  // let dataStr = '';
  const dataStr = `
  <div class="errorCode_popup">
    <div class="erp_row erp_row_bt10">
        <div class="erp_row_tname_full tn_wrap">${data?.tractorName}</div>
    </div>
    <div class="erp_row">
        <div class="erp_row_time">
          <span><img src="${start_time_icon}" alt="" /></span>
          <span>${
            data.created_date_time ? getHour(data.created_date_time) : ''
          }</span>
        </div>
        <div class="erp_row_duration">
          <span><img src="${speed_V2}" alt="" /></span>
          <span>${
            data.avg_speed
              ? Number(Number(data.avg_speed) * 2.237).toFixed(1) // 0.621371
              : '0'
          } Mph</span>
        </div>
    </div>
  </div>`;

  /* dataStr += '<p>Tractor : <span>' + data?.tractorName + '</span></p>';
  dataStr += '<p>Operator : <span>' + data?.operatorName + '</span></p>';
  dataStr += '<p>Mode : <span>' + planner_name + '</span></p>';
  dataStr +=
    '<p>  Speed : <span>' +
    (data.avg_speed
      ? Number(Number(data.avg_speed) * 2.237).toFixed(1) // 0.621371
      : '0') +
    ' Mph</span></p>';
  dataStr +=
    '<p> Time : <span>' +
    (data.created_date_time ? getDateTime(data.created_date_time) : '') +
    '</span></p>'; 
    */
  return dataStr;
};
export const getFleetUsagePointsPathInfo = (data: any) => {
  const planner_name =
    data && data.planner_name && getStatusForTractor(data.planner_name)
      ? getStatusForTractor(data.planner_name)
      : '';
  let dataStr = '';
  dataStr += '<p>Tractor : <span>' + data.tractor_name + '</span></p>';
  dataStr += '<p>Operator : <span>' + data.operator_name + '</span></p>';
  dataStr += '<p>Mode : <span>' + planner_name + '</span></p>';
  dataStr +=
    '<p> Speed : <span>' +
    (data.avg_speed
      ? `${Number(Number(data.avg_speed) * 2.237).toFixed(1)} Mph`
      : '-') +
    ' </span></p>';
  dataStr +=
    '<p> Time : <span>' +
    (data.created_date_time ? getDateTime(data.created_date_time) : '') +
    '</span></p>';
  return dataStr;
};

export const getPathPointsPathInfo = (data: any) => {
  const planner_name =
    data && data.planner_name && getStatusForTractor(data.planner_name)
      ? getStatusForTractor(data.planner_name)
      : '';
  let dataStr = '';
  dataStr += '<p>Mode : <span>' + planner_name + '</span></p>';
  dataStr +=
    '<p> Speed : <span>' +
    (data.avg_speed
      ? `${Number(Number(data.avg_speed) * 2.237).toFixed(1)} Mph`
      : '-') +
    ' </span></p>';
  dataStr +=
    '<p> Time : <span>' +
    (data.created_date_time ? getDateTime(data.created_date_time) : '') +
    '</span></p>';
  return dataStr;
};
export const getPathInfo = (data: any, operationType: string) => {
  return `
           
              <div class="head-section">
                <div class="head-title">${operationType}</div>
                <div class="op-count"></div>
              </div>
              <div class="content-section">
                <div class="operations-row">
                  <div class="left-sec">
                    <span class="op-date">${getDateOnly(
                      data?.drive_action_start_date_time,
                    )}</span>
                    <span class="op-number">${data?.tractor?.name} </span>
                  </div>
                  <div class="right-sec">
                    <span class="avatar-circles-tt">
                      <span class="op-name">${data?.first_name?.charAt(
                        0,
                      )}${data?.last_name?.charAt(0)}</span>
                    </span>
                  </div>
                </div>
              </div>
           
          `;
};
export const getPathTimeInfo = (data: any, operationType: string) => {
  return `
            
              <div class="head-section">
                <div class="head-title">${operationType || 'No Implement'}</div>
                <div class="op-count"></div>
              </div>
              <div class="content-section">
                <div class="operations-row">
                  <div class="left-sec">
                    <span class="op-date">Time</span>
                    <span class="op-number">${
                      data[3] ? getDateTime(data[3]) : ''
                    } </span>
                  </div>
                  
                </div>
              </div>
           
          `;
};
export const getIndInfo = (data: any) => {
  return `
  <div class="head-sec">
    <div class="sec1">
      <span class="ticket-number">Error #${data?.error_code}</span>
      <div class="severity-flag">
      ${renderToString(getSeverity(data.indicator_level))}
      </div>
    </div>
    <div class="creator-time">
      <img src="${getConnectivityImg(data.indicator_level)}" height="12" />
      <span class="ellipse"></span>
      <span class="pm">${getTimeOnly(data?.created_date_time)}</span>
    </div>
  </div>
  <div class="bar"></div>
  <div class="tt_button_sec">
    <span class="tt_button">See More</span>
  </div>
`;
};

export const getSnapInfo = (data: any) => {
  return `
  <div class="head-sec1">
    <div class="tt-sec2">
       <span class="ticket-number">${
         data?.created_at === GUARD_RAILS
           ? data.nameText
           : removeUnderScore(data?.created_at)
       }</span>
      <div class="creator-time">
      <span class="pm">${getTimeOnly(data?.created_date_time)}</span>
    </div>
    </div>     
  </div>
  <div class="bar"></div>
  <div class="tt_button_sec">
    <span class="tt_button">See More</span>
  </div>
`;
};
export const getTicketInfo = (data: any) => {
  return `
  <div class="head-sec">
    <div class="sec1">
      <span class="ticket-number">Ticket #${data?.ticketId}</span>
      <div class="severity-flag">
      ${renderToString(getSeverity(data?.ticketLevel))}
      </div>
    </div>
    <div class="creator-time">
    <img src="${drivability}" alt="" height="12" />
      ${
        (data?.assignee?.first_name &&
          data?.assignee?.last_name &&
          fetchName(data?.assignee?.first_name, data?.assignee?.last_name)) ||
        ''
      }
      <span class="ellipse"></span>
      <span class="pm">${getTimeOnly(data?.created_date_time)}</span>
    </div>
  </div>
  <div class="bar"></div>
  <div class="tt_button_sec">
    <span class="tt_button">See More</span>
  </div>
`;
};
export const IndicatorStyle = (count: number, hover = false) => {
  if (count === 1) {
    return new Style({
      image: new Icon({
        src: FleetIndicator,
        scale: hover ? 1.2 : 1,
        anchor: [0.5, 1],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
      }),
      zIndex: 100,
    });
  } else {
    return new Style({
      image: new Icon({
        src: errorCodeCluster,
        scale: hover ? 1.2 : 1,
        anchor: [0.25, 1],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
      }),
      text: new Text({
        text: count.toString(),
        fill: new Fill({
          color: '#fff',
        }),
        offsetX: hover ? 30 : 25,
        offsetY: hover ? -53 : -45,
        font: '16px Montserrat-SemiBold',
      }),
    });
  }
};
export const OperationIndicatorStyle = (hover = false) => {
  return new Style({
    image: new Icon({
      src: operationInd,
      scale: hover ? 1.2 : 1.5,
      anchor: [0.5, 0.5],
      anchorXUnits: 'fraction',
      anchorYUnits: 'fraction',
    }),
    zIndex: 100,
  });
};

export const SpeedStyle = (count: number, hover = false) => {
  if (count === 1) {
    return new Style({
      image: new Icon({
        src: fleetSpeedIcon,
        scale: hover ? 1.2 : 1,
        anchor: [0.5, 1],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
      }),
      zIndex: 100,
    });
  } else {
    return new Style({
      image: new CircleStyle({
        radius: hover ? 16 : 14,
        stroke: new Stroke({
          color: '#fff',
        }),
        fill: new Fill({
          color: '#3399CC',
        }),
      }),
      text: new Text({
        text: count.toString(),
        fill: new Fill({
          color: '#fff',
        }),
        // offsetX: hover ? 30 : 25,
        // offsetY: hover ? -53 : -45,
        font: `${hover ? '18' : '16'}px Montserrat-SemiBold`,
      }),
    });
  }
};

export const OperationTicketStyle = (hover = false) =>
  new Style({
    image: new Icon({
      src: operationTicket,
      scale: hover ? 2.5 : 1.5,
      anchor: [0.5, 0.5],
      anchorXUnits: 'fraction',
      anchorYUnits: 'fraction',
    }),
    zIndex: 100,
  });

export const TicketStyle = (count: number, hover = false) => {
  if (count === 1) {
    return new Style({
      image: new Icon({
        src: TicketIcon,
        scale: hover ? 2.5 : 2,
        anchor: [0.5, 1],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
      }),
      zIndex: 100,
    });
  } else {
    return new Style({
      image: new CircleStyle({
        radius: hover ? 16 : 14,
        stroke: new Stroke({
          color: '#fff',
        }),
        fill: new Fill({
          color: '#3399CC',
        }),
      }),
      text: new Text({
        text: count.toString(),
        fill: new Fill({
          color: '#fff',
        }),
        // offsetX: hover ? 30 : 25,
        // offsetY: hover ? -53 : -45,
        font: `${hover ? '18' : '16'}px Montserrat-SemiBold`,
      }),
    });
  }
};

export const tractorClusterStyle = (count: number, hover = false) => {
  return new Style({
    image: new Icon({
      src: hover ? tractorsClusterHover : tractorsCluster,
    }),
    text: new Text({
      text: count.toString(),
      fill: new Fill({
        color: '#fff',
      }),
      font: '14px Montserrat-SemiBold',
    }),
  });
};

export const TractorIconConstant = {
  ONLINE: 'ONLINE',
  OFFLINE: 'OFFLINE',
  LOWLOCALIZATION: 'LOWLOCALIZATION',
  CHARGING: 'CHARGING',
};
export const TractorIconImages: any = {
  ONLINE: NewTractorIcon,
  OFFLINE: tractor_offline,
  OFFLINE_HOVER: tractor_offline_hover,
  OFFLINE_SELECTED: tractor_offline_selected,
  LOWLOCALIZATION: LowLocalizationNewTractorIcon,
  CHARGING: charging_normal,
  CHARGING_HOVER: charging_hover,
  TRACTOR_LOCK_NORMAL: tractor_lock_normal,
  TRACTOR_LOCK_HOVER: tractor_lock_hover,
  TRACTOR__NORMAL: tractor__normal,
  TRACTOR__HOVER: tractor__hover,
  SELECTED_TRACTOR_NOUSER: selected_tractor_nouser,
  SELECTED_TRACTOR_USER: selected_tractor_user,
  SELECTED_TRACTOR_CHARGING: selected_tractor_charging,
};
export const tractorStatusColor = (
  isAvailable: string,
  drive_action_created_at: string,
): string => {
  let status = isAvailable;

  if (drive_action_created_at && drive_action_created_at === ATOM) {
    isAvailable =
      isAvailable === tractorConstantsNew.standby.key ||
      isAvailable === tractorConstantsNew.manual.key
        ? tractorConstantsNew.remote_av.key
        : isAvailable;
  }

  switch (isAvailable) {
    case tractorConstantsNew.manual.key:
      status = tractorConstantsNew.manual.key;
      break;
    case tractorConstantsNew.standby.key:
      status = tractorConstantsNew.standby.key;
      break;
    case tractorConstantsNew.copycat.key:
      status = tractorConstantsNew.copycat.key;
      break;
    case tractorConstantsNew.follow_me.key:
      status = tractorConstantsNew.follow_me.key;
      break;
    case tractorConstantsNew.vinerow.key:
      status = tractorConstantsNew.vinerow.key;
      break;
    case tractorConstantsNew.remote_av.key:
      status = tractorConstantsNew.remote_av.key;
      break;
    case tractorConstantsNew.faulty.key:
      status = tractorConstantsNew.faulty.key;
      break;
    default:
      status = isAvailable;
      break;
  }
  return status;
};
export const tractorTextColor = (
  isAvailable: string,
  drive_action_created_at: string,
): string => {
  let textColor = '#000';

  if (drive_action_created_at && drive_action_created_at === ATOM) {
    isAvailable =
      isAvailable === tractorConstantsNew.standby.key ||
      isAvailable === tractorConstantsNew.manual.key
        ? tractorConstantsNew.remote_av.key
        : isAvailable;
  }

  switch (isAvailable) {
    case tractorConstantsNew.off.key:
      textColor = tractorConstantsNew.off.color;
      break;
    case tractorConstantsNew.charging.key:
      textColor = tractorConstantsNew.charging.color;
      break;
    case tractorConstantsNew.available.key:
      textColor = tractorConstantsNew.available.color;
      break;
    case tractorConstantsNew.manual.key:
      textColor = tractorConstantsNew.manual.color;
      break;
    case tractorConstantsNew.standby.key:
      textColor = tractorConstantsNew.standby.color;
      break;
    case tractorConstantsNew.copycat.key:
      textColor = tractorConstantsNew.copycat.color;
      break;
    case tractorConstantsNew.follow_me.key:
      textColor = tractorConstantsNew.follow_me.color;
      break;
    case tractorConstantsNew.vinerow.key:
      textColor = tractorConstantsNew.vinerow.color;
      break;
    case tractorConstantsNew.remote_av.key:
      textColor = tractorConstantsNew.remote_av.color;
      break;
    case tractorConstantsNew.faulty.key:
      textColor = tractorConstantsNew.faulty.color;
      break;
    case tractorConstantsNew.service.key:
      textColor = tractorConstantsNew.service.color;
      break;
    default:
      textColor = '#000';
      break;
  }
  return textColor;
};
export const getToolTip = (tractorName: string, addOnZIndex: number): Style => {
  // const offsetX =
  //   tractorName.length > 5
  //     ? tractorName.length > 9
  //       ? tractorName.length > 15
  //         ? 150
  //         : 100
  //       : 80
  //     : 50;
  // const offsetY = -47;
  // const toolImage = new Icon({
  //   src:
  //     tractorName.length > 5
  //       ? tractorName.length > 9
  //         ? tractorName.length > 15
  //           ? tooltip_icon_xl
  //           : tooltip_icon_long
  //         : tooltip_icon_long9
  //       : tooltip_icon_medium,
  //   anchor: [tractorName.length > 15 ? -0.09 : -0.2, 1.5],
  // });
  // const toolText = new Text({
  //   text:
  //     tractorName.length > 25
  //       ? `${tractorName.substring(0, 24)}...`
  //       : tractorName,
  //   fill: new Fill({
  //     color: '#000',
  //   }),
  //   font: '14px Montserrat-SemiBold',
  //   offsetX: offsetX + 5,
  //   offsetY: offsetY,
  // });
  // tooltipStyle = new Style({
  //   image: toolImage,
  //   text: toolText,
  //   zIndex: addOnZIndex * 999,
  // });
  const width = tractorName.length * 10 + 10;
  const offsetX = 50 + tractorName.length * 5;

  const offsetY = -40 - tractorName.length * 0.5;
  // const anchorX = -Math.abs(1.5 - tractorName.length * 0.2);
  const anchorX = -tractorName.length * 0.3 - 40;
  const anchorY = tractorName.length * 0.02 + 2;
  const image = `<svg  width="${width}px" height="26px"
  viewBox="0 0 ${width} 26"
  version="1.1" xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Layer_1-2">
      <rect id="bg-2" class="cls-1" width="${width}px" height="26" rx="6" ry="6" fill="#fff" />
    </g>
  </svg>`;

  const toolImage = new Icon({
    src: 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(image),
    anchor: [anchorX, anchorY],
    anchorXUnits: 'pixels',
    anchorYUnits: 'fraction',
  });
  const toolText = new Text({
    text: tractorName,
    fill: new Fill({
      color: '#000',
    }),
    font: '14px Montserrat-SemiBold',
    offsetX: offsetX,
    offsetY: offsetY,
  });
  return new Style({
    image: toolImage,
    text: toolText,
    zIndex: addOnZIndex * 999,
  });
};
export const batteryCircle = (
  batteryValue: number,
  type: any,
  addOnZIndex: number,
  tractorTransparent = false,
): Style => {
  // if tractorTransparent true show white full circle
  const backGroundStroke = tractorTransparent ? '#FFFFFF' : 'transparent';
  let frontStroke = tractorTransparent ? '#FFFFFF' : '#58b847';
  // low soc less than 15
  frontStroke = batteryValue > 15 ? frontStroke : '#F7CB18';
  // critical soc less than 5
  frontStroke = batteryValue > 5 ? frontStroke : '#EC1E27';
  const chargeImage = `
  <svg
   width="74.4" height="74.4" viewBox="0 0 74.4 74.4"
   className="circular-chart orange"
   fill="transparent"
   version="1.1" xmlns="http://www.w3.org/2000/svg"
   >
   <path
      stroke-dasharray="100, 100"
      d="M18 2.0845
      a 15.9155 15.9155 0 0 1 0 31.831
      a 15.9155 15.9155 0 0 1 0 -31.831"
      stroke-width="3"
      stroke="${backGroundStroke}"
      />
   <path
      stroke-dasharray="${batteryValue.toString()}, 100"
      d="M18 2.0845
      a 15.9155 15.9155 0 0 1 0 31.831
      a 15.9155 15.9155 0 0 1 0 -31.831"
      stroke="${frontStroke}" 
      stroke-width="2.5"
      />
  </svg> 
  `;
  return new Style({
    image: new Icon({
      src:
        'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(chargeImage),
      scale: 1.65,
      anchor: [0.245, type === TractorIconConstant.OFFLINE ? 0.265 : 0.281],
    }),
    zIndex: addOnZIndex * 999,
  });
};

export const getTractorIcon = (
  type: any,
  onHover = false,
  knownBearing = 0,
  onText = '',
  isSelected = false,
  moving = false,
  onZoom = false,
  batteryValue = 0,
  lowLocalization = false,
  tractorName = '',
  pointTypeIsSelectedForToolTip = false,
  tractorId = 1,
  isFaulty = false,
  isAvailable: string,
  drive_action_created_at: string,
  tractorTransparent = false,
): Style[] => {
  if (type === TractorIconConstant.OFFLINE && !isSelected) {
    const style = new Style();
    return [style];
  }
  // image section
  let image: string = TractorIconImages[type]
    ? TractorIconImages[type]
    : TractorIconImages.OFFLINE;
  // charging on hover
  if (type === TractorIconConstant.CHARGING && onHover) {
    image = TractorIconImages.CHARGING_HOVER;
  }
  // tractor online and user not available available
  if (type === TractorIconConstant.ONLINE) {
    image = TractorIconImages.TRACTOR__NORMAL;
    if (onHover) {
      image = TractorIconImages.TRACTOR__HOVER;
    }
    if (isSelected) {
      image = TractorIconImages.SELECTED_TRACTOR_NOUSER;
    }
  }
  // tractor online and user available
  if (type === TractorIconConstant.ONLINE && onText && onText.length) {
    image = TractorIconImages.TRACTOR_LOCK_NORMAL;
    if (onHover) {
      image = TractorIconImages.TRACTOR_LOCK_HOVER;
    }
    if (
      tractorStatusColor(isAvailable, drive_action_created_at) ===
      tractorConstantsNew.remote_av.key
    ) {
      if (isSelected) {
        image = selected_tractor_user_rv;
      }
    } else if (
      tractorStatusColor(isAvailable, drive_action_created_at) ===
        tractorConstantsNew.manual.key ||
      tractorStatusColor(isAvailable, drive_action_created_at) ===
        tractorConstantsNew.standby.key ||
      tractorStatusColor(isAvailable, drive_action_created_at) ===
        tractorConstantsNew.copycat.key
    ) {
      if (isSelected) {
        image = selected_tractor_user_manual;
      }
    }
    if (isFaulty) {
      if (isSelected) {
        image = selected_tractor_user_faulty;
      }
    }
  }
  if (type === TractorIconConstant.CHARGING && isSelected) {
    image = TractorIconImages.SELECTED_TRACTOR_CHARGING;
  }
  // tractor online and user not available available
  if (type === TractorIconConstant.OFFLINE) {
    image = TractorIconImages.OFFLINE;
    if (onHover) {
      image = TractorIconImages.OFFLINE_HOVER;
    }
    if (isSelected) {
      image = TractorIconImages.OFFLINE_SELECTED;
    }
  }

  let text = onText && onText.length > 0 ? onText : '';
  //on selected tractor on top with z index
  const addOnZIndex = isSelected ? tractorId + 100 : tractorId;
  let textColor = '#000';
  textColor = tractorTextColor(isAvailable, drive_action_created_at);
  if (isFaulty) {
    textColor = tractorConstantsNew.faulty.color;
  }
  if (type === TractorIconConstant.CHARGING) {
    text = '';
  }

  const style = new Style({
    image: new Icon({
      src: image,
    }),
    text: new Text({
      text: text.toUpperCase(),
      fill: new Fill({
        color:
          isAvailable === tractorConstantsNew.follow_me.key ||
          isAvailable === tractorConstantsNew.vinerow.key
            ? textColor
            : isSelected
            ? '#fff'
            : textColor,
      }),
      font: '19px Montserrat-SemiBold',
      offsetY: -3.5,
    }),
    zIndex: addOnZIndex * 999,
  });

  let chargeStyle = null;
  if (batteryValue > 0 && (isSelected || onHover)) {
    chargeStyle = batteryCircle(
      batteryValue,
      type,
      addOnZIndex,
      tractorTransparent,
    );
  }

  let direction = null;
  // on hover show direction icon except charging state and offline
  if (
    !lowLocalization &&
    type !== TractorIconConstant.OFFLINE &&
    type !== TractorIconConstant.CHARGING &&
    (onHover || isSelected || moving)
  ) {
    let directionIcon = DirectionIcon;
    if (
      tractorStatusColor(isAvailable, drive_action_created_at) ===
      tractorConstantsNew.remote_av.key
    ) {
      directionIcon = rvDirectionIcon;
    } else if (
      tractorStatusColor(isAvailable, drive_action_created_at) ===
        tractorConstantsNew.manual.key ||
      tractorStatusColor(isAvailable, drive_action_created_at) ===
        tractorConstantsNew.standby.key ||
      tractorStatusColor(isAvailable, drive_action_created_at) ===
        tractorConstantsNew.copycat.key
    ) {
      directionIcon = manualDirectionIcon;
    }
    if (
      isFaulty &&
      type === TractorIconConstant.ONLINE &&
      onText &&
      onText.length
    ) {
      directionIcon = redDirectionIcon;
    }
    let anchor = [0.5, 3.4];
    anchor = onHover ? [0.5, 3.8] : anchor;
    anchor = isSelected ? [0.5, 3.8] : anchor;
    anchor =
      isAvailable === tractorConstantsNew.available.key &&
      !onHover &&
      !isSelected
        ? [0.5, 3.6]
        : anchor;

    direction = new Style({
      image: new Icon({
        src: directionIcon,
        anchor,
        rotation: ((90 - knownBearing) * Math.PI) / 180,
      }),
      zIndex: addOnZIndex * 1000,
    });

    direction =
      isAvailable === tractorConstantsNew.available.key &&
      !onHover &&
      !isSelected
        ? null
        : direction;
  }

  let isFaultyStyle = null;
  if (isFaulty && type !== TractorIconConstant.OFFLINE) {
    const anchor = [-0.3, 1.5];
    isFaultyStyle = new Style({
      image: new Icon({
        src: faultyTractorIcon,
        anchor,
      }),
      zIndex: addOnZIndex * 1000,
    });
  }

  let lowLocalizationStyle1 = null;
  let lowLocalizationStyle2 = null;
  // if not faulty show low localization
  if (lowLocalization && !isFaulty) {
    const anchor = [-0.55, 1.5];
    lowLocalizationStyle1 = new Style({
      image: new Icon({
        src: redIcon,
        anchor,
      }),
      zIndex: addOnZIndex * 999,
    });
    lowLocalizationStyle2 = new Style({
      image: new Icon({
        src: tractor_bg_shadow,
        anchor: [0.5, 0.55],
      }),
      zIndex: addOnZIndex * 998,
    });
  }
  // on hover,select and moving
  let tooltipStyle = null;
  if (
    (onHover || isSelected || pointTypeIsSelectedForToolTip) &&
    tractorName &&
    tractorName.length > 0
  ) {
    tooltipStyle = getToolTip(tractorName, addOnZIndex);
  }

  const st = tractorTransparent ? [] : [style];
  if (lowLocalizationStyle2) {
    st.push(lowLocalizationStyle2);
  }

  if (chargeStyle) {
    st.push(chargeStyle);
  }
  if (direction) {
    st.push(direction);
  }
  if (lowLocalizationStyle1) {
    st.push(lowLocalizationStyle1);
  }
  if (isFaultyStyle) {
    st.push(isFaultyStyle);
  }
  // hide tractor name tooltip on start point select
  if (!tractorTransparent && tooltipStyle) {
    st.push(tooltipStyle);
  }

  return st;
};

export const sortAlphaNumber = (data: any, key = 'name') => {
  const newMap = data.reduce((a: any, c: any) => {
    if (a.get(c[key])) {
      a.set(c[key], [...a.get(c[key]), c]);
    } else {
      a.set(c[key], [c]);
    }
    return a;
  }, new Map());
  const res = Object.fromEntries(newMap);
  const collator = new Intl.Collator(undefined, {
    numeric: true,
    sensitivity: 'base',
  });
  const result = Object.keys(res)
    .sort(collator.compare)
    .reduce((a: any[], c: string) => {
      a.push(...newMap.get(c));
      return a;
    }, []);
  return [...result];
};

export const sortString = (data: any[], key: string) =>
  data.sort((a: any, b: any) => a[key].localeCompare(b[key]));

export const tractorSort = (records: any, key = 'name') => {
  records = sortAlphaNumber(records, key);
  return tractorStatusSort(records);
};

export const tractorStatusSort = (list: any[]) => {
  const temp = list.reduce(
    (acc: any, item: any) => {
      acc[item.isAvailable].push(item);
      return acc;
    },
    {
      [tractorConstantsNew.standby.key]: [],
      [tractorConstantsNew.manual.key]: [],
      [tractorConstantsNew.vinerow.key]: [],
      [tractorConstantsNew.follow_me.key]: [],
      [tractorConstantsNew.copycat.key]: [],
      [tractorConstantsNew.remote_av.key]: [],
      [tractorConstantsNew.available.key]: [],
      [tractorConstantsNew.update.key]: [],
      [tractorConstantsNew.service.key]: [],
      [tractorConstantsNew.charging.key]: [],
      [tractorConstantsNew.faulty.key]: [],
      [tractorConstantsNew.off.key]: [],
    },
  );
  list = [
    ...temp[tractorConstantsNew.standby.key],
    ...temp[tractorConstantsNew.manual.key],
    ...temp[tractorConstantsNew.vinerow.key],
    ...temp[tractorConstantsNew.follow_me.key],
    ...temp[tractorConstantsNew.copycat.key],
    ...temp[tractorConstantsNew.remote_av.key],
    ...temp[tractorConstantsNew.available.key],
    ...temp[tractorConstantsNew.update.key],
    ...temp[tractorConstantsNew.service.key],
    ...temp[tractorConstantsNew.charging.key],
    ...temp[tractorConstantsNew.faulty.key],
    ...temp[tractorConstantsNew.off.key],
  ];
  return list;
};

// export const tractorStatusSort = (list: any[]) => {
//   const temp = list.reduce(
//     (acc: any, item: any) => {
//       acc[item.isAvailable].push(item);
//       return acc;
//     },
//     {
//       Available: [],
//       Off: [],
//       'In Use': [],
//       Charging: [],
//       Faulty: [],
//       Offline: [],
//     },
//   );
//   list = [
//     ...temp['In Use'],
//     ...temp.Available,
//     ...temp.Charging,
//     ...temp.Faulty,
//     ...temp.Off,
//     ...temp.Offline,
//   ];
//   return list;
// };

export const getChargingText = (hour: number, minute: number): string => {
  if (hour === 0 && minute === 0) {
    return `Fully Charged`;
  }

  const CalenderTime = moment()
    .add(hour, 'hours')
    .add(minute, 'minutes')
    .calendar();
  return `Full charge by ${CalenderTime}`;
};

export const getTimeLeftToCharge = (
  charging: number,
): {
  hour: number;
  minute: number;
} => {
  let hour = 0;
  let minute = 0;
  if (charging) {
    const time = charging.toFixed(2).toString().split('.');
    if (time[0]) {
      hour = Number(time[0]);
    }

    if (time[1]) {
      minute = (Number(time[1]) / 100) * 60;
    }
  }
  return {
    hour,
    minute,
  };
};

export const durationData = [
  ...Array.from({ length: 12 }, (_, i) =>
    Object.assign({ label: `${5 * i} mins`, value: i * 5 * 60 }),
  ),
  ...Array.from({ length: 24 }, (_, i) =>
    Object.assign({}, { label: `${i} hours`, value: i * 60 * 60 }),
  ),
].filter((item) => item.value !== 0);

export const calculateDistance = (points: any[]): number => {
  let [lat1, lon1] = points[0];
  let [lat2, lon2] = points[1];
  // The math module contains a function
  // named toRadians which converts from
  // degrees to radians.
  lon1 = (lon1 * Math.PI) / 180;
  lon2 = (lon2 * Math.PI) / 180;
  lat1 = (lat1 * Math.PI) / 180;
  lat2 = (lat2 * Math.PI) / 180;

  // Haversine formula
  const dlon = lon2 - lon1;
  const dlat = lat2 - lat1;
  const a =
    Math.pow(Math.sin(dlat / 2), 2) +
    Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(dlon / 2), 2);

  const c = 2 * Math.asin(Math.sqrt(a));

  // Radius of earth in kilometers. Use 3956
  // for miles
  const r = 6371;

  // calculate the result
  return c * r * 0.621371;
};
export const calPtsDistanceArray = (paths: any): number => {
  let distance = 0;
  if (paths.length > 1) {
    const points = [paths[0], paths[paths.length - 1]].map((item) => [
      item[1],
      item[2],
    ]);
    distance = calculateDistance(points) * 5280;
  }
  return distance;
};

export const calPtsDistance = (paths: any): number => {
  let distance = 0;
  if (paths.length > 1) {
    const points = [paths[0], paths[paths.length - 1]].map((item) => [
      item.latitude,
      item.longitude,
    ]);
    distance = calculateDistance(points) * 5280;
  }
  return distance;
};

export const calPtsDistanceActualAllow = (paths: any): boolean => {
  let distance = 0;
  if (paths.length > 1) {
    const points = [paths[0], paths[paths.length - 1]].map((item) => [
      item.latitude,
      item.longitude,
    ]);
    distance = calculateDistance(points);
  }
  return distance * 5258 < distanceForGoodPoints ? true : false;
};

export const getStatusClass = (
  isAvailable: string,
  drive_action_created_at = null,
): string => {
  let text = '';
  if (drive_action_created_at && drive_action_created_at === ATOM) {
    isAvailable =
      isAvailable === tractorConstantsNew.standby.key ||
      isAvailable === tractorConstantsNew.manual.key
        ? tractorConstantsNew.remote_av.key
        : isAvailable;
  }

  switch (isAvailable) {
    case tractorConstantsNew.off.key:
      text = tractorConstantsNew.off.class;
      break;
    case tractorConstantsNew.charging.key:
      text = tractorConstantsNew.charging.class;
      break;
    case tractorConstantsNew.available.key:
      text = tractorConstantsNew.available.class;
      break;
    case tractorConstantsNew.manual.key:
      text = tractorConstantsNew.manual.class;
      break;
    case tractorConstantsNew.standby.key:
      text = tractorConstantsNew.standby.class;
      break;
    case tractorConstantsNew.copycat.key:
      text = tractorConstantsNew.copycat.class;
      break;
    case tractorConstantsNew.follow_me.key:
      text = tractorConstantsNew.follow_me.class;
      break;
    case tractorConstantsNew.vinerow.key:
      text = tractorConstantsNew.vinerow.class;
      break;
    case tractorConstantsNew.remote_av.key:
      text = tractorConstantsNew.remote_av.class;
      break;
    case tractorConstantsNew.faulty.key:
      text = tractorConstantsNew.faulty.class;
      break;
    case tractorConstantsNew.service.key:
      text = tractorConstantsNew.service.class;
      break;
    case tractorConstantsNew.update.key:
      text = tractorConstantsNew.update.class;
      break;
    default:
      text = tractorConstantsNew.off.class;
      break;
  }
  return text;
};

export const getOperatorNameByHeartBeat = (heartbeat: any): string => {
  let text = '';
  if (heartbeat && heartbeat.drive_action_details) {
    const { operator_firstname, operator_lastname } =
      heartbeat.drive_action_details;
    if (operator_firstname) text += operator_firstname;
    if (operator_lastname) text += ' ' + operator_lastname;
  }
  return text;
};

export const togglePathStyle = (pathLayerRef: any, zoom = 10): void => {
  try {
    const features = pathLayerRef?.getSource()?.getFeatures();
    features &&
      features.length > 0 &&
      features?.map((feature: any) => {
        if (
          feature.get('pointType') === 'PATH' ||
          feature.get('pointType') === 'LIVEPATH' ||
          feature.get('pointType') === pathConstants.PREVIEW_PATH ||
          feature.get('pointType') === pathConstants.ROUTINE
        ) {
          const style = getPathStyle(
            feature.get('color'),
            feature.get('borderColor'),
            zoom,
          );
          // if (feature.get('pointType') === pathConstants.PREVIEW_PATH) {
          //   style = getPathFeatureStyle(pathConstants.PREVIEW_PATH, zoom);
          // }
          feature.setStyle(style);
        } else if (feature.get('pointType') === 'startPoint') {
          feature.setStyle(
            getCenterStyle(
              feature.get('color'),
              feature.get('borderColor'),
              zoom > 15 ? true : false,
            ),
          );
        }
      });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export const getCenterStyle = (
  color: string,
  borderColor: string,
  zoom = false,
) =>
  new Style({
    image: new Circle({
      radius: zoom ? 12 : 6,
      fill: new Fill({
        color,
      }),
      stroke: new Stroke({
        color: borderColor,
      }),
    }),
    zIndex: 501,
  });

export const getPathFeatureStyle = (planner: string, zoom = 10) => {
  const color = getPathColor(planner);
  const borderColor = getPathBorderColor(planner);
  return getPathStyle(color, borderColor, zoom);
};
export const getPathStyle = (color: string, borderColor: string, zoom = 10) => {
  if (zoom >= 18.5) {
    // console.log('below 20meters');
    return [
      new Style({
        stroke: new Stroke({
          color: borderColor,
          width: 10,
        }),
        zIndex: 500,
      }),
      new Style({
        stroke: new Stroke({
          color,
          width: 7,
        }),
        zIndex: 501,
      }),
    ];
  } else if (zoom < 18.5 && zoom >= 16.5) {
    // console.log(' 20meters to 100 meters');
    return [
      new Style({
        stroke: new Stroke({
          color: borderColor,
          width: 12,
        }),
        zIndex: 500,
      }),
      new Style({
        stroke: new Stroke({
          color,
          width: 8,
        }),
        zIndex: 501,
      }),
    ];
  } else if (zoom < 16.5 && zoom >= 15) {
    // console.log(' 100meters to 200 meters');
    return [
      new Style({
        stroke: new Stroke({
          color: borderColor,
          width: 14,
        }),
        zIndex: 500,
      }),
      new Style({
        stroke: new Stroke({
          color,
          width: 9,
        }),
        zIndex: 501,
      }),
    ];
  }
  //  else {
  // console.log('more then 200 meters');
  // }
  // more then 200 meters
  return [
    new Style({
      stroke: new Stroke({
        color: borderColor,
        width: 7,
      }),
      zIndex: 500,
    }),
    new Style({
      stroke: new Stroke({
        color,
        width: 4,
      }),
      zIndex: 501,
    }),
  ];
};

export const getChargingTextRemaining = (
  heartBeat: TractorHeartBeat,
): string => {
  if (heartBeat && heartBeat.tractor_model) {
    const data = heartBeat.tractor_model.split(',');
    const time = data[mqttConstants.CHARGINGTIME];
    const timeToCharge = getTimeLeftToCharge(Number(time));
    // if (timeToCharge.hour !== 0 || timeToCharge.minute !== 0)
    return `${getChargingText(timeToCharge.hour, timeToCharge.minute)}`;
    // else return `${getChargingText(timeToCharge.hour, timeToCharge.minute)}`;
  }
  return '';
};

export const getRpm = (
  heartbeat: TractorHeartBeat | undefined,
  tractor_model = '',
): string => {
  if (heartbeat && heartbeat.tractor_model) {
    const data = heartbeat.tractor_model.split(',');
    if (data && data.length > 0) {
      let rpm = Number(data[mqttConstants.RPM]);
      const tractorModel =
        tractor_model && tractor_model.length > 0
          ? tractor_model.toLowerCase()
          : '';
      // if beta
      if (tractorModel === 'beta') {
        rpm = rpm / 8;
        return rpm.toFixed();
      } else if (tractorModel === 'pilot') {
        rpm = rpm / 10.36;
        return rpm.toFixed();
      } else {
        rpm = rpm / 8.46;
        return rpm.toFixed();
      }
    }
  }
  return '0';
};

export const getHeight = (heartBeat: TractorHeartBeat | undefined): number => {
  if (heartBeat && heartBeat.tractor_model) {
    const data = heartBeat.tractor_model.split(',');
    if (data && data.length) {
      return Number(
        (
          (Number(data[mqttConstants.LEFTHITCH]) +
            Number(data[mqttConstants.RIGHTHITCH])) /
          2
        ).toFixed(),
      );
    }
  }
  return 0;
};

export const getFNR = (heartBeat: TractorHeartBeat | undefined): string => {
  if (heartBeat && heartBeat.tractor_model) {
    const data = heartBeat.tractor_model.split(',');
    if (data && data.length > 0) {
      const fnr = Number(data[mqttConstants.FNR]);
      if (fnr === 1) {
        return 'F';
      } else if (fnr === 2) {
        return 'R';
      }
    }
  }
  return 'N';
};

export const getPTO = (heartBeat: TractorHeartBeat | undefined): string => {
  if (heartBeat && heartBeat.tractor_model) {
    const data = heartBeat.tractor_model.split(',');
    if (data && data.length > 0) {
      const pto = Boolean(data[mqttConstants.PTOSTATE]);
      if (pto) {
        return 'ON';
      }
    }
  }
  return 'OFF';
};

export const getPTOState = (
  heartBeat: TractorHeartBeat | undefined,
): boolean => {
  if (heartBeat && heartBeat.tractor_model) {
    const data = heartBeat.tractor_model.split(',');

    if (data && data.length > 0) {
      return Boolean(data[mqttConstants.PTOSTATE] === `1` ? true : false);
    }
  }
  return false;
};

export const getSpeed = (heartbeat: TractorHeartBeat | undefined): number =>
  getSpeedFromVelocity(Number(heartbeat?.localization?.velocity));

export const getSpeedFromVelocity = (
  velocity: number,
  // from = 'default',
): number => {
  // console.log('from', from, velocity);
  return velocity && velocity > 0
    ? Number((velocity * 2.237).toFixed(1).replaceAll('-', ''))
    : 0;
};

export const getGear = (heartBeat: TractorHeartBeat | undefined): number => {
  if (heartBeat && heartBeat.tractor_model) {
    const data = heartBeat.tractor_model.split(',');
    if (data && data.length > mqttConstants.GEAR) {
      return Number(data[mqttConstants.GEAR]);
    }
  }
  return 0;
};

export const getGearRange = (gear: number): string => {
  if ([1, 2, 3, 10].includes(gear)) {
    return 'L';
  }
  if ([4, 5, 6, 11].includes(gear)) {
    return 'M';
  }
  if ([7, 8, 9, 12].includes(gear)) {
    return 'H';
  }

  return '';
};

export const getGearRangeNumber = (gear: number) => {
  if ([1, 4, 7, 10].includes(gear)) {
    return 1;
  }
  if ([2, 5, 8, 11].includes(gear)) {
    return 2;
  }
  if ([3, 6, 9, 12].includes(gear)) {
    return 3;
  }
  return '-';
};
export const getFloatText = (
  heartBeat: TractorHeartBeat | undefined,
): string => {
  if (heartBeat && heartBeat.tractor_model) {
    const data = heartBeat.tractor_model.split(',');
    if (data && data.length) {
      const hookUpMode = Number(data[mqttConstants.HOOKUPCONTROL]);
      if (hookUpMode === 0) {
        return 'POSITION';
      } else if (hookUpMode === 1) {
        return 'CLASSIC';
      } else if (hookUpMode === 2) {
        return 'FLOAT';
      } else if (hookUpMode === 3) {
        return 'MANUAL ADJUST';
      }
    }
  }
  return '';
};
export const getStatusForTractor = (
  planner_name: string,
  drive_action_created_at = null,
): string => {
  let text = '';
  planner_name =
    planner_name && planner_name.toLowerCase()
      ? planner_name.toLowerCase()
      : '';
  if (drive_action_created_at && drive_action_created_at === ATOM) {
    planner_name =
      planner_name === tractorConstantsNew.standby.key ||
      planner_name === tractorConstantsNew.manual.key
        ? tractorConstantsNew.remote_av.key
        : planner_name;
  }

  switch (planner_name) {
    case tractorConstantsNew.off.key:
      text = tractorConstantsNew.off.value;
      break;
    case tractorConstantsNew.available.key:
      text = tractorConstantsNew.available.value;
      break;
    case tractorConstantsNew.charging.key:
      text = tractorConstantsNew.charging.value;
      break;
    case tractorConstantsNew.manual.key:
      text = tractorConstantsNew.manual.value;
      break;
    case tractorConstantsNew.standby.key:
      text = tractorConstantsNew.standby.value;
      break;
    case tractorConstantsNew.copycat.key:
      text = tractorConstantsNew.copycat.value;
      break;
    case tractorConstantsNew.follow_me.key:
      text = tractorConstantsNew.follow_me.value;
      break;
    case tractorConstantsNew.vinerow.key:
      text = tractorConstantsNew.vinerow.value;
      break;
    case tractorConstantsNew.remote_av.key:
      text = tractorConstantsNew.remote_av.value;
      break;
    case tractorConstantsNew.faulty.key:
      text = tractorConstantsNew.faulty.value;
      break;
    case tractorConstantsNew.service.key:
      text = tractorConstantsNew.service.value;
      break;
    case tractorConstantsNew.update.key:
      text = tractorConstantsNew.update.value;
      break;
    default:
      text = '';
      break;
  }
  return text;
};
export const geTractorStatusIcon = (planner_name: string): string => {
  let text = '';
  switch (planner_name) {
    case tractorConstantsNew.off.key:
      text = tractorConstantsNew.off.image;
      break;
    case tractorConstantsNew.available.key:
      text = tractorConstantsNew.available.image;
      break;
    case tractorConstantsNew.charging.key:
      text = tractorConstantsNew.charging.image;
      break;
    case tractorConstantsNew.manual.key:
      text = tractorConstantsNew.manual.image;
      break;
    case tractorConstantsNew.standby.key:
      text = tractorConstantsNew.standby.image;
      break;
    case tractorConstantsNew.copycat.key:
      text = tractorConstantsNew.copycat.image;
      break;
    case tractorConstantsNew.follow_me.key:
      text = tractorConstantsNew.follow_me.image;
      break;
    case tractorConstantsNew.vinerow.key:
      text = tractorConstantsNew.vinerow.image;
      break;
    case tractorConstantsNew.remote_av.key:
      text = tractorConstantsNew.remote_av.image;
      break;
    case tractorConstantsNew.faulty.key:
      text = tractorConstantsNew.faulty.image;
      break;
    case tractorConstantsNew.service.key:
      text = tractorConstantsNew.service.image;
      break;
    case tractorConstantsNew.update.key:
      text = tractorConstantsNew.update.image;
      break;
    default:
      text = tractorConstantsNew.manual.image;
      break;
  }
  return text;
};
export const geTractorStatusColor = (planner_name: string): string => {
  let text = '';
  switch (planner_name) {
    case tractorConstantsNew.off.key:
      text = tractorConstantsNew.off.color;
      break;
    case tractorConstantsNew.available.key:
      text = tractorConstantsNew.available.color;
      break;
    case tractorConstantsNew.charging.key:
      text = tractorConstantsNew.charging.color;
      break;
    case tractorConstantsNew.manual.key:
      text = tractorConstantsNew.manual.color;
      break;
    case tractorConstantsNew.standby.key:
      text = tractorConstantsNew.standby.color;
      break;
    case tractorConstantsNew.copycat.key:
      text = tractorConstantsNew.copycat.color;
      break;
    case tractorConstantsNew.follow_me.key:
      text = tractorConstantsNew.follow_me.color;
      break;
    case tractorConstantsNew.vinerow.key:
      text = tractorConstantsNew.vinerow.color;
      break;
    case tractorConstantsNew.remote_av.key:
      text = tractorConstantsNew.remote_av.color;
      break;
    case tractorConstantsNew.faulty.key:
      text = tractorConstantsNew.faulty.color;
      break;
    case tractorConstantsNew.service.key:
      text = tractorConstantsNew.service.color;
      break;
    case tractorConstantsNew.update.key:
      text = tractorConstantsNew.update.color;
      break;

    default:
      text = tractorConstantsNew.manual.color;
      break;
  }
  return text;
};

export const getCameraViewAllow = (
  heartBeat: AdminHeartBeat | undefined,
  camera: string,
  status: string,
): boolean => {
  // Ignore Work Bench Tractors
  if (heartBeat?.tractor_serial_number.includes('MTBENCH')) {
    return true;
  }

  // if offline dont allow to click
  if (status === tractorConstantsNew.off.key) {
    return false;
  }
  if (heartBeat && heartBeat.system_model) {
    const data = heartBeat.system_model.split(',').map((item) => Number(item));
    if (data && data.length) {
      const XAVIER1_POWER_STATUS = data[mqttConstants.XAVIER1_POWER_STATUS]
        ? true
        : false; // left, right
      const XAVIER2_POWER_STATUS =
        data[mqttConstants.XAVIER2_POWER_STATUS] ||
        data[mqttConstants.XAVIER6_POWER_STATUS]
          ? true
          : false; // pto
      const XAVIER3_POWER_STATUS = data[mqttConstants.XAVIER3_POWER_STATUS]
        ? true
        : false; // front
      const XAVIER4_POWER_STATUS =
        data[mqttConstants.XAVIER4_POWER_STATUS] ||
        data[mqttConstants.XAVIER6_POWER_STATUS]
          ? true
          : false; // rear

      if (
        camera === tractorCameras.FRONT_LEFT ||
        camera === tractorCameras.FRONT_RIGHT
      )
        return XAVIER1_POWER_STATUS;
      if (camera === tractorCameras.PTO) return XAVIER2_POWER_STATUS;
      if (camera === tractorCameras.FRONT) return XAVIER3_POWER_STATUS;
      if (camera === tractorCameras.REAR) return XAVIER4_POWER_STATUS;
    }
  }
  return false;
};

export const tractorCameras = {
  FRONT: 'front',
  REAR: 'rear',
  PTO: 'pto',
  FRONT_LEFT: 'front_left',
  FRONT_RIGHT: 'front_right',
  REAR_LEFT: 'rear_left',
  REAR_RIGHT: 'rear_right',
  DRIVER: 'driver',
};
export const tractorCamerasLabelsFromLiveMap: any = {
  front: 'Front',
  rear: 'Rear',
  pto: 'Hitch',
  front_left: 'Left',
  front_right: 'Right',
  rear_right: 'Rear Right',
  rear_left: 'Rear Left',
};

export const tractorCamerasLabels: any = {
  front: 'Front',
  rear: 'Rear',
  pto: 'Hitch',
  front_left: 'Left',
  front_right: 'Right',
  rear_right: 'Rear Right',
  rear_left: 'Rear Left',
};
export const setBadLocalizationStyle = (hover = false) =>
  new Style({
    image: new Icon({
      src: badLoc2,
      scale: hover ? 1.2 : 1,
      anchor: [0.5, 0.75],
      anchorXUnits: 'fraction',
      anchorYUnits: 'fraction',
    }),
    zIndex: Infinity,
  });
export const setBadLocalization = (
  coordinates: Coordinate,
  showBadLocalizationIcon = true,
  item: any,
) => {
  const style = showBadLocalizationIcon
    ? setBadLocalizationStyle(false)
    : new Style({});
  const startingPathPoint = new Feature({
    geometry: new Point(coordinates),
    name: 'bad localization',
    pointType: BadLocalization,
    style: setBadLocalizationStyle(false),
    data: item,
  });

  startingPathPoint.setStyle(style);
  return startingPathPoint;
};

export const formatFleetAnalyticsData = (data: any) =>
  Object.keys(data).reduce((a: any, item: string) => {
    const tdata = { distance: 0, hours: 0 };
    if (data[item]) {
      if (!Object.keys(data[item]).includes('hours')) {
        const t = Object.keys(data[item]).reduce(
          (ac, it) => {
            ac.distance += isNaN(data[item][it].distance)
              ? data[item][it].distance
              : 0;
            ac.hours += isNaN(data[item][it].hours) ? data[item][it].hours : 0;
            return ac;
          },
          { distance: 0, hours: 0 },
        );
        a[item] = t;
      } else {
        tdata.distance += isNaN(data[item].distance) ? data[item].distance : 0;
        tdata.hours += isNaN(data[item].hours) ? data[item].hours : 0;
        a[item] = tdata;
      }
    }

    return a;
  }, {});

export function getRandomColor(): string {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(randomNumber() * 16)];
  }
  return color;
}

export const commands = {
  GOTO_POINT: 'GOTO_POINT',
  ROW_FOLLOW: 'ROW_FOLLOW',
  ROW_FOLLOW_SECOND: 'ROW_FOLLOW_SECOND',
  ROUTINE: 'ROUTINE',
};

export const getNameFromList = (list: MapFeature[], id: number): string => {
  let name = '';
  list &&
    list.length &&
    list.forEach((feature: MapFeature) => {
      if (feature.id && id && feature.id === id) {
        name = feature.name;
      }
    });
  return name;
};

export const cameras = {
  FRONT: 'FRONT',
  REAR: 'REAR',
  HITCH: 'HITCH',
  LEFT: 'LEFT',
  RIGHT: 'RIGHT',
};

export const prepareCoordinates = (vertices: any, base: any): Coordinate[] => {
  const coordinates: Coordinate[] = [];
  if (vertices && vertices.length > 0) {
    vertices.map((point: any) => {
      if (point.latitude !== null && point.longitude !== null) {
        coordinates.push(proj.fromLonLat([point.longitude, point.latitude]));
      } else {
        point.xNew = point.x / 100;
        point.yNew = point.y / 100;
        const latAndLong: [number, number] = getLatitudeAndLongitude(
          [point.yNew, point.xNew],
          base,
          true,
        );
        coordinates.push(proj.fromLonLat(latAndLong));
      }
    });
  }
  return coordinates;
};

export const drawPoly = async (
  userDetails: UserDetails,
  base: any,
  layer: any,
  globalPolyUnique: number,
  localPolyUnique: number,
  poly: any,
  suggested = false,
  isApi = false,
) => {
  let polygonFeature = null;
  let polygonItem: MapFeature | null = null;
  let vertices = [];
  const id = `polygon_${poly.id}`;
  const { color }: { color: string } = poly;
  const { organization } = userDetails;
  try {
    let coordinates: any[] = [];
    if (!isApi) {
      if (!suggested) {
        coordinates = await getPolygonData(
          organization.api_url,
          organization.farm.id,
          poly.polygon_uuid,
        );
      } else {
        coordinates = await getBrownPolygonData(
          organization.api_url,
          organization.farm.id,
          poly.polygon_uuid,
          poly.color,
        );
      }
    } else {
      coordinates = poly.vertices;
    }
    vertices = coordinates;
    coordinates = prepareCoordinates(coordinates, base);
    let satelightPolyStyle = null;
    if (coordinates && coordinates.length > 0) {
      const zIndex = suggested
        ? priority.POLYGON['suggestedGreen']
        : priority.POLYGON[color];
      polygonFeature = new Feature({
        type: 'Polygon',
        pointType: 'Polygon',
        geometry: new Polygon([coordinates]),
        zIndex,
        polygon_uuid: poly.polygon_uuid,
        color: suggested ? suggestedPolygon : color,
        id: poly.id,
        remarks: poly.remarks,
        poly,
        label: poly.name,
        polyName: poly.name,
      });
      const area = getAreaOfPolygon(polygonFeature);
      if (area > 0) {
        // calcualted area in acre and final value rounded
        polygonFeature.set(
          'label',
          poly?.name + ' ' + Math.round(area) + ' ' + 'acre',
        );
      }
      polygonFeature.setId(id);
      satelightPolyStyle = getSateLightPolyStyle(color, zIndex, suggested);
      const roadMapPolyStyle = getRoadMapPolyStyle(color, zIndex, suggested);
      polygonFeature.setStyle(satelightPolyStyle);
      polygonFeature.set('satelightPolyStyle', satelightPolyStyle);
      polygonFeature.set('roadMapPolyStyle', roadMapPolyStyle);
      globalPolyUnique === localPolyUnique &&
        layer &&
        layer?.getSource().addFeature(polygonFeature);
    }
    polygonItem = {
      id: poly.id,
      name: poly.name,
      type: POLYGON,
      mapId: id,
      visible: true,
      style: satelightPolyStyle ? satelightPolyStyle : null,
      color: poly.color,
      polygon_uuid: poly.polygon_uuid,
      vertices,
      suggested: suggested ? true : false,
      remarks: poly?.remarks,
    };
    return { polygonFeature, polygonItem };
  } catch (error: any) {
    notification.error({
      message: error.message,
    });
    return { polygonFeature, polygonItem };
  }
};

export const getAreaOfPolygon = (polygonFeature: any): number => {
  if (polygonFeature) {
    const geo: any = polygonFeature?.getGeometry();
    const area = getArea(geo);
    let output;
    if (area > 0) {
      output = Math.round(area * 100) / 100;
      output = output / 4046.85642;
      return Number(output.toFixed(2));
    }
  }
  return 0;
};

export const mapMakerWayPointStyle = new Style({
  image: new Icon({
    src: WaypointIcon,
    size: [40, 40],
    // anchor: [0.17, 0.44],
    anchor: [0.42, 0.68],
    anchorOrigin: 'center',
    displacement: [0, 0],
    scale: 0.5,
  }),
});

export const mapMakerChargePointStyle = new Style({
  image: new Icon({
    src: ChargepointIcon,
    size: [40, 40],
    // anchor: [0.17, 0.44],
    anchor: [0.42, 0.68],
    anchorOrigin: 'center',
    displacement: [0, 0],
    scale: 0.5,
  }),
});

export const mapmakerVineStyle = new Style({
  image: new Icon({
    src: VineIcon,
    size: [10, 10],
    scale: 0.7,
  }),
});

export const tractorStyle = new Style({
  image: new Icon({
    src: tractorIcon,
    scale: 0.5,
    size: [78, 78],
  }),
  text: new Text({
    text: '',
    scale: 1.0,
    fill: new Fill({
      color: '#2F3F8F',
    }),
    font: ' bold 8px Montserrat-Bold',
  }),
});

export const isEmpty = (value: any) => {
  if (Array.isArray(value)) return !value.length;
  return !Object.keys(value).length;
};
export const isEmptyValue = (value: any) => {
  if (typeof value === 'undefined') return true;
  if (typeof value === 'string' && value.trim() === '') return true;
  if (typeof value === 'number' && isNaN(value)) return true;
  if (value === null) return true;
  return false;
};

export const calTotal = (data: any | undefined) => {
  const state: boolean = data && Object.keys(data).length;
  const total: number = state
    ? Object.values(data).reduce((a: number, c) => (a += Number(c)), 0)
    : 0;
  return { state, total };
};

export const calPercentage = (item: number, total: number) => {
  const num = (Number(item) / Number(total)) * 100;
  const d = ((num + Number.EPSILON) * 100) / 100;
  return Math.round(d);
};

export const diff_minutes = (dt2: number, dt1: number): number => {
  let diff =
    (moment(dt2).toDate().getTime() - moment(dt1).toDate().getTime()) / 1000;
  diff /= 60;
  return Math.abs(Math.round(diff));
};

export const shuffle = (array: any[]) => {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex != 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(randomNumber() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
};

// =======
export const LS_RAV_COMMANDS_KEY = 'remoteAV_commands';
export const LS_ROUTINE = 'routineObj';
export const REFILL_SEEDER = 'refillSeederObj';
export const getLocalStorageData = (key: string, isJson: boolean) => {
  let lsItem: any = localStorage.getItem(key);
  if (isJson && lsItem) {
    lsItem = JSON.parse(lsItem);
  }
  return lsItem ? lsItem : null;
};

export const tractorConnected = (
  heartbeat: TractorHeartBeat,
  userId: number,
): boolean => {
  return heartbeat &&
    heartbeat.drive_action_details &&
    heartbeat.drive_action_details.current_operator_id &&
    heartbeat?.drive_action_details?.current_operator_id === userId &&
    heartbeat?.drive_action_details?.current_drive_action.length > 0
    ? true
    : false;
};

export const checkTractorDrivingState = (
  heartbeat: TractorHeartBeat,
  userId: number,
): boolean => {
  const status = getOperationNewStatus(heartbeat);
  return heartbeat &&
    heartbeat.drive_action_details &&
    heartbeat.drive_action_details.current_operator_id &&
    heartbeat?.drive_action_details?.current_operator_id === userId &&
    heartbeat?.drive_action_details?.drive_action_created_at === ATOM &&
    isPlatFromWeb(heartbeat) &&
    (status === tractorConstantsNew.remote_av.key ||
      status === tractorConstantsNew.copycat.key)
    ? true
    : false;
};
export const checkTractorInConnectedState = (
  id: number,
  userId: number,
  remoteAvTractors: TractorHeartBeat[],
): boolean => {
  const [heartbeat] =
    remoteAvTractors &&
    remoteAvTractors.filter(
      (tractor: TractorHeartBeat) => tractor.tractor_id == id,
    );
  return checkTractorConnectedState(heartbeat, userId);
};
export const checkUserConnectedFromATOMOrNot = (
  heartbeat: TractorHeartBeat,
  userId: number,
): boolean => {
  return heartbeat &&
    heartbeat?.drive_action_details &&
    heartbeat?.drive_action_details?.current_operator_id &&
    heartbeat?.drive_action_details?.current_operator_id === userId &&
    heartbeat?.drive_action_details.current_drive_action.length > 0 &&
    heartbeat?.drive_action_details?.drive_action_created_at === ATOM &&
    isPlatFromWeb(heartbeat)
    ? true
    : false;
};
export const isPlatFromWeb = (heartbeat: TractorHeartBeat): boolean =>
  heartbeat?.drive_action_details?.platform === WEB ||
  heartbeat?.drive_action_details?.platform === undefined ||
  heartbeat?.drive_action_details?.platform === null
    ? true
    : false;
export const checkTractorConnectedState = (
  heartbeat: TractorHeartBeat,
  userId: number,
): boolean => {
  return heartbeat &&
    heartbeat?.drive_action_details &&
    heartbeat?.drive_action_details?.current_operator_id &&
    heartbeat?.drive_action_details?.current_operator_id === userId &&
    heartbeat?.drive_action_details.current_drive_action.length > 0 &&
    heartbeat?.drive_action_details?.drive_action_created_at === ATOM &&
    isPlatFromWeb(heartbeat) &&
    getOperationNewStatus(heartbeat) !== tractorConstantsNew.charging.key &&
    getOperationNewStatus(heartbeat) !== tractorConstantsNew.remote_av.key &&
    getOperationNewStatus(heartbeat) !== tractorConstantsNew.copycat.key
    ? true
    : false;
};
export const checkTractorConnectedFromMobileState = (
  heartbeat: TractorHeartBeat,
  userId: number,
): boolean => {
  return heartbeat &&
    heartbeat?.drive_action_details &&
    heartbeat?.drive_action_details?.current_operator_id &&
    heartbeat?.drive_action_details?.current_operator_id === userId &&
    heartbeat?.drive_action_details.current_drive_action.length > 0 &&
    heartbeat?.drive_action_details?.drive_action_created_at === ATOM &&
    heartbeat?.drive_action_details?.platform === MOBILE
    ? true
    : false;
};

export const getGearText = (heartbeat: TractorHeartBeat): string => `
  ${getGearRange(getGear(heartbeat))}${getGearRangeNumber(getGear(heartbeat))}
              
`;

export const getDiffTime = (startTime: any, endTime: any): string => {
  return moment
    .utc(
      moment(endTime, 'MMM D, YYYY h:mm A').diff(
        moment(startTime, 'MMM D, YYYY h:mm A'),
      ),
    )
    .format('HH:mm:ss');
};

export const pointExistInPolygons = (
  coordinate: Coordinate,
  polygonsLayerRef: any,
): boolean => {
  let allow = true;
  const features = polygonsLayerRef?.getSource()?.getFeatures();
  coordinate &&
    features &&
    features?.map((feature: any) => {
      if (feature && feature.getGeometry().intersectsCoordinate(coordinate)) {
        allow = false;
      }
    });
  return allow;
};

export const setTitleAndDescription = (guardRail: string) => {
  switch (guardRail) {
    case 'human protection':
      return {
        description: 'Person detected close to moving tractor',
        title: 'Human Presence',
      };
    case 'vehicle presence':
      return {
        description: 'Vehicle detected near moving tractor',
        title: 'Vehicle Presence',
      };
    case 'pto protection':
      return {
        description: 'Person detected close to spinning PTO',
        title: 'PTO Danger',
      };
    case 'path protection':
      return {
        description: 'Obstruction detected in the tractors path',
        title: 'Path Detected',
      };
    case 'speed protection':
      return {
        description: 'Speed exceeding safe limits',
        title: 'Speed Guardrail',
      };
    case 'pitch roll protection':
      return {
        description: 'Danger of rolling tractor over',
        title: 'Pitch/Roll Alert',
      };

    default:
      return {
        description: '',
        title: '',
      };
  }
};

export const mapTractors: any = (tractors: TractorsList[]) =>
  tractors.reduce((a, c) => {
    a.set(c.id, c);
    return a;
  }, new Map());

export const drawAbBlockFun = async (
  userDetails: UserDetails,
  base: any,
  layer: any,
  globalPolyUnique: number,
  localPolyUnique: number,
  abBlock: any,
  isApi = false,
) => {
  let polygonFeature = null;
  let polygonItem: MapFeature | null = null;
  let vertices = [];
  const id = `ab_block_${abBlock.id}`;
  const { organization } = userDetails;
  try {
    let coordinates: any[] = [];
    if (!isApi) {
      coordinates = await getAbBlockVerticesData(
        organization.api_url,
        organization.farm.id,
        abBlock.generic_map_uuid,
      );
    } else {
      coordinates = abBlock.vertices;
    }
    vertices = coordinates;
    coordinates = prepareCoordinates(coordinates, base);
    let satelightPolyStyle = null;
    // const color = '#032d57';
    // const color = '#032d57';
    const color = 'abBlock';
    if (coordinates && coordinates.length > 0) {
      const zIndex = priority.ABBLOCK;
      polygonFeature = new Feature({
        type: AB_BLOCK,
        pointType: AB_BLOCK,
        geometry: new Polygon([coordinates]),
        zIndex,
        generic_map_uuid: abBlock.generic_map_uuid,
        color,
        id: abBlock.id,
        remarks: abBlock.remarks,
        poly: abBlock,
        label: abBlock.name,
      });
      polygonFeature.setId(id);
      satelightPolyStyle = getSateLightPolyStyle(color, zIndex);
      const roadMapPolyStyle = getRoadMapPolyStyle(color, zIndex);
      polygonFeature.setStyle(satelightPolyStyle);
      polygonFeature.set('satelightPolyStyle', satelightPolyStyle);
      polygonFeature.set('roadMapPolyStyle', roadMapPolyStyle);
      globalPolyUnique === localPolyUnique &&
        layer &&
        layer?.getSource().addFeature(polygonFeature);
    }
    polygonItem = {
      id: abBlock.id,
      name: abBlock.name,
      type: AB_BLOCK,
      mapId: id,
      visible: true,
      style: satelightPolyStyle ? satelightPolyStyle : null,
      color,
      generic_map_uuid: abBlock.generic_map_uuid,
      vertices,
    };
    return { polygonFeature, polygonItem };
  } catch (error: any) {
    notification.error({
      message: error.message,
    });
    return { polygonFeature, polygonItem };
  }
};

export const drawPolyLineFun = async (
  userDetails: UserDetails,
  base: any,
  layer: any,
  globalPolyUnique: number,
  localPolyUnique: number,
  polyLine: any,
  isApi = false,
) => {
  let polyLineFeature = null;
  let polyLineItem: MapFeature | null = null;
  let vertices = [];
  const id = `${POLY_LINE_ID_CONSTANT}${polyLine.polyline_uuid}`;
  const { organization } = userDetails;
  try {
    let coordinates: any[] = [];
    if (!isApi) {
      coordinates = await getPolyLineVerticesData(
        organization.api_url,
        organization.farm.id,
        polyLine.polyline_uuid,
      );
    } else {
      coordinates = polyLine.vertices;
    }
    vertices = coordinates;
    coordinates = prepareCoordinates(coordinates, base);
    if (coordinates && coordinates.length > 0) {
      polyLineFeature = new Feature({
        pointType: POLY_LINE,
        geometry: new LineString(coordinates),
        name: polyLine.name,
        polyline_uuid: polyLine.polyline_uuid,
      });
      polyLineFeature.setId(id);
      globalPolyUnique === localPolyUnique &&
        layer &&
        layer?.getSource().addFeature(polyLineFeature);
    }
    polyLineItem = {
      id: polyLine.id,
      name: polyLine.name,
      type: POLY_LINE,
      mapId: id,
      visible: true,
      style: PolyLineStyle,
      vertices,
      polyline_uuid: polyLine.polyline_uuid,
    };
    return { polyLineFeature, polyLineItem };
  } catch (error: any) {
    notification.error({
      message: error.message,
    });
    return { polyLineFeature, polyLineItem };
  }
};

export const rangeList = Array.from({ length: 41 }, (_, i) =>
  Number((0.5 * i).toFixed(1)),
);

export const onKeyDown = (e: any) => {
  if (
    e.keyCode === 189 ||
    e.keyCode === 38 ||
    e.keyCode === 40 ||
    e.keyCode === 69
  ) {
    e.preventDefault();
  }
};

export const checkDisconnectCheck = (
  userDetails: UserDetails,
  selectedTractor: TractorsList,
  autoDriveMonitorErrorCodes: any,
): boolean => {
  // checking tractor exist or not
  if (!selectedTractor || !selectedTractor.id) {
    return false;
  }
  // if tractor offline hide disconnect
  if (selectedTractor.isAvailable === tractorConstantsNew.off.key) {
    return false;
  }
  // if login user and tractor connected not same hide disconnect
  if (
    userDetails?.id !==
    selectedTractor?.heartbeat?.drive_action_details?.current_operator_id
  ) {
    return false;
  }
  // if login user not from ATOM hide disconnect
  if (
    selectedTractor?.heartbeat?.drive_action_details
      ?.drive_action_created_at !== ATOM
  ) {
    return false;
  }
  // if they have monitoring errors show disconnect
  if (
    checkAutoDriveMonitorErrorCodes(
      autoDriveMonitorErrorCodes,
      selectedTractor?.id,
    )
  ) {
    return true;
  }
  // if the tractor in remote av or row follow then hide disconnect

  // if (
  //   selectedTractor.isAvailable === tractorContantsNew.remote_av.key ||
  //   selectedTractor.isAvailable === tractorContantsNew.copycat.key
  // ) {
  //   return false;
  // }
  return true;
};

export const checkAutoDriveMonitorErrorCodes = (
  errorCodes: any,
  id: number,
): boolean =>
  errorCodes &&
  errorCodes.errors &&
  errorCodes.errors.length > 0 &&
  errorCodes.tractorId == id
    ? true
    : false;

export const isHaveErrors = (errors: any): boolean =>
  errors && errors.errors && errors.errors.length > 0 ? true : false;

export const isHaveErrorsForRoutineFlow = (errors: any): boolean => {
  let resp = true;
  errors &&
    errors.errors &&
    errors.errors.length > 0 &&
    errors.errors.map((error: number) => {
      if (error == 7101 || error == 7102 || error == 7103) {
        resp = false;
      }
    });
  return resp;
};

// added to calculate hours to days, hours and minutes
export const offTimeSetUp = (numberOfHours: number): string => {
  let resp = '';
  const Days = Math.floor(numberOfHours / 24);
  const Remainder = numberOfHours % 24;
  const Hours = Math.floor(Remainder);
  const Minutes = Math.floor(60 * (Remainder - Hours));
  if (Days && Days > 0) {
    resp = `${Days} Day${Days > 1 ? 's' : ''}`;
  }
  if (Hours && Hours > 0) {
    resp = `${resp} ${Hours} Hour${Hours > 1 ? 's' : ''}`;
  }
  if (Minutes && Minutes > 0) {
    resp = `${resp} ${Minutes} Minute${Minutes > 1 ? 's' : ''} `;
  }
  return resp;
};

export const isLowLocalization = (localization: Localization): boolean => {
  return localization?.gps_status === 'NO Solution';
};
// point in good occuracy and good localization
export const isGoodLocalizationInJumpAndGo = (point: any): boolean => {
  const { gps_status } = point;
  return gps_status !== 'NO Solution';
};
export const checkIsTractorConnectedNoATOMCond = (
  tractor: TractorsList,
): boolean => {
  const { heartbeat, isAvailable } = tractor;
  return heartbeat &&
    heartbeat?.drive_action_details &&
    heartbeat?.drive_action_details?.current_operator_id &&
    heartbeat?.drive_action_details.current_drive_action.length > 0 &&
    isAvailable &&
    isAvailable !== tractorConstantsNew.off.key &&
    isAvailable !== tractorConstantsNew.faulty.key &&
    isAvailable !== tractorConstantsNew.charging.key &&
    isAvailable !== tractorConstantsNew.service.key
    ? true
    : false;
};
export const checkIsTractorConnected = (
  tractor: TractorsList,
  userId: number,
): boolean => {
  const { heartbeat, isAvailable } = tractor;
  return heartbeat &&
    heartbeat?.drive_action_details &&
    heartbeat?.drive_action_details?.current_operator_id &&
    // heartbeat?.drive_action_details?.current_operator_id === userId &&
    heartbeat?.drive_action_details.current_drive_action.length > 0 &&
    heartbeat?.drive_action_details?.drive_action_created_at === ATOM &&
    isAvailable &&
    isAvailable !== tractorConstantsNew.off.key &&
    isAvailable !== tractorConstantsNew.faulty.key &&
    isAvailable !== tractorConstantsNew.charging.key &&
    isAvailable !== tractorConstantsNew.service.key
    ? true
    : false;
};

export const checkIsTractorConnectedUserFromAtom = (
  tractor: TractorsList,
  userId: number,
): boolean => {
  const { heartbeat, isAvailable } = tractor;
  return heartbeat &&
    heartbeat?.drive_action_details &&
    heartbeat?.drive_action_details?.current_operator_id &&
    heartbeat?.drive_action_details?.current_operator_id === userId &&
    heartbeat?.drive_action_details.current_drive_action.length > 0 &&
    heartbeat?.drive_action_details?.drive_action_created_at === ATOM &&
    heartbeat?.drive_action_details?.platform === WEB &&
    isAvailable &&
    isAvailable !== tractorConstantsNew.off.key &&
    isAvailable !== tractorConstantsNew.faulty.key &&
    isAvailable !== tractorConstantsNew.charging.key &&
    isAvailable !== tractorConstantsNew.service.key
    ? true
    : false;
};

// same user login from ATOM or not
export const isLoginUserAndDriveActionUserSame = (
  tractor: TractorsList,
  userId: number,
): boolean => {
  return tractor &&
    tractor?.heartbeat &&
    tractor?.heartbeat?.drive_action_details &&
    tractor?.heartbeat?.drive_action_details?.current_operator_id &&
    tractor?.heartbeat?.drive_action_details?.current_operator_id === userId &&
    tractor?.heartbeat?.drive_action_details?.drive_action_created_at === ATOM
    ? true
    : false;
};
export const noneAttached = 'None Attached';
export const getImplementName = (selectedTractor: TractorsList): string => {
  return selectedTractor?.heartbeat?.implement?.current_implement &&
    selectedTractor?.heartbeat?.implement?.current_implement.toLowerCase() ===
      'none'
    ? noneAttached
    : selectedTractor?.heartbeat?.implement?.current_implement || noneAttached;
};

export function delay(ms: number): Promise<number> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const checkAlphaFeature = (feature: string): boolean =>
  process.env.REACT_APP_ALPHA_FEATURES?.toLowerCase().includes(
    feature.toLowerCase(),
  )
    ? true
    : false;

export const checkPausedStatus = (
  userId: number,
  selectedTractor: TractorsList,
  bar = false,
): string => {
  return userId ===
    selectedTractor?.heartbeat?.drive_action_details?.current_operator_id &&
    selectedTractor?.heartbeat?.planning_manager?.planner_name?.length > 0 &&
    selectedTractor?.heartbeat?.planning_manager?.planner_status?.length > 0 &&
    (selectedTractor?.isAvailable === tractorConstantsNew.copycat.key ||
      selectedTractor?.isAvailable === tractorConstantsNew.remote_av.key) &&
    selectedTractor?.heartbeat?.planning_manager?.planner_status === 'paused'
    ? bar
      ? redBdr
      : PAUSED
    : '';
};

export const checkTractorHaveMonitorStatueError = (
  tractorId: number,
  remoteAvTractors: TractorHeartBeat[],
): string => {
  const [hearbeat] =
    remoteAvTractors &&
    remoteAvTractors.filter(
      (tractor: TractorHeartBeat) => tractor.tractor_id == tractorId,
    );
  return hearbeat && hearbeat.autodrive_monitor_status
    ? hearbeat.autodrive_monitor_status
    : '';
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getTractorAvailableState = (
  connection: string,
  heartbeat: TractorHeartBeat | undefined,
) =>
  connection === 'offline'
    ? tractorConstantsNew.off.key
    : getOperationStatus(heartbeat as TractorHeartBeat);

export const initScroller = () => {
  document.getElementsByClassName('infinite-scroll-component').length &&
    (document.getElementsByClassName(
      'infinite-scroll-component',
    )[0].scrollTop = 0);
};

export const checkActivity = (activity: string) =>
  ['search', 'sort', 'filter', 'refresh'].includes(activity);

// Prototype Section

Object.defineProperty(String.prototype, 'regX', {
  get: function () {
    return this.replace(/[^a-zA-Z0-9!@$^*()_+\-=[\]{}'"\\|,.<>/?\s ]/g, '');
  },
});

Object.defineProperty(String.prototype, 'regXFormat', {
  get: function () {
    return this.replace(/[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/g, '');
  },
});

// Prototype Section

export const AUTODRIVE = 10003;
export const CONVERSE = 10009;

export const SHADOW = 10004;

export const checkSnapFeature = (orgId: number | null) => {
  if (!orgId) return false;
  return (
    checkAlphaFeature('SnapDownload') && ['3', '120'].includes(String(orgId))
  );
};

export const jAGStartNdStop = (points: any, name: string, zIndex = 1) => {
  const tooltipStyle = new Style({
    image: new Icon({
      src: tooltip_icon_medium,
      scale: 0.5,
      anchor: [-0.15, 2.2],
    }),
    text: new Text({
      text: name,
      scale: 1,
      fill: new Fill({
        color: '#000',
      }),
      font: '6px Montserrat-SemiBold',
      offsetX: 33,

      offsetY: -42,
    }),
    zIndex: 1111,
  });
  const style = new Style({
    image: new Icon({
      src:
        name === 'Start'
          ? JumpnGoIconStart
          : name === 'End'
          ? JumpnGoIconEnd
          : JumpnGoIconStart,
      // scale: 1.5,
      anchor: [0.6, 1],
    }),
    zIndex: 1110,
  });
  const feature = new Feature({
    geometry: new Point(points),
    name,
  });
  // feature.setStyle([style, tooltipStyle]);
  feature.setStyle(style);

  return feature;
};
export const fileTypesAccepted = '.jpg, .jpeg, .png, .bmp, .pmg, .gif, .tiff';

export const checkForFileTypes = (type: string): boolean => {
  const fileTypeAllowed =
    type === 'image/jpeg' ||
    type === 'image/png' ||
    type === 'image/jpg' ||
    type === 'image/bmp' ||
    type === 'image/tiff' ||
    type === 'image/gif' ||
    type === 'image/pmg';
  return fileTypeAllowed;
};

export const alignItems = (ts: TractorsList[], id: number) => {
  const data = ts.reduce(
    (a: any, c: TractorsList) => {
      if (c.isAvailable === tractorConstantsNew.faulty.key) {
        a['faulty'].push(c);
      } else if (
        id &&
        id === c?.heartbeat?.drive_action_details?.current_operator_id &&
        c.isAvailable !== tractorConstantsNew.off.key &&
        c.isAvailable !== tractorConstantsNew.charging.key &&
        c.isAvailable !== tractorConstantsNew.update.key
      ) {
        if (c.isAvailable === tractorConstantsNew.remote_av.key) {
          a['autoDriveInUse'].push(c);
        } else if (c.isAvailable === tractorConstantsNew.vinerow.key) {
          a['rowFollowInUse'].push(c);
        } else {
          a['inuse'].push(c);
        }
      } else if (
        c &&
        c?.heartbeat &&
        c?.heartbeat?.drive_action_details &&
        c?.heartbeat?.drive_action_details?.current_operator_id > 0 &&
        c.isAvailable !== tractorConstantsNew.off.key &&
        c.isAvailable !== tractorConstantsNew.charging.key &&
        c.isAvailable !== tractorConstantsNew.update.key
      ) {
        a['otherInuse'].push(c);
      } else if (c.isAvailable === tractorConstantsNew.available.key) {
        a['available'].push(c);
      } else if (c.isAvailable === tractorConstantsNew.charging.key) {
        a['charging'].push(c);
      } else if (c.isAvailable === tractorConstantsNew.update.key) {
        a['updating'].push(c);
      } else {
        a['others'].push(c);
      }
      return a;
    },
    {
      autoDriveInUse: [],
      rowFollowInUse: [],
      inuse: [],
      otherInuse: [],
      available: [],
      charging: [],
      faulty: [],
      updating: [],
      others: [],
    },
  );

  const result: any = Object.values(data).reduce(
    (a: any, c: any) => (a = [...a, ...c]),
    [],
  );
  return result;
};

export const downloadURI = (uri: string, name: string) => {
  const link = document.createElement('a');
  link.setAttribute('download', name);
  link.href = uri;
  link.target = '_blank';
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const initVideoSort = (selector: string) => {
  const main: any = document.getElementById(selector);
  [].map
    .call(main.children, Object)
    .sort(function (a: any, b: any) {
      return +a.id.match(/\d+/) - +b.id.match(/\d+/);
    })
    .forEach(function (elem) {
      main.appendChild(elem);
    });
};

export const tractorModelImageMap = (model: string): string => {
  const m = model.toUpperCase();
  const models: any = {
    DV: pvTractor,
    PV: pvTractor,
    NH: cnhTractor,
    CIH: chTractor,
  };
  return models[m];
};

export const fleetListTabs = {
  fleetListTractorsList: 'fleetListTractorsList',
  fleetListAutoDriveList: 'fleetListAutoDriveList',
  fleetListChargingList: 'fleetListChargingList',
};

export const distBwTwoPoints = (
  curr_pose: Position,
  start_pose: Position,
): number => {
  const xx = (start_pose.x - curr_pose.x) * (start_pose.x - curr_pose.x);
  const yy = (start_pose.y - curr_pose.y) * (start_pose.y - curr_pose.y);
  return Math.sqrt(xx + yy);
};

export const getPosition = (hb: TractorHeartBeat): Position => {
  if (hb && hb.tractor_id) {
    return {
      x: Number(hb.localization.x),
      y: Number(hb.localization.y),
      direction: Number(hb.localization.yaw),
    };
  }
  return { x: 0, y: 0, direction: 0 };
};

export const isStartPosition = (
  curr_pose: Position,
  start_pose: Position,
): boolean => {
  // #curr_pos = [x, y, direction]
  // #start_end.headA = [x, y, direction]

  const radial_dist = distBwTwoPoints(curr_pose, start_pose);
  const arc_angle = compute_arc_angle_start(start_pose, start_pose);
  const inside_go_cone =
    inside_go_cone_space(curr_pose, start_pose, arc_angle, radial_dist) ||
    inside_go_rectangle_space(curr_pose, start_pose, arc_angle);
  return inside_go_cone;
};

// #Helper Functions

export const compute_arc_angle_start = (
  curr_pose: Position,
  start_pose: Position,
): number => {
  let arc_angle_start =
    -start_pose.direction +
    degrees_to_radians(
      Math.atan2(-curr_pose.y + start_pose.y, -curr_pose.x + start_pose.x),
    );
  if (arc_angle_start < -180) arc_angle_start += 360;
  else if (arc_angle_start > 180) arc_angle_start -= 360;
  return arc_angle_start;
};

export const inside_go_cone_space = (
  curr_pose: Position,
  start_pose: Position,
  arc_angle: number,
  radial_dist: number,
): boolean => {
  let theta_upper = 0;
  let theta_lower = 0;

  if (arc_angle > 30 || arc_angle < -30 || radial_dist >= 7) {
    return false;
  } else if (arc_angle >= -30 && arc_angle <= 0) {
    theta_upper = 30 - arc_angle;
    theta_lower = -30 - 2.5 * arc_angle;
  } else if (arc_angle > 0 && arc_angle <= 30) {
    theta_upper = 30 - 2.5 * arc_angle;
    theta_lower = -30 - arc_angle;
  }
  const max_theta =
    10.0 +
    ((Math.min(radial_dist, 2) - 0.0) * (theta_upper - 10.0)) / (2.0 - 0.0);
  const min_theta =
    -10.0 +
    ((Math.min(radial_dist, 2) - 0.0) * (theta_lower + 10.0)) / (2.0 - 0.0);
  let current_yaw_diff_to_start = +curr_pose.direction - start_pose.direction;
  if (current_yaw_diff_to_start > 180) current_yaw_diff_to_start -= 360;
  else if (current_yaw_diff_to_start < -180) current_yaw_diff_to_start += 360;
  if (
    current_yaw_diff_to_start >= min_theta &&
    current_yaw_diff_to_start <= max_theta
  )
    return true;
  else return false;
};

export const degrees_to_radians = (deg: number): number =>
  (deg * Math.PI) / 180.0;

export const inside_go_rectangle_space = (
  curr_pose: Position,
  start_pose: Position,
  arc_angle: number,
): boolean => {
  const a1 = Math.tan(degrees_to_radians(start_pose.direction));
  const b1 = -1;
  const c1 =
    start_pose.y -
    start_pose.x * Math.tan(degrees_to_radians(start_pose.direction));

  const a2 = Math.tan(degrees_to_radians(start_pose.direction + 90));
  const b2 = -1;
  const c2 =
    start_pose.y -
    start_pose.x * Math.tan(degrees_to_radians(start_pose.direction + 90));

  const per_dist_start_pt_inline =
    Math.abs(a1 * curr_pose.x + b1 * curr_pose.y + c1) /
    Math.sqrt(a1 * a1 + b1 * b1);
  const per_dist_start_pt_perp =
    Math.abs(a2 * curr_pose.x + b2 * curr_pose.y + c2) /
    Math.sqrt(a2 * a2 + b2 * b2);

  if (
    per_dist_start_pt_inline <= 0.5 &&
    per_dist_start_pt_perp <= 0.75 &&
    Math.abs(arc_angle) < 15
  )
    return true;
  else return false;
};

export const getMonitorErrorsList = (
  remoteAvTractors: TractorHeartBeat[],
  tractorId: number,
): number[] => {
  const [hearbeat] =
    remoteAvTractors &&
    remoteAvTractors.filter(
      (tractor: TractorHeartBeat) => tractor.tractor_id == tractorId,
    );
  const msg =
    hearbeat &&
    hearbeat.autodrive_monitor_status &&
    hearbeat.autodrive_monitor_status.length > 0
      ? hearbeat.autodrive_monitor_status
      : '';
  return msg.split(',').map((element: string) => Number(element));
};

// only displaying the feedback for particular tractor connected user only
export const checkLoginUserAndTractorConnectUserSame = (
  tractorsMap: Map<number, TractorHeartBeat>,
  tractorId: number,
  userId: number,
): boolean => {
  const rAvTractor = tractorsMap.get(tractorId);
  return rAvTractor &&
    rAvTractor.tractor_id == tractorId &&
    userId === rAvTractor?.drive_action_details?.current_operator_id &&
    rAvTractor?.drive_action_details?.drive_action_created_at === ATOM
    ? true
    : false;
};
export const checkLoginUserAndTractorConnectUserSameTractorsList = (
  rAvTractor: TractorsList,
  tractorId: number,
  userId: number,
): boolean => {
  const { heartbeat } = rAvTractor as TractorsList;
  return heartbeat &&
    heartbeat.tractor_id &&
    heartbeat.tractor_id == tractorId &&
    userId === heartbeat?.drive_action_details?.current_operator_id &&
    heartbeat?.drive_action_details?.drive_action_created_at === ATOM
    ? true
    : false;
};
// getting single elevation value
export const getSingleElevationValue = async (
  coordinates: number[],
): Promise<number> => {
  const [res] = await getElevationValue([
    {
      lat: coordinates[1],
      lng: coordinates[0],
    },
  ]);
  return res && res.elevation ? Number(res.elevation) : 0;
};
export const makeChunkApiCalls = async (list: any[]): Promise<any[]> => {
  // Create an array of promises for each chunk
  const chunks = splitArrayIntoChunks(list, 200);
  const promises = chunks.map(async (chunk: any[]) => {
    try {
      const elevatorValue = await getElevationsValues(chunk);
      return elevatorValue; // Process the response
    } catch (error) {
      console.error('Error making API call:', error);
      return null; // Handle errors as needed
    }
  });

  // Wait for all promises to resolve
  const results = await Promise.allSettled(promises);
  await results.filter((result): result is any => result !== null);
  const finalList = results.flatMap((promise: any) => promise.value);
  return finalList;
};
// getting multiple elevation values
export const getElevationsValues = async (
  coordinates: any[],
): Promise<any[]> => {
  let list;
  const ltv: LocationCoordinates[] = coordinates.map(
    (lat_long_vertices: { latitude: number; longitude: number }) => ({
      lat: lat_long_vertices.latitude,
      lng: lat_long_vertices.longitude,
    }),
  );
  const res = await getElevationValue(ltv);
  if (res && res.length > 0) {
    list = coordinates.map(
      (coor: { latitude: number; longitude: number }, index: number) => ({
        ...coor,
        altitude: res[index].elevation,
      }),
    );
  }

  return res && res.length && list && list.length > 0 ? list : [];
};
export const addingDefaultAltitude = (
  lat_long_vertices: LatLngVertices[],
): LatLngVertices[] => {
  const llv: LatLngVertices[] = lat_long_vertices.map(
    (lat_long: LatLngVertices) => {
      return {
        ...lat_long,
        altitude: 0,
      };
    },
  );
  return llv && llv.length > 0 ? llv : [];
};
// adding altitude into payload
export const addingAltitude = async (
  lat_long_vertices: LatLngVertices[],
): Promise<LatLngVertices[]> => {
  const elevatorValue = await getElevationsValues(lat_long_vertices);
  return elevatorValue && elevatorValue.length > 0 ? elevatorValue : [];
};
export const getElevationValue = async (locations: LocationCoordinates[]) => {
  const elevator = new google.maps.ElevationService();
  const res = await elevator.getElevationForLocations({
    locations,
  });
  return res.results;
};

export const markStyleForDot = new Style({
  image: new Icon({
    src: RoundIcon,
    scale: 1,
  }),
  zIndex: 100,
});

export const distance = (
  lon1: number,
  lat1: number,
  lon2: number,
  lat2: number,
  // unit,
): number => {
  const radlat1 = (Math.PI * lat1) / 180;
  const radlat2 = (Math.PI * lat2) / 180;
  const theta = lon1 - lon2;
  const radtheta = (Math.PI * theta) / 180;
  let dist =
    Math.sin(radlat1) * Math.sin(radlat2) +
    Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
  if (dist > 1) {
    dist = 1;
  }
  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515;
  // if (unit == 'K') {
  dist = dist * 1.609344;
  // }
  // if (unit == 'N') {
  //   dist = dist * 0.8684;
  // }
  return dist;
};

export const nearestLocation = (
  list: any,
  latitude: number,
  longitude: number,
): number => {
  let minDif = 99999;
  let closest = 0;
  list.map((data: any, index: number) => {
    const dif = PythagorasEquirectangular(
      latitude,
      longitude,
      data.latitude,
      data.longitude,
    );
    if (dif < minDif) {
      closest = index;
      minDif = dif;
    }
  });
  return closest;
};

export const nearestLocationNew = (
  list: any,
  latitude: number,
  longitude: number,
): number => {
  let minDif = 99999;
  let closest = 0;
  list.map((data: any, index: number) => {
    const dif = PythagorasEquirectangular(
      latitude,
      longitude,
      data.lat,
      data.long,
    );
    if (dif < minDif) {
      closest = index;
      minDif = dif;
    }
  });
  return closest;
};

// Convert Degress to Radians
const Deg2Rad = (deg: number) => {
  return (deg * Math.PI) / 180;
};

const PythagorasEquirectangular = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number,
): number => {
  lat1 = Deg2Rad(lat1);
  lat2 = Deg2Rad(lat2);
  lon1 = Deg2Rad(lon1);
  lon2 = Deg2Rad(lon2);
  const R = 6371; // km
  const x = (lon2 - lon1) * Math.cos((lat1 + lat2) / 2);
  const y = lat2 - lat1;
  const d = Math.sqrt(x * x + y * y) * R;
  return d;
};

export const timeDifference = (date1: number, date2: number): string => {
  let difference = date1 - date2;

  const daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);
  difference -= daysDifference * 1000 * 60 * 60 * 24;

  const hoursDifference = Math.floor(difference / 1000 / 60 / 60);
  difference -= hoursDifference * 1000 * 60 * 60;

  const minutesDifference = Math.floor(difference / 1000 / 60);
  difference -= minutesDifference * 1000 * 60;

  const secondsDifference = Math.floor(difference / 1000);
  return `${hoursDifference > 0 ? hoursDifference : '-'} hr ${
    minutesDifference > 0 ? minutesDifference : '-'
  } min ${
    minutesDifference === 0
      ? secondsDifference > 0
        ? secondsDifference + ' Seconds'
        : ''
      : ''
  }`;
};

export const getTicketStyle = (hover = false): Style => {
  return new Style({
    image: new Icon({
      src: TicketIcon,
      scale: hover ? 2.5 : 2,
    }),
    zIndex: 100,
  });
};

export const checkForCNHITractor = (model: string): boolean => {
  if (model?.toUpperCase() === 'NH' || model?.toUpperCase() === 'CIH') {
    return true;
  }
  return false;
};

export const getTractorImage = (
  planner_name: string,
  tractorModel: string,
): string => {
  let text = '';
  if (tractorModel?.toUpperCase() === 'NH') {
    return cnhTractor;
  } else if (tractorModel?.toUpperCase() === 'CIH') {
    return chTractor1;
  } else {
    switch (planner_name) {
      case tractorConstantsNew.off.key:
        text = tractorOff;
        break;
      case tractorConstantsNew.available.key:
        text = tractorAvailable;
        break;
      case tractorConstantsNew.charging.key:
        text = tractorCharging;
        break;
      case tractorConstantsNew.manual.key:
        text = tractorManual;
        break;
      case tractorConstantsNew.standby.key:
        text = tractorManual;
        break;
      case tractorConstantsNew.copycat.key:
        text = tractorCopyCat;
        break;
      case tractorConstantsNew.follow_me.key:
        text = tractorShadow;
        break;
      case tractorConstantsNew.vinerow.key:
        text = tractorVineRow;
        break;
      case tractorConstantsNew.remote_av.key:
        text = tractorAutoDrive;
        break;
      case tractorConstantsNew.faulty.key:
        text = tractorFaulty;
        break;
      case tractorConstantsNew.service.key:
        text = tractorService;
        break;
      case tractorConstantsNew.update.key:
        text = tractorManual;
        break;
      default:
        text = tractorManual;
        break;
    }
    return text;
  }
};

export const hideOnTractorConnectCommon = (
  selectedTractor: TractorsList,
  selectedTractorsIds: any[],
  showConnectLoader: boolean,
): boolean => {
  return selectedTractor &&
    selectedTractor.id &&
    selectedTractorsIds.includes(selectedTractor.id) &&
    showConnectLoader
    ? true
    : false;
};

export const showInterruptMsg = (
  autoDriveInterruptMsgs: any,
  selectedTractor: TractorsList,
  userDetails: UserDetails,
): boolean => {
  // const list = Object.values(autoDriveInterruptMsgs).filter(
  //   (item: any) => item.tractor_id === selectedTractor.id,
  // );
  const list = getSystemPausedInterrupts(
    autoDriveInterruptMsgs,
    selectedTractor.id,
  );

  return userDetails &&
    userDetails.id &&
    selectedTractor &&
    selectedTractor.heartbeat &&
    list &&
    list.length > 0 &&
    checkTractorDrivingState(selectedTractor.heartbeat, userDetails.id)
    ? true
    : false;
};

export const formatTeams = (teams: any[], short = false) => {
  const tname = teams.map((team: any) => team?.name || '');
  if (!short) return tname.join(', ');
  return tname.length > 3
    ? tname.slice(0, 3).join(', ') + '....'
    : tname.join(', ');
};

export const getPriorityInterrupt = (
  autoDriveInterruptMsgs: any,
  tractorId: number,
) => {
  const list = getSystemPausedInterrupts(autoDriveInterruptMsgs, tractorId);

  const tractorList = getFirstArrayByPriority(list);
  return tractorList && tractorList.length > 0 ? [tractorList[0]] : [];
  // return list;
};

export const loginUserHaveMosCloudInterruptsOrNot = (
  autoDriveInterruptMsgs: any,
  tractorId: number,
  heartBeat: TractorHeartBeat,
  userId: number,
): boolean => {
  if (checkTractorDrivingState(heartBeat, userId)) {
    const mosClouds = getMosCloudPauseInterrupts(
      autoDriveInterruptMsgs,
      tractorId,
    );
    if (mosClouds && mosClouds.length > 0) {
      return true;
    }
  }
  return false;
};

export const getMosCloudPauseInterrupts = (
  autoDriveInterruptMsgs: any,
  tractorId: number,
) => {
  const list =
    autoDriveInterruptMsgs && autoDriveInterruptMsgs[tractorId]
      ? autoDriveInterruptMsgs[tractorId]
      : {};
  return Object.values(list).filter(
    (item: any) =>
      item?.tractor_id == tractorId && item?.metadata?.category === MOS_CLOUD,
  );
};

export const getSystemPausedInterrupts = (
  autoDriveInterruptMsgs: any,
  tractorId: number,
) => {
  const list =
    autoDriveInterruptMsgs && autoDriveInterruptMsgs[tractorId]
      ? autoDriveInterruptMsgs[tractorId]
      : {};
  return Object.values(list).filter(
    (item: any) =>
      item?.tractor_id == tractorId &&
      item?.metadata?.category === SYSTEM_PAUSE,
  );
};

export const isHaveSeederInterrupt = (
  autoDriveInterruptMsgs: any,
  tractorId: number,
): boolean => {
  let isSeeder = false;
  const list = getSystemPausedInterrupts(autoDriveInterruptMsgs, tractorId);
  list.forEach((item: any) => {
    if (item?.metadata?.details === interruptMessageTypes.implement_refill)
      isSeeder = true;
  });
  return isSeeder;
};

export const loginUserHaveInterruptsOrNot = (
  autoDriveInterruptMsgs: any,
  tractorId: number,
  heartBeat: TractorHeartBeat,
  userId: number,
  returnClass = false,
): string => {
  if (
    checkTractorDrivingState(heartBeat, userId) &&
    autoDriveInterruptMsgs &&
    autoDriveInterruptMsgs[tractorId]
  ) {
    const mosClouds = getMosCloudPauseInterrupts(
      autoDriveInterruptMsgs,
      tractorId,
    );
    const systemPauses = getSystemPausedInterrupts(
      autoDriveInterruptMsgs,
      tractorId,
    );
    if (
      // systemPauses &&
      // systemPauses.length > 0 &&
      mosClouds &&
      mosClouds.length > 0
    ) {
      return !returnClass ? PAUSED : redBdr;
    }
    if (systemPauses && systemPauses.length > 0) {
      return !returnClass ? STOPPED : redBdr;
    }
  }
  return '';
};

export const isRemainingTimeTopic = (
  msg: MosEventsResp,
  orgId: number,
  tractorId: number,
): boolean =>
  msg &&
  msg.category &&
  msg.category === AUTO_DRIVE_METRIC_ESTIMATED_TIME_ARRIVAL &&
  msg.organization_id &&
  msg.organization_id === orgId &&
  msg.tractor_id &&
  msg.tractor_id == tractorId
    ? true
    : false;

export const isRefillPositionTopic = (
  msg: MosEventsResp,
  orgId: number,
  tractorId: number,
): boolean =>
  msg &&
  msg.category &&
  msg.category === AUTO_DRIVE_METRIC_REFILL_POSE2D &&
  msg.organization_id &&
  msg.organization_id === orgId &&
  msg.tractor_id &&
  msg.tractor_id == tractorId
    ? true
    : false;

export const timeDuration = (previous: any) => {
  const x = moment();
  const y = moment(previous);
  const duration = moment.duration(x.diff(y));
  const hours = Math.round(duration.as('hours'));
  const milliSeconds = Math.round(duration.as('milliseconds'));
  const diffDays = Math.floor(milliSeconds / (1000 * 60 * 60 * 24));
  const diffHours = Math.floor(
    (milliSeconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
  );
  const minutes = duration.minutes();
  const newHours =
    diffHours !== 0 && diffHours > 2
      ? `${diffHours} hrs`
      : diffHours < 2 && diffHours !== 0
      ? `${diffHours} hr`
      : '';
  const newHour =
    hours > 0 && hours < 2
      ? `${hours} hr ${minutes} mins`
      : hours === 0
      ? minutes !== 0
        ? `${minutes} mins`
        : ''
      : `${hours} hrs ${minutes} mins`;
  return hours < 24
    ? newHour
    : hours >= 24 && hours <= 48
    ? `${diffDays} day ${newHours}`
    : `${diffDays} days ${newHours}`;
};
export const getInterruptsByTractor = (aDIM: any, tractorId: number) =>
  aDIM && aDIM[tractorId] ? aDIM[tractorId] : {};

export const setInterruptByTractor = (
  aDIM: any,
  tractorADIM: any,
  tractorId: number,
) => {
  aDIM[tractorId] = tractorADIM;
  return aDIM;
};

export const handlePolyExistInBrown = (
  brownFeatsArray: any,
  newCreatedFeature: any,
): boolean => {
  let exist = false;
  if (brownFeatsArray && newCreatedFeature) {
    const entries = Array.from(brownFeatsArray.entries());
    if (entries && entries.length && newCreatedFeature) {
      const format = new GeoJSON();
      const geojsonReader: any = new jsts.io.GeoJSONReader();
      entries.forEach((brownFeat: any) => {
        if (!exist) {
          const polygon1Jsts = geojsonReader.read(
            format.writeFeatureObject(brownFeat[1]),
          ).geometry;
          const polygon2Jsts = geojsonReader.read(
            format.writeFeatureObject(newCreatedFeature),
          ).geometry;
          if (polygon1Jsts.contains(polygon2Jsts)) {
            exist = true;
          }
        }
      });
    }
  }

  return exist;
};
export const handleBrownPolyExistInBrown = (
  brownFeat: any,
  allFeats: any,
): boolean => {
  let exist = true;
  if (brownFeat && allFeats) {
    if (allFeats && allFeats.length && brownFeat) {
      const format = new GeoJSON();
      const geojsonReader: any = new jsts.io.GeoJSONReader();
      allFeats.map((feat: any) => {
        if (exist) {
          const polygon1Jsts = geojsonReader.read(
            format.writeFeatureObject(brownFeat),
          ).geometry;
          const polygon2Jsts = geojsonReader.read(
            format.writeFeatureObject(feat),
          ).geometry;
          if (!polygon1Jsts.contains(polygon2Jsts)) {
            exist = false;
          }
        }
      });
    }
  }
  return exist;
};

export const convertMeterToFeet = (value: number) => {
  return value * 3.28084;
};

export const convertInchesToFeet = (value: number) => {
  return value / 12;
};

export const isValidBlock = (implementWidth: number, blockWidth: number) => {
  const impWidthinFeet = convertInchesToFeet(implementWidth);
  const blockWidthInFeet = convertMeterToFeet(blockWidth);
  return impWidthinFeet + 2 > blockWidthInFeet;
};

export const feedHeadStyle = (knownBearing: number) => {
  return new Style({
    image: new Icon({
      src: feedDirection,
      rotation: ((90 - knownBearing) * Math.PI) / 180,
    }),
    zIndex: 100,
  });
};

export const isFeedLaneChecked = (
  selectedFeedLanes: FeedLaneType[],
  feedLane: FeedLaneType,
) => {
  return selectedFeedLanes.some((obj: FeedLaneType) => obj.id === feedLane.id);
};

export const getSelectedFeedLane = (
  selectedFeedLanes: FeedLaneType[],
  feedLane: FeedLaneType,
) => {
  const [feed] = selectedFeedLanes.filter(
    (feed: FeedLaneType) => feed.id === feedLane.id,
  );
  return feed;
};

export const isVineYardOrDairy = (userDetails: UserDetails) =>
  userDetails?.organization?.crop_type?.toLowerCase() || vineYardAutoDrive;
// dairyAutoDrive;

export const convertBytesToMB = (bytes: number): number =>
  Number((bytes / (1024 * 1024)).toFixed(2));

export const selectTypeImplement = (data: string) =>
  data && data.toLowerCase().includes('sprayer') ? 'Capacity' : 'Weight';

export const selectTypeImplementUnit = (data: string) =>
  data && data.toLowerCase().includes('sprayer') ? 'GAL' : 'LBS';

export const getTimeDifference = (
  timestamp1: number,
  timestamp2: number,
): string => {
  const durationInMilliseconds = Math.abs(timestamp1 - timestamp2);
  const duration = moment.duration(durationInMilliseconds);

  const hours = Math.floor(duration.asHours());
  const minutes = Math.floor(duration.asMinutes()) % 60;
  const seconds = Math.floor(duration.asSeconds()) % 60;

  return `${hours}:${minutes < 10 ? '0' : ''}${minutes}:${
    seconds < 10 ? '0' : ''
  }${seconds}`;
};

export const getTimeFromTimestamp = (timestamp: number) => {
  const date = new Date(timestamp);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  // const seconds = date.getSeconds();

  return `${hours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')}`;
};

export const NumberValidationMethod = (e: any, value: any, number: any) => {
  const str = e;
  const regx = /^[+-]?\d+(\.\d+)?$/;
  if (
    Number(str) >= 0 &&
    Number(str) <= number &&
    (Number(str) === 0 || regx.test(str))
  ) {
    const data = str.trim() === '' ? '' : str;
    return data;
  }
  if (str?.length == 1 && str?.trim() === '') {
    return '';
  }
  const j = value || '';
  return j;
};

export const ValidateIPAddress = (ip: string) => {
  if (
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
      ip,
    )
  ) {
    return true;
  }
  return false;
};

export const customTimeSorter = (timeA: any, timeB: any) => {
  const zeroTime = /^0*$|^00:00:00$/;
  const timeFormat = /^(\d+):([0-5]\d):([0-5]\d)$/;

  if (zeroTime.test(timeA) && zeroTime.test(timeB)) {
    return 0;
  }

  const matchA = timeA.match(timeFormat);
  const matchB = timeB.match(timeFormat);

  if (matchA && matchB) {
    const [hoursA, minutesA, secondsA] = matchA.slice(1).map(Number);
    const [hoursB, minutesB, secondsB] = matchB.slice(1).map(Number);

    if (hoursA === hoursB) {
      if (minutesA === minutesB) {
        return secondsA - secondsB;
      }
      return minutesA - minutesB;
    }

    return hoursA - hoursB;
  }

  if (zeroTime.test(timeA)) {
    return -1;
  }
  if (zeroTime.test(timeB)) {
    return 1;
  }

  return timeA.localeCompare(timeB);
};

export const randomNumber = () => {
  const crypto = window.crypto;
  const array = new Uint32Array(1);
  return Number(`0.${crypto.getRandomValues(array)[0]}`);
};
