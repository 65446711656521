import React, { useContext, useEffect, useState } from 'react';
import { Card, notification, Row, Space } from 'antd';
import { getMapMakerList } from '../../constants/Api';
import Layout, { Content } from 'antd/lib/layout/layout';
import CSelect from '../common/CSelect';
import InfiniteScrollTable from '../common/InfiniteScrollTable';
import translate from '../../locale/en_translate.json';
import { useTranslation } from 'react-i18next';
import { ApplicationContext } from '../../context/AppContext';
import { otaLogs } from '../../constants/types';
import CustomSelect from '../common/CustomSelect';

const MapMakerLogs: React.FC = () => {
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const [loader, setLoader] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPagesize] = useState<any>(25);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [filterData, setFilter] = useState<otaLogs[]>([]);
  const [totalCount, setTotalCount] = useState<any>();
  const [status, setStatus] = useState<string>('vine_row_auto_generation');

  const statusFilterData = [
    { name: 'Vine Row Auto Generation', value: 'vine_row_auto_generation' },
    {
      name: 'Planner Vine Row Generation',
      value: 'planner_vine_row_generation',
    },
    { name: 'Survey Vine Row Generation', value: 'survey_vine_row_generation' },
  ];

  useEffect(() => {
    if (status) {
      getMapMakerLogs();
    }
  }, [userDetails, status, pageNumber]);

  const getMapMakerLogs = async () => {
    try {
      setLoader(true);
      const mapmakerlogs = await getMapMakerList(
        userDetails.organization.api_url,
        userDetails.organization_id,
        pageNumber,
        pageSize,
        status,
      );
      setTotalCount(mapmakerlogs._metadata.total_records_count);
      const data =
        mapmakerlogs && mapmakerlogs.records && mapmakerlogs.records.length > 0
          ? mapmakerlogs.records
          : [];
      const shadowData: any[] = [];
      data.map((record: any) => {
        const obj = {
          id: record.id,
          job_title: record.job_title,
          action: record.action,
          feedback: record.feedback,
          status: record?.status,
        };
        shadowData.push(obj);
      });
      if (data.length > 0) {
        if (pageNumber === 1) {
          setFilter([...shadowData]);
        } else {
          setFilter([...filterData, ...shadowData]);
        }
      } else {
        setFilter(shadowData);
      }
      if (shadowData.length < 25) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    } finally {
      setLoader(false);
    }
  };

  const columns = [
    {
      title: `${t(translate.mapmakerlogs.job_title)}`,
      dataIndex: 'job_title',
      key: 'job_title',
      width: '14%',
      sorter: (a: any, b: any) => a.job_title.localeCompare(b.job_title),
    },
    {
      title: `${t(translate.mapmakerlogs.action)}`,
      dataIndex: 'action',
      key: 'action',
      width: '21%',
    },
    {
      title: `${t(translate.mapmakerlogs.feedback)}`,
      dataIndex: 'feedback',
      key: 'feedback',
      width: '16%',
    },
    {
      title: `${t(translate.mapmakerlogs.status)}`,
      dataIndex: 'status',
      key: 'status',
      width: '16%',
      sorter: (a: any, b: any) => a.status.localeCompare(b.status),
    },
  ];

  const handleLoadMore = () => {
    setPageNumber(pageNumber + 1);
  };

  return (
    <Layout>
      <div className="mainContent">
        <div className="tblContainer viewportContainer">
          <Row>
            <div className="common_wrapper wWidget_wrapper">
              <div className="filters_card personnelCard">
                <CustomSelect
                  label="Status"
                  cssClass="min_width"
                  value={status}
                  setValue={(id: any) => {
                    setStatus(id);
                    setPageNumber(1);
                  }}
                  options={statusFilterData}
                  optionKey="value"
                />
              </div>
              <div className="common_table" style={{ cursor: 'pointer' }}>
                <InfiniteScrollTable
                  columns={columns}
                  loading={loader}
                  hasMore={hasMore}
                  filterData={filterData}
                  totalcount={totalCount}
                  handleLoadMore={handleLoadMore}
                  filename="MapMakerLogs"
                />
              </div>
            </div>
          </Row>
        </div>
      </div>
    </Layout>
  );
};

export default MapMakerLogs;
