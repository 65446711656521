import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import Layout from 'antd/lib/layout/layout';
import { Checkbox, Col, Row, notification } from 'antd';

import searchIcon from './images/searchIcon.svg';
import tractorFleet from './images/tractor-fleet.png';

import CustomRangePicker from './ui/CustomRangePicker';
import useOperationalAnalyticsSearchStore from './store/useOperationalAnalyticsSearchStore';
import { getAnalyticFilters } from './services/api';
import { getUserName, isActiveClass, removeItem } from './common';

import SearchDefault from './SearchDefault';
import SearchResult from './SearchResult';

interface Props {
  onBack: () => void;
}

const SearchOperationField: React.FC<Props> = ({ onBack }) => {
  const [isSearch, setIsSearch] = useState(true);
  const childRef: any = useRef();
  const [operationType, setOperationType] = useState<boolean>(false);
  const [tractorDisplay, setTractorDisplay] = useState<boolean>(false);
  const [operatorDisplay, setOperatorDisplay] = useState<boolean>(false);
  const { setSelectedDateRange, selectedDateRange, setAnalyticsFilter, reset } =
    useOperationalAnalyticsSearchStore();
  const [operationTypes, setOperationTypes] = useState<string[]>([]);
  const [tractors, setTractors] = useState<string[]>([]);
  const [operators, setOperators] = useState<string[]>([]);
  const [filterData, setFilterData] = useState<any>({
    operations: [],
    operators: [],
    tractors: [],
  } as {
    operations: string[];
    operators: any[];
    tractors: any[];
  });
  const {
    operations: fOperations,
    tractors: fTractors,
    operators: fOperators,
  } = filterData;
  const init = useRef(false);

  const clearState = () => {
    setFilterData({
      operations: [],
      operators: [],
      tractors: [],
    });
    setOperationTypes([]);
    setTractors([]);
    setOperators([]);
    reset();
  };

  const initData = async (defaults = false) => {
    try {
      const [fromDate, toDate] = selectedDateRange;
      const response = await getAnalyticFilters(
        moment(fromDate).startOf('day')?.toDate()?.getTime(),
        moment(toDate).endOf('day')?.toDate()?.getTime(),
        operationTypes,
        tractors,
      );
      if (response) {
        const { list_of_operation_all } = response;
        if (defaults) {
          setFilterData({
            ...filterData,
            operations: list_of_operation_all,
          });
        }
        return response;
      }
    } catch (error: any) {
      notification.error({ message: error?.message });
    } finally {
      init.current = true;
    }
  };

  useEffect(() => {
    if (selectedDateRange?.length) initData(true);
  }, [selectedDateRange]);

  useEffect(() => {
    return () => clearState();
  }, []);

  useEffect(() => {
    const loadTractors = async () => {
      try {
        const response = await initData();
        const { tractor_data } = response;
        setFilterData({
          ...filterData,
          tractors: tractor_data,
        });
      } catch (error: any) {
        notification.error({ message: error.message });
      }
    };
    if (init.current && operationTypes) loadTractors();
  }, [operationTypes]);

  useEffect(() => {
    const loadOperators = async () => {
      try {
        const response = await initData();
        const { user_data } = response;
        setFilterData({
          ...filterData,
          operators: user_data,
        });
      } catch (error: any) {
        notification.error({ message: error.message });
      }
    };
    if (init.current && tractors.length) loadOperators();
  }, [tractors]);

  const handleClickOnImplementTag = (item: string) => {
    let newList: string[] = [];
    if (operationTypes.includes(item)) {
      newList = removeItem(operationTypes, item);
    } else {
      newList = [...operationTypes, item];
    }
    setTractors([]);
    setOperationTypes([...newList]);
  };

  const handleSelect = (item: string, type = 'tractor') => {
    const list = type === 'tractor' ? tractors : operators;
    let newList: string[] = [];
    if (list.includes(item)) {
      newList = removeItem(list, item);
    } else {
      newList = [...list, item];
    }
    if (type === 'tractor') {
      setOperators([]);
      setTractors([...newList]);
    } else {
      setOperators([...newList]);
    }
  };

  const textWrap = ({ text, count, placement = 1 }: any) => {
    return placement === -1 ? text?.slice(-count) : text?.slice(count);
  };

  const hideFilters = (toggle = false) => {
    setOperationType(toggle);
    setOperatorDisplay(toggle);
    setTractorDisplay(toggle);
  };

  const handleSearch = () => {
    if (!selectedDateRange?.length) return;
    const filter = {
      operationTypes,
      operatorIds: operators,
      tractorIds: tractors,
    };
    setAnalyticsFilter({
      ...filter,
    });
    setIsSearch(false);
    hideFilters();
    childRef.current.handleSearch(filter);
  };

  const tractorNames = filterData?.tractors
    .filter(({ tractor_id }: any) => tractors.includes(tractor_id))
    .map(({ tractor_pin }: any) => tractor_pin?.slice(-5))
    .join(',');

  const operatorNames = filterData?.operators
    .filter(({ operator_id }: any) => operators.includes(operator_id))
    .map(({ user_first_name }: any) => user_first_name)
    .join(',');

  const onDateSelect = () => {
    setOperationTypes([]);
    setTractors([]);
    setOperators([]);
  };

  return (
    <>
      <div className="search-operations-container">
        <div className="hc_top_bg_op"></div>
        <Layout className="teamsRel">
          <div
            className="back_button"
            onClick={() => {
              onBack();
            }}
          ></div>
          <Row gutter={[0, 40]}>
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 20, offset: 2 }}
              lg={{ span: 20, offset: 2 }}
              xl={{ span: 20, offset: 2 }}
            >
              <div className="search-menu-container">
                <ul className="search-menu">
                  <li className={`list-item sel`}>
                    <div className="list-item-block">
                      <div className="list-item-label">Start / End Date</div>
                      <div className="list-item-tagline scDatepicker">
                        <CustomRangePicker
                          defaultDates={[
                            moment().subtract(7, 'days'),
                            moment(),
                          ]}
                          handleOnChange={(dates) => {
                            setSelectedDateRange(dates);
                            onDateSelect();
                          }}
                        />
                      </div>
                    </div>
                  </li>
                  <li
                    className={`list-item ${operationType && 'sel'}`}
                    onClick={(e) => {
                      e.preventDefault();
                      setOperationType(!operationType);
                      setTractorDisplay(false);
                      setOperatorDisplay(false);
                    }}
                  >
                    <div className="list-item-block">
                      <div className="list-item-label">Operation Type</div>
                      <div className="list-item-tagline">
                        {!operationTypes?.length ? (
                          'Select implements'
                        ) : (
                          <div className="op-ellipsis">
                            {operationTypes?.length == fOperations?.length
                              ? 'All Operations'
                              : operationTypes?.join(', ')}
                          </div>
                        )}
                      </div>
                    </div>
                    {operationType && (
                      <div
                        className="list-content"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <div className="list-wrapper">
                          <div className="op-options-head">
                            <div className="implement-type-txt">
                              Types of Implements
                            </div>
                            <div className="slect-all">
                              <span className="chkbox">
                                <Checkbox
                                  checked={
                                    operationTypes?.length ==
                                    fOperations?.length
                                  }
                                  className="select-box"
                                  onChange={(e) => {
                                    e.stopPropagation();

                                    setOperationTypes(() => {
                                      setTractors([]);
                                      setFilterData({
                                        ...filterData,
                                        tractors: [],
                                      });
                                      return e.target.checked
                                        ? filterData?.operations
                                        : [];
                                    });
                                  }}
                                >
                                  <span>Select All</span>
                                </Checkbox>
                              </span>
                            </div>
                          </div>
                          <div className="implement-chips-container">
                            {fOperations.map((operationType: string) => (
                              <div
                                key={operationType}
                                className={`op-chip-item ${isActiveClass(
                                  operationTypes,
                                  operationType,
                                  'op-item-active',
                                )}`}
                                onClick={() => {
                                  handleClickOnImplementTag(operationType);
                                }}
                              >
                                {operationType}
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    )}
                  </li>
                  <li
                    className={`list-item ${tractorDisplay && 'sel'}`}
                    onClick={(e: any) => {
                      e.preventDefault();
                      setTractorDisplay(!tractorDisplay);
                      setOperationType(false);
                      setOperatorDisplay(false);
                    }}
                  >
                    <div className="list-item-block">
                      <div className="list-item-label">Tractor</div>
                      <div className="list-item-tagline">
                        {!tractors?.length ? (
                          'Select equipment'
                        ) : (
                          <div className="op-ellipsis">
                            {tractors?.length == fTractors?.length
                              ? 'All Tractors'
                              : tractorNames}
                          </div>
                        )}
                      </div>
                    </div>
                    {tractorDisplay && (
                      <div
                        className="list-content"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <div className="list-wrapper">
                          <div className="op-options-head">
                            <div className="implement-type-txt">Your Fleet</div>
                            <div className="slect-all">
                              <span className="chkbox">
                                <Checkbox
                                  checked={
                                    tractors?.length == fTractors?.length
                                  }
                                  className="select-box"
                                  onClick={(e: any) => {
                                    e.stopPropagation();
                                    const ids = filterData?.tractors?.map(
                                      ({ tractor_id }: any) => tractor_id,
                                    );
                                    setTractors(() => {
                                      setOperators([]);
                                      setFilterData({
                                        ...filterData,
                                        operators: [],
                                      });
                                      return e.target?.checked ? ids : [];
                                    });
                                  }}
                                >
                                  <span>Select All</span>
                                </Checkbox>
                              </span>
                            </div>
                          </div>
                          <div className="implement-chips-container">
                            {fTractors.map((tractor: any) => (
                              <div
                                className={`fleet-card ${isActiveClass(
                                  tractors,
                                  tractor?.tractor_id,
                                  'feet-card-active',
                                )}`}
                                key={tractor.tractor_id}
                                onClick={() => {
                                  handleSelect(tractor?.tractor_id);
                                }}
                              >
                                <img src={tractorFleet} />
                                <div className="tractor-no">
                                  {textWrap({
                                    text: tractor?.tractor_pin,
                                    placement: -1,
                                    count: 5,
                                  })}
                                </div>
                              </div>
                            ))}

                            {/* <div className="fleet-card">
                              <img src={tractorFleet} />
                              <div className="tractor-no">10343</div>
                            </div>
                            <div className="fleet-card">
                              <img src={tractorFleet} />
                              <div className="tractor-no">10422</div>
                            </div>
                            <div className="fleet-card">
                              <img src={tractorFleet} />
                              <div className="tractor-no">10373</div>
                            </div>
                            <div className="fleet-card">
                              <img src={tractorFleet} />
                              <div className="tractor-no">10355</div>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    )}
                  </li>
                  <li
                    className={`list-item ${operatorDisplay && 'sel'}`}
                    onClick={(e) => {
                      e.preventDefault();
                      setOperatorDisplay(!operatorDisplay);
                      setTractorDisplay(false);
                      setOperationType(false);
                    }}
                  >
                    <div className="list-item-block">
                      <div className="list-item-label">Operator</div>
                      <div className="list-item-tagline">
                        {!operators?.length ? (
                          'Select operator'
                        ) : (
                          <div className="op-ellipsis">
                            {operators?.length == fOperators?.length
                              ? 'All Operators'
                              : operatorNames}
                          </div>
                        )}
                      </div>
                    </div>
                    {operatorDisplay && (
                      <div
                        className="list-content"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <div className="list-wrapper">
                          <div className="op-options-head">
                            <div className="implement-type-txt">Operators</div>
                            <div className="slect-all">
                              <span className="chkbox">
                                <Checkbox
                                  className="select-box"
                                  checked={
                                    operators?.length == fOperators?.length
                                  }
                                  onClick={(e: any) => {
                                    e.stopPropagation();
                                    setOperatorDisplay(true);
                                    const ids = filterData?.operators?.map(
                                      ({ operator_id }: any) => operator_id,
                                    );
                                    setOperators(e.target.checked ? ids : []);
                                  }}
                                >
                                  <span>Select All</span>
                                </Checkbox>
                              </span>
                            </div>
                          </div>
                          <div className="implement-chips-container">
                            {fOperators.map((operator: any) => (
                              <div
                                className={`operators-names ${isActiveClass(
                                  operators,
                                  operator?.operator_id,
                                  'operators-names-active',
                                )}`}
                                key={operator?.operator_id}
                                onClick={() => {
                                  handleSelect(
                                    operator?.operator_id,
                                    'operator',
                                  );
                                }}
                              >
                                {getUserName(
                                  operator?.user_first_name,
                                  operator?.user_last_name,
                                )}
                              </div>
                            ))}

                            {/* <div className="operators-names">Alex James</div>
                            <div className="operators-names">
                              Brandon Fraiser
                            </div>
                            <div className="operators-names">Dale Howard</div>
                            <div className="operators-names">
                              Derek Matthews
                            </div>
                            <div className="operators-names">Adam Leodones</div>
                            <div className="operators-names">Adam Leodones</div> */}
                          </div>
                        </div>
                      </div>
                    )}
                  </li>
                  <div
                    className={`searchBtn sel ${
                      !selectedDateRange?.length && 'disableSearch'
                    }`}
                  >
                    <img src={searchIcon} onClick={handleSearch} />
                  </div>
                </ul>
              </div>
              {/* <div className="searchOpTxt">Search Operations</div> */}
              {/* <div className="operationsGrid">
                <div className="w932px">
                  <div>
                    <div className="gridPadding">
                      <div className="gridTitle">Start / End Date</div>
                      <div className="gridDesc">Select range</div>
                    </div>
                  </div>
                  <div className="border"></div>
                  <div>
                    <div className="gridPadding">
                      <div className="gridTitle">Operation Type</div>
                      <div className="gridDesc">Select implements</div>
                      <Input className="impInput" placeholder="Select implements" />
                    </div>
                  </div>
                  <div className="border"></div>
                  <div>
                    <div className="gridPadding">
                      <div className="gridTitle">Tractor</div>
                      <div className="gridDesc">Select equipment</div>
                    </div>
                  </div>
                  <div className="border"></div>
                  <div>
                    <div className="gridPadding">
                      <div className="gridTitle">Operator</div>
                      <div className="gridDesc">Select personnel</div>
                    </div>
                  </div>
                </div>
                <div className="searchInGrid">
                  <div className="operationSearch">
                    <SearchOutlined />
                  </div>
                </div>
              </div> */}
            </Col>
          </Row>
          {isSearch && <SearchDefault />}
          {<SearchResult ref={childRef} isSearch={isSearch} />}
        </Layout>
      </div>
    </>
  );
};

export default SearchOperationField;
