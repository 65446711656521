/* eslint-disable no-console */
/* eslint-disable react/jsx-no-undef */
import { notification } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getShadowDetails } from '../../constants/Api';
import { ApplicationContext } from '../../context/AppContext';
import InfiniteScrollTable from '../common/InfiniteScrollTable';
import { getDateTime } from '../../constants/Common';
import translate from '../../locale/en_translate.json';

interface Props {
  reportId: any;
}

const SupportHistorydetails: React.FC<Props> = ({ reportId }: Props) => {
  const { userDetails } = useContext(ApplicationContext);
  const { t } = useTranslation();
  const [filterData, setFilter] = useState<any[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [totalcount, settotalcount] = useState<any>();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [loader, setLoader] = useState<boolean>(false);

  useEffect(() => {
    getShadowDetail();
  }, []);

  const getShadowDetail = async () => {
    try {
      setLoader(true);
      const shadowDetails = await getShadowDetails(
        userDetails.organization.api_url,
        reportId.drive_action_uuid,
        reportId.acr_uuid,
      );
      settotalcount(shadowDetails._metadata.total_records_count);
      const data =
        shadowDetails &&
        shadowDetails.records &&
        shadowDetails.records.length > 0
          ? shadowDetails.records
          : [];
      const shadowInfo: any = [];
      data.map((record: any) => {
        const obj = {
          name: record.tractor?.name ? record.tractor.name : '',
          Date: record.created_date_time
            ? getDateTime(record.created_date_time)
            : '-',
          status: record.autonomous_control_status_name,
        };
        shadowInfo.push(obj);
      });
      if (data.length > 0) {
        if (pageNumber === 1) {
          setFilter(shadowInfo);
        } else {
          setFilter([...filterData, ...shadowInfo]);
        }
      } else {
        setFilter(shadowInfo);
      }
      if (shadowInfo.length < 25) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
      setLoader(false);
      // setShadowList(data as []);
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    }
  };

  const columns = [
    {
      title: `${t(translate.shadowhistory.name)}`,
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: `${t(translate.shadowhistory.date)}`,
      dataIndex: 'Date',
      key: 'Date',
      width: '220px',
    },
    {
      title: `${t(translate.shadowhistory.status)}`,
      dataIndex: 'status',
      key: 'status',
    },
  ];

  const handleLoadMore = () => {
    setPageNumber(pageNumber + 1);
  };

  return (
    <div className="tblDft farmTabsTbl posRel tblStyles supportHistoryDetailsTbl">
      <InfiniteScrollTable
        columns={columns}
        loading={loader}
        hasMore={hasMore}
        filterData={filterData}
        totalcount={totalcount}
        handleLoadMore={handleLoadMore}
        filename="Records"
      />
    </div>
  );
};

export default SupportHistorydetails;
