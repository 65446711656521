import React, { useState } from 'react';
import { Tabs } from 'antd';
import TractorMigration from './TractorMigration';
import Tickets from './Tickets';
import TractorHealth from './TractorHealth';
import BaseStationHealth from './BaseStationHealth';
import LogViewerTabs from './LogViewer/LogViewerTabs';
import UserManual from './UserManual/UserManual';
import ReleaseNotes from './releaseNotes/ReleaseNotes';
import DiagnosticCodes from './DiagnosticCodes';
import OnDemandReports from './onDemandReports/OnDemandReports';
import ServiceWorkOrder from './serviceWorkOrder/ServiceWorkOrder';
import './style.css';

const { TabPane } = Tabs;

const Index: React.FC = () => {
  const [tabid, setTabid] = useState<string>('Tractor Migration');

  return (
    <Tabs
      activeKey={tabid}
      centered
      id="org-tab-Organization"
      className="inventoryTabs newTabContainer maintenance-tabs"
      onChange={(key) => {
        setTabid(key);
      }}
    >
      <TabPane
        tab="Tractor Migration"
        key="Tractor Migration"
        className="attTableHeight mBottom0"
      >
        {tabid === 'Tractor Migration' && <TractorMigration />}
      </TabPane>
      <TabPane
        tab="Support Tickets"
        key="Support Tickets"
        className="attTableHeight mBottom0"
      >
        {tabid === 'Support Tickets' && <Tickets />}
      </TabPane>
      <TabPane
        tab="Tractor Health"
        key="Tractor Health"
        className="attTableHeight mBottom0"
      >
        {tabid === 'Tractor Health' && <TractorHealth />}
      </TabPane>
      <TabPane
        tab="Basestation Health"
        key="Basestation Health"
        className="attTableHeight mBottom0"
      >
        {tabid === 'Basestation Health' && <BaseStationHealth />}
      </TabPane>
      <TabPane
        tab="Log Viewer"
        key="LogViewer"
        className="attTableHeight mBottom0"
      >
        {tabid === 'LogViewer' && <LogViewerTabs />}
      </TabPane>

      <TabPane
        tab="User Manual"
        key="UserManual"
        className="attTableHeight mBottom0"
      >
        {tabid === 'UserManual' && <UserManual />}
      </TabPane>

      <TabPane
        tab="Release Notes"
        key="ReleaseNotes"
        className="attTableHeight mBottom0"
      >
        {tabid === 'ReleaseNotes' && <ReleaseNotes />}
      </TabPane>
      <TabPane
        tab="Diagnostic Codes"
        key="DiagnosticCodes"
        className="attTableHeight mBottom0"
      >
        {tabid === 'DiagnosticCodes' && <DiagnosticCodes />}
      </TabPane>
      <TabPane
        tab="Report Manager"
        key="ReportManager"
        className="attTableHeight mBottom0"
      >
        {tabid === 'ReportManager' && <OnDemandReports />}
      </TabPane>

      <TabPane
        tab="Service WorkOrder"
        key="ServiceWorkOrder"
        className="attTableHeight mBottom0"
      >
        {tabid === 'ServiceWorkOrder' && <ServiceWorkOrder />}
      </TabPane>
    </Tabs>
  );
};

export default Index;
