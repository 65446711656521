/* eslint-disable react/jsx-key */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Card, Input, Row, Space, notification } from 'antd';
import Layout, { Content } from 'antd/lib/layout/layout';
import translate from '../../../locale/en_translate.json';
import InfiniteScrollTable from '../../common/InfiniteScrollTable';
import usePaginate from '../../../hooks/usePaginate';
import { ApplicationContext } from '../../../context/AppContext';
import { initScroller } from '../../../constants/Common';
import { getGoldbuilds, getMarkGoldBuildData } from '../api';
import { useTranslation } from 'react-i18next';
import { mapMarkGoldBulidData } from '../Common';
import { SearchOutlined } from '@ant-design/icons';
import refreshIcon from '../../../assets/images/refreshNew.svg';
import './style.css';

const GoldBuild: React.FC = () => {
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [loader, setLoader] = useState<boolean>(false);
  const searchString = useRef<string>('');
  const [search, setSearch] = useState<string>('');
  const [total, setTotal] = useState<number>(0);
  const [pageSize] = useState<any>(25);
  const [currentGoldBuild, setCurrentGoldBuild] = useState<any>([]);
  const [sortedInfo, setSortedInfo] = useState<any>();
  const { filterData, setData, activity, hasMore, checkActivity } =
    usePaginate();

  const wrapResult = async (
    pageNumber: number,
    pageSize: number,
    search: string,
    sortInfo: any,
  ) => {
    const { organization } = userDetails;
    const result = await getMarkGoldBuildData(
      organization?.api_url,
      pageNumber,
      pageSize,
      sortInfo,
      search,
    );
    return {
      result,
    };
  };

  const getGoldBuild = async () => {
    try {
      if (checkActivity()) initScroller();
      setLoader(true);
      const { result } = await wrapResult(
        pageNumber,
        pageSize,
        search,
        sortedInfo,
      );
      // eslint-disable-next-line no-underscore-dangle
      setTotal(result._metadata.total_records_count);
      const { records } = result;
      const data = mapMarkGoldBulidData(records);
      setData(data);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    } finally {
      setLoader(false);
    }
  };

  const loadTractors = () => {
    if (userDetails && !userDetails.organization) return;
    if (checkActivity()) {
      // eslint-disable-next-line no-unused-expressions
      pageNumber !== 1 ? setPageNumber(1) : getGoldBuild();
    } else if (activity.current === 'paginate' || activity.current === '') {
      getGoldBuild();
    }
  };

  const handleLoadMore = () => {
    if (pageNumber === 1 && checkActivity() && document) initScroller();

    activity.current = 'paginate';
    setPageNumber(pageNumber + 1);
  };

  const handleSearch = (e: any) => {
    activity.current = 'search';
    const value = e.target.value.trim();
    if (value.length <= 1) {
      searchString.current = '';
      setSearch('');
    } else {
      searchString.current = value;
      setSearch(value);
    }
  };

  useEffect(() => {
    if (userDetails?.organization) {
      loadGoldbuilds();
    }
  }, [userDetails]);

  const loadGoldbuilds = async () => {
    const { organization } = userDetails;
    try {
      const records = await getGoldbuilds(organization?.api_url);
      setCurrentGoldBuild(records);
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    }
  };

  useEffect(() => {
    loadTractors();
  }, [userDetails, pageNumber, pageSize, search, sortedInfo]);

  const copyToClipBoard = (url: string) => {
    navigator.clipboard.writeText(url);
  };

  const columnsData = [
    {
      title: `${t(translate.logViewer.softwareVersion)}`,
      dataIndex: 'software_version',
      key: 'software_version',
      width: '150px',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: true,
    },
    {
      title: `${t(translate.logViewer.firmware_version)}`,
      dataIndex: 'firmware_version',
      key: 'firmware_version',
      width: '150px',
    },
    {
      title: `${t(translate.logViewer.model)}`,
      dataIndex: 'model',
      key: 'model',
      width: '150px',
    },
    // {
    //   title: `${t(translate.logViewer.approvedBy)}`,
    //   dataIndex: 'approved_by',
    //   key: 'approved_by',
    //   width: '150px',
    // },
    // {
    //   title: `${t(translate.goldBuild.download)}`,
    //   dataIndex: 'golden_build_url',
    //   key: 'golden_build_url',
    //   width: '150px',
    //   render: (url: string) => (
    //     <>
    //       {url !== null && (
    //         <Button
    //           onClick={() => {
    //             copyToClipBoard(url);
    //             notification.info({ message: 'Url copied' });
    //           }}
    //           className="goldBuildLink"
    //         >
    //           {t(translate.buttons.copyLink)}
    //         </Button>
    //       )}
    //     </>
    //   ),
    // },
    // {
    //   title: `${t(translate.logViewer.createdTime)}`,
    //   dataIndex: 'created_date_time',
    //   key: 'created_date_time',
    //   width: '175px',
    //   sortDirections: ['descend', 'ascend', 'descend'],
    //   sorter: true,
    // },
    // {
    //   title: `${t(translate.logViewer.releaseNotes)}`,
    //   dataIndex: 'comments',
    //   key: 'comments',
    //   width: '200px',
    //   render: (comments: string) => (
    //     <Tooltip title={<Interweave content={comments} />}>
    //       <div
    //         style={{
    //           maxWidth: '150px',
    //           overflow: 'hidden',
    //           textOverflow: 'ellipsis',
    //           whiteSpace: 'nowrap',
    //         }}
    //       >
    //         <Interweave content={comments} />
    //       </div>
    //     </Tooltip>
    //   ),
    //   ellipsis: true,
    // },
  ];

  const handleChange = (pagination: any, filters: any, sorter: any) => {
    activity.current = 'filter';
    const { columnKey, order } = sorter;
    setSortedInfo({
      sort_column: columnKey,
      sort_order: order === 'ascend' ? 'asc' : 'desc',
    });
  };

  const handleRefresh = () => {
    activity.current = 'refresh';
    loadTractors();
  };

  // eslint-disable-next-line no-console
  console.log('currentGoldBuild', currentGoldBuild);

  const GridItem = ({ title, content }: { title: string; content: string }) => {
    return (
      <div className="goldBuildGridItem">
        <div>
          <b>{title}</b> :{' '}
        </div>
        <div>{content}</div>
      </div>
    );
  };

  return (
    <Layout>
      <div className="mainContent">
        <Content>
          <div className="tblContainer viewportContainer">
            <Row>
              <div className="goldBuildSummary">
                {currentGoldBuild &&
                  currentGoldBuild.map((ele: any) => {
                    return (
                      <Card className="goldSummaryCard">
                        {/* <div className="goldBuildGridItem">
                          <div>
                            <b>{ele?.model}</b> :{' '}
                          </div>
                          <div>{ele?.tractor_roof_model}</div>
                        </div> */}
                        <GridItem
                          title={ele?.model}
                          content={ele?.tractor_roof_model}
                        />
                        <GridItem
                          title="Software"
                          content={ele?.software_version}
                        />
                        <GridItem
                          title="Firmware"
                          content={ele?.compatable_firmware_version}
                        />
                      </Card>
                    );
                  })}
              </div>
              <div className="common_wrapper">
                <div className="filters_card personnelCard">
                  <div>
                    <Input
                      addonBefore={<SearchOutlined />}
                      placeholder="Search"
                      className="common_search"
                      onChange={handleSearch}
                      autoComplete="off"
                      width={120}
                    />
                  </div>
                  <div style={{ marginLeft: 'auto', cursor: 'pointer' }}>
                    <img
                      src={refreshIcon}
                      onClick={handleRefresh}
                      height={32}
                      width={32}
                    />
                  </div>
                </div>
                <div className="common_table">
                  <InfiniteScrollTable
                    columns={columnsData}
                    loading={loader}
                    hasMore={hasMore}
                    filterData={filterData}
                    totalcount={total}
                    handleLoadMore={handleLoadMore}
                    filename="Gold Build"
                    onChange={handleChange}
                  />
                </div>
              </div>
            </Row>
          </div>
        </Content>
      </div>
    </Layout>
  );
};

export default GoldBuild;
