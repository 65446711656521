import React, { useState, memo } from 'react';
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Button, Modal, Upload, notification } from 'antd';
import { RcFile } from 'antd/lib/upload';

interface Props {
  title?: string;
  callback: (data: any) => void;
}

const UploadWidget: React.FC<Props> = ({ title = 'Media', callback }) => {
  const [fileList, setFileList] = useState<any>([]);
  const [previewOpen, setPreviewOpen] = useState<boolean>(false);
  const [previewImage, setPreviewImage] = useState<string>('');
  const [previewTitle, setPreviewTitle] = useState<string>('');
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const onChange = (info: any) => {
    const { file, fileList } = info;
    file.status = 'done';
    fileList.map((ele: any, i: any) => {
      ele.index = i;
    });
    setFileList(fileList);
    callback(fileList);
  };

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const onPreview = async (file: any) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }
    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1),
    );
    setCurrentIndex(file.index);
  };
  const beforeUpload = (file: any) => {
    const imgSize = file.size / 1024 / 1024 < 8;
    const fileTypeAccepted =
      file.type === 'image/jpeg' ||
      file.type === 'image/png' ||
      file.type === 'image/jpg' ||
      file.type === 'video/mp4';
    if (!fileTypeAccepted) {
      notification.error({
        message: `${file.type} is not accepted`,
      });
      return Upload.LIST_IGNORE;
    }
    if (!imgSize) {
      notification.error({
        message: 'file size must be smaller than 8MB!',
      });
    }
    return imgSize || Upload.LIST_IGNORE || fileTypeAccepted;
  };

  const leftarrowClick = async () => {
    const leftindex = currentIndex + 1;

    setCurrentIndex(leftindex);
    setPreviewTitle(
      fileList[leftindex].name ||
        fileList[leftindex].url!.substring(
          fileList[leftindex].url!.lastIndexOf('/') + 1,
        ),
    );
    const preview = await getBase64(
      fileList[leftindex].originFileObj as RcFile,
    );
    setPreviewImage(fileList[leftindex].url || (preview as string));
  };

  const rightarrowClick = async () => {
    const rightindex = currentIndex - 1;

    setCurrentIndex(rightindex);
    setPreviewTitle(
      fileList[rightindex].name ||
        fileList[rightindex].url!.substring(
          fileList[rightindex].url!.lastIndexOf('/') + 1,
        ),
    );
    const preview = await getBase64(
      fileList[rightindex].originFileObj as RcFile,
    );
    setPreviewImage(fileList[rightindex].url || (preview as string));
  };
  const handleCancel = () => setPreviewOpen(false);

  return (
    <>
      <div className="attachemtBlk rowGapBtm3 ticketsAttachementBlk attachIcons">
        <h4 className="fSbd overline1">
          {title} ({fileList.length})
        </h4>
        <Upload
          name="avatar"
          listType="picture-card"
          className="avatar-uploader"
          onChange={onChange}
          onPreview={onPreview}
          beforeUpload={beforeUpload}
          maxCount={5}
          accept=".jpg,.png,.mp4,.jpeg"
          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
          openFileDialogOnClick={fileList.length != 5 ? true : false}
        >
          <Button
            className="uploadBtn"
            id="fbs-upload-btn"
            disabled={fileList.length >= 5}
          >
            <PlusOutlined />
          </Button>
        </Upload>
      </div>
      <Modal
        visible={previewOpen}
        title={previewTitle}
        footer={null}
        maskClosable={false}
        onCancel={handleCancel}
        className="previewModal noTitle"
      >
        <div className="previewmodalBody">
          {fileList.length - 1 !== currentIndex && (
            <ArrowLeftOutlined onClick={leftarrowClick} />
          )}
          {previewImage && (
            <img alt="example" className="img-responsive" src={previewImage} />
          )}
          {currentIndex !== 0 && (
            <ArrowRightOutlined onClick={rightarrowClick} />
          )}
        </div>
      </Modal>
    </>
  );
};

export default memo(UploadWidget);
