/* eslint-disable no-console */
import { Button, Modal, notification } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import loading_logo from '../../assets/images/loading_logo.gif';
import { acceptTermsAndCond, getTermsAndCond } from '../../constants/Api';
import { fleetListTabs } from '../../constants/Common';
import { ComplainCode } from '../../constants/types';
import { ApplicationContext } from '../../context/AppContext';
import AppLoader from '../common/AppLoader';
import TextArea from '../common/TextArea';
import {
  SET_SELECTED_OPENED_FLEET_TAB,
  SET_SHOW_CONNECT_LOADER,
} from '../remote_drive_new/actions';
import { RemoteDriveAppCtx } from '../remote_drive_new/RemoteDriveContext';
import logoHorizontalWhite from './../../assets/images/tc_m_logo.svg';
import './style.css';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

interface Props {
  handleCallback: (flag: boolean) => void;
}
export const TermsCond: React.FC<Props> = ({ handleCallback }: Props) => {
  const { userDetails } = useContext(ApplicationContext);
  const { RDReducer, selectedTractorsIds } = useContext(RemoteDriveAppCtx);
  const [state, dispatch] = RDReducer;
  const { selectedTractor, showConnectLoader } = state;
  const [numPages, setNumPages] = useState(0);
  const [loader, setLoader] = useState(false);
  const [complainCode, setComplainCode] = useState<ComplainCode | null>(null);
  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }, []);
  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  const closeModel = () => {
    handleCallback(false);
  };

  useEffect(() => {
    if (userDetails && userDetails.organization) getTermsAndCondHandler();
    return () => {
      setComplainCode(null);
    };
  }, [userDetails]);

  const getTermsAndCondHandler = async () => {
    setLoader(true);

    try {
      const data: ComplainCode = await getTermsAndCond(
        userDetails.organization.api_url,
        userDetails.organization_id,
      );
      data && data.id && setComplainCode(data);
    } catch (error: any) {
      notification.error({
        message: error.message | error.message,
      });
    } finally {
      setLoader(false);
    }
  };

  const acceptTermsAndCondHandler = async (
    is_compliance_acknowledged: boolean,
    callApi = true,
  ) => {
    dispatch({
      type: SET_SHOW_CONNECT_LOADER,
      payload: true,
    });
    setLoader(true);
    try {
      const payload = {
        compliance_id: complainCode?.id,
        compliance_code: complainCode?.compliance_code,
        compliance_version: complainCode?.version,
        app_name: 'web',
        is_compliance_acknowledged,
      };
      is_compliance_acknowledged &&
        dispatch({
          type: SET_SELECTED_OPENED_FLEET_TAB,
          payload: fleetListTabs.fleetListAutoDriveList,
        });
      callApi &&
        (await acceptTermsAndCond(
          userDetails.organization.api_url,
          userDetails.organization_id,
          userDetails.id,
          payload,
        ));
      handleCallback(is_compliance_acknowledged);
      !is_compliance_acknowledged &&
        dispatch({
          type: SET_SHOW_CONNECT_LOADER,
          payload: false,
        });
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    } finally {
      setLoader(false);
    }
  };

  return (
    <>
      {complainCode &&
        complainCode.content_type &&
        complainCode.id &&
        complainCode.content_type === 'pdf' && (
          <Modal
            className="modalContainer profileUpdatePopup termspopup AD_TermsPopupPDF"
            title={
              <div className="term-header">
                <img src={logoHorizontalWhite} />
                <div className="term-head-title headline1 fBld">
                  Terms and Conditions
                </div>
              </div>
            }
            visible={true}
            onCancel={closeModel}
            width={1200}
            closable={false}
            maskClosable={false}
            footer={[
              <>
                <Button
                  key="submit"
                  className="tcBtns tcDecline "
                  onClick={() => {
                    handleCallback(false);
                    acceptTermsAndCondHandler(false);
                  }}
                >
                  DECLINE
                </Button>
                <Button
                  key="back"
                  className="tcBtns tcAccept "
                  onClick={() => acceptTermsAndCondHandler(true)}
                >
                  ACCEPT
                </Button>
              </>,
            ]}
          >
            <div className="modalBox flexnone">
              <div className="formColW">
                <div className="userIcon" key={complainCode?.id}>
                  <Document
                    file={complainCode?.file_path}
                    options={{ workerSrc: '/pdf.worker.js' }}
                    onLoadSuccess={onDocumentLoadSuccess}
                    className="pdf-document"
                  >
                    {Array.from(new Array(numPages), (el, index) => (
                      <Page
                        key={`page_${index + 1}`}
                        className="pdf-page"
                        pageNumber={index + 1}
                        scale={1.5}
                      />
                    ))}
                  </Document>
                </div>
              </div>
              {loader && (
                <div className="edit-loader">
                  <div className="loader">
                    <img
                      style={{ width: '40px', height: '40px' }}
                      src={loading_logo}
                    />
                  </div>
                </div>
              )}
            </div>
          </Modal>
        )}
      {((complainCode &&
        complainCode.id &&
        complainCode.content_type &&
        complainCode.content_type === 'text') ||
        !(complainCode && complainCode.id)) && (
        <Modal
          className="modalContainer profileUpdatePopup termspopup AD_TermsPopupTEXT"
          title={
            <div className="term-header2">
              <img src={logoHorizontalWhite} height="52" />
              <div className="term-head-title2 headline1 fBld">
                Terms and Conditions
              </div>
            </div>
          }
          visible={true}
          onCancel={closeModel}
          width={1200}
          closable={false}
          maskClosable={false}
          footer={[
            <>
              <Button
                key="submit"
                className="tcBtns2 tcDecline2 Button"
                onClick={() => {
                  handleCallback(false);
                  acceptTermsAndCondHandler(
                    false,
                    complainCode && complainCode.id ? true : false,
                  );
                }}
              >
                DECLINE
              </Button>
              <Button
                key="back"
                className="tcBtns2 tcAccept2 Button"
                onClick={() =>
                  acceptTermsAndCondHandler(
                    true,
                    complainCode && complainCode.id ? true : false,
                  )
                }
              >
                ACCEPT
              </Button>
            </>,
          ]}
        >
          <div className="modalBox flexnone">
            <div className="formColW">
              <div className="userIcon" key={complainCode?.id}>
                <div className="AD_term_content body1">
                  {/* {complainCode?.description} */}
                  <TextArea
                    description={
                      complainCode && complainCode.id
                        ? complainCode?.description
                        : ''
                    }
                    // description={`<p>${complainCode?.description}</p>`}
                    readOnly
                    hideToolbar
                  />
                </div>
              </div>
            </div>
            <AppLoader loader={loader} />
            <AppLoader
              loader={
                selectedTractor &&
                selectedTractor.id &&
                selectedTractorsIds.includes(selectedTractor.id) &&
                showConnectLoader
                  ? true
                  : false
              }
            />
          </div>
        </Modal>
      )}
    </>
  );
};

export default TermsCond;
