import React from 'react';
import { Row, Col } from 'antd';
import tractorImage from './images/tractor-pic.png';

const SearchDefault = () => {
  return (
    <Row gutter={[0, 40]}>
      <Col
        xs={{ span: 24, offset: 0 }}
        sm={{ span: 24, offset: 0 }}
        md={{ span: 24, offset: 0 }}
        lg={{ span: 24, offset: 0 }}
        xl={{ span: 24, offset: 0 }}
      >
        <div className="search-empty">
          <div className="no-results-sec">
            <div className="no-result-box">
              <img src={tractorImage} alt="text" />
              {/* *dreamy tractor + <br />
              data graphic goes <br /> here*{' '} */}
            </div>
            <div className="no-result-tagline">
              Explore your farm’s activities and harness the power of a
              data-driven fleet
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default SearchDefault;
