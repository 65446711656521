import {
  LOAD_DRIVING_TRACTORS,
  LOAD_RUNNING_TRACTORS,
  SET_PAUSE_RESUME_LOADER,
  SET_REMAINING_TIME,
  SET_RESET_TIMER,
  SET_SHOW_IMPLEMENT,
  SET_SHOW_LOSE_CONNECTION,
  SET_TIME_LEFT,
  SET_TOGGLE_TIMER,
  SET_VELOCITY,
  SHOW_SIDE_FEED_BACK,
} from '../components/remote_drive_new/actions';
import { TractorHeartBeat } from '../constants/types';
import {
  AUTO_DRIVE_LOGOUT_CONFIRM,
  EXECUTE,
  LOAD_REMOTE_DRIVE_TRACTORS,
  SET_AUTO_DRIVE_TRACTOR_LOST_INTERRUPT_MESSAGES,
  SET_PINNED,
  SET_TRACTOR_IMPLEMENT_STATE,
} from './actions';

interface InitialStateType {
  title: string;
  tractorImplementState: any;
  isPinned: boolean;
  remoteAvTractors: any[];
  autoDriveLogoutConfirm: boolean;
  drivingTractors: TractorHeartBeat[];
  runningTractors: TractorHeartBeat[];
  toggleTimer: { status: boolean; from: 'Click' | 'Event' };
  resetTimer: boolean;
  timeLeft: number;
  showImplement: boolean;
  showLoseConnection: boolean;
  remainingTime: number;
  velocity: number;
  pauseResumeLoader: boolean;
  autoDriveTractorLostInterruptMsg: any;
  showSideFeedBack: any;
}
interface Action {
  type: string;
  payload: any;
}
export const initialState: InitialStateType = {
  title: 'AutoDriveStore',
  tractorImplementState: {},
  isPinned: false,
  remoteAvTractors: [],
  autoDriveLogoutConfirm: false,
  drivingTractors: [],
  runningTractors: [],
  toggleTimer: { status: true, from: 'Event' },
  resetTimer: false,
  timeLeft: 60 * 10,
  showImplement: false,
  showLoseConnection: false,
  remainingTime: 0,
  velocity: 0,
  pauseResumeLoader: false,
  autoDriveTractorLostInterruptMsg: null,
  showSideFeedBack: {},
};

export default function reducer(state: InitialStateType, action: Action) {
  const { type, payload } = action;
  switch (type) {
    case SET_TRACTOR_IMPLEMENT_STATE:
      return {
        ...state,
        tractorImplementState: { ...payload },
      };
    case SET_PINNED:
      return { ...state, isPinned: payload };
    case EXECUTE:
      return { ...state, ...payload };
    case LOAD_REMOTE_DRIVE_TRACTORS:
      return { ...state, remoteAvTractors: payload };
    case AUTO_DRIVE_LOGOUT_CONFIRM:
      return { ...state, autoDriveLogoutConfirm: payload };
    case LOAD_DRIVING_TRACTORS:
      return { ...state, drivingTractors: payload };
    case LOAD_RUNNING_TRACTORS:
      return { ...state, runningTractors: payload };
    case SET_TOGGLE_TIMER:
      return { ...state, toggleTimer: payload };
    case SET_RESET_TIMER:
      return { ...state, resetTimer: payload };
    case SET_TIME_LEFT:
      return { ...state, timeLeft: payload };
    case SET_SHOW_IMPLEMENT:
      return { ...state, showImplement: payload };
    case SET_SHOW_LOSE_CONNECTION:
      return { ...state, showLoseConnection: payload };
    case SET_REMAINING_TIME:
      return { ...state, remainingTime: payload };
    case SET_VELOCITY:
      return { ...state, velocity: payload };
    case SET_PAUSE_RESUME_LOADER:
      return { ...state, pauseResumeLoader: payload };
    case SET_AUTO_DRIVE_TRACTOR_LOST_INTERRUPT_MESSAGES:
      return { ...state, autoDriveTractorLostInterruptMsg: payload };
    case SHOW_SIDE_FEED_BACK:
      return { ...state, showSideFeedBack: payload };
    default:
      // eslint-disable-next-line no-console
      console.log(action);
      throw new Error();
  }
}
