import React from 'react';
import Layout from 'antd/lib/layout/layout';
import { useLocation } from 'react-router-dom';

const VideoPop: React.FC = () => {
  const { search } = useLocation();
  const videoUrl = search.slice(search.indexOf('=') + 1);
  return (
    <>
      <Layout>
        <div className="snapshotW">
          <h3>
            <div>Video</div>
          </h3>
          <video
            style={{ width: '100%', height: '930px' }}
            // src={window.location.search.substring(1).split('=')[1]}
            src={videoUrl}
            controls
          ></video>
        </div>
      </Layout>
    </>
  );
};

export default VideoPop;
