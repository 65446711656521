import React, { useContext, useEffect, useState } from 'react';
import { notification, Row } from 'antd';
import Layout from 'antd/lib/layout/layout';
import { useTranslation } from 'react-i18next';

import {
  getBaseStationDiagnosticsList,
  getBasestationOTALogs,
} from '../../constants/Api';

import { ApplicationContext } from '../../context/AppContext';

import InfiniteScrollTable from '../common/InfiniteScrollTable';
import CustomSelect from '../common/CustomSelect';
import translate from '../../locale/en_translate.json';
import { getDateTimes } from '../../constants/Common';
import LogHistory from './LogHistory';
import usePaginate from '../../hooks/usePaginate';
import CustomSelect1 from '../common/CustomSelect1';

const BasestationOTALog: React.FC = () => {
  const { t } = useTranslation();
  const { user } = useContext(ApplicationContext);
  const [loader, setLoader] = useState<boolean>(false);
  const [basestationId, setBasestationId] = useState<number>();
  const [basestations, setBasestations] = useState<any[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize] = useState<number>(25);
  const [totalCount, setTotalCount] = useState<any>();
  const [selectedOTA, setSelectedOTA] = useState<any>(null);
  const [status, setStatus] = useState<string>('');
  const { filterData, setData, activity, hasMore } = usePaginate();

  const statusFilterData = [
    { name: 'Failed', value: 'Failed' },
    { name: 'In Progress', value: 'Inprogress' },
    { name: 'Pending', value: 'pending' },
    { name: 'Success', value: 'Success' },
    { name: 'Rollback', value: 'Rollback' },
  ];

  useEffect(() => {
    const init = async () => {
      if (!user) return;
      const { organization } = user;
      const { api_url, id } = organization;
      try {
        const data = await getBaseStationDiagnosticsList(api_url, id);
        const baseData = data.map((bs: any) => {
          return {
            label: bs.name,
            value: bs.basestation_id,
          };
        });
        setBasestations(baseData);
      } catch (error: any) {
        notification.error(error.message);
      }
    };
    if (user) init();
  }, [user]);

  useEffect(() => {
    if (basestationId) fetchBasestationOTALogs();
  }, [user, pageNumber, basestationId, status]);

  const fetchBasestationOTALogs = async () => {
    if (!user) return;
    const { organization } = user;
    const { api_url, fleet } = organization;
    try {
      setLoader(true);
      const response = await getBasestationOTALogs(
        api_url,
        fleet.id,
        basestationId || 0,
        pageNumber,
        pageSize,
        status,
      );
      setTotalCount(response._metadata.total_records_count);
      const { records } = response;
      let data = Array.isArray(records) ? records : [];

      data = data.map((item) =>
        Object.assign({
          id: item.id,
          basestationId: item.basestation_id,
          name: item.basestation.name,
          softwareVersion: item.software_version,
          releaseNotes: item.release_notes,
          status: item.status,
          created_date_time: item.created_date_time
            ? getDateTimes(item.created_date_time)
            : '-',
          completion: item?.ota_percentage ? `${item.ota_percentage} %` : '-',
        }),
      );
      setData(data);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    } finally {
      setLoader(false);
    }
  };

  const columns = [
    {
      title: `${t(translate.headers.name)}`,
      dataIndex: 'name',
      key: 'name',
      width: '14%',
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
    },
    {
      title: `${t(translate.fleet.softwareVersion)}`,
      dataIndex: 'softwareVersion',
      key: 'softwareVersion',
      width: '21%',
      sorter: (a: any, b: any) =>
        a.softwareVersion.localeCompare(b.softwareVersion),
    },
    {
      title: `${t(translate.fleetticketsHistory.createdbydate)}`,
      dataIndex: 'created_date_time',
      key: 'created_date_time',
      width: '16%',
    },
    {
      title: `${t(translate.softwarehistorylogs.releaseNotes)}`,
      dataIndex: 'releaseNotes',
      key: 'releaseNotes',
    },
    {
      title: `${t(translate.label.status)}`,
      dataIndex: 'status',
      key: 'status',
      width: '12%',
      sorter: (a: any, b: any) => a.status.localeCompare(b.status),
    },
    {
      title: `${t(translate.bsOtaLogs.completion)}`,
      dataIndex: 'completion',
      key: 'completion',
      width: '12%',
    },
  ];

  const handleLoadMore = () => {
    setPageNumber(pageNumber + 1);
    activity.current = 'paginate';
  };

  const handleRefresh = () => {
    activity.current = 'refresh';
    if (pageNumber !== 1) setPageNumber(1);
    else fetchBasestationOTALogs();
  };

  const handleSelect = async (e: any) => {
    setBasestationId(e);
    setPageNumber(1);
    activity.current = 'refresh';
  };

  return (
    <Layout>
      <div className="mainContent">
        <div className="tblContainer viewportContainer">
          <Row>
            <div className="common_wrapper wWidget_wrapper">
              <div className="filters_card personnelCard">
                <CustomSelect
                  label="Basestation"
                  cssClass="min_width"
                  value={basestationId ?? undefined}
                  setValue={(id: any) => {
                    handleSelect(id);
                  }}
                  options={basestations.map((item) =>
                    Object.assign({
                      id: item.value,
                      name: item.label,
                    }),
                  )}
                />
                <CustomSelect1
                  label="Status"
                  cssClass="min_width"
                  value={status}
                  setValue={(id: any) => {
                    setStatus(id);
                    activity.current = 'refresh';
                  }}
                  options={statusFilterData}
                  optionKey="value"
                  isAll={true}
                />
              </div>
              <div className="common_table">
                <InfiniteScrollTable
                  columns={columns}
                  loading={loader}
                  hasMore={hasMore}
                  filterData={filterData}
                  totalcount={totalCount}
                  handleLoadMore={handleLoadMore}
                  filename="OtaLogs"
                  onRowClick={(record: any) => {
                    setSelectedOTA(record);
                  }}
                />
              </div>
              <div>
                {selectedOTA && (
                  <LogHistory
                    data={selectedOTA}
                    onClose={() => {
                      setSelectedOTA(null);
                      handleRefresh();
                    }}
                  />
                )}
              </div>
            </div>
          </Row>
        </div>
      </div>
    </Layout>
  );
};

export default BasestationOTALog;
