/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-empty-function */
// eslint-disable-next-line object-curly-newline
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Input, Layout, notification, Row, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import translate from '../../../locale/en_translate.json';
import { ApplicationContext } from '../../../context/AppContext';
import CustomMultiSelectMode from '../common/CustomMultiSelectMode';
import usePaginate from '../../../hooks/usePaginate';
import { initScroller } from '../../../constants/Common';
import { SearchOutlined } from '@ant-design/icons';
import TableExt from '../../widget/TableExt';
import InfiniteScrollTable from '../../common/InfiniteScrollTable';
import { removeSpecialChars } from '../common/common';
import { getErrorCodesData, getIndicatorsDropdown } from '../API';
import { Content } from 'antd/lib/layout/layout';

let columns: any[] = [];
const title = 'Diagnostic Codes';

const DiagnosticCodes: React.FC = () => {
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const [loader, setLoader] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [selectecIndicators, setSelectedIndicators] = useState<any>([]);
  const [indicators, setIndicators] = useState<any[]>([]);
  const [headers, setHeaders] = useState<any[]>([]);
  const searchString = useRef<string>('');
  // eslint-disable-next-line object-curly-newline, operator-linebreak
  const { filterData, setData, activity, hasMore, checkActivity } =
    usePaginate();
  const [total, setTotal] = useState<number>(0);
  const tableRef = useRef<any>(null);
  const [toggleColumnsStatus, setToggleColumnsStatus] =
    useState<boolean>(false);

  useEffect(() => {
    const getIndicators = async () => {
      try {
        setLoader(true);
        const records = await getIndicatorsDropdown();
        if (Array.isArray(records)) {
          // eslint-disable-next-line @typescript-eslint/no-shadow
          const data = records
            .filter(({ indicator }: any) => indicator)
            .map(({ indicator, indicator_key }: any) => ({
              value: indicator_key,
              label: indicator,
            }));
          setIndicators(data);
        }
      } catch (err: any) {
        notification.error({
          message: err.message,
        });
      } finally {
        setLoader(false);
      }
    };

    if (userDetails && userDetails.organization) {
      getIndicators();
    }
  }, [userDetails]);

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const wrapResult = async (indicator: string, search: string) => {
    const result = await getErrorCodesData(indicator, search);
    return {
      result,
      searchKey: search,
    };
  };

  const getErrorCodes = async () => {
    try {
      if (checkActivity()) initScroller();
      const indicator = selectecIndicators ? selectecIndicators.join(',') : '';
      setLoader(true);
      const { result, searchKey } = await wrapResult(
        indicator,
        removeSpecialChars(search),
      );
      if (searchString.current !== searchKey) return;

      const data = Array.isArray(result) ? result : [];
      setTotal(data?.length || 0);
      setData(data);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    } finally {
      setLoader(false);
    }
  };

  const handleRefresh = () => {
    activity.current = 'refresh';
    getErrorCodes();
  };

  const handleSearch = (e: any) => {
    activity.current = 'search';
    const value = e.target.value.trim();
    if (value.length <= 1) {
      searchString.current = '';
      setSearch('');
    } else {
      searchString.current = value;
      setSearch(value);
    }
  };

  useEffect(() => {
    columns = [
      {
        title: `${t(translate.guardrails.type)}`,
        dataIndex: 'indicator',
        width: '120px',
      },
      {
        title: `${t(translate.label.errorCodes)}`,
        dataIndex: 'indicator_error_code',
        width: '120px',
      },
      {
        title: `${t(translate.myTask.subSystem)}`,
        dataIndex: 'subsystem',
        width: '160px',
      },
      {
        title: `${t(translate.myTask.description)}`,
        dataIndex: 'description',
        width: '180px',
      },
      {
        title: `${t(translate.myTask.counterMeasure)}`,
        dataIndex: 'counter_measure',
        width: '250px',
      },
    ];

    const header: any = [];
    if (columns) {
      columns.forEach(
        (column: any) =>
          // eslint-disable-next-line implicit-arrow-linebreak
          header.push({
            label: column.title,
            key: column.dataIndex,
          }),
        // eslint-disable-next-line function-paren-newline
      );
      setHeaders(header);
    }
  }, []);

  const handleSelect = (e: any) => {
    setSelectedIndicators(e);
  };

  useEffect(() => {
    if (userDetails?.organization) getErrorCodes();
  }, [userDetails, selectecIndicators, search]);

  return (
    <>
      <Layout onClick={() => setToggleColumnsStatus(!toggleColumnsStatus)}>
        <Content>
          <div className="tblContainer viewportContainer">
            <Row>
              <div className="common_wrapper tab2FilterWidget_wrapper">
                <div className="filters_card ad_filters_card filtersHealthScreen">
                  <div className="displayFlex">
                    <Space className="large">
                      <Input
                        addonBefore={<SearchOutlined />}
                        placeholder="Search Directory"
                        className="common_search"
                        onChange={(e) => handleSearch(e)}
                        autoComplete="off"
                      />
                    </Space>
                    <CustomMultiSelectMode
                      label="Indicators"
                      cssClass="min_width"
                      value={selectecIndicators}
                      setValue={(id: any) => {
                        handleSelect(id);
                      }}
                      options={indicators}
                      optionKey="value"
                      optionDisplay="label"
                    />
                  </div>
                  <TableExt
                    handleRefresh={handleRefresh}
                    csvHeaders={headers}
                    csvData={filterData}
                    csvFilename={title}
                    toggleColumnsStatus={toggleColumnsStatus}
                  />
                </div>

                <div className="common_table">
                  <InfiniteScrollTable
                    columns={columns}
                    loading={loader}
                    hasMore={hasMore}
                    filterData={filterData}
                    totalcount={total}
                    handleLoadMore={() => {}}
                    filename="Diagnostic Codes"
                  />
                </div>
              </div>
            </Row>
          </div>
        </Content>
      </Layout>
    </>
  );
};

export default DiagnosticCodes;
