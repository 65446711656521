/* eslint-disable react/jsx-key */
import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, notification } from 'antd';

import { ApplicationContext } from '../../context/AppContext';
import { getCategories } from './services/API';
import { getImagebyCode } from './services/Common';
import AppLoader from '../common/AppLoader';
import { useHelpContext } from './HelpCenterContext';

interface Props {
  colProps: any;
  handleCategory: (catObj: any) => void;
}

const Topics: React.FC<Props> = ({ colProps, handleCategory }) => {
  const { userDetails } = useContext(ApplicationContext);
  const [categories, setCategories] = useState<any[]>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const { HCReducer } = useHelpContext();
  const [, dispatch] = HCReducer;

  const fetchCategories = async () => {
    try {
      setLoader(true);
      const { organization } = userDetails;
      const { api_url } = organization;
      const result = await getCategories(api_url, 1);
      const data = Array.isArray(result?.data) ? result?.data : [];
      setCategories(data);
      dispatch({
        type: 'CATEGORIES',
        payload: data,
      });
    } catch (error: any) {
      notification.error({ message: error.message });
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (userDetails?.organization) fetchCategories();
  }, [userDetails]);

  return (
    <>
      {' '}
      <h1 className="hc_cat_heads">Topics</h1>
      <Row gutter={[20, 16]}>
        {categories &&
          categories
            .filter(({ category_code }) => category_code !== 1005)
            .map((ele: any) => (
              <Col {...colProps} key={ele.id}>
                <div className="hc_cat_card">
                  <img src={getImagebyCode(ele.category_code)} alt="" />
                  <div onClick={() => handleCategory(ele)}>
                    <h2 className="cat_card_head">{ele?.title}</h2>
                    <p className="cat_card_desc">{ele?.description}</p>
                  </div>
                </div>
              </Col>
            ))}
      </Row>
      <AppLoader loader={loader} />
    </>
  );
};

export default Topics;
