/* eslint-disable no-console */
import { Modal, notification, Select, DatePicker } from 'antd';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { getVineRowJumpAndGoData } from '../../constants/Api';
import { getDateAndTime } from '../../constants/Common';
import { DrawItem, TractorsList, VineRowPayload } from '../../constants/types';
import { ApplicationContext } from '../../context/AppContext';
import { getJumpandGo } from '../analytics/util';

import './style.css';

const { RangePicker } = DatePicker;
interface Props {
  showModal: boolean;
  handleCancel: (show?: boolean) => void;
  vineRowPayload?: VineRowPayload;
  selectedJumpAndGoForVineRow: string[];
  setSelectedJumpAndGoForVineRow: (
    selectedJumpAndGoForVineRow: string[],
  ) => void;
  selectedTractor: string;
  setSelectedTractor: (selectedTractor: string) => void;
  tractors: TractorsList[];
  vineRowModal: DrawItem['vineRowModal'];
}
export const VineRowJumpAndGoModel: React.FC<Props> = ({
  showModal,
  handleCancel,
  setSelectedJumpAndGoForVineRow,
  selectedJumpAndGoForVineRow,
  selectedTractor,
  setSelectedTractor,
  tractors,
  vineRowModal,
}: Props) => {
  const { userDetails } = useContext(ApplicationContext);
  const [jumpAndGos, setJumpAndGos] = useState([]);

  const [selectedDateRange, setSelectedDateRange] = useState<Date[]>([
    moment().subtract(1, 'day').toDate(),
    moment().toDate(),
  ]);
  useEffect(() => {
    selectedTractor &&
      userDetails &&
      userDetails.organization &&
      getJumpAndGoListData();
  }, [selectedTractor, selectedDateRange]);

  const getJumpAndGoListData = async () => {
    setSelectedJumpAndGoForVineRow([]);
    try {
      const { organization } = userDetails;
      const [date1, date2] = selectedDateRange;
      const response =
        vineRowModal === 6
          ? await getJumpandGo(
              userDetails,
              moment(date1).startOf('day').toDate().getTime(),
              moment(date2).endOf('day').toDate().getTime(),
              Number(selectedTractor),
            )
          : await getVineRowJumpAndGoData(
              organization.api_url,
              organization.id,
              Number(selectedTractor),
              moment(date1).startOf('day').toDate().getTime(),
              moment(date2).endOf('day').toDate().getTime(),
            );
      const records = vineRowModal === 6 ? response.records : response;
      const data = records && records.length > 0 ? records : [];
      const jaData = data.map((d: any) => {
        return {
          label: `${getDateAndTime(
            Number(d?.drive_action_finish_date_time),
          )}  - (${d?.operator?.first_name} ${d?.operator?.last_name})`,
          value: d.drive_action_uuid,
          disabled: false,
          index: d.drive_action_uuid,
        };
      });
      setSelectedJumpAndGoForVineRow(jaData);
      setJumpAndGos(jaData);
    } catch (error: any) {
      notification.error({
        message: error?.message,
      });
    }
  };

  const handleSelectDateRange = (dates: any) => {
    const [date1, date2] = dates;
    if (date1 && date2) {
      moment(date1).toDate().getTime() < moment(date2).toDate().getTime() &&
        setSelectedDateRange([date1, date2]);
      // date1.getTime() < date2.getTime() && setSelectedDateRange([date1, date2]);
    } else {
      setSelectedDateRange([date1, date2]);
    }
  };

  const disabledDate = (current: any) => {
    return current && current > moment();
  };

  return (
    <Modal
      title="Select Jump And Go"
      visible={showModal}
      closable={false}
      className="commonPopup baseModalContainer jumpgoModal"
      onOk={() => handleCancel(true)}
      onCancel={() => handleCancel()}
      okButtonProps={{
        className: 'okBtn',
      }}
      cancelButtonProps={{
        className: 'canelBtn',
      }}
    >
      <div className="formRow">
        <div className="formCol">
          <label className="formLabel">
            Tractors <span style={{ color: 'red' }}> *</span>
          </label>
          <Select
            className="customSelect"
            placeholder="Select a tractor"
            style={{ width: '100%' }}
            value={selectedTractor}
            onChange={(id: string) => {
              setJumpAndGos([]);
              setSelectedTractor(id);
            }}
          >
            {tractors.map((data: any) => {
              if (data.label !== '') {
                return (
                  <option key={data.index} value={data.index}>
                    {data.label}
                  </option>
                );
              }
            })}
          </Select>
        </div>
        <div className="formCol">
          <label className="formLabel">
            Start Date - End Date <span style={{ color: 'red' }}> *</span>
          </label>
          <RangePicker
            className="datePickerSel"
            format="YYYY-MM-DD"
            onChange={handleSelectDateRange}
            data-testid="dateSelect-Snapshot"
            disabledDate={disabledDate}
            defaultValue={[
              moment(selectedDateRange[0]),
              moment(selectedDateRange[1]),
            ]}
          />
        </div>
      </div>
      <div className="formRow">
        <div className="formCol">
          <label className="formLabel">
            Jump And Go <span style={{ color: 'red' }}> *</span>
          </label>
          <Select
            className="customSelect"
            placeholder="Jump And Go"
            style={{ width: '100%' }}
            value={selectedJumpAndGoForVineRow}
            onChange={setSelectedJumpAndGoForVineRow}
            mode="multiple"
            labelInValue
          >
            {jumpAndGos.map((data: any) => {
              if (data.label !== '') {
                return (
                  <option key={data.index} value={data.index}>
                    {data.label}
                  </option>
                );
              }
            })}
          </Select>
        </div>
        <div className="formCol"></div>
      </div>
      {/* <div style={{ marginBottom: 6 }}></div>
      <div className="formRow betaRow">
        <div className="formCol betaCol">
          <div className="ant-row ant-form-item">
            <div className="ant-col ant-form-item-label">
              <label>
                Tractors <span style={{ color: 'red' }}> *</span>
              </label>
            </div>
          </div>
          <div className="ant-col ant-form-item-control">
            <div className="ant-form-item-control-input">
              <div className="ant-form-item-control-input-content input-align">
                <Select
                  placeholder="Select a tractor"
                  style={{ width: '100%' }}
                  value={selectedTractor}
                  onChange={(id: string) => {
                    setJumpAndGos([]);
                    setSelectedtractor(id);
                  }}
                >
                  {tractors.map((data: any) => {
                    if (data.label !== '') {
                      return (
                        <option key={data.index} value={data.index}>
                          {data.label}
                        </option>
                      );
                    }
                  })}
                </Select>
              </div>
            </div>
          </div>
        </div>
        <div className="formCol betaCol">
          <div className="ant-row ant-form-item">
            <div className="ant-col ant-form-item-label">
              <label>
                Start Date - End Date <span style={{ color: 'red' }}> *</span>
              </label>
            </div>
          </div>
          <div className="ant-col ant-form-item-control">
            <div className="ant-form-item-control-input">
              <div className="ant-form-item-control-input-content input-align">
                <RangePicker
                  format="YYYY-MM-DD"
                  onChange={handleSelectDateRange}
                  className="dropdownStyle dW250_date dateFilter dpickerWidth"
                  data-testid="dateSelect-Snapshot"
                  disabledDate={disabledDate}
                  defaultValue={[
                    moment(selectedDateRange[0]),
                    moment(selectedDateRange[1]),
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* <div className="formRow betaRow">
        <div className="formCol betaCol">
          <div className="ant-row ant-form-item">
            <div className="ant-col ant-form-item-label">
              <label>
                Start Date <span style={{ color: 'red' }}> *</span>
              </label>
            </div>
          </div>
          <div className="ant-col ant-form-item-control">
            <div className="ant-form-item-control-input">
              <div className="ant-form-item-control-input-content input-align">
                <div className="jumpAndGoList tsDatePic">
                  <ReactDatePicker
                    isClearable
                    maxDate={new Date()}
                    selected={selectedDateRange[0]}
                    placeholderText="Select Start Date"
                    dropdownMode="select"
                    dateFormat="MM-dd-yyyy HH:mm"
                    showTimeSelect
                    onChange={(date: any) => {
                      handleSelectDateRange([date, selectedDateRange[1]]);
                    }}
                    className="dpicker dpickerWidth"
                    timeIntervals={5}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="formCol betaCol">
          <div className="ant-row ant-form-item">
            <div className="ant-col ant-form-item-label">
              <label>
                End Date <span style={{ color: 'red' }}> *</span>
              </label>
            </div>
          </div>
          <div className="ant-col ant-form-item-control">
            <div className="ant-form-item-control-input">
              <div className="ant-form-item-control-input-content input-align">
                <div className="jumpAndGoList tsDatePic">
                  <ReactDatePicker
                    isClearable
                    maxDate={new Date()}
                    minDate={selectedDateRange[0]}
                    selected={selectedDateRange[1]}
                    placeholderText="Select End Date"
                    dropdownMode="select"
                    dateFormat="MM-dd-yyyy HH:mm"
                    showTimeSelect
                    onChange={(date: any) => {
                      handleSelectDateRange([selectedDateRange[0], date]);
                    }}
                    className="dpicker dpickerWidth"
                    timeIntervals={5}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className="formRow betaRow">
        <div className="formCol betaCol">
          <div className="ant-row ant-form-item">
            <div className="ant-col ant-form-item-label">
              <label>
                Jump And Go <span style={{ color: 'red' }}> *</span>
              </label>
            </div>
          </div>
          <div className="ant-col ant-form-item-control">
            <div className="ant-form-item-control-input">
              <div className="ant-form-item-control-input-content input-align">
                <Select
                  placeholder="Jump And Go"
                  style={{ width: '100%' }}
                  value={selectedJumpAndGoForVineRow}
                  onChange={setSelectedJumpAndGoForVineRow}
                  mode="multiple"
                  labelInValue
                >
                  {jumpAndGos.map((data: any) => {
                    if (data.label !== '') {
                      return (
                        <option key={data.index} value={data.index}>
                          {data.label}
                        </option>
                      );
                    }
                  })}
                </Select>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </Modal>
  );
};
export default VineRowJumpAndGoModel;
