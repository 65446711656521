import React, { useContext, useEffect, useRef, useState } from 'react';
import { getAutodriveCsvReport, getAutodriveReport, getOrgsList } from '../API';
import { Button, DatePicker, message, notification, Row, Table } from 'antd';
import loading_logo from '../../../assets/images/loading_logo.gif';
import Layout, { Content } from 'antd/lib/layout/layout';
import moment from 'moment';
import { ApplicationContext } from '../../../context/AppContext';
import { delay, downloadURI } from '../../../constants/Common';
import TableExt from '../../widget/TableExt';
import CropOrgFilter from '../../common/CropOrgFilter';
import translate from '../../../locale/en_translate.json';
import { useTranslation } from 'react-i18next';

const AutoDriveReport = () => {
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const [toggleColumnsStatus, setToggleColumnsStatus] =
    useState<boolean>(false);
  const tableRef = useRef<any>(null);
  const [autoDriveData, setAutoDriveAuto] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [filterObj, setFilterObj] = useState({
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    type: 'Monthly',
  });
  const [total, setTotal] = useState<any>(0);
  const [selectedOrgId, setSelectedOrgId] = useState<any[]>([]);
  const [downloadData, setDownloadData] = useState({
    isDownloaded: false,
    percent: 0,
    status: '',
  });
  const init = useRef<boolean>(true);
  const [organizations, setOrganizations] = useState<any>([]);

  useEffect(() => {
    const initData = async () => {
      try {
        const records = await getOrgsList();
        const list =
          records && records.length && records.length > 0 ? records : [];

        setOrganizations([...list]);
        init.current = true;
      } catch (error: any) {
        notification.error({
          message: error.message,
          duration: 1,
        });
      }
    };
    if (userDetails?.organization) initData();
  }, [userDetails]);

  useEffect(() => {
    if (
      userDetails?.organization &&
      init.current &&
      organizations &&
      selectedOrgId.length
    )
      getAutodrive();
  }, [userDetails, organizations, selectedOrgId]);

  const getAutodrive = async () => {
    if (userDetails) {
      try {
        setLoading(true);
        const data: any = await getAutodriveReport(
          filterObj.month,
          filterObj.year,
          selectedOrgId,
          organizations?.length === selectedOrgId?.length,
        );
        if (data) {
          setAutoDriveAuto(data?.auto_drive_data);
          setTotal(data?.auto_drive_data?.length);
        } else {
          setAutoDriveAuto([]);
          setTotal(0);
        }
      } finally {
        setLoading(false);
        init.current = false;
      }
    }
  };

  const onMonthly = (date: any) => {
    if (date) {
      const year = date.format('YYYY');
      const month = date.format('MM');
      setFilterObj({
        ...filterObj,
        year: Number(year),
        month: Number(month),
        type: 'Monthly',
      });
    }
  };

  const columns: any = [
    {
      title: 'Customer',
      dataIndex: 'customer_name',
      key: 'customer_name',
      width: 200,
      fixed: 'left',
      headerBg: 'red',
      sorter: {
        compare: (a: any, b: any) =>
          a.customer_name.localeCompare(b.customer_name),
      },
    },
    {
      title: 'Number of Tractors',
      dataIndex: 'number_of_tractors',
      key: 'number_of_tractors',
      width: 120,
      fixed: 'left',
      sorter: (a: any, b: any) => a.number_of_tractors - b.number_of_tractors,
    },
    {
      title: 'Driving Hours',
      dataIndex: 'driving_hours',
      key: 'driving_hours',
      width: 100,
      fixed: 'left',
      sorter: {
        compare: (a: any, b: any) =>
          a.driving_hours.localeCompare(b.driving_hours),
      },
    },
    {
      title: 'Number of Rows',
      dataIndex: 'number_of_rows',
      key: 'number_of_rows',
      width: 100,
      fixed: 'left',
      sorter: (a: any, b: any) => a.number_of_rows - b.number_of_rows,
    },
    {
      title: 'Number of headland turns',
      dataIndex: 'number_of_headland_turns',
      key: 'number_of_headland_turns',
      width: 120,
      fixed: 'left',
      sorter: (a: any, b: any) =>
        a.number_of_headland_turns - b.number_of_headland_turns,
    },
    {
      title: 'Stops in the Row',
      children: [
        {
          title: 'Object Detections',
          children: [
            {
              title: '# of Object Detections',
              dataIndex: 'number_of_stops_in_the_row_object_detections',
              key: 'number_of_stops_in_the_row_object_detections',
              width: 150,
            },
            {
              title: 'Soft resume for object detections',
              dataIndex: 'stops_in_the_row_object_detections_soft_resume',
              key: 'stops_in_the_row_object_detections_soft_resume',
              width: 200,
            },
            {
              title: '# of "Resume" commands from remote operator',
              dataIndex:
                'stops_in_the_row_object_resume_commands_from_remote_operator',
              key: 'stops_in_the_row_object_resume_commands_from_remote_operator',
              width: 200,
            },
          ],
        },
        {
          title: 'Human Detections',
          children: [
            {
              title: '# of Human Detections',
              dataIndex: 'number_of_stops_in_the_row_human_detections',
              key: 'number_of_stops_in_the_row_human_detections',
              width: 150,
            },
            {
              title: 'Soft resume for human detections',
              dataIndex: 'stops_in_the_row_human_detections_soft_resume',
              key: 'stops_in_the_row_human_detections_soft_resume',
              width: 200,
            },
            {
              title: '# of "Resume" commands from remote operator',
              dataIndex:
                'stops_in_the_row_human_resume_commands_from_remote_operator',
              key: 'stops_in_the_row_human_resume_commands_from_remote_operator',
              width: 200,
            },
          ],
        },
        {
          title: '# of stops due to Monarch Remote Monitoring Team',
          dataIndex:
            'number_of_stops_in_the_row_monarch_remote_monitoring_team',
          key: 'number_of_stops_in_the_row_monarch_remote_monitoring_team',
          width: 200,
        },
        {
          title: '# of stops due to connectivity loss',
          dataIndex: 'number_of_stops_in_the_row_connectivity_loss',
          key: 'number_of_stops_in_the_row_connectivity_loss',
          width: 200,
        },
        {
          title: '# of all other stops',
          dataIndex: 'number_of_stops_in_the_row_all_other_stops',
          key: 'number_of_stops_in_the_row_all_other_stops',
          width: 200,
        },
      ],
    },
    {
      title: 'Stops out of the Row',
      children: [
        {
          title: 'Object Detections',
          children: [
            {
              title: '# of Object Detections',
              dataIndex: 'number_of_stops_out_of_the_row_object_detections',
              key: 'number_of_stops_out_of_the_row_object_detections',
              width: 150,
            },
            {
              title: 'Soft resume for object detections',
              dataIndex: 'stops_out_of_the_row_object_detections_soft_resume',
              key: 'stops_out_of_the_row_object_detections_soft_resume',
              width: 200,
            },
            {
              title: '# of "Resume" commands from remote operator',
              dataIndex:
                'stops_out_of_the_row_object_resume_commands_from_remote_operator',
              key: 'stops_out_of_the_row_object_resume_commands_from_remote_operator',
              width: 200,
            },
          ],
        },
        {
          title: 'Human Detections',
          children: [
            {
              title: '# of Human Detections',
              dataIndex: 'number_of_stops_out_of_the_row_human_detections',
              key: 'number_of_stops_out_of_the_row_human_detections',
              width: 150,
            },
            {
              title: 'Soft resume for human detections',
              dataIndex: 'stops_out_of_the_row_human_detections_soft_resume',
              key: 'stops_out_of_the_row_human_detections_soft_resume',
              width: 200,
            },
            {
              title: '# of "Resume" commands from remote operator',
              dataIndex:
                'stops_out_of_the_row_human_resume_commands_from_remote_operator',
              key: 'stops_out_of_the_row_human_resume_commands_from_remote_operator',
              width: 200,
            },
          ],
        },
        {
          title: '# of stops due to Monarch Remote Monitoring Team',
          dataIndex:
            'number_of_stops_out_of_the_row_monarch_remote_monitoring_team',
          key: 'number_of_stops_out_of_the_row_monarch_remote_monitoring_team',
          width: 200,
        },
        {
          title: '# of stops due to connectivity loss',
          dataIndex: 'number_of_stops_out_of_the_row_connectivity_loss',
          key: 'number_of_stops_out_of_the_row_connectivity_loss',
          width: 200,
        },
        {
          title: '# of all other stops',
          dataIndex: 'number_of_stops_out_of_the_row_all_other_stops',
          key: 'number_of_stops_out_of_the_row_all_other_stops',
          width: 200,
        },
      ],
    },
  ];

  const handleRefresh = () => {
    getAutodrive();
  };

  const handleOrganizations = (ids: string[]) => {
    setSelectedOrgId(ids);
  };

  const handleDownload = async () => {
    try {
      if (downloadData.status === 'start') return;
      setDownloadData({
        ...downloadData,
        status: 'start',
        percent: 10,
      });
      const data: any = await getAutodriveCsvReport(
        filterObj.month,
        filterObj.year,
        selectedOrgId,
      );
      setDownloadData({
        ...downloadData,
        percent: 100,
        status: 'success',
      });
      await delay(1000); // Wait for the state change before download
      downloadURI(data?.autodrive_csv_url, 'AutoDriveReport.csv');
    } catch (error: any) {
      setDownloadData({
        ...downloadData,
        status: 'exception',
      });
      notification.error({ message: error.message });
    }
  };

  const handleSubmit = () => {
    if (userDetails?.organization && selectedOrgId.length) {
      getAutodrive();
    } else {
      message.warning('Please select Crop');
    }
  };

  return (
    <>
      <Layout className="posRel">
        <Content>
          <div
            className="tblContainer viewportContainer"
            onClick={() => setToggleColumnsStatus(!toggleColumnsStatus)}
          >
            <Row>
              <div className="common_wrapper">
                <div className="filters_card ad_filters_card filtersHealthScreen">
                  <CropOrgFilter handleOrgs={handleOrganizations} isDealer />
                  <DatePicker
                    allowClear={false}
                    format="MMM-YYYY"
                    picker="month"
                    onChange={onMonthly}
                    value={moment(`${filterObj.year}-${filterObj.month}`)}
                  />
                  <Button
                    className="submitBtn"
                    onClick={handleSubmit}
                    loading={loading}
                  >
                    {t(translate.buttons.submit)}
                  </Button>

                  <TableExt
                    handleRefresh={handleRefresh}
                    handleDownload={handleDownload}
                    downloadData={downloadData}
                    tableRef={tableRef}
                  />
                </div>
                <Table
                  className="autoDriveReportTbl"
                  columns={columns}
                  dataSource={autoDriveData}
                  pagination={false}
                  loading={{
                    indicator: (
                      <img
                        style={{ width: '60px', height: '36px' }}
                        src={loading_logo}
                      />
                    ),
                    spinning: loading,
                  }}
                  bordered
                  size="middle"
                  scroll={{
                    x: 'calc(700px + 50%)',
                    y: 240,
                  }}
                />
                <div className="reportTotal">
                  <b>Total:</b> {total} Autodrive Performance Reports
                </div>
              </div>
            </Row>
          </div>
        </Content>
      </Layout>
    </>
  );
};

export default AutoDriveReport;
