import React, { useContext, useEffect } from 'react';
import info_img from '../images/info-3.svg';
import co2_img from '../images/co2.svg';
import './styles.scss';
import LineChart from '../../charts/LineChart';
import { Popover } from 'antd';
import { LineChartSkeleton } from '../../common/Skeleton';
interface Props {
  totalSavings: string;
  co2Savings: string;
  energyCostSavingsData: any[];
  savingChartLoader: boolean;
}

const Savings: React.FC<Props> = ({
  totalSavings,
  co2Savings,
  energyCostSavingsData,
  savingChartLoader,
}) => {
  const tooltipText =
    'Estimated operational cost difference between the MK-V and a comparable diesel tractor';

  return (
    <div className="savings posrel">
      {savingChartLoader && <LineChartSkeleton />}
      <div className="left-side">
        <div className="graph-call-outs2">
          <div className="title-info">
            <div className="operations9 minWd">Savings</div>
            <div className="info-bubbles">
              <Popover
                overlayClassName="savingPopover"
                placement="bottomLeft"
                content={tooltipText}
              >
                <img className="info-3-icon" src={info_img} />
              </Popover>
            </div>
          </div>
          <div className="filter-merger">${totalSavings}</div>
        </div>
        <div className="image-cropper">
          <div className="graph-call-outs3">
            <div className="operations10">
              CO
              <sub>2</sub>
              <span> </span>Savings
            </div>
            <div className="div49">{co2Savings} lbs</div>
          </div>
          <div className="numerical-summarizer">
            <img className="co2-icon" src={co2_img} />
          </div>
        </div>
      </div>
      <div className="savings-graph">
        <LineChart
          loader={savingChartLoader}
          data={energyCostSavingsData}
          chartId="totalSavings"
          smooth={true}
          points={true}
          coverArea={true}
          lineColor="#546cb2"
          yTitle="USD Saved ($)"
        />
      </div>
    </div>
  );
};

export default Savings;
