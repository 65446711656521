/* eslint-disable no-restricted-globals */
/* eslint-disable  @typescript-eslint/ban-types */
/* eslint-disable  no-unused-vars */
/* eslint-disable  no-useless-escape */

import React, { FC, useEffect, useState } from 'react';
import { Input } from 'antd';

interface InputProps {
  label?: string;
  required?: boolean;
  value?: any;
  setValue?: (data: any) => void;
  maxLength?: number;
  maxvalue: number;
  isInt?: boolean;
}

const CustomInputNumber: FC<InputProps> = ({
  label,
  required,
  value,
  setValue,
  maxLength = 50,
  maxvalue,
  isInt = false,
}) => {
  const [focus, setFocus] = useState<boolean>(false);

  useEffect(() => {
    setFocus(false);
  }, [value]);

  const isEmpty = (val: any) => {
    if (typeof val === 'undefined') return true;
    if (typeof val === 'string' && val.trim() === '') return true;
    if (typeof val === 'number' && isNaN(val)) return true;
    return false;
  };

  const labelClass = focus || !isEmpty(value) ? 'label label-float' : 'label';

  const onChangeValue = (e: any) => {
    const str = e?.target?.value;
    const regx = isInt ? /^[+-]?\d+(\.\d+)?$/ : /^[+-]?\d*(\.\d{0,2})?$/;
    if (
      Number(str) >= 0 &&
      Number(str) <= maxvalue &&
      (Number(str) === 0 || regx.test(str)) &&
      setValue
    ) {
      setValue(str.trim() === '' ? '' : str);
    } else if (str?.length === 1 && str?.trim() === '' && setValue) {
      setValue('');
    } else if (setValue) setValue(value || '');
  };

  return (
    <>
      {label ? (
        <div
          className="float-label"
          onBlur={() => setFocus(false)}
          onFocus={() => setFocus(true)}
        >
          <Input value={value} onChange={onChangeValue} type="text" />
          <label className={labelClass}>
            {label}{' '}
            {required && (
              <span style={{ color: 'red' }} className="float-span">
                *
              </span>
            )}
          </label>
        </div>
      ) : (
        <Input
          maxLength={maxLength}
          value={value}
          onChange={(e) => onChangeValue(e)}
          type="text"
        />
      )}
    </>
  );
};
export default CustomInputNumber;
