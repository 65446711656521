/* eslint-disable no-console */
import { Button, Tooltip, notification } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import connecting_generating_path from '../../assets/images/connecting_generating_path.gif';
import end_cross_round from '../../assets/images/end_cross_round.svg';
import errorIcon from '../../assets/images/errorCode_icon.svg';
import hitch_cam_av from '../../assets/images/hi_gray.svg';
import param_cogs from '../../assets/images/param_cogs.svg';
import param_float from '../../assets/images/param_float.svg';
import param_rpm from '../../assets/images/param_rpm.svg';
import param_rpm_gray from '../../assets/images/param_rpm_gray.svg';
import param_speed from '../../assets/images/param_speed.svg';
import {
  addRemoteAVCommand,
  getIncompleteOperations,
} from '../../constants/Api';
import {
  commands,
  getImplementName,
  isHaveErrors,
  isHaveErrorsForRoutineFlow,
} from '../../constants/Common';
import {
  ATOM,
  AutoDriveTabs,
  dataSource,
  defaultSetUpKeys,
  getCommand,
  getImplementType,
  handleSeederShow,
  noImplementCode,
} from '../../constants/constant';
import { ApplicationContext } from '../../context/AppContext';
import { AutoDriveApplicationContext } from '../../context/AutoDriveContext';
import { SET_TRACTOR_IMPLEMENT_STATE } from '../../context/actions';
import AppLoader from '../common/AppLoader';
import GoToBlock from '../map/GoToBlock';
import AutoDriveErrorCodePopup from './AutoDriveErrorCodePopup';
import CommonPopAutoDrive from './CommonPopAutoDrive';
import ImplementDetail from './ImplementDetail';
import { RemoteDriveAppCtx } from './RemoteDriveContext';
import {
  AUTO_DRIVE_ERROR,
  SELECTED_ROUTINE,
  SET_ACTIVE_AUTODRIVE_TAB,
  SET_ADD_STEP,
  SET_SETUP_KEYS,
  SET_SHOW_IMPLEMENT,
  SET_SHOW_ROUTINE,
  SET_TRIGGER_TO_STARTING_POINT,
  SHOW_TRACTOR_COMMAND_LOADER,
} from './actions';
import DriveOptionSettingModal from '../common/DriveOptionSettingModal';

interface Props {
  addRowFollowRVCommand: () => void;
  preCommandState: string;
  handleResetCommand: (state: string) => void;
  clearPreviewPath: (id: number) => void;
  setShowEndAutoDrive: (flag: boolean) => void;
}
const AutoDriveRoutine: React.FC<Props> = ({
  handleResetCommand,
  preCommandState,
  addRowFollowRVCommand,
  clearPreviewPath,
  setShowEndAutoDrive,
}) => {
  const {
    RDReducer,
    commandsList,
    clearCommandApiHandler,
    startAutoDriveIteration,
    addImplementSettings,
  } = useContext(RemoteDriveAppCtx);
  const { userDetails, user } = useContext(ApplicationContext);
  const { AutoDriveReducer } = useContext(AutoDriveApplicationContext);
  const [selectedImplement, setSelectedImplement] =
    useState<string>(noImplementCode);
  const [implement, setImplement] = useState<any>(null);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [incompleteRoutineList, setIncompleteRoutineList] = useState<any>([]);

  const commandForConfirm: any = useRef();
  const [showCameras, setShowCameras] = useState(true);

  const [autoDriveState, autoDriveDispatch] = AutoDriveReducer;
  const [showEditConfirm, setShowEditConfirm] = useState(false);
  const [showRoutineEditConfirm, setShowRoutineEditConfirm] = useState(false);
  const [autonomyFlag, setAutonomyFlag] = useState<boolean>(false);
  const [state, dispatch] = RDReducer;
  const { tractorImplementState, showImplement } = autoDriveState;
  const {
    selectedTractor,
    showRoutine,
    autoDriveError,
    addStep,
    showTractorStart,
    selectedImplementName,
    loaderForImplementChange,
    activeAutoDriveTab,
    showTractorCommandLoader,
    showPresentCommand,
    setUpKeys,
    autoDriveLoader,
    selectedImplementType,
  } = state;
  const { heartbeat } = selectedTractor;

  useEffect(() => {
    if (
      heartbeat &&
      heartbeat.live_streaming_status !== null &&
      heartbeat.live_streaming_status !== undefined &&
      heartbeat?.drive_action_details?.drive_action_created_at === ATOM
    ) {
      const status = heartbeat.live_streaming_status ? true : false;
      setShowCameras(status);
    } else {
      setShowCameras(true);
    }
  }, [heartbeat]);
  const checkImplementVerifiedAndUpdate = () => {
    return (
      loaderForImplementChange.toLowerCase() ===
      getImplementName(selectedTractor).replace(' Attached', '').toLowerCase()
    );
  };
  const addRoutineHandler = () => {
    // if (
    //   checkImplementVerifiedAndUpdate() &&
    //   tractorImplementState[selectedTractor.id]
    // ) {
    if (
      user?.isSupportUser() ||
      (!user?.isSupportUser() && incompleteRoutineList.length > 0)
    ) {
      dispatch({
        type: SET_SHOW_ROUTINE,
        payload: !showRoutine,
      });
    } else {
      dispatch({
        type: SET_ADD_STEP,
        payload: true,
      });
      autoDriveDispatch({
        type: SET_SHOW_IMPLEMENT,
        payload: false,
      });
      dispatch({
        type: SET_ACTIVE_AUTODRIVE_TAB,
        payload: AutoDriveTabs.Parameter,
      });
    }
    // after clicking new command clear the auto_drive error
    dispatch({
      type: AUTO_DRIVE_ERROR,
      payload: {
        tractor_id: selectedTractor.id,
        message: '',
        errors: [],
      },
    });
    handleResetCommand('');
    // }
  };
  const goToHandler = () => {
    handleResetCommand('GOTO');
  };

  useEffect(() => {
    if (activeAutoDriveTab === AutoDriveTabs.Parameter) {
      handleResetCommand('');
      commandsList &&
        commandsList[selectedTractor.id] &&
        commandsList[selectedTractor.id].length > 0 &&
        commandsList[selectedTractor.id].map((command: any, index: number) => {
          if (index + 1 === commandsList[selectedTractor.id].length) {
            commandClearHandler(command, true);
          }
        });
      // hide loader on tab change
      showTractorCommandLoader[selectedTractor.id] = false;
      dispatch({
        type: SHOW_TRACTOR_COMMAND_LOADER,
        payload: {
          ...showTractorCommandLoader,
        },
      });
    }
    if (activeAutoDriveTab === AutoDriveTabs.Block) {
      dispatch({
        type: SET_SETUP_KEYS,
        payload: {
          ...setUpKeys,
          isBlockSelected: false,
        },
      });
      // hide loader on tab change
      showTractorCommandLoader[selectedTractor.id] = false;
      dispatch({
        type: SHOW_TRACTOR_COMMAND_LOADER,
        payload: {
          ...showTractorCommandLoader,
        },
      });
      goToHandler();
      commandsList &&
        commandsList[selectedTractor.id] &&
        commandsList[selectedTractor.id].length > 0 &&
        commandsList[selectedTractor.id].map((command: any, index: number) => {
          if (index + 1 === commandsList[selectedTractor.id].length) {
            commandClearHandler(command, true);
          }
        });
    }
  }, [activeAutoDriveTab]);

  useEffect(() => {
    if (preCommandState === commands.ROW_FOLLOW) {
      dispatch({
        type: SET_ACTIVE_AUTODRIVE_TAB,
        payload: AutoDriveTabs.PATH,
      });
    }
  }, [preCommandState]);

  const getCommandName = (command: any) =>
    `${
      command.command === 'goto_point'
        ? ` GOTO Point ${command.name ? '( ' + command.name + ' )' : ''}`
        : ''
    }${
      command.command === 'vine_block'
        ? ` ${command.name} ${command.block_name}`
        : ''
    } ${
      command.command === 'routine'
        ? ` Routine ${command.name ? '( ' + command.name + ' )' : ''}`
        : ''
    } ${
      command.command === 'incomplete_operation'
        ? ` ${command?.param_name} (${command?.param_type})`
        : ''
    } `;

  const commandClearHandler = (command: any, stopClearInterrupt = false) => {
    // to clear preview path
    clearPreviewPath(selectedTractor.id);
    // call clear command api
    clearCommandApiHandler(selectedTractor.id, 'AutoRoutine');
    dispatch({
      type: SELECTED_ROUTINE,
      payload: null,
    });

    !stopClearInterrupt &&
      dispatch({
        type: AUTO_DRIVE_ERROR,
        payload: {
          tractor_id: selectedTractor.id,
          message: '',
          errors: [],
        },
      });
    // if "Add Routine" selected toggle the routine selection popup
    command.command === 'routine' &&
      tractorImplementState[selectedTractor.id] &&
      dispatch({
        type: SET_SHOW_ROUTINE,
        payload: !showRoutine,
      });
    // if goto point select the goto section
    if (
      command.command !== 'incomplete_operation' &&
      command.command !== 'routine'
    ) {
      handleResetCommand('GOTO');
    }
  };

  const getFrequencyValue = (id: string) => {
    const [item] = dataSource.filter((item) => item.id === id);
    return item?.name ? item?.name : '';
  };
  const addRowFollowRVCommandClone = async (commandData: any) => {
    try {
      const { organization } = userDetails;
      if (organization && organization.api_url) {
        await addRemoteAVCommand(
          organization.api_url,
          [commandData],
          selectedTractor?.heartbeat?.drive_action_details
            ?.current_drive_action as string,
        );
      }
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    } finally {
      // console.log('error');
    }
  };
  // after interrupt message close pop click
  const handleInterruptCloseHandler = (state: boolean, error: number) => {
    // ok button click
    if (!state) {
      const command = getCommand(selectedTractor.id);
      if (
        command &&
        command.command &&
        (command.command === 'routine' ||
          command.command === 'incomplete_operation')
      ) {
        autoDriveDispatch({
          type: SET_SHOW_IMPLEMENT,
          payload: true,
        });
        dispatch({
          type: SET_SHOW_ROUTINE,
          payload: true,
        });
        tractorImplementState[selectedTractor.id] = false;
        autoDriveDispatch({
          type: SET_TRACTOR_IMPLEMENT_STATE,
          payload: tractorImplementState,
        });
      }
      clearCommandApiHandler(selectedTractor.id, 'routine');
      dispatch({
        type: AUTO_DRIVE_ERROR,
        payload: {
          tractor_id: selectedTractor.id,
          message: '',
          errors: [],
        },
      });
      dispatch({
        type: SET_ACTIVE_AUTODRIVE_TAB,
        payload: AutoDriveTabs.Block,
      });
      if (showPresentCommand === 'GOTO') {
        showTractorCommandLoader[selectedTractor.id] = false;
        dispatch({
          type: SHOW_TRACTOR_COMMAND_LOADER,
          payload: {
            ...showTractorCommandLoader,
          },
        });
      }
      // clearing the all data and redirect to implement change
      if (error === 7103) {
        autoDriveDispatch({
          type: SET_SHOW_IMPLEMENT,
          payload: true,
        });
        tractorImplementState[selectedTractor.id] = false;
        autoDriveDispatch({
          type: SET_TRACTOR_IMPLEMENT_STATE,
          payload: tractorImplementState,
        });
        commandsList &&
          commandsList[selectedTractor.id] &&
          commandsList[selectedTractor.id].length > 0 &&
          commandsList[selectedTractor.id].map(
            (command: any, index: number) => {
              if (index + 1 === commandsList[selectedTractor.id].length) {
                commandClearHandler(command);
              }
            },
          );
      }
    } else {
      // try again button click
      commandsList &&
        commandsList[selectedTractor.id] &&
        commandsList[selectedTractor.id].length > 0 &&
        commandsList[selectedTractor.id].map((command: any, index: number) => {
          if (index + 1 === commandsList[selectedTractor.id].length) {
            addRowFollowRVCommandClone(command);
          }
        });
      dispatch({
        type: AUTO_DRIVE_ERROR,
        payload: {
          tractor_id: selectedTractor.id,
          message: '',
          errors: [],
        },
      });
    }
  };

  const showTabs = () =>
    commandsList &&
    commandsList[selectedTractor.id] &&
    commandsList[selectedTractor.id].length > 0 &&
    showTractorStart &&
    showTractorStart[selectedTractor.id]
      ? true
      : false;

  const editRoutineConfirm = (command: any) => {
    dispatch({
      type: SET_SHOW_ROUTINE,
      payload: true,
    });
    dispatch({
      type: SET_ADD_STEP,
      payload: false,
    });
    autoDriveDispatch({
      type: SET_SHOW_IMPLEMENT,
      payload: true,
    });
    tractorImplementState[selectedTractor.id] = false;
    autoDriveDispatch({
      type: SET_TRACTOR_IMPLEMENT_STATE,
      payload: tractorImplementState,
    });

    commandClearHandler(command);
  };
  useEffect(() => {
    userDetails && userDetails.organization && getInCompleteRoutines();
  }, [userDetails]);
  const getInCompleteRoutines = async () => {
    try {
      // setLoader(true);
      const report = await getIncompleteOperations(
        userDetails.organization.api_url,
        userDetails.organization.farm.id,
        '',
        'created_date_time',
        'desc',
        selectedImplementName,
      );
      setIncompleteRoutineList(report);
    } finally {
      // setLoader(false);
    }
  };

  if (showImplement && !tractorImplementState[selectedTractor.id])
    return (
      <ImplementDetail
        selectedImplement={selectedImplement}
        setSelectedImplement={setSelectedImplement}
        setShowEndAutoDrive={setShowEndAutoDrive}
        addRoutineHandler={addRoutineHandler}
      />
    );
  return (
    <div className="rautoDriveRightSec ts_autodrive_color">
      <div className="auto-drive-routine-container w100per">
        <div className="automatedOprBlk">
          <h2>Automated Operation</h2>
          <div className="automatedOprBox">
            <div className="automatedOprTitle">
              <div className="automatedOprTracName">
                <h3>
                  <Tooltip title={selectedTractor?.name}>
                    {selectedTractor?.name}
                  </Tooltip>
                </h3>
                <h4 className="">
                  {selectedImplementType ||
                    getImplementType(heartbeat?.implement)}{' '}
                  Operation
                </h4>
              </div>
              <div className="automatedOprImpName">
                <div>
                  <h6>
                    <img src={hitch_cam_av} alt="" /> Implement
                  </h6>
                  <h5>
                    {selectedImplementName || getImplementName(selectedTractor)}
                  </h5>
                </div>
                <span
                  className="adEditBtn"
                  onClick={() => {
                    autoDriveDispatch({
                      type: SET_SHOW_IMPLEMENT,
                      payload: true,
                    });
                    tractorImplementState[selectedTractor.id] = false;
                    autoDriveDispatch({
                      type: SET_TRACTOR_IMPLEMENT_STATE,
                      payload: tractorImplementState,
                    });
                    commandsList &&
                      commandsList[selectedTractor.id] &&
                      commandsList[selectedTractor.id].length > 0 &&
                      commandsList[selectedTractor.id].map(
                        (command: any, index: number) => {
                          if (
                            index + 1 ===
                            commandsList[selectedTractor.id].length
                          ) {
                            commandClearHandler(command);
                          }
                        },
                      );
                  }}
                >
                  Edit
                </span>
              </div>
            </div>
            {!showTabs() && (
              <>
                {!showTractorCommandLoader[selectedTractor.id] &&
                  !(
                    commandsList &&
                    commandsList[selectedTractor.id] &&
                    commandsList[selectedTractor.id].length > 0
                  ) && (
                    <>
                      {addStep && (
                        <>
                          <GoToBlock
                            isValid={isValid}
                            setIsValid={setIsValid}
                            selectedImplement={selectedImplement}
                            implement={implement}
                            setImplement={setImplement}
                            addRowFollowRVCommand={addRowFollowRVCommand}
                          />
                        </>
                      )}
                    </>
                  )}
                {showTractorCommandLoader[selectedTractor.id] && (
                  <div className="opertaionReviewBlock tabsContainer">
                    <div className="connectAutoDriveLoader">
                      <div className="loader">
                        <img src={connecting_generating_path} />
                      </div>
                      <div className="createPath">Creating Path</div>
                    </div>
                  </div>
                )}
              </>
            )}
            {showTabs() && (
              <>
                {commandsList[selectedTractor.id].map(
                  (command: any, index: number) =>
                    index + 1 === commandsList[selectedTractor.id].length && (
                      <div
                        className={`opertaionReviewBlock tabsContainer ${
                          command.command === 'routine' ||
                          command.command === 'incomplete_operation'
                            ? 'opertaionReviewBlockHeightNone'
                            : ''
                        }`}
                        key={index}
                      >
                        <div className="blockTxt automatedTitle mb15">
                          <p>Review and Start</p>
                        </div>
                        <h5 className="body1 automatedPara mb20">
                          Make sure to review path and parameters before
                          starting operation
                        </h5>
                        <>
                          <div className="newOperationParamBlk">
                            <p className="operParamTitle">
                              Operation Parameters{' '}
                              <span
                                onClick={() => {
                                  setAutonomyFlag(true);
                                }}
                              >
                                Edit
                              </span>
                            </p>
                            <div className="mb20">
                              <ul>
                                <li>
                                  <img src={param_speed} alt="" />
                                  {implement?.imp_ctrl_in_row_tractor_speed} mph
                                </li>
                                <li>
                                  <img src={param_cogs} alt="" />
                                  {implement?.imp_ctrl_in_row_gear}
                                </li>
                              </ul>
                              <div className="operParamFlex">
                                <div className="operParamBox">
                                  <h5>In Row</h5>
                                  <h6>
                                    <img src={param_rpm_gray} alt="" />{' '}
                                    {implement?.imp_ctrl_in_row_pto
                                      ? implement?.imp_ctrl_in_row_pto_rpm +
                                        ' RPM'
                                      : 'OFF'}
                                  </h6>
                                  <h6>
                                    <img src={param_float} alt="" />{' '}
                                    {implement?.imp_ctrl_in_row_3pt_hitch_mode}{' '}
                                    {implement?.imp_ctrl_in_row_3pt_hitch_mode?.toLowerCase() ===
                                      'position' && (
                                      <>
                                        {isNaN(
                                          implement?.imp_ctrl_in_row_3pt_hitch_height,
                                        )
                                          ? ''
                                          : implement?.imp_ctrl_in_row_3pt_hitch_height +
                                            '%'}
                                      </>
                                    )}
                                  </h6>
                                </div>
                                <div className="operParamBox">
                                  <h5>Out of Row</h5>
                                  <h6>
                                    <img src={param_rpm} alt="" />{' '}
                                    {implement?.imp_ctrl_out_of_row_pto
                                      ? implement?.imp_ctrl_out_of_row_pto_rpm +
                                        ' RPM'
                                      : 'OFF'}
                                  </h6>
                                  <h6>
                                    <img src={param_float} alt="" />{' '}
                                    {
                                      implement?.imp_ctrl_out_of_row_3pt_hitch_mode
                                    }{' '}
                                    {implement?.imp_ctrl_out_of_row_3pt_hitch_mode?.toLowerCase() ===
                                      'position' && (
                                      <>
                                        {isNaN(
                                          implement?.imp_ctrl_out_of_row_3pt_hitch_height,
                                        )
                                          ? ''
                                          : implement?.imp_ctrl_out_of_row_3pt_hitch_height +
                                            '%'}
                                      </>
                                    )}
                                  </h6>
                                </div>
                              </div>
                            </div>
                            {handleSeederShow(implement) && (
                              <>
                                <p className="operParamTitle">
                                  Seeder Refill
                                  {/* <span>Edit</span> */}
                                </p>
                                <div className="srfill_qty_sec">
                                  <div
                                    className="operParamFlex2"
                                    // style={{ marginLeft: 10 }}
                                  >
                                    <div className="operParamBox2">
                                      <h5>{implement?.current_capacity} lb</h5>
                                      <h6>Quantity</h6>
                                    </div>
                                  </div>
                                  <div className="operParamFlex2">
                                    <div className="operParamBox2">
                                      <h5>
                                        {implement?.application_rate} lb/ac
                                      </h5>
                                      <h6>Rate</h6>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                          <div className="operationPramBlk">
                            <div className="dirContainer">
                              <div className="dirList">
                                {command.command === 'vine_block' && (
                                  <div className="dirItem">
                                    <div className="dirStartPointer">
                                      <div className="startCir dirComm"></div>
                                      <div className="dirDots dirComm"></div>
                                    </div>
                                    <div className="directions-searchbox">
                                      <div className="searchBlockSection">
                                        <input
                                          type="text"
                                          placeholder="[Block Name]"
                                          style={{ width: '100%' }}
                                          value={`Block ${command.name}`}
                                        />
                                        <button
                                          className="rvEditBtn editButtonStyle"
                                          style={{ cursor: 'pointer' }}
                                          onClick={() => {
                                            setShowEditConfirm(true);
                                          }}
                                        >
                                          Edit
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {(command.command === 'routine' ||
                                  command.command ===
                                    'incomplete_operation') && (
                                  <div className="dirItem">
                                    <div className="dirStartPointer">
                                      <div className="startCir dirComm"></div>
                                      <div className=" dirComm"></div>
                                    </div>
                                    <div className="directions-searchbox">
                                      <div className="searchBlockSection">
                                        <input
                                          type="text"
                                          placeholder="[Block Name]"
                                          style={{ width: '100%' }}
                                          value={getCommandName(command)}
                                        />
                                        <button
                                          className="rvEditBtn editButtonStyle"
                                          style={{ cursor: 'pointer' }}
                                          onClick={() => {
                                            commandForConfirm.current = command;
                                            setShowRoutineEditConfirm(true);
                                          }}
                                        >
                                          Edit
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {command.command === 'vine_block' && (
                                  <>
                                    <div className="dirItem">
                                      <div className="dirStartPointer">
                                        <div className="startCir dirComm"></div>
                                        <div className="dirDots dirComm"></div>
                                      </div>
                                      <div className="directions-searchbox">
                                        <div className="searchBlockSection">
                                          <input
                                            type="text"
                                            placeholder="[Block Name]"
                                            style={{ width: '100%' }}
                                            value={`Rows ${command.vinerow_name_2} -- ${command.vinerow_name_3}`}
                                          />
                                          <button
                                            className="rvEditBtn editButtonStyle"
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                              // changing the tab
                                              dispatch({
                                                type: SET_ACTIVE_AUTODRIVE_TAB,
                                                payload: AutoDriveTabs.Block,
                                              });
                                              setTimeout(() => {
                                                // selecting previous poly
                                                dispatch({
                                                  type: SET_SETUP_KEYS,
                                                  payload: {
                                                    ...defaultSetUpKeys,
                                                    mapId: setUpKeys.mapId,
                                                    laneFilterContent:
                                                      setUpKeys.laneFilterContent,
                                                    isBlockSelected: true,
                                                    polygonUuid:
                                                      setUpKeys.polygonUuid,
                                                  },
                                                });
                                                // selecting previous poly
                                                dispatch({
                                                  type: SET_TRIGGER_TO_STARTING_POINT,
                                                  payload: true,
                                                });
                                              }, 100);
                                            }}
                                          >
                                            Edit
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="dirItem">
                                      <div className="dirStartPointer">
                                        <div className="startCir dirComm"></div>
                                        <div className="dirComm"></div>
                                      </div>
                                      <div className="directions-searchbox">
                                        <div className="searchBlockSection">
                                          <input
                                            type="text"
                                            placeholder="[Block Name]"
                                            style={{ width: '100%' }}
                                            value={`${getFrequencyValue(
                                              command.row_seq,
                                            )}`}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </>
                      </div>
                    ),
                )}
              </>
            )}
          </div>
        </div>
        <div className="adr-title autoDHead" style={{ display: 'none' }}>
          <h2 className="autoDHeadText headline1">Autodrive Routine</h2>
        </div>
        {/* added below class to add red border when auto drive errors occur */}
        {autoDriveError &&
          autoDriveError.errors &&
          autoDriveError.errors.map((error: any, index: number) =>
            error == 7102 || error == 7101 || error == 7103 ? (
              <CommonPopAutoDrive
                showModal={true}
                closeModel={(state: boolean) => {
                  // first=> button return always false
                  // second => button return always true
                  handleInterruptCloseHandler(state, error);
                }}
                title={`${
                  error == 7102 || error == 7101
                    ? 'Tractor path could not be generated'
                    : ''
                }
                ${
                  error == 7103
                    ? 'Implement dimensions are not compatible with Autodrive.'
                    : ''
                }
                `}
                description={`${
                  error == 7102 || error == 7101
                    ? 'Please check to see if the correct starting row was selected or re-position the tractor close to the beginning of the first row and try again'
                    : ''
                }
                ${
                  error == 7103
                    ? ' Please select one of the compatible implements or contact Monarch.'
                    : ''
                }
                `}
                key={index}
                showSecondButton={
                  error == 7103
                    ? false
                    : error == 7102 || error == 7101
                    ? true
                    : false
                }
              />
            ) : (
              <></>
            ),
          )}
        {showEditConfirm && (
          <CommonPopAutoDrive
            showModal={true}
            closeModel={(state: boolean) => {
              // first=> button return always false
              // second => button return always true
              setShowEditConfirm(false);
              // state => false change the tab
              !state &&
                dispatch({
                  type: SET_ACTIVE_AUTODRIVE_TAB,
                  payload: AutoDriveTabs.Block,
                });
            }}
            title="Are You Sure?"
            description="This will reset all subsequent settings"
            firstButtonText="Yes, Edit Field"
            secondButtonText="Cancel"
          />
        )}
        {showRoutineEditConfirm && (
          <CommonPopAutoDrive
            showModal={true}
            closeModel={(state: boolean) => {
              // first=> button return always false
              // second => button return always true
              setShowRoutineEditConfirm(false);
              // state => false change the tab
              commandForConfirm &&
                commandForConfirm.current &&
                !state &&
                editRoutineConfirm(commandForConfirm.current);
              //   dispatch({
              //     type: SET_ACTIVE_AUTODRIVE_TAB,
              //     payload: AutoDriveTabs.Block,
              //   });
            }}
            title="Are You Sure?"
            description="This will reset all subsequent settings"
            firstButtonText="Yes, Edit Field"
            secondButtonText="Cancel"
          />
        )}
        {isHaveErrors(autoDriveError) &&
          isHaveErrorsForRoutineFlow(autoDriveError) && (
            <AutoDriveErrorCodePopup showModal={isHaveErrors(autoDriveError)} />
          )}
      </div>
      {/* end auto drive pop show option*/}

      <div className="verifyTxt">
        {commandsList &&
          commandsList[selectedTractor.id] &&
          commandsList[selectedTractor.id].length > 0 &&
          showTractorStart &&
          showTractorStart[selectedTractor.id] && (
            <>
              <Button
                className="Button fBld adStartBtn mb10"
                onClick={() => {
                  startAutoDriveIteration(selectedTractor.id);
                  dispatch({
                    type: SET_ADD_STEP,
                    payload: false,
                  });
                }}
              >
                START AUTODRIVE
              </Button>
              {!showCameras && (
                <div className="video_unavilable mb10">
                  <span>
                    <img src={errorIcon} alt=" " className="errorIcon" />
                  </span>
                  <p>
                    Live video is unavailable due to poor tractor connectivity
                    and may not return for the remainder of your Autodrive
                  </p>
                </div>
              )}
            </>
          )}
        <button
          className="Button2 btnBgNone"
          onClick={() => setShowEndAutoDrive(true)}
        >
          <span className="endDnone">
            <img src={end_cross_round} alt="" />
          </span>
          <span>End Autodrive</span>
        </button>
      </div>
      {autonomyFlag && (
        <DriveOptionSettingModal
          showModal={autonomyFlag}
          handleClose={(details?: any) => {
            details && addImplementSettings(details, false);
            details && setImplement(details);
            setAutonomyFlag(false);
          }}
          id={selectedImplement}
          tractorId={selectedTractor.id}
          driveActionUUID={
            selectedTractor?.heartbeat.drive_action_details
              ?.current_drive_action as string
          }
        />
      )}
      <AppLoader loader={autoDriveLoader} />
    </div>
  );
};

export default AutoDriveRoutine;
