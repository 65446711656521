import React, { memo, useEffect, useRef, useState } from 'react';

interface Props {
  videoRef: any;
  seek: (sec: number) => void;
  vLength: number;
  progressSelector?: string;
  progressBarSelector?: string;
}

const BarHandler: React.FC<Props> = ({
  videoRef,
  seek,
  vLength,
  progressSelector = 'video_progress',
  progressBarSelector = 'video_progress__filled',
}) => {
  const timerRef = useRef<any>();
  const curTime: any = useRef();
  const fixTime: any = useRef();
  const [barValue, setBarValue] = useState<number>(0);
  const [seekTime, setSeekTime] = useState<number>(0);
  const [duration, setDuration] = useState(vLength);

  const progress: any = document.querySelector(`.${progressSelector}`);
  const progressBar: any = document.querySelector(`.${progressBarSelector}`);

  const barHandling = (e: any) => {
    try {
      if (videoRef && videoRef.current) {
        // const { player } = videoRef?.current.front?.getState();
        const seekTime = (e.offsetX / progress.offsetWidth) * duration;
        seek(seekTime);
        const percent = (seekTime / duration) * 100;
        progressBar.style.flexBasis = `${percent}%`;
        // currentTime1(currentTime);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  progress?.addEventListener('click', barHandling);

  useEffect(() => {
    const durationMinutes = Math.floor(duration / 60);
    const durationSeconds = Math.floor(duration - durationMinutes * 60);
    if (durationMinutes || durationSeconds) {
      fixTime.current.innerHTML = `0${durationMinutes}: ${
        durationSeconds < 10 ? '0' + durationSeconds : durationSeconds
      }`;
    }
  }, [duration]);

  const currentTime1 = (currentTime: number) => {
    const currentMinutes = Math.floor(currentTime / 60);
    const currentSeconds = Math.floor(currentTime - currentMinutes * 60);

    if (curTime?.current)
      curTime.current.innerHTML = `0${currentMinutes}: ${
        currentSeconds < 10 ? '0' + currentSeconds : currentSeconds
      }`;
    const percent = (currentTime / duration) * 100;
    setBarValue(percent);
    progressBar?.setAttribute('data-time', currentTime);
  };

  useEffect(() => {
    setDuration(vLength);
  }, [vLength]);

  useEffect(() => {
    if (videoRef && duration) {
      clearInterval(timerRef.current);
      timerRef.current = setInterval(() => {
        const currentTime = Object.values(videoRef.current)
          .filter((item) => item)
          .filter((item: any) => {
            const { player } = item.getState();
            return !player.error;
          })
          .map((item: any) => {
            const { player } = item.getState();
            return Math.round(player.currentTime);
          });
        const avg = currentTime.reduce((a, b) => a + b, 0) / currentTime.length;
        currentTime1(avg);
      }, 1000);
    }
    return () => {
      clearInterval(timerRef.current);
    };
  }, [duration, videoRef]);

  useEffect(() => {
    const seekTime = Math.round((barValue * duration) / 100);
    // if (seekTime % 10 === 0) setSeekTime(seekTime);
    if (progressBar) progressBar.style.flexBasis = `${barValue}%`;
  }, [barValue]);

  useEffect(() => {
    Object.values(videoRef.current).forEach((item: any) => {
      if (item) item.seek(seekTime);
    });
  }, [seekTime]);

  return (
    <>
      <div
        style={{
          width: '140px',
          fontFamily: 'Montserrat-SemiBold',
        }}
      >
        <span ref={curTime}>00:00</span> / <span ref={fixTime}>00:00</span>
      </div>
    </>
  );
};

export default memo(BarHandler);
