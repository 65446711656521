import { Input, Modal, Select, notification } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { uploadVineBlockFileUpload } from '../../constants/Api';
import { ApplicationContext } from '../../context/AppContext';
import translate from '../../locale/en_translate.json';
import './style.css';

import { useTranslation } from 'react-i18next';
import { PolygonDropDown } from '../../constants/types';

interface Props {
  showModal: boolean;
  handleCancel: (reload?: boolean) => void;
  polygonsListForPop: PolygonDropDown[];
}

export const VineBlockFileUpload: React.FC<Props> = ({
  showModal,
  handleCancel,
  polygonsListForPop,
}: Props) => {
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const [PolygonFile, setPolygonFile] = useState<any>('');
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [vineBlockName, setVineBlockName] = useState<string>('');
  const [selectedPolygonUuid, setSelectedPolygonUuid] = useState('');
  useEffect(() => {
    if (PolygonFile) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [userDetails, PolygonFile]);
  const onChangeFile = (event: any) => {
    setPolygonFile(event.target.files[0]);
  };
  const uploadVineBlock = async () => {
    const { organization } = userDetails;
    const formData = new FormData();
    formData.append('files', PolygonFile, 'files.csv');
    formData.append('vine_block_name', vineBlockName);
    formData.append('polygon_uuid', selectedPolygonUuid);
    // eslint-disable-next-line no-console
    // console.log(formData, 'formmdata');
    try {
      const resp = await uploadVineBlockFileUpload(
        organization.api_url,
        organization.farm.id,
        formData,
      );
      if (resp && resp.msg) {
        handleCancel(true);
        notification.success({
          message: resp.msg,
        });
      }
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    }
  };
  return (
    <Modal
      className="commonPopup addPolygon popupStyles"
      title="Upload VineBlock Elements"
      centered
      width={'50vw'}
      visible={showModal}
      onCancel={() => handleCancel(false)}
      okText="Save"
      onOk={uploadVineBlock}
      okButtonProps={{
        className: 'btnSave Button ',
        disabled: isDisabled,
      }}
      cancelButtonProps={{
        className: 'btnCancel Button',
      }}
    >
      <div className="formRow width-class">
        <div className="formCol">
          <label className="formLabel">
            {t(translate.map.crop_block)} Name{' '}
            <span style={{ color: 'red' }}> *</span>
          </label>
          <Input
            className="commInput"
            onChange={(e) => setVineBlockName(e.target.value)}
            value={vineBlockName}
          />
        </div>
        <div className="formCol">
          <label className="formLabel">
            {t(translate.map.boundary)} <span style={{ color: 'red' }}> *</span>
          </label>
          <Select
            className="customSelect"
            onSelect={(value) => {
              setSelectedPolygonUuid(value);
            }}
            value={selectedPolygonUuid}
          >
            {polygonsListForPop.map((data: any) => {
              if (data.name !== '') {
                return (
                  <option value={data.polygon_uuid} key={data.polygon_uuid}>
                    {data.name} ({data.color})
                  </option>
                );
              }
            })}
          </Select>
        </div>
      </div>
      <div className="formRow">
        <div className="formCol">
          <div className="commInput pl10">
            <input
              type="file"
              onChange={onChangeFile}
              className="body1"
              accept=".csv"
            />
          </div>
        </div>
      </div>
      <div className="formRow">
        <div className="formCol ">
          {/* <div className="commInput pl10"> */}
          {/* <input type="file" onChange={onChangeFile} /> */}

          {/* <div className="fileUploadInput commInput">
            <input type="file" onChange={onChangeFile} />
            <span className="button">Choose</span>
            <span className="label" data-js-label>
              No file selected
            </span>
          </div> */}
        </div>
      </div>
      <div className="formCol"></div>
      {/* </div> */}
    </Modal>
  );
};
export default VineBlockFileUpload;
