/* eslint-disable react/no-unescaped-entities */
import { Col, Row, Tabs } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';

import hc_monarch_logo from '../HelpCenter/images/hc_monarch_logo.svg';
import TrainingVideos from './TrainingVideos';
// import MonarchTrainingGuide from './MonarchTrainingGuide';
// import OperatorsGuide from './OperatorsGuide';
// import AutoDriveTrainingGuide from './AutoDriveTrainingGuide';
import { HelpCenterContext } from './HelpCenterContext';
import HealthcenterSearch from './HealthcenterSearch';
import { useHistory } from 'react-router-dom';
import { searchRoute } from './lib/constants';
import '../HelpCenter/styles.css';
import SubCategoryContent from './SubCategoryContent';
import { track } from '../../util/logger';

const { TabPane } = Tabs;

const TabContentData: any = Object.freeze({
  1001: <TrainingVideos />,
  1002: <SubCategoryContent title="Operators Guide" />,
  1003: <SubCategoryContent title="Monarch Training Guide " />,
  1004: <SubCategoryContent title="AutoDrive Training Guide" />,
});

const HelpCenter: React.FC = () => {
  const history = useHistory();
  const { HCReducer } = useContext(HelpCenterContext);
  const [state, dispatch] = HCReducer;
  const { category, categories } = state;
  const [tabid, setTabid] = useState<string>('1001');
  const categoryRef = useRef<string>(category?.title ?? '');

  const onSelect = (id: any) => {
    categories.filter((ele: any) => {
      if (ele.category_code === Number(id)) {
        dispatch({
          type: 'SET_DATA',
          payload: {
            category: ele,
            subCategory: '',
          },
        });
      }
    });
  };

  useEffect(() => {
    if (category) {
      setTabid(String(category?.category_code));
      track('Help Center Tabs', {
        event: `${category?.title} Tab`,
        start: true,
      });
      categoryRef.current = `${category?.title}`;
    }
    return () => {
      if (categoryRef.current) {
        track('Help Center Tabs', {
          event: `${categoryRef.current} Tab`,
          start: false,
        });
      }
    };
  }, [category]);

  return (
    <div className="common_tabs">
      <div className="help_center_container">
        <div className="hc_top_bg"></div>
        <div className="hc_logo_section mb47">
          <div
            className="flex cursorPointer"
            onClick={() => {
              dispatch({
                type: 'RESET',
              });
              history.push(searchRoute);
            }}
          >
            <div>
              <img src={hc_monarch_logo} alt="logo" />
              <h4 className="hc_title_top">Help Center</h4>
            </div>
          </div>
          <div className="top_search_bar">
            <Row gutter={[20, 0]}>
              <Col xl={{ span: 24, offset: 0 }} className="hc_search">
                <HealthcenterSearch flag={false}></HealthcenterSearch>
              </Col>
            </Row>
          </div>
        </div>
        <div className="hc_tabs_section">
          <Tabs
            defaultActiveKey="1001"
            activeKey={tabid}
            centered
            onChange={(id) => {
              onSelect(id);
              setTabid(id);
            }}
          >
            {categories.map(({ category_code, title }: any) => {
              return (
                <TabPane tab={title} key={category_code}>
                  {String(category_code) == tabid &&
                    TabContentData[category_code]}
                </TabPane>
              );
            })}

            {/* <TabPane
              tab="Known Issues"
              key="1005"
              className="known_issues mt-16"
            >
              {tabid === '1005' && <Knowissues />}
            </TabPane> */}
            {/* <TabPane
              tab="Fleet Indicator & Diagnostics"
              key="1006"
              className="fleet_indicators mt-16"
            >
              <Row gutter={[20, 0]}>
                <Col xl={{ span: 20, offset: 2 }}>
                  <div className="fleetIndicators_container">
                    {tabid === '1006' && <FleetIndicators />}
                  </div>
                </Col>
              </Row>
            </TabPane> */}
            {/* Add more TabPane components as needed */}
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default HelpCenter;
