import { Col, Row, Tabs, Tooltip } from 'antd';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import imp_icon from './images/implement-1.svg';
import user_fill from './images/user-fill-1.svg';

import Efficiency from './efficiency';

import useAnalytics from './hooks/useAnalytics';
import useStore from './store/operationAnalytics';
import useOperatorAnalyticStore from './store/operatorAnalyticStore';

import { ApplicationContext } from '../../context/AppContext';

import OperationalCoverageMap from './OperationalCoverageMap/OperationalCoverageMap';

import MultiStackedBarChart from '../charts/MultiStackedBarChart';
import StackedBarChart from '../charts/StackedBarChart';

import TotalSavings from './totalSavings';
import { WeekendsFilter } from './ui/helper';

import './css/global.scss';
import './css/styles.scss';
import './styles.scss';
import { pathColorCodes } from './constants';
import CDateRangePicker from './ui/CDateRangePicker';
import { ChartSkeleton } from '../common/Skeleton';

const selOpTxt =
  'Select an operation type on the graph below for specific analytics and videos.';
const { TabPane } = Tabs;

interface Props {
  onChangeOperation: (type: string, selectedTab: string) => void;
}

const OperationAnalytics: React.FC<Props> = ({ onChangeOperation }: Props) => {
  const {
    operationalHours,
    coverage,
    toggleWeekends,
    includeWeekends,
    operationalHoursByOperations,
    operationalHoursByOperators,
    coverageByOperators,
    coverageByOperations,
    setDateRange,
    dateRange,
    setView,
  } = useStore();
  const {
    operationsLoader,
    initOperationalHoursAndCoverageData,
    dateRangeText,
  } = useAnalytics();
  const { setOperatorId } = useOperatorAnalyticStore();
  const { userDetails } = useContext(ApplicationContext);
  const [handleType, setHandleType] = useState<string>('operations');
  // const [tabid, setTabid] = useState('operationalHours');
  const [cardSize, setCardSize] = useState({ width: 0, height: 0 });
  const cardRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (userDetails?.organization) {
      const [fromDate, toDate] = dateRange;
      initOperationalHoursAndCoverageData(
        fromDate?.startOf('day')?.toDate()?.getTime(),
        toDate?.endOf('day')?.toDate()?.getTime(),
      );
    }
  }, [dateRange, userDetails]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        setCardSize({
          width: entry.contentRect.width,
          height: entry.contentRect.height,
        });
      }
    });

    if (cardRef.current) {
      resizeObserver.observe(cardRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const handleOperatorsType = () => {
    setHandleType((prevType) =>
      prevType === 'operations' ? 'operators' : 'operations',
    );
  };

  const OnOperationsClick = (category: string) => {
    onChangeOperation(category, 'operationalHours');
    setView(category === 'No Implement' ? 'no_implement' : 'operations');
  };

  const OnOperatorClick = (operator: string, data?: any) => {
    setOperatorId(data?.operator_id);
    onChangeOperation(operator, 'operationalHours');
    setView('operator');
  };

  const operationalHoursStats = useCallback(
    (type) => (
      <>
        <div className="opHrsSec">
          <div className="op_tab_title">
            {type === 'operations' ? 'Operational' : 'Operator'} Hours
          </div>
        </div>
        {type === 'operations' && (
          <div className="wrapper opHrsTxt">
            <div className="div34 hrsText">
              <span className="span">
                {type === 'operations'
                  ? operationalHours?.operations?.hours
                  : operationalHours?.operators?.hours}
              </span>
              <span className="h">h</span>
              <span className="span1">
                {' '}
                {type === 'operations'
                  ? operationalHours?.operations?.minutes
                  : operationalHours?.operators?.minutes}
              </span>
              <span className="m1">m</span>
            </div>
          </div>
        )}
      </>
    ),
    [operationalHours],
  );

  return (
    <>
      <section className="background-parent">
        <Row gutter={[20, 20]}>
          <Col xs={24} sm={24} md={24} lg={12} xl={10}>
            <div className="frame-parent">
              <div className="frame-wrapper">
                <div className="frame-group">
                  <div className="november-3rd-9th-parent">
                    <h1 className="november-3rd">{dateRangeText}</h1>
                    {/* <DateRangePicker
                      handleOnChange={(dates) => setDateRange(dates)}
                      selectedDates={dateRange}
                    /> */}
                    <CDateRangePicker
                      handleOnChange={(dates) => {
                        setDateRange(dates);
                      }}
                      selectedDate={dateRange}
                    />
                  </div>
                  <div className="select-an-operation">
                    <Tooltip title={selOpTxt}>{selOpTxt}</Tooltip>
                  </div>
                </div>
              </div>
              <div className="operations-graph" ref={cardRef}>
                {operationsLoader && <ChartSkeleton />}
                <section className="op_tabs_sec">
                  <div className="chip_container">
                    <div className="shape-compiler">
                      <img
                        className="implement-1-icon1"
                        alt=""
                        src={imp_icon}
                      />

                      <div className="image-parser">
                        {operationalHoursByOperations?.length}
                      </div>
                    </div>
                  </div>
                  {/* <div className="toggle-placement">
                    <input
                      type="checkbox"
                      id="toggle"
                      className="toggleCheckbox"
                      onChange={handleOperatorsType}
                    />
                    <label htmlFor="toggle" className="toggleContainer">
                      <div>
                        <img src={imp_icon} />
                        <span className="opTgl">Operations</span>
                      </div>
                      <div>
                        <img src={user_fill} />
                        <span className="opTgl">Operators</span>
                      </div>
                    </label>
                  </div> */}
                  {/* <Tabs
                    defaultActiveKey="1"
                    activeKey={tabid}
                    onChange={(key) => {
                      setTabid(key);
                    }}
                  > */}
                  {/* <TabPane
                    tab={ */}
                  <>
                    {/* <div>
                      <div className="op_tab_title">Operational Hours</div>
                    </div>

                    <div className="wrapper">
                      <div className="div34">
                        <span className="span">
                          {handleType === 'operations'
                            ? operationalHours?.operations?.hours
                            : operationalHours?.operators?.hours}
                        </span>
                        <span className="h">h</span>
                        <span className="span1">
                          {' '}
                          {handleType === 'operations'
                            ? operationalHours?.operations?.minutes
                            : operationalHours?.operators?.minutes}
                        </span>
                        <span className="m1">m</span>
                      </div>
                    </div> */}
                    {operationalHoursStats('operations')}
                  </>
                  {/* }
                    key="operationalHours"
                  > */}
                  {/* {handleType === 'operations' && ( */}
                  <MultiStackedBarChart
                    className="msBarChart"
                    loader={operationsLoader}
                    chartId="OperationalHoursByOperations"
                    data={operationalHoursByOperations}
                    barColors={[
                      pathColorCodes.manual,
                      pathColorCodes.op_assists,
                      pathColorCodes.autonomy,
                      pathColorCodes.idle,
                    ]}
                    yTitle="Hours Of Operation"
                    onClick={OnOperationsClick}
                    tabId={handleType}
                    cardWidth={cardSize.width}
                  />
                  {/* )} */}
                  {/* {handleType === 'operators' && (
                      <StackedBarChart
                        className="msBarChart"
                        loader={operationsLoader}
                        chartId="operationalHoursByOperators"
                        barColors={[
                          pathColorCodes.manual,
                          pathColorCodes.op_assists,
                          pathColorCodes.autonomy,
                          pathColorCodes.idle,
                        ]}
                        labels={['level1', 'level2', 'level4', 'level5']}
                        data={operationalHoursByOperators}
                        yTitle="Hours of Operation"
                        onClick={OnOperatorClick}
                        dataType="time"
                        tabId={tabid || handleType}
                        cardWidth={cardSize.width}
                      />
                    )} */}
                  {/* </TabPane> */}
                  {/* <TabPane
                    tab={
                      <div>
                        <div className="op_tab_title">Coverage (ac)</div>
                        <div className="container cv_ac">
                          <div className="div35">
                            {handleType === 'operations'
                              ? coverage?.operations
                              : coverage?.operators}
                          </div>
                        </div>
                      </div>
                    }
                    key="coverage"
                  >
                    {handleType === 'operations' && (
                      <StackedBarChart
                        className="msBarChart"
                        loader={operationsLoader}
                        chartId="CoverageByOperations"
                        barColors={[pathColorCodes.coverage]}
                        labels={['total']}
                        data={coverageByOperations}
                        yTitle="Acres Covered"
                        onClick={OnOperationsClick}
                        dataType="area"
                        type="operationscoverage"
                        tabId={tabid || handleType}
                        cardWidth={cardSize.width}
                      />
                    )}
                    {handleType === 'operators' && (
                      <StackedBarChart
                        className="msBarChart"
                        loader={operationsLoader}
                        chartId="CoverageByOperators"
                        barColors={[pathColorCodes.coverage]}
                        labels={['level1', 'level2', 'level4', 'level5']}
                        data={coverageByOperators}
                        yTitle="Acres Covered"
                        onClick={OnOperatorClick}
                        dataType="area"
                        tabId={tabid || handleType}
                        cardWidth={cardSize.width}
                      />
                    )}
                  </TabPane> */}
                  {/* </Tabs> */}
                </section>
              </div>
              {/* <Efficiency onOperationsClick={OnOperationsClick} /> */}
              <div className="operations-graph" ref={cardRef}>
                {operationsLoader && <ChartSkeleton />}
                <section className="op_tabs_sec">
                  {operationalHoursStats('operator')}
                  <div className="chip_container">
                    <div className="shape-compiler">
                      <img
                        className="implement-1-icon1"
                        alt=""
                        src={user_fill}
                      />

                      <div className="image-parser">
                        {operationalHoursByOperators?.length}
                      </div>
                    </div>
                  </div>
                  <StackedBarChart
                    className="msBarChart"
                    loader={operationsLoader}
                    chartId="operationalHoursByOperators"
                    barColors={[
                      pathColorCodes.manual,
                      pathColorCodes.op_assists,
                      pathColorCodes.autonomy,
                      pathColorCodes.idle,
                    ]}
                    labels={['level1', 'level2', 'level4', 'level5']}
                    data={operationalHoursByOperators}
                    yTitle="Hours of Operation"
                    // onClick={OnOperatorClick}
                    dataType="time"
                    tabId={handleType}
                    cardWidth={cardSize.width}
                  />
                </section>
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={14}>
            <div className="scale-scientist">
              <WeekendsFilter
                includeWeekends={includeWeekends}
                toggleWeekends={toggleWeekends}
              />

              <OperationalCoverageMap onClick={OnOperationsClick} />
              <TotalSavings />
            </div>
          </Col>
        </Row>
      </section>
    </>
  );
};

export default OperationAnalytics;
