import { Select } from 'antd';
import React from 'react';

interface DataItem {
  value: string | number;
  label: string;
}

interface Props {
  data: DataItem[];
  placeholder: string;
  handleOnChange: (value: string, key: any) => void;
  // handleOnSearch: (value: string) => void;
}

const SelectSearch: React.FC<Props> = ({
  data,
  placeholder,
  handleOnChange,
  // handleOnSearch,
}) => {
  const onChange = (value: string, key: any) => {
    handleOnChange(value, key);
    // console.log(`selected ${value}`);
  };

  const onSearch = (value: string) => {
    // handleOnSearch(value);
    // console.log('search:', value);
  };
  return (
    <Select
      showSearch
      size="large"
      style={{ width: 470 }}
      placeholder={placeholder}
      optionFilterProp="children"
      // className="overline1 selectMenu w100"
      onChange={onChange}
      onSearch={onSearch}
      filterOption={(input, option: any) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
      }
      options={data}
      getPopupContainer={(trigger) => trigger.parentNode}
    />
  );
};

export default SelectSearch;
