/* eslint-disable react/react-in-jsx-scope */
import { ResponsiveBar, BarTooltipProps, BarDatum } from '@nivo/bar';
import { ColorSchemeId } from '@nivo/colors';
import { OnClickBarChart } from '../types/linked_charts';
// import React from 'react'
// type setLinkedChartFn<K> = React.Dispatch<React.SetStateAction<K>>

const FirstBarChart = ({
  title,
  data_list,
  key_list,
  y_axis_title,
  tool_tip,
  onClickBar,
  color_scheme,
  enable_interaction_title = true,
  colors,
}: {
  title: string;
  y_axis_title: string;
  data_list: BarDatum[];
  key_list: string[];
  tool_tip: React.FC<BarTooltipProps<BarDatum>>;
  // Access by index 1, indexValue='May12' id: PS1 => daily_duration_list
  onClickBar?: OnClickBarChart;
  enable_interaction_title?: boolean;
  color_scheme?: {
    scheme: ColorSchemeId;
  };
  colors?: string[];
}): JSX.Element => (
  <div className="">
    <div className="cost-graph-labor">
      <ResponsiveBar
        data={data_list}
        keys={key_list}
        onClick={onClickBar}
        margin={{ top: 20, right: 100, bottom: 80, left: 80 }}
        indexBy="date"
        padding={0.3}
        groupMode="grouped"
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        animate={false}
        enableLabel={false}
        tooltip={tool_tip}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 10,
          tickRotation: 90,
          legend: '',
          legendOffset: 50,
          legendPosition: 'middle',
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 10,
          tickRotation: 0,
          legend: y_axis_title,
          legendOffset: -50,
          legendPosition: 'middle',
        }}
        colors={color_scheme ? color_scheme : colors}
        isInteractive={true}
      />
    </div>
  </div>
);

export default FirstBarChart;
