/* eslint-disable no-console */
import React, { useContext, useEffect, useState } from 'react';

import { ApplicationContext } from '../../context/AppContext';

import arrow_backward_d from '../../assets/images/arrow_backward_d.svg';

import { AutoDriveApplicationContext } from '../../context/AutoDriveContext';

import { notification } from 'antd';
import {
  addRemoteAVCommand,
  deletePreset,
  savePreset,
  updatePreset,
} from '../../constants/Api';
import {
  AutoDriveTabs,
  MEDIUM_VALUE,
  noImplementCode,
} from '../../constants/constant';
import {
  DairyCommand,
  FeedLaneType,
  feedAmountValueType,
  operationType,
} from '../../lib/types';
import AppLoader from '../common/AppLoader';
import CommonPopAutoDrive from '../remote_drive_new/CommonPopAutoDrive';
import { RemoteDriveAppCtx } from '../remote_drive_new/RemoteDriveContext';

import {
  AUTO_DRIVE_ERROR,
  SELECTED_ROUTINE,
  SET_ACTIVE_AUTODRIVE_TAB,
  SET_ADD_STEP,
  SET_FEED_AMOUNT,
  SET_SELECTED_FEED_LANES,
  SET_SETUP_KEYS,
  SET_SHOW_IMPLEMENT,
  SET_SHOW_ROUTINE,
  SHOW_TRACTOR_COMMAND_LOADER,
} from '../remote_drive_new/actions';
import DairyImplementDetail from './DairyImplementDetail';
import DeletePreset from './DeletePreset';
import DairyLoader from './DiaryLoader';
import FeedLaneSelection from './FeedLaneSelection';
import FeedUpdate from './FeedUpdate';
import PresetList from './PresetList';
import ReviewAndStart from './ReviewAndStart';
import SaveChanges from './SaveChanges';
import SavePreset from './SavePreset';
import './dairyStyle.css';
import { SET_TRACTOR_IMPLEMENT_STATE } from '../../context/actions';
interface Props {
  handleResetCommand: (state: string) => void;
  setShowDairyEndAutoDrive: (show: boolean) => void;
  clearPreviewPath: (id: number) => void;
}
const DairyAutoDrive: React.FC<Props> = ({
  handleResetCommand,
  setShowDairyEndAutoDrive,
  clearPreviewPath,
}: Props) => {
  const { userDetails } = useContext(ApplicationContext);

  const { AutoDriveReducer } = useContext(AutoDriveApplicationContext);
  const [autoDriveState, autoDriveDispatch] = AutoDriveReducer;
  const { tractorImplementState, showImplement } = autoDriveState;
  const {
    RDReducer,
    commandsList,
    saveCommandTOLocalDB,
    clearAllLocalDBCommands,
    clearCommandApiHandler,
  } = useContext(RemoteDriveAppCtx);
  const [state, dispatch] = RDReducer;

  const [selectedImplement, setSelectedImplement] = useState(noImplementCode);
  const {
    selectedTractor,
    showTractorCommandLoader,
    showRoutine,
    showTractorStart,
    setUpKeys,
    activeAutoDriveTab,
    feedLanes,
    selectedFeedLanes,
    autoDriveError,
    feedAmount,
  } = state;
  const [showMixed, setShowMixed] = useState(false);
  const [showSaveOperation, setShowSaveOperation] = useState(false);
  const [operationName, setOperationName] = useState<string>('');
  const [speed, setSpeed] = useState<number>(4.5);
  const [selectedCommand, setSelectedCommand] = useState<DairyCommand | null>();
  const [showUpdateFeed, setShowUpdateFeed] = useState(false);
  const [showPresetDelete, setShowPresetDelete] = useState<boolean>(false);
  const [autoDriveLoader, setAutoDriveLoader] = useState(false);
  const [saveConfirm, setSaveConfirm] = useState<boolean>(false);
  const [newOrEditOperation, setNewOrEditOperation] =
    useState<operationType>('');
  const [selectedFeedLane, setSelectedFeedLane] =
    useState<FeedLaneType | null>(null);
  const [refreshOperations, setRefreshOperations] = useState(false);

  useEffect(() => {
    setOperationName('');
    if (selectedCommand && selectedCommand.command && selectedCommand.command) {
      const list = selectedCommand.command.lane_list;
      const offsetList = selectedCommand.command.offset_sequence;
      const uniqueArr = Object.keys(
        offsetList.reduce((hash: any, num: any) => {
          hash[num] = 1;
          return hash;
        }, {}),
      );
      uniqueArr.length > 1 && setShowMixed(true);
      uniqueArr.length === 1 && setShowMixed(false);
      const filteredData = feedLanes.filter((item: FeedLaneType) =>
        list.includes(item.name),
      );
      filteredData.map((item: FeedLaneType, index: number) => {
        item.feedAmount = offsetList[index] as feedAmountValueType;
      });
      dispatch({
        type: SET_SELECTED_FEED_LANES,
        payload: filteredData,
      });
      dispatch({
        type: SET_FEED_AMOUNT,
        payload: selectedCommand.command.feed_amount || MEDIUM_VALUE,
      });
      setOperationName(selectedCommand?.name);
    }
  }, [selectedCommand]);

  const addRoutineHandler = () => {
    dispatch({
      type: SET_ADD_STEP,
      payload: true,
    });
    autoDriveDispatch({
      type: SET_SHOW_IMPLEMENT,
      payload: false,
    });
    dispatch({
      type: SET_ACTIVE_AUTODRIVE_TAB,
      payload: AutoDriveTabs.Parameter,
    });
    clearPreviewPath(selectedTractor.id);
    clearAllLocalDBCommands(selectedTractor.id);
    // after clicking new command clear the auto_drive error
    dispatch({
      type: AUTO_DRIVE_ERROR,
      payload: {
        tractor_id: selectedTractor.id,
        message: '',
        errors: [],
      },
    });
    handleResetCommand('');
  };
  const getCommandData = (isFeedAmount = false) => {
    const laneNames: any[] = [];
    const offsetSequence: any[] = [];
    selectedFeedLanes.map((listItem: FeedLaneType) => {
      laneNames.push(listItem.name);
      offsetSequence.push(listItem.feedAmount);
    });
    const commandData: any = {
      command: 'feedlane_sequence',
      lane_list: laneNames,
      offset_sequence: offsetSequence,
      // feed_amount: feedAmount,
    };
    if (isFeedAmount) {
      commandData.feed_amount = feedAmount;
    }
    return commandData;
  };

  const handleEditPreset = async () => {
    setAutoDriveLoader(true);
    try {
      const payload = {
        implement_uuid: selectedImplement,
        name: operationName,
        command: getCommandData(true),
      };
      const { msg: message } = await updatePreset(
        userDetails.organization.api_url,
        payload,
        selectedCommand?.preset_uuid as string,
      );
      setSaveConfirm(false);
      handleGeneratePath();
      // message &&
      //   notification.success({
      //     message,
      //   });
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    } finally {
      setAutoDriveLoader(false);
    }
  };
  const showTabs = () =>
    commandsList &&
    commandsList[selectedTractor.id] &&
    commandsList[selectedTractor.id].length > 0 &&
    showTractorStart &&
    showTractorStart[selectedTractor.id]
      ? true
      : false;

  // drag and drop code ends here

  useEffect(() => {
    if (activeAutoDriveTab === AutoDriveTabs.Parameter) {
      handleResetCommand('');
      commandsList &&
        commandsList[selectedTractor.id] &&
        commandsList[selectedTractor.id].length > 0 &&
        commandsList[selectedTractor.id].map((command: any, index: number) => {
          if (index + 1 === commandsList[selectedTractor.id].length) {
            commandClearHandler(command, true);
          }
        });
      // hide loader on tab change
      showTractorCommandLoader[selectedTractor.id] = false;
      dispatch({
        type: SHOW_TRACTOR_COMMAND_LOADER,
        payload: {
          ...showTractorCommandLoader,
        },
      });
    }
    if (activeAutoDriveTab === AutoDriveTabs.Block) {
      dispatch({
        type: SET_SETUP_KEYS,
        payload: {
          ...setUpKeys,
          isBlockSelected: false,
        },
      });
      // hide loader on tab change
      showTractorCommandLoader[selectedTractor.id] = false;
      dispatch({
        type: SHOW_TRACTOR_COMMAND_LOADER,
        payload: {
          ...showTractorCommandLoader,
        },
      });
      handleResetCommand('GOTO');

      commandsList &&
        commandsList[selectedTractor.id] &&
        commandsList[selectedTractor.id].length > 0 &&
        commandsList[selectedTractor.id].map((command: any, index: number) => {
          if (index + 1 === commandsList[selectedTractor.id].length) {
            commandClearHandler(command, true);
          }
        });
    }
  }, [activeAutoDriveTab]);
  const commandClearHandler = (command: any, stopClearInterrupt = false) => {
    // to clear preview path
    clearPreviewPath(selectedTractor.id);
    // call clear command api
    clearCommandApiHandler(selectedTractor.id, 'AutoRoutine');
    dispatch({
      type: SELECTED_ROUTINE,
      payload: null,
    });

    !stopClearInterrupt &&
      dispatch({
        type: AUTO_DRIVE_ERROR,
        payload: {
          tractor_id: selectedTractor.id,
          message: '',
          errors: [],
        },
      });
    // if "Add Routine" selected toggle the routine selection popup
    command.command === 'routine' &&
      tractorImplementState[selectedTractor.id] &&
      dispatch({
        type: SET_SHOW_ROUTINE,
        payload: !showRoutine,
      });
    // if goto point select the goto section
    if (
      command.command !== 'incomplete_operation' &&
      command.command !== 'routine'
    ) {
      handleResetCommand('GOTO');
    }
  };
  const handleGeneratePath = () => {
    dispatch({
      type: SET_SETUP_KEYS,
      payload: {
        ...setUpKeys,
        isLastTime: true,
      },
    });
    addDairyFeedRVCommand();
    showTractorCommandLoader[selectedTractor.id] = true;
    dispatch({
      type: SHOW_TRACTOR_COMMAND_LOADER,
      payload: {
        ...showTractorCommandLoader,
      },
    });
  };

  const addDairyFeedRVCommand = async (normal = false, command?: any) => {
    setAutoDriveLoader(true);
    try {
      const { organization } = userDetails;
      if (organization && organization.api_url) {
        const commandData = normal ? command : getCommandData();
        await addRemoteAVCommand(
          organization.api_url,
          [commandData],
          selectedTractor?.heartbeat?.drive_action_details
            ?.current_drive_action as string,
        );
        commandData.name = 'Feed';
        !normal &&
          selectedTractor &&
          selectedTractor.id &&
          saveCommandTOLocalDB(selectedTractor.id, commandData);
      }
    } catch (err: any) {
      notification.error({
        message: err.message,
        duration: 1,
      });
    } finally {
      setAutoDriveLoader(false);
    }
  };
  const handleSavePreset = async () => {
    setAutoDriveLoader(true);

    try {
      const payload = {
        implement_uuid: selectedImplement,
        name: operationName,
        command: getCommandData(true),
      };
      const resp = await savePreset(userDetails.organization.api_url, payload);
      setSelectedCommand(resp);
      setShowSaveOperation(false);
      handleGeneratePath();
      // resp.msg &&
      //   notification.success({
      //     message: resp.msg,
      //   });
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    } finally {
      setAutoDriveLoader(false);
    }
  };

  // after interrupt message close pop click
  const handleInterruptCloseHandler = (state: boolean, error: number) => {
    // ok button click
    if (!state) {
      setNewOrEditOperation('');
      clearCommandApiHandler(selectedTractor.id, 'routine');
      dispatch({
        type: AUTO_DRIVE_ERROR,
        payload: {
          tractor_id: selectedTractor.id,
          message: '',
          errors: [],
        },
      });
      dispatch({
        type: SET_ACTIVE_AUTODRIVE_TAB,
        payload: AutoDriveTabs.Parameter,
      });
      // if (showPresentCommand === 'GOTO') {
      showTractorCommandLoader[selectedTractor.id] = false;
      dispatch({
        type: SHOW_TRACTOR_COMMAND_LOADER,
        payload: {
          ...showTractorCommandLoader,
        },
      });
      // clearing the all data and redirect to implement change
      if (error === 7103) {
        autoDriveDispatch({
          type: SET_SHOW_IMPLEMENT,
          payload: true,
        });
        tractorImplementState[selectedTractor.id] = false;
        autoDriveDispatch({
          type: SET_TRACTOR_IMPLEMENT_STATE,
          payload: tractorImplementState,
        });
        commandsList &&
          commandsList[selectedTractor.id] &&
          commandsList[selectedTractor.id].length > 0 &&
          commandsList[selectedTractor.id].map(
            (command: any, index: number) => {
              if (index + 1 === commandsList[selectedTractor.id].length) {
                commandClearHandler(command);
              }
            },
          );
        dispatch({
          type: SET_SELECTED_FEED_LANES,
          payload: [],
        });
      }

      // }
    } else {
      // try again button click
      commandsList &&
        commandsList[selectedTractor.id] &&
        commandsList[selectedTractor.id].length > 0 &&
        commandsList[selectedTractor.id].map((command: any, index: number) => {
          if (index + 1 === commandsList[selectedTractor.id].length) {
            command['cmdIndex'] && delete command['cmdIndex'];

            addDairyFeedRVCommand(true, command);
          }
        });
      dispatch({
        type: AUTO_DRIVE_ERROR,
        payload: {
          tractor_id: selectedTractor.id,
          message: '',
          errors: [],
        },
      });
    }
  };
  const handleDeletePreset = async () => {
    setAutoDriveLoader(true);
    try {
      const { msg: message } = await deletePreset(
        userDetails.organization.api_url,
        selectedCommand?.preset_uuid as string,
      );
      if (message) {
        // notification.success({
        //   message,
        // });

        setShowPresetDelete(false);
        setRefreshOperations(!refreshOperations);
      }
    } catch (err: any) {
      notification.error({
        message: err.message,
        duration: 1,
      });
    } finally {
      setAutoDriveLoader(false);
    }
  };

  if (showImplement && !tractorImplementState[selectedTractor.id])
    return (
      <>
        <DairyImplementDetail
          setSelectedImplement={setSelectedImplement}
          selectedImplement={selectedImplement}
          addRoutineHandler={addRoutineHandler}
          setShowDairyEndAutoDrive={setShowDairyEndAutoDrive}
          setSpeed={setSpeed}
          speed={speed as number}
          setNewOrEditOperation={setNewOrEditOperation}
        />
      </>
    );
  return (
    <>
      <div className="rautoDriveRightSec feed_pusher_container">
        <div className="exit_autodrive d_Button3">
          <div
            onClick={() => setShowDairyEndAutoDrive(true)}
            className="pointer"
          >
            <img src={arrow_backward_d} />
            <span>Exit Autodrive</span>
          </div>
          <div>
            <span className="beta-btn">BETA</span>
          </div>
        </div>
        {/* ************************ preset operations section start here  */}
        {!showTabs() && activeAutoDriveTab === AutoDriveTabs.Parameter && (
          <PresetList
            setNewOrEditOperation={setNewOrEditOperation}
            setSelectedImplement={setSelectedImplement}
            setShowPresetDelete={setShowPresetDelete}
            selectedImplement={selectedImplement}
            setSelectedCommand={setSelectedCommand}
            refreshOperations={refreshOperations}
            setAutoDriveLoader={setAutoDriveLoader}
            setShowMixed={setShowMixed}
          />
        )}

        {/* ************************ preset operations section end here  */}

        {/* ************************ Select Lanes section start here  */}
        {!showTractorCommandLoader[selectedTractor.id] &&
          !showTabs() &&
          activeAutoDriveTab === AutoDriveTabs.Block && (
            <FeedLaneSelection
              setShowSaveOperation={setShowSaveOperation}
              setSaveConfirm={setSaveConfirm}
              newOrEditOperation={newOrEditOperation}
              setSelectedFeedLane={setSelectedFeedLane}
              setShowUpdateFeed={setShowUpdateFeed}
              showMixed={showMixed}
              setShowMixed={setShowMixed}
              setSelectedCommand={setSelectedCommand}
              setOperationName={setOperationName}
            />
          )}
        {/* ************************ Select Lanes section end here  */}

        {/* ************************ Review path section start here  */}

        {showTabs() && (
          <ReviewAndStart
            speed={speed as number}
            clearPreviewPath={clearPreviewPath}
            setNewOrEditOperation={setNewOrEditOperation}
            operationName={operationName}
            selectedCommand={selectedCommand as DairyCommand}
          />
        )}

        <div>
          {showSaveOperation && (
            <SavePreset
              showSaveOperation={showSaveOperation}
              setShowSaveOperation={setShowSaveOperation}
              handleSavePreset={handleSavePreset}
              handleGeneratePath={handleGeneratePath}
              operationName={operationName}
              setOperationName={setOperationName}
            />
          )}
          {showUpdateFeed && (
            <FeedUpdate
              showUpdateFeed={showUpdateFeed}
              setShowUpdateFeed={setShowUpdateFeed}
              selectedFeedLane={selectedFeedLane as FeedLaneType}
              selectedFeedLanes={selectedFeedLanes}
              setSelectedFeedLane={setSelectedFeedLane}
              setShowMixed={setShowMixed}
            />
          )}
          {showPresetDelete && (
            <DeletePreset
              showPresetDelete={showPresetDelete}
              setShowPresetDelete={setShowPresetDelete}
              handleDeletePreset={handleDeletePreset}
              presetName={selectedCommand?.name as string}
            />
          )}
          {saveConfirm && (
            <SaveChanges
              saveConfirm={saveConfirm}
              setSaveConfirm={setSaveConfirm}
              handleEditPreset={handleEditPreset}
              handleGeneratePath={handleGeneratePath}
            />
          )}
          {showTractorCommandLoader[selectedTractor.id] && (
            <DairyLoader
              loader={showTractorCommandLoader[selectedTractor.id]}
            />
          )}
          {showPresetDelete && (
            <DeletePreset
              showPresetDelete={showPresetDelete}
              setShowPresetDelete={setShowPresetDelete}
              handleDeletePreset={handleDeletePreset}
              presetName={selectedCommand?.name as string}
            />
          )}
          {showTractorCommandLoader[selectedTractor.id] && (
            <DairyLoader
              loader={showTractorCommandLoader[selectedTractor.id]}
            />
          )}
        </div>
        {autoDriveError &&
          autoDriveError.errors &&
          autoDriveError.errors.map((error: any) =>
            error == 7102 || error == 7101 || error == 7103 ? (
              <CommonPopAutoDrive
                key={error}
                showModal={true}
                closeModel={(state: boolean) => {
                  // first=> button return always false
                  // second => button return always true
                  handleInterruptCloseHandler(state, error);
                }}
                title={`${
                  error == 7102 || error == 7101
                    ? 'Tractor path could not be generated'
                    : ''
                }
                ${
                  error == 7103
                    ? 'Implement dimensions are not compatible with Autodrive.'
                    : ''
                }
                `}
                description={`${
                  error == 7102 || error == 7101
                    ? 'Please check to see if the correct starting row was selected or re-position the tractor close to the beginning of the first row and try again'
                    : ''
                }
                ${
                  error == 7103
                    ? ' Please select one of the compatible implements or contact Monarch.'
                    : ''
                }
                `}
                // for 7103 hide try again button
                showSecondButton={
                  error == 7103
                    ? false
                    : error == 7102 || error == 7101
                    ? true
                    : false
                }
              />
            ) : (
              <></>
            ),
          )}
        {/* {showEditConfirm && (
          <CommonPopAutoDrive
            showModal={true}
            closeModel={(state: boolean) => {
              // first=> button return always false
              // second => button return always true
              setShowEditConfirm(false);
              // state => false change the tab
              !state &&
                dispatch({
                  type: SET_ACTIVE_AUTODRIVE_TAB,
                  payload: AutoDriveTabs.Block,
                });
            }}
            title="Are You Sure?"
            description="This will reset all subsequent settings"
            firstButtonText="Yes, Edit Field"
            secondButtonText="Cancel"
          />
        )} */}
        {/* {showRoutineEditConfirm && (
          <CommonPopAutoDrive
            showModal={true}
            closeModel={(state: boolean) => {
              // first=> button return always false
              // second => button return always true
              setShowRoutineEditConfirm(false);
              // state => false change the tab
              commandForConfirm &&
                commandForConfirm.current &&
                !state &&
                editRoutineConfirm(commandForConfirm.current);
              //   dispatch({
              //     type: SET_ACTIVE_AUTODRIVE_TAB,
              //     payload: AutoDriveTabs.Block,
              //   });
            }}
            title="Are You Sure?"
            description="This will reset all subsequent settings"
            firstButtonText="Yes, Edit Field"
            secondButtonText="Cancel"
          />
        )} */}
        <AppLoader loader={autoDriveLoader} />
      </div>
    </>
  );
};

export default DairyAutoDrive;
