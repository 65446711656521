import React, { useState } from 'react';
import AutoDrivereport from './AutoDrivereport';
import DriveActions from './DriveActions';

const Index: React.FC = () => {
  // const [showDriveActions, setShowDriveActions] = useState<boolean>(false);
  const [selectedCustomer, setSelectedCustomer] = useState<any>(null);
  return (
    <div>
      <div
        style={{
          display: !selectedCustomer ? 'block' : 'none',
        }}
      >
        <AutoDrivereport
          handleSelectCustomer={(selectedCustomer: any) => {
            // setShowDriveActions(true);
            setSelectedCustomer(selectedCustomer);
          }}
        />
      </div>
      {selectedCustomer && (
        <DriveActions
          selectedCustomer={selectedCustomer}
          onBack={() => {
            setSelectedCustomer(null);
            // setShowDriveActions(false);
          }}
        />
      )}
    </div>
  );
};

export default Index;
