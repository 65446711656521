import axios from '../../../constants/ApiInterceptor';
import {
  getHeaders,
  sortKeyWrapper,
  // noneAttached,
  // sortAlphaNumber,
  // sortKeyWrapper,
} from '../../../constants/Common';
import {
  ARTICLE_API,
  CATEGORIES_API,
  COVERAGE_RANGE_API,
  FAQ_API,
  FLEET_INDICATORS_API,
  FREQUENT_SEARCHES_API,
  SEARCH_ARTICLE_API,
  SUBCATEGORIES_API,
} from './endpoints';

const BASE_URL = process.env.REACT_APP_BASE_URL;

// const { REACT_APP_COGNIITO_ACCESS_TOKEN_URL } = process.env;

const isEmpty = (value: any) => {
  if (typeof value === 'undefined') return true;
  if (typeof value === 'string' && value.trim() === '') return true;
  if (typeof value === 'object' && value === null) return true;
  if (typeof value === 'number' && value === 0) return true;
  return false;
};

const emptyFilter = (data: any) => (key: string) => !isEmpty(data[key]);

const getBaseURL = (url: string, id?: number): string =>
  `${url}organization${id ? `/${id}` : ''}`;

const getURL = (url: string): string => `${url.slice(0, -1)}`;

// const getFarmURL = (url: string, id?: number): string =>
//   `${url}farm${id ? `/${id}` : ''}`;

// const getFleetURL = (url: string, id?: number): string =>
//   `${url}fleet${id ? `/${id}` : ''}`;

// const getConverseURL = (url: string, id: number): string =>
//   `${url}converse/organization/${id}`;

export const fetchCall =
  (ENDPOINT: string) =>
  async (orgURL: string, orgId: number): Promise<any> => {
    const url = getBaseURL(orgURL, orgId) + ENDPOINT;
    const response = await getAPI(url);
    return response.data;
  };

export const fetchPaginateCall =
  (ENDPOINT: string) =>
  async (
    orgURL: string,
    orgId: number,
    page_number: number,
    page_size: number,
    search?: string,
  ): Promise<any> => {
    let url = getBaseURL(orgURL, orgId) + ENDPOINT;
    url += prepareQuery({
      page_number,
      page_size,
      q: search && encodeURI(search),
    });
    const response = await getAPI(url);
    return response.data;
  };

export const postCall =
  (ENDPOINT: string) =>
  async (orgURL: string, orgId: number, data: any): Promise<any> => {
    const url = getBaseURL(orgURL, orgId) + ENDPOINT;
    const response = await postAPI(url, data);
    return response.data;
  };

const prepareURL = (url: string, data: any = {}) =>
  Object.keys(data).length
    ? Object.keys(data).reduce(
        (a, c) => (a = a.replace(`:${c}`, data[c])) && a,
        url,
      )
    : url;

const prepareQuery = (data: any) =>
  Object.keys(data)
    .filter(emptyFilter(data))
    .reduce(
      (a, c, i, arr) =>
        (a += `${i === 0 ? '?' : ''}${c}=${data[c]}${
          arr.length - 1 !== i ? '&' : ''
        }`) && a,
      '',
    );

const postAPI = async (url: string, data: any) =>
  axios.post(url, data, {
    headers: await getHeaders(),
  });

const putAPI = async (url: string, data: any) =>
  axios.put(url, data, {
    headers: await getHeaders(),
  });

const getAPI = async (url: string) =>
  axios.get(url, {
    headers: await getHeaders(),
  });

const deleteAPI = async (url: string) =>
  axios.delete(url, {
    headers: await getHeaders(),
  });

export const getCategories = async (
  orgURL: string,
  parent_id: number,
): Promise<any> => {
  let url = getURL(orgURL) + CATEGORIES_API;
  url += prepareQuery({
    parent_id,
  });
  const response = await getAPI(url);
  return response.data;
};

export const getSubCategories = async (
  orgURL: string,
  parent_id: number,
): Promise<any> => {
  let url = getURL(orgURL) + SUBCATEGORIES_API;
  url += prepareQuery({
    parent_id,
  });
  const response = await getAPI(url);
  return response.data;
};

export const getArticle = async (
  orgURL: string,
  parent_id: number | string,
): Promise<any> => {
  let url = getURL(orgURL) + ARTICLE_API;
  url += prepareQuery({
    parent_id,
  });
  const response = await getAPI(url);
  return response.data;
};

export const getFaqs = async (orgURL: string): Promise<any> => {
  const url = getURL(orgURL) + FAQ_API;
  const response = await getAPI(url);
  return response.data;
};

export const searchArticle = async ({
  orgURL,
  search,
  category,
  file_type,
}: any): Promise<any> => {
  let url = getURL(orgURL) + SEARCH_ARTICLE_API;
  url += prepareQuery({
    q: search && encodeURI(search),
    category: category || '',
    file_type: file_type || '',
  });

  const response = await getAPI(url);
  return response.data;
};

export const getFleetIndicators = async (
  orgURL: string,
  parent_id: string,
  page_number: number,
  page_size: number,
  sort?: any,
): Promise<any> => {
  let url = getURL(orgURL) + FLEET_INDICATORS_API;
  url += prepareQuery({
    parent_id,
    page_number,
    page_size,
  });
  url = sortKeyWrapper(url, sort);
  const response = await getAPI(url);
  return response.data;
};

export const getFrequentSearches = async (orgURL: string) => {
  let url = getURL(orgURL) + FREQUENT_SEARCHES_API;
  url += prepareQuery({
    frequency: 'searches',
  });
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getCoverageRangePoints = async (
  orgId: number,
  zoomLevel: number,
) => {
  let url = BASE_URL + COVERAGE_RANGE_API;
  url += prepareQuery({
    customer_org_id: orgId,
    zoom_level: zoomLevel,
  });
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

// export const getBasestationList = async (
//   orgURL: string,
//   orgId: number,
//   page_number: number,
//   page_size: number,
//   search?: string,
//   sort?: any,
// ): Promise<any> => {
//   let url = getBaseURL(orgURL, orgId) + BASESTATION_API;
//   url += prepareQuery({
//     page_number,
//     page_size,
//     q: search && encodeURI(search),
//   });
//   url = sortKeyWrapper(url, sort);
//   const response = await getAPI(url);
//   return response.data;
// };
