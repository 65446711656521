/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import {
  notification,
  Spin,
  Select,
  Form,
  Button,
  Upload,
  Space,
  Radio,
} from 'antd';
import { useTranslation } from 'react-i18next';
import translate from '../../../../locale/en_translate.json';
import './style.css';
import {
  EXECUTE,
  SET_SELECTED_KEYS,
  SET_TOPIC_CREATE,
} from './UserManualReducer';
import { UserManualAppContext } from './UserManualContext';
import { FileUplaodS3Payload } from '../../common/types';
import { addTopicinTopic, UploadToS3, UploadToS3Status } from '../../API';
import CInput from '../../../common/CInput';
import TextArea from '../../../common/TextArea';
import ErrorCodes from '../../common/ErrorCodes';
import { randomNumber } from '../../../../constants/Common';

interface Props {
  chapterId: number;
  topicNumber: number;
  refresh: () => void;
}

const CreateTopicWidget: React.FC<Props> = ({
  chapterId,
  topicNumber,
  refresh,
}) => {
  const { t } = useTranslation();
  const { userManualReducer } = useContext(UserManualAppContext);
  const [state, dispatch] = userManualReducer;
  const [contentType, setContentType] = useState<string>('content');
  const [formData, setFormData] = useState<any>({});
  const [loader, setLoader] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [fileList, setFileList] = useState<any>([]);
  const [description, setDescription] = useState<string>('');
  const [toggleErrorCodes, setToggleErrorCodes] = useState<boolean>(false);
  const [fileAcceptable, setFileAcceptable] = useState<boolean>(false);
  const [errors, setErrors] = useState({
    title: true,
  });

  useEffect(() => {
    setIsDisabled(!Object.values(errors).every((item) => !item));
  }, [errors]);

  useEffect(
    () => () => {
      dispatch({ type: SET_SELECTED_KEYS, payload: [] });
    },
    [],
  );

  useEffect(() => {
    if (formData?.title && formData?.title.trim().length > 0) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [formData]);

  const addTopicMethod = async () => {
    try {
      setLoader(true);
      const data = new FormData();
      if (topicNumber) {
        data.append('parent_topic_id', String(topicNumber));
      }

      data.append('topic_number', String(topicNumber));
      data.append('description', description?.trim());
      data.append('indicator_error_codes', state.selectedKeys);

      if (fileList.length > 0) {
        // data.append('file', fileList[0].originFileObj);
        data.append('file_name', fileList[0].name);
        data.append('content_type', 'pdf');
      }

      Object.keys(formData).forEach((key) => {
        if (typeof formData[key] === 'string' && formData[key].trim().length)
          data.append(key, formData[key]);
        if (Array.isArray(formData[key]) && formData[key].length)
          data.append(key, formData[key]);
      });

      const response: any = await addTopicinTopic(chapterId, data);
      const { id, chapter_id, upload_file_response } = response;

      if (upload_file_response) {
        const {
          fields,
          url: s3ApiUrl,
        }: { url: string; fields: FileUplaodS3Payload | any } =
          upload_file_response;

        if (fields) {
          const s3FormData = Object.keys(fields).reduce((a, key) => {
            a.append(key, fields[key]);
            return a;
          }, new FormData());
          if (fileList.length > 0) {
            s3FormData.append('file', fileList[0].originFileObj);
          }
          try {
            const res = await UploadToS3(s3ApiUrl, s3FormData);
            if (res?.ok) {
              const { status } = res;
              if (status === 204) {
                const ups3StausPayload = {
                  file_name: fields?.key,
                };
                const { msg } = await UploadToS3Status(
                  chapter_id,
                  id,
                  ups3StausPayload,
                );
                notification.success({
                  message: msg || 'Topic Created Successfully',
                });
                dispatch({ type: SET_TOPIC_CREATE, payload: false });
                refresh();
              }
            }
          } catch (error: any) {
            notification.error({ message: error.message });
          }
        }
      } else if (response && response.id) {
        const { msg } = response;
        notification.success({
          message: msg || 'Topic Created Successfully',
        });
        refresh();
      }
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    } finally {
      setLoader(false);
    }
  };

  const onChange = (info: any) => {
    if (info.file.type === 'application/pdf') {
      // eslint-disable-next-line no-param-reassign
      info.file.status = 'done';
      // setFileList(info.fileList);
      setFileList([info.fileList[info.fileList.length - 1]]);
    }
    return false;
  };

  const handleTags = (fields: string, value: any) => {
    setFormData({
      ...formData,
      [fields]: value,
    });
  };

  const CTags = (name: string, title: string) => (
    <Form.Item label={title} className="mb16 w25">
      <Select
        mode="tags"
        style={{ width: '100%' }}
        placeholder={title}
        data-testid="keyWordsDropdownField-CreateTopicWidget"
        onChange={(tags) => handleTags(name, tags)}
      />
    </Form.Item>
  );

  const handleSubmit = () => {
    addTopicMethod();
  };

  const handleOnChange = (e: any) => {
    setContentType(e.target.value);
  };

  const beforeUpload = (file: any) => {
    if (file.type !== 'application/pdf') {
      notification.error({
        message: 'This file type is not accepted',
      });
      setFileAcceptable(false);
      return false;
    }
    setFileAcceptable(true);
    return true;
  };

  return (
    <>
      <Form.Item label="Title" className="mb16 w25">
        <CInput
          max={60}
          min={3}
          value={formData.title}
          data-testid="titleFieldTopic-CreateTopicWidget"
          name="title"
          onChange={(value: string) => {
            setFormData({ ...formData, title: value });
          }}
          hasError={(state) => setErrors({ ...errors, title: state })}
        />
      </Form.Item>
      <Form.Item>
        <Radio.Group onChange={handleOnChange} value={contentType}>
          <Radio
            value="file"
            data-testid="fileUploadRadioBox-CreateTopicWidget"
          >
            {' '}
            {t(translate.myTask.fileUpload)}{' '}
          </Radio>
          <Radio
            value="content"
            data-testid="contentRadioBox-CreateTopicWidget"
          >
            {' '}
            {t(translate.howToManual.content)}{' '}
          </Radio>
        </Radio.Group>
      </Form.Item>
      {contentType === 'content' ? (
        <TextArea
          description={description}
          setDescription={setDescription}
          readOnly={false}
          height={400}
          hideToolbar={false}
        />
      ) : (
        <Form.Item>
          <Upload
            listType="text"
            accept=".pdf"
            onChange={onChange}
            key={randomNumber()}
            beforeUpload={beforeUpload}
            maxCount={1}
            showUploadList={fileAcceptable}
          >
            <Button className="primary">
              {t(translate.buttons.UploadAFile)}
            </Button>
          </Upload>
          {fileAcceptable && fileList[0] && <div>{fileList[0]?.name}</div>}
        </Form.Item>
      )}
      {CTags('key_words', 'Keywords')}
      <Form.Item label={t(translate.label.errorCodes)} className="mb16 w25">
        <Button
          type="primary"
          onClick={() => setToggleErrorCodes(true)}
          data-testid="addErrorCodesLink-CreateTopicWidget"
        >
          {t(translate.buttons.addErrorCodes)}
        </Button>
      </Form.Item>
      <Form.Item className="mb16 w25">
        <Select
          mode="tags"
          style={{ width: '100%' }}
          // placeholder={t(translate.label.errorCodes)}
          disabled
          value={state.selectedKeys}
        />
      </Form.Item>
      <Form.Item>
        <Space>
          <Button
            htmlType="button"
            onClick={() =>
              dispatch({
                type: EXECUTE,
                payload: {
                  topicEdit: false,
                  topicCreate: false,
                },
              })
            }
          >
            {t(translate.buttons.cancel)}
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            disabled={isDisabled}
            onClick={handleSubmit}
          >
            {t(translate.buttons.save)}
          </Button>
        </Space>
      </Form.Item>
      <div className="edit-loader">
        {loader && (
          <div className="loader">
            <Spin size="large" />
          </div>
        )}
      </div>
      {toggleErrorCodes && (
        <ErrorCodes onClose={() => setToggleErrorCodes(false)} />
      )}
    </>
  );
};

export default CreateTopicWidget;
