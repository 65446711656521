import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Row, Tag, message, notification } from 'antd';
import Layout, { Content } from 'antd/lib/layout/layout';
import translate from '../../../locale/en_translate.json';
import InfiniteScrollTable from '../../common/InfiniteScrollTable';
import usePaginate from '../../../hooks/usePaginate';
import { ApplicationContext } from '../../../context/AppContext';
import { initScroller, tractorConstantsNew } from '../../../constants/Common';
import { getBaseStationStatus } from '../api';
import { useTranslation } from 'react-i18next';
import { mapBasestationStatusData } from '../Common';
import './style.css';
import CustomSelect from '../../common/CustomSelect';
import refreshIcon from '../../../assets/images/refreshNew.svg';
import { CSVLink } from 'react-csv';
import Download from './Download';
import CropOrgFilter from '../../common/CropOrgFilter';
import { connectionData } from '../../../constants/constant';
import BaseStationHealthModal from './BaseStationHealthModal';

const DOWNLOAD_SIZE = 100;
let columns: any;

const BasestationStatus = () => {
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [selectedOrgId, setSelectedOrgId] = useState<string[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [pageSize] = useState<any>(25);
  const [loading, setLoading] = useState(false);
  const [connection, setConnection] = useState<number>(1);
  const [subStatus, setsubStatus] = useState<number>(1);
  const [routerStatus, setrouterStatus] = useState<number>(1);
  const { filterData, setData, activity, hasMore, checkActivity } =
    usePaginate();
  const [csvData, setCSVData] = useState<any[]>([]);
  const [headers, setHeaders] = useState<any[]>([]);
  const [downloadData, setDownloadData] = useState({
    isDownloaded: false,
    percent: 0,
    status: '',
  });
  const [fileDownloadStatus, setFileDownloadStatus] = useState<boolean>(false);
  const init = useRef<boolean>(true);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [baseStationHealthDetails, setBaseStationHealthDetails] =
    useState<any>('');

  const wrapResult = async (
    OrgUrl: any,
    selectedOrgId: any,
    pageNumber: number,
    pageSize: number,
    connection: any,
    subStatus: any,
    routerStatus: any,
  ) => {
    const result = await getBaseStationStatus(
      OrgUrl,
      selectedOrgId,
      pageNumber,
      pageSize,
      connection,
      subStatus,
      routerStatus,
    );
    return {
      result,
    };
  };

  const getbaseStationList = async () => {
    try {
      if (checkActivity()) initScroller();
      setLoading(true);
      const { result } = await wrapResult(
        userDetails?.organization.api_url,
        selectedOrgId,
        pageNumber,
        pageSize,
        connection,
        subStatus,
        routerStatus,
      );

      setTotal(result?._metadata?.total_records_count);
      const { records } = result;
      let data = Array.isArray(records) ? records : [];
      data = mapBasestationStatusData(data);
      setData(data);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    } finally {
      setLoading(false);
    }
  };
  const loadBaseStation = () => {
    if (userDetails && !userDetails.organization) return;
    if (checkActivity()) {
      // eslint-disable-next-line no-unused-expressions
      pageNumber !== 1 ? setPageNumber(1) : getbaseStationList();
    } else if (activity.current === 'paginate' || activity.current === '') {
      getbaseStationList();
    }
  };

  const handleOrg = (ids: any) => {
    activity.current = 'filter';
    setSelectedOrgId(ids);
  };

  const handleLoadMore = () => {
    if (pageNumber === 1 && checkActivity() && document) initScroller();

    activity.current = 'paginate';
    setPageNumber(pageNumber + 1);
  };

  const handleRefresh = () => {
    activity.current = 'refresh';
    loadBaseStation();
  };
  useEffect(() => {
    if (
      userDetails &&
      userDetails.organization &&
      selectedOrgId.length > 0 &&
      init.current
    ) {
      loadBaseStation();
      init.current = false;
    }
  }, [userDetails, selectedOrgId]);

  useEffect(() => {
    if (selectedOrgId.length === 0) {
      setData([]);
      setTotal(0);
      setCSVData([]);
    } else {
      loadBaseStation();
    }
  }, [pageNumber]);

  useEffect(() => {
    columns = [
      {
        title: t(translate.healthData.name),
        dataIndex: 'name',
        key: 'name',
        onCell: (record: any) => ({
          onClick: () => getRow(record),
        }),
        render: (name: string) => <span>{name}</span>,
        width: '100px',
        ellipsis: true,
      },
      {
        title: `${t(translate.healthData.customerName)}`,
        dataIndex: 'customerName',
        key: 'customerName',
        onCell: (record: any) => ({
          onClick: () => getRow(record),
        }),
        ellipsis: true,
        render: (customerName: string) => <span>{customerName}</span>,

        width: '180px',
      },
      {
        title: `${t(translate.healthData.model)}`,
        dataIndex: 'model',
        key: 'model',
        onCell: (record: any) => ({
          onClick: () => getRow(record),
        }),
        width: '100px',
      },
      {
        title: `${t(translate.healthData.serialNumber)}`,
        dataIndex: 'serial_number',
        key: 'serial_number',
        onCell: (record: any) => ({
          onClick: () => getRow(record),
        }),
        width: '130px',
      },
      {
        title: `${t(translate.healthData.softwareVersion)}`,
        dataIndex: 'software_version',
        key: 'software_version',
        onCell: (record: any) => ({
          onClick: () => getRow(record),
        }),
        width: '150px',
        ellipsis: true,
      },
      {
        title: `${t(translate.healthData.lastUpdatedTime)}`,
        dataIndex: 'date_time',
        key: 'date_time',
        onCell: (record: any) => ({
          onClick: () => getRow(record),
        }),
        width: '180px',
      },
      {
        title: `${t(translate.healthData.activeNetwork)}`,
        dataIndex: 'active_network',
        key: 'active_network',
        onCell: (record: any) => ({
          onClick: () => getRow(record),
        }),
        width: '100px',
      },
      {
        title: `${t(translate.healthData.routerstatus)}`,
        dataIndex: 'router_status',
        key: 'router_status',
        onCell: (record: any) => ({
          onClick: () => getRow(record),
        }),
        width: '110px',
      },
      {
        title: `${t(translate.healthData.sbcstatus)}`,
        dataIndex: 'sbc_status',
        key: 'sbc_status',
        onCell: (record: any) => ({
          onClick: () => getRow(record),
        }),
        width: '110px',
      },
      {
        title: `${t(translate.healthData.status)}`,
        dataIndex: 'status',
        key: 'status',
        onCell: (record: any) => ({
          onClick: () => getRow(record),
        }),
        width: '120px',
        render: (status: any, record: any) => {
          const { isAvailable } = record;
          return (
            <span
              className={`fleetMsgTxt ${
                isAvailable === 'online'
                  ? tractorConstantsNew.available.class
                  : tractorConstantsNew.off.class
              }`}
            >
              {isAvailable === 'online' ? 'On' : 'Off'}
            </span>
          );
        },
      },
      {
        title: t(translate.healthData.rtkConfigured),
        dataIndex: 'rtkConfigured',
        key: 'rtkConfigured',
        onCell: (record: any) => ({
          onClick: () => getRow(record),
        }),
        width: '120px',
        ellipsis: true,
      },
      {
        title: `${t(translate.healthData.tractorsConnected)}`,
        dataIndex: 'tractorsConnected',
        key: 'tractorsConnected',
        width: '150px',
        onCell: (record: any) => ({
          onClick: () => getRow(record),
        }),
        render: (tractors: Array<string>, dataitem: any) =>
          tractors &&
          tractors?.length > 0 &&
          tractors?.map((tractor: any) => (
            <Tag color="blue" key={tractor} style={{ marginTop: 4 }}>
              {tractor}
            </Tag>
          )),
      },
    ];
    if (columns) {
      const header = columns.map((column: any) => ({
        label: column.title,
        key: column.dataIndex,
      }));
      setHeaders(header);
    }
  }, []);

  const delay = (ms = 1000) =>
    new Promise((resolve) => setTimeout(resolve, ms));

  useEffect(() => {
    const { status } = downloadData || {};
    const download = async () => {
      const doc = document;
      const element: any = doc.querySelector(`#BaseSttionStatus`);
      if (element) {
        element?.click();
      }
      await delay();
      setFileDownloadStatus(false);
    };
    if (['success', 'exception'].includes(status)) download();
  }, [downloadData]);

  const handleDownload = async () => {
    setFileDownloadStatus(true);
    try {
      if (downloadData.status === 'start') return;
      setDownloadData({
        ...downloadData,
        status: 'start',
        percent: 10,
      });
      let data: any[] = [];
      const pages = Math.ceil(total / DOWNLOAD_SIZE);
      for (const page of Array.from({ length: pages }, (_, i) => i + 1)) {
        const { result } = await wrapResult(
          userDetails?.organization.api_url,
          selectedOrgId,
          page,
          DOWNLOAD_SIZE,
          connection,
          subStatus,
          routerStatus,
        );
        const { records } = result;
        let tdata = Array.isArray(records) ? records : [];
        tdata = mapBasestationStatusData(tdata);
        data = [...data, ...tdata];
        setDownloadData({
          ...downloadData,
          status: 'start',
          percent: Math.round((data.length / total) * 100),
        });
      }

      setCSVData([...data]);
      setDownloadData({
        ...downloadData,
        percent: 100,
        status: 'success',
      });
    } catch (error: any) {
      setDownloadData({
        ...downloadData,
        status: 'exception',
      });
      notification.error({ message: error.message });
    }
  };

  const handleDownloadData = () => {
    activity.current = 'filter';
    if (userDetails && selectedOrgId.length) {
      handleDownload();
    } else {
      message.warning('Please select an Orgnizations');
      setData([]);
      setCSVData([]);
    }
  };

  const handleSubmit = () => {
    activity.current = 'filter';
    if (userDetails && selectedOrgId.length) {
      loadBaseStation();
    } else {
      message.warning('Please select an Orgnizations');
      setData([]);
      setCSVData([]);
    }
  };
  const getRow = (record: any) => {
    setBaseStationHealthDetails(record);
    setShowModal(true);
  };

  return (
    <>
      <div className="basestation_container">
        <Row>
          <div className="common_wrapper_full">
            <div className="filters_card filter_inputs align_top">
              <div className="filtersHealthScreen">
                <CropOrgFilter handleOrgs={handleOrg} />

                <CustomSelect
                  label="status"
                  cssClass="min_width"
                  value={connection}
                  setValue={(id: any) => {
                    activity.current = 'filter';
                    setConnection(id);
                  }}
                  options={connectionData}
                  optionKey="value"
                  optionDisplay="name"
                  isAll
                />
                <CustomSelect
                  label="Sbc Status"
                  cssClass="min_width"
                  value={subStatus}
                  setValue={(id: any) => {
                    activity.current = 'filter';
                    setsubStatus(id);
                  }}
                  options={connectionData}
                  optionKey="value"
                  optionDisplay="name"
                  isAll
                />
                <CustomSelect
                  label="Router Status"
                  cssClass="min_width"
                  value={routerStatus}
                  setValue={(id: any) => {
                    activity.current = 'filter';
                    setrouterStatus(id);
                  }}
                  options={connectionData}
                  optionKey="value"
                  optionDisplay="name"
                  isAll
                />
                <Button
                  className="submitBtn"
                  onClick={handleSubmit}
                  loading={loading}
                >
                  {t(translate.buttons.submit)}
                </Button>
              </div>
              <div className="FleetStatusFilterIcons">
                <CSVLink
                  id="BaseSttionStatus"
                  style={{ display: 'none' }}
                  data={csvData}
                  filename={`BaseStation.csv`}
                  headers={headers}
                >
                  <div className="filterIcon">
                    <div className="downloadIcon" />
                  </div>
                </CSVLink>
                <div
                  className="filterIcon"
                  onClick={() => {
                    handleDownloadData();
                  }}
                >
                  <div className="downloadIcon" />
                </div>
                <img
                  src={refreshIcon}
                  onClick={handleRefresh}
                  height={32}
                  width={32}
                  style={{ marginLeft: '7px' }}
                />
              </div>
            </div>
            <div className="common_table">
              <InfiniteScrollTable
                columns={columns}
                loading={loading}
                hasMore={hasMore}
                filterData={filterData}
                totalcount={total}
                handleLoadMore={handleLoadMore}
                filename={total > 0 ? 'BaseStations' : 'BaseStation'}
              />
            </div>
          </div>
        </Row>
      </div>
      {fileDownloadStatus && (
        <Download
          loading={fileDownloadStatus}
          setLoading={setFileDownloadStatus}
          percent={downloadData?.percent || 0}
          status={downloadData?.status}
        />
      )}

      {baseStationHealthDetails && showModal && (
        <BaseStationHealthModal
          showModal={showModal}
          handleCancel={() => setShowModal(false)}
          baseStationHealthDetails={baseStationHealthDetails}
        />
      )}
    </>
  );
};

export default BasestationStatus;
