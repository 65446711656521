import { Feature } from 'ol';
import Geometry from 'ol/geom/Geometry';
import React, { useContext } from 'react';
import applicationIds from '../../locale/applicationIds.json';

import mapNor from '../../assets/images/mapNor.svg';
import mapSat from '../../assets/images/mapSat.svg';
import MapCenterIcon from '../../assets/images/map_recenter.svg';
import { track } from '../../util/logger';
import { RemoteDriveAppCtx } from '../remote_drive_new/RemoteDriveContext';

interface Props {
  selectedTractorId: number;
  recenterTractor: (selectedTractorId: number) => void;
  map: any;
  fieldFeatureItem: Feature<Geometry>;
  displayRecenterViewPortSetUp: (
    fieldFeatureItem: Feature<Geometry>,
    map: any,
  ) => void;
  toggleSatellite: boolean;
  setToggleSatellite: (toggleSatellite: boolean) => void;
}
const MapIcons: React.FC<Props> = ({
  selectedTractorId,
  recenterTractor,
  map,
  fieldFeatureItem,
  displayRecenterViewPortSetUp,
  toggleSatellite,
  setToggleSatellite,
}) => {
  const { RDReducer } = useContext(RemoteDriveAppCtx);
  const [state] = RDReducer;
  const { showRemoteDriveDetails, selectedTractor } = state;
  const handleZoom = (zoomType: string) => {
    if (map) {
      const view = map.getView();
      const zoom = view.getZoom();

      if (zoom) {
        if (zoomType === 'zoomIn') {
          view.setZoom(zoom + 1);
          track('Live Map', {
            event: `Map Zoom In ${zoom + 1}`,
          });
        } else {
          view.setZoom(zoom - 1);
          track('Live Map', {
            event: `Map Zoom Out ${zoom - 1}`,
          });
        }
      }
    }
  };

  return (
    <div
      className={
        showRemoteDriveDetails && selectedTractor && selectedTractor.id
          ? 'mapIconSec tglexpand'
          : 'mapIconSec'
      }
    >
      <div className="mapIcons">
        <div
          className="mapRecenter"
          data-testid="mapRecentreIcon-MapIcons"
          onClick={() => {
            if (Number(selectedTractorId)) {
              recenterTractor(Number(selectedTractorId));
            } else {
              map &&
                displayRecenterViewPortSetUp(
                  fieldFeatureItem as Feature<Geometry>,
                  map,
                );
            }
          }}
        >
          <img src={MapCenterIcon} />
        </div>

        <div className="zoom_in_out">
          <button
            id={applicationIds.liveMap.zoomInBtn}
            className="liveZoomIn"
            data-testid="zoomIn-MapIcons"
            onClick={() => handleZoom('zoomIn')}
          />
          <button
            id={applicationIds.liveMap.zoomOutBtn}
            className="liveZoomOut"
            data-testid="zoomOut-MapIcons"
            onClick={() => handleZoom('zoomOut')}
          />
        </div>
        <div className="mapView" data-testid="mapViewButton-MapIcons">
          <img
            id={applicationIds.liveMap.mapViewBtn}
            src={toggleSatellite ? mapNor : mapSat}
            onClick={() => {
              track('Live Map', {
                event: `${toggleSatellite ? 'Map View' : 'satellite View'}`,
              });
              setToggleSatellite(!toggleSatellite);
            }}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default MapIcons;
