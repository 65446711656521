export const SET_SELECTED_CHAPTER = 'SET_SELECTED_CHAPTER';
export const SET_SELECTED_TOPIC = 'SET_SELECTED_TOPIC';
export const SET_TOPIC_CREATE = 'SET_TOPIC_CREATE';
export const SET_TOPIC_EDIT = 'SET_TOPIC_EDIT';
export const SET_SELECTED_ERROR_CODES = 'SET_SELECTED_ERROR_CODES';
export const SET_SELECTED_KEYS = 'SET_SELECTED_KEYS';
export const EXECUTE = 'EXECUTE';
export const RESET = 'RESET';

interface Action {
  type: string;
  payload?: any;
}

interface IntialState {
  selectedChapter: any;
  selectedTopic: any;
  topicCreate: boolean;
  topicEdit: boolean;
  selectedErrorCodes: any[];
  selectedKeys: any[];
}

export const intialState: IntialState = {
  selectedChapter: null,
  selectedTopic: null,
  topicEdit: false,
  topicCreate: false,
  selectedErrorCodes: [],
  selectedKeys: [],
};

export default function reducer(state: IntialState, action: Action) {
  const { type, payload } = action;
  switch (type) {
    case SET_SELECTED_CHAPTER:
      return { ...state, selectedChapter: payload };
    case SET_SELECTED_TOPIC:
      return {
        ...state,
        selectedTopic: payload,
        topicCreate: false,
        topicEdit: false,
      };
    case SET_TOPIC_CREATE:
      return { ...state, topicCreate: payload, topicEdit: false };
    case SET_TOPIC_EDIT:
      return { ...state, topicEdit: payload, topicCreate: false };
    case SET_SELECTED_ERROR_CODES:
      return {
        ...state,
        selectedErrorCodes: payload,
      };
    case SET_SELECTED_KEYS:
      return {
        ...state,
        selectedKeys: payload,
      };
    case EXECUTE:
      return { ...state, ...payload };
    case RESET:
      return intialState;
    default:
      throw Error('invalid State');
  }
}
