import React, { useState, useContext, useEffect, useRef } from 'react';
import { Layout, Row, Space, Input, notification } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { SearchOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { ApplicationContext } from '../../context/AppContext';
import applicationIds from '../../locale/applicationIds.json';
import translate from '../../locale/en_translate.json';
import { getMaintenancelist } from '../../constants/Api';
import { initScroller } from '../../constants/Common';
import { MaintenanceStatus } from '../../constants/AppData';
import InfiniteScrollTable from '../common/InfiniteScrollTable';
import MaintenanceView from './MaintenanceView';
import CustomSelect from '../common/CustomSelect';
import usePaginate from '../../hooks/usePaginate';
import { mapMaintenance } from '../../lib/dataFormat';
import '../fleethealth/style.css';
import { track } from '../../util/logger';

export const Maintenancelist: React.FC = () => {
  const { t } = useTranslation();
  const [search, setSearch] = useState<string>('');
  const [total, setTotal] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [updateStatus, setUpdateStatus] = useState<string>('show_all');
  const { userDetails } = useContext(ApplicationContext);
  const [loading, setLoading] = useState<boolean>(true);
  const [mainflag, setMainflag] = useState<boolean>(false);
  const [initialize, setInitialize] = useState<boolean>(false);
  const searchString = useRef<string>('');
  const [pageSize] = useState<number>(25);
  const [mainRecord, setmainRecord] = useState<any>();

  const { filterData, setData, activity, hasMore, checkActivity } =
    usePaginate();

  useEffect(() => {
    if (userDetails && userDetails.organization) getMaintenance();
  }, [userDetails, pageNumber]);

  useEffect(() => {
    searchString.current = search;
    activity.current = 'search';

    if (userDetails && userDetails.organization && initialize) getMaintenance();
  }, [search]);

  useEffect(() => {
    if (initialize) {
      activity.current = 'filter';
      getMaintenance();
    }
  }, [updateStatus]);

  const getMaintenance = () => {
    if (userDetails && !userDetails.organization) return;
    if (checkActivity()) {
      pageNumber !== 1 ? setPageNumber(1) : getMain();
    } else if (activity.current === 'paginate' || activity.current === '') {
      getMain();
    }
  };

  const wrapResult = async (
    apiUrl: string,
    id: number,
    pageNumber: number,
    pageSize: number,
    search: string,
    updateStatus: string,
  ) => {
    const result = await getMaintenancelist(
      apiUrl,
      id,
      pageNumber,
      pageSize,
      search,
      updateStatus,
    );
    return {
      result,
      searchKey: search,
    };
  };

  const getMain = async () => {
    if (checkActivity()) initScroller();
    setLoading(true);
    try {
      const { result, searchKey } = await wrapResult(
        userDetails.organization.api_url,
        userDetails.organization.id,
        pageNumber,
        pageSize,
        search,
        updateStatus !== 'show_all' ? updateStatus : '',
      );

      if (searchString.current !== searchKey) return;

      const { _metadata } = result;
      setTotal(_metadata?.total_records_count);
      const { records } = result;
      let data = Array.isArray(records) ? records : [];
      data = mapMaintenance(data);
      setData(data);
    } catch (err: any) {
      notification.error({
        message: err?.message,
      });
    } finally {
      setLoading(false);
      if (!initialize) setInitialize(true);
    }
  };

  const handleLoadMore = () => {
    if (pageNumber === 1 && checkActivity() && document) initScroller();

    activity.current = 'paginate';
    setPageNumber(pageNumber + 1);
  };

  const handleSearch = (e: any) => {
    activity.current = 'search';
    const value = e.target.value.trim().regX;
    searchString.current = value;
    if (value.length <= 2) {
      searchString.current = '';
      if (search != '') setData([]);
      setSearch('');
    } else {
      searchString.current = value;
      setSearch(value);
      track('Maintenance', {
        event: `Search Maintenance : ${value}`,
      });
    }
  };

  const columns = [
    {
      title: `${t(translate.maintenance.tractor_name)}`,
      dataIndex: 'tractorName',
      key: 'tractorName',
    },
    {
      title: `${t(translate.maintenance.rulename)}`,
      dataIndex: 'rulename',
      key: 'rulename',
    },
    {
      title: `${t(translate.maintenance.status)}`,
      dataIndex: 'status',
      key: 'status',
      render: (status: string) => {
        return (
          <div>
            <span
              className={`statusMaintenance ${
                status === 'Open'
                  ? 'statusOpen'
                  : status === 'Due'
                  ? 'statusDue'
                  : 'statusCompleted'
              }`}
            >
              {status}
            </span>
          </div>
        );
      },
    },
    {
      title: `${t(translate.maintenance.datetime)}`,
      dataIndex: 'open_date_time',
      key: 'open_date_time',
    },
  ];
  const Close = () => {
    setMainflag(false);
    if (pageNumber === 1) {
      getMaintenance();
    } else {
      setPageNumber(1);
    }
  };

  const onRowClick = (record: any) => {
    setmainRecord(record);
    setMainflag(true);
  };

  return (
    <>
      <Layout>
        <div className="mainContent">
          <Content>
            <div className="tblContainer viewportContainer">
              <Row>
                <div className="common_wrapper wWidget_wrapper">
                  <div className="filters_card">
                    <Space size="large">
                      <Input
                        autoComplete="off"
                        addonBefore={<SearchOutlined />}
                        id={
                          applicationIds.personnelScreen
                            .softwareUpdateSearcchText
                        }
                        placeholder="Search Directory"
                        data-testid="searchInput-SoftwareUpdate"
                        onChange={(e) => handleSearch(e)}
                        className="common_search"
                      />

                      <CustomSelect
                        label="Status"
                        cssClass="min_width"
                        value={updateStatus}
                        setValue={(selected) => setUpdateStatus(selected)}
                        options={MaintenanceStatus}
                        optionKey="id"
                        optionDisplay="name"
                      />
                    </Space>
                  </div>

                  <div className="common_table">
                    <InfiniteScrollTable
                      columns={columns}
                      loading={loading}
                      hasMore={hasMore}
                      filterData={filterData}
                      totalcount={total}
                      handleLoadMore={handleLoadMore}
                      filename="Maintenance"
                      onRowClick={(record: any) => onRowClick(record)}
                    />
                  </div>
                </div>
              </Row>
            </div>
          </Content>
          {mainRecord && mainflag && (
            <MaintenanceView
              toggleWidget={mainflag}
              Close={Close}
              mainRecord={mainRecord}
            ></MaintenanceView>
          )}
        </div>
      </Layout>
    </>
  );
};

export default Maintenancelist;
