import React, { useContext, useEffect, useState } from 'react';
import { Layout, Row, Tabs } from 'antd';
import './library.css';
import RecordedVideo from './../recordedVideo/RecordedVideo';
import DayView from './../recordedVideo/DayView';
import VideoSnaps from './../recordedVideo/VideoSnaps';
import { useLocation } from 'react-router-dom';

const { TabPane } = Tabs;
export const Pages = {
  RecordedVideo: 'RecordedVideo',
  DayView: 'DayView',
  VideoSnaps: 'VideoSnaps',
};
import Snapshot from '../snapshot/Sanpshot';
import { track } from '../../util/logger';
import { privilagesConstants } from '../../constants/Privilages';
import { ApplicationContext } from '../../context/AppContext';

interface Props {
  transactionId: string;
}

const Library: React.FC<Props> = ({ transactionId }) => {
  const location = useLocation();
  const state: any = location.state;
  const { privilegeChecker } = useContext(ApplicationContext);
  const [currentPage, setCurrentPage] = useState(Pages.RecordedVideo);
  const [stateData, setStateData] = useState();
  const [tabId, setTabId] = useState<any>('Snapshot');

  useEffect(() => {
    if (state && (state === 'Snapshot' || state === 'Recorded Videos')) {
      setTabId(state);
    }
  }, [state]);

  return (
    <div className="libraryTab">
      <Tabs
        activeKey={tabId}
        centered
        id="library-tab-Library"
        onChange={(key) => {
          setTabId(key);
          track(key, {
            event: `${key} Tab`,
          });
        }}
        className=""
      >
        <TabPane tab="Snapshot" key="Snapshot">
          <Snapshot
            transactionId={transactionId}
            data-testid="snapshotTab-Library"
          />
        </TabPane>

        {privilegeChecker(privilagesConstants.Video_Library_Access) && (
          <TabPane tab="Recorded Videos" key="Recorded Videos">
            <div className="rcvideos_cont">
              {Pages &&
                Pages.RecordedVideo &&
                currentPage === Pages.RecordedVideo && (
                  <RecordedVideo
                    setCurrentPage={(page) => setCurrentPage(page)}
                    setStateData={(state) => setStateData(state)}
                    data-testid="recordedVideosTab-Library"
                  />
                )}
              {Pages && Pages.DayView && currentPage === Pages.DayView && (
                <DayView
                  setCurrentPage={(page) => setCurrentPage(page)}
                  stateData={stateData}
                  setStateData={(state) => setStateData(state)}
                />
              )}
              {Pages &&
                Pages.VideoSnaps &&
                currentPage === Pages.VideoSnaps && (
                  <VideoSnaps
                    setCurrentPage={(page) => setCurrentPage(page)}
                    stateData={stateData}
                    setStateData={(state) => setStateData(state)}
                  />
                )}
            </div>
          </TabPane>
        )}
      </Tabs>
    </div>
  );
};

export default Library;
