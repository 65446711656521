/* eslint-disable no-console */
import React from 'react';
import { CommonProgressBar } from './CommonProgressBar';
import schVerified from '../../assets/images/schVerified.svg';

interface Props {
  filterData: any;
}

export const UpToDateContainer: React.FC<Props> = ({ filterData }) => {
  const upToDate = () =>
    filterData.filter(
      (record: any) =>
        record?.ota_status === 'up_to_date' ||
        record?.ota_status === 'inprogress' ||
        record?.ota_status === 'in_progress' ||
        record?.ota_status === 'in-progress' ||
        record?.ota_status === 'pending',
    );

  const statusOrder = [
    'inprogress',
    'in_progress',
    'in-progress',
    'pending',
    'up_to_date',
  ];

  return (
    <div className="mainContainers1">
      <div className="tractorContainer padding1620">
        <div className="outOfTxt">Installations</div>
        <div className="tractorsLength">{upToDate().length}</div>
      </div>
      <div className="outBoxContainer">
        {upToDate().length > 0 ? (
          <>
            {upToDate()
              .sort(
                (a: any, b: any) =>
                  statusOrder.indexOf(a?.ota_status?.toLocaleLowerCase()) -
                  statusOrder.indexOf(b?.ota_status?.toLocaleLowerCase()),
              )
              .map((d: any) => (
                <>
                  {d?.ota_status === 'up_to_date' ? (
                    <div className="installationsCon " key={d.id}>
                      <div className="tractorContainer mb8">
                        <div className="numberTxt">{d?.name}</div>
                        <img src={schVerified} alt="" />
                      </div>
                      <div className="swUpdateTxt">
                        <span>Software</span>
                        {d?.softwareVersion}
                      </div>
                      <div className="swUpdateTxt">
                        <span>Firmware</span>
                        {d?.firmWareVersion}
                      </div>
                    </div>
                  ) : (
                    <div className="upToDateBox" key={d.id}>
                      <div className="mb8">
                        <div className="tractorContainer">
                          <div className="numberTxt">{d?.name}</div>
                        </div>
                        <CommonProgressBar data={d} install estTime />
                      </div>
                    </div>
                  )}
                </>
              ))}
          </>
        ) : (
          <div className="outOfBoxContent">
            Monitor your tractors install progress here
          </div>
        )}
      </div>
    </div>
  );
};
