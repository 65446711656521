import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  notification,
  Space,
  Divider,
  Radio,
  RadioChangeEvent,
  Spin,
} from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ApplicationContext } from '../../../../context/AppContext';
import { getBaseLogViewerFavouritesData } from '../../API';

const pageSize = 25;
interface DIDropdownList {
  data_index: number;
  name: string;
}

interface Props {
  // eslint-disable-next-line no-unused-vars
  handleDataIndexs: (model: string, indexs: any[]) => void;
}

const BasestationLogDataIndexList: React.FC<Props> = ({ handleDataIndexs }) => {
  const { userDetails, dealerDetails } = useContext(ApplicationContext);
  const [val, setVal] = useState<number>(0);
  const [selectedFav, setSelectedFav] = useState<any>(null);
  const [favourites, setFavourites] = useState<any[]>([]);
  const [dataIndexs, setDataIndexs] = useState<DIDropdownList[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [loader, setLoader] = useState<boolean>(false);

  const favMap = useCallback(
    () =>
      favourites.reduce((a, c) => {
        a.set(c.id, c);
        return a;
      }, new Map()),
    [favourites],
  );

  const favDropDown = useCallback(
    () =>
      favourites.map(({ id, name, attribute_key }) => (
        <Radio key={id} value={id}>
          {name} ({attribute_key})
        </Radio>
      )),
    [favourites],
  );

  useEffect(() => {
    const initLogFavouriteData = async () => {
      try {
        setLoader(true);
        const { id } = userDetails;
        const { records }: any = await getBaseLogViewerFavouritesData(
          dealerDetails?.id,
          pageNumber,
          pageSize,
          'bs_log_viewer',
        );
        if (records.length < pageSize) {
          setHasMore(false);
        } else {
          setHasMore(true);
        }
        if (pageNumber === 1) setFavourites(records);
        else setFavourites([...favourites, ...records]);
      } catch (error: any) {
        notification.error({ message: error.message });
      } finally {
        setLoader(false);
      }
    };
    if (userDetails?.id) {
      initLogFavouriteData();
    }
  }, [userDetails, pageNumber]);

  useEffect(() => {
    if (val) {
      const sFav = favMap().get(val);
      setSelectedFav(sFav);
      const { attribute_value_json } = sFav;
      setDataIndexs(attribute_value_json?.attribute_value_json || []);
    } else {
      setDataIndexs([]);
    }
  }, [val, setDataIndexs, favMap]);

  useEffect(() => {
    const tDataIndexs = dataIndexs.map(({ data_index, name }: any) => ({
      data_index,
      data_name: name,
    }));
    if (selectedFav) {
      handleDataIndexs(selectedFav?.attribute_key, tDataIndexs);
    }
  }, [dataIndexs]);

  const onChange = (e: RadioChangeEvent) => {
    setVal(e.target.value);
  };

  const handleLoadMore = () => {
    setPageNumber(pageNumber + 1);
  };

  return (
    <>
      <div className="ldil-model-block">
        <Space>
          <div>Model :</div>
          <div>{selectedFav?.attribute_key || ''}</div>
        </Space>
      </div>
      <Space align="start" size="large">
        <div className="dataIndexListLeft">
          <Radio.Group onChange={onChange} value={val}>
            <InfiniteScroll
              scrollThreshold={0.8}
              height={400}
              // height={window.innerHeight - 240}
              dataLength={favourites.length}
              next={() => handleLoadMore()}
              hasMore={hasMore}
              loader={
                <div style={{ width: '100%', textAlign: 'center' }}>
                  {' '}
                  <p>Loading...</p>
                </div>
              }
            >
              <Space direction="vertical">{favDropDown()}</Space>
            </InfiniteScroll>
          </Radio.Group>
        </div>
        <div>
          <Divider orientation="left">Data Indexes</Divider>
          <div className="dataIndexList">
            <pre>
              {dataIndexs.map((item: any) => (
                <div key={item.name}>{item?.name}</div>
              ))}
            </pre>
          </div>
        </div>
      </Space>
      <div className="edit-loader">
        {loader && (
          <div className="loader1">
            <Spin size="large" />
          </div>
        )}
      </div>
    </>
  );
};

export default BasestationLogDataIndexList;
