import React, { useContext, useEffect, useRef, useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Input, Row, Space, notification } from 'antd';
import Layout, { Content } from 'antd/lib/layout/layout';
import translate from '../../../locale/en_translate.json';
import InfiniteScrollTable from '../../common/InfiniteScrollTable';
import usePaginate from '../../../hooks/usePaginate';
import { ApplicationContext } from '../../../context/AppContext';
import { initScroller } from '../../../constants/Common';
import { getAllTractors, getOrgDropdown } from '../api';
import { useTranslation } from 'react-i18next';
import CustomMultiSelect from '../../common/CustomMultiSelect';
import { mapTractorsData } from '../Common';
import './style.css';

const Tractors: React.FC = () => {
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [loader, setLoader] = useState<boolean>(false);
  const searchString = useRef<string>('');
  const [selectedOrgId, setSelectedOrgId] = useState<string[]>([]);
  const [organizations, setOrganizations] = useState<string[]>([]);
  const [search, setSearch] = useState<string>('');
  const [total, setTotal] = useState<number>(0);
  const [pageSize] = useState<any>(25);
  const { filterData, setData, activity, hasMore, checkActivity } =
    usePaginate();
  //   const [sortInfo, setSortInfo] = useState<any>(null);
  const wrapResult = async (
    pageNumber: number,
    pageSize: number,
    search: string,
  ) => {
    const { organization } = userDetails;
    const result = await getAllTractors(
      organization.api_url,
      selectedOrgId,
      pageNumber,
      pageSize,
      search,
      //   sortInfo,
    );
    return {
      result,
      searchKey: search,
    };
  };

  const getAllOrganizations = async () => {
    try {
      const { api_url } = userDetails.organization;
      const result = await getOrgDropdown(api_url);
      const orgList = result.map((data: any) => ({
        title: <span className="ellipsis">{data.name}</span>,
        value: `${data.id}`,
      }));
      const defaultSelectedIds = result?.map((item: any) => String(item.id));
      setSelectedOrgId(defaultSelectedIds);
      setOrganizations(orgList);
    } catch (error: any) {
      notification.error({ message: error.message });
    }
  };

  const getTractorsList = async () => {
    try {
      if (checkActivity()) initScroller();
      setLoader(true);
      const { result, searchKey } = await wrapResult(
        pageNumber,
        pageSize,
        search,
      );
      if (searchString.current !== searchKey) return;
      // eslint-disable-next-line no-underscore-dangle
      setTotal(result._metadata.total_records_count);
      const { records } = result;
      const data = mapTractorsData(records);
      setData(data);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    } finally {
      //   if (!init.current) init.current = true;
      setLoader(false);
    }
  };

  const loadTractors = () => {
    if (userDetails && !userDetails.organization) return;
    if (checkActivity()) {
      // eslint-disable-next-line no-unused-expressions
      pageNumber !== 1 ? setPageNumber(1) : getTractorsList();
    } else if (activity.current === 'paginate' || activity.current === '') {
      getTractorsList();
    }
  };

  const handleOrg = (ids: any) => {
    setSelectedOrgId(ids);
    activity.current = 'filter';
  };

  const handleLoadMore = () => {
    if (pageNumber === 1 && checkActivity() && document) initScroller();

    activity.current = 'paginate';
    setPageNumber(pageNumber + 1);
  };

  const handleSearch = (e: any) => {
    activity.current = 'search';
    const value = e.target.value.trim();
    if (value.length <= 1) {
      searchString.current = '';
      setSearch('');
    } else {
      searchString.current = value;
      setSearch(value);
    }
  };

  useEffect(() => {
    if (selectedOrgId?.length === 0) {
      setData([]);
      setTotal(0);
    } else if (selectedOrgId.length) {
      loadTractors();
    }
  }, [userDetails, selectedOrgId, pageNumber, pageSize, search]);

  useEffect(() => {
    if (userDetails?.organization) getAllOrganizations();
  }, [userDetails]);

  const columnsData = [
    {
      title: `${t(translate.tractors.name)}`,
      dataIndex: 'name',
      key: 'name',
      render: (tractorName: string) => <span>{tractorName}</span>,
    },
    {
      title: `${t(translate.tractors.serialNumber)}`,
      dataIndex: 'serial_number',
      key: 'serial_number',
      render: (serialNumber: string) => <span>{serialNumber}</span>,
    },
    {
      title: `${t(translate.tractors.tractorPin)}`,
      dataIndex: 'tractor_pin',
      key: 'tractor_pin',
      render: (tractor_pin: any) => (
        <span className="emailAlign hoverWrapper">
          <span className="tractorPinStyle">{tractor_pin}</span>
          {/* {tractor_pin !== '-' && (
            <img
              //   src={copyIcon}
              alt="Edit"
              height="32"
              width="32"
              className="marginLeftIcon hoverShow1 on-hover-hand-change"
              onClick={() => {
                navigator.clipboard.writeText(tractor_pin);
                notification.info({ message: 'Copied' });
              }}
            />
          )} */}
        </span>
      ),
    },
    // {
    //   title: `${t(translate.tractors.radio_id)}`,
    //   dataIndex: 'radio_id',
    //   key: 'radio_id',
    //   width: '120px',
    // },
    // {
    //   title: `${t(translate.tractors.radio_channel_number)}`,
    //   dataIndex: 'radio_channel_number',
    //   key: 'radio_channel_number',
    //   width: '200px',
    // },
    // {
    //   title: `${t(translate.tractors.installation_key)}`,
    //   dataIndex: 'installation_key',
    //   key: 'installation_key',
    //   ellipsis: true,
    //   width: '210px',
    //   render: (installation_key: string) => (
    //     <span className="hoverWrapper installationkey">
    //       <span data-testid={`${installation_key}-InstallationKey`}>
    //         {installation_key}
    //       </span>
    //       <img
    //         // src={copyIcon}
    //         alt="Edit"
    //         height="32"
    //         width="32"
    //         className="marginLeftIcon hoverShow1 on-hover-hand-change"
    //         onClick={() => {
    //           navigator.clipboard.writeText(installation_key);
    //           notification.info({ message: 'Copied' });
    //         }}
    //       />
    //     </span>
    //   ),
    // },

    {
      title: `${t(translate.tractors.model)}`,
      dataIndex: 'model_name',
      key: 'model_name',
      render: (modelName: string) => <span>{modelName}</span>,
    },
    {
      title: `${t(translate.tractors.organization)}`,
      dataIndex: 'customer_organization_name',
      key: 'customer_organization_name',
      ellipsis: true,
      render: (orgName: string) => <span>{orgName}</span>,
    },

    {
      title: `${t(translate.tractors.dateAdded)}`,
      dataIndex: 'startTime',
      key: 'startTime',
      width: '180px',
    },
  ];

  //   const handleChange = (pagination: any, filters: any, sorter: any) => {
  //     activity.current = 'sort';

  //     const { columnKey, order } = sorter;
  //     // setSortedData({ ...sortedData, [columnKey]: order });
  //     setSortInfo(sorter);
  //   };
  return (
    <Layout className="posRel">
      <Content>
        <div className="tblContainer viewportContainer">
          <Row>
            <div className="common_wrapper wWidget_wrapper">
              <div className="filters_card spaceBtnAlignCenter personnelCard ad_filters_card">
                <div className="displayFlex">
                  <Space size="large">
                    {/* <Input
                      data-testid="searchDirectoryInputField-Employees"
                      addonBefore={<SearchOutlined />}
                      placeholder="Search Directory"
                      className="common_search"
                      onChange={(e) => handleSearch(e)}
                      autoComplete="off"
                    /> */}
                    <CustomMultiSelect
                      handleTreeSelect={handleOrg}
                      selectedId={selectedOrgId}
                      list={organizations}
                      label="Organizations"
                    />
                  </Space>
                </div>
              </div>
              <div className="common_table">
                <InfiniteScrollTable
                  columns={columnsData}
                  loading={loader}
                  hasMore={hasMore}
                  filterData={filterData}
                  totalcount={total}
                  handleLoadMore={handleLoadMore}
                  filename="Tractors"
                  //   onRowClick={(record) => getRow(record)}
                  //   onChange={handleChange}
                />
              </div>
            </div>
          </Row>
        </div>
      </Content>
    </Layout>
  );
};

export default Tractors;
