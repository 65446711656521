/* eslint-disable react/jsx-key */
import React, { useState } from 'react';
import { Select } from 'antd';
import FlagGrayIcon from '../../assets/images/flag_gray.svg';
import FlagOrangeIcon from '../../assets/images/flag_orange.svg';
import FlagRedIcon from '../../assets/images/flag_red.svg';
// import './style.css';

const { Option } = Select;

const levels = [
  { id: 1, name: '1' },
  { id: 2, name: '2' },
  { id: 3, name: '3' },
];

interface Props {
  label?: string;
  value?: any;
  cssClass: any;
  onSelect: (dates: any) => void;
  required?: boolean;
  testId?: any;
}

const CustomSeverityLevel: React.FC<Props> = ({
  label,
  value,
  cssClass,
  onSelect,
  required,
  testId,
}: Props) => {
  const [focus, setFocus] = useState(false);

  const labelClass =
    focus || (value && value.length !== 0) ? 'label label-float' : 'label';

  const handleOnChange = (data: any) => {
    onSelect(data);
  };

  const getSeverity = (level: number) => {
    let images;
    switch (level) {
      case 1:
        images = (
          <>
            <img src={FlagOrangeIcon} className="mr7" />
            <img src={FlagGrayIcon} className="mr7" />
            <img src={FlagGrayIcon} className="mr7" />
          </>
        );
        break;
      case 2:
        images = (
          <>
            <img src={FlagOrangeIcon} className="mr7" />
            <img src={FlagOrangeIcon} className="mr7" />
            <img src={FlagGrayIcon} className="mr7" />
          </>
        );
        break;
      case 3:
        images = (
          <>
            <img src={FlagRedIcon} className="mr7" />
            <img src={FlagRedIcon} className="mr7" />
            <img src={FlagRedIcon} className="mr7" />
          </>
        );
        break;
      default:
        break;
    }
    return <>{images}</>;
  };

  return (
    <div
      className="float-label"
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      <Select
        data-testid={testId}
        style={{ width: '100%' }}
        value={value}
        className={cssClass}
        onSelect={handleOnChange}
      >
        {levels.map((user) => (
          <Option
            key={user.id}
            value={user.id}
            data-testid={`${user.name}-CustomSeverityLevel`}
            className="severity"
          >
            {getSeverity(user.id)}{' '}
            <>
              {user?.id === 1 && <>Low</>}
              {user?.id === 2 && <>Medium</>}
              {user?.id === 3 && <>High</>}{' '}
            </>
          </Option>
        ))}{' '}
      </Select>
      <label className={labelClass}>
        {label} {required && <span className="float-span">*</span>}
      </label>
    </div>
  );
};

export default CustomSeverityLevel;
