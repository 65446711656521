import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import './style.css';
import rowWayPointer from '../../../assets/images/rowWayPointer.svg';
// import { ArrowLeftOutlined } from '@ant-design/icons';
import { ApplicationContext } from '../../../context/AppContext';
import { getGroundZero } from '../../../constants/Api';

import { View } from 'ol';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { Fill, Icon, Stroke, Style } from 'ol/style';
import * as proj from 'ol/proj';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import XYZ from 'ol/source/XYZ';
import OLMap from 'ol/Map';
import '../../map/style.css';
import ground_zero from '../../../assets/images/ground_zero.svg';

import { notification } from 'antd';
// import RoutesConstants from '../../../routes/RoutesConstant';
import {
  pathColorConstants,
  polygonHoverLabel,
  priority,
} from '../../../constants/Common';
import LineString from 'ol/geom/LineString';
import { getPolygons, PolygonReturns } from '../../map/Polygon';
import CameraGrid1 from '../../map/CameraGrid1';
import { useHistory } from 'react-router';

const tractorId = 14;
const tractorName = 'PS9';

const VideoFeed: React.FC = () => {
  //
  const mapElement: any = useRef();
  const mapSmallElement: any = useRef();
  const movTractRef = useRef<Feature<any> | null>(null);
  const movTractPathRef = useRef<Feature<any> | null>(null);
  const mapRef = useRef<OLMap | null>(null);
  const pathLayerRef = useRef<VectorLayer<any>>();
  const { userDetails } = useContext(ApplicationContext);
  const [mapInitialized, setMapInitialized] = useState(false);
  const [base, setBase] = useState<[number, number]>([0, 0]);
  const [openedView, setOpenedView] = useState(1);
  const greenPolygonsLayerRef = useRef<VectorLayer<any>>();
  const whitePolygonsLayerRef = useRef<VectorLayer<any>>();
  const grayPolygonsLayerRef = useRef<VectorLayer<any>>();
  const fieldFeatureItem = useRef<any>();
  const centreEnabledRef = useRef(true);

  const { push } = useHistory();

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      document.title = `${tractorName} Remote Drive`;
      initializeMap();
    }
  }, [userDetails]);

  /**
   * Polygons initialization Starts Here
   */
  useEffect(() => {
    if (mapInitialized) {
      setTimeout(async () => {
        const {
          fieldFeature,
          greenLayer,
          whiteLayer,
          grayLayer,
        }: PolygonReturns = await getPolygons(userDetails, base);
        // setPolygons(list);
        fieldFeatureItem.current = fieldFeature;
        if (whiteLayer) {
          mapRef.current?.addLayer(whiteLayer);
          whitePolygonsLayerRef.current = whiteLayer;
        }
        if (greenLayer) {
          mapRef.current?.addLayer(greenLayer);
          greenPolygonsLayerRef.current = greenLayer;
        }
        if (grayLayer) {
          mapRef.current?.addLayer(grayLayer);
          grayPolygonsLayerRef.current = grayLayer;
        }
        polygonHoverLabel(mapRef.current);
      }, 10);
    }
  }, [userDetails, mapInitialized, base]);

  //  mapInitialization start here
  const initializeMap = async () => {
    try {
      const { organization } = userDetails;
      const { latitude, longitude } = await getGroundZero(
        organization.api_url,
        organization.farm.id,
      );
      setBase([latitude, longitude]);

      const baseLocation = new Feature({
        geometry: new Point(proj.fromLonLat([longitude, latitude])),
      });
      baseLocation.set('name', 'Ground zero');
      //  set id 1 for base station recenter
      baseLocation.setId(1);
      const style = new Style({
        image: new Icon({
          src: ground_zero,
          scale: 0.2,
        }),
        zIndex: 100,
      });
      baseLocation.setStyle(style);

      const tracLocation = new Feature({
        geometry: new Point(proj.fromLonLat([longitude, latitude])),
      });

      tracLocation.setStyle(
        new Style({
          image: new Icon({
            src: rowWayPointer,
          }),
          zIndex: priority.TRACTOR,
        }),
      );

      const tractorStyle = new Style({
        // image: new CircleStyle({
        //   radius: 3,
        //   fill: new Fill({
        //     color: pathColorConstants.DEFAULT,
        //   }),
        // }),
        stroke: new Stroke({
          color: pathColorConstants.REMOTE_AV,
          width: 9,
        }),
        fill: new Fill({
          color: pathColorConstants.REMOTE_AV,
        }),
        zIndex: priority.PATH,
      });
      const tracPathLocation = new Feature({
        geometry: new LineString([proj.fromLonLat([longitude, latitude])]),
      });
      tracPathLocation.setStyle(tractorStyle);

      const initialFeaturesLayer = new VectorLayer({
        source: new VectorSource({
          features: [baseLocation, tracLocation, tracPathLocation],
        }),
        zIndex: Infinity,
      });

      const vectorLayer = new TileLayer({
        source: new OSM(),
        visible: false,
      });

      const satelliteLayer = new TileLayer({
        source: new XYZ({
          url: 'http://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}&s=Ga',
          cacheSize: 1000,
        }),
        visible: true,
      });
      // live command path layer
      const initalPathLayer = new VectorLayer({
        source: new VectorSource({
          features: [],
        }),
        visible: false,
        zIndex: priority.PREVIEWPATH,
      });

      // create map
      const initialMap = new OLMap({
        target: mapElement.current,
        layers: [
          satelliteLayer,
          vectorLayer,
          initialFeaturesLayer,
          initalPathLayer,
        ],
        controls: [],
        view: new View({
          projection: 'EPSG:3857',
          center: proj.transform(
            [longitude, latitude],
            'EPSG:4326',
            'EPSG:3857',
          ),
          zoom: 17,
          maxZoom: 25,
        }),
      });
      mapRef.current = initialMap;
      movTractRef.current = tracLocation;
      movTractPathRef.current = tracPathLocation;
      pathLayerRef.current = initalPathLayer;
      setMapInitialized(true);
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    }
  };

  const handleZoom = (zoomType: string) => {
    if (mapRef.current) {
      const view = mapRef.current.getView();
      const zoom = view.getZoom();

      if (zoom) {
        if (zoomType === 'zoomIn') {
          view.setZoom(zoom + 1);
          //   track('Live Map', {
          //     event: `Map Zoom In ${zoom + 1}`,
          //   });
        } else {
          view.setZoom(zoom - 1);
          //   track('Live Map', {
          //     event: `Map Zoom Out ${zoom - 1}`,
          //   });
        }
      }
    }
  };

  useEffect(() => {
    if (mapInitialized) {
      if (openedView === 1) {
        mapRef?.current?.setTarget(mapElement.current);
      } else {
        mapRef?.current?.setTarget(mapSmallElement.current);
      }
      mapRef.current?.updateSize();
    }
  }, [openedView, mapInitialized]);

  const cameraMap: any = {
    front: 'Front',
    rear: 'Rear',
    front_left: 'Front Left',
    front_right: 'Front Right',
    pto: 'Hitch',
  };

  const frameHTML: any = useCallback(
    (view: string) => (
      <>
        <h1 className="iframe-title"> {cameraMap[view]} </h1>
        <iframe
          frameBorder="0"
          className="responsive-iframe"
          src={`${origin}/CameraView/${tractorId}/stream/${tractorName}/${view}/false`}
        ></iframe>
      </>
    ),
    [],
  );

  // const goToFungicideSpraying = () => {
  //   push(RoutesConstants.fungicideSpraying);
  // };

  return (
    <>
      <div className="videoFeed">
        <div className="header">
          <div className="personnelSec">
            {/* <ArrowLeftOutlined onClick={goToFungicideSpraying} /> */}
          </div>
          <div>
            <h2>Fungicide Spraying Video Feed</h2>
            <h4>
              Control video playback by selecting a point on the map or sliding
              along the seek bar
            </h4>
          </div>
        </div>
        <div className="cameraContainer" style={{ display: 'block' }}>
          <div className="cameraWrapper cameraBackground">
            <CameraGrid1
              frameHTML={frameHTML}
              mapSmallElement={mapSmallElement}
              openedView={openedView}
              mapElement={mapElement}
              centreEnabledRef={centreEnabledRef}
              handleZoom={handleZoom}
              layout={1}
            />
          </div>
          {/* <div className="cameraControls">
            <img src={playerIcon} alt="" />
            <div className="layoutButtonSec">
              <button
                className={
                  layout === 1
                    ? 'layoutButton layout1 lsel'
                    : 'layoutButton layout1'
                }
                onClick={() => setLayout(1)}
              ></button>

              <button
                className={
                  layout === 3
                    ? 'layoutButton layout2 lsel'
                    : 'layoutButton layout2'
                }
                onClick={() => setLayout(3)}
              ></button>
              <button
                className={
                  layout === 2
                    ? 'layoutButton layout3 lsel'
                    : 'layoutButton layout3'
                }
                onClick={() => setLayout(2)}
              ></button>
            </div>
          </div> */}
        </div>
        <div className="remotecamerasBlk"></div>
      </div>
    </>
  );
};

export default VideoFeed;
