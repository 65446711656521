/* eslint-disable no-console */
// Import necessary packages and styles
import * as am4charts from '@amcharts/amcharts4/charts';
import '@amcharts/amcharts4/charts.js';
import * as am4core from '@amcharts/amcharts4/core';
import '@amcharts/amcharts4/core.js';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import moment from 'moment';
import React, { useEffect, useRef } from 'react';
import { selectedPath } from '../operationalAnalytics/common';
let gOnPathClick = false;
interface Props {
  data: any;
  chartId: string;
  selectedDate: any;
  onClick: (state: selectedPath) => void;
  setHasZoomed: (state: boolean) => void;
  setOnPathClick: (state: boolean) => void;
  onPathClick: boolean;
}
// Apply amCharts themes
am4core.useTheme(am4themes_animated);

const ProgressChart: React.FC<Props> = ({
  data,
  chartId,
  selectedDate,
  onClick,
  setHasZoomed,
  setOnPathClick,
  onPathClick,
}) => {
  const chartRef = useRef<any>(null);
  const xAxisRef = useRef<any>(null);
  useEffect(() => {
    gOnPathClick = onPathClick;
  }, [onPathClick]);
  useEffect(() => {
    setHasZoomed(false);
    // Create a chart instance
    const chart = am4core.create(chartId, am4charts.XYChart);
    chart.logo.disabled = true;
    chartRef.current = chart;

    chart.data = data;

    const yAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    yAxis.dataFields.category = 'category';

    // Set margins and paddings to zero
    chart.paddingTop = 0;
    chart.paddingBottom = 0;
    chart.strokeWidth = 0;
    // Set margins to zero (outer margin)
    chart.marginTop = 0;
    chart.marginBottom = 0;

    yAxis.renderer.grid.template.disabled = true;
    yAxis.renderer.labels.template.disabled = true;

    const xAxis = chart.xAxes.push(new am4charts.DateAxis());
    xAxis.renderer.grid.template.disabled = true;
    xAxis.renderer.grid.template.disabled = true;
    xAxis.renderer.labels.template.disabled = true;
    // if (data?.length > 0) {
    //   xAxis.skipEmptyPeriods = true;
    // }
    xAxis.baseInterval = {
      timeUnit: 'minute',
      count: 1,
    };
    chart.seriesContainer.draggable = false;
    chart.seriesContainer.resizable = false;

    // Create series
    const series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.dateX = 'to';
    series.dataFields.openDateX = 'from';
    series.dataFields.categoryY = 'category';
    series.columns.template.propertyFields.fill = 'color';
    series.columns.template.strokeOpacity = 0;
    series.columns.template.height = 6;
    series.columns.template.paddingTop = 12;
    series.columns.template.fillOpacity = 0.3;
    let hoverLabel: any;
    const tooltip = series.createChild(am4core.Tooltip);
    series.tooltip = tooltip;
    series.tooltip.zIndex = 999;
    series.tooltip.getFillFromObject = false;
    series.tooltip.background.fill = am4core.color('#ffffff');
    series.tooltip.background.cornerRadius = 10;
    series.tooltip.background.strokeWidth = 0;
    series.tooltip.label.fill = am4core.color('#000');
    series.tooltip.zIndex = 100000;
    series.tooltip.dy = -52;
    series.columns.template.events.on('over', (event: any) => {
      const dataItem = event.target.dataItem;
      if (dataItem && dataItem?._dataContext?.opName !== 'N/A') {
        event.target.cursorOverStyle = am4core.MouseCursorStyle.pointer;

        event.target.fillOpacity = 1;

        // Add "Review videos" label
        hoverLabel = event.target.createChild(am4core.Label);
        hoverLabel.text = 'Review videos';
        hoverLabel.fill = am4core.color('#B5B5B6');
        hoverLabel.fontFamily = 'Montserrat';
        hoverLabel.fontWeight = 500;
        hoverLabel.fontSize = 10;
        hoverLabel.fontStyle = 'normal';
        hoverLabel.lineHeight = 'normal';
        hoverLabel.align = 'center';
        hoverLabel.isMeasured = false;
        hoverLabel.y = am4core.percent(100);
        hoverLabel.dy = 10;
        // hoverLabel.y = chart.bottomAxesContainer.pixelHeight + 20;
        hoverLabel.verticalCenter = 'top';
        hoverLabel.zIndex = 2;
        const fromTime = chart.dateFormatter.format(
          dataItem._dataContext.from,
          'hh:mm a',
        );
        const toTime = chart.dateFormatter.format(
          dataItem._dataContext.to,
          'hh:mm a',
        );

        event.target.tooltipHTML = `<div class="bulletTooltip">
            <p class="tooltipDrive">Drive Session</p>
            <p class="tooltipUser"> ${dataItem._dataContext.opName}</p>
            <p class="tooltipTime">${fromTime} - ${toTime}</p>
        </div>`;
      }
    });

    series.columns.template.events.on('out', (event: any) => {
      // const dataItem = event.target.dataItem;
      // event.target.fill = am4core.color(dataItem.dataContext.color);
      event.target.fillOpacity = 0.3;
      if (hoverLabel) {
        hoverLabel.dispose();
        hoverLabel = null;
      }
    });
    // series.columns.template.zIndex = 5;

    series.columns.template.marginBottom = 0;
    series.columns.template.column.cornerRadiusTopLeft = 5;
    series.columns.template.column.cornerRadiusTopRight = 5;
    series.columns.template.column.cornerRadiusBottomLeft = 5;
    series.columns.template.column.cornerRadiusBottomRight = 5;

    if (data?.length > 0) {
      // Ranges/labels
      chart.events.on('beforedatavalidated', function (ev) {
        const data = chart?.data;
        for (let i = 0; i < data?.length; i++) {
          const range = xAxis.axisRanges.create();
          range.date = data[i].from;
          range.endDate = data[i].to;
          const fromTime = chart.dateFormatter.format(data[i].from, 'hh:mm a');
          const toTime = chart.dateFormatter.format(data[i].to, 'hh:mm a');
          if (data[i].operator !== 'N/A') {
            const bullet = new am4charts.AxisBullet();
            bullet.location = 0;
            range.bullet = bullet;
            const tooltip = bullet.createChild(am4core.Tooltip);
            bullet.tooltip = tooltip;
            bullet.tooltip.zIndex = 9999999;
            bullet.tooltip.getFillFromObject = false;
            bullet.tooltip.background.fill = am4core.color('#ffffff');
            bullet.tooltip.background.cornerRadius = 10;
            bullet.tooltip.background.strokeWidth = 0;
            bullet.tooltip.pointerOrientation = 'left';
            bullet.tooltip.label.fill = am4core.color('#000');
            bullet.tooltip.adapter.add('y', function (y, target) {
              if (y) {
                return (y = 20);
              }
            });
            bullet.tooltip.background.filters.clear();
            const shadow = new am4core.DropShadowFilter();
            shadow.blur = 6;
            shadow.dy = 4;
            shadow.dx = 0;
            shadow.opacity = 0.1;
            bullet.tooltip.background.filters.push(shadow);

            bullet.tooltipHTML = `<div class="bulletTooltip">
            <p class="tooltipDrive">Drive Session</p>
            <p class="tooltipUser"> ${data[i].opName}</p>
            <p class="tooltipTime">${fromTime} - ${toTime}</p>
        </div>`;
            bullet.location = 0;
            range.bullet = bullet;
            const circle = bullet.createChild(am4core.Circle);
            circle.events.on('over', function (event: any) {
              circle.stroke = am4core.color('#67afb7');
              circle.strokeWidth = 1;
            });
            circle.events.on('out', function (event: any) {
              circle.strokeWidth = 0;
            });
            circle.radius = 10;
            circle.fill = am4core.color('#EEEEEE');
            circle.strokeWidth = 0;
            range.grid.disabled = true;
            circle.horizontalCenter = 'middle';
            bullet.dy = -40;
            bullet.dx = 0;
            bullet.align = 'center';
            bullet.valign = 'middle';
            const label = bullet.createChild(am4core.Label);
            label.fill = am4core.color('#366376');
            label.text = data[i]?.operator;
            label.fontSize = 8;
            label.fontFamily = 'Montserrat';
            label.fontWeight = '600';
            label.horizontalCenter = 'middle';
            label.verticalCenter = 'middle';
          }
        }
      });
    }

    let toolValue: any = '';
    if (data?.length > 0) {
      xAxis.adapter.add('getTooltipText', function (text: any, target) {
        toolValue = text;
        return text;
      });
    }

    if (data?.length > 0) {
      series.columns.template.events.on('hit', function (ev: any) {
        if (ev?.target?.dataItem?.dataContext?.opName !== 'N/A') {
          const momentObj = moment(selectedDate).startOf('day');
          const amPm = toolValue?.split(' ')[1];
          const hour = Number(toolValue?.split(':')[0]);
          const minAndAmPm = toolValue?.split(':')[1];
          const minutes = Number(minAndAmPm?.split(' ')[0]);
          momentObj.hours(
            amPm && amPm?.toLowerCase() === 'pm' ? hour + 12 : hour,
          );
          momentObj.minutes(minutes);
          const epochTimeInMillis = momentObj.valueOf();
          setOnPathClick(!gOnPathClick);
          onClick({
            fromClick: 'chart',
            created_date_time: Date.parse(
              ev?.target?.dataItem?.dataContext?.from,
            ),
            drive_action_uuid:
              ev?.target?.dataItem?.dataContext?.drive_action_uuid,
            drive_action_uuid_id:
              ev?.target?.dataItem?.dataContext?.drive_action_uuid_id,
            from: Date.parse(ev?.target?.dataItem?.dataContext?.from),
            to: Date.parse(ev?.target?.dataItem?.dataContext?.to),
            operator: ev?.target?.dataItem?.dataContext?.operatorData,
          });
          setHasZoomed(true);
        }
      });
    }
    xAxisRef.current = xAxis;
    if (chart.zoomOutButton.label) {
      chart.zoomOutButton.label.text = 'Exit Drive Session';
      chart.zoomOutButton.background.fill = am4core.color('transparent');
      chart.zoomOutButton.background.strokeWidth = 0;
      chart.zoomOutButton.background.paddingBottom = 0;
      chart.zoomOutButton.cursorOverStyle = am4core.MouseCursorStyle.pointer;
      const hoverkey = chart.zoomOutButton.background.states.getKey('hover');
      if (hoverkey) {
        hoverkey.properties.fill = am4core.color('white');
        hoverkey.properties.fillOpacity = 0;
        chart.zoomOutButton.label.fill = am4core.color('#99999C');
        chart.zoomOutButton.label.fontWeight = '600';
        chart.zoomOutButton.label.fontSize = '10';
      }
      // Positioning buttons
      chart.zoomOutButton.marginRight = 0;
      chart.zoomOutButton.marginTop = -8;
      chart.zoomOutButton.zIndex = 1;
      chart.zoomOutButton.marginBottom = 50;

      const downkey = chart.zoomOutButton.background.states.getKey('down');
      if (downkey) {
        downkey.properties.fill = am4core.color('white');
        downkey.properties.fillOpacity = 0;
      }
      chart.zoomOutButton.background.fillOpacity = 0; // Set opacity to 0
      chart.zoomOutButton.background.strokeOpacity = 0; // Set stroke opacity to 0
    }

    // Clean up when the component is unmounted
    return () => {
      chart.dispose();
    };
  }, []); // Ensure this effect runs only once on mount
  useEffect(() => {
    // Update chart data when the data prop changes
    if (chartRef.current) {
      chartRef.current.data = data;
    }
  }, [data]);

  return (
    <>
      {data?.length > 0 && (
        <div className="startTime">
          {moment(data[0]?.from).format('hh:mm A')}
        </div>
      )}
      <div
        id={chartId}
        className="progressC"
        style={{
          width: '100%',
          height: '85px',
          marginLeft: '45px',
          marginRight: '40px',
          // zIndex: 2,
        }}
      />
      {data?.length > 0 && (
        <div className="endTime">
          {moment(data[data?.length - 1].to).format('hh:mm A')}
        </div>
      )}
    </>
  );
};

export default ProgressChart;
