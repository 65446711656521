import { getImplementsBySearch } from '../../constants/Api';

export const wrapResult = async (
  apiUrl: string,
  fleet_id: number,
  search: string,
) => {
  const result = await getImplementsBySearch(apiUrl, fleet_id, search);
  return {
    result,
    searchKey: search,
  };
};
