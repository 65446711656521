/* eslint-disable react/jsx-key */
import React, { useState } from 'react';
import { DatePicker } from 'antd';

const { RangePicker } = DatePicker;

interface Props {
  label?: string;
  value?: any;
  cssClass: any;
  onChange: (dates: any) => void;
  dateTime?: boolean;
  required?: any;
  validations?: any;
  format?: string;
  showTime?: boolean;
  allowClear?: boolean;
  disabledDateCount?: any;
  showTimes?: boolean;
  disabledMinutes?: any;
  disabledHours?: any;
  clearIcon?: any;
}

const CustomDate1: React.FC<Props> = ({
  label,
  value,
  cssClass,
  onChange,
  disabledDateCount,
  disabledMinutes,
  disabledHours,
  clearIcon,
  required,
}: Props) => {
  const [focus, setFocus] = useState(false);

  const labelClass =
    focus || (value && value.length !== 0) ? 'label label-float' : 'label';

  const handleOnChange = (dates: any) => {
    onChange(dates);
  };

  return (
    <div
      className="float-label"
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      <>
        <RangePicker
          format="YYYY-MM-DD HH:mm A"
          className={cssClass}
          onChange={handleOnChange}
          defaultValue={value}
          showTime={{ format: 'HH:mm A' }}
          disabledDate={disabledDateCount}
          disabledMinutes={disabledMinutes}
          disabledHours={disabledHours}
          clearIcon={clearIcon}
        />
        <label className={labelClass}>
          {label} {required && <span className="float-span">*</span>}
        </label>
      </>
    </div>
  );
};

export default CustomDate1;
