/* eslint-disable no-console */
import React, { memo, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  checkIsTractorConnected,
  checkIsTractorConnectedUserFromAtom,
  fleetListTabs,
  tractorConstantsNew,
} from '../../constants/Common';
import { TractorsList } from '../../constants/types';
import { ApplicationContext } from '../../context/AppContext';
import { AutoDriveApplicationContext } from '../../context/AutoDriveContext';
import translate from '../../locale/en_translate.json';
import AppLoader from '../common/AppLoader';
import GlobalPauseResume from '../remote_drive_new/globalPauseResume';
import { RemoteDriveAppCtx } from '../remote_drive_new/RemoteDriveContext';
import TractorCard from './TractorCard';

type Props = {
  tractors: any;
  setTractorTabes: (params: TractorsList) => void;
  keyFilter: string;
};
const TractorList: React.FC<Props> = ({
  tractors,
  setTractorTabes,
  keyFilter,
}) => {
  const { RDReducer, selectedTractorsIds } = useContext(RemoteDriveAppCtx);
  const [rdState] = RDReducer;
  const { showConnectLoader } = rdState;
  const { userDetails, APPReducer } = useContext(ApplicationContext);
  const [state] = APPReducer;
  const { t } = useTranslation();
  const { tractorsLoader } = state;
  const { AutoDriveReducer } = useContext(AutoDriveApplicationContext);
  const [autoDriveState] = AutoDriveReducer;
  const { drivingTractors } = autoDriveState;
  // const [errorMessage, setErrorMessage] = useState(true);
  const [filteredList, setFilteredList] = useState<TractorsList[]>([]);

  const isAutoDriveCond = (tractor: TractorsList) =>
    checkIsTractorConnectedUserFromAtom(tractor, userDetails.id) ||
    (selectedTractorsIds.includes(tractor.id) && showConnectLoader);
  useEffect(() => {
    const list = tractors.filter(
      (tractor: any) =>
        (keyFilter === fleetListTabs.fleetListTractorsList &&
          !isAutoDriveCond(tractor)) ||
        (keyFilter === fleetListTabs.fleetListAutoDriveList &&
          isAutoDriveCond(tractor)) ||
        (keyFilter === fleetListTabs.fleetListChargingList &&
          tractor.isAvailable === tractorConstantsNew.charging.key),
    );
    setFilteredList(list);
  }, [tractors]);

  return (
    <>
      {/* {filteredList &&
        filteredList.length > 0 &&
        filteredList.map((tractor: any) => {
          if (
            keyFilter === fleetListTabs.fleetListTractorsList ||
            (keyFilter === fleetListTabs.fleetListAutoDriveList &&
              (checkIsTractorConnected(tractor, userDetails.id) ||
                (selectedTractorsIds.includes(tractor.id) &&
                  showConnectLoader))) ||
            (keyFilter === fleetListTabs.fleetListChargingList &&
              tractor.isAvailable === tractorConstantsNew.charging.key)
          ) {
            errorMessage && setErrorMessage(false);
            return (
              <TractorCard
                key={tractor.id}
                tractor={tractor}
                setTractorTabes={(tractor) => setTractorTabes(tractor)}
                from={'FLEET_LIST'}
              />
            );
          }
        })} */}
      {filteredList && filteredList.length > 0 && (
        <>
          {keyFilter === fleetListTabs.fleetListAutoDriveList && (
            <div className="myautodriveBlk">
              <div className="myautodriveTitle">My Autodrives</div>
              {drivingTractors.length > 0 && <GlobalPauseResume />}
            </div>
          )}

          <div
            className={`${
              keyFilter === fleetListTabs.fleetListAutoDriveList
                ? 'childAccordion2 fleetOverallBlk'
                : ''
            }`}
          >
            {filteredList.map((tractor: any) => (
              <TractorCard
                key={tractor.id}
                tractor={tractor}
                setTractorTabes={(tractor) => setTractorTabes(tractor)}
                from={'FLEET_LIST'}
              />
            ))}
          </div>
          {keyFilter === fleetListTabs.fleetListAutoDriveList && (
            <div className="borderBottom" />
          )}
        </>
      )}
      {filteredList.length === 0 &&
        !tractorsLoader &&
        keyFilter === fleetListTabs.fleetListTractorsList && (
          <span className="no-inuse-tractors">
            {t(translate.label.noTractorsAvailable)}
            {/* {keyFilter === fleetListTabs.fleetListAutoDriveList &&
            t(translate.label.noTractorsConnected)} */}
          </span>
        )}
      {filteredList.length === 0 &&
        keyFilter === fleetListTabs.fleetListChargingList && (
          <span className="no-inuse-tractors">
            {t(translate.label.noTractorsCharged)}
          </span>
        )}
      {keyFilter === fleetListTabs.fleetListTractorsList && (
        <div className="ld">
          <AppLoader loader={tractorsLoader} />
        </div>
      )}
    </>
  );
};

export default memo(TractorList);
