/* eslint-disable react/jsx-key */
/* eslint-disable react/jsx-props-no-multi-spaces */
/* eslint-disable array-callback-return */
/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from 'react';
import { Button, Input, Modal, Spin, notification, DatePicker } from 'antd';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { SearchOutlined } from '@ant-design/icons';
import moment from 'moment';
import refesh_gray from '../../../../assets/images/refesh_gray.svg';
import { ApplicationContext } from '../../../../context/AppContext';
import { fromToDateWrapper } from '../../../../constants/Common';
import { getCanLogSignals, getCanLogSignalsGraphList } from '../../API';

const { RangePicker } = DatePicker;

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

interface Props {
  callback: (state: boolean) => void;
}

const CanlogViewer: React.FC<Props> = ({ callback }) => {
  const { userDetails } = useContext(ApplicationContext);
  const [canLogSignals, setCanLogSignals] = useState<any>([]);
  const [canLogGraph, setCanLogGraph] = useState<any>({
    labels: [],
    datasets: [],
  });
  const [signalValues, setSignalValues] = useState<any>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [search, setSearch] = useState<any>('');
  const [fromtime, setfromTime] = useState<any>(
    moment().subtract(5, 'day').toDate(),
  );
  const [totime, settoTime] = useState<any>(moment().toDate());

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      getCanLogSignalsList();
    }
  }, [userDetails, search]);

  const getCanLogSignalsList = async () => {
    try {
      const response = await getCanLogSignals(search);
      if (response?.data) {
        const records = response?.data.map((ele: any, index: any) => {
          const obj = {
            signal: ele,
            signalflag: false,
            index,
          };
          return obj;
        });
        setSignalValues([]);
        setCanLogSignals([]);
        const obj = {
          labels: [],
          datasets: [],
        };
        setCanLogGraph(obj);
        setCanLogSignals(records);
      }
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    }
  };

  useEffect(() => {
    if (
      userDetails &&
      userDetails.organization &&
      signalValues &&
      signalValues.length > 0
    ) {
      getCanLogSignalsGraph();
    }
  }, [userDetails, signalValues, fromtime, totime]);

  const getCanLogSignalsGraph = async () => {
    const fromDate = moment(fromtime).format('YYYY-MM-DD HH:mm:ss');
    const toDate = moment(totime).format('YYYY-MM-DD HH:mm:ss');
    try {
      setLoader(true);
      const response = await getCanLogSignalsGraphList(
        signalValues,
        fromDate,
        toDate,
      );

      if (response?.body) {
        const labels: string[] = [];
        const datasets1: any[] = [];

        // Map through each data object from the API
        response?.body.forEach((data: any) => {
          // Extract hour and minute from the time

          const time = new Date(data.time);
          const hours = time.getUTCHours().toString().padStart(2, '0');
          const minutes = time.getUTCMinutes().toString().padStart(2, '0');
          const seconds = time.getUTCSeconds().toString().padStart(2, '0');
          const HHmmss = `${hours}:${minutes}:${seconds}`;

          // If the label doesn't exist in labels array, add it
          if (!labels.includes(HHmmss)) {
            labels.push(HHmmss);
          }

          // Push data points into the respective datasets
          Object.keys(data).forEach((key, index) => {
            if (key !== 'time') {
              if (!datasets1[index]) {
                datasets1[index] = {
                  label: key,
                  fill: false,
                  lineTension: 0.5,
                  backgroundColor: 'black',
                  borderColor: 'rgba(0,0,0,1)',
                  borderWidth: 2,
                  data: [],
                };
              }
              if (data[key]) {
                datasets1[index].data.push(data[key]);
              }
            }
          });
        });
        if (datasets1 && datasets1.length > 0) {
          const datasets = datasets1.filter((item: any) => item !== null);
          const obj = {
            labels,
            datasets,
          };
          setCanLogGraph(obj);
        }
      }
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    } finally {
      setLoader(false);
    }
  };
  const selectCard = (view: any) => {
    const data = canLogSignals.map((ele: any) => {
      if (view?.index === ele?.index) {
        ele.signalflag = !view?.signalflag;
      }
      return ele;
    });
    const signalData: any[] = [];
    data.filter((item: any) => {
      if (item?.signalflag === true) {
        signalData.push(item?.signal);
      }
    });
    console.log(data, 'fdfgg');
    setSignalValues(signalData);
    setCanLogSignals([...data]);
  };
  const onSearch = (e: any) => {
    setSearch(e.target.value);
  };

  const refreshIcon = () => {
    getCanLogSignalsGraph();
  };

  const onDateRangeChange = (dates: any) => {
    if (dates) {
      const [from, to] = fromToDateWrapper(dates);
      setfromTime(from.toDate());
      settoTime(to.toDate());
    } else {
      setfromTime(null);
      settoTime(null);
    }
  };
  return (
    <>
      <Modal
        visible
        onCancel={() => callback(false)}
        footer={null}
        className="modalContainer conLogContainer"
      >
        <div className="canLogSignalCon">
          <div className="canLogSignals">
            <Input
              className="canLogSearch"
              placeholder="Search"
              onChange={(e: any) => onSearch(e)}
              prefix={<SearchOutlined />}
            />
            {canLogSignals &&
              canLogSignals.map((ele: any) => (
                <Button
                  className={
                    ele?.signalflag === true
                      ? 'canLogSignalsbtn'
                      : 'canLogSignalsbtn1'
                  }
                  style={{ marginBottom: '10px' }}
                  onClick={() => selectCard(ele)}
                >
                  {ele?.signal}
                </Button>
              ))}
          </div>
          <div className="canLogGraph">
            <div className="canViewer">CanLog Viewer</div>

            <div className="refreshContainer">
              <RangePicker
                showTime={{ format: 'HH:mm' }}
                format="MM-DD-YYYY HH:mm"
                style={{
                  width: '350px',
                  marginRight: '200px',
                  marginTop: '15px',
                }}
                onChange={(dates: any) => {
                  onDateRangeChange(dates);
                }}
                defaultValue={[moment(fromtime), moment(totime)]}
              />
              <img
                src={refesh_gray}
                alt=""
                className="refreshIcon"
                onClick={() => refreshIcon()}
              />
            </div>
            {canLogGraph && <Line data={canLogGraph} options={options} />}
          </div>
        </div>
        <div className="custom-loader-profile resendLoader">
          {loader && (
            <div className="loader canLogloader">
              <Spin size="large" />
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

export default CanlogViewer;

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
      display: false,
    },
    title: {
      display: false,
      text: 'Chart.js Line Chart',
    },
    tooltip: {
      enabled: false,
    },
  },
};
