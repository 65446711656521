import { notification } from 'antd';
import { useContext } from 'react';
import { UpdateComplianceCheck } from '../../../constants/Api';
import { ApplicationContext } from '../../../context/AppContext';

const useComplaince = () => {
  const { userDetails, analyticDashboardData } = useContext(ApplicationContext);
  const updateCompliance = async () => {
    try {
      const { compliance_id, compliance_code, compliance_version } =
        analyticDashboardData;
      const payload: any = {
        compliance_id,
        compliance_code,
        compliance_version,
        app_name: 'web',
        is_compliance_acknowledged: true,
      };
      await UpdateComplianceCheck(
        userDetails.organization.api_url,
        userDetails.organization_id,
        userDetails?.id,
        payload,
      );
    } catch (error: any) {
      notification.error({ message: error.message });
    }
  };
  return { updateCompliance };
};

export default useComplaince;
