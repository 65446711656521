import { mapTractors } from '../constants/Common';
import { GroundZero } from '../constants/types';
import {
  EXECUTE,
  RESET_NAVIGATE,
  SET_GROUND_ZERO,
  SET_JAG_DACTION,
  SET_LAST_TAB_INDEX,
  SET_NAVIGATE,
  SET_POLYGONS_LIST,
  SET_ROUTE_REFRESH,
  SET_SHOW_AUTO_DRIVE,
  SET_SHOW_CONVERSE,
  SET_TEAMS,
  SET_TITLE,
  SET_TRACTORS,
  SET_TRACTORS_LIST,
  SET_TRACTORS_LOADER,
  TOGGLE_VIDEO_WIDGET,
} from './actions';

interface InitialStateType {
  title: string;
  navigate: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  teams: any[];
  videoWidgetData: {
    videoTime: string | number;
    toggle: boolean;
    tractorId: number;
  };
  routeRefresh: boolean;
  jAGDAction: any;
  tractors: [];
  tractorsMap: null;
  tractorsList: any[];
  groundZero: GroundZero | null;
  polygonsList: any[];
  tractorsLoader: boolean;
}
interface Action {
  type: string;
  payload: any;
}
export const initialState: InitialStateType = {
  title: 'AppStore',
  navigate: null,
  teams: [],
  videoWidgetData: {
    toggle: false,
    videoTime: 0,
    tractorId: 0,
  },
  routeRefresh: false,
  jAGDAction: null,
  tractors: [],
  tractorsMap: null,
  tractorsList: [],
  groundZero: null,
  polygonsList: [],
  tractorsLoader: true,
};

export default function reducer(state: InitialStateType, action: Action) {
  const { type, payload } = action;
  switch (type) {
    case SET_TRACTORS:
      return { ...state, tractors: payload, tractorsMap: mapTractors(payload) };
    case SET_TRACTORS_LOADER:
      return { ...state, tractorsLoader: payload };
    case SET_NAVIGATE:
      return { ...state, navigate: payload };
    case TOGGLE_VIDEO_WIDGET:
      return {
        ...state,
        videoWidgetData: {
          toggle: payload.toggle,
          videoTime: payload?.videoTime,
          tractorId: payload?.tractorId,
        },
      };
    case RESET_NAVIGATE:
      return { ...state, navigate: null };
    case SET_TEAMS:
      return { ...state, teams: payload };
    case SET_LAST_TAB_INDEX:
      localStorage.setItem('lastTabIndex', JSON.stringify(payload));
      return { ...state };
    case SET_TITLE:
      return { ...state, title: 'Change AppStore State' };
    case SET_TRACTORS_LIST:
      return { ...state, tractorsList: payload };
    case SET_GROUND_ZERO:
      return { ...state, groundZero: payload };
    case SET_POLYGONS_LIST:
      return { ...state, polygonsList: payload };
    case SET_ROUTE_REFRESH:
      return { ...state, routeRefresh: !state.routeRefresh };
    case SET_JAG_DACTION:
      return { ...state, jAGDAction: payload };
    case SET_SHOW_AUTO_DRIVE:
      return { ...state, showAutoDriveConnect: payload };
    case SET_SHOW_CONVERSE:
      return { ...state, showConverseChat: payload };
    case EXECUTE:
      return { ...state, ...payload };
    default:
      // eslint-disable-next-line no-console
      console.log(action);
      throw new Error();
  }
}
