/* eslint-disable react/jsx-key */
/* eslint-disable no-console */
import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Empty,
  Input,
  Layout,
  Space,
  Spin,
  Switch,
  notification,
} from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import CustomSelect1 from '../common/CustomSelect1';
import { ApplicationContext } from '../../context/AppContext';
import verified from '../../assets/images/verified.svg';
import plus from '../../assets/images/plus.svg';
import noimplementSmall from '../../assets/images/no-implement-small.svg';

import {
  getCategoryList,
  getManufactureList,
  approvedImmList,
} from '../../constants/Api';
import CreateImplementModal from './CreateImplementModal';
import CustomSelectSearch from '../common/CustomSelectSearch';
import { approvedImplementsList } from '../../constants/types';
import GlobalImplementModal from './GlobalImplementModal';
import {
  selectTypeImplement,
  selectTypeImplementUnit,
} from '../../constants/Common';

interface Props {
  setAddNewImplement: (data: boolean) => void;
}
const ImplementsList: React.FC<Props> = ({ setAddNewImplement }) => {
  const { userDetails } = useContext(ApplicationContext);
  const [loader, setLoader] = useState<boolean>(true);
  const [implementTypeList, setImplementTypeList] = useState<any>([]);
  const [implementType, setImplementType] = useState<any>('');
  const [createImplement, setCreateImplement] = useState<boolean>(false);
  const [manufactures, setManufactures] = useState<any[]>([]);
  const [selectManufacture, setSelectManufacture] = useState<any>('');
  const [approvedImmListData, setApprovedImmListData] = useState<
    approvedImplementsList[]
  >([]);
  const [autoDriveCertified, setAutoDriveCertified] = useState<any>('');
  const [showDimensions, setShowDimensions] = useState<boolean>(false);
  const [globalImplement, setglobalImplement] = useState<boolean>(false);
  const [globalImplementData, setGlobalImplementData] = useState<any>();
  const [attachments, setAttachments] = useState<any[]>([]);
  const [selectAttachment, setSelectAttachment] = useState<any>('');
  const [search, setSearch] = useState<string>('');

  useEffect(() => {
    if (userDetails && userDetails?.organization) {
      getCategoryTypes();
      getAttachmentList();
    }
  }, [userDetails]);

  useEffect(() => {
    getManufactureListData();
  }, []);

  useEffect(() => {
    getApprovedImmList();
  }, [
    implementType,
    selectManufacture,
    autoDriveCertified,
    search,
    selectAttachment,
  ]);

  const getAttachmentList = async () => {
    try {
      const response = await getCategoryList(
        userDetails.organization?.api_url,
        'attachment_type',
      );
      setAttachments(response);
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    }
  };

  const getApprovedImmList = async () => {
    try {
      setLoader(true);
      const data = await approvedImmList(
        userDetails.organization.api_url,
        implementType,
        selectManufacture,
        autoDriveCertified,
        selectAttachment,
        search,
      );
      setApprovedImmListData(data);
    } catch (error: any) {
      notification.error({
        message: error.message,
        duration: 2,
      });
    } finally {
      setLoader(false);
    }
  };

  const getCategoryTypes = async () => {
    try {
      const data = await getCategoryList(
        userDetails.organization.api_url,
        'implement_type',
      );
      setImplementTypeList(data);
    } catch (error: any) {
      notification.error({
        message: error.message,
        duration: 2,
      });
    }
  };

  const getManufactureListData = async () => {
    try {
      setLoader(true);
      const result: any = await getManufactureList(
        userDetails.organization.api_url,
        userDetails.organization.fleet.id,
      );
      if (Array.isArray(result))
        setManufactures(
          result
            .filter((a: any) => a.name)
            .sort((a: any, b: any) =>
              a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1,
            ),
        );
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    } finally {
      setLoader(false);
    }
  };

  const closeImplement = () => {
    setCreateImplement(false);
    setglobalImplement(false);
  };

  const onSelect = (text: any) => {
    setSelectManufacture(text);
  };

  const handleEditImplement = (data: any) => {
    setGlobalImplementData(data);
    setglobalImplement(true);
  };

  const onSelectAttachment = (text: any) => {
    setSelectAttachment(text);
  };

  const handleSearch = (e: any) => {
    const value = e.target.value.trim().regX;
    if (value.length <= 1) {
      setSearch('');
    } else {
      setSearch(value);
    }
  };

  return (
    <Layout>
      <div className="mainContent">
        <Content>
          <div className="tblContainer viewportContainer">
            <div className="impListContainer">
              <div className="backArrowTxt">
                <div
                  className="back-button-imp"
                  onClick={() => setAddNewImplement(false)}
                />
                <div>
                  <div className="impListHeader">
                    Monarch Implement Database
                  </div>
                  <div className="impListTxt">
                    Search this database for implements to use with your fleet.
                  </div>
                </div>
              </div>
              <Button
                className="createNewBtn"
                onClick={() => setCreateImplement(true)}
              >
                <PlusOutlined /> Create New
              </Button>
            </div>

            <div className="common_wrapper mt34">
              <div className="filters_card spaceBtnAlignCenter">
                <div className="displayFlex al-center filter-fields">
                  <Space size="large">
                    <Input
                      addonBefore={<SearchOutlined />}
                      placeholder="Search Database"
                      className="common_search"
                      onChange={(e) => handleSearch(e)}
                      // onKeyDown={(e) => handleKey(e)}
                      autoComplete="off"
                    />
                    <div className="min_width dbManufactureWidth">
                      <CustomSelectSearch
                        label="Manufacturer"
                        cssClass="min_width"
                        value={selectManufacture}
                        options={manufactures}
                        optionKey="name"
                        onSelect={onSelect}
                        optionDisplay="name"
                        isAll={true}
                      />
                    </div>
                    <CustomSelect1
                      label="Type"
                      cssClass="min_width"
                      value={implementType}
                      setValue={(id: any) => {
                        // activity.current = 'filter';
                        setImplementType(id);
                      }}
                      options={implementTypeList}
                      optionKey="name"
                      isAll={true}
                    />

                    <CustomSelect1
                      label="Attachment"
                      cssClass="min_width"
                      value={selectAttachment}
                      setValue={onSelectAttachment}
                      options={attachments}
                      optionKey="name"
                      isAll={true}
                    ></CustomSelect1>

                    <div className="certifiedToggleTxt">
                      <span>Autodrive Certified</span>
                      <Switch
                        onChange={(e) => {
                          setAutoDriveCertified(e);
                          console.log(
                            'autoDriveCertified',
                            autoDriveCertified,
                            e,
                          );
                        }}
                      />
                    </div>
                  </Space>
                </div>
                <div
                  className="showDimTxt"
                  onClick={() => setShowDimensions(!showDimensions)}
                >
                  Show Dimensions
                </div>
              </div>
              {approvedImmListData && approvedImmListData.length > 0 ? (
                <>
                  <div className="aprImpList">
                    {approvedImmListData.map(
                      (immList: approvedImplementsList) => (
                        <>
                          <div className="implement-row-wrapper">
                            <div
                              key={immList.manufacturer}
                              className="acampoTxt"
                            >
                              {immList.manufacturer}
                            </div>

                            <div className="acampoGrids">
                              {immList &&
                                immList?.model_details?.map(
                                  (modelData: any) => (
                                    <div
                                      key={modelData.id}
                                      className="grid_item"
                                      onClick={() =>
                                        handleEditImplement(modelData)
                                      }
                                    >
                                      <div className="grid_item_Details">
                                        <div className="impImage">
                                          <img
                                            src={
                                              modelData?.image_url ||
                                              noimplementSmall
                                            }
                                            width="58"
                                            alt=""
                                          />
                                        </div>
                                        <div className="implement_Details_wrapper">
                                          <img
                                            className="ml5 plusAbs"
                                            src={plus}
                                            alt="plus"
                                          />
                                          <div className="implement_Details">
                                            {modelData?.is_autonomy_implement && (
                                              <div className="impAutoDriveTxt">
                                                <img src={verified} alt="" />{' '}
                                                Autodrive Certified for 8 - 10ft
                                                Rows
                                              </div>
                                            )}
                                          </div>
                                          <div className="impMdl-sec">
                                            <div className="spTxt">
                                              {modelData?.model}
                                            </div>
                                            <div className="impAttachment">
                                              <div className="rotaryTxt">
                                                {modelData?.implement_type}
                                              </div>
                                              <div className="rotaryTxt">
                                                {modelData?.attachment_type}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      {showDimensions && (
                                        <div>
                                          <div className="dimensionTxt">
                                            Dimensions
                                          </div>
                                          <div className="dimensionBorder">
                                            <div className="pd5 widthWeightTxt">
                                              <span className="mL10">
                                                Width{' '}
                                                <span className="dimSize">
                                                  {modelData?.width}{' '}
                                                  <sub>in</sub>
                                                </span>{' '}
                                              </span>{' '}
                                              <span className="mL10 mr10">
                                                {selectTypeImplement(
                                                  modelData?.implement_type,
                                                )}
                                                <span className="dimSize">
                                                  {modelData?.weight}{' '}
                                                  <sub className="lc">
                                                    {selectTypeImplementUnit(
                                                      modelData?.implement_type,
                                                    )}
                                                  </sub>
                                                </span>
                                              </span>
                                            </div>
                                            <div className="lengthHeightBr pd5 widthWeightTxt">
                                              <span className="mL10">
                                                Length{' '}
                                                <span className="dimSize">
                                                  {modelData?.length}{' '}
                                                  <sub>in</sub>
                                                </span>{' '}
                                              </span>
                                              <span className="mL10 mr10">
                                                Height{' '}
                                                <span className="dimSize">
                                                  {modelData?.height}{' '}
                                                  <sub>in</sub>
                                                </span>
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  ),
                                )}
                            </div>
                          </div>
                        </>
                      ),
                    )}
                  </div>
                </>
              ) : (
                <div className="noImpTxt">
                  <Empty description="No Implement in database" />
                </div>
              )}
            </div>
          </div>
        </Content>
        <div className="edit-loader">
          {loader && (
            <div className="loader">
              <Spin size="large" />
            </div>
          )}
        </div>
      </div>

      {createImplement && (
        <CreateImplementModal
          createImplement={createImplement}
          closeImplement={closeImplement}
          setAddNewImplement={setAddNewImplement}
        />
      )}
      {globalImplementData && globalImplement && (
        <GlobalImplementModal
          globalImplement={globalImplement}
          closeImplement={closeImplement}
          globalImplementData={globalImplementData}
          setAddNewImplement={setAddNewImplement}
        />
      )}
    </Layout>
  );
};

export default ImplementsList;
