/* eslint-disable no-console */
import React, { useContext, useEffect, useState } from 'react';
import atArrow from '../../../assets/images/atArrow.svg';
import hitch_implement_S from '../../../assets/images/hitch_implement_S.svg';
import fleet_implement_new from '../../../assets/images/hitch_implement_gray.svg';
import hoursOfOp from '../../../assets/images/hours_of_op.svg';
import tCLock from '../../../assets/images/tCLock.svg';

import base_station_icon from '../../../assets/images/base_station_icon.svg';
import nworkOff from '../../../assets/images/low.svg';
import tractorAlertIcon from '../../../assets/images/tractorAlert.svg';
import tractorspic from '../../../assets/images/tractor_health.svg';
import tOff from '../../../assets/images/tractor_off.svg';
import wifiOff from '../../../assets/images/wOff.svg';

import './ADashboard.css';

import { notification } from 'antd';
import { PubSub } from 'aws-amplify';
import { ApplicationContext } from '../../../context/AppContext';
import AdminLoader from '../AdminLoader';
import {
  adminBatteryValue,
  adminIsEmptyValue,
  adminNoneAttached,
  adminSecondsToHours,
  adminTractorConst,
  adminWrapIndictorsData,
  getAdminConnectivityImg,
  getAdminFNR,
  getAdminFloatText,
  getAdminGearText,
  getAdminHeight,
  getAdminImplementName,
  getAdminNetworkTypeImg,
  getAdminOperationStatus,
  getAdminOperatorNameByHeartBeat,
  getAdminPTOState,
  getAdminRpm,
  getAdminSpeed,
  getAdminStatusClass,
  getAdminStatusForTractor,
  getAdminTractorImage,
  getAdminTractorStatusIcon,
} from '../Common';
import LiveCamera from '../LiveCamera/LiveCamera';
import { getTractorDetails } from '../api';
import { AdminHeartBeat, AdminTractor } from '../types';
let globalTractor: AdminTractor | null = null;
let globalSelectedTractorId = 0;
interface Props {
  selectedTractorId: number;
  selectedOrg: number;
  setSelectedTractorId: (tractorId: number) => void;
  setHeartbeat: (hb: AdminHeartBeat) => void;
}
const TractorDetails: React.FC<Props> = ({
  selectedTractorId,
  selectedOrg,
  setSelectedTractorId,
  setHeartbeat,
}: Props) => {
  const { userDetails } = useContext(ApplicationContext);
  const [loader, setLoader] = useState<boolean>(false);
  const [selectedTractor, setSelectedTractor] =
    useState<AdminTractor | null>(null);
  useEffect(() => {
    const init = async () => {
      try {
        setLoader(true);
        const { organization } = userDetails;
        const st = await getTractorDetails(
          organization.api_url,
          selectedOrg,
          selectedTractorId,
        );
        st.isAvailable =
          st.connection === 'offline'
            ? adminTractorConst.off.key
            : getAdminOperationStatus(st?.heartbeat as AdminHeartBeat);

        setSelectedTractor(st);
      } catch (error: any) {
        notification.error({
          message: error.message,
          duration: 1,
        });
      } finally {
        setLoader(false);
      }
    };
    if (
      (userDetails && userDetails.organization && selectedTractorId,
      selectedOrg)
    ) {
      init();
    }
  }, [userDetails, selectedOrg, selectedTractorId]);
  useEffect(() => {
    globalTractor = selectedTractor;
  }, [selectedTractor]);
  useEffect(() => {
    globalSelectedTractorId = selectedTractorId;
  }, [selectedTractorId]);

  const getIndicatorIcons = (tractor: any) => {
    const { indicators: data } = tractor;
    if (!data) {
      return;
    }
    const indicatorsData = adminWrapIndictorsData(data);
    const connectivity = getAdminConnectivityImg(
      indicatorsData['Tractor_Communication'],
    );
    const isBaseStationIcon = !adminIsEmptyValue(
      tractor?.heartbeat?.bs_serial_no,
    );

    const isError =
      tractor.indicatorsData.filter(
        (indicator: { indicator_level: number }) =>
          indicator.indicator_level === 3,
      ).length > 0;
    if (tractor.isAvailable === adminTractorConst.off.key) {
      return (
        <>
          <div className="t_signals">
            <div className="t_signals_img">
              <img className="mr4" src={tOff} style={{ cursor: 'pointer' }} />
            </div>
            <div className="t_signals_img">
              {tractor.active_network === 'cellular' ? (
                <img
                  className="mr4"
                  style={{ marginLeft: '5px', cursor: 'pointer' }}
                  height="11"
                  src={nworkOff}
                />
              ) : (
                <img
                  className="mr4"
                  src={wifiOff}
                  style={{ cursor: 'pointer' }}
                />
              )}
            </div>
          </div>
        </>
      );
    }
    return (
      connectivity.trim() !== '' && (
        <div
          className={`t_signals ${isError ? 't_error' : ''}`}
          onClick={(e: any) => {
            e.stopPropagation();
            // mapOpen(tractor);
          }}
        >
          <div className="t_signals_img">
            <img
              className="mr4"
              src={isError ? tractorAlertIcon : tractorspic}
              style={{ cursor: 'pointer' }}
            />
          </div>
          <div className="t_signals_img">
            {tractor.active_network === 'cellular' ? (
              <img
                className="mr4"
                width="16px"
                src={getAdminNetworkTypeImg(tractor)}
                style={{ cursor: 'pointer' }}
              />
            ) : (
              <img
                className={isBaseStationIcon ? 'mrl10' : 'mr4'}
                src={
                  isBaseStationIcon
                    ? base_station_icon
                    : getAdminNetworkTypeImg(tractor)
                }
                style={{ cursor: 'pointer' }}
              />
            )}
          </div>
        </div>
      )
    );
  };

  useEffect(() => {
    if (selectedOrg === 0) return;
    try {
      const sub1 = PubSub.subscribe(`HeartBeat/org/${selectedOrg}`).subscribe({
        next: (data: any) => {
          const tractorHeartBeat = data.value as AdminHeartBeat;
          if (
            tractorHeartBeat.tractor_id &&
            globalSelectedTractorId &&
            tractorHeartBeat.tractor_id === globalSelectedTractorId
          ) {
            const tractor: any = globalTractor as AdminTractor;
            console.log(
              'a',
              getAdminOperationStatus(tractorHeartBeat as AdminHeartBeat),
            );
            tractor['isAvailable'] = getAdminOperationStatus(
              tractorHeartBeat as AdminHeartBeat,
            );
            tractor['heartbeat'] = tractorHeartBeat;
            setHeartbeat(JSON.parse(JSON.stringify(tractorHeartBeat)));
            setSelectedTractor(JSON.parse(JSON.stringify(tractor)));
          }
        },
        error: (error: any) => console.error(error),
      });

      return () => {
        sub1.unsubscribe();
      };
    } catch (error) {
      console.log(error);
    }
  }, [selectedOrg]);

  return (
    <>
      <div className="rautoDriveRightSec rautoDriveRightSecWidth">
        <div className="autoDriveTractorHead">
          <div
            className="adtHeadSec"
            onClick={() => {
              setSelectedTractorId(0);
            }}
          >
            <span className="adtHeadTextIcon">
              <img src={atArrow} alt="atArrow" />
            </span>
            <span
              className="adtHeadText Button"
              data-testid="tractorDetailsLink-RemoteDriveDetailsNew"
            >
              TRACTOR DETAILS
            </span>
          </div>
        </div>
        <div className="adtMainContainer">
          <div
            className={`adtTopSection ${getAdminTractorStatusIcon(
              selectedTractor?.isAvailable as string,
            )}_color `}
          >
            <div className="adtTopBox">
              <div className="adTabsHeadSec">
                <div
                  className="headline3 adtabsHeadText"
                  data-testid="tractorName-RemoteDriveDetailsNew"
                >
                  {selectedTractor?.name}
                </div>
                <div
                  className={`ttractor_state  ${getAdminStatusClass(
                    selectedTractor?.isAvailable as string,
                    selectedTractor?.heartbeat?.drive_action_details
                      ?.drive_action_created_at as string,
                  )}`}
                >
                  {getAdminStatusForTractor(
                    selectedTractor?.isAvailable as string,
                    selectedTractor?.heartbeat?.drive_action_details
                      ?.drive_action_created_at as string,
                  )}
                </div>
              </div>
              <div
                className="adTabsContentSec adTabsContentSec1"
                id="adTabsContentSec"
              >
                <div className="rautoDriveStatus">
                  <div className="autoDrivetime">
                    <img src={hoursOfOp} alt="" className="hourIcon" />
                    <span className="ttipMeter body2 fReg">
                      {adminSecondsToHours(
                        Number(selectedTractor?.heartbeat?.daily_hour_meter),
                      )}
                    </span>
                  </div>
                  <div className="autoDriverName body2">
                    {selectedTractor?.isAvailable !==
                      adminTractorConst.off.key && (
                      <>
                        {getAdminOperatorNameByHeartBeat(
                          selectedTractor?.heartbeat,
                        ).trim() !== '' && (
                          <img className="t_lock" src={tCLock} />
                        )}
                        <span className="aDname body3">
                          {getAdminOperatorNameByHeartBeat(
                            selectedTractor?.heartbeat,
                          )}
                        </span>
                      </>
                    )}
                  </div>
                  <div className="autodriveStatusIcons">
                    {selectedTractor &&
                      selectedTractor?.id &&
                      getIndicatorIcons(selectedTractor)}
                  </div>
                </div>
                <div className="tractorSpeedometer">
                  <div className="t_info_progress_circle ">
                    <div
                      className="progressbar"
                      style={{
                        background: `radial-gradient(
                    closest-side,
                    white 75%,
                    transparent 0 99.9%,
                    white 0
                  ),
                  conic-gradient(#5BCD66 ${adminBatteryValue(
                    selectedTractor as AdminTractor,
                  )}%, #F5F7F6 0)`,
                      }}
                    >
                      <div className="tractor_status_img">
                        <div className="tractorImage">
                          {' '}
                          <img
                            src={getAdminTractorImage(
                              selectedTractor?.isAvailable as string,
                              selectedTractor?.tractor_model as string,
                            )}
                          />
                        </div>
                        {/* display if not charging,offline and available */}
                        {selectedTractor?.isAvailable !==
                          adminTractorConst.available.key &&
                          selectedTractor?.isAvailable !==
                            adminTractorConst.faulty.key &&
                          selectedTractor?.isAvailable !==
                            adminTractorConst.off.key &&
                          selectedTractor?.isAvailable !==
                            adminTractorConst.charging.key &&
                          selectedTractor?.isAvailable !==
                            adminTractorConst.service.key && (
                            <div className="t_speed_cont">
                              <span className="type">
                                {getAdminFNR(selectedTractor?.heartbeat)}
                              </span>
                              <span className="speed headline1">
                                {getAdminSpeed(selectedTractor?.heartbeat)}{' '}
                              </span>
                              <span className="measure">mph</span>
                            </div>
                          )}
                      </div>
                      <div
                        className="rotate"
                        style={{
                          transform: `${`rotate(${
                            adminBatteryValue(selectedTractor as AdminTractor) *
                            3.6
                          }deg)`}`,
                        }}
                      >
                        <div className="counterrotate">
                          <div
                            className="inner"
                            style={{
                              transform: `${`rotate(-${
                                adminBatteryValue(
                                  selectedTractor as AdminTractor,
                                ) * 3.6
                              }deg)`}`,
                            }}
                          >
                            <div
                              className="innerInner"
                              data-testid="chargingPercentage-RemoteDriveDetailsNew"
                            >
                              {adminBatteryValue(
                                selectedTractor as AdminTractor,
                              )}
                              %
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tOperationDtlSec">
                  <div className="tOpTypeSec">
                    <span className="a_icon a_pto_icon iconAutodrive"></span>
                    <span className="a_value headline3 fMed">
                      {getAdminPTOState(selectedTractor?.heartbeat)
                        ? getAdminRpm(
                            selectedTractor?.heartbeat as AdminHeartBeat,
                            selectedTractor?.tractor_model,
                          )
                        : '-'}
                    </span>
                    <span className="a_label body2">
                      {getAdminPTOState(
                        selectedTractor?.heartbeat as AdminHeartBeat,
                      )
                        ? ' PTO RPM'
                        : ' PTO OFF'}
                    </span>
                  </div>
                  <div className="tOpTypeSec">
                    <span className="a_icon a_gear_icon iconAutodrive"></span>
                    <span className="a_value headline3 fMed">
                      {selectedTractor &&
                        getAdminGearText(selectedTractor?.heartbeat)}
                    </span>
                    <span className="a_label body2">Gear</span>
                  </div>
                  <div className="tOpTypeSec">
                    <span className="a_icon a_height_icon iconAutodrive"></span>
                    <span className="a_value headline3 fMed">
                      {selectedTractor &&
                        getAdminHeight(selectedTractor.heartbeat)}
                      %
                    </span>
                    <span className="a_label body2">HEIGHT</span>
                  </div>
                  <div className="tOpTypeSec">
                    <span className="a_icon a_potion_icon iconAutodrive"></span>
                    <span className="a_value2 headline3 fMed">
                      {getAdminFloatText(selectedTractor?.heartbeat)}
                    </span>
                  </div>
                </div>
                {/* <div className="cameraViewTitle openCamtgl">
                  <h5 className="headline5 pointer">
                    <span className="camHeadViewTxt">Hour Meter : </span>
                  </h5>
                  {adminHourMeter(selectedTractor)}
                </div>
                <div className="cameraViewTitle openCamtgl">
                  <h5 className="headline5 pointer">
                    <span className="camHeadViewTxt">Firmware Version: </span>
                  </h5>
                  {selectedTractor?.heartbeat?.firmware_version}
                </div>
                <div className="cameraViewTitle openCamtgl">
                  <h5 className="headline5 pointer">
                    <span className="camHeadViewTxt">Hardware Version: </span>
                  </h5>
                  {selectedTractor?.heartbeat?.software_version}
                </div>
                <div className="cameraViewTitle openCamtgl">
                  <h5 className="headline5 pointer">
                    <span className="camHeadViewTxt">Last Updated Time: </span>
                  </h5>
                  {getAdminDateStamp(selectedTractor?.heartbeat?.date_time)}
                </div> */}
                <div className="autoDriveScoller">
                  <div className="autoDriveCamViews">
                    <LiveCamera
                      triggerSnapshot={() => console.log('test')}
                      selectedTractorId={selectedTractor?.id as number}
                      orgId={selectedOrg as number}
                      tractorName={selectedTractor?.name as string}
                      model={selectedTractor?.tractor_model}
                      selectedTractor={selectedTractor as AdminTractor}
                    />
                  </div>
                </div>
                <div
                  className={`tImplement ${
                    getAdminImplementName(selectedTractor as AdminTractor) ===
                    adminNoneAttached
                      ? 'disableRoutine'
                      : ''
                  }`}
                >
                  <span>
                    {getAdminImplementName(selectedTractor as AdminTractor) ===
                    adminNoneAttached ? (
                      <img src={fleet_implement_new} alt="" className="mr12" />
                    ) : (
                      <img
                        src={hitch_implement_S}
                        alt="implement"
                        width="16"
                        height="16"
                        className="mr12"
                      />
                    )}
                  </span>
                  <span className="Button">
                    {` ${getAdminImplementName(
                      selectedTractor as AdminTractor,
                    )}`}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <AdminLoader loader={loader} />
      </div>
      ;
    </>
  );
};

export default TractorDetails;
