/* eslint-disable react/jsx-key */
import React, { useContext, useEffect, useState } from 'react';
import { Collapse, notification } from 'antd';
import { ApplicationContext } from '../../context/AppContext';
import { getFaqs } from './services/API';
import Interweave from 'interweave';
const { Panel } = Collapse;

const FAQs: React.FC = () => {
  const { userDetails } = useContext(ApplicationContext);
  const [faqsData, setFaqsData] = useState<any>([]);
  const fetchFaqs = async () => {
    try {
      const { organization } = userDetails;
      const { api_url } = organization;
      const result = await getFaqs(api_url);
      const data = Array.isArray(result?.data) ? result?.data : [];
      setFaqsData(data);
    } catch (error: any) {
      notification.error({ message: error.message });
    }
  };

  useEffect(() => {
    if (userDetails?.organization) fetchFaqs();
  }, [userDetails]);

  const leftData = faqsData.slice(0, faqsData.length / 2);
  const rightData = faqsData.slice(faqsData.length / 2);

  return (
    <>
      <h1 className="hc_cat_heads">Troubleshooting & FAQ</h1>
      <div className="hc_faq_section hc_faq">
        <div className="hc_faq_col">
          {leftData.map((ele: any, i: any) => (
            <Collapse
              accordion
              className={`acc_sec expanded`}
              expandIconPosition="right"
              key={ele.id}
            >
              <Panel
                header={
                  <div className="hc_panel_custom_header">
                    <h3>{ele?.question}</h3>
                  </div>
                }
                key={ele.id}
              >
                <Interweave content={ele?.answer} />
              </Panel>
            </Collapse>
          ))}
        </div>
        <div className="hc_faq_col">
          {rightData.map((ele: any, i: number) => (
            <Collapse
              key={ele.id}
              accordion
              className={`acc_sec expanded`}
              expandIconPosition="right"
            >
              <Panel
                header={
                  <div className="hc_panel_custom_header">
                    <h3>{ele?.question}</h3>
                  </div>
                }
                key={ele.id}
              >
                <Interweave content={ele?.answer} />
              </Panel>
            </Collapse>
          ))}
        </div>
      </div>
    </>
  );
};

export default FAQs;
