import React, { useContext, useEffect, useState } from 'react';
import { Input, Row, Space, notification } from 'antd';
import Layout, { Content } from 'antd/lib/layout/layout';
import { getRoute } from '../../constants/Api';
import { ApplicationContext } from '../../context/AppContext';
import { getDateTimes, getTime } from '../../constants/Common';
import translate from '../../locale/en_translate.json';
import { useTranslation } from 'react-i18next';
import InfiniteScrollTable from '../common/InfiniteScrollTable';
import { RoutesList } from '../../constants/types';
import { SearchOutlined } from '@ant-design/icons';

interface Routes {
  created_by: string;
  created_date_time: string;
  id: number;
  name: string;
}

const Routines: React.FC = () => {
  const { userDetails } = useContext(ApplicationContext);
  const { t } = useTranslation();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [loader, setLoader] = useState<boolean>(false);
  const [totalcount, settotalcount] = useState<number>();
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [routines, setRoutines] = useState<Routes[]>([]);

  const getRoutes = async () => {
    try {
      setLoader(true);
      const { organization } = userDetails;
      let records = await getRoute(
        organization.api_url,
        organization.farm.id,
        search,
      );
      records = records && records.length > 0 ? records : [];
      settotalcount(records.length);
      const routesList: Routes[] = [];
      records.map((route: RoutesList) => {
        const obj: Routes = {
          id: route.id,
          created_by: route.created_by
            ? route.created_by.first_name + ' ' + route.created_by.last_name
            : '-',
          created_date_time: route.created_date_time
            ? getDateTimes(route.created_date_time)
            : '-',
          name: route.name ? route.name : '-',
        };
        routesList.push(obj);
      });
      setRoutines(routesList);
      setLoader(false);
    } catch (error: any) {
      setLoader(false);
      notification.error({
        message: error.message,
      });
    }
  };

  useEffect(() => {
    if (userDetails && userDetails.organization) getRoutes();
  }, [userDetails, search]);

  const columns = [
    {
      title: `${t(translate.routines.name)}`,
      dataIndex: 'name',
      key: 'name',
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
    },
    {
      title: `${t(translate.routines.createdby)}`,
      dataIndex: 'created_by',
      key: 'created_by',
      sorter: (a: any, b: any) => a.created_by.localeCompare(b.created_by),
    },
    {
      title: `${t(translate.routines.createdTime)}`,
      dataIndex: 'created_date_time',
      key: 'created_date_time',
      // width: '240px',
    },
  ];

  const handleLoadMore = () => {
    setPageNumber(pageNumber + 1);
  };

  const handleSearch = (e: any) => {
    const value = e.target.value.trim().regX;
    if (value.length <= 2) {
      if (search != '') {
        setRoutines([]);
      }
      setSearch('');
    } else {
      setSearch(value);
    }
  };

  return (
    <Layout>
      <div className="mainContent">
        <div className="tblContainer viewportContainer">
          <Row>
            <div className="common_wrapper wWidget_wrapper">
              <div className="filters_card personnelCard">
                <Space size="large">
                  <Input
                    addonBefore={<SearchOutlined />}
                    placeholder="Search Routines"
                    className="common_search"
                    onChange={(e) => handleSearch(e)}
                    autoComplete="off"
                  />
                </Space>
              </div>
              <div className="common_table">
                <InfiniteScrollTable
                  columns={columns}
                  loading={loader}
                  hasMore={hasMore}
                  filterData={routines}
                  totalcount={totalcount}
                  handleLoadMore={handleLoadMore}
                  filename="Routines"
                />
              </div>
            </div>
          </Row>
        </div>
      </div>
    </Layout>
  );
};

export default Routines;
