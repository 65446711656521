/* eslint-disable react/display-name */
import React, { useContext, useEffect, useState } from 'react';
import { Modal, Input, Button, Table, notification, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import deleteIcon from '../../../../assets/images/del_icon.png';
import { ApplicationContext } from '../../../../context/AppContext';
import translate from '../../../../locale/en_translate.json';
import { isValidEmail } from '../../../../constants/Common';
import { addDistribution } from '../../API';

interface Props {
  showModal: boolean;
  // eslint-disable-next-line no-unused-vars
  close: (state?: boolean) => void;
  emails: Array<string>;
  reportId: number;
}

const EmailAddModal: React.FC<Props> = ({
  showModal,
  close,
  emails,
  reportId,
}: Props) => {
  const title = React.useRef(null);
  const { t } = useTranslation();
  const [loader, setLoader] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState('');
  const [dataSource, setdataSource] = useState<any>([]);
  const { userDetails } = useContext(ApplicationContext);

  useEffect(() => {
    const data = emails.map((email: string) => ({
      email,
      action: email,
    }));
    setdataSource(data as []);
  }, []);

  const addEmail = () => {
    const filterDatas = dataSource.filter(
      (data: any) => data.email === inputValue,
    );
    if (filterDatas < 1) {
      if (isValidEmail(inputValue)) {
        setdataSource([
          ...dataSource,
          { email: inputValue, action: inputValue },
        ]);
      } else {
        notification.warn({
          message: `${t(translate.tractorusagereport.enterValidEmail)}`,
        });
      }
    } else {
      notification.info({
        message: `${t(translate.tractorusagereport.emailIdExist)}`,
      });
    }
  };

  const removeEmail = (email: string) => {
    const filterData = dataSource.filter((data: any) => data.email !== email);
    setdataSource(filterData);
  };

  const handleSave = async () => {
    try {
      setLoader(true);
      const emailIds = dataSource.map((data: any) => data.email);

      await addDistribution(userDetails.organization.id, reportId, {
        distribution_email_ids: emailIds,
      });
      close(true);
    } catch (error: any) {
      notification.error({ message: error?.message });
    } finally {
      setLoader(false);
    }
  };

  const columns = [
    {
      title: `${t(translate.emailAddModal.email)}`,
      dataIndex: 'email',
      key: 'email',
      render: (email: string) => (
        <span data-testid={`${email}-EmailAddModal`}>{email}</span>
      ),
    },
    {
      title: `${t(translate.updateManager.action)}`,
      dataIndex: 'action',
      key: 'action',
      render: (data: string) => (
        <img
          src={deleteIcon}
          alt="Delete"
          className="marginLeftIcon"
          onClick={() => removeEmail(data)}
          data-testid={`${data}-deleteIcon-EmailAddModal`}
        />
      ),
    },
  ];

  return (
    <Modal
      title={t(translate.OnDemandReports.addEmail)}
      visible={showModal}
      className="baseModalContainer"
      closable={false}
      onOk={handleSave}
      onCancel={() => close()}
      okText="Save"
      okButtonProps={
        {
          className: 'okBtn',
          'data-testid': 'addEmailOkButton-EmailAddModal',
        } as any
      }
      cancelButtonProps={{
        className: 'canelBtn',
      }}
    >
      <div style={{ marginBottom: 12 }}>
        <div className="formCol">
          <div className="ant-row ant-form-item">
            <div className="ant-col ant-form-item-label">
              <label className="Txt1">
                {t(translate.emailAddModal.email)}{' '}
                <span style={{ color: 'red' }}> *</span>
              </label>
            </div>
            <div className="ant-col ant-form-item-control">
              <div className="ant-form-item-control-input">
                <div className="ant-form-item-control-input-content input-align addTxt">
                  <Input
                    className="input"
                    data-testid="emailInputField-EmailAddModal"
                    value={inputValue}
                    onChange={(e) => {
                      setInputValue(e.currentTarget.value);
                    }}
                    ref={title}
                  />
                  <Button
                    data-testid="addButton-EmailAddModal"
                    type="text"
                    className="okBtn addBtn"
                    onClick={addEmail}
                    style={{ height: '47px' }}
                  >
                    {t(translate.buttons.add)}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        sticky
        className="addEmailTable"
      />
      <div className="edit-loader">
        {loader && (
          <div className="loader">
            <Spin size="large" />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default EmailAddModal;
