/* eslint-disable react/jsx-no-undef */
import { DatePicker, Input, notification, Spin } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { useContext, useEffect, useState } from 'react';
import { editTask } from '../../constants/Api';
import { ApplicationContext } from '../../context/AppContext';
import 'react-datepicker/dist/react-datepicker.css';
import './style.css';
import { useTranslation } from 'react-i18next';
import translate from '../../locale/en_translate.json';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import moment from 'moment';
import { fromToDateWrapper } from '../../constants/Common';
import { track } from '../../util/logger';
import logo from '../../assets/images/logo192.png';
import CustomInput from '../common/CustomInput';
import CustomDate1 from '../common/CustomDate1';
const { RangePicker } = DatePicker;
interface Props {
  showModel: boolean;
  closeModel: () => void;
  taskData: any;
}

const EditTaskModel: React.FC<Props> = ({
  showModel,
  closeModel,
  taskData,
}: Props) => {
  const { userDetails } = useContext(ApplicationContext);
  const { t } = useTranslation();
  const [fromDate, setFromDate] = useState<any>(
    new Date(taskData.planned_start_date_time),
  );
  const [toDate, setToDate] = useState<any>(
    new Date(taskData.planned_finish_date_time),
  );
  const [title, setTitle] = useState(taskData.title);
  const [description, setDescriptions] = useState(taskData.description);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [loader, setLoader] = useState<boolean>(false);

  useEffect(() => {
    if (fromDate && toDate && title.trim().length > 0) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [fromDate, toDate, title]);

  const edittask = async () => {
    try {
      setLoader(true);

      if (description && description.length > 19999) {
        notification.error({
          message: 'Description maximum length exceeded',
        });
        return;
      }
      const response: { msg: string } = await editTask(
        userDetails.organization.api_url,
        {
          title: title.trim(),
          description: description,
          planned_start_date_time: fromDate.getTime(),
          planned_finish_date_time: toDate.getTime(),
          supervisor_id: taskData.supervisor.id,
        },
        userDetails.organization.id,
        taskData.task_uuid,
      );
      track('UpdatedTask', {
        event: 'Task Updated Sucessfully',
      });
      notification.success({
        message: response.msg,
      });
      closeModel();
    } catch (err: any) {
      track('UpdatedTaskFailed', {
        event: 'Task Updated Failed',
      });
      notification.error({
        message: err.message,
      });
    } finally {
      setLoader(false);
    }
  };
  const onDateRangeChange = (dates: any) => {
    if (dates) {
      const [from, to] = fromToDateWrapper(dates);
      setFromDate(from.toDate());
      setToDate(to.toDate());
    } else {
      setFromDate(null);
      setFromDate(null);
    }
  };

  return (
    <Modal
      title={false}
      closable={false}
      visible={showModel}
      className="tm-modal betaModal taskModal"
      onCancel={closeModel}
      okText={t(translate.buttons.save)}
      onOk={edittask}
      okButtonProps={{
        className: 'tm-button btnSave Button',
        disabled: isDisabled,
      }}
      cancelButtonProps={{
        className: 'btnCancel Button',
      }}
    >
      <div className="createtitle">
        <div className="pull-left">
          <h1 className="title headline1">Edit Operation Planner</h1>
        </div>
        <div className="img-div pull-right">
          <img src={logo} />
        </div>
      </div>
      <div className="widget_card">
        <div className="formRow betaRow">
          <div className="formCol betaCol">
            <div className="ant-col ant-form-item-control">
              <div className="ant-form-item-control-input">
                <div className="ant-form-item-control-input-content input-align">
                  <CustomInput
                    label="Title"
                    value={title}
                    cssClass="width100per"
                    setValue={(value) => {
                      setTitle(value);
                    }}
                    required={true}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="formCol betaCol">
            <div className="ant-col ant-form-item-control">
              <div className="ant-form-item-control-input">
                <div className="ant-form-item-control-input-content input-align">
                  <CustomInput
                    label="Type"
                    value={taskData.task_type}
                    cssClass="width100per"
                    disabled={true}
                    setValue={(value) => {
                      setTitle(value);
                    }}
                    required={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="formRow betaRow">
          <div className="formColW">
            <div className="ant-col ant-form-item-control">
              <div className="ant-form-item-control-input">
                <div
                  className="ant-form-item-control-input-content input-align"
                  style={{
                    width: '100%',
                    overflowY: 'auto',
                  }}
                >
                  <div
                    style={{
                      minHeight: '200px',
                    }}
                  >
                    <ReactQuill
                      style={{ height: 150 }}
                      value={description}
                      onChange={(e) => setDescriptions(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="formRow betaRow">
          <div className="formCol betaCol dPick">
            <div className="ant-col ant-form-item-control">
              <div className="ant-form-item-control-input">
                <div className="ant-form-item-control-input-content input-align input-flex">
                  <CustomDate1
                    format="YYYY-MM-DD HH:mm A"
                    label="Start Date - End Date"
                    cssClass="dropdownStyle dW250_date "
                    disabledDateCount={(d: any) => !d || d.isBefore(new Date())}
                    value={[
                      moment(fromDate, 'YYYY-MM-DD HH:mm A'),
                      moment(toDate, 'YYYY-MM-DD HH:mm A'),
                    ]}
                    onChange={onDateRangeChange}
                    showTimes={true}
                    clearIcon={false}
                  ></CustomDate1>
                </div>
              </div>
            </div>
          </div>
          <div className="formCol betaCol"></div>
        </div>
      </div>

      <div className="edit-loader">
        {loader && (
          <div className="loader">
            <Spin size="large" />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default EditTaskModel;
