/* eslint-disable no-console */
import { Input, InputNumber, Modal, notification, Spin } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { ApplicationContext } from '../../context/AppContext';
import translate from '../../locale/en_translate.json';

import { useTranslation } from 'react-i18next';
import { saveVineRowsData } from '../../constants/Api';

interface Props {
  showModal: boolean;
  handleCancel: (reload?: boolean) => void;
}
export const FeedLine: React.FC<Props> = ({
  showModal,
  handleCancel,
}: Props) => {
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const [name, setName] = useState<string>('');
  const [startY, setStartY] = useState('');
  const [startX, setStartX] = useState('');
  const [endX, setEndX] = useState('');
  const [endY, setEndY] = useState('');
  const [startAltitude, setStartAltitude] = useState('');
  const [endAltitude, setEndAltitude] = useState('');
  const [selectedPolygonUuid, setSelectedPolygonUuid] = useState('');
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [loader, setLoader] = useState<boolean>(false);
  const saveVineRows = async () => {
    setLoader(true);
    const payload: any = {
      name: name.trim(),
      head_a_y: startY,
      head_a_x: startX,
      head_b_x: endX,
      head_b_y: endY,
      head_a_altitude: startAltitude,
      head_b_altitude: endAltitude,
      polygon_uuid: selectedPolygonUuid,
      category: 'feed_lane',
    };
    setName(name.trim());
    try {
      const { organization } = userDetails;
      console.log('payload', payload);
      const response = await saveVineRowsData(
        organization.api_url,
        organization.farm.id,
        payload,
      );
      handleCancel(true);
      notification.success({
        message: response.msg,
      });
    } catch (error: any) {
      notification.error({
        message: error?.message,
      });
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (
      userDetails &&
      userDetails.organization &&
      name &&
      name.trim().length > 0
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [userDetails, name]);

  return (
    <Modal
      className="commonPopup addPolygon"
      title={t(translate.map.feed_line)}
      closable={false}
      centered
      width={'60vw'}
      visible={showModal}
      onCancel={() => handleCancel()}
      okText="Save"
      onOk={saveVineRows}
      okButtonProps={{
        className: 'btnSave ',
        disabled: isDisabled,
      }}
      cancelButtonProps={{
        className: 'btnCancel',
      }}
    >
      <div className="formRow width-class">
        <div className="formCol">
          <label className="formLabel">
            Name <span style={{ color: 'red' }}> *</span>
          </label>
          <Input
            className="commInput"
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
        </div>

        {/* <div className="formCol">
          <label className="formLabel">
            {t(translate.map.boundary)} <span style={{ color: 'red' }}> *</span>
          </label>
          <Select
            className="customSelect"
            onSelect={(value) => {
              setSelectedPolygonUuid(value);
            }}
            value={selectedPolygonUuid}
          >
            {polygonsListForPop.map((data: any) => {
              if (data.name !== '') {
                return (
                  <option value={data.polygon_uuid} key={data.polygon_uuid}>
                    {data.name} ({data.color})
                  </option>
                );
              }
            })}
          </Select>
        </div> */}
      </div>
      <div className="formRow">
        <div className="formCol">
          <label className="formLabel lblHeads">Start Lane</label>
        </div>
        <div className="formCol">
          <label className="formLabel lblHeads">End Lane</label>
        </div>
      </div>
      <div className="formRow">
        <div className="formCol">
          <label className="formLabel">X</label>
          <InputNumber
            className="commInput w100"
            onChange={setStartX}
            value={startX}
          />
        </div>
        <div className="formCol">
          <label className="formLabel">X</label>
          <InputNumber
            className="commInput w100"
            onChange={setEndX}
            value={endX}
          />
        </div>
      </div>
      <div className="formRow">
        <div className="formCol">
          <label className="formLabel">Y</label>
          <InputNumber
            className="commInput w100"
            onChange={setStartY}
            value={startY}
          />
        </div>
        <div className="formCol">
          <label className="formLabel">Y</label>
          <InputNumber
            className="commInput w100"
            onChange={setEndY}
            value={endY}
          />
        </div>
      </div>
      <div className="formRow">
        <div className="formCol">
          <label className="formLabel">Starting Theta</label>
          <InputNumber
            className="commInput w100"
            onChange={setStartAltitude}
            value={startAltitude}
          />
        </div>
        <div className="formCol">
          <label className="formLabel">Ending Theta</label>
          <InputNumber
            className="commInput w100"
            onChange={setEndAltitude}
            value={endAltitude}
          />
        </div>
      </div>
      <div className="edit-loader">
        {loader && (
          <div className="loader">
            <Spin size="large" />
          </div>
        )}
      </div>
    </Modal>
  );
};
export default FeedLine;
