import React, { useContext, useEffect, useState } from 'react';
import Layout from 'antd/lib/layout/layout';
import applicationIds from '../../locale/applicationIds.json';
import './ImpStyle.css';
import close_icon from '../../assets/images/right_widget_close_icon.svg';
import alert_circle_red from '../../assets/images/alert_circle_red.svg';
import htich_view from '../../assets/images/htich_view.svg';
import hitch_bottom_gray from '../../assets/images/hitch_bottom_gray.svg';
import { Button, notification } from 'antd';
import EditImplement from './EditImplement';
import hitch_icon from '../../assets/images/hitch_icon.svg';
import { UpdateImplements } from '../../constants/Api';
import { useTranslation } from 'react-i18next';
import { ApplicationContext } from '../../context/AppContext';
import AppLoader from '../common/AppLoader';
import translate from '../../locale/en_translate.json';
import {
  selectTypeImplement,
  selectTypeImplementUnit,
} from '../../constants/Common';

interface Props {
  Close: () => void;
  toggleWidget: boolean;
  detailsList: any;
  setRefresh: () => void;
  setActive: (data: any) => void;
}

const ImplementDetails: React.FC<Props> = ({
  Close,
  toggleWidget,
  detailsList,
  setRefresh,
  setActive,
}: Props) => {
  const [editflag, setEditflag] = useState<boolean>(false);
  const [activeFlag, setActiveFlag] = useState<boolean>();
  const [details, setDeatils] = useState<any>();
  const { t } = useTranslation();
  const { userDetails, APPReducer } = useContext(ApplicationContext);
  const [tstate] = APPReducer;
  const [loader, setLoader] = useState<boolean>(false);

  useEffect(() => {
    if (detailsList) {
      setDeatils(detailsList);
      setActiveFlag(detailsList?.is_active);
    }
  }, [detailsList]);

  const activeImplemnt = async (is_active: any) => {
    details.is_active = is_active;

    try {
      setLoader(true);
      const response: any = await UpdateImplements(
        userDetails.organization.api_url,
        userDetails.organization.fleet.id,
        details?.config_implements_uuid,
        details,
      );
      if (response) {
        response.config_imm = detailsList?.config_imm;
        setActiveFlag(response?.is_active);
        setDeatils(response);
        setActive(response?.is_active);
      }
    } catch (err: any) {
      notification.error({
        message: err?.message,
      });
    } finally {
      setLoader(false);
    }
  };

  return (
    <Layout>
      <div className={'rightSideWidegt ' + (toggleWidget ? 'expand' : 'hide')}>
        <div className="widgetCloseBtn impCloseBtn">
          <img
            id={applicationIds.implementsScreen.impDetailsClose}
            src={close_icon}
            alt="close icon"
            onClick={Close}
          />
        </div>
        <div className="profileView empView rightWidgetView impWidget addImplementWidget widget_card">
          {tstate?.showAutoDriveConnect &&
            activeFlag &&
            !details?.is_autonomy_full_filled &&
            details?.is_autonomy_implement && (
              <p className="Button2 inCompleteAutodriveMsg">
                <img src={alert_circle_red} alt="" />{' '}
                <span>{t(translate.implementView.incompleteAutoDrive)}</span>
              </p>
            )}
          {!activeFlag && (
            <p
              className="Button2 deactivatedAutodriveMsg"
              data-testid="deactivateMessage-ImplementDetails"
            >
              {t(translate.implementView.implementDeactivated)}
            </p>
          )}
          <div style={{ opacity: !activeFlag ? 0.5 : '' }}>
            <div className="userProfileIcon">
              {details?.image_url && details?.image_url !== '' ? (
                <img
                  src={details?.image_url}
                  alt=""
                  className="userProfileImg"
                />
              ) : (
                <img src={hitch_icon} alt="" />
              )}
            </div>
            <h4 className="headline2 viewImpName">{details?.name}</h4>
            <div className="flexBtnCenter body2 mt20">
              <div className="w50per">{details?.config_imm?.manufacturer}</div>
              <div className="w50per textRight">
                {detailsList?.config_imm?.model}
              </div>
            </div>
            <div className="flexBtnCenter body2 mt12">
              <div className="w50per">{details?.implement_type}</div>
              <div className="w50per textRight">{details?.attachment_type}</div>
            </div>
            <h5 className="headline5 viewImpSubHead mt24 mb8">
              {t(translate.implementView.dimensions)}
            </h5>
            <div className="impBox">
              <div className="impInrBox flexBtnCenter gap28">
                <div className="gridCenter gap20 w50per textCenter impDimesions">
                  <div className="">
                    <h6 className="body2">
                      {t(translate.implementView.height)}
                    </h6>
                    <p>
                      {details?.height && (
                        <>
                          {details?.height}{' '}
                          <span>{t(translate.implementView.inches)}</span>
                        </>
                      )}
                    </p>
                  </div>
                  <img src={htich_view} alt="" />
                </div>
                <div className="gridCenter gap20 w50per textCenter impDimesions">
                  <div className="">
                    <h6 className="body2">
                      {t(translate.implementView.length)}
                    </h6>
                    <p>
                      {details?.length && (
                        <>
                          {details?.length}{' '}
                          <span>{t(translate.implementView.inches)}</span>
                        </>
                      )}
                    </p>
                  </div>
                  <img src={hitch_bottom_gray} alt="" />
                </div>
              </div>
              <div className="impInrBox flexBtnCenter gap28">
                <div className="gridCenter gap20 w50per impDimesions">
                  <h6 className="body2 textCenter">
                    {t(translate.implementView.width)}
                  </h6>
                  <p>
                    {details?.width && (
                      <>
                        {details?.width}{' '}
                        <span>{t(translate.implementView.inches)}</span>
                      </>
                    )}
                  </p>
                </div>
                <div className="gridCenter gap20 w50per impDimesions">
                  <h6 className="body2 textCenter">
                    {selectTypeImplement(details?.implement_type)}
                  </h6>
                  <p>
                    {details?.weight && (
                      <>
                        {details?.weight}{' '}
                        <span className="lc">
                          {selectTypeImplementUnit(details?.implement_type)}
                        </span>
                      </>
                    )}
                  </p>
                </div>
              </div>
            </div>
            {tstate?.showAutoDriveConnect && details?.is_autonomy_implement && (
              <>
                <h5 className="headline5 viewImpSubHead mt24 mb10">
                  {t(translate.implementView.autodriveSettings)}
                </h5>
                <div className="impAutodriveBlk">
                  <div
                    className={
                      details?.is_autonomy_full_filled
                        ? 'w50per'
                        : 'w50per NoImpData'
                    }
                  >
                    <>
                      <h6 className="Button2 txtCenter mb11">
                        {t(translate.implementView.inOperation)}
                      </h6>
                      <div className="impDimesions impAutodriveInrBlk txtCenter">
                        <div className="impAutodriveBox">
                          <h6 className="body2">
                            {t(translate.implementView.pto)}
                          </h6>
                          {details?.imp_ctrl_in_row_pto &&
                          details?.imp_ctrl_in_row_pto_rpm ? (
                            <p className="">
                              {details?.imp_ctrl_in_row_pto_rpm}{' '}
                              <span>{t(translate.implementView.rpm)}</span>
                            </p>
                          ) : (
                            <p className="">{t(translate.implementView.off)}</p>
                          )}
                        </div>
                        <div className="flexBtnStart impAutodriveBox">
                          <div className="w50per">
                            <h6 className="body2">
                              {t(translate.implementView.speed)}
                            </h6>
                            <p className="">
                              {details?.imp_ctrl_in_row_tractor_speed}{' '}
                              {details?.imp_ctrl_in_row_tractor_speed > 0 && (
                                <span>{t(translate.implementView.mph)}</span>
                              )}
                            </p>
                          </div>
                          <div className="w50per">
                            <h6 className="body2">
                              {t(translate.implementView.gear)}
                            </h6>
                            <p className="">{details?.imp_ctrl_in_row_gear}</p>
                          </div>
                        </div>
                        <div className="flexBtnStart impAutodriveBox">
                          <div className="w50per">
                            <h6 className="body2">
                              {t(translate.implementView.mode)}
                            </h6>
                            <p className="">
                              {details?.imp_ctrl_in_row_3pt_hitch_mode}
                            </p>
                          </div>
                          {details?.imp_ctrl_in_row_3pt_hitch_mode && (
                            <div className="w50per">
                              <h6 className="body2">
                                {t(translate.implementView.height)}
                              </h6>
                              <p className="">
                                {details?.imp_ctrl_in_row_3pt_hitch_height}%
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  </div>
                  <div
                    className={
                      details?.is_autonomy_full_filled
                        ? 'w50per'
                        : 'w50per NoImpData'
                    }
                  >
                    <h6 className="Button2 txtCenter mb11">
                      {t(translate.implementView.outOfOperation)}
                    </h6>
                    <div className="impDimesions impAutodriveInrBlk txtCenter">
                      <div className="impAutodriveBox">
                        <h6 className="body2">PTO</h6>
                        {details.imp_ctrl_out_of_row_pto &&
                        details?.imp_ctrl_out_of_row_pto_rpm ? (
                          <p className="">
                            {details?.imp_ctrl_out_of_row_pto_rpm}{' '}
                            <span>{t(translate.implementView.rpm)}</span>
                          </p>
                        ) : (
                          <p className="">{t(translate.implementView.off)}</p>
                        )}
                      </div>
                      <div className="flexBtnStart impAutodriveBox">
                        <div className="hintTxt">
                          {t(translate.addImplement.outOfRowHint)}
                        </div>
                      </div>
                      <div className="flexBtnStart impAutodriveBox">
                        <div className="w50per">
                          <h6 className="body2">
                            {t(translate.implementView.mode)}
                          </h6>
                          <p className="">
                            {details?.imp_ctrl_out_of_row_3pt_hitch_mode}
                          </p>
                        </div>
                        {details?.imp_ctrl_out_of_row_3pt_hitch_mode && (
                          <div className="w50per">
                            <h6 className="body2">
                              {t(translate.implementView.height)}
                            </h6>
                            <p className="">
                              {details?.imp_ctrl_out_of_row_3pt_hitch_height}%
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="loaderIcon">
            <AppLoader loader={loader} />
          </div>

          {activeFlag === true && (
            <div className="impBtmBtns">
              <div
                className="impDeactivateBtn Button2"
                onClick={() => activeImplemnt(false)}
                data-testid="deactivateButton-ImplementDetails"
              >
                {' '}
                {t(translate.buttons.deactivate)}
              </div>
              <Button
                className="impEditBtn Button2"
                data-testid="editImplementButton-ImplementDetails"
                onClick={() => setEditflag(true)}
              >
                {t(translate.buttons.editImplement)}
              </Button>
            </div>
          )}

          {activeFlag === false && (
            <Button
              className="Button addImplementBtn"
              onClick={() => activeImplemnt(true)}
              data-testid="reactivateButton-ImplementDetails"
            >
              {t(translate.buttons.reactivateImplement)}
            </Button>
          )}
        </div>
      </div>
      {editflag && details && (
        <EditImplement
          Close={Close}
          toggleWidget={editflag}
          detailsList={details}
          setRefresh={setRefresh}
        />
      )}
    </Layout>
  );
};

export default ImplementDetails;
