/* eslint-disable no-console */
/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import {
  DoubleLeftOutlined,
  ExclamationCircleOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import {
  Button,
  Empty,
  Layout,
  Menu,
  Modal,
  notification,
  Result,
  Space,
} from 'antd';
import { Content } from 'antd/lib/layout/layout';
import Sider from 'antd/lib/layout/Sider';
import { useTranslation } from 'react-i18next';
import translate from '../../../../locale/en_translate.json';

import {
  EXECUTE,
  SET_SELECTED_CHAPTER,
  SET_SELECTED_TOPIC,
  SET_TOPIC_CREATE,
  SET_TOPIC_EDIT,
} from './UserManualReducer';
import './style.css';
import { UserManualAppContext } from './UserManualContext';
import EditTopicWidget from './EditTopicWidget';
import CreateTopicWidget from './CreateTopicWidget';
import { SubTopic } from '../../../../constants/types';
import SubItems from '../../../HowToManual/SubItems';
import RenderPDF from './RenderPDF';
import TextArea from '../../../common/TextArea';
import { DeleteTopic, getTopicData } from '../../API';

interface Props {
  showModel: boolean;
  close: (state?: boolean) => void;
  manualrecord?: any;
}
const Manual: React.FC<Props> = ({ showModel, close, manualrecord }) => {
  const { state } = useLocation();
  const { goBack } = useHistory();
  const { t } = useTranslation();
  const { userManualReducer } = useContext(UserManualAppContext);
  const [rstate, dispatch] = userManualReducer;
  const data: any = manualrecord;
  const [openKeys, setOpenKeys] = React.useState(['']);
  const [topics, setTopics] = useState([]);
  const [refresh, setRefresh] = useState<boolean>(false);
  const { selectedTopic, selectedChapter, topicEdit, topicCreate } = rstate;

  console.log(rstate, 'hgycyhfujh');

  const rootSubmenuKeys = [''];
  const onOpenChange = (keys: any) => {
    const latestOpenKey = keys.find((key: any) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  useEffect(() => {
    if (state) {
      dispatch({ type: SET_SELECTED_CHAPTER, payload: data });
      loadTopicData();
    }
  }, [state, refresh]);

  const loadTopicData = async () => {
    const resp = await getTopicData(data.id, 0);
    const tresp = resp.reverse();
    setTopics(tresp);
  };

  useEffect(() => {
    if (topics && topics.length && selectedTopic) {
      const [topic] = topics.filter(
        (item: any) => Number(item.id) === Number(selectedTopic.id),
      );
      if (topic) dispatch({ type: SET_SELECTED_TOPIC, payload: topic });
    } else if (topics.length) {
      dispatch({ type: SET_SELECTED_TOPIC, payload: topics[0] });
    } else if (!topics.length) {
      dispatch({ type: SET_SELECTED_TOPIC, payload: null });
    }
  }, [topics]);

  const handleSelectedTopic = (topic: any) => {
    dispatch({ type: SET_SELECTED_TOPIC, payload: topic });
  };

  const handleGoBack = () => {
    close(true);
  };

  const deleteTopic = async () => {
    try {
      const response = await DeleteTopic(selectedChapter.id, selectedTopic.id);
      notification.success({
        message: response.msg,
      });
      setRefresh(!refresh);
      dispatch({
        type: SET_SELECTED_TOPIC,
        payload: null,
      });
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    }
  };

  const confirm = (topic: any) => {
    Modal.confirm({
      title: `Are you sure you want to delete ${topic?.title}?`,
      icon: <ExclamationCircleOutlined />,
      // content: 'Some descriptions',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => deleteTopic(),
    });
  };

  return (
    <Layout style={{ minHeight: '100vh' }} className="hm_Container">
      <Sider
        trigger={null}
        collapsible={false}
        style={{
          background: '#f00',
          color: '#fff',
        }}
        className="manualMenu"
        width="300"
      >
        <div className="ht_Head">
          <Space>
            <DoubleLeftOutlined
              style={{ margin: '0 15px' }}
              onClick={handleGoBack}
            />
            <div onClick={handleGoBack}>{selectedChapter?.title}</div>
            {!selectedChapter?.published_status && (
              <PlusCircleOutlined
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch({
                    type: EXECUTE,
                    payload: {
                      selectedTopic: null,
                      topicCreate: true,
                    },
                  });
                }}
              />
            )}
          </Space>
        </div>
        {selectedTopic && !selectedChapter?.published_status && <></>}
        <div className="manual_Main_section">
          <Menu
            theme="dark"
            defaultSelectedKeys={['1']}
            mode="inline"
            className="hm_listitems"
            openKeys={openKeys}
            onOpenChange={onOpenChange}
          >
            {topics ? (
              topics.map((topic: SubTopic) => (
                <>
                  {topic.is_has_sub_topics ? (
                    <>
                      <SubItems topic={topic} />
                    </>
                  ) : (
                    <>
                      <Menu.Item
                        key={topic.id}
                        onClick={() => handleSelectedTopic(topic)}
                        className={
                          selectedTopic?.id === topic?.id
                            ? 'topic-selected'
                            : ''
                        }
                      >
                        {topic.title}
                      </Menu.Item>
                    </>
                  )}
                </>
              ))
            ) : (
              <></>
            )}
          </Menu>
        </div>
      </Sider>
      <Layout className="hm_rightWrapper manualEditorBlk">
        <div className="ht_right" style={{ cursor: 'pointer' }}>
          <Space>
            <Button
              type="primary"
              onClick={(e) => {
                e.stopPropagation();
                dispatch({
                  type: EXECUTE,
                  payload: {
                    selectedTopic: null,
                    topicCreate: true,
                  },
                });
              }}
            >
              {' '}
              {t(translate.buttons.createTopic)}
            </Button>
            <Space>
              <Button
                type="primary"
                onClick={() =>
                  dispatch({ type: SET_TOPIC_CREATE, payload: true })
                }
              >
                {t(translate.buttons.createSubSection)}
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  dispatch({ type: SET_TOPIC_EDIT, payload: true });
                }}
              >
                {t(translate.buttons.edit)}
              </Button>
              <Button
                className="btnCancel"
                htmlType="button"
                onClick={() => confirm(selectedTopic)}
              >
                {t(translate.buttons.delete)}
              </Button>
            </Space>
          </Space>
        </div>
        <Content style={{ paddingTop: '30px' }}>
          {selectedTopic ? (
            <>
              {topicEdit && (
                <EditTopicWidget refresh={() => setRefresh(!refresh)} />
              )}
            </>
          ) : (
            !topicCreate && (
              <Result
                icon={<Empty />}
                extra={
                  !selectedChapter?.published_status && (
                    <Button
                      type="primary"
                      data-testid="createTopicButton-Manual"
                      onClick={() =>
                        dispatch({ type: SET_TOPIC_CREATE, payload: true })
                      }
                    >
                      {t(translate.buttons.createTopic)}
                    </Button>
                  )
                }
              />
            )
          )}
          <>
            {topicCreate && (
              <CreateTopicWidget
                refresh={() => setRefresh(!refresh)}
                chapterId={selectedChapter?.id}
                topicNumber={selectedTopic?.id}
              />
            )}
          </>
          <>
            {!topicCreate && !topicEdit && (
              <>
                {selectedTopic?.file_path ? (
                  <RenderPDF filePath={selectedTopic?.file_path} />
                ) : (
                  selectedTopic?.description && (
                    <div className="fixedHeightEditor">
                      <TextArea
                        description={selectedTopic?.description}
                        readOnly
                        hideToolbar
                      />
                    </div>
                  )
                )}
              </>
            )}
          </>
        </Content>
      </Layout>
    </Layout>
  );
};

export default Manual;
