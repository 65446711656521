import { Layout, Row, Input, notification, Space, Button } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { useContext, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import translate from '../../locale/en_translate.json';
import { getCategoryList } from '../../constants/Api';
import { ApplicationContext } from '../../context/AppContext';
import InfiniteScrollTable from '../common/InfiniteScrollTable';
import {
  alignItems,
  initClearSelection,
  initScroller,
} from '../../constants/Common';
import { delay } from '../../constants/Common';
import verifyIcon from '../../assets/images/7641727 (1).png';
import CustomSelect from '../common/CustomSelect';
import CustomSelect1 from '../common/CustomSelect1';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
// import ImplementDetails from './ImplementDetails';
import alert_circle_red from '../../assets/images/alert_circle_red.svg';
import usePaginate from '../../hooks/usePaginate';
import { mapImplementsData } from '../../lib/dataFormat';
import { monarchCertifiedImplements } from './data';

interface Props {
  handleImports: (data: any) => void;
  disabledRecords: any[];
}

export const preferences = [
  {
    value: 'Monarch',
    name: 'Monarch Preferred',
  },
  {
    value: 'None',
    name: 'None',
  },
];

export const certificationLevel = [
  {
    value: 0,
    name: 'Not allowed',
  },
  {
    value: 1,
    name: 'Manual',
  },
  {
    value: 2,
    name: 'Manual + ADAS',
  },
  {
    value: 3,
    name: 'Manual + ADAS + Supervised Autodrive',
  },
  {
    value: 4,
    name: 'Manual + ADAS + Supervised Autodrive & unsupervised Autodrive',
  },
];

let columnsData: any[] = [];

const CertifiedImplements: React.FC<Props> = ({
  handleImports,
  disabledRecords,
}) => {
  const { t } = useTranslation();
  const { userDetails, privilegeChecker, APPReducer } =
    useContext(ApplicationContext);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [implementSearch, setImplementSearch] = useState<string>('');
  const [loader, setLoader] = useState<boolean>(true);
  const [totalCount, setTotalCount] = useState<any>();
  const [implementType, setImpleteType] = useState<any>([]);
  const [implemeant_Type, setImplemeant] = useState<any>('');
  const [init, setInit] = useState<boolean>(false);
  const [preference, setPreference] = useState<string>('');
  const [certifications, setCertifications] = useState<number>();

  const [sortedData, setSortedData] = useState<any>({
    name: 'ascend',
  });
  const [sortedInfo, setSortedInfo] = useState<any>({
    columnKey: 'name',
    order: 'ascend',
  });
  const [keyCode, setKeyCode] = useState<boolean>(false);
  const [tstate] = APPReducer;
  const { showAutoDriveConnect } = tstate;

  const searchString = useRef<string>('');
  const { filterData, setData, activity, hasMore, checkActivity } =
    usePaginate();
  const [columns, setColumns] = useState<any[]>([]);

  useEffect(() => {
    if (userDetails?.organization) getProfileLookps();
    getImplementsListData();
  }, [userDetails]);

  const filterImplements = () => {
    if (activity.current === 'filter') {
      if (implemeant_Type === '') {
        setData(mapImplementsData(monarchCertifiedImplements?.records, tstate));
      } else {
        const filteredImplements = filterData?.filter(
          (implement: any) => implement.implement_type === implemeant_Type,
        );
        setData(mapImplementsData(filteredImplements, tstate));
      }
    }
    if (activity.current === 'search') {
      if (implementSearch === '') {
        setData(mapImplementsData(monarchCertifiedImplements?.records, tstate));
      } else {
        const filteredImplements = filterData?.filter((implement: any) =>
          implement?.name
            ?.toLowerCase()
            ?.includes(implementSearch?.toLowerCase()),
        );
        setData(mapImplementsData(filteredImplements, tstate));
      }
    }
  };

  useEffect(() => {
    if (init) filterImplements();
  }, [implemeant_Type, implementSearch]);

  useEffect(() => {
    columnsData = [
      {
        title: (filters: any) => {
          return (
            <>
              {t(translate.implements.implementid)}
              {/* <span className="sort-pad">
                {sortedInfo.columnKey === 'name' && (
                  <img
                    className="sort-pad"
                    src={sortedData['name'] === 'ascend' ? sortUp : sortDown}
                  />
                )}
              </span> */}
            </>
          );
        },
        defaultSortOrder: 'ascend',
        sortDirections: ['descend', 'ascend', 'descend'],
        dataIndex: 'name',
        key: 'name',
        sorter: (a: any, b: any) => {
          return a.name?.localeCompare(b.name);
        },
        sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
        ellipsis: true,
        showSorterTooltip: false,
        render: (name: string, record: any) => {
          return (
            <span data-testid={`${name}-Implements`}>
              {(record?.name.includes('Land Pride') ||
                record?.name.includes('Schmeiser')) && (
                <img
                  src={verifyIcon}
                  alt=""
                  style={{ marginRight: '7px', height: '20px', width: '20px' }}
                />
              )}
              {name}
            </span>
          );
        },
      },
      {
        title: `${t(translate.implements.manufacturer)}`,
        dataIndex: 'manufacturer',
        key: 'manufacturer',
        render: (manufacturer: string) => {
          return (
            <span data-testid={`${manufacturer}-Implements`}>
              {manufacturer}
            </span>
          );
        },
      },
      {
        title: `${t(translate.implements.model)}`,
        dataIndex: 'model',
        key: 'model',
        render: (model: string) => {
          return (
            <span className="description" data-testid={`${model}-Implements`}>
              {model}
            </span>
          );
        },
      },

      {
        title: `${t(translate.implements.implemeantType)}`,
        dataIndex: 'implement_type',
        key: 'implement_type',
        render: (implement_type: string) => {
          return (
            <span data-testid={`${implement_type}-Implements`}>
              {implement_type}
            </span>
          );
        },
      },
      {
        title: `Action`,
        dataIndex: 'name',
        key: 'name',
        render: (data: string, record: any) => {
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Button
                className="implement_action"
                type="primary"
                style={{ marginRight: '10px' }}
                onClick={() => {
                  initClearSelection();
                  handleImports(record);
                }}
              >
                Import{' '}
              </Button>

              {(record?.name.includes('Land Pride') ||
                record?.name.includes('Schmeiser')) && (
                <Button
                  className="implement_action"
                  type="primary"
                  // onClick={() => {
                  //   initClearSelection();
                  //   handleImports(record);
                  // }}
                >
                  I want this{' '}
                </Button>
              )}
            </div>
          );
        },
      },
    ];
    setColumns(columnsData);
  }, [disabledRecords]);

  const handleChange = (pagination: any, filters: any, sorter: any) => {
    setLoader(true);
    activity.current = 'sort';
    const { columnKey, order } = sorter;
    setSortedData({ ...sortedData, [columnKey]: order });
    setSortedInfo(sorter);
  };

  const getProfileLookps = async () => {
    try {
      const cropsdata = await getCategoryList(
        userDetails.organization.api_url,
        'implement_type',
      );
      setImpleteType(cropsdata);
    } catch (error: any) {
      notification.error({
        message: error.message,
        duration: 2,
      });
    }
  };

  const getImplementsListData = async () => {
    try {
      if (checkActivity()) initScroller();
      if (keyCode) {
        await delay(1000);
      }

      setLoader(true);
      setTotalCount(monarchCertifiedImplements?._metadata?.total_records_count);
      const { records } = monarchCertifiedImplements;
      let data = Array.isArray(records) ? records : [];
      data = mapImplementsData(records, tstate);
      setData(data);
    } catch (error: any) {
      notification.error({
        message: error.message,
        duration: 2,
      });
    } finally {
      setLoader(false);
      if (!init) setInit(true);
    }
  };

  const handleSearch = (e: any) => {
    activity.current = 'search';
    const value = e.target.value.trim().regX;
    if (value.length <= 1) {
      searchString.current = '';
      setImplementSearch('');
    } else {
      searchString.current = value;
      setImplementSearch(value);
    }
  };

  // const addImplement = () => {
  //   setAddImplementFlag(true);
  // };

  const Close = () => {
    activity.current = 'refresh';
    // setAddImplementFlag(false);
    // setImplementDetails(false);
    initClearSelection();
  };

  const handleLoadMore = () => {
    if (pageNumber === 1 && checkActivity() && document) initScroller();

    activity.current = 'paginate';
    setPageNumber(pageNumber + 1);
  };

  // const getRow = (record: any) => {
  //   editimplementmethod(record?.config_implements_uuid);
  // };

  // const editimplementmethod = async (id: string) => {
  //   try {
  //     const implemeantsData = await getimplementbyId(
  //       userDetails.organization.api_url,
  //       userDetails.organization.fleet.id,
  //       id,
  //     );
  //     setDetailsList(implemeantsData);
  //     setImplementDetails(true);
  //   } catch (err: any) {
  //     notification.error({
  //       message: err.message,
  //     });
  //   }
  // };
  const handleKey = (e: any) => {
    setKeyCode(false);
    if (e.keyCode === 8) {
      setKeyCode(true);
    }
  };

  return (
    <Layout>
      <div className="mainContent">
        <Content>
          <div className="tblContainer viewportContainer support_implements">
            <Row>
              <div
                className={`common_wrapper ${
                  !showAutoDriveConnect ? 'wWidget_wrapper' : ''
                }`}
              >
                <div className="filters_card spaceBtnAlignCenter">
                  <div className="displayFlex">
                    <Space size="large">
                      <Input
                        data-testid="searchInventoryInputField-Implements"
                        addonBefore={<SearchOutlined />}
                        placeholder="Search Inventory"
                        className="common_search"
                        onChange={(e) => handleSearch(e)}
                        onKeyDown={(e) => handleKey(e)}
                        autoComplete="off"
                      />
                    </Space>

                    <CustomSelect
                      label="Preferences"
                      cssClass="min_width"
                      value={preference}
                      setValue={(value: any) => {
                        activity.current = 'filter';
                        setPreference(value);
                      }}
                      options={preferences}
                      optionKey="value"
                      optionDisplay="name"
                    ></CustomSelect>

                    <CustomSelect1
                      label="Type"
                      cssClass="min_width"
                      value={implemeant_Type}
                      setValue={(id: any) => {
                        activity.current = 'filter';
                        setImplemeant(id);
                      }}
                      options={implementType}
                      optionKey="name"
                      isAll={true}
                    />
                    <CustomSelect1
                      label="Certifications"
                      cssClass="min_width"
                      value={certifications}
                      setValue={(id: any) => {
                        activity.current = 'filter';
                        setCertifications(id);
                      }}
                      options={certificationLevel}
                      optionKey="value"
                      optionDisplay="name"
                    />
                  </div>
                </div>
                <div className="common_table implements_tbl">
                  <InfiniteScrollTable
                    columns={columns}
                    hasMore={hasMore}
                    loading={loader}
                    filterData={filterData}
                    totalcount={totalCount}
                    handleLoadMore={handleLoadMore}
                    filename="Implements"
                    // onRowClick={(record) => getRow(record)}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </Row>
          </div>
        </Content>
        {/* {detailsList && implementDetails && (
          <ImplementDetails
            Close={Close}
            toggleWidget={implementDetails}
            detailsList={detailsList}
            setRefresh={() => setRefresh(true)}
            setActive={setActive}
          />
        )} */}
      </div>
    </Layout>
  );
};
export default CertifiedImplements;
