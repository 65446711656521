import React, { memo, useContext, useEffect, useRef, useState } from 'react';
import {
  CaretRightOutlined,
  ExpandOutlined,
  LeftOutlined,
  PauseOutlined,
  RightOutlined,
} from '@ant-design/icons';
import { Button, Card, Layout, Modal, Row, Space, Tooltip } from 'antd';
import { ApplicationContext } from '../../../context/AppContext';
import { Content } from 'antd/lib/layout/layout';
import moment from 'moment';
import { Player } from 'video-react';
import { getDateTime, sortCameraViews } from '../../../constants/Common';
import RoutesConstants from '../../../routes/RoutesConstant';
import { AnalyticsContext } from '../AnalyticsContext';
import 'video-react/dist/video-react.css'; // import css
import './style.css';
import { getVideoURL } from '../../../constants/Api';
import AppLoader from '../../common/AppLoader';
import VideoGrid from './VideoGrid';
import { VideoListObj } from '../../../constants/types';
import BarHandler from '../../common/BarHandler';

const cameraMap: any = {
  front: 'Front',
  front_left: 'Left',
  front_right: 'Right',
  rear: 'Rear',
  pto: 'Hitch',
  driver: 'driver',
  rear_left: 'rear_left',
  rear_right: 'rear_left',
};

const MediaWidget1: React.FC = () => {
  const { analyticReducer, videoTime } = useContext(AnalyticsContext);
  const { userDetails } = useContext(ApplicationContext);
  const [state] = analyticReducer;
  const { JAG } = state;
  const ini = useRef(false);
  const iv = useRef<any>();
  const [updatedTime, setUpdatedTime] = useState<number>(0);
  const [videos, setVideos] = useState<VideoListObj[]>([]);
  const [playState, setPlayState] = useState(true);
  const [duration, setDuration] = useState<number[]>([0, 0]);
  const [vLength, setVLength] = useState<number>(300);
  const [showLoader, setShowLoader] = useState<boolean>(true);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [videoModal, setVideoModal] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      setUpdatedTime(0);
      setVideos([]);
      setPlayState(true);
      setDuration([0, 0]);
      setVLength(0);
      setRefresh(false);
      setShowLoader(false);
      setVideoModal(false);
      clearInterval(iv.current);
    };
  }, []);

  const errorRef = useRef<any>({
    front: false,
    rear: false,
    pto: false,
    front_left: false,
    front_right: false,
  });

  const videoRef = useRef<any>({
    front: undefined,
    rear: undefined,
    pto: undefined,
    front_left: undefined,
    front_right: undefined,
  });

  const checkVideos = () => {
    return Object.values(videoRef.current).map((item) => item !== undefined);
  };

  const loadDuration = () => {
    if (checkVideos().includes(true) && ini.current) {
      const durations = Object.values(videoRef.current)
        .filter((item) => item)
        .map((item: any) => {
          const { player } = item.getState();
          return Math.round(player.duration);
        });
      setVLength(Math.max(...durations));
    }
  };

  const play = () => {
    setPlayState(false);
    Object.values(videoRef.current).forEach((item: any) => {
      if (item) item.play();
    });
  };

  const pause = () => {
    setPlayState(true);
    Object.values(videoRef.current).forEach((item: any) => {
      if (item) item.pause();
    });
  };

  const delay = (ms: number) =>
    new Promise<void>((resolve) => setTimeout(() => resolve(), ms));

  const seek = async (seconds: number) => {
    setPlayState(true);
    Object.values(videoRef.current).forEach((item: any) => {
      if (item) {
        item.seek(seconds);
        item.pause();
      }
    });
    // await delay(1000);
    // play();
  };

  const rightArrowClick = () => {
    if (duration[1] >= moment(updatedTime).add(5, 'minutes').valueOf()) {
      setUpdatedTime((prev: any) => {
        return moment(prev).add(5, 'minutes').valueOf();
      });
    }
  };

  const leftArrowClick = () => {
    if (duration[0] <= moment(updatedTime).subtract(5, 'minutes').valueOf()) {
      setUpdatedTime((prev: any) => {
        return moment(prev).subtract(5, 'minutes').valueOf();
      });
    }
  };

  const processDates = (dates: any[]) =>
    dates.map((date: any) => {
      date = new Date(date);
      date.setSeconds(0);
      date.setMilliseconds(0);
      return date.getTime();
    });

  const getVideoData = async () => {
    try {
      pause();
      ini.current = false;
      setShowLoader(true);
      let response: VideoListObj[] = await getVideoURL(
        userDetails.organization.api_url,
        userDetails.organization.farm.id,
        JAG?.tractor?.id,
        updatedTime,
      );
      response = sortCameraViews(response, 'direction', 'videos');
      response = response.map((item) => {
        item.direction = item.direction.replace('-', '_');
        return item;
      });
      response = response.filter((item: any) =>
        ['front', 'rear', 'pto', 'front_left', 'front_right'].includes(
          item.direction,
        ),
      );
      setVideos(response);
    } finally {
      setShowLoader(false);
      setRefresh(!refresh);
    }
  };

  // const load = () => {
  //   Object.values(videoRef.current).forEach((item: any) => {
  //     if (item) item.load();
  //   });
  // };

  // const forward = (seconds: number) => {
  //   Object.values(videoRef.current).map((item: any) => {
  //     if (item) {
  //       item.video.video.currentTime = item.video.video.currentTime + seconds;
  //       item.seek(item.video.video.currentTime);
  //     }
  //   });
  // };

  // const onError = (face: string) => {
  //   if (videoRef.current[face]) {
  //     const { player } = videoRef.current[face].getState();
  //     errorRef.current[face] = player.error ? true : false;
  //   }
  // };

  // const openFullscreen = (videoUrl: any) => {
  //   videoUrl &&
  //     window.open(
  //       `${RoutesConstants.VideoPop}?videoUrl=${videoUrl}`,
  //       `Video URL`,
  //       'max-width: 100%',
  //     );
  // };

  const playbackRate = (steps: number) => {
    // setPlayState(true);
    Object.values(videoRef.current).forEach((item: any) => {
      if (item) {
        item.video.video.playbackRate = steps;
      }
    });
  };

  // const setMuted = (muted) => {
  //   Object.values(videoRef.current).map((item) => {
  //     if (item instanceof Player) {
  //       item.muted = muted;
  //     }
  //   });
  // };

  // const changeVolume = (steps:) => {
  //   Object.values(videoRef.current).map((item: Player) => {
  //     if (item instanceof Player) {
  //       item.volume = item.volume + steps;
  //     }
  //   });
  // };

  useEffect(() => {
    if (JAG) {
      const startDate: any = JAG['drive_action_start_date_time'];
      const endDate: any = JAG['drive_action_finish_date_time'];
      const dates = processDates([startDate, endDate]);
      setDuration(dates);
      setUpdatedTime(videoTime ? videoTime : dates[0]);
    }
  }, [JAG, videoTime]);

  useEffect(() => {
    if (userDetails && userDetails.organization && updatedTime && JAG) {
      setVideos([]);
      getVideoData();
    }
  }, [userDetails, updatedTime]);

  useEffect(() => {
    const checkReadyState = () => {
      ini.current = false;
      if (!checkVideos().includes(true)) return;
      const readyStates = Object.values(videoRef.current)
        .filter((item) => item)
        .every((item: any) => {
          if (item?.getState()) {
            const { player } = item.getState();
            errorRef.current[item.direction] = Boolean(player?.error);
            return player.readyState === 4;
          }
          return false;
        });
      if (readyStates) {
        ini.current = true;
        setRefresh(!refresh);
        playbackRate(0.75);
        clearInterval(iv.current);
      }
    };

    if (videos.length)
      iv.current = setInterval(() => {
        checkReadyState();
      }, 1000);

    return () => clearInterval(iv.current);
  }, [videos]);

  useEffect(() => {
    loadDuration();
    return () => {
      setRefresh(false);
    };
  }, [refresh]);

  const NOVIDEO = (
    <>{!showLoader && <div className="noVideofile">No Video Available</div>}</>
  );

  const videosDisplay = () => {
    if (videos && videos.length > 0) {
      pause();
      setVideoModal(true);
    }
  };
  return (
    <div className="media-widget1">
      <Tooltip title="Full Screen">
        <ExpandOutlined
          style={{ opacity: videos && videos.length > 0 ? 1 : 0.2 }}
          className="fullScreenVideoThumb vExp"
          onClick={videosDisplay}
        />
      </Tooltip>
      <Layout>
        <div className="mainContent">
          <Content>
            <Row>
              <Card
                bordered={true}
                className="dbWidget videoDbCon"
                extra={
                  <Space
                    className="mt0"
                    style={{ marginBottom: 0, position: 'relative' }}
                  >
                    <p
                      style={{ fontFamily: 'Montserrat-Medium', fontSize: 13 }}
                    >
                      <a
                        style={{ fontWeight: 'bold' }}
                        onClick={leftArrowClick}
                      >
                        <LeftOutlined />
                      </a>{' '}
                      <span>{updatedTime && getDateTime(updatedTime)}</span>
                      <a
                        style={{ fontWeight: 'bold' }}
                        onClick={rightArrowClick}
                      >
                        {' '}
                        <RightOutlined />
                      </a>
                    </p>
                  </Space>
                }
              >
                <div
                  className="snapContainer fullWidth vediocontiner mediaContainer iconSize"
                  style={{ position: 'relative' }}
                >
                  {videos && !!videos.length
                    ? videos
                        .filter((vid) => !errorRef.current[vid.direction])
                        .map((vid: any, i: number) => {
                          return (
                            <div className="videoSnaps" key={i}>
                              <p className="snapText">
                                {cameraMap[vid?.direction]}
                              </p>
                              <Player
                                // autoPlay
                                ref={(player: any) => {
                                  videoRef.current[vid.direction] = player;
                                }}
                                // onError={() =>
                                //   videoRef.current[vid.direction] && onError(vid.direction)
                                // }
                                playsInline
                                // poster="/assets/poster.png"
                                src={vid.video_url}
                              />
                            </div>
                          );
                        })
                    : NOVIDEO}
                  {/* <AppLoader loader={!ini.current} /> */}
                  <AppLoader loader={showLoader} />
                </div>
                {videos && !!videos.length && (
                  <div className="barDisplay">
                    <Button
                      style={{ display: 'block' }}
                      className="okBtn"
                      onClick={() => (playState ? play() : pause())}
                    >
                      {playState ? <CaretRightOutlined /> : <PauseOutlined />}
                    </Button>

                    <div className="progress">
                      <div className="progress__filled"></div>
                    </div>
                    <div
                      style={{
                        width: '140px',
                        fontFamily: 'Montserrat-SemiBold',
                      }}
                    >
                      {/* <span ref={curTime}>00:00</span> /{' '}
                      <span ref={fixTime}>00:00</span> */}
                      {!!document.getElementsByClassName('progress').length && (
                        <BarHandler
                          videoRef={videoRef}
                          seek={seek}
                          vLength={vLength}
                          progressSelector="progress"
                          progressBarSelector="progress__filled"
                        />
                      )}
                    </div>
                  </div>
                )}
              </Card>
            </Row>
          </Content>
        </div>
        {updatedTime && videoModal && (
          <Modal
            title={''}
            visible={videoModal}
            className="thubnailVideosFull"
            onCancel={() => setVideoModal(false)}
            footer={null}
            width="95vw"
          >
            <VideoGrid UpdatedTime={updatedTime} />
          </Modal>
        )}
      </Layout>
    </div>
  );
};

export default MediaWidget1;
