/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-shadow */
import { notification, Button, Layout, Row } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import './styles.css';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DownloadOutlined } from '@ant-design/icons';
import { ApplicationContext } from '../../../../context/AppContext';
import translate from '../../../../locale/en_translate.json';
import 'react-datepicker/dist/react-datepicker.css';
import constants from '../../../../constants/constant';
import usePaginate from '../../../../hooks/usePaginate';
import { initScroller } from '../../../../constants/Common';
import TableExt from '../../../widget/TableExt';
import InfiniteScrollTable from '../../../common/InfiniteScrollTable';
import { getOnDemandReportsList } from '../../API';
import { mapNexusOnDemandReportsData } from '../../../../lib/dataFormat';

interface Props {
  showModal: boolean;
  // eslint-disable-next-line no-unused-vars
  close: (state?: boolean) => void;
  recordObj: any;
}

const { DOWNLOAD_SIZE } = constants;

let columns: any[] = [];
const OnDemandReportsList: React.FC<Props> = ({
  showModal,
  close,
  recordObj,
}: Props) => {
  const { userDetails } = useContext(ApplicationContext);
  const { state } = useLocation();
  const { t } = useTranslation();
  const { id, name, report_type } = recordObj as {
    id: number;
    name: string;
    report_type: string;
  };
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [loader, setLoader] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);
  const [pageSize] = useState<number>(25);
  const [initialize, setInitialize] = useState<boolean>(false);
  const [headers, setHeaders] = useState<any[]>([]);
  const { filterData, setData, activity, hasMore, checkActivity } =
    usePaginate();
  const [csvData, setCSVData] = useState<any[]>([]);
  const [downloadData, setDownloadData] = useState({
    isDownloaded: false,
    percent: 0,
    status: '',
  });
  const [toggleColumnsStatus, setToggleColumnsStatus] = useState(false);

  const wrapResult = async (
    orgId: number,
    id: number,
    pageNumber: number,
    pageSize: number,
  ) => {
    const result = await getOnDemandReportsList(
      orgId,
      id,
      pageNumber,
      pageSize,
    );
    return {
      result,
    };
  };

  const getFleetUsageList = async () => {
    try {
      if (checkActivity()) initScroller();
      setLoader(true);
      const { result } = await wrapResult(
        userDetails.organization_id,
        id,
        pageNumber,
        pageSize,
      );

      setTotal(result._metadata.total_records_count);
      const { records } = result;
      let data = Array.isArray(records) ? records : [];

      data = mapNexusOnDemandReportsData(data, state, report_type);
      setData(data);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    } finally {
      setLoader(false);
      if (!initialize) setInitialize(true);
    }
  };

  const loadOnDemandReports = () => {
    if (userDetails && !userDetails.organization) return;
    if (checkActivity()) {
      // eslint-disable-next-line no-unused-expressions
      pageNumber !== 1 ? setPageNumber(1) : getFleetUsageList();
    } else if (activity.current === 'paginate' || activity.current === '') {
      getFleetUsageList();
    }
  };

  const handleLoadMore = () => {
    if (pageNumber === 1 && checkActivity() && document) initScroller();

    activity.current = 'paginate';
    setPageNumber(pageNumber + 1);
  };
  useEffect(() => {
    columns = [
      {
        title: `${t(translate.baseStation.Date)}`,
        dataIndex: 'createdDateTime',
        key: 'createdDateTime',
        width: '100px',
      },
      {
        title: `${t(translate.baseStation.Name)}`,
        dataIndex: 'reports_name',
        key: 'reports_name',
        width: '240px',
        ellipsis: true,
      },
      {
        title: `${t(translate.OnDemandReports.Status)}`,
        dataIndex: 'reports_status',
        key: 'reports_status',
        width: '120px',
      },
      {
        title: `${t(translate.updateManager.action)}`,
        dataIndex: 'id',
        key: 'id',
        width: '100px',
        // eslint-disable-next-line react/display-name
        render: (_: any, record: any) => (
          <>
            <Button
              onClick={() => {
                window.open(record.reports_url);
              }}
              icon={<DownloadOutlined />}
            />
          </>
        ),
      },
    ];

    if (columns) {
      const header = columns
        .map((column: any) => ({
          label: column.title,
          key: column.dataIndex,
        }))
        .filter(({ key }) => key !== 'id');
      setHeaders(header);
    }
  }, []);

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      loadOnDemandReports();
    }
  }, [userDetails, pageNumber, pageSize, state]);

  const handleRefresh = () => {
    activity.current = 'refresh';
    loadOnDemandReports();
  };

  const handleDownload = async () => {
    try {
      if (downloadData.status === 'start') return;
      setDownloadData({
        ...downloadData,
        status: 'start',
        percent: 10,
      });
      let data: any[] = [];
      const pages = Math.ceil(total / DOWNLOAD_SIZE);
      // eslint-disable-next-line no-restricted-syntax
      for (const page of Array.from({ length: pages }, (_, i) => i + 1)) {
        // eslint-disable-next-line no-await-in-loop
        const { result } = await wrapResult(
          userDetails.organization_id,
          id,
          page,
          DOWNLOAD_SIZE,
        );
        const { records } = result;
        let tdata = Array.isArray(records) ? records : [];
        tdata = mapNexusOnDemandReportsData(tdata, state, report_type);
        data = [...data, ...tdata];
        setDownloadData({
          ...downloadData,
          status: 'start',
          percent: Math.round((data.length / total) * 100),
        });
      }

      setCSVData([...data]);
      setDownloadData({
        ...downloadData,
        percent: 100,
        status: 'success',
      });
    } catch (error: any) {
      setDownloadData({
        ...downloadData,
        status: 'exception',
      });
      notification.error({ message: error.message });
    }
  };

  return (
    <>
      <Layout onClick={() => setToggleColumnsStatus(!toggleColumnsStatus)}>
        <div className="mainContent">
          <div className="tblContainer viewportContainer">
            <Row>
              <div className="common_wrapper tab2FilterWidget_wrapper">
                <div className="filters_card ad_filters_card filtersHealthScreen">
                  <Button
                    className="ant-btn-primary"
                    onClick={() => close(true)}
                  >
                    Go Back
                  </Button>
                  <TableExt
                    handleRefresh={handleRefresh}
                    handleDownload={handleDownload}
                    downloadData={downloadData}
                    csvHeaders={headers}
                    csvData={csvData}
                    csvFilename="OnDemandReportList"
                    toggleColumnsStatus={toggleColumnsStatus}
                  />
                </div>

                <div className="common_table">
                  <InfiniteScrollTable
                    columns={columns}
                    loading={loader}
                    hasMore={hasMore}
                    filterData={filterData}
                    totalcount={total}
                    handleLoadMore={handleLoadMore}
                    filename="OnDemand Report List"
                  />
                </div>
              </div>
            </Row>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default OnDemandReportsList;
