import React, { createContext, useContext, useReducer } from 'react';
import helpcenterReducer, { initialState } from './heathCenterSearchReducer';

export const HelpCenterContext = createContext({ HCReducer: [] } as {
  HCReducer: any;
});

interface Props {
  children: React.ReactElement;
}

// eslint-disable-next-line react/prop-types
const HelpCenterProvider: React.FC<Props> = ({ children }) => {
  const hCReducer = useReducer(helpcenterReducer, initialState);
  return (
    <HelpCenterContext.Provider
      value={{
        HCReducer: hCReducer,
      }}
    >
      {children}
    </HelpCenterContext.Provider>
  );
};

export const useHelpContext = () => useContext(HelpCenterContext);

export default HelpCenterProvider;
