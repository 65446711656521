import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { notification } from 'antd';
import { ApplicationContext } from '../../../context/AppContext';
import { defaultSelectedOrg } from '../../admin/Common';
import CustomMultiSelect from '../../common/CustomMultiSelect';
import { getOrgsList } from '../API';
import { dealersData } from './common';

interface Props {
  handleOrgs: (ids: string[]) => void;
  defaultOrgs?: any[];
  setDealers?: (ids: any) => void;
  isflag?: any;
}

const OrgTypeFilter: React.FC<Props> = ({
  handleOrgs,
  defaultOrgs,
  setDealers,
  isflag,
}) => {
  const { userDetails } = useContext(ApplicationContext);
  const [selectedInternalOrgs, setSelectedInternalOrgs] = useState<string[]>(
    [],
  );
  const [selectedExternalOrgs, setSelectedExternalOrgs] = useState<string[]>(
    [],
  );
  const [orgTypes, setOrgTypes] = useState<string[]>([]);
  const [organizations, setOrganizations] = useState<any>([]);
  const [internalOrgs, setInternalOrgs] = useState<any>([]);
  const [externalOrgs, setExternalOrgs] = useState<any>([]);
  const init = useRef<boolean>(false);

  useEffect(() => {
    return () => {
      setOrganizations([]);
      setExternalOrgs([]);
      setInternalOrgs([]);
      setSelectedExternalOrgs([]);
      setSelectedInternalOrgs([]);
    };
  }, []);

  const selectExternalOrgs = (ids: any) => {
    setSelectedExternalOrgs(ids);
  };

  const selectInternalOrgs = (ids: any) => {
    setSelectedInternalOrgs(ids);
  };

  const mapDropdown = (list: any[]) =>
    list
      ?.sort((a: any, b: any) =>
        a?.name?.toLowerCase() > b?.name?.toLowerCase() ? 1 : -1,
      )
      .map((data: any) => ({
        title: <span className="ellipsis">{data.name}</span>,
        value: `${data.id}`,
      }));

  useEffect(() => {
    const initData = async () => {
      try {
        const records = await getOrgsList();
        const list =
          records && records.length && records.length > 0 ? records : [];

        setOrganizations([...list]);
        init.current = true;
      } catch (error: any) {
        notification.error({
          message: error.message,
          duration: 1,
        });
      }
    };
    if (userDetails?.organization) initData();
  }, [userDetails]);

  useEffect(() => {
    let extOrgs = organizations.filter((org: any) => defaultSelectedOrg(org));
    extOrgs = mapDropdown(extOrgs);
    setExternalOrgs([...extOrgs]);
    const defaultSelect = extOrgs.map((org: any) => org.value);

    setSelectedExternalOrgs(defaultSelect);

    let intOrgs = organizations.filter((org: any) => !defaultSelectedOrg(org));
    intOrgs = mapDropdown(intOrgs);
    const defaultIntOrgs = intOrgs.map((org: any) => org.value);
    setSelectedInternalOrgs(defaultIntOrgs);

    setInternalOrgs([...intOrgs]);
  }, [organizations]);

  useEffect(() => {
    const orgs = [...selectedExternalOrgs, ...selectedInternalOrgs];
    handleOrgs([...orgs]);
  }, [selectedExternalOrgs, selectedInternalOrgs]);

  const customerTypes: any = useMemo(
    () => ({
      monarch_dealers: organizations
        .filter((org: any) => org.org_type === 'DEALER')
        .map(({ id }: { id: number }) => String(id)),
      monarch_oems: organizations
        .filter((org: any) => org.org_type === 'OEM')
        .map(({ id }: { id: number }) => String(id)),
      monarch_internal: organizations
        .filter(
          (org: any) =>
            org.org_type === 'customer' &&
            org.account_type === 'internal_customer',
        )
        .map(({ id }: { id: number }) => String(id)),
      monarch_customers: organizations
        .filter(
          (org: any) =>
            org.org_type === 'customer' &&
            org.account_type === 'external_customer',
        )
        .map(({ id }: { id: number }) => String(id)),
    }),
    [organizations],
  );

  useEffect(() => {
    const ids = orgTypes.reduce((a: string[], c: any) => {
      // eslint-disable-next-line no-param-reassign
      a = [...a, ...customerTypes[c]];
      return a;
    }, []);
    handleOrgs([...ids]);
  }, [orgTypes, organizations]);

  useEffect(() => {
    const dealers = Object.keys(customerTypes);
    setDealers && setDealers(dealers);
    setOrgTypes(dealers);
  }, []);

  const handleDealers = (value: string[]) => {
    setDealers && setDealers(value);
    setOrgTypes(value);
  };
  return (
    <>
      {isflag && (
        <CustomMultiSelect
          list={dealersData}
          selectedId={orgTypes}
          label="Organization Type"
          handleTreeSelect={handleDealers}
        />
      )}

      <CustomMultiSelect
        handleTreeSelect={selectExternalOrgs}
        selectedId={selectedExternalOrgs}
        list={externalOrgs}
        label="External Organizations"
      />
      <CustomMultiSelect
        handleTreeSelect={selectInternalOrgs}
        selectedId={selectedInternalOrgs}
        list={internalOrgs}
        label="Internal Organizations"
      />
    </>
  );
};

export default OrgTypeFilter;
