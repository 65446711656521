/* eslint-disable no-console */
import { Collapse, Input, Modal, notification } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import delete_btn from '../../assets/images/delete_btn.svg';
import hitch_implement_S from '../../assets/images/hitch_implement_S.svg';
import {
  deleteUnfinishedRouteFun,
  getIncompleteOperations,
  getRoute,
} from '../../constants/Api';

import { getDateStamp, LS_ROUTINE, secondsToHms } from '../../constants/Common';
import { ApplicationContext } from '../../context/AppContext';

import translate from '../../locale/en_translate.json';
import AppLoader from '../common/AppLoader';
import {
  SELECTED_ITEM_NAME,
  SELECTED_ROUTINE,
  SET_ACTIVE_AUTODRIVE_TAB,
  SET_ADD_STEP,
  SET_SHOW_IMPLEMENT,
  SET_SHOW_ROUTINE,
} from '../remote_drive_new/actions';
import { RemoteDriveAppCtx } from '../remote_drive_new/RemoteDriveContext';
import { SimpleMap } from './SimpleMap';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { AutoDriveTabs } from '../../constants/constant';
import { SET_TRACTOR_IMPLEMENT_STATE } from '../../context/actions';
import { AutoDriveApplicationContext } from '../../context/AutoDriveContext';
import refresh from './../../assets/images/refresh.svg';
import refreshD from './../../assets/images/refreshD.svg';
import refreshUp from './../../assets/images/refUp.svg';
import sortA from './../../assets/images/sortA.svg';
import sortAD from './../../assets/images/sortAD.svg';
import sortUP from './../../assets/images/sortUP.svg';

const { Panel } = Collapse;
const { Search } = Input;
const { confirm } = Modal;

interface Props {
  showModal: boolean;
  handleCancel: (routine?: any) => void;
  tractorName: string;
}
export const RoutineNewDesign: React.FC<Props> = ({
  showModal,
  handleCancel,
  tractorName,
}) => {
  const { t } = useTranslation();
  const { userDetails, user } = useContext(ApplicationContext);
  const { AutoDriveReducer } = useContext(AutoDriveApplicationContext);
  const [autoDriveState, autoDriveDispatch] = AutoDriveReducer;
  const { tractorImplementState } = autoDriveState;
  const [routineList, setRoutineList] = useState<any>([]);
  const [incompleteRoutineList, setIncompleteRoutineList] = useState<any>([]);

  const [search, setSearch] = useState<string>('');
  const [loader, setLoader] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string | string[]>([]);
  const [sortColumn, setSortColumn] = useState<string>('created_date_time');
  const [sortBy, setSortBy] = useState<string>('desc');

  const { RDReducer } = useContext(RemoteDriveAppCtx);
  const [state, dispatch] = RDReducer;
  const { selectedTractor, selectedImplementName } = state;

  useEffect(() => {
    if (user && userDetails && userDetails.organization) {
      user?.isSupportUser() && getRoutines();
      getInCompleteRoutines();
    }
  }, [user, userDetails, search, sortColumn, sortBy]);

  const getRoutines = async () => {
    try {
      setLoader(true);
      const report = await getRoute(
        userDetails.organization.api_url,
        userDetails.organization.farm.id,
        search ? search : '',
        sortColumn,
        sortBy,
        selectedImplementName,
      );
      setRoutineList(report);
    } finally {
      setLoader(false);
    }
  };
  const getInCompleteRoutines = async () => {
    try {
      setLoader(true);
      const report = await getIncompleteOperations(
        userDetails.organization.api_url,
        userDetails.organization.farm.id,
        search ? search : '',
        sortColumn,
        sortBy,
        selectedImplementName,
      );
      setIncompleteRoutineList(report);
    } finally {
      setLoader(false);
    }
  };
  const handleRoutine = (routine: any) => {
    dispatch({
      type: SET_ADD_STEP,
      payload: true,
    });
    autoDriveDispatch({
      type: SET_SHOW_IMPLEMENT,
      payload: false,
    });
    dispatch({
      type: SET_ACTIVE_AUTODRIVE_TAB,
      payload: AutoDriveTabs.Parameter,
    });
    dispatch({ type: SELECTED_ROUTINE, payload: routine });
    handleCancel(routine);
    dispatch({
      type: SELECTED_ITEM_NAME,
      payload: routine.name,
    });
    localStorage.removeItem(LS_ROUTINE);
    localStorage.setItem(LS_ROUTINE, JSON.stringify(routine));
  };
  const handleIncompleteRoutine = (routine: any) => {
    dispatch({
      type: SET_ADD_STEP,
      payload: true,
    });
    autoDriveDispatch({
      type: SET_SHOW_IMPLEMENT,
      payload: false,
    });
    dispatch({
      type: SET_ACTIVE_AUTODRIVE_TAB,
      payload: AutoDriveTabs.Parameter,
    });

    dispatch({ type: SELECTED_ROUTINE, payload: routine });
    handleCancel(routine);
    dispatch({
      type: SELECTED_ITEM_NAME,
      payload: `${routine?.param_name} (${routine?.param_type})`,
    });
    localStorage.removeItem(LS_ROUTINE);
    localStorage.setItem(LS_ROUTINE, JSON.stringify(routine));
  };

  const handleSearch = (e: any) => {
    const value = e.target.value.trim();
    if (value.length <= 2) {
      if (search != '') {
        setRoutineList(['']);
      }
      setSearch('');
    } else {
      setSearch(value);
    }
  };
  const handleSorting = (name: string) => {
    if (sortColumn !== name) {
      setSortColumn(name);
      setSortBy('asc');
    } else {
      setSortBy((prev) => (prev === 'asc' ? 'desc' : 'asc'));
    }
  };
  const handleNewOperation = () => {
    console.log('AutoDriveTabs');
    if (tractorImplementState) {
      tractorImplementState[selectedTractor.id] = true;
      autoDriveDispatch({
        type: SET_TRACTOR_IMPLEMENT_STATE,
        payload: tractorImplementState,
      });
    }
    dispatch({ type: SET_SHOW_ROUTINE, payload: false });
    autoDriveDispatch({
      type: SET_SHOW_IMPLEMENT,
      payload: false,
    });
    dispatch({
      type: SET_ACTIVE_AUTODRIVE_TAB,
      payload: AutoDriveTabs.Parameter,
    });

    dispatch({
      type: SET_ADD_STEP,
      payload: true,
    });
    localStorage.removeItem(LS_ROUTINE);
  };

  const confirmDelete = (routine: any) => {
    const titleMsg = `Are you sure you want to delete ${routine?.param_name} ${routine?.param_type}?`;
    confirm({
      title: titleMsg,
      icon: <ExclamationCircleOutlined />,
      content: '',
      okButtonProps: {
        className: 'okBtn',
      },
      cancelButtonProps: {
        className: 'canelBtn',
      },
      async onOk() {
        if (routine?.incomplete_operation_uuid) {
          const { msg } = await deleteUnfinishedRouteFun(
            userDetails.organization.api_url,
            routine?.incomplete_operation_uuid,
          );
          if (msg) {
            getInCompleteRoutines();
            notification.success({
              message: msg,
              duration: 2,
            });
          }
        }
      },
    });
  };
  return (
    <Modal
      visible={showModal}
      className="rPopup"
      onCancel={handleCancel}
      maskClosable={false}
    >
      <div className="RoutineContainer">
        <div className="rHeaderSec dblock">
          <div className="adrHeaderRow">
            <div className="adrHeaderTxt">
              Unfinished Operations for this Implement
            </div>
          </div>
          <div className="adrTractorSec">
            <div className="rTractorName headline1">{tractorName}</div>
          </div>
          <div className="adrTractorSec">
            <div className="rImageSec">
              <img src={hitch_implement_S} height="12" />
              <div className="adrImplementName">{selectedImplementName}</div>
            </div>
            <div className="rSearchSec">
              <div style={{ marginRight: 15 }}>
                <img
                  alt="sortAD"
                  src={
                    sortColumn !== 'name'
                      ? sortAD
                      : sortBy === 'asc'
                      ? sortUP
                      : sortA
                  }
                  height="25"
                  style={{
                    marginRight: 8,
                    backgroundColor: '#fff',
                    borderRadius: 6,
                    cursor: 'pointer',
                  }}
                  onClick={() => handleSorting('name')}
                />
                <img
                  alt="dateTime"
                  src={
                    sortColumn !== 'created_date_time'
                      ? refreshD
                      : sortBy === 'asc'
                      ? refreshUp
                      : refresh
                  }
                  style={{
                    backgroundColor: '#fff',
                    borderRadius: 6,
                    cursor: 'pointer',
                  }}
                  height="25"
                  onClick={() => handleSorting('created_date_time')}
                />
              </div>
              <Search
                placeholder="Search"
                style={{ width: 200 }}
                onChange={(e) => handleSearch(e)}
              />
            </div>
          </div>
        </div>

        <div className="rBodySec">
          <div className="rNewOperation">
            <div className="newOprBtn" onClick={handleNewOperation}>
              start new operation
            </div>
            <span className="newOprTxt">
              Or select from unfinished operations below
            </span>
          </div>
          <div className="rBodyWrapper">
            <div className="scrollRMap">
              {incompleteRoutineList &&
                incompleteRoutineList.length > 0 &&
                incompleteRoutineList.map((routine: any, i: number) => (
                  <>
                    <div className="adRoutinesBlock adRoutinesRedBlock">
                      <div className="rRowItem" key={routine.id}>
                        <div className="rDateUser">
                          <div className="rrBy">
                            {/* <span className="rduTxt rduREDTxt">
                            {routine.created_date_time
                              ? getFullDate(routine.created_date_time)
                              : '-'}
                            &nbsp; UNFINISHED:
                          </span> */}
                            <span className="rduTxt">
                              {routine.created_date_time
                                ? getDateStamp(routine.created_date_time)
                                : '-'}
                            </span>
                            <span className="rduTxt">
                              {/* RECORDED BY {routine?.created_by?.first_name}{' '} */}
                              {routine?.created_by?.first_name}{' '}
                              {routine?.created_by?.last_name}
                            </span>
                          </div>
                          <div className="adrouteName">
                            <div className="rNamesSec">
                              {routine?.param_name} ({routine?.param_type})
                            </div>
                          </div>
                          <div className="adSeedNameBlk">
                            <div className="adSeedName">
                              {routine?.implements?.implement_type}
                            </div>
                          </div>
                          <div className="adrImpleName">
                            <div className="adrImpName">
                              {routine?.implement_name}
                            </div>
                          </div>
                        </div>
                        {/* <div className="adrFreqency">
                        <div className="adrDvalue">Every 2 rows</div>
                        <div className="adrDLabel">Frequency</div>
                      </div> */}
                        {/* <div className="adrDuration">
                        <div className="adrDvalue">
                          {secondsToHms(routine?.duration) || '-'}
                        </div>
                        <div className="adrDLabel">Duration</div>
                      </div> */}
                        <div className="adrSelBtn">
                          <button
                            className="deleteBtn"
                            onClick={() => confirmDelete(routine)}
                          >
                            <span>
                              <img src={delete_btn} alt="deleteBtn" />
                            </span>
                            <span>DELETE</span>
                          </button>
                          <button
                            onClick={() => handleIncompleteRoutine(routine)}
                            type="button"
                            className="adrSelectBtn"
                          >
                            SELECT
                          </button>
                        </div>
                      </div>
                      <div className="seePathSec">
                        <Collapse
                          onChange={(e) =>
                            setSelectedId(
                              selectedId === routine.incomplete_operation_uuid
                                ? ''
                                : routine.incomplete_operation_uuid,
                            )
                          }
                          activeKey={selectedId}
                        >
                          <Panel
                            header="SEE PATH"
                            key={routine.incomplete_operation_uuid}
                          >
                            <p>
                              <SimpleMap
                                selectedRoutine={routine}
                                type={Number(2)}
                              />
                            </p>
                          </Panel>
                        </Collapse>
                      </div>
                    </div>
                  </>
                ))}
              {routineList &&
                routineList.length > 0 &&
                routineList.map((routine: any) => (
                  <>
                    <div className="adRoutinesBlock">
                      <div className="rRowItem" key={routine.id}>
                        <div className="rDateUser">
                          <div className="rrBy">
                            <span className="rduTxt">
                              {routine.created_date_time
                                ? getDateStamp(routine.created_date_time)
                                : '-'}
                            </span>
                            <span className="rduTxt">
                              RECORDED BY {routine?.created_by?.first_name}{' '}
                              {routine?.created_by?.last_name}
                            </span>
                          </div>
                          <div className="adrouteName">
                            <div className="rNamesSec">{routine.name}</div>
                          </div>
                          <div className="adrImpleName">
                            <div className="adrImpName">
                              {routine?.implement_name}
                            </div>
                          </div>
                        </div>
                        <div className="adrDuration">
                          <div className="adrDvalue">
                            {secondsToHms(routine?.duration) || '-'}
                          </div>
                          <div className="adrDLabel">Duration</div>
                        </div>
                        <div className="adrSelBtn">
                          <button
                            onClick={() => handleRoutine(routine)}
                            type="button"
                            className="adrSelectBtn"
                          >
                            SELECT
                          </button>
                        </div>
                      </div>
                      <div className="seePathSec">
                        <Collapse
                          onChange={(e) =>
                            setSelectedId(
                              selectedId === routine.id ? '' : routine.id,
                            )
                          }
                          activeKey={selectedId}
                        >
                          <Panel header="SEE PATH" key={routine.id}>
                            <p>
                              <SimpleMap
                                selectedRoutine={routine}
                                type={Number(1)}
                              />
                            </p>
                          </Panel>
                        </Collapse>
                      </div>
                    </div>
                  </>
                ))}
              {routineList.length === 0 && incompleteRoutineList.length === 0 && (
                <>
                  {!loader && (
                    <div className="routines-empty-block">
                      <div>{t(translate.label.noRoutines)}</div>
                    </div>
                  )}
                </>
              )}
              <AppLoader loader={loader} />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
