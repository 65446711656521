/* eslint-disable react/jsx-no-undef */
import React, { useContext } from 'react';
import { Button, Image } from 'antd';
import Layout from 'antd/lib/layout/layout';
import { useTranslation } from 'react-i18next';
import translate from '../../locale/en_translate.json';
import closeGreen from '../../assets/images/closeGreen.svg';
import { SnapShot } from '../../constants/types';
import applicationIds from '../../locale/applicationIds.json';
import usStandIcon from '../../assets/images/usStandIcon.svg';
import {
  formatCameraText,
  getFullName,
  removeUnderScore,
} from '../../constants/Common';
import { useCameraSnap, useSnap } from '../../constants/customHooks';
import { ApplicationContext } from '../../context/AppContext';
import { TOGGLE_VIDEO_WIDGET } from '../../context/actions';
import AppLoader from '../common/AppLoader';
import './styles.css';

const GUARD_RAILS = 'GUARD_RAILS';

interface Props {
  snapshotFlag: boolean;
  guardrailsList: any;
  Close: () => void;
  selectedJumpnGo: any;
}
const SnapView: React.FC<Props> = ({
  snapshotFlag,
  guardrailsList,
  Close,
  selectedJumpnGo,
}: Props) => {
  const { t } = useTranslation();
  const {
    guard_rail_triggered_uuid: transactionId,
    id: tractorId,
    created_by,
  } = guardrailsList;
  const [snapshot, loader] = useSnap(transactionId, tractorId);
  const [cameraData] = useCameraSnap(guardrailsList);
  const { APPReducer } = useContext(ApplicationContext);
  const [state, dispatch] = APPReducer;

  const handleVideos = () => {
    const {
      created_date_time,
      date_time,
      tractor_id: tractorId,
    } = guardrailsList;
    const videoTime = date_time || created_date_time;
    dispatch({
      type: TOGGLE_VIDEO_WIDGET,
      payload: {
        toggle: !state.videoWidgetData.toggle,
        videoTime: videoTime,
        tractorId: tractorId,
      },
    });
  };
  if (!guardrailsList) return <></>;
  return (
    <Layout>
      <div className={'rightSideWidegt ' + (snapshotFlag ? 'expand' : 'hide')}>
        <div className="closeButton">
          <img
            id={applicationIds.snapshotScreen.snapshotCloseBtn}
            src={closeGreen}
            alt="close icon"
            onClick={Close}
          />
        </div>

        <div className="proRoleHead posRel">
          <span className="headline3">
            {guardrailsList.created_at === 'GUARD_RAILS'
              ? 'Guardrails'
              : guardrailsList.created_at === 'JUMP_AND_GO'
              ? 'Jump And Go'
              : 'Snapshot'}
          </span>
          {guardrailsList.created_at === 'GUARD_RAILS' && (
            <img
              src={usStandIcon}
              alt="dataLayer"
              height={18}
              style={{ padding: '0px 10px', height: '28px' }}
            />
          )}
          <h6 className="snapDate overline1">{guardrailsList?.title}</h6>
        </div>
        <div className="profileView">
          <div className="proRowType mb0 mt24">
            <h5 className="headline4 subFlex">
              <span className="subTitles">
                {t(translate.analyticContainer.tractorLabel)}
              </span>
              <span className="subdesc body2">
                {selectedJumpnGo?.tractor?.name}
              </span>
            </h5>
          </div>

          <div className="proRowType mb0 mt24">
            <h5 className="headline4 subFlex">
              <span className="subTitles" data-testid="creatorName-SnapView">
                {t(translate.analyticContainer.operatorLabel)}
              </span>
              <span className="subdesc body2">
                {' '}
                {getFullName(created_by) || '-'}
              </span>
            </h5>
          </div>

          <div className="proRowType mb0 mt24">
            <h5 className="headline4 subFlex">
              <span className="subTitles">
                {t(translate.analyticContainer.creationType)}
              </span>
              <span className="subdesc body2">
                {guardrailsList?.created_at === GUARD_RAILS
                  ? // ? removeUnderScore(guardrailsList?.guard_rail_type)
                    guardrailsList?.created_type_text
                  : removeUnderScore(guardrailsList?.created_at)}
              </span>
            </h5>
          </div>

          {guardrailsList?.created_at === GUARD_RAILS && (
            <div className="proRowType mb0 mt24">
              <h5 className="headline4 subFlex">
                <span className="subTitles">
                  {t(translate.analyticContainer.camera)}
                </span>
                <span className="subdesc body2">
                  {' '}
                  {formatCameraText(cameraData?.camera)}
                </span>
              </h5>
            </div>
          )}

          <div className="proRowType mb0 mt24">
            <h5 className="headline4 subFlex">
              <span className="subTitles">
                {t(translate.analyticContainer.images)}
              </span>
            </h5>
          </div>

          <div className="camWrapper snapsContainer">
            <AppLoader loader={loader} />
            <Image.PreviewGroup>
              {!loader &&
                snapshot.map((item: SnapShot, index: number) => (
                  <div key={index}>
                    {item.camera_image_url !== '' ? (
                      <div className="camBox">
                        <div className="camBoxContent">
                          <Image src={item.camera_image_url} className="sImg" />
                        </div>
                        <div className="camBoxHeads">{item.title}</div>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                ))}
            </Image.PreviewGroup>
          </div>

          <div className="proRowType mt32">
            <Button
              type="primary"
              className="employeeCreate w160 Button"
              onClick={handleVideos}
            >
              {t(translate.buttons.viewVideos)}
            </Button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default SnapView;
