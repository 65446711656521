import React, { useState, useMemo, useEffect, useContext } from 'react';
import { Input, message, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import translate from '../../../../locale/en_translate.json';
import { ApplicationContext } from '../../../../context/AppContext';
import { LogViewerDataIndex } from '../../common/types';
import { addLogViewerFavourites } from '../../API';

const errorMessage = 'min 3 characters required';

interface Props {
  // eslint-disable-next-line no-unused-vars
  callback: (state: boolean, payload?: any) => void;
  selectedModels: LogViewerDataIndex[];
  selectedModel: string;
}

const AddLogFavModal: React.FC<Props> = ({
  // visible,
  callback,
  selectedModels,
  selectedModel,
}) => {
  const { t } = useTranslation();
  const { userDetails, dealerDetails } = useContext(ApplicationContext);
  const [init, setInit] = useState(false);
  const [val, setVal] = useState('');
  const [favLoading, setFavLoading] = useState(false);

  const handleAddFavourite = async () => {
    try {
      setFavLoading(true);
      const payload = {
        feature: 'log_viewer',
        name: val,
        attribute_key: selectedModel,
        attribute_value_json: selectedModels,
      };
      const data = await addLogViewerFavourites(dealerDetails.id, payload);
      message.success(data?.msg);
      unmountState();
      callback(true);
    } catch (error: any) {
      message.error(error.message);
    } finally {
      setFavLoading(false);
    }
  };

  const error = useMemo(
    () => Boolean(typeof val === 'undefined' || val.length < 3),
    [val],
  );

  const unmountState = () => {
    setInit(false);
    setVal('');
  };

  useEffect(() => () => unmountState(), []);

  const handleSubmit = () => {
    if (error) return message.error(errorMessage);
    return handleAddFavourite();
  };

  return (
    <>
      <Modal
        title="Do you want to add favorites ?"
        visible
        onOk={handleSubmit}
        confirmLoading={favLoading}
        onCancel={() => callback(false)}
        okText={t(translate.buttons.addFavorite)}
      >
        <div style={{ textAlign: 'center' }}>
          <Input
            placeholder="Name"
            style={{
              width: '150px',
              margin: '0px 8px',
              border: `${init && error ? '1px solid red' : ''}`,
            }}
            onChange={(e) => {
              setVal(e.target.value?.trim());
              if (!init) setInit(true);
            }}
          />
        </div>
      </Modal>
    </>
  );
};

export default AddLogFavModal;
