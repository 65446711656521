import React from 'react';
import { Content } from 'antd/lib/layout/layout';
import { AutoComplete, Layout, notification, Image, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import Kb_logo from '../../assets/images/monarch_logo_kb.svg';
import pdf_icon from '../../assets/images/pdf.png';
import './style.css';
import { HistoryOutlined, SearchOutlined } from '@ant-design/icons';
import { ApplicationContext } from '../../context/AppContext';
import { useContext, useEffect, useRef, useState } from 'react';
import { getFrequentData, getKnowledgeBaseList } from '../../constants/Api';
import KnowledgeBaseDetails from './KnowledgeBaseDetails';
import video_icon from '../../assets/images/play_icon.png';
import KnowledgeBaseFile from './KnowledgeBaseFile';
import AppLoader from '../common/AppLoader';
import translate from '../../locale/en_translate.json';
import Converse from '../converse/Converse';

interface Props {
  errorCode?: string;
  showConverse?: boolean;
}

const KnowledgeBase: React.FC<Props> = ({
  errorCode = '',
  showConverse = true,
}) => {
  const { t } = useTranslation();
  const { userDetails, APPReducer } = useContext(ApplicationContext);
  const [searchKey, setSearchKey] = useState<string>('');
  const [knowledgeData, setKnowledgeData] = useState<any>();
  const [title, setTitle] = useState<string>('');
  const { Option } = AutoComplete;
  const searchString = useRef<string>('');
  const [frequentData, setfrequentData] = useState<any[]>([]);
  const [recentArtList, setRecentArtList] = useState<any[]>([]);
  const [knowledgebaseflag, setKnowledgeBaseFlag] = useState<boolean>(false);
  const [knowledgeFileFlag, setknowledgeFileFlag] = useState<boolean>(false);
  const [recordFile, setRecordFile] = useState<any>();
  const [loader, setLoader] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [appState] = APPReducer;
  const { showConverseChat } = appState;

  useEffect(() => {
    return () => {
      setTitle('');
      setSearchKey('');
      setfrequentData([]);
      setRecentArtList([]);
      setknowledgeFileFlag(false);
      setKnowledgeBaseFlag(false);
    };
  }, []);

  useEffect(() => {
    if (userDetails && userDetails.organization) getknowledgeBase();
  }, [userDetails, searchKey]);

  useEffect(() => {
    if (errorCode && errorCode?.trim() !== '') {
      setknowledgeFileFlag(true);
      setKnowledgeBaseFlag(true);
      setTitle(errorCode);
    }
  }, [errorCode]);

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      getFrequentlist();
      getrecetArticleList();
    }
  }, [userDetails]);

  const getknowledgeBase = async () => {
    try {
      const result: any = await getKnowledgeBaseList(searchKey);
      if (result) setKnowledgeData(result.data);
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    }
  };

  const getFrequentlist = async () => {
    try {
      setLoader(true);
      const result: any = await getFrequentData('published');
      if (result) setfrequentData(result.data);
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    } finally {
      setLoader(false);
    }
  };

  const getrecetArticleList = async () => {
    try {
      setLoader(true);
      const result: any = await getFrequentData('searches');
      if (result) setRecentArtList(result.data);
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    } finally {
      setLoader(false);
    }
  };

  const handleSearch = (e: any) => {
    const value = e.trim().regXFormat;
    if (value.length <= 2) {
      searchString.current = '';
      setSearchKey('');
      setKnowledgeData([]);
    } else {
      searchString.current = value;
      setSearchKey(value);
    }
  };
  const onSelect = (e: any) => {
    setTitle(e);
    setKnowledgeBaseFlag(true);
  };
  const Onkey = (e: any) => {
    if (e.key === 'Enter') {
      setTitle(e.target.value);
      setKnowledgeBaseFlag(true);
    }
  };

  const KnowledgeBaseFilelist = (record: any) => {
    setRecordFile(record);
    setknowledgeFileFlag(true);
  };

  return (
    <>
      {!knowledgebaseflag && !knowledgeFileFlag && (
        <Layout>
          <Content>
            <div className="mainContent">
              <div className="tblContainer selectReport test knowledgeBaseContent knowledgeBaseBlk">
                <div>
                  <img src={Kb_logo} alt="" className="kb_logo" />
                  <div className="baseSearchFilter">
                    <div className="baseSearchFilterBlk">
                      <AutoComplete
                        style={{ width: 200 }}
                        onSearch={handleSearch}
                        onSelect={onSelect}
                        defaultValue={title}
                        placeholder=" Search"
                        data-testid="inputSupervisor-AddTask"
                        onKeyUp={(e: any) => Onkey(e)}
                      >
                        {searchKey
                          ? knowledgeData &&
                            knowledgeData.length > 0 &&
                            knowledgeData.map((ele: any) => (
                              <Option key={ele.id} value={ele.title}>
                                <SearchOutlined />
                                <span style={{ paddingLeft: '6px' }}>
                                  {ele.title}
                                </span>
                              </Option>
                            ))
                          : recentArtList &&
                            recentArtList.length > 0 &&
                            recentArtList.map((ele: any) => (
                              <Option key={ele.id} value={ele.title}>
                                <HistoryOutlined />
                                <span style={{ paddingLeft: '6px' }}>
                                  {ele.title}
                                </span>
                              </Option>
                            ))}
                      </AutoComplete>
                      <SearchOutlined />
                    </div>
                  </div>
                  <div className="recentPublishArticles">
                    <h4 className="headline3 knowledgeBaseTitle">
                      {t(translate.KnowledgeBase.recentArticles)}
                    </h4>
                    <div className="knowledgeBaseFlex">
                      {frequentData.length > 0 &&
                        frequentData.map((ele: any) => (
                          <div className="kbCard" key={ele.id}>
                            {ele.media_type === 'image' && (
                              <div className="knowledgeBaseBox">
                                <Tooltip
                                  title={
                                    ele?.title[0].toUpperCase() +
                                    ele?.title.slice(1)
                                  }
                                >
                                  <Image
                                    src={ele.file_url}
                                    className="knowledgebaseimg"
                                    preview={{
                                      src: `${ele.file_url}`,
                                    }}
                                  />
                                </Tooltip>
                              </div>
                            )}
                            {ele.media_type === 'pdf' && (
                              <div
                                className="knowledgeBaseBox"
                                onClick={() => {
                                  KnowledgeBaseFilelist(ele);
                                }}
                              >
                                <Tooltip
                                  title={
                                    ele?.title[0].toUpperCase() +
                                    ele?.title.slice(1)
                                  }
                                >
                                  <img src={pdf_icon} alt="" />
                                </Tooltip>
                              </div>
                            )}
                            {ele.media_type === 'video' && (
                              <div
                                className="knowledgeBaseBox"
                                onClick={() => {
                                  KnowledgeBaseFilelist(ele);
                                }}
                              >
                                <Tooltip
                                  title={
                                    ele?.title[0].toUpperCase() +
                                    ele?.title.slice(1)
                                  }
                                >
                                  <video
                                    className="video knowledgeImg"
                                    src={ele?.file_url}
                                    poster={ele?.thumbnail_file_url}
                                  />
                                  <img
                                    src={video_icon}
                                    alt=""
                                    className="playIcon"
                                  />
                                </Tooltip>
                              </div>
                            )}
                            <p className="headline4">
                              {ele?.title[0].toUpperCase() +
                                ele?.title.slice(1)}
                            </p>
                          </div>
                        ))}
                    </div>
                  </div>
                  <div className="recentSearchesArticles">
                    <h4 className="headline3 knowledgeBaseTitle">
                      {t(translate.KnowledgeBase.recentSearches)}
                    </h4>
                    <div className="knowledgeBaseFlex">
                      {recentArtList.length > 0 &&
                        recentArtList.map((ele: any) => (
                          <div className="kbCard" key={ele.id}>
                            {ele.media_type === 'image' && (
                              <div className="knowledgeBaseBox">
                                <Tooltip
                                  title={
                                    ele?.title[0].toUpperCase() +
                                    ele?.title.slice(1)
                                  }
                                >
                                  <Image
                                    src={ele.file_url}
                                    className="knowledgebaseimg"
                                    preview={{
                                      src: `${ele.file_url}`,
                                    }}
                                  />
                                </Tooltip>
                              </div>
                            )}
                            {ele.media_type === 'pdf' && (
                              <div
                                className="knowledgeBaseBox"
                                onClick={() => {
                                  KnowledgeBaseFilelist(ele);
                                }}
                              >
                                <Tooltip
                                  title={
                                    ele?.title[0].toUpperCase() +
                                    ele?.title.slice(1)
                                  }
                                >
                                  <img src={pdf_icon} alt="" />
                                </Tooltip>
                              </div>
                            )}
                            {ele.media_type === 'video' && (
                              <div
                                className="knowledgeBaseBox"
                                onClick={() => {
                                  KnowledgeBaseFilelist(ele);
                                }}
                              >
                                <Tooltip
                                  title={
                                    ele?.title[0].toUpperCase() +
                                    ele?.title.slice(1)
                                  }
                                >
                                  <video
                                    className="video knowledgeImg"
                                    src={ele?.file_url}
                                  />
                                  <img
                                    src={video_icon}
                                    alt=""
                                    className="playIcon"
                                  />
                                </Tooltip>
                              </div>
                            )}
                            <p className="headline4">
                              {' '}
                              {ele?.title[0].toUpperCase() +
                                ele?.title.slice(1)}
                            </p>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Content>
          <AppLoader loader={loader} />
        </Layout>
      )}
      {showConverseChat && showConverse && (
        <div
          className="mapLayerIcon2 chatIconBase"
          onClick={() => setOpen(true)}
        >
          &nbsp;
        </div>
      )}
      {open && <Converse open={open} setOpen={setOpen} />}
      <div>
        {title && knowledgebaseflag && (
          <KnowledgeBaseDetails
            closeModel={() => {
              setTitle('');
              setSearchKey('');
              setfrequentData([]);
              setRecentArtList([]);
              setknowledgeFileFlag(false);
              setKnowledgeBaseFlag(false);
              getknowledgeBase();
              getFrequentlist();
              getrecetArticleList();
            }}
            titleview={title}
          />
        )}
      </div>

      <div>
        {recordFile && knowledgeFileFlag && (
          <KnowledgeBaseFile
            closeModel={() => {
              setknowledgeFileFlag(false);
            }}
            recordFile={recordFile}
          />
        )}
      </div>
    </>
  );
};

export default KnowledgeBase;
