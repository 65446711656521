import React, { useEffect, useRef, useState, memo } from 'react';
import { Pie } from '@ant-design/charts';
import { useTranslation } from 'react-i18next';
import { DataItem } from '@amcharts/amcharts4/core';
import {
  calTotal,
  calPercentage,
  colors1,
  colors,
  secondsToHms,
} from '../../../constants/Common';
import translate from '../../../locale/en_translate.json';
interface Props {
  allDayOperation: any;
}

const AnalyticChart: React.FC<Props> = ({ allDayOperation }) => {
  const { t } = useTranslation();
  const [data, setData] = useState<DataItem[]>([]);
  const isNew = useRef<boolean>(false);
  const [total, setTotal] = useState<number>(0);

  useEffect(() => {
    return () => {
      setData([]);
      setTotal(0);
      isNew.current = false;
    };
  }, [allDayOperation]);

  const newDataDisplay = (data: any) => {
    let total = 0;
    const { planning_manager_analytics } = data;
    const {
      operator_assist_hours = 0,
      manual_hours = 0,
      auto_drive_hours = 0,
    } = planning_manager_analytics;

    const result = calTotal({
      operator_assist_hours,
      manual_hours,
      auto_drive_hours,
    });
    if (result.state) {
      total = result.total;
      setTotal(total);
    }
    if (!total) {
      isNew.current = false;
      oldDataDisplay(data);
      return;
    }
    const dataItem: any[] = [
      {
        type: 'Manual',
        value: calPercentage(manual_hours, total),
      },
      {
        type: 'Operator Assist',
        value: calPercentage(operator_assist_hours, total),
      },
      {
        type: 'Auto Drive',
        value: calPercentage(auto_drive_hours, total),
      },
    ];
    setData(dataItem);
  };

  const oldDataDisplay = (data: any) => {
    let total = 0;
    const { planning_manager_analytics } = data;
    const {
      idle_hours = 0,
      manual_hours = 0,
      autonomy_hours = 0,
    } = planning_manager_analytics;
    const result = calTotal({ idle_hours, manual_hours, autonomy_hours });
    if (result.state) {
      total = result.total;
      setTotal(total);
    }
    const dataItem: any[] = [
      {
        type: 'Idle',
        value: calPercentage(Number(idle_hours), total),
      },
      // {
      //   type: 'Manual',
      //   value: calPercentage(manual_hours, total),
      // },
      // {
      //   type: 'Autonomous',
      //   value: calPercentage(autonomy_hours, total),
      // },
    ];
    setData(dataItem);
  };

  useEffect(() => {
    const dataItem: any[] = [];
    setData(dataItem);
    if (allDayOperation && allDayOperation?.planning_manager_analytics) {
      isNew.current = true;
      newDataDisplay(allDayOperation);
    }
    // else if (allDayOperation) {
    //   oldDataDisplay(allDayOperation);
    // }
  }, [allDayOperation]);

  const config: any = {
    legend: false,
    data: data,
    angleField: 'value',
    colorField: 'type',
    color: isNew.current ? colors1 : ['#FBB313'],
    radius: 0.9,
    autofit: true,
    tooltips: {
      type: 'inner',
      content: function content(_ref: any) {
        return ''.concat(_ref.value, '%');
      },
      style: {
        fontSize: 14,
        textAlign: 'center',
      },
    },
    tooltop: false,
    label: {
      type: 'inner',
      offset: '-30%',
      style: {
        fontSize: 0,
        textAlign: 'center',
      },
    },
    options: {
      legend: {
        display: false,
      },
      tooltips: {
        enabled: false,
      },
    },
    interactions: [{ type: 'element-active' }],
  };

  return (
    <>
      <div className="operationalHours mb5">
        <div className="totalHours">Drive Time</div>
        <div className="hrMinTxt" style={{ textTransform: 'uppercase' }}>
          {secondsToHms(total, true)}
        </div>
      </div>
      <div className="sprayBlk mb10">
        <div className="pieTxtStyle">
          <Pie
            className="sprayPiechart"
            {...config}
            style={{ height: '80px', width: 80, marginLeft: '-16px' }}
          />
        </div>
        <div className="graphTbl sprayTbl" style={{ top: 62 }}>
          {isNew.current ? (
            <table>
              <tbody>
                <tr>
                  <td className="">
                    <span className="manualdriveBox"></span>
                    {t(translate.analyticContainer.manual)}
                  </td>
                  <td className="">
                    {allDayOperation?.planning_manager_analytics?.manual_hours
                      ? `${secondsToHms(
                          allDayOperation?.planning_manager_analytics
                            ?.manual_hours,
                          true,
                        )}`
                      : '-'}
                  </td>
                </tr>

                <tr>
                  <td className="">
                    <span className="operatorassitsBox"></span>
                    {t(translate.analyticContainer.operatorAssist)}
                  </td>
                  <td className="">
                    {allDayOperation?.planning_manager_analytics
                      ?.operator_assist_hours
                      ? `${secondsToHms(
                          allDayOperation?.planning_manager_analytics
                            ?.operator_assist_hours,
                          true,
                        )}`
                      : '-'}
                  </td>
                </tr>
                <tr>
                  <td className="">
                    <span className="autodriveBox"></span>
                    {t(translate.analyticContainer.autoDrive)}
                  </td>
                  <td className="">
                    {allDayOperation?.planning_manager_analytics
                      ?.auto_drive_hours
                      ? `${secondsToHms(
                          allDayOperation?.planning_manager_analytics
                            ?.auto_drive_hours,
                          true,
                        )}`
                      : '-'}
                  </td>
                </tr>
              </tbody>
            </table>
          ) : (
            <table>
              <tbody>
                <tr>
                  <td className="">
                    <span className="idleBox"></span>
                    {t(translate.analyticContainer.idle)}
                  </td>
                  <td className="">
                    {allDayOperation?.planning_manager_analytics?.idle_hours
                      ? `${secondsToHms(
                          allDayOperation?.planning_manager_analytics
                            ?.idle_hours,
                          true,
                        )}`
                      : '-'}
                  </td>
                </tr>
                {/* <tr>
                <td className="">
                  <span className="manualBoxForPie"></span>Manual
                </td>
                <td className="">
                  {allDayOperation?.planning_manager_analytics?.manual_hours
                    ? `${secondsToHms(
                        allDayOperation?.planning_manager_analytics
                          ?.manual_hours,
                        true,
                      )}`
                    : '-'}
                </td>
              </tr>
              <tr>
                <td className="">
                  <span className="autonomousBox"></span>Autonomous
                </td>
                <td className="">
                  {allDayOperation?.analytics?.autonomy_hours
                    ? `${secondsToHms(
                        allDayOperation?.analytics?.autonomy_hours,
                        true,
                      )}`
                    : '-'}
                </td>
              </tr> */}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  );
};

export default memo(AnalyticChart);
