import { RoleT, OrganizationT } from './types';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
class Role {
  id: number;
  name: string;
  role_key: string;
  role_type: string;
  privileges: string;
  constructor(role: RoleT) {
    this.id = role.id;
    this.name = role.name;
    this.role_key = role.role_key;
    this.role_type = role.role_type;
    this.privileges = role.privileges;
  }
}

class Organization {
  name: string;
  description: string;
  org_type: string;
  account_type: string;
  address: string;
  org_uuid: string;
  profile_url: string;
  api_url: string;
  api_gateway_url: string;
  mqtt_ip: string;
  mqtt_port: number;
  fleet: {
    id: number;
    organization_id: number;
  };
  farm: { id: number; organization_id: number };

  constructor(org: OrganizationT) {
    this.name = org.name;
    this.description = org.description;
    this.org_type = org.org_type;
    this.account_type = org.account_type;
    this.address = org.address;
    this.org_uuid = org.org_uuid;
    this.profile_url = org.profile_url;
    this.api_url = org.api_url;
    this.api_gateway_url = org.api_url;
    this.mqtt_ip = org.mqtt_ip;
    this.mqtt_port = org.mqtt_port;
    this.fleet = {
      id: org.fleet.id,
      organization_id: org.fleet.organization_id,
    };
    this.farm = {
      id: org.farm.id,
      organization_id: org.farm.organization_id,
    };
  }
  isInternalUser() {
    return this.account_type === 'internal_customer';
  }
}

export class User extends Organization {
  id: number;
  organization_id: number;
  username: string;
  first_name: string;
  last_name: string;
  member_uuid: string;
  profile_url: string;
  member_status: string;
  change_password_required: boolean;
  last_logged_in_time: number;
  exp: number;
  ops_code: number;
  email: string;
  is_active: boolean;
  is_org_admin: boolean | undefined;
  is_support_user: boolean;
  organization: OrganizationT;
  roles: RoleT[];

  constructor(user: User) {
    super(user.organization);
    this.id = user.id;
    this.organization_id = user.organization_id;
    this.username = user.username;
    this.first_name = user.first_name;
    this.last_name = user.last_name;
    this.member_uuid = user.member_uuid;
    this.profile_url = user.profile_url;
    this.member_status = user.member_status;
    this.change_password_required = user.change_password_required;
    this.last_logged_in_time = user.last_logged_in_time;
    this.exp = user.exp;
    this.ops_code = user.ops_code;
    this.organization = user.organization;
    this.email = user.email;
    this.is_active = user.is_active;
    this.is_org_admin = user.is_org_admin;
    this.is_support_user = user.is_support_user;
    this.roles = user.roles || [];
  }
  getUserOrganization() {
    return new Organization(this.organization);
  }
  isInternalUser() {
    return super.isInternalUser();
  }
  isSupportUser() {
    return this.is_support_user;
  }
  getUserName() {
    return this.first_name + ' ' + this.last_name;
  }
  getRoles() {
    return this.roles.map((role: RoleT) => new Role(role));
  }
}
