/* eslint-disable react/react-in-jsx-scope */
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { bar_hour_tooltip } from './bar_tool_tip';
import {
  firstBarToPieChart,
  OpCostToPieDatumList,
} from './transform/pie_chart';
import { OperatorCostReferenceDict } from '../types/operator';
import FirstBarChart1 from '../linked_charts/FirstBarChart1';
import SecondPieChart from '../linked_charts/SecondPieChart';
import { OnClickBarChart } from '../types/linked_charts';
import loading_logo from '../../../assets/images/loading_logo.gif';
import { ArrowLeftOutlined } from '@ant-design/icons';
import moment from 'moment';
import CSelect from '../../common/CSelect';
import { days } from '../../../constants/AppData';
import AppLoader from '../../common/AppLoader';
import translate from '../../../locale/en_translate.json';

interface Props {
  day: any;
  setTabid: (key: any) => void;
  setday: (key: any) => void;
  data: any;
  loader: boolean;
  init: any;
}

const LaborCostsBar: React.FC<Props> = ({
  day,
  setTabid,
  setday,
  data,
  loader,
  init,
}: Props) => {
  const { t } = useTranslation();
  const [ref_dict, setref_dict] = useState<any>();
  const [key_list, setkey_list] = useState<any>();
  const [data_list, setdata_list] = useState<any>();
  const [first_date, setfirst_date] = useState<any>();
  const [first_total_cost, setfirst_total_cost] = useState<any>();
  const [pie_chart_input, setPieChart] = useState<any>();
  const [selectedDate, setSelectedDate] = useState<number>(0);

  const reset = () => {
    setSelectedDate(0);
    setPieChart(null);
  };

  useEffect(() => {
    if (data) {
      setref_dict(data.reference_dict);
      setkey_list(data.bar_chart_key_list);
      setdata_list(data.bar_chart_data_list);
    }
    return () => {
      reset();
    };
  }, [data]);

  useEffect(() => {
    if (data_list && data_list[0]) {
      setfirst_date(data_list[0].date);
      setfirst_total_cost(data_list[0].setfirst_total_cost);
    }
  }, [data_list]);

  useEffect(() => {
    if (ref_dict && first_date && first_total_cost) {
      setPieChart(firstBarToPieChart(ref_dict, first_date, first_total_cost));
    }
  }, []);

  const makeOnClickBarChart =
    (ref_dict: OperatorCostReferenceDict): OnClickBarChart =>
    (datum) => {
      const {
        indexValue, // 'Jan 01'
        id, // 'total'
        value, // '0.2221'
      } = datum;

      const op_cost_dict = ref_dict[indexValue];
      if (op_cost_dict && value) {
        const pie_datum_list = OpCostToPieDatumList(op_cost_dict);
        const dateTime = new Date(
          indexValue + ` ${new Date().getFullYear()}`,
        ).getTime();
        setSelectedDate(dateTime);
        setPieChart({
          line_id: id,
          info: {
            x: indexValue.toString(),
            y: 'USD ' + value.toFixed(2),
          },
          data: pie_datum_list,
        });
      } else {
        // this should not be possible
      }
    };

  const onClickBar = makeOnClickBarChart(ref_dict);

  return (
    <>
      <div className="tractorUage">
        {data_list && (
          <div className="chartGraph" style={{ position: 'relative' }}>
            <div style={{ display: 'flex' }}>
              <div
                className="fdHeight"
                onClick={() => {
                  reset();
                  setTabid('1');
                }}
              >
                {/* <ArrowLeftOutlined data-testid="backArrowIcon-EditRoles" /> */}
              </div>
              <div>
                <div className="text-3xl font-bold">
                  {t(translate.fleetUsageCharts.laborCost)}
                </div>
                <div className="text-xl my-4 px-4 py-2 w-auto inline-block bg-kikuchiba text-kogecha rounded">
                  <strong>{t(translate.fleetUsageCharts.interaction)}:</strong>
                  <br />
                  {t(translate.fleetUsageCharts.info)}
                </div>
              </div>
            </div>
            <div className="ddDurationenergy">
              <CSelect
                list={days}
                isAll={false}
                selectTrigger={(selected: any) => {
                  const { value = '30' } = selected;
                  init.current && setday(value);
                }}
                label="Days"
                keyValue="value"
                defaultId={day}
              />
            </div>
            <FirstBarChart1
              title={
                'Labor Cost ' +
                (selectedDate
                  ? `(${moment(selectedDate).format('DD MMM YYYY')})`
                  : '')
              }
              y_axis_title={'Labor Cost (USD)'}
              tool_tip={bar_hour_tooltip}
              key_list={key_list}
              data_list={data_list}
              onClickBar={onClickBar}
              enable_interaction_title={true}
              // color_scheme={{ scheme: 'set2' }}
              colors={['#8bbda7']}
            />
          </div>
        )}
        {pie_chart_input && (
          <div className="chartGraph cost-graph-labor">
            <SecondPieChart
              title={'Labor Costs by Operator'}
              {...pie_chart_input}
            />
          </div>
        )}
        <AppLoader loader={loader} />
      </div>
    </>
  );
};

export default LaborCostsBar;
