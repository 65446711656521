import cat_icon_tv from '../../HelpCenter/images/tv.svg';
import cat_icon_og from '../../HelpCenter/images/og.svg';
import cat_icon_mt from '../../HelpCenter/images/mt.svg';
import cat_icon_at from '../../HelpCenter/images/at.svg';
import cat_icon_ki from '../../HelpCenter/images/ki.svg';
import cat_icon_fi from '../../HelpCenter/images/fi.svg';

const catImge: any = {
  '1001': cat_icon_tv,
  '1002': cat_icon_og,
  '1003': cat_icon_mt,
  '1004': cat_icon_at,
  '1005': cat_icon_ki,
  '1006': cat_icon_fi,
};

export const getImagebyCode = (catCode: string): any => {
  return catImge[catCode];
};
