/* eslint-disable react/jsx-key */
import React, { useContext, useEffect, useRef, useState } from 'react';
import fuv_upload from '../../assets/images/fuv_upload.svg';
import './fuvStyles.css';
import fuv_root from '../../assets/images/fuv_root.svg';
import { PubSub } from 'aws-amplify';
import { Modal, Switch } from 'antd';

import {
  CaretRightOutlined,
  PauseCircleOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons';
import { Button, Collapse, notification, Spin } from 'antd';
import {
  getLiveVideoStatus,
  getTractorsListData,
  getUploadFiles,
  updateLiveVideoStatus,
  uploadFilesControl,
} from '../../constants/Api';
import { FileUploadList, TractorsList } from '../../constants/types';
import { ApplicationContext } from '../../context/AppContext';
import { getTractorAvailableState, tractorSort } from '../../constants/Common';
import CustomSelect from '../common/CustomSelect2';

const { Panel } = Collapse;

interface TractorResponseType {
  label: string;
  value: number | string | boolean;
  disabled: boolean;
  index: number | string;
  isAvailable: string;
  isOnline: boolean;
}

interface MosEvent {
  category: string;
  message: string;
  tractor_id: number;
}

const text = `
  Lorem ipsum is a placeholder text commonly
`;
const FileUploadView: React.FC = () => {
  const { userDetails } = useContext(ApplicationContext);
  const [fileUploading, setFileUploading] = useState<FileUploadList[]>([]);
  const [tractorsData, setTractorsData] = useState<TractorResponseType[]>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [filterTractor, setFilterTractor] = useState<any>();
  const selectedTractorId = useRef(0);

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      getTractorsList();
    }
  }, [userDetails]);

  useEffect(() => {
    if (filterTractor) {
      getUploadFilesList();
    }
  }, [filterTractor]);

  const getLiveStatus = async () => {
    try {
      if (userDetails && userDetails.organization) {
        await getLiveVideoStatus(
          userDetails.organization.api_url,
          filterTractor,
        );
      }
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    }
  };

  const getTractorsList = async () => {
    try {
      const tractor: { records: TractorsList[] } = await getTractorsListData(
        userDetails.organization.api_url,
        userDetails.organization_id,
        1,
      );
      const data =
        tractor && tractor.records && tractor.records.length > 0
          ? tractor.records
          : [];
      const tractors: TractorResponseType[] = data.map((d: TractorsList) => {
        return {
          label: d.name,
          value: d.id,
          disabled: false,
          index: d.id,
          connection: d.connection,
          isOnline: d.connection === 'online' ? true : false,
          isAvailable: getTractorAvailableState(d.connection, d?.heartbeat),
        };
      });
      const arr = tractorSort(
        tractors.filter((e) => e.isOnline),
        'label',
      );
      setTractorsData([...tractorsData, ...arr]);
      if (tractors.length > 0) {
        setFilterTractor(arr[0]?.value);
        selectedTractorId.current = arr[0]?.value;
      }
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    }
  };

  const getUploadFilesList = async () => {
    try {
      setLoader(true);
      const data = await getUploadFiles(
        userDetails?.organization?.api_url,
        filterTractor,
      );

      setFileUploading(data);
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    } finally {
      setLoader(false);
    }
  };

  const uploadFilesControlling = async (payload: any) => {
    try {
      const response = await uploadFilesControl(
        userDetails.organization.api_url,
        filterTractor,
        payload,
      );
      notification.success({ message: response.msg });

      getUploadFilesList();
    } catch (err: any) {
      notification.error({
        message: err?.message,
      });
    }
  };

  const showModel = (event: MosEvent) => {
    Modal.confirm({
      title: 'Live Video Stream',
      content: `Live Video Stream is ${
        event?.message
      } do you want to change it to ${
        event?.message === 'active' ? 'Inactive' : 'Active'
      }`,
      onOk: () => handleToogleLiveVideo(event?.message !== 'active'),
    });
  };

  useEffect(() => {
    try {
      if (userDetails && userDetails.organization) {
        const sub1 = PubSub.subscribe(
          `mos_events_${userDetails.organization_id}`,
        ).subscribe({
          next: (data: any) => {
            const dataValue = data.value;
            const { category }: MosEvent = dataValue;
            if (category === 'live_video_streaming_status') {
              showModel(dataValue);
            }
          },
          error: (error: any) =>
            // eslint-disable-next-line no-console
            console.error('error connecting to IOT ', error),
        });

        return () => {
          sub1.unsubscribe();
        };
      }
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    }
  }, []);

  const handleSelect = async (e: any) => {
    setFilterTractor(e);
    selectedTractorId.current = e;
  };

  const handleToogleLiveVideo = async (checked: boolean) => {
    try {
      await updateLiveVideoStatus(
        userDetails.organization.api_url,
        selectedTractorId?.current,
        !checked,
      );
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    }
  };

  return (
    <>
      <div className="personnelSec fileUploadBack"></div>
      <div className="fileUploadContainer tblContainer">
        <div className="fuvWrapper">
          <div className="fuvRow fullBdr">
            <div className="fuvHead">
              <div className="fuvButtons">
                <Button
                  className="resumeAllTxt"
                  onClick={() =>
                    uploadFilesControlling({
                      upload_all: true,
                    })
                  }
                >
                  <img src={fuv_upload} /> &nbsp; Upload All{' '}
                </Button>

                <Button
                  className="fuvBtn"
                  onClick={() =>
                    uploadFilesControlling({
                      pause_all: true,
                    })
                  }
                >
                  {' '}
                  <PauseCircleOutlined style={{ fontSize: 15 }} /> Pause All{' '}
                </Button>
              </div>
              <div className="filters_card slctTractor">
                <CustomSelect
                  label="Tractor"
                  cssClass="min_width"
                  value={filterTractor}
                  setValue={(id: any) => {
                    handleSelect(id);
                  }}
                  options={tractorsData.map((item) =>
                    Object.assign({
                      id: item.index,
                      name: item.label,
                      isAvailable: item.isAvailable,
                    }),
                  )}
                  optionKey="id"
                  optionDisplay="name"
                  isAll={false}
                />
              </div>
            </div>
          </div>
          <div className="fuvRow mb10 fullBdr">
            <div className="fuvRoot">
              <div className="fuvHead fuvbdr">
                <span>
                  <Button className="fuvBtn" onClick={() => getLiveStatus()}>
                    {' '}
                    <VideoCameraOutlined style={{ fontSize: 15 }} /> Check Live
                    Video Status{' '}
                  </Button>
                </span>
              </div>
              <div className="fuvHead fuvbdr">
                <img src={fuv_root} /> <span className="rootTxt">Root</span>
              </div>
              <div className="rootFolders">
                <Collapse
                  bordered={false}
                  expandIcon={({ isActive }) => (
                    <CaretRightOutlined rotate={isActive ? 1 : 0} />
                  )}
                  className="site-collapse-custom-collapse"
                >
                  {fileUploading?.length > 0 &&
                    fileUploading.map((item: FileUploadList, i: any) => {
                      return (
                        <Panel
                          header={
                            <span className="rootItemsTxt">
                              {item.attribute_name}
                            </span>
                          }
                          key={i}
                          extra={
                            <Switch
                              checkedChildren="Uploading..."
                              unCheckedChildren="Paused"
                              onChange={() => {
                                uploadFilesControlling({
                                  attribute_name: item.attribute_name,
                                  upload: !item.upload,
                                  network: 'all',
                                });
                              }}
                              checked={item.upload}
                            />
                          }
                        >
                          <p>{text}</p>
                        </Panel>
                      );
                    })}
                </Collapse>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="edit-loader">
        {loader && (
          <div className="loader">
            <Spin size="large" />
          </div>
        )}
      </div>
    </>
  );
};

export default FileUploadView;
