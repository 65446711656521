import React, { useState, useEffect, useContext, memo } from 'react';
import { useTranslation } from 'react-i18next';
import totalResolved from '../../assets/images/totalresolved.svg';
import total_unresolved from '../../assets/images/total_unresolved.svg';
import assignedToMe from '../../assets/images/assignedtome.svg';
import mtSupport from '../../assets/images/mtsupport.svg';
import { getTicketsSummary } from '../../constants/Api';
import { notification } from 'antd';
import { ApplicationContext } from '../../context/AppContext';
import translate from '../../locale/en_translate.json';

interface Props {
  summeryflag: any;
}

const TicketSummary: React.FC<Props> = ({ summeryflag }: Props) => {
  const { userDetails } = useContext(ApplicationContext);
  const [ticketInfo, setTicketInfo] = useState<any>(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (userDetails && userDetails.organization) getSummary();
    return () => setTicketInfo(null);
  }, [userDetails]);

  useEffect(() => {
    if (summeryflag) {
      getSummary();
    }
  }, [summeryflag]);

  const getSummary = async () => {
    try {
      const data = await getTicketsSummary(
        userDetails.organization.api_url,
        userDetails.organization.fleet.id,
      );
      setTicketInfo(data);
    } catch (error: any) {
      notification.error({ message: error.message });
    }
  };

  return (
    <div className="gridContainer tcktGridContainer">
      <>
        <div className="gridItem">
          <img src={total_unresolved} alt="total_unresolved" />
          <div className="widget_content">
            <div className="assignTxt">
              {t(translate.tickets.summary.totalUnresolved)}
            </div>
            <div className="assignNum">{ticketInfo?.total_unresolved || 0}</div>
          </div>
        </div>
        <div className="gridItem">
          <img src={assignedToMe} alt="assignedToMe" />

          <div className="widget_content">
            <div className="assignTxt">
              {t(translate.tickets.summary.assignedToMe)}
            </div>
            <div className="assignNum">
              {ticketInfo?.assigned_to_user || '-'}
            </div>
          </div>
        </div>
        <div className="gridItem mtRight">
          <img src={mtSupport} alt="mtSupport" />
          <div className="widget_content">
            <div className="assignTxt">
              {t(translate.tickets.summary.mtSupport)}
            </div>
            <div className="assignNum">
              {ticketInfo?.forwarded_tickets +
                ticketInfo?.in_progress_tickets || ''}
            </div>
          </div>
          <div>
            <div className="forwardTxt">
              <span className="squareCon"></span>
              <span>{ticketInfo?.forwarded_tickets}</span>
              <h4>{t(translate.tickets.summary.forwarded)}</h4>
            </div>
            <div className="forwardTxt">
              <span className="squareCon squareBg"></span>
              <span>{ticketInfo?.in_progress_tickets || 0}</span>
              <h4>{t(translate.tickets.summary.inProgress)}</h4>
            </div>
          </div>
        </div>
        <div className="gridItem">
          <img src={totalResolved} alt="totalResolved" />
          <div className="widget_content">
            <div className="assignTxt">
              {t(translate.tickets.summary.totalResolved)}
            </div>
            <div className="assignNum">{ticketInfo?.total_resolved || 0}</div>
          </div>
        </div>
      </>
    </div>
  );
};

export default memo(TicketSummary);
