/* eslint-disable  @typescript-eslint/ban-types */
/* eslint-disable  no-unused-vars */
/* eslint-disable  no-useless-escape */

import React, { FC, ChangeEvent, useState, useEffect } from 'react';
import { Input } from 'antd';
import { isValidEmail } from '../../constants/Common';
import classNames from 'classnames';

interface InputProps {
  type?: string;
  value: string;
  placeholder?: string;
  onChange?: (event: any) => void;
  min?: number;
  max?: number;
  name: string;
  dataTestid?: string;
  dataTestidError?: string;
  className?: string;
  hasError?: (state: boolean) => void;
  onKeyPress?: (event: any) => void;
  validations?: any;
  required?: boolean;
  label?: any;
  disabled?: any;
}

const CInput: FC<InputProps> = ({
  type,
  value,
  placeholder,
  onChange,
  min = 3,
  max = 60,
  name,
  dataTestid,
  dataTestidError,
  className,
  hasError,
  onKeyPress,
  validations,
  required,
  label,
  disabled,
}) => {
  const [error, setError] = useState<string>('');
  const [focus, setFocus] = useState(false);

  const isEmpty = (value: any) => {
    if (typeof value === 'undefined') return true;
    if (typeof value === 'string' && value.trim() === '') return true;
    if (typeof value === 'number' && isNaN(value)) return true;
    return false;
  };

  const labelClass = focus || !isEmpty(value) ? 'label label-float' : 'label';

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;

    if (name !== 'email' && name !== 'password') {
      if (handleMinInputLengthValidation(newValue)) {
        if (handleMaxInputLengthValidation(newValue)) {
          handleInputSpecialCharValidations(newValue);
        }
      }
    } else if (name === 'email') {
      handleEmailValidation(newValue);
    }
  };

  const handleEmailValidation = (newValue: string) => {
    setError('');
    const validationError = isValidEmail(newValue);
    if (newValue?.trim().length && !validationError) {
      setError('Not a valid email');
    } else {
      setError('');
    }
    onChange && onChange(newValue);
  };

  const handleMinInputLengthValidation = (newValue: string): boolean => {
    let errorFlag = false;
    if (min && newValue.length >= min) {
      setError('');
      errorFlag = true;
    } else {
      setError(`Min ${min} Characters Required`);
      errorFlag = false;
    }
    onChange && onChange(newValue);
    return errorFlag;
  };

  const handleMaxInputLengthValidation = (newValue: string): boolean => {
    let errorFlag = false;
    if (max && newValue.length <= max) {
      setError('');
      errorFlag = true;
    } else {
      setError(`Should not exceed ${max} characters`);
      errorFlag = false;
    }
    onChange && onChange(newValue);
    return errorFlag;
  };

  const handleInputSpecialCharValidations = (newValue: string): any => {
    if (name !== 'orgAddress' && name !== 'city') {
      const specialCharactersRegex = /[!@#$%^&*()+{}\[\]:;<>,".=?~\\|/]/;
      if (!specialCharactersRegex.test(newValue)) {
        setError('');
      } else {
        setError('Should not contain special character');
      }
      onChange && onChange(newValue);
    }
    onChange && onChange(newValue);
  };

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log(value, 'value');
    if (typeof value === 'string' && value?.trim().length && hasError)
      hasError(error !== '');
  }, [error, value]);

  return (
    <div className={`${className} inputContainer`}>
      <div
        className="float-label"
        onBlur={() => setFocus(false)}
        onFocus={() => setFocus(true)}
      >
        <Input
          type={type}
          value={value}
          placeholder={placeholder}
          onChange={handleChange}
          min={min}
          max={max}
          disabled={disabled}
          data-testid={dataTestid}
          // className={className}
          onKeyPress={onKeyPress}
          className={
            validations === false && required
              ? classNames({
                  errorClass: validations === false && required,
                })
              : className
          }
        />
        <label className={labelClass}>
          {label} {required && <span className="float-span">*</span>}
        </label>
        {error !== '' && (
          <div className="errorTxt" data-testid={dataTestidError}>
            {error}
          </div>
        )}
      </div>
    </div>
  );
};
export default CInput;
