import { Layout, notification } from 'antd';
import React, { useState, useEffect, useContext } from 'react';
import { getBatteryData } from '../../constants/Api';
import { ApplicationContext } from '../../context/AppContext';
import { useTranslation } from 'react-i18next';
import translate from '../../locale/en_translate.json';
import InfiniteScrollTable from '../common/InfiniteScrollTable';
import { initScroller } from '../../constants/Common';
import usePaginate from '../../hooks/usePaginate';
import { mapBatteryData } from '../../lib/dataFormat';

interface Props {
  search: string;
}
const Battery: React.FC<Props> = ({ search }) => {
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const [total, setTotal] = useState<number>(0);
  const [pageSize] = useState<number>(25);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [initialize, setInitialize] = useState<boolean>(false);

  const { filterData, setData, activity, hasMore, checkActivity } =
    usePaginate();

  const columns = [
    {
      render: (name: string) => {
        return (
          <>
            <span className="upTname" data-testid={`${name}-Battery`}>
              {name}
            </span>
          </>
        );
      },
      title: () => {
        return <>{t(translate.baseStation.Name)}</>;
      },
      dataIndex: 'battery_name',
      key: 'battery_name',
      width: '20%',
      showSorterTooltip: false,
    },
    {
      title: `${t(translate.baseStation.houseMeter)}`,
      dataIndex: 'hour_meter',
      key: 'hour_meter',
      width: '30%',
      showSorterTooltip: false,
    },
    {
      title: `${t(translate.fleet.pin)}`,
      dataIndex: 'bin',
      key: 'bin',
      width: '50%',
    },
  ];

  const getBatterysList = async () => {
    try {
      if (checkActivity()) initScroller();
      setLoading(true);
      const result = await getBatteryData(
        userDetails.organization.api_url,
        userDetails.organization_id,
        pageNumber,
        pageSize,
        search ? search : '',
      );
      setTotal(result?._metadata?.total_records_count);
      const { records } = result;
      let data = Array.isArray(records) ? records : [];
      data = mapBatteryData(data);
      setData(data);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    } finally {
      setLoading(false);
      if (!initialize) setInitialize(true);
    }
  };

  const loadBatteries = () => {
    if (userDetails && !userDetails.organization) return;
    if (checkActivity()) {
      pageNumber !== 1 ? setPageNumber(1) : getBatterysList();
    } else if (activity.current === 'paginate' || activity.current === '') {
      getBatterysList();
    }
  };

  const handleLoadMore = () => {
    if (pageNumber === 1 && checkActivity() && document) initScroller();

    activity.current = 'paginate';
    setPageNumber(pageNumber + 1);
  };

  useEffect(() => {
    if (userDetails && userDetails.organization) loadBatteries();
  }, [userDetails, pageNumber]);

  useEffect(() => {
    activity.current = 'search';
    if (userDetails && userDetails.organization && initialize) loadBatteries();
  }, [search]);

  return (
    <Layout>
      <div className="common_table">
        <InfiniteScrollTable
          xScroll={400}
          columns={columns}
          hasMore={hasMore}
          loading={loading}
          filterData={filterData}
          totalcount={total}
          handleLoadMore={handleLoadMore}
          filename={
            Number(total) === 0 || Number(total) > 1 ? 'Batteries' : 'Batterys'
          }
        />
      </div>
    </Layout>
  );
};

export default Battery;
