import React, { useState, useEffect, useContext } from 'react';
import { Button, Form, Input, Space, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import translate from '../../../locale/en_translate.json';
import { UserManualAppContext } from '../maintenance/UserManual/UserManualContext';
import { SET_SELECTED_KEYS } from '../maintenance/UserManual/UserManualReducer';

interface Props {
  columns: Array<any>;
  dataSource: Array<any>;
  loader: boolean;
}

const CustomTable1: React.FC<Props> = ({ columns, dataSource, loader }) => {
  const { t } = useTranslation();
  const { userManualReducer } = useContext(UserManualAppContext);
  const [state, dispatch] = userManualReducer;
  const { selectedKeys } = state;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<ColumnsType<any>>([]);
  const [value, setValue] = useState<string>('');

  const start = () => {
    setLoading(true);
    // ajax request after empty completing
    setTimeout(() => {
      // setSelectedRowKeys([]);
      dispatch({ type: SET_SELECTED_KEYS, payload: [] });
      setValue('');
      setLoading(false);
    }, 1000);
  };

  useEffect(() => {
    setData(dataSource);
  }, [dataSource]);

  useEffect(() => {
    if (value.trim() !== '' && value.length > 2) {
      const re = new RegExp(value, 'i');
      const tdata = dataSource.filter((item: any) =>
        re.test(item.indicator_error_code),
      );
      setData(tdata);
    } else {
      setData(dataSource);
    }
  }, [value]);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    if (value && newSelectedRowKeys.length === 1) {
      dispatch({
        type: SET_SELECTED_KEYS,
        payload: [...selectedKeys, ...newSelectedRowKeys],
      });
    } else if (newSelectedRowKeys.length !== 0) {
      dispatch({
        type: SET_SELECTED_KEYS,
        payload: newSelectedRowKeys,
      });
    }
  };

  const rowSelection = {
    selectedRowKeys: selectedKeys,
    onChange: onSelectChange,
    onSelect: (record: any, selected: boolean, selectedRows: any[]) => {
      if (!selected && (!selectedRows.length || value.trim() !== '')) {
        const tselectedKeys = selectedKeys.filter(
          (item: any) => item !== record.key,
        );
        dispatch({
          type: SET_SELECTED_KEYS,
          payload: tselectedKeys,
        });
      }
    },
  };

  const hasSelected = selectedKeys.length > 0;

  return (
    <div>
      <div className="searchItemSec">
        <div>
          <Input
            placeholder="search"
            data-testid="searchErrorCodes-CustomTable1"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </div>
        <div>
          <Button
            data-testid="clearAllButton-CustomTable1"
            type="primary"
            onClick={start}
            disabled={!hasSelected}
            loading={loading}
          >
            {t(translate.buttons.clearAll)}
          </Button>
          <span style={{ marginLeft: 8 }}>
            {hasSelected ? `Selected ${selectedKeys.length} items` : ''}
          </span>
        </div>
      </div>
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={data}
        // pagination={pagination}
        loading={loader}
      />
    </div>
  );
};

export default CustomTable1;
