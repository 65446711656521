/* eslint-disable react/jsx-no-duplicate-props */
import React, { useState } from 'react';
import { AutoComplete } from 'antd';

interface Props {
  label?: any;
  value?: any;
  cssClass?: any;
  setValue?: (data: any) => void;
  options?: any;
  optionKey?: any;
  optionDisplay?: any;
  jsonFormat?: boolean;
  required?: any;
  validations?: any;
  testId?: any;
  searchmethod?: (data: any) => void;
}

const CustomAutoComplete: React.FC<Props> = ({
  label,
  value,
  setValue,
  options,
  optionKey,
  optionDisplay,
  jsonFormat,
  required,
  searchmethod,
}: Props) => {
  const [focus, setFocus] = useState(false);
  const { Option } = AutoComplete;

  const isEmpty = (value: any) => {
    if (typeof value === 'undefined') return true;
    if (typeof value === 'string' && value.trim() === '') return true;
    if (typeof value === 'number' && isNaN(value)) return true;
    return false;
  };
  const labelClass = focus || !isEmpty(value) ? 'label label-float' : 'label';

  return (
    <div
      className="float-label"
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      <AutoComplete
        className=""
        onSearch={(text: any) => searchmethod && searchmethod(text)}
        onSelect={(e: any) => {
          setValue && setValue(e);
        }}
        defaultValue={value}
      >
        {options &&
          options?.length > 0 &&
          options?.map((data: any) => {
            return (
              <Option
                value={jsonFormat ? JSON.stringify(data) : data[optionKey]}
                key={data[optionKey]}
                data-testid={`${
                  optionDisplay ? data[optionDisplay] : data[optionKey]
                }-CustomSelect`}
                disabled={data && data.disabled ? true : false}
              >
                {optionDisplay ? data[optionDisplay] : data[optionKey]}
              </Option>
            );
          })}
      </AutoComplete>
      <label className={labelClass}>
        {label} {required && <span className="float-span">*</span>}
      </label>
    </div>
  );
};

export default CustomAutoComplete;
