import Layout, { Content, Header } from 'antd/lib/layout/layout';
import React, { createRef, useContext, useEffect, useState } from 'react';
import FullCalendar, {
  EventClickArg,
  EventSourceInput,
} from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import {
  getCategoryList,
  getLookpsList,
  getTaskList,
  getTasksearch,
  getTractorsListData,
} from '../../constants/Api';
import {
  TaskResponse,
  Taskview,
  Taskviewdetails,
  TractorsList,
} from '../../constants/types';
import listPlugin from '@fullcalendar/list';
import { useHistory, useLocation } from 'react-router';
import RoutesConstants from '../../routes/RoutesConstant';
import './style.css';
import { Button, Input, notification, Space } from 'antd';
import { ApplicationContext } from '../../context/AppContext';
import { SearchOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import translate from '../../locale/en_translate.json';
import CreateTaskModel from './CreateTaskModel';
import { track } from '../../util/logger';
import CustomSelect from '../common/CustomSelect';
import { TypesData, statusList } from '../../constants/AppData';

type Event = {
  id: number;
  task_uuid: string;
  start: number;
  end: number;
  title: string;
  backgroundColor: string;
};

const TaskMaster: React.FC = () => {
  const { push } = useHistory();
  const { pathname } = useLocation();
  const { userDetails } = useContext(ApplicationContext);
  const [event, setEvent] = useState<Event[]>([]);
  const calenderRef: React.RefObject<FullCalendar> = createRef();
  const [viewTitle, setViewTitle] = useState('');
  const [showCreate, setShowCreate] = useState(false);
  const [fromDate, setFromDate] = useState(new Date());
  const [titleSearch, setTitleSearch] = useState<string>('');
  const [tractorName, setTractorName] = useState<any>();
  const [tractors, setTractorsData] = useState<TractorsList[]>([]);
  const [type, setType] = useState<any>();
  const [lookupdata, setLookupData] = useState<any[]>([]);
  const [lookup, setlookup] = useState<any>();
  const [status, setStatus] = useState<any>();
  const [result, setResult] = useState<any[]>([]);
  const [assignid, setAssignid] = useState<any>('');

  const { t } = useTranslation();
  useEffect(() => {
    if (userDetails && userDetails.organization) {
      setTitleSearch('');
      getTasks('');
    }
  }, [
    viewTitle,
    userDetails,
    showCreate,
    type,
    lookup,
    tractorName,
    status,
    assignid,
  ]);

  const getTasks = async (title: string) => {
    try {
      const startTime = calenderRef.current
        ?.getApi()
        .view.activeStart.getTime();
      const endTime = calenderRef.current?.getApi().view.activeEnd.getTime();
      const tasks: TaskResponse[] = await getTaskList(
        userDetails.organization.api_url,
        startTime || 0,
        endTime || 0,
        title,
        status !== 'All' ? status : 'created,started,finished',
        assignid,
        type || '',
        lookup || 0,
        tractorName || 0,
      );

      const getColor = (status: string) => {
        if (status === 'Closed') {
          return 'grey';
        } else if (status === 'Finished') {
          return 'orange';
        } else if (status === 'Started') {
          return '#0A7189';
        } else if (status === 'Created') {
          return 'green';
        } else {
          return '';
        }
      };
      const events: Event[] = [];
      tasks.forEach((task: any) => {
        events.push({
          id: task.id,
          task_uuid: task.event_uuid,
          start: task.event_start_time,
          end: task.event_finish_time,
          title: task.event_title,
          backgroundColor: getColor(task.event_status),
        });
      });

      setEvent(events);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    }
  };

  const handleClickEvent = (e: EventClickArg) => {
    push({
      pathname: RoutesConstants.Taskdetails,
      state: {
        task_uuid: e.event._def.extendedProps.task_uuid,
      },
    });
  };

  const handleCloseModel = () => {
    setFromDate(new Date());
    setShowCreate(false);
  };
  useEffect(() => {
    if (userDetails && userDetails.organization) {
      getTractorsList();
      getLookpsEvents();
      getassigned();
    }
  }, [userDetails]);

  const getassigned = async () => {
    const data: Taskviewdetails = await getTasksearch(
      userDetails.organization.api_url,
      '',
    );
    data.records.forEach((ele: Taskview) => {
      ele.fullname = ele.first_name + ' ' + ele.last_name;
    });
    const { records } = data;
    setResult(records);
  };
  const getLookpsEvents = async () => {
    const data = await getLookpsList(
      userDetails.organization.api_url,
      'events',
    );
    setLookupData(data);
  };

  const getTractorsList = async () => {
    try {
      const tractor = await getTractorsListData(
        userDetails.organization.api_url,
        userDetails.organization_id,
        1,
      );
      const data =
        tractor && tractor.records && tractor.records.length > 0
          ? tractor.records
          : [];
      const tractorData = data.map((d: TractorsList) => {
        return {
          label: d.name,
          value: d.id,
          disabled: false,
          index: d.id,
        };
      });
      setTractorsData(tractorData);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    }
  };

  const onSelectTypes = (e: any) => {
    setType(e);
  };

  const onSelecttractor = (e: any) => {
    setTractorName(e);
  };
  const onSelect = (e: any) => {
    setAssignid(e);
  };
  const onSelectLooks = (e: any) => {
    setlookup(e);
  };
  const onSelectStatus = (e: any) => {
    setStatus(e);
  };

  return (
    <Layout className="taskmasterComp">
      <div className="mainContent common_wrapper">
        <div className="taskmasterblk">
          <div className="dbWidget tmTbl calTbl taskmasterWidget tblContainer workScheduleFilters">
            <Header className="tm-header ant-card-head">
              <div className="ant-card-head-title">Operation Planner</div>
              <div className="ant-card-extra filters_card">
                <Space size="large">
                  <Input
                    className="common_search"
                    placeholder="Search tasks"
                    data-testid="searchtask-WorkSchedule"
                    value={titleSearch}
                    // prefix={<SearchOutlined />}
                    addonBefore={<SearchOutlined />}
                    onChange={(e) => {
                      setTitleSearch(e.target.value);
                      getTasks(e.target.value);
                    }}
                  />
                </Space>
                <CustomSelect
                  label="Tractor"
                  value={tractorName}
                  cssClass="min_width max_width"
                  options={tractors}
                  optionDisplay="label"
                  optionKey="value"
                  setValue={onSelecttractor}
                ></CustomSelect>
                <CustomSelect
                  label="Operation"
                  cssClass="min_width max_width"
                  value={assignid}
                  setValue={onSelect}
                  options={result}
                  optionKey="id"
                  optionDisplay="fullname"
                />
                <CustomSelect
                  label="Status"
                  cssClass="min_width max_width"
                  value={status}
                  setValue={onSelectStatus}
                  options={statusList}
                  optionDisplay="name"
                  optionKey="value"
                ></CustomSelect>
                <CustomSelect
                  label="Mode"
                  cssClass="min_width max_width"
                  value={type}
                  setValue={onSelectTypes}
                  options={TypesData}
                  optionKey="name"
                ></CustomSelect>
                <CustomSelect
                  label="Event Type"
                  cssClass="min_width max_width"
                  value={lookup}
                  setValue={onSelectLooks}
                  options={lookupdata}
                  optionKey="id"
                  optionDisplay="name"
                ></CustomSelect>
                <Button
                  className="tm-button ant-btn-primary btn-create float-right"
                  data-testid="addbutton-WorkSchedule"
                  onClick={() => {
                    track('AddTask', {
                      event: 'Add Task',
                    });
                    setShowCreate(true);
                  }}
                >
                  {t(translate.buttons.add)}
                </Button>
              </div>
            </Header>
            <Content className="tm-content ant-card-body calendar-body rmTbl2">
              {' '}
              <FullCalendar
                ref={calenderRef}
                plugins={[
                  dayGridPlugin,
                  timeGridPlugin,
                  interactionPlugin,
                  listPlugin,
                ]}
                initialView="listWeek"
                headerToolbar={{
                  center: 'title',
                  left: 'prev,next',
                  right: 'listWeek,dayGridMonth,timeGridWeek,timeGridDay',
                }}
                buttonText={{
                  list: 'schedule',
                }}
                editable={false}
                selectable={true}
                selectMirror={true}
                dayMaxEvents={5}
                events={event as EventSourceInput}
                eventsSet={() =>
                  setViewTitle(calenderRef.current?.getApi().view.title || '')
                }
                eventClick={(e) => handleClickEvent(e)}
                dateClick={(e) => {
                  setShowCreate(true);
                  setFromDate(e.date);
                }}
                moreLinkClick={(e) =>
                  calenderRef.current
                    ?.getApi()
                    .changeView('timeGridDay', e.date)
                }
              />
            </Content>
            {showCreate && (
              <CreateTaskModel
                visible={showCreate}
                close={() => handleCloseModel()}
                success={() => {
                  handleCloseModel();
                }}
                selectedDate={fromDate}
                selectedfromDate={fromDate}
              />
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default TaskMaster;
