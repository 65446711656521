import { Tabs } from 'antd';
import React, { useState } from 'react';
import { track } from '../../../util/logger';
import ImpactDashboard from '../impactDashboard/ImpactDashboard';
import AutoDriveReport from '../autodrivereport';
import TractorResultsDashboard from '../tractorResultsDashboard/tractorResultsDashboard';
import OperationsUsage from '../autodrivereport/OperationsUsage';

const { TabPane } = Tabs;

const DealerAnalytics: React.FC = () => {
  const [tabid, setTabid] = useState('1');
  return (
    <Tabs
      activeKey={tabid}
      centered
      id="fleet-tab-FleetHealthContainer"
      className="common_tabs"
      onChange={(key) => {
        setTabid(key);
        track(key, {
          event: `${key} Tab`,
        });
      }}
    >
      <TabPane
        tab="Impact Dashboard"
        key="1"
        className="attTableHeight mBottom0"
      >
        <ImpactDashboard />
      </TabPane>
      <TabPane
        tab="Autodrive Performance Report"
        key="2"
        className="attTableHeight mBottom0"
      >
        <AutoDriveReport />
      </TabPane>
      {/* <TabPane
        tab="Operations Usage"
        key="4"
        className="attTableHeight mBottom0"
      >
        <OperationsUsage />
      </TabPane> */}
      <TabPane
        tab="Undesired Stops Dashboard"
        key="3"
        className="attTableHeight mBottom0"
      >
        <TractorResultsDashboard />
      </TabPane>
    </Tabs>
  );
};

export default DealerAnalytics;
