import React from 'react';
import './style.css';
import { useState, useEffect, memo } from 'react';
import {
  getStatusForTractor,
  getTractorStatusClass,
} from '../../constants/Common';
import { Tooltip } from 'antd';

interface Props {
  placeholder?: string;
  label?: string;
  list: any[];
  isAll?: boolean;
  defaultId?: string;
  keyValue?: string;
  keyLabel?: string;
  selectTrigger: (data: any) => void;
  filterType?: number;
  dataTestkey?: string;
  dataTestLabel?: string;
  tractorStatus?: boolean;
  className?: string;
}

const CSelect: React.FC<Props> = ({
  label = 'Type',
  placeholder = 'All',
  list = [],
  isAll = true,
  defaultId,
  keyValue = 'id',
  keyLabel = 'name',
  selectTrigger,
  filterType,
  dataTestkey = keyValue,
  dataTestLabel = keyLabel,
  tractorStatus = false,
  className = '',
}) => {
  const [sel1, setSel1] = useState<boolean>(false);
  const [selected, setSelected] = useState<any>(null);
  const [farr, setFarr] = useState<any>([]);
  const [arr, setArr] = useState<any>([]);

  useEffect(() => {
    if (list.length > 0) {
      list = list.map((item) =>
        Object.assign({
          [keyValue]: item[keyValue],
          [keyLabel]: item[keyLabel],
          isAvailable: item['isAvailable'],
        }),
      );
      setArr(list);
    }
  }, [list]);

  const handleSelect = (id: string) => {
    setSel1(false);
    const [selected] = arr.filter(
      (item: any) => String(item[keyValue]) == String(id),
    );
    setSelected(selected);
    const [selected1] = list.filter(
      (item: any) => String(item[keyValue]) == String(id),
    );
    selectTrigger(selected1);
  };

  useEffect(() => {
    if (arr.length > 0 && defaultId) handleSelect(String(defaultId));
  }, [defaultId, arr]);

  useEffect(() => {
    if (selected && isAll && filterType !== 3) {
      const tarr = [{ [keyValue]: '', [keyLabel]: placeholder }, ...arr];
      setFarr(tarr);
    } else if (selected && !isAll && filterType === 3) {
      setFarr(arr);
    } else if (selected && !isAll) {
      const tarr = arr.filter(
        (item: any) => item[keyValue] != selected[keyValue],
      );
      setFarr(tarr);
    } else {
      setFarr(arr);
    }
  }, [sel1, selected]);

  const buttonGroupPressed = (e: any) => {
    e.stopPropagation();
    const isButton = e.target.closest('.flbBlock');
    if (!isButton) {
      setSel1(false);
      return;
    }
  };

  useEffect(() => {
    const buttonGroup: any = document.querySelector('body');
    buttonGroup.addEventListener('click', buttonGroupPressed);
    return () => {
      buttonGroup.removeEventListener('click', buttonGroupPressed);
    };
  }, []);

  const handleCommonClick = (e: any) => {
    const isButton = e.target?.closest('.flbBlock');
    const ID = isButton?.getAttribute('id');
    const elements: any = document.querySelectorAll('.flbBlock.selectOpen');
    for (const element of elements) {
      const id = element.getAttribute('id');
      if (id !== ID) element?.click();
    }
  };

  return (
    <div
      className={`CSelect ${className} ${
        tractorStatus ? 'CSelectTractorStatus' : ''
      }`}
    >
      <div
        className={
          selected && isAll && filterType !== 3
            ? 'flblWrapper all-select trasctorlistwidth'
            : 'flblWrapper trasctorlistwidth'
        }
      >
        <div
          className={sel1 ? 'flbBlock selectOpen' : 'flbBlock'}
          id={`CSELECT-${label}`}
          onClick={(e: any) => {
            e.stopPropagation();
            handleCommonClick(e);
            setSel1(!sel1);
          }}
        >
          {/* <input type="checkbox" id="chck1" /> */}
          <label
            className={selected ? 'tab-label' : 'tab-label selNor'}
            htmlFor="chck1"
            data-testid={`${label}-${dataTestLabel}`}
          >
            <div className={selected ? 'flbSelCont' : 'flbSelCont selNor'}>
              {selected && selected?.[keyValue] !== '' && (
                <div className="flbTopFixed">{label}</div>
              )}

              {selected && selected?.[keyValue] !== '' ? (
                <div className="flbSelected">
                  <span
                    style={{ flex: 2, minWidth: '64px' }}
                    className="text-ellipsis"
                  >
                    {selected[keyLabel]}
                  </span>
                  {/* {tractorStatus && (
                    <span
                      style={{ margin: 'auto 10px', flex: 1 }}
                      className={getTractorStatusClass(selected?.isAvailable)}
                    >
                      {getStatusForTractor(selected?.isAvailable)}
                    </span>
                  )} */}
                </div>
              ) : (
                <div className="flbSelected selGray">{label}</div>
              )}
            </div>
          </label>
          {/* {sel1 && ( */}
          <div
            className="tab-content"
            style={{ display: sel1 ? 'block' : 'none' }}
          >
            <div className="flbContLine"></div>
            <ul className="flbUL">
              {farr.length > 0 &&
                farr.map((item: any, i: number) => {
                  // eslint-disable-next-line react/jsx-key
                  return (
                    <li
                      className={
                        selected?.[keyValue] === item[keyValue] &&
                        item?.[keyValue] != ''
                          ? 'item sel'
                          : 'item'
                      }
                      data-testid={`${item[dataTestkey]}-${dataTestLabel}`}
                      key={i}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSelect(item[keyValue]);
                      }}
                      style={{ display: 'flex' }}
                    >
                      {/* {item[keyLabel] && item[keyLabel].trim().length > 15 ? (
                        <Tooltip title={item[keyLabel]}>
                          <span
                            style={{ flex: 2, minWidth: '64px' }}
                            className="text-ellipsis"
                            data-testid={`${item[keyLabel]}`}
                          >
                            {item[keyLabel]}
                          </span>
                        </Tooltip>
                      ) : (
                        <span
                          data-testid={`${item[keyLabel]}`}
                          style={{ flex: 2, minWidth: '64px' }}
                          className="text-ellipsis"
                        >
                          {item[keyLabel]}
                        </span>
                      )} */}

                      <Tooltip
                        title={
                          item[keyLabel] && item[keyLabel].trim().length > 15
                            ? item[keyLabel]
                            : ''
                        }
                      >
                        <span
                          style={{ flex: 2 }}
                          className={`text-ellipsis ddMarquee
                          ${
                            item[keyLabel] && item[keyLabel].trim().length > 15
                              ? 'marquee2'
                              : ''
                          }
                            `}
                          data-testid={`${item[keyLabel]}`}
                        >
                          <span>
                            <span>{item[keyLabel]}</span>
                          </span>
                        </span>
                      </Tooltip>

                      {tractorStatus && item?.[keyValue] !== '' && (
                        <span
                          style={{ margin: 'auto 10px', flex: 1 }}
                          className={getTractorStatusClass(
                            item?.isAvailable,
                            item?.heartbeat?.drive_action_details
                              ?.drive_action_created_at,
                          )}
                        >
                          {getStatusForTractor(
                            item?.isAvailable,
                            item?.heartbeat?.drive_action_details
                              ?.drive_action_created_at,
                          )}
                        </span>
                      )}
                    </li>
                  );
                })}
            </ul>
          </div>
          {/* )} */}
        </div>
      </div>
    </div>
  );
};

export default memo(CSelect);
