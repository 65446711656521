/* eslint-disable no-console */
import { notification, Row, DatePicker, Modal } from 'antd';
import Layout, { Content } from 'antd/lib/layout/layout';
import React, { useContext, useEffect, useState } from 'react';
import { getFleetUsageReports, generateFleetReport } from '../../constants/Api';
import { ApplicationContext } from '../../context/AppContext';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import translate from '../../locale/en_translate.json';
import { downloadURI, initScroller } from '../../constants/Common';
import dwlIcon from '../../assets/images/dwlIcon.svg';
import { DownOutlined } from '@ant-design/icons';
import InfiniteScrollTable from '../common/InfiniteScrollTable';
import sortDown from '../../assets/images/sort_down.svg';
import sortUp from '../../assets/images/sort_up.svg';
import { privilagesConstants } from '../../constants/Privilages';
import ReportRenderer from './ReportRenderer';
import usePaginate from '../../hooks/usePaginate';
import { mapReportsData } from '../../lib/dataFormat';

import 'antd/dist/antd.css';
import CustomSelect from '../common/CustomSelect';
import { dateFilters } from '../admin/Common';
import CustomYear from '../common/CustomYear';

const ReportsDistribution: React.FC = () => {
  const { t } = useTranslation();
  const { userDetails, privilegeChecker } = useContext(ApplicationContext);
  const [pageSize] = useState<any>(25);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const [loader, setLoader] = useState<boolean>(false);
  const [isGenerate, setIsGenerate] = useState<boolean>(false);
  // const [DateTime, setDateTime] = useState<any>(null);
  const [reportDate, setReportDate] = useState<any>(null);
  const [sortedData, setSortedData] = useState<any>({
    date: 'descend',
  });
  const [sortedInfo, setSortedInfo] = useState<any>({
    columnKey: 'date',
    order: 'descend',
  });
  const [refresh, setRefresh] = useState(false);
  const [showPDFRenderer, setShowPDFRenderer] = useState(false);
  const [selectedReport, setSelectedReport] = useState('');
  const { filterData, setData, activity, hasMore, checkActivity } =
    usePaginate();
  const [reportId, setReportId] = useState<any>('1');
  const [selectedYear, setSelectedYear] = useState<any>(moment().year());

  const loadReports = () => {
    if (userDetails && !userDetails.organization) return;
    if (checkActivity()) {
      pageNumber !== 1 ? setPageNumber(1) : getReports();
    } else if (activity.current === 'paginate' || activity.current === '') {
      getReports();
    }
  };

  useEffect(() => {
    if (userDetails && userDetails.organization) loadReports();
  }, [
    userDetails,
    selectedYear,
    reportId,
    pageNumber,
    pageSize,
    sortedData,
    refresh,
    sortedInfo.order,
  ]);

  const getReports = async () => {
    try {
      if (checkActivity()) initScroller();
      setLoader(true);
      const result = await getFleetUsageReports(
        userDetails.organization.api_url,
        userDetails.organization_id,
        pageNumber,
        pageSize,
        sortedInfo,
        reportId,
        selectedYear,
      );
      setTotal(result?._metadata?.total_records_count);
      const { records } = result;
      let data = Array.isArray(records) ? records : [];
      data = mapReportsData(data);
      setData(data);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    } finally {
      setLoader(false);
    }
  };

  const handleFilter = (data: number) => {
    activity.current = 'filter';
    setReportId(data);
    setPageNumber(1);
  };
  const handleChange = (pagination: any, filters: any, sorter: any) => {
    activity.current = 'sort';

    const { columnKey, order } = sorter;
    setSortedData({ ...sortedData, [columnKey]: order });
    setSortedInfo(sorter);
  };

  const columns = [
    {
      title: () => {
        return (
          <>
            {t(translate.headers.dateTime)}
            <span className="sort-pad">
              {sortedInfo.columnKey === 'date' && (
                <img
                  className="sort-pad"
                  src={sortedData['date'] === 'ascend' ? sortUp : sortDown}
                />
              )}
            </span>
          </>
        );
      },
      defaultSortOrder: 'descend',
      sortDirections: ['descend', 'ascend', 'descend'],
      ellipsis: true,
      dataIndex: 'date',
      key: 'date',
      width: 240,
      sorter: true,
      visible: true,
      showSorterTooltip: false,
      render: (date: number, record: any) => {
        return (
          <div>{reportId !== '1' ? `${date}` : `${record.reports_date}`}</div>
        );
      },
      onCell: (record: any) => ({
        onClick: () => {
          setSelectedReport(record);
          setShowPDFRenderer(true);
        },
      }),
    },
    {
      title: `${t(translate.reportsDistribution.reportType)}`,
      dataIndex: 'name',
      key: 'name',
      visible: true,
      onCell: (record: any) => ({
        onClick: () => {
          setSelectedReport(record);
          setShowPDFRenderer(true);
        },
      }),
    },
    {
      dataIndex: 'id',
      key: 'id',
      // only displaying download icon which have access
      visible: privilegeChecker(privilagesConstants.Download_Reports),
      // eslint-disable-next-line react/display-name
      render: (id: number, record: any) => (
        <img
          style={{ cursor: 'pointer' }}
          src={dwlIcon}
          onClick={() => {
            if (privilegeChecker(privilagesConstants.Download_Reports)) {
              downloadURI(record.download_report_url, record.id);
            }
          }}
          height="38px"
          alt="download"
        />
      ),
    },
  ].filter((item: any) => item.visible);

  const handleLoadMore = () => {
    if (pageNumber === 1 && checkActivity() && document) initScroller();

    activity.current = 'paginate';
    setPageNumber(pageNumber + 1);
  };

  const handleGenerate = async () => {
    try {
      if (reportDate && userDetails) {
        const date = moment(reportDate).format('YYYY-MM-D');
        const response = await generateFleetReport(
          userDetails.organization.api_url,
          userDetails.organization.id,
          date,
        );
        notification.success({
          message: response?.msg,
        });
        pageNumber != 1 ? setPageNumber(1) : setRefresh(!refresh);
        setIsGenerate(!isGenerate);
      }
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    }
  };

  const handleYearChange = (dates: moment.Moment | null) => {
    if (dates) {
      activity.current = 'filter';
      setSelectedYear(dates.year());
      setPageNumber(1);
    } else {
      setSelectedYear(null);
    }
  };

  return (
    <>
      <div className="reportsTab">
        <div className="common_wrapper">
          <div className="filters_card">
            <CustomSelect
              label="Time Range"
              cssClass="ad_min_width"
              value={reportId}
              setValue={handleFilter}
              options={dateFilters}
              optionKey="value"
              optionDisplay="name"
            />
            <CustomYear
              label="Year"
              value={selectedYear}
              cssClass="min_width"
              onChange={handleYearChange}
            />
          </div>
          <div className="common_table">
            <InfiniteScrollTable
              columns={columns}
              hasMore={hasMore}
              filterData={filterData}
              totalcount={total}
              loading={loader}
              handleLoadMore={handleLoadMore}
              filename="Reports"
              onChange={handleChange}
            />
          </div>
        </div>

        <Modal
          title={t(translate.headers.generateReport)}
          visible={isGenerate}
          onOk={() => handleGenerate()}
          okText={t(translate.buttons.generate)}
          onCancel={() => setIsGenerate(false)}
        >
          <p>
            <span style={{ fontSize: '18px' }}>Select Date : </span>{' '}
            <DatePicker
              suffixIcon={<DownOutlined />}
              format="YYYY-MM-DD"
              value={reportDate}
              placeholder="Date"
              onChange={(date: any) => setReportDate(date)}
              className="dropdownStyle"
              disabledDate={(d) => !d || d.isAfter(new Date())}
            />
          </p>
        </Modal>
        <ReportRenderer
          selectedReport={selectedReport}
          closeModel={() => setShowPDFRenderer(false)}
          showModel={showPDFRenderer}
        />
      </div>
    </>
  );
};
export default ReportsDistribution;
