/* eslint-disable react/jsx-key */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Divider, Input, Select, Space, notification } from 'antd';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { ApplicationContext } from '../../../../context/AppContext';
import CustomSelect from '../../../common/CustomSelect';
import CustomInput from '../../../common/CustomInput';
import CustomInputNumber from '../../common/CustomInputNumber';
import CustomDatePicker from '../../common/CustomDatePicker';
import CustomSelectDivider from '../../common/CustomSelectDivider';
import { editWorkOrder, getLaborList, getPartList } from '../../API';

interface Props {
  closeModal: () => void;
  workOrderId: number;
  ticketDescription: string;
  resolveTicket: () => void;
  orderDetails: any;
  disabled: boolean;
}

const LaborAndPartsList: React.FC<Props> = ({
  closeModal,
  workOrderId,
  ticketDescription,
  resolveTicket,
  orderDetails,
  disabled,
}: Props) => {
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [laborList, setLaborList] = useState<any[]>([]);
  const [partList, setPartList] = useState<any[]>([]);
  const [laborDetails, setLaborDetails] = useState<any>(
    orderDetails?.work_order_labor_data,
  );
  const [partDetails, setPartDetails] = useState<any>(
    orderDetails?.work_order_parts_data,
  );
  const [taxRate, setTaxRate] = useState<number>(
    orderDetails?.tax_rate ? orderDetails?.tax_rate : '10.25',
  );
  const date1 =
    orderDetails && orderDetails?.work_order_promised_date
      ? new Date(orderDetails?.work_order_promised_date)
      : new Date();
  const [fromDate, setFromDate] = useState<Date>(date1);
  const [newModal, setnewModal] = useState<any>();
  const [userid, setUserId] = useState<any>(orderDetails?.qc_user_id?.id);

  useEffect(() => {
    const result = (obj: any) =>
      Object.keys(obj).every((item) => obj[item].toString().trim().length > 0);
    const res = laborDetails.every((item: any) => result(item));
    const res1 = partDetails.every((item: any) => result(item));
    const d =
      !disabled &&
      ticketDescription &&
      ticketDescription.trim().length > 0 &&
      fromDate &&
      res &&
      res1
        ? setIsDisabled(false)
        : setIsDisabled(true);
    return d;
  }, [
    ticketDescription,
    taxRate,
    fromDate,
    laborDetails,
    partDetails,
    disabled,
  ]);

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      getAllLabors();
    }
  }, [userDetails]);

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      getAllParts();
    }
  }, [userDetails]);

  const getAllLabors = async () => {
    try {
      const records = await getLaborList(userDetails.organization.id, '');
      records.map((ele: any) => {
        ele.name = `${ele.first_name} ${ele.last_name}`;
        return ele;
      });
      setLaborList(records);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    }
  };

  const getAllParts = async () => {
    try {
      const records = await getPartList();
      setPartList(records);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    }
  };

  const handleInput = (value: any, i: number, laborName: string) => {
    const newValues = [...laborDetails];
    newValues[i][laborName] = value;
    setLaborDetails(newValues);
  };

  const handleInput1 = (e: any, i: number) => {
    const newValues = [...laborDetails];
    newValues[i].labor_id = Number(e);
    setLaborDetails(newValues);
  };

  const handlePartsInput = (e: any, i: number) => {
    const newValues = [...partDetails];
    newValues[i].part_number = e;
    setPartDetails([...newValues]);
  };

  const additem = (e: any, i: number) => {
    const newValues = [...partDetails];
    newValues[i].part_number = e;
    setPartDetails([...newValues]);
    setPartList([...partList, { part_number: e }]);
    setnewModal('');
  };

  const handlePartsInput1 = (e: any, i: number, partName: string) => {
    const newValues = [...partDetails];
    newValues[i][partName] = Number(e.target.value) || e.target.value;
    setPartDetails(newValues);
  };

  const addFields = () => {
    if (laborDetails.length <= 9) {
      setLaborDetails([
        ...laborDetails,
        { labor_id: '', hours: '', labor_amount: 225 },
      ]);
    }
  };

  const addPartFields = () => {
    setPartDetails([
      ...partDetails,
      {
        part_number: '',
        part_name: '',
        quantity: '',
        price_per_unit: '',
        parts_amount: '',
      },
    ]);
  };

  const removeFields = (i: number) => {
    laborDetails.splice(i, 1);
    setLaborDetails([...laborDetails]);
  };

  const removePartFields = (i: number) => {
    partDetails.splice(i, 1);
    setPartDetails([...partDetails]);
  };

  const closeWorkOrder = async () => {
    const date = fromDate ? moment(fromDate).format('YYYY-MM-DD') : '';
    try {
      if (workOrderId) {
        const partListData = partDetails.map((item: any) => ({
          ...item,
          parts_amount: item.parts_amount.replace('$', ''),
        }));
        const payload = {
          status: 'completed',
          tractor_issue_resolved_description: ticketDescription,
          tax_rate: Number(taxRate),
          work_order_promised_date: date,
          labor_data_list: [...laborDetails],
          parts_data_list: [...partListData],
          qc_user_id: userid,
        };
        const response = await editWorkOrder(workOrderId, payload);
        closeModal();
        notification.success({ message: response.msg });
      }
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    } finally {
      resolveTicket();
    }
  };

  return (
    <>
      <div className="workOrderTxt">Work Order:</div>
      <div className="formRow">
        <div className="formCol">
          <div className="ant-row ant-form-item">
            <div className="ant-col ant-form-item-control">
              <div className="ant-form-item-control-input">
                <div className="ant-form-item-control-input-content input-align filters_card">
                  <CustomInputNumber
                    label="Tax Rate"
                    required
                    value={taxRate}
                    setValue={setTaxRate}
                    maxvalue={1000}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="formCol">
          <div className="ant-row ant-form-item">
            <div className="ant-col ant-form-item-control">
              <div className="ant-form-item-control-input">
                <div className="ant-form-item-control-input-content input-align filters_card">
                  <CustomDatePicker
                    label="Work Order Promised Date"
                    required
                    value={fromDate}
                    setValue={setFromDate}
                    className="dpicker dpickerWidth bgWhite"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="laborContent">
        <div className="laborPartDetails">Labor Description & Hours:</div>
        {laborDetails && laborDetails.length > 0 ? (
          laborDetails.map((ele: any, index: number) => (
            <div className="formRow laborForm">
              <div className="formCol">
                <div className="ant-col ant-form-item-control">
                  <div className="ant-form-item-control-input">
                    <div className="ant-form-item-control-input-content input-align filters_card mt10">
                      <CustomSelect
                        label="Select Labor"
                        cssClass="min_width"
                        value={ele?.labor_id}
                        setValue={(e: any) => handleInput1(e, index)}
                        options={laborList}
                        optionKey="id"
                        optionDisplay="name"
                        showSearch
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="formCol">
                <div className="ant-col ant-form-item-control">
                  <div className="ant-form-item-control-input">
                    <div className="ant-form-item-control-input-content input-align filters_card mt10">
                      <CustomInputNumber
                        label="Hrs"
                        value={ele?.hours}
                        setValue={(e: any) => handleInput(e, index, 'hours')}
                        maxvalue={99.99}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="formCol">
                <div className="ant-col ant-form-item-control">
                  <div className="ant-form-item-control-input">
                    <div className="ant-form-item-control-input-content input-align filters_card mt10">
                      <CustomInputNumber
                        label="Rate"
                        value={ele?.labor_amount}
                        setValue={(e: any) =>
                          handleInput(e, index, 'labor_amount')
                        }
                        maxvalue={99999}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="formCol mt10">
                <Button
                  className="ml12 deleteOutline"
                  shape="circle"
                  onClick={() => removeFields(index)}
                  icon={<DeleteOutlined />}
                />

                {laborDetails.length - 1 === index &&
                  laborDetails.length <= 9 && (
                    <>
                      {laborDetails[index].labor_id !== '' &&
                      laborDetails[index].hours !== '' ? (
                        <Button
                          className="ml12"
                          onClick={addFields}
                          shape="circle"
                          icon={<PlusOutlined />}
                        />
                      ) : (
                        <Button
                          className="ml12"
                          shape="circle"
                          disabled
                          icon={<PlusOutlined />}
                        />
                      )}
                    </>
                  )}
              </div>
            </div>
          ))
        ) : (
          <Button className="ml12" onClick={addFields}>
            Add Labor Details
          </Button>
        )}
      </div>

      <div className="laborContent">
        <div className="laborPartDetails mt5">Add Parts:</div>
        {partDetails && partDetails.length > 0 ? (
          partDetails.map((ele: any, index: number) => (
            <div className="formRow partForm">
              <div className="formCol">
                <div className="ant-col ant-form-item-control">
                  <div className="ant-form-item-control-input">
                    <div className="ant-form-item-control-input-content input-align filters_card mt10">
                      <CustomSelectDivider
                        label="Select Part Number"
                        value={ele?.part_number}
                        setValue={(e: any) => handlePartsInput(e, index)}
                        cssClass="min_width200"
                        options={partList}
                        optionKey="part_number"
                        optionDisplay="part_number"
                        onSearch={(e: any) => setnewModal(e)}
                        addItem={() => additem(newModal, index)}
                        newModal={newModal}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="formCol">
                <div className="ant-col ant-form-item-control">
                  <div className="ant-form-item-control-input">
                    <div className="ant-form-item-control-input-content input-align filters_card mt10">
                      {/* <Input
                        style={{ width: 135 }}
                        placeholder="Name"
                        name="part_name"
                        value={ele?.part_name}
                        onChange={(e: any) => handlePartsInput1(e, index)}
                      /> */}
                      <CustomInput
                        label="Name"
                        value={ele?.part_name}
                        setValue={(e: any) =>
                          handlePartsInput1(e, index, 'part_name')
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="formCol">
                <div className="ant-col ant-form-item-control">
                  <div className="ant-form-item-control-input">
                    <div className="ant-form-item-control-input-content input-align filters_card mt10">
                      <CustomInput
                        label="QTY"
                        value={ele?.quantity}
                        setValue={(e: any) =>
                          handlePartsInput1(e, index, 'quantity')
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="formCol">
                <div className="ant-col ant-form-item-control">
                  <div className="ant-form-item-control-input">
                    <div className="ant-form-item-control-input-content input-align filters_card mt10">
                      <CustomInput
                        label="Price Per Unit"
                        value={ele?.price_per_unit}
                        setValue={(e: any) =>
                          handlePartsInput1(e, index, 'price_per_unit')
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="partsAmount mt10">
                Total:{' '}
                {
                  (partDetails[index].parts_amount =
                    ele.quantity &&
                    ele.price_per_unit &&
                    `$${Math.round(ele.quantity * ele.price_per_unit)}`)
                }
              </div>

              <div className="formCol btnsRow mt10">
                <Button
                  shape="circle"
                  className="ml12 deleteOutline"
                  onClick={() => removePartFields(index)}
                  icon={<DeleteOutlined />}
                />
                {partDetails.length - 1 === index && (
                  <>
                    {partDetails[index].part_number !== '' &&
                    partDetails[index].part_name !== '' &&
                    partDetails[index].quantity !== '' &&
                    partDetails[index].price_per_unit !== '' ? (
                      <Button
                        className="ml12"
                        shape="circle"
                        onClick={addPartFields}
                        icon={<PlusOutlined />}
                      />
                    ) : (
                      <Button
                        className="ml12"
                        size="middle"
                        shape="circle"
                        icon={<PlusOutlined />}
                        disabled
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          ))
        ) : (
          <Button className="ml12" onClick={addPartFields}>
            Add Part Details
          </Button>
        )}
      </div>
      <div className="formRow mt20 laborForm filters_card">
        <div className="formCol">
          <div className="ant-row ant-form-item">
            <div className="ant-col ant-form-item-label">
              <label>QC Checkout</label>
            </div>
            <div className="ant-col ant-form-item-control">
              <div className="ant-form-item-control-input">
                <div className="ant-form-item-control-input-content input-align mt10">
                  <CustomSelect
                    label="Select QC Checkout"
                    cssClass="min_width200"
                    value={userid}
                    setValue={(id: any) => {
                      setUserId(id);
                    }}
                    options={laborList}
                    optionKey="id"
                    optionDisplay="name"
                    showSearch
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="forwardTicketButtons newForwardStyle">
        <Button className="fwdTcktCancelBtn" onClick={closeModal}>
          Cancel
        </Button>

        <Button
          disabled={isDisabled}
          type="primary"
          className="fwdTcktForwardBtn"
          onClick={closeWorkOrder}
        >
          Resolve
        </Button>
      </div>
    </>
  );
};

export default LaborAndPartsList;
