/* eslint-disable no-restricted-globals */
/* eslint-disable  @typescript-eslint/ban-types */
/* eslint-disable  no-unused-vars */
/* eslint-disable  no-useless-escape */

import React, { FC, useEffect, useState } from 'react';
import { Input } from 'antd';

interface InputProps {
  label: string;
  required?: boolean;
  value: any;
  setValue: (e: any) => void;
}

const CustomInput: FC<InputProps> = ({ label, required, value, setValue }) => {
  const [focus, setFocus] = useState(false);

  useEffect(() => {
    setFocus(false);
  }, [value]);

  const isEmpty = (val: any) => {
    if (typeof val === 'undefined') return true;
    if (typeof val === 'string' && val.trim() === '') return true;
    if (typeof val === 'number' && isNaN(val)) return true;
    return false;
  };

  const labelClass = focus || !isEmpty(value) ? 'label label-float' : 'label';

  return (
    <div
      className="float-label"
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      <Input value={value} onChange={setValue} type="text" />
      <label className={labelClass}>
        {label}{' '}
        {required && (
          <span style={{ color: 'red' }} className="float-span">
            *
          </span>
        )}
      </label>
    </div>
  );
};
export default CustomInput;
