/* eslint-disable no-console */
/* eslint-disable react/jsx-key */

import { Modal, notification, Select, Spin, Tag, Image, Table } from 'antd';
import 'antd/dist/antd.css';
import Layout from 'antd/lib/layout/layout';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import closeGreen from '../../assets/images/closeGreen.svg';
import FlagGrayIcon from '../../assets/images/flag_gray.svg';
import FlagOrangeIcon from '../../assets/images/flag_orange.svg';
import Assigned_to from '../../assets/images/assigned_to.png';
import FlagRedIcon from '../../assets/images/flag_red.svg';
import translate from '../../locale/en_translate.json';
import { getDateTimes } from '../../constants/Common';
import { getTicketDetailsById } from './services/api';
import { ApplicationContext } from '../../context/AppContext';
import errorCodeIcon from '../../assets/images/errorCodeIcon.svg';
import Interweave from 'interweave';
import { useErrorCodes } from '../../hooks/useErrorCodes';
import ResolveTicketModal from '../tickets/ResolveTicketModal';
import ForwardTicketModal from '../tickets/ForwardTicketModal';
import { privilagesConstants } from '../../constants/Privilages';
import tractorAnalyticDetailsStore from './store/tractorAnalyticDetails';
import useAnalyticsStore from './store/operationAnalytics';
import useTractorAnalyticsDetails from './hooks/useTractorAnalyticsDetails';

const { Option } = Select;

interface Props {
  ticketId: number;
  onCloseTicketWidget: () => void;
  toggleTicketWidget: boolean;
}
const OperationalAnalyticsTicketDetails: React.FC<Props> = ({
  ticketId,
  onCloseTicketWidget,
  toggleTicketWidget,
}: Props) => {
  const { privilegeChecker } = useContext(ApplicationContext);
  const { initAnalyticsData } = useTractorAnalyticsDetails();
  const { selectedTractor } = tractorAnalyticDetailsStore();
  const { selectedDate } = useAnalyticsStore();
  const { t } = useTranslation();
  const errorCodes = useErrorCodes();
  const { userDetails } = useContext(ApplicationContext);
  const [loader, setLoader] = useState<boolean>(false);
  const [ticketDetails, setTicketDetails] = useState<any>();
  const [errorcodeFlag, setErrorcodeFlag] = useState<boolean>(false);
  const [errCode, setErrCodes] = useState<any>();
  const [statusLabel, setStatusLabel] = useState<any>();
  const [forwardTicketModalView, setForwardTicketModel] =
    useState<boolean>(false);
  const [statusmodal, setStatusModal] = useState<boolean>(false);

  useEffect(() => {
    userDetails && userDetails.organization && ticketId && getTicketById();
  }, [userDetails, ticketId]);

  const initTickets = () => {
    const { implementName, tractorId } = selectedTractor;
    initAnalyticsData(
      selectedDate?.startOf('day')?.toDate()?.getTime(),
      selectedDate?.endOf('day')?.toDate()?.getTime(),
      implementName,
      tractorId,
    );
  };

  const getTicketById = async () => {
    try {
      setLoader(true);
      const result: any = await getTicketDetailsById(
        userDetails.organization.api_url,
        ticketId,
      );
      if (result.error_codes) {
        const commaData = result.error_codes.includes(',');
        const pipeData = result.error_codes.includes('|');
        if (commaData) {
          const error_codes = result.error_codes.split(',');
          const errordata = errorCodes.filter((x: any) =>
            error_codes.includes(x.indicator_error_code.toString()),
          );
          setErrCodes(errordata);
        } else if (pipeData) {
          const error_codes = result.error_codes.split('|');
          const errordata = errorCodes.filter((x: any) =>
            error_codes.includes(x.indicator_error_code.toString()),
          );
          setErrCodes(errordata);
        }
      }

      const dataarray: any[] = [];
      result?.Attachments?.map((ele: any, index: any) => {
        const obj = {
          uid: index.toString(),
          name: '',
          status: 'done',
          url: ele.image_url ? ele.image_url : '',
          id: ele.id,
          fleet_maintenance_log_id: ele?.fleet_maintenance_log_id,
          fleet_id: result?.fleet_id,
          index: index,
        };
        dataarray.push(obj);
      });
      if (result?.status === 'open' || result?.status === 'inprogress') {
        setStatusLabel('Unresolved');
      } else {
        setStatusLabel('Resolved');
      }
      if (result.assignee) {
        const { first_name, last_name } = result.assignee;
        result.assignee = `${first_name} ${last_name}`;
      }
      // if (result.created_by_user) {
      //   const { first_name, last_name } = result.created_by_user;
      //   result.created_by_user = `${first_name} ${last_name}`;
      // }
      // if (result.monarch_service_user) {
      //   const { first_name, last_name } = result.monarch_service_user;
      //   result.monarch_service_user = `${first_name} ${last_name}`;
      // }
      const fleetTags: any = [];
      result?.Tags?.map((tag: any) => {
        fleetTags.push(tag?.data_value);
      });
      result.tags = fleetTags;
      setTicketDetails(result);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    } finally {
      setLoader(false);
    }
  };

  const columns = [
    {
      title: 'Severity',
      dataIndex: 'level',
      key: 'level',
      width: '110px',
      render: (level: any) => {
        return (
          <div className="description">
            <img className="mr7" src={getSeverityIcon(level, 1)} />
            <img className="mr7" src={getSeverityIcon(level, 2)} />
            <img className="mr7" src={getSeverityIcon(level, 3)} />
          </div>
        );
      },
    },
    {
      title: 'Error Code',
      dataIndex: 'indicator_error_code',
      key: 'indicator_error_code',
      width: '120px',
      render: (indicator_error_code: any) => <>{indicator_error_code}</>,
    },
    {
      title: 'Indicator Types',
      width: '200px',
      dataIndex: 'indicator',
      key: 'indicator',
    },
    {
      title: 'Issues',
      width: '160px',
      dataIndex: 'subsystem',
      key: 'subsystem',
      render: (subsystem: string) => {
        return <span className="description">{subsystem}</span>;
      },
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: '200px',
      render: (description: string) => {
        return (
          <div className="description">
            <div className="diagnosticsdesc">{description}</div>
          </div>
        );
      },
    },
  ];

  const getSeverityIcon = (level: number, orderNumber: number) => {
    if (level >= 3) {
      return FlagRedIcon;
    }
    if (orderNumber <= level) {
      return FlagOrangeIcon;
    }
    return FlagGrayIcon;
  };

  const errorCodesLink = () => {
    setErrorcodeFlag(true);
  };

  const errorcodeCancel = () => {
    setErrorcodeFlag(false);
  };

  const forwaredTicketModal = () => {
    setForwardTicketModel(true);
  };
  const StatusOnChangeModal = () => {
    setStatusModal(true);
  };

  const resolveCancel = () => {
    // setDescriptionResolve('');
    setStatusModal(false);
  };

  const Close = (action = 'close') => {
    if (action !== 'close') {
      initTickets();
    }
    setStatusModal(false);
    getTicketById();
  };

  const forwardCancel = () => {
    setForwardTicketModel(false);
  };

  return (
    <Layout>
      <div
        className={
          'rightSideWidegt ' + (toggleTicketWidget ? 'expand' : 'hide')
        }
      >
        <div className="widgetCloseBtn flexBtn">
          <img
            src={closeGreen}
            alt="close icon"
            onClick={onCloseTicketWidget}
          />
        </div>
        {ticketId && ticketDetails ? (
          <>
            <div className="profileEdit widget_card">
              <div className="proRow tcktInfo">
                <h4 className="posRel errorTcktTitle headline3">
                  <span style={{ fontFamily: 'Montserrat-SemiBold' }}>
                    {t(translate.tickets.ticket)} #{ticketDetails?.ticket_id}
                  </span>
                </h4>
                <h3 className="overline1">
                  {getDateTimes(ticketDetails?.created_date_time)}
                </h3>
              </div>

              <div className="proRowType rowGapBtm3">
                <h5 className="subFlex alignCenter">
                  <>
                    <span className="headline4 subTitles">
                      {t(translate.headers.severityLevel)}
                    </span>
                    <span className="width50 body2">
                      <img
                        className="mr7"
                        src={getSeverityIcon(ticketDetails?.ticket_level, 1)}
                      />
                      <img
                        className="mr7"
                        src={getSeverityIcon(ticketDetails?.ticket_level, 2)}
                      />
                      <img
                        className="mr7"
                        src={getSeverityIcon(ticketDetails?.ticket_level, 3)}
                      />
                      {ticketDetails?.ticket_level === 1 && (
                        <>{t(translate.tickets.ticketSeverity.low)}</>
                      )}
                      {ticketDetails?.ticket_level === 2 && (
                        <>{t(translate.tickets.ticketSeverity.medium)}</>
                      )}
                      {ticketDetails?.ticket_level === 3 && (
                        <>{t(translate.tickets.ticketSeverity.high)}</>
                      )}
                    </span>
                  </>
                </h5>
              </div>

              <div className="proRowType rowGapBtm3">
                <h5 className="subFlex alignCenter">
                  <span className="headline4 subTitles">
                    {t(translate.fleetticketsHistory.status)}
                  </span>
                  <span className="width50 tickStatus">
                    {ticketDetails?.view_ticket_status === 'completed' ? (
                      <Tag
                        className="resolveStyle"
                        style={{ borderRadius: '12px' }}
                      >
                        {t(translate.tickets.status.resolved)}
                      </Tag>
                    ) : (
                      <>
                        {ticketDetails?.view_ticket_status === 'inprogress' ? (
                          <Tag className="inProgressStyle">
                            {' '}
                            {t(translate.tickets.status.inProgress)}
                          </Tag>
                        ) : (
                          <>
                            {ticketDetails?.view_ticket_status ===
                            'Forwarded' ? (
                              <Tag className="forwardStyle">
                                {ticketDetails?.view_ticket_status}
                              </Tag>
                            ) : (
                              <Tag color="red" className="openStyle">
                                {ticketDetails ? 'Open' : ''}
                              </Tag>
                            )}{' '}
                          </>
                        )}
                      </>
                    )}
                  </span>
                </h5>
              </div>

              {ticketDetails?.equipment ? (
                <div className="proRowType rowGapBtm3">
                  <h5 className="subFlex alignCenter">
                    <span className="headline4 subTitles">
                      {t(translate.tickets.equipment)}
                    </span>
                    <span className="width50 body2">
                      <label>{ticketDetails?.equipment?.name}</label>
                    </span>
                  </h5>
                </div>
              ) : (
                <div className="proRowType rowGapBtm3">
                  <h5 className="subFlex alignCenter">
                    <span className="headline4 subTitles">
                      {' '}
                      {t(translate.tickets.equipment)}
                    </span>
                    <span className="width50 body2">
                      <label>{t(translate.tickets.other)}</label>
                    </span>
                  </h5>
                </div>
              )}

              {
                <div className="proRowType rowGapBtm3">
                  <h5 className="subFlex alignCenter">
                    <span className="headline4 subTitles">
                      {t(translate.fleetticketsHistory.creator)}
                    </span>
                    <span className="width50 body2 subdesc">
                      {ticketDetails?.monarch_service_user ? (
                        <label>
                          {ticketDetails?.monarch_service_user?.first_name}{' '}
                          {ticketDetails?.monarch_service_user?.last_name}
                        </label>
                      ) : (
                        <label>
                          {ticketDetails?.created_by_user?.first_name}{' '}
                          {ticketDetails?.created_by_user?.last_name}
                        </label>
                      )}
                    </span>
                  </h5>
                </div>
              }

              {ticketDetails?.assignee && (
                <div className="proRowType rowGapBtm3">
                  <h5 className="subFlex alignCenter">
                    <span className="headline4 subTitles">
                      {t(translate.fleetticketsHistory.assignto)}
                    </span>
                    <span className="width50 body2 subdesc">
                      {ticketDetails?.is_service_ticket_raised ? (
                        <label className="status_value status_value_forward">
                          <img src={Assigned_to}></img>
                          {t(translate.tickets.defaultAssignee)}
                        </label>
                      ) : ticketDetails?.assignee ? (
                        <label>
                          {ticketDetails?.assignee?.first_name}{' '}
                          {ticketDetails?.assignee?.last_name}
                        </label>
                      ) : (
                        <label></label>
                      )}
                    </span>
                  </h5>
                </div>
              )}

              {ticketDetails?.ticket_description && (
                <div className="proRowType rowGapBtm3">
                  <h5 className="headline4">
                    {t(translate.tickets.description)}
                  </h5>
                  <div className="txtLftGap w100 body2 mb0">
                    <Interweave content={ticketDetails?.ticket_description} />
                  </div>
                </div>
              )}

              {ticketDetails?.Tags && ticketDetails?.Tags.length > 0 && (
                <div className="rowGapBtm3 tagsSec subFlex alignBaseline mb20">
                  <h4 className="mr11 headline4 mb15">
                    {t(translate.fleetticketsHistory.tags)}
                  </h4>
                  <div className="tagsBlk">
                    <div className="tags-container">
                      {ticketDetails?.Tags?.map((data: any) => {
                        return (
                          <div
                            className="tag-chip-selected body3 fMed"
                            key={data?.data_value}
                          >
                            <span>{data?.data_value}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}

              <div className="attachemtBlk rowGapBtm1">
                <h4 className="headline4 mb16">
                  {t(translate.tickets.attachements)}(
                  {ticketDetails?.Attachments?.length})
                </h4>
                <div className="videoImgStyle">
                  {ticketDetails &&
                    ticketDetails?.Attachments &&
                    ticketDetails?.Attachments?.map((ele: any, i: number) => {
                      return (
                        <Image
                          key={i}
                          src={ele?.image_url}
                          style={{
                            marginRight: 10,
                            marginBottom: 10,
                            width: 66,
                            height: 70,
                          }}
                        />
                      );
                    })}
                </div>
              </div>

              {errCode && errCode.length > 0 && (
                <div className="errorCodesBlk mb32 errorCodesList">
                  <h4 className="headline4">
                    {t(translate.tickets.errorCodes)}
                    <img
                      src={errorCodeIcon}
                      className="errorCodeIcon"
                      onClick={errorCodesLink}
                    />
                  </h4>

                  <table className="table proRoleValue">
                    <tbody>
                      {errCode &&
                        errCode.map((x: any, index: number) => {
                          return (
                            <tr key={index}>
                              <td>-</td>
                              <td>Error: {x?.indicator_error_code}</td>
                              <td>
                                <img
                                  className="mr8"
                                  src={getSeverityIcon(x?.level, 1)}
                                />
                                <img
                                  className="mr8"
                                  src={getSeverityIcon(x?.level, 2)}
                                />
                                <img src={getSeverityIcon(x?.level, 3)} />
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              )}

              <div className="create_ticket_btn_sec">
                {!loader && (
                  <div className="fwdBtnsSec">
                    {ticketDetails &&
                      statusLabel === 'Unresolved' &&
                      privilegeChecker(
                        privilagesConstants.Resolve_a_ticket_not_assigned_to_the_individual,
                      ) && (
                        <button
                          type="button"
                          className="ticket_resolve_btn"
                          onClick={StatusOnChangeModal}
                        >
                          {t(translate.tickets.resolveTicket)}
                        </button>
                      )}

                    {privilegeChecker(
                      privilagesConstants.Forward_a_ticket_to_Monarch,
                    ) &&
                      statusLabel === 'Unresolved' &&
                      ticketDetails &&
                      !ticketDetails.is_service_ticket_raised && (
                        <button
                          type="button"
                          className="ticket_fwd_btn addTicketBtn"
                          onClick={forwaredTicketModal}
                        >
                          {t(translate.tickets.forwardToMonarch)}
                        </button>
                      )}
                  </div>
                )}
                {!loader && statusLabel === 'Resolved' && (
                  <>
                    {ticketDetails && (
                      <button
                        type="button"
                        className="ticket_unresolved_btn"
                        onClick={StatusOnChangeModal}
                      >
                        {t(translate.tickets.unresolveTicket)}
                      </button>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="edit-loader">
              {loader && (
                <div className="loader">
                  <Spin size="large" />
                </div>
              )}
            </div>
          </>
        ) : (
          <div className="edit-loader">
            {loader && (
              <div className="loader">
                <Spin size="large" />
              </div>
            )}
          </div>
        )}
      </div>

      {forwardTicketModalView && (
        <ForwardTicketModal
          forwardCancel={forwardCancel}
          TickObj={ticketDetails}
          getTicketById={getTicketById}
          setTicketLoader={loader as any}
        />
      )}

      {statusmodal && (
        <ResolveTicketModal
          TickObj={ticketDetails}
          statusLabel={statusLabel}
          resolveCancel={resolveCancel}
          ticketObj={ticketDetails}
          setStatusModal={setStatusModal}
          SetStatusLable={setStatusLabel}
          getTicketById={getTicketById}
          Close={Close}
        />
      )}
      {errorcodeFlag && (
        <Modal
          title="Error Codes"
          visible={true}
          onCancel={errorcodeCancel}
          width={1200}
          maskClosable={false}
          bodyStyle={{ height: 'auto' }}
          footer={false}
          className="opPopup errorCodesModal"
        >
          <div className="tblDft farmTabsTbl hoverable dsTbl errorCodesTbl">
            <Table
              sticky={true}
              dataSource={errCode}
              columns={columns}
              pagination={false}
            />
          </div>
        </Modal>
      )}
    </Layout>
  );
};

export default OperationalAnalyticsTicketDetails;
