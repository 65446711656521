import React, { useContext, useEffect, useRef, useState } from 'react';
import { Row, Space, Tooltip, notification } from 'antd';
import Layout, { Content } from 'antd/lib/layout/layout';
import translate from '../../../locale/en_translate.json';
import InfiniteScrollTable from '../../common/InfiniteScrollTable';
import usePaginate from '../../../hooks/usePaginate';
import { ApplicationContext } from '../../../context/AppContext';
import { initScroller } from '../../../constants/Common';
import { getGoldBuildData } from '../api';
import { useTranslation } from 'react-i18next';
import { mapGoldBulidData } from '../Common';
import './style.css';

const GoldBuild = () => {
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [loader, setLoader] = useState<boolean>(false);
  const searchString = useRef<string>('');
  const [search, setSearch] = useState<string>('');
  const [total, setTotal] = useState<number>(0);
  const [pageSize] = useState<any>(25);
  const { filterData, setData, activity, hasMore, checkActivity } =
    usePaginate();
  //   const [sortInfo, setSortInfo] = useState<any>(null);
  const wrapResult = async (
    pageNumber: number,
    pageSize: number,
    search: string,
  ) => {
    const { organization } = userDetails;
    const result = await getGoldBuildData(
      organization.api_url,
      pageNumber,
      pageSize,
      search,
      //   sortInfo,
    );
    return {
      result,
      searchKey: search,
    };
  };

  const getGoldBuild = async () => {
    try {
      if (checkActivity()) initScroller();
      setLoader(true);
      const { result, searchKey } = await wrapResult(
        pageNumber,
        pageSize,
        search,
      );
      if (searchString.current !== searchKey) return;
      // eslint-disable-next-line no-underscore-dangle
      setTotal(result._metadata.total_records_count);
      const { records } = result;
      const data = mapGoldBulidData(records);
      setData(data);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    } finally {
      //   if (!init.current) init.current = true;
      setLoader(false);
    }
  };

  const loadTractors = () => {
    if (userDetails && !userDetails.organization) return;
    if (checkActivity()) {
      // eslint-disable-next-line no-unused-expressions
      pageNumber !== 1 ? setPageNumber(1) : getGoldBuild();
    } else if (activity.current === 'paginate' || activity.current === '') {
      getGoldBuild();
    }
  };

  const handleLoadMore = () => {
    if (pageNumber === 1 && checkActivity() && document) initScroller();

    activity.current = 'paginate';
    setPageNumber(pageNumber + 1);
  };

  const handleSearch = (e: any) => {
    activity.current = 'search';
    const value = e.target.value.trim();
    if (value.length <= 1) {
      searchString.current = '';
      setSearch('');
    } else {
      searchString.current = value;
      setSearch(value);
    }
  };

  useEffect(() => {
    loadTractors();
  }, [userDetails, pageNumber, pageSize, search]);

  const columnsData = [
    {
      title: `${t(translate.logViewer.softwareVersion)}`,
      dataIndex: 'software_version',
      key: 'software_version',
      width: '150px',
    },
    {
      title: `${t(translate.logViewer.createdTime)}`,
      dataIndex: 'created_date_time',
      key: 'created_date_time',
      width: '175px',
    },
    {
      title: `${t(translate.logViewer.releaseNotes)}`,
      dataIndex: 'comments',
      key: 'comments',
      width: '200px',
      render: (comments: string) => (
        <div className="swBuildInterWeave swGBInterWeave">
          <Tooltip title={comments}>
            <div className="description-text">{comments}</div>
          </Tooltip>
        </div>
      ),
    },
    {
      title: `${t(translate.logViewer.remarks)}`,
      dataIndex: 'remarks',
      key: 'remarks',
      ellipsis: true,
      width: '250px',
      render: (remarks: string) => (
        <Tooltip title={remarks}>
          <div className="description-text">{remarks}</div>
        </Tooltip>
      ),
    },
  ];

  return (
    <Layout className="posRel">
      <Content>
        <div className="tblContainer viewportContainer">
          <Row>
            <div className="common_wrapper wWidget_wrapper">
              {/* <div className="filters_card spaceBtnAlignCenter personnelCard ad_filters_card">
                <div className="displayFlex">
                  <Space size="large" />
                </div>
              </div> */}
              <div className="common_table">
                <InfiniteScrollTable
                  columns={columnsData}
                  loading={loader}
                  hasMore={hasMore}
                  filterData={filterData}
                  totalcount={total}
                  handleLoadMore={handleLoadMore}
                  filename="Gold Build"
                  //   onRowClick={(record) => getRow(record)}
                  //   onChange={handleChange}
                />
              </div>
            </div>
          </Row>
        </div>
      </Content>
    </Layout>
  );
};

export default GoldBuild;
