/* eslint-disable react/jsx-key */
import React, { useContext, useEffect, useState } from 'react';
import { RightOutlined } from '@ant-design/icons';
import { MenuContext } from '../../../context/MenuContext';
import { track } from '../../../util/logger';
import MediaWidget from './MediaWidget1';

import './style.css';

export const JumpAndGo = 'JumpAndGo';
export const SNAPSHOT = 'SNAPSHOT';
// export const JUMPANDGO = 'JUMPANDGO';

interface Props {
  showThumbnails: boolean;
  setRightSideThumbnailsToggle: (status: boolean) => void;
  // spinner: boolean;
}

const ThumbnailMenu: React.FC<Props> = ({
  showThumbnails,
  setRightSideThumbnailsToggle,
}: // spinner,
Props) => {
  const { collapsed } = useContext(MenuContext);

  const [, setMenuWidth] = useState(260);

  useEffect(() => {
    setMenuWidth(collapsed ? 80 : 260);
  }, [collapsed]);

  return (
    <>
      {showThumbnails ? (
        <div
          className={
            showThumbnails
              ? 'analyticsRightside newAnalytics expand'
              : 'analyticsRightside newAnalytics'
          }
        >
          <div className="dataLayerWrapper">
            <div
              className="dataLayerRowHead2"
              style={{
                position: 'absolute',
                left: '-20px',
                top: '5px',
              }}
            >
              <span
                onClick={() => {
                  track('Analytics Map', {
                    event: `${
                      showThumbnails ? 'Data Layer Closed' : 'Data Layer Opened'
                    }`,
                  });
                  setRightSideThumbnailsToggle(!showThumbnails);
                }}
                style={{ cursor: 'pointer' }}
              >
                <RightOutlined />
              </span>
            </div>

            <div className="datalayerContent2">
              <div className="anaJngTabSec">
                <div className="tbTabs anaJngTabs dbThumb">
                  <div className="thumbWraper noVideos">
                    {showThumbnails && <MediaWidget />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default ThumbnailMenu;
