/* eslint-disable no-console */
import React, { useCallback } from 'react';
import './style.css';
import BatteryBlack from '../../assets/images/batteryBlack1.svg';

interface Props {
  battery: any;
  hover?: boolean;
  charging?: string;
}

const Battery: React.FC<Props> = ({
  battery,
  hover = false,
  charging,
}: Props) => {
  const batteryIcon = useCallback(
    () => (
      <svg
        // xmlns="http://www.w3.org/2000/svg"
        width="16.469"
        height="8.235"
        viewBox="0 0 16.469 8.235"
      >
        <g id="battery" transform="translate(0)">
          <path
            id="Path_8878"
            data-name="Path 8878"
            d="M21.9,24.235H8.515A.516.516,0,0,1,8,23.72V16.515A.516.516,0,0,1,8.515,16H21.9a.516.516,0,0,1,.515.515V23.72A.516.516,0,0,1,21.9,24.235ZM9.029,23.205H21.381V17.029H9.029Z"
            transform="translate(-8 -16)"
            fill="#333"
          />
          <path
            id="Path_8879"
            data-name="Path 8879"
            d="M36.573,24.147H34.515A.516.516,0,0,1,34,23.632V19.515A.516.516,0,0,1,34.515,19h2.059a.516.516,0,0,1,.515.515v4.117A.516.516,0,0,1,36.573,24.147Zm-1.544-1.029h1.029V20.029H35.029Z"
            transform="translate(-20.619 -17.456)"
            fill="#333"
          />
          <rect
            id="Rectangle_4133"
            data-name="Rectangle 4133"
            width={battery / 10}
            height="4.641"
            transform="translate(2.06 1.797)"
            // fill="#333"
            fill={getcolor(battery)?.backgroundColor}
          />
        </g>
      </svg>
    ),
    [battery],
  );
  const getcolor = (energy: any) => {
    if (energy > 0) {
      const width = energy + '' + '%';
      let backgroundColor =
        energy > 25
          ? '#000'
          : energy <= 25 && energy > 10
          ? '#EC9322'
          : '#D33C39';
      if (hover) backgroundColor = '#67afb7';
      return {
        backgroundColor,
        width,
      };
    }
  };
  let style;
  if (hover) style = { borderColor: '#67afb7' };
  return (
    <>
      {charging === 'Charging' && battery !== 100 ? (
        <img
          className="indicatorImg mr5"
          // width="22px"
          // height="14px"
          // width="14px"
          // height="8px"
          src={BatteryBlack}
        />
      ) : (
        // <div className="battery-blk">
        //   <div className="charging-container" style={style}>
        //     <div className="battery-percentage" style={getcolor(battery)}></div>
        //   </div>
        // </div>
        <>{batteryIcon()}</>
      )}
    </>
  );
};

export default Battery;
