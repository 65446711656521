import { notification, Spin } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { useContext, useEffect, useState } from 'react';
import {
  createTask,
  getCategoryList,
  getImplementsList1,
  getLookpsList,
  getTasksearch,
  getTractorsListData,
  SaveOperationsPlanner,
} from '../../constants/Api';
import { ApplicationContext } from '../../context/AppContext';
import { Taskview, Taskviewdetails, TractorsList } from '../../constants/types';
import { track } from '../../util/logger';
import './style.css';
import { useTranslation } from 'react-i18next';
import translate from '../../locale/en_translate.json';
import { EditorState, convertToRaw } from 'draft-js';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import { fromToDateWrapper } from '../../constants/Common';
import logo from '../../assets/images/logo192.png';
import moment from 'moment';
import CustomInput from '../common/CustomInput';
import CustomSelect from '../common/CustomSelect';
import { TypesData } from '../../constants/AppData';
import CustomAutoComplete from '../common/CustomAutoComplete';
import CustomDate1 from '../common/CustomDate1';
interface Props {
  readonly visible: boolean;
  close: () => void;
  success: () => void;
  selectedDate: Date;
  selectedfromDate: Date;
}

const CreateTaskModel: React.FC<Props> = ({
  visible,
  close,
  success,
  selectedDate,
  selectedfromDate,
}: Props) => {
  const { userDetails } = useContext(ApplicationContext);
  const { t } = useTranslation();
  const [fromDate, setFromDate] = useState<any>(selectedDate);
  const [toDate, setToDate] = useState<any>(selectedfromDate);
  const [result, setResult] = useState<any[]>([]);
  const [resultOperator, setResultOperator] = useState<any[]>([]);
  const [title, setTitle] = useState('');
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const fullnamedetails = userDetails
    ? userDetails.first_name + ' ' + userDetails.last_name
    : '';
  const [autoValue, setAutoValue] = useState(fullnamedetails);
  const [autoid, setautoid] = useState(userDetails.id);
  const [opeatorName, setOpeatorName] = useState(fullnamedetails);
  const [operatorId, setOperatorId] = useState(userDetails.id);
  const [loader, setLoader] = useState<boolean>(false);
  const [type, setType] = useState();

  const [tractors, setTractorsData] = useState<TractorsList[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty(),
  );
  const [description, setDescription] = useState('');
  const [tractorName, setTractorName] = useState<any>();
  const [implementsList, setImplements] = useState<any[]>([]);
  const [selectedImplement, setselectedImplement] = useState();
  const [operationType, setOperationType] = useState();
  const [operationData, setOperationData] = useState<any[]>([]);
  const [recurrencetype, setrecurrencetype] = useState();
  const [recurrencetypeData, setrecurrencetypeData] = useState<any[]>([]);
  const [recurrflag, setRecurrflag] = useState<boolean>();
  const [rfromDate, setRFromDate] = useState<any>(selectedDate);
  const [rtoDate, setRToDate] = useState<any>(selectedfromDate);
  const [recurrencetypeName, setrecurrencetypeName] = useState<any>();

  useEffect(() => {
    if (
      tractorName &&
      fromDate &&
      toDate &&
      title.trim().length > 0 &&
      description
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [description, tractorName, fromDate, toDate, title]);

  const onDateRangeChange = (dates: any) => {
    if (dates) {
      const [from, to] = fromToDateWrapper(dates);
      setFromDate(from.toDate());
      setToDate(to.toDate());
    } else {
      setFromDate(null);
      setFromDate(null);
    }
  };

  const onDateRangeChangeRecurrence = (dates: any) => {
    if (dates) {
      const [from, to] = fromToDateWrapper(dates);
      setRFromDate(from.toDate());
      setRToDate(to.toDate());
    } else {
      setRFromDate(null);
      setRFromDate(null);
    }
  };

  const handleSubmit = async () => {
    try {
      setLoader(true);
      let raw: any = convertToRaw(editorState.getCurrentContent());
      raw = JSON.stringify(raw);
      if (raw && raw.length > 19999) {
        notification.error({
          message: 'Description maximum length exceeded',
        });
        return;
      }
      const payload: any = {
        event_title: title.trim(),
        event_description: description,
        event_start_time: fromDate.getTime(),
        event_finish_time: toDate.getTime(),
        event_type_lkp_id: 1,
        is_recurring: recurrflag,
        recurrence_pattern_lkp_id: recurrencetype,
        tractor_id: tractorName,
        operator_id: operatorId,
        supervisor_id: autoid ? autoid : null,
        implements_uuid: selectedImplement,
        operation_type: operationType,
        operation_mode: type,
        organization_id: userDetails.organization.id,
      };
      if (recurrencetype && recurrencetype !== 1) {
        (payload.recurrence_start_time = rfromDate.getTime()),
          (payload.recurrence_end_time = toDate.getTime());
      }

      const response: { msg: string } = await SaveOperationsPlanner(
        userDetails.organization.api_url,
        payload,
      );
      track('AddedTask', {
        event: 'Task Added Sucessfully',
      });
      notification.success({
        message: response.msg,
      });
      success();
    } catch (err: any) {
      track('AddedTaskFailed', {
        event: 'Task Added Failed',
      });
      notification.error({
        message: err.message,
      });
    } finally {
      setLoader(false);
    }
  };

  const handleSearch = async (value: any) => {
    if (value.length >= 3) {
      const taskviewData: Taskviewdetails = await getTasksearch(
        userDetails.organization.api_url,
        value,
      );
      if (taskviewData?.records?.length > 0) {
        taskviewData.records.forEach((ele: Taskview) => {
          ele.fullname = ele.first_name + ' ' + ele.last_name;
        });
        setResult(taskviewData.records);
      }
    } else if (value.length <= 3) {
      setResult([]);
    }
  };

  const handleSearchOperator = async (value: any) => {
    if (value.length >= 3) {
      const taskviewData: Taskviewdetails = await getTasksearch(
        userDetails.organization.api_url,
        value,
      );
      if (taskviewData?.records?.length > 0) {
        taskviewData.records.forEach((ele: Taskview) => {
          ele.fullname = ele.first_name + ' ' + ele.last_name;
        });
        setResultOperator(taskviewData.records);
      }
    } else if (value.length <= 3) {
      setResultOperator([]);
    }
  };
  const onSelect = (e: any) => {
    result.map((ele: any) => {
      if (ele.fullname === e) {
        setAutoValue(ele.fullname);
        setautoid(ele.id);
        setResult([]);
      }
    });
  };
  const onSelectOperator = (e: any) => {
    result.map((ele: any) => {
      if (ele.fullname === e) {
        setOpeatorName(ele.fullname);
        setOperatorId(ele.id);
        setResultOperator([]);
      }
    });
  };

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      getTractorsList();
      getImplementsListData();
      getProfileLookps();
      getrecurrencetype();
    }
  }, [userDetails]);

  const getProfileLookps = async () => {
    const cropsData = await getCategoryList(
      userDetails.organization.api_url,
      'operation_type',
    );
    setOperationData(cropsData);
  };

  const getrecurrencetype = async () => {
    const data = await getLookpsList(
      userDetails.organization.api_url,
      'recurrence-type',
    );
    setrecurrencetypeData(data);
    setrecurrencetype(data[0].id);
    setrecurrencetypeName(data[0].name);
    setRecurrflag(data[0].is_recurring);
  };

  const getImplementsListData = async () => {
    try {
      const records = await getImplementsList1(
        userDetails.organization.api_url,
        userDetails.organization.fleet.id,
      );
      records && records.length > 0
        ? setImplements(records)
        : setImplements([]);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    }
  };

  const getTractorsList = async () => {
    try {
      const tractor = await getTractorsListData(
        userDetails.organization.api_url,
        userDetails.organization_id,
        pageNumber,
      );
      const data =
        tractor && tractor.records && tractor.records.length > 0
          ? tractor.records
          : [];
      const tractorData = data.map((d: TractorsList) => {
        return {
          label: d.name,
          value: d.id,
          disabled: false,
          index: d.id,
        };
      });
      setTractorsData(tractorData);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    }
  };

  const onSelectTypes = (e: any) => {
    setType(e);
  };

  const onSelecttractor = (e: any) => {
    setTractorName(e);
  };
  const onSelectImplement = (e: any) => {
    setselectedImplement(e);
  };
  const onSelectOperation = (e: any) => {
    setOperationType(e);
  };
  const onSelectRecurrence = (e: any) => {
    const raw = JSON.parse(e);
    setrecurrencetype(raw.id);
    setRecurrflag(raw?.is_recurring);
    setrecurrencetypeName(raw?.name);
  };
  return (
    <Modal
      title={false}
      visible={visible}
      closable={false}
      className="tm-modal betaModal taskModal"
      onCancel={close}
      okText={t(translate.buttons.create)}
      onOk={handleSubmit}
      okButtonProps={{
        className: 'tm-button btnSave Button',
        disabled: isDisabled,
      }}
      cancelButtonProps={{
        className: 'btnCancel Button',
      }}
    >
      <div className="createtitle">
        <div className="pull-left">
          <h1 className="title headline1">Operation Planner</h1>
        </div>
        <div className="img-div pull-right">
          <img src={logo} />
        </div>
      </div>
      <div className="widget_card">
        <div className="formRow betaRow">
          <div className="formCol betaCol">
            <div className="ant-col ant-form-item-control">
              <div className="ant-form-item-control-input">
                <div
                  className="ant-form-item-control-input-content input-align"
                  data-testid="inputtitle-AddTask"
                >
                  <CustomInput
                    label="Title"
                    testId="inputImplementId-AddImplement"
                    value={title}
                    cssClass="width100per"
                    setValue={(value) => {
                      setTitle(value);
                    }}
                    required={true}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="formCol betaCol">
            <div className="ant-col ant-form-item-control">
              <div className="ant-form-item-control-input">
                <div className="ant-form-item-control-input-content input-align">
                  <CustomAutoComplete
                    label="Operator"
                    cssClass="min_width"
                    value={opeatorName}
                    setValue={onSelectOperator}
                    options={resultOperator}
                    optionKey="fullname"
                    searchmethod={handleSearchOperator}
                  ></CustomAutoComplete>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="formRow betaRow">
          <div className="formCol betaCol">
            <div className="ant-col ant-form-item-control">
              <div className="ant-form-item-control-input">
                <div className="ant-form-item-control-input-content input-align">
                  <CustomAutoComplete
                    label="Supervisor"
                    cssClass="min_width"
                    value={autoValue}
                    setValue={onSelect}
                    options={result}
                    optionKey="fullname"
                    searchmethod={handleSearch}
                  ></CustomAutoComplete>
                </div>
              </div>
            </div>
          </div>

          <div className="formCol betaCol">
            <div className="ant-col ant-form-item-control">
              <div className="ant-form-item-control-input">
                <div className="ant-form-item-control-input-content input-align">
                  <CustomSelect
                    label="Mode"
                    cssClass="min_width"
                    value={type}
                    setValue={onSelectTypes}
                    options={TypesData}
                    optionKey="name"
                  ></CustomSelect>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="formRow betaRow">
          <div className="formCol betaCol">
            <div className="ant-col ant-form-item-control">
              <div className="ant-form-item-control-input">
                <div className="ant-form-item-control-input-content input-align">
                  <CustomSelect
                    label="Operation Type"
                    value={operationType}
                    cssClass="min_width"
                    options={operationData}
                    optionDisplay="name"
                    optionKey="name"
                    setValue={onSelectOperation}
                  ></CustomSelect>
                </div>
              </div>
            </div>
          </div>

          <div className="formCol betaCol">
            <div className="ant-col ant-form-item-control">
              <div className="ant-form-item-control-input">
                <div className="ant-form-item-control-input-content input-align">
                  <CustomSelect
                    label="Implement"
                    cssClass="min_width"
                    value={selectedImplement}
                    setValue={onSelectImplement}
                    options={implementsList}
                    optionKey="config_implements_uuid"
                    optionDisplay="name"
                  ></CustomSelect>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="formRow betaRow">
          <div className="formCol betaCol">
            <div className="ant-col ant-form-item-control">
              <div className="ant-form-item-control-input">
                <div className="ant-form-item-control-input-content input-align">
                  <CustomSelect
                    label="Tractor"
                    value={tractorName}
                    cssClass="min_width"
                    options={tractors}
                    optionDisplay="label"
                    optionKey="value"
                    setValue={onSelecttractor}
                    required={true}
                  ></CustomSelect>
                </div>
              </div>
            </div>
          </div>

          <div className="formCol betaCol">
            <div className="ant-col ant-form-item-control">
              <div className="ant-form-item-control-input">
                <div className="ant-form-item-control-input-content input-align">
                  <CustomDate1
                    format="YYYY-MM-DD HH:mm A"
                    label=" Event Start Date - End Date"
                    cssClass=""
                    disabledDateCount={(d: any) => !d || d.isBefore(new Date())}
                    value={[
                      moment(fromDate, 'YYYY-MM-DD HH:mm A'),
                      moment(toDate, 'YYYY-MM-DD HH:mm A'),
                    ]}
                    onChange={onDateRangeChange}
                    showTimes={true}
                    clearIcon={false}
                    required={true}
                  ></CustomDate1>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="formRow betaRow">
          <div className="formCol betaCol">
            <div className="ant-col ant-form-item-control">
              <div className="ant-form-item-control-input">
                <div className="ant-form-item-control-input-content input-align">
                  <CustomSelect
                    label="Recurrence Type"
                    value={recurrencetypeName}
                    cssClass="min_width"
                    options={recurrencetypeData}
                    optionDisplay="name"
                    optionKey="id"
                    jsonFormat={true}
                    setValue={onSelectRecurrence}
                  ></CustomSelect>
                </div>
              </div>
            </div>
          </div>
          {recurrencetype && recurrencetype !== 1 && (
            <div className="formCol betaCol dPick">
              <div className="ant-col ant-form-item-control">
                <div className="ant-form-item-control-input">
                  <div className="ant-form-item-control-input-content input-align input-flex">
                    <CustomDate1
                      format="YYYY-MM-DD HH:mm A"
                      label="Recurrence Start Date - End Date"
                      cssClass=""
                      disabledDateCount={(d: any) =>
                        !d || d.isBefore(new Date())
                      }
                      value={[
                        moment(rfromDate, 'YYYY-MM-DD HH:mm A'),
                        moment(rtoDate, 'YYYY-MM-DD HH:mm A'),
                      ]}
                      onChange={onDateRangeChangeRecurrence}
                      showTimes={true}
                      clearIcon={false}
                    ></CustomDate1>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="formRow betaRow">
          <div className="formColW">
            <div className="ant-col ant-form-item-control">
              <div className="ant-form-item-control-input">
                <div
                  className="ant-form-item-control-input-content input-align"
                  data-testid="inputDescription-AddTask"
                  style={{
                    width: '100%',
                    overflowY: 'auto',
                    minHeight: 200,
                  }}
                >
                  <ReactQuill
                    style={{ height: 150 }}
                    value={description}
                    onChange={(e) => setDescription(e)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="edit-loader">
        {loader && (
          <div className="loader">
            <Spin size="large" />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default CreateTaskModel;
