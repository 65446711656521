/* eslint-disable no-console */
import { PubSub } from 'aws-amplify';

const options = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0,
};
let keyValue: string;
let dataValue: any;
const success = (pos: any, key: any, data: any): void => {
  const crd = pos.coords;
  publishTrackMsg(key, data, {
    latitude: crd.latitude,
    longitude: crd.longitude,
  });
};

const error = (key: any, data: any): void => {
  // publishTrackMsg({ latitude: 0, longitude: 0 });
  publishTrackMsg(key, data);
};

export const publishTrackMsg = (
  key: any,
  data: any,
  coordinates?: {
    latitude: number;
    longitude: number;
  },
): void => {
  // default "lat lon" = 43.8041, 120.5542
  const timestamp = new Date().getTime();
  const token = localStorage.getItem('auth');
  const width = window.innerWidth;
  const isMobile = width <= 768;
  try {
    let browserName;
    const userAgent = window.navigator.userAgent;
    if (userAgent.match(/chrome|chromium|crios/i)) {
      browserName = 'chrome';
    } else if (userAgent.match(/firefox|fxios/i)) {
      browserName = 'firefox';
    } else if (userAgent.match(/safari/i)) {
      browserName = 'safari';
    } else if (userAgent.match(/opr\//i)) {
      browserName = 'opera';
    } else if (userAgent.match(/edg/i)) {
      browserName = 'edge';
    } else {
      browserName = 'No browser detection';
    }
    // let userAgent = window.navigator.userAgent.toLowerCase(),
    const macosPlatforms = /(macintosh|macintel|macppc|mac68k|macos)/i;
    const windowsPlatforms = /(win32|win64|windows|wince)/i;
    const iosPlatforms = /(iphone|ipad|ipod)/i;
    let os = null;

    if (macosPlatforms.test(userAgent)) {
      os = 'macos';
    } else if (iosPlatforms.test(userAgent)) {
      os = 'ios';
    } else if (windowsPlatforms.test(userAgent)) {
      os = 'windows';
    } else if (/android/.test(userAgent)) {
      os = 'android';
    } else if (!os && /linux/.test(userAgent)) {
      os = 'linux';
    }

    const payload = {
      token,
      page_name: key,
      device_name: os, // os -> mac or windows
      device_type: isMobile ? 'MOBILE' : 'DESKTOP',
      browser_name: browserName,
      app_name: 'CUSTOMER-PORTAL',
      timestamp,
      user_location: {
        latitude: coordinates?.latitude || 0,
        longitude: coordinates?.longitude || 0,
      },
      location_type: coordinates?.latitude ? 'real' : 'default', // default or real
      event_on_page: key,
      error_message: '',
      key,
      data,
    };
    // console.log(JSON.stringify(payload));
    try {
      PubSub.publish('rum_data', payload);
    } catch (error) {
      // console.log(error);
    }
    // });
  } catch (error) {
    // console.log(error);
  }
};

export const track = (key: string, data: any): void => {
  keyValue = key;
  dataValue = data;
  try {
    navigator.geolocation.getCurrentPosition(
      (e) => {
        success(e, key, data);
      },
      () => {
        error(key, data);
      },
      options,
    );
  } catch (error) {
    // console.log(error);
  }
};
