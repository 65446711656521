import { Feature } from 'ol';
import VectorLayer from 'ol/layer/Vector';
import Cluster from 'ol/source/Cluster';
import VectorSource from 'ol/source/Vector';
import Point from 'ol/geom/Point';
import * as proj from 'ol/proj';
import {
  guardRailStyle,
  priority,
  styleSnapFun,
  styleFun,
  snapshotStyle,
} from '../../../constants/Common';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const drawGuardrails = (guardrailsData: any[]) => {
  if (!guardrailsData) return false;

  const guardrailFeatures: Feature<any>[] = [];
  const snapshotFeatures: Feature<any>[] = [];
  guardrailsData?.map((snapshot: any) => {
    if (snapshot && snapshot.latitude && snapshot.longitude) {
      const coordinates = [snapshot.latitude, snapshot.longitude];
      const name =
        snapshot.created_at === 'GUARD_RAILS' ? 'Guard Rails' : 'SNAPSHOT';
      const style =
        snapshot.created_at === 'GUARD_RAILS'
          ? guardRailStyle(1, false)
          : snapshotStyle(1, false);

      const feature = new Feature({
        geometry: new Point(proj.fromLonLat([coordinates[1], coordinates[0]])),
        name: name,
        pointType: 'SNAP',
        content: snapshot,
        style,
        point: [coordinates[1], coordinates[0]],
      });
      feature.setStyle(style);
      feature.set('style', style);
      feature.set('content', snapshot);
      feature.set(
        'type',
        snapshot.created_at === 'GUARD_RAILS' ? 'GUARDRAIL' : 'SNAPSHOT',
      );
      snapshot.created_at === 'GUARD_RAILS'
        ? guardrailFeatures.push(feature)
        : snapshotFeatures.push(feature);
    }
  });

  // snapshot features
  const snapshotSource = new VectorSource({
    features: snapshotFeatures,
  });
  const snapshotClusterSource = new Cluster({
    distance: 10,
    source: snapshotSource,
  });
  const snapshotLayer = new VectorLayer({
    source: snapshotClusterSource,
    zIndex: priority.SNAP,
    style: function (feature: any) {
      return styleSnapFun(feature);
    },
  });
  // gaurd rails features
  const guardrailSource = new VectorSource({
    features: guardrailFeatures,
  });
  const guardrailClusterSource = new Cluster({
    distance: 10,
    source: guardrailSource,
  });
  const guardrailsLayer = new VectorLayer({
    source: guardrailClusterSource,
    zIndex: priority.SNAP,
    style: function (feature: any) {
      return styleFun(feature);
    },
  });
  return { guardrailsLayer, snapshotLayer };
};

export default drawGuardrails;
