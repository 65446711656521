/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React, { useContext, useEffect, useState } from 'react';
import {
  Modal,
  Checkbox,
  Button,
  notification,
  Select,
  Col,
  Space,
  Input,
  Radio,
  RadioChangeEvent,
  Spin,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { ApplicationContext } from '../../../../context/AppContext';
import translate from '../../../../locale/en_translate.json';
import './style.css';
import { logViewerData, LogViewerDataIndex } from '../../common/types';
import { setItem } from '../../common/LStorage';
import LogDataIndexList from './LogDataIndexList';
import { getLogModelTypes, getModels } from '../../API';

interface Props {
  showModal: boolean;
  handleCancel: (
    // eslint-disable-next-line no-unused-vars
    selectedModel: string,
    // eslint-disable-next-line no-unused-vars
    selectedModels: LogViewerDataIndex[] | any[],
  ) => void;
  selModel: string;
  selModelId: Set<number>;
}
export const LogDataModel: React.FC<Props> = ({
  showModal,
  handleCancel,
  selModel,
  selModelId,
}: Props) => {
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [loader, setLoader] = useState<boolean>(false);
  const [dataModels, setDataModels] = useState<logViewerData[]>([]);
  const [models, setModels] = useState<any[]>([]);
  const [filterData, setFilter] = useState<any[]>([]);
  const [search, setSearch] = useState<string>('');
  const [selectedModel, setSelectedModel] = useState<string>('');
  const [selectedModelId, setSelectedModelId] = useState<Set<number>>(
    new Set<number>(),
  );
  const [selectedModels, setSelectedModels] = useState<any[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [favourite, setFavourite] = useState(false);

  useEffect(() => {
    if (selModel) {
      setSelectedModel(selModel);
    }
  }, [selModel]);

  useEffect(() => {
    if (selModelId) {
      setSelectedModelId(selModelId);
    }
  }, [selModelId]);

  useEffect(() => {
    localStorage.setItem('logFavourite', 'false');
  }, []);

  const onChange = (e: CheckboxChangeEvent) => {
    const item = e.target.value;
    item.visible = !e.target.checked;
    const sel: any = [];
    if (item.visible) {
      setSelectedModelId((prev) => {
        prev.delete(item.data_index);
        return prev;
      });
    } else {
      setSelectedModelId((prev) => {
        prev.add(item.data_index);
        return prev;
      });
    }
    const unSel: any[] = [];
    models.filter((modelItem: { data_index: number }) => {
      if (modelItem.data_index === item.data_index) {
        if (!item.visible) {
          sel.push(modelItem);
        } else {
          unSel.push(modelItem);
        }
      } else if (selectedModelId.has(modelItem.data_index)) {
        sel.push(modelItem);
      } else {
        unSel.push(modelItem);
      }
    });
    if (search === '') {
      setFilter(unSel);
      setSelectedModels(sel);
    } else if (search) {
      const filteredList = unSel.filter(
        (model: { data_name: string; data_index: number }) => {
          if (
            !selectedModelId.has(model.data_index) &&
            model.data_name &&
            model.data_name.toLowerCase().includes(search.toLocaleLowerCase())
          ) {
            return model;
          }
        },
      );
      setFilter(filteredList);
      setSelectedModels(sel);
    }
  };
  const onSelectedChange = (e: CheckboxChangeEvent) => {
    // filterValues('');
    const item = e.target.value;
    item.visible = !e.target.checked;
    setSelectedModelId((prev) => {
      prev.delete(item.data_index);
      return prev;
    });
    const sel: any[] = [];
    const unSel: any[] = [];
    models.forEach((selected: { visible: boolean; data_index: number }) => {
      if (
        item.data_index !== selected.data_index &&
        selectedModelId.has(selected.data_index)
      ) {
        // eslint-disable-next-line no-param-reassign
        selected.visible = true;
        sel.push(selected);
      } else {
        // eslint-disable-next-line no-param-reassign
        selected.visible = false;
        unSel.push(selected);
      }
    });
    if (search === '') {
      setFilter(unSel);
      setSelectedModels(sel);
    } else if (search) {
      const filteredList = unSel.filter(
        (model: { data_name: string; data_index: number }) => {
          if (
            model.data_name &&
            model.data_name.toLowerCase().includes(search.toLocaleLowerCase())
          ) {
            return model;
          }
        },
      );
      setFilter(filteredList);
      setSelectedModels(sel);
    }
  };

  useEffect(() => {
    if (selectedModels.length > 0) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [selectedModels]);

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      getModelTypesData();
    }
  }, [userDetails]);

  const getModelTypesData = async () => {
    try {
      setLoader(true);
      const response: any = await getLogModelTypes(
        userDetails.organization.api_url,
        'tractor',
      );
      setDataModels(response);
      if (selModel === '' && response && response.length > 0) {
        setSelectedModel(response[0].model_name);
      }
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (userDetails && userDetails.organization && selectedModel) {
      getModelsData();
    }
  }, [userDetails, selectedModel]);

  const getModelsData = async () => {
    setModels([]);
    setFilter([]);
    try {
      setLoader(true);
      const response: any[] = await getModels(
        userDetails.organization.api_url,
        selectedModel,
        'tractor',
      );
      const sel: any = [];
      const unSel: any = [];
      response.forEach((item: { visible: boolean; data_index: number }) => {
        if (selectedModelId.has(item.data_index)) {
          // eslint-disable-next-line no-param-reassign
          item.visible = true;
          sel.push(item);
        } else {
          unSel.push(item);
        }
      });
      setSelectedModels(sel);
      setModels(response);
      setFilter(unSel);
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    } finally {
      setLoader(false);
    }
  };

  const handleOk = async () => {
    handleCancel(selectedModel, selectedModels);
  };

  const handleCancelClick = async () => {
    handleCancel('', []);
  };

  const filterValues = (currValue: string) => {
    setSearch(currValue);
    const filteredList = models.filter(
      (model: { data_name: string; data_index: number }) => {
        if (
          !selectedModelId.has(model.data_index) &&
          model.data_name &&
          model.data_name.toLowerCase().includes(currValue.toLocaleLowerCase())
        ) {
          return model;
        }
      },
    );
    if (currValue) {
      setFilter(filteredList);
    } else {
      const filter: any[] = [];
      models.map((item: { visible: boolean; data_index: number }) => {
        if (!selectedModelId.has(item.data_index)) {
          // eslint-disable-next-line no-param-reassign
          item.visible = false;
          filter.push(item);
        }
      });
      setFilter(filter);
    }
  };

  const onAllSelectedChange = (e: CheckboxChangeEvent) => {
    setSelectAll(e.target.checked);
    if (e.target.checked) {
      setFilter([]);
      models.map((item: { visible: boolean; data_index: number }) => {
        // eslint-disable-next-line no-param-reassign
        item.visible = true;
        setSelectedModelId((prev) => {
          prev.add(item.data_index);
          return prev;
        });
      });
      setSelectedModels(models);
    } else {
      models.map((item: { visible: boolean; data_index: number }) => {
        // eslint-disable-next-line no-param-reassign
        item.visible = false;
        setSelectedModelId((prev) => {
          prev.delete(item.data_index);
          return prev;
        });
      });
      setFilter(models);
      setSelectedModels([]);
    }
  };

  return (
    <>
      <Modal
        className="addManual w800"
        title={t(translate.logViewer.dataItems)}
        centered
        closable={false}
        visible={showModal}
        onCancel={handleOk}
        footer={[
          <Button key="back" onClick={handleCancelClick} className="btnCancel">
            {t(translate.buttons.cancel)}
          </Button>,
          <Button
            key="submit"
            disabled={isDisabled}
            onClick={handleOk}
            className="ant-btn-primary"
          >
            {t(translate.buttons.add)}
          </Button>,
        ]}
      >
        <Space className="mb10">
          <Radio.Group
            onChange={(e: RadioChangeEvent) => {
              setFavourite(e.target.value);
              setItem('logFavourite', e.target.value);
            }}
            value={favourite}
          >
            <Radio className="tractorCheckBox" value={false}>
              {t(translate.logViewer.dataIndexes)}
            </Radio>
            <Radio className="tractorCheckBox" value>
              {t(translate.logViewer.favourites)}
            </Radio>
          </Radio.Group>
        </Space>

        {favourite ? (
          <LogDataIndexList
            handleDataIndexs={(model, indexs) => {
              setSelectedModel(model);
              setSelectedModels(indexs);
              // setSelectedModelId(new Set(indexs));
            }}
          />
        ) : (
          <>
            <div className="formRow">
              <Select
                style={{ width: 208 }}
                defaultValue="Data model"
                value={selectedModel}
                onSelect={(model: string) => {
                  setSelectedModel(model);
                  setSelectedModelId(new Set([]));
                  setSelectAll(false);
                }}
              >
                {dataModels.map((model: any) => (
                  <option key={model.model_name} value={model.model_name}>
                    {model.model_name}
                  </option>
                ))}
              </Select>
              <Space
                className="mt0"
                style={{ marginBottom: 16, position: 'relative' }}
              >
                <Input
                  className="search-box"
                  placeholder="Search data indexes"
                  prefix={<SearchOutlined />}
                  onChange={(e) => filterValues(e.target.value)}
                />
              </Space>
            </div>
            <div className="models-list">
              <Space
                className="mt0"
                style={{ margin: 10, position: 'relative' }}
              >
                {' '}
                {selectedModel &&
                  selectedModel !== 'SmartScreen' &&
                  selectedModel !== 'Debug' &&
                  selectedModel !== 'Calibration' &&
                  selectedModel !== 'XavierLogs' &&
                  selectedModel !== 'CANBusDown' &&
                  selectedModel !== 'RosCanLogs' &&
                  !selectAll && (
                    <>
                      {t(translate.logViewer.dataIndexes)}
                      <Checkbox
                        className="tractorCheckBox"
                        checked={selectAll}
                        onChange={onAllSelectedChange}
                      >
                        {t(translate.logViewer.selectAll)}
                      </Checkbox>
                    </>
                  )}
              </Space>
              <br />
              {filterData && filterData.length > 0
                ? filterData.map((listItem) => (
                    <>
                      <Col span={24} style={{ padding: '5px 2px' }}>
                        <Checkbox
                          // eslint-disable-next-line react/no-array-index-key
                          key={listItem.data_index}
                          defaultChecked={listItem.visible}
                          value={listItem}
                          onChange={onChange}
                          className="mapCheckbox"
                        >
                          {listItem?.data_name}
                        </Checkbox>
                      </Col>
                    </>
                  ))
                : selectedModels && selectedModels.length > 0
                ? ''
                : 'No data indexes available. '}
              {selectedModel &&
                (selectedModel === 'SmartScreen' ||
                  selectedModel === 'RosCanLogs' ||
                  selectedModel === 'Calibration' ||
                  selectedModel !== 'XavierLogs') && (
                  <span>{t(translate.logViewer.addToGenerateLogFiles)}</span>
                )}
            </div>
            <div
              className="models-list"
              style={{
                right: 0,
                top: 180,
                position: 'absolute',
              }}
            >
              <Space
                className="mt0"
                style={{ margin: 10, position: 'relative' }}
              >
                {' '}
                {t(translate.logViewer.selectedDataIndexes)}{' '}
                {selectedModel &&
                  selectedModel !== 'SmartScreen' &&
                  selectedModel !== 'Calibration' &&
                  selectedModel !== 'XavierLogs' &&
                  selectedModel !== 'CANBusDown' &&
                  selectedModel !== 'RosCanLogs' &&
                  selectAll && (
                    <Checkbox
                      className="tractorCheckBox"
                      checked={selectAll}
                      onChange={onAllSelectedChange}
                    >
                      {t(translate.logViewer.unselectAll)}
                    </Checkbox>
                  )}
              </Space>

              {selectedModels && selectedModels.length > 0
                ? selectedModels.map((listItem) => (
                    <>
                      <Col span={24} style={{ padding: '5px 2px' }}>
                        <Checkbox
                          // eslint-disable-next-line react/no-array-index-key
                          key={listItem.data_index}
                          defaultChecked
                          value={listItem}
                          onChange={onSelectedChange}
                          className="mapCheckbox"
                        >
                          {listItem?.data_name}
                        </Checkbox>
                      </Col>
                    </>
                  ))
                : null}
            </div>
          </>
        )}
        <div className="edit-loader">
          {loader && (
            <div className="loader1">
              <Spin size="large" />
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};
