/* eslint-disable no-console */
import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  ArrowLeftOutlined,
  SearchOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { notification, Pagination, Space, Tabs, Tooltip } from 'antd';
import {
  durationData,
  getAnalyticFormat,
  getHour,
  isEmpty,
  rangeList,
  sortAOByKey,
} from '../../../constants/Common';
import FleetMap from '../Map/FleetMap';
import { AnalyticsContext } from '../AnalyticsContext';
import {
  getAllAnalysticsTractors,
  getJumpAndGoAllDay,
  getOperators,
} from '../../../constants/Api';
import { TractorsList, AllDayContent } from '../../../constants/types';
import { ApplicationContext } from '../../../context/AppContext';
import moment from 'moment';
import AnalyticChart from '../common/AnalyticChart';
import CustomRangePicker from '../../common/CustomRangePicker';
import { getJumpandGo } from './util';
import {
  RESET,
  SET_OPERATIONS,
  SET_OPERATION_FILTER,
  SET_OPERATION_FILTER_LABEL,
  SET_SELECTED_JAG,
} from '../action';
import '../Database/database.css';
import AnalyticsStats from '../common/AnalyticsStat';
import AppLoader from '../../common/AppLoader';
import translate from '../../../locale/en_translate.json';

const { TabPane } = Tabs;
interface Props {
  toggleLiveMap: () => void;
}

const AnalyticsDashboard: React.FC<Props> = ({ toggleLiveMap }) => {
  const { t } = useTranslation();
  const { analyticReducer } = useContext(AnalyticsContext);
  const { userDetails } = useContext(ApplicationContext);
  const [state, dispatch] = analyticReducer;
  const [selectedDateRange, setSelectedDateRange] = useState([
    moment().subtract(1, 'day').toDate(),
    moment().toDate(),
  ]);
  const [activeData, setActiveData] = useState({
    active: false,
    tab: 0,
  });
  const [operators, setOperators] = useState<any>([]);
  const [allDayOperation, setAllDayOperation] =
    useState<AllDayContent | null>();
  const [tractors, setTractors] = useState<any[]>([]);
  const [toggleDatePicker, setToggleDatePicker] = useState<boolean>(false);
  const { operationFilter, operationFilterLabel, operations, isLoading } =
    state;
  const [selectFilter, setSelectFilter] = useState<any>();
  const initDatePicker = useRef<boolean>(false);
  const [showLoader, setShowLoader] = useState<boolean>(true);
  const [selectRange] = useState<any[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize] = useState<any>(10);
  const [totalCount, setTotalCount] = useState<number>(0);

  const handleOnClick = (e: number) => {
    if (e !== 3) setToggleDatePicker(false);
    setActiveData({
      ...activeData,
      active: e ? !activeData.active : false,
      tab: !activeData.active ? e : e === 3 ? 3 : 0,
    });
  };
  useEffect(() => {
    localStorage.setItem('operationFilter', JSON.stringify(operationFilter));
  }, [operationFilter]);

  const getTractorsList = async () => {
    try {
      const tractors: TractorsList[] = await getAllAnalysticsTractors(
        userDetails.organization.api_url,
        userDetails.organization_id,
      );
      const data = tractors && tractors.length > 0 ? tractors : [];
      setTractors(data);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    }
  };

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      getTractorsList();
    }
  }, [userDetails]);

  const getAllDayContent = async () => {
    setAllDayOperation(null);
    setShowLoader(true);
    try {
      const { date, tractorId } = operationFilter;
      const [fromDate, toDate] = date;
      const jngResponse = await getJumpAndGoAllDay(
        userDetails.organization.api_url,
        userDetails.organization_id,
        tractorId,
        moment(fromDate).startOf('day').toDate().getTime(),
        moment(toDate).endOf('day').toDate().getTime(),
      );
      setAllDayOperation(jngResponse);
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  useEffect(() => {
    if (userDetails && userDetails.organization && operationFilter) {
      setSelectFilter({ ...operationFilter });
      const { date } = operationFilter;
      if (date) handleSelectDateRange(date);
      getAllDayContent();
      loadOperations();
    }
  }, [operationFilter, userDetails]);

  useEffect(() => {
    const isValid = selectedDateRange.every((item) => item != null);
    if (!isValid) return;
    loadOperators();
  }, [selectedDateRange]);

  const handleSelectDateRange = (dates: any[]) => {
    let [date1, date2] = dates;
    date1 = date1 && moment(date1).startOf('day').toDate();
    date2 = date2 && moment(date2).endOf('day').toDate();
    // if (date1 && date2 && date1.getTime() < date2.getTime()) {
    setSelectedDateRange([date1, date2]);
    handleSelect('date', [date1, date2]);
    // } else {
    //   setSelectedDateRange([date1, date2]);
    //   handleSelect('date', [date1, date2]);
    // }
  };

  const loadOperations = async () => {
    try {
      const { date, tractorId, operatorId, distance, duration } =
        operationFilter;
      let tdistance = null;
      if (distance && distance[1]) tdistance = distance.join(',');
      dispatch({
        type: SET_SELECTED_JAG,
        payload: null,
      });
      const { records, _metadata } = await getJumpandGo(
        userDetails,
        moment(date[0]).startOf('day').toDate().getTime(),
        moment(date[1]).endOf('day').toDate().getTime(),
        tractorId,
        operatorId,
        duration,
        tdistance,
        pageNumber,
        pageSize,
      );
      setTotalCount(_metadata.total_records_count);
      const data = records && records.length ? records : [];
      dispatch({
        type: SET_OPERATIONS,
        payload: data,
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const handleSearch = () => {
    const isValid = selectedDateRange.every((item) => item != null);
    if (!isValid) return;
    dispatch({
      type: SET_OPERATION_FILTER,
      payload: selectFilter,
    });
  };

  const validHour = (timestamp: any) => {
    const hour = getHour(timestamp);
    return hour === 'Invalid date' ? '  ' : hour;
  };

  const loadOperators = async () => {
    if (!userDetails.organization) return;
    const result = await getOperators(
      userDetails.organization.api_url,
      moment(selectedDateRange[0]).startOf('day').toDate().getTime(),
      moment(selectedDateRange[1]).endOf('day').toDate().getTime(),
    );
    if (result && result[1] === 404) return;
    const members =
      result &&
      result.map((ele: any) =>
        Object.assign({
          id: ele.id,
          name: ele.first_name + ' ' + ele.last_name,
        }),
      );
    setOperators(members);
  };

  useEffect(() => {
    if (selectFilter && !isEmpty(selectFilter)) {
      const { operatorId, duration, tractorId, date, distance } = selectFilter;
      let distanceStr = distance[0] ? `${distance[0]} ` : ' ';
      distanceStr += distance[1] ? ` - ${distance[1]} miles` : 'miles';
      if (!distance[1]) distanceStr = 'milles';
      const [operator]: any = operators.filter(
        (item: any) => item.id == operatorId,
      );
      const [tractor] = tractors.filter((item: any) => item.id == tractorId);
      const [duration1] = durationData.filter(
        (item: any) => item.value == duration,
      );
      dispatch({
        type: SET_OPERATION_FILTER_LABEL,
        payload: {
          durationLabel: duration1 ? duration1?.label : '',
          operatorLabel: operator ? operator.name : '',
          tractorLabel: tractor ? tractor?.name : '',
          dateLabel: getAnalyticFormat(date),
          distanceLabel: distanceStr,
        },
      });
      setPageNumber(1);
    }
  }, [selectFilter, tractors, operators]);

  const handleSelect = (key: string, value: string | number | any) => {
    handleOnClick(0);
    initDatePicker.current
      ? setSelectFilter({
          ...selectFilter,
          [key]: value,
        })
      : setSelectFilter({
          ...operationFilter,
          [key]: value,
        });
    initDatePicker.current = true;
  };

  const reset = () => {
    setOperators([]);
    setTractors([]);
    setAllDayOperation(null);
    setSelectFilter({});
    setActiveData({
      active: false,
      tab: 0,
    });
    dispatch({
      type: RESET,
    });
  };

  useEffect(() => {
    if (isLoading) {
      loadOperations();
    }
  }, [pageNumber]);

  // const frangeList = rangeList.filter((item) => item > selectRange[0]);

  return (
    <>
      <div className="personnelSec fileUploadBack sprayOper">
        <ArrowLeftOutlined
          data-testid="backArrowIcon-AnalyticsDashboard"
          onClick={() => {
            toggleLiveMap();
            reset();
          }}
        />
        <div className="sprayTabMenu">
          <ul
            className={
              activeData.active
                ? `sprayUlList sprayActive cursor`
                : `sprayUlList cursor`
            }
          >
            <li
              className={
                activeData.tab === 1 ? `operationTxt sparyItemActive` : ``
              }
              data-testid="searchDuration-AnalyticsDashboard"
              onClick={(e) => {
                e.stopPropagation();
                handleOnClick(1);
              }}
            >
              <span className="titleTxt">
                {t(translate.analyticContainer.duration)}
              </span>{' '}
              {operationFilterLabel.durationLabel
                ? operationFilterLabel.durationLabel
                : 'Duration'}
              <div
                className="operationsDropdown"
                style={{ display: activeData.tab === 1 ? 'block' : 'none' }}
              >
                <h6>{t(translate.analyticContainer.duration)}</h6>
                <div className="operationDroplistBlk">
                  <div className="operationDroplistInrBlk">
                    {durationData.map((item, key) => {
                      return (
                        <div
                          data-testid={`${item.label}-AnalyticsDashboard`}
                          className="operationContainer"
                          key={key}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleSelect('duration', item.value);
                          }}
                        >
                          <div className="operationalHours">
                            <div className="herbSprayStyle">{item?.label}</div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <h5
                  onClick={(e) => {
                    e.stopPropagation();
                    handleSelect('duration', 0);
                  }}
                  data-testid="durationSelectAll-AnalyticsDashboard"
                >
                  {t(translate.buttons.selectAll)}
                </h5>
              </div>
              {/* <span className="titleTxt">Distance</span>{' '}
              {operationFilterLabel.distanceLabel
                ? operationFilterLabel.distanceLabel
                : 'Distance'}
              <div
                className="operationsDropdown"
                style={{ display: activeData.tab === 1 ? 'block' : 'none' }}
                onClick={(e) => e.stopPropagation()}
              >
                <h6>Distance</h6>
                <div className="operationDroplistBlk">
                  <div className="operationDroplistInrBlk">
                    <div className="mb10">
                      <h3>From</h3>
                      <Select
                        onSelect={(e) => setSelectRange([Number(e), undefined])}
                        value={selectRange[0]}
                        placeholder="Select Miles"
                        className="w100"
                      >
                        {rangeList.map((value, i) => {
                          return (
                            <Option key={i} value={Number(value)}>
                              {value} miles
                            </Option>
                          );
                        })}
                      </Select>
                    </div>
                    <div>
                      <h3>To</h3>
                      <Select
                        onSelect={(e) => {
                          setSelectRange([selectRange[0], Number(e)]);
                          handleOnClick(1);
                          handleSelect('distance', [selectRange[0], Number(e)]);
                        }}
                        placeholder="Select Miles"
                        value={selectRange[1]}
                        className="w100"
                      >
                        {frangeList.map((value, i) => {
                          return (
                            <Option key={i} value={Number(value)}>
                              {value} miles
                            </Option>
                          );
                        })}
                      </Select>
                    </div>
                  </div>
                </div>
                <h5
                  onClick={(e) => {
                    e.stopPropagation();
                    handleSelect('distance', [0, 0]);
                    setSelectRange([undefined, undefined]);
                  }}
                >
                  Clear all
                </h5>
              </div> */}
            </li>
            <li
              className={
                activeData.tab === 2
                  ? `operationTxt sparyItemActive`
                  : `operationTxt`
              }
              data-testid="searchOperators-AnalyticsDashboard"
              onClick={(e) => {
                e.stopPropagation();
                handleOnClick(2);
              }}
            >
              <span className="titleTxt">
                {t(translate.analyticContainer.operatorName)}
              </span>{' '}
              {operationFilterLabel.operatorLabel
                ? operationFilterLabel.operatorLabel
                : 'All Operators'}
              <div
                className="operationsDropdown"
                style={{ display: activeData.tab === 2 ? 'block' : 'none' }}
              >
                <h6>{t(translate.analyticContainer.operators)}</h6>
                <div className="operationDroplistBlk">
                  <div className="operationDroplistInrBlk">
                    {operators.length > 0 &&
                      operators.map((operator: any, key: number) => {
                        return (
                          <div
                            className="operationContainer"
                            key={key}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleSelect('operatorId', operator.id);
                            }}
                          >
                            <div className="operationalHours">
                              <div className="herbSprayStyle">
                                {operator?.name}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
                <h5
                  onClick={(e) => {
                    e.stopPropagation();
                    handleSelect('operatorId', 0);
                  }}
                  data-testid="selectAllOperators-AnalyticsDashboard"
                >
                  {t(translate.buttons.selectAll)}
                </h5>
              </div>
            </li>
            <li
              className=""
              data-testid="searchDate-AnalyticsDashboard"
              onClick={(e) => {
                e.stopPropagation();
                if (!toggleDatePicker) handleOnClick(3);
                if (!toggleDatePicker) setToggleDatePicker(true);
              }}
            >
              <span className="titleTxt">
                {t(translate.analyticContainer.dates)}
              </span>
              {getAnalyticFormat(selectedDateRange)}
              <div
                className="operationsDropdown"
                style={{
                  display: toggleDatePicker ? 'block' : 'none',
                }}
              >
                <div style={{ width: '180px' }}>
                  <CustomRangePicker
                    onChange={(dates: any) => {
                      handleSelectDateRange(dates);
                      setToggleDatePicker(false);
                    }}
                    open={toggleDatePicker}
                    format="MMM DD, YY"
                  />
                </div>
              </div>
            </li>
            <li
              className="operationTxt"
              data-testid="searchTractors-AnalyticsDashboard"
              style={{ width: '150px' }}
              onClick={() => {
                handleOnClick(4);
              }}
            >
              <span className="titleTxt">
                {t(translate.analyticContainer.tractors)}
              </span>
              {operationFilterLabel?.tractorLabel
                ? operationFilterLabel?.tractorLabel
                : 'All Tractors'}
              <div
                className="operationsDropdown"
                style={{ display: activeData.tab === 4 ? 'block' : 'none' }}
              >
                <h6>{t(translate.analyticContainer.tractors)}</h6>
                <div className="operationDroplistBlk">
                  <div className="operationDroplistInrBlk">
                    {tractors.length &&
                      tractors.map((tractor, key) => {
                        return (
                          <div
                            data-testid={`${tractor.name}-AnalyticsDashboard`}
                            className="operationContainer"
                            key={key}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleSelect('tractorId', tractor.id);
                            }}
                          >
                            <div className="operationalHours">
                              <div className="herbSprayStyle">
                                {tractor?.name}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
                <h5
                  onClick={(e) => {
                    e.stopPropagation();
                    handleSelect('tractorId', 0);
                  }}
                >
                  {t(translate.buttons.selectAll)}
                </h5>
              </div>
            </li>
            <li
              style={{ position: 'absolute', right: '10px' }}
              onClick={(e) => {
                e.stopPropagation();
                handleSearch();
              }}
            >
              <SearchOutlined
                className="searchSprayIcon"
                data-testid="searchOpertAnalytics-AnalyticsDashboard"
              />
            </li>
          </ul>
        </div>
      </div>
      <div className="analytics-pagination">
        <Pagination
          defaultCurrent={pageNumber}
          defaultPageSize={pageSize}
          total={totalCount}
          current={pageNumber}
          pageSize={pageSize}
          onChange={(page) => isLoading && setPageNumber(page)}
        />
      </div>
      <div className="sprayContainer">
        <div className="flexColumn tractorOperDtls">
          <div
            className="pieChartStyle mb10 piechartBlk1"
            style={{ position: 'relative' }}
          >
            <div
              className="sprayTxt"
              data-testid="searchHeader-AnalyticsDashboard"
            >
              {t(translate.analyticContainer.operationAnlaytics)}
            </div>

            {allDayOperation && operations?.length > 0 && (
              <AnalyticChart allDayOperation={allDayOperation} />
            )}
            <AnalyticsStats allDayOperation={allDayOperation} />
          </div>
          <div
            className="pieChartStyle sprayoperlist"
            style={{
              position: 'relative',
              paddingTop: 0,
            }}
          >
            <Tabs>
              <TabPane
                tab={
                  <Space className="cursorDefault">
                    <div>{t(translate.analyticContainer.operations)}</div>
                    <div className="tabNor">
                      {operations && operations.length} ({totalCount})
                    </div>
                  </Space>
                }
                key="Operations"
              >
                <>
                  {operations &&
                    operations.length > 0 &&
                    sortAOByKey(operations, 'duration_in_seconds')
                      // .filter((data: any) => data.drive_action_finish_date_time)
                      .map((jng: any, key: number) => (
                        <div
                          className="operationContainer"
                          key={key}
                          onClick={() =>
                            dispatch({
                              type: SET_SELECTED_JAG,
                              payload: jng,
                            })
                          }
                        >
                          <div className="operationalHours">
                            <div className="herbSprayStyle">
                              {jng?.tractor?.name}
                            </div>
                          </div>
                          <div className="dateStyle">
                            {/* <div className="dateStyle">Tue, 6/21 - Thu, 6/23</div> */}
                          </div>
                          <div className="dateStyle">
                            {validHour(jng?.drive_action_start_date_time) +
                              ' - ' +
                              validHour(jng?.drive_action_finish_date_time) +
                              ' | ' +
                              moment(jng?.drive_action_start_date_time).format(
                                'MMM Do',
                              )}
                            <span style={{ marginLeft: 10 }}>
                              {jng?.report_status === 'PENDING' && (
                                <Tooltip title="Report Generation Pending">
                                  {' '}
                                  <WarningOutlined
                                    style={{ color: '#EC9322' }}
                                  />{' '}
                                </Tooltip>
                              )}
                            </span>
                          </div>
                        </div>
                      ))}
                </>
              </TabPane>
            </Tabs>
            <AppLoader loader={showLoader} />
          </div>
        </div>

        <div className="sparyMapContainer">
          <FleetMap />
        </div>
      </div>
    </>
  );
};

export default AnalyticsDashboard;
