import React, { useState, useContext, useEffect, useRef } from 'react';
import { Button, Card, Modal, notification, Select, Space } from 'antd';
import { PubSub } from 'aws-amplify';
import { useTranslation } from 'react-i18next';
import { ApplicationContext } from '../../context/AppContext';
import './style.css';
import AppLoader from '../common/AppLoader';
import { getBasestationList, saveAssignMethod } from '../../constants/Api';
import translate from '../../locale/en_translate.json';

const { Option } = Select;

interface Props {
  showModal: boolean;
  handleCancel: (value?: boolean) => void;
  assignObj: any;
}

const AssignBasestationTractor: React.FC<Props> = ({
  showModal,
  handleCancel,
  assignObj,
}: Props) => {
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const [showLoader, setshowLoader] = useState<boolean>(false);
  const [baseStationList, setBaseStationList] = useState<any[]>([]);
  const [baseStationId, setBaseStationId] = useState<any>('');
  const [assignTractor, setAssignTractor] = useState<boolean>(false);
  let sub: any;
  let timeoutId: any;
  const isMounted = useRef(true);
  const selectedOptionRef = useRef(undefined);

  useEffect(() => {
    if (userDetails) {
      getAssignedList();
      subscribeToBasestationEvent();
    }
    return () => {
      clearTimeout(timeoutId);
      isMounted.current = false;
      timeoutId = null;
      setAssignTractor(false);
      selectedOptionRef.current = undefined;
      sub.unsubscribe();
    };
  }, [userDetails]);

  const subscribeToBasestationEvent = () => {
    sub = PubSub.subscribe(
      `mos_events_${userDetails.organization_id}`,
    ).subscribe({
      next: (data: any) => {
        if (
          selectedOptionRef.current === undefined &&
          data?.value?.category === 'bs_tractor_assignment' &&
          data?.value?.tractor_id === assignObj?.id
        ) {
          if (data.value.status === 1000) {
            notification.success({ message: data?.value?.message });
          }
          if (data.value.status === 1001) {
            notification.error({ message: data?.value?.message });
          }
          handleCancel(true);
        }
        if (
          data?.value?.category === 'bs_tractor_assignment' &&
          data?.value?.basestation_id === selectedOptionRef.current &&
          data?.value?.tractor_id === assignObj?.id
        ) {
          if (data.value.status === 1000) {
            notification.success({ message: data?.value?.message });
          }
          if (data.value.status === 1001) {
            notification.error({ message: data?.value?.message });
          }
          handleCancel(true);
        }
      },
      // eslint-disable-next-line no-console
      error: (error: any) => console.error(error),
    });
  };

  const getAssignedList = async () => {
    setshowLoader(true);
    const result = await getBasestationList(
      userDetails.organization.api_url,
      userDetails.organization.id,
      1,
      500,
    );
    const data =
      result && result?.records && result?.records?.length > 0
        ? result?.records
        : [];
    data.map((ele: any) => {
      if (ele.basestation_id === assignObj?.basestation?.basestation_id) {
        setBaseStationId(ele.basestation_id);
        selectedOptionRef.current = ele.basestation_id;
      }
    });
    setBaseStationList(data);
    setshowLoader(false);
  };

  const handleAssign = () => {
    if (baseStationId) {
      const payload = {
        is_assign: true,
        basestation_id: baseStationId,
      };
      saveAssign(payload);
    } else {
      const payload = {
        is_assign: false,
      };
      saveAssign(payload);
    }
  };

  const saveAssign = async (payload: any) => {
    try {
      setAssignTractor(true);
      const result: any = await saveAssignMethod(
        userDetails.organization.api_url,
        assignObj?.id,
        payload,
      );
      if (result) {
        timeoutId = setTimeout(async () => {
          if (isMounted.current) {
            notification.error({
              message: baseStationId
                ? 'Assignment Failed'
                : 'UnAssignment Failed',
            });
            try {
              const newPayload = {
                is_assign: payload.is_assign,
                basestation_id: assignObj?.basestation?.basestation_id ?? '',
                fail_over_rollback: true,
              };
              await saveAssignMethod(
                userDetails.organization.api_url,
                assignObj?.id,
                newPayload,
              );
            } catch (err: any) {
              notification.error({
                message: err.message,
              });
            }
            handleCancel(true);
          }
        }, 90000);
      }
    } catch (err: any) {
      setAssignTractor(false);
      handleCancel(false);
      notification.error({
        message: err.message,
      });
    }
  };

  const handleBasestation = (e: any) => {
    selectedOptionRef.current = e === '' ? undefined : e;
    setBaseStationId(e);
  };

  return (
    <Modal
      visible={showModal}
      footer={false}
      maskClosable={false}
      onCancel={() => handleCancel(false)}
      className="permissonPopup"
    >
      <>
        <Card className="softwareCard">
          <div className="verHistorySec">
            <h3 className="posRel assignBs">
              {t(translate.editFleet.assignBasestation)}
            </h3>

            <div>
              <div className="mt16 addImp assignbasestation">
                <Select
                  style={{ width: '100%' }}
                  className="dataBaseDropdownStyle"
                  placeholder="Select Status"
                  data-testid="durationSelect-AnalyticsDatabase"
                  value={
                    baseStationList &&
                    baseStationList.length > 0 &&
                    baseStationId
                  }
                  onSelect={handleBasestation}
                  disabled={assignTractor}
                >
                  <Option value="">None</Option>
                  {baseStationList.map((data: any) => (
                    <Option
                      disabled={
                        data?.basestation_connected_status === 'offline'
                      }
                      key={data.id}
                      value={data.basestation_id}
                    >
                      {data.name}
                    </Option>
                  ))}
                </Select>
              </div>
              <div className="softversionreq">
                <p>Minimum software versions required:</p>

                <p>Tractor : 2024.02.06.17, Basestation: 2024.02.06.28</p>
              </div>

              <div className="permissonVerSec">
                <Space>
                  <Button
                    onClick={() => handleCancel(false)}
                    className="verCancelBtn"
                  >
                    {t(translate.buttons.close)}
                  </Button>
                  <Button
                    onClick={handleAssign}
                    className="verResetBtn assignButton"
                    loading={assignTractor}
                  >
                    {assignTractor
                      ? t(translate.buttons.assignProgress)
                      : t(translate.buttons.save)}
                  </Button>
                </Space>
              </div>
            </div>

            <AppLoader loader={showLoader} />
          </div>
        </Card>
      </>
    </Modal>
  );
};

export default AssignBasestationTractor;
