/* eslint-disable no-console */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Content } from 'antd/lib/layout/layout';
import { Button, Checkbox, Layout, Row, notification } from 'antd';
import UpdatesSummary from './UpdatesSummary';
import { getUpdateSummary, getUpdatesList } from '../../constants/Api';
import { getSummary } from '../../constants/types';
import { ApplicationContext } from '../../context/AppContext';
import { ScheduleLater } from './ScheduleLater';
import { InstallNow } from './InstallNow';
import usePaginate from '../../hooks/usePaginate';
import { mapSchUpdatesData } from '../../lib/dataFormat';
import { initScroller, wrapIndictorsData } from '../../constants/Common';
import { CommonReleaseNotes } from './CommonReleaseNotes';
import './style.css';
import { OutOfDateContainer } from './OutOfDateContainer';
import { UpToDateContainer } from './UpToDateContainer';
import { SchUpdatesList } from './SchUpdatesList';
import { UpdateProcessModal } from './UpdateProcessModal';
import { InstallationsContainer } from './InstallationsContainer';
import AdminLoader from '../admin/AdminLoader';

interface Props {
  updateCount: number;
}

export const SchUpdates: React.FC<Props> = ({ updateCount }) => {
  const { userDetails } = useContext(ApplicationContext);
  const [loader, setLoader] = useState<boolean>(false);
  const [disable, setDisable] = useState<boolean>(true);
  const [summaryList, setSummaryList] = useState<getSummary>();
  const [scheduleUpdate, setScheduleUpdate] = useState<boolean>(false);
  const [installUpdate, setInstallUpdate] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize] = useState<any>(500);
  const [total, setTotal] = useState<number>(0);
  const [initialize, setInitialize] = useState<boolean>(false);
  const [tractorIds, setTractorIds] = useState<any[]>([]);
  const [seeAll, setSeeAll] = useState<boolean>(false);
  const [confirmSchedule, setConfirmSchedule] = useState<boolean>(false);
  const [updateProcess, setUpdateProcess] = useState<boolean>(false);
  const { filterData, activity, checkActivity, hasMore, setData } =
    usePaginate();
  const callbackInterval = useRef<any>(null);

  useEffect(() => {
    if (tractorIds.length > 0) {
      setDisable(false);
    } else setDisable(true);
  }, [tractorIds]);

  useEffect(() => {
    if (userDetails && userDetails.organization) getSummaryData();
  }, [userDetails]);

  const getSummaryData = async () => {
    try {
      setLoader(true);
      const records = await getUpdateSummary(userDetails.organization.api_url);
      setSummaryList(records);
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    } finally {
      setLoader(false);
    }
  };

  const wrapResult = async (
    apiUrl: string,
    pageNumber: number,
    pageSize: number,
  ) => {
    const result = await getUpdatesList(apiUrl, pageNumber, pageSize);
    return {
      result,
    };
  };

  const getSoftwareUpdates = async () => {
    if (checkActivity()) initScroller();
    setLoader(true);
    try {
      const { result } = await wrapResult(
        userDetails.organization.api_url,
        pageNumber,
        pageSize,
      );

      setTotal(result?._metadata?.total_records_count);
      const { records } = result;
      let data = Array.isArray(records) ? records : [];
      data = mapSchUpdatesData(data);
      data = data.map((d) => {
        d.indicators = wrapIndictorsData(d.indicators);
        return d;
      });
      setData(data);
    } catch (err: any) {
      notification.error({
        message: err?.message,
      });
    } finally {
      setLoader(false);
      if (!initialize) setInitialize(true);
    }
  };

  useEffect(() => {
    if (userDetails && userDetails.organization) loadUpdates();
  }, [pageNumber]);

  const loadUpdates = () => {
    if (userDetails && !userDetails.organization) return;
    if (checkActivity()) {
      pageNumber !== 1 ? setPageNumber(1) : getSoftwareUpdates();
    } else if (activity.current === 'paginate' || activity.current === '') {
      getSoftwareUpdates();
    }
  };

  useEffect(() => {
    callbackInterval.current = setInterval(() => {
      if (userDetails?.organization) loadUpdates();
    }, 5 * 60 * 1000);
    if (userDetails?.organization) loadUpdates();
    return () =>
      callbackInterval.current && clearInterval(callbackInterval.current);
  }, [userDetails]);

  const handleLoadMore = () => {
    if (pageNumber === 1 && checkActivity() && document) initScroller();

    activity.current = 'paginate';
    setPageNumber(pageNumber + 1);
  };

  const closeUpdate = () => {
    setInstallUpdate(false);
    setTractorIds([]);
    getSummaryData();
    loadUpdates();
  };

  const closeUpdateSchedule = () => {
    setScheduleUpdate(false);
    setConfirmSchedule(false);
    setTractorIds([]);
    getSummaryData();
    loadUpdates();
  };

  return (
    <Layout>
      {!(scheduleUpdate || installUpdate || seeAll) ? (
        <div className="mainContent">
          <Content>
            <div className="tblContainer viewportContainer">
              <Row>
                {/* {updateCount > 0 && <UpdateSchedule setSeeAll={setSeeAll} />} */}
                <UpdatesSummary summaryList={summaryList as any} />
                <div className="tractorContainer">
                  <div className="scheduleFleet">
                    <div className="fleetTxt">Your Fleet</div>
                    <div className="upAvailableBtn">Update Available</div>
                  </div>

                  <div className="noteProcess">
                    <button onClick={() => setSeeAll(true)}>
                      Release Notes
                    </button>
                    <span />
                    <button onClick={() => setUpdateProcess(true)}>
                      Update Process
                    </button>
                  </div>

                  {/* <div className="secGrid">
                    <Button
                      data-testid="installNowButton-Updates"
                      className="installNowBtn"
                      onClick={() => setInstallUpdate(true)}
                      disabled={disable}
                    >
                      Install Now
                    </Button>
                    <Button
                      className="schBtn"
                      onClick={() => setScheduleUpdate(true)}
                      disabled={disable}
                    >
                      Schedule
                    </Button>
                  </div> */}
                </div>
                <div className="fleetContent mt12 mb20">
                  Your fleet will require internet to download the update before
                  you can schedule or install it. Tractors are still operable
                  while the download is in progress.
                </div>
              </Row>
              {updateCount && updateCount > 0 ? (
                <div className="fleerContainers">
                  <OutOfDateContainer filterData={filterData} />
                  <InstallationsContainer
                    filterData={filterData}
                    tractorIds={tractorIds}
                    setTractorIds={setTractorIds}
                    setInstallUpdate={setInstallUpdate}
                    setScheduleUpdate={setScheduleUpdate}
                    disable={disable}
                  />
                  <UpToDateContainer filterData={filterData} />
                </div>
              ) : (
                <SchUpdatesList
                  loader={loader}
                  filterData={filterData}
                  handleLoadMore={handleLoadMore}
                  hasMore={hasMore}
                />
              )}
            </div>
          </Content>
        </div>
      ) : (
        <>
          {scheduleUpdate && tractorIds && (
            <ScheduleLater
              closeModal={() => {
                setScheduleUpdate(false);
                setTractorIds([]);
              }}
              closeAndUpdate={closeUpdateSchedule}
              loader={loader}
              setLoader={setLoader}
              tractorIds={tractorIds}
              summaryList={summaryList as any}
              confirmSchedule={confirmSchedule}
              setConfirmSchedule={setConfirmSchedule}
            />
          )}
          {installUpdate && (
            <InstallNow
              closeModal={() => {
                setInstallUpdate(false);
                setTractorIds([]);
              }}
              closeAndUpdate={closeUpdate}
              summaryList={summaryList as any}
              tractorIds={tractorIds}
            />
          )}
          {seeAll && (
            <CommonReleaseNotes
              summaryList={summaryList as any}
              close={() => {
                setSeeAll(false);
                setTractorIds([]);
              }}
            />
          )}
        </>
      )}
      {updateProcess && (
        <UpdateProcessModal
          openModal={updateProcess}
          closeModal={() => setUpdateProcess(false)}
        />
      )}
      {!scheduleUpdate && <AdminLoader loader={loader} />}
    </Layout>
  );
};
