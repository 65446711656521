/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/no-unescaped-entities */

import React, { useContext, useEffect, useRef, useState } from 'react';
import Layout, { Content } from 'antd/lib/layout/layout';
import 'antd/dist/antd.css';
import {
  EllipsisOutlined,
  WarningFilled,
  PlusOutlined,
} from '@ant-design/icons';
import {
  Avatar,
  Button,
  Checkbox,
  Dropdown,
  Input,
  Menu,
  notification,
  Space,
  Spin,
  Tag,
  Tooltip,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

import DuplicateTeam from './DuplicateTeam';
import { ApplicationContext } from '../../context/AppContext';
import { useTeamsContext } from '../../context/TeamContext';

import {
  getTeamById,
  getTeamPriveleges,
  getTeamUsers,
  updateTeamPrivileges,
  addUsersToTeam,
} from '../../constants/Api';

import loading_logo from '../../assets/images/loading_logo.gif';

import { track } from '../../util/logger';
import translate from '../../locale/en_translate.json';
import { privilagesConstants } from '../../constants/Privilages';

import EmployeeDetails from './EmployeeDetails';
import { privileges, Teamprevileges, TeamUser } from '../../constants/types';
import EditTeam from './EditTeam';
import DeleteTeam from './DeleteTeam';
import ViewEmployee from '../common/ViewEmployee';
import DiscardChanges from './DiscardModal';
import './style.css';
import { SET_TEAMS_REFRESH } from '../../context/actions';

const { Search } = Input;
interface Props {
  setPermission: (key: boolean) => void;
  teamId: number;
  setTeamId: (d: number) => void;
}

const PermissionsDashBoard: React.FC<Props> = ({
  setPermission,
  teamId,
  setTeamId,
}) => {
  const { t } = useTranslation();
  const { userDetails, privilegeChecker, getOrgCodesData } =
    useContext(ApplicationContext);
  const { teamReducer } = useTeamsContext();
  const [, dispatch] = teamReducer;
  const [duplicate, setDuplicate] = useState<boolean>(false);
  const [employeeFlag, setEmployeeFlag] = useState<boolean>(false);
  const [permissionData, setPermissionData] = useState<any[]>([]);
  const [edit, setEdit] = useState<boolean>(false);
  const [EmpData, setEmpData] = useState<any>([]);
  const [toggleWidget, setToggleWidget] = useState(false);
  const [empId, setEmpId] = useState(0);

  const [title, setTitle] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [teamprivileges, setTeamPrivileges] = useState<Teamprevileges[]>([]);
  const [refresh, setRefresh] = useState(true);
  const [loader, setLoader] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [_searchValue, _setSearchValue] = useState<string>('');
  const [teamids, setTeamids] = useState<number>(teamId);
  const [enableEmployeeDetails, setEnableEmployeeDetails] =
    useState<boolean>(false);
  const [defalultTeam, setDefaultTeam] = useState<boolean>(false);
  const [deleteTeam, setDeleteTeam] = useState<boolean>(false);
  const [showDiscard, setShowDiscard] = useState<boolean>(false);
  const [appAcess, setAppAcess] = useState<boolean>(false);
  const [savechangesFlag, setSaveChangesFlag] = useState<boolean>(false);
  const [enableSave, setEnableSave] = useState<boolean>(false);
  const [permissionValue, setPermissionValue] = useState<string>('');
  const [originalteamprivileges, setOriginalteamprivileges] = useState<
    Teamprevileges[]
  >([]);
  const [getTeamsToggle, setGetTeamsToggle] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const searchString = useRef<string>('');

  useEffect(() => {
    if (teamId) setTeamids(teamId);
  }, [teamId]);

  useEffect(() => {
    filterValues(permissionValue);
  }, [permissionValue]);

  useEffect(() => {
    if (userDetails && userDetails.organization && teamids) {
      getTeam();
      getTeamsPermisssions();
      setTeamId(teamId);
    }
  }, [userDetails, teamids]);

  useEffect(() => {
    getTeamEmployees();
  }, [getTeamsToggle]);

  useEffect(() => {
    if (!toggleWidget) {
      setRefresh(false);
    } else {
      getTeamEmployees();
      setRefresh(true);
    }
  }, [toggleWidget]);

  useEffect(() => {
    if (toggleWidget === false) {
      getTeamEmployees();
    }
  }, [toggleWidget]);

  const getTeam = async () => {
    try {
      setLoader(true);
      const response = await getTeamById(
        userDetails.organization.api_url,
        userDetails.organization.id,
        teamids,
      );
      setTitle(response.name);
      setDescription(response.description);
      setDefaultTeam(response.is_default_team);
      setAppAcess(response.app_access);
      setLoader(false);
    } catch (error: any) {
      notification.error({ message: 'Unable to get team data' });
    }
  };

  const getTeamsPermisssions = async () => {
    setLoading(true);
    try {
      setLoader(true);
      const response = await getTeamPriveleges(
        userDetails.organization.api_url,
        userDetails.organization.id,
        teamids,
      );
      setOriginalteamprivileges(response);
      setTeamPrivileges(response);
      setLoader(false);
    } catch (error: any) {
      notification.error({ message: 'Unable to get team permission' });
    } finally {
      setLoading(false);
    }
  };

  const closeModel = (state = false) => {
    setDuplicate(false);
    setEdit(false);
    if (state) setRefresh(true);
  };

  const closeEditModel = (status: boolean) => {
    if (status) {
      getTeam();
    }
    setEdit(false);
  };

  const addUser = async (userId: any) => {
    const userArray = [];
    userArray.push(userId);
    setLoader(true);
    try {
      const response = await addUsersToTeam(
        userDetails.organization.api_url,
        userDetails.organization.id,
        teamId,
        {
          users: userArray,
        },
      );
      setGetTeamsToggle(!getTeamsToggle);
      notification.success({
        message: response.msg,
        duration: 2,
      });
    } catch (error: any) {
      if (error?.message) {
        notification.error({
          message: error?.message,
          duration: 2,
        });
      } else {
        notification.error({
          message: `Unable to add user to team`,
          duration: 2,
        });
      }
    } finally {
      _setSearchValue('');
      setLoader(false);
    }
  };
  const getTeamEmployees = async () => {
    let res: any[] = [];
    const teamUsers: TeamUser[] = await getTeamUsers(
      userDetails.organization.api_url,
      userDetails.organization.id,
      teamids,
      true,
    );
    teamUsers.forEach((ele: TeamUser) => {
      if (ele.first_name && ele.last_name) {
        ele.fullname = ele.first_name + ' ' + ele.last_name;
      }
    });
    res = teamUsers;

    setPermissionData(res);
  };

  const wrapResult = async (
    apiUrl: string,
    orgId: number,
    teamId: number,
    belongs: boolean,
    search: string,
  ) => {
    const teamUsers: TeamUser[] = await getTeamUsers(
      apiUrl,
      orgId,
      teamId,
      belongs,
      searchValue ? searchValue : '',
    );
    return {
      teamUsers,
      searchKey: search,
    };
  };

  const getNonTeamEmployees = async () => {
    let res: any[] = [];
    const { teamUsers } = await wrapResult(
      userDetails.organization.api_url,
      userDetails.organization.id,
      teamId,
      false,
      searchValue ? searchValue : '',
    );
    res = teamUsers;
    setEmpData(res);
  };

  useEffect(() => {
    if (employeeFlag) {
      getNonTeamEmployees();
    }
  }, [employeeFlag, searchValue]);

  useEffect(() => {
    if (refresh) {
      getTeamEmployees();
      getNonTeamEmployees();
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    searchEmployee(_searchValue);
  }, [_searchValue]);
  const getRow = (record: any) => {
    setEmpId(record.id);
    // setIsEdit(false);
    track('Employee', {
      event: `${record.first_name} ${record.last_name} Clicked`,
    });
  };

  const seeMore = () => {
    searchString.current = '';
    setPermissionValue('');
    setToggleWidget(false);
    setEnableEmployeeDetails(true);
  };

  const closeDetails = () => {
    setEnableEmployeeDetails(false);
    setToggleWidget(false);
  };

  const previlegeSelection = (
    e: CheckboxChangeEvent,
    privilegeId: number,
    groupId: number,
  ) => {
    const newTeamPrivileges: any = teamprivileges.map((item) => {
      if (item.id === groupId) {
        item.privileges.map((privilege: any) => {
          if (privilege.id === privilegeId) {
            privilege.status = e.target.checked;
          }
        });
      }
      return item;
    });
    setTeamPrivileges(newTeamPrivileges);
    setEnableSave(true);
  };

  const closeDeleteModal = (status: boolean) => {
    if (status) {
      setPermission(false);
    }
    setDeleteTeam(false);
  };

  const searchEmployee = (value: any) => {
    const valuedata: any = value.trim();
    if (valuedata.length <= 2) {
      searchString.current = '';
      setSearchValue('');
    } else {
      searchString.current = searchValue;
      setSearchValue(valuedata);
    }
  };

  const filterValues = (currValue: string) => {
    const newPrivileges: any = [];
    originalteamprivileges.map((item: any) => {
      const subprivilegs: any = [];
      item.privileges.map((privilege: any) => {
        if (
          privilege?.privilege_name
            ?.toLowerCase()
            .includes(currValue.toLowerCase())
        ) {
          subprivilegs.push(privilege);
        }
        return privilege;
      });
      if (subprivilegs.length > 0) {
        newPrivileges.push({ ...item, privileges: subprivilegs });
      }
      return item;
    });
    if (currValue) {
      setTeamPrivileges(newPrivileges);
    } else {
      getTeamsPermisssions();
    }
  };

  useEffect(() => {
    if (savechangesFlag) {
      setSaveChangesFlag(false);
      saveprivileges();
    }
  }, [savechangesFlag]);

  const saveprivileges = async () => {
    searchString.current = '';
    const privilegesSelect: any = [];
    teamprivileges.map((item) => {
      item.privileges.map((privilege: any) => {
        const obj = {
          privilege_id: privilege.id,
          privilege_group_id: privilege.privilege_group_id,
          status: privilege.status ? 'checked' : 'unchecked',
        };
        privilegesSelect.push(obj);
      });
    });
    try {
      await updateTeamPrivileges(
        userDetails.organization.api_url,
        userDetails.organization.id,
        teamId,
        privilegesSelect,
      );
      notification.success({
        message: t(translate.label.teamprivilegesUpdated),
        duration: 2,
      });
      closeDiscardModal(false);
      setEnableSave(false);
      getOrgCodesData();
      getTeamsPermisssions();
      dispatch({ type: SET_TEAMS_REFRESH });
    } catch (error: any) {
      if (error?.message) {
        notification.error({
          message: error?.message,
          duration: 2,
        });
      } else {
        notification.error({
          message: 'unable to update team privileges',
          duration: 2,
        });
      }
    }
  };

  const navigateToTeams = () => {
    if (enableSave) {
      setShowDiscard(true);
    } else {
      setPermission(false);
    }
  };

  const closeDiscardModal = (discard: boolean) => {
    setShowDiscard(false);
    if (discard) {
      setPermission(false);
      setEnableSave(false);
    }
  };

  const checkGroupPrivilege = (groupId: number) => {
    let value = false;
    teamprivileges?.filter((groupPrivilege: any) => {
      if (groupPrivilege.id === groupId) {
        const check = groupPrivilege?.privileges?.every(
          (subPrivilege: any) => subPrivilege.status === true,
        );
        value = check;
      }
    });
    return value;
  };

  const GroupPrivilegeSelection = (e: CheckboxChangeEvent, groupId: number) => {
    const newTeamPrivileges: any = teamprivileges.map((item) => {
      if (item.id === groupId) {
        item.privileges.map((privilege: any) => {
          privilege.status = e.target.checked;
        });
      }
      return item;
    });
    setTeamPrivileges(newTeamPrivileges);
    setEnableSave(true);
  };

  const menu = () => {
    return (
      <Menu>
        {!defalultTeam && privilegeChecker(privilagesConstants.Edit_Teams) && (
          <Menu.Item
            data-testid="editTeamLink-PermissionsDashBoard"
            key="1"
            onClick={() => {
              searchString.current = '';
              setPermissionValue('');
              setEdit(true);
            }}
          >
            {t(translate.permissionDashboard.optionsMenu.editTeam)}
          </Menu.Item>
        )}
        <Menu.Item
          data-testid="duplicateTeamLink-PermissionsDashBoard"
          key="2"
          onClick={() => {
            searchString.current = '';
            setPermissionValue('');
            setDuplicate(true);
          }}
        >
          {t(translate.permissionDashboard.optionsMenu.duplicateTeam)}
        </Menu.Item>
        {!defalultTeam && privilegeChecker(privilagesConstants.Delete_a_Team) && (
          <Menu.Item
            data-testid="deleteTeamLink-PermissionsDashBoard"
            key="3"
            onClick={() => {
              searchString.current = '';
              setPermissionValue('');
              setDeleteTeam(true);
            }}
          >
            {t(translate.permissionDashboard.optionsMenu.deleteTeam)}
          </Menu.Item>
        )}
      </Menu>
    );
  };

  return (
    <Spin
      className="loader"
      spinning={loader}
      indicator={
        <img style={{ width: '40px', height: '40px' }} src={loading_logo} />
      }
    >
      <div
        onClick={() => {
          searchString.current = '';
          setSearchValue('');
          if (employeeFlag === true) setEmployeeFlag(false);
        }}
      >
        <Layout className="posRel">
          {!enableEmployeeDetails && teamids && (
            <Content>
              <div className="tblContainer">
                <div className="teamdashboard">
                  <div className="filters_card spaceBtnAlignCenter">
                    <div className="subPermissionTitle posrel">
                      <div
                        className="teamarrow"
                        onClick={navigateToTeams}
                        data-testid="backArrowIcon-EditRoles"
                      ></div>
                      <div className="teamTitle">
                        <h4
                          className="headline4 mb0"
                          data-testid={`${title}-PermissionsDashBoard`}
                        >
                          {title}
                        </h4>
                        <p className="teamDesc body2">{description}</p>
                      </div>
                    </div>
                    <div className="searchPermissions prmsrch">
                      <div className="searchSec">
                        <Search
                          id="search-field"
                          placeholder="Search Permissions"
                          data-testid="fleetSearchInputField-Fleet"
                          onChange={(e) => {
                            setPermissionValue(e.target.value);
                            filterValues(e.target.value);
                          }}
                          autoComplete="off"
                          style={{ width: 200 }}
                          value={permissionValue}
                        />
                      </div>
                      <div className="ellipseBox">
                        <Dropdown overlay={menu()} trigger={['click']}>
                          <a
                            onClick={(e) => {
                              e.preventDefault();
                            }}
                          >
                            <Space>
                              <EllipsisOutlined className="ellipsiIcon" />
                            </Space>
                          </a>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                  <div className="permissionFlex">
                    <div className="employeesTeams">
                      <h4 className="headline3">
                        {t(translate.headers.employees)}
                        <span className="pull-right overline2">
                          {permissionData.length}
                        </span>
                      </h4>
                      <ul style={{ paddingLeft: 0, paddingBottom: '20px' }}>
                        {permissionData.map((ele: any) => {
                          return (
                            <li
                              value={ele?.id}
                              key={ele?.id}
                              className="listemployees"
                              onClick={() => {
                                getRow(ele);
                                setToggleWidget(true);
                              }}
                            >
                              <span className="emailAlign empName emp body1">
                                <Avatar
                                  style={{
                                    width: '34px',
                                    height: '34px',
                                    marginRight: '17px',
                                    backgroundColor: '#67AFB7',
                                    color: 'white',
                                    fontFamily: 'Montserrat-Bold',
                                  }}
                                  src={ele?.profile_url}
                                >
                                  {ele?.first_name?.charAt(0)}
                                  {ele?.last_name?.charAt(0)}
                                </Avatar>
                                <span
                                  className="optionname optnwrap"
                                  data-testid={`${ele.fullname}-permissionsDashBoard`}
                                >
                                  {ele.first_name} {ele.last_name}
                                </span>
                                {ele?.is_org_admin && (
                                  <Tag
                                    className="body1"
                                    color="geekblue"
                                    style={{
                                      marginLeft: '10px',
                                    }}
                                  >
                                    {t(translate.permissionDashboard.admin)}
                                  </Tag>
                                )}

                                {appAcess && !ele?.username && (
                                  <Tooltip
                                    title="Need Email to grant some permissions."
                                    placement="bottom"
                                    style={{
                                      marginLeft: '10px',
                                    }}
                                  >
                                    <WarningFilled
                                      style={{
                                        color: '#fbb313',
                                        marginLeft: '10px',
                                        fontSize: '22px',
                                        position: 'absolute',
                                        right: '24px',
                                        top: '15px',
                                      }}
                                    />
                                  </Tooltip>
                                )}
                              </span>
                            </li>
                          );
                        })}
                      </ul>
                      {privilegeChecker(privilagesConstants.Add_Employees) &&
                        !employeeFlag && (
                          <div
                            className="employeePermission Button"
                            data-testid="addEmployeeButton-PermissionsDashBoard"
                            onClick={() => {
                              setSearchValue('');
                              _setSearchValue('');
                              setEmployeeFlag(true);
                            }}
                          >
                            <PlusOutlined /> {''}
                            {t(translate.permissionDashboard.addEmployee)}
                          </div>
                        )}
                      {employeeFlag && (
                        <div className="employeePermission employeePermission2">
                          <ul style={{ marginBottom: 0 }}>
                            {EmpData.map((ele: any) => {
                              return (
                                <li
                                  className="listemployees"
                                  key={ele?.id + ele?.fullname}
                                  onClick={() => {
                                    addUser(ele?.id);
                                  }}
                                >
                                  <span className="emailAlign empName body1">
                                    <Avatar
                                      style={{
                                        width: '34px',
                                        height: '34px',
                                        marginRight: '25px',
                                        backgroundColor: '#67AFB7',
                                        color: 'white',
                                        fontFamily: 'Montserrat-Bold',
                                      }}
                                      src={ele?.profile_url}
                                    >
                                      {ele?.first_name?.charAt(0)}
                                      {ele?.last_name?.charAt(0)}
                                    </Avatar>
                                    <span
                                      className="optionname"
                                      data-testid={`${ele.first_name} ${ele.last_name}-PermissionsDashBoard`}
                                    >
                                      {ele.first_name} {ele.last_name}
                                    </span>
                                  </span>
                                </li>
                              );
                            })}
                          </ul>
                          {EmpData.length === 0 && (
                            <div>
                              <p
                                style={{
                                  textDecoration: 'none',
                                  position: 'absolute',
                                  top: '50%',
                                  left: '37%',
                                }}
                              >
                                {t(translate.permissionDashboard.notAvailable)}
                              </p>
                            </div>
                          )}
                          <div
                            className="searchSec searchicon"
                            onClick={(event) => {
                              event.stopPropagation();
                            }}
                          >
                            <Search
                              value={_searchValue}
                              data-testid="searchEmployeeInputField-PermissionsDashBoard"
                              placeholder="Search Employees"
                              onChange={(e) => {
                                _setSearchValue(e.target.value);
                              }}
                              style={{ marginBottom: 5 }}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="permissionTeam">
                      <h4 className="headline3">
                        {t(translate.permissionDashboard.permissions)}
                      </h4>
                      <div className="permissionsTeamTbl">
                        <table>
                          {teamprivileges.length > 0 &&
                            teamprivileges.map(
                              (mainprivilege: Teamprevileges) => (
                                <tbody key={mainprivilege.id}>
                                  <tr>
                                    <td className="body1">
                                      {mainprivilege.privilege_group_name}
                                    </td>
                                    <td>
                                      <Checkbox
                                        data-testid={`${mainprivilege.privilege_group_name}-PermissionsDashBoard`}
                                        checked={checkGroupPrivilege(
                                          mainprivilege.id,
                                        )}
                                        disabled={
                                          title === 'Administrator' ||
                                          !privilegeChecker(
                                            privilagesConstants.Assign_Permissions,
                                          )
                                        }
                                        onChange={(e) =>
                                          GroupPrivilegeSelection(
                                            e,
                                            mainprivilege.id,
                                          )
                                        }
                                      />
                                    </td>
                                  </tr>
                                  {mainprivilege.privileges.map(
                                    (subprevilege: privileges) => (
                                      <tr
                                        className="teamschildrow body2"
                                        key={subprevilege.id}
                                      >
                                        <td>{subprevilege.privilege_name}</td>
                                        <td>
                                          <Checkbox
                                            data-testid={`${subprevilege.privilege_name}-PermissionsDashBoard`}
                                            className="mapCheckbox"
                                            checked={subprevilege.status}
                                            disabled={
                                              title === 'Administrator' ||
                                              !privilegeChecker(
                                                privilagesConstants.Assign_Permissions,
                                              )
                                            }
                                            onChange={(e) =>
                                              previlegeSelection(
                                                e,
                                                subprevilege.id,
                                                subprevilege.privilege_group_id,
                                              )
                                            }
                                          />
                                        </td>
                                      </tr>
                                    ),
                                  )}
                                </tbody>
                              ),
                            )}
                        </table>
                        {teamprivileges.length <= 0 && !loading && (
                          <div className="notFound">
                            <h3
                              style={{ textDecoration: 'none' }}
                              className="tableEmptyTxt overline1"
                            >
                              {t(translate.permissionDashboard.notAvailable)}
                            </h3>
                          </div>
                        )}
                        {enableSave && (
                          <div className="save_change_sec">
                            <Button
                              key="submit"
                              className="btnteamSave btnprivileges"
                              onClick={saveprivileges}
                              data-testid="saveChangesButton-PermissionsDashBoard"
                            >
                              {t(translate.permissionDashboard.saveChanges)}
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {duplicate && (
                  <DuplicateTeam
                    showModal={duplicate}
                    closeModel={closeModel}
                    teamId={teamId}
                    setTeamId={setTeamId}
                  ></DuplicateTeam>
                )}
                {edit && (
                  <EditTeam
                    showModal={edit}
                    closeModel={closeEditModel}
                    teamName={title}
                    description={description}
                    teamId={teamId}
                  ></EditTeam>
                )}
                {deleteTeam && (
                  <DeleteTeam
                    showModal={deleteTeam}
                    closeModel={closeDeleteModal}
                    teamName={title}
                    teamId={teamId}
                  />
                )}
              </div>
            </Content>
          )}
          {enableEmployeeDetails && (
            <EmployeeDetails
              empId={empId}
              closeEmpDetails={closeDetails}
              setTeamids={setTeamids}
            />
          )}
          {showDiscard && (
            <DiscardChanges
              showModal={showDiscard}
              closeModel={closeDiscardModal}
              setSaveChangesFlag={setSaveChangesFlag}
            />
          )}
          {toggleWidget && (
            <ViewEmployee
              empId={empId}
              seeMore={seeMore}
              onClose={() => setToggleWidget(false)}
            />
          )}
        </Layout>
      </div>
    </Spin>
  );
};

export default PermissionsDashBoard;
