/* eslint-disable react/jsx-key */
import React, { useState } from 'react';
import { getSummary } from '../../constants/types';
import Interweave from 'interweave';
import { LeftOutlined } from '@ant-design/icons';
import { Switch, Tabs } from 'antd';

interface Props {
  summaryList?: getSummary;
  close: () => void;
}

const { TabPane } = Tabs;

export const CommonReleaseNotes: React.FC<Props> = ({ summaryList, close }) => {
  const [languageToggle, setLanguageToggle] = useState<boolean>(false);
  return (
    <>
      <div className="scheduleFleet flex-between">
        <div className="scheduleFleet">
          <div className="updateArrow" onClick={() => close()}>
            <LeftOutlined />
          </div>
          <div className="otaHead mb0">Release Notes</div>
        </div>
        <div className="languageTxt" style={{ display: 'none' }}>
          <span style={{ color: languageToggle ? '#99999C' : '#333333' }}>
            Espanol{' '}
          </span>
          <Switch
            checked={languageToggle}
            onChange={(e) => {
              setLanguageToggle(e);
            }}
          />{' '}
          <span style={{ color: languageToggle ? '#333333' : '#99999C' }}>
            English
          </span>
        </div>
      </div>
      <div className="releaseAllNotes">
        {summaryList?.latest_software &&
        summaryList?.latest_software?.length > 1 ? (
          <Tabs centered type="card" className="commonReleaseTabs">
            {summaryList?.latest_software?.map((data: any) => (
              <TabPane tab={data?.model} key={data?.software_version_id}>
                <div className="releaseNotesTxt">
                  <Interweave content={data?.release_notes} />
                </div>
              </TabPane>
            ))}
          </Tabs>
        ) : (
          <>
            {summaryList?.latest_software?.map((data: any) => (
              <div className="releaseNotesTxt">
                <Interweave content={data?.release_notes} />
              </div>
            ))}
          </>
        )}
      </div>
    </>
  );
};
