import { Button } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React from 'react';

import wai_logo from '../../assets/images/wai_logo.svg';

interface Props {
  readonly showPresetDelete: boolean;
  setShowPresetDelete: (state: boolean) => void;
  handleDeletePreset: () => void;
  presetName: string;
}

const DeletePreset: React.FC<Props> = ({
  showPresetDelete,
  setShowPresetDelete,
  handleDeletePreset,
  presetName,
}: Props) => {
  return (
    <Modal
      visible={showPresetDelete}
      closable={false}
      footer={false}
      className="edit_operator"
    >
      <div className="popup_head">
        <img src={wai_logo} />
        <span className="head_text">Delete Preset {`"${presetName}"`}?</span>
      </div>
      <p className="popup_desc_text">
        Deleting preset will erase it from library?
      </p>
      <div className="button_sec">
        <Button key="edit" className="normal_btn" onClick={handleDeletePreset}>
          Delete
        </Button>
        <Button
          key="cancel"
          className="green_btn"
          onClick={() => setShowPresetDelete(false)}
        >
          Cancel
        </Button>
      </div>
    </Modal>
  );
};

export default DeletePreset;
