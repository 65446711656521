import { AutoComplete, notification, Spin } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { useContext, useState } from 'react';
import { getTasksearch, updateSupervisor } from '../../constants/Api';
import { ApplicationContext } from '../../context/AppContext';
import { Taskview, Taskviewdetails } from '../../constants/types';
import './style.css';
import { useTranslation } from 'react-i18next';
import translate from '../../locale/en_translate.json';
import { track } from '../../util/logger';

interface Props {
  showModel: boolean;
  closeModel: () => void;
  tasksuper: any;
}

const Updatesupervisor: React.FC<Props> = ({
  showModel,
  closeModel,
  tasksuper,
}: Props) => {
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const fullname =
    tasksuper && tasksuper.supervisor
      ? tasksuper.supervisor.first_name + ' ' + tasksuper.supervisor.last_name
      : '';

  const [autoValue, setAutoValue] = useState(fullname);
  const supervisorid =
    tasksuper && tasksuper.supervisor ? tasksuper.supervisor.id : '0';
  const [autoid, setautoid] = useState(supervisorid);
  const [result, setResult] = useState<Taskview[]>([]);
  const [loader, setLoader] = useState<boolean>(false);

  const { Option } = AutoComplete;

  const handleSearch = async (value: string) => {
    let res: Taskview[] = [];
    if (value.length >= 3) {
      const taskviewData: Taskviewdetails = await getTasksearch(
        userDetails.organization.api_url,
        value,
      );
      taskviewData.records.forEach((ele: Taskview) => {
        ele.fullname = ele.first_name + ' ' + ele.last_name;
      });
      res = taskviewData.records;
      setResult(res);
    } else if (value.length <= 3) {
      res = [];
      setResult([]);
    }
  };
  const onSelect = (e: string) => {
    result.map((ele: Taskview) => {
      if (ele.fullname === e) {
        setAutoValue(ele.fullname);
        setautoid(ele.id);
        setResult([]);
      }
    });
  };

  const updatesupersubmit = async () => {
    try {
      setLoader(true);
      const response: { msg: string } = await updateSupervisor(
        userDetails.organization.api_url,
        {
          supervisor_id: autoid,
        },
        userDetails.organization.id,
        tasksuper.task_uuid,
      );
      track('UpdatedSupervisor', {
        event: 'Supervisor Updated Sucessfully',
      });
      notification.success({
        message: response.msg,
      });
      closeModel();
    } catch (err: any) {
      track('UpdatedSupervisorFailed', {
        event: 'Supervisor Updated Failed',
      });
      notification.error({
        message: err.message,
      });
    } finally {
      setLoader(false);
    }
  };

  return (
    <Modal
      title={t(translate.headers.assignsupervisor)}
      visible={showModel}
      className="astm-modal betaModal"
      onCancel={closeModel}
      okText={t(translate.buttons.save)}
      onOk={updatesupersubmit}
      okButtonProps={{
        className: 'tm-button btnSave',
      }}
      cancelButtonProps={{
        className: 'btnCancel',
      }}
    >
      <div className="formRow betaRow">
        <div className="formCol betaCol">
          <div className="ant-row ant-form-item">
            <div className="ant-col ant-form-item-label">
              <label>Supervisor</label>
            </div>
          </div>
          <div className="ant-col ant-form-item-control">
            <div className="ant-form-item-control-input">
              <div className="ant-form-item-control-input-content input-align">
                <AutoComplete
                  className="gSearch"
                  onSearch={handleSearch}
                  onSelect={onSelect}
                  defaultValue={autoValue}
                  placeholder="Supervisor"
                >
                  {result.map((ele: Taskview) => (
                    <Option key={ele.id} value={ele.fullname}>
                      {ele.fullname}
                    </Option>
                  ))}
                </AutoComplete>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="edit-loader">
        {loader && (
          <div className="loader">
            <Spin size="large" />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default Updatesupervisor;
