/* eslint-disable react/jsx-no-undef */
import { Button, Modal, Tabs } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import translate from '../../locale/en_translate.json';
import SupportHistorydetails from './SupportHistorydetails';
import SupportHistoryplanner from './SupportHistoryplanner';
const { TabPane } = Tabs;

interface Props {
  showModel: boolean;
  closeModel: () => void;
  reportId: any;
  title: string;
}

const SupportHistoryView: React.FC<Props> = ({
  showModel,
  closeModel,
  reportId,
  title,
}: Props) => {
  const { t } = useTranslation();
  const [tabid, setTabid] = useState<any>('1');

  return (
    <Modal
      title={title}
      visible={showModel}
      className="baseModalContainer profileUpdatePopup grPopup supportHistoryModal"
      onCancel={closeModel}
      footer={[
        <Button
          type="primary"
          key="back"
          onClick={closeModel}
          className="employeeCreate"
          size="large"
        >
          {t(translate.buttons.close)}
        </Button>,
      ]}
    >
      <Tabs
        activeKey={tabid}
        className="elTabs fleetUsage"
        onChange={(key) => {
          setTabid(key);
        }}
      >
        <TabPane tab="Details" key="1" className="attTableHeight mBottom0">
          <SupportHistorydetails reportId={reportId}></SupportHistorydetails>
        </TabPane>
        <TabPane tab="Videos" key="2" className="attTableHeight mBottom0">
          <SupportHistoryplanner reportId={reportId}></SupportHistoryplanner>
        </TabPane>
      </Tabs>
    </Modal>
  );
};

export default SupportHistoryView;
