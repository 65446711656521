/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/react-in-jsx-scope */
import { Content } from 'antd/lib/layout/layout';
import { Layout } from 'antd';
import { useTranslation } from 'react-i18next';
import './style.css';
import { LeftOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { Document, Page } from 'react-pdf';
import { getDateTime } from '../../constants/Common';
import moment from 'moment';
import translate from '../../locale/en_translate.json';

interface Props {
  closeModel: () => void;
  recordFile: any;
}

const KnowledgeBaseFile: React.FC<Props> = ({ closeModel, recordFile }) => {
  const { t } = useTranslation();
  const [numPages, setNumPages] = useState(0);
  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };
  return (
    <>
      <Layout>
        <Content>
          <div className="mainContent">
            <div className="tblContainer selectReport test knowledgeBaseContent knowledgeBaseFile">
              <div className="knowledgeBaseFileBlk">
                <div className="knowledgeFileHeader">
                  <div className="backBtnIcon" onClick={closeModel}>
                    <LeftOutlined />
                    <span className="overline1">
                      {t(translate.KnowledgeBase.back)}
                    </span>
                  </div>
                  <h4 className="headline1">
                    {recordFile?.title[0].toUpperCase() +
                      recordFile?.title.slice(1)}
                  </h4>
                  <ul>
                    <li className="headline4">
                      {t(translate.KnowledgeBase.createdBy)}:{' '}
                      <span>{recordFile?.created_by}</span>
                    </li>
                    <li className="headline4">
                      {t(translate.KnowledgeBase.published)}:{' '}
                      <span>
                        {moment(recordFile?.created_date_time).fromNow()}
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="knowledgeFileBody">
                  <div className="knowledgeFileBodyContent">
                    {recordFile?.media_type === 'pdf' && (
                      <Document
                        file={recordFile?.file_url}
                        onLoadSuccess={onDocumentLoadSuccess}
                        className="pdf-document"
                      >
                        {Array.from(new Array(numPages), (el, index) => (
                          <Page
                            key={`page_${index + 1}`}
                            className="pdf-page"
                            pageNumber={index + 1}
                            scale={1.5}
                          />
                        ))}
                      </Document>
                    )}
                    {recordFile?.media_type === 'video' && (
                      <div className="knowledgeFileVideo">
                        <video
                          width="100%"
                          height="100%"
                          id="video"
                          controls
                          poster={recordFile?.thumbnail_file_url}
                        >
                          <source src={recordFile?.file_url} id="source" />
                        </video>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Content>
      </Layout>
    </>
  );
};

export default KnowledgeBaseFile;
