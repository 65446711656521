import React, { useContext, useEffect } from 'react';
import info_img from '../images/info-3.svg';
import co2_img from '../images/co2.svg';

import './styles.scss';
import useAnalyticsStore from '../store/operationAnalytics';
import LineChart from '../../charts/LineChart';
import useAnalytics from '../hooks/useAnalytics';
import { ApplicationContext } from '../../../context/AppContext';
import { Popover } from 'antd';
import { LineChartSkeleton } from '../../common/Skeleton';

const TotalSavings: React.FC = () => {
  const { totalSavings, co2Savings, savingsData, dateRange } =
    useAnalyticsStore();
  const [fromDate, toDate] = dateRange;
  const { addSavingsData, addCo2Savings, addTotalSavings } =
    useAnalyticsStore();
  const { totalSavingsLoader, initEnergySavingCostData } = useAnalytics();
  const { userDetails } = useContext(ApplicationContext);

  useEffect(() => {
    if (userDetails?.organization && toDate !== null && fromDate !== null) {
      initEnergySavingCostData(
        fromDate?.startOf('day')?.toDate()?.getTime(),
        toDate?.endOf('day')?.toDate()?.getTime(),
      );
    }
    return () => {
      addSavingsData([]);
      addCo2Savings('');
      addTotalSavings('');
    };
  }, [userDetails, dateRange]);

  const tooltipText =
    'Estimated operational cost difference between the MK-V and a comparable diesel tractor';

  return (
    <div className="savings posrel">
      {totalSavingsLoader && <LineChartSkeleton />}
      <div className="left-side">
        <div className="graph-call-outs2">
          <div className="title-info">
            <div className="operations9">Total Savings</div>
            <div className="info-bubbles">
              {/* <img className="info-3-icon" src={info_img} /> */}
              <Popover
                overlayClassName="savingPopover"
                placement="bottomLeft"
                content={tooltipText}
                // trigger={['click']}
              >
                <img className="info-3-icon" src={info_img} />
              </Popover>
            </div>
          </div>
          <div className="filter-merger">${totalSavings}</div>
        </div>
        <div className="image-cropper">
          <div className="graph-call-outs3">
            <div className="operations10">
              CO
              <sub>2</sub>
              <span> </span>Savings
            </div>
            <div className="div49">{co2Savings} lbs</div>
          </div>
          <div className="numerical-summarizer">
            <img className="co2-icon" src={co2_img} />
          </div>
        </div>
      </div>
      <div className="savings-graph">
        <LineChart
          loader={totalSavingsLoader}
          data={savingsData}
          chartId="totalSavings"
          smooth={true}
          points={true}
          coverArea={true}
          lineColor="#546cb2"
          yTitle="USD Saved ($)"
        />
      </div>
    </div>
  );
};

export default TotalSavings;
