import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Button, DatePicker, message, notification, Tabs } from 'antd';
import co2_saved from '../images/co2_saved.svg';
import driving_hours from '../images/driving_hours.svg';
import energy_used from '../images/energy_used.svg';
import kms_driven from '../images/km_driven.svg';
import moment from 'moment';
import walk_icon from '../images/walk_icon.svg';
import t_tractor from '../images/t_tractor.svg';

import { ApplicationContext } from '../../../context/AppContext';
import {
  dayWiseCumulative,
  getCumulativeData,
  getChargeSummary,
  getIndicatorsSummary,
  getTicketsSummary,
  monthWiseTractorUsage,
  getTractorSummary,
} from '../api';
import { extractHours, impactFilters, restrictDecimal } from '../Common';
import CustomSelect from '../../common/CustomSelect';
import StackedBarChartWithSlider from './charts/StackedBarChart';
import LineChart from './charts/LineChart';
import MultiLineChart from './charts/MultiLineChart';
import AppLoader from '../../common/AppLoader';
import './style.css';
import CropOrgFilter from '../../common/CropOrgFilter';
import RoutesConstants from '../../../routes/RoutesConstant';
import { useHistory } from 'react-router-dom';
import TractorUsageReport from './TractorUsageReport';

const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const ImpactDashboard: React.FC = () => {
  const { push } = useHistory();
  const { userDetails } = useContext(ApplicationContext);
  const [loader, setLoader] = useState(false);
  const [selectedOrg, setSelectedOrg] = useState<any>([]);
  // const [selectedTractors, setSelectedTractors] = useState<any>([]);
  const [filter, setFilter] = useState<string>('Daily');
  const [totalKmsDriven, setTotalKmsDriven] = useState<number>();
  const [totalC02Saved, setTotalC02Saved] = useState<number>();
  const [totalEnergyUsed, setTotalEnergyUsed] = useState<number>();
  const [totalDrivingHours, setTotalDrivingHours] = useState<number>();
  const [operationalHoursData, setOperationalHoursData] = useState<any>([]);

  const [filterObj, setFilterObj] = useState({
    year: new Date().getFullYear() - 1,
    month: new Date().getMonth() + 1,
    from_date: moment().subtract(7, 'days').format('YYYY-MM-DD'),
    to_date: moment().format('YYYY-MM-DD'),
    type: filter,
  });
  const [data, setData] = useState<any[]>([]);
  const [ticketData, setTicketData] = useState<any>([]);
  const [totalChargeData, setTotalChargeData] = useState<any>([]);
  const [totalChargeCycles, setTotalChargeCyles] = useState<any>([]);
  const [avgChargeTime, setAvgChargeTime] = useState<any>([]);
  const [totaloperationalHours, setTotalOperationalHours] = useState<any>();
  const [totalEnergy, setTotalEnergy] = useState<any>();
  const [totalChaged, setTotalCharged] = useState<any>();
  const [totaldetections, setTotalDetections] = useState<any>();
  const [indicatorsData, setIndicatorsData] = useState<any>([]);
  const [tractorSummary, setTractorSummary] = useState<any>();

  const [totalChargeCycle, setTotalChargeCycle] = useState<any>();
  const [totalAvgTime, setTotalAvgTime] = useState<any>();
  const init = useRef<boolean>(true);
  const [tractorUsageFilter, setTractorUsageFilter] = useState<any>(null);
  const [chargingLoader, setChargingLoader] = useState<boolean>(false);
  const [indicatorLoader, setIndicatorLoader] = useState<boolean>(false);
  const [ticketLoader, setTicketLoader] = useState<boolean>(false);
  const [operationalHourLoader, setOperationalHourLoader] =
    useState<boolean>(false);

  const emptyData = () => {
    setOperationalHoursData([]);
    setIndicatorsData([]);
    setTicketData([]);
    setIndicatorsData([]);
    setTotalChargeData([]);
    setTotalChargeCyles([]);
    setAvgChargeTime([]);
    setData([]);
  };

  const resetData = () => {
    setTotalEnergy('');
    setTotalCharged('');
    setTractorSummary(null);
    setTotalChargeCycle('');
    setTotalAvgTime('');
    setTotalOperationalHours('');
    setTotalDetections('');
    setTotalKmsDriven(0);
    setTotalC02Saved(0);
    setTotalEnergyUsed(0);
    setTotalDrivingHours(0);
    emptyData();
  };

  const isLoading = useMemo(
    () =>
      chargingLoader ||
      indicatorLoader ||
      ticketLoader ||
      operationalHourLoader,
    [chargingLoader, indicatorLoader, ticketLoader, operationalHourLoader],
  );

  useEffect(() => {
    const init = async () => {
      try {
        const { organization } = userDetails;
        const { api_url } = organization;
        const resposne = await getTractorSummary(api_url, selectedOrg);
        if (resposne) setTractorSummary(resposne);
      } catch (error: any) {
        notification.error({ message: error.message });
      }
    };
    if (selectedOrg?.length) init();
  }, [selectedOrg]);

  const getChargeData = async () => {
    try {
      setChargingLoader(true);
      const { organization } = userDetails;
      const resp: any = await getChargeSummary(
        organization.api_url,
        selectedOrg,
        filter === 'Lifetime'
          ? '2023-01-01'
          : filterObj.type === 'Yearly'
          ? `${filterObj.year}-01-01`
          : filterObj.from_date,
        filter === 'Lifetime'
          ? moment().format('YYYY-MM-DD')
          : filterObj.type === 'Yearly'
          ? `${filterObj.year}-12-31`
          : filterObj.to_date,
        // selectedTractors,
        filter === 'Lifetime'
          ? 'yearly'
          : filterObj.type === 'Yearly'
          ? 'monthly'
          : 'daily',
      );
      if (!resp?.analytics_data?.length) {
        message.error('Charge Data Not Found');
      } else {
        const { analytics_data } = resp;
        const chargeData = analytics_data?.map((ele: any) => ({
          category:
            filter === 'Lifetime'
              ? `${ele.charge_year}`
              : filterObj.type === 'Yearly'
              ? ele.month_start_date
              : ele.charge_date,
          value: Number(ele?.kwh_charged),
        }));
        const chargeCyles = analytics_data?.map((ele: any) => ({
          category:
            filter === 'Lifetime'
              ? `${ele.charge_year}`
              : filterObj.type === 'Yearly'
              ? ele.month_start_date
              : ele.charge_date,
          value: ele.charge_cycles,
        }));
        const avgChargeTime = analytics_data?.map((ele: any) => ({
          category:
            filter === 'Lifetime'
              ? `${ele.charge_year}`
              : filterObj.type === 'Yearly'
              ? ele.month_start_date
              : ele.charge_date,
          value: extractHours(ele?.avg_charge_duration_str),
        }));
        setTotalChargeData(chargeData);
        setTotalChargeCyles(chargeCyles);
        setAvgChargeTime(avgChargeTime);
      }
      if (resp?.summary) {
        const { summary } = resp;
        setTotalCharged(Math.round(Number(summary.kwh_charged)));
        setTotalChargeCycle(summary?.charge_cycles);
        setTotalAvgTime(
          `${summary?.avg_charge_duration_str?.split(':')[0]} hr ${
            summary?.avg_charge_duration_str?.split(':')[1]
          } min`,
        );
      }
    } catch (error: any) {
      notification.error({
        message: error.message,
        duration: 1,
      });
    } finally {
      setChargingLoader(false);
    }
  };

  const getTicketsData = async () => {
    try {
      setTicketLoader(true);
      const { organization } = userDetails;
      const resp: any = await getTicketsSummary(
        organization.api_url,
        selectedOrg,
        filterObj.type === 'Yearly'
          ? `${filterObj.year}-01-01`
          : filterObj.from_date,
        filterObj.type === 'Yearly'
          ? `${filterObj.year}-12-31`
          : filterObj.to_date,
        // selectedTractors,
        filterObj.type === 'Yearly' ? 'monthly' : 'daily',
      );
      if (!resp?.tickets_data?.length) {
        return message.error('Tickets Data  Not Found');
      } else {
        const { tickets_data } = resp;
        const ticketsData = tickets_data?.map((ele: any) => {
          return {
            category:
              filterObj.type === 'Yearly'
                ? ele.month_start_date
                : ele.ticket_date,
            value: ele.ticket_created_count,
            value2: ele.ticket_resolved_count,
          };
        });
        // setTotalTickets(summary.ticket_created_count);
        setTicketData(ticketsData);
      }
    } catch (error: any) {
      notification.error({
        message: error.message,
        duration: 1,
      });
    } finally {
      setTicketLoader(false);
    }
  };

  const getIndicatorsData = async () => {
    try {
      setIndicatorLoader(true);
      const { organization } = userDetails;
      const resp: any = await getIndicatorsSummary(
        organization.api_url,
        selectedOrg,
        filterObj.type === 'Yearly'
          ? `${filterObj.year}-01-01`
          : filterObj.from_date,
        filterObj.type === 'Yearly'
          ? `${filterObj.year}-12-31`
          : filterObj.to_date,
        // selectedTractors,
        filterObj.type === 'Yearly' ? 'monthly' : 'daily',
      );
      if (!resp?.analytics_data?.length) {
        return message.error('Indicator Data Not Found');
      } else {
        const { analytics_data } = resp;
        const indicatorsData = analytics_data?.map((ele: any) => {
          return {
            category:
              filterObj.type === 'Yearly'
                ? ele.month_start_date
                : ele.report_date,
            value: Number(ele.indicator_level_1),
            value2: Number(ele.indicator_level_2),
            value3: Number(ele.indicator_level_3),
          };
        });
        setIndicatorsData(indicatorsData);
      }
    } catch (error: any) {
      notification.error({
        message: error.message,
        duration: 1,
      });
    } finally {
      setIndicatorLoader(false);
    }
  };

  const getYearlyData = async () => {
    try {
      setOperationalHourLoader(true);
      const { organization } = userDetails;
      const resp = await getCumulativeData(organization.api_url, selectedOrg);
      if (!resp?.analytics_data?.length) {
        message.error('No data Found');
      } else {
        const { analytics_data } = resp;
        let data = analytics_data;
        data = data?.sort((a: any, b: any) => a.report_year - b.report_year);
        setData([...data]);

        const stackedData = data?.map((ele: any) => {
          return {
            category: `${ele?.report_year}`,
            value: extractHours(ele?.manual_hours_str),
            value2: extractHours(ele?.ADAS_hours_str),
            value3: extractHours(ele?.autonomy_hours_str),
          };
        });
        setOperationalHoursData([...stackedData]);
      }

      // const summery = resp && resp.summary ? resp.summary : [];
      if (resp?.summary) {
        const { summary: summery } = resp;
        setTotalOperationalHours(
          `${summery?.driving_hours_str?.split(':')[0]} hr ${
            summery?.driving_hours_str?.split(':')[1]
          } min`,
        );
        setTotalEnergy(Math.round(summery?.energy_used));
        setTotalDetections(Math.round(summery?.human_detections));
        setTotalKmsDriven(restrictDecimal(summery.distance));
        setTotalC02Saved(restrictDecimal(summery.calculated_co2_saved));
        setTotalEnergyUsed(restrictDecimal(summery.energy_used));
        setTotalDrivingHours(summery.driving_hours_str);
      } else {
        setTotalOperationalHours('');
        setTotalEnergy(0);
        setTotalDetections(0);
        setTotalKmsDriven(0);
        setTotalC02Saved(0);
        setTotalEnergyUsed(0);
        setTotalDrivingHours(0);
      }
    } catch (error: any) {
      notification.error({
        message: error.message,
        duration: 1,
      });
    } finally {
      setOperationalHourLoader(false);
    }
  };

  const getDailyData = async () => {
    try {
      setOperationalHourLoader(true);
      const { organization } = userDetails;
      const resp = await dayWiseCumulative(
        organization.api_url,
        selectedOrg,
        filterObj.from_date,
        filterObj.to_date,
        // selectedTractors,
      );
      if (!resp?.analytics_data?.length) {
        message.error('No data Found');
      } else {
        const { analytics_data } = resp;
        setData([...analytics_data]);
        const stackedData = resp?.analytics_data?.map((ele: any) => {
          return {
            category: ele?.report_date,
            value: extractHours(ele?.manual_hours_str),
            value2: extractHours(ele?.ADAS_hours_str),
            value3: extractHours(ele?.autonomy_hours_str),
          };
        });
        setOperationalHoursData(stackedData);
      }
      if (resp?.summary) {
        const { summary: summery } = resp;
        setTotalOperationalHours(
          `${summery?.driving_hours_str?.split(':')[0]} hr ${
            summery?.driving_hours_str?.split(':')[1]
          } min`,
        );
        setTotalEnergy(Math.round(summery?.energy_used));
        setTotalDetections(Math.round(summery?.human_detections));
        setTotalKmsDriven(restrictDecimal(summery?.distance));
        setTotalC02Saved(restrictDecimal(summery?.calculated_co2_saved));
        setTotalEnergyUsed(restrictDecimal(summery?.energy_used));
        setTotalDrivingHours(extractHours(summery?.driving_hours_str));
      } else {
        setTotalOperationalHours('');
        setTotalEnergy(0);
        setTotalDetections(0);
        setTotalKmsDriven(0);
        setTotalC02Saved(0);
        setTotalEnergyUsed(0);
        setTotalDrivingHours(0);
      }
    } catch (error: any) {
      notification.error({
        message: error.message,
        duration: 1,
      });
    } finally {
      setOperationalHourLoader(false);
    }
  };

  const loadData = () => {
    if (selectedOrg?.length) {
      getTicketsData();
      getChargeData();
      emptyData();
      if (filter === 'Yearly' && filterObj.year) getMonthlyData();
      if (filter === 'Lifetime') getYearlyData();
      if (
        (filter === 'Weekly' || filter === 'Monthly' || filter === 'Daily') &&
        filterObj.from_date &&
        filterObj.to_date
      ) {
        getDailyData();
      }
      getIndicatorsData();
    }
  };

  useEffect(() => {
    if (
      userDetails?.organization &&
      filter === 'Daily' &&
      filterObj.year &&
      init.current &&
      selectedOrg?.length
    ) {
      loadData();
      init.current = false;
    }
  }, [filter, userDetails, selectedOrg]);

  const getMonthlyData = async () => {
    try {
      setOperationalHourLoader(true);
      // emptyData();
      const { organization } = userDetails;
      const resp = await monthWiseTractorUsage(
        organization.api_url,
        selectedOrg,
        filterObj.year,
        1,
        12,
      );
      if (!resp?.analytics_data?.length) {
        message.error('No data Found');
      } else {
        const { analytics_data } = resp;
        setData([...analytics_data]);
        const stackedData = resp?.analytics_data?.map((ele: any) => {
          return {
            category: ele.month_start_date,
            value: extractHours(ele?.manual_hours_str),
            value2: extractHours(ele?.ADAS_hours_str),
            value3: extractHours(ele?.autonomy_hours_str),
          };
        });
        setOperationalHoursData(stackedData);
      }

      if (resp?.summary) {
        const { summary: summery } = resp;
        setTotalOperationalHours(
          `${summery?.driving_hours_str?.split(':')[0]} hr ${
            summery?.driving_hours_str?.split(':')[1]
          } min`,
        );
        setTotalEnergy(Math.round(summery.energy_used));
        setTotalDetections(Math.round(summery.human_detections));
        setTotalKmsDriven(restrictDecimal(summery.distance));
        setTotalC02Saved(restrictDecimal(summery.calculated_co2_saved));
        setTotalEnergyUsed(restrictDecimal(summery.energy_used));
        setTotalDrivingHours(extractHours(summery.driving_hours_str));
      } else {
        setTotalOperationalHours('');
        setTotalEnergy(0);
        setTotalDetections(0);
        setTotalKmsDriven(0);
        setTotalC02Saved(0);
        setTotalEnergyUsed(0);
        setTotalDrivingHours(0);
      }
    } catch (error: any) {
      notification.error({
        message: error.message,
        duration: 1,
      });
    } finally {
      setOperationalHourLoader(false);
    }
  };

  const handleOrg = (ids: any) => setSelectedOrg(ids);

  const handleFilter = (value: string) => {
    const [from_date, to_date] = getDatesByWeek();

    if (value === 'Lifetime') setFilterObj({ ...filterObj, type: filter });
    if (value === 'Yearly') {
      setFilterObj({
        ...filterObj,
        year: new Date().getFullYear() - 1,
        type: value,
        from_date,
        to_date,
      });
    }
    if (value === 'Weekly' || value === 'Daily') {
      const [from_date, to_date] = getDatesByWeek();
      setFilterObj({ ...filterObj, from_date, to_date, type: value });
    }
    if (value === 'Monthly') {
      const from_date = moment(`${filterObj.year}-${filterObj.month}`)
        .startOf('month')
        .format('YYYY-MM-DD');
      const to_date = moment(`${filterObj.year}-${filterObj.month}`)
        .endOf('month')
        .format('YYYY-MM-DD');
      setFilterObj({ ...filterObj, from_date, to_date, type: value });
    }
    setFilter(value);
  };

  const disabledDates = (current: any) => {
    return (
      (current && current > moment()) ||
      (current && current <= moment('12-31-2022'))
    );
  };

  const onYealy = (date: any, dateString: string) => {
    setFilterObj({
      ...filterObj,
      year: Number(dateString),
      type: 'Yearly',
    });
  };

  const onMonthly = (date: any, dateString: string) => {
    if (date) {
      const year = date.format('YYYY');
      const month = date.format('MM');
      const from_date = moment(date).startOf('month').format('YYYY-MM-DD');
      const to_date = moment(date).endOf('month').format('YYYY-MM-DD');
      setFilterObj({
        ...filterObj,
        year: Number(year),
        month: Number(month),
        from_date,
        to_date,
        type: 'Monthly',
      });
    }
  };

  const getDatesByWeek = (weekNumber = moment().week()) => {
    const from_date = moment()
      .day('Sunday')
      .week(weekNumber)
      .format('YYYY-MM-DD');
    const to_date = moment(from_date).add('6', 'days').format('YYYY-MM-DD');
    return [from_date, to_date];
  };

  const onWeekly = (date: any) => {
    const week = moment(date).week();
    const from_date = moment(date)
      .day('Sunday')
      .week(week)
      .format('YYYY-MM-DD');
    const to_date = moment(from_date).add('6', 'days').format('YYYY-MM-DD');
    setFilterObj({
      ...filterObj,
      year: date.format('YYYY'),
      month: date.format('MM'),
      from_date,
      to_date,
      type: 'Weekly',
    });
  };

  const onDateRangeChange = (dates: any) => {
    const [from_date, to_date] = dates;
    setFilterObj({
      ...filterObj,
      from_date: from_date.format('YYYY-MM-DD'),
      to_date: to_date.format('YYYY-MM-DD'),
      type: 'Daily',
    });
  };

  const humanDetectionData = useMemo(() => {
    return data?.map((ele: any) => ({
      category:
        filter === 'Lifetime'
          ? String(ele?.report_year)
          : filter === 'Yearly'
          ? ele.month_start_date
          : ele?.report_date,
      value: Number(ele?.human_detections),
    }));
  }, [data]);

  const energyUsed = useMemo(
    () =>
      data?.map((ele: any) => ({
        category:
          filter === 'Lifetime'
            ? String(ele?.report_year)
            : filter === 'Yearly'
            ? ele.month_start_date
            : ele?.report_date,
        value: Number(ele?.energy_used),
      })),
    [data],
  );

  const handleSubmit = () => {
    if (userDetails && selectedOrg.length) loadData();
    else {
      message.warning('Please select an organization');
      resetData();
    }
  };

  const handleTicketsClick = (data: any) => {
    const filter = {
      date: data?.dateX,
      selectedOrgs: selectedOrg,
    };
    push({
      pathname: RoutesConstants.markMaintenance,
      state: filter,
    });
  };

  const handleOperationsClick = (data: any) => {
    const filter = {
      date: data?.category,
      selectedOrgs: selectedOrg,
    };
    setTractorUsageFilter(filter);
  };

  return (
    <>
      <div
        className="impact-dashboardTab"
        style={{ display: tractorUsageFilter ? 'none' : 'block' }}
      >
        <div className="ad_ResultsTitleFlex">
          <div className="ad_impactTitle ad_displayFlexNew padBm10">
            Impact Dashboard
            {/* <div className="ad_calendarIcon">
            <img src={calendar_icon} />
          </div> */}
          </div>
          <div className="ad_filters_card ad_filters_card_new">
            <div className="ad_displayFlex newFilterWrap">
              <CropOrgFilter handleOrgs={handleOrg} />
              <CustomSelect
                label="Filters"
                cssClass="ad_min_width"
                value={filter}
                setValue={handleFilter}
                options={impactFilters}
                optionKey="value"
                optionDisplay="name"
              />
              {filter === 'Monthly' && (
                <DatePicker
                  allowClear={false}
                  format="MMM-YYYY"
                  value={moment(`${filterObj.year}-${filterObj.month}`)}
                  onChange={onMonthly}
                  disabledDate={disabledDates}
                  picker="month"
                />
              )}
              {filter === 'Weekly' && (
                <DatePicker
                  allowClear={false}
                  value={moment(filterObj.from_date)}
                  onChange={onWeekly}
                  disabledDate={disabledDates}
                  picker="week"
                />
              )}
              {filter === 'Yearly' && (
                <DatePicker
                  allowClear={false}
                  value={moment(`${filterObj.year}-01-01`)}
                  onChange={onYealy}
                  disabledDate={disabledDates}
                  picker="year"
                />
              )}
              {filter === 'Daily' && (
                <RangePicker
                  allowClear={false}
                  value={[
                    moment(filterObj.from_date),
                    moment(filterObj.to_date),
                  ]}
                  onChange={onDateRangeChange}
                  disabledDate={disabledDates}
                  style={{ width: '215px' }}
                />
              )}
              <Button
                className="submitBtn"
                onClick={handleSubmit}
                loading={isLoading}
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
        <div className="ant-tabs-content-holder ad_content_holder">
          <div className="ad_common_wrapper SummaryCont">
            <div className="widgets_block2">
              <div className="gridItem summaryCard">
                <img src={t_tractor} alt="Kms Driven" />
                <div className="widget_content">
                  <div className="assignTxt">Total Tractors</div>
                  <div className="assignNum">
                    {tractorSummary?.total_no_of_tractors || '-'}
                  </div>
                </div>
              </div>
              <div className="gridItem summaryCard">
                <img src={kms_driven} alt="Kms Driven" />
                <div className="widget_content">
                  <div className="assignTxt">Kms Driven (Kms)</div>
                  <div className="assignNum">{totalKmsDriven || '-'}</div>
                </div>
              </div>
              <div className="gridItem summaryCard">
                <img src={co2_saved} alt="Co2 Saved" />
                <div className="widget_content">
                  <div className="assignTxt">Co2 Saved (Tons)</div>
                  <div className="assignNum">{totalC02Saved || '-'}</div>
                </div>
              </div>
              <div className="gridItem summaryCard">
                <img src={energy_used} alt="Energy Used" />
                <div className="widget_content">
                  <div className="assignTxt">Energy Used (kWH)</div>
                  <div className="assignNum">{totalEnergyUsed || '-'}</div>
                </div>
              </div>
              <div className="gridItem summaryCard">
                <img src={driving_hours} alt="Driving Hours" />
                <div className="widget_content">
                  <div className="assignTxt">Driving Hours (Hrs)</div>
                  <div className="assignNum">{totalDrivingHours || '-'}</div>
                </div>
              </div>
            </div>
            <AppLoader loader={loader} />
            <div className="ad_charts">
              <div className="ad_charts_left">
                <div className="ad_charts_left_top">
                  <div className="ad_charts_box ad_charts_left_top_one">
                    <div className="ad_charts_flex">
                      <div className="ad_charts_title">
                        <h6>Operational Hours</h6>
                        <h3>{totaloperationalHours || '-'}</h3>
                      </div>
                      <div className="ad_charts_status">
                        <div className="ad_charts_status_info">
                          <span className="manual_drive_box"></span>
                          <h6>Manual drive</h6>
                        </div>
                        <div className="ad_charts_status_info">
                          <span className="operator_assist_box"></span>
                          <h6>Operator Assist</h6>
                        </div>
                        <div className="ad_charts_status_info">
                          <span className="auto_drive_box"></span>
                          <h6>Autodrive</h6>
                        </div>
                      </div>
                    </div>
                    <StackedBarChartWithSlider
                      loader={operationalHourLoader}
                      chartId="operationalHours"
                      barColors={['#67AFB7', '#F1CE90', '#EC9322']}
                      labels={['Manual Hours', 'ADAS Hours', 'Autonomy Hours']}
                      data={operationalHoursData}
                      yTitle="Hours of Operation"
                      onClick={(data) => {
                        handleOperationsClick(data.dataContext);
                      }}
                    />
                  </div>
                  <div className="ad_charts_box ad_charts_left_top_two">
                    <div className="ad_charts_flex">
                      <div className="ad_charts_title">
                        <h6>Energy Used (kWH)</h6>
                        <h3>{totalEnergy || '-'}</h3>
                      </div>
                    </div>
                    <StackedBarChartWithSlider
                      loader={operationalHourLoader}
                      chartId="energyUsed"
                      barColors={['#67AFB7']}
                      labels={['kWH']}
                      data={energyUsed}
                      yTitle="Energy Used (kWH)"
                    />
                  </div>
                </div>
                <div className="">
                  <div className="ad_charts_box ad_charts_left_bottom">
                    <Tabs defaultActiveKey="1">
                      <TabPane
                        tab={
                          <div>
                            <div className="ad_tab_title">
                              Total Charged (kWH)
                            </div>
                            <div className="ad_tab_subTitle">
                              {totalChaged || '-'}
                            </div>
                          </div>
                        }
                        key="1"
                      >
                        <StackedBarChartWithSlider
                          loader={chargingLoader}
                          data={totalChargeData}
                          chartId="totalCharge"
                          barColors={['#70B374']}
                          labels={['kWH']}
                          yTitle="Total Charged (kWH)"
                        />
                      </TabPane>
                      <TabPane
                        tab={
                          <div>
                            <div className="ad_tab_title">Charge Cycles</div>
                            <div className="ad_tab_subTitle">
                              {totalChargeCycle || '-'}
                            </div>
                          </div>
                        }
                        key="2"
                      >
                        <StackedBarChartWithSlider
                          loader={chargingLoader}
                          data={totalChargeCycles}
                          chartId="totalChargeCycles"
                          barColors={['#70B374']}
                          labels={['Cycles']}
                          yTitle="Charge Cycles"
                        />
                      </TabPane>
                      <TabPane
                        tab={
                          <div>
                            <div className="ad_tab_title">Avg Charge Time</div>
                            <div className="ad_tab_subTitle">
                              {totalAvgTime || '-'}
                            </div>
                          </div>
                        }
                        key="3"
                        className="chargeTime"
                      >
                        <StackedBarChartWithSlider
                          loader={chargingLoader}
                          data={avgChargeTime}
                          chartId="avgChargeTime"
                          barColors={['#70B374']}
                          labels={['Time']}
                          yTitle="Avg Charge Time"
                        />
                      </TabPane>
                    </Tabs>
                  </div>
                </div>
              </div>
              <div className="ad_charts_right">
                <div className="ad_charts_box">
                  <div className="ad_charts_flex">
                    <div className="ad_charts_title">
                      <h6>Tickets</h6>
                      {/* <h3>{totalTickets || '-'}</h3> */}
                    </div>
                    <div className="ad_charts_status">
                      <div className="ad_charts_status_info">
                        <span className="total_tckts"></span>
                        <h6>Total Tickets</h6>
                      </div>
                      <div className="ad_charts_status_info">
                        <span className="open_tckts"></span>
                        <h6>open Tickets</h6>
                      </div>
                    </div>
                  </div>
                  <MultiLineChart
                    loader={ticketLoader}
                    chartId="ticketLineChart"
                    points={true}
                    lineColors={['#DE827A', '#8595CA']}
                    labels={['Created Tickets', 'Resolved Tickets']}
                    data={ticketData}
                    onClick={handleTicketsClick}
                  />
                </div>
                <div className="ad_charts_box">
                  <div className="ad_charts_flex">
                    <div className="ad_charts_title">
                      <h6>Total Indicators</h6>
                      {/* <h3>{totalIndicators || 0}</h3> */}
                    </div>
                    <div className="ad_charts_status">
                      <div className="ad_charts_status_info">
                        <span className="level_one_box"></span>
                        <h6>Level 1</h6>
                      </div>
                      <div className="ad_charts_status_info">
                        <span className="level_two_box"></span>
                        <h6>Level 2</h6>
                      </div>
                      <div className="ad_charts_status_info">
                        <span className="level_three_box"></span>
                        <h6>Level 3</h6>
                      </div>
                    </div>
                  </div>
                  <MultiLineChart
                    loader={indicatorLoader}
                    chartId="indicatorsLineChart"
                    points={true}
                    lineColors={['#CFCFCF', '#FBB313', '#D43D38']}
                    labels={['Level 1', 'Level 2', 'Level 3']}
                    data={indicatorsData}
                  />
                </div>
                <div className="ad_charts_box">
                  <div className="ad_charts_flex">
                    <div className="ad_charts_title">
                      <h6>Detections</h6>
                      <h3>{totaldetections || '-'}</h3>
                    </div>
                    <div className="ad_charts_status1">
                      <div className="ad_charts_status_info">
                        <img src={walk_icon} />
                      </div>
                    </div>
                  </div>
                  <LineChart
                    loader={operationalHourLoader}
                    data={humanDetectionData}
                    chartId="detections"
                    smooth={false}
                    points={true}
                    coverArea={false}
                    lineColor="#8595CA"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {tractorUsageFilter && (
        <TractorUsageReport
          defaultFilters={tractorUsageFilter}
          onBack={() => setTractorUsageFilter(null)}
        />
      )}
    </>
  );
};

export default ImpactDashboard;
