/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import { Modal, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import type { ColumnsType } from 'antd/es/table';
import { ApplicationContext } from '../../../context/AppContext';
import translate from '../../../locale/en_translate.json';
import CustomTable1 from './CustomTable1';
import { getErrorCodesData } from '../API';

interface Props {
  onClose: () => void;
  onCloseValue?: () => void;
}
const ErrorCodes: React.FC<Props> = ({ onClose, onCloseValue }) => {
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const [loader, setLoader] = useState<boolean>(false);
  const [errorCodes, setErrorCodes] = useState<any>([]);

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      getErrorCodes();
    }
    return () => {
      setErrorCodes([]);
      setLoader(false);
    };
  }, [userDetails]);

  const getErrorCodes = async () => {
    try {
      setLoader(true);
      let records = await getErrorCodesData();
      records = records.map((record: any) => {
        // eslint-disable-next-line no-param-reassign
        record.key = record.indicator_error_code;
        return record;
      });
      setErrorCodes(records);
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    } finally {
      setLoader(false);
    }
  };

  const columns: ColumnsType<any> = [
    {
      title: `${t(translate.tractors.name)}`,
      dataIndex: 'indicator',
      render: (indicator: string) => <span>{indicator}</span>,
      width: '120px',
    },
    {
      title: `${t(translate.label.errorCodes)}`,
      dataIndex: 'indicator_error_code',
      render: (indicator_error_code: string) => (
        <span>{indicator_error_code}</span>
      ),
      width: '100px',
    },
    {
      title: `${t(translate.myTask.subSystem)}`,
      dataIndex: 'subsystem',
      render: (subsystem: string) => <span>{subsystem}</span>,
    },
    {
      title: `${t(translate.myTask.description)}`,
      dataIndex: 'description',
      render: (description: string) => <span>{description}</span>,
    },
    {
      title: `${t(translate.myTask.counterMeasure)}`,
      dataIndex: 'counter_measure',
      render: (counter_measure: string) => <span>{counter_measure}</span>,
    },
  ];

  return (
    <>
      <Modal
        title={t(translate.label.errorCodes)}
        visible
        onOk={() => onClose()}
        onCancel={() => onClose && onClose()}
        width={1000}
        className="addManual w800 err_popup"
      >
        <CustomTable1
          columns={columns}
          dataSource={errorCodes}
          loader={loader}
        />
      </Modal>
    </>
  );
};

export default ErrorCodes;
