/* eslint-disable no-console */
/* eslint-disable react/react-in-jsx-scope */
import { useContext, useEffect, useRef, useState } from 'react';
// import { bar_hour_tooltip } from './bar_tool_tip';
import SecondPieChart from '../linked_charts/SecondPieChart';
import { useTranslation } from 'react-i18next';
import { ApplicationContext } from '../../../context/AppContext';
import { getTractorEnergyChartData } from '../../../constants/Api';
import loading_logo from '../../../assets/images/loading_logo.gif';
import { Row, Col } from 'antd';
import ChartDetails from '../ChartDetails';
import { ArrowLeftOutlined } from '@ant-design/icons';
import moment from 'moment';
import { ResponsiveBar } from '@nivo/bar';
import { BasicTooltip } from '@nivo/tooltip';
import CSelect from '../../common/CSelect';
import { days } from '../../../constants/AppData';
import AppLoader from '../../common/AppLoader';
import translate from '../../../locale/en_translate.json';

interface Props {
  day: any;
  setday: (key: any) => void;
  setTabid: (key: any) => void;
  data: any[];
  loader: boolean;
  init: any;
}

const ElectricityCosts: React.FC<Props> = ({
  day,
  setTabid,
  setday,
  data,
  loader,
  init,
}: Props) => {
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const [dateFilter, setDateFilter] = useState<any>();
  const [selectedData, setSelectedData] = useState<any>();
  const [pie_chart_input, setPieChart] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedDate, setSelectedDate] = useState<number>(0);
  const [tractor, setTractor] = useState<any>();

  const reset = () => {
    setDateFilter(null);
    setPieChart(null);
    setSelectedDate(0);
  };

  const initTractorEnergyChart = async () => {
    setLoading(true);
    try {
      let data = await getTractorEnergyChartData(
        userDetails.organization.api_url,
        userDetails.organization.id,
        dateFilter,
      );
      if (data) {
        data = data
          .filter((item1: any, i: number) => {
            if (!item1.total) return false;
            return (
              data.findIndex((item: any) => item.name === item1.name) === i
            );
          })
          .map((item: any) => {
            return {
              id: item.name,
              label: item.name,
              value: item.total.toFixed(2),
              tractorId: item.id,
            };
          });
        const {
          indexValue, // 'Jan 01'
          id, // 'total'
          value, // '0.2221'
        } = selectedData;

        const dateTime = new Date(
          indexValue + ` ${selectedData?.data?.year}`,
        ).getTime();
        setSelectedDate(dateTime);
        const tdata = {
          line_id: 'total',
          info: {
            x: indexValue.toString(),
            y: 'USD ' + value.toFixed(2),
          },
          data: data,
        };
        setPieChart(tdata);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const onClickBar = (data: any) => {
    const date = new Date(data.indexValue + ' ' + data?.data?.year);
    const dateFilter = {
      startDate: moment(date).startOf('day').toDate().getTime(),
      endDate: moment(date).endOf('day').toDate().getTime(),
    };
    setSelectedData(data);
    setDateFilter(dateFilter);
    setTractor(null);
  };

  useEffect(() => {
    if (userDetails && userDetails.organization && dateFilter)
      initTractorEnergyChart();
  }, [dateFilter]);

  const onClickPie = (eledata: any) => {
    setTractor(eledata?.data);
  };

  const bar_hour_tooltip = ({
    id,
    value,
    indexValue,
    data,
  }: {
    id: string | number;
    value: string | number;
    indexValue: string | number;
    data: any;
  }) => {
    const { energy_used } = data;
    return (
      <BasicTooltip
        id={
          <div className="bg-white-500">
            <div className="text-sm">
              <strong>{t(translate.fleetUsageCharts.date)}:</strong>{' '}
              {indexValue}
            </div>
            <div className="text-sm">
              <strong>{t(translate.fleetUsageCharts.energyCost)}:</strong>{' '}
              {t(translate.fleetUsageCharts.usd)} {Number(value).toFixed(2)}
            </div>
            <div className="text-sm">
              <strong>{t(translate.fleetUsageCharts.energyUsed)}:</strong>{' '}
              {Number(energy_used).toFixed(2)}{' '}
              {t(translate.fleetUsageCharts.kwh)}
            </div>
          </div>
        }
      />
    );
  };

  return (
    <div className="tractorUage">
      <div className="chartGraph" style={{ position: 'relative' }}>
        <div style={{ display: 'flex' }}>
          <div
            className="fdHeight"
            onClick={() => {
              reset();
              setTabid('1');
            }}
            data-testid="backArrowIcon-EditRoles"
          >
            {/* <ArrowLeftOutlined data-testid="backArrowIcon-EditRoles" /> */}
          </div>
          <div className="">
            <span className="text-3xl font-bold">
              {t(translate.fleetUsageCharts.energyCost)}{' '}
              {selectedDate > 0 &&
                `(${moment(selectedDate).format('DD MMM YYYY')})`}
            </span>
            <div className="text-xl my-4 px-4 py-2 w-auto inline-block bg-kikuchiba text-kogecha rounded">
              <strong>{t(translate.fleetUsageCharts.interaction)}:</strong>
              <br />
              {t(translate.fleetUsageCharts.info)}
            </div>
            <div className="ddDurationenergy">
              <CSelect
                list={days}
                isAll={false}
                selectTrigger={(selected: any) => {
                  const { value = '30' } = selected;
                  init.current && setday(value);
                }}
                label="Days"
                keyValue="value"
                defaultId={day}
              />
            </div>
          </div>
        </div>
        <div className="cost-graph-energy">
          {data && data.length > 0 && (
            <ResponsiveBar
              data={data}
              keys={['Energy Cost']}
              indexBy="date"
              margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
              padding={0.3}
              valueScale={{ type: 'linear' }}
              indexScale={{ type: 'band', round: true }}
              colors={['#445A69']}
              enableLabel={false}
              defs={[
                {
                  id: 'dots',
                  type: 'patternDots',
                  background: 'inherit',
                  color: '#38bcb2',
                  size: 4,
                  padding: 1,
                  stagger: true,
                },
                {
                  id: 'lines',
                  type: 'patternLines',
                  background: 'inherit',
                  color: '#eed312',
                  rotation: -45,
                  lineWidth: 6,
                  spacing: 10,
                },
              ]}
              borderColor={{
                from: 'color',
                modifiers: [['darker', 1.6]],
              }}
              tooltip={bar_hour_tooltip}
              axisTop={null}
              axisRight={null}
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'Energy Cost',
                legendPosition: 'middle',
                legendOffset: -40,
              }}
              axisBottom={{
                // format: "%b %d",
                tickSize: 5,
                tickPadding: 10,
                tickRotation: 90,
                legend: '',
                legendOffset: 50,
                legendPosition: 'middle',
              }}
              labelSkipWidth={12}
              labelSkipHeight={12}
              labelTextColor={{
                from: 'color',
                modifiers: [['darker', 1.6]],
              }}
              role="application"
              ariaLabel="Nivo bar chart demo"
              onClick={onClickBar}
            />
          )}
          {loader && (
            <div className="edit-loader">
              <div className="loader">
                <img
                  style={{ width: '70px', height: '40px' }}
                  src={loading_logo}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      {pie_chart_input && (
        <div style={{ height: '80vh', overflowY: 'scroll' }}>
          <div className="dflex">
            <div className="chartGraph">
              <Row>
                <Col span={24}>
                  {/* <div className="tractor-title">{tractor?.label}</div> */}
                  <SecondPieChart
                    title={'Energy Cost by Tractor'}
                    {...pie_chart_input}
                    onClick={onClickPie}
                  />
                </Col>
              </Row>
            </div>
            <div className="chartGraph">
              <Row>
                <Col span={24}>
                  {selectedDate && (
                    <ChartDetails datetime={selectedDate} tractor={tractor} />
                  )}
                  <AppLoader loader={loading} />
                </Col>
              </Row>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ElectricityCosts;
