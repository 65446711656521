import { Button, Modal, Select, Space, Tag, notification } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import translate from '../../../locale/en_translate.json';
import { ApplicationContext } from '../../../context/AppContext';
import TextArea from 'antd/lib/input/TextArea';
import {
  AddattachmentsFiles,
  getOrgEquipments,
  getOrgTags,
  saveFarmObservation,
} from '../../../constants/Api';
import UploadWidget from '../../widget/UploadWidget';
import web_attachment_icon from '../../../assets/images/web-attachment-icon.svg';
import web_mic_icon from '../../../assets/images/web-mic-icon.svg';
import web_mic_icon_w from '../../../assets/images/web-mic-icon-w.svg';
import './style.css';
import Recorder from '../../widget/AudioRecorder';
import AppLoader from '../AppLoader';
import SelectSearch from '../SelectSearch';
import { speCharRegX } from '../../../constants/constant';
const { Option } = Select;
const { CheckableTag } = Tag;

const validations = {
  DESC_ERROR: 'Description is required',
  DESC_1_ERROR: 'Description is must contains 3 characters',
  TRACTOR_ERROR: 'Tractor is required',
};
interface Props {
  addobservationFlag: any;
  Close: (action?: string) => void;
}

const AddFarmObservation: React.FC<Props> = ({
  addobservationFlag,
  Close,
}: Props) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(true);
  const [tractorId, setTractorId] = useState<string>('');
  const [tagsList, setTagsList] = useState<any>([]);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const { userDetails } = useContext(ApplicationContext);
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [description, setDescription] = useState<string>('');
  const [audioList, setAudioList] = useState<any>();
  const [fileList, setFileList] = useState<any>();
  const [loader, setLoader] = useState<boolean>(false);
  const [saveflag, setSaveFlag] = useState<boolean>(false);
  const [tractorError, setTractorError] = useState<string>('');
  const [descError, setDescError] = useState<string>('');
  const [descLenError, setDescLenError] = useState<string>('');
  const [equipmentsList, setEquipmentsList] = useState<any[]>([]);
  const [tractorType, setTractorType] = useState<string>();

  const handleOk = () => {
    setIsModalOpen(false);
    Close();
  };

  const handleValidations = () => {
    if (!tractorId) {
      setTractorError(validations.TRACTOR_ERROR);
      return false;
    }
    if (description.trim() === '') {
      setDescError(validations.DESC_ERROR);
      return false;
    }
    if (description.trim().length <= 3) {
      setDescLenError(validations.DESC_1_ERROR);
      return false;
    }
    return true;
  };

  const handleCancel = () => {
    setIsModalOpen(false);

    setFileList([]);
    Close();
  };

  const handleOnChange = (e: string, key: any) => {
    setTractorId(e);
    setTractorType(key.equipment_type);
  };

  const callback = async (bData: any) => {
    setAudioList('');
    const file = bData
      ? new File([bData], 'Audio.wav', { type: bData.type })
      : undefined;
    setAudioList(file);
  };

  const handleChange = (tag: any, checked: any) => {
    tag.selected = checked;
    const nextSelectedTags = checked
      ? [...selectedTags, tag]
      : selectedTags.filter((t: any) => t !== tag);
    setSelectedTags(nextSelectedTags);
  };

  const loadTags = async () => {
    const resultTags: any = await getOrgTags(
      userDetails.organization.api_url,
      userDetails.organization_id,
    );

    resultTags.map((tag: any) => {
      tag.selected = false;
    });

    setTagsList(resultTags);
  };

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      loadEquipments();
      loadTags();
    }
  }, [userDetails]);

  const saveObservation = async () => {
    setTractorError('');
    setDescError('');
    setDescLenError('');
    if (!handleValidations()) return;
    const selTags: any[] = [];
    tagsList.map((tag: any) => {
      if (tag.selected) {
        selTags.push(tag.name);
      }
    });
    const payload = {
      description: description.trim(),
      equipment_id: tractorId,
      equipment_type: tractorType,
      tags: selTags,
    };
    try {
      setLoader(true);
      const result: any = await saveFarmObservation(
        userDetails.organization_id,
        payload,
      );
      if (result && fileList && fileList.length > 0) {
        saveFileList(result);
      }
      if (result && audioList && fileList === undefined) {
        saveAudioList(result);
      }
      if (audioList === undefined && fileList === undefined) {
        setTimeout(() => {
          handleCancel();
          setSaveFlag(false);
        }, 3);
      }
      setSaveFlag(true);
      notification.success({
        message: result?.msg,
      });
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    } finally {
      setLoader(false);
    }
  };

  const saveFileList = async (result: any) => {
    if (fileList.length > 0) {
      fileList.map(async (ele: any) => {
        const formData = new FormData();
        formData.append('file', ele.originFileObj);
        try {
          setLoader(true);
          const data: any = await AddattachmentsFiles(
            userDetails.organization_id,
            result?.observations_uuid,
            formData,
          );
        } catch (err: any) {
          notification.error({
            message: err.message,
          });
        }
      });
      if (result && audioList) {
        saveAudioList(result);
      } else {
        setTimeout(() => {
          setLoader(false);
          handleCancel();
          setSaveFlag(false);
        }, 3);
      }
    }
  };

  const saveAudioList = async (result: any) => {
    const formData = new FormData();
    formData.append('file', audioList);
    try {
      setLoader(true);
      const data: any = await AddattachmentsFiles(
        userDetails.organization_id,
        result?.observations_uuid,
        formData,
      );
      setTimeout(() => {
        handleCancel();
        setSaveFlag(false);
      }, 3);
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    } finally {
      setLoader(false);
    }
  };
  const callBackImages = (images: any) => {
    const data = images.length > 0 ? images : undefined;
    setFileList(data);
  };

  if (!addobservationFlag) return <></>;

  const loadEquipments = async () => {
    setEquipmentsList([]);
    let result: any = await getOrgEquipments(
      userDetails.organization.api_url,
      userDetails.organization_id,
    );
    result = result.filter(
      (equipment: any) => equipment.equipment_type !== 'None',
    );
    result = result.map((equip: any, i: any) => {
      const { name } = equip;
      equip.uuid = i + 1;
      equip.name = !speCharRegX?.test(name) ? encodeURIComponent(name) : name;
      return equip;
    });
    setEquipmentsList(result);
  };

  return (
    <>
      <Modal
        className="fbs-modal farmObservaatioModal"
        title={t(translate.label.createFarmObservations)}
        visible={addobservationFlag}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <div className="farm-observation-container">
          {!tractorId && <p className="v-error">{tractorError}</p>}
          {description.trim() === '' && <p className="v-error">{descError}</p>}
          {description.trim().length <= 3 && (
            <p className="v-error">{descLenError}</p>
          )}

          <SelectSearch
            data={equipmentsList.map((tractor: any) =>
              Object.assign({
                value: tractor.equipment_id,
                label: tractor.name,
                key: tractor.uuid,
                equipment_type: tractor.equipment_type,
              }),
            )}
            placeholder="Select Tractor"
            handleOnChange={(value, key) => handleOnChange(value, key)}
          />
          <p style={{ marginTop: 20 }}>
            <TextArea
              placeholder="Your Observations"
              className="overline1"
              onChange={(e: any) => {
                setDescription(e.target.value);
              }}
            />
          </p>
          <div className="tagsSec">
            <h4 className="overline1 fSbd">Tags</h4>
            <div className="tagsBlk" data-testid="selectTag-TicketCreateEdit">
              {tagsList?.map((data: any) => {
                return (
                  <CheckableTag
                    className="checktag body3"
                    key={data.id}
                    checked={selectedTags.indexOf(data) > -1}
                    onChange={(checked: any) => handleChange(data, checked)}
                  >
                    {data.name}
                  </CheckableTag>
                );
              })}
            </div>
          </div>
          <div className="fbs-media-section">
            <UploadWidget callback={callBackImages} />
            <div className="recorder">
              <Recorder recording={isRecording} callback={callback} />
            </div>
          </div>
          <div>
            <Space className="fbs-icon-section">
              <img
                src={web_attachment_icon}
                onClick={() => {
                  const doc: any = document;
                  doc.getElementById('fbs-upload-btn').click();
                }}
              />
              <img
                onClick={() => setIsRecording(!isRecording)}
                src={isRecording ? web_mic_icon_w : web_mic_icon}
              />
              <AppLoader loader={loader} />
              <Button className="fbs-save" onClick={saveObservation}>
                Save
              </Button>
            </Space>
          </div>
          {saveflag && (
            <div className="fbs_saved_msg">
              <p className="overline1">Saved Successfully</p>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

export default AddFarmObservation;
