import { Avatar, notification, Spin, Tooltip } from 'antd';
import { MqttClient } from 'mqtt';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import loading_logo from '../../assets/images/loading_logo.gif';
import {
  getInternetSpeed,
  getRandomColor,
  tractorCamerasLabels,
} from '../../constants/Common';
import {
  LiveStreamWatchers,
  TractorHeartBeat,
  Watcher,
} from '../../constants/types';
import { ApplicationContext } from '../../context/AppContext';
import RoutesConstants from '../../routes/RoutesConstant';
import { getClient } from '../../util/MqttConnection';
import LiveVideo from '../livecommand/LiveVideo';
import './style.css';
import AllCameraDown from '../temp/components/common/CameraViews/AllCameraDown';
import Timer from '../common/Timer';

const url = window.location.href;
const origin = window.location.origin;
const popUrl = url.replace(RoutesConstants.CameraView + '/', '');
const srcString = popUrl.replace(origin, '');
const viewData = srcString;
const tractorId = viewData.split('/')[0];
const tractorName = viewData.split('/')[2];
const cameraFace = viewData.split('/')[3];
const showStats = viewData.split('/')[4];
const heartBeatTopic = 'HeartBeat';

const CameraView: React.FC = () => {
  const { userDetails } = useContext(ApplicationContext);
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const [src, setSrc] = useState('');
  const [showSpinner, setShowSpinner] = useState(true);
  const [spinnerDescription] = useState('Connecting to Tractor');
  const mqttClientRef = useRef<MqttClient>();
  const [, setInternetSpeed] = useState(0);
  const [watchers, setWatchers] = useState<Watcher[]>([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [setsetBitrate, setSetsetBitrate] = useState(0);
  const orgId = searchParams.get('orgId') || userDetails?.organization?.id;

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      setSrc(
        `${userDetails.organization.api_url}/live-camera/tractor/${viewData}`,
      );
    }
    document.title = `${tractorName} ${cameraFace
      .toLowerCase()
      .replaceAll('_', ' ')} camera`;
  }, [userDetails]);

  useEffect(() => {
    try {
      if (userDetails && userDetails.organization) {
        const { organization } = userDetails;
        const mqttClient = getClient(
          organization.mqtt_ip,
          organization.mqtt_port,
        );

        mqttClient.on('connect', () => {
          mqttClient.subscribe([heartBeatTopic, 'live_stream_watch_logs']);
        });
        mqttClient.on('message', (topic: string, message: any) => {
          if (topic === heartBeatTopic) {
            const heartBeat: TractorHeartBeat = JSON.parse(message.toString());
            if (
              heartBeat &&
              heartBeat.organization_id &&
              userDetails.organization_id &&
              heartBeat.organization_id === userDetails.organization_id &&
              heartBeat.tractor_id === Number(tractorId)
            ) {
              const speed = getInternetSpeed(heartBeat);
              setInternetSpeed(speed);
            }
          }

          if (topic === 'live_stream_watch_logs') {
            const watchers: LiveStreamWatchers = JSON.parse(message.toString());
            if (
              watchers.tractor_id.toString() === tractorId &&
              watchers.camera === cameraFace
            ) {
              const filteredUserd = watchers?.live_stream_watcher?.filter(
                (v, i, a) =>
                  a.findIndex((v2) => v2.user_id === v.user_id) === i,
              );

              setWatchers(filteredUserd);
            }
          }
        });
        mqttClientRef.current = mqttClient;
        return () => {
          mqttClient.unsubscribe([heartBeatTopic, 'live_stream_watch_logs']);
          mqttClient.end();
        };
      }
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    }
  }, [userDetails]);

  return (
    <Spin
      className="camera-view-spinner"
      spinning={showSpinner}
      tip={spinnerDescription}
      indicator={
        <img style={{ width: '2em', height: '1em' }} src={loading_logo} />
      }
    >
      {showStats === 'true' && (
        <div className="avatarGroup">
          <Avatar.Group
            maxCount={2}
            size="large"
            maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf' }}
          >
            {watchers &&
              watchers.map((watcher: Watcher, index: number) => (
                <Tooltip key={index} title={watcher.user_name} placement="top">
                  <Avatar
                    style={{ backgroundColor: getRandomColor() }}
                    src={watcher.user_profile}
                  >
                    {watcher.user_name?.charAt(0)}
                  </Avatar>
                </Tooltip>
              ))}
          </Avatar.Group>
        </div>
      )}
      <div style={{ height: '100vh', backgroundColor: '#E8E8E880' }}>
        {/* {errorMessage && errorMessage.length > 0 && (
            <div className="overlay">{errorMessage}</div>
          )} */}
        {errorMessage && errorMessage.length > 0 && (
          <AllCameraDown
            face={tractorCamerasLabels[cameraFace]}
            message={errorMessage}
          />
        )}
        {errorMessage.length === 0 && src.length > 0 && (
          <>
            <div className="col-md-6" id="videos"></div>
            {/* <div className="poorConnectivity">
              {setsetBitrate < 600 &&
                'Live Video may be a little slow due to internet speed issues'}
            </div> */}
            <LiveVideo
              tractorName={tractorId}
              orgId={Number(orgId)}
              cameraFace={cameraFace}
              setErrorMessage={(msg) => {
                setErrorMessage(msg);
                setShowSpinner(false);
              }}
              setShowSpinner={setShowSpinner}
              setSetsetBitrate={setSetsetBitrate}
            />
            {showStats === 'true' && (
              <Timer promptTimeoutMinutes={1} timeoutMinutes={6} />
            )}
          </>
        )}
      </div>
    </Spin>
  );
};

export default CameraView;
