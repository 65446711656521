/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-empty-function */
// eslint-disable-next-line object-curly-newline
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Input, Layout, Space, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import translate from '../../../locale/en_translate.json';
import { ApplicationContext } from '../../../context/AppContext';
import { initScroller } from '../../../constants/Common';
import InfiniteScrollTable from '../../common/InfiniteScrollTable';
import { getErrorCodesData, getIndicatorsDropdown } from '../api';
import usePaginate from '../../../hooks/usePaginate';
import { Content } from 'antd/lib/layout/layout';
import CustomMultiSelect from '../../common/CustomMultiSelect';

// const columns: any[] = [];
const title = 'Diagnostic Codes';

const DiagnosticCodes: React.FC = () => {
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [selectecIndicators, setSelectedIndicators] = useState<any>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [indicators, setIndicators] = useState<any[]>([]);
  const searchString = useRef<string>('');
  // eslint-disable-next-line object-curly-newline, operator-linebreak
  const { filterData, setData, activity, hasMore, checkActivity } =
    usePaginate();
  const [total, setTotal] = useState<number>(0);
  const [pageSize] = useState<number>(25);

  const loadData = () => {
    if (userDetails && !userDetails.organization) return;
    if (checkActivity()) {
      pageNumber !== 1 ? setPageNumber(1) : getErrorCodes();
    } else if (activity.current === 'paginate' || activity.current === '') {
      getErrorCodes();
    }
  };

  const getIndicators = async () => {
    try {
      const { organization } = userDetails;
      setLoading(true);
      const records = await getIndicatorsDropdown(organization.api_url);
      if (Array.isArray(records)) {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const data = records
          .filter(({ indicator }: any) => indicator)
          .map(({ indicator, indicator_key }: any) => ({
            value: indicator_key,
            label: indicator,
          }));
        setIndicators(data);
      }
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    } finally {
      setLoading(false);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const wrapResult = async (
    orgURL: string,
    indicator: string,
    pageNumber: number,
    pageSize: number,
    search: string,
  ) => {
    const result = await getErrorCodesData({
      orgURL,
      indicator,
      pageNumber,
      pageSize,
      search,
    });
    return {
      result,
      searchKey: search,
    };
  };

  const getErrorCodes = async () => {
    try {
      const { organization } = userDetails;
      if (checkActivity()) initScroller();
      // const indicator = selectecIndicators;
      const indicator = selectecIndicators ? selectecIndicators.join(',') : '';
      setLoading(true);
      const { result, searchKey } = await wrapResult(
        organization.api_url,
        indicator,
        pageNumber,
        pageSize,
        search,
      );
      if (searchString.current !== searchKey) return;
      const { records } = result;
      const data = Array.isArray(records) ? records : [];
      setTotal(result._metadata?.total_records_count);
      setData(data);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    } finally {
      setLoading(false);
    }
  };

  // const handleRefresh = () => {
  //   activity.current = 'refresh';
  //   getErrorCodes();
  // };

  const handleSearch = (e: any) => {
    activity.current = 'search';
    const value = e.target.value.trim();
    searchString.current = value;
    if (searchString.current?.length <= 2) {
      setSearch('');
    } else {
      searchString.current = value;
      setSearch(value);
    }
  };

  const columns = [
    {
      title: `${t(translate.errorCodes.type)}`,
      dataIndex: 'indicator',
      // width: '120px',
    },
    {
      title: `${t(translate.errorCodes.errorCode)}`,
      dataIndex: 'indicator_error_code',
      // width: '120px',
    },
    {
      title: `${t(translate.errorCodes.subSystem)}`,
      dataIndex: 'subsystem',
      // width: '160px',
    },
    {
      title: `${t(translate.errorCodes.description)}`,
      dataIndex: 'description',
      // width: '180px',
    },
    {
      title: `${t(translate.errorCodes.counterMeasure)}`,
      dataIndex: 'counter_measure',
      // width: '250px',
    },
  ];

  const handleSelect = (e: any) => {
    activity.current = 'filter';
    setSelectedIndicators(e);
  };

  const handleLoadMore = () => {
    if (pageNumber === 1 && checkActivity() && document) initScroller();

    activity.current = 'paginate';
    setPageNumber(pageNumber + 1);
  };

  useEffect(() => {
    if (userDetails?.organization) loadData();
  }, [userDetails, selectecIndicators, search, pageNumber]);

  useEffect(() => {
    if (userDetails?.organization) getIndicators();
  }, [userDetails]);

  const handleIndicators = (id: string[]) => {
    handleSelect(id);
  };

  return (
    <Layout>
      <div className="mainContent">
        <Content>
          <div className="tblContainer viewportContainer">
            <div className="common_wrapper wWidget_wrapper">
              <div className="filters_card personnelCard ad_filters_card">
                <Space size="large">
                  {/* <Input
                    addonBefore={<SearchOutlined />}
                    placeholder="Search Directory"
                    data-testid="searchFleetInputField-Fleet"
                    className="common_search"
                    onChange={handleSearch}
                    autoComplete="off"
                    // value={search}
                  /> */}
                  <CustomMultiSelect
                    handleTreeSelect={handleIndicators}
                    selectedId={selectecIndicators}
                    list={indicators}
                    label="Indicators"
                  />
                </Space>
              </div>
              <div className="common_table">
                <InfiniteScrollTable
                  xScroll={400}
                  columns={columns}
                  hasMore={hasMore}
                  loading={loading}
                  filterData={filterData}
                  totalcount={total}
                  handleLoadMore={handleLoadMore}
                  filename="Error Codes"
                />
              </div>
            </div>
          </div>
        </Content>
      </div>
    </Layout>
  );
};

export default DiagnosticCodes;
