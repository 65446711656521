import React, { useContext, useState } from 'react';
import d_edit from '../../assets/images/d_edit.svg';
import speed_d from '../../assets/images/speed_d.svg';
import {
  AutoDriveTabs,
  NewOperation,
  OldOperation,
  getFeedTypeImage,
} from '../../constants/constant';
import { DairyCommand, operationType } from '../../lib/types';
import { RemoteDriveAppCtx } from '../remote_drive_new/RemoteDriveContext';
import {
  SET_ACTIVE_AUTODRIVE_TAB,
  SET_ADD_STEP,
} from '../remote_drive_new/actions';
import EditPresetConfirm from './EditPresetConfirm';

interface Props {
  speed: number;
  setNewOrEditOperation: (state: operationType) => void;
  clearPreviewPath: (tractorId: number) => void;

  operationName: string;
  selectedCommand: DairyCommand;
}

const ReviewAndStart: React.FC<Props> = ({
  setNewOrEditOperation,
  clearPreviewPath,
  speed,
  operationName,
  selectedCommand,
}: Props) => {
  const {
    RDReducer,
    commandsList,
    clearCommandApiHandler,
    startAutoDriveIteration,
  } = useContext(RemoteDriveAppCtx);
  const [state, dispatch] = RDReducer;
  const { selectedTractor, showTractorStart } = state;
  const [showEditPresetConfirm, setShowEditPresetConfirm] = useState(false);

  //   const [operationName, setOperationName] = useState<string>('');
  const handleEditConfirm = () => {
    clearPreviewPath(selectedTractor.id);
    clearCommandApiHandler(selectedTractor.id, 'dairy');
    dispatch({
      type: SET_ACTIVE_AUTODRIVE_TAB,
      payload: AutoDriveTabs.Block,
    });
    if (selectedCommand && selectedCommand.id) {
      setNewOrEditOperation(OldOperation);
    } else {
      setNewOrEditOperation(NewOperation);
    }
    setShowEditPresetConfirm(false);
  };
  return (
    <div className="review_path_section">
      <div className="select_lanes_head">
        <h2 className="review_head_text">Review and Start</h2>
        <p className="review_text">
          Review path and parameters before starting your operation.
        </p>
      </div>
      <div className="review_speed_sec">
        <div>
          <div className="presetTxt">Preset</div>
          <div className="presetTime">{operationName}</div>
        </div>
        <div className="speed_block">
          <span className="speed_text">Speed</span>
          <span className="speed_count">
            <img src={speed_d} /> {speed} mph
          </span>
        </div>
        <div>
          <span
            className="edit_list"
            onClick={() => setShowEditPresetConfirm(true)}
          >
            <img src={d_edit} />
            Edit
          </span>
        </div>
      </div>
      {commandsList[selectedTractor.id].map(
        (command: any, index: number) =>
          index + 1 === commandsList[selectedTractor.id].length && (
            <>
              <div className="review_list-sec" key={selectedTractor.id}>
                {command.lane_list.map((item: any, nameIndex: number) => (
                  <>
                    <div className="review_list_item">
                      <div className="list_number">
                        <span>{nameIndex + 1}</span>
                      </div>
                      <div className="review_list_item_card selBdr">
                        <div className="lanes_list_op_name_sec">
                          <div className="review_list_op_name">{item}</div>
                        </div>
                        <div className="lanes_list_op_icon">
                          <span className="mr2">
                            <img
                              src={getFeedTypeImage(
                                command.offset_sequence[nameIndex],
                              )}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </>
          ),
      )}
      {commandsList &&
        commandsList[selectedTractor.id] &&
        commandsList[selectedTractor.id].length > 0 &&
        showTractorStart &&
        showTractorStart[selectedTractor.id] && (
          <div className="d_button_sec">
            <button
              className="button_default d_Button3"
              onClick={() => {
                setShowEditPresetConfirm(true);
              }}
            >
              Previous
            </button>
            <button
              className="button_green d_Button3 dairy_start_button"
              onClick={() => {
                startAutoDriveIteration(selectedTractor.id);
                dispatch({
                  type: SET_ADD_STEP,
                  payload: false,
                });
              }}
            >
              Start
            </button>
          </div>
        )}
      {showEditPresetConfirm && (
        <EditPresetConfirm
          showPop={showEditPresetConfirm}
          setShowPop={setShowEditPresetConfirm}
          handleEditConfirm={handleEditConfirm}
        />
      )}
    </div>
  );
};

export default ReviewAndStart;
