import { Radio, Space, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import InternalExternalComments from './InternalExternalComments';

interface Props {
  selectedTicketId: number;
  data: any;
  setEditComment: () => void;
  setCommentId: any;
  setSelectedTicketById: () => void;
  onClose: () => void;
}

const CommentsView: React.FC<Props> = ({
  selectedTicketId,
  data,
  setEditComment,
  setCommentId,
  setSelectedTicketById,
  onClose,
}) => {
  const { t } = useTranslation();
  const [tabId, setTabId] = useState<any>('CustomerComments');

  return (
    <>
      <Space>
        <Radio.Group value={tabId} onChange={(e) => setTabId(e.target.value)}>
          <Radio.Button value="CustomerComments">Customer</Radio.Button>
          <Radio.Button value="InternalComments">Internal</Radio.Button>
        </Radio.Group>
      </Space>
      <br />
      {tabId === 'CustomerComments' && (
        <InternalExternalComments
          selectedTicketId={selectedTicketId}
          // ticketDetails={ticketDetails}
          data={data}
          setEditComment={setEditComment}
          setCommentId={setCommentId}
          setSelectedTicketById={setSelectedTicketById}
          handleRefresh={() => onClose()}
          tabId={tabId}
        />
      )}
      {tabId === 'InternalComments' && (
        <InternalExternalComments
          selectedTicketId={selectedTicketId}
          // ticketDetails={ticketDetails}
          data={data}
          setEditComment={setEditComment}
          setCommentId={setCommentId}
          setSelectedTicketById={setSelectedTicketById}
          handleRefresh={() => onClose()}
          tabId={tabId}
        />
      )}
    </>
  );
};

export default CommentsView;
