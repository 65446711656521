import { Layout, Space, notification, Button } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { useContext, useEffect, useRef, useState } from 'react';
import InfiniteScrollTable from '../../common/InfiniteScrollTable';
import usePaginate from '../../../hooks/usePaginate';
import { ApplicationContext } from '../../../context/AppContext';
import { initScroller } from '../../../constants/Common';
import { getAllOrganizations } from '../api';
import translate from '../../../locale/en_translate.json';
import { useTranslation } from 'react-i18next';
import './style.css';
import CreateCustomerModal from './CreateCustomerModal';

const Customers: React.FC = () => {
  const { t } = useTranslation();
  const [addCustomer, setAddCustomer] = useState<boolean>(false);

  const [total, setTotal] = useState<number>(0);
  // const [sortedInfo, setSortedInfo] = useState<any>({
  //   columnKey: 'name',
  //   order: 'ascend',
  // });
  const [search, setSearch] = useState<string>('');
  // const [refresh, setRefresh] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  // const [sortedData, setSortedData] = useState<any>({
  //   name: 'ascend',
  // });
  const [inputValue, setInputValue] = useState('');
  const searchString = useRef<string>('');
  const { userDetails } = useContext(ApplicationContext);
  const [pageNumber, setPageNumber] = useState<number>(1);

  const { filterData, setData, activity, hasMore, checkActivity } =
    usePaginate();

  const wrapResult = async (
    apiUrl: string,
    pageNumber: number,
    pageSize: number,
    search: string,
  ) => {
    const result: any = await getAllOrganizations({
      orgURL: apiUrl,
      pageNumber,
      pageSize,
      search: search ? search : '',
    });
    return {
      result,
      searchKey: search,
    };
  };

  const fetchOrganizations = async () => {
    try {
      const { organization } = userDetails;
      const { api_url } = organization;
      setLoading(true);
      const { result, searchKey } = await wrapResult(
        api_url,
        pageNumber,
        25,
        search || '',
      );

      if (searchString.current !== searchKey) return;

      const { records } = result;
      const data = Array.isArray(records) ? records : [];
      setTotal(result._metadata?.total_records_count);
      setData(data);
    } catch (error: any) {
      notification.error({ message: error?.message });
    } finally {
      setLoading(false);
    }
  };

  const loadData = () => {
    if (userDetails && !userDetails.organization) return;
    if (checkActivity()) {
      pageNumber !== 1 ? setPageNumber(1) : fetchOrganizations();
    } else if (activity.current === 'paginate' || activity.current === '') {
      fetchOrganizations();
    }
  };

  useEffect(() => {
    if (userDetails?.organization) {
      loadData();
    }
  }, [userDetails, search]);

  const columns = [
    {
      title: `${t(translate.customer.name)}`,
      dataIndex: 'name',
      key: 'name',
      onCell: (record: any) => ({
        // onClick: () => getRow(record),
      }),
    },
    {
      title: `${t(translate.customer.cropType)}`,
      dataIndex: 'crop_type',
      key: 'crop_type',
      onCell: (record: any) => ({
        // onClick: () => getRow(record),
      }),
    },
    {
      title: `${t(translate.customer.city)}`,
      dataIndex: 'city',
      key: 'city',
      onCell: (record: any) => ({
        // onClick: () => getRow(record),
      }),
    },
    {
      title: `${t(translate.customer.country)}`,
      dataIndex: 'country',
      key: 'country',
      onCell: (record: any) => ({
        // onClick: () => getRow(record),
      }),
    },
  ];

  const handleLoadMore = () => {
    if (pageNumber === 1 && checkActivity() && document) initScroller();

    activity.current = 'paginate';
    setPageNumber(pageNumber + 1);
  };

  // const handleChange = (pagination: any, filters: any, sorter: any) => {
  //   activity.current = 'sort';
  //   const { columnKey, order } = sorter;
  //   setSortedData({ ...sortedData, [columnKey]: order });
  //   setSortedInfo(sorter);
  // };

  const handleSearch = (value: any) => {
    const searchValue = value.trim().regX;
    activity.current = 'search';
    searchString.current = searchValue;
    setInputValue(value);
    if (searchValue.length <= 2) {
      searchString.current = '';
      if (search != '') setData([]);
      setSearch('');
    } else {
      searchString.current = searchValue;
      setSearch(searchValue);
    }
  };

  return (
    <Layout>
      <div className="mainContent">
        <Content>
          <div className="tblContainer viewportContainer">
            <div className="common_wrapper wWidget_wrapper">
              <div className="filters_card personnelCard cusAdd">
                <Space size="large">
                  {/* <Input
                    addonBefore={<SearchOutlined />}
                    placeholder="Search Directory"
                    data-testid="searchFleetInputField-Fleet"
                    className="common_search"
                    onChange={(e) => handleSearch(e.target.value)}
                    autoComplete="off"
                    value={inputValue}
                  /> */}
                  <Button
                    className="customerAddBtn"
                    onClick={() => setAddCustomer(true)}
                  >
                    Add
                  </Button>
                </Space>
              </div>
              <div className="common_table">
                <InfiniteScrollTable
                  xScroll={400}
                  columns={columns}
                  hasMore={hasMore}
                  loading={loading}
                  filterData={filterData}
                  totalcount={total}
                  handleLoadMore={handleLoadMore}
                  filename="Customers"
                  // onChange={handleChange}
                />
              </div>
            </div>
          </div>
        </Content>
      </div>
      {addCustomer && (
        <CreateCustomerModal
          addCustomer={addCustomer}
          close={() => setAddCustomer(false)}
        />
      )}
    </Layout>
  );
};

export default Customers;
