import { Button } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React from 'react';

import wai_logo from '../../assets/images/wai_logo.svg';

interface Props {
  readonly showPop: boolean;
  setShowPop: (state: boolean) => void;
  handleEditConfirm: () => void;
}

const EditPresetConfirm: React.FC<Props> = ({
  showPop: showPresetDelete,
  setShowPop: setShowPresetDelete,
  handleEditConfirm,
}: Props) => {
  return (
    <Modal
      visible={showPresetDelete}
      closable={false}
      footer={false}
      className="edit_operator"
    >
      <div className="popup_head">
        <img src={wai_logo} />
        <span className="head_text">Edit operation?</span>
      </div>
      <p className="popup_desc_text">
        Any changes you make must be saved to update preset?
      </p>
      <div className="button_sec">
        <Button
          key="cancel"
          className="normal_btn"
          onClick={() => setShowPresetDelete(false)}
        >
          Cancel
        </Button>
        <Button key="edit" className="green_btn" onClick={handleEditConfirm}>
          Edit
        </Button>
      </div>
    </Modal>
  );
};

export default EditPresetConfirm;
