/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import SubMenu from 'antd/lib/menu/SubMenu';
import { Menu } from 'antd';
import { AimOutlined } from '@ant-design/icons';
// import { SET_SELECTED_TOPIC } from './UserManualReducer';
import { getSubTopicsData } from '../../constants/Api';
import { SubTopic } from '../../constants/types';
import { ApplicationContext } from '../../context/AppContext';
// import { UserManualAppContext } from './UserManualContext';

interface Props {
  topic: SubTopic;
  setSelectedTopic?: (topic: any) => void;
}

const SubItems: React.FC<Props> = ({ topic, setSelectedTopic, ...others }) => {
  const { userDetails } = useContext(ApplicationContext);

  const [topics, setTopics] = useState([]);
  // const { userManualReducer } = useContext(UserManualAppContext);
  // const [state, dispatch] = userManualReducer;
  // const { selectedTopic } = state;
  const selectedTopic: any = null;

  useEffect(() => {
    const init = async () => {
      const resp = await getSubTopicsData(
        userDetails.organization.api_url,
        topic?.chapter_id,
        topic?.id,
      );
      setTopics(resp);
    };
    if (userDetails && userDetails.organization && topic) init();
  }, [topic, userDetails]);

  useEffect(() => {
    if (topics && topics.length && selectedTopic) {
      const [ttopic] = topics.filter(
        (item: any) => Number(item.id) === Number(selectedTopic.id),
      );
      if (ttopic) setSelectedTopic && setSelectedTopic(ttopic);
      // if (ttopic) dispatch({ type: SET_SELECTED_TOPIC, payload: ttopic });
    }
  }, [topics]);

  return (
    <SubMenu
      title={topic.title}
      key={topic.id}
      onTitleClick={() => {
        setSelectedTopic && setSelectedTopic(topic);
        // dispatch({ type: SET_SELECTED_TOPIC, payload: topic });
      }}
      className={selectedTopic?.id === topic?.id ? 'topic-selected' : ''}
      {...others}
    >
      {topics ? (
        topics.map((data: SubTopic) => (
          <>
            {data.is_has_sub_topics ? (
              <>
                <SubItems
                  key={data.id}
                  topic={data}
                  setSelectedTopic={setSelectedTopic}
                  {...others}
                />
              </>
            ) : (
              <>
                <Menu.Item
                  icon={<AimOutlined />}
                  title={data.title}
                  key={data.id}
                  onClick={() => {
                    setSelectedTopic && setSelectedTopic(data);
                    // dispatch({ type: SET_SELECTED_TOPIC, payload: data });
                  }}
                  {...others}
                >
                  {data.title}
                </Menu.Item>
              </>
            )}
          </>
        ))
      ) : (
        <></>
      )}
    </SubMenu>
  );
};

export default SubItems;
