/* eslint-disable no-restricted-globals */
/* eslint-disable  @typescript-eslint/ban-types */
/* eslint-disable  no-unused-vars */
/* eslint-disable  no-useless-escape */

import { DatePicker } from 'antd';
import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';

interface InputProps {
  label: string;
  required?: boolean;
  value: any;
  setValue: (e: any) => void;
  className: string;
}

const CustomDatePicker: FC<InputProps> = ({
  label,
  required,
  value,
  setValue,
  className,
}) => {
  const [focus, setFocus] = useState<boolean>(false);

  useEffect(() => {
    setFocus(false);
  }, [value]);

  const labelClass =
    focus || (value && value.length !== 0) ? 'label label-float' : 'label';

  const onchange = (date: any) => {
    setValue(date);
  };

  return (
    <div
      className="float-label"
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      <DatePicker
        allowClear
        defaultValue={moment(value)}
        format="YYYY-MM-DD"
        onChange={(date: any) => onchange(date)}
        className={className}
      />
      <label className={labelClass}>
        {label}{' '}
        {required && (
          <span style={{ color: 'red' }} className="float-span">
            *
          </span>
        )}
      </label>
    </div>
  );
};
export default CustomDatePicker;
