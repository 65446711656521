import React from 'react';
import { Button, Modal } from 'antd';
import translate from '../../locale/en_translate.json';
import { useTranslation } from 'react-i18next';
import logo from '../../assets/images/logo192.png';
import './style.css';

interface Props {
  showModal?: boolean;
  closeModel: (success: boolean) => void;
  setSaveChangesFlag: (flag: boolean) => void;
}

export const DiscardChanges: React.FC<Props> = ({
  showModal,
  closeModel,
  setSaveChangesFlag,
}: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <Modal
        className="modalContainer teammodal discardmodal"
        visible={showModal}
        onCancel={() => closeModel(false)}
        width={590}
        closable={false}
        maskClosable={false}
        footer={[
          <>
            <Button
              key="back"
              className="btnteamCancel Button"
              onClick={() => closeModel(true)}
            >
              {t(translate.buttons.discard)}
            </Button>
            <Button
              className="btnteamSave Button"
              onClick={() => setSaveChangesFlag(true)}
            >
              {t(translate.buttons.save)}
            </Button>
          </>,
        ]}
      >
        <div className="createteamContainer">
          <div className="createtitle headline1 mBtm48">
            <div className="pull-left">
              <h1 className="title titleName headline1">
                {t(translate.permissionDashboard.discardChanges)}
              </h1>
            </div>
            <div className="img-div pull-right">
              <img src={logo} />
            </div>
          </div>
          <p className="teamdescription body1 mBtm48">
            {t(translate.permissionDashboard.discardChangesDesc)}
          </p>
        </div>
      </Modal>
    </>
  );
};

export default DiscardChanges;
