/* eslint-disable no-console */
import { Modal, notification } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { saveFeedBack } from '../../constants/Api';
import { getDriveActionUuid } from '../../constants/constant';
import { ApplicationContext } from '../../context/AppContext';
import {
  SET_AUTO_DRIVE_FEEDBACK_PAYLOAD,
  SET_AUTO_DRIVE_FEEDBACK_POP,
  SHOW_SIDE_FEED_BACK,
} from '../remote_drive_new/actions';
import { RemoteDriveAppCtx } from '../remote_drive_new/RemoteDriveContext';
import './style.css';
import { AutoDriveApplicationContext } from '../../context/AutoDriveContext';

interface Props {
  showModal: boolean;
  type: number; // type = 1 => feedback pop, top = 2 right side details part
  disConnectTractor?: (e: any) => void;
}
const AutoDriveFeedBackPop: React.FC<Props> = ({
  showModal,
  type,
  disConnectTractor,
}: Props) => {
  const { userDetails } = useContext(ApplicationContext);
  const { RDReducer, startAutoDrive } = useContext(RemoteDriveAppCtx);
  const [state, dispatch] = RDReducer;
  const { AutoDriveReducer } = useContext(AutoDriveApplicationContext);
  const [autoDriveState, autoDriveDispatch] = AutoDriveReducer;
  const { showSideFeedBack } = autoDriveState;
  const [userFeedBack, setUserFeedBack] = useState('');
  const [rating, setRating] = useState(0);
  const { feedBackPayload, selectedTractor, selectedTractors } = state;

  const feedBackHandler = async () => {
    try {
      const { organization } = userDetails;
      let drive_action_uuid = '';
      let tractor_id = 0;
      // feedback pop
      if (type == 2 && selectedTractor && selectedTractor.id) {
        drive_action_uuid = getDriveActionUuid(
          selectedTractors,
          selectedTractor.id,
        );
        tractor_id = selectedTractor.id;
      }
      // feedback in right side details part
      if (type == 1 && feedBackPayload && feedBackPayload.drive_action_uuid) {
        drive_action_uuid = feedBackPayload.drive_action_uuid;
        tractor_id = feedBackPayload.tractor_id;
      }

      const payload = {
        user_rating: rating,
        user_feedback: userFeedBack,
        tractor_id,
        drive_action_uuid,
      };
      const { msg } = await saveFeedBack(organization.api_url, payload);
      // console.log('payload', payload);
      msg &&
        dispatch({
          type: SET_AUTO_DRIVE_FEEDBACK_PAYLOAD,
          payload: null,
        });
      msg &&
        notification.success({
          message: msg,
        });
      dispatch({ type: SET_AUTO_DRIVE_FEEDBACK_POP, payload: false });
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    } finally {
      console.log();
    }
  };
  return (
    <>
      {type == 1 && (
        <Modal
          title=""
          centered
          visible={showModal}
          footer={false}
          onOk={() =>
            dispatch({ type: SET_AUTO_DRIVE_FEEDBACK_POP, payload: false })
          }
          onCancel={() =>
            dispatch({ type: SET_AUTO_DRIVE_FEEDBACK_POP, payload: false })
          }
          width={850}
          className="ratingPopup"
          maskClosable={false}
        >
          <div className="adrEndTitle">Autodrive Ended</div>
          <div className="adrEndQuestion">
            Why did you end your Autodrive early?
          </div>
          <textarea
            rows={6}
            className="rateTextarea"
            onChange={(e) => setUserFeedBack(e.target.value)}
          ></textarea>
          <div className="adrEndQuestion mb5">
            How would you rate your Autodrive Experience?
          </div>
          <div className="ratingStars pdl25 mb30">
            <div className="rating">
              <span
                className={`${rating >= 1 ? 'starsel' : ''}`}
                onClick={() => setRating(rating === 1 ? 0 : 1)}
              >
                ☆
              </span>
              <span
                className={`${rating >= 2 ? 'starsel' : ''}`}
                onClick={() => setRating(rating === 2 ? 0 : 2)}
              >
                ☆
              </span>
              <span
                className={`${rating >= 3 ? 'starsel' : ''}`}
                onClick={() => setRating(rating === 3 ? 0 : 3)}
              >
                ☆
              </span>
              <span
                className={`${rating >= 4 ? 'starsel' : ''}`}
                onClick={() => setRating(rating === 4 ? 0 : 4)}
              >
                ☆
              </span>
              <span
                className={`${rating == 5 ? 'starsel' : ''}`}
                onClick={() => setRating(rating === 5 ? 0 : 5)}
              >
                ☆
              </span>
            </div>
          </div>
          <div className="feedBtnSec">
            <button
              key="back"
              className="feedSubmitBtn"
              type="button"
              style={{
                cursor: userFeedBack.trim() === '' ? 'default' : 'pointer',
              }}
              onClick={() => {
                feedBackHandler();
              }}
              disabled={userFeedBack.trim() === '' ? true : false}
            >
              Submit
            </button>
          </div>
        </Modal>
      )}
      {type == 2 && (
        <div className="feedbackDtlSec">
          <div className="feedbackCompInfo">Autodrive Complete!</div>
          <div className="rateExperience">
            How would you rate your Autodrive Experience?
          </div>
          <div className="ratingStars">
            <div className="rating">
              <span
                className={`${rating >= 1 ? 'starsel' : ''}`}
                onClick={() => setRating(rating === 1 ? 0 : 1)}
              >
                ☆
              </span>
              <span
                className={`${rating >= 2 ? 'starsel' : ''}`}
                onClick={() => setRating(rating === 2 ? 0 : 2)}
              >
                ☆
              </span>
              <span
                className={`${rating >= 3 ? 'starsel' : ''}`}
                onClick={() => setRating(rating === 3 ? 0 : 3)}
              >
                ☆
              </span>
              <span
                className={`${rating >= 4 ? 'starsel' : ''}`}
                onClick={() => setRating(rating === 4 ? 0 : 4)}
              >
                ☆
              </span>
              <span
                className={`${rating == 5 ? 'starsel' : ''}`}
                onClick={() => setRating(rating === 5 ? 0 : 5)}
              >
                ☆
              </span>
            </div>
          </div>
          <div className="afterRatingBtns">
            <button
              type="button"
              className="rateBtn ratingStartBtn"
              onClick={(e) => {
                showSideFeedBack[selectedTractor.id] = false;
                autoDriveDispatch({
                  type: SHOW_SIDE_FEED_BACK,
                  payload: {
                    ...showSideFeedBack,
                  },
                });
                feedBackHandler();
                startAutoDrive(e);
              }}
            >
              Start new Autodrive
            </button>
            <button
              type="button"
              className="rateBtn ratingDiscBtn"
              onClick={(e) => {
                showSideFeedBack[selectedTractor.id] = false;
                autoDriveDispatch({
                  type: SHOW_SIDE_FEED_BACK,
                  payload: {
                    ...showSideFeedBack,
                  },
                });
                selectedTractor && selectedTractor.id && feedBackHandler();
                disConnectTractor && disConnectTractor(e);
              }}
            >
              Disconnect
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default AutoDriveFeedBackPop;
