import { notification } from 'antd';
import { Feature } from 'ol';
import Geometry from 'ol/geom/Geometry';
import Polygon from 'ol/geom/Polygon';
import VectorLayer from 'ol/layer/Vector';
import * as proj from 'ol/proj';
import VectorSource from 'ol/source/Vector';
import { Fill, Stroke, Style, Text } from 'ol/style';
import {
  getLatitudeAndLongitude,
  getRoadMapRGBColor,
  getRoadMapRGBFillColor,
  getSatelightRGBColor,
  getSatelightRGBFillColor,
  priority,
} from '../../constants/Common';
import { MapFeature } from '../../constants/types';
import { POLYGON } from '../../constants/constant';
export interface PolygonReturns {
  layer: any;
  list: MapFeature[];
  fieldFeature: Feature<Geometry> | null;
  greenLayer: any;
  whiteLayer: any;
  grayLayer: any;
  blueLayer: any;
}
export const getPolygons = async (
  records: any,
  base: [number, number],
): Promise<PolygonReturns> => {
  let fieldFeature = null;
  try {
    // const { organization } = userDetails;
    // const response = await getPolygonsData(
    //   organization.api_url,
    //   organization.farm.id,
    // );

    // let { records } = polyList;
    const polygonFeatures: Feature<any>[] = [];
    const greenPolygonFeatures: Feature<any>[] = [];
    const whitePolygonFeatures: Feature<any>[] = [];
    const grayPolygonFeatures: Feature<any>[] = [];
    const bluePolygonFeatures: Feature<any>[] = [];
    const polygonsList: MapFeature[] = [];
    let coordinates: any = [];
    records = records && records.length > 0 ? records : [];
    records.map((poly: any) => {
      const id = `polygon_${poly.id}`;
      coordinates = [];
      const { color } = poly;
      const satelightRgbColor = getSatelightRGBColor(color);
      const roadMapRgbColor = getRoadMapRGBColor(color);
      const satelightRgbFillColor = getSatelightRGBFillColor(color);
      const roadMapRgbFillColor = getRoadMapRGBFillColor(color);
      poly.vertices.map((point: any) => {
        if (point.latitude !== null && point.longitude !== null) {
          coordinates.push(proj.fromLonLat([point.longitude, point.latitude]));
        } else {
          point.xNew = point.x / 100;
          point.yNew = point.y / 100;
          const latAndLong: [number, number] = getLatitudeAndLongitude(
            [point.yNew, point.xNew],
            base,
          );
          coordinates.push(proj.fromLonLat([latAndLong[1], latAndLong[0]]));
        }
      });

      const mapPolygon = new Feature({
        type: 'Polygon',
        geometry: new Polygon([coordinates]),
      });

      mapPolygon.setId(id);
      // mapPolygon.set('name', poly.name);
      const zIndex = priority.POLYGON[color];

      const satelightPolyStyle = new Style({
        fill: new Fill({
          color:
            color === 'white' ? 'rgba(255,255,255,0.1)' : satelightRgbFillColor,
        }),
        stroke: new Stroke({
          color: satelightRgbColor,
          width: color == 'white' ? 4 : 3,
        }),
        text: new Text({
          font: '16px Montserrat-SemiBold',
          fill: new Fill({ color: '#000' }),
          stroke: new Stroke({
            color: '#fff',
            width: 2,
          }),
          text: '',
        }),
        zIndex,
      });
      const roadMapPolyStyle = new Style({
        fill: new Fill({
          color:
            color === 'white' ? 'rgba(255,255,255,0.1)' : roadMapRgbFillColor,
        }),
        stroke: new Stroke({
          color: roadMapRgbColor,
          width: color === 'white' ? 6 : 3,
        }),
        text: new Text({
          font: '16px Montserrat-SemiBold',
          fill: new Fill({ color: '#000' }),
          stroke: new Stroke({
            color: '#fff',
            width: 2,
          }),
          text: '',
        }),
        zIndex,
      });

      mapPolygon.setStyle(satelightPolyStyle);
      mapPolygon.set('satelightPolyStyle', satelightPolyStyle);
      mapPolygon.set('roadMapPolyStyle', roadMapPolyStyle);
      mapPolygon.set('label', poly.name);
      if (color === 'white') {
        fieldFeature = mapPolygon;
        whitePolygonFeatures.push(mapPolygon);
      }

      if (color === 'green') {
        greenPolygonFeatures.push(mapPolygon);
      }

      if (color === 'gray') {
        grayPolygonFeatures.push(mapPolygon);
      }
      if (color === 'blue') {
        bluePolygonFeatures.push(mapPolygon);
      }
      polygonFeatures.push(mapPolygon);
      if (
        color === 'white' ||
        color === 'green' ||
        color === 'gray' ||
        color === 'blue'
      ) {
        polygonsList.push({
          id: poly.id,
          name: poly.name,
          type: POLYGON,
          mapId: id,
          visible: true,
          style: satelightPolyStyle,
          color: poly.color,
          vertices: poly.vertices,
          polygon_uuid: poly.polygon_uuid,
        });
      }
    });

    const polygonsFeaturesLayer = new VectorLayer({
      source: new VectorSource({
        features: polygonFeatures,
      }),
      visible: true,
    });

    const greenPolygonsFeaturesLayer = new VectorLayer({
      source: new VectorSource({
        features: greenPolygonFeatures,
      }),
      visible: true,
    });

    const whitePolygonsFeaturesLayer = new VectorLayer({
      source: new VectorSource({
        features: whitePolygonFeatures,
      }),
      visible: true,
    });

    const grayPolygonsFeaturesLayer = new VectorLayer({
      source: new VectorSource({
        features: grayPolygonFeatures,
      }),
      visible: true,
    });
    const bluePolygonsFeaturesLayer = new VectorLayer({
      source: new VectorSource({
        features: bluePolygonFeatures,
      }),
      visible: true,
    });
    greenPolygonsFeaturesLayer?.setZIndex(priority.POLYGON.green);
    whitePolygonsFeaturesLayer?.setZIndex(priority.POLYGON.white);
    grayPolygonsFeaturesLayer?.setZIndex(priority.POLYGON.gray);
    bluePolygonsFeaturesLayer?.setZIndex(priority.POLYGON.blue);

    return {
      layer: polygonsFeaturesLayer,
      list: polygonsList,
      fieldFeature,
      greenLayer: greenPolygonsFeaturesLayer,
      whiteLayer: whitePolygonsFeaturesLayer,
      grayLayer: grayPolygonsFeaturesLayer,
      blueLayer: bluePolygonsFeaturesLayer,
    };
  } catch (error: any) {
    notification.error({
      message: error.message,
    });
    return {
      layer: null,
      list: [],
      fieldFeature: null,
      greenLayer: null,
      whiteLayer: null,
      grayLayer: null,
      blueLayer: null,
    };
  }
};
