/* eslint-disable no-console */
import React, { useContext, useEffect, useRef, useState } from 'react';

import { Spin, notification } from 'antd';
import faultyTractorIcon from '../../assets/images/faultyTractorIcon.svg';
import { getGuardRailsImages } from '../../constants/Api';
import { GuardRailImage } from '../../constants/types';
import { ApplicationContext } from '../../context/AppContext';
import AutodriveSnapshotPopup from './AutodriveSnapshotPopup';

import loading_logo from '../../assets/images/loading_logo.gif';

const INTERVAL = 5000;
const API_COUNT = 10;
interface Props {
  guardrailId: string;
  loader?: boolean;
}
const GuardRailsImages: React.FC<Props> = ({ guardrailId, loader }: Props) => {
  const { userDetails } = useContext(ApplicationContext);
  const [snapPop, setSnapPop] = useState(false);

  const [image, setImage] = useState<GuardRailImage>();
  const interval = useRef<any>(null);
  const counter = useRef<number>(0);
  const [imgLoader, setImgLoader] = useState<boolean>(true);
  const [imageSuccess, setImageSuccess] = useState(true);

  useEffect(() => {
    const getHitchImage = async () => {
      try {
        setImgLoader(true);
        setImageSuccess(true);
        const snap = await getGuardRailsImages(
          userDetails.organization.api_url,
          userDetails.organization.farm.id,
          guardrailId,
        );
        if (snap?.is_image_available || counter.current === API_COUNT) {
          clearInterval(interval.current);
          setImgLoader(false);
        }
        if (snap?.is_image_available && snap.image_url) {
          setImage(snap);
          setImgLoader(false);
        }
      } catch (error: any) {
        notification.error({
          message: error.response?.data?.error?.message || error.message,
        });
      }
    };
    const initialize = () => {
      interval.current = setInterval(() => {
        if (counter.current === API_COUNT) {
          clearInterval(interval.current);
          return;
        }
        counter.current += 1;
        getHitchImage();
      }, INTERVAL);
    };
    if (userDetails && userDetails.organization && guardrailId) initialize();
    return () => clearInterval(interval.current);
  }, [guardrailId, userDetails]);

  return (
    <>
      {loader && imgLoader && (
        <>
          <Spin
            className="loader"
            spinning={imgLoader}
            indicator={
              <img
                style={{ width: '40px', height: '40px' }}
                src={loading_logo}
              />
            }
          />
        </>
      )}
      {image && image.is_image_available && imageSuccess && (
        <>
          <div className="snapshotsBox" onClick={() => setSnapPop(true)}>
            <div className="camName">
              <span className="camErrIcon">
                <img src={faultyTractorIcon} alt="" height="13" />
              </span>
              <span className="snapCamName">{image.camera}</span>
            </div>
            <img
              src={image.image_url}
              height="45"
              alt=""
              className="snapImg"
              onError={() => setImageSuccess(false)}
            />
            <i className="eyeIcon"></i>
          </div>
          {snapPop && (
            <AutodriveSnapshotPopup
              image={image}
              snapPop={snapPop}
              setSnapPop={setSnapPop}
              guardrailId={Number(guardrailId)}
            />
          )}
        </>
      )}
      {/* {loader &&
        !imgLoader &&
        (!image?.is_image_available || !imageSuccess) && (
          <span>Snapshot Unavailable</span>
        )} */}
    </>
  );
};

export default GuardRailsImages;
