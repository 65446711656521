export const getMinSecFormat = (time: number) => {
  if (isNaN(time)) return '00:00';
  const minutes = Math.floor(time / 60);
  const seconds = time - minutes * 60;
  function str_pad_left(string: number, pad: string, length: number) {
    return (new Array(length + 1).join(pad) + string).slice(-length);
  }
  const finalTime =
    str_pad_left(minutes, '0', 2) + ':' + str_pad_left(seconds, '0', 2);
  return finalTime;
};

export const wrapText = (value: number, digits = 2) => {
  return !isNaN(value) ? Number(value).toFixed(digits) : 0;
};

export function secondsToHms(d: number) {
  d = Number(d);
  const h = Math.floor(d / 3600);
  const m = Math.floor((d % 3600) / 60);
  const s = Math.floor((d % 3600) % 60);

  const hDisplay = h > 0 ? h + (h == 1 ? ' hr, ' : ' hrs, ') : '';
  const mDisplay = m > 0 ? m + (m == 1 ? ' min, ' : ' mins, ') : '';
  const sDisplay = s > 0 ? s + (s == 1 ? ' sec' : ' secs') : '';
  return hDisplay + mDisplay + sDisplay;
}

export const appendSort = (url: string, sortInfo: any) => {
  const searchParams = new URLSearchParams(sortInfo);
  return `${url}&${searchParams}`;
};
