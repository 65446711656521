/* eslint-disable no-console */
import React from 'react';
import poor_wifi from '../../assets/images/poor_wifi.svg';
import { CommonProgressBar } from './CommonProgressBar';
import constant from '../../constants/constant';
import fullWifi from '../../assets/images/wFull.svg';
import lowWifi from '../../assets/images/wLow.svg';
import mediumWifi from '../../assets/images/wMedium.svg';
import sch_info_icon from '../../assets/images/sch_info_icon.svg';
import sch_info_icon_gray from '../../assets/images/sch_info_icon_gray.svg';

const { TRACTOR_COMMUNICATION } = constant;
interface Props {
  filterData: any;
}

export const OutOfDateContainer: React.FC<Props> = ({ filterData }) => {
  const outOfDate = () =>
    filterData.filter(
      (record: any) =>
        (record?.ota_status === 'out_of_date' ||
          record?.ota_status === 'sw_fw_mismatch') &&
        record?.otaDownloadStatus &&
        record?.otaDownloadPercentage < 100,
    );

  const getConnectivityImg = (state: number) => {
    const img =
      state === 0 || state === 1
        ? fullWifi
        : state === 2
        ? mediumWifi
        : lowWifi;

    return img;
  };

  return (
    <div className="mainContainers1">
      <div className="tractorContainer padding1620">
        <div className="outOfTxt">Out of Date</div>
        <div className="tractorsLength">{outOfDate().length}</div>
      </div>
      <div className="outBoxContainer">
        {outOfDate().length > 0 ? (
          <>
            {outOfDate().map((d: any) => (
              <div className="outOfBox" key={d.id}>
                <div className="swFwTxt">
                  <div className="tractorContainer mb8">
                    <div className="numberTxt">{d?.name}</div>
                    {d.connection === 'offline' ? (
                      <img src={poor_wifi} alt="" />
                    ) : (
                      <img
                        className="indicatorImg"
                        src={getConnectivityImg(
                          d.indicators[TRACTOR_COMMUNICATION],
                        )}
                      />
                    )}
                  </div>

                  <div className="swUpdateTxt">
                    <span>Software</span> {d?.softwareVersion}
                  </div>
                  <div className="swUpdateTxt">
                    <span>Firmware</span> {d?.firmWareVersion}
                  </div>
                </div>
                {d.connection === 'offline' && (
                  <div className="internetTxt">
                    <div className="grayColor">
                      <img src={sch_info_icon_gray} alt="" height="14px" /> Need
                      internet to download update
                    </div>
                    {d?.otaDownloadPercentage > 0 && (
                      <CommonProgressBar data={d} />
                    )}

                    <div className="ud_i_tooltip">
                      The update can download when the tractor is online with no
                      one logged in and has good internet connection. Your
                      progress is saved and will resume from the last
                      checkpoint.
                    </div>
                  </div>
                )}
                {d.connection === 'online' && (
                  <>
                    <>
                      {d.ota_status === 'sw_fw_mismatch' ? (
                        <>
                          <div className="backgroundInfo">
                            <div className="ud_i_tooltip redBdr">
                              Mismatch of software and firmware on the tractor.
                              A service ticket has been created, and Monarch
                              will provide further instructions shortly.
                            </div>

                            <img src={sch_info_icon} alt="" height="14px" />
                            <div className="ml5">Update Incomplete</div>
                          </div>
                        </>
                      ) : (
                        <>
                          {d.ota_download_status === 'paused' ? (
                            <div className="internetTxt selectedDateTime">
                              <div className="grayColor">
                                <img
                                  src={sch_info_icon_gray}
                                  alt=""
                                  height="14px"
                                />{' '}
                                Download Paused{' '}
                              </div>
                              <div className="grayColor">
                                {d?.otaDownloadPercentage !== 0 &&
                                  `${d?.otaDownloadPercentage}%`}
                              </div>

                              <div className="ud_i_tooltip">
                                Downloading pauses when someone has logged in to
                                the tractor
                              </div>
                            </div>
                          ) : (
                            <>
                              {d.ota_download_status === 'pending' ? (
                                <div className="internetTxt selectedDateTime">
                                  <div className="grayColor">
                                    <img
                                      src={sch_info_icon_gray}
                                      alt=""
                                      height="14px"
                                    />{' '}
                                    Download not started{' '}
                                  </div>
                                </div>
                              ) : (
                                <div className="internetTxt">
                                  <div style={{ color: '#1890ff' }}>
                                    {' '}
                                    Downloading...{' '}
                                  </div>
                                  <CommonProgressBar data={d} />
                                </div>
                              )}{' '}
                            </>
                          )}
                        </>
                      )}
                    </>
                  </>
                )}
              </div>
            ))}
          </>
        ) : (
          <div className="outOfBoxContent">
            All your tractors are ready to be installed or up to date
          </div>
        )}
      </div>
    </div>
  );
};
