/* eslint-disable react/jsx-key */
import { Checkbox, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { FilterOutlined } from '@ant-design/icons';

import tablesColumnsMap from '../../../lib/extCol';

import './style.scss';

interface LabelValueType {
  label: string | React.ReactNode;
  value: string;
}

interface Props {
  columnsData: any[];
  allColLabel?: any;
  defaultColumns?: string[];
  // eslint-disable-next-line no-unused-vars
  handleCallback: (columns: any[]) => void;
  toggleColumnsStatus?: boolean;
  tableName?: string;
}

const ExtCol: React.FC<Props> = ({
  columnsData,
  allColLabel,
  defaultColumns,
  handleCallback,
  toggleColumnsStatus,
  tableName = '',
}) => {
  const { defaults } = tablesColumnsMap[tableName] || {};
  const [toggle, setToggle] = useState<boolean>(false);
  const [selected, setSelected] = useState<string[]>(
    defaultColumns || defaults,
  );

  const options: any[] =
    (allColLabel &&
      Object.keys(allColLabel).map((item) => ({
        label: allColLabel[item],
        value: item,
      }))) ||
    columnsData.map((item: any) => ({
      label: item.title,
      value: item.dataIndex,
    }));

  useEffect(() => {
    setToggle(false);
  }, [toggleColumnsStatus]);

  const handleFilter = (colFilters: string[]) => {
    const tcolumns: any = columnsData.filter((column: any) =>
      colFilters.includes(column.key),
    );
    handleCallback(tcolumns);
  };

  const handleOnChange = (e: any) => {
    let tSelected: string[] = selected;
    if (e.target.checked) {
      tSelected = [...selected, e.target.value];
      setSelected(tSelected);
    } else {
      tSelected = selected.filter((item: any) => item !== e.target.value);
      setSelected(tSelected);
    }
    handleFilter(tSelected);
  };

  return (
    <div className={toggle ? 'ext-widget active' : 'ext-widget'}>
      <div
        // className="tblColumnSec"
        data-testid="columnsDropdown-ExtCol"
        onClick={(e) => {
          e.stopPropagation();
          setToggle(!toggle);
        }}
      >
        <div className="filterIcon">
          <span className="filter_icon"></span>
        </div>
      </div>
      {toggle && (
        <ul
          className="ext-dropdown"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {options?.map((item: LabelValueType) => (
            <li>
              <Checkbox
                value={item.value}
                data-testid={`${item.value}-ExtCol`}
                onChange={handleOnChange}
                checked={selected.includes(item.value)}
              />
              {typeof item.label === 'string' && item.label?.length > 10 ? (
                <Tooltip title={item.label}>{item.label}</Tooltip>
              ) : (
                <span>{item.label}</span>
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default ExtCol;
