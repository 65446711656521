/* eslint-disable no-console */
import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import $ from 'jquery';
import { DatePicker } from 'antd';
import cal_img from '../images/taskplanner-outline01.svg';

import './styles.scss';

interface Props {
  handleOnChange: (dates: any) => void;
  selectedDate: any;
  showToday?: boolean;
}

const CustomDatePicker: React.FC<Props> = ({
  handleOnChange,
  selectedDate,
  showToday = true,
}) => {
  const [toggle, setToggle] = useState(false);
  const wrapperRef: any = useRef(null);
  const [date, setDate] = useState<any>(null);

  useEffect(() => {
    if (toggle) setDate(selectedDate);
  }, [selectedDate, toggle]);

  const onChange = (dates: any) => {
    if (dates) {
      setDate(dates);
      handleOnChange(dates);
    } else {
      setDate(null);
      handleOnChange(null);
    }
    togglePicker();
  };

  const togglePicker = () => {
    setToggle(!toggle);
  };

  function disabledDate(current: any) {
    // return (
    //   [0, 6].includes(moment(current).day()) || !current.isBefore(moment())
    // );
    return !current.isBefore(moment());
  }

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    const handleClickOutside = (event: any) => {
      if (toggle && !$(event.target).parents('.ant-picker-dropdown')?.length) {
        if (!$(event.target).parents('.date-picker').length) setToggle(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [toggle]);

  return (
    <div className={toggle ? 'dhover date-picker' : 'date-picker'}>
      <div ref={wrapperRef} id="datePicker">
        <DatePicker
          value={date}
          className="hideDatePicker"
          dropdownClassName="singleMonth"
          disabledDate={disabledDate}
          open={toggle}
          onChange={onChange}
          showToday={showToday}
        />
        {/* <img onClick={togglePicker} src={cal_img} alt="calendar" /> */}
        <div
          className={toggle ? 'dpIconhover' : 'dpIcon'}
          onClick={togglePicker}
        ></div>
      </div>
    </div>
  );
};

export default CustomDatePicker;
