import { Button } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { useContext, useEffect, useState } from 'react';

import X_icon from '../../assets/images/X_icon.svg';
import heavy_feed_amount_icon from '../../assets/images/heavy.svg';
import light_feed_amount_icon from '../../assets/images/light.svg';
import medium_feed_amount_icon from '../../assets/images/medium.svg';
import {
  HEAVY_VALUE,
  LIGHT_VALUE,
  MEDIUM_VALUE,
} from '../../constants/constant';
import { FeedLaneType, feedAmountValueType } from '../../lib/types';
import { RemoteDriveAppCtx } from '../remote_drive_new/RemoteDriveContext';
import { SET_SELECTED_FEED_LANES } from '../remote_drive_new/actions';

interface Props {
  readonly showUpdateFeed: boolean;
  setShowUpdateFeed: (state: boolean) => void;
  selectedFeedLane: FeedLaneType;
  selectedFeedLanes: FeedLaneType[];
  setSelectedFeedLane: (feedLanes: FeedLaneType) => void;
  setShowMixed: (state: boolean) => void;
}

const FeedUpdate: React.FC<Props> = ({
  showUpdateFeed,
  setShowUpdateFeed,
  selectedFeedLane,
  selectedFeedLanes,
  setSelectedFeedLane,
  setShowMixed,
}: Props) => {
  const { RDReducer } = useContext(RemoteDriveAppCtx);
  const [, dispatch] = RDReducer;
  const [popFeedAmount, setPopFeedAmount] =
    useState<feedAmountValueType>(MEDIUM_VALUE);

  const handleSingleFeedAmountChange = () => {
    if (selectedFeedLane && selectedFeedLane.id) {
      let stateUpdated = false;
      const updatedArray = selectedFeedLanes.map((feedLane: FeedLaneType) => {
        if (feedLane.id === selectedFeedLane.id) {
          stateUpdated = true;
          return { ...feedLane, feedAmount: popFeedAmount };
        }
        return feedLane;
      });
      if (stateUpdated && selectedFeedLane) {
        const item = { ...selectedFeedLane, feedAmount: popFeedAmount };
        setSelectedFeedLane(item);
      }
      stateUpdated &&
        dispatch({
          type: SET_SELECTED_FEED_LANES,
          payload: updatedArray,
        });
      stateUpdated && setShowMixed(true);
      setShowUpdateFeed(false);
    }
  };
  useEffect(() => {
    if (selectedFeedLane && selectedFeedLane.id) {
      setPopFeedAmount(selectedFeedLane?.feedAmount || MEDIUM_VALUE);
    }
  }, [selectedFeedLane]);
  return (
    <Modal
      visible={showUpdateFeed}
      closable={false}
      footer={false}
      className="feed_amount"
    >
      <div className="cross_close">
        <img src={X_icon} onClick={() => setShowUpdateFeed(false)} />
      </div>
      <div className="popup_head2">
        <span className="head_text">Feed Amount</span>
        <p>{selectedFeedLane?.name}</p>
      </div>
      <p className="popup_desc_text">
        Set the distance from the fence by defining the amount of feed in this
        lane.
      </p>
      <div className="p_lanes_tabs_section">
        <div
          className={`p_lanes_tab ${
            popFeedAmount === HEAVY_VALUE ? 'sel' : ''
          }`}
          onClick={() => setPopFeedAmount(HEAVY_VALUE)}
        >
          <img src={heavy_feed_amount_icon} className="p_tab_icon" />
          <p className="p_tab_name">Heavy</p>
        </div>
        <div
          className={`p_lanes_tab ${
            popFeedAmount === MEDIUM_VALUE ? 'sel' : ''
          }`}
          onClick={() => setPopFeedAmount(MEDIUM_VALUE)}
        >
          <img src={medium_feed_amount_icon} className="p_tab_icon" />
          <p className="p_tab_name">Medium</p>
        </div>
        <div
          className={`p_lanes_tab ${
            popFeedAmount === LIGHT_VALUE ? 'sel' : ''
          }`}
          onClick={() => setPopFeedAmount(LIGHT_VALUE)}
        >
          <img src={light_feed_amount_icon} className="p_tab_icon" />
          <p className="p_tab_name">Light</p>
        </div>
      </div>
      <div className="feed_amount_feet_sec">
        <p className="feed_feet_txt">{popFeedAmount} foot from fence</p>
      </div>
      <div className="button_sec evenly">
        <Button
          key="edit"
          className="green_btn"
          onClick={handleSingleFeedAmountChange}
        >
          Save
        </Button>
      </div>
    </Modal>
  );
};

export default FeedUpdate;
