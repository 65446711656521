import React, { useState } from 'react';
import { CheckOutlined, MoreOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { useTranslation } from 'react-i18next';
import translate from '../../locale/en_translate.json';

interface Props {
  selectSpeed: (state: number, e: any) => void;
  speed: string;
}
const PlayBackSpeed: React.FC<Props> = ({ selectSpeed, speed }) => {
  const { t } = useTranslation();
  const [openMenu, setOpenMenu] = useState(false);
  const [speedMenu, setSpeedMenu] = useState(false);

  const onClickSpeedMenu = () => {
    setOpenMenu(false);
    setSpeedMenu(true);
  };

  const handleSelect = (speed: number, e: any) => {
    setSpeedMenu(false);
    selectSpeed(speed, e);
  };

  return (
    <div className="moreDiv">
      <div onClick={() => setOpenMenu(true)}>
        <MoreOutlined />
      </div>
      {openMenu && (
        <Menu>
          <Menu.Item key="playback speed" onClick={onClickSpeedMenu}>
            {t(translate.analyticContainer.playbackSpeed)}
          </Menu.Item>
        </Menu>
      )}
      {speedMenu && (
        <Menu className="">
          <Menu.Item key="0.25" onClick={(e) => handleSelect(0.25, e)}>
            0.25
            {speed === '0.25' && <CheckOutlined className="checkmark" />}
          </Menu.Item>
          <Menu.Item key="0.5" onClick={(e) => handleSelect(0.5, e)}>
            0.5
            {speed === '0.5' && <CheckOutlined className="checkmark" />}
          </Menu.Item>
          <Menu.Item key="0.75" onClick={(e) => handleSelect(0.75, e)}>
            0.75
            {speed === '0.75' && <CheckOutlined className="checkmark" />}
          </Menu.Item>
          <Menu.Item key="1" onClick={(e) => handleSelect(1, e)}>
            Normal
            {speed === '1' && <CheckOutlined className="checkmark" />}
          </Menu.Item>
          <Menu.Item key="1.25" onClick={(e) => handleSelect(1.25, e)}>
            1.25
            {speed === '1.25' && <CheckOutlined className="checkmark" />}
          </Menu.Item>
          <Menu.Item key="1.5" onClick={(e) => handleSelect(1.5, e)}>
            1.5
            {speed === '1.5' && <CheckOutlined className="checkmark" />}
          </Menu.Item>
          <Menu.Item key="1.75" onClick={(e) => handleSelect(1.75, e)}>
            1.75
            {speed === '1.75' && <CheckOutlined className="checkmark" />}
          </Menu.Item>
          <Menu.Item key="2" onClick={(e) => handleSelect(2, e)}>
            2{speed === '2' && <CheckOutlined className="checkmark" />}
          </Menu.Item>
        </Menu>
      )}
    </div>
  );
};

export default PlayBackSpeed;
