/* eslint-disable react/jsx-key */
import React, { useState } from 'react';
import { getSummary } from '../../constants/types';

import { LeftOutlined } from '@ant-design/icons';

import { CommonRightSchedule } from './CommonRightSchedule';
import { CommonReleaseNotes } from './CommonReleaseNotes';
import { InstallNowLeft } from './InstallNowLeft';
import { Spin } from 'antd';

interface Props {
  closeModal: () => void;
  closeAndUpdate: () => void;
  summaryList: getSummary;
  tractorIds: any;
}

export const InstallNow: React.FC<Props> = ({
  closeModal,
  closeAndUpdate,
  summaryList,
  tractorIds,
}: Props) => {
  const [seeAll, setSeeAll] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);

  return (
    <>
      {!seeAll ? (
        <>
          <div className="scheduleFleet">
            <div
              className="updateArrow"
              onClick={() => {
                closeModal();
              }}
            >
              <LeftOutlined />
            </div>
            <div className="otaHead">Confirm Installation</div>
          </div>
          <div className="updateVersionTxt">
            You will not be able to operate the tractors or use the Smart Screen
            during that time. All requirements must be met for the tractors to
            update.
          </div>

          <div className="scheduleGrid">
            <InstallNowLeft
              closeAndUpdate={closeAndUpdate}
              tractorIds={tractorIds}
              setLoader={setLoader}
            />
            <CommonRightSchedule
              summaryList={summaryList as any}
              setSeeAll={setSeeAll}
              text="Install"
            />
          </div>
          <div className="edit-loader">
            {loader && (
              <div className="loader">
                <Spin size="large" />
              </div>
            )}
          </div>
        </>
      ) : (
        <CommonReleaseNotes
          summaryList={summaryList as any}
          close={() => setSeeAll(false)}
        />
      )}
    </>
  );
};
