/* eslint-disable react/jsx-no-duplicate-props */
import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import classNames from 'classnames';

interface Props {
  label?: string;
  value?: any;
  cssClass?: string;
  setValue?: (data: any) => void;
  options?: any[];
  optionKey?: string;
  optionDisplay?: string;
  optionDisplay2?: string;
  jsonFormat?: boolean;
  required?: boolean;
  validations?: any;
}

const CustomSelectMulti: React.FC<Props> = ({
  label,
  value,
  setValue,
  cssClass,
  options,
  optionKey = 'id',
  optionDisplay = 'name',
  optionDisplay2,
  jsonFormat,
  required,
  validations,
}: Props) => {
  const [focus, setFocus] = useState(false);

  const isEmpty = (value: any) => {
    if (typeof value === 'undefined') return true;
    if (typeof value === 'string' && value.trim() === '') return true;
    if (typeof value === 'number' && isNaN(value)) return true;
    return false;
  };
  const labelClass = focus || !isEmpty(value) ? 'label label-float' : 'label';

  return (
    <div
      className="float-label"
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      <Select
        getPopupContainer={(triggerNode) => triggerNode.parentNode}
        style={{ width: '100%' }}
        value={value}
        onSelect={(e: any) => {
          setValue && setValue(e);
        }}
        className={
          !validations && required
            ? classNames({
                errorClass: !validations && required,
              })
            : cssClass
        }
      >
        {options &&
          options?.length > 0 &&
          options?.map((data: any) => {
            return (
              <option
                value={jsonFormat ? JSON.stringify(data) : data[optionKey]}
                key={data[optionKey]}
              >
                {data[optionDisplay] == 'No Implement' ? (
                  <>{optionDisplay ? data[optionDisplay] : data[optionKey]}</>
                ) : (
                  <div className="selectOptionTwoSide">
                    <div className="optl">
                      {optionDisplay ? data[optionDisplay] : data[optionKey]}
                    </div>
                    {optionKey !== 'none' && (
                      <div className="optr">
                        {optionDisplay2
                          ? data[optionDisplay2]
                          : data[optionKey]}
                      </div>
                    )}
                  </div>
                )}
              </option>
            );
          })}
      </Select>
      <label className={labelClass}>
        {label} {required && <span className="float-span">*</span>}
      </label>
    </div>
  );
};

export default CustomSelectMulti;
