import { useContext, useEffect, useState } from 'react';
import { ApplicationContext } from '../../../context/AppContext';
import { getArticle } from '../services/API';
import { notification } from 'antd';

const useArticles = () => {
  const [loader, setLoader] = useState(false);
  const { userDetails } = useContext(ApplicationContext);
  const [data, setData] = useState([]);
  const [subCategoryId, setSubCategoryId] = useState<number>(0);

  useEffect(() => {
    const init = async () => {
      try {
        setData([]);
        setLoader(true);
        const { organization } = userDetails;
        const { api_url } = organization;
        const result = await getArticle(api_url, subCategoryId);
        const data = Array.isArray(result?.data) ? result?.data : [];
        setData(data);
      } catch (error: any) {
        notification.error({ message: error.message });
      } finally {
        setLoader(false);
      }
    };
    if (userDetails?.organization && subCategoryId) init();
  }, [userDetails, subCategoryId]);

  return { loader, data, setSubCategoryId };
};

export default useArticles;
