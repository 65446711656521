import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Modal, notification, Spin, Steps } from 'antd';
import { useTranslation } from 'react-i18next';
import { PubSub } from 'aws-amplify';
import {
  saveTractorMigration,
  getTractorHealthReport,
  checkNetworkSpeed,
} from '../API';
import Overview, { TractorReport } from './Overview';
import translate from '../../../locale/en_translate.json';
import { ApplicationContext } from '../../../context/AppContext';
import Logs from './Log';
import { NetworkSpeed } from '../../../constants/types';

const { Step } = Steps;

interface Props1 {
  tractroSerialNumber: string;
  sourceOrgId: string;
  next: () => void;
}

const RestartTractor: React.FC<Props1> = () => (
  <>
    <h2>
      <b>Manual Step</b>
    </h2>
    <ul>
      <li>Please Restart the tractor.</li>
    </ul>
  </>
);

interface Props {
  showModal: boolean;
  closeModel: () => void;
  selectedTractor: any;
}

export const MigrateModal: React.FC<Props> = ({
  showModal,
  closeModel,
  selectedTractor,
}: Props) => {
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const [current, setCurrent] = useState(0);
  const [migrationId, setMigrationId] = useState(0);
  const [tractorReport, setTractorReport] = useState<TractorReport>();
  const [loader, setLoader] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [iotInitialized, setIotInitialized] = useState(false);
  const [serviceCheckFailed, setServiceCheckFailed] = useState(true);
  const [checkSpeed, setServiceCheckSpeed] = useState<boolean>(true);
  const serviceCheckFailedRef = useRef(true);
  const [showServiceCheckFailure, setShowServiceCheckFailure] = useState(false);
  const [speed, setSpeed] = useState<NetworkSpeed>();

  useEffect(() => {
    if (
      selectedTractor?.migration?.status &&
      selectedTractor?.migration?.status === 'Inprogress'
    ) {
      setMigrationId(selectedTractor.migration?.id);
      setCurrent(current + 1);
    }
  }, [selectedTractor]);

  useEffect(() => {
    if (speed) {
      setServiceCheckSpeed(false);
    }
  }, [speed]);

  useEffect(() => {
    setTimeout(() => {
      if (serviceCheckFailedRef.current) {
        setShowServiceCheckFailure(true);
      }
    }, 20000);
  }, []);

  useEffect(() => {
    PubSub.subscribe('tractor_mqtt_connection_ack_to_nexus').subscribe({
      next: (data) => {
        setServiceCheckFailed(false);
        setShowServiceCheckFailure(false);
        serviceCheckFailedRef.current = false;
      },
    });

    PubSub.subscribe('tractor_network_check_report_to_nexus').subscribe({
      next: (data) => {
        const networkData: NetworkSpeed = data.value;
        setSpeed(networkData);
      },
    });
    setIotInitialized(true);
  }, []);

  const next = () => {
    if (current === 0) {
      migrateMethod();
    } else if (current === 2) {
      Modal.warning({
        title: 'Warning',
        content:
          'Migration procress will not be completed without configuring VPN',
        onOk: () => setCurrent(3),
      });
    } else {
      setCurrent(current + 1);
    }
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const steps = [
    {
      title: 'Overview',
      content: (
        <div>
          <Overview
            selectedTractor={selectedTractor}
            tractorReport={tractorReport as TractorReport}
            reTestSpeed={() => reTestSpeed()}
            speed={speed as NetworkSpeed}
            showServiceCheckFailure={showServiceCheckFailure}
          />
        </div>
      ),
    },
    {
      title: 'Migrate',
      content: (
        <div style={{ textAlign: 'left' }}>
          <Logs
            migrationId={migrationId}
            handleDisable={(e) => setIsDisabled(e)}
          />
        </div>
      ),
    },

    {
      title: 'Restart Tractor',
      content: (
        <RestartTractor
          tractroSerialNumber={selectedTractor.serial_number}
          sourceOrgId={selectedTractor.organization.id}
          next={() => next()}
        />
      ),
    },
  ];

  const migrateMethod = async () => {
    const payload = {
      target_organization_id: selectedTractor.target_organization_id,
      source_organization_id: selectedTractor.organization.id,
    };
    try {
      const response = await saveTractorMigration(
        selectedTractor.serial_number,
        payload,
      );
      setMigrationId(response.id);
      setCurrent(current + 1);
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    }
  };

  useEffect(() => {
    if (userDetails && userDetails.organization && iotInitialized) {
      setTimeout(() => {
        getTractorHealthReports();
        reTestSpeed();
      }, 2000);
    }
  }, [userDetails, iotInitialized]);

  const getTractorHealthReports = async () => {
    try {
      setLoader(true);
      if (selectedTractor.migration?.status !== 'Inprogress') {
        const records = await getTractorHealthReport(
          selectedTractor.serial_number,
          selectedTractor.organization?.id,
          selectedTractor.target_organization_id,
          selectedTractor.id,
        );
        setIsDisabled(
          records.connection_report.status_code !== 1006 ||
            records.mt_fleet_software_health_report?.status_code !== 2001,
        );
        setTractorReport(records);
      }
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    } finally {
      setLoader(false);
    }
  };

  const reTestSpeed = () => {
    setSpeed(undefined);
    checkNetworkSpeed(
      selectedTractor.serial_number,
      selectedTractor.organization?.id,
    );
  };

  return (
    <Modal
      className="tm-modal migrateContainer"
      title={selectedTractor?.name}
      visible={showModal}
      closable={false}
      onCancel={closeModel}
      footer={[
        <>
          {current < steps.length - 1 && (
            <Button
              type="primary"
              onClick={() => next()}
              disabled={isDisabled || checkSpeed}
            >
              {current === 0 ? 'Start Migration' : 'Next'}
            </Button>
          )}
          {current === steps.length - 1 && (
            <Button type="primary" onClick={() => closeModel()}>
              {t(translate.buttons.done)}
            </Button>
          )}
          {current > 1 && (
            <Button className="btnCancel" onClick={() => prev()}>
              {t(translate.buttons.previous)}
            </Button>
          )}
          {current !== 3 && (
            <Button className="btnCancel" onClick={() => closeModel()}>
              {current === 0 ? 'Close' : 'Minimize'}
            </Button>
          )}
        </>,
      ]}
    >
      <Steps current={current}>
        {steps.map((data) => (
          <Step key={data.title} title={data.title} />
        ))}
      </Steps>
      <div className="stepsContent">{steps[current].content}</div>

      <div className="edit-loader">
        {loader && (
          <div className="loader1">
            <Spin />
          </div>
        )}
      </div>
    </Modal>
  );
};
