import React, { useEffect } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

am4core.useTheme(am4themes_animated);

interface BarChartProps {
  data: any;
  loader: boolean;
  chartId: string;
  yTitle: string;
  xTitle?: string;
}

const BarChart: React.FC<BarChartProps> = ({
  data,
  chartId,
  loader,
  yTitle,
  xTitle,
}) => {
  useEffect(() => {
    // Create chart instance
    const chart: am4charts.XYChart = am4core.create(chartId, am4charts.XYChart);
    chart.logo.disabled = true;

    // Add data
    chart.data = data;

    // Create axes
    const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'category';
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;
    if (xTitle) categoryAxis.title.text = xTitle;
    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = yTitle;

    categoryAxis.renderer.labels.template.fontSize = 10;
    categoryAxis.renderer.labels.template.fill = am4core.color('#1F1F1F');
    categoryAxis.renderer.labels.template.fontFamily = 'Montserrat-Regular';
    categoryAxis.title.fontSize = 10;
    categoryAxis.title.fill = am4core.color('#333333');
    categoryAxis.renderer.labels.template.rotation =
      data?.length >= 10 ? -45 : 0;
    categoryAxis.renderer.labels.template.textAlign = 'start';
    categoryAxis.title.fontFamily = 'Montserrat-Medium';
    categoryAxis.renderer.labels.template.fontSize = 10;

    valueAxis.renderer.labels.template.fontSize = 10;
    valueAxis.renderer.labels.template.fill = am4core.color('#5c5c5c');
    valueAxis.renderer.labels.template.fontFamily = 'Montserrat-Regular';
    valueAxis.title.fontSize = 10;
    valueAxis.title.fill = am4core.color('#333333');
    valueAxis.title.fontFamily = 'Montserrat-Medium';
    valueAxis.renderer.labels.template.fontSize = 10;

    categoryAxis.renderer.grid.template.strokeOpacity = 0;
    valueAxis.renderer.grid.template.strokeOpacity = 0.1;

    const markerTemplate = chart?.legend?.markers?.template;
    if (markerTemplate) {
      markerTemplate.width = 10;
      markerTemplate.height = 10;
      chart.legend.position = 'top';
      chart.legend.align = 'center';
      chart.legend.fontFamily = 'Montserrat-Regular';
      chart.legend.fontSize = 10;
    }

    // Create series
    const series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = 'value';
    series.dataFields.categoryX = 'category';
    series.name = 'Series 1';
    series.columns.template.tooltipText = '{categoryX}: [bold]{valueY}[/]';
    series.columns.template.fill = am4core.color('#67AFB7');
    series.strokeWidth = 0;
    series.columns.template.column.cornerRadiusTopLeft = 5;
    series.columns.template.column.cornerRadiusTopRight = 5;

    let indicator: any;
    let indicatorInterval: any;

    function showIndicator(chart: any) {
      if (!indicator) {
        indicator = chart.tooltipContainer.createChild(am4core.Container);
        indicator.background.fill = am4core.color('#fff');
        indicator.background.fillOpacity = 0.8;
        indicator.width = am4core.percent(100);
        indicator.height = am4core.percent(100);

        const indicatorLabel = indicator.createChild(am4core.Label);
        indicatorLabel.text = 'Loading...';
        indicatorLabel.align = 'center';
        indicatorLabel.valign = 'middle';
        indicatorLabel.fontSize = 20;
        indicatorLabel.dy = 50;
      }

      indicator.hide(0);
      indicator.show();
    }

    function hideIndicator() {
      indicator?.hide();
      clearInterval(indicatorInterval);
    }

    loader ? showIndicator(chart) : hideIndicator();

    return () => {
      if (chart) {
        chart.dispose();
      }
    };
  }, [data, loader]);

  return <div id={chartId} style={{ width: '100%', height: '350px' }} />;
};

export default BarChart;
