import axios from '../../constants/ApiInterceptor';

import { Auth } from 'aws-amplify';
import { Organization, appendSort } from './Common';
import { sortKeyWrapper } from '../../constants/Common';
// import axios from 'axios';

export const getAccessJwtToken = async () => {
  // Auth.currentSession() checks if token is expired and refreshes with Cognito if needed automatically
  const session = await Auth.currentSession();
  const access_token = await session.getIdToken().getJwtToken();
  localStorage.setItem('access_token', access_token);
  return access_token;
};
export const getHeaders = async (): Promise<{
  Authorization: string;
  Api_Token?: string;
}> => {
  await getAccessJwtToken();
  return {
    Authorization: localStorage.getItem('access_token') || '',
    Api_Token: localStorage.getItem('auth') || '',
  };
};

export const getOrgTractor = async (orgURL: string) => {
  const url = `${orgURL}monarch-digital/organization-tractor-list?category=organization,tractor`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getOrgDropdown = async (orgURL: string) => {
  const url = `${orgURL}monarch-digital/organization-tractor-list?category=organization`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getAllOrganizations = async ({
  orgURL,
  pageNumber,
  pageSize,
  search,
}: any) => {
  let url = `${orgURL}monarch-digital/dealer/organization?`;
  if (pageNumber) url += `&page_number=${pageNumber}&page_size=${pageSize}`;
  if (search) url += `&search=${search}`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getAllTractors = async (
  orgURL: string,
  customer_org_id: string[],
  pageNumber: number,
  pageSize?: number,
  search?: string,
) => {
  // monarch-digital/dealer/tractor?customer_org_id=&page_number=&page_size=
  let url = `${orgURL}monarch-digital/dealer/tractor?`;
  if (pageNumber) url += `&page_number=${pageNumber}&page_size=${pageSize}`;
  if (search) url += `&search=${search}`;
  if (customer_org_id) url += `&customer_org_id=${customer_org_id}`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getTractors = async (orgURL: string, orgId: number) => {
  const url = `${orgURL}monarch-digital/organization/${orgId}/tractor-list`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getDashBoardInfo = async (orgURL: string, orgId: number) => {
  const url = `${orgURL}monarch-digital/organization/analytics?category=${Organization}&category_id=${orgId}`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getOrganizations = async (orgURL: string, cropType: string) => {
  const url = `${orgURL}monarch-digital/${Organization}/lookup?crop_type=${encodeURI(
    cropType,
  )}`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getTractorsList = async (orgURL: string, orgId: any) => {
  let url = `${orgURL}monarch-digital/tractor/lookup`;
  if (orgId) {
    url += `?org_id=${orgId}`;
  }
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getCumulativeData = async (orgURL: string, orgId: any) => {
  let url = `${orgURL}monarch-digital/analytics/tractor-usage-summary/cumulative?`;
  if (orgId) {
    url += `&customer_org_id=${orgId}`;
  }
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const monthWiseTractorUsage = async (
  orgURL: string,
  customerOrganizationId: any,
  year?: any,
  from_month?: number,
  to_month?: number,
) => {
  let url = `${orgURL}monarch-digital/analytics/tractor-usage-summary/month-wise`;
  if (customerOrganizationId) {
    url += `?customer_org_id=${customerOrganizationId}`;
  }
  if (year) {
    url += `&year=${year}`;
  }
  if (from_month && to_month) {
    url += `&from_month=${from_month}&to_month=${to_month}`;
  }
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const weekWiseTractorUsage = async (
  orgURL: string,
  customerOrganizationId: any,
  year: any,
  from_month?: number,
  to_month?: number,
) => {
  let url = `${orgURL}monarch-digital/analytics/tractor-usage-summary/week-wise?year=${year}`;
  if (customerOrganizationId) {
    url += `&customer_org_id=${customerOrganizationId}`;
  }
  if (from_month && to_month) {
    url += `&from_month=${from_month}&to_month=${to_month}`;
  }

  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const dayWiseCumulative = async (
  orgURL: string,
  customerOrganizationId: any,
  from_date?: any,
  to_date?: any,
  // tractor_id?: any,
) => {
  let url = `${orgURL}monarch-digital/analytics/tractor-usage-summary/day-wise?cumulative_org_data=true`;
  if (customerOrganizationId) {
    url += `&customer_org_id=${customerOrganizationId}`;
  }
  // if (tractor_id) {
  //   url += `&tractor_id=${tractor_id}`;
  // }
  if (from_date && to_date) {
    url += `&from_date=${from_date}&to_date=${to_date}`;
  }
  const response = await axios.get(url, {
    headers: getHeaders(),
  });
  return response.data;
};

export const getTractorDetails = async (
  orgURL: string,
  orgId: number,
  tractorId: number,
) => {
  const url = `${orgURL}monarch-digital/organization/${orgId}/tractor/${tractorId}`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getAdminPolygonsData = async (orgURL: string) => {
  const url = `${orgURL}monarch-digital/polygon-list?color=brown,white`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getPolyCoordinates = async (
  orgURL: string,
  polygon_uuid: number,
) => {
  const url = `${orgURL}monarch-digital/polygon/${polygon_uuid}`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getTicketsSummary = async (
  orgURL: string,
  customerOrganizationId: any,
  from_date?: any,
  to_date?: any,
  // tractor_id?: any,
  category?: string,
) => {
  let url = `${orgURL}monarch-digital/organization/maintenance-tickets/summary?`;
  if (customerOrganizationId) {
    url += `&customer_org_id=${customerOrganizationId}`;
  }
  // if (tractor_id) {
  //   url += `&tractor_id=${tractor_id}`;
  // }
  if (from_date && to_date) {
    url += `&from_date=${from_date}&to_date=${to_date}`;
  }
  if (category) {
    url += `&category=${category}`;
  }
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getChargeSummary = async (
  orgURL: string,
  customerOrganizationId: any,
  from_date?: any,
  to_date?: any,
  // tractor_id?: any,
  category?: string,
) => {
  let url = `${orgURL}monarch-digital/tractor/charge-summary?`;
  if (customerOrganizationId) {
    url += `&customer_org_id=${customerOrganizationId}`;
  }
  // if (tractor_id) {
  //   url += `&tractor_id=${tractor_id}`;
  // }
  if (from_date && to_date) {
    url += `&from_date=${from_date}&to_date=${to_date}`;
  }
  if (category) {
    url += `&category=${category}`;
  }
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getIndicatorsSummary = async (
  orgURL: string,
  customerOrganizationId: any,
  from_date?: any,
  to_date?: any,
  // tractor_id?: any,
  category?: string,
) => {
  let url = `${orgURL}monarch-digital/fleet/indicator-summary?`;
  if (customerOrganizationId) {
    url += `&customer_org_id=${customerOrganizationId}`;
  }
  // if (tractor_id) {
  //   url += `&tractor_id=${tractor_id}`;
  // }
  if (from_date && to_date) {
    url += `&from_date=${from_date}&to_date=${to_date}`;
  }
  if (category) {
    url += `&category=${category}`;
  }
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getTractorSummary = async (orgURL: string, orgIds: any) => {
  let url = `${orgURL}monarch-digital/tractor-health-summary?`;
  if (orgIds) {
    url += `&customer_org_id=${orgIds}`;
  }
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getTractorsListData = async (
  orgURL: string,
  page_number: number,
  page_size: number,
  searchKey: string,
  sortInfo?: any,
) => {
  let url = `${orgURL}factory/mt-tractor?page_number=${page_number}&page_size=${page_size}&q=${searchKey}`;
  if (sortInfo) url = appendSort(url, sortInfo);
  const response = await axios.get(url, {
    headers: getHeaders(),
  });
  return response.data;
};

export const getIndicatorsDropdown = async (orgURL: string) => {
  const URL = `${orgURL}monarch-digital/fleet/indicator-error-code/indicator/drop-down`;
  const response = await axios.get(URL, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getErrorCodesData = async ({
  orgURL,
  indicator = '',
  pageNumber = 1,
  pageSize = 25,
  search = '',
}: any) => {
  let URL = `${orgURL}monarch-digital/fleet/indicator-error-code?`;
  if (pageNumber) URL += `&page_number=${pageNumber}&page_size=${pageSize}`;
  if (indicator) URL += `&indicator_key=${indicator}`;
  if (search) URL += `&q=${search}`;

  const response = await axios.get(URL, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getDealerIndicatorHistory = async (
  orgURL: string,
  customerOrganizationId: any,
  tractor_id: any,
  indicator_key: string,
  from_date_time: string,
  to_date_time: string,
  page_number: number,
  page_size: number,
  indicator_level: number,
) => {
  let url = `${orgURL}monarch-digital/fleet/indicator/history?tractor_id=${tractor_id}&indicator_key=${indicator_key}&page_number=${page_number}&page_size=${page_size}&indicator_level=${indicator_level}`;
  if (customerOrganizationId) {
    url += `&customer_org_id=${customerOrganizationId}`;
  }
  if (from_date_time && to_date_time) {
    url =
      url + `&from_date_time=${from_date_time}&to_date_time=${to_date_time}`;
  }
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getGoldBuildData = async (
  orgURL: any,
  pageNumber: number,
  pageSize: number,
  search?: string,
) => {
  let URL = `${orgURL}monarch-digital/dealer/gold-builds?`;
  if (pageNumber) URL += `&page_number=${pageNumber}&page_size=${pageSize}`;
  if (search) URL += `&q=${search}`;
  const response = await axios.get(URL, {
    headers: await getHeaders(),
  });
  return response.data;
};
export const getTractorsData = async (
  orgURL: string,
  customer_org_id: number,
  org_id: any,
  page_number: number,
  page_size: number,
  connection?: string,
  searchKey?: string,
  tractor_health_state?: number,
  sortInfo?: any,
) => {
  let url = `${orgURL}monarch-digital/organization/tractor-health?page_number=${page_number}&page_size=${page_size}`;
  if (String(org_id).trim() !== '') {
    url += `&customer_org_id=${org_id}`;
  }
  if (connection) {
    url += `&connection=${connection}`;
  }
  if (searchKey) {
    url += `&q=${searchKey}`;
  }
  if (tractor_health_state) {
    url += `&tractor_health_state=${tractor_health_state}`;
  }
  if (sortInfo) url = appendSort(url, sortInfo);
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getTractorHealthSummary = async (
  orgURL: string,
  customer_org_ids: any,
) => {
  let url = `${orgURL}monarch-digital/tractor-health/summary`;
  if (customer_org_ids?.length > 0) {
    url += `?customer_org_id=${customer_org_ids}`;
  }
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getAutodriveReport = async (
  orgURL: string,
  month: any,
  year: any,
) => {
  const url = `${orgURL}analytics/auto-drive-report?month=${month}&year=${year}`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getTractorReport = async (
  orgURL: string,
  crop_type?: any,
  customer_org_id?: any,
  tractor_pin?: any,
  fw_version?: any,
  sw_version?: any,
  from_date?: any,
  to_date?: any,
) => {
  let url = `${orgURL}analytics/tractor-key-report?`;

  if (crop_type?.length > 0) {
    url += `crop_type=${crop_type}`;
  }
  if (customer_org_id?.length > 0) {
    url += `&customer_org_id=${customer_org_id}`;
  }
  if (tractor_pin?.length > 0) {
    url += `&tractor_pin=${tractor_pin}`;
  }
  if (fw_version?.length > 0) {
    url += `&fw_version=${fw_version}`;
  }
  if (sw_version?.length > 0) {
    url += `&sw_version=${sw_version}`;
  }
  if (from_date && to_date) {
    url += `&from_date=${from_date}&to_date=${to_date}`;
  }
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getCropTypes = async (orgURL: string) => {
  const url = `${orgURL}analytics/crop-filter-args`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getOrgsByCrops = async (orgURL: string, crop_type?: any) => {
  let url = `${orgURL}analytics/org-filter-args`;
  if (crop_type?.length > 0) {
    url += `?crop_type=${crop_type}`;
  }
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getTractorPins = async (
  orgURL: string,
  customer_org_ids?: any,
) => {
  let url = `${orgURL}analytics/tractor-pin-filter-args`;
  if (customer_org_ids?.length > 0) {
    url += `?org_id=${customer_org_ids}`;
  }
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getFirmwareVersions = async (
  orgURL: string,
  tractor_pin?: any,
  from_date?: any,
  to_date?: any,
) => {
  let url = `${orgURL}analytics/fw-filter-args`;
  if (tractor_pin?.length > 0) {
    url += `?tractor_pin=${tractor_pin}`;
  }
  if (from_date && to_date) {
    url += `&from_date=${from_date}&to_date=${to_date}`;
  }
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getSoftwareVersions = async (
  orgURL: string,
  tractor_pin?: any,
  from_date?: any,
  to_date?: any,
) => {
  let url = `${orgURL}analytics/sw-filter-args`;
  if (tractor_pin?.length > 0) {
    url += `?tractor_pin=${tractor_pin}`;
  }
  if (from_date && to_date) {
    url += `&from_date=${from_date}&to_date=${to_date}`;
  }
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getGoldbuilds = async (orgURL: string) => {
  const url = `${orgURL}monarch-digital/current-gold-builds`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getMarkGoldBuildData = async (
  orgURL: any,
  pageNumber: number,
  pageSize: number,
  sortInfo: any,
  search: string,
) => {
  let URL = `${orgURL}monarch-digital/build-software/golden-builds?`;
  if (pageNumber) URL += `&page_number=${pageNumber}&page_size=${pageSize}`;
  if (sortInfo) URL = appendSort(URL, sortInfo);
  if (search) URL += `&q=${search}`;
  const response = await axios.get(URL, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getMarkTractorHealthSummary = async (
  orgURL: string,
  customer_org_ids: any,
) => {
  let url = `${orgURL}monarch-digital/tractor/health-summary`;
  if (customer_org_ids?.length > 0) {
    url += `?customer_org_id=${customer_org_ids}`;
  }
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getMarkTractorVersions = async (
  orgURL: string,
  build_category: string,
) => {
  let url = `${orgURL}monarch-digital/versions/dropdown`;
  if (build_category) {
    url += `?build_category=${build_category}`;
  }
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getMarkTractorsData = async (
  orgURL: string,
  org_id: any,
  software_version: any,
  firmware_version: any,
  is_gold_build: string,
  page_number: number,
  page_size: number,
  connection?: string,
  searchKey?: string,
  // tractor_health_state?: number,
  sortInfo?: any,
  tractor_id?: any,
) => {
  let url = `${orgURL}monarch-digital/fleet/health-details?page_number=${page_number}&page_size=${page_size}`;
  if (is_gold_build === 'gold build' || is_gold_build === 'non gold build') {
    url += `&is_gold_build=${is_gold_build === 'gold build' ? true : false}`;
  }
  if (String(org_id).trim() !== '') {
    url += `&cus_org_ids=${org_id}`;
  }
  if (software_version?.length) {
    url += `&software_version=${software_version}`;
  }
  if (firmware_version?.length) {
    url += `&firmware_version=${firmware_version}`;
  }
  if (connection) {
    url += `&connection=${connection}`;
  }
  if (searchKey) {
    url += `&q=${searchKey}`;
  }
  // if (tractor_health_state) {
  //   url += `&tractor_health_state=${tractor_health_state}`;
  // }
  if (tractor_id) {
    url += `&tractor_ids=${tractor_id}`;
  }
  if (sortInfo) url = appendSort(url, sortInfo);
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getMarkTicketsHistory = async (
  orgURL: string,
  fleetId: number,
  org_id: any,
  pageNumber: number,
  page_size: number,
  severityLevel?: any,
  from_date_time?: any,
  to_date_time?: any,
  search?: string,
  sort?: any,
) => {
  let url = `${orgURL}analytics/fleet/${fleetId}/maintenance-log?page_number=${pageNumber}&page_size=${page_size}`;
  if (severityLevel) {
    url += `&severity_level=${severityLevel}`;
  }
  if (String(org_id).trim() !== '') {
    url += `&org_ids=${org_id}`;
  }
  if (from_date_time && to_date_time) {
    url += `&from_time=${from_date_time}&to_time=${to_date_time}`;
  }
  if (search && search != '') {
    url += `&q=${encodeURI(search)}`;
  }
  if (sort) {
    url = appendSort(url, sort);
  }

  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getMarkTicketsSummary = async (
  orgURL: string,
  fleetId: number,
  org_id: any,
  from_date_time?: any,
  to_date_time?: any,
) => {
  let URL = `${orgURL}analytics/fleet/${fleetId}/maintenance-log/tickets-summary?`;
  if (String(org_id).trim() !== '') {
    URL += `org_ids=${org_id}`;
  }
  if (from_date_time && to_date_time) {
    URL += `&from_time=${from_date_time}&to_time=${to_date_time}`;
  }
  const Response = await axios.get(URL, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const getAutodriveReportData = async (
  orgURL: string,
  fromDate: string,
  toDate: string,
  customerIds: string,
) => {
  let URL = `${orgURL}analytics/auto-drive-obj-detection?`;
  URL += `&from_date=${fromDate}&to_date=${toDate}&customer_id=${customerIds}`;
  const response = await axios.get(URL, {
    headers: await getHeaders(),
  });
  return response.data;
};
export const getAutoDriveUsageData = async (
  orgURL: string,
  fromDate: string,
  toDate: string,
  reportView: string,
) => {
  let URL = `${orgURL}analytics/daily-operations/usage?`;
  if (fromDate) {
    URL += `&from_date=${fromDate}`;
  }
  if (toDate) {
    URL += `&to_date=${toDate}`;
  }
  if (reportView) {
    URL += `&report_view=${reportView}`;
  }

  const response = await axios.get(URL, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getDriveActionListData = async (
  orgURL: string,
  customerId: number,
  fromDate: string,
  toDate: string,
  totalHours: string,
  pageNumber: number,
  pageSize: number,
  sort: any,
) => {
  let URL = `${orgURL}analytics/autodrive-performance/drive-action-list?`;
  URL += `customer_org_id=${customerId}&from_date=${fromDate}&to_date=${toDate}&total_hours=${totalHours}`;
  if (pageNumber) URL += `&page_number=${pageNumber}&page_size=${pageSize}`;
  URL = sortKeyWrapper(URL, sort);
  const response = await axios.get(URL, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getDriveActionInteruptData = async (
  orgURL: string,
  customerId: string,
  driveActionUUID: string,
) => {
  let URL = `${orgURL}analytics/autodrive-performance/interrupt-feedback-data?`;
  // URL += `customer_org_id=${customerId}&drive_action_uuid=17164455647770`;
  URL += `customer_org_id=${customerId}&drive_action_uuid=${driveActionUUID}`;
  const response = await axios.get(URL, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getTractorUsageReport = async (
  orgURL: string,
  from_date: string,
  to_date: string,
  customer_org_id: string[],
) => {
  let URL = `${orgURL}monarch-digital/analytics/tractor-usage-summary/org-wise?`;
  URL += `customer_org_id=${customer_org_id}&from_date=${from_date}&to_date=${to_date}`;
  const response = await axios.get(URL, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getBaseStationStatus = async (
  orgURL: string,
  customer_org_id: any,
  page_number: number,
  page_size: number,
  connection?: any,
  subStatus?: any,
  routerStatus?: any,
  sortInfo?: any,
) => {
  let url = `${orgURL}analytics/basestation/health?page_number=${page_number}&page_size=${page_size}`;
  if (customer_org_id) {
    url += `&customer_org_id=${customer_org_id}`;
  }
  if (connection || connection === 0) {
    url += `&connection=${connection}`;
  }
  if (subStatus || subStatus === 0) {
    url += `&sbc_status=${subStatus}`;
  }
  if (routerStatus || routerStatus === 0) {
    url += `&router_status=${routerStatus}`;
  }
  if (sortInfo) url = appendSort(url, sortInfo);

  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getDriveActionsTractorsList = async (
  orgURL: string,
  orgId: any,
) => {
  let url = `${orgURL}analytics/drive-actions/tractor-list`;
  if (orgId) {
    url += `?customer_org_id=${orgId}`;
  }
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getFleetindicatorHistory = async (
  orgURL: string,
  customer_org_id: any,
  page_number: number,
  page_size: number,
  selectedTractorId: any,
  fromdate: any,
  todate: any,
  indicatorKey: any,
) => {
  const url = `${orgURL}analytics/fleet-indicator-history?tractor_id=${selectedTractorId}&from_date_time=${fromdate}&to_date_time=${todate}&page_number=${page_number}&page_size=${page_size}&customer_org_id=${customer_org_id}&indicator_key=${indicatorKey}`;
  const response = await axios.get(url, {
    headers: await getHeaders(),
  });
  return response.data;
};
