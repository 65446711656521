import React, { useContext, useEffect, useState } from 'react';
import Layout from 'antd/lib/layout/layout';
import { useTranslation } from 'react-i18next';
import close_icon from '../../assets/images/right_widget_close_icon.svg';
import CustomInput from '../common/CustomInput';
import { createChargePoint, getElectricityPlan } from '../../constants/Api';
import { ApplicationContext } from '../../context/AppContext';
import { Button, notification } from 'antd';
import DiscardChanges from '../Implements/DiscardChanges';
import CustomSelect from '../common/CustomSelect';
import AppLoader from '../common/AppLoader';
import translate from '../../locale/en_translate.json';

interface Props {
  Close: (state?: boolean) => void;
}

const AddChargePoint: React.FC<Props> = ({ Close }: Props) => {
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const [chargePointName, setChargePointName] = useState('');
  const [make, setMake] = useState('');
  const [model, setModel] = useState('');
  const [voltage, setVoltage] = useState('');
  const [electricityList, setElectricityList] = useState<any[]>([]);
  const [providerId, setProviderId] = useState<number>();
  const [latitude, setLatitude] = useState<string>('');
  const [longitude, setLongitude] = useState<string>('');
  const [altitude, setAltitude] = useState<string>('');
  const [loader, setLoader] = useState<boolean>(true);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [discard, setDiscard] = useState<boolean>(false);

  useEffect(() => {
    if (
      chargePointName &&
      chargePointName.trim().length > 1 &&
      make &&
      make.trim().length > 1 &&
      model &&
      model.trim().length > 1 &&
      voltage &&
      providerId
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [chargePointName, make, model, voltage, providerId]);

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      getElectricityRate();
    }
  }, [userDetails]);

  const getElectricityRate = async () => {
    try {
      setLoader(true);
      const data = await getElectricityPlan(
        userDetails.organization.api_url,
        userDetails.organization.id,
      );
      setElectricityList(data);
    } catch (error: any) {
      notification.error({
        message: error.message,
        duration: 2,
      });
    } finally {
      setLoader(false);
    }
  };

  const CloseIcon = () => {
    if (
      chargePointName ||
      make ||
      model ||
      voltage ||
      providerId ||
      latitude ||
      longitude ||
      altitude
    ) {
      setDiscard(true);
    } else {
      setDiscard(false);
      Close();
    }
  };

  const saveChargePoint = async () => {
    try {
      setLoader(true);
      setIsDisabled(true);

      const payload = {
        charge_point_name: chargePointName.trim(),
        make: make.trim(),
        model: model.trim(),
        voltage: voltage,
        electricity_provider_plan_id: providerId,
        location_latlong: {
          latitide: latitude,
          longitude: longitude,
          altitide: altitude,
        },
      };

      const response: { msg: string } = await createChargePoint(
        userDetails.organization.api_url,
        userDetails.organization.id,
        payload,
      );
      notification.success({
        message: response.msg,
        duration: 2,
      });
      Close(true);
    } catch (err: any) {
      notification.error({
        message: err.message,
        duration: 2,
      });
    } finally {
      setLoader(false);
      setIsDisabled(false);
    }
  };

  const onSelect = (e: any) => {
    setProviderId(e);
  };

  const closeModel = () => {
    setDiscard(false);
  };

  const closeDiscard = () => {
    Close();
    setDiscard(false);
  };

  return (
    <Layout>
      <div className="rightSideWidegt expand">
        <div className="widgetCloseBtn impCloseBtn">
          <img src={close_icon} alt="close icon" onClick={CloseIcon} />
        </div>
        <div className="profileEdit widget_card">
          <h3
            className="createTcktTitle headline3 rowGapBtm3"
            style={{ textAlign: 'center' }}
            data-testid="createTicketHeader-TicketsCreateEdit"
          >
            {t(translate.addChargePoint.createChargePoint)}
          </h3>
          <div className="mb16">
            <CustomInput
              label="Charge Point Name"
              value={chargePointName}
              cssClass="width100per"
              setValue={(value) => {
                setChargePointName(value);
              }}
              required={true}
            />
          </div>
          <div className="mb16">
            <CustomInput
              label="Make"
              value={make}
              cssClass="width100per"
              setValue={(value) => {
                setMake(value);
              }}
              required={true}
            />
          </div>
          <div className="mb16">
            <CustomInput
              label="Model"
              value={model}
              cssClass="width100per"
              setValue={(value) => {
                setModel(value);
              }}
              required={true}
            />
          </div>
          <div className="mb16">
            <CustomInput
              label="Voltage"
              value={voltage}
              cssClass="width100per"
              setValue={(value) => {
                setVoltage(value);
              }}
              required={true}
              numberType="number"
            />
          </div>
          <div className="mb16">
            <CustomSelect
              label="Electricity Provider"
              cssClass="min_width"
              value={providerId}
              setValue={onSelect}
              options={electricityList}
              optionKey="id"
              optionDisplay="provider"
              required={true}
            />
          </div>
          <div className="mb16">
            <CustomInput
              label="Latitude"
              value={latitude}
              cssClass="width100per"
              setValue={(value) => {
                setLatitude(value);
              }}
              numberType="number"
            />
          </div>
          <div className="mb16">
            <CustomInput
              label="Longitude"
              value={longitude}
              cssClass="width100per"
              setValue={(value) => {
                setLongitude(value);
              }}
              numberType="number"
            />
          </div>
          <div className="mb16">
            <CustomInput
              label="Altitude"
              value={altitude}
              cssClass="width100per"
              setValue={(value) => {
                setAltitude(value);
              }}
              numberType="number"
            />
          </div>
          <AppLoader loader={loader} />
          <div className="create_ticket_btn_sec">
            <Button onClick={saveChargePoint} disabled={isDisabled}>
              {t(translate.buttons.createChargePoint)}
            </Button>
          </div>
        </div>
      </div>
      {discard && (
        <DiscardChanges
          showModal={discard}
          closeModel={closeModel}
          closeDiscard={closeDiscard}
        ></DiscardChanges>
      )}
    </Layout>
  );
};

export default AddChargePoint;
