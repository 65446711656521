import React, { useState } from 'react';
import { Tabs } from 'antd';
import { track } from '../../../util/logger';
const { TabPane } = Tabs;
import '../common/tab.css';
import Inventory from './Inventory';
import Maintainence from './Maintainence';
import Planning from './Planning';

const Dealers: React.FC = () => {
  const [tabid, setTabid] = useState('1');
  return (
    <Tabs
      activeKey={tabid}
      centered
      id="org-tab-Organization"
      className="common_tabs implementTab"
      onChange={(key) => {
        setTabid(key);
        track(key, {
          event: `${key} Tab`,
        });
      }}
    >
      <TabPane tab="Inventory" key="1" className="attTableHeight mBottom0">
        <Inventory />
      </TabPane>
      <TabPane tab="Maintainence" key="2" className="attTableHeight mBottom0">
        <Maintainence />
      </TabPane>
      <TabPane tab="Planning" key="3" className="attTableHeight mBottom0">
        <Planning />
      </TabPane>
    </Tabs>
  );
};

export default Dealers;
