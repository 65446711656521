/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import { Divider, Select, Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import classNames from 'classnames';

interface Props {
  label?: any;
  value?: any;
  cssClass?: any;
  options?: any;
  optionKey?: any;
  required?: any;
  searchmethod?: (data: any) => void;
  onSelect?: (data: any) => void;
  searchName?: any;
  additem?: () => void;
  validations?: any;
  optionDisplay?: any;
  testId?: any;
  jsonFormat?: boolean;
  isAll?: boolean;
}

const CustomSelectSearch: React.FC<Props> = ({
  label,
  value,
  cssClass,
  options,
  optionKey,
  required,
  searchmethod,
  onSelect,
  searchName,
  additem,
  validations,
  optionDisplay,
  testId,
  jsonFormat,
  isAll = false,
}: Props) => {
  const [focus, setFocus] = useState(false);

  const isEmpty = (value: any) => {
    if (typeof value === 'undefined') return true;
    if (typeof value === 'string' && value.trim() === '' && !isAll) return true;
    if (typeof value === 'number' && isNaN(value)) return true;
    return false;
  };
  const labelClass = focus || !isEmpty(value) ? 'label label-float' : 'label';

  return (
    <div
      className="float-label"
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      <Select
        placeholder={label}
        showSearch={true}
        value={value}
        data-testid={testId}
        notFoundContent={<em>No Matches Found</em>}
        onSearch={(text: any) => searchmethod && searchmethod(text)}
        onSelect={onSelect}
        className={classNames({
          cssClass: true,
          errorClass: !validations && required,
        })}
        dropdownRender={(menu) => (
          <>
            {menu}
            {searchName && searchName.length > 0 && (
              <>
                <Divider style={{ margin: '8px 0' }} />
                <div className="mfAdd" onClick={() => additem && additem()}>
                  <Space style={{ padding: '0 8px 4px' }}>
                    <PlusOutlined />'{searchName}'
                  </Space>
                </div>
              </>
            )}
          </>
        )}
      >
        {isAll && <option value="">All</option>}
        {options &&
          options?.length > 0 &&
          options?.map((data: any) => {
            if (data[optionDisplay]) {
              return (
                <option
                  value={jsonFormat ? JSON.stringify(data) : data[optionKey]}
                  key={data[optionKey]}
                  data-testid={`${
                    optionDisplay ? data[optionDisplay] : data[optionKey]
                  }-CustomSelectSearch`}
                >
                  {optionDisplay ? data[optionDisplay] : data[optionKey]}
                </option>
              );
            }
          })}
      </Select>

      <label className={labelClass}>
        {label} {required && <span className="float-span">*</span>}
      </label>
    </div>
  );
};

export default CustomSelectSearch;
