import React, { useState } from 'react';
import { Tabs } from 'antd';
import Maintenance from './maintenance';
import Inventory from './inventory';
import Planning from './planning';

const { TabPane } = Tabs;

const Index: React.FC = () => {
  const [tabid, setTabid] = useState<string>('inventory');

  return (
    <div className="rightSideContainer">
      <Tabs
        activeKey={tabid}
        centered
        id="org-tab-Organization"
        className="common_tabs implementTab"
        onChange={(key) => {
          setTabid(key);
        }}
      >
        <TabPane
          tab="Inventory"
          key="inventory"
          className="attTableHeight mBottom0"
        >
          {tabid === 'inventory' && <Inventory />}
        </TabPane>
        <TabPane
          tab="Maintenance"
          key="maintenance"
          className="attTableHeight mBottom0"
        >
          {tabid === 'maintenance' && <Maintenance />}
        </TabPane>
        <TabPane
          tab="Planning"
          key="planning"
          className="attTableHeight mBottom0"
        >
          {tabid === 'planning' && <Planning />}
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Index;
