import { Tabs } from 'antd';
import React, { useState } from 'react';
import { track } from '../../../util/logger';
import Indicators from '../indicators/Indicators';
import DiagnosticCodes from '../diagnostics';
import KnowledgeBase from '../../knowledgeBase/KnowlegeBase';
import Maintenancelist from '../../maintenance/Maintenancelist';
import RemoteAccess from './RemoteAccess';
import GoldBuild from './GoldBuild';

const { TabPane } = Tabs;

const DealersMaintenance: React.FC = () => {
  const [tabid, setTabid] = useState('1');
  return (
    <Tabs
      activeKey={tabid}
      centered
      id="fleet-tab-FleetHealthContainer"
      className="common_tabs"
      onChange={(key) => {
        setTabid(key);
        track(key, {
          event: `${key} Tab`,
        });
      }}
    >
      <TabPane tab="Indicators" key="1" className="attTableHeight mBottom0">
        <Indicators />
      </TabPane>
      <TabPane
        tab="Diagnostic Error Codes"
        key="2"
        className="attTableHeight mBottom0"
      >
        <DiagnosticCodes />
      </TabPane>
      <TabPane tab="Knowledge Base" key="3" className="attTableHeight mBottom0">
        <KnowledgeBase showConverse={false} />
      </TabPane>
      {/* <TabPane
        tab="Maintainance Planner"
        key="4"
        className="attTableHeight mBottom0"
      >
        <Maintenancelist />
      </TabPane> */}
      <TabPane tab="Remote Access" key="4" className="attTableHeight mBottom0">
        <RemoteAccess />
      </TabPane>
    </Tabs>
  );
};

export default DealersMaintenance;
