/* eslint-disable no-dupe-else-if */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import flag_red from '../../assets/images/flag_red.svg';
import appIcon from '../../assets/images/ic_apps.svg';
import nfIcon from '../../assets/images/nfIcon.svg';
import nfTractor from '../../assets/images/nfTractor.svg';
import nf_flag2 from '../../assets/images/nf_flag2.svg';
import { checkAlphaFeature } from '../../constants/Common';
import translate from '../../locale/en_translate.json';
import RoutesConstants from '../../routes/RoutesConstant';
import { Avatar, Button, notification } from 'antd';
import {
  cancelAutomatedReminders,
  getTremsAndConditions,
  getUpdatesCount,
} from '../../constants/Api';
import { ApplicationContext } from '../../context/AppContext';
import { ApplicationThemeContext } from '../../context/ThemeContext';
import { onMessageListener } from '../../firebaseInit';
import TermsConditions from '../auth/TermsConditions';
import VideoWidget from '../common/VideoWidget';
import NotificationView from '../notifications/NotificationView';
import monarch_technology from '../../assets/images/monarch_technology.svg';
import { WINGSPANAI } from '../../constants/constant';
import { AUTO_DRIVE_LOGOUT_CONFIRM } from '../../context/actions';
import { AutoDriveApplicationContext } from '../../context/AutoDriveContext';
import { AutoDriveLogoutConfirm } from '../map/AutoDriveLogoutConfirm';
import './style.css';
import AnalyticCard from '../common/AnalyticsDashbordCard/AnalyticCard';

interface Props {
  onLinkClick?: (link: string) => void;
}

const AppSideMenu: React.FC<Props> = ({ onLinkClick }: Props) => {
  const { t } = useTranslation();
  const loc = useLocation();
  // const { profile_url } = userProfile || {};
  const isActive = (name: string[] | string) => {
    if (Array.isArray(name)) {
      return loc && name.includes(loc.pathname)
        ? 'iconBox mItemSel'
        : 'iconBox';
    } else {
      return loc && name === loc.pathname ? 'iconBox mItemSel' : 'iconBox';
    }
  };

  const { handleLogout, handlePauseResumeStopLiveCom } = useContext(
    AutoDriveApplicationContext,
  );
  const {
    user,
    fullScreen,
    APPReducer,
    userDetails,
    isDealerAccount,
    analyticDashboardData,
  } = useContext(ApplicationContext);
  const { styles } = useContext(ApplicationThemeContext);
  const [termsData, setTermsData] = useState<any>();
  const [showModal, setshowModal] = useState<boolean>(false);
  const [notificationData, setNotificationData] = useState<any>();
  const [state] = APPReducer;
  const { AutoDriveReducer } = useContext(AutoDriveApplicationContext);
  const [autoDriveState, autoDriveDispatch] = AutoDriveReducer;
  // autoDriveLogoutConfirm key for display the popup
  const { autoDriveLogoutConfirm } = autoDriveState;
  const { videoWidgetData } = state;
  const [updateCount, setUpdateCount] = useState<number>(0);
  const userData = useRef<any>(null);
  userData.current = userDetails;

  useEffect(() => {
    if (userDetails && userDetails.organization) getCount();
  }, [userDetails]);

  const getCount = async () => {
    try {
      const { tractor_software_update_count } = await getUpdatesCount(
        userDetails.organization.api_url,
        userDetails.organization.id,
      );
      setUpdateCount(tractor_software_update_count);
    } catch (error: any) {
      notification.error({ message: error.message });
    }
  };

  const handleClick = (routeLnk: string) => {
    localStorage.removeItem('lastTabIndex');
    onLinkClick && onLinkClick(routeLnk);
  };

  const handleDismiss = async (data: any, key: string) => {
    if (!userData.current?.organization) return;
    const { ref_uuid } = data;
    try {
      const { api_url } = userData.current.organization;
      const payload = {
        ticket_id: ref_uuid,
        action: 'cancel_ticket_notification',
      };
      await cancelAutomatedReminders(api_url, payload);
      notification.close(key);
    } catch (error: any) {
      notification.error({ message: error.message });
    }
  };

  const profileImg = () => (
    <Avatar
      style={{
        width: '34px',
        height: '34px',
        backgroundColor: '#000',
        color: 'white',
        fontFamily: 'Montserrat-Bold',
      }}
      src={user?.profile_url}
    >
      {user?.first_name?.charAt(0)}
      {user?.last_name?.charAt(0)}
    </Avatar>
  );

  const openNotification = async (data: any = {}) => {
    const {
      message = '',
      event_category = 'Indicator',
      event_level = 1,
      user_id,
      body = '',
      camera = '',
    } = data;
    const key = `open${Date.now()}`;
    notification.open({
      message: '',
      duration: 2,
      key,
      description: (
        <div className="proRoleType subFlex flexBtn flexAlignStart mb0 notificationBlk">
          <div style={{ position: 'relative', width: '20%' }}>
            <div className="nfIcon posRel">
              <div className="nfTopIcon">
                <img
                  className=""
                  src={event_category !== 'Indicator' ? nfIcon : nfTractor}
                />
              </div>
              <div className="nfBottomIcon">
                {event_category === 'Indicator' ? (
                  <>
                    <img
                      className="flagIcon"
                      src={event_level === 3 ? flag_red : nf_flag2}
                    />
                    {event_level}
                  </>
                ) : (
                  profileImg()
                )}
              </div>
            </div>
          </div>
          <div className="notificationInrBlk ml20">
            <p className="notificationMsg body2">
              {message && message.trim() !== '' ? message : body}
              <br />
              {camera && camera.trim() !== '' ? `Camera : ${camera}` : ''}
            </p>
          </div>
          <div className="notificationbtn">
            {data?.event_category === 'guard_rails' && (
              <Button
                key="submit"
                onClick={() => {
                  setNotificationData(data);
                }}
                className="btnView"
              >
                View
              </Button>
            )}
            {Boolean(data?.notification_code === '100006') && (
              <Button
                key="submit"
                onClick={() => {
                  handleDismiss(data, key);
                }}
                className="btnView btnDismiss"
              >
                Dismiss
              </Button>
            )}
          </div>
        </div>
      ),
    });
  };

  useEffect(() => {
    getTermsAndConditionMethod();
  }, [user]);

  const getTermsAndConditionMethod = async () => {
    if (!user) return;
    const {
      id,
      organization_id,
      organization: { api_url },
    } = user;
    try {
      const data = await getTremsAndConditions(api_url, organization_id, id);
      if (data[0]) {
        setTermsData(data[0]);
        setshowModal(true);
      }
    } catch (error: any) {
      notification.error(error.message);
    }
  };

  const closeModel = () => {
    setshowModal(false);
  };

  onMessageListener()
    .then((payload: any) => {
      if (payload) {
        if (payload?.data) openNotification(payload?.data);
      }
    })
    // eslint-disable-next-line no-console
    .catch((err) => console.error('failed: ', err));

  return (
    <>
      <nav
        className={`main-menu ${
          checkAlphaFeature(WINGSPANAI) ? 'wingSpanAI' : ''
        }`}
        style={{
          zIndex: fullScreen ? 99 : 1002,
          background: styles.menuColor,
        }}
        data-testid="sideMenu-AppSideMenu"
      >
        <div className="login_logo">
          <div className="login_logo_box">
            <div className="company_logo"></div>
            {/* <img src={login_logo_small} alt="" className="sLogo" />
            <img src={login_logo_large} alt="" className="lLogo" /> */}
          </div>
        </div>
        <div className="login_user_sec">
          <div className="login_user_dtl">
            <ul className="">
              <li className="">
                <Link to={RoutesConstants.Profile}>
                  <div className="iconBoxProfile">
                    {/* <img src={profile_url || profilePic} alt="profilePic" /> */}
                    <Avatar
                      style={{
                        backgroundColor: '#67AFB7',
                        color: 'white',
                        fontFamily: 'Montserrat-Bold',
                      }}
                      data-testid="profileIcon-AppSideMenu"
                      src={user?.profile_url}
                    >
                      {user?.first_name?.charAt(0)}
                      {user?.last_name?.charAt(0)}
                    </Avatar>
                  </div>
                  <span
                    className="nav-text overline2"
                    data-testid={`${user?.getUserName()}-AppSideMenu`}
                  >
                    {user?.getUserName()}
                  </span>
                </Link>
              </li>
              {checkAlphaFeature('wingspanai') && (
                <li className="monarch_tech_img">
                  <img src={monarch_technology} alt="" />
                </li>
              )}
              {/* <li className="monarch_tech_img">
                <img src={monarch_technology} alt="" />
              </li> */}
            </ul>
          </div>
        </div>
        <div className="side_menu_sec">
          <ul className="sMenuUL">
            <li>
              <Link to={RoutesConstants.LiveMap}>
                <div className={isActive('/')}>
                  {/* <img src={sectionFarm} alt="section_farm" className="wv25" /> */}
                  <div className="liveops_icon"></div>
                </div>
                <span
                  className="nav-text overline2"
                  data-testid="liveMapMenu-AppSideMenu"
                >
                  {t(translate.dashboard.liveMap)}
                </span>
              </Link>
            </li>
            <li className="has-subnav">
              <a
                id="org_profile"
                onClick={() => {
                  handleClick(RoutesConstants.Organization);
                }}
              >
                <div className={isActive('/organization')}>
                  {/* <img src={farmPro} alt="Farm Profile" className="wv22" /> */}
                  <div className="farmprofile_icon"></div>
                </div>
                <span
                  className="nav-text overline2"
                  data-testid="farmProfileMenu-AppSideMenu"
                >
                  {t(translate.sideMenu.farmProfile)}
                </span>
              </a>
            </li>

            {/* <li>
            <a
              onClick={() => {
                setVisible(!visible);
                setNewNotification(false);
              }}
            >
              <div className="iconBox">
                <img
                  src={newNotification ? nfs : nfsp}
                  alt="Notifications"
                  className="wv22"
                />
              </div>
              <span
                className="nav-text"
                data-testid="notificationsMenu-AppSideMenu"
              >
                {t(translate.sideMenu.notification)}
              </span>
            </a>
          </li> */}
            {process.env.REACT_APP_ALPHA_FEATURES &&
              process.env.REACT_APP_ALPHA_FEATURES.includes('TaskMaster') && (
                <li>
                  <a
                    onClick={() => {
                      handleClick(RoutesConstants.Taskmaster);
                    }}
                  >
                    <div
                      className={isActive('/tasks')}
                      // style={{ opacity: 0.4 }}
                    >
                      {/* <img src={taskIcon} alt="Tasks" className="wv21" /> */}
                      <div className="works_schedule_icon"></div>
                    </div>
                    <span
                      className="nav-text overline2"
                      data-testid="tasksMenu-AppSideMenu"
                    >
                      {t(translate.sideMenu.workSchedule)}
                    </span>
                  </a>
                </li>
              )}
            <li className="has-subnav">
              <a
                onClick={() => {
                  handleClick(RoutesConstants.FleetHealth);
                }}
              >
                <div className={isActive('/fleethealth')}>
                  {/* <img src={fleetHealth} alt="Fleet Health" className="wv22" /> */}
                  <div
                    className={
                      updateCount === 0 ? 'maintenance_icon' : 'maintanance_red'
                    }
                  />
                </div>
                <span
                  className="nav-text overline2"
                  data-testid="fleetHealthMenu-AppSideMenu"
                >
                  {t(translate.sideMenu.fleetHealth)}
                </span>
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  handleClick(RoutesConstants.Analytic);
                }}
              >
                <div className={isActive('/analytic')}>
                  {/* <img src={analyticsIcon} alt="analytics" className="wv16" /> */}
                  <div
                    className={
                      analyticDashboardData?.show_analytics
                        ? 'analytics_red'
                        : 'analytics_icon'
                    }
                  ></div>
                </div>
                <span
                  className="nav-text overline2"
                  data-testid="analyticsMenu-AppSideMenu"
                >
                  {t(translate.sideMenu.fleetUsage)}
                </span>
              </a>
            </li>

            {/* {userDetails?.organization?.account_type === 'dealer' && (
              <li>
                <a
                  onClick={() => {
                    handleClick(RoutesConstants.dealers);
                  }}
                >
                  <div className={isActive('/dealers')}>
                    <div className="dealers_icon"></div>
                  </div>
                  <span
                    className="nav-text overline2"
                    data-testid="analyticsMenu-AppSideMenu"
                  >
                    {t(translate.sideMenu.admin)}
                  </span>
                </a>
              </li>
            )} */}

            <li>
              <a
                onClick={() => {
                  handleClick(RoutesConstants.HelpCenterSearch);
                }}
              >
                <div
                  className={isActive([
                    RoutesConstants.HelpCenter,
                    RoutesConstants.HelpCenterSearch,
                    RoutesConstants.HelpCenterResult,
                    RoutesConstants.HelpCenterMedia,
                  ])}
                >
                  <div className="helpCenter_icon"></div>
                </div>
                <span
                  className="nav-text overline2"
                  data-testid="analyticsMenu-AppSideMenu"
                >
                  {t(translate.sideMenu.helpCenter)}
                </span>
              </a>
            </li>

            {(isDealerAccount ||
              userDetails?.organization?.account_type === 'dealer') && (
              <li>
                <a
                  onClick={() => {
                    handleClick(RoutesConstants.dealer);
                  }}
                >
                  <div className={isActive('/dealer')}>
                    {/* <img src={analyticsIcon} alt="analytics" className="wv16" /> */}
                    <div className="dealers_icon"></div>
                  </div>
                  <span
                    className="nav-text overline2"
                    data-testid="analyticsMenu-AppSideMenu"
                  >
                    {t(translate.sideMenu.dealer)}
                  </span>
                </a>
              </li>
            )}

            {user?.isSupportUser() && (
              <li>
                <a
                  onClick={() => {
                    handleClick(RoutesConstants.FleetUsage);
                  }}
                >
                  <div className={isActive('/fleetusage')}>
                    {/* <img
                        src={fleetUsageIcon}
                        alt="fleetusage"
                        className="wv22"
                      /> */}
                    <div className="fleetusage_icon"></div>
                  </div>
                  <span
                    className="nav-text overline2"
                    data-testid="fleetusage-AppSideMenu"
                  >
                    {t(translate.sideMenu.supportOptions)}
                  </span>
                </a>
              </li>
            )}
            {process.env.REACT_APP_ALPHA_FEATURES &&
              process.env.REACT_APP_ALPHA_FEATURES.includes('DigitalApps') && (
                <li>
                  <a
                    onClick={() => {
                      handleClick(RoutesConstants.Apps);
                    }}
                  >
                    <div className={isActive('/apps')}>
                      <img src={appIcon} alt="apps" className="wv16" />
                    </div>
                    <span
                      className="nav-text overline2"
                      data-testid="apps-AppSideMenu"
                    >
                      {t(translate.sideMenu.digitalAppStore)}
                    </span>
                  </a>
                </li>
              )}

            <div className="bottomLine"></div>
          </ul>

          <ul className="logout">
            <li className="logoutLink" onClick={() => handleLogout()}>
              <a>
                <div className="iconBox">
                  {/* <img src={logout} alt="logout" className="wv20" /> */}
                  <div className="logout_icon"></div>
                </div>
                <span
                  className="nav-text overline2"
                  data-testid="logoutButton-AppSideMenu"
                >
                  {t(translate.sideMenu.logout)}
                </span>
              </a>
            </li>
          </ul>
        </div>
      </nav>
      <NotificationView data={notificationData} />
      {termsData && showModal && (
        <TermsConditions
          showModal={showModal}
          closeModel={closeModel}
          termsData={termsData}
        ></TermsConditions>
      )}
      {videoWidgetData?.toggle && <VideoWidget />}
      {/* Logout confirm dialog if auto_drive running */}
      {autoDriveLogoutConfirm && (
        <AutoDriveLogoutConfirm
          showModal={autoDriveLogoutConfirm}
          closeModel={(ok?: boolean) => {
            autoDriveDispatch({
              type: AUTO_DRIVE_LOGOUT_CONFIRM,
              payload: false,
            });
            if (ok) {
              handlePauseResumeStopLiveCom('stop');
            }
          }}
        />
      )}
      {/* {drivingTractors.length > 0 && <DeadManHandle />} */}
      {!!analyticDashboardData?.show_analytics && <AnalyticCard />}
    </>
  );
};

export default AppSideMenu;
