import { Button, Checkbox } from 'antd';
import React from 'react';
import sch_info_icon from '../../assets/images/sch_info_icon.svg';
import sch_info_icon_gray from '../../assets/images/sch_info_icon_gray.svg';
import scheduled_date from '../../assets/images/scheduled_date.svg';
import poor_wifi from '../../assets/images/poor_wifi.svg';
import fullWifi from '../../assets/images/wFull.svg';
import alert_circle_red from '../../assets/images/alert_circle_red.svg';
import lowWifi from '../../assets/images/wLow.svg';
import mediumWifi from '../../assets/images/wMedium.svg';
import { CommonProgressBar } from './CommonProgressBar';
import constant from '../../constants/constant';

const { TRACTOR_COMMUNICATION } = constant;
interface Props {
  filterData: any;
  tractorIds: any;
  setTractorIds: (data: any) => void;
  setInstallUpdate: (d: boolean) => void;
  setScheduleUpdate: (d: boolean) => void;
  disable: boolean;
}

export const InstallationsContainer: React.FC<Props> = ({
  filterData,
  tractorIds,
  setTractorIds,
  setInstallUpdate,
  setScheduleUpdate,
  disable,
}) => {
  const statusOrder = [
    'sw_fw_mismatch',
    'schedule_failed',
    'failed',
    'scheduled',
    'out_of_date',
  ];

  const scheduleList = () =>
    filterData.filter(
      (record: any) =>
        record?.ota_status !== 'up_to_date' &&
        record?.ota_status !== 'inprogress' &&
        record?.ota_status !== 'in_progress' &&
        record?.ota_status !== 'in-progress' &&
        record?.ota_status !== 'pending' &&
        (!record?.otaDownloadStatus ||
          (record?.otaDownloadStatus && record?.otaDownloadPercentage === 100)),
    );

  const handleCheckBox = (id: number) => {
    const updatedSelectedIds = [...tractorIds];

    if (updatedSelectedIds.includes(id)) {
      updatedSelectedIds.splice(updatedSelectedIds.indexOf(id), 1);
    } else {
      updatedSelectedIds.push(id);
    }

    setTractorIds(updatedSelectedIds);
  };

  const getConnectivityImg = (state: number) => {
    const img =
      state === 0 || state === 1
        ? fullWifi
        : state === 2
        ? mediumWifi
        : lowWifi;

    return img;
  };

  const showTractorCard = (status: string, record: any) => {
    if (
      status === 'schedule_failed' ||
      status === 'failed' ||
      status === 'rollback'
    ) {
      return (
        <>
          <div
            className={
              tractorIds.includes(record.id)
                ? 'outOfBox box_blur blueBorder'
                : 'outOfBox box_blur pointer'
            }
            key={record.id}
          >
            <div className="swFwTxt" onClick={() => handleCheckBox(record.id)}>
              <div className="tractorContainer mb8">
                <div className="numberTxt">
                  {' '}
                  <Checkbox
                    className="mr10"
                    key={record.id}
                    checked={tractorIds.includes(record.id)}
                  />
                  {record?.name}
                </div>

                <div className="downloadTxt">Downloaded</div>
              </div>

              <div className="swUpdateTxt ml30 ofHidden">
                <div>
                  <span>Software</span> {record?.softwareVersion}
                </div>
                <div>
                  <span>Firmware</span> {record?.firmWareVersion}
                </div>
              </div>
            </div>

            <>
              <div className="backgroundInfo">
                <div className="ud_i_tooltip redBdr">
                  {status === 'schedule_failed' ? (
                    <div>
                      {`Scheduled update on ${record?.scheduled_date_time} could
                      not start due to the following issues. Please try again.`}
                      <div className="updateCon mt10">
                        <img src={alert_circle_red} alt="" className="mr4" />
                        {record?.scheduleRemarks}
                      </div>
                    </div>
                  ) : (
                    'File a fleet ticket to Monarch Support. Reboot the tractor and try installing again.'
                  )}
                </div>

                <img src={sch_info_icon} alt="" height="14px" />
                <div className="ml5">
                  {status === 'schedule_failed'
                    ? 'Install Failed: Missing Requirements. Try again.'
                    : 'Install Failed. Try again.'}
                </div>
              </div>
            </>
          </div>
        </>
      );
    }

    if (status === 'scheduled' || status === 'cancelled') {
      return (
        <>
          <div
            className={
              tractorIds.includes(record.id)
                ? 'outOfBox box_blur blueBorder'
                : 'outOfBox box_blur pointer'
            }
            key={record.id}
            onClick={() => handleCheckBox(record.id)}
          >
            <div className="swFwTxt">
              <div className="tractorContainer mb8">
                <div className="numberTxt">
                  {' '}
                  <Checkbox
                    className="mr10"
                    key={record.id}
                    checked={tractorIds.includes(record.id)}
                  />
                  {record?.name}
                </div>

                <div className="downloadTxt">
                  <img
                    height="18px"
                    className="mr7"
                    src={scheduled_date}
                    alt=""
                  />
                  {record?.schDate}
                </div>
              </div>

              <div className="swUpdateTxt ml30 ofHidden">
                <div>
                  <span>Software</span> {record?.softwareVersion}
                </div>
                <div>
                  <span>Firmware</span> {record?.firmWareVersion}
                </div>
              </div>
            </div>
            <></>
          </div>
        </>
      );
    }

    if (status === 'out_of_date') {
      return (
        <>
          <div
            className={
              tractorIds.includes(record.id)
                ? 'outOfBox box_blur blueBorder'
                : 'outOfBox box_blur pointer'
            }
          >
            <div className="swFwTxt" onClick={() => handleCheckBox(record.id)}>
              <div className="tractorContainer mb8">
                <div className="numberTxt">
                  {' '}
                  <Checkbox
                    className="mr10"
                    key={record.id}
                    checked={tractorIds.includes(record.id)}
                  />
                  {record?.name}
                </div>
                {record?.otaDownloadStatus && (
                  <div className="downloadTxt">Downloaded</div>
                )}
                {!record?.otaDownloadStatus && (
                  <>
                    {record.connection === 'offline' ? (
                      <img src={poor_wifi} alt="" />
                    ) : (
                      <img
                        className="indicatorImg"
                        src={getConnectivityImg(
                          record.indicators[TRACTOR_COMMUNICATION],
                        )}
                      />
                    )}
                  </>
                )}
              </div>

              <div className="swUpdateTxt ml30 ofHidden">
                <div>
                  <span>Software</span> {record?.softwareVersion}
                </div>
                <div>
                  <span>Firmware</span> {record?.firmWareVersion}
                </div>
              </div>
            </div>
            <>
              {!record?.otaDownloadStatus && (
                <div className="backgroundInfo">
                  <div className="ud_i_tooltip">
                    This tractor is outdated and does not have background
                    download functionality. To update, tractor will need strong
                    connectivity and will take longer.
                  </div>

                  <img src={sch_info_icon_gray} alt="" height="14px" />
                  <div className="grayColor ml5">
                    Background download unavailable
                  </div>
                </div>
              )}
            </>
          </div>
        </>
      );
    }
    if (status === 'sw_fw_mismatch') {
      return (
        <>
          <div
            className={
              tractorIds.includes(record.id)
                ? 'outOfBox box_blur blueBorder'
                : 'outOfBox box_blur pointer'
            }
          >
            <div className="swFwTxt" onClick={() => handleCheckBox(record.id)}>
              <div className="tractorContainer mb8">
                <div className="numberTxt">
                  {' '}
                  <Checkbox
                    className="mr10"
                    key={record.id}
                    checked={tractorIds.includes(record.id)}
                  />
                  {record?.name}
                </div>
              </div>

              <div className="swUpdateTxt ml30 ofHidden">
                <div>
                  <span>Software</span> {record?.softwareVersion}
                </div>
                <div>
                  <span>Firmware</span> {record?.firmWareVersion}
                </div>
              </div>
            </div>
            <>
              <div className="backgroundInfo">
                <div className="ud_i_tooltip redBdr">
                  Mismatch of software and firmware on the tractor. A service
                  ticket has been created, and Monarch will provide further
                  instructions shortly.
                </div>

                <img src={sch_info_icon} alt="" height="14px" />
                <div className="ml5">Update Incomplete</div>
              </div>
            </>
          </div>
        </>
      );
    }
  };

  return (
    <div className="mainContainers1">
      <div className="tractorContainer padding1620">
        <div className="outOfTxt">Ready for Install</div>
        <div className="tractorsLength">{scheduleList().length}</div>
      </div>
      <div className="insBoxContainer">
        {scheduleList().length > 0 ? (
          <>
            {scheduleList()
              .sort(
                (a: any, b: any) =>
                  statusOrder.indexOf(a?.ota_status?.toLocaleLowerCase()) -
                  statusOrder.indexOf(b?.ota_status?.toLocaleLowerCase()),
              )
              .map((record: any) =>
                showTractorCard(
                  record?.ota_status?.toLocaleLowerCase(),
                  record,
                ),
              )}
          </>
        ) : (
          <div className="outOfBoxContent">
            Once download is finished, you will be notified to return here to
            update your fleet.{' '}
          </div>
        )}
        {/* <div className="installationsCon1">
          <div className="tractorContainer">
            <div className="numberTxt">
              <Checkbox className="mr10" />
              10168
            </div>
            <div className="downloadTxt">Downloaded</div>
          </div>
          <div className="swUpdateTxt">Software</div>
          <div className="swUpdateTxt">Firmware</div>
        </div> */}
      </div>

      <div className="buttonsCon">
        <Button
          data-testid="installNowButton-Updates"
          className="installNowBtn"
          onClick={() => setInstallUpdate(true)}
          disabled={disable}
        >
          Install Now
        </Button>
        <Button
          className="schBtn"
          onClick={() => setScheduleUpdate(true)}
          disabled={disable}
        >
          Schedule
        </Button>
      </div>
    </div>
  );
};
