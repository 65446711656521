import { useEffect, useState } from 'react';
import tablesColumnsMap from '../lib/extCol';

const useColumns = (tableName = ''): any => {
  const [columnsData, setColumnsData] = useState<any>([]);
  const [columns, setColumns] = useState<any[]>([]);
  const { defaults, labels } = tablesColumnsMap[tableName];
  const [headers, setHeaders] = useState<any[]>([]);

  useEffect(() => {
    let theaders: any = [];
    if (columns) {
      theaders = columns
        .map((column: any) => ({
          label: labels ? labels[column.dataIndex] : column.title,
          key: column.dataIndex,
        }))
        .filter(({ key }) => key !== 'action');
      setHeaders(theaders);
    }
  }, [columns]);

  useEffect(() => {
    const handleColumns = () => {
      const tcols = defaults;
      const tcolumns: any[] = columnsData.filter((column: any) =>
        tcols.includes(column.key),
      );
      setColumns(tcolumns);
    };
    handleColumns();
  }, [columnsData]);

  return {
    setColumnsData,
    setColumns,
    columns,
    headers,
  };
};

export default useColumns;
