import React, { useContext, useState } from 'react';
import { Button, Modal, notification } from 'antd';
import { ApplicationContext } from '../../context/AppContext';
import { useTeamsContext } from '../../context/TeamContext';
import { SET_TEAMS_REFRESH } from '../../context/actions';
import translate from '../../locale/en_translate.json';
import { useTranslation } from 'react-i18next';
import logo from '../../assets/images/logo192.png';
import { removeTeam } from '../../constants/Api';

import AppLoader from '../common/AppLoader';
import './style.css';

interface Props {
  showModal: boolean;
  closeModel: (status: boolean) => void;
  teamName: string;
  teamId: number;
}

export const DeleteTeam: React.FC<Props> = ({
  showModal,
  closeModel,
  teamName,
  teamId,
}: Props) => {
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const { teamReducer } = useTeamsContext();
  const [, dispatch] = teamReducer;
  const [loader, setLoader] = useState<boolean>(false);

  const deleteTeam = async () => {
    try {
      setLoader(true);
      await removeTeam(
        userDetails.organization.api_url,
        userDetails.organization.id,
        teamId,
      );
      notification.success({
        message: (
          <span data-testid="teamDeletedSuccessMessage-DeleteTeam">{`${teamName} Deleted Successfully`}</span>
        ),
      });
      setLoader(false);
      closeModel(true);
      dispatch({ type: SET_TEAMS_REFRESH });
    } catch (error: any) {
      notification.error({ message: error.message });
      setLoader(false);
      closeModel(false);
    }
  };

  return (
    <>
      <Modal
        className="modalContainer teammodal discardmodal"
        visible={showModal}
        onCancel={() => closeModel(false)}
        width={590}
        closable={false}
        maskClosable={false}
        footer={[
          <>
            <Button
              data-testid="cancelButton-DeleteTeam"
              key="back"
              className="btnteamCancel captalize Button"
              onClick={() => closeModel(false)}
            >
              {t(translate.buttons.cancel)}
            </Button>
            <Button
              data-testid="deleteButton-DeleteTeam"
              key="submit"
              className="btnteamSave captalize Button"
              onClick={deleteTeam}
            >
              {t(translate.buttons.delete)}
            </Button>
          </>,
        ]}
      >
        <AppLoader loader={loader} />
        <div className="createteamContainer">
          <div className="createtitle">
            <div className="pull-left">
              <h1
                className="title headline1"
                data-testid="deleteTeamTitle-DeleteTeam"
              >
                {t(translate.deleteTeam.title)}
              </h1>
            </div>
            <div className="img-div pull-right">
              <img src={logo} />
            </div>
          </div>
          <p
            className="teamdescription body1"
            data-testid="deleteTeamConfirmPopUp-DeleteTeam"
          >
            {t(translate.deleteTeam.confirmation)}{' '}
            <span className="teambold">{teamName}</span>{' '}
            {t(translate.deleteTeam.team)}?
          </p>
        </div>
      </Modal>
    </>
  );
};

export default DeleteTeam;
