import { Button, Space, Spin } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';

import loading_logo from '../../../assets/images/loading_logo.gif';
import tglRightIcon from '../../../assets/images/arrow_toggle_right.svg';
import tglDownIcon from '../../../assets/images/arrow_toggle_down.svg';

import {
  getCameraViewAllow,
  tractorCameras as cameras,
  tractorConstantsNew,
  tractorCamerasLabels,
  checkForCNHITractor,
} from '../../../constants/Common';
import { track } from '../../../util/logger';
import { speCharRegX } from '../../../constants/constant';

import { ApplicationContext } from '../../../context/AppContext';

import { RemoteDriveAppCtx } from '../../remote_drive_new/RemoteDriveContext';
import AllCameraDown from '../../temp/components/common/CameraViews/AllCameraDown';

import './style.scss';

interface Props1 {
  isUp: boolean;
  title: string;
}

const TooltipC: React.FC<Props1> = ({ children, isUp }) => {
  return !isUp ? (
    <div
      className="cam-down"
      style={{
        width: '100%',
      }}
    >
      {children}
    </div>
  ) : (
    <>{children}</>
  );
};

interface Props {
  orgId: number;
  selectedTractorId: number;
  tractorName: string;
  triggerSnapshot: () => void;
  model?: string;
}
export const LiveCamera: React.FC<Props> = ({
  orgId,
  selectedTractorId,
  tractorName,
  triggerSnapshot,
  model,
}) => {
  const [selectedCamera, setSelectedCamera] = useState('');
  const [toggle, setToggle] = useState<boolean>(true);

  const cameraWindows = useRef<Map<string, any | undefined>>(
    new Map<string, any | undefined>(),
  );
  const { RDReducer } = useContext(RemoteDriveAppCtx);
  const { openCameraWindow } = useContext(ApplicationContext);
  const [loading, setLoading] = useState(true);
  const [state] = RDReducer;
  const { selectedTractor } = state;
  const { heartbeat, isAvailable } = selectedTractor;
  const [allCamerasDown, setAllCamerasDown] = useState(true);

  tractorName = !speCharRegX?.test(tractorName)
    ? encodeURIComponent(tractorName)
    : tractorName;

  useEffect(() => {
    if (
      selectedTractor &&
      selectedTractor.isAvailable &&
      selectedTractor.isAvailable === tractorConstantsNew.off.key
    ) {
      setAllCamerasDown(true);
    } else {
      setAllCamerasDown(false);
    }
  }, [selectedTractor]);

  const getCameraStatus = (face: string) =>
    getCameraViewAllow(heartbeat, face, isAvailable);

  useEffect(() => {
    const allStatus = {
      [cameras.PTO]: getCameraStatus(cameras.PTO),
      [cameras.FRONT]: getCameraStatus(cameras.FRONT),
      [cameras.FRONT_LEFT]: getCameraStatus(cameras.FRONT_LEFT),
      [cameras.FRONT_RIGHT]: getCameraStatus(cameras.FRONT_RIGHT),
      [cameras.REAR]: getCameraStatus(cameras.REAR),
    };
    const [cface] = Object.keys(allStatus).filter(
      (status) => allStatus[status],
    );
    if (model && checkForCNHITractor(model)) {
      setSelectedCamera(cface || cameras.FRONT);
    } else {
      setSelectedCamera(cface || cameras.PTO);
    }
  }, [selectedTractorId, model]);

  const handleClick = (face: string) => {
    getCameraView(selectedTractorId, face, tractorName);
  };

  useEffect(() => {
    setLoading(true);
  }, [selectedCamera]);

  useEffect(() => {
    setToggle(allCamerasDown);
  }, [allCamerasDown]);

  const getCameraView = (
    tractorId: number,
    cameraView: string,
    tractorName: string,
  ) => {
    if (cameraWindows.current.has(cameraView)) {
      track('Live Map', {
        event: `Fleet List Camera ${cameraView} View Clicked`,
      });
      const getWindow = cameraWindows.current.get(cameraView);
      let camViews = localStorage.getItem('cam_views');
      if (camViews && !camViews.includes(`${tractorId}${cameraView}`)) {
        camViews = `${camViews}, ${tractorId}${cameraView}`;
        localStorage.setItem('cam_views', camViews);
      } else {
        localStorage.setItem('cam_views', `${tractorId}${cameraView}`);
      }
      if (`${tractorId}${cameraView}` !== getWindow?.name) {
        openCameraWindow(orgId, tractorId, cameraView, tractorName);
      } else {
        getWindow.focus();
      }
    } else {
      openCameraWindow(orgId, tractorId, cameraView, tractorName);
    }
  };

  const getTitle = (face: string) => {
    if ([cameras.FRONT_LEFT, cameras.FRONT_RIGHT].includes(face))
      return 'Xavier1 is Down';
    if ([cameras.FRONT].includes(face)) return 'Xavier2 is Down';
    if ([cameras.REAR, cameras.PTO].includes(face)) return 'Xavier6 is Down';
    return 'System is Down';
  };

  return (
    <>
      <div
        className={`${
          toggle ? 'cameraViewTitle openCamtgl' : 'cameraViewTitle'
        }`}
      >
        <h5
          className="headline5 pointer"
          data-testid="cameraViewsHeader-CamerasPage1"
          onClick={() => setToggle(!toggle)}
        >
          <span className="mr5">
            {!toggle ? (
              <img src={tglRightIcon} alt="" />
            ) : (
              <img src={tglDownIcon} alt="" />
            )}
          </span>
          <span className="camHeadViewTxt">Camera Views </span>
        </h5>
      </div>
      <div className={`camera-views ${toggle ? 'camShow' : ''}`}>
        <div className="alert">
          {allCamerasDown && <AllCameraDown />}

          <div className="pad0 mbcams">
            {!allCamerasDown && selectedCamera !== '' && (
              <div
                className="cameraViewSec cameraViewScreen"
                style={{ position: 'relative' }}
              >
                {getCameraStatus(selectedCamera) ? (
                  <Space>
                    <span
                      className="popoutIcon"
                      onClick={() => handleClick(selectedCamera)}
                    ></span>
                    <span
                      data-testid="cameraViewIcon-CamerasPage1"
                      className="captureIcon"
                      onClick={triggerSnapshot}
                    ></span>

                    {/* <Spin indicator={antIcon} spinning={loading}> */}
                    <Spin
                      className="camera-view-spinner"
                      spinning={loading}
                      tip="Connecting to Tractor"
                      indicator={
                        <img
                          style={{ width: '2em', height: '1em' }}
                          src={loading_logo}
                        />
                      }
                    >
                      <iframe
                        src={`${window.location.origin}/CameraView/${selectedTractorId}/stream/${tractorName}/${selectedCamera}/false?orgId=${orgId}`}
                        height={'100%'}
                        width={'100%'}
                        style={{ overflow: 'hidden' }}
                        scrolling="no"
                        id="cammms"
                        onLoad={() => setLoading(false)}
                      />
                    </Spin>
                  </Space>
                ) : (
                  <AllCameraDown face={tractorCamerasLabels[selectedCamera]} />
                )}
              </div>
            )}

            {!allCamerasDown && (
              <div className="camera_box_container mb4 border-none camera_view_buttons newCamBtns">
                {model && !checkForCNHITractor(model) && (
                  <TooltipC
                    isUp={getCameraStatus(cameras.PTO)}
                    title={getTitle(cameras.PTO)}
                  >
                    <Button
                      block
                      className={`camera_box ${
                        selectedCamera === cameras.PTO ? 'active' : ''
                      }`}
                      onClick={() => setSelectedCamera(cameras.PTO)}
                    >
                      <h3 className="t_camera_lbl body3">Hitch</h3>
                    </Button>
                  </TooltipC>
                )}
                <TooltipC
                  isUp={getCameraStatus(cameras.FRONT)}
                  title={getTitle(cameras.FRONT)}
                >
                  <Button
                    block
                    className={`camera_box ${
                      selectedCamera === cameras.FRONT ? 'active' : ''
                    }`}
                    onClick={() => setSelectedCamera(cameras.FRONT)}
                  >
                    <h3 className="t_camera_lbl body3">Front</h3>
                  </Button>
                </TooltipC>
                <TooltipC
                  isUp={getCameraStatus(cameras.FRONT_LEFT)}
                  title={getTitle(cameras.FRONT_LEFT)}
                >
                  <Button
                    block
                    className={`camera_box ${
                      selectedCamera === cameras.FRONT_LEFT ? 'active' : ''
                    }`}
                    onClick={() => setSelectedCamera(cameras.FRONT_LEFT)}
                  >
                    <h3 className="t_camera_lbl body3">Left</h3>
                  </Button>
                </TooltipC>
                <TooltipC
                  isUp={getCameraStatus(cameras.FRONT_RIGHT)}
                  title={getTitle(cameras.FRONT_RIGHT)}
                >
                  <Button
                    block
                    className={`camera_box ${
                      selectedCamera === cameras.FRONT_RIGHT ? 'active' : ''
                    }`}
                    onClick={() => setSelectedCamera(cameras.FRONT_RIGHT)}
                  >
                    <h3 className="t_camera_lbl body3">Right</h3>
                  </Button>
                </TooltipC>
                <TooltipC
                  isUp={getCameraStatus(cameras.REAR)}
                  title={getTitle(cameras.REAR)}
                >
                  <Button
                    block
                    className={`camera_box ${
                      selectedCamera === cameras.REAR ? 'active' : ''
                    }`}
                    onClick={() => setSelectedCamera(cameras.REAR)}
                  >
                    <h3 className="t_camera_lbl body3">Rear</h3>
                  </Button>
                </TooltipC>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default LiveCamera;
