/* eslint-disable react/jsx-key */
import { Modal } from 'antd';
import React from 'react';

interface Props {
  openModal: boolean;
  closeModal: () => void;
}
export const UpdateProcessModal: React.FC<Props> = ({
  openModal,
  closeModal,
}: Props) => {
  return (
    <Modal
      className="updateScheduleModal schClose"
      visible={openModal}
      onCancel={closeModal}
      footer={false}
    >
      <div className="updatePrTxt">Update Process</div>
      <div className="steps_items">
        <div className="upProcess1">
          <div className="ad_posrel">
            <div className="process1">1</div>
            <div className="processLine" />
          </div>
          <div>
            <div className="backgroundTxt">Background Download</div>
            <div className="bgTractorTxt">
              The tractor will auto download the update when connected to the
              internet and nobody is logged in.
            </div>
          </div>
        </div>

        <div className="upProcess1">
          <div className="ad_posrel">
            <div className="process1">2</div>
            <div className="processLine" />
          </div>
          <div>
            <div className="backgroundTxt">Schedule or Install Now</div>
            <div className="bgTractorTxt">
              When the update is ready to be installed, you can schedule the
              update for a later time or install now.
            </div>
          </div>
        </div>

        <div className="upProcess1">
          <div className="ad_posrel">
            <div className="process1">3</div>
            <div className="processLine" />
          </div>
          <div>
            <div className="backgroundTxt">Requirements Check</div>
            <div className="bgTractorTxt">
              Make sure that the tractor meets all requirements prior to
              updating. The tractor’s battery must be above 20%, motor turned
              off, and all computers should be online.
            </div>
          </div>
        </div>

        <div className="upProcess1 mb20">
          <div className="process1">4</div>
          <div>
            <div className="backgroundTxt">Successful Update</div>
            <div className="bgTractorTxt">
              Explore the new and improved features and read the release notes
              to learn what’s new.
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
