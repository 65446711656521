/* eslint-disable react/jsx-key */
import React, { useState } from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';
import classNames from 'classnames';
// import './style.css';

const { RangePicker } = DatePicker;

interface Props {
  label?: string;
  value?: any;
  cssClass: any;
  onChange: (dates: any) => void;
  dateTime?: boolean;
  required?: any;
  validations?: any;
  format?: string;
  showTime?: boolean;
  allowClear?: boolean;
  testId?: any;
}

const CustomDate: React.FC<Props> = ({
  label,
  value,
  cssClass,
  onChange,
  dateTime,
  required,
  validations,
  format,
  showTime,
  testId,
  allowClear = true,
}: Props) => {
  const [focus, setFocus] = useState(false);

  const labelClass =
    focus || (value && value.length !== 0) ? 'label label-float' : 'label';

  const handleOnChange = (dates: any) => {
    onChange(dates);
  };

  function disabledDate(current: any) {
    // Can not select days before today and today
    return current && current > moment();
  }

  return (
    <div
      className="float-label"
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      {dateTime ? (
        <>
          <DatePicker
            format={format || 'MM-DD-YYYY hh:mm A'}
            data-testid="incidentTimeInputField-CustomData"
            value={value}
            onChange={handleOnChange}
            showTime={showTime}
            allowClear={allowClear}
            inputReadOnly={true}
            disabledDate={disabledDate}
            className={
              !validations && required
                ? classNames({
                    errorClass: !validations && required,
                  })
                : cssClass
            }
          />
          <label className={labelClass}>
            {label} {required && <span className="float-span">*</span>}
          </label>
        </>
      ) : (
        <>
          <RangePicker
            format="YYYY-MM-DD"
            className={cssClass}
            onChange={handleOnChange}
            defaultValue={value}
            disabledDate={disabledDate}
          />
          <label className={labelClass}>{label}</label>
        </>
      )}
    </div>
  );
};

export default CustomDate;
