// Import necessary packages and styles
import React, { useEffect } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import '@amcharts/amcharts4/core.js';
import '@amcharts/amcharts4/charts.js';
import { convertDateFormat } from '../operationalAnalytics/common';
import loading_logo from '../../assets/images/loading_logo.gif';

interface Props {
  data: any;
  chartId: string;
  smooth: boolean;
  points: boolean;
  coverArea: boolean;
  lineColor: string;
  areaColor?: string;
  loader: boolean;
  yTitle?: string;
  height?: number;
  width?: number;
}
// Apply amCharts themes
am4core.useTheme(am4themes_animated);

const LineChart: React.FC<Props> = ({
  data,
  chartId,
  smooth,
  points,
  coverArea,
  lineColor,
  loader,
  yTitle,
  height,
  width,
}) => {
  useEffect(() => {
    // Create a chart instance
    const chart = am4core.create(chartId, am4charts.XYChart);
    chart.logo.disabled = true;
    chart.paddingTop = 30;

    if (!loader && data?.length > 0) chart.data = data;

    // Create category axis
    const categoryAxis: any = chart.xAxes.push(new am4charts.DateAxis());

    categoryAxis.title.fontSize = 10;
    categoryAxis.title.fontFamily = 'Montserrat';
    categoryAxis.title.fontWeight = '500';
    categoryAxis.title.fill = am4core.color('#333333');

    categoryAxis.renderer.labels.template.fontSize = 10;
    categoryAxis.renderer.labels.template.fontFamily = 'Montserrat';
    categoryAxis.renderer.labels.template.fontWeight = '400';
    categoryAxis.renderer.labels.template.fill = am4core.color('#5C5C5C');

    categoryAxis.renderer.labels.template.paddingRight = 0;
    categoryAxis.renderer.labels.template.paddingLeft = 0;
    categoryAxis.renderer.labels.template.marginRight = 6;
    categoryAxis.renderer.labels.template.marginLeft = 6;

    chart.paddingRight = 0;
    chart.paddingBottom = 0;
    chart.paddingLeft = 0;

    chart.marginRight = 0;
    chart.marginBottom = 0;
    chart.marginLeft = 0;
    // categoryAxis.dataFields.category = 'category';
    categoryAxis.renderer.labels.template.location = 0.5;
    categoryAxis.renderer.labels.template.fontSize = 10;
    categoryAxis.dataFields.date = 'category';
    // categoryAxis.title.text = 'Categories';
    // categoryAxis.renderer.grid.template.disabled = true;
    // categoryAxis.renderer.labels.template.disabled = true;
    categoryAxis.dateFormatter.dateFormat = 'MM/dd';
    categoryAxis.dateFormats.setKey('day', 'MM/dd');
    categoryAxis.renderer.labels.template.fontSize = 10;
    categoryAxis.renderer.labels.template.fill = am4core.color('#1F1F1F');
    categoryAxis.renderer.labels.template.fontFamily = 'Montserrat-Regular';
    categoryAxis.title.fontSize = 10;
    categoryAxis.title.fill = am4core.color('#333333');
    categoryAxis.title.fontFamily = 'Montserrat-Medium';
    categoryAxis.renderer.minGridDistance = 20;

    // Create value axis
    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.fontSize = 10;
    valueAxis.title.fontFamily = 'Montserrat';
    valueAxis.title.fontWeight = '500';
    valueAxis.title.fill = am4core.color('#333333');

    valueAxis.renderer.labels.template.fontSize = 10;
    valueAxis.renderer.labels.template.fontFamily = 'Montserrat';
    valueAxis.renderer.labels.template.fontWeight = '400';
    valueAxis.renderer.labels.template.fill = am4core.color('#5C5C5C');

    valueAxis.renderer.labels.template.paddingRight = 0;
    valueAxis.renderer.labels.template.paddingLeft = 0;
    valueAxis.renderer.labels.template.marginRight = 6;
    valueAxis.renderer.labels.template.marginLeft = 6;
    if (yTitle) {
      valueAxis.title.text = yTitle;
      valueAxis.title.fontSize = 10;
      valueAxis.title.fontFamily = 'Montserrat';
      valueAxis.title.fontWeight = '500';
      valueAxis.title.fill = am4core.color('#333333');
    }
    // valueAxis.title.text = 'Values';
    // valueAxis.renderer.grid.template.disabled = true;
    // valueAxis.renderer.labels.template.disabled = true;

    // Create series
    const series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = 'value';
    series.dataFields.dateX = 'category';
    series.name = 'Series 1';
    series.tooltipHTML = `<div style="padding:6px">
  <div style="font-weight:400; font-size:12px">{dateX}</div>
  <div style="font-weight:600">
    ${chartId === 'efficiency' ? '' : '$'}{value}
  </div>
</div>`;

    const bullet = series.bullets.push(new am4charts.CircleBullet());
    bullet.circle.stroke = am4core.color('#fff');
    bullet.circle.fill = am4core.color('#546CB2');
    bullet.circle.radius = 4;
    bullet.circle.strokeWidth = 1;
    bullet.fillOpacity = 0;
    bullet.strokeOpacity = 0;

    const bulletState = bullet.states.create('hover');
    bulletState.properties.fillOpacity = 1;
    bulletState.properties.strokeOpacity = 1;

    // Add a cursor
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.lineY.disabled = true;
    chart.cursor.lineX.disabled = true;
    chart.cursor.lineX.stroke = am4core.color('#546CB2');
    chart.cursor.lineX.strokeWidth = 2;
    chart.cursor.lineX.strokeOpacity = 0.2;
    chart.cursor.lineX.strokeDasharray = '';
    valueAxis.cursorTooltipEnabled = false;
    categoryAxis.cursorTooltipEnabled = false;

    chart.events.on('beforedatavalidated', () => {
      categoryAxis.renderer.labels.template.adapter.add(
        'text',
        (text: any, target: any) => {
          const dataLength = chart?.data?.length;
          const lastDate = chart?.data[dataLength - 1]?.category;
          if (
            chart?.data?.length &&
            (target.dataItem.text ===
              convertDateFormat(chart?.data[0]?.category) ||
              target.dataItem.text === convertDateFormat(lastDate))
          ) {
            return text;
          } else {
            return '';
          }
        },
      );
    });

    // // Add legend
    // chart.legend = new am4charts.Legend();
    const markerTemplate = chart?.legend?.markers?.template;
    if (markerTemplate) {
      markerTemplate.width = 10;
      markerTemplate.height = 10;
      chart.legend.position = 'top';
      chart.legend.align = 'center';
      chart.legend.fontFamily = 'Montserrat-Regular';
      chart.legend.fontSize = 10;
    }

    //hide grid behind the chart
    categoryAxis.renderer.grid.template.opacity = 0;
    // valueAxis.renderer.grid.template.opacity = 0;

    // // Set the line to be smooth
    if (smooth) {
      series.tensionX = 0.8;
    }

    // Customize the color of the line
    series.stroke = am4core.color(lineColor);

    series.fill = am4core.color(lineColor);

    //stroke width
    series.strokeWidth = 2;

    // Apply gradient fill to the series
    // const gradient = new am4core.LinearGradient();
    // gradient.rotation = 90; // 90 degrees for top to bottom direction
    // gradient.addColor(am4core.color('#379526'), 0.6);
    // gradient.addColor(am4core.color('#379526'), 0);

    // series.fill = gradient;

    // fill color below the chart
    if (coverArea) {
      const gradient = new am4core.LinearGradient();
      gradient.addColor(am4core.color(lineColor), 0.6); // Start color (red) at 0%
      gradient.addColor(am4core.color(lineColor), 0); // Middle color (green) at 50%
      gradient.rotation = 90; // 90 degrees for top to bottom direction
      series.fillOpacity = 1; // Adjust the opacity as needed
      series.fill = am4core.color(lineColor);
      series.fill = gradient;
      series.strokeWidth = 2.5;

      // Customize tooltip appearance (if needed)
      if (series.tooltip) {
        series.tooltip.getFillFromObject = false;
        series.tooltip.background.fill = am4core.color('#F7F7F7'); // Set tooltip background color
        series.tooltip.label.fill = am4core.color('#546CB2'); // Set tooltip text color
        series.tooltip.autoTextColor = false;
        series.tooltip.fontSize = 12;
        series.tooltip.pointerOrientation = 'vertical';
        // series.tooltip.dy = -50;
        series.tooltip.fontFamily = 'Montserrat';
      }
    }

    // // fill color below the chart
    // if (coverArea) {
    //   series.fillOpacity = 0.2; // Adjust the opacity as needed
    //   series.fill = am4core.color(lineColor);
    // }

    // Show points on the line
    // if (points) {
    //   const bullet = series.bullets.push(new am4charts.CircleBullet());
    //   bullet.circle.fill = am4core.color(lineColor);
    // }

    let indicator: any;
    let indicatorInterval: any;

    function showIndicator(chart: any) {
      if (!indicator) {
        indicator = chart.tooltipContainer.createChild(am4core.Container);
        indicator.background.fill = am4core.color('#fff');
        indicator.background.fillOpacity = 0.8;
        indicator.width = am4core.percent(100);
        indicator.height = am4core.percent(100);

        // const indicatorLabel = indicator.createChild(am4core.Label);
        // indicatorLabel.text = 'Loading...';
        // indicatorLabel.align = 'center';
        // indicatorLabel.valign = 'middle';
        // indicatorLabel.fontSize = 20;
        // indicatorLabel.dy = 0;

        const hourglass = indicator.createChild(am4core.Image);
        hourglass.href = loading_logo;
        hourglass.align = 'center';
        hourglass.valign = 'middle';
        hourglass.horizontalCenter = 'middle';
        hourglass.verticalCenter = 'middle';
        hourglass.scale = 0.7;
      }

      indicator.hide(0);
      indicator.show();
    }

    function hideIndicator() {
      indicator?.hide();
      clearInterval(indicatorInterval);
    }

    loader ? showIndicator(chart) : hideIndicator();

    // Clean up when the component is unmounted
    return () => {
      chart.dispose();
    };
  }, [data, chartId, points, coverArea, smooth, lineColor, loader]); // Ensure this effect runs only once on mount

  return (
    <div
      id={chartId}
      // style={{
      //   width: width ?? '100%',
      //   height: height ?? '150px',
      // }}
      className="efficiencyGraph"
    />
  );
};

export default LineChart;
