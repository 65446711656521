import React, { useContext, useState, useEffect, useRef } from 'react';
import { Row, notification, Space, Input, Tag } from 'antd';
import Layout, { Content } from 'antd/lib/layout/layout';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { SearchOutlined } from '@ant-design/icons';

import {
  getBaseOrgErrorCodesData,
  getBaseOrgSubsystemsData,
  getBaseStationDiagnostics,
  getBaseStationDiagnosticsList,
  getBaseStationIndicatorSummaryData,
} from '../../constants/Api';

import { ApplicationContext } from '../../context/AppContext';

import {
  fromToDateWrapper,
  initScroller,
  sortString,
} from '../../constants/Common';
import translate from '../../locale/en_translate.json';

import sortDown from '../../assets/images/sort_down.svg';
import sortUp from '../../assets/images/sort_up.svg';
import successCheck from '../../assets/images/success_check.svg';
import alertIcon from '../../assets/images/alert_icon.svg';
import failCross from '../../assets/images/fail_cross.svg';
import FlagGrayIcon from '../../assets/images/flag_gray.svg';
import FlagOrangeIcon from '../../assets/images/flag_orange.svg';
import FlagRedIcon from '../../assets/images/flag_red.svg';
import communication from '../../assets/images/communication.svg';
import connectivity from '../../assets/images/connectivity.svg';
import compute from '../../assets/images/compute.svg';
import software from '../../assets/images/software.svg';

import { track } from '../../util/logger';
import usePaginate from '../../hooks/usePaginate';
import { mapBasestationDiagnosticData } from '../../lib/dataFormat';
import CSelect from '../common/CSelect';
// import { delay } from '../../constants/Common';
import CustomDate from '../common/CustomDate';
import CustomSeverityLevel from '../common/CustomSeverityLevel';
import CustomSelect1 from '../common/CustomSelect1';
import CustomSelect from '../common/CustomSelect';
import { resolveFilterData1 } from '../../constants/AppData';
import InfiniteScrollTable from '../common/InfiniteScrollTable';

import 'react-datepicker/dist/react-datepicker.css';

export const getTags = (details: any) => {
  const { functionality_affected } = details;
  let tags = <></>;
  if (functionality_affected && functionality_affected.trim() !== '') {
    tags = functionality_affected
      .split(functionality_affected.includes(';') ? ';' : ',')
      .map((element: string, key: number) => (
        <Tag key={key} className="tagsName">
          {element}
        </Tag>
      ));
  }
  return tags;
};
export const getSeverity = (level: number) => {
  let images;
  switch (level) {
    case 1:
      images = (
        <>
          <img src={FlagOrangeIcon} className="mr7" />
          <img src={FlagGrayIcon} className="mr7" />
          <img src={FlagGrayIcon} className="mr7" />
        </>
      );
      break;
    case 2:
      images = (
        <>
          <img src={FlagOrangeIcon} className="mr7" />
          <img src={FlagOrangeIcon} className="mr7" />
          <img src={FlagGrayIcon} className="mr7" />
        </>
      );
      break;
    case 3:
      images = (
        <>
          <img src={FlagRedIcon} className="mr7" />
          <img src={FlagRedIcon} className="mr7" />
          <img src={FlagRedIcon} className="mr7" />
        </>
      );
      break;
    default:
      images = (
        <>
          <img src={FlagGrayIcon} className="mr7" />
          <img src={FlagGrayIcon} className="mr7" />
          <img src={FlagGrayIcon} className="mr7" />
        </>
      );
      break;
  }
  return <Space>{images}</Space>;
};
const BasestationDiagnostics: React.FC = () => {
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize] = useState<number>(25);
  const [basestations, setBaseSationData] = useState<any[]>([]);
  const [basestationsHeartbeatData, setbasestationsHeartbeatData] = useState<
    any[]
  >([]);
  const [baseId, setBaseId] = useState<number>(0);
  const [toDateTime, setToDateTime] = useState<any>(null);
  const [totalCount, setTotalCount] = useState();
  const [search, setSearch] = useState<string>('');
  const [subSystem, setSubSystem] = useState<string>('');
  const [indicatorType, setIndicatorType] = useState<string>('');
  const [level, setLevel] = useState<string>('');
  const [indicators, setIndicators] = useState<any>({});
  const [errorCodes, setErrorCodes] = useState<any>([]);
  const [subSystems, setSubSystems] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [sortedInfo, setSortedInfo] = useState<any>({
    columnKey: 'indicator_level',
    order: 'descend',
  });
  const [sortedData, setSortedData] = useState<any>({
    created_date_time: 'descend',
  });
  const [dateFilter, setDateFilter] = useState<any>([]);
  // const [keyCode, setKeyCode] = useState<boolean>(false);
  const [status, setStatus] = useState<string>('resolved');
  const [errocode, setErrocode] = useState<string>();
  const searchString = useRef<string>('');
  const { filterData, setData, activity, hasMore, checkActivity } =
    usePaginate();

  const DiagnosticsindicatorList = [
    {
      indicator_name: 'Communication',
      indicator_key: 'Basestation_Communication',
    },
    {
      indicator_name: 'Connectivity',
      indicator_key: 'Basestation_Connectivity',
    },
    {
      indicator_name: 'Compute',
      indicator_key: 'Basestation_Compute',
    },

    {
      indicator_name: 'Software',
      indicator_key: 'Basestation_Software',
    },
  ];

  const columns = [
    {
      title: () => {
        return (
          <>
            {t(translate.headers.severity)}
            <span className="sort-pad">
              {sortedInfo.columnKey === 'indicator_level' && (
                <img
                  src={
                    sortedData['indicator_level'] === 'ascend'
                      ? sortUp
                      : sortDown
                  }
                />
              )}
            </span>
          </>
        );
      },

      sortDirections: ['descend', 'ascend', 'descend'],
      width: '13%',
      dataIndex: 'indicator_level',
      key: 'indicator_level',
      render: (indicator_level: number) => {
        return getSeverity(indicator_level);
      },
      sorter: (a: any, b: any) => {
        return a.indicator_level - b.indicator_level;
      },
      sortOrder: sortedInfo.columnKey === 'indicator_level' && sortedInfo.order,
      ellipsis: true,
      showSorterTooltip: false,
    },
    {
      title: () => {
        return (
          <>
            {t(translate.headers.dateTime)}
            <span className="sort-pad">
              {sortedInfo.columnKey === 'created_date_time' && (
                <img
                  className="sort-pad"
                  src={
                    sortedData['created_date_time'] === 'ascend'
                      ? sortUp
                      : sortDown
                  }
                />
              )}
            </span>
          </>
        );
      },
      defaultSortOrder: 'ascend',
      sortDirections: ['descend', 'ascend', 'descend'],
      dataIndex: 'created_date_time',
      key: 'created_date_time',
      width: '18%',
      sorter: (a: any, b: any) => {
        return a.created_date_time - b.created_date_time;
      },
      sortOrder:
        sortedInfo.columnKey === 'created_date_time' && sortedInfo.order,
      ellipsis: true,
      showSorterTooltip: false,
    },

    {
      title: `${t(translate.tractorDetails.errorcode)}`,
      dataIndex: 'error_code',
      key: 'error_code',
      width: '14%',
      render: (error_code: any) => <>{error_code}</>,
    },
    {
      title: `${t(translate.headers.indicatorType)}`,
      width: '18%',
      dataIndex: 'indicator_name',
      key: 'indicator_name',
    },
    {
      title: `${t(translate.headers.issue)}`,
      width: '18%',
      dataIndex: 'subsystem',
      key: 'subsystem',
      render: (subsystem: string) => {
        return <span className="description">{subsystem}</span>;
      },
    },
    {
      title: `${t(translate.myTask.description)}`,
      dataIndex: 'description',
      key: 'description',
      // width: '200px',
      ellipsis: true,
      render: (description: string) => {
        return (
          <div className="description">
            <div className="description-text">{description}</div>
          </div>
        );
      },
    },
  ];

  const getOrgErrorCodes = async () => {
    const result: any = await getBaseOrgErrorCodesData(
      userDetails.organization.api_url,
    );
    const errorCodesData = result && result.length > 0 ? result : [];

    const data = errorCodesData.map((item: any) =>
      Object.assign({
        label: item.indicator_error_code,
        value: item.indicator_error_code,
      }),
    );
    setErrorCodes(data);
  };

  const getOrgSubsystems = async () => {
    const result: any = await getBaseOrgSubsystemsData(
      userDetails.organization.api_url,
    );
    const subSystemsData = result && result.length > 0 ? result : [];
    const data = subSystemsData.map((item: any) =>
      Object.assign({
        label: item.subsystem,
        value: item.id,
      }),
    );
    setSubSystems(data);
  };

  const initDefaults = async () => {
    if (userDetails && userDetails.organization) {
      getBaseStaionList();
    }
  };

  const wrapResult = async (
    apiUrl: string,
    pageNumber: number,
    pageSize: number,
    search: string,
    sortedInfo: string,
    fromDate: string,
    toDate: string,
    status: string,
    level: string,
    type: string,
    subsystem: string,
    errCode: string,
    baseId: number,
  ) => {
    const result: any = await getBaseStationDiagnostics(
      apiUrl,
      pageNumber,
      pageSize,
      search,
      sortedInfo,
      fromDate,
      toDate,
      status,
      level,
      type,
      subsystem,
      errCode,
      baseId,
    );
    return {
      result,
      searchKey: search,
    };
  };

  const fleetIndicatorHistoryList = async () => {
    if (checkActivity()) initScroller();
    // if (keyCode) {
    //   await delay(1000);
    // }
    const { organization } = userDetails;
    try {
      const fromDateTime = dateFilter
        ? dateFilter[0] &&
          moment(dateFilter[0]).startOf('day').toDate().getTime()
        : '';
      const toDateTime = dateFilter
        ? dateFilter[1] && moment(dateFilter[1]).endOf('day').toDate().getTime()
        : '';
      let type = '';
      DiagnosticsindicatorList.forEach((ele: any) => {
        if (ele.indicator_name === indicatorType) {
          type = ele.indicator_key;
        }
      });
      setLoading(true);
      const { result, searchKey } = await wrapResult(
        organization.api_url,
        pageNumber,
        pageSize,
        search ? search : '',
        sortedInfo,
        fromDateTime,
        toDateTime,
        status,
        level,
        type,
        subSystem,
        errocode || '',
        baseId,
      );

      if (searchString.current !== searchKey) return;

      const { _metadata } = result;
      setTotalCount(_metadata?.total_records_count);
      const { records } = result;
      let data = Array.isArray(records) ? records : [];
      data = mapBasestationDiagnosticData(data);
      setData(data);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const wrapIndictorsData = (arr: any) => {
    const data = arr.reduce((a: any, c: any) => {
      a[c.indicator_name] = c.indicator_level;
      return a;
    }, {});
    return data;
  };

  const getBaseIndicatorSummary = async () => {
    try {
      const data = await getBaseStationIndicatorSummaryData(
        userDetails.organization.api_url,
        baseId,
      );
      const indicatorsData = wrapIndictorsData(data);
      setIndicators(indicatorsData);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const getBaseStaionList = async () => {
    try {
      const records: any = await getBaseStationDiagnosticsList(
        userDetails.organization.api_url,
        userDetails.organization_id,
      );
      let data = Array.isArray(records) ? records : [];
      data = data.map((element: any) => {
        const {
          basestation_id,
          name,
          basestation_connected_status: bcs,
        } = element;
        element.id = basestation_id;
        element.name = name || '-';
        element.status = bcs ? 'available' : 'Off';
        return element;
      });
      setbasestationsHeartbeatData(data);
      if (data.length) setBaseId(data[0].id);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    }
  };

  const handleSelect = async (e: any) => {
    setBaseId(e);
  };

  const levelSelect = (e: string) => {
    setLevel(e);
  };
  const selectIndicatorType = (e: string) => {
    setIndicatorType(e);
  };
  const subSystemSelect = (e: string) => {
    setSubSystem(e);
  };

  const errorCodesSelect = (e: string) => {
    setErrocode(e);
  };

  const onDateRangeChange = (dates: any) => {
    if (dates) {
      const [from, to] = fromToDateWrapper(dates);
      setToDateTime(to.toDate());
    } else {
      setToDateTime(null);
    }
  };

  const handleChange = (pagination: any, filters: any, sorter: any) => {
    activity.current = 'sort';
    const { columnKey, order } = sorter;
    setSortedData({ ...sortedData, [columnKey]: order });
    setSortedInfo(sorter);
  };

  const handleSearch = (e: any) => {
    activity.current = 'search';
    const value = e.target.value.trim().regX;
    searchString.current = value;
    if (value.length <= 2) {
      searchString.current = '';
      if (search != '') setData([]);
      setSearch('');
    } else {
      searchString.current = value;
      setSearch(value);
      track('Basectation Diagnostics', {
        event: `Search Basestation Dignostics : ${value}`,
      });
    }
  };

  const handleLoadMore = () => {
    if (pageNumber === 1 && checkActivity() && document) initScroller();

    activity.current = 'paginate';
    setPageNumber(pageNumber + 1);
  };

  const getCrossCheck = (state: number) => {
    if (state == 0 || state == 1) {
      return <img src={successCheck} height="21px" width="21px" />;
    } else if (state == 2) {
      return <img src={alertIcon} height="21px" width="21px" />;
    } else if (state == 3) {
      return <img src={failCross} height="21px" width="21px" />;
    }
  };

  const handleIndicatorTypes = (indType: string) => {
    if (indType === indicatorType) {
      selectIndicatorType('');
    } else {
      selectIndicatorType(indType);
    }
  };

  useEffect(() => {
    if (dateFilter && dateFilter.length > 0) {
      onDateRangeChange(dateFilter);
    }
    if (dateFilter === null) {
      setToDateTime('');
    }
  }, [dateFilter]);

  useEffect(() => {
    if (basestationsHeartbeatData.length > 0) {
      setBaseSationData(basestationsHeartbeatData);
    }
  }, [basestationsHeartbeatData]);

  useEffect(() => {
    initDefaults();
  }, [userDetails]);

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      getOrgErrorCodes();
      getOrgSubsystems();
    }
  }, [userDetails]);

  useEffect(() => {
    if (userDetails && userDetails.organization && baseId) {
      activity.current = 'filter';
      getBaseIndicatorSummary();
    }
  }, [baseId]);

  useEffect(() => {
    if (baseId) {
      activity.current = 'filter';
      loadAlerts();
    }
  }, [level, subSystem, indicatorType, status, toDateTime, errocode]);

  const loadAlerts = () => {
    if (userDetails && !userDetails.organization) return;
    if (checkActivity()) {
      pageNumber !== 1 ? setPageNumber(1) : fleetIndicatorHistoryList();
    } else if (activity.current === 'paginate' || activity.current === '') {
      fleetIndicatorHistoryList();
    }
  };

  useEffect(() => {
    if (userDetails && userDetails.organization && sortedInfo.order && baseId) {
      loadAlerts();
    }
  }, [sortedInfo]);

  useEffect(() => {
    if (userDetails && userDetails.organization && baseId) {
      loadAlerts();
    }
  }, [userDetails, pageNumber, baseId, search]);

  // const handleKey = (e: any) => {
  //   setKeyCode(false);
  //   if (e.keyCode === 8) {
  //     setKeyCode(true);
  //   }
  // };

  const statusSelect = (e: string) => {
    setStatus(e);
  };

  return (
    <Layout>
      <Content>
        <div className="tblContainer">
          <Row className="w100per">
            <div className="flex w100per">
              <div className="item mr20">
                <div className="mb16 selectTractorDiag1">
                  <CSelect
                    list={sortString(basestations, 'status').map((item) =>
                      Object.assign({
                        id: item.id,
                        name: item.name,
                        isAvailable: item.status,
                      }),
                    )}
                    selectTrigger={(selected) => {
                      handleSelect(selected ? selected?.id : '');
                    }}
                    placeholder=""
                    label="Basestation"
                    tractorStatus={true}
                    defaultId={String(baseId)}
                    className="tractor-status"
                  />
                </div>
              </div>

              <div
                className="flex-1 flex-bg tractorHealthBlk"
                style={{ marginTop: '0' }}
              >
                <b
                  className="tractorHealthTitle overline2"
                  data-testid="tractorHealthHeader-Diagnostics"
                >
                  {t(translate.baseStationDiagnostics.title)}{' '}
                </b>
                <div className="flex tractorHealthGrid mt20">
                  <div
                    className="pointer"
                    onClick={() => handleIndicatorTypes('Communication')}
                  >
                    <div
                      className={
                        indicatorType === 'Communication'
                          ? 'pointerEvents'
                          : 'pointerEventsDefault'
                      }
                    >
                      <img className="indicatorImg" src={communication} />
                    </div>

                    <div
                      className="center tractorHealthTxt overline1"
                      data-testid="drivabilityHeader-Diagnostics"
                    >
                      {getCrossCheck(indicators['Communication'])}
                      {t(translate.baseStationDiagnostics.communication)}
                    </div>
                  </div>
                  <div
                    className="pointer"
                    onClick={() => handleIndicatorTypes('Connectivity')}
                  >
                    <div
                      className={
                        indicatorType === 'Connectivity'
                          ? 'pointerEvents'
                          : 'pointerEventsDefault'
                      }
                    >
                      <img className="indicatorImg" src={connectivity} />
                    </div>

                    <div
                      className="center tractorHealthTxt overline1"
                      data-testid="autonomyHeader-Diagnostics"
                    >
                      {getCrossCheck(indicators['Connectivity'])}
                      {t(translate.baseStationDiagnostics.connectivity)}
                    </div>
                  </div>
                  <div
                    className="pointer"
                    onClick={() => handleIndicatorTypes('Compute')}
                  >
                    <div
                      className={
                        indicatorType === 'Compute'
                          ? 'pointerEvents'
                          : 'pointerEventsDefault'
                      }
                    >
                      <img className="indicatorImg" src={compute} />
                    </div>

                    <div
                      className="center tractorHealthTxt overline1"
                      data-testid="batteryAndChargerText-Diagnostics"
                    >
                      {getCrossCheck(indicators['Compute'])}
                      {t(translate.baseStationDiagnostics.compute)}
                    </div>
                  </div>
                  <div
                    className="pointer"
                    onClick={() => handleIndicatorTypes('Software')}
                  >
                    <div
                      className={
                        indicatorType === 'Software'
                          ? 'pointerEvents'
                          : 'pointerEventsDefault'
                      }
                    >
                      <img className="indicatorImg" src={software} />
                    </div>

                    <div
                      className="center tractorHealthTxt overline1"
                      data-testid="hitchAndImplementText-Diagnostics"
                    >
                      {getCrossCheck(indicators['Software'])}
                      {t(translate.baseStationDiagnostics.software)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="common_wrapper diag_wrapper mt16">
              <div className="filters_card">
                <div className="displayFlex">
                  <Space size="large">
                    <Input
                      addonBefore={<SearchOutlined />}
                      placeholder="Search Alerts"
                      className="common_search"
                      data-testid="searchInput-Diagnostics"
                      onChange={(e) => handleSearch(e)}
                      // onKeyDown={(e) => handleKey(e)}
                      autoComplete="off"
                    />
                  </Space>
                  <CustomDate
                    label="Start Date - End Date"
                    testId="datePicker-Diagnostics"
                    cssClass="min_width"
                    value={dateFilter}
                    onChange={(dates: any) => {
                      setDateFilter(dates);
                    }}
                  />
                  <CustomSeverityLevel
                    label="Severity Level"
                    testId="severityDropdownSelect-Diagnostics"
                    cssClass="min_width"
                    value={level}
                    onSelect={levelSelect}
                  />
                  <CustomSelect
                    label="Status"
                    cssClass="min_width"
                    value={status}
                    setValue={statusSelect}
                    options={resolveFilterData1}
                    optionKey="value"
                    optionDisplay="name"
                    testId="statusDropdownField-Tickets"
                  ></CustomSelect>
                  <CustomSelect1
                    label="Indicator"
                    testId="indicatorDropdownSelect-Diagnostics"
                    cssClass="min_width"
                    value={indicatorType}
                    setValue={(selected: any) =>
                      selectIndicatorType(selected ? selected : '')
                    }
                    options={DiagnosticsindicatorList.map((item) =>
                      Object.assign({
                        id: item.indicator_name,
                        name: item.indicator_name,
                      }),
                    )}
                    // optionKey="id"
                    optionDisplay="name"
                    isAll={true}
                  />

                  <CustomSelect1
                    label="Error Codes"
                    cssClass="min_width"
                    value={errocode}
                    setValue={(selected: any) =>
                      errorCodesSelect(selected ? selected : '')
                    }
                    options={errorCodes.map((item: any) =>
                      Object.assign({
                        id: item.label,
                        name: item.label,
                      }),
                    )}
                    isAll={true}
                  />

                  <CustomSelect1
                    label="SubSystem"
                    testId="subSystemDropdownSelect-Diagnostics"
                    cssClass="min_width"
                    value={subSystem}
                    setValue={(selected: any) =>
                      subSystemSelect(selected ? selected : '')
                    }
                    options={subSystems.map((item: any) =>
                      Object.assign({
                        id: item.label,
                        name: item.label,
                      }),
                    )}
                    isAll={true}
                  />
                </div>
              </div>
              <div className="common_table">
                <InfiniteScrollTable
                  columns={columns}
                  hasMore={hasMore}
                  loading={loading}
                  filterData={filterData}
                  totalcount={totalCount}
                  handleLoadMore={handleLoadMore}
                  filename="Alerts"
                  onChange={handleChange}
                />
              </div>
            </div>
          </Row>
        </div>
      </Content>
    </Layout>
  );
};

export default BasestationDiagnostics;
