import { Button, Result } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
import RoutesConstants from '../../routes/RoutesConstant';
import { useTranslation } from 'react-i18next';
import translate from '../../locale/en_translate.json';

const SomethingWentWrong: React.FC = () => {
  const { push } = useHistory();
  const { t } = useTranslation();

  const pushLink = () => {
    push(RoutesConstants.Login);
  };
  const translateData = () => {
    return t(translate.buttons.gotoLogin);
  };
  return (
    <Result
      status="404"
      title="Something went wrong"
      subTitle={
        <Button
          type="primary"
          onClick={() => {
            localStorage.clear();
            pushLink();
            window.location.reload();
          }}
        >
          {translateData()}
        </Button>
      }
    />
  );
};

export default SomethingWentWrong;
