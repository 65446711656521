import { Layout, Row, Input, notification, Space } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { useState } from 'react';
import './style.css';
import OrgImplements from './OrgImplements';
import CertifiedImplements from './CertifiedImplements';
import { monarchCertifiedImplements } from './data';

const Implements: React.FC = () => {
  const [certifiedData, setCertifiedData] = useState<any>({});
  const [disabledRecords, setDisabledRecords] = useState<any[]>([]);

  const updateOrgImplements = (data: any) => {
    // eslint-disable-next-line no-console
    console.log('data', data);
    const check = monarchCertifiedImplements?.records?.filter(
      (item: any) => item.id !== data.id,
    );
    // eslint-disable-next-line no-console
    console.log('check', check);
    setDisabledRecords([...check]);
    setCertifiedData(data);
  };

  return (
    <Layout>
      <div className="mainContent">
        <Content>
          <div className="tblContainer viewportContainer">
            <div>
              <div className="tableTitle">
                <h4>Implement in my Organization</h4>
              </div>
              <OrgImplements certifiedData={certifiedData} />
            </div>
            <div className="table2">
              <div className="tableTitle">
                <h4>Monarch Certified Implements</h4>
              </div>
              <CertifiedImplements
                handleImports={updateOrgImplements}
                disabledRecords={disabledRecords}
              />
            </div>
          </div>
        </Content>
      </div>
    </Layout>
  );
};
export default Implements;
