import React from 'react';
import { Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import discard_logo from '../../assets/images/discard_logo.svg';
import translate from '../../locale/en_translate.json';

interface Props {
  showModal?: boolean;
  closeModel?: any;
  closeDiscard?: any;
}
export const DiscardChanges: React.FC<Props> = ({
  showModal,
  closeModel,
  closeDiscard,
}: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <Modal
        className="modalContainer profileUpdatePopup implementPopup"
        title=""
        visible={showModal}
        onCancel={closeModel}
        width={1000}
        closable={false}
        maskClosable={false}
        footer={[
          <>
            <Button
              data-testid="discardCancelButton-DiscardChanges"
              key="back"
              className="impCancelBtn Button2"
              onClick={() => closeModel()}
            >
              {t(translate.buttons.cancel)}
            </Button>
            <Button
              data-testid="discardChangesButton-DiscardChanges"
              key="submit"
              className="impDiscardBtn Button2"
              onClick={() => closeDiscard()}
            >
              {t(translate.buttons.discardChanges)}
            </Button>
          </>,
        ]}
      >
        <div className="modalBox flexnone">
          <div className="formColW">
            <div className="userIcon">
              <h4 className="headline3">
                <img src={discard_logo} alt="" />{' '}
                {t(translate.addImplement.unsaveChanges)}
              </h4>
              <p className="body2">
                {t(translate.addImplement.discardChangesMessage)}
              </p>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DiscardChanges;
