/* eslint-disable react/jsx-key */
import React, { useContext, useEffect, useState } from 'react';
import { notification, AutoComplete, Input } from 'antd';

import { ApplicationContext } from '../../context/AppContext';
import { getFrequentSearches } from './services/API';
import { useHelpContext } from './HelpCenterContext';
// import { HistoryOutlined } from '@ant-design/icons';
import histroy_outline_icon from '../HelpCenter/images/histroy_outline_icon.svg';
import { resultRoute } from './lib/constants';
import { useHistory } from 'react-router-dom';
import { track } from '../../util/logger';

interface Props {
  flag: boolean;
}

const HealthcenterSearch: React.FC<Props> = ({ flag }) => {
  const { push } = useHistory();
  const { userDetails } = useContext(ApplicationContext);
  const { HCReducer } = useHelpContext();
  const [recentArtList, setRecentArtList] = useState<any[]>([]);
  const [state, dispatch] = HCReducer;
  const { searchKeyword } = state;

  useEffect(() => {
    return () => {
      setRecentArtList([]);
    };
  }, []);

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      getrecetArticleList();
    }
  }, [userDetails]);

  const getrecetArticleList = async () => {
    try {
      const { organization } = userDetails;
      const result: any = await getFrequentSearches(organization?.api_url);
      if (result) setRecentArtList(result.data);
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    }
  };
  const onSelect = (e: any) => {
    track('Help Center Search Word', {
      event: `${e}`,
    });
    dispatch({
      type: 'SEARCH_KEYWORD',
      payload: e,
    });
    push(resultRoute);
  };

  const renderItem = (title: string) => ({
    value: title,
    label: (
      <div
        style={{
          display: 'flex',
          gap: '8px',
          fontFamily: 'Montserrat-Regular',
          color: '#333333',
          alignItems: 'center',
          justifyContent: 'space-between',
          minHeight: '33px',
          padding: '0px 5px',
          fontSize: '12px',
        }}
      >
        <img src={histroy_outline_icon} />
        {title}
      </div>
    ),
  });

  const options = recentArtList.map(({ title }: { title: string }) => {
    return renderItem(title);
  });

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    dispatch({
      type: 'SEARCH_KEYWORD',
      payload: value,
    });
  };

  const handleSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { key } = e;
    if (key === 'Enter') push(resultRoute);
  };

  return (
    <>
      {' '}
      <AutoComplete
        onSelect={onSelect}
        dropdownMatchSelectWidth={flag ? 500 : 0}
        style={{ width: flag ? '40vw' : '' }}
        options={searchKeyword ? [] : options}
        value={searchKeyword}
      >
        <Input.Search
          size="large"
          placeholder="Search by topic, product, and more"
          onChange={onInputChange}
          onKeyUp={handleSearch}
          value={searchKeyword}
        />
      </AutoComplete>
    </>
  );
};

export default HealthcenterSearch;
