import React from 'react';
import { useTranslation } from 'react-i18next';
import translate from '../../../locale/en_translate.json';

interface Props {
  allDayOperation: any;
}

const AnalyticStats: React.FC<Props> = ({ allDayOperation }) => {
  const { t } = useTranslation();
  return (
    <div className="sprayoperhrs">
      <div className="operationalHours mb5">
        <div className="totalHours">
          {t(translate.analyticContainer.coveredAcres)}
        </div>
        <div className="hrMinTxt">
          {allDayOperation?.analytics?.area_covered_in_acre
            ? `${Number(allDayOperation?.analytics?.area_covered_in_acre)
                .toFixed(2)
                .toLocaleString()} Acres`
            : '-'}
        </div>
      </div>

      <div className="operationalHours mb5">
        <div className="totalHours">
          {' '}
          {t(translate.analyticContainer.coveredVineRows)}
        </div>
        <div className="hrMinTxt">
          {allDayOperation?.analytics?.num_of_vine_rows_covered
            ? `${Number(
                allDayOperation?.analytics?.num_of_vine_rows_covered,
              ).toFixed(0)}`
            : '-'}
        </div>
      </div>

      <div className="operationalHours mb5">
        <div className="totalHours">
          {' '}
          {t(translate.analyticContainer.AcresPerHour)}
        </div>
        <div className="hrMinTxt">
          {allDayOperation?.analytics?.acres_per_hours
            ? `${Number(allDayOperation?.analytics?.acres_per_hours).toFixed(
                2,
              )}`
            : '-'}
        </div>
      </div>

      <div className="operationalHours mb5">
        <div className="totalHours">
          {' '}
          {t(translate.analyticContainer.coverage)}
        </div>
        <div className="hrMinTxt">
          {allDayOperation?.analytics?.distance
            ? `${Number(allDayOperation?.analytics?.distance)
                .toFixed(2)
                .toLocaleString()} Miles`
            : '-'}
        </div>
      </div>
      <div className="operationalHours mb5">
        <div className="totalHours">
          {' '}
          {t(translate.analyticContainer.avgSpeed)}
        </div>
        <div className="hrMinTxt">
          {allDayOperation?.analytics?.avg_speed_in_miles
            ? `${Number(
                Number(allDayOperation?.analytics?.avg_speed_in_miles),
              ).toFixed(1)} Mph`
            : '-'}
        </div>
      </div>

      <div className="line" />

      <div className="operationalHours mb5">
        <div className="totalHours">
          {' '}
          {t(translate.analyticContainer.operationalSavings)}
        </div>
        <div className="hrMinTxt">
          {allDayOperation?.analytics?.operational_savings
            ? `$${Number(
                allDayOperation?.analytics?.operational_savings,
              ).toFixed(2)}`
            : '-'}
        </div>
      </div>

      <div className="operationalHours mb5">
        <div className="totalHours">
          CO<sub>2</sub> {t(translate.analyticContainer.savings)}
        </div>
        <div className="hrMinTxt">
          {allDayOperation?.analytics?.aggregate_emission_reduction
            ? `${Number(
                allDayOperation?.analytics?.aggregate_emission_reduction,
              ).toFixed(2)} kg`
            : '-'}
        </div>
      </div>

      <div className="operationalHours mb5">
        <div className="totalHours">
          {t(translate.analyticContainer.energyUsed)}
        </div>
        <div className="hrMinTxt">
          {allDayOperation?.analytics?.soc_used
            ? `${allDayOperation?.analytics?.soc_used}%`
            : '-'}{' '}
          {t(translate.analyticContainer.soc)}
          {/* <br /> */} (
          <span>
            {allDayOperation?.analytics?.energy
              ? `${Number(allDayOperation?.analytics?.energy)
                  .toFixed(2)
                  .toLocaleString()} kwh`
              : '-'}
          </span>
          )
        </div>
      </div>
      <div className="analyticsHover">
        <div className="line" />

        <div className="operationalHours mb5">
          <div className="totalHours">
            {t(translate.analyticContainer.electricityRate)}
          </div>
          <div className="hrMinTxt">
            {allDayOperation?.analytics?.electricity_rate
              ? `$${Number(
                  allDayOperation?.analytics?.electricity_rate,
                ).toFixed(2)}`
              : '-'}{' '}
            / {t(translate.analyticContainer.kwh)}
          </div>
        </div>
        <div className="operationalHours mb5">
          <div className="totalHours">
            {t(translate.analyticContainer.dieselRate)}
          </div>
          <div className="hrMinTxt">
            {allDayOperation?.analytics?.electricity_rate
              ? `$${Number(allDayOperation?.analytics?.diesel_cost).toFixed(2)}`
              : '-'}{' '}
            / {t(translate.analyticContainer.gallon)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnalyticStats;
