/* eslint-disable no-console */
import { PauseOutlined } from '@ant-design/icons';
import React, { useContext, useEffect, useRef, useState } from 'react';
import fleetlist_pause from '../../assets/images/fleetlist_pause.svg';
import loading_logo from '../../assets/images/loading_logo.gif';
import { ATOM, PAUSE, RESUME } from '../../constants/constant';
import { TractorHeartBeat } from '../../constants/types';
import { AutoDriveApplicationContext } from '../../context/AutoDriveContext';
import { SET_RESET_TIMER, SET_TOGGLE_TIMER } from './actions';

const GlobalPauseResume: React.FC = () => {
  const [showDeadMan, setShowDeadMan] = useState(false);
  const init = useRef<boolean>(false);
  const { AutoDriveReducer, handlePauseResumeStopLiveCom } = useContext(
    AutoDriveApplicationContext,
  );
  const [autoDriveState, autoDriveDispatch] = AutoDriveReducer;
  const {
    drivingTractors,
    toggleTimer,
    resetTimer,
    timeLeft,
    pauseResumeLoader,
  } = autoDriveState;
  const { status: toggle } = toggleTimer;
  const handlePauseResumeClick = () => {
    if (showDeadMan && init.current) {
      handlePauseResumeStopLiveCom(!toggle ? PAUSE : RESUME, true);
      autoDriveDispatch({ type: SET_RESET_TIMER, payload: toggle });
    }
  };

  useEffect(() => {
    setShowDeadMan(false);
    let statusCheck = false;
    drivingTractors.map((drivingTractor: TractorHeartBeat) => {
      if (
        drivingTractor?.drive_action_details?.drive_action_created_at === ATOM
      ) {
        setShowDeadMan(true);
        statusCheck = true;
      }
    });
    if (statusCheck) init.current = true;
    handlePausedOperations();
  }, [drivingTractors]);
  const handlePausedOperations = () => {
    let containPaused = false;
    let containResumed = false;
    drivingTractors.map((drivingTractor: TractorHeartBeat) => {
      if (
        drivingTractor?.drive_action_details?.drive_action_created_at ===
          ATOM &&
        drivingTractor?.planning_manager?.planner_status === 'paused'
      ) {
        containPaused = true;
      }
      if (
        drivingTractor?.drive_action_details?.drive_action_created_at ===
          ATOM &&
        drivingTractor?.planning_manager?.planner_status !== 'paused'
      ) {
        containResumed = true;
      }
    });
    if (!containPaused && containResumed) {
      autoDriveDispatch({
        type: SET_TOGGLE_TIMER,
        payload: {
          status: false,
          from: !resetTimer && timeLeft == 0 ? 'Event' : 'Click',
        },
      });
    } else if (containPaused && !containResumed) {
      autoDriveDispatch({
        type: SET_TOGGLE_TIMER,
        payload: {
          status: true,
          from: !resetTimer && timeLeft == 0 ? 'Event' : 'Click',
        },
      });
    } else {
      autoDriveDispatch({
        type: SET_TOGGLE_TIMER,
        payload: {
          status: false,
          from: !resetTimer && timeLeft == 0 ? 'Event' : 'Click',
        },
      });
    }
  };

  return (
    <div
      className={`autodrivesTxt ${toggle ? 'cursorDefault' : ''}`}
      onClick={() => !toggle && handlePauseResumeClick()}
    >
      {!pauseResumeLoader && !toggle && (
        <div className="autodrivesPlayPausecircle">
          {/* <img src={fleetlist_pause} alt="" /> */}
          <PauseOutlined />
        </div>
      )}
      {pauseResumeLoader && (
        <div className=" loader">
          <img style={{ width: '20px', height: '20px' }} src={loading_logo} />
        </div>
      )}
      <p>{!toggle ? 'Pause all' : 'All Tractors paused'} </p>
    </div>
  );
};

export default GlobalPauseResume;
