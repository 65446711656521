import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import React, { useContext, useEffect, useState } from 'react';
import humanDR from '../../assets/images/humanDR.svg';
import pathPR from '../../assets/images/pathPR.svg';
import ptoPR from '../../assets/images/ptoPR.svg';
import { ApplicationContext } from '../../context/AppContext';
import { AutoDriveApplicationContext } from '../../context/AutoDriveContext';
import { RemoteDriveAppCtx } from './RemoteDriveContext';
interface Props {
  handleIgnore: () => void;
}

export const GuardrailWidget: React.FC<Props> = ({ handleIgnore }) => {
  const [title, setTitle] = useState('');
  const [image, setImage] = useState('');
  const { RDReducer } = useContext(RemoteDriveAppCtx);
  const { userDetails } = useContext(ApplicationContext);
  const { AutoDriveReducer } = useContext(AutoDriveApplicationContext);
  const [autoDriveState] = AutoDriveReducer;
  const { remoteAvTractors } = autoDriveState;
  const [state] = RDReducer;
  const { triggeredGuardrail, selectedTractor } = state;
  const [toggle, setToggle] = useState<boolean>(true);
  const [isCurrentUser, setIsCurrentUser] = useState<boolean>(false);

  useEffect(() => {
    if (triggeredGuardrail === 'path protection') {
      setImage(pathPR);
      setTitle('Obstructed Path');
    } else if (triggeredGuardrail === 'pto protection') {
      setImage(ptoPR);
      setTitle('PTO Danger');
    } else if (triggeredGuardrail === 'human protection') {
      setImage(humanDR);
      setTitle('Human Presence');
    }
  }, [triggeredGuardrail]);

  useEffect(() => {
    if (remoteAvTractors && remoteAvTractors.length && selectedTractor) {
      const [tractor] = remoteAvTractors.filter(
        (item: any) => item.tractor_id == selectedTractor.id,
      );
      if (tractor?.drive_action_details && userDetails?.organization) {
        const { current_operator_id } = tractor.drive_action_details;
        setIsCurrentUser(Boolean(current_operator_id == userDetails.id));
      }
    }
  }, [remoteAvTractors, userDetails]);

  if (!isCurrentUser) return <></>;

  return (
    <div className={`pathPopup ${toggle ? '' : 'minimise'}`}>
      {toggle ? (
        <div className="pathPopup-minus" onClick={() => setToggle(!toggle)}>
          <MinusOutlined />
        </div>
      ) : (
        <div className="pathPopup-plus" onClick={() => setToggle(!toggle)}>
          <PlusOutlined />
        </div>
      )}
      <div style={{ display: 'flex' }}>
        <div className="pathPopup-img">
          <img src={image} alt="" style={{ height: '45px', width: '30px' }} />
        </div>
        <div className="path-inr-blk">
          <h4>{title}</h4>
          <p>
            Tractor has come to a stop because of an obstruction in its path.
            Operation will resume when resolved
          </p>
        </div>
      </div>

      <div className="continuebtn" onClick={handleIgnore}>
        ignore & continue
      </div>
    </div>
  );
};
