import React, { useEffect, useState } from 'react';
import deleteIcon from '../../../assets/images/del_icon.png';
import { DeleteOutlined } from '@ant-design/icons';

interface Props {
  recording?: boolean;
  callback: (data: any) => any;
}

const AudioRecorder: React.FC<Props> = ({ recording = false, callback }) => {
  const [audioURL, setAudioURL] = useState<string>('');
  const [recorder, setRecorder] = useState<any>(null);

  useEffect(() => {
    // Lazily obtain recorder first time we're recording.
    if (recorder === null) {
      if (recording) {
        // eslint-disable-next-line no-console
        requestRecorder().then(setRecorder, console.error);
      }
      return;
    }

    // Manage recorder state.
    if (recording && recorder.state === 'inactive') {
      setAudioURL('');
      recorder.start();
    } else {
      recorder.state !== 'inactive' && recorder.stop();
    }
    // Obtain the audio when ready.
    const handleData = (e: BlobEvent) => {
      setAudioURL(URL.createObjectURL(e.data));
      callback(e.data);
    };

    recorder.addEventListener('dataavailable', handleData);
    return () => recorder.removeEventListener('dataavailable', handleData);
  }, [recorder, recording]);

  const removeAudio = () => {
    setAudioURL('');
    callback('');
  };

  return (
    <div className="App recorderBlock">
      {audioURL.trim() !== '' && (
        <>
          <audio src={audioURL} controls />
          <span>
            <DeleteOutlined onClick={() => removeAudio()} />
          </span>
        </>
      )}

      {recording && (
        <p>
          <em style={{ color: 'red', fontSize: '18px' }}>
            Audio recording in progress...
          </em>
        </p>
      )}
    </div>
  );
};

async function requestRecorder() {
  const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
  return new MediaRecorder(stream);
}

export default AudioRecorder;
