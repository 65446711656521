import React from 'react';
import { Progress, Modal } from 'antd';
import './styles.css';

// const twoColors = { '0%': '#108ee9', '100%': '#87d068' };
const conicColors = { '0%': '#87d068', '50%': '#ffe58f', '100%': '#ffccc7' };

const messages: any = {
  success: 'Downloaded Successfully',
  start: 'Please wait downloading CSV',
  failed: 'Download Failed!',
};

interface Props {
  percent: number;
  loading: boolean;
  // eslint-disable-next-line no-unused-vars
  setLoading: (state: boolean) => void;
  status: string;
}

const Download: React.FC<Props> = ({
  percent,
  loading,
  setLoading,
  status,
}) => {
  // const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCancel = () => {
    setLoading(false);
  };

  return (
    <Modal
      visible={loading}
      onCancel={handleCancel}
      footer={false}
      width={360}
      className="downloadPop"
    >
      <div className="downloadContainer">
        {status === 'exception' ? (
          <Progress
            className="downloadCircle"
            type="circle"
            percent={percent}
            status="exception"
          />
        ) : (
          <Progress
            className="downloadCircle"
            type="circle"
            percent={percent}
            strokeColor={conicColors}
          />
        )}
        <p>{status && messages[status]}</p>
      </div>
    </Modal>
  );
};

export default Download;
