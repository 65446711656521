/* eslint-disable no-console */
import React, { memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import autodrive_stop_circle from '../../assets/images/autodrive_stop_circle.svg';
import { getImplementName, isVineYardOrDairy } from '../../constants/Common';
import {
  CONTINUE_LATER,
  USER_INITIATED,
  tractorEndPopDefault,
  vineYardAutoDrive,
} from '../../constants/constant';
import { MosEventsResp, UserDetails } from '../../constants/types';
import translate from '../../locale/en_translate.json';
import { RemoteDriveAppCtx } from './RemoteDriveContext';
import { ApplicationContext } from '../../context/AppContext';

interface Props {
  setEndPopIndex: (endPop: number) => void;
  endOperation?: (aDIM: MosEventsResp) => void;
  endADIM?: any;
  stopAndDisconnectHandler: () => void;
  endPopIndex: number;
  index: number;
  From: 'INTERRUPT' | 'DETAILS';
}
const AutoDriveEndPopup: React.FC<Props> = ({
  setEndPopIndex,
  endOperation,
  endADIM,
  stopAndDisconnectHandler,
  endPopIndex,
  index,
  From,
}: Props) => {
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const { startAutoDriveIteration, RDReducer } = useContext(RemoteDriveAppCtx);
  const [state] = RDReducer;
  const { selectedTractor } = state;
  const continueLaterOperation = () => {
    startAutoDriveIteration(selectedTractor.id, {
      action: CONTINUE_LATER,
      details: '',
      category: USER_INITIATED,
      type: '',
    });
    setTimeout(() => {
      stopAndDisconnectHandler();
      setEndPopIndex(tractorEndPopDefault);
    }, 100);
  };
  return (
    <>
      {index === endPopIndex && (
        <>
          <div className="newEndingOperation mb20">
            <div className="">
              <div
                className="newadtClose"
                onClick={() => setEndPopIndex(tractorEndPopDefault)}
                data-testid=""
              ></div>
              <h2 className="headline3 fSbd mb16 textCenter">
                {t(translate.AutoDrive.AutoDriveInterrupt.EndOperation)}
              </h2>
              <div className="minH130">
                <p className="body2">
                  {t(translate.AutoDrive.AutoDriveInterrupt.EndOperationDesc1)}
                </p>
                {isVineYardOrDairy(userDetails as UserDetails) ===
                  vineYardAutoDrive && (
                  <p className="body2">
                    {t(
                      translate.AutoDrive.AutoDriveInterrupt.EndOperationDesc2,
                    )}{' '}
                    <b> {getImplementName(selectedTractor)} </b> .
                  </p>
                )}
              </div>
            </div>
            <div className="pathPRowBtn">
              <button
                type="button"
                className="pOpBtn Button2 textBtnColor"
                onClick={() => {
                  if (From === 'DETAILS') {
                    stopAndDisconnectHandler();
                  }
                  if (From === 'INTERRUPT') {
                    setEndPopIndex(tractorEndPopDefault);
                    endOperation && endOperation(endADIM);
                  }
                }}
              >
                {isVineYardOrDairy(userDetails as UserDetails) ===
                vineYardAutoDrive
                  ? t(translate.AutoDrive.Actions.EndWithoutSaving)
                  : t(translate.AutoDrive.Actions.EndOperation)}
              </button>
              {isVineYardOrDairy(userDetails as UserDetails) ===
                vineYardAutoDrive && (
                <button
                  type="button"
                  className="pOpBtn Button2 saveProgressBtn"
                  onClick={() => {
                    continueLaterOperation();
                  }}
                >
                  <img src={autodrive_stop_circle} className="mr6" />
                  {t(translate.AutoDrive.Actions.SaveProgress)}
                </button>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default memo(AutoDriveEndPopup);
