import React, { useContext, useState, useEffect } from 'react';
import { Modal, Button, notification, Avatar } from 'antd';
import ProfileIcon from '../../assets/images/defaultProfileIcon.svg';
import { ApplicationContext } from '../../context/AppContext';
import './style.css';
import { addMapmakerImage, getProfileData } from '../../constants/Api';
import { useTranslation } from 'react-i18next';
import translate from '../../locale/en_translate.json';
import { OrganizationProfileDetails } from '../../constants/types';
import AppLoader from '../common/AppLoader';
import ImageCrop from '../widget/ImageCrop';

interface Props {
  showModal: boolean;
  handleCancel: () => void;
}

export const ImageUpload: React.FC<Props> = ({
  showModal,
  handleCancel,
}: Props) => {
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const [loader, setLoader] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [organizationProfile, setOrganizationProfile] =
    useState<OrganizationProfileDetails>();
  const [fileUrl, setFileUrl] = useState<any>('');
  const [fileUrlData, setfileUrlData] = useState<any>('');

  const getProfile = async () => {
    try {
      setLoader(true);
      const data: OrganizationProfileDetails = await getProfileData(
        userDetails.organization.api_url,
        userDetails.organization.id,
      );
      setFileUrl(data?.farm_layout ? data?.farm_layout : '');
      setOrganizationProfile(data);
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getProfile();
  }, [userDetails]);

  useEffect(() => {
    if (fileUrlData) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [fileUrlData]);

  const handleSave = async () => {
    await updateImage();
    handleCancel();
  };

  const updateImage = async () => {
    try {
      if (fileUrlData) {
        const payload = new FormData();
        payload.append('image', fileUrlData);
        setLoader(true);
        await addMapmakerImage(
          userDetails.organization.api_url,
          userDetails.organization.id,
          payload,
        );
      }
      notification.success({
        message: 'Image Uploaded successfully',
      });
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    } finally {
      setLoader(false);
    }
  };

  return (
    <Modal
      className="commonPopup addPolygon imgUploadStyle popupStyles"
      title="Upload Farm Profile"
      centered
      width={'50vw'}
      confirmLoading={true}
      visible={showModal}
      onCancel={handleCancel}
      closable={false}
      footer={[
        <Button
          key="back"
          onClick={handleCancel}
          className="btnCancel Button"
          data-testid="cancelButtonUploadimg-ImageUpload"
        >
          {t(translate.buttons.cancel)}
        </Button>,
        <Button
          key="submit"
          disabled={isDisabled}
          onClick={() => handleSave()}
          className="btnSave Button"
          data-testid="saveButtonuploadImg-ImageUpload"
        >
          {t(translate.buttons.save)}
        </Button>,
      ]}
    >
      <div className="uploadImg proRow mr0 mb24">
        <div className="userIcon personeeltab imageTooltip">
          <div className="proRow proPic">
            {fileUrl !== '' ? (
              <Avatar src={fileUrl} size={200}></Avatar>
            ) : (
              <Avatar
                src={ProfileIcon}
                size={200}
                className="defaulticon"
              ></Avatar>
            )}
          </div>
          <div className="modalCol">
            <div className="imgCrop">
              <ImageCrop
                setFileUrl={setFileUrl}
                setfileUrlData={setfileUrlData}
                uploadAnImage={true}
              ></ImageCrop>
            </div>
          </div>
        </div>
      </div>
      <AppLoader loader={loader} />
    </Modal>
  );
};

export default ImageUpload;
