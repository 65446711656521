import { Input } from 'antd';
import classNames from 'classnames';
import React, { useState } from 'react';

interface Props {
  label?: any;
  value?: any;
  setValue: (data: any) => void;
  cssClass?: any;
  required?: any;
  showLabel?: any;
  validations?: any;
  type?: any;
  testId?: any;
  maxLength?: number;
  onBlur?: () => void;
  disabled?: any;
  numberType?: any;
  min?: number;
  max?: number;
}

const CustomInput: React.FC<Props> = ({
  label,
  value,
  setValue,
  cssClass,
  required,
  showLabel,
  validations,
  type,
  testId,
  maxLength = 50,
  onBlur,
  disabled,
  numberType,
  min,
  max,
}: Props) => {
  const [focus, setFocus] = useState(false);

  const isEmpty = (value: any) => {
    if (typeof value === 'undefined') return true;
    if (typeof value === 'string' && value.trim() === '') return true;
    if (typeof value === 'number' && isNaN(value)) return true;
    return false;
  };
  const labelClass = focus || !isEmpty(value) ? 'label label-float' : 'label';

  const onChangeValue = (e: any) => {
    if (type === 'Per') {
      const str = e?.target?.value;
      const regx = /^[+-]?\d+(\.\d+)?$/;
      if (
        0 <= Number(str) &&
        Number(str) <= 100 &&
        (Number(str) === 0 || regx.test(str))
      ) {
        setValue(str.trim() === '' ? '' : str);
      } else if (str?.length == 1 && str?.trim() === '') {
        setValue('');
      } else {
        const j = value ? value : '';
        setValue(j);
      }
    } else if (type === 'Number' && label === 'Weight') {
      const str = e?.target?.value;
      const regx = /^[+-]?\d+(\.\d+)?$/;
      if (
        0 <= Number(str) &&
        Number(str) <= 9999 &&
        (Number(str) === 0 || regx.test(str))
      ) {
        setValue(str.trim() === '' ? '' : str);
      } else if (str?.length == 1 && str?.trim() === '') {
        setValue('');
      } else {
        const j = value ? value : '';
        setValue(j);
      }
    } else if (type === 'Number') {
      const str = e?.target?.value;
      const regx = /^[+-]?\d+(\.\d+)?$/;
      if (
        0 <= Number(str) &&
        Number(str) <= 1000 &&
        (Number(str) === 0 || regx.test(str))
      ) {
        setValue(str.trim() === '' ? '' : str);
      } else if (str?.length == 1 && str?.trim() === '') {
        setValue('');
      } else {
        const j = value ? value : '';
        setValue(j);
      }
    } else if (type === 'Range' && min && max) {
      // min,max,type === 'Range'
      const str = e?.target?.value;
      if (str.includes('-')) {
        const num = str.replace('-', '');
        if (num > 0 && num <= max) {
          setValue(Number(str));
        } else {
          setValue('-');
        }
      } else if (str === 0) {
        setValue(0);
      } else if (Number(str) <= max) {
        setValue(Number(str));
      } else {
        setValue(null);
      }
    } else if (type === 'AlphaNum') {
      const str = e?.target?.value;
      const wal = str.replace(/[^a-zA-Z0-9_  -]/g, '');
      setValue(wal ? wal : '');
    } else {
      setValue(e?.target?.value);
    }
  };

  return (
    <div
      className="float-label"
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      <Input
        maxLength={maxLength}
        value={value}
        onChange={(e) => onChangeValue(e)}
        data-testId={testId}
        className={
          validations === false && required
            ? classNames({
                errorClass: validations === false && required,
              })
            : cssClass
        }
        onBlur={() => onBlur && onBlur()}
        disabled={disabled}
        type={numberType}
      />

      <label className={labelClass}>
        {label} {required && <span className="float-span">*</span>}
      </label>
      {showLabel && <span className="dimPos">{showLabel}</span>}
    </div>
  );
};

export default CustomInput;
