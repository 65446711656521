/* eslint-disable react/jsx-key */
import React, { useContext, useEffect, useState } from 'react';
import { Modal, Button, notification, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { ApplicationContext } from '../../../../context/AppContext';
import translate from '../../../../locale/en_translate.json';
import { getDateTimes, getTime } from '../../../../constants/Common';
import { getWorkOrderDetails } from '../../API';
// import { getDateTimes, getTime } from '../../constants/Common';
// import RoutesConstants from '../../routes/RoutesConstant';

interface Props {
  showModal: boolean;
  // eslint-disable-next-line no-unused-vars
  close: (state?: boolean) => void;
  workOrderId: number;
}

const WorkOrderDetailsModal: React.FC<Props> = ({
  showModal,
  close,
  workOrderId,
}: Props) => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const { userDetails } = useContext(ApplicationContext);
  const [loader, setLoader] = useState<boolean>(false);
  const [orderDetails, setOrderDetails] = useState<any>();

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      orderList();
    }
  }, [userDetails]);

  const orderList = async () => {
    try {
      setLoader(true);
      const data = await getWorkOrderDetails(workOrderId);
      setOrderDetails(data);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    } finally {
      setLoader(false);
    }
  };

  const linkSupport = (rec: any) => {
    // push({
    //   pathname: RoutesConstants.ServiceTicket,
    //   state: rec,
    // });
  };
  return (
    <Modal
      className="tm-modal customModal"
      title={t(translate.modal.workOrderDetails)}
      centered
      visible={showModal}
      onCancel={() => close()}
      footer={[
        <Button onClick={() => close()} className="btnCancel">
          {t(translate.buttons.cancel)}
        </Button>,
      ]}
    >
      <div className="col2">
        <div className="col2-styles" style={{ margin: 0 }}>
          <div className="orgDetails wd240">
            <p>Organization Name:</p>
          </div>
          <div className="orgData">
            <p>
              {orderDetails?.organization
                ? orderDetails?.organization?.name
                : '-'}
            </p>
          </div>
        </div>

        <div className="col2-styles" style={{ margin: 0 }}>
          <div className="orgDetails wd240">
            <p>Organization Address:</p>
          </div>
          <div className="orgData">
            <p>{orderDetails?.organization?.address || '-'}</p>
          </div>
        </div>

        <div className="col2-styles" style={{ margin: 0 }}>
          <div className="orgDetails wd240">
            <p>Work Order Number:</p>
          </div>
          <div className="orgData">
            <p>{orderDetails?.work_order_number || '-'}</p>
          </div>
        </div>

        <div className="col2-styles" style={{ margin: 0 }}>
          <div className="orgDetails wd240">
            <p>Date / Time:</p>
          </div>
          <div className="orgData">
            <p>
              {orderDetails?.created_date_time
                ? getDateTimes(orderDetails.created_date_time)
                : '-'}
            </p>
          </div>
        </div>

        <div className="col2-styles" style={{ margin: 0 }}>
          <div className="orgDetails wd240">
            <p>Equipment Pin:</p>
          </div>
          <div className="orgData">
            <p>{orderDetails?.tractor_pin || '-'}</p>
          </div>
        </div>

        <div className="col2-styles" style={{ margin: 0 }}>
          <div className="orgDetails wd240">
            <p>Equipment Model:</p>
          </div>
          <div className="orgData">
            <p>{orderDetails?.equipment_model}</p>
          </div>
        </div>

        <div className="col2-styles" style={{ margin: 0 }}>
          <div className="orgDetails wd240">
            <p>Equipment Hours Meter:</p>
          </div>
          <div className="orgData">
            <p>
              {orderDetails?.tractor_hours
                ? getTime(orderDetails?.tractor_hours)
                : '-'}
            </p>
          </div>
        </div>

        <div className="col2-styles" style={{ margin: 0 }}>
          <div className="orgDetails wd240">
            <p>Issue:</p>
          </div>
          <div className="orgData">
            <p>{orderDetails?.tractor_issue_description}</p>
          </div>
        </div>

        <div className="col2-styles" style={{ margin: 0 }}>
          <div className="orgDetails wd240">
            <p>QC Checkout:</p>
          </div>
          <div className="orgData">
            <p>
              {orderDetails?.qc_user_id && (
                <>
                  {orderDetails?.qc_user_id?.first_name}{' '}
                  {orderDetails?.qc_user_id?.last_name}
                </>
              )}
            </p>
          </div>
        </div>

        <div className="col2-styles" style={{ margin: 0 }}>
          <div className="orgDetails wd240">
            <p>Ticket Id:</p>
          </div>
          <div className="orgData">
            <a href="#" onClick={() => linkSupport(orderDetails)}>
              {orderDetails?.ticket_id}
            </a>
          </div>
        </div>
      </div>
      <div className="edit-loader">
        {loader && (
          <div className="loader1">
            <div className="loader1">
              <Spin size="large" />
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};
export default WorkOrderDetailsModal;
