/* eslint-disable no-console */
import { Input, InputNumber, Modal, notification, Select, Spin } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import {
  // saveAbLineData,
  saveAbLineData1,
} from '../../constants/Api';
import {
  AbLinePayloadSingle,
  // AbLinePayloadTypesSingle,
  AbLinePayloadTypesSingle1,
  PolygonDropDown,
} from '../../constants/types';
import { ApplicationContext } from '../../context/AppContext';
import translate from '../../locale/en_translate.json';

import { useTranslation } from 'react-i18next';

interface Props {
  showModal: boolean;
  handleCancel: (reload?: boolean) => void;
  abLinePayload: AbLinePayloadSingle;
  polygonsListForPop: PolygonDropDown[];
}
export const AbLine: React.FC<Props> = ({
  showModal,
  handleCancel,
  abLinePayload,
  polygonsListForPop,
}: Props) => {
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const [name, setName] = useState<string>('');
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [numberOfRows, setNumberOfRows] = useState<number>(2);
  const [selectedPolygonUuid, setSelectedPolygonUuid] = useState('');
  const [vineRowData, setVineRowData] = useState<any>({
    head_a_latitude: abLinePayload.abLineXY[0].latitude,
    head_a_longitude: abLinePayload.abLineXY[0].longitude,
    head_b_latitude:
      abLinePayload.abLineXY[abLinePayload.abLineXY.length - 1].latitude,
    head_b_longitude:
      abLinePayload.abLineXY[abLinePayload.abLineXY.length - 1].longitude,
    head_a_bearing: abLinePayload.head_a_bearing,
    head_b_bearing: abLinePayload.head_b_bearing,
  });
  const [loader, setLoader] = useState<boolean>(false);
  useEffect(() => {
    if (abLinePayload) {
      if (abLinePayload.name) {
        setName(abLinePayload.name);
      }
    }
  }, [abLinePayload]);
  const saveVineRows = async () => {
    setLoader(true);
    // const list =
    //   abLinePayload &&
    //   abLinePayload.abLineXY &&
    //   abLinePayload.abLineXY.map((abLine: any) => {});
    const lat_long_vertices = {
      head_a_latitude: abLinePayload.abLineXY[0].latitude,
      head_a_longitude: abLinePayload.abLineXY[0].longitude,
      head_b_latitude:
        abLinePayload.abLineXY[abLinePayload.abLineXY.length - 1].latitude,
      head_b_longitude:
        abLinePayload.abLineXY[abLinePayload.abLineXY.length - 1].longitude,
      head_a_bearing: abLinePayload.head_a_bearing,
      head_b_bearing: abLinePayload.head_b_bearing,
      ...vineRowData,
    };
    const payload: AbLinePayloadTypesSingle1 = {
      vine_block_name: name.trim(),
      head_a_latitude: lat_long_vertices.head_a_latitude,
      head_a_longitude: lat_long_vertices.head_a_longitude,
      head_b_latitude: lat_long_vertices.head_b_latitude,
      head_b_longitude: lat_long_vertices.head_b_longitude,
      polygon_uuid: selectedPolygonUuid,
      row_spacing: numberOfRows,
    };
    setName(name.trim());
    try {
      const { organization } = userDetails;
      if (abLinePayload.action === 'Add') {
        const response = await saveAbLineData1(
          organization.api_url,
          organization.farm.id,
          payload,
        );
        handleCancel(true);
        notification.success({
          message: response.msg,
          // message: 'success',
        });
      }
      // if (abLinePayload.action === 'Edit') {
      //   const response = await updateAbLineData(
      //     organization.api_url,
      //     organization.farm.id,
      //     abLinePayload.vineRowId,
      //     payload,
      //   );
      //   handleCancel(true);
      //   notification.success({
      //     message: response.msg,
      //   });
      // }
    } catch (error: any) {
      notification.error({
        message: error?.message,
      });
    } finally {
      setLoader(false);
    }
  };
  const setVineRowDataHandler = (value: number, type: string) => {
    const item = vineRowData;
    item[type] = value;
    setVineRowData(item);
  };

  const swapAAndB = () => {
    const {
      head_a_latitude,
      head_a_longitude,
      head_a_bearing,
      head_b_latitude,
      head_b_longitude,
      head_b_bearing,
    } = vineRowData;
    const payload = {
      head_a_latitude: head_b_latitude,
      head_a_longitude: head_b_longitude,
      head_a_bearing: head_b_bearing,
      head_b_latitude: head_a_latitude,
      head_b_longitude: head_a_longitude,
      head_b_bearing: head_a_bearing,
    };
    setVineRowData(payload);
  };

  useEffect(() => {
    if (
      userDetails &&
      userDetails.organization &&
      name &&
      name.trim().length > 0
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [userDetails, name]);

  return (
    <Modal
      className="commonPopup addPolygon"
      title={`${abLinePayload?.action} Ab Line`}
      centered
      closable={false}
      width={'60vw'}
      visible={showModal}
      onCancel={() => handleCancel()}
      okText="Save"
      onOk={saveVineRows}
      okButtonProps={{
        className: 'btnSave ',
        disabled: isDisabled,
      }}
      cancelButtonProps={{
        className: 'btnCancel',
      }}
    >
      <div className="formRow width-class">
        <div className="formCol">
          <label className="formLabel">
            Name <span style={{ color: 'red' }}> *</span>
          </label>
          <Input
            className="commInput"
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
        </div>
        <div className="formCol">
          <label className="formLabel">
            {t(translate.map.boundary)} <span style={{ color: 'red' }}> *</span>
          </label>
          <Select
            className="customSelect"
            onSelect={(value) => {
              setSelectedPolygonUuid(value);
            }}
            value={selectedPolygonUuid}
          >
            {polygonsListForPop.map((data: any) => {
              if (data.name !== '') {
                return (
                  <option value={data.polygon_uuid} key={data.polygon_uuid}>
                    {data.name} ({data.color})
                  </option>
                );
              }
            })}
          </Select>
        </div>
      </div>
      <div className="formRow">
        <div className="formCol ">
          <label className="formLabel">
            Row Spacing <span style={{ color: 'red' }}> *</span>
          </label>
          <InputNumber
            min={1}
            type="number"
            max={99999}
            className="commInput w100"
            value={numberOfRows}
            onChange={(value) => setNumberOfRows(value)}
          />
        </div>
        <div className="formCol ">
          <label className="formLabel"></label>
          <button
            className="btnSave swapBtn  swapBtnAddOn"
            onClick={() => swapAAndB()}
            style={{ float: 'right' }}
          >
            Swap Head A & B
          </button>
        </div>
      </div>

      <div className="formRow">
        <div className="formCol">
          <label className="formLabel lblHeads">Head A</label>
        </div>
        <div className="formCol">
          <label className="formLabel lblHeads">Head B</label>
        </div>
      </div>
      <div className="formRow">
        <div className="formCol">
          <label className="formLabel">
            Latitude <span style={{ color: 'red' }}> *</span>
          </label>
          <InputNumber
            className="commInput w100"
            onChange={(value) =>
              setVineRowDataHandler(value, 'head_a_latitude')
            }
            defaultValue={abLinePayload.abLineXY[0].latitude}
            value={vineRowData.head_a_latitude}
          />
        </div>
        <div className="formCol">
          <label className="formLabel">
            Latitude <span style={{ color: 'red' }}> *</span>
          </label>
          <InputNumber
            className="commInput w100"
            onChange={(value) =>
              setVineRowDataHandler(value, 'head_b_latitude')
            }
            defaultValue={
              abLinePayload.abLineXY[abLinePayload.abLineXY.length - 1].latitude
            }
            value={vineRowData.head_b_latitude}
          />
        </div>
      </div>
      <div className="formRow">
        <div className="formCol">
          <label className="formLabel">
            Longitude <span style={{ color: 'red' }}> *</span>
          </label>
          <InputNumber
            className="commInput w100"
            onChange={(value) =>
              setVineRowDataHandler(value, 'head_a_longitude')
            }
            defaultValue={abLinePayload.abLineXY[0].longitude}
            value={vineRowData.head_a_longitude}
          />
        </div>
        <div className="formCol">
          <label className="formLabel">
            Longitude <span style={{ color: 'red' }}> *</span>
          </label>
          <InputNumber
            className="commInput w100"
            onChange={(value) =>
              setVineRowDataHandler(value, 'head_b_longitude')
            }
            defaultValue={
              abLinePayload.abLineXY[abLinePayload.abLineXY.length - 1]
                .longitude
            }
            value={vineRowData.head_b_longitude}
          />
        </div>
      </div>
      {/* <div className="formRow">
        <div className="formCol">
          <label className="formLabel">
            Heading <span style={{ color: 'red' }}> *</span>
          </label>
          <InputNumber
            className="commInput w100"
            onChange={(value) => setVineRowDataHandler(value, 'head_a_bearing')}
            defaultValue={abLinePayload.head_a_bearing}
            value={vineRowData.head_a_bearing}
          />
        </div>
        <div className="formCol">
          <label className="formLabel">
            Heading <span style={{ color: 'red' }}> *</span>
          </label>
          <InputNumber
            className="commInput w100"
            onChange={(value) => setVineRowDataHandler(value, 'head_b_bearing')}
            defaultValue={abLinePayload.head_b_bearing}
            value={vineRowData.head_b_bearing}
          />
        </div>
      </div> */}
      <div className="edit-loader">
        {loader && (
          <div className="loader">
            <Spin size="large" />
          </div>
        )}
      </div>
    </Modal>
  );
};
export default AbLine;
