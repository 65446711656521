/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-restricted-globals */
import React, { useState } from 'react';
import { Divider, Select, Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

interface Props {
  label?: string;
  value?: string | number | undefined;
  setValue: (data: string | number | undefined) => void;
  cssClass?: string;
  options?: any[];
  optionKey?: string;
  optionDisplay?: string;
  required?: boolean;
  onSearch: (e: any) => void;
  addItem: (e: any) => void;
  newModal: any;
}
const CustomSelectDivider: React.FC<Props> = ({
  label,
  value,
  setValue,
  cssClass,
  options,
  optionKey = 'id',
  optionDisplay,
  required,
  onSearch,
  addItem,
  newModal,
}: Props) => {
  const [focus, setFocus] = useState(false);

  const isEmpty = (value: any) => {
    if (typeof value === 'undefined') return true;
    if (typeof value === 'string' && value.trim() === '') return true;
    if (typeof value === 'number' && isNaN(value)) return true;
    return false;
  };

  const labelClass = focus || !isEmpty(value) ? 'label label-float' : 'label';
  return (
    <div
      className="float-label"
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      <Select
        style={{ width: '100%' }}
        placeholder="Select Part Number"
        onSelect={setValue}
        value={value}
        className={cssClass}
        showSearch
        onSearch={onSearch}
        // optionFilterProp="children"
        notFoundContent={<em>No Matches Found</em>}
        dropdownRender={(menu) => (
          <>
            {menu}
            {newModal && newModal.length > 0 && (
              <>
                <Divider style={{ margin: '8px 0' }} />
                <div className="mfAdd" onClick={addItem}>
                  <Space style={{ padding: '0 8px 4px', cursor: 'pointer' }}>
                    <PlusOutlined />'{newModal}'
                  </Space>
                </div>
              </>
            )}
          </>
        )}
      >
        {options &&
          options?.length > 0 &&
          options?.map((data: any, key) => (
            <option
              value={data[optionKey]}
              key={key}
              data-testid={`${
                optionDisplay ? data[optionDisplay] : data[optionKey]
              }-CustomSelect`}
            >
              {optionDisplay ? data[optionDisplay] : data[optionKey]}{' '}
            </option>
          ))}
      </Select>
      <label className={labelClass}>
        {label} {required && <span className="float-span">*</span>}
      </label>
    </div>
  );
};

export default CustomSelectDivider;
