import { Button, notification, Modal, Space } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import InfiniteScrollTable from '../common/InfiniteScrollTable';
import { getBasestationOTALogsHistory } from '../../constants/Api';
import translate from '../../locale/en_translate.json';
import { useTranslation } from 'react-i18next';
import { ApplicationContext } from '../../context/AppContext';
import { getDateTimes } from '../../constants/Common';
import { ReloadOutlined } from '@ant-design/icons';

interface Props {
  onClose: () => void;
  data: any;
}

const LogHistory: React.FC<Props> = ({ onClose, data }: Props) => {
  const { t } = useTranslation();
  const { user } = useContext(ApplicationContext);
  const [loader, setLoader] = useState<boolean>(false);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [filterData, setFilter] = useState<any[]>([]);
  const callbackInterval = useRef<any>(null);

  useEffect(() => {
    callbackInterval.current = setInterval(() => {
      if (user && data) initLogData();
    }, 15 * 1000);
    if (user && data) initLogData();
    return () =>
      callbackInterval.current && clearInterval(callbackInterval.current);
  }, [user]);

  const initLogData = async () => {
    if (!user) return;
    const { organization } = user;
    const { api_url, fleet } = organization;
    const { basestationId, id } = data;
    try {
      setLoader(true);
      const response = await getBasestationOTALogsHistory(
        api_url,
        fleet.id,
        basestationId,
        id,
      );
      setTotalCount(response._metadata.total_records_count);
      const { records } = response;
      let data = Array.isArray(records) ? records : [];

      data = data.map((item: any) =>
        Object.assign({
          message: item.message ?? '-',
          created_date_time: item.created_date_time
            ? getDateTimes(item.created_date_time)
            : '-',
        }),
      );
      setFilter(data);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    } finally {
      setLoader(false);
    }
  };

  const columns = [
    {
      title: `${t(translate.fleetticketsHistory.createdbydate)}`,
      dataIndex: 'created_date_time',
      key: 'created_date_time',
    },
    {
      title: `${t(translate.headers.description)}`,
      dataIndex: 'message',
      key: 'message',
    },
  ];

  return (
    <Modal
      title={[
        <Space size={16} key="space">
          <span key="title">{`${data?.name} OTA Logs`}</span>
          <ReloadOutlined key="reload" onClick={initLogData} />
        </Space>,
      ]}
      visible={true}
      className="baseModalContainer profileUpdatePopup grPopup"
      onCancel={onClose}
      footer={[
        <Button
          type="primary"
          key="back"
          onClick={onClose}
          className="employeeCreate"
          size="large"
        >
          {t(translate.buttons.close)}
        </Button>,
      ]}
    >
      <div className="tblDft farmTabsTbl posRel tblStyles">
        <InfiniteScrollTable
          columns={columns}
          loading={loader}
          filterData={filterData}
          totalcount={totalCount}
          filename="Records"
        />
      </div>
    </Modal>
  );
};

export default LogHistory;
