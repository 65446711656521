import React from 'react';
import allCamDownIcon from './images/all_camera_icon.svg';
import camDownEx from './images/cam_down_ex.svg';
import './style.scss';

interface Props {
  face?: string;
  message?: string;
}

const AllCameraDown: React.FC<Props> = ({ face = '', message = '' }) => {
  const isAll = face?.trim() === '';
  const content = !isAll && (
    <>
      {message || (
        <>
          please reboot the system on the <br /> tractor to resolve issue
        </>
      )}
    </>
  );

  return (
    <div className={`all-camera-down ${isAll ? 'all-cam' : ''}`}>
      <div className="acd-container">
        <div className="acd-img-block">
          <img src={isAll ? allCamDownIcon : camDownEx} className="acd-icon" />
        </div>
        <div className="acd-title">
          {isAll ? 'Tractor is Offline' : `${face} Camera is Down`}
        </div>
        <div className="acd-desc">{content}</div>
      </div>
    </div>
  );
};

export default AllCameraDown;
