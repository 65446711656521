/* eslint-disable no-console */
import { Button, notification } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import arrow_backward_d from '../../assets/images/arrow_backward_d.svg';
import capture from '../../assets/images/capture.svg';
import infoGreen from '../../assets/images/info_green.svg';
import CustomSelect from '../common/CustomSelect';

import { getImplementsData, setImplement } from '../../constants/Api';
import {
  Buffer10,
  Buffer30,
  Buffer60,
  dairySpeedList,
  getDriveActionUuid,
  getImplementType,
  noImplementCode,
} from '../../constants/constant';
import { ImplementDropDownType, Speed } from '../../constants/types';
import { ApplicationContext } from '../../context/AppContext';
import { BufferTimeOut } from '../../lib/types';
import AppLoader from '../common/AppLoader';
import CustomSelectMulti from '../common/CustomSelectMulti';
import DriveOptionSettings from '../common/DriveOptionSettings';
import HitchImage from '../remote_drive_new/HitchImage';
import { RemoteDriveAppCtx } from '../remote_drive_new/RemoteDriveContext';
import {
  SELECTED_IMPLEMENT_NAME,
  SELECTED_IMPLEMENT_TYPE,
  SET_LOADER_FOR_IMPLEMENT_CHANGE,
  SET_SETUP_KEYS,
  SHOW_TRACTOR_COMMAND_LOADER,
} from '../remote_drive_new/actions';

interface Props {
  setSelectedImplement: (implement: string) => void;
  setShowDairyEndAutoDrive: (show: boolean) => void;
  setSpeed: (speed: number) => void;
  speed: number;
  selectedImplement: string;
  addRoutineHandler: () => void;
  setNewOrEditOperation: (state: '' | 'NEW' | 'OLD') => void;
}

const DairyImplementDetail: React.FC<Props> = ({
  setShowDairyEndAutoDrive,
  setSelectedImplement,
  setSpeed,
  speed,
  selectedImplement,
  addRoutineHandler,
  setNewOrEditOperation,
}) => {
  const [implementsList, setImplementsList] = useState<any>([]);
  const [implementListForDropdown, setImplementListForDropdown] = useState<
    ImplementDropDownType[]
  >([]);
  const { userDetails, APPReducer } = useContext(ApplicationContext);
  const [appState] = APPReducer;
  const { tractorsList } = appState;

  const { RDReducer, addImplementSettings, clearCommandApiHandler } =
    useContext(RemoteDriveAppCtx);
  const [state, dispatch] = RDReducer;

  const {
    selectedTractor,
    selectedImplementName,
    showTractorCommandLoader,
    setUpKeys,
  } = state;

  const [ingearSpeedData] = useState<Speed[]>(dairySpeedList);
  const [savedImplementDetails, setSavedImplementDetails] = useState<any>(null);
  useEffect(() => {
    userDetails && userDetails.organization && getImplementsListData();
  }, [userDetails]);
  const [autoDriveLoader, setAutoDriveLoader] = useState<boolean>(false);
  const [hitchImage, setHitchImage] = useState('');
  const [bufferTimeout, setBufferTimeout] = useState<BufferTimeOut>(0);
  const getImplementsListData = async () => {
    setAutoDriveLoader(true);
    try {
      const { records } = await getImplementsData(
        userDetails.organization.api_url,
        userDetails.organization.fleet.id,
      );
      records && records.length > 0
        ? setImplementsList(records)
        : setImplementsList([]);

      const records1: any[] = records.map((d: any) => {
        return {
          label: d.name,
          value: d.config_implements_uuid,
          index: d.config_implements_uuid,
          type: d.implement_type,
          id: d.config_implements_uuid,
        };
      });
      setImplementListForDropdown([
        {
          label: noImplementCode,
          value: noImplementCode,
          index: noImplementCode,
          type: '',
          id: noImplementCode,
        },
        ...records1,
      ]);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    } finally {
      setAutoDriveLoader(false);
    }
  };

  useEffect(() => {
    dispatch({
      type: SELECTED_IMPLEMENT_NAME,
      payload: null,
    });
    dispatch({
      type: SELECTED_IMPLEMENT_TYPE,
      payload: '',
    });
    if (selectedTractor && selectedTractor.id && implementsList.length > 0) {
      implementsList.forEach((implement: any) => {
        if (
          selectedTractor &&
          selectedTractor?.heartbeat &&
          selectedTractor?.heartbeat?.implement &&
          selectedTractor?.heartbeat?.implement?.current_implement &&
          selectedTractor?.heartbeat?.implement?.current_implement.length > 0 &&
          implement.name ===
            selectedTractor?.heartbeat?.implement?.current_implement
        ) {
          dispatch({
            type: SELECTED_IMPLEMENT_NAME,
            payload: implement.name,
          });
          dispatch({
            type: SELECTED_IMPLEMENT_TYPE,
            payload: getImplementType(implement) || '',
          });
          setSelectedImplement(implement.config_implements_uuid);
        }
      });
    }
  }, [selectedTractor, implementsList]);

  const approveImplementSettings = async () => {
    setAutoDriveLoader(true);
    try {
      const { organization } = userDetails;
      if (organization && organization.api_url) {
        dispatch({
          type: SET_LOADER_FOR_IMPLEMENT_CHANGE,
          payload: selectedImplementName ? selectedImplementName : null,
        });
        await setImplement(
          organization.api_url,
          selectedTractor.id,
          selectedImplementName ? selectedImplementName : 'None',
        );
      }
    } catch (err: any) {
      notification.error({
        message: err?.message,
        duration: 1,
      });
    } finally {
      setAutoDriveLoader(false);
    }
  };
  // useEffect(() => {
  //   userDetails && userDetails.organization && getGearDataByRPM(540);
  // }, [userDetails]);
  // const getGearDataByRPM = async (RPM: number) => {
  //   setAutoDriveLoader(true);
  //   try {
  //     const result: GearSpeedDataType[] = await getptoRpmList(
  //       userDetails.organization.api_url,
  //       RPM,
  //     );
  //     if (result && result.length > 0) {
  //       const data = result.map((ele: any, i: number) => {
  //         i === 0 && setSpeed(ele.speed);
  //         ele.name = ele.speed + ' ' + ele.unit;
  //         ele.value = ele.speed;
  //         return ele;
  //       });
  //       setInGearSpeedData(data);
  //     }
  //   } catch (err: any) {
  //     notification.error({
  //       message: err.message,
  //     });
  //   } finally {
  //     setAutoDriveLoader(false);
  //   }
  // };
  useEffect(() => {
    setBufferTimeout(savedImplementDetails?.buffer_timeout || 0);
    const output = dairySpeedList.some((dairySpeed: Speed) => {
      return (
        dairySpeed.value == savedImplementDetails?.imp_ctrl_in_row_tractor_speed
      );
    });
    setSpeed(
      output
        ? Number(savedImplementDetails?.imp_ctrl_in_row_tractor_speed)
        : 4.5,
    );
  }, [savedImplementDetails]);
  const handleDisabled = (): boolean => {
    let state = false;
    // if (selectedImplement === '') {
    //   state = true;
    // }
    if (
      bufferTimeout !== Buffer10 &&
      bufferTimeout !== Buffer30 &&
      bufferTimeout !== Buffer60
    ) {
      state = true;
    }
    return state;
  };

  return (
    <div className="rautoDriveRightSec feed_pusher_container newAutodriveWidget">
      {/* ************************ snapshot section start here  */}
      <div className="snapshot_section dairy_imp_section">
        <div className="exit_autodrive d_Button3">
          <div
            onClick={() => setShowDairyEndAutoDrive(true)}
            className="pointer"
          >
            <img src={arrow_backward_d} />
            <span>Exit Autodrive</span>
          </div>
          <div>
            <span className="beta-btn">BETA</span>
          </div>
        </div>
        <div className="snapshot_sec d_body3 ">
          <img src={capture} />
          Here’s a snapshot from the Front camera
        </div>
        <div className="d_camera_sec">
          {/* <img src={feed_pusher_camera} /> */}
          <HitchImage
            hitchImage={hitchImage}
            setHitchImage={setHitchImage}
            driveActionUUID={
              selectedTractor?.heartbeat?.drive_action_details
                .current_drive_action
            }
            cameraType={'front'}
          />
        </div>
        <div className="implement_list_sec">
          <div>
            <h2 className="select_implement_head">
              Select implement from list
            </h2>
            <p>Only Monarch verified implements can be used with Autodrive</p>
          </div>
          <div className="mb20">
            <CustomSelectMulti
              label="Implement"
              value={
                implementListForDropdown && implementListForDropdown.length > 0
                  ? selectedImplement
                  : ''
              }
              setValue={(selected) => {
                const [implement] = implementsList.filter(
                  (implement: any) =>
                    selected === implement.config_implements_uuid,
                );
                if (implement) {
                  dispatch({
                    type: SELECTED_IMPLEMENT_NAME,
                    payload:
                      implement && implement.name ? implement.name : null,
                  });
                  dispatch({
                    type: SELECTED_IMPLEMENT_TYPE,
                    payload: getImplementType(implement) || '',
                  });
                  setSelectedImplement(selected);
                }
                if (selected === noImplementCode) {
                  dispatch({
                    type: SELECTED_IMPLEMENT_NAME,
                    payload: null,
                  });
                  dispatch({
                    type: SELECTED_IMPLEMENT_TYPE,
                    payload: '',
                  });
                  setSelectedImplement(noImplementCode);
                }
              }}
              options={implementListForDropdown}
              optionKey="id"
              optionDisplay="label"
              optionDisplay2="type"
            />
          </div>
          <div className="adj_op_speed_sec mb20">
            <h2 className="adj_speed">Adjust operating speed</h2>
            <CustomSelect
              label="Speed"
              cssClass="w100Per msDD"
              value={speed}
              setValue={setSpeed}
              optionKey="value"
              options={ingearSpeedData}
              optionDisplay="name"
            />
          </div>
        </div>
        <div className="bufferBlk">
          <p className="headline3">
            Connectivity Loss<span className="hintTxtRed">*</span>
          </p>
          <h6 className="seedTxt">
            If you lose connectivity you will not be able to pause/resume or
            review safety stops from WingspanAI.
          </h6>
          <h6 className="seedTxt">
            Select how long the operation should continue without a connection
            to WingspanAI.
          </h6>
          <div className="bufferFlex">
            <Button
              className={`${bufferTimeout === Buffer10 ? 'selected' : ''}`}
              onClick={() => setBufferTimeout(Buffer10)}
            >
              Cautious <span>{Buffer10}s</span>
            </Button>
            <Button
              className={`${bufferTimeout === Buffer30 ? 'selected' : ''}`}
              onClick={() => setBufferTimeout(Buffer30)}
            >
              Moderate <span>{Buffer30}s</span>
            </Button>
            <Button
              className={`${bufferTimeout === Buffer60 ? 'selected' : ''}`}
              onClick={() => setBufferTimeout(Buffer60)}
            >
              Relaxed <span>{Buffer60}s</span>
            </Button>
          </div>
          {bufferTimeout !== Buffer10 &&
            bufferTimeout !== Buffer30 &&
            bufferTimeout !== Buffer60 && (
              <div className="hintTxt hintTxtRed">
                Please select Connectivity Buffer
              </div>
            )}
          {bufferTimeout === Buffer10 && (
            <div className="infoSection">
              <img src={infoGreen} alt="" />
              <div>
                <div>
                  If connectivity isn&apos;t restored within the chosen time,
                  the tractor will stop. Collision avoidance remains active even
                  without a connection.
                </div>
              </div>
            </div>
          )}
          {bufferTimeout === Buffer30 && (
            <div className="infoSection">
              <img src={infoGreen} alt="" />
              <div>
                <div>
                  If connectivity isn&apos;t restored within the chosen time,
                  the tractor will stop. Collision avoidance remains active even
                  without a connection.
                </div>
              </div>
            </div>
          )}
          {bufferTimeout === Buffer60 && (
            <div className="infoSection">
              <img src={infoGreen} alt="" />
              <div>
                <div>
                  If connectivity isn&apos;t restored within the chosen time,
                  the tractor will stop. Collision avoidance remains active even
                  without a connection.
                </div>
              </div>
            </div>
          )}
        </div>

        {tractorsList &&
          tractorsList.length &&
          selectedTractor &&
          selectedTractor.id && (
            <div className="opsSettings" style={{ display: 'none' }}>
              <DriveOptionSettings
                id={selectedImplement as string}
                tractorId={selectedTractor.id}
                driveActionUUID={
                  getDriveActionUuid(tractorsList, selectedTractor.id) as string
                }
                handleDetails={(data) => {
                  setSavedImplementDetails(data);
                }}
              />
            </div>
          )}
      </div>
      <div className="d_button_sec">
        <button
          className="button_green d_Button3"
          disabled={handleDisabled()}
          onClick={() => {
            setNewOrEditOperation('');
            approveImplementSettings();
            showTractorCommandLoader[selectedTractor.id] = false;
            dispatch({
              type: SHOW_TRACTOR_COMMAND_LOADER,
              payload: {
                ...showTractorCommandLoader,
              },
            });
            addRoutineHandler();
            addImplementSettings(
              {
                ...savedImplementDetails,
                imp_ctrl_in_row_tractor_speed: speed,
                imp_ctrl_out_of_row_tractor_speed: speed,
                buffer_timeout: bufferTimeout,
              },
              false,
            );
            dispatch({
              type: SET_SETUP_KEYS,
              payload: {
                ...setUpKeys,
                isBlockSelected: false,
              },
            });
          }}
        >
          Next
        </button>
      </div>
      {/* ************************ snapshot section end here  */}
      <AppLoader loader={autoDriveLoader} />
    </div>
  );
};

export default DairyImplementDetail;
