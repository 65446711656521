/* eslint-disable no-console */
import React, { createContext, useContext, useEffect, useReducer } from 'react';
import TeamsReducer, { initialState } from './TeamsReducer';
import { getTeamsList } from '../constants/Api';
import { ApplicationContext } from './AppContext';
import { SET_TEAMS } from './actions';
export const TeamsContext = createContext({
  teamReducer: [],
  teams: [],
} as {
  teamReducer: any;
  teams: any[];
});

// eslint-disable-next-line react/prop-types
const TeamsAppContext: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(TeamsReducer, initialState);
  const { user } = useContext(ApplicationContext);
  const { teams = [], teamsRefresh } = state;

  const initTeams = async (pageNumber = 0) => {
    try {
      if (!user) return;
      const {
        organization: { api_url, id },
      } = user;
      const response = await getTeamsList(api_url, id, pageNumber);
      const data =
        response && response.records && response.records.length > 0
          ? response.records
          : [];
      dispatch({ type: SET_TEAMS, payload: data });
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    initTeams();
  }, [user, teamsRefresh]);

  return (
    <TeamsContext.Provider
      value={{
        teamReducer: [state, dispatch],
        teams: teams,
      }}
    >
      {children}
    </TeamsContext.Provider>
  );
};

export default TeamsAppContext;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useTeamsContext = () => useContext(TeamsContext);
