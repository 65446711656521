import { create } from 'zustand';

interface OperationalHours {
  hours: number;
  minutes: number;
}

interface CoverageByOperations {
  category: string;
  value1: number;
  value2: number;
  value3: number;
  value4: number;
  total: number;
}

interface NoImplementSummary {
  avgSpeed: string;
  distance: string;
  efficiency: string;
}

// define types for state values and actions separately
type State = {
  operationalHours: OperationalHours;
  idleTime: OperationalHours;
  coverage: string;
  efficiency: string;
  completedWork: any[];
  tractorEfficiencyData: any[];
  operationalHoursByOperator: any[];
  coverageByOperator: any[];
  operatorId: string;
};

type Actions = {
  addOperationalHours: (operationalHours: OperationalHours) => void;
  addIdleTime: (operationalHours: OperationalHours) => void;
  addCoverage: (coverage: any) => void;
  addEfficiency: (efficiency: any) => void;
  addCompletedWorkData: (completedWork: any[]) => void;
  addCoverageByOperator: (coverageByOperator: CoverageByOperations[]) => void;
  addOperationalHoursByOperator: (operationalHoursByOperator: any[]) => void;
  setOperatorId: (operatorId: string) => void;
  addTractorEfficiencyData: (completedWork: any[]) => void;
  reset: () => void;
};

// define the initial state
const initialState: State = {
  operationalHours: {
    hours: 0,
    minutes: 0,
  },
  coverage: '0',
  efficiency: '0',
  idleTime: {
    hours: 0,
    minutes: 0,
  },
  completedWork: [],
  tractorEfficiencyData: [],
  operationalHoursByOperator: [],
  coverageByOperator: [],
  operatorId: '',
};

const useOperationalAnalyticDetailsStore = create<State & Actions>()(
  (set, get) => ({
    ...initialState,
    addOperationalHours: (operationalHours) =>
      set((state) => ({ operationalHours })),
    addCoverage: (coverage) =>
      set((state) => ({
        coverage,
      })),
    addEfficiency: (efficiency) =>
      set((state) => ({
        efficiency,
      })),
    addCompletedWorkData: (completedWork) =>
      set((state) => ({
        completedWork,
      })),
    addOperationalHoursByOperator: (operationalHoursByOperator) =>
      set((state) => ({
        operationalHoursByOperator,
      })),
    addCoverageByOperator: (coverageByOperator) =>
      set((state) => ({
        coverageByOperator,
      })),
    setOperatorId: (operatorId) =>
      set((state) => ({
        operatorId,
      })),
    addTractorEfficiencyData: (tractorEfficiencyData) =>
      set((state) => ({
        tractorEfficiencyData,
      })),
    addIdleTime: (idleTime) =>
      set((state) => ({
        idleTime,
      })),
    reset: () => {
      set(initialState);
    },
  }),
);

export default useOperationalAnalyticDetailsStore;
