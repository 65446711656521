import { MinusOutlined } from '@ant-design/icons';
import { Checkbox } from 'antd';
import React from 'react';

import datalayerIcon from '../../../assets/images/mpLayersIcon_new.svg';

import mpl_boundary from '../../../assets/images/layer_icons/layer-boundary.svg';
import mpl_geofence from '../../../assets/images/layer_icons/white geofence.svg';
import orgDairyIconImage from './../../../assets/images/crop_blue.svg';
import orgVineYardIconImage from './../../../assets/images/crop_purple.svg';
import tractorIconImage from './../../../assets/images/tractor__normal.svg';
interface Props {
  mapLayersToggle: boolean;
  setMapLayersToggle: (mapLayersToggle: boolean) => void;

  showTractors: boolean;
  setShowTractors: (showTractors: boolean) => void;

  showVineYardOrgs: boolean;
  setVineYardShowOrgs: (showVineYardOrgs: boolean) => void;

  showDairyOrgs: boolean;
  setShowDairyOrgs: (showDairyOrgs: boolean) => void;

  showWhitePolygons: boolean;
  setShowWhitePolygons: (showWhitePolygons: boolean) => void;

  showBrownPolygons: boolean;
  setShowBrownPolygons: (showBrownPolygons: boolean) => void;
  farm: boolean;
  setFarm: (farm: boolean) => void;
}
const MapLayers: React.FC<Props> = ({
  mapLayersToggle,
  setMapLayersToggle,
  farm,
  setFarm,
  showVineYardOrgs,
  setVineYardShowOrgs,
  showDairyOrgs,
  setShowDairyOrgs,
  showWhitePolygons,
  setShowWhitePolygons,
  showTractors,
  setShowTractors,
  showBrownPolygons,
  setShowBrownPolygons,
}) => {
  return (
    <div
      className={
        mapLayersToggle
          ? 'bottomMapLayers mpOpen ad_layers'
          : 'bottomMapLayers mpClose'
      }
    >
      <div className="dataLayerWrapper">
        <div className="dataLayerRowHead mb28">
          <div
            className="mpCloseIcon"
            data-testid="mapLayerClose-MapLayers"
            onClick={() => {
              setMapLayersToggle(!mapLayersToggle);
            }}
            style={{ cursor: 'pointer' }}
          ></div>
          <div className="mpLayerHead">
            <span className="dataIcon">
              <img src={datalayerIcon} alt="dataLayer" />
            </span>
            <span className="dataHead" data-testid="mapLayersHeader-MapLayers">
              MAP LAYERS
            </span>
          </div>
        </div>
        <div className="dataLayerSec">
          <div className="dataLayerContainer mb7">
            <div className="dataLayerRow mplChkBox">
              <div className="checkBoxItem">
                <span
                  className="mapLayerNameHead"
                  data-testid="farmLayerHeader-MapLayer"
                >
                  Farm
                </span>
                {/* <span
                  className="lChkBox"
                  data-testid="farmOverallCheckBox-MapLayers"
                >
                  <Checkbox
                    defaultChecked={farm}
                    checked={farm}
                    onChange={(e) => {
                      setFarm(e.target.checked);
                    }}
                  ></Checkbox>
                  {(showTractors ||
                    showVineYardOrgs ||
                    showWhitePolygons ||
                    showBrownPolygons) &&
                    !farm && <MinusOutlined className="fleet-minus" />}
                </span> */}
              </div>
            </div>
            <div className="dataLayerInnerRow mplChkBox">
              <div className="checkBoxItem ml50">
                <span className="iconW mplBsIcon">
                  <img
                    src={orgVineYardIconImage}
                    width="16"
                    height="20"
                    alt="tractor"
                    style={{ marginTop: 8 }}
                    className="t_org_icon_layer"
                  />
                </span>
                <span className="layerName ">Vineyard</span>
                <span
                  className="lChkBox"
                  data-testid="basestationCheckBox-MapLayers"
                >
                  <Checkbox
                    defaultChecked={showVineYardOrgs}
                    checked={showVineYardOrgs}
                    onChange={(e) => {
                      setVineYardShowOrgs(e.target.checked);
                    }}
                  ></Checkbox>
                </span>
              </div>
            </div>
            <div className="dataLayerInnerRow mplChkBox">
              <div className="checkBoxItem ml50">
                <span className="iconW mplBsIcon">
                  <img
                    src={orgDairyIconImage}
                    width="16"
                    height="20"
                    alt="tractor"
                    style={{ marginTop: 8 }}
                    className="t_org_icon_layer"
                  />
                </span>
                <span className="layerName ">Dairy</span>
                <span
                  className="lChkBox"
                  data-testid="basestationCheckBox-MapLayers"
                >
                  <Checkbox
                    defaultChecked={showDairyOrgs}
                    checked={showDairyOrgs}
                    onChange={(e) => {
                      setShowDairyOrgs(e.target.checked);
                    }}
                  ></Checkbox>
                </span>
              </div>
            </div>
            <div className="dataLayerInnerRow mplChkBox">
              <div className="checkBoxItem ml50">
                <span className="iconW mplBsIcon">
                  <img
                    src={tractorIconImage}
                    width="13"
                    height="18"
                    alt="tractor"
                    style={{ marginTop: 8 }}
                    className="t_tractor_icon_layer"
                  />
                </span>
                <span className="layerName ">Tractor</span>
                <span
                  className="lChkBox"
                  data-testid="basestationCheckBox-MapLayers"
                >
                  <Checkbox
                    defaultChecked={showTractors}
                    checked={showTractors}
                    onChange={(e) => {
                      setShowTractors(e.target.checked);
                    }}
                  ></Checkbox>
                </span>
              </div>
            </div>
            <div className="dataLayerInnerRow mplChkBox">
              <div className="checkBoxItem ml50">
                <span className="iconW">
                  <img src={mpl_boundary} alt="tractor" />
                </span>
                <span className="layerName">Boundary (Brown)</span>
                <span
                  className="lChkBox"
                  data-testid="boundryBrownCheckBox-MapLayers"
                >
                  <Checkbox
                    defaultChecked={showBrownPolygons}
                    checked={showBrownPolygons}
                    onChange={(e) => {
                      setShowBrownPolygons(e.target.checked);
                    }}
                  ></Checkbox>
                </span>
              </div>
            </div>
            <div className="dataLayerInnerRow mplChkBox">
              <div className="checkBoxItem ml50">
                <span className="iconW">
                  <img src={mpl_geofence} alt="tractor" />
                </span>
                <span className="layerName">Geofence (White)</span>
                <span
                  className="lChkBox"
                  data-testid="geofenceWhiteCheckBox-MapLayers"
                >
                  <Checkbox
                    defaultChecked={showWhitePolygons}
                    checked={showWhitePolygons}
                    onChange={(e) => {
                      setShowWhitePolygons(e.target.checked);
                    }}
                  ></Checkbox>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MapLayers;
