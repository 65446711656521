export const monarchCertifiedImplements = {
  _metadata: {
    page_number: 1,
    page_size: 25,
    total_pages: 1,
    total_records_count: 11,
  },
  records: [
    {
      attachment_type: '3 PT Hitch',
      config_imm: {
        config_imm_uuid: 'f6f345d8cbca44b0ae0cca0dc48d82ee',
        global_imm_uuid: '715b16812e3340d281c495fcab48fdc4',
        manufacturer: 'Bush Hog',
        model: 'BH-215',
      },
      config_imm_uuid: 'f6f345d8cbca44b0ae0cca0dc48d82ee',
      config_implements_uuid: 'f321e97eb8414686bc62425565276728',
      created_by: {
        first_name: 'AutomationqhHJcMOABf',
        id: 567,
        last_name: 'AutomationvbIERsxpzA',
      },
      created_date_time: 1665164296125,
      current_tractor: null,
      current_tractor_id: 0,
      fleet_id: 18,
      floating_hitch: false,
      height: 41.0,
      id: 190,
      image_url: '',
      imp_ctrl_in_row_3pt_hitch_height: 90.0,
      imp_ctrl_in_row_3pt_hitch_mode: 'Position',
      imp_ctrl_in_row__hyd_imp_ctrl: '',
      imp_ctrl_in_row_aux_hydraulics_1: 2.0,
      imp_ctrl_in_row_aux_hydraulics_2: 18.0,
      imp_ctrl_in_row_aux_hydraulics_6: 12.0,
      imp_ctrl_in_row_gear: 'L3',
      imp_ctrl_in_row_generic_ctrl_1: true,
      imp_ctrl_in_row_generic_ctrl_2: true,
      imp_ctrl_in_row_generic_ctrl_3: true,
      imp_ctrl_in_row_generic_ctrl_4: true,
      imp_ctrl_in_row_generic_ctrl_5: 1.0,
      imp_ctrl_in_row_generic_ctrl_6: 1.0,
      imp_ctrl_in_row_pto: false,
      imp_ctrl_in_row_pto_rpm: 540.0,
      imp_ctrl_in_row_toplink_pos: 89.0,
      imp_ctrl_in_row_tractor_speed: 2.2,
      imp_ctrl_out_of_row_3pt_hitch_height: 95.0,
      imp_ctrl_out_of_row_3pt_hitch_mode: 'Position',
      imp_ctrl_out_of_row__hyd_imp_ctrl: '',
      imp_ctrl_out_of_row_aux_hydraulics_1: 1.0,
      imp_ctrl_out_of_row_aux_hydraulics_2: 1.0,
      imp_ctrl_out_of_row_aux_hydraulics_6: 1.0,
      imp_ctrl_out_of_row_generic_ctrl_1: false,
      imp_ctrl_out_of_row_generic_ctrl_2: false,
      imp_ctrl_out_of_row_generic_ctrl_3: false,
      imp_ctrl_out_of_row_generic_ctrl_4: false,
      imp_ctrl_out_of_row_generic_ctrl_5: 1.0,
      imp_ctrl_out_of_row_generic_ctrl_6: 1.0,
      imp_ctrl_out_of_row_pto: false,
      imp_ctrl_out_of_row_pto_rpm: 540.0,
      imp_ctrl_out_of_row_toplink_pos: 1.0,
      imp_ctrl_out_of_row_tractor_speed: 2.2,
      imp_ctrl_out_row_gear: 'L3',
      implement_type: 'Rotary Mower',
      is_active: true,
      is_autonomy_full_filled: true,
      is_autonomy_implement: true,
      is_deleted: 0,
      is_implement_info_missing: false,
      length: 93.0,
      modified_by: 0,
      modified_date_time: 1708017214390,
      name: 'Bush Hog BH-215',
      organization_id: 20,
      power: '',
      serial_number: '',
      weight: 1000.0,
      width: 64.0,
      x: 0.0,
      y: 0.0,
    },
    {
      attachment_type: '3 PT Hitch',
      config_imm: {
        config_imm_uuid: 'bbef5947ceb34436992bd05f632db3b4',
        global_imm_uuid: 'b69ca4d8e0434f0e82fa2899d3acf9bf',
        manufacturer: 'Domries',
        model: 'SN-2324',
      },
      config_imm_uuid: 'bbef5947ceb34436992bd05f632db3b4',
      config_implements_uuid: 'c315c4c6ddbc40e1adf00414237b01d2',
      created_by: {
        first_name: 'AutomationqhHJcMOABf',
        id: 567,
        last_name: 'AutomationvbIERsxpzA',
      },
      created_date_time: 1665437156214,
      current_tractor: null,
      current_tractor_id: 0,
      fleet_id: 18,
      floating_hitch: false,
      height: 47.0,
      id: 191,
      image_url: '',
      imp_ctrl_in_row_3pt_hitch_height: 89.0,
      imp_ctrl_in_row_3pt_hitch_mode: 'Position',
      imp_ctrl_in_row__hyd_imp_ctrl: '',
      imp_ctrl_in_row_aux_hydraulics_1: 0.0,
      imp_ctrl_in_row_aux_hydraulics_2: 0.0,
      imp_ctrl_in_row_aux_hydraulics_6: 0.0,
      imp_ctrl_in_row_gear: 'M1',
      imp_ctrl_in_row_generic_ctrl_1: false,
      imp_ctrl_in_row_generic_ctrl_2: false,
      imp_ctrl_in_row_generic_ctrl_3: false,
      imp_ctrl_in_row_generic_ctrl_4: false,
      imp_ctrl_in_row_generic_ctrl_5: 4.0,
      imp_ctrl_in_row_generic_ctrl_6: 4.0,
      imp_ctrl_in_row_pto: false,
      imp_ctrl_in_row_pto_rpm: 540.0,
      imp_ctrl_in_row_toplink_pos: 10.0,
      imp_ctrl_in_row_tractor_speed: 2.9,
      imp_ctrl_out_of_row_3pt_hitch_height: 89.0,
      imp_ctrl_out_of_row_3pt_hitch_mode: 'Position',
      imp_ctrl_out_of_row__hyd_imp_ctrl: '',
      imp_ctrl_out_of_row_aux_hydraulics_1: 2.0,
      imp_ctrl_out_of_row_aux_hydraulics_2: 9.0,
      imp_ctrl_out_of_row_aux_hydraulics_6: 6.0,
      imp_ctrl_out_of_row_generic_ctrl_1: false,
      imp_ctrl_out_of_row_generic_ctrl_2: false,
      imp_ctrl_out_of_row_generic_ctrl_3: false,
      imp_ctrl_out_of_row_generic_ctrl_4: false,
      imp_ctrl_out_of_row_generic_ctrl_5: 6.0,
      imp_ctrl_out_of_row_generic_ctrl_6: 6.0,
      imp_ctrl_out_of_row_pto: false,
      imp_ctrl_out_of_row_pto_rpm: 1.0,
      imp_ctrl_out_of_row_toplink_pos: 6.0,
      imp_ctrl_out_of_row_tractor_speed: 2.9,
      imp_ctrl_out_row_gear: 'M1',
      implement_type: 'Disc',
      is_active: true,
      is_autonomy_full_filled: true,
      is_autonomy_implement: true,
      is_deleted: 0,
      is_implement_info_missing: false,
      length: 68.0,
      modified_by: 0,
      modified_date_time: 1708017225548,
      name: 'Domries Disc',
      organization_id: 20,
      power: '',
      serial_number: '',
      weight: 1400.0,
      width: 50.0,
      x: 0.0,
      y: 0.0,
    },
    {
      attachment_type: '3 PT Hitch',
      config_imm: {
        config_imm_uuid: '46ff1c32763c4000a4127043770db6ae',
        global_imm_uuid: 'd71255d939df4f60b5f7f515bf2f8a23',
        manufacturer: 'Land Pride',
        model: 'BB1272',
      },
      config_imm_uuid: '46ff1c32763c4000a4127043770db6ae',
      config_implements_uuid: 'd195f30c43534a7bbd2e7d26409207dd',
      created_by: {
        first_name: 'AutomationBydcerNiTR',
        id: 568,
        last_name: 'AutomationqtPmYHnTmv',
      },
      created_date_time: 1697043707918,
      current_tractor: null,
      current_tractor_id: 0,
      fleet_id: 18,
      floating_hitch: false,
      height: 33.0,
      id: 203,
      image_url: '',
      imp_ctrl_in_row_3pt_hitch_height: 0.0,
      imp_ctrl_in_row_3pt_hitch_mode: 'Position',
      imp_ctrl_in_row__hyd_imp_ctrl: '',
      imp_ctrl_in_row_aux_hydraulics_1: 0.0,
      imp_ctrl_in_row_aux_hydraulics_2: 0.0,
      imp_ctrl_in_row_aux_hydraulics_6: 0.0,
      imp_ctrl_in_row_gear: 'L1',
      imp_ctrl_in_row_generic_ctrl_1: false,
      imp_ctrl_in_row_generic_ctrl_2: false,
      imp_ctrl_in_row_generic_ctrl_3: false,
      imp_ctrl_in_row_generic_ctrl_4: false,
      imp_ctrl_in_row_generic_ctrl_5: 0.0,
      imp_ctrl_in_row_generic_ctrl_6: 0.0,
      imp_ctrl_in_row_pto: false,
      imp_ctrl_in_row_pto_rpm: 540.0,
      imp_ctrl_in_row_toplink_pos: 0.0,
      imp_ctrl_in_row_tractor_speed: 1.1,
      imp_ctrl_out_of_row_3pt_hitch_height: 0.0,
      imp_ctrl_out_of_row_3pt_hitch_mode: 'Position',
      imp_ctrl_out_of_row__hyd_imp_ctrl: '',
      imp_ctrl_out_of_row_aux_hydraulics_1: 0.0,
      imp_ctrl_out_of_row_aux_hydraulics_2: 0.0,
      imp_ctrl_out_of_row_aux_hydraulics_6: 0.0,
      imp_ctrl_out_of_row_generic_ctrl_1: false,
      imp_ctrl_out_of_row_generic_ctrl_2: false,
      imp_ctrl_out_of_row_generic_ctrl_3: false,
      imp_ctrl_out_of_row_generic_ctrl_4: false,
      imp_ctrl_out_of_row_generic_ctrl_5: 0.0,
      imp_ctrl_out_of_row_generic_ctrl_6: 0.0,
      imp_ctrl_out_of_row_pto: false,
      imp_ctrl_out_of_row_pto_rpm: 540.0,
      imp_ctrl_out_of_row_toplink_pos: 0.0,
      imp_ctrl_out_of_row_tractor_speed: 1.1,
      imp_ctrl_out_row_gear: 'L1',
      implement_type: 'Box Blade',
      is_active: true,
      is_autonomy_full_filled: true,
      is_autonomy_implement: true,
      is_deleted: 0,
      is_implement_info_missing: false,
      length: 32.0,
      modified_by: 0,
      modified_date_time: 1708017422857,
      name: 'Land Pride Box Scraper',
      organization_id: 20,
      power: '',
      serial_number: '',
      weight: 404.0,
      width: 72.0,
      x: 0.0,
      y: 0.0,
    },
    {
      attachment_type: '3 PT Hitch',
      config_imm: {
        config_imm_uuid: 'bb5677a77feb4a81aada32511d116ae5',
        global_imm_uuid: 'e9d51c84b75743f2b0e544675bfe13cd',
        manufacturer: 'Land Pride',
        model: 'RCR1260',
      },
      config_imm_uuid: 'bb5677a77feb4a81aada32511d116ae5',
      config_implements_uuid: '1989899d77714497993b1d524e541ad0',
      created_by: {
        first_name: 'AutomationqhHJcMOABf',
        id: 567,
        last_name: 'AutomationvbIERsxpzA',
      },
      created_date_time: 1689098051576,
      current_tractor: null,
      current_tractor_id: null,
      fleet_id: 18,
      floating_hitch: false,
      height: 44.0,
      id: 195,
      image_url: '',
      imp_ctrl_in_row_3pt_hitch_height: 80.0,
      imp_ctrl_in_row_3pt_hitch_mode: 'Position',
      imp_ctrl_in_row__hyd_imp_ctrl: '',
      imp_ctrl_in_row_aux_hydraulics_1: 0.0,
      imp_ctrl_in_row_aux_hydraulics_2: 0.0,
      imp_ctrl_in_row_aux_hydraulics_6: 0.0,
      imp_ctrl_in_row_gear: 'L1',
      imp_ctrl_in_row_generic_ctrl_1: false,
      imp_ctrl_in_row_generic_ctrl_2: false,
      imp_ctrl_in_row_generic_ctrl_3: false,
      imp_ctrl_in_row_generic_ctrl_4: false,
      imp_ctrl_in_row_generic_ctrl_5: 0.0,
      imp_ctrl_in_row_generic_ctrl_6: 0.0,
      imp_ctrl_in_row_pto: false,
      imp_ctrl_in_row_pto_rpm: 540.0,
      imp_ctrl_in_row_toplink_pos: 0.0,
      imp_ctrl_in_row_tractor_speed: 1.1,
      imp_ctrl_out_of_row_3pt_hitch_height: 100.0,
      imp_ctrl_out_of_row_3pt_hitch_mode: 'Position',
      imp_ctrl_out_of_row__hyd_imp_ctrl: '',
      imp_ctrl_out_of_row_aux_hydraulics_1: 0.0,
      imp_ctrl_out_of_row_aux_hydraulics_2: 0.0,
      imp_ctrl_out_of_row_aux_hydraulics_6: 0.0,
      imp_ctrl_out_of_row_generic_ctrl_1: false,
      imp_ctrl_out_of_row_generic_ctrl_2: false,
      imp_ctrl_out_of_row_generic_ctrl_3: false,
      imp_ctrl_out_of_row_generic_ctrl_4: false,
      imp_ctrl_out_of_row_generic_ctrl_5: 0.0,
      imp_ctrl_out_of_row_generic_ctrl_6: 0.0,
      imp_ctrl_out_of_row_pto: false,
      imp_ctrl_out_of_row_pto_rpm: 540.0,
      imp_ctrl_out_of_row_toplink_pos: 0.0,
      imp_ctrl_out_of_row_tractor_speed: 1.1,
      imp_ctrl_out_row_gear: 'L1',
      implement_type: 'Rotary Mower',
      is_active: true,
      is_autonomy_full_filled: true,
      is_autonomy_implement: true,
      is_deleted: 0,
      is_implement_info_missing: false,
      length: 98.0,
      modified_by: 0,
      modified_date_time: 1708038295083,
      name: 'Land Pride RCR1260',
      organization_id: 20,
      power: null,
      serial_number: null,
      weight: 496.0,
      width: 64.0,
      x: null,
      y: null,
    },
    {
      attachment_type: '3 PT Hitch',
      config_imm: {
        config_imm_uuid: '0691293ca8f6464a97a4dc499b8168e9',
        global_imm_uuid: '0691293ca8f6464a97a4dc499b8168e9',
        manufacturer: 'Nobili',
        model: 'Triturator VK 115',
      },
      config_imm_uuid: '0691293ca8f6464a97a4dc499b8168e9',
      config_implements_uuid: '3632a6935f2846fd8d0852c117ae3a03',
      created_by: {
        first_name: 'AutomationBydcerNiTR',
        id: 568,
        last_name: 'AutomationqtPmYHnTmv',
      },
      created_date_time: 1697043537755,
      current_tractor: null,
      current_tractor_id: 0,
      fleet_id: 18,
      floating_hitch: false,
      height: 18.0,
      id: 202,
      image_url: '',
      imp_ctrl_in_row_3pt_hitch_height: 0.0,
      imp_ctrl_in_row_3pt_hitch_mode: 'Position',
      imp_ctrl_in_row__hyd_imp_ctrl: '',
      imp_ctrl_in_row_aux_hydraulics_1: 0.0,
      imp_ctrl_in_row_aux_hydraulics_2: 0.0,
      imp_ctrl_in_row_aux_hydraulics_6: 0.0,
      imp_ctrl_in_row_gear: 'L1',
      imp_ctrl_in_row_generic_ctrl_1: false,
      imp_ctrl_in_row_generic_ctrl_2: false,
      imp_ctrl_in_row_generic_ctrl_3: false,
      imp_ctrl_in_row_generic_ctrl_4: false,
      imp_ctrl_in_row_generic_ctrl_5: 0.0,
      imp_ctrl_in_row_generic_ctrl_6: 0.0,
      imp_ctrl_in_row_pto: false,
      imp_ctrl_in_row_pto_rpm: 540.0,
      imp_ctrl_in_row_toplink_pos: 0.0,
      imp_ctrl_in_row_tractor_speed: 1.1,
      imp_ctrl_out_of_row_3pt_hitch_height: 0.0,
      imp_ctrl_out_of_row_3pt_hitch_mode: 'Position',
      imp_ctrl_out_of_row__hyd_imp_ctrl: '',
      imp_ctrl_out_of_row_aux_hydraulics_1: 0.0,
      imp_ctrl_out_of_row_aux_hydraulics_2: 0.0,
      imp_ctrl_out_of_row_aux_hydraulics_6: 0.0,
      imp_ctrl_out_of_row_generic_ctrl_1: false,
      imp_ctrl_out_of_row_generic_ctrl_2: false,
      imp_ctrl_out_of_row_generic_ctrl_3: false,
      imp_ctrl_out_of_row_generic_ctrl_4: false,
      imp_ctrl_out_of_row_generic_ctrl_5: 0.0,
      imp_ctrl_out_of_row_generic_ctrl_6: 0.0,
      imp_ctrl_out_of_row_pto: false,
      imp_ctrl_out_of_row_pto_rpm: 540.0,
      imp_ctrl_out_of_row_toplink_pos: 0.0,
      imp_ctrl_out_of_row_tractor_speed: 1.1,
      imp_ctrl_out_row_gear: 'L1',
      implement_type: 'Flail Mower',
      is_active: true,
      is_autonomy_full_filled: true,
      is_autonomy_implement: true,
      is_deleted: 0,
      is_implement_info_missing: false,
      length: 50.0,
      modified_by: 0,
      modified_date_time: 1708017404262,
      name: 'Nobili Triturator VK 115',
      organization_id: 20,
      power: '',
      serial_number: '',
      weight: 728.0,
      width: 37.0,
      x: 0.0,
      y: 0.0,
    },
    {
      attachment_type: '3pt Cat I',
      config_imm: {
        config_imm_uuid: '6dfd9ed186b14d5dbdc15bf07e2401db',
        global_imm_uuid: '3eabcc3faaf74278a6a40fddde61fca4',
        manufacturer: 'Rears',
        model: 'Pul Blast 300 Gallon Narrow',
      },
      config_imm_uuid: '6dfd9ed186b14d5dbdc15bf07e2401db',
      config_implements_uuid: 'eeca42103b7e4a4484873036348f54c6',
      created_by: {
        first_name: 'Ben',
        id: 539,
        last_name: 'Gatten',
      },
      created_date_time: 1686850104149,
      current_tractor: null,
      current_tractor_id: null,
      fleet_id: 18,
      floating_hitch: false,
      height: 78.0,
      id: 62,
      image_url: null,
      imp_ctrl_in_row_3pt_hitch_height: 70.0,
      imp_ctrl_in_row_3pt_hitch_mode: 'Position',
      imp_ctrl_in_row__hyd_imp_ctrl: null,
      imp_ctrl_in_row_aux_hydraulics_1: 0.0,
      imp_ctrl_in_row_aux_hydraulics_2: 0.0,
      imp_ctrl_in_row_aux_hydraulics_6: 0.0,
      imp_ctrl_in_row_gear: 'L1',
      imp_ctrl_in_row_generic_ctrl_1: null,
      imp_ctrl_in_row_generic_ctrl_2: null,
      imp_ctrl_in_row_generic_ctrl_3: null,
      imp_ctrl_in_row_generic_ctrl_4: null,
      imp_ctrl_in_row_generic_ctrl_5: 0.0,
      imp_ctrl_in_row_generic_ctrl_6: 0.0,
      imp_ctrl_in_row_pto: true,
      imp_ctrl_in_row_pto_rpm: 540.0,
      imp_ctrl_in_row_toplink_pos: 0.0,
      imp_ctrl_in_row_tractor_speed: 1.1,
      imp_ctrl_out_of_row_3pt_hitch_height: 70.0,
      imp_ctrl_out_of_row_3pt_hitch_mode: 'Position',
      imp_ctrl_out_of_row__hyd_imp_ctrl: null,
      imp_ctrl_out_of_row_aux_hydraulics_1: 0.0,
      imp_ctrl_out_of_row_aux_hydraulics_2: 0.0,
      imp_ctrl_out_of_row_aux_hydraulics_6: 0.0,
      imp_ctrl_out_of_row_generic_ctrl_1: null,
      imp_ctrl_out_of_row_generic_ctrl_2: null,
      imp_ctrl_out_of_row_generic_ctrl_3: null,
      imp_ctrl_out_of_row_generic_ctrl_4: null,
      imp_ctrl_out_of_row_generic_ctrl_5: 0.0,
      imp_ctrl_out_of_row_generic_ctrl_6: 0.0,
      imp_ctrl_out_of_row_pto: false,
      imp_ctrl_out_of_row_pto_rpm: 0.0,
      imp_ctrl_out_of_row_toplink_pos: 0.0,
      imp_ctrl_out_of_row_tractor_speed: 1.1,
      imp_ctrl_out_row_gear: 'L1',
      implement_type: 'Rotary Mower',
      is_active: true,
      is_autonomy_full_filled: true,
      is_autonomy_implement: true,
      is_deleted: 0,
      is_implement_info_missing: false,
      length: 84.0,
      modified_by: null,
      modified_date_time: 1708063115341,
      name: 'Rears 300 Gallon Pul Blast',
      organization_id: 20,
      power: null,
      serial_number: null,
      weight: 3000.0,
      width: 57.0,
      x: null,
      y: null,
    },
    {
      attachment_type: '3 PT Hitch',
      config_imm: {
        config_imm_uuid: '6ff05fe26cf24cb1b3cc90a30934887a',
        global_imm_uuid: '6ff05fe26cf24cb1b3cc90a30934887a',
        manufacturer: 'Schmeiser',
        model: 'VD48PW',
      },
      config_imm_uuid: '6ff05fe26cf24cb1b3cc90a30934887a',
      config_implements_uuid: '75a80d9883a64ea4b39ddfcb574dc285',
      created_by: {
        first_name: 'AutomationBydcerNiTR',
        id: 568,
        last_name: 'AutomationqtPmYHnTmv',
      },
      created_date_time: 1692306527291,
      current_tractor: null,
      current_tractor_id: 0,
      fleet_id: 18,
      floating_hitch: false,
      height: 50.0,
      id: 196,
      image_url: '',
      imp_ctrl_in_row_3pt_hitch_height: 70.0,
      imp_ctrl_in_row_3pt_hitch_mode: 'Position',
      imp_ctrl_in_row__hyd_imp_ctrl: '',
      imp_ctrl_in_row_aux_hydraulics_1: 0.0,
      imp_ctrl_in_row_aux_hydraulics_2: 0.0,
      imp_ctrl_in_row_aux_hydraulics_6: 0.0,
      imp_ctrl_in_row_gear: 'L2',
      imp_ctrl_in_row_generic_ctrl_1: false,
      imp_ctrl_in_row_generic_ctrl_2: false,
      imp_ctrl_in_row_generic_ctrl_3: false,
      imp_ctrl_in_row_generic_ctrl_4: false,
      imp_ctrl_in_row_generic_ctrl_5: 0.0,
      imp_ctrl_in_row_generic_ctrl_6: 0.0,
      imp_ctrl_in_row_pto: false,
      imp_ctrl_in_row_pto_rpm: 540.0,
      imp_ctrl_in_row_toplink_pos: 0.0,
      imp_ctrl_in_row_tractor_speed: 1.6,
      imp_ctrl_out_of_row_3pt_hitch_height: 80.0,
      imp_ctrl_out_of_row_3pt_hitch_mode: 'Position',
      imp_ctrl_out_of_row__hyd_imp_ctrl: '',
      imp_ctrl_out_of_row_aux_hydraulics_1: 0.0,
      imp_ctrl_out_of_row_aux_hydraulics_2: 0.0,
      imp_ctrl_out_of_row_aux_hydraulics_6: 0.0,
      imp_ctrl_out_of_row_generic_ctrl_1: false,
      imp_ctrl_out_of_row_generic_ctrl_2: false,
      imp_ctrl_out_of_row_generic_ctrl_3: false,
      imp_ctrl_out_of_row_generic_ctrl_4: false,
      imp_ctrl_out_of_row_generic_ctrl_5: 0.0,
      imp_ctrl_out_of_row_generic_ctrl_6: 0.0,
      imp_ctrl_out_of_row_pto: false,
      imp_ctrl_out_of_row_pto_rpm: 540.0,
      imp_ctrl_out_of_row_toplink_pos: 0.0,
      imp_ctrl_out_of_row_tractor_speed: 1.6,
      imp_ctrl_out_row_gear: 'L2',
      implement_type: 'Seeder',
      is_active: true,
      is_autonomy_full_filled: true,
      is_autonomy_implement: true,
      is_deleted: 0,
      is_implement_info_missing: false,
      length: 69.0,
      modified_by: 0,
      modified_date_time: 1708017338959,
      name: 'Schmeiser 4ft Seeder',
      organization_id: 20,
      power: '',
      serial_number: '',
      weight: 1754.0,
      width: 48.0,
      x: 0.0,
      y: 0.0,
    },
    {
      attachment_type: '3 PT Hitch',
      config_imm: {
        config_imm_uuid: '1e13ffa2ef084a8d943ee9cf46871628',
        global_imm_uuid: 'ae9fb5e6d2d34069ba50365867f2894c',
        manufacturer: 'Schmeiser',
        model: 'VD60PW',
      },
      config_imm_uuid: '1e13ffa2ef084a8d943ee9cf46871628',
      config_implements_uuid: '03672024c2074e22afd25ab8ac7b506c',
      created_by: {
        first_name: 'AutomationBydcerNiTR',
        id: 568,
        last_name: 'AutomationqtPmYHnTmv',
      },
      created_date_time: 1697043424287,
      current_tractor: null,
      current_tractor_id: 0,
      fleet_id: 18,
      floating_hitch: false,
      height: 50.0,
      id: 201,
      image_url: '',
      imp_ctrl_in_row_3pt_hitch_height: 70.0,
      imp_ctrl_in_row_3pt_hitch_mode: 'Position',
      imp_ctrl_in_row__hyd_imp_ctrl: '',
      imp_ctrl_in_row_aux_hydraulics_1: 0.0,
      imp_ctrl_in_row_aux_hydraulics_2: 0.0,
      imp_ctrl_in_row_aux_hydraulics_6: 0.0,
      imp_ctrl_in_row_gear: 'L3',
      imp_ctrl_in_row_generic_ctrl_1: false,
      imp_ctrl_in_row_generic_ctrl_2: false,
      imp_ctrl_in_row_generic_ctrl_3: false,
      imp_ctrl_in_row_generic_ctrl_4: false,
      imp_ctrl_in_row_generic_ctrl_5: 0.0,
      imp_ctrl_in_row_generic_ctrl_6: 0.0,
      imp_ctrl_in_row_pto: false,
      imp_ctrl_in_row_pto_rpm: 540.0,
      imp_ctrl_in_row_toplink_pos: 0.0,
      imp_ctrl_in_row_tractor_speed: 2.2,
      imp_ctrl_out_of_row_3pt_hitch_height: 95.0,
      imp_ctrl_out_of_row_3pt_hitch_mode: 'Position',
      imp_ctrl_out_of_row__hyd_imp_ctrl: '',
      imp_ctrl_out_of_row_aux_hydraulics_1: 0.0,
      imp_ctrl_out_of_row_aux_hydraulics_2: 0.0,
      imp_ctrl_out_of_row_aux_hydraulics_6: 0.0,
      imp_ctrl_out_of_row_generic_ctrl_1: false,
      imp_ctrl_out_of_row_generic_ctrl_2: false,
      imp_ctrl_out_of_row_generic_ctrl_3: false,
      imp_ctrl_out_of_row_generic_ctrl_4: false,
      imp_ctrl_out_of_row_generic_ctrl_5: 0.0,
      imp_ctrl_out_of_row_generic_ctrl_6: 0.0,
      imp_ctrl_out_of_row_pto: false,
      imp_ctrl_out_of_row_pto_rpm: 540.0,
      imp_ctrl_out_of_row_toplink_pos: 0.0,
      imp_ctrl_out_of_row_tractor_speed: 2.2,
      imp_ctrl_out_row_gear: 'L3',
      implement_type: 'Seeder',
      is_active: true,
      is_autonomy_full_filled: true,
      is_autonomy_implement: true,
      is_deleted: 0,
      is_implement_info_missing: false,
      length: 69.0,
      modified_by: 0,
      modified_date_time: 1708017358712,
      name: 'Schmeiser 5ft Seeder',
      organization_id: 20,
      power: '',
      serial_number: '',
      weight: 1795.0,
      width: 62.0,
      x: 0.0,
      y: 0.0,
    },
    {
      attachment_type: '3 PT Hitch',
      config_imm: {
        config_imm_uuid: 'ab7cb2a6f66b4fe68d7a9cba2ff6055f',
        global_imm_uuid: 'ab7cb2a6f66b4fe68d7a9cba2ff6055f',
        manufacturer: 'Schmeiser',
        model: 'VD72PW',
      },
      config_imm_uuid: 'ab7cb2a6f66b4fe68d7a9cba2ff6055f',
      config_implements_uuid: '496f2dbd15c24dc4bbbd092024b9bb93',
      created_by: {
        first_name: 'Monarch Support',
        id: 752,
        last_name: 'Team',
      },
      created_date_time: 1694722402314,
      current_tractor: null,
      current_tractor_id: 0,
      fleet_id: 18,
      floating_hitch: false,
      height: 50.0,
      id: 198,
      image_url: '',
      imp_ctrl_in_row_3pt_hitch_height: 90.0,
      imp_ctrl_in_row_3pt_hitch_mode: 'Position',
      imp_ctrl_in_row__hyd_imp_ctrl: '',
      imp_ctrl_in_row_aux_hydraulics_1: 0.0,
      imp_ctrl_in_row_aux_hydraulics_2: 0.0,
      imp_ctrl_in_row_aux_hydraulics_6: 0.0,
      imp_ctrl_in_row_gear: 'L3',
      imp_ctrl_in_row_generic_ctrl_1: false,
      imp_ctrl_in_row_generic_ctrl_2: false,
      imp_ctrl_in_row_generic_ctrl_3: false,
      imp_ctrl_in_row_generic_ctrl_4: false,
      imp_ctrl_in_row_generic_ctrl_5: 0.0,
      imp_ctrl_in_row_generic_ctrl_6: 0.0,
      imp_ctrl_in_row_pto: false,
      imp_ctrl_in_row_pto_rpm: 540.0,
      imp_ctrl_in_row_toplink_pos: 0.0,
      imp_ctrl_in_row_tractor_speed: 2.2,
      imp_ctrl_out_of_row_3pt_hitch_height: 90.0,
      imp_ctrl_out_of_row_3pt_hitch_mode: 'Position',
      imp_ctrl_out_of_row__hyd_imp_ctrl: '',
      imp_ctrl_out_of_row_aux_hydraulics_1: 0.0,
      imp_ctrl_out_of_row_aux_hydraulics_2: 0.0,
      imp_ctrl_out_of_row_aux_hydraulics_6: 0.0,
      imp_ctrl_out_of_row_generic_ctrl_1: false,
      imp_ctrl_out_of_row_generic_ctrl_2: false,
      imp_ctrl_out_of_row_generic_ctrl_3: false,
      imp_ctrl_out_of_row_generic_ctrl_4: false,
      imp_ctrl_out_of_row_generic_ctrl_5: 0.0,
      imp_ctrl_out_of_row_generic_ctrl_6: 0.0,
      imp_ctrl_out_of_row_pto: false,
      imp_ctrl_out_of_row_pto_rpm: 540.0,
      imp_ctrl_out_of_row_toplink_pos: 0.0,
      imp_ctrl_out_of_row_tractor_speed: 2.2,
      imp_ctrl_out_row_gear: 'L3',
      implement_type: 'Seeder',
      is_active: true,
      is_autonomy_full_filled: true,
      is_autonomy_implement: true,
      is_deleted: 0,
      is_implement_info_missing: false,
      length: 69.0,
      modified_by: 0,
      modified_date_time: 1708017307656,
      name: 'Schmeiser 6ft Seeder (4088)',
      organization_id: 20,
      power: '',
      serial_number: '',
      weight: 1895.0,
      width: 76.0,
      x: 0.0,
      y: 0.0,
    },
    {
      attachment_type: '3 PT Hitch',
      config_imm: {
        config_imm_uuid: '327b29aab7f74038b4497f8cd2294f64',
        global_imm_uuid: '06d3acd35ccf4e24998557cc0798b812',
        manufacturer: 'Tierre',
        model: 'Lupo Revers 140',
      },
      config_imm_uuid: '327b29aab7f74038b4497f8cd2294f64',
      config_implements_uuid: '41760777e42048de9536eee6c5f00897',
      created_by: {
        first_name: 'AutomationqhHJcMOABf',
        id: 567,
        last_name: 'AutomationvbIERsxpzA',
      },
      created_date_time: 1665164145812,
      current_tractor: null,
      current_tractor_id: null,
      fleet_id: 18,
      floating_hitch: false,
      height: 33.0,
      id: 189,
      image_url: '',
      imp_ctrl_in_row_3pt_hitch_height: 80.0,
      imp_ctrl_in_row_3pt_hitch_mode: 'Position',
      imp_ctrl_in_row__hyd_imp_ctrl: '',
      imp_ctrl_in_row_aux_hydraulics_1: 4.0,
      imp_ctrl_in_row_aux_hydraulics_2: 50.0,
      imp_ctrl_in_row_aux_hydraulics_6: 50.0,
      imp_ctrl_in_row_gear: 'L1',
      imp_ctrl_in_row_generic_ctrl_1: true,
      imp_ctrl_in_row_generic_ctrl_2: true,
      imp_ctrl_in_row_generic_ctrl_3: true,
      imp_ctrl_in_row_generic_ctrl_4: false,
      imp_ctrl_in_row_generic_ctrl_5: 50.0,
      imp_ctrl_in_row_generic_ctrl_6: 50.0,
      imp_ctrl_in_row_pto: false,
      imp_ctrl_in_row_pto_rpm: 540.0,
      imp_ctrl_in_row_toplink_pos: 75.0,
      imp_ctrl_in_row_tractor_speed: 1.1,
      imp_ctrl_out_of_row_3pt_hitch_height: 100.0,
      imp_ctrl_out_of_row_3pt_hitch_mode: 'Position',
      imp_ctrl_out_of_row__hyd_imp_ctrl: '',
      imp_ctrl_out_of_row_aux_hydraulics_1: 1.0,
      imp_ctrl_out_of_row_aux_hydraulics_2: 1.0,
      imp_ctrl_out_of_row_aux_hydraulics_6: 1.0,
      imp_ctrl_out_of_row_generic_ctrl_1: false,
      imp_ctrl_out_of_row_generic_ctrl_2: false,
      imp_ctrl_out_of_row_generic_ctrl_3: false,
      imp_ctrl_out_of_row_generic_ctrl_4: false,
      imp_ctrl_out_of_row_generic_ctrl_5: 1.0,
      imp_ctrl_out_of_row_generic_ctrl_6: 1.0,
      imp_ctrl_out_of_row_pto: false,
      imp_ctrl_out_of_row_pto_rpm: 540.0,
      imp_ctrl_out_of_row_toplink_pos: 56.0,
      imp_ctrl_out_of_row_tractor_speed: 1.1,
      imp_ctrl_out_row_gear: 'L1',
      implement_type: 'Flail Mower',
      is_active: true,
      is_autonomy_full_filled: true,
      is_autonomy_implement: true,
      is_deleted: 0,
      is_implement_info_missing: false,
      length: 32.0,
      modified_by: 0,
      modified_date_time: 1708062747192,
      name: 'Tierre Lupo Revers 140',
      organization_id: 20,
      power: null,
      serial_number: null,
      weight: 1000.0,
      width: 62.0,
      x: null,
      y: null,
    },
    {
      attachment_type: '3 PT Hitch',
      config_imm: {
        config_imm_uuid: '81e20e8c02d149e3b6d7fc16bd02d336',
        global_imm_uuid: 'a072a48133ce4663885d0c83b857a9c5',
        manufacturer: 'Vrisimo',
        model: 'MM72',
      },
      config_imm_uuid: '81e20e8c02d149e3b6d7fc16bd02d336',
      config_implements_uuid: 'e14e0a6625334b9c82f3c57fe32910e9',
      created_by: {
        first_name: 'AutomationBydcerNiTR',
        id: 568,
        last_name: 'AutomationqtPmYHnTmv',
      },
      created_date_time: 1697043293342,
      current_tractor: null,
      current_tractor_id: 0,
      fleet_id: 18,
      floating_hitch: false,
      height: 0.0,
      id: 200,
      image_url: '',
      imp_ctrl_in_row_3pt_hitch_height: 0.0,
      imp_ctrl_in_row_3pt_hitch_mode: 'Position',
      imp_ctrl_in_row__hyd_imp_ctrl: '',
      imp_ctrl_in_row_aux_hydraulics_1: 0.0,
      imp_ctrl_in_row_aux_hydraulics_2: 0.0,
      imp_ctrl_in_row_aux_hydraulics_6: 0.0,
      imp_ctrl_in_row_gear: 'L1',
      imp_ctrl_in_row_generic_ctrl_1: false,
      imp_ctrl_in_row_generic_ctrl_2: false,
      imp_ctrl_in_row_generic_ctrl_3: false,
      imp_ctrl_in_row_generic_ctrl_4: false,
      imp_ctrl_in_row_generic_ctrl_5: 0.0,
      imp_ctrl_in_row_generic_ctrl_6: 0.0,
      imp_ctrl_in_row_pto: false,
      imp_ctrl_in_row_pto_rpm: 540.0,
      imp_ctrl_in_row_toplink_pos: 0.0,
      imp_ctrl_in_row_tractor_speed: 1.1,
      imp_ctrl_out_of_row_3pt_hitch_height: 0.0,
      imp_ctrl_out_of_row_3pt_hitch_mode: 'Position',
      imp_ctrl_out_of_row__hyd_imp_ctrl: '',
      imp_ctrl_out_of_row_aux_hydraulics_1: 0.0,
      imp_ctrl_out_of_row_aux_hydraulics_2: 0.0,
      imp_ctrl_out_of_row_aux_hydraulics_6: 0.0,
      imp_ctrl_out_of_row_generic_ctrl_1: false,
      imp_ctrl_out_of_row_generic_ctrl_2: false,
      imp_ctrl_out_of_row_generic_ctrl_3: false,
      imp_ctrl_out_of_row_generic_ctrl_4: false,
      imp_ctrl_out_of_row_generic_ctrl_5: 0.0,
      imp_ctrl_out_of_row_generic_ctrl_6: 0.0,
      imp_ctrl_out_of_row_pto: false,
      imp_ctrl_out_of_row_pto_rpm: 540.0,
      imp_ctrl_out_of_row_toplink_pos: 0.0,
      imp_ctrl_out_of_row_tractor_speed: 1.1,
      imp_ctrl_out_row_gear: 'L1',
      implement_type: 'Flail Mower',
      is_active: true,
      is_autonomy_full_filled: true,
      is_autonomy_implement: true,
      is_deleted: 0,
      is_implement_info_missing: false,
      length: 0.0,
      modified_by: 0,
      modified_date_time: 1708017445841,
      name: 'Vrisimo MM72',
      organization_id: 20,
      power: '',
      serial_number: '',
      weight: 490.0,
      width: 81.0,
      x: 0.0,
      y: 0.0,
    },
  ],
};

export const orgImplementsData = {
  records: [
    {
      attachment_type: '3 PT Hitch',
      config_imm: {
        config_imm_uuid: 'f6f345d8cbca44b0ae0cca0dc48d82ee',
        global_imm_uuid: '715b16812e3340d281c495fcab48fdc4',
        manufacturer: 'Bush Hog',
        model: 'BH-215',
      },
      config_imm_uuid: 'f6f345d8cbca44b0ae0cca0dc48d82ee',
      config_implements_uuid: 'f321e97eb8414686bc62425565276728',
      created_by: {
        first_name: 'AutomationqhHJcMOABf',
        id: 567,
        last_name: 'AutomationvbIERsxpzA',
      },
      created_date_time: 1665164296125,
      current_tractor: null,
      current_tractor_id: 0,
      fleet_id: 18,
      floating_hitch: false,
      height: 41.0,
      id: 190,
      image_url: '',
      imp_ctrl_in_row_3pt_hitch_height: 90.0,
      imp_ctrl_in_row_3pt_hitch_mode: 'Position',
      imp_ctrl_in_row__hyd_imp_ctrl: '',
      imp_ctrl_in_row_aux_hydraulics_1: 2.0,
      imp_ctrl_in_row_aux_hydraulics_2: 18.0,
      imp_ctrl_in_row_aux_hydraulics_6: 12.0,
      imp_ctrl_in_row_gear: 'L3',
      imp_ctrl_in_row_generic_ctrl_1: true,
      imp_ctrl_in_row_generic_ctrl_2: true,
      imp_ctrl_in_row_generic_ctrl_3: true,
      imp_ctrl_in_row_generic_ctrl_4: true,
      imp_ctrl_in_row_generic_ctrl_5: 1.0,
      imp_ctrl_in_row_generic_ctrl_6: 1.0,
      imp_ctrl_in_row_pto: false,
      imp_ctrl_in_row_pto_rpm: 540.0,
      imp_ctrl_in_row_toplink_pos: 89.0,
      imp_ctrl_in_row_tractor_speed: 2.2,
      imp_ctrl_out_of_row_3pt_hitch_height: 95.0,
      imp_ctrl_out_of_row_3pt_hitch_mode: 'Position',
      imp_ctrl_out_of_row__hyd_imp_ctrl: '',
      imp_ctrl_out_of_row_aux_hydraulics_1: 1.0,
      imp_ctrl_out_of_row_aux_hydraulics_2: 1.0,
      imp_ctrl_out_of_row_aux_hydraulics_6: 1.0,
      imp_ctrl_out_of_row_generic_ctrl_1: false,
      imp_ctrl_out_of_row_generic_ctrl_2: false,
      imp_ctrl_out_of_row_generic_ctrl_3: false,
      imp_ctrl_out_of_row_generic_ctrl_4: false,
      imp_ctrl_out_of_row_generic_ctrl_5: 1.0,
      imp_ctrl_out_of_row_generic_ctrl_6: 1.0,
      imp_ctrl_out_of_row_pto: false,
      imp_ctrl_out_of_row_pto_rpm: 540.0,
      imp_ctrl_out_of_row_toplink_pos: 1.0,
      imp_ctrl_out_of_row_tractor_speed: 2.2,
      imp_ctrl_out_row_gear: 'L3',
      implement_type: 'Rotary Mower',
      is_active: true,
      is_autonomy_full_filled: true,
      is_autonomy_implement: true,
      is_deleted: 0,
      is_implement_info_missing: false,
      length: 93.0,
      modified_by: 0,
      modified_date_time: 1708017214390,
      name: 'Bush Hog BH-215',
      organization_id: 20,
      power: '',
      serial_number: '',
      weight: 1000.0,
      width: 64.0,
      x: 0.0,
      y: 0.0,
    },
    {
      attachment_type: '3 PT Hitch',
      config_imm: {
        config_imm_uuid: 'bbef5947ceb34436992bd05f632db3b4',
        global_imm_uuid: 'b69ca4d8e0434f0e82fa2899d3acf9bf',
        manufacturer: 'Domries',
        model: 'SN-2324',
      },
      config_imm_uuid: 'bbef5947ceb34436992bd05f632db3b4',
      config_implements_uuid: 'c315c4c6ddbc40e1adf00414237b01d2',
      created_by: {
        first_name: 'AutomationqhHJcMOABf',
        id: 567,
        last_name: 'AutomationvbIERsxpzA',
      },
      created_date_time: 1665437156214,
      current_tractor: null,
      current_tractor_id: 0,
      fleet_id: 18,
      floating_hitch: false,
      height: 47.0,
      id: 191,
      image_url: '',
      imp_ctrl_in_row_3pt_hitch_height: 89.0,
      imp_ctrl_in_row_3pt_hitch_mode: 'Position',
      imp_ctrl_in_row__hyd_imp_ctrl: '',
      imp_ctrl_in_row_aux_hydraulics_1: 0.0,
      imp_ctrl_in_row_aux_hydraulics_2: 0.0,
      imp_ctrl_in_row_aux_hydraulics_6: 0.0,
      imp_ctrl_in_row_gear: 'M1',
      imp_ctrl_in_row_generic_ctrl_1: false,
      imp_ctrl_in_row_generic_ctrl_2: false,
      imp_ctrl_in_row_generic_ctrl_3: false,
      imp_ctrl_in_row_generic_ctrl_4: false,
      imp_ctrl_in_row_generic_ctrl_5: 4.0,
      imp_ctrl_in_row_generic_ctrl_6: 4.0,
      imp_ctrl_in_row_pto: false,
      imp_ctrl_in_row_pto_rpm: 540.0,
      imp_ctrl_in_row_toplink_pos: 10.0,
      imp_ctrl_in_row_tractor_speed: 2.9,
      imp_ctrl_out_of_row_3pt_hitch_height: 89.0,
      imp_ctrl_out_of_row_3pt_hitch_mode: 'Position',
      imp_ctrl_out_of_row__hyd_imp_ctrl: '',
      imp_ctrl_out_of_row_aux_hydraulics_1: 2.0,
      imp_ctrl_out_of_row_aux_hydraulics_2: 9.0,
      imp_ctrl_out_of_row_aux_hydraulics_6: 6.0,
      imp_ctrl_out_of_row_generic_ctrl_1: false,
      imp_ctrl_out_of_row_generic_ctrl_2: false,
      imp_ctrl_out_of_row_generic_ctrl_3: false,
      imp_ctrl_out_of_row_generic_ctrl_4: false,
      imp_ctrl_out_of_row_generic_ctrl_5: 6.0,
      imp_ctrl_out_of_row_generic_ctrl_6: 6.0,
      imp_ctrl_out_of_row_pto: false,
      imp_ctrl_out_of_row_pto_rpm: 1.0,
      imp_ctrl_out_of_row_toplink_pos: 6.0,
      imp_ctrl_out_of_row_tractor_speed: 2.9,
      imp_ctrl_out_row_gear: 'M1',
      implement_type: 'Disc',
      is_active: true,
      is_autonomy_full_filled: true,
      is_autonomy_implement: true,
      is_deleted: 0,
      is_implement_info_missing: false,
      length: 68.0,
      modified_by: 0,
      modified_date_time: 1708017225548,
      name: 'Domries Disc',
      organization_id: 20,
      power: '',
      serial_number: '',
      weight: 1400.0,
      width: 50.0,
      x: 0.0,
      y: 0.0,
    },
    {
      attachment_type: '3 PT Hitch',
      config_imm: {
        config_imm_uuid: '46ff1c32763c4000a4127043770db6ae',
        global_imm_uuid: 'd71255d939df4f60b5f7f515bf2f8a23',
        manufacturer: 'Land Pride',
        model: 'BB1272',
      },
      config_imm_uuid: '46ff1c32763c4000a4127043770db6ae',
      config_implements_uuid: 'd195f30c43534a7bbd2e7d26409207dd',
      created_by: {
        first_name: 'AutomationBydcerNiTR',
        id: 568,
        last_name: 'AutomationqtPmYHnTmv',
      },
      created_date_time: 1697043707918,
      current_tractor: null,
      current_tractor_id: 0,
      fleet_id: 18,
      floating_hitch: false,
      height: 33.0,
      id: 203,
      image_url: '',
      imp_ctrl_in_row_3pt_hitch_height: 0.0,
      imp_ctrl_in_row_3pt_hitch_mode: 'Position',
      imp_ctrl_in_row__hyd_imp_ctrl: '',
      imp_ctrl_in_row_aux_hydraulics_1: 0.0,
      imp_ctrl_in_row_aux_hydraulics_2: 0.0,
      imp_ctrl_in_row_aux_hydraulics_6: 0.0,
      imp_ctrl_in_row_gear: 'L1',
      imp_ctrl_in_row_generic_ctrl_1: false,
      imp_ctrl_in_row_generic_ctrl_2: false,
      imp_ctrl_in_row_generic_ctrl_3: false,
      imp_ctrl_in_row_generic_ctrl_4: false,
      imp_ctrl_in_row_generic_ctrl_5: 0.0,
      imp_ctrl_in_row_generic_ctrl_6: 0.0,
      imp_ctrl_in_row_pto: false,
      imp_ctrl_in_row_pto_rpm: 540.0,
      imp_ctrl_in_row_toplink_pos: 0.0,
      imp_ctrl_in_row_tractor_speed: 1.1,
      imp_ctrl_out_of_row_3pt_hitch_height: 0.0,
      imp_ctrl_out_of_row_3pt_hitch_mode: 'Position',
      imp_ctrl_out_of_row__hyd_imp_ctrl: '',
      imp_ctrl_out_of_row_aux_hydraulics_1: 0.0,
      imp_ctrl_out_of_row_aux_hydraulics_2: 0.0,
      imp_ctrl_out_of_row_aux_hydraulics_6: 0.0,
      imp_ctrl_out_of_row_generic_ctrl_1: false,
      imp_ctrl_out_of_row_generic_ctrl_2: false,
      imp_ctrl_out_of_row_generic_ctrl_3: false,
      imp_ctrl_out_of_row_generic_ctrl_4: false,
      imp_ctrl_out_of_row_generic_ctrl_5: 0.0,
      imp_ctrl_out_of_row_generic_ctrl_6: 0.0,
      imp_ctrl_out_of_row_pto: false,
      imp_ctrl_out_of_row_pto_rpm: 540.0,
      imp_ctrl_out_of_row_toplink_pos: 0.0,
      imp_ctrl_out_of_row_tractor_speed: 1.1,
      imp_ctrl_out_row_gear: 'L1',
      implement_type: 'Box Blade',
      is_active: true,
      is_autonomy_full_filled: true,
      is_autonomy_implement: true,
      is_deleted: 0,
      is_implement_info_missing: false,
      length: 32.0,
      modified_by: 0,
      modified_date_time: 1708017422857,
      name: 'Land Pride Box Scraper',
      organization_id: 20,
      power: '',
      serial_number: '',
      weight: 404.0,
      width: 72.0,
      x: 0.0,
      y: 0.0,
    },
  ],
};
