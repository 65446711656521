import { notification, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import translate from '../../../../locale/en_translate.json';
import CInput from '../../../common/CInput';
import { addChapter } from '../../API';

interface Props {
  showModel: boolean;
  // eslint-disable-next-line no-unused-vars
  close: (state?: boolean) => void;
  chapter?: any;
}

const DuplicateChapter: React.FC<Props> = ({ showModel, close, chapter }) => {
  const { t } = useTranslation();
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [title, setTitle] = useState<string>('');
  const [errors, setErrors] = useState({
    title: true,
  });

  useEffect(() => {
    setIsDisabled(!Object.values(errors).every((item) => !item));
  }, [errors]);

  const handleChapterMethod = async () => {
    try {
      const resp = await addChapter({
        title,
        is_duplicate: true,
        source_chapter_id: chapter.id,
      });

      notification.success({
        message: resp.msg,
        duration: 2,
      });
      close(true);
    } catch (err: any) {
      notification.error({
        message: err?.message,
      });
    }
  };

  return (
    <Modal
      title={t(translate.training.addDuplicateManual)}
      visible={showModel}
      className="customModal"
      onCancel={() => close()}
      okText="Add"
      onOk={handleChapterMethod}
      okButtonProps={{
        className: 'ant-btn-primary',
        disabled: isDisabled,
      }}
      cancelButtonProps={{
        className: 'btnCancel',
      }}
    >
      <div className="">
        <div className="ant-row ant-form-item">
          <div className="ant-col ant-form-item-label">
            <label>
              {t(translate.howToManual.title)}{' '}
              <span style={{ color: 'red' }}> *</span>
            </label>
          </div>
          <div className="ant-col ant-form-item-control">
            <div className="ant-form-item-control-input">
              <div className="ant-form-item-control-input-content input-align">
                <CInput
                  max={60}
                  min={3}
                  value={title}
                  name="title"
                  className="input"
                  onChange={(value: string) => setTitle(value)}
                  hasError={(state) => setErrors({ ...errors, title: state })}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DuplicateChapter;
