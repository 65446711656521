import { create } from 'zustand';

// define types for state values and actions separately
type State = {
  selectedDateRange: any[];
  analyticsFilter: {
    tractorIds: string[];
    operatorIds: string[];
    operationTypes: string[];
  };
};

type Actions = {
  setSelectedDateRange: (date: any[]) => void;
  setAnalyticsFilter: (state: any) => void;
  reset: () => void;
};

// define the initial state
const initialState: State = {
  selectedDateRange: [],
  analyticsFilter: {
    tractorIds: [],
    operatorIds: [],
    operationTypes: [],
  },
};

const useOperationalAnalyticsSearchStore = create<State & Actions>()(
  (set, get) => ({
    ...initialState,
    setSelectedDateRange: (selectedDateRange) =>
      set((state) => ({
        selectedDateRange,
      })),
    setAnalyticsFilter: ({ tractorIds, operationTypes, operatorIds }) =>
      set((state) => ({
        analyticsFilter: {
          tractorIds,
          operatorIds,
          operationTypes,
        },
      })),
    reset: () => {
      set(initialState);
    },
  }),
);

export default useOperationalAnalyticsSearchStore;
