/* eslint-disable react/jsx-key */
import React, { useContext, useEffect, useState } from 'react';
import { Modal, Button, notification, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import translate from '../../../../locale/en_translate.json';
import { ApplicationContext } from '../../../../context/AppContext';
import './style.css';
import { getLogConvertStatus } from '../../API';

interface Props {
  showModal: boolean;
  closeModal: () => void;
  transacationId: number | undefined;
}

const BasestationLogViewerConvertStatus: React.FC<Props> = ({
  showModal,
  closeModal,
  transacationId,
}: Props) => {
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const [loader, setLoader] = useState<boolean>(false);
  const [statusDetails, setStatusDetails] = useState<any>();

  useEffect(() => {
    if (userDetails && userDetails.organization && transacationId) {
      convertStatus();
    }
  }, [userDetails]);

  const convertStatus = async () => {
    try {
      setLoader(true);
      if (transacationId) {
        const data = await getLogConvertStatus(transacationId);
        setStatusDetails(data);
      }
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    } finally {
      setLoader(false);
    }
  };

  return (
    <Modal
      className="tm-modal customModal"
      title={t(translate.modal.convertStatus)}
      centered
      visible={showModal}
      onCancel={closeModal}
      footer={[
        <Button onClick={closeModal} className="btnCancel">
          {t(translate.buttons.cancel)}
        </Button>,
      ]}
    >
      <div className="col2">
        <div className="col2-styles statusValue" style={{ margin: 0 }}>
          <div className="orgDetails">
            <p>BLF Files Count :</p>
          </div>
          <div className="orgData valueRight">
            <p>{statusDetails?.blf_conversion_data?.blf_files_count ?? '-'}</p>
          </div>
        </div>

        <div className="col2-styles statusValue" style={{ margin: 0 }}>
          <div className="orgDetails">
            <p>Conversion Completed :</p>
          </div>
          <div className="orgData valueRight">
            <p>
              {statusDetails?.blf_conversion_data?.conversion_completed_count ??
                '-'}
            </p>
          </div>
        </div>

        <div className="col2-styles statusValue" style={{ margin: 0 }}>
          <div className="orgDetails">
            <p>Conversion Failed :</p>
          </div>
          <div className="orgData valueRight">
            <p>
              {statusDetails?.blf_conversion_data?.conversion_failed_count ??
                '-'}
            </p>
          </div>
        </div>

        <div className="col2-styles statusValue" style={{ margin: 0 }}>
          <div className="orgDetails">
            <p>Conversion In Progress :</p>
          </div>
          <div className="orgData valueRight">
            <p>
              {statusDetails?.blf_conversion_data
                ?.conversion_in_progress_count ?? '-'}
            </p>
          </div>
        </div>

        <div className="col2-styles statusValue" style={{ margin: 0 }}>
          <div className="orgDetails">
            <p>Conversion Pending :</p>
          </div>
          <div className="orgData valueRight">
            <p>
              {statusDetails?.blf_conversion_data?.conversion_pending_count ??
                '-'}
            </p>
          </div>
        </div>

        <div className="col2-styles statusValue" style={{ margin: 0 }}>
          <div className="orgDetails">
            <p>Conversion Failed remarks :</p>
          </div>
          <div className="orgData valueRight">
            {statusDetails?.blf_conversion_data?.conversion_failed_remarks?.map(
              (item: string) => (
                <p>{item ?? '-'}</p>
              ),
            )}
          </div>
        </div>
      </div>
      <div className="edit-loader">
        {loader && (
          <div className="loader1">
            <div className="loader1">
              <Spin size="large" />
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};
export default BasestationLogViewerConvertStatus;
