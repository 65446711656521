import React, { useContext, useState } from 'react';
import { ApplicationContext } from '../../../context/AppContext';
import { Tabs } from 'antd';
import UsageDashboardReport from './UsageDashboardReport';
import AutoDriveReport from './AutoDriveReport';

const { TabPane } = Tabs;

const UsageDashboardTabs = () => {
  const { APPReducer } = useContext(ApplicationContext);
  const [, dispatch] = APPReducer;
  const [tabid, setTabid] = useState<string>('1');

  return (
    <Tabs
      activeKey={tabid}
      centered
      id="org-tab-Organization"
      className="inventoryTabs"
      onChange={(key) => {
        setTabid(key);
      }}
    >
      <TabPane
        tab="Usage DashBoard"
        key="1"
        className="attTableHeight mBottom0"
      >
        {tabid === '1' && <UsageDashboardReport />}
      </TabPane>
      <TabPane
        tab="AutoDrive Performance Dashboard"
        key="2"
        className="attTableHeight mBottom0"
      >
        {tabid === '2' && <AutoDriveReport />}
      </TabPane>
    </Tabs>
  );
};

export default UsageDashboardTabs;
