import React, { useState } from 'react';
import { Input } from 'antd';

const { TextArea } = Input;

interface Props {
  label?: string;
  value?: any;
  setValue: (data: any) => void;
  required?: boolean;
  description?: any;
  descriptionCount?: any;
  dataTestid?: any;
}

const CustomTextArea: React.FC<Props> = ({
  label,
  value,
  setValue,
  required,
  description,
  descriptionCount,
  dataTestid,
  ...props
}: Props) => {
  const [focus, setFocus] = useState(false);

  const labelClass =
    focus || (value && value.length !== 0) ? 'label label-float' : 'label';

  return (
    <div
      className="float-label"
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      <TextArea
        className={`ticketArea ${
          descriptionCount >= 1 && descriptionCount < 3 ? 'newTicket' : ''
        }`}
        autoSize={{ minRows: 5, maxRows: 8 }}
        defaultValue={value}
        value={value}
        data-testid={dataTestid}
        maxLength={500}
        onChange={(e: any) => setValue(e.target.value)}
        {...props}
      />
      <span
        style={{
          color:
            descriptionCount >= 1 && descriptionCount < 3 ? '#ff0000d1' : '',
        }}
        className="descCount"
      >
        {description?.length === undefined ||
        description?.length === null ||
        description?.length === ''
          ? 0
          : description?.length}{' '}
        / 500
      </span>

      <label className={labelClass}>
        {label} {required && <span className="float-span">*</span>}
      </label>
    </div>
  );
};

export default CustomTextArea;
