import { useEffect, useState, useContext } from 'react';
import { getGuraudrailsCamera, getSnapshot } from './Api';
import { betaCams, sortCameraViews } from './Common';
import { ApplicationContext } from '../context/AppContext';
import { SnapShot } from './types';
import { notification } from 'antd';
const GUARD_RAILS = 'GUARD_RAILS';

export const useCameraSnap = (guardrails: any) => {
  const [data, setData] = useState<any>(null);
  const { userDetails } = useContext(ApplicationContext);
  const { guard_rail_triggered_uuid, created_at } = guardrails;

  useEffect(() => {
    const initGuardrailsCamera = async () => {
      try {
        const { organization } = userDetails;
        const data = await getGuraudrailsCamera(
          organization.api_url,
          organization.id,
          guard_rail_triggered_uuid,
        );
        setData(data);
      } catch (error: any) {
        notification.error({ message: error.message });
      }
    };
    if (created_at === GUARD_RAILS) initGuardrailsCamera();
  }, [guard_rail_triggered_uuid]);

  return [data];
};

export const useSnap = (transactionId: string, tractorId: string) => {
  const [snapshot, setSnapShots] = useState<any>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const { userDetails } = useContext(ApplicationContext);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    const init = async () => {
      try {
        setLoader(true);
        let response = await getSnapshot(
          userDetails.organization.api_url,
          transactionId,
          tractorId,
        );
        response = response.reduce((a: any, c: any) => {
          a[c.camera_name] = c;
          return a;
        }, {});
        const images: SnapShot[] = Object.keys(betaCams)
          .map((cam) => {
            const item = response[cam];
            const url = item?.camera_image_url || '';
            return {
              camera_image_url: url,
              title: betaCams[cam],
              camera_name: cam,
            };
          })
          .filter((item) => item.camera_image_url);
        const result = sortCameraViews(images, 'camera_name', 'images');
        setSnapShots(result);
      } catch (error: any) {
        notification.error({ message: error.message });
      } finally {
        setLoader(false);
      }
    };
    if (tractorId) init();
  }, [transactionId, tractorId, refresh]);

  const handleRefresh = () => {
    setRefresh(!refresh);
  };

  return [snapshot, loader, handleRefresh];
};
