import React, { useState, useContext, useEffect, useRef } from 'react';
import { notification, Avatar, Spin, Radio, Modal, Result, Empty } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ApplicationContext } from '../../context/AppContext';
import {
  acknowledgeNotifications,
  getNotificationsList,
} from '../../constants/Api';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import 'moment-duration-format';
import translate from '../../locale/en_translate.json';
import flag_red from '../../assets/images/flag_red.svg';
import nfIcon from '../../assets/images/nfIcon.svg';
import nf_flag2 from '../../assets/images/nf_flag2.svg';
import nfTractor from '../../assets/images/nfTractor.svg';
import loading_logo from '../../assets/images/loading_logo.gif';
import ack_done from '../../assets/images/ack_done.svg';
import ack_wait from '../../assets/images/ack_wait.svg';
import { useHistory } from 'react-router-dom';
import RoutesConstants from '../../routes/RoutesConstant';
import { getUserName } from '../../constants/Common';
import NotificationView from './NotificationView';
import { SET_NAVIGATE } from '../../context/actions';
import './notification.css';

const Notification: React.FC = () => {
  const { t } = useTranslation();
  const { goBack, push } = useHistory();
  const [notifications, setNotifications] = useState<any>([]);
  const [notificationsMap, setNotificationsMap] = useState<any>(null);
  const { userDetails, APPReducer } = useContext(ApplicationContext);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPagesize] = useState<any>(25);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [loader, setLoader] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [notificationData, setNotificationData] = useState<any>();
  const [notificationType, setNotificationType] = useState<string>('all');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const initalize = useRef<boolean>(false);
  const [, dispatch] = APPReducer;

  const showModal = () => {
    setIsModalOpen(true);
  };

  // const handleOk = () => {
  //   setIsModalOpen(false);
  // };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onClose = () => {
    //   setVisible(true);
  };

  const resetData = () => {
    setNotifications([]);
    setNotificationsMap(null);
  };

  useEffect(() => {
    return () => {
      resetData();
    };
  }, []);

  const getNotificationList = async () => {
    try {
      setLoader(true);
      const result: { records: any[] } = await getNotificationsList(
        userDetails.organization.api_url,
        userDetails.id,
        notificationType,
        pageNumber,
        pageSize,
      );
      if (!initalize.current) initalize.current = true;

      const data =
        result && result.records && result.records.length > 0
          ? result.records
          : [];
      if (data.length === 0) {
        if (pageNumber === 1) setNotifications([]);
        setHasMore(false);
      } else {
        if (pageNumber === 1) setNotifications(data);
        else setNotifications([...notifications, ...data]);
        setHasMore(true);
      }
      setLoader(false);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      getNotificationList();
    }
  }, [userDetails, pageNumber, refresh]);

  useEffect(() => {
    if (pageNumber > 1) {
      setPageNumber(1);
    } else if (pageNumber === 1 && initalize.current) {
      getNotificationList();
    }
  }, [notificationType]);

  const handleLoadMore = () => {
    setPageNumber(pageNumber + 1);
  };

  const profileImg = (userDetails: any) => (
    <Avatar
      style={{
        width: '34px',
        height: '34px',
        backgroundColor: '#000',
        color: 'white',
        fontFamily: 'Montserrat-Bold',
      }}
      src={userDetails?.profile_url}
    >
      {userDetails?.first_name?.charAt(0)}
      {userDetails?.last_name?.charAt(0)}
    </Avatar>
  );

  const getIcon = (notification: any) => {
    const { event_category, event_level } = notification;
    return (
      <>
        <div className="nfTopIcon">
          <img
            className=""
            src={event_category === 'Fleet_Ticket' ? nfIcon : nfTractor}
          />
        </div>
        <div className="nfBottomIcon">
          {event_category === 'Indicator' ? (
            <>
              <img
                className="flagIcon"
                src={event_level === 3 ? flag_red : nf_flag2}
              />
              {event_level}
            </>
          ) : (
            profileImg(userDetails)
          )}
        </div>
      </>
    );
  };

  const handleAcknowledgeMessage = async (notification: any) => {
    try {
      const { notification_code, tractor } = notification;
      if (notification_code === 500006 || notification_code === 500007) {
        await acknowledgeNotifications(
          userDetails.organization.api_url,
          userDetails.id,
          {
            notification_code: notification_code,
            tractor_id: tractor?.id,
          },
        );
        resetData();
        setRefresh(!refresh);
      }
    } catch (error) {
      // acknowledged
    }
  };

  const handleClickNotification = (notification: any) => {
    const { event_category } = notification;
    localStorage.setItem('lastTabIndex', 'null');
    if (event_category === 'Indicator' && notification.tractor)
      dispatch({
        type: SET_NAVIGATE,
        payload: {
          pathname: RoutesConstants.FleetHealth,
          data: notification,
          meta: {
            action: 'INDICATOR',
          },
        },
      });
    if (event_category === 'Fleet_Ticket' && notification.user)
      dispatch({
        type: SET_NAVIGATE,
        payload: {
          pathname: RoutesConstants.FleetHealth,
          data: notification,
          meta: {
            action: 'TICKET',
          },
        },
      });
    if (event_category === 'Software Release')
      push({
        pathname: RoutesConstants.FleetHealth,
        state: {
          action: 'SOFTWARE',
        },
      });
    if (
      !['INDICATOR', 'Fleet_Ticket', 'Software Release'].includes(
        event_category,
      )
    ) {
      setNotificationData(notification);
      if (event_category === 'guard_rails') {
        setIsModalOpen1(true);
      }
      if (event_category !== 'guard_rails') showModal();
    }
  };

  const formatNotificatons = () => {
    let tdata = [...notifications];
    // if (notificationType !== 'all')
    // tdata = tdata.filter((item) => item.event_category === notificationType);
    tdata = tdata.reduce((a, notification) => {
      const diff = moment()
        .endOf('day')
        .diff(moment(notification.event_date_time), 'hours');
      const formatDate = moment(notification.event_date_time).format('llll');
      const day =
        diff < 24
          ? 'today'
          : diff >= 24 && diff <= 48
          ? 'yesterday'
          : formatDate.substring(formatDate.lastIndexOf(','), 0);
      if (Array.isArray(a[day])) {
        a[day].push(notification);
      } else {
        a[day] = [];
        a[day].push(notification);
      }
      return a;
    }, {});
    setNotificationsMap(tdata);
  };

  useEffect(() => {
    formatNotificatons();
  }, [notifications]);

  return (
    <>
      <div className="rowGapNf40">
        <div className="notification-filter">
          <Radio.Group
            value={notificationType}
            onChange={(e) => setNotificationType(e.target.value)}
            className="overline2"
          >
            <Radio.Button
              data-testid="notificationAll-Notification"
              value="all"
            >
              All
            </Radio.Button>
            <Radio.Button
              data-testid="guardrailsTab-Notification"
              value="guard_rails"
            >
              {t(translate.label.guardrails)}
            </Radio.Button>
            <Radio.Button
              data-testid="indicatorTab-Notification"
              value="Indicator"
            >
              {' '}
              {t(translate.label.indicators)}
            </Radio.Button>
            <Radio.Button
              data-testid="ticketsTab-Notification"
              value="Fleet_Ticket"
            >
              {' '}
              {t(translate.label.tickets)}
            </Radio.Button>
          </Radio.Group>
        </div>
        <div className="notificationEdit">
          <InfiniteScroll
            scrollThreshold={0.95}
            height={window.innerHeight - 240}
            dataLength={notifications.length} //This is important field to render the next data
            next={() => handleLoadMore()}
            hasMore={hasMore}
            loader={
              <></>
              // <div style={{ width: '100%', textAlign: 'center' }}>
              //   <p>Loading...</p>
              // </div>
            }
          >
            <div className="notificationContainer">
              {notificationsMap &&
                Object.keys(notificationsMap)
                  .sort((a: any, b: any) => {
                    return b.event_date_time - a.event_date_time;
                  })
                  .map((item, ky: number) => {
                    const view = notificationsMap[item].map(
                      (notification: any, key: number) => {
                        return (
                          <ul className="nfUL nfToday" key={key}>
                            <li
                              className={`plr38 ptb16 ${
                                item === 'today' ? 'active' : ''
                              }`}
                              onClick={(e) => {
                                handleClickNotification(notification);
                              }}
                            >
                              <div className="nfItem">
                                <div className="nfIcon posRel">
                                  {getIcon(notification)}
                                </div>
                                <div className="nfContent posRel">
                                  <p
                                    className="notificationMsg body2"
                                    data-testid="notificationMessage-Notification"
                                  >
                                    {notification.message}
                                  </p>
                                  <h6 className="nfTime body4">
                                    {ky === 0
                                      ? moment(
                                          notification.event_date_time,
                                        ).fromNow()
                                      : moment(
                                          notification.event_date_time,
                                        ).format('HH:mm a')}
                                  </h6>
                                  {(notification.notification_code === 500006 ||
                                    notification.notification_code ===
                                      500007) &&
                                    notification.is_acknowledged &&
                                    notification.acknowledge_by && (
                                      <h6 className="nfTime body4">
                                        Acknowledged:{' '}
                                        <span className="ackByTxt">
                                          {getUserName(
                                            notification.acknowledge_by,
                                          )}
                                        </span>
                                      </h6>
                                    )}
                                  {(notification.notification_code === 500006 ||
                                    notification.notification_code ===
                                      500007) &&
                                    (!notification.is_acknowledged ? (
                                      <div
                                        style={{
                                          color: '#366376',
                                          textAlign: 'right',
                                          fontWeight: 'bolder',
                                        }}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleAcknowledgeMessage(
                                            notification,
                                          );
                                        }}
                                      >
                                        {' '}
                                        <img
                                          className="ack"
                                          src={ack_done}
                                        />{' '}
                                      </div>
                                    ) : (
                                      <div
                                        style={{
                                          color: '#366376',
                                          textAlign: 'right',
                                          fontWeight: 'bolder',
                                        }}
                                      >
                                        {' '}
                                        <img
                                          className="ack"
                                          src={ack_wait}
                                        />{' '}
                                      </div>
                                    ))}
                                </div>
                              </div>
                            </li>
                          </ul>
                        );
                      },
                    );
                    return (
                      <>
                        <div className="notificationDayWise mb32" key={ky}>
                          <h4 className="notificationDate notificationDate1 headline3">
                            {item}
                          </h4>
                          {view}
                        </div>
                      </>
                    );
                  })}
            </div>
          </InfiniteScroll>
          <div className="notiLoader">
            {loader && (
              <div className="loader">
                <Spin
                  size="large"
                  className="loader"
                  spinning={loader}
                  indicator={
                    <img
                      style={{
                        width: '40px',
                        height: '40px',
                        marginTop: '280px',
                      }}
                      src={loading_logo}
                    />
                  }
                />
              </div>
            )}
            {notificationsMap !== null &&
              !loader &&
              Object.keys(notificationsMap).length === 0 && (
                <div className="no_notification">
                  <div
                    className="overline1"
                    data-testid="noNotificationAvailable-Notification"
                  >
                    No notifications available
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>

      {isModalOpen1 && (
        <NotificationView
          data={notificationData}
          close={() => setIsModalOpen1(false)}
        />
      )}
      {isModalOpen && (
        <Modal
          className="modal-theme-1"
          title={notificationData?.title}
          visible={isModalOpen}
          onCancel={handleCancel}
          footer={false}
        >
          {notificationData?.message}
        </Modal>
      )}
    </>
  );
};

export default Notification;
