/* eslint-disable no-console */
import React, { memo, useContext } from 'react';
import { RemoteDriveAppCtx } from '../remote_drive_new/RemoteDriveContext';

import { Button, Tabs } from 'antd';
import connecting_generating_path from '../../assets/images/connecting_generating_path.gif';
import select_block from '../../assets/images/select_block.svg';

import { LS_ROUTINE } from '../../constants/Common';
import {
  AutoDriveTabs,
  dataSource,
  defaultSetUpKeys,
} from '../../constants/constant';
import CustomSelect from '../common/CustomSelect';
import DriveOptionSettings from '../common/DriveOptionSettings';
import {
  AUTO_DRIVE_ERROR,
  SET_ACTIVE_AUTODRIVE_TAB,
  // SET_SELECTED_IMPLEMENT_WIDTH,
  SET_SETUP_KEYS,
  SET_TRIGGER_TO_STARTING_POINT,
  SHOW_TRACTOR_COMMAND_LOADER,
} from '../remote_drive_new/actions';
import './style.css';

const { TabPane } = Tabs;

interface Props {
  isValid: boolean;
  setIsValid: (value: boolean) => void;
  selectedImplement: string;
  implement: any;
  setImplement: (value: any) => void;
  addRowFollowRVCommand: () => void;
}
const GoToBlock: React.FC<Props> = ({
  isValid,
  setIsValid,
  selectedImplement,
  implement,
  setImplement,
  addRowFollowRVCommand,
}: Props) => {
  const { RDReducer, commandsList, addImplementSettings } =
    useContext(RemoteDriveAppCtx);
  const [state, dispatch] = RDReducer;
  const {
    selectedTractor,
    activeAutoDriveTab,
    setUpKeys,
    showTractorCommandLoader,
  } = state;

  const { addRVRoutineCommand } = useContext(RemoteDriveAppCtx);
  const handleDefaultParamsSave = () => {
    const routine: string | null = localStorage.getItem(LS_ROUTINE);
    const routineContent: any = routine && JSON.parse(routine);
    return (
      <>
        <Button
          className="Button automatedBtn"
          onClick={() => {
            if (isValid) {
              if (
                (routineContent && routineContent.incomplete_operation_uuid) ||
                (routineContent && routineContent.id)
              ) {
                addImplementSettings(implement, false);
                addRVRoutineCommand();
                showTractorCommandLoader[selectedTractor.id] = true;
                dispatch({
                  type: SHOW_TRACTOR_COMMAND_LOADER,
                  payload: {
                    ...showTractorCommandLoader,
                  },
                });
                dispatch({
                  type: SET_SETUP_KEYS,
                  payload: {
                    ...setUpKeys,
                    isBlockSelected: false,
                  },
                });
              } else {
                addImplementSettings(implement, true);
                dispatch({
                  type: SET_SETUP_KEYS,
                  payload: {
                    ...setUpKeys,
                    isBlockSelected: false,
                  },
                });
              }
            }
          }}
          disabled={!isValid}
        >
          {(routineContent && routineContent.incomplete_operation_uuid) ||
          (routineContent && routineContent.id)
            ? 'NEXT'
            : 'SAVE'}
        </Button>
      </>
    );
  };

  return (
    <>
      <Tabs
        activeKey={activeAutoDriveTab}
        onChange={(key) => {
          dispatch({
            type: SET_ACTIVE_AUTODRIVE_TAB,
            payload: key,
          });
          // after clicking new command clear the auto_drive error
          dispatch({
            type: AUTO_DRIVE_ERROR,
            payload: {
              tractor_id: selectedTractor.id,
              message: '',
              errors: [],
            },
          });
        }}
        className="autodriveTabs"
      >
        <TabPane className="sel" tab="PARAMETERS" key={AutoDriveTabs.Parameter}>
          <div className="tabsContainer">
            <div className="autoDriveScroll">
              <div className="blockTxt automatedTitle mb20">
                <p>
                  Set Operation <br /> Parameters
                </p>
              </div>
              <div className="sop_sec">
                <DriveOptionSettings
                  handleDetails={(details?: any) => {
                    // dispatch({
                    //   type: SET_SELECTED_IMPLEMENT_WIDTH,
                    //   payload: details.width,
                    // });
                    setImplement(details);
                  }}
                  id={selectedImplement}
                  tractorId={selectedTractor.id}
                  driveActionUUID={
                    selectedTractor?.heartbeat.drive_action_details
                      ?.current_drive_action as string
                  }
                  handleValidate={(state: boolean) => {
                    setIsValid(state);
                  }}
                />
              </div>
            </div>
            {handleDefaultParamsSave()}
          </div>
        </TabPane>
        {activeAutoDriveTab !== AutoDriveTabs.Parameter && (
          <>
            <TabPane className="sel" tab="BLOCK" key={AutoDriveTabs.Block}>
              <div className="tabsContainer">
                <div className="automatedSelectBlock">
                  <div className="blockTxt automatedTitle">
                    <p>Select Block</p>
                  </div>
                  <h3 className="body1 automatedPara">
                    Click on the desired block on the map for your operation
                  </h3>
                  <img src={select_block} alt="" />
                  <Button
                    className="Button automatedBtn"
                    disabled={!setUpKeys.isBlockSelected}
                    onClick={() => {
                      dispatch({
                        type: SET_TRIGGER_TO_STARTING_POINT,
                        payload: true,
                      });
                    }}
                  >
                    NEXT
                  </Button>
                </div>
              </div>
            </TabPane>
            {activeAutoDriveTab !== AutoDriveTabs.Block && (
              <TabPane className="sel" tab="PATH" key={AutoDriveTabs.PATH}>
                {!(
                  commandsList &&
                  commandsList[selectedTractor.id] &&
                  commandsList[selectedTractor.id].length > 0
                ) && (
                  <>
                    {(!setUpKeys.isFirstTime ||
                      (setUpKeys.isFirstTime && !setUpKeys.isLastTime)) && (
                      <div className="tabsContainer pathBlk">
                        <div>
                          {!setUpKeys.isFirstTime && (
                            <div>
                              <div className="blockTxt automatedTitle mb20">
                                <p>Select First Row</p>
                              </div>
                              <p className="body1 automatedPara">
                                Click on the starting point on the map for the
                                first row of your operation.
                              </p>
                              <p className="body1 automatedPara">
                                Ensure the front of your tractor is positioned
                                just inside the beginning of the first row you
                                select.
                              </p>
                              <Button
                                className="Button automatedBtn"
                                disabled={
                                  setUpKeys.startWith === '' ? true : false
                                }
                                onClick={() => {
                                  dispatch({
                                    type: SET_SETUP_KEYS,
                                    payload: {
                                      ...setUpKeys,
                                      isFirstTime: true,
                                    },
                                  });
                                }}
                              >
                                NEXT
                              </Button>
                            </div>
                          )}

                          {setUpKeys.isFirstTime && !setUpKeys.isLastTime && (
                            <div>
                              <div className="blockTxt automatedTitle">
                                <p>Select Last Row & Frequency</p>
                              </div>
                              <p className="body1 automatedPara">
                                Click the row on the map which you’d like to be
                                the last row of this operation.
                              </p>
                              <p className="body1 automatedPara">
                                By default, the operation will happen in every
                                fourth row.
                              </p>
                              <div className="frequencyField">
                                <CustomSelect
                                  label="Frequency"
                                  value={setUpKeys.everyFilter}
                                  options={dataSource}
                                  setValue={(value) => {
                                    dispatch({
                                      type: SET_SETUP_KEYS,
                                      payload: {
                                        everyFilter: value,
                                      },
                                    });
                                  }}
                                  optionKey="id"
                                  optionDisplay="name"
                                ></CustomSelect>
                              </div>
                              <Button
                                className="Button automatedBtn"
                                disabled={
                                  setUpKeys.endIndexUuid === '' ? true : false
                                }
                                onClick={() => {
                                  dispatch({
                                    type: SET_SETUP_KEYS,
                                    payload: {
                                      ...setUpKeys,
                                      isLastTime: true,
                                    },
                                  });

                                  addRowFollowRVCommand();
                                  showTractorCommandLoader[selectedTractor.id] =
                                    true;
                                  dispatch({
                                    type: SHOW_TRACTOR_COMMAND_LOADER,
                                    payload: {
                                      ...showTractorCommandLoader,
                                    },
                                  });
                                }}
                              >
                                CREATE PATH
                              </Button>

                              <div
                                className="reselectBtn"
                                onClick={() => {
                                  // changing the tab
                                  dispatch({
                                    type: SET_ACTIVE_AUTODRIVE_TAB,
                                    payload: AutoDriveTabs.Block,
                                  });
                                  setTimeout(() => {
                                    // selecting previous poly
                                    dispatch({
                                      type: SET_SETUP_KEYS,
                                      payload: {
                                        ...defaultSetUpKeys,
                                        mapId: setUpKeys.mapId,
                                        laneFilterContent:
                                          setUpKeys.laneFilterContent,
                                        isBlockSelected: true,
                                        polygonUuid: setUpKeys.polygonUuid,
                                      },
                                    });
                                    // selecting previous poly
                                    dispatch({
                                      type: SET_TRIGGER_TO_STARTING_POINT,
                                      payload: true,
                                    });
                                  }, 100);
                                }}
                              >
                                RESELECT ENTRY POINT
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </>
                )}
                {showTractorCommandLoader[selectedTractor.id] && (
                  <div className="opertaionReviewBlock tabsContainer">
                    <div className="connectAutoDriveLoader">
                      <div className="loader">
                        <img src={connecting_generating_path} />
                      </div>
                      <div className="createPath">Creating Path</div>
                    </div>
                  </div>
                )}
              </TabPane>
            )}
          </>
        )}
      </Tabs>
    </>
  );
};

export default memo(GoToBlock);
