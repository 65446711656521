import { Button } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React from 'react';

import wai_logo from '../../assets/images/wai_logo.svg';

interface Props {
  readonly saveConfirm: boolean;
  setSaveConfirm: (state: boolean) => void;
  handleEditPreset: () => void;
  handleGeneratePath: () => void;
}

const SaveChanges: React.FC<Props> = ({
  saveConfirm,
  setSaveConfirm,
  handleEditPreset,
  handleGeneratePath,
}: Props) => {
  return (
    <Modal
      visible={saveConfirm}
      closable={false}
      footer={false}
      className="edit_operator"
    >
      <div className="popup_head">
        <img src={wai_logo} />
        <span className="head_text">Save Changes?</span>
      </div>
      <p className="popup_desc_text">Do you want to save changes to preset?</p>
      <div className="button_sec">
        <Button
          key="cancel"
          className="normal_btn"
          onClick={() => {
            handleGeneratePath();
            setSaveConfirm(false);
          }}
        >
          Don’t Save
        </Button>
        <Button key="edit" className="green_btn" onClick={handleEditPreset}>
          Save Changes
        </Button>
      </div>
    </Modal>
  );
};

export default SaveChanges;
