import React, { useState, useEffect, useContext } from 'react';
import { Modal, Button, Input, Spin, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { updateLatLong } from '../../constants/Api';
import translate from '../../locale/en_translate.json';
import { ApplicationContext } from '../../context/AppContext';
import { getSingleElevationValue } from '../../constants/Common';
import CommonPopAutoDrive from '../remote_drive_new/CommonPopAutoDrive';

interface Props {
  showModal: boolean;
  closeModal: () => void;
  groundLat: any;
  groundLang: any;
}

const GroundZeroEditModal: React.FC<Props> = ({
  showModal,
  closeModal,
  groundLat,
  groundLang,
}: Props) => {
  const { userDetails } = useContext(ApplicationContext);
  const { t } = useTranslation();
  const [loader, setLoader] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [latitudeValue, setLatitudeValue] = useState();
  const [altitude, setAltitude] = useState();
  const [longitudeValue, setLongitudeValue] = useState();
  const [showConfirm, setShowConfirm] = useState(false);

  useEffect(() => {
    setLatitudeValue(groundLat);
  }, [groundLat]);
  useEffect(() => {
    setLongitudeValue(groundLang);
  }, [groundLang]);
  useEffect(() => {
    const onChange = async () => {
      const altitude: any = await getSingleElevationValue([
        Number(longitudeValue),
        Number(latitudeValue),
      ]);
      setAltitude(altitude);
    };
    onChange();
    if (latitudeValue !== '' && longitudeValue !== '') {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [latitudeValue, longitudeValue]);

  const editLatLongValues = async () => {
    try {
      setLoader(true);
      const payload = {
        latitude: Number(latitudeValue),
        longitude: Number(longitudeValue),
        altitude: Number(altitude),
      };
      const { organization } = userDetails;
      const response = await updateLatLong(
        organization.api_url,
        organization.farm.id,
        payload,
      );
      notification.success({ message: response.msg });
      closeModal();
      window.location.reload();
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    } finally {
      setLoader(false);
    }
  };

  return (
    <Modal
      className="commonPopup addPolygon popupStyles"
      title={t(translate.modal.editGroundZero)}
      centered
      width={'50vw'}
      visible={showModal}
      onCancel={closeModal}
      footer={[
        <Button key="back" onClick={closeModal} className="btnCancel Button">
          {t(translate.buttons.cancel)}
        </Button>,
        <Button
          key="submit"
          // onClick={editLatLongValues}
          onClick={() => setShowConfirm(true)}
          className="btnSave Button"
          disabled={isDisabled}
        >
          {t(translate.buttons.save)}
        </Button>,
      ]}
    >
      <div className="formRow">
        <div className="formCol">
          <div className="ant-row ant-form-item">
            <div className="ant-col ant-form-item-label">
              <label>{t(translate.modal.latitude)}</label>
            </div>
            <div className="ant-col ant-form-item-control">
              <div className="ant-form-item-control-input-content input-align">
                <Input
                  type="number"
                  value={latitudeValue}
                  onChange={(e) => setLatitudeValue(e.target.value as any)}
                  className="body1 fMed"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="formCol">
          <div className="ant-row ant-form-item">
            <div className="ant-col ant-form-item-label">
              <label>{t(translate.modal.longitude)}</label>
            </div>
            <div className="ant-col ant-form-item-control">
              <div className="ant-form-item-control-input-content input-align">
                <Input
                  type="number"
                  className="body1 fMed"
                  value={longitudeValue}
                  onChange={(e) => setLongitudeValue(e.target.value as any)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="formRow">
        <div className="formCol">
          <div className="ant-row ant-form-item">
            <div className="ant-col ant-form-item-label">
              <label>{t(translate.modal.altitude)}</label>
            </div>
            <div className="ant-col ant-form-item-control">
              <div className="ant-form-item-control-input-content input-align">
                <Input
                  type="number"
                  value={altitude}
                  onChange={(e) => setAltitude(e.target.value as any)}
                  className="body1 fMed"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="formCol"></div>
      </div>
      <div className="edit-loader">
        {loader && (
          <div className="loader">
            <Spin size="large" />
          </div>
        )}
      </div>
      {showConfirm && (
        <CommonPopAutoDrive
          showModal={showConfirm}
          closeModel={(state: boolean) => {
            if (!state) {
              setShowConfirm(false);
            } else {
              editLatLongValues();
            }
            // first=> button return always false
            // second => button return always true
            // handleInterruptCloseHandler(state);
          }}
          title="Shifting the ground zero will shift all survey data and recorded routines."
          description="Are you sure you want to continue?"
          firstButtonText="Cancel"
          secondButtonText="Okay"
        />
      )}
    </Modal>
  );
};

export default GroundZeroEditModal;
