import React, { useContext, useEffect, useMemo, useState } from 'react';
import Layout from 'antd/lib/layout/layout';
import { useTranslation } from 'react-i18next';
import translate from '../../locale/en_translate.json';
import closeGreen from '../../assets/images/right_widget_close_icon.svg';
import applicationIds from '../../locale/applicationIds.json';
import { ApplicationContext } from '../../context/AppContext';
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  RightOutlined,
  WarningFilled,
} from '@ant-design/icons';
import { Avatar, Modal, notification } from 'antd';
import { initClearSelection } from '../../constants/Common';
import { privilagesConstants } from '../../constants/Privilages';
import EditEmployee from './EditEmployee';
import {
  deleteInactive,
  getEmployeePermissionDetails,
} from '../../constants/Api';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import AppLoader from './AppLoader';
interface Props {
  empId: number;
  seeMore: () => void;
  onClose: (state?: boolean) => void;
}

const ViewEmployee: React.FC<Props> = ({ empId, seeMore, onClose }: Props) => {
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const { confirm } = Modal;
  const [profile, setProfile] = useState<any>(null);
  const { user, privilegeChecker } = useContext(ApplicationContext);
  const [passwordShown, setPasswordShown] = useState(false);
  const [editFlag, setEditFlag] = useState<boolean>(false);
  const [appAcess, setAppAcess] = useState<boolean>(false);
  const [loader, setLoader] = useState(false);
  const [show, setShow] = useState(false);

  useEffect(() => {
    const getProfile = async (empId: number) => {
      try {
        if (!user) return;
        const { organization } = user;
        setLoader(true);
        const data = await getEmployeePermissionDetails(
          organization.api_url,
          organization.id,
          empId,
        );
        const { name: organization_name } = data.organization || {};
        data.organization_name = organization_name;
        setProfile(data);
        setShow(true);
      } finally {
        setLoader(false);
      }
    };
    if (user) getProfile(empId);
    return () => setProfile(null);
  }, [user, empId]);

  useEffect(() => {
    if (profile) {
      const check = profile?.teams?.map((team: any) => team.app_access);
      check.length > 0 && setAppAcess(check.includes(true));
    }
    return () => {
      setPasswordShown(false);
    };
  }, [profile]);

  const closeBtn = () => {
    initClearSelection();
    onClose(editFlag);
  };

  const showPassWord = () => setPasswordShown(!passwordShown);

  const editClose = () => {
    setEditFlag(false);
    setShow(false);
    closeBtn();
  };

  const DeleteEmploye = async (profile: any) => {
    confirm({
      title: ``,
      icon: <ExclamationCircleOutlined />,
      content: `Are you sure you want to delete ${profile.first_name} ${profile.last_name}?`,
      className: 'delEmpModal',
      onOk() {
        deleteEmployeeById(profile.id);
      },
      okButtonProps: {
        className: 'okBtn',
      },
      cancelButtonProps: {
        className: 'canelBtn',
      },
    });
  };

  const deleteEmployeeById = async (id: number) => {
    try {
      const resp = await deleteInactive(userDetails.organization.api_url, id);
      notification.success({
        message: resp.msg,
      });
      onClose(true);
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    }
  };

  const ViewBlock = useMemo(
    () =>
      profile && (
        <div className="profileView personeeltab profileSideTab rightWidgetView widget_card">
          <div className="proRow mb24 proPic profile rowGapBtm5">
            {profile?.profile_url !== '' && profile?.profile_url ? (
              <Avatar
                src={profile?.profile_url}
                className={profile?.is_org_admin && 'profile-avatar'}
              ></Avatar>
            ) : (
              <Avatar
                className={
                  profile?.is_org_admin
                    ? 'profile-avatar profile-picture'
                    : 'profile-picture'
                }
              >
                {profile?.first_name?.charAt(0)?.toUpperCase()}
                {profile?.last_name?.charAt(0)?.toUpperCase()}
              </Avatar>
            )}
          </div>
          <div className="proRow rowGapBtm1">
            <h4
              className="headline3"
              data-testid={`${profile.first_name} ${profile.last_name}-EmployeeWidget`}
            >{`${profile.first_name} ${profile.last_name}`}</h4>
            <h3 className="overline1 member_uuid">
              {t(translate.employeeView.id)}: {profile.member_uuid}
            </h3>
          </div>
          {profile.teams ? (
            <div className="rowGapBtm25">
              <div className="flex alignCenter">
                <div className="proRoleTypeHead flex-1">
                  <h5 className="headline4 fSbd rowGapBtm15">
                    {t(translate.employeeView.teams)}
                  </h5>
                </div>
                <p
                  className="proSideHeading seeteamBtn body3"
                  data-testid="seeMoreLink-ViewEmployee"
                  onClick={seeMore}
                >
                  {' '}
                  {t(translate.employeeView.seeMore)}
                  <RightOutlined />
                </p>
              </div>
              {profile?.teams.map((ele: any) => {
                return (
                  <div className="proRoleCards" key={ele?.name}>
                    <h6 className="proRoleTypeHead_details flex-1 overline1 limit">
                      {ele?.name}
                    </h6>
                    <div className="proRoleType_details ">
                      {ele?.description}
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            ''
          )}
          <div className="proRoleTypeHead rowGapBtm25">
            <h5 className="headline4 fSbd mb11">
              {t(translate.employeeView.contact)}
            </h5>
            <h6 className="proRoleValue userName body2">
              {profile.email || profile.username}
            </h6>
            <h6 className="proRoleValue contactNumber mt16 body2">
              {profile.contact_number}
            </h6>
            {appAcess && !profile?.username && (
              <div style={{ display: 'flex' }}>
                <WarningFilled
                  style={{
                    color: '#fbb313',
                    marginRight: '10px',
                    fontSize: '22px',
                  }}
                />
                <span className="warning-msg">
                  {t(translate.requiredMessages.requieredEmail)}
                </span>
              </div>
            )}
          </div>
          {profile?.ops_code && (
            <div
              className="flex"
              style={{
                display: profile?.ops_code ? 'flex' : 'none',
              }}
            >
              <div className="flex-1 headline4 fSbd">
                {t(translate.employeeView.opCode)}
              </div>
              <div className="proRoleValue flex-1">
                <div className="flex">
                  <div className="flex-1">
                    {privilegeChecker(privilagesConstants.View_Operator_Code) &&
                    passwordShown
                      ? profile?.ops_code
                      : '* * * *'}
                  </div>
                  {privilegeChecker(privilagesConstants.View_Operator_Code) && (
                    <div className="flex-1 showPwdIcon" onClick={showPassWord}>
                      <>
                        {!passwordShown ? (
                          <EyeTwoTone />
                        ) : (
                          <EyeInvisibleOutlined />
                        )}
                      </>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {(privilegeChecker(privilagesConstants.Edit_personnel_information) ||
            empId === userDetails?.id) && (
            <div className="create_ticket_btn_sec plainBtnSec">
              <div
                className="Button editEmpBtn"
                data-testid="editButton-ViewEmployee"
                onClick={() => setEditFlag(true)}
              >
                {t(translate.buttons.edit)}
              </div>
              {!profile?.is_active && (
                <div
                  className="Button delBtn"
                  data-testid="deleteButton-ViewEmployee"
                  onClick={() => DeleteEmploye(profile)}
                >
                  {t(translate.buttons.delete)}
                </div>
              )}
            </div>
          )}
        </div>
      ),
    [profile, passwordShown],
  );

  return (
    <>
      <Layout>
        <div className={'rightSideWidegt ' + (show ? 'expand' : 'hide')}>
          <div className="widgetCloseBtn">
            <img
              id={applicationIds.personnelScreen.personnelCloseBtn}
              src={closeGreen}
              alt="close icon"
              onClick={closeBtn}
            />
          </div>
          <AppLoader loader={loader} />
          {profile &&
            (!editFlag ? (
              ViewBlock
            ) : (
              <EditEmployee
                Close={editClose}
                toggleWidget={editFlag}
                userProfile={profile}
              />
            ))}
        </div>
      </Layout>
    </>
  );
};

export default ViewEmployee;
