/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
import {
  Button,
  DatePicker,
  Input,
  Layout,
  message,
  notification,
  Row,
} from 'antd';
import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import downloadIcon from '../../../../assets/images/dwlIcon.svg';
import nEdit from '../../../../assets/images/nEdit.svg';
import { ApplicationContext } from '../../../../context/AppContext';
import translate from '../../../../locale/en_translate.json';
import WorkOrderDetailsModal from './WorkOrderDetailsModal';
import WorkOrderEditModal from './WorkOrderEditModal';
import constants from '../../../../constants/constant';
import { serviceWorkList } from '../../common/types';
import usePaginate from '../../../../hooks/usePaginate';
import useColumns from '../../../../hooks/useColumns';
import { fromToDateWrapper, initScroller } from '../../../../constants/Common';
import { stringColumnSort } from '../../common/common';
import { SearchOutlined } from '@ant-design/icons';
import CustomSelect from '../../../common/CustomSelect';
import CropOrgFilter from '../../../common/CropOrgFilter';
import TableExt from '../../../widget/TableExt';
import InfiniteScrollTable from '../../../common/InfiniteScrollTable';
import { getWorkOrderList } from '../../API';
import { mapNexusServiceWorkData } from '../../../../lib/dataFormat';

const { DOWNLOAD_SIZE } = constants;

const { RangePicker } = DatePicker;

let columnsData: any[] = [];
const title = 'Service Work Orders';

const ServiceWorkOrder: React.FC = () => {
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const [workOrderList, setWorkOrderList] = useState<serviceWorkList[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [workOrderId, setWorkOrderId] = useState<number>(0);
  const [dateFilter, setDateFilter] = useState<any>([]);
  const [toDateTime, setToDateTime] = useState<any>(null);
  const [status, setStatus] = useState<string>('open');
  const [selectedOrdId, setSelectedOrdId] = useState<any[]>([]);
  const [toggleColumnsStatus, setToggleColumnsStatus] =
    useState<boolean>(false);
  const [workOrderEditModal, setWorkOrderEditModal] = useState<boolean>(false);
  const [orderList, setOrderList] = useState<any>();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const [pageSize] = useState<number>(25);
  const [search, setSearch] = useState<string>('');
  const searchString = useRef<string>('');
  const tableRef = useRef<any>(null);
  const init = useRef(true);
  const { filterData, setData, activity, hasMore, checkActivity } =
    usePaginate();
  const { headers, columns, setColumns, setColumnsData } =
    useColumns('ServiceWorkOrder');
  const [csvData, setCSVData] = useState<any[]>([]);
  const [downloadData, setDownloadData] = useState({
    isDownloaded: false,
    percent: 0,
    status: '',
  });

  const resolveFilterData = [
    { name: 'Open', value: 'open' },
    { name: 'Completed', value: 'completed' },
  ];

  useEffect(() => {
    if (init.current && selectedOrdId.length > 0) {
      loadTickets();
      init.current = false;
    }
  }, [userDetails, pageNumber, search, selectedOrdId, toDateTime, status]);

  const loadTickets = () => {
    if (userDetails && !userDetails.organization && selectedOrdId.length === 0)
      return;
    if (checkActivity()) {
      // eslint-disable-next-line no-unused-expressions
      pageNumber !== 1 ? setPageNumber(1) : getWorkOrder();
    } else if (activity.current === 'paginate' || activity.current === '') {
      getWorkOrder();
    }
  };

  useEffect(() => {
    if (dateFilter === null) {
      setToDateTime('');
    }
  }, [dateFilter]);

  const handleRefresh = () => {
    activity.current = 'refresh';
    loadTickets();
  };

  useEffect(() => {
    if (dateFilter && dateFilter.length > 0) {
      onDateRangeChange(dateFilter);
    }
  }, [dateFilter]);

  const onDateRangeChange = (dates: any) => {
    workOrderList.length = 0;
    if (dates) {
      const [_, to] = fromToDateWrapper(dates);
      setToDateTime(to.toDate());
    } else {
      setToDateTime(null);
    }
  };

  const getRow = (record: any) => {
    setWorkOrderId(record.id);
    setShowModal(true);
  };

  const getWorkOrder = async () => {
    if (selectedOrdId.length === 0) return;
    if (checkActivity()) initScroller();
    const fromDateTime = dateFilter
      ? dateFilter[0] && moment(dateFilter[0]).startOf('day').toDate()
      : '';
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const toDateTime = dateFilter
      ? dateFilter[1] && moment(dateFilter[1]).endOf('day').toDate()
      : '';

    try {
      setLoader(true);
      const response = await getWorkOrderList(
        pageNumber,
        pageSize,
        search,
        fromDateTime ? fromDateTime.getTime() : '',
        toDateTime ? toDateTime.getTime() : '',
        selectedOrdId,
        status,
      );
      if (searchString.current !== search) return;
      // eslint-disable-next-line no-underscore-dangle
      setTotal(response._metadata.total_records_count);
      const { records } = response;
      let data = Array.isArray(records) ? records : [];
      data = mapNexusServiceWorkData(data);
      setData(data);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    } finally {
      if (!init.current) init.current = false;
      setLoader(false);
    }
  };

  useEffect(() => {
    columnsData = [
      {
        title: `${t(translate.serviceticket.organization)}`,
        dataIndex: 'organizationName',
        key: 'organizationName',
        width: '16%',
        ellipsis: true,
        onCell: (record: any) => ({
          onClick: () => getRow(record),
        }),
        sorter: (a: any, b: any) => stringColumnSort(a, b, 'organizationName'),
      },
      {
        title: `${t(translate.serviceticket.equipment)}`,
        dataIndex: 'equipmentName',
        key: 'equipmentName',
        width: '13%',
        onCell: (record: any) => ({
          onClick: () => getRow(record),
        }),
        sorter: (a: any, b: any) => stringColumnSort(a, b, 'equipmentName'),
      },
      {
        title: `${t(translate.serviceWorkOrder.workOrderNumber)}`,
        dataIndex: 'work_order_number',
        key: 'work_order_number',
        width: '18%',
        onCell: (record: any) => ({
          onClick: () => getRow(record),
        }),
      },

      {
        title: `${t(translate.serviceWorkOrder.tractorIssueDescription)}`,
        dataIndex: 'tractor_issue_description',
        key: 'tractor_issue_description',
        width: '22%',
        onCell: (record: any) => ({
          onClick: () => getRow(record),
        }),
        render: (name: string) => <span className="description">{name}</span>,
      },
      {
        title: `${t(translate.serviceticket.status)}`,
        dataIndex: 'status',
        key: 'status',
        width: '10%',
        onCell: (record: any) => ({
          onClick: () => getRow(record),
        }),
      },
      {
        title: `${t(translate.serviceticket.ticketId)}`,
        dataIndex: 'ticketId',
        key: 'ticketId',
        width: '12%',
        onCell: (record: any) => ({
          onClick: () => getRow(record),
        }),
      },
      {
        title: `${t(translate.updateManager.action)}`,
        dataIndex: 'workOrderReportUrl',
        key: 'workOrderReportUrl',
        width: '10%',
        render: (workOrderReportUrl: any, record: any) => (
          <>
            {record && record.status === 'open' && (
              <img
                src={nEdit}
                alt="editIcon"
                className="mr8"
                style={{ width: 22 }}
                onClick={() => {
                  setWorkOrderEditModal(true);
                  setOrderList(record);
                }}
              />
            )}
            {workOrderReportUrl !== null && (
              <img
                onClick={() => window.open(workOrderReportUrl)}
                src={downloadIcon}
                alt="downloadIcon"
                style={{ width: 22 }}
              />
            )}
          </>
        ),
      },
    ];
    setColumnsData(columnsData);
  }, []);

  const handleDescWidth = (tcolumns: any[]) => {
    const tcols = tcolumns.map((item: any) => item.key);
    if (tcols.includes('tractor_issue_description')) {
      tcolumns = tcolumns.map((item: any) => {
        const titem = item;
        if (titem.key === 'tractor_issue_description') {
          titem.width = tcolumns.length === 2 ? '85%' : '22%';
        }
        return titem;
      });
    }
    return tcolumns;
  };

  const handleLoadMore = () => {
    if (pageNumber === 1 && checkActivity() && document) initScroller();
    activity.current = 'paginate';
    setPageNumber(pageNumber + 1);
  };

  function disabledDate(current: any) {
    // Can not select days before today and today
    return current && current > moment();
  }

  const selectStatus = (e: any) => {
    setStatus(e);
  };

  const emptyTable = () => {
    setSelectedOrdId([]);
    setWorkOrderList([]);
    setData([]);
    setTotal(0);
    setCSVData([]);
    activity.current = 'filter';
  };

  const closeModal = (state = false) => {
    setShowModal(false);
    if (state) handleRefresh();
  };

  const handleSearch = (e: any) => {
    activity.current = 'search';
    const value = e.target.value.trim();
    if (value.length <= 1) {
      searchString.current = '';
      setSearch('');
    } else {
      searchString.current = value;
      setSearch(value);
    }
  };

  const closeEditModal = (state = false) => {
    setWorkOrderEditModal(false);
    if (state) handleRefresh();
  };

  const handleDownload = async () => {
    try {
      if (downloadData.status === 'start') return;
      setDownloadData({
        ...downloadData,
        status: 'start',
        percent: 10,
      });
      let data: any[] = [];
      const pages = Math.ceil(total / DOWNLOAD_SIZE);
      const fromDateTime = dateFilter
        ? dateFilter[0] && moment(dateFilter[0]).startOf('day').toDate()
        : '';
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const toDateTime = dateFilter
        ? dateFilter[1] && moment(dateFilter[1]).endOf('day').toDate()
        : '';
      // eslint-disable-next-line no-restricted-syntax
      for (const page of Array.from({ length: pages }, (_, i) => i + 1)) {
        // eslint-disable-next-line no-await-in-loop
        const response = await getWorkOrderList(
          page,
          DOWNLOAD_SIZE,
          search,
          fromDateTime ? fromDateTime.getTime() : '',
          toDateTime ? toDateTime.getTime() : '',
          selectedOrdId,
          status,
        );
        const { records } = response;
        let tdata = Array.isArray(records) ? records : [];
        tdata = mapNexusServiceWorkData(tdata);
        data = [...data, ...tdata];
        setDownloadData({
          ...downloadData,
          status: 'start',
          percent: Math.round((data.length / total) * 100),
        });
      }

      setCSVData([...data]);
      setDownloadData({
        ...downloadData,
        percent: 100,
        status: 'success',
      });
    } catch (error: any) {
      setDownloadData({
        ...downloadData,
        status: 'exception',
      });
      notification.error({ message: error.message });
    }
  };

  const handleOrgs = (ids: any) => {
    activity.current = 'filter';
    setSelectedOrdId(ids);
  };
  const handleSubmit = () => {
    if (userDetails && selectedOrdId.length) {
      loadTickets();
    } else {
      message.warning('Please select Crop');
    }
  };

  return (
    <>
      <>
        <Layout onClick={() => setToggleColumnsStatus(!toggleColumnsStatus)}>
          <div className="mainContent">
            <div className="tblContainer viewportContainer">
              <Row>
                <div className="common_wrapper tab2FilterWidget_wrapper">
                  <div className="filters_card ad_filters_card filtersHealthScreen">
                    <div className="displayFlex">
                      <div>
                        <Input
                          addonBefore={<SearchOutlined />}
                          placeholder="Search Directory"
                          className="common_search"
                          onChange={(e) => handleSearch(e)}
                          autoComplete="off"
                        />
                      </div>

                      <CropOrgFilter handleOrgs={handleOrgs} isDealer />

                      <RangePicker
                        format="MM-DD-YYYY"
                        onChange={(dates) => setDateFilter(dates)}
                        value={dateFilter}
                        className="dropdownStyle dW250_date dateFilter"
                        disabledDate={disabledDate}
                        style={{ width: '230px' }}
                      />

                      <CustomSelect
                        label={t(translate.supportTicket.status)}
                        cssClass="min_width"
                        value={status}
                        setValue={(id: any) => {
                          selectStatus(id);
                        }}
                        options={resolveFilterData}
                        optionKey="value"
                        optionDisplay="name"
                        isAll
                      />
                      <Button
                        className="submitBtn"
                        onClick={handleSubmit}
                        loading={loader}
                      >
                        Submit
                      </Button>
                    </div>

                    <TableExt
                      handleRefresh={handleRefresh}
                      handleDownload={handleDownload}
                      downloadData={downloadData}
                      csvHeaders={headers}
                      csvData={csvData}
                      csvFilename="Service_Work_Orders"
                      tableRef={tableRef}
                      tableName="ServiceWorkOrder"
                      handleCallback={(args: any[]) => {
                        setColumns(args);
                      }}
                      columnsData={columnsData}
                      toggleColumnsStatus={toggleColumnsStatus}
                    />
                  </div>
                  <div className="common_table">
                    <InfiniteScrollTable
                      columns={columns}
                      loading={loader}
                      hasMore={hasMore}
                      filterData={filterData}
                      totalcount={total}
                      handleLoadMore={handleLoadMore}
                      filename={title}
                    />
                  </div>
                </div>
              </Row>
            </div>
          </div>
        </Layout>
        {showModal && (
          <WorkOrderDetailsModal
            showModal={showModal}
            close={closeModal}
            workOrderId={workOrderId}
          />
        )}
        {workOrderEditModal && (
          <WorkOrderEditModal
            showModal={workOrderEditModal}
            close={closeEditModal}
            orderList={orderList}
            getWorkOrder={getWorkOrder}
          />
        )}
      </>
    </>
  );
};

export default ServiceWorkOrder;
