import React from 'react';
import { Button, Modal } from 'antd';
import discard_logo from '../../assets/images/discard_logo.svg';

interface Props {
  showModal: boolean;
  // first=> button return always false
  // second => button return always true
  closeModel: (showModel: boolean) => void;
  title: string; // pop title
  description: string; // pop description
  firstButtonText?: string; //first button text
  secondButtonText?: string; //second button text
  showFirstButton?: boolean;
  showSecondButton?: boolean;
}
// commonly used pop
// don't make it as static
export const CommonPopAutoDrive: React.FC<Props> = ({
  showModal,
  closeModel,
  title,
  description,
  firstButtonText,
  secondButtonText,
  showFirstButton = true,
  showSecondButton = true,
}: Props) => {
  return (
    <>
      <Modal
        className="modalContainer profileUpdatePopup implementPopup"
        title=""
        visible={showModal}
        onCancel={() => closeModel(false)}
        width={1000}
        closable={false}
        maskClosable={false}
        footer={[
          <>
            {showFirstButton && (
              <Button
                key="back"
                className="impCancelBtn Button2"
                onClick={() => closeModel(false)}
              >
                {firstButtonText || 'Okay'}
              </Button>
            )}
            {showSecondButton && (
              <Button
                key="submit"
                className="impDiscardBtn Button2"
                onClick={() => closeModel(true)}
              >
                {secondButtonText || 'Try Again'}
              </Button>
            )}
          </>,
        ]}
      >
        <div className="modalBox flexnone">
          <div className="formColW">
            <div className="userIcon">
              <h4 className="headline3 inlineTxt">
                <img src={discard_logo} alt="" /> {title}
              </h4>
              <p className="body2">{description}</p>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CommonPopAutoDrive;
