import React, { useState, memo } from 'react';
import { Select } from 'antd';

interface Props {
  label?: string;
  value?: string | number | undefined;
  cssClass?: string;
  setValue: (data: string | number | undefined) => void;
  options?: any[];
  optionKey?: string;
  optionDisplay: string;
  optionDisplay2: string;
  testId?: any;
}

const CustomSelect3: React.FC<Props> = ({
  label,
  value,
  setValue,
  cssClass,
  options,
  optionKey = 'id',
  optionDisplay = 'name',
  optionDisplay2,
  testId,
}: Props) => {
  const [focus, setFocus] = useState(false);

  const isEmpty = (value: any) => {
    if (typeof value === 'undefined') return true;
    if (typeof value === 'string' && value.trim() === '') return true;
    if (typeof value === 'number' && isNaN(value)) return true;
    return false;
  };

  const labelClass = focus || !isEmpty(value) ? 'label label-float' : 'label';

  return (
    <div
      className="float-label"
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      <Select
        getPopupContainer={(triggerNode) => triggerNode.parentNode}
        style={{ width: '100%' }}
        data-testid={testId}
        value={value}
        className={cssClass}
        onSelect={(e: any) => {
          setValue(e);
        }}
      >
        {options &&
          options?.length > 0 &&
          options?.map((data: any, key) => {
            return (
              <option
                value={data[optionKey]}
                key={key}
                data-testid={`${data[optionDisplay]} ${data[optionDisplay2]}-CustomSelect3`}
              >
                <span>{data[optionDisplay]} MPH</span>
                <span>{data[optionDisplay2]} GEAR</span>
              </option>
            );
          })}
      </Select>
      <label className={labelClass}>{label}</label>
    </div>
  );
};

export default memo(CustomSelect3);
