/* eslint-disable no-console */
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

import { RightOutlined } from '@ant-design/icons';
import { Checkbox, notification } from 'antd';

import { Feature, Overlay, View } from 'ol';
import { ScaleLine } from 'ol/control';
import Point from 'ol/geom/Point';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import OLMap from 'ol/Map';
import * as proj from 'ol/proj';
import OSM from 'ol/source/OSM';
import VectorSource from 'ol/source/Vector';
import XYZ from 'ol/source/XYZ';
import { Fill, Icon, Stroke, Style } from 'ol/style';
import moment from 'moment';
import LineString from 'ol/geom/LineString';
import Cluster from 'ol/source/Cluster';
import CircleStyle from 'ol/style/Circle';
import { useTranslation } from 'react-i18next';
import translate from '../../../locale/en_translate.json';
import ground_zero from '../../../assets/images/ground_zero.svg';
import datalayerIcon from '../../../assets/images/dataLayer.svg';
import JumpnGoIcon from '../../../assets/images/m_jngo.svg';
import SnapIcon from '../../../assets/images/m_sshot.svg';
import TicketIcon from '../../../assets/images/m_ticket.svg';
import MapCenterIcon from '../../../assets/images/map_recenter.svg';
import mapNor from '../../../assets/images/mapNor.svg';
import mapSat from '../../../assets/images/mapSat.svg';
import polygonIcon from '../../../assets/images/polygon.svg';
import RoundIcon from '../../../assets/images/selRound.svg';
import thumbnail_icon from '../../../assets/images/thumbnail_icon.svg';
import FleetIndicator from '../../../assets/images/tractor-icons/FleetIndicator.svg';
import fleetSpeedIcon from '../../../assets/images/tractor-icons/fleet_speed_icon.svg';
import guardRails from '../../../assets/images/tractor-icons/guardRails.svg';
import {
  calPtsDistance,
  displayRecenterAllViewPortSetUp,
  drawPoly,
  getDateTime,
  getDateTimes,
  getFleetUsagePointsInfo,
  getFleetUsagePointsPathInfo,
  getFullName,
  getPathBorderColor,
  getPathColor,
  guardRailStyle,
  IndicatorStyle,
  polygonHoverLabel,
  priority,
  snapshotStyle,
  SpeedStyle,
  styleFun,
  styleSnapFun,
  TicketStyle,
  toggleVineRowStyle,
  vineRowHoverStyle,
} from '../../../constants/Common';
import {
  getAllTractorSnapShot,
  getDriveActionPath,
  getMapFleetIndicators,
  getMapFleetTickets,
  getMapSpeedTests,
  getPolygonsData,
} from '../../../constants/Api';
import { ApplicationContext } from '../../../context/AppContext';
import { AnalyticsContext } from '../AnalyticsContext';
import {
  ATOM,
  GILES,
  GUARD_RAILS,
  JUMP_AND_GO,
  rangeForAnalytics,
} from '../../../constants/constant';
import { SET_POLYGONS_LIST } from '../../../context/actions';
import applicationIds from '../../../locale/applicationIds.json';
import { track } from '../../../util/logger';
import { getSeverity, getTags } from '../../fleethealth/Diagnostics';
import DiagnosticWidget from '../../fleethealth/DiagnosticWidget';
import { getFleetTicketDetails } from '../util';
import { onGlobalZoomToShowZoomIcons } from '../../map/LiveMap';
import { getVineRows } from '../../map/VineRows';
import SnapView from '../../snapshot/SnapView';
import TicketsCreateEdit from '../../tickets/TicketsCreateEdit';
import TicketViewEdit from '../../tickets/TicketViewEdit';
import ThumbnailMenu from '../Database/ThumbnailMenu';
import { drawLayer } from '../../../hooks/useDrawLayer';
import { TractorSpeedTestResponse } from '../../../constants/types';
import drawGuardrails from '../common/guardrails';

let selectedDriveActionUser = '';
let toggleForGuardRails = false;
let toggleForTicket = false;
let toggleForIndicator = false;
let globalGuardrailData: any = null;
let globalZoom = 0;
let globalMapClickAllow = true;

interface Props {
  driveAction?: any;
}

const LiveMap: React.FC<Props> = ({ driveAction }) => {
  const { t } = useTranslation();
  const { userDetails, updateFullScreen, APPReducer } =
    useContext(ApplicationContext);
  const { setVideoTime, analyticReducer, jumpAndGoStore } =
    useContext(AnalyticsContext);
  const [appState, appDispatch] = APPReducer;
  const { tractorsMap, groundZero, polygonsList } = appState;
  const [analyticState] = analyticReducer;

  const [mapInitialized, setMapInitialized] = useState(false);
  const [base, setBase] = useState<[number, number]>([0, 0]);
  const [toggleSatellite, setToggleSatellite] = useState(true);
  const [rightSideToggle, setRightSideToggle] = useState(false);
  const [selectedDriveAction, setSelectedDriveAction] = useState('');
  const [selectedJumpnGo, setselectedJumpnGo] = useState<any>();
  const selectedJumpnGoRef = useRef<any>(null);
  const [mapSnapshots, setMapSnapshots] = useState<any>([]);
  const [selectedTicket, setSelectedTicket] = useState<any>();
  const [showTicketPopup, setShowTicketPopup] = useState<boolean>(false);
  const [showThumbnails, setShowThumbnails] = useState<boolean>(false);
  const [showGuardrailModel, setShowGuardrailModel] = useState(false);
  const [guardrailData, setGuardrailData] = useState<any | null>(null);
  const [isEdit, setIsEdit] = useState(false);
  const [errorDetails, setErrorDetails] = useState<any>();
  const [showIndicatorModel, setShowIndicatorModel] = useState(false);
  const mapElement: any = useRef();
  const container: any = useRef();
  const content: any = useRef();
  const centerRef: any = useRef();
  const mapRef = useRef<OLMap | null>(null);
  const vectorTileLayerRef = useRef<TileLayer<any>>();
  const satelliteTileLayerRef = useRef<TileLayer<any>>();
  const initialLayer = useRef<VectorLayer<any>>();
  const mapTicketsRefs = useRef<VectorLayer<any>>();
  const mapIndicatorRefs = useRef<VectorLayer<any>>();
  const tractorPathLayerRef = useRef<VectorLayer<any>>();
  const markerPointRef = useRef<VectorLayer<any>>();
  const guardRailsRefs = useRef<VectorLayer<any>>();
  const snapshotRefs = useRef<VectorLayer<any>>();
  const jumpAndGoRefs = useRef<VectorLayer<any>>();
  const jumpAndGoIconRefs = useRef<VectorLayer<any>>();
  const hoverPointLayerRef = useRef<VectorLayer<any>>();
  const polygonsLayerRef = useRef<VectorLayer<any>>();
  const vineRowsLayerRef = useRef<VectorLayer<any>>();
  const [toggleTicketWidget, setToggleTicketWidget] = useState<any>(false);
  const [ticketDetails, setTicketDetails] = useState(null);
  const [tractorId, setTractorId] = useState<any>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [fullScreenFlag, setFullScreenFlag] = useState<boolean>(false);
  const [showGuardRailsLayer, setShowGuardRailsLayer] = useState(true);
  const [showSnapShotRailsLayer, setShowSnapShotRailsLayer] = useState(true);
  const [showPathlayer, setShowPathlayer] = useState(true);
  const [showIndicatorsLayer, setShowIndicatorsLayer] = useState(true);
  const [showSpeedLayer, setShowSpeedLayer] = useState(false);
  const [rightSideThumbnailsToggle, setRightSideThumbnailsToggle] =
    useState<boolean>(false);
  const [showTicketsLayer, setshowTicketsLayer] = useState(true);
  const [showPolygonsLayer, setshowPolygonsLayer] = useState(true);

  const jAGSSIcons = useRef<any>(new Map());

  const mapSpeedRefs = useRef<VectorLayer<any>>();

  const { JAG } = analyticState;
  // cleanup component state
  useEffect(() => {
    return () => {
      setGuardrailData(null);
      globalMapClickAllow = true;
    };
  }, []);

  useEffect(() => {
    if (driveAction && JAG) {
      const content = driveAction;
      setGuardrailData({
        guard_rail_triggered_uuid: content.transaction_id,
        id: content.tractor_id.id,
        tractor_id: content.tractor_id.id,
        title: getDateTime(content.created_date_time),
        created_date_time: new Date(content.created_date_time).getTime(),
        guard_rail_type: content.guard_rail_type,
        created_at: content.created_at,
        created_by: content.created_by,
      });
      setShowGuardrailModel(true);
    }
  }, [driveAction, JAG]);

  useEffect(() => {
    if (JAG) setselectedJumpnGo(JAG);
  }, [JAG]);

  const onCreateTicket = () => {
    setTicketDetails(errorDetails);
    setTractorId(errorDetails?.tractor_id);
    setShowIndicatorModel(false);
    setToggleTicketWidget(true);
    track('CreateTicket', {
      event: 'Create Ticket',
    });
  };

  const handleRecenter = () => {
    if (
      mapRef &&
      mapRef.current &&
      tractorPathLayerRef &&
      tractorPathLayerRef.current
    ) {
      const feats = tractorPathLayerRef.current?.getSource().getFeatures();
      feats &&
        feats.length > 0 &&
        displayRecenterAllViewPortSetUp(feats, mapRef.current);
    }
  };

  const handleCenterPoint = (point: number[]) => {
    if (mapRef.current && Array.isArray(point) && point?.length) {
      const view = mapRef.current?.getView();
      const zoom = view.getZoom();
      if (zoom) {
        mapRef.current?.getView().setCenter(point);
        mapRef.current?.getView().setZoom(17);
        centerRef.current = point;
      }
    }
  };

  useEffect(() => {
    if (userDetails && userDetails.organization && groundZero) {
      initializeMap();
    }
  }, [userDetails, groundZero]);

  //  mapInitialization start here
  const initializeMap = async () => {
    try {
      // const { organization } = userDetails;
      // const { latitude, longitude } = await getGroundZero(
      //   organization.api_url,
      //   organization.farm.id,
      // );
      const { latitude, longitude } = groundZero;
      setBase([latitude, longitude]);

      const baseLocation = new Feature({
        geometry: new Point(proj.fromLonLat([longitude, latitude])),
      });
      baseLocation.set('name', 'Ground zero');
      baseLocation.setStyle(
        new Style({
          image: new Icon({
            src: ground_zero,
            scale: 0.2,
          }),
          zIndex: 100,
        }),
      );

      const initialFeaturesLayer = new VectorLayer({
        source: new VectorSource({
          features: [baseLocation],
        }),
      });

      const vectorLayer = new TileLayer({
        source: new OSM(),
        visible: false,
      });

      const satelliteLayer = new TileLayer({
        source: new XYZ({
          url: 'http://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}&s=Ga',
          cacheSize: 1000,
        }),
        visible: true,
      });

      // polygons list layar
      const polygonsFeaturesLayer = new VectorLayer({
        source: new VectorSource({
          features: [],
        }),
        visible: true,
      });
      const traveledPathLayer = new VectorLayer({
        source: new VectorSource({
          features: [],
        }),
        visible: true,
        zIndex: priority.PATH,
      });

      // create map
      const initialMap = new OLMap({
        target: mapElement.current,
        layers: [
          satelliteLayer,
          vectorLayer,
          initialFeaturesLayer,
          polygonsFeaturesLayer,
          traveledPathLayer,
        ],
        controls: [scaleControl()],
        view: new View({
          projection: 'EPSG:3857',
          center: proj.transform(
            [longitude, latitude],
            'EPSG:4326',
            'EPSG:3857',
          ),
          zoom: 17,
          maxZoom: 25,
        }),
      });
      // set flag for map initialization
      setMapInitialized(true);
      mapRef.current = initialMap;
      initialLayer.current = initialFeaturesLayer;
      // initialize satelight layer
      satelliteTileLayerRef.current = satelliteLayer;
      // initialize vector layer
      vectorTileLayerRef.current = vectorLayer;
      // polygons list
      polygonsLayerRef.current = polygonsFeaturesLayer;

      // traveled path
      tractorPathLayerRef.current = traveledPathLayer;
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    }
  };
  //  mapInitialization ends here

  const scaleControl = () => {
    const control = new ScaleLine({
      units: 'metric',
      className: 'positionMap',
    });
    return control;
  };
  // to change road map and satelight map
  useEffect(() => {
    vectorTileLayerRef.current?.setVisible(!toggleSatellite);
    satelliteTileLayerRef.current?.setVisible(toggleSatellite);
  }, [toggleSatellite]);
  /**
   * Zoom In Zoom Out and Recenter
   */

  const handleZoom = (zoomType: string) => {
    if (mapRef.current) {
      const view = mapRef.current.getView();
      const zoom = view.getZoom();
      if (zoom) {
        if (zoomType === 'zoomIn') {
          view.setZoom(zoom + 1);
          track('Analytics Map', {
            event: `Map Zoom In ${zoom + 1}`,
          });
        } else {
          view.setZoom(zoom - 1);
          track('Analytics Map', {
            event: `Map Zoom out ${zoom - 1}`,
          });
        }
      }
    }
  };

  const moveEndHandler = (map: any) => {
    let currZoom = map?.getView().getZoom();
    map &&
      map.on('moveend', function (e: any) {
        const newZoom = map?.getView().getZoom();
        if (currZoom != newZoom) {
          currZoom = newZoom;
          globalZoom = newZoom;
          handleLayerVisible(newZoom);
        }
      });
  };

  const handleLayerVisible = (zoom: number) => {
    vineRowsLayerRef?.current?.setVisible(zoom >= 17);
    toggleVineRowStyle(
      vineRowsLayerRef.current,
      toggleSatellite,
      globalZoom >= onGlobalZoomToShowZoomIcons,
    );
  };

  useEffect(() => {
    if (mapInitialized) {
      if (mapRef.current) {
        moveEndHandler(mapRef.current);
        polygonHoverLabel(mapRef.current);
      }
      const overlay = new Overlay({
        element: container.current,
        autoPan: false,
        autoPanAnimation: {
          duration: 10,
        },
      });
      mapRef.current?.on('pointermove', function (evt) {
        const hit = mapRef.current?.forEachFeatureAtPixel(
          evt.pixel,
          function (feature: any) {
            const coordinate = evt.coordinate;
            const name = feature.get('name');
            if (name) {
              content.current.innerHTML = '<p>' + name + '</p>';
              overlay.setPosition(coordinate);
              mapRef.current?.addOverlay(overlay);
              return true;
            }
            return false;
          },
        );
        if (hit) {
          evt.map.getTargetElement().style.cursor = 'pointer';
        } else {
          overlay.setPosition(undefined);
          mapRef.current?.addOverlay(overlay);
          evt.map.getTargetElement().style.cursor = '';
        }
      });
    }
  }, [userDetails, mapInitialized]);

  /**
   * Polygons initialization Starts Here
   */
  useEffect(() => {
    const init = async () => {
      polygonsLayerRef.current?.getSource().clear();
      try {
        const { organization } = userDetails;
        let recordsList = [];
        if (polygonsList && polygonsList.length > 0) {
          recordsList = polygonsList;
        } else {
          const response = await getPolygonsData(
            organization.api_url,
            organization.farm.id,
          );
          const { records } = response;
          recordsList =
            records && records.length && records.length > 0 ? records : [];
        }
        drawPolygon(
          recordsList,
          polygonsList && polygonsList.length > 0 ? false : true,
        );
      } catch (error: any) {
        notification.error({
          message: error.message,
        });
      }
    };
    if (userDetails && userDetails.organization && mapInitialized && base) {
      init();
    }
  }, [userDetails, mapInitialized, base, polygonsList]);

  const drawPolygon = async (polyList: any, save: boolean) => {
    try {
      const records = polyList && polyList.length > 0 ? polyList : [];
      records.map(async (record: any, index: number) => {
        const { polygonItem } = await drawPoly(
          userDetails,
          base,
          polygonsLayerRef.current,
          1,
          1,
          record,
          false, // suggested as false
          !save, // call api to get vertices -> false call api , -> true dont call api
        );
        if (polygonItem && polygonItem.vertices) {
          record.vertices = polygonItem.vertices;
        }

        // polygons added to maintain app context start
        // listItems.push(polygonItem as MapFeature);
        if (records && records.length - 1 === index) {
          save &&
            appDispatch({
              type: SET_POLYGONS_LIST,
              payload: records,
            });
        }
        // polygons added to maintain app context end
      });

      polygonHoverLabel(mapRef.current);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    }
  };

  useEffect(() => {
    const init = async () => {
      const { layer }: { layer: any } = await getVineRows(userDetails, base);
      if (layer) {
        mapRef.current?.addLayer(layer);
        vineRowsLayerRef.current = layer;
      }
    };
    if (userDetails && userDetails.organization && mapInitialized && base) {
      init();
    }
  }, [userDetails, mapInitialized, base]);

  /**
   * Subscribing to Tractors and getting Information Starts Here
   */

  const setHoverName = (user: any) => {
    const n = `${user?.first_name}  ${user?.last_name}`;
    selectedDriveActionUser = n;
  };

  const getPoints = async () => {
    if (
      userDetails &&
      userDetails.organization &&
      selectedDriveAction &&
      selectedDriveAction.length > 0
    ) {
      const { organization } = userDetails;
      clearTractorPoints();
      setIsLoading(false);
      let response;
      if (jumpAndGoStore.current.get(selectedDriveAction)) {
        response = jumpAndGoStore.current.get(selectedDriveAction);
      } else {
        response = await getDriveActionPath(
          organization.api_url,
          selectedDriveAction,
        );
        jumpAndGoStore.current.set(selectedDriveAction, response);
      }
      if (selectedJumpnGo || selectedJumpnGoRef.current) {
        const { tractor, operator } =
          selectedJumpnGo || selectedJumpnGoRef.current;
        response['jng'] = selectedJumpnGo || selectedJumpnGoRef.current;
        response['tractor_name'] = tractor?.name;
        response['operator_name'] = getFullName(operator);
      }
      drawJAG(selectedDriveAction, response);
      setIsLoading(true);
    }
  };

  useEffect(() => {
    if (
      userDetails &&
      userDetails.organization &&
      selectedDriveAction &&
      mapInitialized
    )
      getPoints();
  }, [selectedDriveAction, mapInitialized, userDetails]);

  // Handle Jump and Go Start and Stop Icons
  useEffect(() => {
    if (jAGSSIcons.current.size && selectedDriveAction) {
      if (jumpAndGoIconRefs.current) {
        jumpAndGoIconRefs.current.getSource().clear();
        mapRef.current?.removeLayer(jumpAndGoIconRefs.current);
      }
      const items = jAGSSIcons.current.get(selectedDriveAction);
      if (items) {
        const [jng, point1, point2] = items;
        const pointFeature = jAGStartNdStop(point1, 'Start', jng);
        const pointFeature1 = jAGStartNdStop(point2, 'End', jng);

        const jumpAndGoSource = new VectorSource({
          features: [pointFeature, pointFeature1],
        });

        const jumpAndGolayer = new VectorLayer({
          source: jumpAndGoSource,
          zIndex: priority.SNAP,
        });

        mapRef.current?.addLayer(jumpAndGolayer);
        jumpAndGoIconRefs.current = jumpAndGolayer;
      }
    }
  }, [selectedDriveAction, jAGSSIcons.current.size]);

  useEffect(() => {
    const initTicketLayer = (data: any[]) => {
      const init = () => {
        const layer = drawLayer(data, 'Ticket');
        mapTicketsRefs.current = layer;
        mapTicketsRefs.current?.setVisible(showTicketsLayer);
        if (mapTicketsRefs.current)
          mapRef.current?.addLayer(mapTicketsRefs.current);
      };
      if (mapRef.current && mapTicketsRefs.current) {
        mapTicketsRefs.current.getSource().clear();
        mapRef.current?.removeLayer(mapTicketsRefs.current);
        init();
      } else if (mapRef.current) {
        init();
      }
    };

    const initTicketData = async () => {
      if (selectedDriveAction && selectedDriveAction.trim().length > 0) {
        try {
          if (mapTicketsRefs.current) {
            mapRef.current?.removeLayer(mapTicketsRefs.current);
          }
          const data = await getMapFleetTickets(
            userDetails.organization.api_url,
            userDetails.organization.id,
            selectedDriveAction,
          );
          if (data && data.length > 0) {
            const [{ fleet_tickets_data }] = data;
            initTicketLayer(fleet_tickets_data);
          }
        } catch (error) {
          console.error(error);
        }
      }
    };
    initTicketData();
  }, [selectedDriveAction]);

  useEffect(() => {
    const initIndicatorLayer = (data: any[]) => {
      const init = () => {
        const layer = drawLayer(data, 'INDICATOR');
        mapIndicatorRefs.current = layer;
        mapIndicatorRefs.current?.setVisible(showIndicatorsLayer);
        if (mapIndicatorRefs.current)
          mapRef.current?.addLayer(mapIndicatorRefs.current);
      };
      if (mapRef.current && mapIndicatorRefs.current) {
        mapIndicatorRefs.current.getSource().clear();
        mapRef.current?.removeLayer(mapIndicatorRefs.current);
        init();
      } else if (mapRef.current) {
        init();
      }
    };

    const initIndicatorData = async () => {
      if (selectedDriveAction && selectedDriveAction.trim().length > 0) {
        try {
          if (mapIndicatorRefs.current) {
            mapRef.current?.removeLayer(mapIndicatorRefs.current);
          }
          const data = await getMapFleetIndicators(
            userDetails.organization.api_url,
            userDetails.organization.id,
            selectedDriveAction,
          );
          if (data && data.length > 0) {
            const [{ indicators_data }] = data;
            initIndicatorLayer(indicators_data);
          }
        } catch (error) {
          console.error(error);
        }
      }
    };
    initIndicatorData();
  }, [selectedDriveAction, userDetails]);

  useEffect(() => {
    const initSpeedLayer = (data: any[]) => {
      const init = () => {
        const layer = drawLayer(data, 'SPEED');
        mapSpeedRefs.current = layer;
        mapSpeedRefs.current?.setVisible(showSpeedLayer);
        if (mapSpeedRefs.current)
          mapRef.current?.addLayer(mapSpeedRefs.current);
      };
      if (mapRef.current && mapSpeedRefs.current) {
        mapSpeedRefs.current.getSource().clear();
        mapRef.current?.removeLayer(mapSpeedRefs.current);
        init();
      } else if (mapRef.current) {
        init();
      }
    };
    const mapFunc = (data: any[]) => {
      const fData = data.map((item: any) => {
        const { latitude, longitude } = item.location_latlong;
        item.latitude = latitude;
        item.longitude = longitude;
        return item;
      });
      initSpeedLayer(fData);
    };

    const initSpeedData = async () => {
      if (selectedDriveAction && selectedDriveAction.trim().length > 0) {
        try {
          if (mapSpeedRefs.current) {
            mapRef.current?.removeLayer(mapSpeedRefs.current);
          }
          const data: TractorSpeedTestResponse[] = await getMapSpeedTests(
            userDetails.organization.api_url,
            userDetails.organization.id,
            selectedDriveAction,
          );
          if (data && data.length > 0) mapFunc(data);
        } catch (error) {
          console.error(error);
        }
      }
    };
    initSpeedData();
  }, [selectedDriveAction, userDetails]);

  const clearTractorPoints = () => {
    if (snapshotRefs.current) {
      snapshotRefs.current.getSource().clear();
      mapRef.current?.removeLayer(snapshotRefs.current);
    }
    if (jumpAndGoRefs.current) {
      jumpAndGoRefs.current.getSource().clear();
      mapRef.current?.removeLayer(jumpAndGoRefs.current);
    }
    if (jumpAndGoIconRefs.current) {
      jumpAndGoIconRefs.current.getSource().clear();
      mapRef.current?.removeLayer(jumpAndGoIconRefs.current);
    }
    if (guardRailsRefs.current) {
      guardRailsRefs.current.getSource().clear();
      mapRef.current?.removeLayer(guardRailsRefs.current);
    }
    if (mapTicketsRefs.current) {
      mapTicketsRefs.current.getSource().clear();
      mapRef.current?.removeLayer(mapTicketsRefs.current);
    }
    if (mapIndicatorRefs.current) {
      mapIndicatorRefs.current.getSource().clear();
      mapRef.current?.removeLayer(mapIndicatorRefs.current);
    }
    if (mapSpeedRefs.current) {
      mapSpeedRefs.current.getSource().clear();
      mapRef.current?.removeLayer(mapSpeedRefs.current);
    }
    if (tractorPathLayerRef.current) {
      tractorPathLayerRef?.current?.getSource()?.clear();
    }
    if (markerPointRef.current) {
      markerPointRef?.current?.getSource()?.clear();
      mapRef.current?.removeLayer(markerPointRef.current);
    }
  };

  const jAGStartNdStop = (points: any, name: string, content: any) => {
    const style = new Style({
      image: new Icon({
        src: JumpnGoIcon,
        scale: 2,
      }),
      zIndex: 100,
    });
    const feature = new Feature({
      geometry: new Point(points),
      name,
      pointType: 'JAG_START_STOP',
      content: { ...content, jAGStatus: name },
      style,
      point: points,
    });
    feature.setStyle(style);
    return feature;
  };

  const drawJAG = (id: string, jumpAndGo: any, all = false) => {
    const {
      tractor_id: tractorId,
      records: points,
      tractor_name = '',
      operator_name = '',
      jng,
    } = jumpAndGo;
    const defaultLayer = tractorPathLayerRef.current;
    let oarr: any = [];
    let lastPlannerLastPoint: any = null;
    let lastIndexPoint: any = null;

    points
      .filter((point: any) => point.planner !== 'standby') // filter standby
      .map((point: any, index: number, tpoints: any) => {
        // slice points
        if (lastIndexPoint) point.path = [lastIndexPoint, ...point.path];

        const result = point.path.reduce(
          (
            a: { data: any[][]; i: number | undefined; last_item: any },
            path_pt: {
              longitude: number;
              latitude: number;
              created_date_time: number;
              dTime: string;
            },
            i: number,
          ) => {
            const ar = point.path;
            // if (i == 0 && Object.keys(a.last_item).length) ar.push(point.path);

            let distance = 0;

            if (i < ar.length) {
              distance = ar[i + 1] ? calPtsDistance([path_pt, ar[i + 1]]) : 1;

              if (distance > rangeForAnalytics) {
                a.data.push(ar.slice(a.i, i + 1));
                a.i = i + 1;
                if (ar[i + 1]) a.data.push([ar[i], ar[i + 1]]);
              }
            }

            if (ar.length - 1 == i && a.data.length == 0) {
              a.data.push(ar);
              // a.last_item = ar[ar.length - 1];
              lastIndexPoint = ar[ar.length - 1];
              return a.data;
            }
            if (ar.length - 1 == i && a.data.length > 0) {
              // a.last_item = ar[ar.length - 1];
              lastIndexPoint = ar[ar.length - 1];
              return a.data;
            }
            return a;
          },
          { i: 0, data: [], last_item: {} },
        );

        result
          .filter((item: any, key: number) => !(key % 2))
          .map((item: any, key: number) => {
            const arr: any[] = [];
            // if (lastPlannerLastPoint) arr.push(lastPlannerLastPoint);
            item.map(
              (
                path_pt: {
                  longitude: number;
                  latitude: number;
                  created_date_time: number;
                  dTime: string;
                },
                i: number,
              ) => {
                path_pt.dTime = getDateTime(path_pt.created_date_time);
                arr?.push(
                  proj.fromLonLat([path_pt.longitude, path_pt.latitude]),
                );
              },
            );
            let tractorLocation: Feature<any> = new Feature();
            if (defaultLayer) {
              tractorLocation = defaultLayer?.getSource().getFeatureById(id);
            }
            if (!tractorLocation) {
              tractorLocation = new Feature();
            }
            oarr = [...oarr, ...arr];

            tractorLocation.setGeometry(new LineString(arr));
            // let color = '#13B0FC';
            // const borderColor = '#FFFFFF';
            // if (point.planner === pathContants.standby.key) {
            //   color = '#EECDB4';
            // } else if (
            //   point.planner === pathContants.copycat.key ||
            //   point.planner === pathContants.remoteav.key
            // ) {
            //   color = '#755bb6';
            // } else if (
            //   point.planner === pathContants.follow_me.key ||
            //   point.planner === pathContants.vinerow.key
            // ) {
            //   color = '#7adcda';
            // } else if (point.planner === pathContants.manual.key) {
            //   color = '#42aebc';
            // }
            let color = getPathColor(point.planner);
            const borderColor = getPathBorderColor(point.planner);
            if (key % 2) color = 'grey';
            const style = [
              new Style({
                stroke: new Stroke({
                  color: borderColor,
                  width: 8,
                }),
                zIndex: 0,
              }),
              new Style({
                stroke: new Stroke({
                  color: color,
                  width: 6,
                }),
                zIndex: 1,
              }),
            ];
            const pathHover = [
              new Style({
                stroke: new Stroke({
                  color: borderColor,
                  width: 10,
                }),
                zIndex: 1,
              }),
              new Style({
                stroke: new Stroke({
                  color,
                  width: 6,
                }),
                zIndex: 1,
              }),
            ];

            tractorLocation.setStyle(style);
            tractorLocation.set('style', style);
            tractorLocation.set('hoverStyle', pathHover);
            tractorLocation.set('name', selectedDriveActionUser);
            tractorLocation.set('pointType', 'PATH');
            tractorLocation.set('drawPointsText', point);
            tractorLocation.set('tractorId', tractorId);
            tractorLocation.set('tractor_name', tractor_name);
            tractorLocation.set('operator_name', operator_name);
            tractorLocation.set('jng', jng);
            defaultLayer?.getSource().addFeature(tractorLocation);
            if (arr && arr.length) {
              lastPlannerLastPoint = arr[arr.length - 1];
            }
          });
      });

    if (oarr.length > 1) {
      jAGSSIcons.current?.set(id, [jng, oarr[0], oarr[oarr.length - 1]]);
    } else if (points?.length == 1 && points[0]?.planner === 'standby') {
      const arr = points[0].path.slice(0, 2).map(
        (
          path_pt: {
            longitude: number;
            latitude: number;
            created_date_time: number;
            dTime: string;
          },
          i: number,
        ) => {
          return proj.fromLonLat([path_pt.longitude, path_pt.latitude]);
        },
      );
      if (arr.length) {
        jAGSSIcons.current?.set(id, [jng, arr[0], arr[arr.length - 1]]);
        handleCenterPoint(arr[0]);
      }
    }
    if (oarr.length) handleCenterPoint(oarr[0]);
  };

  useEffect(() => {
    if (guardRailsRefs.current) {
      guardRailsRefs.current.getSource().clear();
      mapRef.current?.removeLayer(guardRailsRefs.current);
    }
    const getSnapsGuardRails = async () => {
      try {
        const { organization } = userDetails;
        const snapshot = await getAllTractorSnapShot(
          organization.api_url,
          organization.farm.id,
          selectedJumpnGo.tractor.id,
          1,
          300,
          'completed',
          '',
          selectedJumpnGo.start_time
            ? selectedJumpnGo.start_time
            : selectedJumpnGo.drive_action_start_date_time,
          selectedJumpnGo.finish_time
            ? selectedJumpnGo.finish_time
            : selectedJumpnGo.drive_action_finish_date_time,
        );
        setMapSnapshots(snapshot);
      } catch (error) {
        console.error(error);
      }
    };

    if (selectedJumpnGo && userDetails && userDetails.organization) {
      getSnapsGuardRails();
      if (markerPointRef.current) {
        markerPointRef.current.setVisible(false);
      }
    }
  }, [selectedJumpnGo, userDetails]);

  useEffect(() => {
    if (mapSnapshots) {
      if (guardRailsRefs.current)
        mapRef.current?.removeLayer(guardRailsRefs.current);
      if (snapshotRefs.current)
        mapRef.current?.removeLayer(snapshotRefs.current);
      if (jumpAndGoRefs.current)
        mapRef.current?.removeLayer(jumpAndGoRefs.current);

      const { records } = mapSnapshots;
      const data = drawGuardrails(records);
      if (data) {
        const { guardrailsLayer, snapshotLayer } = data;
        guardRailsRefs.current = guardrailsLayer;
        snapshotRefs.current = snapshotLayer;
        guardRailsRefs.current.setVisible(showGuardRailsLayer);
        mapRef.current?.addLayer(guardrailsLayer);
        mapRef.current?.addLayer(snapshotLayer);
      }
    }
  }, [mapSnapshots]);

  const onCloseTicketWidget = () => {
    setShowIndicatorModel(false);
    setToggleTicketWidget(false);
  };

  useEffect(() => {
    if (mapInitialized) {
      handleClick();
      handlePointerMove();
      const pointLocation: Feature<any> = new Feature();
      pointLocation.setId(999);
      const selectPointFeatureLayer = new VectorLayer({
        source: new VectorSource({
          features: [pointLocation],
        }),
        visible: true,
        style: new Style({}),
        zIndex: 111,
      });
      mapRef.current?.addLayer(selectPointFeatureLayer);
      hoverPointLayerRef.current = selectPointFeatureLayer;
    }
  }, [mapInitialized]);

  const handleClick = () => {
    mapRef.current?.on('click', (evt) => {
      if (markerPointRef.current) {
        mapRef.current?.removeLayer(markerPointRef.current);
      }
      mapRef.current?.forEachFeatureAtPixel(evt.pixel, function (feature: any) {
        const feat = feature.get('features');
        if (feat && feat.length > 1) {
          const view = mapRef.current?.getView();
          if (view) {
            const zoom = view.getZoom();
            if (zoom && zoom < 18) {
              view.setZoom(18);
            }
          }
          feat.map((f: any) => {
            const point = f.get('point');
            f.setGeometry(
              new Point(
                proj.fromLonLat([
                  point[0] * Number(`1.000000${Math.floor(Math.random() * 3)}`),
                  point[1] * Number(`1.000000${Math.floor(Math.random() * 3)}`),
                ]),
              ),
            );
          });
        }
        const pointType = feature.get('pointType');
        if (pointType === 'Polygon') return;
        if (pointType === 'vinerow') return;
        if (pointType === 'Mark') return;
        if (
          (globalMapClickAllow && pointType === 'PATH') ||
          pointType == 'SNAP' ||
          pointType == 'INDICATOR' ||
          pointType == 'Ticket'
        ) {
          globalMapClickAllow = false;
          setTimeout(() => {
            globalMapClickAllow = true;
          }, 100);
          if (feat && feat.length >= 1) {
            const pointType = feat[0].get('pointType');
            if (pointType == 'SNAP') {
              const content = feat[0].get('content');
              if (!toggleForGuardRails) {
                const point = feat[0].get('point');
                if (
                  [GUARD_RAILS, GILES, ATOM, JUMP_AND_GO].includes(
                    content?.created_at,
                  )
                ) {
                  setGuardrailData({
                    guard_rail_triggered_uuid: content.transaction_id,
                    id: content.tractor_id.id || content.tractor_id,
                    tractor_id: content.tractor_id.id || content.tractor_id,
                    title: getDateTime(content.created_date_time),
                    created_date_time: content.created_date_time,
                    guard_rail_type: content.guard_rail_type,
                    created_at: content.created_at,
                    created_by: content.created_by,
                  });
                  setShowGuardrailModel(true);

                  setShowIndicatorModel(false);
                  setShowTicketPopup(false);
                  setRightSideThumbnailsToggle(false);
                  setRightSideToggle(false);
                  globalGuardrailData = content;
                } else {
                  // setSelectedMapSnapshot(content);
                }
                toggleForGuardRails = true;
                const view = mapRef.current?.getView();
                if (view) {
                  mapRef.current?.getView().setCenter(proj.fromLonLat(point));
                }
              } else {
                // setGuardrailData(null);
                if (content?.id === globalGuardrailData?.id)
                  setShowGuardrailModel(false);
                toggleForGuardRails = false;
              }
            } else if (pointType && pointType === 'INDICATOR') {
              if (!toggleForIndicator) {
                const point = feat[0].get('point');
                const content = feat[0].get('content');
                const { subsystem = '', functionality_affected = '' } =
                  content.error_code_data ? content.error_code_data : {};
                content.functionality_affected = functionality_affected;
                content.subsystem = subsystem;
                content.severity = content.indicator_level;
                content.start_time = content.created_date_time
                  ? getDateTimes(content.created_date_time)
                  : '';
                content.counter_measure = content?.error_code_data
                  ?.counter_measure
                  ? content?.error_code_data?.counter_measure?.split(';')
                  : '';
                setErrorDetails(content);
                setShowIndicatorModel(true);

                setShowGuardrailModel(false);
                setShowTicketPopup(false);
                setRightSideThumbnailsToggle(false);
                setRightSideToggle(false);
                toggleForIndicator = true;
                mapRef.current?.getView().setCenter(proj.fromLonLat(point));
              } else {
                setShowIndicatorModel(false);
                toggleForIndicator = false;
              }
            } else if (pointType && pointType === 'Ticket') {
              if (!toggleForTicket) {
                const content = feature.get('content');
                const ticketId = content.id;
                getFleetTicketDetails(ticketId, userDetails)
                  .then((data) => {
                    setSelectedTicket(data);
                    setShowTicketPopup(true);

                    setShowIndicatorModel(false);
                    setShowGuardrailModel(false);
                    setRightSideThumbnailsToggle(false);
                    setRightSideToggle(false);
                    toggleForTicket = true;
                  })
                  .catch((error) => {
                    //do nothing
                  });
              } else {
                setShowTicketPopup(false);
                toggleForTicket = false;
              }
            }
          } else if (pointType && pointType === 'PATH') {
            // on path click hide the ticket , indicator, guard rail
            setShowIndicatorModel(false);
            setShowGuardrailModel(false);
            setShowTicketPopup(false);

            const name = feature.get('name');
            const jng = feature.get('jng');
            if (name) {
              const geometry: any = feature.getGeometry();
              if (geometry) {
                const point = geometry.getClosestPoint(evt.coordinate);
                const coordinates = geometry.getCoordinates();
                for (
                  let element = 0;
                  element < coordinates.length - 1;
                  element++
                ) {
                  if (
                    new LineString([
                      coordinates[element],
                      coordinates[element + 1],
                    ]).intersectsCoordinate(point)
                  ) {
                    const drawPointsText = feature.get('drawPointsText');
                    if (drawPointsText.path && drawPointsText.path[element]) {
                      const t = drawPointsText.path[element].dTime;
                      const time = moment(t).toDate().getTime();
                      // const tractorId = feature.get('tractorId');
                      // on path click show the thumbnail
                      setRightSideThumbnailsToggle(() => {
                        setRightSideToggle(false);
                        setVideoTime(time);
                        return true;
                      });
                      if (markerPointRef.current) {
                        mapRef.current?.removeLayer(markerPointRef.current);
                      }
                      const lonLat = proj.transform(
                        evt.coordinate,
                        'EPSG:3857',
                        'EPSG:4326',
                      ); //, 'EPSG:4326'
                      const markerLocation = new Feature({
                        geometry: new Point(
                          proj.fromLonLat([lonLat[0], lonLat[1]]),
                        ),
                        pointType: 'Mark',
                      });
                      markerLocation.setStyle(
                        new Style({
                          image: new Icon({
                            src: RoundIcon,
                            scale: 1,
                          }),
                          zIndex: 100,
                        }),
                      );
                      const markerLayer = new VectorLayer({
                        source: new VectorSource({
                          features: [markerLocation],
                        }),
                        zIndex: 12,
                      });
                      mapRef.current?.addLayer(markerLayer);
                      markerPointRef.current = markerLayer;
                    }
                  }
                }
              }
            }
            // feature && displayRecenterAllViewPortSetUp([feature], mapRef.current);
            // if (!selectedJumpnGo && jng) setselectedJumpnGo(jng);
            // setLoadAll(false);
            // analyticDispatch({
            //   type: SET_SELECTED_JAG,
            //   payload: jng,
            // });
          }
        }
      });
    });
  };

  useEffect(() => {
    if (!showTicketPopup) {
      toggleForTicket = false;
    } else {
      setShowIndicatorModel(false);
      setShowGuardrailModel(false);
    }
  }, [showTicketPopup]);

  useEffect(() => {
    if (!showIndicatorModel) {
      toggleForIndicator = false;
    } else {
      setShowTicketPopup(false);
      setShowGuardrailModel(false);
    }
  }, [showIndicatorModel]);

  useEffect(() => {
    if (!showGuardrailModel) {
      toggleForGuardRails = false;
    } else {
      setShowTicketPopup(false);
      setShowIndicatorModel(false);
    }
  }, [showGuardrailModel]);

  const handlePointerMove = () => {
    const overlay = new Overlay({
      element: container.current,
      autoPan: false,
      autoPanAnimation: {
        duration: 10,
      },
    });
    let selected: any = null;
    mapRef.current?.on('pointermove', function (evt) {
      const pointLayer = hoverPointLayerRef.current;
      if (selected !== null) {
        const feat = pointLayer?.getSource().getFeatureById(999);
        feat?.setStyle(new Style({}));
        selected?.setStyle(selected?.get('style'));
        selected = null;
      }
      const hit = mapRef.current?.forEachFeatureAtPixel(
        evt.pixel,
        function (feature: any) {
          const pointType = feature.get('pointType');
          if (pointType && pointType === 'vinerow') {
            selected = feature;
            feature.setStyle(
              vineRowHoverStyle(globalZoom > onGlobalZoomToShowZoomIcons),
            );
            return true;
          }
          // features
          const feats = feature.get('features');
          if (feats && feats.length) {
            const pointType = feats[0].get('pointType');
            if (pointType == 'INDICATOR') {
              selected = feature;
              selected?.setStyle(IndicatorStyle(feats.length, true));
            }
            if (pointType == 'SPEED') {
              selected = feature;
              selected?.setStyle(SpeedStyle(feats.length, true));
            }
            if (pointType == 'Ticket') {
              selected = feature;
              selected?.setStyle(TicketStyle(feats.length, true));
            }
            if (pointType == 'SNAP') {
              const type = feats[0].get('type');
              selected = feature;
              selected?.setStyle(
                type === 'SNAPSHOT'
                  ? snapshotStyle(feats.length, true)
                  : guardRailStyle(feats.length, true),
              );
            }
          }

          if (pointType === 'PATH') {
            const feat = pointLayer?.getSource().getFeatureById(999);
            if (feat) {
              feat.setGeometry(
                new Point(
                  proj.fromLonLat(
                    proj.transform(evt.coordinate, 'EPSG:3857', 'EPSG:4326'),
                  ),
                ),
              );
              feat.setStyle(
                new Style({
                  image: new CircleStyle({
                    radius: 5,
                    stroke: new Stroke({
                      color: 'blue',
                    }),
                    fill: new Fill({
                      color: '#3399CC',
                    }),
                  }),
                  zIndex: 10,
                }),
              );
              selected = feature;
              selected?.setStyle(feature.get('hoverStyle'));
              const geometry = feature.getGeometry();
              const point = geometry.getClosestPoint(evt.coordinate);
              const coordinates = geometry.getCoordinates();

              for (
                let element = 0;
                element < coordinates.length - 1;
                element++
              ) {
                if (
                  new LineString([
                    coordinates[element],
                    coordinates[element + 1],
                  ]).intersectsCoordinate(point)
                ) {
                  const drawPointsText = selected.get('drawPointsText');
                  if (drawPointsText.path && drawPointsText.path[element]) {
                    drawPointsText.path[element]['tractor_name'] =
                      selected.get('tractor_name');
                    drawPointsText.path[element]['operator_name'] =
                      selected.get('operator_name');
                    const innerHTML = getFleetUsagePointsPathInfo(
                      drawPointsText.path[element],
                    );
                    selected.set('name', innerHTML);
                    content.current.innerHTML = innerHTML;
                    if (
                      !overlay
                        .getElement()
                        ?.classList.contains('ol-details-popup')
                    ) {
                      overlay.getElement()?.classList.add('ol-details-popup');
                    }
                    overlay.setPosition(evt.coordinate);
                    mapRef.current?.addOverlay(overlay);
                    return true;
                  }
                }
              }
            }
          }
          const coordinate = evt.coordinate;
          const name = feature.get('name');
          const dataType = feature.get('pointType');
          const contentData = feature.get('content');
          if (name) {
            content.current.innerHTML = '<p>' + name + '</p>';
            if (
              dataType == 'JAG_START_STOP' ||
              dataType == 'INDICATOR' ||
              dataType == 'Ticket' ||
              dataType == 'SPEED' ||
              name === 'GUARD_RAILS'
            ) {
              if (name === 'GUARD_RAILS') {
                const feat = feature.get('features')[0];
                const dataType = feat.get('pointType');
                const contentData = feat.get('content');
                content.current.innerHTML = getFleetUsagePointsInfo(
                  dataType,
                  contentData,
                );
              } else {
                if (
                  contentData &&
                  contentData.tractor_id &&
                  dataType === 'INDICATOR'
                ) {
                  const tractor = tractorsMap.get(contentData.tractor_id);
                  contentData.tractorName = tractor?.name || '';
                }
                content.current.innerHTML = getFleetUsagePointsInfo(
                  dataType,
                  contentData,
                );
                if (
                  contentData &&
                  contentData.tractor_id &&
                  dataType === 'SPEED'
                ) {
                  const tractor = tractorsMap.get(contentData.tractor_id);
                  contentData.tractorName = tractor?.name || '';
                }
                content.current.innerHTML = getFleetUsagePointsInfo(
                  dataType,
                  contentData,
                );
              }

              if (
                !overlay.getElement()?.classList.contains('ol-details-popup')
              ) {
                overlay.getElement()?.classList.add('ol-details-popup');
              }
            } else {
              // stop removing path class removal
              if (dataType !== 'PATH') {
                overlay.getElement()?.classList.remove('ol-details-popup');
              }
            }

            overlay.setPosition(coordinate);
            mapRef.current?.addOverlay(overlay);
            return true;
          }

          return false;
        },
      );
      if (hit) {
        evt.map.getTargetElement().style.cursor = 'pointer';
      } else {
        overlay.setPosition(undefined);
        mapRef.current?.addOverlay(overlay);
        evt.map.getTargetElement().style.cursor = '';
      }
    });
  };

  const handleShowImagesVideos = (e: boolean) => {
    setShowThumbnails(e);
    snapshotRefs.current?.setVisible(e);
  };
  const handleShowGuardRails = (e: boolean) => {
    guardRailsRefs.current?.setVisible(e);
  };

  const handleShowTravelledPath = (e: boolean) => {
    tractorPathLayerRef.current?.setVisible(e);
    jumpAndGoRefs.current?.setVisible(e);
  };

  const handleShowIndicators = (e: boolean) => {
    mapIndicatorRefs.current?.setVisible(e);
  };

  const handleShowSpeed = (e: boolean) => {
    mapSpeedRefs.current?.setVisible(e);
  };

  const handleShowTickets = (e: boolean) => {
    mapTicketsRefs.current?.setVisible(e);
  };

  const handleShowPolygons = (e: boolean) => {
    polygonsLayerRef.current?.setVisible(e);
  };
  useEffect(() => {
    if (!showThumbnails) {
      // toggleForGuardRails = false;
      if (!driveAction) setShowGuardrailModel(false);
    }
  }, [showThumbnails]);

  const toggleMapView = () => {
    track('Analytics Map', {
      event: `${toggleSatellite ? 'Map View' : 'satellite View'}`,
    });
    setToggleSatellite(!toggleSatellite);
    if (vineRowsLayerRef.current)
      toggleVineRowStyle(
        vineRowsLayerRef.current,
        !toggleSatellite,
        globalZoom >= onGlobalZoomToShowZoomIcons,
      );
  };

  useEffect(() => {
    if (selectedJumpnGo) {
      setSelectedDriveAction(selectedJumpnGo?.drive_action_uuid);
      // setShowThumbnails(true);
      setHoverName(selectedJumpnGo?.operator);
      // setRightSideThumbnailsToggle(true);
    }
    selectedJumpnGoRef.current = selectedJumpnGo;
  }, [selectedJumpnGo]);

  const toggleFullScreen = (flagdata: any) => {
    updateFullScreen(flagdata);
    setFullScreenFlag(flagdata);
  };

  const iconSection = useMemo(
    () => (
      <div
        className={`
      mapIconSec
      ${rightSideThumbnailsToggle || rightSideToggle ? ' anaExpand' : ''}
      ${
        showGuardrailModel || showIndicatorModel || showTicketPopup
          ? 'tglexpand'
          : ''
      }
      `}
        style={{ marginTop: '180px' }}
      >
        <div
          className={`mapIcons ${
            showThumbnails ? 'afterAnaMap' : 'beforeCollapseAnaMap'
          } `}
        >
          <div className="mapRecenter" onClick={handleRecenter}>
            <img src={MapCenterIcon} />
          </div>
          <div
            className={fullScreenFlag ? 'f_screenCol' : 'f_screen f_screen1'}
            onClick={() => {
              toggleFullScreen(!fullScreenFlag);
              setTimeout(() => {
                mapRef.current?.updateSize();
              }, 100);
            }}
          ></div>
          <div className="zoom_in_out">
            <button
              id={applicationIds.analyticsMapScreen.mapZoomIn}
              className="zoomIn"
              onClick={() => handleZoom('zoomIn')}
            />
            <button
              id={applicationIds.analyticsMapScreen.mapZoomOut}
              className="zoomOut"
              onClick={() => handleZoom('zoomOut')}
            />
          </div>
          <div className="mapView">
            <img
              id={applicationIds.analyticsMapScreen.toggleMap}
              src={toggleSatellite ? mapNor : mapSat}
              onClick={toggleMapView}
              alt=""
            />
          </div>
        </div>
      </div>
    ),
    [
      rightSideThumbnailsToggle,
      rightSideToggle,
      fullScreenFlag,
      toggleSatellite,
      showTicketPopup,
      showIndicatorModel,
      showGuardrailModel,
    ],
  );

  return (
    <>
      <div className={fullScreenFlag ? 'fullScreen' : 'normalCl'}>
        <div className="tblContainer lMapCont analyticsMapSec">
          <div
            ref={mapElement}
            style={{
              height: fullScreenFlag ? '100vh' : '100%',
              width: fullScreenFlag ? '100vw' : '100%',
            }}
            //to align right bottom of map distance
            className={`posHd ${showThumbnails ? 'afterAnaMapAlign' : ''} 
          ${
            rightSideToggle || rightSideThumbnailsToggle
              ? 'afterAnaMapRightAlign'
              : ''
          } 
          ${
            showGuardrailModel || showIndicatorModel || showTicketPopup
              ? 'afterAnaMapRightAlign1'
              : ''
          }`}
          >
            <div
              id={applicationIds.analyticsMapScreen.toggleRowBtn}
              className={
                rightSideThumbnailsToggle
                  ? 'dataLayerBtn tglexpand2'
                  : 'dataLayerBtn'
              }
              onClick={() => {
                setRightSideToggle(!rightSideToggle);
                setRightSideThumbnailsToggle(rightSideToggle);
              }}
            >
              <span className="dataArrow">{/* <LeftOutlined /> */}</span>
              <span className="dLIcon">
                <img src={datalayerIcon} alt="dataLayer" width="18" />
              </span>
              <span className="dLTxt">{t(translate.fleetMap.mapLayers)}</span>
            </div>
            {selectedJumpnGo && (
              <div
                id={applicationIds.analyticsMapScreen.toggleRowBtn}
                // className="dataLayerBtn"
                className={
                  rightSideToggle ? 'dataLayerBtn tglexpand2' : 'dataLayerBtn'
                }
                style={{ top: '150px' }}
                onClick={() => {
                  setRightSideThumbnailsToggle(!rightSideThumbnailsToggle);
                  setRightSideToggle(rightSideThumbnailsToggle);
                  if (selectedJumpnGo)
                    setVideoTime(
                      selectedJumpnGo['drive_action_start_date_time'],
                    );
                }}
              >
                <span className="dataArrow">{/* <LeftOutlined /> */}</span>
                <span className="dLIcon">
                  <img src={thumbnail_icon} alt="dataLayer" width="17" />
                </span>
                <span className="dLTxt">
                  {t(translate.fleetMap.thumbnails)}
                </span>
              </div>
            )}
            {iconSection}
            {selectedJumpnGo && (
              <>
                <div
                  className={
                    rightSideThumbnailsToggle
                      ? 'analyticsRightside expand'
                      : 'analyticsRightside'
                  }
                >
                  <ThumbnailMenu
                    showThumbnails={rightSideThumbnailsToggle}
                    setRightSideThumbnailsToggle={setRightSideThumbnailsToggle}
                  />
                </div>
              </>
            )}
            <div
              className={
                rightSideToggle
                  ? 'analyticsRightside expand'
                  : 'analyticsRightside'
              }
            >
              <div className="dataLayerWrapper">
                <div className="dataLayerRowHead mb28">
                  <span
                    onClick={() => {
                      track('Analytics Map', {
                        event: `${
                          rightSideToggle
                            ? 'Data Layer Closed'
                            : 'Data Layer Opened'
                        }`,
                      });
                      setRightSideToggle(!rightSideToggle);
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    <RightOutlined />
                  </span>
                  <span className="dataIcon">
                    <img src={datalayerIcon} alt="dataLayer" height="18" />
                  </span>
                  <span className="dataHead">
                    {t(translate.fleetMap.mapLayersCap)}
                  </span>
                </div>
                {/* datalayers block */}
                <div className="datalayerContent">
                  <div className="dataLayerRow mplChkBox">
                    <div className="checkBoxItem">
                      <span className="layerName">
                        {t(translate.fleetMap.mapData)}
                      </span>
                    </div>
                  </div>
                  <div className="dataLayerInnerRow mplChkBox">
                    <div className="checkBoxItem ml50">
                      <span className="iconW">
                        <img
                          src={guardRails}
                          alt="GuardRails"
                          width="14"
                          height="20"
                        />
                      </span>
                      <span className="layerName">
                        {t(translate.fleetMap.guardRails)}
                      </span>
                      <span className="lChkBox">
                        <Checkbox
                          data-testid="guardCheckBox-AnalyticMap"
                          defaultChecked
                          checked={showGuardRailsLayer}
                          onChange={(e) => {
                            handleShowGuardRails(e.target.checked);
                            setShowGuardRailsLayer(e.target.checked);
                          }}
                        />
                      </span>
                    </div>
                  </div>
                  <div className="dataLayerInnerRow mplChkBox">
                    <div className="checkBoxItem ml50">
                      <span className="iconW">
                        <img
                          src={SnapIcon}
                          alt="Snapshots"
                          width="14"
                          height="20"
                        />
                      </span>
                      <span className="layerName">
                        {t(translate.fleetMap.snapshots)}
                      </span>
                      <span className="lChkBox">
                        <Checkbox
                          data-testid="snapShotsCheckBox-AnalyticMap"
                          defaultChecked
                          checked={showSnapShotRailsLayer}
                          onChange={(e) => {
                            handleShowImagesVideos(e.target.checked);
                            setShowSnapShotRailsLayer(e.target.checked);
                          }}
                        />
                      </span>
                    </div>
                  </div>
                  <div className="dataLayerInnerRow mplChkBox">
                    <div className="checkBoxItem ml50">
                      <span className="iconW">
                        <img
                          src={FleetIndicator}
                          alt="Implements"
                          width="14"
                          height="20"
                        />
                      </span>
                      <span className="layerName">
                        {t(translate.fleetMap.healthIndicators)}
                      </span>
                      <span className="lChkBox">
                        <Checkbox
                          data-testid="healthIndicatorCheckBox-AnalyticMap"
                          defaultChecked
                          checked={showIndicatorsLayer}
                          onChange={(e) => {
                            handleShowIndicators(e.target.checked);
                            setShowIndicatorsLayer(e.target.checked);
                          }}
                        />
                      </span>
                    </div>
                  </div>
                  <div className="dataLayerInnerRow mplChkBox">
                    <div className="checkBoxItem ml50">
                      <span className="iconW">
                        <img
                          src={fleetSpeedIcon}
                          alt="speedTests"
                          width="14"
                          height="20"
                        />
                      </span>
                      <span className="layerName">
                        {t(translate.fleetMap.speedTest)}
                      </span>
                      <span className="lChkBox">
                        <Checkbox
                          data-testid="healthIndicatorCheckBox-AnalyticMap"
                          defaultChecked
                          checked={showSpeedLayer}
                          onChange={(e) => {
                            handleShowSpeed(e.target.checked);
                            setShowSpeedLayer(e.target.checked);
                          }}
                        />
                      </span>
                    </div>
                  </div>

                  <div className="dataLayerInnerRow mplChkBox">
                    <div className="checkBoxItem ml50">
                      <span className="iconW">
                        <img
                          src={TicketIcon}
                          alt="Implements"
                          width="14"
                          height="20"
                        />
                      </span>
                      <span className="layerName">
                        {t(translate.fleetMap.tickets)}
                      </span>
                      <span className="lChkBox">
                        <Checkbox
                          data-testid="ticketsCheckBox-AnalyticMap"
                          defaultChecked
                          checked={showTicketsLayer}
                          onChange={(e) => {
                            handleShowTickets(e.target.checked);
                            setshowTicketsLayer(e.target.checked);
                          }}
                        />
                      </span>
                    </div>
                  </div>

                  <div className="dataLayerInnerRow mplChkBox">
                    <div className="checkBoxItem ml50">
                      <span className="iconW">
                        <img
                          src={polygonIcon}
                          alt="Implements"
                          width="14"
                          height="20"
                        />
                      </span>
                      <span className="layerName">
                        {t(translate.fleetMap.polygon)}
                      </span>
                      <span className="lChkBox">
                        <Checkbox
                          data-testid="polygonCheckBox-AnalyticMap"
                          defaultChecked
                          checked={showPolygonsLayer}
                          onChange={(e) => {
                            handleShowPolygons(e.target.checked);
                            setshowPolygonsLayer(e.target.checked);
                          }}
                        ></Checkbox>
                      </span>
                    </div>
                  </div>
                  <div className="dataLayerRow mplChkBox">
                    <div className="checkBoxItem">
                      <span className="layerName">
                        {t(translate.fleetMap.pathData)}
                      </span>
                      <span
                        className="lChkBox"
                        data-testid="pathCheckBox-AnalyticMap"
                      >
                        <Checkbox
                          defaultChecked
                          checked={showPathlayer}
                          onChange={(e) => {
                            handleShowTravelledPath(e.target.checked);
                            setShowPathlayer(e.target.checked);
                          }}
                        ></Checkbox>
                      </span>
                    </div>
                  </div>

                  <div className="dataLayerInnerRow mplChkBox">
                    <div className="checkBoxItem ml50">
                      <span className="iconW">
                        <span className="pathBox manualBox"></span>
                      </span>
                      <span className="layerName">
                        {t(translate.fleetMap.manual)}
                      </span>
                    </div>
                  </div>

                  <div className="dataLayerInnerRow mplChkBox">
                    <div className="checkBoxItem ml50">
                      <span className="iconW">
                        <span className="pathBox operatorAssist"></span>
                      </span>
                      <span className="layerName">
                        {t(translate.fleetMap.operatorAssist)}
                      </span>
                    </div>
                  </div>
                  <div className="dataLayerInnerRow mplChkBox">
                    <div className="checkBoxItem ml50">
                      <span className="iconW">
                        <span className="pathBox autodrive"></span>
                      </span>
                      <span className="layerName">
                        {t(translate.fleetMap.autoDrive)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {mapInitialized && (
            <div ref={container} className="ol-popup">
              <div ref={content}></div>
            </div>
          )}
          {selectedTicket && showTicketPopup && (
            <TicketViewEdit
              Close={() => setShowTicketPopup(false)}
              ticketObj={selectedTicket}
              toggleWidget={showTicketPopup}
            ></TicketViewEdit>
          )}
          {showIndicatorModel && (
            <DiagnosticWidget
              isEdit={isEdit}
              details={errorDetails}
              setIsEdit={setIsEdit}
              setToggleWidget={setShowIndicatorModel}
              getSeverity={getSeverity}
              getTags={getTags}
              onCreateTicket={onCreateTicket}
              toggleWidget={showIndicatorModel}
              tractor={tractorsMap.get(errorDetails?.tractor_id)}
            />
          )}

          {toggleTicketWidget && (
            <TicketsCreateEdit
              tractorId={tractorId}
              Close={onCloseTicketWidget}
              ticketObj={ticketDetails}
              toggleWidget={toggleTicketWidget}
            ></TicketsCreateEdit>
          )}

          {showGuardrailModel && (
            <SnapView
              snapshotFlag={showGuardrailModel}
              guardrailsList={guardrailData}
              Close={() => setShowGuardrailModel(false)}
              selectedJumpnGo={selectedJumpnGo}
            />
          )}
        </div>
        <div className={!isLoading ? 'wave-loader' : 'hide'}>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </>
  );
};

export default LiveMap;
