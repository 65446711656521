import React, { createContext, useReducer } from 'react';
import UserManualReduer, { intialState } from './UserManualReducer';

interface UserManualAppContextType {
  userManualReducer: any[];
}

export const UserManualAppContext = createContext<UserManualAppContextType>({
  userManualReducer: [],
});

const UserManualContext: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(UserManualReduer, intialState);
  return (
    <UserManualAppContext.Provider
      value={{
        userManualReducer: [state, dispatch],
      }}
    >
      {children}
    </UserManualAppContext.Provider>
  );
};

export default UserManualContext;
