import React, { useRef, useState } from 'react';
import { Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
// import translate from '../../locale/en_translate.json';
import DriveOptionSettings from './DriveOptionSettings';

interface Props {
  showModal: boolean;
  handleClose: (details?: any) => void;
  id: string;
  tractorId: number;
  driveActionUUID: string;
}

const DriveOptionSettingModal: React.FC<Props> = ({
  showModal,
  handleClose,
  id,
  tractorId,
  driveActionUUID,
}) => {
  const details: any = useRef(null);
  const [isValid, setIsValid] = useState<boolean>(false);
  const { t } = useTranslation();

  return (
    <Modal
      visible={showModal}
      onCancel={() => handleClose()}
      className="operationParamsModal editParametersPopup"
      footer={false}
      width={500}
    >
      <div className="operationParamsContainer">
        <div className="operationParamsHeading">Operation Parameters</div>
        <div className="operationParamsDesp">
          Edited parameters will only be used for this Autodrive and will NOT
          override the default parameters set for this implement
        </div>
        <div className="opsSettings">
          <DriveOptionSettings
            id={id}
            tractorId={tractorId}
            driveActionUUID={driveActionUUID as string}
            handleDetails={(data) => {
              details.current = data;
            }}
            handleValidate={(state: boolean) => {
              setIsValid(state);
            }}
          />
        </div>
        <div className="d-flex operationParamsBtns">
          <Button
            className="Button noBorder cancelBtn"
            onClick={() => handleClose()}
          >
            Cancel
          </Button>
          <Button
            className="Button automatedBtn"
            onClick={() => isValid && handleClose(details.current)}
            disabled={!isValid}
          >
            Confirm
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DriveOptionSettingModal;
