/* eslint-disable react/jsx-no-undef */
import { Modal } from 'antd';
import React from 'react';

interface Props {
  showModel: boolean;
  closeModel: () => void;
  showdata: any;
}

const SupportHistoryplannerView: React.FC<Props> = ({
  showModel,
  closeModel,
  showdata,
}: Props) => {
  return (
    <Modal
      title={showdata?.direction.replace('_', ' ')}
      visible={showModel}
      className="baseModalContainer profileUpdatePopup grPopup"
      onCancel={closeModel}
      footer={null}
    >
      <div className="knowledgeFileVideo">
        <video width="100%" height="100%" id="video" controls>
          <source src={showdata?.video_url} id="source" />
        </video>
      </div>
    </Modal>
  );
};

export default SupportHistoryplannerView;
