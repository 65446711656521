/* eslint-disable no-console */
import { Modal } from 'antd';
import React from 'react';

import { GuardRailImage } from '../../constants/types';
import { getDateAndTime } from '../../constants/Common';

interface Props {
  image: GuardRailImage;
  snapPop: boolean;
  setSnapPop: (data: any) => void;
  guardrailId: number;
}
const AutodriveSnapshotPopup: React.FC<Props> = ({
  image,
  snapPop,
  setSnapPop,
  guardrailId,
}: Props) => {
  return (
    <>
      <Modal
        title={''}
        visible={snapPop}
        className="commonPopup"
        onCancel={() => setSnapPop(false)}
        footer={null}
      >
        <div className="">
          <div className="adsnapHead">{image.camera}</div>
          <div className="adsnapName">
            <span>
              <img src={image.image_url} width={`100%`} />
            </span>
          </div>
          <div className="adsnapFooter">{getDateAndTime(guardrailId)}</div>
        </div>
      </Modal>
    </>
  );
};

export default AutodriveSnapshotPopup;
