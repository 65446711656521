/* eslint-disable no-console */
import { DeleteOutlined, ReloadOutlined } from '@ant-design/icons';
import { Checkbox, Collapse, notification, Tooltip } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React, { useContext } from 'react';
import locationIcon from '../../assets/images/c_loc.svg';
import { publishVineBlockWithName } from '../../constants/Api';
import { MapFeature } from '../../constants/types';
import { ApplicationContext } from '../../context/AppContext';
import './style.css';
import { VINE_BLOCK, VINE_ROW } from '../../constants/constant';

interface Props {
  topic: MapFeature;
  setMapFeature: (data: MapFeature) => void;
  toggleMapItem: () => void;
  recenterSelctedFeature: (data: MapFeature) => void;
  reloadVineBlock?: () => void;
  deletVineBlock?: (id: string) => void;
}
const VBVRAccordion: React.FC<Props> = ({
  topic,
  setMapFeature,
  toggleMapItem,
  recenterSelctedFeature,
  reloadVineBlock,
  deletVineBlock,
}: Props) => {
  const { userDetails } = useContext(ApplicationContext);
  const { Panel } = Collapse;
  const onChange = (e: CheckboxChangeEvent) => {
    const item = e.target.value;
    item.visible = e.target.checked ? true : false;
    setMapFeature(item);
    toggleMapItem();
  };
  // publishing vine block
  const publishVineBlock = async (vine_block_name: string) => {
    const payload = {
      vine_block_name,
    };
    try {
      const { organization } = userDetails;
      const response: { msg: string } = await publishVineBlockWithName(
        userDetails.organization.api_url,
        organization.farm.id,
        payload,
      );
      reloadVineBlock && reloadVineBlock();
      notification.success({ message: response.msg });
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    }
  };
  return (
    <div>
      {topic && topic.name ? (
        <div
          className="accordionContainer accordionContainer-margin vineBlocksNewStyle"
          style={{ left: topic.type === VINE_ROW ? 10 : 0 }}
        >
          <table className="base">
            <tr>
              <td>
                <span className="text-aln">
                  <Checkbox
                    key={topic.name}
                    defaultChecked={topic.visible}
                    value={topic}
                    onChange={onChange}
                    className="mapCheckbox"
                  >
                    <div style={{ display: 'flex' }}>
                      <Tooltip title={topic.name}>
                        <div className="mapNames">{topic.name}</div>{' '}
                      </Tooltip>
                      {topic && topic?.type === VINE_BLOCK && (
                        <Tooltip title="Delete" placement="top">
                          <DeleteOutlined
                            onClick={(e) => {
                              e.preventDefault();
                              deletVineBlock && deletVineBlock(topic.mapId);
                            }}
                            style={{
                              zIndex: 99,
                              marginLeft: -22,
                              left: 245,
                              top: 7,
                            }}
                          />
                        </Tooltip>
                      )}
                      {topic &&
                        topic?.type === VINE_BLOCK &&
                        !topic.is_published && (
                          <Tooltip title="Publish" placement="top">
                            <ReloadOutlined
                              onClick={(e) => {
                                e.preventDefault();
                                publishVineBlock(topic?.name);
                              }}
                              style={{
                                zIndex: 99,
                                marginLeft: -22,
                                left: 228,
                                top: 7,
                              }}
                            />
                          </Tooltip>
                        )}
                      {(topic?.type === VINE_ROW ||
                        topic?.type === VINE_BLOCK) && (
                        <Tooltip title="Recenter" placement="top">
                          <img
                            style={{
                              zIndex: 99,
                              marginLeft: topic.type === VINE_ROW ? -20 : 15,
                              right: -38,
                              position: 'relative',
                            }}
                            src={locationIcon}
                            width="28"
                            onClick={(e) => {
                              e.preventDefault();
                              recenterSelctedFeature(
                                topic as unknown as MapFeature,
                              );
                              e.stopPropagation();
                            }}
                          />
                        </Tooltip>
                      )}
                    </div>
                  </Checkbox>
                </span>
              </td>
            </tr>
          </table>
          <div className="rpmSection">
            {topic && topic?.children && topic.children.length > 0 ? (
              <Collapse accordion expandIconPosition={'left'}>
                <Panel header="" key="1">
                  {topic?.children.map((chapter: MapFeature) => {
                    return (
                      <VBVRAccordion
                        topic={chapter}
                        key={chapter.name}
                        toggleMapItem={() => toggleMapItem()}
                        setMapFeature={(data: MapFeature) =>
                          setMapFeature(data)
                        }
                        recenterSelctedFeature={(data: MapFeature) =>
                          recenterSelctedFeature(data)
                        }
                      />
                    );
                  })}
                </Panel>
              </Collapse>
            ) : (
              <Collapse
                accordion
                collapsible="disabled"
                style={{ cursor: 'pointer' }}
              >
                <Panel header="" key="1" showArrow={false}>
                  {!!topic?.children?.length &&
                    topic?.children.map((chapter: MapFeature) => {
                      return (
                        <VBVRAccordion
                          topic={chapter}
                          key={chapter.name}
                          toggleMapItem={() => toggleMapItem()}
                          setMapFeature={(data: MapFeature) =>
                            setMapFeature(data)
                          }
                          recenterSelctedFeature={(data: MapFeature) =>
                            recenterSelctedFeature(data)
                          }
                        />
                      );
                    })}
                </Panel>
              </Collapse>
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default VBVRAccordion;
