import React, { useContext, useState } from 'react';
import { ApplicationContext } from '../../../../context/AppContext';
import { Tabs } from 'antd';
import LogViewer from './LogViewer';
import BasestationLogViewer from '../BaseStationLogViewer/BasestationLogViewer';

const { TabPane } = Tabs;

const LogViewerTabs = () => {
  const { APPReducer } = useContext(ApplicationContext);
  const [, dispatch] = APPReducer;
  const [tabid, setTabid] = useState<string>('tractors');

  return (
    <Tabs
      activeKey={tabid}
      centered
      id="org-tab-Organization"
      className="inventoryTabs"
      onChange={(key) => {
        setTabid(key);
      }}
    >
      <TabPane
        tab="Tractors"
        key="tractors"
        className="attTableHeight mBottom0"
      >
        {tabid === 'tractors' && <LogViewer />}
      </TabPane>
      <TabPane
        tab="Basestation"
        key="basestation"
        className="attTableHeight mBottom0"
      >
        {tabid === 'basestation' && <BasestationLogViewer />}
      </TabPane>
    </Tabs>
  );
};

export default LogViewerTabs;
