import { Button, notification, Modal } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import InfiniteScrollTable from '../common/InfiniteScrollTable';
import { getOtaLogsDetails } from '../../constants/Api';
import translate from '../../locale/en_translate.json';
import { useTranslation } from 'react-i18next';
import { ApplicationContext } from '../../context/AppContext';
import { getDateTimes } from '../../constants/Common';

interface Props {
  showModel: boolean;
  closeModel: () => void;
  otaRecords: any;
}

const OtaDetailsModal: React.FC<Props> = ({
  showModel,
  closeModel,
  otaRecords,
}: Props) => {
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const [loader, setLoader] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [totalCount, setTotalCount] = useState<any>();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPagesize] = useState<number>(25);
  const [filterData, setFilter] = useState<any[]>([]);

  useEffect(() => {
    if (otaRecords?.tractorId) {
      getOtaLogsList();
    }
  }, [userDetails, pageNumber, pageSize]);

  const getOtaLogsList = async () => {
    try {
      setLoader(true);
      const logs = await getOtaLogsDetails(
        userDetails.organization.api_url,
        userDetails.organization.fleet.id,
        otaRecords?.tractorId,
        otaRecords.id,
        pageNumber,
        pageSize,
      );
      setTotalCount(logs._metadata.total_records_count);
      const data =
        logs && logs.records && logs.records.length > 0 ? logs.records : [];
      const shadowData: any[] = [];
      data.map((record: any) => {
        const obj = {
          created_date_time: record?.created_date_time
            ? getDateTimes(record?.created_date_time)
            : '-',
          message: record?.message ? record.message : '-',
        };
        shadowData.push(obj);
      });
      if (data.length > 0) {
        if (pageNumber === 1) {
          setFilter([...shadowData]);
        } else {
          setFilter([...filterData, ...shadowData]);
        }
      } else {
        setFilter(shadowData);
      }
      if (shadowData.length < 25) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
      setLoader(false);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    }
  };

  const columns = [
    {
      title: `${t(translate.fleetticketsHistory.createdbydate)}`,
      dataIndex: 'created_date_time',
      key: 'created_date_time',
    },
    {
      title: `${t(translate.headers.description)}`,
      dataIndex: 'message',
      key: 'message',
    },
  ];

  const handleLoadMore = () => {
    setPageNumber(pageNumber + 1);
  };

  return (
    <Modal
      title={otaRecords && `${otaRecords?.name} OTA Logs`}
      visible={showModel}
      className="baseModalContainer profileUpdatePopup grPopup"
      onCancel={closeModel}
      footer={[
        <Button
          type="primary"
          key="back"
          onClick={closeModel}
          className="employeeCreate"
          size="large"
        >
          {t(translate.buttons.close)}
        </Button>,
      ]}
    >
      <div className="tblDft farmTabsTbl posRel tblStyles">
        <InfiniteScrollTable
          columns={columns}
          loading={loader}
          hasMore={hasMore}
          filterData={filterData}
          totalcount={totalCount}
          handleLoadMore={handleLoadMore}
          filename="Records"
        />
      </div>
    </Modal>
  );
};

export default OtaDetailsModal;
